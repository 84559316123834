import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { faFilePdf, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import Constants from '../../../values'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentEditable from 'react-contenteditable'
import { debounce } from 'lodash'
import Tippy from '@tippyjs/react'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Import Tippy styles
import PatientDetailsTooltip from './patientDetailsTooltip'
import ProviderDetailsTooltip from './providerDetailsTooltip'
import ChargeDetailsTooltip from './chargeDetailsTooltip'

import ReactTooltip from 'react-tooltip'
// import 'react-tooltip/dist/react-tooltip.css'

import { toast } from 'react-toastify'
import 'react-datepicker/dist/react-datepicker.css'
import PatientDetailsHeader from './patientDetailsHeader'
import ProviderDetailsHeader from './providerDetailsHeader'
import ChargeDetailsHeader from './chargeDetailsHeader'

const claimsColumns = ({
  providersList,
  patientList,
  generatePdf, // this is for super bill
  generateNotesPdf,
  navigatetoPatientProfile,
  onPatientNavLinkClicked,
  saveAppointmentData,
  saveToCareplanData,
  supervisor,
  openNotesEditModal,
  careplanNotesModal,
  onInsuranceEditClicked,
  renderEditable,
  handleInputChange,
  getStripeChargeUrl,
  getStripeRefund,
  openStripeRefundModal,
  openStripeChargeModal,
  navigateToCms1500,
}) => {
  // const[patientId, setPatientId] = useState(null)

  return [
    {
      Header: 'Date of Service',
      accessor: (obj) =>
        obj?.start
          ? moment(obj?.start).format('ll')
          : // I don't think this is being sent
            moment(obj?.careplan?.created_at).format('ll'),
      width: 100,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <DatePicker
                selected={filterValue ? new Date(filterValue) : null}
                onChange={(date) => {
                  setFilter(date ? moment(date).format('ll') : '')
                }}
                placeholderText="Select Date"
              />
            </div>
          </span>
        )
      },
      style: {
        position: 'sticky',
        left: 0,
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
    },
    {
      Header: 'Patient/ Client Name',
      // accessor: 'patientName',
      accessor: (obj) => {
        let patientData = obj
        return patientData?.firstName + ' ' + patientData?.lastName
      },
      Cell: ({ row }) => {
        let patientData = row?.original
        // console.log('row', row)
        let phoneNumber = patientData?.phone_number
        return (
          <>
            <Link
              onClick={() => {
                navigatetoPatientProfile(row?.original?.uid)
                onPatientNavLinkClicked('profile')
              }}
              to={{
                pathname: '/patient/profile',
                state: 'info',
                key: 2,
              }}
              className={Styles.link}
            >
              <PatientDetailsHeader
                firstName={patientData?.firstName}
                lastName={patientData?.lastName}
                patientId={patientData?.patient_id}
              />
              {/* <Tippy
                // content={
                //   <div>
                //     <div>ST:{phoneNumber}</div>
                //     <div>PH:{phoneNumber}</div>
                //   </div>
                // }
                content={
                  <PatientDetailsTooltip patientId={patientData?.patient_id} />
                }
                placement="right"
                onShow={(instance) => {
                  console.log('i->', instance)
                }}
              >
                {/* <Tippy
                interactive={true}
                hideOnClick={true}
                trigger="mouseenter focus"
                render={(attrs) => (
                  <div
                    {...attrs}
                    id="custom-tooltip"
                    role="tooltip"
                    className={Styles.tooltip} // Apply your main styles
                  >
                    My custom tooltip
                    <div
                      id="custom-arrow"
                      data-popper-arrow
                      className={Styles.tooltipArrow} // Apply the imported tooltip styles
                    ></div>
                  </div>
                )}
              > // comment ends here
                <p>
                  {patientData?.firstName || 'ERROR'}{' '}
                  {patientData?.lastName || '(OR DELETED)'}
                </p>
              </Tippy> */}
            </Link>
          </>
        )
      },
      width: 250,
      sort: true,
      style: {
        position: 'sticky',
        left: 200,
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        let bufferredString = ''
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search Name"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                // onChange={(e) => {
                //   setFilter(e.target.value)
                // }}
                onKeyDown={(e) => {
                  // console.log(e.target.value)
                  // What how is this working?
                  if (e.key === 'Enter') {
                    // bufferredString = e.target.value
                    // console.log('set', bufferredString)
                    setFilter(e.target.value)
                  }
                  if (e.key === 'Backspace') {
                    setFilter('')
                    // filterValue = filterValue.slice(0, -1)
                    // bufferredString = bufferredString.slice(0, -1)
                    // console.log('updating', bufferredString)
                  }
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Health Plan',
      accessor: (obj) => {
        let insurance = Array.isArray(obj?.insurances)
          ? obj?.insurances?.find((ins) => ins.preference === 1) || null
          : obj?.insurances || null
        return `${insurance?.insurance_company?.insurance_name || ''}`
      },
      Cell: ({ row }) => {
        let patientData = row?.original
        let insurance = Array.isArray(patientData?.insurances)
          ? patientData?.insurances?.find((ins) => ins.preference === 1) || null
          : patientData?.insurances || null
        return (
          <p
            onClick={() => {
              toast.info('Please edit insurance from the patient profile page')
              // onInsuranceEditClicked(
              //   true,
              //   patientData?.insurances,
              //   patientData?.uid,
              // )
            }}
            className={Styles.link}
          >
            {/* INFO: Use the front image/ back image if name does not exist */}
            {insurance?.insurance_company?.insurance_name || ''}
          </p>
        )
      },
      width: 250,
      sort: true,
    },
    {
      id: 'Billing Type',
      Header: 'Billing Type',
      accessor: (obj) => {
        // console.log('obj', obj?.patientData?.cashpay)
        if (obj?.patientData?.cashpay) return 'Cash Pay'
        else return 'Insurance Pay'
        // let insurance_name = Array.isArray(obj?.insurances)
        //   ? obj?.insurances?.find((ins) => ins.preference === 1) || null
        //   : obj?.insurances || null
        // // return `${insurance?.insurance_company?.insurance_name || ''}`
        // let insurance = Array.isArray(obj?.insurances)
        //   ? obj?.insurances?.find((ins) => ins.preference === 1) || null
        //   : obj?.insurances || null
        // // BUG: If patient has no insurance and not cash pay, this will default to cashpay
        // if (insurance?.insurance_type?.cashpay) return 'Cash Pay'
        // else if (insurance_name?.insurance_company?.insurance_name)
        //   return 'Insurance Pay'
        // else return 'NONE'
        // return `${
        //   insurance?.insurance_type?.cashpay ? 'Cash Pay' : 'Insurance Pay'
        // }`
      },
      width: 150,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="claimType"
                placeholder="Select"
                options={[
                  { label: 'Cash Pay', value: 'Cash Pay' },
                  { label: 'Insurance Pay', value: 'Insurance Pay' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.label)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Rendering Provider',
      accessor: (obj) => {
        return obj.providerName
        // let providerData = obj?.providerData || null
        // return `${providerData?.first_name || ''} ${
        //   providerData?.last_name || ''
        // }`
      },
      Cell: ({ row }) => {
        let providerName = row?.original?.providerName || 'ERROR'
        let providerId = row?.original?.providerData?.firebase_id || 'ERROR'
        return (
          <ProviderDetailsHeader
            providerName={providerName}
            providerId={providerId}
          />
          // <Tippy
          //   content={<ProviderDetailsTooltip providerId={providerId} />}
          //   placement="right"
          //   onShow={(instance) => {
          //     console.log('i->', instance)
          //   }}
          // >
          //   <p>{providerName || 'ERROR'}</p>
          // </Tippy>
        )
      },
      width: 300,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      sort: true,
    },
    {
      id: 'Status',
      Header: 'Status',
      accessor: (obj) => obj?.claim?.status,
      Cell: ({ row }) => {
        let note_status = null
        let careplanList = row?.original?.careplan
        if (Array.isArray(careplanList)) {
          note_status = careplanList?.[0]?.note_status
        } else {
          note_status = careplanList?.note_status
        }
        if (row?.original?.valid_claim) note_status = 'sent_for_billing'
        let status = row?.original?.claim?.status || note_status
        let ifNoShow = row?.original?.no_show

        return (
          <p
            style={{
              color:
                status === 'denied'
                  ? 'red'
                  : status === 'fully_paid'
                  ? 'green'
                  : status === 'partial_payment'
                  ? 'yellow'
                  : status === 'needs_investigation'
                  ? 'yellow'
                  : status === 'deductible_applies'
                  ? 'yellow'
                  : status === 'sent_for_billing'
                  ? 'brown'
                  : ifNoShow
                  ? 'black'
                  : status === 'ready_to_bill' || status === 'completed'
                  ? 'black'
                  : 'red',
            }}
          >
            {' '}
            {status === 'denied'
              ? 'Denied'
              : status === 'fully_paid'
              ? 'Full Paid'
              : status === 'partial_payment'
              ? 'Partial Payment'
              : status === 'needs_investigation'
              ? 'Needs Investigation'
              : status === 'deductible_applies'
              ? 'Deductible applies'
              : status === 'sent_for_billing'
              ? 'Sent for Billing'
              : ifNoShow
              ? '-'
              : status === 'ready_to_bill' || status === 'completed'
              ? 'Ready to Bill'
              : 'Not ready to Bill'}
          </p>
        )
      },
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="Statustype"
                placeholder="Select"
                options={[
                  {
                    label: 'Not Ready to Bill',
                    value: 'not_ready_to_bill',
                  },
                  {
                    label: 'Ready to Bill',
                    value: 'ready_to_bill',
                  },
                  {
                    label: 'Sent for Billing',
                    value: 'sent_for_billing',
                  },
                  {
                    label: 'Deductible Applies',
                    value: 'deductible_applies',
                  },
                  {
                    label: 'Needs Investigation',
                    value: 'needs_investigation',
                  },
                  {
                    label: 'Partial Payment',
                    value: 'partial_payment',
                  },
                  {
                    label: 'Fully Paid',
                    value: 'fully_paid',
                  },
                  {
                    label: 'Denied',
                    value: 'denied',
                  },
                  // { label: 'Created', value: 'Created' },
                  // { label: 'Sent', value: 'Sent' },
                  // { label: 'Deductible Applies', value: 'Deductible Applies' },
                  // {
                  //   label: 'Needs Investigation',
                  //   value: 'Needs Investigation',
                  // },
                  // { label: 'Partial Payment', value: 'Partial Payment' },
                  // { label: 'Fully Paid', value: 'Fully Paid' },
                  // { label: 'Denied', value: 'Denied' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Claim Number',
      accessor: 'claim_number',
      Cell: ({ row }) => {
        let claim_number = row?.original?.claim_number?.[0]?.claimid
        return <div>{claim_number}</div>
      },
      width: 200,
    },
    {
      id: 'Days from submission date',
      Header: 'Days from submission date',
      accessor: (obj) => {
        if (
          obj?.days_from_submission == undefined ||
          obj?.days_from_submission == null
        )
          return 'Not Submitted'
        if (obj?.days_from_submission > 45) return '>45'
        else return obj?.days_from_submission
      },
      Cell: ({ row }) => {
        return <dib>{row.original?.days_from_submission}</dib>
      },
    },
    {
      id: 'Total Charges Billed',
      Header: 'Total Charges Billed',
      accessor: (obj) => (obj?.billed_amount ? obj?.billed_amount : '-'),
      Cell: ({ row }) => {
        // let billed_amount = row?.original?.billed_amount
        //   ? '$' + row?.original?.billed_amount?.replace(/[^0-9|.]/g, '')
        //   : '-'
        // console.log('row', row?.original)
        // let billed_amount = row?.original?.visit_fee
        let amount_collected = row?.original?.invoice?.[0]?.amount_paid
        if(amount_collected) {
          amount_collected = parseFloat(amount_collected / 100).toFixed(2)
        }
        let billed_amount = row?.original?.claim_total_charge || amount_collected || "-"
        let eventId = row?.original?.event_id
        // let ifNoShow = row?.original?.no_show
        return (
          <ChargeDetailsHeader
            eventId={eventId}
            // noShow={ifNoShow}
            billed_amount={billed_amount}
          />
          // <Tippy
          //   content={<ChargeDetailsTooltip eventId={eventId} />}
          //   placement="right"
          //   onShow={(instance) => {
          //     console.log('i->', instance)
          //   }}
          // >
          //   <p>{billed_amount || ''}</p>
          // </Tippy>
          // <ContentEditable
          //   html={billed_amount}
          //   onChange={debounce(async (event) => {
          //     // if(event.target.value === keyboa
          //     await handleInputChange(row, 'billed_amount', event)
          //   }, 2500)}
          // />
        )
      },
      width: 200,
      // sort: true,
    },
    {
      Header: 'Allowed Amount',
      accessor: (obj) => (obj?.allowed_amount ? obj?.allowed_amount : '-'),
      Cell: ({ row }) => {
        let allowed_amount = row?.original?.allowed_amount
          ? '$' + row?.original?.allowed_amount?.replace(/[^0-9|.]/g, '')
          : '-'
        return (
          <ContentEditable
            html={allowed_amount}
            onChange={(event) =>
              handleInputChange(row, 'allowed_amount', event)
            }
          />
        )
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Patient Amount Due (Allowed Amt - Insurance Paid Amt)',
      accessor: (obj) =>
        obj?.coinsurance_amount ? obj?.coinsurance_amount : '-',
      Cell: ({ row }) => {
        let due_amount =
          (row?.original?.allowed_amount?.replace(/[^0-9|.]/g, '') || 0) -
          (row?.original?.insurance_amount?.replace(/[^0-9|.]/g, '') || 0)
        let amount_paid = row?.original?.invoice?.[0]?.amount_paid || 0
        due_amount =
          parseFloat(due_amount).toFixed(2) -
          parseFloat(amount_paid / 100).toFixed(2)
        let coinsurance_amount = row?.original?.coinsurance_amount?.replace(
          /[^0-9|.]/g,
          '',
        )
          ? '$' + row?.original?.coinsurance_amount?.replace(/[^0-9|.]/g, '')
          : due_amount > 0
          ? '$' + due_amount.toString()
          : '-'
        return (
          <ContentEditable
            html={coinsurance_amount}
            onChange={(event) =>
              handleInputChange(row, 'coinsurance_amount', event)
            }
          />
        )
      },
      width: 200,
      sort: true,
    },
    {
      // INFO: Change this to show stripe invoice on clicking
      id: 'Copay/Cash Amount Collected',
      Header: 'Copay/Cash Amount Collected',
      // accessor: 'copay_amount_collected',
      accessor: (obj) => {
        // BUG: I need to check if this is correct
        let copay_amount_collected = obj?.copay_amount_collected
          ? '$' + obj?.copay_amount_collected?.replace(/[^0-9|.]/g, '')
          : '-'
        return copay_amount_collected || '-'
      },
      Cell: ({ row }) => {
        let invoice = row?.original?.invoice
        let copay_amount_collected = row?.original?.copay_amount_collected
        let amount_collected = row?.original?.invoice?.[0]?.amount_paid
        if (amount_collected)
          amount_collected = '$' + parseFloat(amount_collected / 100).toFixed(2)
        // console.log('invoice', invoice)
        if (!invoice) return '-'
        // else return 'OK'
        else if (invoice.length > 0) {
          return (
            <div className={Styles.link}>
              {invoice.map((inv) => {
                if (inv?.amount_paid && inv?.refunded !== true) {
                  return (
                    <p
                      onClick={() => {
                        inv?.id && getStripeChargeUrl(inv.charge)
                      }}
                      className={Styles.link}
                    >
                      {amount_collected}
                    </p>
                  )
                }
              })}
            </div>
          )
        } else {
          return 'N/A'
        }
      },
    },
    {
      // This is kinda incorrectly built,
      // there are estimations made and
      // incorrect field values checked,
      // will need to finally come back to
      // this once claimMD is completely integrated
      id: 'Percent Collected',
      Header: 'Percent Collected',
      accessor: (obj) => {
        let res = null
        let invoice = obj?.invoice?.[0] || 0
        let invoice_amount = parseFloat(invoice?.amount_paid / 100).toFixed(2)
        if (Number.isNaN(invoice_amount) || invoice_amount === 'NaN')
          invoice_amount = 0
        // console.log((obj?.insurance_amount?.replace(/[^0-9|.]/g, "")), (obj?.billed_amount|| obj?.visit_fee.replace(/[^0-9|.]/g, "")))
        // return obj?.insurance_amount && obj?.visit_fee && obj?.billed_amount
        // if (obj?.patientData?.firebase_id == 'qtd4FrXnD6PkAWGHhJYlJDeJru52')
        //   console.log('obj', obj)
        if (obj?.visit_fee)
          res =
            (
              (
                Number(obj?.insurance_amount?.replace(/[^0-9|.]/g, '') || 0) +
                Number(invoice_amount)
              ).toFixed(2) /
              (obj?.billed_amount?.replace(/[^0-9|.]/g, '') ||
                obj?.visit_fee.toString()?.replace(/[^0-9|.]/g, ''))
            ).toFixed(2) * 100 || '-'
        if (typeof res === 'number' && res > 100) return '100%'
        else if (res == 'number') return res + '%'
        return '-'
      },
      width: 100,
    },
    {
      Header: 'Charge',
      accessor: (obj) => {
        let invoicePaid = obj?.invoice?.[0]?.amount_paid ? 'Paid' : 'Charge'
        return invoicePaid
      },
      Cell: ({ row }) => {
        let patientData = row?.original?.patientData
        let data = row?.original
        let duration = moment(data.end).diff(moment(data.start), 'minutes')
        let isSpecialist =
          data?.providerData?.user_type !== 'Health Coach' &&
          data?.providerData?.user_type !== 'Wellness Coach'
        let amount_paid = row?.original?.invoice?.[0]?.amount_paid
        let has_cc = row?.original?.has_cc
        return (
          <div
            onClick={() => {
              if (amount_paid) return
              let due_amount =
                (row?.original?.allowed_amount?.replace(/[^0-9|.]/g, '') || 0) -
                (row?.original?.insurance_amount?.replace(/[^0-9|.]/g, '') || 0)
              let patient_due = row?.original?.coinsurance_amount?.replace(
                /[^0-9|.]/g,
                '',
              )
                ? '$' +
                  row?.original?.coinsurance_amount?.replace(/[^0-9|.]/g, '')
                : due_amount > 0
                ? '$' + due_amount.toString()
                : '-'
              if (patientData?.stripe?.customer_id) {
                openStripeChargeModal(
                  patientData?.stripe?.customer_id,
                  isSpecialist,
                  data?.event_id,
                  duration,
                  data.doctor_id,
                  row?.original?.allowed_amount,
                  patient_due,
                  patientData?.firebase_id,
                  // BUG: This is not working
                  data?.start || data?.careplan?.created_at,
                  data?.careplan?._id,
                )
              } else {
                window.alert(
                  // 'Customer has not saved their CC details, please contact support',
                  'No Credit Card details found!',
                )
              }
            }}
            // to={{
            //   pathname: '/patient/profile',
            //   state: 'info',
            //   key: 2,
            // }}
            className={
              amount_paid ? Styles.notLink : has_cc ? Styles.link : Styles.error
            }
          >
            {amount_paid ? 'Paid' : has_cc ? 'Charge' : 'NO CC'}
          </div>
        )
      },
      width: 150,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="claimCharged"
                placeholder="Select"
                options={[
                  {
                    label: 'Charge',
                    value: 'Charge',
                  },
                  {
                    label: 'Paid',
                    value: 'Paid',
                  },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.label)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Notes',
      accessor: (obj) => {
        let res = ''
        if (obj?.careplan && Array.isArray(obj?.careplan)) {
          // obj?.careplan.forEach((careplan, index) => {
          for (let index in obj?.careplan) {
            // console.log(
            //   'obj?.careplan[index].note_status',
            //   obj?.careplan[index].note_status,
            // )
            // res += careplan.note_status.toUpperCase()
            res += obj?.careplan[index]?.note_status?.toUpperCase()
            if (index < obj?.careplan?.length - 1) res += ', '
          }
        }
        return res
      },
      Cell: ({ row }) => {
        let ifNoShow = row?.original?.no_show
        let careplanList = row?.original?.careplan

        if (ifNoShow) return <p style={{ color: 'red' }}>NO SHOW</p>

        if (Array.isArray(careplanList)) {
          return (
            <div>
              {careplanList &&
                // Array.isArray(row?.original?.careplan) &&
                careplanList.map((eachCarePlan, index) => {
                  // return <p>{careplan.note_status.toUpperCase()},</p>
                  return (
                    <div
                      key={index}
                      style={{
                        // display: 'flex',
                        // flexDirection: 'row',
                        justifyContent: 'space-around',
                      }}
                    >
                      {/* <p
                        onClick={() => {
                          // console.log('view note', row.original)
                          // let careplanNote = careplan?.careplan
                          // careplanNote.doctor = row.original?.providerData
                          // row?.original?.careplan &&
                          // openNotesEditModal(careplanNote, row?.original)
                          openNotesEditModal(
                            row?.original?.event_id, // TODO: Edit this to event id
                            row.original?.providerData,
                          )
                        }}
                        className={Styles.link}
                        style={{
                          textDecoration: 'underline',
                          color:
                            (!eachCarePlan ||
                              !(
                                (eachCarePlan?.note_status === 'completed')
                                // Assuming if completed then ICD10 code is present
                                // &&
                                // eachCarePlan?.careplan?.some(
                                //   (icd) => icd?.icd_10?.icd10_code,
                                // )
                              )) &&
                            'red',
                        }}
                      >
                        {eachCarePlan
                          ? eachCarePlan?.note_status === 'completed'
                            ? // Assuming if completed then ICD10 code is present
                              // &&
                              //   // Why was this logic being checked?
                              //   eachCarePlan?.careplan?.some(
                              //     (icd) => icd?.icd_10?.icd10_code,
                              //   )
                              'Notes'
                            : 'Incomplete'
                          : 'No Notes Found'}
                      </p> */}
                      {eachCarePlan && (
                        <FontAwesomeIcon
                          onClick={() => {
                            // console.log('view note', row.original)
                            eachCarePlan &&
                              // generateNotesPdf(
                              //   eachCarePlan.note_id, // TODO: get this from backend, edit to take event id
                              //   row?.original,
                              // )
                              careplanNotesModal(
                                eachCarePlan.note_id,
                                row.original?.providerData,
                              )
                          }}
                          icon={faFilePdf}
                          style={{
                            color:
                              !eachCarePlan ||
                              !(eachCarePlan?.note_status === 'completed')
                                ? 'red'
                                : Constants.primaryTheme,
                            fontSize: 20,
                            marginLeft: 10,
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </div>
                  )
                })}
            </div>
          )
        } else {
          let careplan = careplanList // In this care careplanList is a object
          return (
            <div
              style={{
                // display: 'flex',
                // flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              {/* <p
                onClick={() => {
                  console.log('opening notes')
                  openNotesEditModal(
                    careplan.note_id, // TODO: Edit this to event id
                    row.original?.providerData,
                  )
                }}
                className={Styles.link}
                style={{
                  textDecoration: 'underline',
                  color:
                    (!careplan ||
                      !(
                        (careplan?.note_status === 'completed')
                        // &&
                        // careplan?.careplan?.some(
                        //   (icd) => icd?.icd_10?.icd10_code,
                        // )
                      )) &&
                    'red',
                }}
              >
                {careplan
                  ? careplan?.note_status === 'completed'
                    ? // &&
                      //   // Why was this logic being checked?
                      //   // Also do i need to do this for async notes?
                      //   careplan?.careplan?.some((icd) => icd?.icd_10?.icd10_code)
                      'Notes'
                    : 'Incomplete'
                  : 'No Notes Found'}
              </p> */}
              {careplan && (
                <FontAwesomeIcon
                  onClick={() => {
                    careplan &&
                      // generateNotesPdf(
                      //   careplan.note_id, // TODO: get this from backend, edit to take event id
                      //   row?.original,
                      // )
                      careplanNotesModal(
                        careplan.note_id, // TODO: Edit this to event id
                        row.original?.providerData,
                      )
                  }}
                  icon={faFilePdf}
                  style={{
                    color:
                      !careplan || !(careplan?.note_status === 'completed')
                        ? 'red'
                        : Constants.primaryTheme,
                    fontSize: 20,
                    marginLeft: 10,
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
          )
        }
      },
      width: 100,
    },
    // BUG: Need to fix
    {
      Header: 'Superbill',
      // accessor: 'Superbill',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              // console.log('view superbill', row.original)
              window.alert('Cannot generate superbill, contact support')
              // generatePdf(row.original)
            }}
            className={Styles.link}
            style={{ textDecoration: 'underline' }}
          >
            View
          </p>
        )
      },
      width: 100,
    },
    // BUG: Refund mechanism needs to be tested
    {
      Header: 'Refund',
      accessor: (obj) => {
        let res = ''
        let invoice = obj?.invoice?.[0]
        return invoice?.refunded
          ? 'Refunded $' +
              parseFloat(invoice?.refunds?.data?.[0]?.amount / 100).toFixed(2)
          : // : invoice?.charge
            // ? 'Refund'
            'N/A'
      },
      Cell: ({ row }) => {
        let invoice = row?.original?.invoice?.[0]
        return (
          <div
            onClick={() => {
              // window.alert('Cannot refund, please contact support')
              if (!invoice?.refunded && invoice?.charge) {
                openStripeRefundModal(
                  invoice.charge,
                  invoice?.transfer_data?.destination ? true : false,
                  parseFloat(invoice?.amount_paid / 100).toFixed(2),
                )
              }
            }}
            className={
              invoice?.refunded || !invoice?.charge
                ? Styles.disabledCursor
                : Styles.link
            }
          >
            {invoice?.refunded
              ? 'Refunded $' +
                parseFloat(invoice?.refunds?.data?.[0]?.amount / 100).toFixed(2)
              : invoice?.charge
              ? 'Refund'
              : 'N/A'}
          </div>
        )
      },
      width: 150,
    },
    {
      id: 'CMS-1500',
      Header: 'CMS-1500',
      width: 100,
      Cell: ({ row }) => {
        // Add link to cms 1500
        return (
          <div
            onClick={() => {
              console.log('Navigate to CMS 1500')
              // navigateToCms1500(row?.original)
              // INFO: This actually opens the CMS1500 modal and not the insurance modal,
              // I have reused this component, there was a weird bug when trying to render two modals with the same table.
              // Super weird, will fix if I ever get time.
              console.log('Opening CMS 1500 for eventid', row?.original)
              onInsuranceEditClicked(
                true,
                row?.original?.event_id,
                row?.original?.claim_saved,
              )
            }}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </div>
          // <p
          //   onClick={() => {
          //     console.log('Navigate to CMS 1500')
          //     // navigateToCms1500(row?.original)
          //     // INFO: This actually opens the CMS1500 modal and not the insurance modal,
          //     // I have reused this component, there was a weird bug when trying to render two modals with the same table.
          //     // Super weird, will fix if I ever get time.
          //     console.log('OPening CMS 1500 for eventid', row?.original)
          //     onInsuranceEditClicked(true, row?.original?.event_id)
          //   }}
          //   // to={{
          //   //   pathname: '/admin',
          //   //   state: 'cms_1500',
          //   //   key: 2,
          //   // }}
          //   className={Styles.link}
          // >
          //   CMS 1500
          //   {/* {row.patientName || 'ERROR (OR DELETED)'} */}
          // </p>
        )
      },
    },
    // {
    //   Header: 'Claim File',
    //   accessor: (obj) => {
    //     return obj?.claim_file
    //   },
    // },
    // {
    //   Header: 'Email',
    //   accessor: (obj) => {
    //     let patientData = obj.patientData
    //     return patientData?.email
    //   },
    // },

    // {
    //   id: 'DOB',
    //   Header: 'DOB',
    //   accessor: (obj) => {
    //     // console.log('obj', obj)
    //     let patientData = obj.patientData
    //     let dob = patientData?.dob
    //     if (dob) {
    //       // Convert from dd/mm/yyyy to mm/dd/yyyy
    //       let date = dob.split('/')
    //       let newDate = date[1] + '/' + date[0] + '/' + date[2]
    //       return newDate
    //     } else return '-'
    //   },
    // },
    // {
    //   Header: 'Gender',
    //   accessor: (obj) => {
    //     let patientData = obj.patientData
    //     return patientData?.gender
    //   },
    // },
    // {
    //   id: 'Phone',
    //   Header: 'Phone',
    //   accessor: (obj) => {
    //     let patientData = obj.patientData
    //     return patientData?.phone_number
    //   },
    // },
    // {
    //   id: 'Patient Address Line 1',
    //   Header: 'Patient Address Line 1',
    //   accessor: (obj) => {
    //     let patientData = obj.patientData
    //     return patientData?.street_address
    //   },
    // },
    // {
    //   id: 'Patient Address Line 2',
    //   Header: 'Patient Address Line 2',
    //   accessor: (obj) => {
    //     let patientData = obj.patientData
    //     return patientData?.street_address2
    //   },
    // },
    // {
    //   Header: 'Patient City',
    //   accessor: (obj) => {
    //     let patientData = obj.patientData
    //     if (patientData?.zipcode_data?.city)
    //       return patientData?.zipcode_data?.city
    //     else if (patientData?.address_components) {
    //       let city = patientData?.address_components?.find(
    //         (comp) =>
    //           comp.types[0] === 'locality' || comp.types[1] === 'locality',
    //       )
    //       return city?.long_name
    //     } else return 'UNKNOWN'
    //   },
    // },
    // {
    //   Header: 'Patient Zipcode',
    //   accessor: (obj) => {
    //     let patientData = obj.patientData
    //     return patientData?.zipcode
    //   },
    // },
    // {
    //   Header: 'Patient State',
    //   accessor: (obj) => {
    //     let patientData = obj.patientData
    //     if (patientData?.state_residence) return patientData?.state_residence
    //     else if (patientData?.zipcode_data?.state)
    //       return patientData?.zipcode_data?.state
    //     // administrative_area_level_1
    //     else if (patientData?.address_components) {
    //       let state = patientData?.address_components?.find(
    //         (comp) =>
    //           comp.types[0] === 'administrative_area_level_1' ||
    //           comp.types[0] === 'administrative_area_level_1',
    //       )
    //       return state?.long_name
    //     } else return 'UNKNOWN'
    //   },
    // },
    // {
    //   Header: 'Relationship to Insured',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.insured?.insured_relationship || ''}`
    //   },
    // },
    // {
    //   id: 'Select',
    //   Header: 'Select',
    //   Cell: ({ row }) => {
    //     let reviewed = row?.original?.reviewed || false
    //     return (
    //       <div
    //         style={{
    //           margin: 'auto',
    //           display: 'flex',
    //           justifyContent: 'center',
    //           flex: 'auto',
    //         }}
    //       >
    //         <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
    //           <input
    //             type="checkbox"
    //             checked={reviewed}
    //             disabled={!supervisor}
    //             onClick={() => {
    //               // console.log('reviewed', obj)
    //               if (row?.original?.event_id)
    //                 saveAppointmentData(row?.original, {
    //                   updateReviewed: true,
    //                   reviewed: !reviewed,
    //                 })
    //               // BUG: This data does not exits in the
    //               //new data format, need to get data from the endpoint
    //               else
    //                 saveToCareplanData(row?.original, {
    //                   updateReviewed: true,
    //                   reviewed: !reviewed,
    //                 })
    //             }}
    //           />
    //           <span class={Styles.checkmark}></span>
    //         </label>
    //       </div>
    //     )
    //   },
    // },
    // {
    //   id: 'Claim Number',
    //   Header: 'Claim Number',
    //   accessor: (obj) => {
    //     // BUG: Incorrect, revisit this and access the correct variable
    //     return obj?.claim_number
    //   },
    //   width: 200,
    // },
    // {
    //   id: 'Claim Type',
    //   Header: 'Claim Type',
    //   accessor: (obj) => {
    //     // For claims related to visits/appointments
    //     // console.log('TEST: ', obj)
    //     if (obj.event_id) {
    //       return obj?.timeSpent?.method_of_visit
    //         ? obj?.timeSpent?.method_of_visit
    //         : 'Telehealth - Video Visit'
    //     } else {
    //       // for claims related to careplan notes
    //       return obj?.time_spent?.method_of_visit
    //         ? obj?.time_spent?.method_of_visit
    //         : 'Careplan Note'
    //       // return 'Notes'
    //     }
    //   },
    //   width: 300,
    //   Filter: ({ column }) => {
    //     const { filterValue, setFilter } = column
    //     return (
    //       <span>
    //         <div style={{ width: '100%', fontWeight: 'normal' }}>
    //           <Select
    //             inputId="claimType"
    //             placeholder="Select"
    //             options={[
    //               {
    //                 label: 'Telehealth - Video Visit',
    //                 value: 'Telehealth - Video Visit',
    //               },
    //               {
    //                 label: 'Telehealth - Phone Call',
    //                 value: 'Telehealth - Phone Call',
    //               },
    //               {
    //                 label: 'Telehealth - E-Consult',
    //                 value: 'Telehealth - E-Consult',
    //               },
    //               {
    //                 label: 'Telehealth- Asynchronous visit',
    //                 value: 'Telehealth- Asynchronous visit',
    //               },
    //               { label: 'Careplan Note', value: 'Careplan Note' },
    //               { label: 'Others', value: 'Others' },
    //             ]}
    //             value={
    //               filterValue
    //                 ? { label: filterValue, value: filterValue }
    //                 : null
    //             }
    //             isSearchable
    //             autoBlur
    //             onChange={(val) => {
    //               setFilter(val.label)
    //               // filterByPatientType(val)
    //             }}
    //             theme={(theme) => ({
    //               ...theme,
    //               colors: {
    //                 ...theme.colors,
    //                 primary25: '#20A89233',
    //                 primary: '#20A892',
    //               },
    //             })}
    //           />
    //         </div>
    //       </span>
    //     )
    //   },
    // },
    // {
    //   Header: 'No Show',
    //   accessor: (obj) => {
    //     // For claims related to visits/appointments
    //     // console.log('TEST: ', obj)
    //     // if (obj.event_id) {
    //     return obj?.no_show ? 'No Show' : null
    //     // } else {
    //     //   // for claims related to careplan notes
    //     //   return null
    //     // }
    //   },
    //   Cell: ({ row }) => {
    //     let no_show = row?.original?.no_show
    //     return no_show ? 'No Show' : null
    //     // if (no_show) return 'No Show'
    //     // else return null
    //   },
    //   width: 200,
    // },
    // {
    //   Header: 'ID on file?',
    //   accessor: (obj) => {
    //     let patientData = obj?.patientData
    //     return patientData?.id_front_image_url ? 'Yes' : 'No'
    //   },
    // },
    // {
    //   Header: 'CC on File?',
    //   accessor: (obj) => {
    //     let stripe_data = obj?.patientData?.stripe?.customer_id
    //     if (stripe_data && stripe_data !== '') return 'Yes'
    //     else return 'No'
    //   },
    // },
    // {
    //   Header: 'Insurance Card on File?',
    //   accessor: (obj) => {
    //     let pInsurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return pInsurance?.front_image_url ? 'Yes' : 'No'
    //   },
    // },
    // {
    //   Header: 'HMO',
    //   accessor: (obj) => {
    //     let patientData = obj?.patientData
    //     return patientData?.insurance_type?.hmo ? 'Yes' : 'No'
    //   },
    // },
    // {
    //   Header: 'Medicare Advantage',
    //   accessor: (obj) => {
    //     let patientData = obj?.patientData
    //     return patientData?.insurance_type?.medi_adv ? 'Yes' : 'No'
    //   },
    // },
    // {
    //   Header: 'Medicare',
    //   accessor: (obj) => {
    //     let patientData = obj?.patientData
    //     return patientData?.medicare ? 'Yes' : 'No'
    //   },
    // },
    // {
    //   Header: 'Medicaid',
    //   accessor: (obj) => {
    //     let patientData = obj?.patientData
    //     return patientData?.medicaid ? 'Yes' : 'No'
    //   },
    // },
    // {
    //   Header: "Workers' Comp",
    //   accessor: (obj) => {
    //     // let patientData = obj?.patientData
    //     // return patientData?.workers_comp ? 'Yes' : 'No'
    //     return 'No'
    //   },
    // },
    // {
    //   Header: 'Subscriber ID',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.subscriber_id || ''}`
    //   },
    // },
    // {
    //   Header: 'Group Number',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.group_number || ''}`
    //   },
    // },
    // {
    //   Header: 'Copay - Office Visit',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.benefits?.office_visit_copay || ''}`
    //   },
    // },
    // {
    //   Header: 'Co-pay Specialist Visit',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.benefits?.copay || ''}`
    //   },
    // },
    // {
    //   Header: 'PBM',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.benefits?.pbm || ''}`
    //   },
    // },
    // {
    //   Header: 'Rx BIN',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.benefits?.rxbin || ''}`
    //   },
    // },
    // {
    //   Header: 'Rx PCN',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.benefits?.rxpcn || ''}`
    //   },
    // },
    // {
    //   Header: 'Rx Group',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.benefits?.rxgroup || ''}`
    //   },
    // },
    // {
    //   Header: 'Provider NPI',
    //   accessor: (obj) => {
    //     let providerData = obj?.providerData || null
    //     return `${providerData?.npi?.number || '-'}`
    //   },
    // },
    // {
    //   Header: 'Eligibility',
    //   accessor: 'eligibility',
    // },
    // {
    //   Header: 'Requesting Facility',
    //   accessor: 'requesting_facility',
    // },
    // {
    //   Header: 'Place of Service',
    //   accessor: 'place_of_service',
    // },
    // {
    //   Header: 'Authorization Number',
    //   accessor: 'authorization_number',
    // },
    // BUG: Not a field in the sent data
    // {
    //   Header: 'Submission Date',
    //   accessor: (obj) =>
    //     obj?.claim?.date_of_submission
    //       ? moment(obj?.claim?.date_of_submission).format('ll')
    //       : '',
    //   width: 200,
    //   sort: true,
    // },
    // // BUG: Not a field in the sent data
    // {
    //   Header: 'Days from Submission Date',
    //   accessor: (obj) =>
    //     obj?.claim?.date_of_submission
    //       ? moment(obj?.claim?.date_of_submission).diff(moment(), 'days')
    //       : '-',
    //   width: 200,
    //   sort: true,
    // },
    // // BUG: Wtf is this?
    // {
    //   Header: 'Cheque Number',
    //   accessor: 'chequeNumber',
    //   width: 200,
    //   // sort: true,
    // },
    // BUG: Not working
    // {
    //   Header: 'Cashpay Amount',
    //   accessor: 'cashpay_amount',
    // },
    // {
    //   Header: 'Charge billed',
    //   accessor: 'charge_billed',
    // },
    // {
    //   Header: 'Insurance Paid Amount',
    //   accessor: (obj) => (obj?.insurance_amount ? obj?.insurance_amount : '-'),
    //   Cell: ({ row }) => {
    //     let insurance_amount = row?.original?.insurance_amount
    //       ? // ? '$' + row?.original?.insurance_amount?.replace(/[^0-9|.]/g, '') // Removed this as user i unaware if error persists in the backend
    //         '$' + row?.original?.insurance_amount
    //       : '-'
    //     return (
    //       <ContentEditable
    //         html={insurance_amount}
    //         onChange={debounce(async (event) => {
    //           await handleInputChange(row, 'insurance_amount', event)
    //         }, 2500)}
    //       />
    //     )
    //   },
    //   width: 200,
    //   // sort: true,
    // },
    // {
    //   Header: 'Projected Collections',
    //   accessor: (obj) =>
    //     // If appointment payment is not paid and visit fee is present
    //     !obj?.appointment_payment_paid && obj?.visit_fee
    //       ? '' + obj?.visit_fee // Is a empty string being prepended to render as a string, if so why?
    //       : obj?.free_appointment
    //       ? 'Included'
    //       : '-',
    //   Cell: ({ row }) => {
    //     return (
    //       <ContentEditable
    //         html={
    //           row?.original?.visit_fee
    //             ? '$' + row?.original?.visit_fee
    //             : row?.original?.free_appointment
    //             ? 'Included'
    //             : '-'
    //         }
    //         onChange={debounce(async (event) => {
    //           await handleInputChange(row, 'visit_fee', event)
    //         }, 1500)}
    //       />
    //     )
    //   },
    //   width: 200,
    //   // sort: true,
    // },
    // {
    //   Header: 'Patient Paid Amount(Stripe - fees included)',
    //   accessor: (obj) => {
    //     let res = ''
    //     let invoice = obj?.invoice
    //     if (!invoice) return '-'
    //     // else return 'OK'
    //     else if (invoice.length > 0) {
    //       for (let inv of invoice) {
    //         if (inv?.amount_paid && inv?.refunded !== true) {
    //           // if (inv?.amount_paid) {
    //           // return (
    //           res +=
    //             '$' +
    //             String(parseFloat(inv?.amount_paid / 100).toFixed(2)) +
    //             ','
    //           // )
    //         }
    //       }
    //     } else return 'N/A'
    //     if (res == '') return 'REFUNDED'
    //     return res
    //   },
    //   Cell: ({ row }) => {
    //     let invoice = row?.original?.invoice
    //     // console.log('invoice', invoice)
    //     if (!invoice) return '-'
    //     // else return 'OK'
    //     else if (invoice.length > 0) {
    //       return (
    //         <div>
    //           {invoice.map((inv) => {
    //             if (inv?.amount_paid && inv?.refunded !== true) {
    //               // if (inv?.amount_paid) {
    //               return (
    //                 <p>
    //                   {inv?.amount_paid
    //                     ? '$' + parseFloat(inv?.amount_paid / 100).toFixed(2)
    //                     : '-'}
    //                   ,
    //                 </p>
    //               )
    //             }
    //           })}
    //         </div>
    //       )
    //     } else {
    //       return 'N/A'
    //     }
    //   },
    //   width: 200,
    //   // sort: true,
    // },
    // BUG: There can be more than one invoice in this column
    // {
    //   Header: 'Charged Invoice ID',
    //   accessor: (obj) => {
    //     let res = ''
    //     let invoice = obj?.invoice
    //     // console.log('invoice', invoice)
    //     if (!invoice) return '-'
    //     // else return 'OK'
    //     else if (invoice.length > 0) {
    //       for (let inv of invoice) {
    //         if (inv?.amount_paid && inv?.refunded !== true) {
    //           res += inv?.id + ','
    //         }
    //       }
    //     } else return 'N/A'
    //     // This is the case when the invoice is refunded
    //     if (res == '' && invoice.length == 1 && invoice[0]?.refunded == true)
    //       return invoice[0]?.id
    //     return res
    //   },
    //   Cell: ({ row }) => {
    //     let invoice = row?.original?.invoice
    //     // console.log('invoice', invoice)
    //     if (!invoice) return '-'
    //     // else return 'OK'
    //     else if (invoice.length > 0) {
    //       return (
    //         <div className={Styles.link}>
    //           {invoice.map((inv) => {
    //             if (inv?.amount_paid && inv?.refunded !== true) {
    //               return (
    //                 <p
    //                   onClick={() => {
    //                     inv?.id && getStripeChargeUrl(inv.charge)
    //                   }}
    //                   className={Styles.link}
    //                 >
    //                   {inv?.id},
    //                 </p>
    //               )
    //             }
    //           })}
    //         </div>
    //       )
    //     } else {
    //       return 'N/A'
    //     }
    //   },
    //   width: 150,
    //   // sort: true,
    //   Filter: ({ column }) => {
    //     const { filterValue, setFilter } = column
    //     return (
    //       <span>
    //         <div style={{ width: '100%' }}>
    //           <input
    //             placeholder="Search"
    //             className={Styles.textInput}
    //             style={{ width: '100%' }}
    //             type="text"
    //             value={filterValue}
    //             onChange={(e) => {
    //               setFilter(e.target.value)
    //             }}
    //           />
    //         </div>
    //       </span>
    //     )
    //   },
    //   // obj.first_name && obj.last_name ? obj.first_name + ' ' + obj.last_name : '',
    // },
    // {
    //   Header: 'Percent Collected Overall - (Insurance + Patient Paid/Billed)',
    //   accessor: (obj) => {
    //     let invoice = obj?.invoice?.[0] || 0 // Choosing invoice [0] as its the latest invoice
    //     let invoice_amount = parseFloat(invoice?.amount_paid / 100).toFixed(2)
    //     if (Number.isNaN(invoice_amount) || invoice_amount === 'NaN')
    //       invoice_amount = 0
    //     // console.log((obj?.insurance_amount?.replace(/[^0-9|.]/g, "")), (obj?.billed_amount|| obj?.visit_fee.replace(/[^0-9|.]/g, "")))
    //     return obj?.insurance_amount && obj?.visit_fee && obj?.billed_amount
    //       ? (
    //           (
    //             Number(obj?.insurance_amount?.replace(/[^0-9|.]/g, '') || 0) +
    //             Number(invoice_amount)
    //           ).toFixed(2) /
    //           (obj?.billed_amount?.replace(/[^0-9|.]/g, '') ||
    //             obj?.visit_fee.toString()?.replace(/[^0-9|.]/g, ''))
    //         ).toFixed(2) *
    //           100 +
    //           '%'
    //       : '-'
    //   },
    //   width: 200,
    //   // sort: true,
    // },
    // {
    //   Header: 'Adjustment Amount',
    //   accessor: (obj) =>
    //     obj?.adjustment_amount ? obj?.adjustment_amount : '-',
    //   Cell: ({ row }) => {
    //     let adjustment_amount = row?.original?.adjustment_amount
    //       ? '$' + row?.original?.adjustment_amount?.replace(/[^0-9|.]/g, '')
    //       : '-'
    //     return (
    //       <ContentEditable
    //         html={adjustment_amount}
    //         onChange={(event) =>
    //           handleInputChange(row, 'adjustment_amount', event)
    //         }
    //       />
    //     )
    //   },
    //   width: 200,
    //   sort: true,
    // },
    // {
    //   Header: 'Deductible (Cal yr $)',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.benefits?.deductible_calendar || ''}`
    //   },
    // },
    // {
    //   Header: 'Deductible (Yr to Date $)',
    //   accessor: (obj) => {
    //     let insurance = Array.isArray(obj?.insurances)
    //       ? obj?.insurances?.find((ins) => ins.preference === 1) || null
    //       : obj?.insurances || null
    //     return `${insurance?.benefits?.deductible_date || ''}`
    //   },
    // },
    // BUG: There is an error
    // {
    //   Header: 'Time Spent(Pre Review +  After Review + Provider Collab)',
    //   accessor: (obj) => {
    //     if (obj?.event_id) {
    //       let careplanData = obj?.careplan
    //       let pre_review_time = 0
    //       let after_review_time = 0
    //       let provider_collab_time = 0
    //       for (let i = 0; i < careplanData?.length; i++) {
    //         if (careplanData[i]?.time_spent) {
    //           // if (Object.keys(careplanData[i]?.time_spent).length === 0)
    //           //   return '0'
    //           pre_review_time += careplanData[i]?.time_spent?.pre_review_time
    //             ? parseInt(careplanData[i]?.time_spent?.pre_review_time)
    //             : 0
    //           after_review_time += careplanData[i]?.time_spent?.after_visit
    //             ? parseInt(careplanData[i]?.time_spent?.after_visit)
    //             : 0
    //           provider_collab_time += careplanData[i]?.time_spent
    //             ?.provider_colab
    //             ? parseInt(careplanData[i]?.time_spent?.provider_colab)
    //             : 0
    //         }
    //       }
    //       // Number(
    //       //   moment
    //       //     .unix(obj?.visit_end_time / 1000)
    //       //     .diff(moment.unix(obj?.visit_start_time / 1000), 'minute') || 0,
    //       // ) +
    //       let timeSpent =
    //         Number(obj?.visit_call_time) +
    //         Number(pre_review_time) +
    //         Number(after_review_time) +
    //         Number(provider_collab_time)
    //       // return `${pre_review_time} + ${after_review_time} + ${provider_collab_time}`
    //       return `${timeSpent}`
    //     } else {
    //       let pre_review_time = obj?.time_spent?.pre_review_time || 0
    //       let after_visit = obj?.time_spent?.after_visit || 0
    //       let provider_colab = obj?.time_spent?.provider_colab || 0
    //       return `${pre_review_time + after_visit + provider_colab}`
    //     }
    //   },
    //   Cell: ({ row }) => {
    //     if (row?.original?.event_id) {
    //       // console.log('Has event id')
    //       let careplanData = row?.original?.careplan
    //       let pre_review_time = 0
    //       let after_review_time = 0
    //       let provider_collab_time = 0
    //       for (let i = 0; i < careplanData?.length; i++) {
    //         if (careplanData[i]?.time_spent) {
    //           // if (Object.keys(careplanData[i]?.time_spent).length === 0)
    //           //   return '0'
    //           pre_review_time += careplanData[i]?.time_spent?.pre_review_time
    //             ? parseInt(careplanData[i]?.time_spent?.pre_review_time)
    //             : 0
    //           after_review_time += careplanData[i]?.time_spent?.after_visit
    //             ? parseInt(careplanData[i]?.time_spent?.after_visit)
    //             : 0
    //           provider_collab_time += careplanData[i]?.time_spent
    //             ?.provider_colab
    //             ? parseInt(careplanData[i]?.time_spent?.provider_colab)
    //             : 0
    //         }
    //       }
    //       // let start = moment.unix(row?.original?.visit_start_time / 1000)
    //       // let end = moment.unix(row?.original?.visit_end_time / 1000)
    //       // let duration = moment(end.diff(start), 'minute') || 0
    //       // console.log(
    //       //   start,
    //       //   end,
    //       //   'duration',
    //       //   duration,
    //       //   pre_review_time,
    //       //   after_review_time,
    //       //   provider_collab_time,
    //       // )
    //       let timeSpent =
    //         row?.original?.visit_call_time +
    //         Number(pre_review_time) +
    //         Number(after_review_time) +
    //         Number(provider_collab_time)
    //       // console.log('-', timeSpent)

    //       return (
    //         <p>{timeSpent}</p>
    //         // <p>
    //         //   {Number(duration) +
    //         //     Number(pre_review_time) +
    //         //     Number(after_review_time) +
    //         //     Number(provider_collab_time)}
    //         // </p>
    //       )
    //     } else {
    //       // For notes and async visits
    //       let pre_review_time = row?.original?.time_spent?.pre_review_time || 0
    //       let after_visit = row?.original?.time_spent?.after_visit || 0
    //       let provider_colab = row?.original?.time_spent?.provider_colab || 0
    //       return (
    //         <p>
    //           {Number(pre_review_time) +
    //             Number(after_visit) +
    //             Number(provider_colab)}
    //         </p>
    //       )
    //     }
    //   },
    //   width: 150,
    // },
    // {
    //   Header: 'CPT-Code',
    //   accessor: (obj) => {
    //     let cpt_data = ''
    //     if (Array.isArray(obj?.cpts)) {
    //       cpt_data = obj?.cpts?.map((cpt) => cpt?.cpt_code).join(', ')
    //     }
    //     return cpt_data
    //   },
    //   width: 300,
    //   // sort: true,
    // },
    // {
    //   Header: 'Amended CPT Code',
    //   accessor: (obj) => {
    //     let amended_cpt_data = ''
    //     if (Array.isArray(obj?.amended_cpts)) {
    //       amended_cpt_data = obj?.amended_cpts
    //         ?.map((cpt) => cpt?.cpt_code)
    //         .join(', ')
    //     }
    //     return amended_cpt_data
    //   },
    // },
    // {
    //   Header: 'ICD-10',
    //   accessor: (obj) => {
    //     let icd_10_data = ''
    //     if (Array.isArray(obj?.icd10s)) {
    //       icd_10_data = obj?.icd10s?.map((icd) => icd?.icd10_code).join(', ')
    //     }
    //     return icd_10_data
    //   },
    //   width: 300,
    //   // sort: true,
    // },
    // {
    //   Header: 'Notes',
    //   accessor: (obj) => {
    //     let res = ''
    //     if (obj?.careplan && Array.isArray(obj?.careplan)) {
    //       // obj?.careplan.forEach((careplan, index) => {
    //       for (let index in obj?.careplan) {
    //         // console.log(
    //         //   'obj?.careplan[index].note_status',
    //         //   obj?.careplan[index].note_status,
    //         // )
    //         // res += careplan.note_status.toUpperCase()
    //         res += obj?.careplan[index]?.note_status?.toUpperCase()
    //         if (index < obj?.careplan?.length - 1) res += ', '
    //       }
    //     }
    //     return res
    //   },
    //   Cell: ({ row }) => {
    //     let careplanList = row?.original?.careplan
    //     if (Array.isArray(careplanList)) {
    //       return (
    //         <div>
    //           {careplanList &&
    //             // Array.isArray(row?.original?.careplan) &&
    //             careplanList.map((eachCarePlan, index) => {
    //               // return <p>{careplan.note_status.toUpperCase()},</p>
    //               return (
    //                 <div
    //                   key={index}
    //                   style={{ display: 'flex', flexDirection: 'row' }}
    //                 >
    //                   <p
    //                     onClick={() => {
    //                       // console.log('view note', row.original)
    //                       // let careplanNote = careplan?.careplan
    //                       // careplanNote.doctor = row.original?.providerData
    //                       // row?.original?.careplan &&
    //                       // openNotesEditModal(careplanNote, row?.original)
    //                       openNotesEditModal(
    //                         row?.original?.event_id, // TODO: Edit this to event id
    //                         row.original?.providerData,
    //                       )
    //                     }}
    //                     className={Styles.link}
    //                     style={{
    //                       textDecoration: 'underline',
    //                       color:
    //                         (!eachCarePlan ||
    //                           !(
    //                             eachCarePlan?.note_status === 'completed' &&
    //                             eachCarePlan?.careplan?.some(
    //                               (icd) => icd?.icd_10?.icd10_code,
    //                             )
    //                           )) &&
    //                         'red',
    //                     }}
    //                   >
    //                     {eachCarePlan
    //                       ? eachCarePlan?.note_status === 'completed' &&
    //                         // Why was this logic being checked?
    //                         eachCarePlan?.careplan?.some(
    //                           (icd) => icd?.icd_10?.icd10_code,
    //                         )
    //                         ? 'Notes'
    //                         : 'Incomplete'
    //                       : 'No Notes Found'}
    //                   </p>
    //                   {eachCarePlan && (
    //                     <FontAwesomeIcon
    //                       onClick={() => {
    //                         // console.log('view note', row.original)
    //                         eachCarePlan &&
    //                           generateNotesPdf(
    //                             eachCarePlan.note_id, // TODO: get this from backend, edit to take event id
    //                             row?.original,
    //                           )
    //                       }}
    //                       icon={faFilePdf}
    //                       style={{
    //                         color: Constants.primaryTheme,
    //                         fontSize: 20,
    //                         marginLeft: 10,
    //                         cursor: 'pointer',
    //                       }}
    //                     />
    //                   )}
    //                 </div>
    //               )
    //             })}
    //         </div>
    //       )
    //     } else {
    //       let careplan = careplanList // In this care careplanList is a object
    //       return (
    //         <div style={{ display: 'flex', flexDirection: 'row' }}>
    //           <p
    //             onClick={() => {
    //               console.log('opening notes')
    //               openNotesEditModal(
    //                 careplan.note_id, // TODO: Edit this to event id
    //                 row.original?.providerData,
    //               )
    //             }}
    //             className={Styles.link}
    //             style={{
    //               textDecoration: 'underline',
    //               color:
    //                 (!careplan ||
    //                   !(
    //                     careplan?.note_status === 'completed' &&
    //                     careplan?.careplan?.some(
    //                       (icd) => icd?.icd_10?.icd10_code,
    //                     )
    //                   )) &&
    //                 'red',
    //             }}
    //           >
    //             {careplan
    //               ? careplan?.note_status === 'completed' &&
    //                 // Why was this logic being checked?
    //                 // Also do i need to do this for async notes?
    //                 careplan?.careplan?.some((icd) => icd?.icd_10?.icd10_code)
    //                 ? 'Notes'
    //                 : 'Incomplete'
    //               : 'No Notes Found'}
    //           </p>
    //           {careplan && (
    //             <FontAwesomeIcon
    //               onClick={() => {
    //                 careplan &&
    //                   generateNotesPdf(
    //                     careplan.note_id, // TODO: get this from backend, edit to take event id
    //                     row?.original,
    //                   )
    //               }}
    //               icon={faFilePdf}
    //               style={{
    //                 color: Constants.primaryTheme,
    //                 fontSize: 20,
    //                 marginLeft: 10,
    //                 cursor: 'pointer',
    //               }}
    //             />
    //           )}
    //         </div>
    //       )
    //     }
    //   },
    //   width: 200,
    // },
    // {
    //   Header: 'Medical Director Reviewed',
    //   accessor: (obj) => {
    //     return obj.reviewed || false
    //   },
    //   Cell: ({ row }) => {
    //     let reviewed = row?.original?.reviewed || false
    //     return (
    //       <div
    //         style={{
    //           margin: 'auto',
    //           display: 'flex',
    //           justifyContent: 'center',
    //           flex: 'auto',
    //         }}
    //       >
    //         <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
    //           <input
    //             type="checkbox"
    //             checked={reviewed}
    //             disabled={!supervisor}
    //             onClick={() => {
    //               // console.log('reviewed', obj)
    //               if (row?.original?.event_id)
    //                 saveAppointmentData(row?.original, {
    //                   updateReviewed: true,
    //                   reviewed: !reviewed,
    //                 })
    //               // BUG: This data does not exits in the
    //               //new data format, need to get data from the endpoint
    //               else
    //                 saveToCareplanData(row?.original, {
    //                   updateReviewed: true,
    //                   reviewed: !reviewed,
    //                 })
    //             }}
    //           />
    //           <span class={Styles.checkmark}></span>
    //         </label>
    //       </div>
    //     )
    //   },
    //   width: 200,
    // },
    // {
    //   Header: 'Event id(For engineering team Reference)',
    //   accessor: (obj) => {
    //     // let duration = moment(obj.end).diff(moment(obj.start), 'minutes')
    //     return obj?.event_id
    //   },
    //   width: 400,
    // },
    // {
    //   Header: 'Reason',
    //   accessor: (obj) => {
    //     return obj?.reason
    //   },
    // },
  ]
}

export default claimsColumns
