import React, { useState, Component, useEffect } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import {
  addMongoUser,
  addProviderData,
  stopTimer,
  updateDoctorMongoUser,
  fetchDoctorMongoUser,
  fetchSpecificDoctorMongoUser
} from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'

import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Credentialing.module.scss'
import Config from '../../config'
import Identification from './Identification'
import Licensure from './Licensure'
import EducationCertification from './EducationCertification'
import Malpractice from './Malpractice'
import WorkHistory from './WorkHistory'
import Cme from './Cme'
import Logins from './Logins'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customModalStyles2 = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth - 200,
    height: windowHeight - 20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

class Credentialing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //tab index to render
      renderTabsIndex: 1,

      showConnectCalendar: true,
      speciality: null,
      degree: null,
      gender: null,
      languages: null,
      insurances: null,
      userDetails: null,
      loading: false,

      signLocation: '',
      signImage: null,
      signUploaded: false,
      signChanged: false,

      profileChanged: false,
      imageUploaded: false,
      imageLocation: '',
      image: null,
      error: false,
      errorString: '',
      saving: false,
      locations: [null],
      modalIsOpen: false,
      modalLoading: false,
      modalError: false,
      modalErrorString: '',
      modalNumber: '',
      organization: '',
      showIframe: false,
      region: '',
      states: null,
      licenseNumbers: null,
      statesAbbrs: null,
      verifiedFlags: null,
    }
    this.imageUploadRef = React.createRef()
    this.frontImageRef = React.createRef()
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    const { token, adminId } = this.props

    this.props.stopTimer(adminId, token)
  }

  onSavePressed() {
    const {
      languages,
      speciality,
      degree,
      insurances,
      userDetails,
      gender,
      organization,
      states,
      licenseNumbers,
      statesAbbrs,
      verifiedFlags,
    } = this.state

    if (!speciality) {
      console.log('missing speciality')
      this.setState({
        error: true,
        errorString: 'Please select a speciality',
        loading: false,
      })
      return
    }
    userDetails.speciality = speciality.value

    if (!degree) {
      console.log('missing degree')
      this.setState({
        error: true,
        errorString: 'Please select a Degree',
        loading: false,
      })
      return
    }
    userDetails.degree = degree.value

    if (!languages || languages.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please select at least one language',
        loading: false,
      })
      return
    }
    userDetails.languages = languages.map((x) => x.label)

    // if (!states || states.length === 0) {
    //   this.setState({
    //     error: true,
    //     errorString: 'Please select at least one state!',
    //     loading: false,
    //   })
    //   return
    // }
    // userDetails.states = states.map((x) => x.label)

    console.log('trying to save 2')

    userDetails.insurances = insurances.map((x) => x.label)
    userDetails.gender = gender.value
    userDetails.organization = organization

    if (this.state.locations) {
      const locations = this.state.locations.map((x) => x)
      userDetails.locations = locations
    }

    if (
      licenseNumbers &&
      licenseNumbers.length > 0 &&
      states &&
      states.length > 0 &&
      states[0] !== null
    ) {
      const licensedStates = []
      let flag = false
      licenseNumbers.forEach((x, index) => {
        if (!x) {
          console.log('missing license number', index)
          flag = true
        }

        if (!states[index] || states[index].length === 0) {
          console.log('missing state ', index)
          flag = true
        }

        if (!statesAbbrs[index] || statesAbbrs[index].length === 0) {
          console.log('missing state abbrs ', index)
          flag = true
        }

        if (!flag) {
          licensedStates.push({
            state: states[index],
            abbr: statesAbbrs[index],
            verified: verifiedFlags[index] || false,
            number: x,
          })
        }
      })

      if (flag) {
        toast.error('Missing values')
        this.setState({
          error: true,
          errorString: 'Please fill missing license number information',
          loading: false,
        })
        return
      }
      userDetails.licensed_states = licensedStates
    }

    console.log('trying to save', userDetails)
    this.setState({ error: false, loading: true, saving: true })

    if (this.state.imageUploaded || this.state.signUploaded) {
      this.saveImageToFirebase(userDetails)
    } else {
      this.saveUserToBackend(userDetails)
    }
  }

  saveUserToBackend(user) {
    console.log('user', user)
    const self = this
    const { token, mongoUser } = this.props
    const url = `${Config.BACKEND_URL}providers`
    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: user,
    })
      .then(({ data }) => {
        console.log('successfully added doctor to backend')
        self.props.addProviderData(user)
        toast.success('Successfully updated profile')
        // this.props.fetchSpecificDoctorMongoUser(mongoUser.firebase_id, token)
        this.setState({ renderTabsIndex: this.state.renderTabsIndex + 1 })

        if (self.state.imageUploaded) toast.success('Updated profile picture')
        self.setState({ loading: false, saving: false, imageUploaded: false })
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  handleCallback(data) {
    console.log(data, this.props.token)
    data.firebase_id = this.props.mongoUser.firebase_id
    this.saveUserToBackend(data)
    // this.props.updateDoctorMongoUser(
    //   data,
    //   this.props.mongoUser,
    //   this.props.token,
    // )
  }

  renderTabContent() {
    switch (this.state.renderTabsIndex) {
      case 1:
        return (
          <Identification parentCallback={this.handleCallback.bind(this)} />
        )
      case 2:
        return <Licensure parentCallback={this.handleCallback.bind(this)} />
      case 3:
        return (
          <EducationCertification
            parentCallback={this.handleCallback.bind(this)}
          />
        )
      case 4:
        return <Malpractice parentCallback={this.handleCallback.bind(this)} />
      case 5:
        return <WorkHistory parentCallback={this.handleCallback.bind(this)} />
      case 6:
        return <Cme parentCallback={this.handleCallback.bind(this)} />
      case 7:
        return <Logins parentCallback={this.handleCallback.bind(this)} />
      default:
        this.setState({ renderTabsIndex: 1 })
        return
    }
  }

  render() {
    const { userDetails } = this.state

    return (
      <div className={GlobalStyles.container}>
        <Header header="Credentialing" />

        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minWidth: '100%',
              height: 400,
              alignItems: 'center',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div className={GlobalStyles.contentWrapper}>
            {/* <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              {this.renderAccountDetails()}
              {this.renderInfoCard()}
            </div> */}
            <CredsTabs
              activeTab={this.state.renderTabsIndex}
              onTabSelected={(index) => {
                this.setState({ renderTabsIndex: index })
              }}
            />

            {this.renderTabContent(this)}

            {/* <div
              style={{
                width: '100%',
                marginTop: 60,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={this.onSavePressed.bind(this)}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{ width: 200 }}
                variant="primary"
              >
                {this.state.loading ? 'Loading....' : 'Save'}
              </Button>
              {this.state.error && (
                <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
                  {this.state.errorString}
                </p>
              )}
            </div> */}

            {/* <Modal
              ariaHideApp={false}
              onRequestClose={() => this.onModalClose()}
              isOpen={this.state.modalIsOpen}
              style={customModalStyles}
              contentLabel="Modal"
            >
              {this.renderModal()}
            </Modal> */}
          </div>
        )}
      </div>
    )
  }
}

const CredsTabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.activeTab)
  if (props.activeTab !== activeTab) {
    setActiveTab(props.activeTab)
  }
  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray',
        width: '100%',
        height: 40,
        marginTop: 10,
      }}
    >
      <div
        onClick={() => onTabSelected(1)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 1 ? '#DCDCDC' : '' }}
      >
        <p style={{ fontWeight: 'bold' }}>Identification</p>
      </div>

      <div
        onClick={() => onTabSelected(2)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 2 ? '#DCDCDC' : '' }}
      >
        <p style={{ fontWeight: 'bold' }}>Licensure</p>
      </div>

      <div
        onClick={() => onTabSelected(3)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 3 ? '#DCDCDC' : '' }}
      >
        <p style={{ fontWeight: 'bold' }}>Education & Certifications</p>
      </div>

      <div
        onClick={() => onTabSelected(4)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 4 ? '#DCDCDC' : '' }}
      >
        <p style={{ fontWeight: 'bold' }}>Malpractice Insurance</p>
      </div>

      <div
        onClick={() => onTabSelected(5)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 5 ? '#DCDCDC' : '' }}
      >
        <p style={{ fontWeight: 'bold' }}>Work History</p>
      </div>

      <div
        onClick={() => onTabSelected(6)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 6 ? '#DCDCDC' : '' }}
      >
        <p style={{ fontWeight: 'bold' }}>CME</p>
      </div>

      <div
        onClick={() => onTabSelected(7)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 7 ? '#DCDCDC' : '' }}
      >
        <p style={{ fontWeight: 'bold' }}>Logins</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state /* , ownProps */) => {
  const { userReducer } = state
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    // mongoUser: userReducer.mongoUser,
    mongoUser: userReducer.providerData,
    adminId: userReducer.adminId,
  }
}

const mapDispatchToProps = {
  addMongoUser,
  addProviderData,
  stopTimer,
  updateDoctorMongoUser,
  fetchDoctorMongoUser,
  fetchSpecificDoctorMongoUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Credentialing)
