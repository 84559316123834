/* eslint-disable */
import React, { useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Constants from '../../../../values'
import GlobalStyles from '../../../styles/global.module.scss'
import Styles from '../styles/profile.module.scss'

import {} from '@fortawesome/free-regular-svg-icons'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import Config from '../../../../config'
import * as MetaData from '../../../../metadata'

const axios = require('axios')

import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'

const medicationsConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'medications',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigMedications = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: medicationsConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      aila_id: { raw: {} },
      drug_name: { raw: {} },
      object_id: { raw: {} },
    },
  },
}

// const MedicationForms = MetaData.medicationForms
// const GoalDesc = MetaData.goalDesc

// const eventFire = (el, etype) => {
//   if (el.fireEvent) {
//     el.fireEvent('on' + etype)
//   } else {
//     var evObj = document.createEvent('Events')
//     evObj.initEvent(etype, true, false)
//     el.dispatchEvent(evObj)
//   }
// }

// const ClearQuery = ({ refine }) => {
//   const onClick = (ev) => {
//     refine('')
//     ev.stopPropagation()
//   }
//   return (
//     <div onClick={onClick} style={{ display: 'none' }}>
//       <ConnectedClearAllRefinements />
//     </div>
//   )
// }

// const ClearAllRefinements = ({ refine, items }) => {
//   const onClick = () => refine(items)
//   return (
//     <button id="clearQuery" onClick={onClick}>
//       ClearAll
//     </button>
//   )
// }

// const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
// const ConnectedClearAllRefinements =
//   connectCurrentRefinements(ClearAllRefinements)

export const RenderAddMedicationsManually = ({
  onManualAddModalHide,
  getProviderMedications,
  currentAddModalData,
  patientId,
}) => {
  // console.log('INSIDE::::::::', currentAddModalData)
  const token = useSelector((state) => state.authReducer.token)
  const patient = patientId
    ? { patient_id: patientId }
    : useSelector((state) => state.patientReducer.patient)
  const doctor_id = useSelector((state) => state.userReducer.adminId)
  const doctor_details = useSelector((state) => state.userReducer.mongoUser)
  const appointmentData = useSelector(
    (state) => state.appointmentReducer.appointmentData,
  )
  const showVideoModal = useSelector(
    (state) => state.genericReducer.showVideoModal,
  )

  const [medication, setMedication] = useState(
    currentAddModalData?.code?.coding
      ? {
          id: currentAddModalData?.code?.coding?.[0]?.code,
          name: currentAddModalData?.code?.coding?.[0]?.display,
        }
      : {
          id: currentAddModalData?.ID,
          name: currentAddModalData?.name,
        },
  )
  // const [medForm, setMedForm] = useState()
  const [medicationQuery, setMedicationQuery] = useState('')
  const [clinicalStatus, setClinicalStatus] = useState(
    currentAddModalData?.status
      ? {
          code: currentAddModalData?.status,
          label: currentAddModalData?.status,
        }
      : {},
  )
  const [intent, setIntent] = useState(
    currentAddModalData?.intent
      ? {
          code: currentAddModalData?.intent,
          label: currentAddModalData?.intent,
        }
      : {},
  )
  const [priority, setPriority] = useState(
    currentAddModalData?.priority
      ? {
          code: currentAddModalData?.priority,
          label: currentAddModalData?.priority,
        }
      : {},
  )
  const [doseQuantity, setDoseQuantity] = useState(
    currentAddModalData?.dose ? currentAddModalData.dose.quantity : 1,
  )
  const [unit, setUnit] = useState(
    currentAddModalData?.dose ? { label: currentAddModalData.dose.unit } : '',
  )
  const [frequencyQuantity, setFrequencyQuantity] = useState(
    currentAddModalData?.frequency ? currentAddModalData.frequency.quantity : 1,
  )
  const [frequencyunit, setFrequencyUnit] = useState(
    currentAddModalData?.frequency
      ? { label: currentAddModalData.frequency.recurring }
      : '',
  )
  const [noteValue, setNoteValue] = useState(
    currentAddModalData?.note?.[0] || '',
  )
  const [inactive, setInactive] = useState(
    currentAddModalData?.inactive
      ? {
          label: currentAddModalData.inactive,
          code: currentAddModalData.inactive,
        }
      : {},
  )

  const onClickSave = () => {
    if (!medication?.name) {
      toast.error('Please select Medication')
      return
    }

    let firebase_id = patient?.patient_id

    let medications = {
      patient_id: patient?.patient_id,
      doctor_id: doctor_id,
      resourceType: 'MedicationRequest',
      name: medication?.name ? medication.name : 'NA',
      AILA_ID: medication?.id ? medication.id : 'NA',
      dose: {
        quantity: doseQuantity,
        unit: unit.label,
      },
      frequency: {
        quantity: frequencyQuantity,
        recurring: frequencyunit.label,
      },
      code: {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: medication.id,
            display: medication.name,
          },
        ],
        text: medication.name,
      },
      status: clinicalStatus.code, // R!  active | on-hold | cancelled | completed | entered-in-error | stopped | draft | unknown
      intent: intent.code, // R!  proposal | plan | order | original-order | reflex-order | filler-order | instance-order | option
      priority: priority.code, // routine | urgent | asap | stat
      medicationCodeableConcept: {
        // from Element: extension
        coding: [
          {
            // from Element: extension
            system: 'http://snomed.info/sct', // Identity of the terminology system
            code: medication.id, // Symbol in syntax defined by the system
            display: medication.name, // Representation defined by the system
          },
        ], // Code defined by a terminology system
      },
      subject: {
        reference: `Patient/${patient?.patient_id}`,
        display: `${patient?.first_name} ${patient?.last_name}`,
      },
      Image_url: '',
      recorder: {
        reference: `${doctor_details.user_type}/${doctor_id}`,
        // display: `${doctor_details.first_name} ${doctor_details.last_name}`
      },
      inactive: inactive.code,
      note: [noteValue],
      recordedDate: new Date(),
      ...(appointmentData?.event_id &&
        showVideoModal && { appointment_event_id: appointmentData?.event_id }),
    }

    let data = {
      firebase_id: firebase_id,
      medications: [medications],
    }

    if (medications.inactive) {
      let config = {
        method: 'delete',
        headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
        url:
          Config.BACKEND_URL +
          `fhir/MedicationRequest/${currentAddModalData?._id}`,
        data: data,
      }

      axios(config)
        .then((resp) => {
          onManualAddModalHide()
          getProviderMedications()
          console.log('SAVED DATA: ', data)
        })
        .catch((err) => {
          console.log('error when getting saving provider goals data', err)
        })
    } else {
      let config = {
        method: currentAddModalData ? 'put' : 'post',
        headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
        url:
          Config.BACKEND_URL +
          (currentAddModalData?._id
            ? `fhir/MedicationRequest/${currentAddModalData?._id}`
            : 'fhir/MedicationRequest'),
        data: data,
      }

      axios(config)
        .then((resp) => {
          onManualAddModalHide()
          getProviderMedications()
          console.log('SAVED DATA: ', data)
        })
        .catch((err) => {
          console.log('error when getting saving provider goals data', err)
        })
    }
  }

  // const Hit = (props) => {
  //   let { hit } = props
  //   // console.log('MED PROPS: ', hit)
  //   const handleClick = () => {
  //     eventFire(document.getElementById('clearQuery'), 'click')
  //     setMedication({ id: hit.AILA_ID, name: hit.DrugName })
  //     setMedicationQuery('')
  //   }
  //   return (
  //     <div
  //       style={{ width: '100%', cursor: 'pointer' }}
  //       onClick={(e) => {
  //         handleClick(e)
  //       }}
  //     >
  //       {hit.AILA_ID} : {hit.DrugName}
  //     </div>
  //   )
  // }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div
        style={{
          height: '90%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => onManualAddModalHide()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Medication
        </h4>

        <div className={Styles.addManualRow}>
          <div className={Styles.addManualQuestion}>
            {/* <InstantSearch
                indexName={'MEDICATIONS'}
                searchClient={condnClient}
                onSearchStateChange={({ query }) => setMedicationQuery(query)}
              >
                <SearchBox
                  translations={{
                    placeholder: 'Search medications',
                  }}
                />
                <ClearQueryAndRefinements />
                {medicationQuery.length !== 0 && (
                  <div
                    style={{
                      overflow: 'auto',
                      height: 150,
                      backgroundColor: '#F1F1F1',
                    }}
                  >
                    <RefinementList limit={5} />
                    <Hits hitComponent={(props) => Hit(props)} />
                  </div>
                )}
              </InstantSearch> */}
            <SearchProvider config={esConfigMedications}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'drug_name',
                        }}
                        onSelectAutocomplete={(e) => {
                          console.log('SELECTED: ', e)
                          setMedication({
                            id: e?.aila_id.raw,
                            name: e?.drug_name.raw,
                          })
                          setSearchTerm('')
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search medications',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
          <p className={Styles.selectedPtag}>
            {medication?.id ? `${medication?.id} - ${medication?.name}` : ''}
          </p>
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Status: </p>
          <Select
            options={[
              { code: 'active', label: 'Active' },
              { code: 'on-hold', label: 'On Hold' },
              { code: 'cancelled', label: 'Cancelled' },
              { code: 'completed', label: 'Completed' },
              { code: 'stopped', label: 'Stopped' },
              { code: 'draft', label: 'Draft' },
              { code: 'unknown', label: 'Unknown' },
              { code: 'entered-in-error', label: 'Entered in Error' },
            ]}
            isSearchable={true}
            placeholder="Select status"
            onChange={(val) => setClinicalStatus(val)}
            value={clinicalStatus}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        {/* <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Intent: </p>
            <Select
              options={[
                { code: 'proposal', label: 'Proposal' },
                { code: 'plan', label: 'Plan' },
                { code: 'order', label: 'Order' },
                { code: 'original-order', label: 'Original Order' },
                { code: 'reflex-order', label: '	Reflex Order' },
                { code: 'filler-order', label: 'Filler Order' },
                { code: 'instance-order', label: 'Instance Order' },
                { code: 'option', label: 'Option' },
              ]}
              isSearchable={true}
              placeholder="Select status"
              onChange={(val) => setIntent(val)}
              value={intent}
              getOptionValue={(option) => option.label}
              className={Styles.addSelectInput}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div> */}

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Priority: </p>
          <Select
            options={[
              { code: 'routine', label: 'Routine' },
              { code: 'urgent', label: 'Urgent' },
              { code: 'asap', label: 'ASAP' },
              { code: 'stat', label: 'STAT' },
            ]}
            isSearchable={true}
            placeholder="Select priority"
            onChange={(val) => setPriority(val)}
            value={priority}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Dose Quantity: </p>
          {/* <div className={Styles.addSliderInput}> */}
          <input
            placeholder={'Please enter dose quantity'}
            className={Styles.addManualTextInput}
            // type="text"
            type="number"
            id="dosequantity"
            name="dosequantity"
            min="1"
            value={doseQuantity}
            onChange={(e) => {
              let text = e.target.value
              // if(isNumber(text))
              setDoseQuantity(text)
            }}
          />

          <p className={Styles.addManualQuestion}>Unit: </p>
          <Select
            options={[
              { label: 'ml' },
              { label: 'mg' },
              { label: 'mcg' },
              { label: 'pills' },
            ]}
            isSearchable={true}
            placeholder="Select unit"
            onChange={(val) => setUnit(val)}
            value={unit}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Frequency Quantity: </p>
          {/* <div className={Styles.addSliderInput}> */}
          <input
            placeholder={'Please enter dose quantity'}
            className={Styles.addManualTextInput}
            // type="text"
            type="number"
            id="freqquantity"
            name="freqquantity"
            min="1"
            value={frequencyQuantity}
            onChange={(e) => {
              let text = e.target.value
              // if(isNumber(text))
              setFrequencyQuantity(text)
            }}
          />
          {/* </div> */}

          {/* <div className={Styles.addManualRow}> */}
          <p className={Styles.addManualQuestion}>Recurring: </p>
          <Select
            options={[
              { label: 'day' },
              { label: 'week' },
              { label: 'month' },
              { label: 'year' },
            ]}
            isSearchable={true}
            placeholder="Select unit"
            onChange={(val) => setFrequencyUnit(val)}
            value={frequencyunit}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
          {/* </div> */}
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>
            Want to deactivate the medication ?{' '}
          </p>
          <Select
            options={[
              { code: true, label: 'True' },
              { code: false, label: 'False' },
            ]}
            isSearchable={true}
            placeholder="Select deactivation"
            onChange={(val) => setInactive(val)}
            value={inactive}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Note: </p>
          <textarea
            placeholder={'Please enter Information about the prescription'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '70%',
              // height: '40px',
            }}
            rows="2"
            type="text"
            value={noteValue}
            onChange={(e) => setNoteValue(e.target.value)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => onClickSave()}
          className={GlobalStyles.button}
          // disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}
