import React from 'react'
import Select from 'react-select'
import Styles from './styles/ReferralsTable.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'

export const renderRemoteMonitoringTableColumns = (
  navigateToPatientProfile,
  onPatientNavLinkClicked,
  // onViewOrderPressed,
  // onViewClinicalReportClicked,
) => {
  return [
    {
      Header: 'Patient Name',
      accessor: (obj) => {
        let patientData = obj?.patientData
        let first_name = patientData?.patientData?.first_name || '-'
        let last_name = patientData?.patientData?.last_name || '-'
        return first_name + ' ' + last_name
      },
      Cell: ({ row }) => {
        let patientData = row?.original
        return (
          <Link
            onClick={() => {
              navigateToPatientProfile(row?.original?.patient_id)
              onPatientNavLinkClicked('profile')
            }}
            to={{
              pathname: '/patient/profile',
              state: 'info',
              key: 2,
            }}
            className={Styles.link}
          >
            {patientData.patientData.first_name || '-'}{' '}
            {patientData.patientData.last_name || '-'}
          </Link>
        )
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
    },
    {
      Header: 'Referring Clinician',
      accessor: (obj) => {
        return (
          (obj?.providerData?.first_name || '') +
          ' ' +
          (obj?.providerData?.last_name || '')
        )
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Devices Ordered',
      accessor: (obj) => {
        let res = ''
        // let devices = obj?.device_ids
        let devices = obj?.lines
        if (devices) {
          for (let i = 0; i < devices.length; i++) {
            // let val = devices[i]
            // switch (devices[i]) {
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            //   case '863859049206316': val = 'SM5000-IB';
            // }
            res += devices[i].line_name + ' (' + devices[i].quantity + ')'
            if (i !== devices.length - 1) res += ', '
          }
        }
        return res
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Device ID',
      accessor: (obj) => {
        let res = ''
        let devices = obj?.device_ids
        if (devices) {
          for (let i = 0; i < devices.length; i++) {
            res += devices[i]
            if (i !== devices.length - 1) res += ', '
          }
        }

        // Test
        return res
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Enrolled Date',
      accessor: (obj) => {
        // console.log('obj', obj, obj?.vitals[0]?.created_at)
        return moment(obj?.vitals[0]?.created_at).format('MMM Do YYYY')
      },
      // disableFilters: true,
      sortType: (a, b) => {
        return new Date(b.values.created_at) - new Date(a.values.created_at)
      },
      // Cell: (row) => (
      //   <div style={{ textAlign: 'left' }}>{moment(row.value)}</div>
      // ),
      width: 250,
      sort: true,
    },
    {
      // TODO: make the date in RED if the last reading is more than 7 days
      Header: 'Last Reading',
      accessor: (obj) => {
        // console.log('obj', obj, obj?.vitals[0]?.updated_at)
        return moment(obj?.vitals[0].updated_at).format('MMM Do YYYY')
      },
      // disableFilters: true,
      sortType: (a, b) => {
        return new Date(b.values.created_at) - new Date(a.values.created_at)
      },
      // Cell: (row) => (
      //   <div style={{ textAlign: 'left' }}>
      //     {console.log('row', row)}
      //     {moment(row.value)}
      //   </div>
      // ),
      width: 250,
      sort: true,
    },
    {
      Header: 'Status',
      accessor: (obj) => {
        let TYPES = [
          { label: 'Normal', value: 'normal' },
          { label: 'Watch', value: 'watch' },
          { label: 'Needs Attention', value: 'needs_attention' },
        ]
        let referralType = TYPES.find((type) => type.value === obj?.status)
        if (referralType?.label === 'Needs Attention')
          return <div style={{ color: 'red' }}>{referralType?.label}</div>
        else if (referralType?.label === 'Watch')
          return <div style={{ color: 'orange' }}>{referralType?.label}</div>
        else if (referralType?.label === 'Normal')
          return <div style={{ color: 'green' }}>{referralType?.label}</div>
        else return <div style={{ color: 'blue' }}>UNKNOWN</div> || '-'
      },
      width: 300,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="select-state"
                placeholder="Select"
                options={[
                  { label: 'Normal', value: 'normal' },
                  { label: 'Watch', value: 'watch' },
                  { label: 'Needs Attention', value: 'needs_attention' },
                ]}
                value={
                  filterValue
                    ? {
                        label: filterValue,
                        value: filterValue,
                      }
                    : ''
                }
                // isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.label)
                  // setMemberType(val)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },

    {
      Header: 'Rpm Time',
      accessor: (obj) => {
        return obj?.rpm_time ? obj?.rpm_time : '-'
      },
      width: 250,
    },
    {
      Header: 'CCM Time',
      accessor: (obj) => {
        return obj?.ccm_time ? obj?.ccm_time : '-'
      },
      width: 250,
    },
    {
      Header: 'MTM Time',
      accessor: (obj) => {
        return obj?.mtm_time ? obj?.mtm_time : '-'
      },
      width: 250,
    },
  ]
}
