import React, { Component } from 'react'
import Header from '../../components/Header'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Insights.module.scss'
import * as Metadata from '../../metadata.js'
import Constants from '../../values.js'
import Modal from 'react-modal';
import Select from "react-select";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDna, faCapsules, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import PatientInfoStrip from "../../components/PatientInfoStrip";

const GREEN = '#20A89255'
const YELLOW = '#ffae4255'
const RED = '#ff000055'

class Insights extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
    this.trackScrolling = this.trackScrolling.bind(this)
    this.isBottom = this.isBottom.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if(props.patientCore && props.patient)
      return {loading: false}

    return null
  }

  componentDidMount() {
    if(!this.props.patient) {
      this.props.history.push('/patient/list')
      return
    }

    window.scrollTo(0, 0)
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling() {
    const wrappedElement = document.getElementById('bottom');
    if (this.isBottom(wrappedElement)) {
      this.props.history.push('/patient/careplan')
      document.removeEventListener('scroll', this.trackScrolling);
    }
  };

  renderTable() {
    return (
      <table style={{width: '100%'}}>
        <thead>
        <tr>
          <th>Category</th>
          <th>Drug Class</th>
          <th style={{backgroundColor: GREEN}}>Use as directed</th>
          <th style={{backgroundColor: YELLOW}}>Use with caution</th>
          <th style={{backgroundColor: RED}}>Consider alternatives</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Anticancer</td>
          <td></td>
          <td style={{backgroundColor: GREEN}}></td>
          <td style={{backgroundColor: YELLOW}}></td>
          <td style={{backgroundColor: RED}}></td>
        </tr>
        <tr>
          <td rowSpan="2">Cardiovascular</td>
          <td>Anticoagulants</td>
          <td style={{backgroundColor: GREEN}}>Apixaban(Eliquis), Rivaroxaban(Xarelto)</td>
          <td style={{backgroundColor: YELLOW}}>Warfarin(Coumadin)</td>
          <td style={{backgroundColor: RED}}></td>
        </tr>
        <tr>
          <td>Beta Blockers</td>
          <td style={{backgroundColor: GREEN}}>Carvedilol(Coreg), Timolol(Timoptic)</td>
          <td style={{backgroundColor: YELLOW}}></td>
          <td style={{backgroundColor: RED}}>Metoprolol(Lopressor)</td>
        </tr>
        <tr>
          <td>Mental Health</td>
          <td>Antidepressants</td>
          <td style={{backgroundColor: GREEN}}>Desvenlafaxine(Prestiq), Selegiline(Emsam)</td>
          <td style={{backgroundColor: YELLOW}}>Citalopram(Celexa), Escitalopram(Lexapro)</td>
          <td style={{backgroundColor: RED}}>Buproprion(Wellbutrin), Paroxetine(Paxil)</td>
        </tr>

        </tbody>
      </table>
    )
  }

  renderPharmaRow() {
    return (
      <div className={Styles.sectionDivider}>
        <div className={Styles.rowIcon}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <FontAwesomeIcon icon={faDna} style={{color: 'white', fontSize: 24}}/>
            <FontAwesomeIcon icon={faCapsules} style={{color: 'white', fontSize: 24}}/>
          </div>
          <p className={Styles.rowIconText}>{"Pharmaco \u2010 genomics"}</p>
        </div>

        <div style={{width: '100%', paddingLeft: 20}}>
          {this.renderTable()}
        </div>

      </div>
    )
  }

  renderRiskRow() {
    return (
      <div className={Styles.sectionDivider} style={{borderBottomWidth: 0}}>
        <div className={Styles.rowIcon}>
          <FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'white', fontSize: 30}}/>
          <p className={Styles.rowIconText}>Risks</p>
        </div>

        <div className={Styles.entrySection}>
          <div className={Styles.row}>
            <div className={Styles.entry}>
              <div className={Styles.redDot} style={{visibility: 'hidden'}}/>
              <p><span style={{fontWeight: 'bold'}}>Risk for ED Visit: </span>Low</p>
            </div>
            <div className={Styles.entry}>
              <div className={Styles.redDot} style={{visibility: 'hidden'}}/>
              <p><span style={{fontWeight: 'bold'}}>Kidney Disease Risk: </span>None or Slight[05/05/2020/2020]</p>
            </div>
          </div>

          <div className={Styles.row}>
            <div className={Styles.entry}>
              <div className={Styles.redDot} style={{visibility: 'hidden'}}/>
              <p><span style={{fontWeight: 'bold'}}>Fall Risk: </span>Low</p>
            </div>
            <div className={Styles.entry}>
              <div className={Styles.redDot}/>
              <p><span style={{fontWeight: 'bold'}}>Diabetes Risk: </span>Pre-diabetes[07/01/2020]</p>
            </div>
          </div>

          <div className={Styles.row}>
            <div className={Styles.entry}>
              <div className={Styles.redDot} style={{visibility: 'hidden'}}/>
              <p><span style={{fontWeight: 'bold'}}>10 Year ASCVD Risk: </span>10%</p>
            </div>
            <div className={Styles.entry}>
              <div className={Styles.redDot}/>
              <p><span style={{fontWeight: 'bold'}}>Depression Risk: </span>PHQ-9 Moderate Depression[05/05/2020]</p>
            </div>
          </div>

          <div className={Styles.row}>
            <div className={Styles.entry}>
              <div className={Styles.redDot}/>
              <p><span style={{fontWeight: 'bold'}}>COVID-19 Screen: </span>Referred to doctor visit[05/05/2020]</p>
            </div>
            <div className={Styles.entry}>
              <div className={Styles.redDot} style={{visibility: 'hidden'}}/>
              <p><span style={{fontWeight: 'bold'}}>Breast Cancer Risk Score: </span>N/A</p>
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.entry}>
              <div className={Styles.redDot}/>
              <p><span style={{fontWeight: 'bold'}}>COPD Risk: </span>5/10, requires HCP review</p>
            </div>
            <div className={Styles.entry}>
              <div className={Styles.redDot}/>
              <p><span style={{fontWeight: 'bold'}}>Colorectal Cancer Risk: </span>Higher than average</p>
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.entry} >
              <div className={Styles.redDot} style={{visibility: 'hidden'}}/>
              <p><span style={{fontWeight: 'bold'}}>Morbid Obesity: </span>High[07/01/2020]</p>
            </div>
          </div>

        </div>
      </div>
    )
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        {
          this.state.loading ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
              <div className={GlobalStyles.loader} />
            </div>
            :
            <div>
              <PatientInfoStrip
                patient={this.props.patient}
                patientCore={this.props.patientCore}
                color={this.props.color}
                cohorts={this.props.mongoUser ? this.props.mongoUser.cohorts : []}
              />

              {this.renderPharmaRow()}
              {this.renderRiskRow()}
            </div>
        }

        <div id={'bottom'} style={{visibility: 'hidden', marginTop: 400}}>
          asdasd
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    patient: state.patientReducer.patient,
    color: state.patientReducer.color,
    patientCore: state.patientReducer.patientCore,
    mongoUser: state.userReducer.mongoUser
  }
}

export default connect(
  mapStateToProps,
  null
)(Insights)
