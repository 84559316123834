import React, { Component, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import DatePicker from 'react-date-picker'

import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import { addMongoUser, stopTimer } from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'

import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Credentialing.module.scss'
import Config from '../../config'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles2 = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth - 200,
    height: windowHeight - 20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const STATUS_OPTIONS = [
  { label: 'Active', value: 'Active' },
  { label: 'Expired/Need to renew', value: 'Expired/Need to renew' },
  { label: 'Pending', value: 'Pending' },
]

const Licensure = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)

  const mongoUser = useSelector((state) => state.userReducer?.providerData)
  const userType = useSelector((state) => state.userReducer?.userType)
  const adminId = useSelector((state) => state.userReducer?.adminId)
  const firebaseAuthUser = useSelector(
    (state) => state.userReducer?.firebaseAuthUser,
  )

  const [loading, setLoading] = useState(true)
  const [openDoc, setOpenDoc] = useState(false)
  const [docFile, setDocFile] = useState('')

  //LICENSE DATA
  const [licensedStates, setLicensedStates] = useState([])

  const [fileIndex, setFileIndex] = useState(null)

  const [npi, setNpi] = useState(null)
  const [caqh, setCaqh] = useState('')
  const [accessData, setAccessData] = useState(false)
  const [dea, setDea] = useState('')
  const [cds, setCds] = useState('')
  const [medicareId, setMedicareId] = useState('')
  const [medicaidId, setMedicaidId] = useState('')
  const [attest, setAttest] = useState(false)

  useEffect(() => {
    setLoading(true)
    getProviderData()
    // setLoading(false)
  }, [])

  const getProviderData = () => {
    setLoading(true)
    // console.log('mongo user::', adminId, userType, mongoUser)
    // console.log('firebaseuser::', firebaseAuthUser)

    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: mongoUser.firebase_id, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        console.log('got doctor mongo details', data)

        setLicensedStates(data?.licensed_states || [])
        setLoading(false)
        // dispatch(addMongoUser(data))
      })
      .catch((err) => {
        console.log('error when getting doctor mongo user Licensure', err)
      })
  }

  const saveImageToFirebase = (license, index) => {
    setLoading(true)

    // licensedStates.forEach((license, index) => {
    if (license.image) {
      const file = license.image
      let temp = [...licensedStates]

      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef.child(`license/${file.name}`).put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log(downloadURL)

            let newPair = { license_image: downloadURL }
            temp[index] = { ...temp[index], ...newPair }
            delete temp[index].image
            delete temp[index].location
            // console.log('temp:::', temp)
            setLicensedStates(temp)
            setLoading(false)

            // if (index === licensedStates.length - 1) saveUserToBackend(temp)

            // self.saveUserToBackend(user)
          })
        },
      )
    } else {
      setLoading(false)

      console.log('could not save firebase data')
      // if (index === licensedStates.length - 1) saveUserToBackend(licensedStates)
    }
    // })
  }

  const onSavePressed = () => {
    let returnData = {
      licensed_states: licensedStates,
    }
    // console.log(returnData)
    props.parentCallback(returnData)
  }

  const onImageChange = (e, index) => {
    const file = e.target.files[0] || false
    // const index = fileIndex
    let temp = [...licensedStates]
    console.log(temp, index)
    if (
      file &&
      window.confirm(`Are you sure you want to upload ${file.name}?`)
    ) {
      // this.setState({imageUploaded: true, imageLocation: })

      const reader = new FileReader()

      reader.onload = function (e) {
        const image = e.target.result
        let newPair = { location: image, image: file }
        temp[index] = { ...temp[index], ...newPair }
        setLicensedStates(temp)
        saveImageToFirebase(temp[index], index)

        // temp[index]['location'] = image
        // temp[index]['image'] = file
        // setLicensedStates(temp)
        console.log('confirmed upload - license', temp)
      }

      reader.readAsDataURL(file) // convert to base64 string
    } else {
      console.log('ignored')
    }
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: '50vh', // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const renderLicensedStates = () => {
    return (
      licensedStates &&
      licensedStates?.map((license, index) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              key={index.toString()}
              style={{
                width: '100%',
                marginBottom: '2%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <p className={Styles.rowLabel} style={{ width: '100%' }}>
                    State
                  </p>
                  <Select
                    placeholder="Select State..."
                    inputId="state"
                    options={metadata.stateResidence}
                    value={
                      license.state
                        ? {
                            label: license.state,
                            value: license.state,
                            abbreviation: license.abbr,
                          }
                        : null
                    }
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = [...licensedStates]
                      temp[index].state = val.value
                      temp[index].abbr = val.abbreviation
                      setLicensedStates(temp)
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
                <div
                  style={{
                    width: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <p className={Styles.rowLabel} style={{ width: '100%' }}>
                    License Number
                  </p>
                  <div style={{ width: '100%', marginLeft: 10 }}>
                    <input
                      placeholder="License Number..."
                      className={Styles.textInput}
                      style={{ width: '80%' }}
                      type="text"
                      value={license.number}
                      onChange={(e) => {
                        let temp = [...licensedStates]
                        temp[index].number = e.target.value
                        setLicensedStates(temp)
                        //   const { licenseNumbers } = this.state
                        //   licenseNumbers[index] = e.target.value
                        //   this.setState({ licenseNumbers })
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <p className={Styles.rowLabel} style={{ width: '100%' }}>
                    Status
                  </p>
                  <Select
                    placeholder="Select Status..."
                    inputId="state"
                    options={STATUS_OPTIONS}
                    value={
                      license.status
                        ? {
                            label: license.status,
                            value: license.status,
                          }
                        : null
                    }
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = [...licensedStates]
                      temp[index].status = val.value
                      setLicensedStates(temp)
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>

                {/* {license.verified && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 20,
                    marginLeft: 10,
                  }}
                />
              )} */}
              </div>
              <label
                htmlFor={`proof_files${index}`}
                style={{
                  textDecoration: 'underline',
                  color: Constants.primaryTheme,
                  cursor: 'pointer',
                  width: '40%',
                  margin: '0% 0% 0% 5%',
                }}
              >
                Upload License
              </label>
              <input
                id={`proof_files${index}`}
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setFileIndex(index)
                  console.log('upload licence clicked', index)
                  onImageChange(e, index)
                }}
              />

              <FontAwesomeIcon
                onClick={() => {
                  let temp = [...licensedStates]
                  temp.splice(index, 1)
                  setLicensedStates(temp)
                  //   const { licenseNumbers, states, statesAbbrs, verifiedFlags } =
                  //     this.state
                  //   licenseNumbers.splice(index, 1)
                  //   states.splice(index, 1)
                  //   statesAbbrs.splice(index, 1)
                  //   verifiedFlags.splice(index, 1)
                  //   this.setState({
                  //     licenseNumbers,
                  //     states,
                  //     statesAbbrs,
                  //     verifiedFlags,
                  //   })
                }}
                icon={faTrash}
                style={{
                  color: 'gray',
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
              />
            </div>

            <div
              key={index.toString()}
              style={{
                width: '60%',
                marginBottom: '4%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <p className={Styles.rowLabel} style={{ width: '100%' }}>
                    Issue Date
                  </p>
                  {/* <div style={{ width: '100%', marginLeft: 10 }}> */}
                  <div
                    style={{
                      width: '40%',
                      margin: '0% 0% 0% 5%',
                    }}
                  >
                    <DatePicker
                      // minDate={new Date('09/01/2019')}
                      // format={'MMM-dd-y'}
                      onChange={(date) => {
                        //   let tempQ = { ...education }
                        //   tempQ.expiration_date = date
                        //   setEducation(tempQ)

                        let temp = [...licensedStates]
                        temp[index].issue_date = date
                        setLicensedStates(temp)
                        // console.log(date)
                      }}
                      value={license.issue_date ? new Date(license.issue_date) : new Date()}
                      style={{ width: '40%', margin: '0% 0% 0% 5%' }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <p className={Styles.rowLabel} style={{ width: '100%' }}>
                    Expiration Date
                  </p>
                  {/* <div style={{ width: '100%', marginLeft: 10 }}> */}
                  <div
                    style={{
                      width: '40%',
                      margin: '0% 0% 0% 5%',
                    }}
                  >
                    <DatePicker
                      // minDate={new Date('09/01/2019')}
                      // format={'MMM-dd-y'}
                      onChange={(date) => {
                        //   let tempQ = { ...education }
                        //   tempQ.expiration_date = date
                        //   setEducation(tempQ)

                        let temp = [...licensedStates]
                        temp[index].expiration_date = date
                        setLicensedStates(temp)
                        // console.log(date)
                      }}
                      value={license.expiration_date ? new Date(license.expiration_date) : new Date()}
                      style={{ width: '40%', margin: '0% 0% 0% 5%' }}
                    />
                  </div>
                </div>

                {/* {license.verified && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 20,
                    marginLeft: 10,
                  }}
                />
              )} */}
              </div>
            </div>

            <div
              style={{ width: '30%', display: 'flex', flexDirection: 'row' }}
            >
              {license.location ? (
                <img
                  src={license.location}
                  style={{
                    height: '100%',
                    width: '90%',
                    objectFit: 'cover',
                  }}
                  alt="No Image"
                />
              ) : (
                license.license_image && (
                  <img
                    src={license.license_image}
                    style={{
                      height: '100%',
                      width: '90%',
                      objectFit: 'cover',
                    }}
                    alt="No Image"
                  />
                )
              )}
            </div>
            <div className={Styles.row}>
              <p
                style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}
              ></p>
            </div>
          </div>
        )
      })
    )
  }

  const renderContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        {/*  countries */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel} style={{ alignSelf: 'flex-start' }}>
            Licensed in States
          </p>
          <div
            style={{
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {renderLicensedStates()}
          </div>
          <div
            onClick={() => {
              let temp = [...licensedStates]
              temp.push({
                abbr: '',
                number: '',
                state: '',
                issue_date: new Date(),
                expiration_date: new Date(),
                verified: false,
              })
              setLicensedStates(temp)
            }}
            style={{
              cursor: 'pointer',
              marginLeft: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 5,
              }}
            />
            <p style={{ color: Constants.primaryTheme }}>Add another</p>
          </div>
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            onClick={onSavePressed}
            className={GlobalStyles.button}
            disabled={loading}
            style={{ width: 200 }}
            variant="primary"
          >
            {loading ? 'Loading....' : 'Save'}
          </Button>
          {/* {error && (
            <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
              {errorString}
            </p>
          )} */}
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={openDoc}
          onRequestClose={() => {
            setOpenDoc(false)
            setDocFile('')
          }}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${docFile}`}
          />
        </Modal>
      </div>
    )
  }

  if (loading) return renderLoader()
  return renderContent()
}

export default Licensure
