module.exports = {
  'screenings/mental': {
    no_pleasure: 'Had little interest or pleasure in doing things?',
    depressed: 'Felt down, depressed or hopeless?',
    sleep: 'Had trouble falling asleep, staying asleep, or sleeping too much?',
    energy: 'Felt tired or having little energy?',
    eating: 'Had poor apetite or overeating?',
    despair:
      'Felt bad about yourself - or that you’re a failure or have let yourself or your family down?',
    concentration:
      'Had trouble concentrating on things, such as reading the newspaper or watching television?',
    movement:
      'Been noticeably moving or speaking slowly or being overly fidgety?',
    suicidal:
      'Had thoughts that you would be better off dead or of hurting yourself in some way?',
    phq9_score: 'PHQ9 score',
  },
  'screenings/anxiety': {
    feeling_anxious: 'Feeling nervous, anxious or on edge?',
    cant_stop_worrying: 'Not being able to stop or control worrying?',
    worrying_too_much: 'Worrying too much about different things?',
    trouble_relaxing: 'Trouble relaxing?',
    restless: 'Being so restless that it is hard to sit still?',
    irritable: 'Becoming easily annoyed or irritable?',
    feeling_afraid: 'Feeling afraid as if something awful might happen?',
    anxietyScore: 'Anxiety Score',
  },
  'screenings/gastro': {
    well_being: 'Describe your general well being yesterday',
    abdominal_pain: 'Describe your abdominal pain yesterday',
    abdominal_mass: 'Describe your abdominal mass yesterday',
    liquid_stools: 'Number of liquid/soft stools yesterday',
    symptoms: 'Are you experiencing any of the following? (symptoms)',
    harvey_bradshaw_index: 'Harvey Bradshaw Index',
  },
  'screenings/colitis': {
    bowel_movement_daytime:
      'How many bowel motions have you been passing during the daytime (on average)?',
    bowel_movement_night:
      'How many times did you get out of bed at night (on average) for a bowel movement?',
    urgency: 'When you have a bowel movement, how quickly do you need to go?',
    blood: 'How much blood has been in your stool?',
    well_being: 'What is your well being?',
    // complications: '',
    colitisScore: 'P-SCCAI Score',
  },
  'screenings/fatigue-promis': {
    feel_fatigued: 'I feel fatigued',
    trouble_starting: 'I have trouble starting things because I am tired',
    run_down: 'How run-down did you feel on average?',
    avg_fatigue: 'How fatigued were you on average?',
    bothered_by_fatigue:
      'How much were you bothered by your fatigue on average?',
    interfere_physical:
      'To what degree did your fatigue interfere with your physical functioning?',
    push_yourself:
      'How often did you have to push yourself to get things done because of your fatigue?',
    trouble_finishing:
      'How often did you have trouble finishing things because of your fatigue?',
  },
  'screenings/cfs-me': {
    decreased_ability:
      'Are you experiencing profound fatigue that substantially decreases your ability to function?',
    persisted_6months:
      'Has your profound fatigue persisted for more than six months?',
    pem: '“Post-exertional malaise” (PEM) occurs when even mild exertion or normal activity results in the loss of physical and mental stamina and the worsening of other symptoms after exertion. Do you experience post-exertional malaise after physical or mental exertion or normal daily activities?',
    unrefreshing_sleep:
      'Do you experience unrefreshing sleep? This occurs when you awake from sleep feeling as exhausted as when you went to bed, despite being in bed for an extensive period of time.',
    cognitive_impairment:
      'Are you experiencing cognitive impairment? Examples of this include memory problems, attention deficits, or trouble with skills where thinking and movement are combined (such as balance or coordination).',
    symptoms:
      'Do your aforementioned symptoms get worse when standing or sitting upright and improve when lying down?',
    result: 'Result',
  },
  'screenings/fatigue': {
    tired: 'How tired do you feel on average?',
    fatigued: 'How fatigued were you on average?',
    exhausted: 'How exhausted were you on average?',
    social_activities:
      'To what degree did your fatigue interfere with your social activities?',
    recreational_activities:
      'To what degree did your fatigue interfere with your ability to engage in recreational activities?',
    trouble_starting:
      'To what degree did you have trouble starting things because of your fatigue?',
    trouble_finishing:
      'To what degree did you have trouble finishing things because of your fatigue?',
    decision_making:
      'To what degree did your fatigue make it difficult to make decisions?',
    slow_thinking:
      'To what degree did your fatigue make you feel slowed down in your thinking?',
    less_effective:
      'How often were you less effective at home due to your fatigue?',
    push_self:
      'How often did you have to push yourself to get things done because of your fatigue?',
    limit_social_activities:
      'How often did you have to limit your social activities because of your fatigue?',
    friends: 'How often were you too tired to socialize with friends?',
    think_clearly: 'How often were you too tired to think clearly?',
    forgetful: 'How often did your fatigue make you more forgetful?',
    fatigue_level:
      'In the last week, what was the level of your fatigue on most days?',
  },
  'screenings/physical': {
    toilet: 'Get on and off the toilet?',
    car_doors: 'Open Car Doors?',
    stand_chair: 'Stand up straight from a chair?',
    walk_flat: 'Walk outdoors on flat ground?',
    wait_line: 'Wait in line for 15 minutes?',
    lift_bag:
      'Reach and get down a 5 pound object (such as a bag of sugar) from just above your head?',
    climb_stairs: 'Go up 2 or more flights of stairs?',
    work_outside: 'Do outside work (e.g. Yardwork)?',
    lift_heavy: 'Lift heavy objects?',
    move_heavy: 'Move Heavy objects?',
    pain_from_illness:
      'How much pain have you had because of your illness in the past week? (0 - not a problem, 10 - severe problem)',
    disease_impact:
      'How much is your disease impacting you overall in the past week? (0 - not a problem, 10 - severe problem)',
    haqScore: 'HAQ Score',
  },
  'screenings/fibromyalgia': {
    fatigue: 'Fatigue',
    walking_unrefreshed: 'Waking unrefreshed',
    cognitive_symptoms: 'Cognitive symptoms',
    symptoms: 'Check if you have experienced any of these in the past week',
  },
  'screenings/fertility': {
    trying: 'Are you actively trying to get pregnant?',
    no_of_months:
      'If you have already started trying please specify number of months',
    avg_cycle: 'Average Cycle Length',
    regular_cycles: 'Are your cycles typically regular',
    pregnant_count: 'How many times have you been pregnant?',
    intercourse_per_week: 'How many times per week do you have intercourse?',
    timing_intercourse_fertility:
      'Are you timing intercourse with your fertile window?',
    partner_age: 'How old is your partner?',
  },
  'screenings/menstrual': {
    current_period: 'Do you have a period?',
    last_period: 'Date of Last Period',
    days_between_cycles: 'Number of days between cycles (e.g. 28-31)',
    avg_cycle_duration: 'Average Cycle Duration (e.g. 3-7 days)',
    regular_cycles: 'Are your cycles typically regular?',
    missed_periods:
      'Have you missed any periods in the past 3 months? (If you are not pregrant)',
    period_visual: 'How would you describe your period (visually)?',
    heavy_period:
      'Are your periods heavy? “Heavy" means changing your tampon or pad every hour or so or during the night, having periods that last for more than a week, or passing blood clots bigger than a quarter.',
    symptoms: 'Do you experience any of the following while on your period?',
    bleeding_inbetween_periods:
      'Do you experience bleeding in between periods?',
    contraception: 'Are you using any of the following types of contraception?',
  },
  'screenings/burnout': {
    drained_energy:
      'I feel run down and drained of physical or emotional energy.',
    negative_thoughts_job: 'I have negative thoughts about my job.',
    less_sympathetic:
      'I am harder and less sympathetic with people than perhaps they deserve.',
    easily_irritated:
      'I am easily irritated by small problems, or by my co-workers.',
    feel_misunderstood:
      'I feel misunderstood or unappreciated by my co-workers.',
    no_one_to_talk: 'I feel that I have no one to talk to.',
    achieving_less: 'I feel that I am achieving less than I should.',
    unpleasant_pressure:
      'I feel under an unpleasant level of pressure to succeed.',
    not_getting_enough_from_job:
      'I feel that I am not getting what I want out of my job.',
    wrong_organization:
      'I feel that I am in the wrong organization or profession.',
    frustrated_with_job: 'I am frustrated with parts of my job.',
    organizational_politics:
      'I feel that organizational politics or bureaucracy frustrate my ability to do a good job.',
    more_than_I_can_do:
      'I feel that there is more work to do than I practically have the ability to do.',
    not_enough_time_for_job:
      'I feel that I do not have time to do many of the things that are important to doing a good quality job.',
    not_enough_time_to_plan:
      'I find that I do not have time to plan as much as I want to.',
    score: "Burnout Score"
  },
  'screenings/basdai': {
    fatigue:
      'How would you describe the overall level of fatigue/tiredness you have experienced?',
    nbh_pain:
      'How would you describe the overall level of Ankylosing Spondylitis (AS) neck, back, or hip pain you have had?',
    other_joints_pain:
      'How would you describe the overall level of pain/swelling in joints other than neck, back, hips you have had?',
    discomfort_area:
      'How would you describe the level of discomfort you have had from an area tender to touch or pressure?',
    morning_stiffness:
      'How would you describe the level of morning stiffness you have had from the time you wake up?',
    stiffness_time:
      'How long does your morning stiffness last from the time you wake up?',
  },
  'screenings/cognitive-promis': {
    slow_thinking: 'My thinking has been slow...',
    brain_function:
      'It has seemed like my brain was not working as well as usual...',
    work_harder:
      'I have had to work harder than usual to keep track of what I was doing...',
    context_switching:
      'I have had trouble shifting back and forth between different activities that require thinking...',
  },
  'screenings/dyspnea-promis': {
    dressing: 'Over the past 7 days, how short of breath did you get with Dressing yourself without help...',
    walking_flat:
      'Over the past 7 days, how short of breath did you get with Walking 50 steps/paces on flat ground at a normal speed without stopping...',
    walking_stairs: 'Over the past 7 days, how short of breath did you get with Walking up 20 stairs (2 flights) without stopping...',
    preparing_meals: 'Over the past 7 days, how short of breath did you get with Preparing meals...',
    washing_dishes: 'Over the past 7 days, how short of breath did you get with Washing dishes...',
    sweeping: 'Over the past 7 days, how short of breath did you get with Sweeping or mopping...',
    making_bed: 'Over the past 7 days, how short of breath did you get with Making a bed...',
    lifting_weight:
      'Over the past 7 days, how short of breath did you get with Lifting something weighing 10-20 lbs (about 4.5-9kg, like a bag of groceries)...',
    carrying_weight:
      'Over the past 7 days, how short of breath did you get with Carrying something weighing 10-20 lbs (about 4.5-9kg, like a bag of groceries) from one room to another...',
    walking_fast:
      'Over the past 7 days, how short of breath did you get with Walking (faster than your usual speed) for 1/2 mile (almost 1 km) without stopping...',
  },
  'screenings/pain-promis': {
    avg_pain: 'How would you rate your pain on average? (0 - No Pain, 10 - Worst Pain Imaginable)',
    interfere_daily_activities:
      'How much did pain interfere with your daily activities?',
    interfere_work_home:
      'How much did pain interfere with work around the home?',
    interfere_social_activities:
      'How much did pain interfere with your ability to participate in social activities?',
    interfere_household_chores:
      'How much did pain interfere with your household chores?',
  },
  'screenings/wellness': {
    interests: 'Interests',
    'relationships.marital_status': 'Marital Status',
    'relationships.quality.spouse': 'Quality of your relationship with spouse (0 - worst, 10 - best)',
    'relationships.quality.family': 'Quality of your relationship with spouse family (0 - worst, 10 - best)',
    'relationships.quality.coworkers': 'Quality of your relationship with spouse coworkers (0 - worst, 10 - best)',
    'relationships.quality.boss': 'Quality of your relationship with spouse boss (0 - worst, 10 - best)',
    'relationships.quality.friends': 'Quality of your relationship with spouse friends (0 - worst, 10 - best)',
    'stress.management': 'How do you manage your stress?',
    'stress.events': 'Have you experienced any of these stressful events?',
    'stress.about': 'What do you stress about?',
    'energy.timings': 'When do you feel most energized throughout the day?',
    'energy.description':
      'What best describes your energy level throughout the day?',
    'sleep.habits': 'What are your sleeping habits like',
    'sleep.statement': 'I typically...',
    allergies: 'allergies',
    food_preferences:
      'Are you allergic to any of the following(food preferences)',
    'food_sensitivities.stomach_discomfort':
      'How often do you experience stomach discomfort?',
    'food_sensitivities.foods': 'Do you have any known food sensitivities',
    'nutrition.diet': 'What is your typical diet?',
    'nutrition.cooking_choice': 'What  best describes you? (cooking choice)',
    'lifestyle_habits.alcohol':
      'How much alcohol do you typically drink per week?',
    'lifestyle_habits.coffee':
      'How many cups of coffee do you typically drink per day?',
    'lifestyle_habits.cigarette':
      'How many cigarettes do you typically smoke per day?',
    'lifestyle_habits.drugs':
      'How often do you use recreational drugs per week ?',
    exercises: 'What type of exercise do you like to do?',
    'exercise_style.ability': 'Are you physically able to exercise?',
    'exercise_style.frequency':
      'How many days per week would you like to exercise?',
    'exercise_style.style': 'What describes your typical exercise style?',
  },
  'screenings/activities': {
    'energy.1': 'Leave bed, Get dressed, Phone call, Take Pills, Use Toilet',
    'energy.2': 'Read, Bathe, Internet, Watch TV, Pet Care',
    'energy.3': 'Cook, Clean, Drive, Public Transit, Visit Doctor',
    'energy.4': 'Shop, Work, Childcare, Exercise, Socialize ',
  },
  'screenings/hra': {
    'copd.short_of_breath':
      'During the past 4 weeks, how much of the time did you feel short of breath?',
    'copd.mucus': 'Do you ever cough up any “stuff”, such as mucus or phlegm?',
    'copd.breathing_problems':
      'Select an answer that best describes you:\nIn the past 12 months, I do less than I used to because of my breathing problems.',
    'copd.cigarettes_100':
      'Have you smoked more than 100 cigarettes in your entire life?',
    'copd.smoker': 'Are you a cigarette smoker?',
    'copd.quit_smoking': 'When did you quit smoking?',
    'diabetes.diabetes': 'Have you ever been diagnosed with diabetes?',
    'diabetes.gestational_diabetes':
      'Have you ever been diagnosed with gestational diabetes?',
    'diabetes.high_bp':
      'Have you ever been diagnosed with high blood pressure?',
    'diabetes.medications_bp':
      'Are you currently taking medication to manage your blood pressure / hypertension?',
    'diabetes.statin':
      'Are you currently taking a statin (a drug to lower your cholesterol)?',
    'diabetes.aspirin': 'Are you currently taking aspirin?',
    'diabetes.history':
      'Do you have a history of Myocardial Infraction, Angina Pectoris, Coronary Insufficency, Intermittent Claudicaton, or Congestive Heart Failure?',
    'diabetes.physically_active': 'Are you physically active?',
    'diabetes.hearing_problems': 'Do you have any hearing problems?',
    'diabetes.opioids': 'Have you used opioids in the last 6 months?',
    ascvd:
      'Does anyone in your immediate family (father, mother, brother, sister, daughter, son) have any of the following conditions?',
  },
  'screenings/joints': {
    right_temporomandibular: 'Jaw Lock (Right)',
    left_temporomandibular: 'Jaw Lock (Left)',
    cervical_spine: 'Cervical Spine',
    right_sternoclavicular: 'Sternoclavicular (Right)',
    left_sternoclavicular: 'Sternoclavicular (Left)',
    left_shoulder: 'Shoulder (Left)',
    right_shoulder: 'Shoulder (Right)',
    left_elbow: 'Elbow (Left)',
    right_elbow: 'Elbow (Right)',
    left_wrist: 'Wrist (Left)',
    right_wrist: 'Wrist (Right)',
    left_sacroiliac: 'Sacroiliac (Left)',
    right_sacroiliac: 'Sacroiliac (Right)',
    left_hip: 'Hip (Left)',
    right_hip: 'Hip (Right)',
    right_knee: 'Knee (Right)',
    left_knee: 'Knee (Left)',
    left_ankle: 'Ankle (Left)',
    right_ankle: 'Ankle (Right)',
    rh_1_pip: 'Right Thumb (PIP)',
    rh_2_pip: 'Right Index Finger (PIP)',
    rh_3_pip: 'Right Middle Finger (PIP)',
    rh_4_pip: 'Right Ring Finger (PIP)',
    rh_5_pip: 'Right Little Finger (PIP)',
    rh_1_mcp: 'Right Thumb (MCP)',
    rh_2_mcp: 'Right Index Finger (MCP)',
    rh_3_mcp: 'Right Middle Finger (MCP)',
    rh_4_mcp: 'Right Ring Finger (MCP)',
    rh_5_mcp: 'Right Little Finger (MCP)',
    rh_2_dip: 'Right Index Finger (DIP)',
    rh_3_dip: 'Right Middle Finger (DIP)',
    rh_4_dip: 'Right Ring Finger (DIP)',
    rh_5_dip: 'Right Little Finger (DIP)',
    lh_1_pip: 'Left Thumb (PIP)',
    lh_2_pip: 'Left Index Finger (PIP)',
    lh_3_pip: 'Left Middle Finger (PIP)',
    lh_4_pip: 'Left Ring Finger (PIP)',
    lh_5_pip: 'Left Little Finger (PIP)',
    lh_1_mcp: 'Left Thumb (MCP)',
    lh_2_mcp: 'Left Index Finger (MCP)',
    lh_3_mcp: 'Left Middle Finger (MCP)',
    lh_4_mcp: 'Left Ring Finger (MCP)',
    lh_5_mcp: 'Left Little Finger (MCP)',
    lh_2_dip: 'Left Index Finger (DIP)',
    lh_3_dip: 'Left Middle Finger (DIP)',
    lh_4_dip: 'Left Ring Finger (DIP)',
    lh_5_dip: 'Left Little Finger (DIP)',
    rf_1_tpip: 'Right Big Toe (PIP)',
    rf_2_tpip: 'Right Long Toe (PIP)',
    rf_3_tpip: 'Right Middle Toe (PIP)',
    rf_4_tpip: 'Right Ring Toe (PIP)',
    rf_5_tpip: 'Right Little Toe (PIP)',
    rf_1_mtp: 'Right Big Toe (MTP)',
    rf_2_mtp: 'Right Long Toe (MTP)',
    rf_3_mtp: 'Right Middle Toe (MTP)',
    rf_4_mtp: 'Right Ring Toe (MTP)',
    rf_5_mtp: 'Right Little Toe (MTP)',
    rf_midfoot: 'Right Midfoot',
    rf_subtalar: 'Right Subtalar Joint',
    lf_1_tpip: 'Left Big Toe (PIP)',
    lf_2_tpip: 'Left Long Toe (PIP)',
    lf_3_tpip: 'Left Middle Toe (PIP)',
    lf_4_tpip: 'Left Ring Toe (PIP)',
    lf_5_tpip: 'Left Little Toe (PIP)',
    lf_1_mtp: 'Left Big Toe (MTP)',
    lf_2_mtp: 'Left Long Toe (MTP)',
    lf_3_mtp: 'Left Middle Toe (MTP)',
    lf_4_mtp: 'Left Ring Toe (MTP)',
    lf_5_mtp: 'Left Little Toe (MTP)',
    lf_midfoot: 'Left Midfoot',
    lf_subtalar: 'Left Subtalar Joint',
  },
  'screenings/pain': {
    jaw: 'Jaw',
    neck: 'Neck',
    chest: 'Chest',
    abdomen: 'Abdomen',
    shoulder_gridle_right: 'Shoulder Gridle (Right)',
    shoulder_gridle_left: 'Shoulder Gridle (Left)',
    upper_arm_right: 'Upper Arm (Right)',
    upper_arm_left: 'Upper Arm (Left)',
    lower_arm_right: 'Lower Arm (Right)',
    lower_arm_left: 'Lower Arm (Left)',
    upper_leg_right: 'Upper Leg (Right)',
    upper_leg_left: 'Upper Leg (Left)',
    lower_leg_right: 'Lower Leg (Right)',
    lower_leg_left: 'Lower Leg (Left)',
    upper_arm_right_back: 'Upper Arm Back (Right)',
    upper_arm_left_back: 'Upper Arm Back (Left)',
    lower_arm_right_back: 'Lower Arm Back (Right)',
    lower_arm_left_back: 'Lower Arm Back (Left)',
    upper_leg_right_back: 'Upper Leg Back (Right)',
    upper_leg_left_back: 'Upper Leg Back (Left)',
    lower_leg_right_back: 'Lower Leg Back (Right)',
    lower_leg_left_back: 'Lower Leg Back (Left)',
    upper_back: 'Upper Back',
    lower_back: 'Lower Back',
    hip_buttock_right: 'Hip/ Buttock (Right)',
    hip_buttock_left: 'Hip/ Buttock (Left)',
    painIndex: 'Pain Index'
  },
  'screenings/ccfm': {
    'answers.1': 'Intensity of occupational demand',
    'answers.2': 'Intensity of physical training',
    'answers.3': 'Intensity of your recreational activity',
    'answers.4': 'Stress',
    'answers.5': 'Mood',
    'answers.7': 'Quality of sleep last night',
    'answers.8': 'Mental alertness',
    'answers.9': 'Level of focus',
    'answers.10': 'Level of energy',
    'answers.11': 'Level of productivity',
    'answers.12': 'Level of soreness',
    'answers.15': 'Nutrition compliance',
    'answers.16': 'Hydration compliance',
    'answers.17': 'Fitness program compliance',
    'answers.18': 'Stress mastery and breathing program compliance',
    'answers.19': 'Sleep compliance',
    'answers.20': 'Metabolic Challenge, Fasting',
  },
  'screenings/ace': {
    swear: 'Did a parent or other adult in the household often or very often.. Swear at you, insult you, put you down, or humiliate you? Or act in a way that made you afraid that you might be physically hurt?',
    injured: 'Did a parent or other adult in the household often or very often.. Push, grab, slap, or throw something at you? or Ever hit you so hard that you had marks or were injured?',
    fondle: 'Did an adult or person at least five years older than you ever.. Touch or fondle you or have you touch their body in a sexual way? or Attempt or  actually have oral, anal, or vaginal intercourse with you?',
    no_love: "Did you often or very often feel that.. No one in your family loved you or thought you were important or special? or Your family didn't look out for each other, feel close to each other, or support each other?",
    protect: "Did you often or very often feel that.. You didn't have enough to eat, had to wear dirty clothes, and had no one to protect you? or Your parents were too drunk or high to take care of you or take you to the doctor if you needed it?",
    divorced: 'Were your patents everseperated or divorced',
    threatened: 'Was your mother or stepmother: Often or very often pushed, grabbed, slapped or had something thrown at her? or Sometimes, often or very often kicked, bitten, hit with a fist, or hit at lease a few minutes or threatened with a gun or knife?',
    live_with_alcoholic: 'Did you live with anyone who was a problem drinker or alcoholic or who used to street drugs?',
    depressed: 'Was a household member depressed or mentally ill, or did a household member attempt suicide?',
    prison: 'Did a household member go to prison?',
    score: 'ACE Score'
  },
  'screenings/sleepapnea': {
    snore: "Do you snore loudly (louder than talking or loud enough to be heard through closed doors)?",
    tired: "Do you often feel tired or sleepy?",
    stop_breathing: "Has anyone observed you stop breathing?",
    high_bp: "Do you have or are you being treated for high blood pressure?",
    bmi: "Is your Body Mass Index (BMI) over 35?",
    age: "Are you over the age of 50?",
    male: "Are you a male or were you a male at birth?",
    neck_circumference: "Is your neck circumference greater than 16 inches (40 cm)?",
    score: 'Sleep Apnea Score'
  },
  'screenings/globalhealth': {
    health:"In general, would you say your health is:",
    quality_of_life:"In general, would you say your quality of life is:",
    physical_health:"In general, how would you rate your physical health?",
    mental_health:"In general, how would you rate your mental health, including your mood and ability to think?",
    satisfaction:"In general, how would you rate your satisfaction with social activities and relationships?",
    social_activities:"In general, please rate how well you carry out your usual social activities and roles. (this includes activities at hoe, at work and in your comunity and responsibilities as a arent, child, spouse, employee, friend, etc.)",
    physical_activities:"To what extent are you able to carry out your everyday physical activities such as walking, climbing stairs, carrying groceries, or moving a chair?",
    emotional_problems:"In the past 7 days, how often have you been bothered by emotional problems such as feeling anxious, depressed or irritable?",
    fatigue_rate:"How would you rate your fatigue on average?",
    pain_rate:"How would you rate your pain on average? (0 - No Pain, 10 - Worst imaginable pain)",
    physical_score:"Physical Score",
    mental_score:"Mental Score",
  },
  'screenings/hrqol': {
    general_health: "In general, would you say your health is:", 
    compare_health: "Compared to one year ago, how would you rate your health in general now?",
    vigorus_activities: "Vigorous activities, such as running, lifting heavy objects, participating in strenuous sports",
    moderate_activities: "Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf",
    lifting: "Lifting or carrying groceries",
    several_flights: "Climbing several flights of stairs",
    one_flights: "Climbing one flight of stairs",
    bending: "Bending, kneeling, or stooping",
    walking_mile: "Walking more than a mile",
    walking_several: "Walking several blocks",
    walking_one: "Walking one block",
    dressing: "Bathing or dressing yourself",
    physical_cut_work: "During the past 4 weeks, have you cut down on the amount of time you spent on work or other activities as a result of your physical health",
    physical_accomplish_less: "During the past 4 weeks, have you accomplished less than you would like as a result of your physical health",
    physical_limit_work: "During the past 4 weeks, were limited in the kind of work or other activities as a result of your physical health",
    physical_difficult_perform: "During the past 4 weeks, have you had difficulty performing the work or other activities (for example, it took extra effort) as a result of your physical health",
    emotional_cut_work: "During the past 4 weeks, have you cut down on the amount of time you spent on work or other activities as a result of any emotional problems",
    emotional_accomplish_less: "During the past 4 weeks, have you accomplished less than you would like as a result of any emotional problems",
    emotional_difficult_perform: "During the past 4 weeks, did you work or perform other activities less carefully than usual as a result of any emotional problems",
    interfere_social: "During the past 4 weeks, to what extent has your physical health or emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?",
    bodily_pain: "How much bodily pain have you had during the past 4 weeks?",
    interfere_work: "During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?",
    full_pep: "During the past 4 weeks, did you feel full of pep?",
    nervous_person: "During the past 4 weeks, have you been a very nervous person?",
    no_cheer: "During the past 4 weeks, have you felt so down in the dumps that nothing could cheer you up?",
    felt_calm: "During the past 4 weeks, have you felt calm and peaceful?",
    have_energy: "During the past 4 weeks, did you have a lot of energy?",
    down_hearted: "During the past 4 weeks, have you felt downhearted and blue?",
    worn_out: "During the past 4 weeks, did you feel worn out?",
    happy_person: "During the past 4 weeks, have you been a happy person?",
    feel_tired: "During the past 4 weeks, did you feel tired?",
    time_interfere_social: "During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with your social activities (like visiting friends, relatives, etc.)?",
    easy_sick: "I seem to get sick a little easier than other people",
    health_anybody: "I am as healthy as anybody I know",
    health_worse: "I expect my health to get worse",
    health_excellent: "My health is excellent",
    physical_functioning_score: "Physical functioning score",
    role_limitations_physical_score: "Role limitations due to physical health score",
    role_limitations_emotional_score: "Role limitations due to emotional health score",
    energy_fatigue_score: "Energy/Fatigue score",
    emotional_wellbeing_score: "Emotional well-being score",
    social_functioning_score: "Social functioning score",
    pain_sf36_score: "Pain score",
    general_health_sf36_score: "General health score",
  },
  'longcovid/questionnaire': {
    //COVID HISTORY
    'covidHistory.covid': 'Did you have Covid-19?',
    'covidHistory.covid_test':  'Did you receive a Covid test?',
    'covidHistory.covid_test_type': 'What type of test did you receive?',
    'covidHistory.symptoms_start':  'When did your initial Covid symptoms start?',
    'covidHistory.duration_months' : 'How long were you sick with the initial Covid infection?',
    // 'covidHistory.duration_weeks': '',
    // 'covidHistory.duration_days': '',
    'covidHistory.hospitalized': 'Were you hospitalized as a result of a Covid-19 infection?',
    'covidHistory.initial_severity': 'How would you rate the severity of your initial Covid infection?',
    'covidHistory.recover': 'Did you recover from your initial Covid infection?',
    'covidHistory.persistent_symptoms': 'Did symptoms return or did you develop new persistent symptoms after your initial Covid infection?',
    'covidHistory.severity': 'Please rate the overall severity of your Long Covid symptoms on a scale of 1-10?',
    'covidHistory.hospitalized_symptoms': 'Were you hospitalized as a result of persistent Long Covid Symptoms?',
    'covidHistory.recover_long': 'If you recovered from your initial infection, how long after did you start experiencing long COVID symptoms?',
    'covidHistory.care_providers': 'Which of the following care providers have you seen to manage your Long Covid symptoms?',
    'covidHistory.pasc_diagnosis': 'Have you been diagnosed with long COVID or PASC (Post-Acute Sequelae of COVID-19) by a healthcare provider?',
    'covidHistory.covid_vaccine':  'Have you received the Covid-19 vaccine?',
    'covidHistory.covid_family': 'Are any of your family members also experiencing long Covid symptoms?',
    //HEAD NEURO
    'headNeuro.symptoms':  'Which of the following head and neurological symptoms are you experiencing?',
    'headNeuro.added_symptoms' : 'Please add any head and neurological symptoms you are experiencing here:',
    'headNeuro.severity':  'Please rate the overall severity of your head and neurological symptoms on a scale of 1-10?',
    'headNeuro.notes':    'Please add any additional notes about your head and neurological symptoms here.',
    //EAR THROAT
    'earThroat.symptoms':  'Which of the following ear, nose and throat symptoms are you experiencing?',
    'earThroat.added_symptoms' :     'Please add any ear, nose and throat symptoms you are experiencing here:',
    'earThroat.severity': 'Please rate the overall severity of your ear, nose and throat symptoms on a scale of 1-10?',
    'earThroat.notes':   'Please add any additional notes about your ear, nose and throat symptoms here.',
    //CARDIO
    'cardioVascular.symptoms':  'Which of the following cardiovascular symptoms are you experiencing?',
    'cardioVascular.added_symptoms' : 'Please add any other cardiovascular symptoms you are experiencing here:',
    'cardioVascular.severity':  'Please rate the overall severity of your cardiovascular symptoms on a scale of 1-10? ',
    'cardioVascular.notes':  'Please add any additional notes about your cardiovascular symptoms here.',
    //RESPIRATORY
    'respiRatory.symptoms':                     'Which of the following respiratory symptoms are you experiencing?',
    'respiRatory.added_symptoms' :           'Please add any other respiratory symptoms you are experiencing here:',
    'respiRatory.severity':           'Please rate the overall severity of your respiratory symptoms on a scale of 1-10?',
    'respiRatory.notes':           'Please add any additional notes about your respiratory symptoms here.',
    //STOMACH
    'stomachDigestive.symptoms':          'Which of the following stomach and digestive symptoms are you experiencing?',
    'stomachDigestive.added_symptoms' :            'Please add any stomach and digestive symptoms you are experiencing here:',
    'stomachDigestive.severity':                   'Please rate the overall severity of your stomach and digestive symptoms on a scale of 1-10?',
    'stomachDigestive.notes':                      'Please add any additional notes about your stomach and digestive symptoms here.',
    //ENDOCRINE
    'endoCrine.symptoms':        'Which of the following endocrine symptoms are you experiencing?', 
    'endoCrine.added_symptoms' :            'Please add any other endocrine symptoms you are experiencing here:',
    'endoCrine.severity':                   'Please rate the overall severity of your endocrine symptoms on a scale of 1-10? ',
    'endoCrine.notes':                  'Please add any additional notes about your endocrine symptoms here.',
    //JOINT
    'jointPain.symptoms':           'Which of the following joint and muscular pain symptoms are you experiencing?',
    'jointPain.added_symptoms' :            'Please add any joint and muscular pain symptoms you are experiencing here:',
    'jointPain.severity':                   'Please rate the overall severity of your joint and muscular pain symptoms on a scale of 1-10?',
    'jointPain.notes':                      'Please add any additional notes about your joint and muscular pain symptoms here.',
    //MENTAL HEALTH    
    'mentalHealth.symptoms':             'Which of the following mental health symptoms are you experiencing?',
    'mentalHealth.added_symptoms' :            'Please add any other mental health symptoms you are experiencing here:',
    'mentalHealth.severity':                   'Please rate the overall severity of your mental health symptoms on a scale of 1-10?',
    'mentalHealth.notes':                      'Please add any additional notes about your mental health symptoms here.',
    //OTHERS
    'generalHealth.symptoms':           'Which of the following others symptoms are you experiencing?',
    'generalHealth.added_symptoms' :            'Please add any other symptoms you are experiencing here:',
    'generalHealth.severity':                   'Please rate the overall severity of your other symptoms on a scale of 1-10?',
    'generalHealth.notes':                      'Please add any additional notes about your other symptoms here.',
    //GENERAL ABILITY 
    'generalAbility.same_job':                     'Have you been able to keep the same job you had before you got sick?',
    'generalAbility.work_hours':           'Have you had to reduce your hours at work since you got sick?',
    'generalAbility.household' :          'Are you physically able to manage your household chores and responsibilities independently?',
    'generalAbility.disability':           'Have you applied for disability?',
    'generalAbility.financial_difficulty' :          'Have you experienced financial difficulties as a result of your illness',
    'generalAbility.before_covid' :          'Overall, what was your quality of life before Long Covid?',
    'generalAbility.after_covid':           'Overall, what was your quality of life after having Long Covid?',
  },

}
