import React, { Component, useState } from 'react'
import GlobalStyles from './styles/global.module.scss'
import { connect } from 'react-redux'
import Header from '../components/Header'
import Select from 'react-select'
import Constants from '../values'
import Styles from './Account/styles/Account.module.scss'
import Button from 'react-bootstrap/Button'
import { ToastContainer, toast } from 'react-toastify'
import Config from '../config'
const axios = require('axios')

class Support extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      method: null,
      level: null,
      subject: '',
      message: '',
      error: false,
      errorString: null,
      fromPatient: false,
    }
    this.postTicketToBackend = this.postTicketToBackend.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn || !this.props.docFirebaseAuthUser) {
      this.props.history.push('/login')
      return
    }

    if (this?.props?.location?.patient === 'Patient') {
      this.setState({ fromPatient: true })
    }
  }

  onSubmitPressed() {
    let { method, level, subject, message, fromPatient } = this.state
    // if(!method) {
    //   this.setState({error: true, errorString: 'Please select a preferred contact method'})
    //   return
    // }
    level = fromPatient ? { label: 'High', value: 'HIGH' } : level

    if (!level) {
      this.setState({
        error: true,
        errorString: 'Please select a priority level',
      })
      return
    }

    if (!subject) {
      this.setState({ error: true, errorString: 'Please enter a subject' })
      return
    }

    if (!message) {
      this.setState({ error: true, errorString: 'Please enter a message' })
      return
    }

    this.setState({ error: false, loading: true })
    let { mongoUser, patient } = this.props
    let data = fromPatient
      ? {
          priority: level.value,
          subject: subject,
          description: message,
          firebase_id: patient.uid,
          name: patient.firstName,
          email: patient.email,
        }
      : {
          priority: level.value,
          subject: subject,
          description: message,
          firebase_id: mongoUser.firebase_id,
          name: mongoUser.first_name,
          email: mongoUser.email,
        }
    this.postTicketToBackend(data)
  }

  postTicketToBackend(data) {
    let self = this
    let { token } = this.props
    let url = self.state.fromPatient
      ? Config.BACKEND_URL + 'patient/support'
      : Config.BACKEND_URL + 'provider/support'

    axios({
      method: 'post',
      url: url,
      headers: { Authorization: 'JWT ' + token },
      data: data,
    })
      .then(({ data }) => {
        console.log('posted ticket')
        toast.success('Support request submitted')
        self.setState({
          loading: false,
          level: null,
          method: null,
          message: '',
          subject: '',
        })
      })
      .catch((err) => {
        console.log('error when adding ticket', err)
        toast.error('Something went wrong')
        self.setState({ loading: false, error: false })
      })
  }

  renderForm() {
    let { fromPatient } = this.state
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 40,
          width: '100%',
          alignItems: 'center',
        }}
      >
        {/*<div className={GlobalStyles.supportEntryRow}>*/}
        {/*  <p style={{color: Constants.primaryTheme, width: 160, textAlign: 'right', marginRight: 20}}>Preferred Contact</p>*/}
        {/*  <div style={{width: 300}}>*/}
        {/*    <Select*/}
        {/*      isSearchable={false}*/}
        {/*      autoBlur={true}*/}
        {/*      placeholder={'Select preferred method..'}*/}
        {/*      options={[{label: 'E-mail', value: 'email'}, {label: 'Phone', value: 'phone'}]}*/}
        {/*      value={this.state.method}*/}
        {/*      onChange={(val) => this.setState({method: val})}*/}
        {/*      theme={(theme) => ({*/}
        {/*        ...theme,*/}
        {/*        colors: {*/}
        {/*          ...theme.colors,*/}
        {/*          primary25: '#20A89233',*/}
        {/*          primary: '#20A892',*/}
        {/*        },*/}
        {/*      })}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        {!fromPatient && (
          <div className={GlobalStyles.supportEntryRow}>
            <p
              style={{
                color: Constants.primaryTheme,
                width: 160,
                textAlign: 'right',
                marginRight: 20,
              }}
            >
              Prority
            </p>
            <div style={{ width: 300 }}>
              <Select
                isSearchable={false}
                autoBlur={true}
                placeholder={'Select priority level..'}
                options={[
                  { label: 'Critical', value: 'CRITICAL' },
                  { label: 'High', value: 'HIGH' },
                  { label: 'Medium', value: 'MEDIUM' },
                  { label: 'Low', value: 'LOW' },
                ]}
                value={this.state.level}
                onChange={(val) => this.setState({ level: val })}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
        )}

        <div className={GlobalStyles.supportEntryRow}>
          <p
            style={{
              color: Constants.primaryTheme,
              width: 160,
              textAlign: 'right',
              marginRight: 20,
            }}
          >
            Subject
          </p>
          <input
            placeholder={'Enter subject...'}
            className={GlobalStyles.supportTextInput}
            type="text"
            value={this.state.subject}
            onChange={(e) => this.setState({ subject: e.target.value })}
          />
        </div>

        <div
          className={GlobalStyles.supportEntryRow}
          style={{ marginBottom: 10 }}
        >
          <p
            style={{
              color: Constants.primaryTheme,
              width: 160,
              textAlign: 'right',
              marginRight: 20,
            }}
          >
            Message
          </p>
          <textarea
            placeholder={'Enter your message here.....'}
            style={{
              width: '80%',
              height: 300,
              padding: '5px 20px',
              resize: 'none',
              borderColor: '#aeaeae',
              borderRadius: 4,
            }}
            value={this.state.message}
            onChange={(e) => this.setState({ message: e.target.value })}
          />
        </div>

        {this.state.error && (
          <p style={{ color: 'red', fontSize: 12 }}>{this.state.errorString}</p>
        )}

        <Button
          onClick={this.onSubmitPressed.bind(this)}
          className={GlobalStyles.button}
          style={{
            width: 200,
            position: 'absolute',
            bottom: 20,
            margin: '0 auto',
          }}
          variant="primary"
        >
          Submit
        </Button>
      </div>
    )
  }

  render() {
    let { fromPatient } = this.state
    return (
      <div className={GlobalStyles.container}>
        <Header
          header={!fromPatient ? 'Contact Support' : 'Contact Techinal Support'}
        />

        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 200,
              minWidth: '100%',
              height: '100vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          this.renderForm()
        )}
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    patient: state.patientReducer.patient,
  }
}

// const mapDispatchToProps = {addMongoUser,fetchDoctorInviteList,addDoctorInvites, fetchPatientsList, stopTimer}

export default connect(mapStateToProps, null)(Support)
