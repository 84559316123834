import React from 'react'
import Styles from './styles/styles.module.scss'
import AilaLogo from '../assets/images/aila_logo.png'

const Footer = (props) => {
  return (
    <div
      style={{
        // height: 100,
        // paddingLeft: 40,
        backgroundColor: 'white',
        marginTop: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <div style={{ flexGrow: 1, paddingTop: '10vh', color: '#808080' }}>
        <img
          src={AilaLogo}
          style={{ height: 80, width: 80, float: 'center' }}
        />
        Powered by Aila Health
      </div>
    </div>
  )
}

export default Footer
