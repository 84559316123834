import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import { toast, ToastContainer } from 'react-toastify'
import {
  faPhone,
  faBolt,
  faAppleAlt,
  faBrain,
  faMoon,
  faFireAlt,
  faCheckCircle,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import Config from '../../config'
import Styles from './styles/MobileSubscription.module.scss'
import Constants from '../../values'
import AilaLogo from '../../assets/images/aila_logo.png'
import CommImage from '../../assets/images/comm_1.png'
import { subscriptionPlans } from '../../metadata'
const queryString = require('query-string')
const moment = require('moment')
const axios = require('axios')


export default class ResourcePayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      token: null,
      patientId: null,
      resource_id: null,
      resource_type: null,
    }
    this.createCheckoutSession = this.createCheckoutSession.bind(this)
    this.getAuthToken = this.getAuthToken.bind(this)
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search)
    console.log('parsed', parsed)
    const patientId = parsed.patient_id
    const resource_id = parsed.resource_id
    const resource_type = parsed.resource_type
    const customer = parsed?.customer
    const email = parsed?.email
    // const { plan } = parsed
    if (!patientId || !resource_id) {
      console.log('missing info', patientId)
      toast.error('Missing information')
    } else {
      this.getAuthToken(patientId, resource_id, email, customer, resource_type)
      this.setState({ resource_id, resource_type })
    }
  }

  async getAuthToken(patientId, resource_id, email, customer, resource_type) {
    const url = `${Config.BACKEND_URL}authorization`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_firebase_id: patientId },
        url,
      })
      const token = response.data && response.data.token
      this.setState({ token, patientId })
      this.createCheckoutSession(token, patientId, resource_id, email, customer, resource_type)
    } catch (err) {
      console.log('error when auth token', err)
      toast.error('Error when getting authorization from backend')
    }
  }

  async createCheckoutSession(token, patientId, resource_id, email, customer, resource_type) {
    const url = `${Config.BACKEND_URL}stripe/resource/sessions`
    const stripePublishableKey = Config.STRIPE_PUBLISHABLE_API_KEY
    this.setState({ loading: true })
    try {
      const stripe = await loadStripe(stripePublishableKey)
      let config = {
        method: 'get',
        headers: {
          Authorization: `JWT ${token}`,
          x_patient_id: patientId,
          resource_id: resource_id,
          email: email,
          resource_type: resource_type
        },
        url,
      }
      if(customer)
        config.headers.customer = customer
      const response = await axios(config)

      const { data } = response
      this.setState({ loading: false })
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId,
        })
        .then(() => {
          console.log('stripe success')
        })
        .catch((err) => {
          console.log('error when creating stripe session', err)
        })
    } catch (err) {
      console.log('error when creating session', err)
      this.setState({ loading: false })
    }
  }


  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          display: 'flex',
          //   flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 10,
          backgroundColor: 'white',
          //   overflow: 'scroll',
          justifyContent: 'center',
          //   width: window.innerWidth,
          width: '100%',
        }}
      >
        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              marginTop: 40,
            }}
          >
            <Loader
              type="Oval"
              color={Constants.primaryTheme}
              height={40}
              width={40}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    )
  }
}
