import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
} from '../../../redux/actions'
import firebase from '../../../services/firebase.js'

import GlobalStyles from '../../styles/global.module.scss'
import Header from '../../../components/Header'
import Constants from '../../../values.js'
import Styles from './styles/PatientAccount.module.scss'
import Config from '../../../config'

const moment = require('moment')
const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

class PatientAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: '',
      last_name: '',
      address: '',
      phone_number: '',
      cell: '',
      fax: '',
      email: '',
      userDetails: '',

      loading: false,
      error: false,
      errorString: '',
      saving: false,
    }
    this.saveUserToBackend = this.saveUserToBackend.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.token && props.patient && props.patientCore) {
      const user = { ...props.patient, ...props.patientCore }

      const firstName = user.pcp?.first_name || ''
      const lastName = user.pcp?.last_name || ''
      const address = user?.pcp?.address || ''
      const phone_number = user?.pcp?.phone_number || ''
      const cell = user?.pcp?.cell || ''
      const fax = user?.pcp?.fax || ''
      const email = user?.pcp?.email || ''

      return {
        loading: state.saving,
        first_name: state.first_name || firstName,
        last_name: state.last_name || lastName,
        address: state.address || address,
        phone_number: state.phone_number || phone_number,
        cell: state.cell || cell,
        fax: state.fax || fax,
        email: state.email || email,
        userDetails: state.userDetails || user,
      }
    }

    return null
  }

  componentDidMount() {
    console.log('PROPS:', this.props.fromProvider)
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    console.log(this.props.patient, this.props.patientCore)
  }

  saveUserToBackend() {
    const self = this
    const { token, patient } = this.props
    const {
      first_name,
      last_name,
      email,
      phone_number,
      fax,
      cell,
      address,
      userDetails,
    } = this.state

    const mongoData = {
      pcp: {
        first_name,
        last_name,
        email,
        phone_number,
        fax,
        cell,
        address,
      },
    }
    const url = `${Config.BACKEND_URL}users`
    console.log('userDetails.uid: ', userDetails.uid)
    axios({
      method: 'put',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: userDetails.uid,
      },
      data: mongoData,
    })
      .then(({ data }) => {
        console.log('successfully added user details to mongo backend')
        // self.props.addMongoUser(user)
        toast.success('Successfully updated profile')

        this.props.fetchPatientCoreDate(patient.uid, token)

        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        self.setState({ loading: false, saving: false })

        // , imageUploaded: false })
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  renderAccountDetails() {
    const {
      first_name,
      last_name,
      email,
      phone_number,
      fax,
      cell,
      address,
      userDetails,
    } = this.state

    const { token, patient, patientCore } = this.props
    return (
      <div
        style={{
          width: !this.props?.fromProvider ? '65%' : '100%',
          padding: '20px 40px',
        }}
      >
        {/* license no */}

        {/* PATIENT NAME */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>First Name</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter First Name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={first_name}
              onChange={(e) => {
                this.setState({ first_name: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Last Name</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter First Name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={last_name}
              onChange={(e) => {
                this.setState({ last_name: e.target.value })
              }}
            />
          </div>
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Email</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Email"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={email}
              onChange={(e) => {
                this.setState({ email: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Phone Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder={'Phone Number'}
              type="number"
              id="phone"
              name="phone"
              //   min="0"
              value={phone_number}
              onChange={(e) => {
                this.setState({ phone_number: e.target.value })
              }}
              className={Styles.textInput}
              style={{ width: '50%' }}
            />
          </div>
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Cell</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder={'Cell Number'}
              type="number"
              id="cell"
              name="cell"
              //   min="0"
              value={cell}
              onChange={(e) => {
                this.setState({ cell: e.target.value })
              }}
              className={Styles.textInput}
              style={{ width: '50%' }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Fax Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Mobile number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={fax}
              onChange={(e) => {
                this.setState({ fax: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Address</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Address"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={address}
              onChange={(e) => {
                // const { userDetails } = this.state
                // userDetails.streetAddress2 = e.target.value
                this.setState({ address: e.target.value })
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { userDetails } = this.state
    const { patientCore } = this.props
    return (
      <div className={GlobalStyles.contentWrapper} style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          {this.renderAccountDetails()}
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {this.state.error && (
            <p
              style={{
                marginTop: 4,
                marginBottom: 4,
                fontSize: 12,
                color: 'red',
              }}
            >
              {this.state.errorString}
            </p>
          )}
          <Button
            onClick={this.saveUserToBackend.bind(this)}
            className={GlobalStyles.button}
            disabled={this.state.loading}
            style={{ width: 200 }}
            variant="primary"
          >
            {this.state.loading ? 'Loading....' : 'Save'}
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  const { userReducer } = state
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser:
      userReducer.userType && userReducer.userType.includes('team')
        ? userReducer.teamMongoUser
        : userReducer.mongoUser,
    adminMongoUser: userReducer.mongoUser,
    patient: state.patientReducer.patient,
    userType: userReducer.userType,
    adminId: userReducer.adminId,
    patientCore: state.patientReducer.patientCore,
  }
}

const mapDispatchToProps = {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAccount)
