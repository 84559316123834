import React, { Component, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import DatePicker from 'react-date-picker'

import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import { addMongoUser, stopTimer } from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'

import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Credentialing.module.scss'
import Config from '../../config'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles2 = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth - 200,
    height: windowHeight - 20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const Logins = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)

  const mongoUser = useSelector((state) => state.userReducer?.providerData)
  const userType = useSelector((state) => state.userReducer?.userType)
  const adminId = useSelector((state) => state.userReducer?.adminId)
  const firebaseAuthUser = useSelector(
    (state) => state.userReducer?.firebaseAuthUser,
  )

  const [loading, setLoading] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [docFile, setDocFile] = useState('')

  //WORK HISTORY DATA
  const [reference1, setReference1] = useState(null)
  const [reference2, setReference2] = useState(null)
  const [reference3, setReference3] = useState(null)
  const [workHistory, setWorkHistory] = useState([])
  const [currentCv, setCurrentCv] = useState('')

  const [logins, setLogins] = useState([])
  const [cme, setCme] = useState([])
  const [uploadingIndex, setUploadingIndex] = useState(1)

  //IDENTIFICATION DATA
  const [npi, setNpi] = useState(null)
  const [caqh, setCaqh] = useState('')
  const [accessData, setAccessData] = useState(false)
  const [dea, setDea] = useState('')
  const [cds, setCds] = useState('')
  const [medicareId, setMedicareId] = useState('')
  const [medicaidId, setMedicaidId] = useState('')
  const [attest, setAttest] = useState(false)

  useEffect(() => {
    setLoading(true)
    getProviderData()
    setLoading(false)
  }, [])

  const getProviderData = () => {
    setLoading(true)
    // console.log('mongo user::', adminId, userType, mongoUser)
    // console.log('firebaseuser::', firebaseAuthUser)

    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: mongoUser.firebase_id, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        console.log('got doctor mongo details', data)
        setLogins(
          data?.logins || [
            {
              name: 'CAQH',
              url: 'https://proview.caqh.org/',
              login: '',
              password: '',
            },
          ],
        )

        setLoading(false)
        // dispatch(addMongoUser(data))
      })
      .catch((err) => {
        console.log('error when getting doctor mongo user Login', err)
      })
  }

  const onSavePressed = () => {
    let returnData = {
      logins: logins,
    }
    // console.log(returnData)
    props.parentCallback(returnData)
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: 100, // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const verifyNpiPressed = () => {
    if (!npi || npi.length !== 10) {
      // npi number must be exactly 10 digits
      toast.warning(
        'NPI number must be unique 10-digit National Provider Identifier assigned to the provider',
      )
      //   this.setState({
      //     error: true,
      //     modalErrorString:
      //       'NPI number must be unique 10-digit National Provider Identifier assigned to the provider',
      //   })
      return
    }

    // use the NPI registry API to verify the provided NPI
    // const { mongoUser, token } = this.props
    const url = `${Config.BACKEND_URL}provider/npi-verification`
    axios({
      method: 'post',
      headers: { Authorization: `JWT ${token}` },
      url,
      data: { number: npi, firebase_id: mongoUser.firebase_id },
    })
      .then(({ data }) => {
        console.log('got the response', data)
        if (data.npiVerification) {
          toast.success('Successfully verified NPI. You are all set.')
          //   this.props.addMongoUser(data.user)
          dispatch(addMongoUser(data.user))
          //   this.setState({
          //     modalLoading: false,
          //     modalError: false,
          //     modalIsOpen: false,
          //   })
        } else {
          toast.error('Invalid NPI number')
          //   this.setState({
          //     modalLoading: false,
          //     modalError: true,
          //     modalErrorString: 'Invalid NPI number. Could not verify.',
          //   })
        }
      })
      .catch((err) => {
        console.log('error when verifying NPI', err)
        toast.warning(
          'The verification system is down. Please try again later. If the problem persists, contact support.',
        )
        // this.setState({
        //   modalLoading: false,
        //   modalError: true,
        //   modalErrorString:
        //     'The verification system is down. Please try again later. If the problem persists, contact support. ',
        // })
      })
  }

  const renderLogins = () => {
    return (
      logins &&
      logins?.map((item, index) => {
        return (
          <div
            key={index.toString()}
            style={{
              width: '100%',
              marginBottom: '4%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <p className={Styles.rowLabel} style={{ width: '100%' }}>
                Name
              </p>
              <div style={{ width: '100%', marginLeft: 10 }}>
                <input
                  placeholder="Website URL"
                  className={Styles.textInput}
                  style={{ width: '80%' }}
                  type="text"
                  value={item.name}
                  onChange={(e) => {
                    let temp = [...logins]
                    temp[index].name = e.target.value
                    setLogins(temp)
                    //   const { licenseNumbers } = this.state
                    //   licenseNumbers[index] = e.target.value
                    //   this.setState({ licenseNumbers })
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <p className={Styles.rowLabel} style={{ width: '100%' }}>
                Website URL
              </p>
              <div style={{ width: '100%', marginLeft: 10 }}>
                <input
                  placeholder="Website URL"
                  className={Styles.textInput}
                  style={{ width: '80%' }}
                  type="text"
                  value={item.url}
                  onChange={(e) => {
                    let temp = [...logins]
                    temp[index].url = e.target.value
                    setLogins(temp)
                    //   const { licenseNumbers } = this.state
                    //   licenseNumbers[index] = e.target.value
                    //   this.setState({ licenseNumbers })
                  }}
                />
              </div>
            </div>

            <div
              style={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <p className={Styles.rowLabel} style={{ width: '100%' }}>
                Login
              </p>
              <div style={{ width: '100%', marginLeft: 10 }}>
                <input
                  placeholder="Login"
                  className={Styles.textInput}
                  style={{ width: '80%' }}
                  type="text"
                  value={item.login}
                  onChange={(e) => {
                    let temp = [...logins]
                    temp[index].login = e.target.value
                    setLogins(temp)
                    //   const { licenseNumbers } = this.state
                    //   licenseNumbers[index] = e.target.value
                    //   this.setState({ licenseNumbers })
                  }}
                />
              </div>
            </div>

            <div
              style={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <p className={Styles.rowLabel} style={{ width: '100%' }}>
                Password
              </p>
              <div style={{ width: '100%', marginLeft: 10 }}>
                <input
                  placeholder="Password "
                  className={Styles.textInput}
                  style={{ width: '80%' }}
                  type="password"
                  value={item.password}
                  autoComplete="new-password"
                  onChange={(e) => {
                    let temp = [...logins]
                    temp[index].password = e.target.value
                    setLogins(temp)
                    //   const { licenseNumbers } = this.state
                    //   licenseNumbers[index] = e.target.value
                    //   this.setState({ licenseNumbers })
                  }}
                />
              </div>
            </div>
          </div>
        )
      })
    )
  }

  const renderContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        {/*  LOGINS */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            marginTop: '20px',
          }}
        >
          {/* <p className={Styles.rowLabel} style={{ alignSelf: 'flex-start' }}>
            
          </p> */}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {renderLogins()}
          </div>
          <div
            onClick={() => {
              let temp = [...logins]
              temp.push({
                name: '',
                url: '',
                login: '',
                password: '',
              })
              setLogins(temp)
            }}
            style={{
              cursor: 'pointer',
              marginLeft: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 5,
              }}
            />
            <p style={{ color: Constants.primaryTheme }}>Add another</p>
          </div>
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            onClick={onSavePressed}
            className={GlobalStyles.button}
            disabled={loading}
            style={{ width: 200 }}
            variant="primary"
          >
            {loading ? 'Loading....' : 'Save'}
          </Button>
          {/* {error && (
            <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
              {errorString}
            </p>
          )} */}
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={openDoc}
          onRequestClose={() => {
            setOpenDoc(false)
            setDocFile('')
          }}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${docFile}`}
          />
        </Modal>
      </div>
    )
  }

  if (loading) return renderLoader()
  return renderContent()
}

export default Logins
