import React, { Component } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
} from '../../../redux/actions'
import firebase from '../../../services/firebase.js'
import PlaceHolderProfileImage from '../../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../../metadata.js'

import GlobalStyles from '../../styles/global.module.scss'
import Header from '../../../components/Header'
import Constants from '../../../values.js'
import Styles from './styles/PatientAccount.module.scss'
import Config from '../../../config'
import PatientInsurance from './PatientInsurance'
import PatientIdentity from './PatientIdentity'
import DatePicker from '../../../components/DatePicker'

const moment = require('moment')
const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}
const TEXT_COLOR = '#7E7E7E'

class PatientAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dob: null,
      gender: null,
      ethnicity: null,
      heightFeet: null,
      heightInches: null,
      weightLbs: null,
      language: null,
      address: '',
      address2: null,
      addressComponents: null,
      postalCode: '',
      emergencyContactName: '',
      emergencyContactPhone: '',
      phoneNumber: '',
      email: '',
      profileChanged: false,
      profileLocation: '',
      profileImage: null,
      profileUploaded: false,
      insuranceBack: false,
      backLocation: '',
      backImage: null,
      backUploaded: false,
      frontLocation: '',
      frontImage: null,
      frontUploaded: false,
      insuranceFront: false,

      showPaymentModal: false,
      userInsurance: null,

      pharmacyList: [],
      zip: '',
      pharma: null,

      userDetails: null,

      //////////////////////////

      loading: false,
      imageUploaded: false,
      imageLocation: '',
      image: null,
      error: false,
      errorString: '',
      saving: false,
      modalIsOpen: false,
      modalLoading: false,
      modalError: false,
      modalErrorString: '',
      modalNumber: '',
      showIframe: false,
      states: null,
      statesAbbrs: null,
      verifiedFlags: null,

      //INSURANCE NEW FLOW TAKE IN SECONDARY AND PRIMARY INSURANCE
      showInsuranceModal: false,
      showIdentityModal: false,
    }
    this.imageUploadRef = React.createRef()
    this.frontImageRef = React.createRef()
    this.backImageRef = React.createRef()
    this.saveUserToBackend = this.saveUserToBackend.bind(this)
    this.saveImageToFirebase = this.saveImageToFirebase.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.updateUserInsurance = this.updateUserInsurance.bind(this)
    this.updateUserDataOnFirebase = this.updateUserDataOnFirebase.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.token && props.patient && props.patientCore) {
      const user = { ...props.patient, ...props.patientCore }

      const heightFeet =
        user.height_unit === 'centimeter'
          ? Math.round(user.height / 2.54)
          : Math.floor(user.height / 12)

      const heightInches =
        user.height_unit !== 'centimeter' ? Math.round(user.height % 12) : 0

      const weightLbs =
        user.weight_unit === 'kilograms'
          ? Math.round(user.weight * 2.20462)
          : user.weight

      const language = user.language
        ? { label: user.language, value: user.language }
        : {}

      const address = user.streetAddress ? user.streetAddress : ''

      const address2 = user.streetAddress2 ? user.streetAddress2 : ''
      const addressComponents = user.addressComponents
        ? user.addressComponents
        : []
      const postalCode = user.zipcode ? user.zipcode : ''

      const ethnicity = user.ethnicity
        ? { label: user.ethnicity, value: user.ethnicity }
        : {}

      let patientPharmacy = user.change?.pharmacy
      const pharma = patientPharmacy
        ? {
            label: patientPharmacy
              ? patientPharmacy?.name?.[1] +
                `, ${patientPharmacy?.address_1}` +
                `, ${patientPharmacy?.city}`
              : '',
            value: patientPharmacy,
          }
        : null

      const gender = user.gender
        ? { label: user.gender, value: user.gender }
        : {}
      const dob = user.dob || new Date()

      const userInsurance = Array.isArray(user?.insurance)
        ? user?.insurance[0]
        : user?.insurance || null
      const secondaryInsurance =
        Array.isArray(user?.insurance) && user?.insurance[1]
          ? user?.insurance[1]
          : {}

      const emergencyContactName = user.emergencyContactName || ''
      const emergencyContactPhone = user.emergencyContactPhone || ''
      const phoneNumber = user.phoneNumber || ''
      const email = user.email || ''

      return {
        loading: state.saving,
        userDetails: state.userDetails || user,
        gender: state.gender || gender,
        dob: state.dob || dob,
        ethnicity: state.ethnicity || ethnicity,
        pharma: state.pharma || pharma,
        heightFeet: state.heightFeet || heightFeet,
        heightInches: state.heightInches || heightInches,
        weightLbs: state.weightLbs || weightLbs,
        language: state.language || language,
        address: state.address || address,
        address2: state.address2 || address2,
        addressComponents: state.addressComponents || addressComponents,
        postalCode: state.postalCode || postalCode,
        userInsurance: state.userInsurance || userInsurance,
        secondaryInsurance: state.secondaryInsurance || secondaryInsurance,
        emergencyContactName:
          state.emergencyContactName || emergencyContactName,
        emergencyContactPhone:
          state.emergencyContactPhone || emergencyContactPhone,
        phoneNumber: state.phoneNumber || phoneNumber,
        email: state.email || email,
      }
    }

    return null
  }

  componentDidMount() {
    console.log('PROPS:', this.props.fromProvider)
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    console.log(this.props.patient, this.props.patientCore)
  }

  onSearch() {
    const { patient, token } = this.props
    // setLoading(true)
    let url = Config.CHANGE_BACKEND_URL
    var config = {
      method: 'get',
      url: `${url}change/pharmacies/search/zip`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient.uid,
        zip: this.state.zip,
      },
    }

    axios(config)
      .then(({ data }) => {
        let tempList = []
        data.forEach((patientPharmacy) => {
          tempList.push({
            label: patientPharmacy
              ? patientPharmacy?.name?.[1] +
                `, ${patientPharmacy?.address_1}` +
                `, ${patientPharmacy?.city}`
              : '',
            value: patientPharmacy,
          })
        })
        this.setState({ pharmacyList: tempList || [] })
        // if (data) {
        //   setPharmacyList(data || [])
        //   setLoading(false)
        // }
      })
      .catch(function (error) {
        // setLoading(false)
        // setPharmacyList([])
        console.log('error - pharmacy', error)
      })
  }

  // Updates data on firebase and Mongo now
  updateUserDataOnFirebase = (uid, data) => {
    console.log('success firebase inside function')
    firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .update(data)
      .then((doc) => {
        console.log('success')

        let temp = { ...data }
        temp.emergency_contact_name = temp.emergencyContactName
        temp.emergency_contact_phone = temp.emergencyContactPhone
        temp.first_name = temp.firstName
        temp.last_name = temp.lastName
        temp.phone_number = temp.phoneNumber || ''
        temp.street_address = temp.streetAddress
        temp.street_address2 = temp.streetAddress2
        temp.profile_picture_url = temp.profilePictureURL || ''
        temp.address_components = temp.addressComponents
        delete temp.emergencyContactName
        delete temp.emergencyContactPhone
        delete temp.firstName
        delete temp.lastName
        delete temp.phoneNumber
        delete temp.streetAddress
        delete temp.streetAddress2
        delete temp.profilePictureURL
        delete temp.addressComponents

        axios({
          method: 'put',
          url: `${Config.BACKEND_URL}users`,
          headers: {
            Authorization: `JWT ${this.props.token}`,
            x_firebase_id: uid,
          },
          data: temp,
        }).then((res) => {
          console.log('Updated the data to backend-mongo', res)
        })

        // First update data in firebase and mongo then update the state in the frontend, right?
        firebase
          .firestore()
          .collection('users')
          .doc(uid)
          .get()
          .then((doc) => {
            if (doc && doc.data()) {
              const data = doc.data()
              console.log('user firebase saved :: ', data)
              console.log('success || firebase || savedata', data)
              this.props.addFirebaseUser(data)
              this.props.addPatient(data, 'gray')
            }
          })
        // getFirebaseUser(uid)
      })
      .catch((err) => {
        console.log('error - saving firebase ', err)
      })
  }

  updateUserInsurance(uid, data) {
    let { userInsurance } = this.state
    const { token } = this.props

    if (!userInsurance._id) {
      //need to send firebase ID the first time
      data.firebase_id = uid
    }

    var config = {
      method: userInsurance._id ? 'put' : 'post',
      url: `${Config.BACKEND_URL}patients/insurances${
        userInsurance._id ? `/${userInsurance._id}` : ''
      }`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: uid,
      },
      data: data,
    }

    userInsurance?.insurance_provider?.length &&
      axios(config)
        .then(function (response) {
          console.log('saved insurance information in the backend')
          // toast.success('Successfully updated insurance')
        })
        .catch(function (error) {
          console.log('error when saving info to backend', error)
          // toast.warning('Could not update Insurance')
        })
  }

  onSavePressed() {
    const {
      userDetails,
      dob,
      gender,
      ethnicity,
      heightFeet,
      heightInches,
      weightLbs,
      language,
      address,
      address2,
      addressComponents,
      postalCode,
      pharma,
      frontImage,
      backImage,
      emergencyContactPhone,
      emergencyContactName,
      phoneNumber,
      email,
    } = this.state

    console.log('On saved pressed')
    this.setState({ error: false, errorString: '' })

    // if (!pharma) {
    //   this.setState({ error: true, errorString: 'Please select Pharmacy' })
    //   return
    // }
    if (!addressComponents) {
      this.setState({ error: true, errorString: 'Please enter your Address' })
      return
    }

    if (!emergencyContactPhone?.length || !emergencyContactName?.length) {
      this.setState({
        error: true,
        errorString: 'Please enter Emergency contact information',
      })
      return
    }

    // if (!userDetails.insurance) {
    //   this.setState({
    //     error: true,
    //     errorString: 'Please upload Insurance Details',
    //   })
    //   return
    // }

    // if (!address) {
    //   this.setState({
    //     error: true,
    //     errorString: 'Please enter Address',
    //   })
    //   return
    // }

    userDetails.gender = gender?.value || ''
    userDetails.dob = dob || ''
    userDetails.ethnicity = ethnicity?.value || ''
    userDetails.height = +(heightFeet * 12) + +heightInches
    userDetails.height_unit = 'feet'
    userDetails.weight = weightLbs
    userDetails.weight_unit = 'lbs'
    userDetails.language = language?.value || ''
    userDetails.streetAddress = address || ''
    userDetails.streetAddress2 = address2 || ''
    userDetails.addressComponents = addressComponents || []
    userDetails.zipcode = postalCode
    userDetails.emergencyContactPhone = emergencyContactPhone || ''
    userDetails.emergencyContactName = emergencyContactName || ''
    userDetails.phoneNumber = phoneNumber || ''
    userDetails.email = email || ''
    if (pharma?.value)
      userDetails.change = pharma?.value ? { pharmacy: pharma.value } : {}

    this.setState({
      error: false,
      errorString: '',
      loading: true,
      saving: true,
    })

    this.setState({ userDetails })

    if (
      this.state.profileUploaded ||
      this.state.frontUploaded ||
      this.state.backUploaded
    ) {
      this.saveImageToFirebase(userDetails)
    } else {
      this.saveUserToBackend(userDetails)
    }
  }

  saveUserToBackend(user) {
    const self = this
    const { token, patient } = this.props
    const { userDetails, dob } = this.state

    // TODO: update data to use snake case
    const firebaseData = {
      // email: '',
      // dob: moment(dob).format('DD/MM/YYYY'),
      emergencyContactName: userDetails?.emergencyContactName,
      // emergency_contact_name: userDetails?.emergencyContactName,
      emergencyContactPhone: userDetails?.emergencyContactPhone,
      // emergency_contact_phone: userDetails?.emergencyContactPhone,
      firstName: userDetails?.firstName,
      // first_name: userDetails?.firstName,
      lastName: userDetails?.lastName,
      // last_name: userDetails?.lastName,
      phoneNumber: userDetails?.phoneNumber || '',
      // phone_number: userDetails?.phoneNumber || '',
      email: userDetails?.email || '',
      streetAddress: userDetails?.streetAddress,
      // street_address: userDetails?.streetAddress,
      streetAddress2: userDetails?.streetAddress2,
      // street_address2: userDetails?.streetAddress2,
      zipcode: userDetails?.zipcode,
      // zipcode: userDetails?.zipcode,
      // currentAppVersion: getVersion(),
      profilePictureURL: userDetails?.profilePictureURL || '',
      // profile_picture_url: userDetails?.profilePictureURL || '',
      addressComponents: userDetails?.addressComponents,
      // address_components: userDetails?.addressComponents,
    }

    console.log('firebase:::', firebaseData)

    this.updateUserDataOnFirebase(userDetails?.uid, firebaseData)

    const insuranceData = {
      insurance_provider:
        userDetails?.insurance?.insurance_provider &&
        userDetails?.insurance?.insurance_provider.trim(),
      subscriber_id:
        userDetails?.insurance?.subscriber_id &&
        userDetails?.insurance?.subscriber_id.trim(),
      plan_type:
        userDetails?.insurance?.plan_type &&
        userDetails?.insurance?.plan_type.trim(),
      member_name:
        userDetails?.insurance?.member_name &&
        userDetails?.insurance?.member_name.trim(),
      group_number:
        userDetails?.insurance?.group_number &&
        userDetails?.insurance?.group_number.trim(),
      front_image_url: user?.insurance?.front_image_url,
      back_image_url: user?.insurance?.back_image_url,
    }

    // this.updateUserInsurance(userDetails.uid, insuranceData)

    const mongoData = {
      change: userDetails?.change,
      height: userDetails?.height,
      height_unit: userDetails?.height_unit,
      weight_unit: userDetails?.weight_unit,
      weight: userDetails?.weight,
      language: userDetails?.language,
      uid: userDetails?.uid,
      ethnicity: userDetails?.ethnicity,
      gender: userDetails?.gender,
      phone_number: userDetails?.phoneNumber,
      dob: dob,  // utc cause have to take the local time date of birth without converting to utc
    }

    const url = `${Config.BACKEND_URL}users`

    console.log('mongo data::', mongoData, url)

    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: user.uid },
      data: mongoData,
    })
      .then(({ data }) => {
        console.log('successfully added user details to mongo backend')
        // self.props.addMongoUser(user)
        toast.success('Successfully updated profile')

        this.props.fetchPatientCoreDate(patient.uid, token)

        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        self.setState({ loading: false, saving: false })

        // , imageUploaded: false })
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  getRandomString = (len) => {
    var result = []
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (let i = 0; i < len; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength)),
      )
    }
    return result.join('')
  }

  saveImageToFirebase(user) {
    const self = this

    if (this.state.profileUploaded) {
      const file = this.state.profileImage
      const storageRef = firebase.storage().ref()

      const uploadTask = storageRef.child(`${file.type}/${file.name}`).put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          //   self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            user.profilePictureURL = downloadURL
            self.saveUserToBackend(user)
          })
        },
      )
    }

    if (this.state.frontUploaded) {
      const file = this.state.frontImage
      const storageRef = firebase.storage().ref()
      const fileExtension = self.state.frontImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${user.uid}/${
            self.state.frontUploaded
              ? `front-${self.getRandomString(10)}`
              : `back-${self.getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            user.insurance.front_image_url = downloadURL
            self.saveUserToBackend(user)
          })
        },
      )
    }
    if (this.state.backUploaded) {
      const file = this.state.backImage
      const storageRef = firebase.storage().ref()
      const fileExtension = self.state.backImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/insurances/${user.uid}/${
            self.state.frontUploaded
              ? `front-${self.getRandomString(10)}`
              : `back-${self.getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            user.insurance.back_image_url = downloadURL
            self.saveUserToBackend(user)
          })
        },
      )
    }
  }

  onImageChange(e) {
    const file = e.target.files[0] || false
    if (
      file &&
      window.confirm(`Are you sure you want to upload ${file.name}?`)
    ) {
      // this.setState({imageUploaded: true, imageLocation: })

      const reader = new FileReader()
      const self = this

      reader.onload = function (e) {
        const image = e.target.result
        if (self.state.profileChanged) {
          console.log('confirmed upload - profile')

          self.setState(
            {
              profileLocation: image,
              // imageLocation: image,
              // imageUploaded: true,
              // image: file,
              profileUploaded: true,
              profileImage: file,
            },
            () => {
              self.setState({ profileChanged: false })
            },
          )
        } else if (self.state.insuranceFront) {
          console.log('confirmed upload - insurance front')

          self.setState(
            {
              frontLocation: image,
              // imageUploaded: true,
              // image: file,
              frontUploaded: true,
              frontImage: file,
            },
            () => {
              self.setState({ insuranceFront: false })
            },
          )
        } else if (self.state.insuranceBack) {
          console.log('confirmed upload - insurance back')

          self.setState(
            {
              backLocation: image,
              // imageUploaded: true,
              backUploaded: true,
              backImage: file,
            },
            () => {
              self.setState({ insuranceBack: false })
            },
          )
        }
      }
      reader.readAsDataURL(file) // convert to base64 string
    } else {
      console.log('ignored')
    }
  }

  renderInfoCard() {
    const { userDetails } = this.state
    const { patientCore } = this.props

    const stars = []
    for (let i = 0; i < 5; i++) {
      stars.push(
        <FontAwesomeIcon
          icon={faStar}
          style={{
            color: 'gold',
            fontSize: 20,
            marginRight: 5,
            marginLeft: i === 0 ? 10 : 0,
          }}
        />,
      )
    }

    let image
    if (this.state.profileUploaded) image = this.state.profileLocation
    else image = userDetails?.profilePictureURL || PlaceHolderProfileImage

    let state_residence = null

    let addressComponents = userDetails?.addressComponents
    if (addressComponents && addressComponents.length > 0) {
      addressComponents.forEach((addressComponent) => {
        let types = addressComponent.types || []
        if (types.includes('administrative_area_level_1'))
          state_residence = addressComponent.long_name
      })
    }

    let insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 1) || null
      : patientCore?.insurance || null

    return (
      <div style={{ width: '35%', paddingTop: 50, paddingRight: 20 }}>
        <div className={Styles.infoWrapper}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={image}
              style={{
                height: 150,
                width: 150,
                objectFit: 'cover',
                borderRadius: '50%',
              }}
              alt="No Image"
            />
            <input
              onChange={(e) => {
                console.log('profile image changed ')
                this.setState({ profileChanged: true })
                this.onImageChange(e)
              }}
              //   onChange={this.onImageChange.bind(this)}
              type="file"
              id="file"
              ref={this.imageUploadRef}
              accept="image/*"
              style={{ display: 'none' }}
              multiple={false}
            />
            <p
              onClick={() => this.imageUploadRef.current.click()}
              style={{
                textDecoration: 'underline',
                color: 'gray',
                cursor: 'pointer',
              }}
            >
              Upload/Change Photo
            </p>
          </div>
          <p
            style={{
              color: Constants.primaryTheme,
              marginTop: 10,
              fontWeight: 'bold',
              fontSize: 'larger',
            }}
          >
            {`${userDetails?.firstName} ${userDetails?.lastName}`}
          </p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', width: '40%', marginRight: 10 }}>
              Email:{' '}
            </p>
            {userDetails?.email}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', width: '40%', marginRight: 10 }}>
              State:{' '}
            </p>
            {state_residence}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Language:
            </p>
            <p>{userDetails?.language}</p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Insurance:{' '}
            </p>
            <p style={{ width: '50%', textOverflow: 'break-word' }}>
              {insurance?.insurance_company?.insurance_name}
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <p style={{ fontWeight: 'bold', marginRight: 10, width: '40%' }}>
              Subscription:{' '}
            </p>
            <p style={{ width: '50%', textOverflow: 'break-word' }}>
              {userDetails?.coaching?.plan?.toUpperCase() || 'BASIC'}
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderAccountDetails() {
    const {
      userDetails,
      userInsurance,
      secondaryInsurance,
      emergencyContactPhone,
      emergencyContactName,
      phoneNumber,
      email,
    } = this.state

    const { token, patient, patientCore } = this.props

    let insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 1) || null
      : patientCore?.insurance || null

    let frontImage
    if (this.state.frontUploaded) frontImage = this.state.frontLocation
    else frontImage = insurance?.front_image_url || null

    let backImage
    if (this.state.backUploaded) backImage = this.state.backLocation
    else backImage = insurance?.back_image_url || null

    return (
      <div
        style={{
          width: !this.props?.fromProvider ? '65%' : '100%',
          padding: '20px 40px',
        }}
      >
        {/* license no */}

        {/* PATIENT NAME */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>First Name</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter First Name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={userDetails?.firstName}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.firstName = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Last Name</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter First Name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={userDetails?.lastName}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.lastName = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Date of birth</p>
          <div style={{ width: '50%' }}>
            <DatePicker
              defaultDate={moment(this.state.dob, 'DD/MM/YYYY').format()}
              dateChange={(date) => {
                this.setState({ dob: moment.utc(date).format('DD/MM/YYYY') })
              }}
            />
          </div>
        </div>

        {/*  gender */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Gender at birth</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="gender"
              options={[
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Non-Binary', value: 'Non-Binary' },
              ]}
              value={this.state.gender}
              autoBlur
              onChange={(val) => {
                this.setState({ gender: val })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {/*  ETHNICITY */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Ethnicity</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="ethnicity"
              options={metadata.ETHNICITY_OPTIONS}
              value={this.state.ethnicity}
              isSearchable
              autoBlur
              onChange={(val) => {
                this.setState({ ethnicity: val })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        {/*  HEIGHT WEIGHT */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Height</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder={'Feet'}
              type="number"
              id="height"
              name="height"
              min="1"
              max="10"
              value={this.state.heightFeet}
              onChange={(e) => {
                this.setState({ heightFeet: e.target.value })
              }}
              // onChange={(e) => {
              //   let text = e.target.value
              //   setHeight(text)
              // }}
              className={Styles.textInput}
              style={{ width: '40%' }}
            />{' '}
            ft.{' '}
            <input
              placeholder={'inches'}
              type="number"
              id="heightinch"
              name="heightinch"
              min="0"
              value={this.state.heightInches}
              onChange={(e) => {
                this.setState({ heightInches: e.target.value })
              }}
              // onChange={(e) => {
              //   let text = e.target.value
              //   setHeightinch(text)
              // }}
              className={Styles.textInput}
              style={{ width: '40%' }}
            />{' '}
            in.
          </div>
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Weight</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder={'Pounds'}
              type="number"
              id="weightLbs"
              name="weightLbs"
              //   min="0"
              value={this.state.weightLbs}
              onChange={(e) => {
                this.setState({ weightLbs: e.target.value })
              }}
              // onChange={(e) => {
              //   let text = e.target.value
              //   setHeightinch(text)
              // }}
              className={Styles.textInput}
              style={{ width: '50%' }}
            />{' '}
            Pounds
          </div>
        </div>

        {/*  languages */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Preferred Language</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="languages"
              options={[
                { label: 'English', value: 'English' },
                { label: 'Spanish', value: 'Spanish' },
                { label: 'French', value: 'French' },
                { label: 'Portuguese', value: 'Portuguese' },
                { label: 'German', value: 'German' },
                { label: 'Arabic', value: 'Arabic' },
                { label: 'Italian', value: 'Italian' },
                { label: 'Mandarin', value: 'Mandarin' },
                { label: 'Cantonese', value: 'Cantonese' },
                { label: 'Japanese', value: 'Japanese' },
                { label: 'Russian', value: 'Russian' },
                { label: 'Korean', value: 'Korean' },
              ]}
              value={this.state.language}
              isSearchable={false}
              autoBlur
              onChange={(val) => this.setState({ language: val })}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Mobile Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Mobile number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.phoneNumber}
              onChange={(e) => {
                this.setState({ phoneNumber: e.target.value })
              }}
            />
          </div>
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Email</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Email"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={email}
              onChange={(e) => {
                this.setState({ email: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        {/*  ADDRESS */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Address</p>
          <div style={{ width: '50%' }}>
            <GooglePlacesAutocomplete
              apiKey={Config.GOOGLE_API_KEY}
              selectProps={{
                placeholder: this.state.address || 'Select Address',
                value: this.state.address,
                onChange: (picked = null) => {
                  //get ADDRESS COMPONENT
                  geocodeByAddress(picked.label)
                    .then((details) => {
                      // 'details' is provided when fetchDetails = true
                      const zip = details[0]?.address_components.find(
                        (addressComponent) =>
                          addressComponent.types.includes('postal_code'),
                      )?.short_name
                      const address = picked.value.description || ''
                      this.setState({ address: address, postalCode: zip })

                      const addressComponents =
                        details && details[0].address_components
                      this.setState({ addressComponents: addressComponents })
                    })
                    .catch((error) => console.error(error))
                },
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Apt, Floor</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Apt, Floor"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.address2}
              onChange={(e) => {
                // const { userDetails } = this.state
                // userDetails.streetAddress2 = e.target.value
                this.setState({ address2: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Zip Code</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Zip Code"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.postalCode}
              onChange={(e) => {
                // const { userDetails } = this.state
                // userDetails.zipcode = e.target.value
                this.setState({ postalCode: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        <div className={Styles.row} style={{}}>
          <p className={Styles.rowLabel}>Preferred Pharmacy</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Search Zip Code"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={
                // patientPharmacy
                //   ? patientPharmacy?.name?.[1] +
                //     `, ${patientPharmacy?.address_1}` +
                //     `, ${patientPharmacy?.city}`
                //   :
                this.state.zip
              }
              onChange={(e) => {
                // const { userDetails } = this.state

                // userDetails.zipcode = e.target.value
                this.setState({ zip: e.target.value }, () => {
                  this.onSearch()
                })
                this.onSearch.bind(this)
              }}
            />
          </div>
        </div>

        <div className={Styles.row} style={{}}>
          <p className={Styles.rowLabel}></p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="pharmacyZip"
              placeholder="Please select pharmacy"
              options={this.state.pharmacyList}
              value={this.state.pharma}
              isSearchable
              autoBlur
              onChange={(val) => {
                this.setState({ pharma: val })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        {/*IDENTITY VERIFICATION */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Identity Verification</p>
          <div style={{ width: 'auto', display: 'flex', flexDirection: 'row' }}>
            <input
              placeholder="Edit Identity"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={userDetails?.firstName + ' ' + userDetails?.lastName}
              disabled={true}
              // onChange={(e) => {
              //   const { userDetails } = this.state
              //   userDetails.insurance.insurance_provider = e.target.value
              //   this.setState({ userDetails })
              // }}
            />

            <FontAwesomeIcon
              onClick={() => {
                this.props.toggleSidebar(true)
                this.setState({ showIdentityModal: true })
              }}
              icon={faEdit}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginLeft: 10,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Government Issued ID</p>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {patientCore?.id_back_image_url && (
                <img
                  src={patientCore?.id_back_image_url || null}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
              )}

              {/* <input
                onChange={(e) => {
                  this.setState({ insuranceFront: true })
                  this.onImageChange(e)
                }}
                type="file"
                id="file"
                ref={this.frontImageRef}
                accept="image/*"
                style={{ display: 'none' }}
                multiple={false}
              />
              <p
                onClick={() => this.frontImageRef.current.click()}
                style={{
                  textDecoration: 'underline',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                Upload Front
              </p> */}
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {patientCore?.id_front_image_url && (
                <img
                  src={patientCore?.id_front_image_url || null}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
              )}

              {/* <input
                onChange={(e) => {
                  this.setState({ insuranceBack: true })
                  this.onImageChange(e)
                }}
                type="file"
                id="file"
                ref={this.backImageRef}
                accept="image/*"
                style={{ display: 'none' }}
                multiple={false}
              />
              <p
                onClick={() => this.backImageRef.current.click()}
                style={{
                  textDecoration: 'underline',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                Upload Back
              </p> */}
            </div>
          </div>
        </div>

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        {/*INSURANCE */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Insurance Provider</p>
          <div style={{ width: 'auto', display: 'flex', flexDirection: 'row' }}>
            <input
              placeholder="Edit Insurance"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={insurance?.insurance_company?.insurance_name}
              disabled={true}
              // onChange={(e) => {
              //   const { userDetails } = this.state
              //   userDetails.insurance.insurance_provider = e.target.value
              //   this.setState({ userDetails })
              // }}
            />

            <FontAwesomeIcon
              onClick={() => {
                this.props.toggleSidebar(true)
                this.setState({ showInsuranceModal: true })
              }}
              icon={faEdit}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginLeft: 10,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>Insurance Plan Type</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Insurance Plan Type"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={userDetails.insurance.plan_type}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.insurance.plan_type = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Member Name</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Member Name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={userDetails.insurance.member_name}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.insurance.member_name = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div> */}

        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>Subscriber ID</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Subscriber ID"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={userDetails.insurance.subscriber_id}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.insurance.subscriber_id = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div> */}

        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>Group Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Group Number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={userDetails.insurance.group_number}
              onChange={(e) => {
                const { userDetails } = this.state
                userDetails.insurance.group_number = e.target.value
                this.setState({ userDetails })
              }}
            />
          </div>
        </div> */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Insurance Card Image</p>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {frontImage && (
                <img
                  src={frontImage}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
              )}

              {/* <input
                onChange={(e) => {
                  this.setState({ insuranceFront: true })
                  this.onImageChange(e)
                }}
                type="file"
                id="file"
                ref={this.frontImageRef}
                accept="image/*"
                style={{ display: 'none' }}
                multiple={false}
              />
              <p
                onClick={() => this.frontImageRef.current.click()}
                style={{
                  textDecoration: 'underline',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                Upload Front
              </p> */}
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {backImage && (
                <img
                  src={backImage}
                  style={{ height: '100%', width: '90%', objectFit: 'cover' }}
                  alt="No Image"
                />
              )}

              {/* <input
                onChange={(e) => {
                  this.setState({ insuranceBack: true })
                  this.onImageChange(e)
                }}
                type="file"
                id="file"
                ref={this.backImageRef}
                accept="image/*"
                style={{ display: 'none' }}
                multiple={false}
              />
              <p
                onClick={() => this.backImageRef.current.click()}
                style={{
                  textDecoration: 'underline',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                Upload Back
              </p> */}
            </div>
          </div>
        </div>

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Emergency Contact Name</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter emergency contact name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={emergencyContactName}
              onChange={(e) => {
                this.setState({ emergencyContactName: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Emergency Contact Number</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter emergency contact number"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={emergencyContactPhone}
              onChange={(e) => {
                this.setState({ emergencyContactPhone: e.target.value })
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>

        {!this.props?.fromProvider && (
          <>
            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Subscription Type</p>
              <div style={{ width: '50%' }}>
                <input
                  //   placeholder="Please Subscribe"
                  className={Styles.textInput}
                  style={{ width: '80%' }}
                  type="text"
                  value={userDetails?.coaching?.plan?.toUpperCase() || 'BASIC'}
                  disabled
                  //   onChange={(e) => {
                  //     const { userDetails } = this.state
                  //     userDetails.lastName = e.target.value
                  //     this.setState({ userDetails })
                  //   }}
                />

                <FontAwesomeIcon
                  onClick={() => {
                    // this.props.toggleSidebar(true)
                    let src = `${Config.WEB_HOST}stripe/subscription?patient_id=${userDetails?.uid}&email=${userDetails?.email}`
                    window.open(
                      src,
                      '',
                      'toolbar=0,status=0,width=700,height=500',
                    )
                    // this.setState({ showPaymentModal: true })
                  }}
                  icon={faEdit}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 20,
                    marginLeft: 10,
                    cursor: 'pointer',
                  }}
                />
                {userDetails?.coaching && userDetails?.coaching.active && (
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: 14,
                      color: TEXT_COLOR,
                    }}
                  >
                    Subscription started on:{' '}
                    {userDetails?.coaching.start &&
                      moment(userDetails?.coaching.start).format(
                        'Do MMMM YYYY',
                      )}
                  </p>
                )}
              </div>
            </div>

            <div className={Styles.row}>
              <p
                style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}
              ></p>
            </div>
          </>
        )}
      </div>
    )
  }

  handleInsuranceCallback = (childData) => {
    console.log('callback::', childData)
    const self = this
    const { token, patient } = this.props
    if (childData) {
      self.setState({ showInsuranceModal: false }, () => {
        self.props.toggleSidebar(false)
        self.setState({
          error: false,
          errorString: '',
          loading: true,
          saving: true,
        })
        self.props.fetchPatientCoreDate(patient.uid, token)

        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        self.setState({ loading: false, saving: false })
        const userInsurance = Array.isArray(patient?.insurance)
          ? patient?.insurance[0]
          : patient?.insurance || null
        this.setState({ userInsurance: userInsurance }, () => {
          console.log('updated user insurance')
        })
      })
    } else {
      self.setState({ showInsuranceModal: false })
      self.props.toggleSidebar(false)
    }
  }

  renderIdentityModal() {
    const { userDetails } = this.state
    const { patientCore } = this.props

    let primary_insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 1) || null
      : patientCore?.insurance || null

    let secondary_insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 2)
      : {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.setState({ showIdentityModal: false })
            this.props.toggleSidebar(false)
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Identity Verification
        </h4>
        <PatientIdentity
          parentCallback={this.handleInsuranceCallback.bind(this)}
          patient={patientCore}
          primaryInsurance={primary_insurance}
          secondaryInsurance={secondary_insurance}
        />
      </div>
    )
  }

  renderInsuranceModal() {
    const { userDetails } = this.state
    const { patientCore } = this.props

    let primary_insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 1) || null
      : patientCore?.insurance || null

    let secondary_insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 2)
      : {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.setState({ showInsuranceModal: false })
            this.props.toggleSidebar(false)

            if (
              !patientCore?.id_front_image_url ||
              !patientCore?.id_back_image_url
            ) {
              this.setState({ showIdentityModal: true })
              this.props.toggleSidebar(true)
            }
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Insurance
        </h4>
        <PatientInsurance
          parentCallback={this.handleInsuranceCallback.bind(this)}
          primaryInsurance={primary_insurance}
          secondaryInsurance={secondary_insurance}
          fromProvider={this.props?.fromProvider}
        />
      </div>
    )
  }

  renderPaymentModal() {
    const { userDetails } = this.state
    let state_residence = null

    let addressComponents = userDetails?.addressComponents
    if (addressComponents && addressComponents.length > 0) {
      let state = null
      addressComponents.forEach((addressComponent) => {
        let types = addressComponent.types || []
        if (types.includes('administrative_area_level_1'))
          state = addressComponent.short_name
      })
      console.log('user state is', state)
      state_residence = state
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            flex: 1,
            alignSelf: 'center',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <iframe
            id="payment_web"
            src={`${Config.WEB_HOST}stripe/subscription?patient_id=${userDetails?.uid}&email=${userDetails?.email}`}
            title="payment"
            style={{ height: '100%', width: '100%' }}
            frame-ancestors="*"
          />
        </div>
      </div>
    )
  }

  render() {
    const { userDetails } = this.state
    const { patientCore } = this.props

    if (this.props?.fromProvider) {
      return (
        <div className={GlobalStyles.contentWrapper} style={{ width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {this.renderAccountDetails()}
          </div>

          <div
            style={{
              width: '100%',
              marginTop: 60,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {this.state.error && (
              <p
                style={{
                  marginTop: 4,
                  marginBottom: 4,
                  fontSize: 12,
                  color: 'red',
                }}
              >
                {this.state.errorString}
              </p>
            )}
            <Button
              onClick={this.onSavePressed.bind(this)}
              className={GlobalStyles.button}
              disabled={this.state.loading}
              style={{ width: 200 }}
              variant="primary"
            >
              {this.state.loading ? 'Loading....' : 'Save'}
            </Button>
          </div>

          {this.state.showPaymentModal && (
            <Modal
              ariaHideApp={false}
              onRequestClose={() => {
                this.setState({ showPaymentModal: false })
                this.props.toggleSidebar(false)
              }}
              isOpen={this.state.showPaymentModal}
              style={paymentModalStyles}
              contentLabel="Modal"
            >
              {this.renderPaymentModal()}
            </Modal>
          )}

          {this.state.showIdentityModal && (
            <Modal
              ariaHideApp={false}
              onRequestClose={() => {
                this.setState({ showIdentityModal: false })
                this.props.toggleSidebar(false)
              }}
              isOpen={this.state.showIdentityModal}
              style={paymentModalStyles}
              contentLabel="Modal"
            >
              {this.renderIdentityModal()}
            </Modal>
          )}

          {this.state.showInsuranceModal && (
            <Modal
              ariaHideApp={false}
              onRequestClose={() => {
                this.setState({ showInsuranceModal: false })
                this.props.toggleSidebar(false)
                if (
                  !patientCore?.id_front_image_url ||
                  !patientCore?.id_back_image_url
                ) {
                  this.setState({ showIdentityModal: true })
                  this.props.toggleSidebar(true)
                }
              }}
              isOpen={this.state.showInsuranceModal}
              style={paymentModalStyles}
              contentLabel="Modal"
            >
              {this.renderInsuranceModal()}
            </Modal>
          )}
        </div>
      )
    } else {
      return (
        <div className={GlobalStyles.container}>
          <Header header="My Account" />

          {this.state.loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                minWidth: '100%',
                height: 400,
                alignItems: 'center',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          ) : (
            <div className={GlobalStyles.contentWrapper}>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                {this.renderAccountDetails()}
                {!this.props?.fromProvider && this.renderInfoCard()}
              </div>

              <div
                style={{
                  width: '100%',
                  marginTop: 60,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {this.state.error && (
                  <p
                    style={{
                      marginTop: 4,
                      marginBottom: 4,
                      fontSize: 12,
                      color: 'red',
                    }}
                  >
                    {this.state.errorString}
                  </p>
                )}
                <Button
                  onClick={this.onSavePressed.bind(this)}
                  className={GlobalStyles.button}
                  disabled={this.state.loading}
                  style={{ width: 200 }}
                  variant="primary"
                >
                  {this.state.loading ? 'Loading....' : 'Save'}
                </Button>
              </div>

              {this.state.showPaymentModal && (
                <Modal
                  ariaHideApp={false}
                  onRequestClose={() => {
                    this.setState({ showPaymentModal: false })
                    this.props.toggleSidebar(false)
                  }}
                  isOpen={this.state.showPaymentModal}
                  style={paymentModalStyles}
                  contentLabel="Modal"
                >
                  {this.renderPaymentModal()}
                </Modal>
              )}

              {this.state.showIdentityModal && (
                <Modal
                  ariaHideApp={false}
                  onRequestClose={() => {
                    this.setState({ showIdentityModal: false })
                    this.props.toggleSidebar(false)
                  }}
                  isOpen={this.state.showIdentityModal}
                  style={paymentModalStyles}
                  contentLabel="Modal"
                >
                  {this.renderIdentityModal()}
                </Modal>
              )}

              {this.state.showInsuranceModal && (
                <Modal
                  ariaHideApp={false}
                  onRequestClose={() => {
                    this.setState({ showInsuranceModal: false })
                    this.props.toggleSidebar(false)
                    if (
                      !patientCore?.id_front_image_url ||
                      !patientCore?.id_back_image_url
                    ) {
                      this.setState({ showIdentityModal: true })
                      this.props.toggleSidebar(true)
                    }
                  }}
                  isOpen={this.state.showInsuranceModal}
                  style={paymentModalStyles}
                  contentLabel="Modal"
                >
                  {this.renderInsuranceModal()}
                </Modal>
              )}
            </div>
          )}
        </div>
      )
    }
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  const { userReducer } = state
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser:
      userReducer.userType && userReducer.userType.includes('team')
        ? userReducer.teamMongoUser
        : userReducer.mongoUser,
    adminMongoUser: userReducer.mongoUser,
    patient: state.patientReducer.patient,
    userType: userReducer.userType,
    adminId: userReducer.adminId,
    patientCore: state.patientReducer.patientCore,
  }
}

const mapDispatchToProps = {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAccount)
