import React, {useState, useEffect} from 'react';
import moment from 'moment';
import Select from "react-select";
import Constants from "../../values";
import GlobalStyles from "../../pages/styles/global.module.scss";
import Styles from "./Musculoskeletal.module.scss";
import QuestionMetaData from '../../questionMetadata'
import Config from "../../config";
import axios from 'axios';

export default function AnswerTable({tableName, pathName, token, patientId}) {

  const [selectedDate, setQuestionDate] = useState('')
  const [allAnswer, setAllAnswer] = useState([])

  useEffect(() => {
		getAnswerTableData()
	}, [])

	const getAnswerTableData = () => {
		const url = Config.BACKEND_URL + pathName
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patientId, complete: true },
    })
      .then(({ data }) => {
        setAllAnswer(data)
				setQuestionDate(data?.[0]?.created_at)
      })
      .catch((err) => {
        console.log('error when getting answer table data', err)
      })
	}

  const renderQuestions = (answerData) => {
    if (!answerData) {
      return ([
        <div className={GlobalStyles.container} style={{height: '100vh'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: '100%'}}>
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      ])
    }
    let toReturn = []
    answerData && Object.entries(QuestionMetaData[pathName]).forEach(
      ([key, value], index) => {
        toReturn.push(
          <div
            key={index.toString()}
            className={Styles.inviteRow}
            style={{ backgroundColor: index % 2 === 0 ? "#e8e8e8" : "" }}
          >
            <p className={Styles.inviteListEntry} style={{ width: "10%" }}>
              {index + 1}.
            </p>
            <p className={Styles.inviteListEntry} style={{ width: "60%", textAlign: 'start' }}>
              {value}
            </p>
            <p className={Styles.inviteListEntry} style={{ width: "25%" }}>
              {
                `${answerData?.[key]}`
              }
            </p>
          </div>
        )
      }
    )
    return toReturn
  }

  if(allAnswer.length === 0) {
    return (
      <div className={Styles.questionContainer} style={{height: 350}}>
        <h4 style={{ color: Constants.primaryTheme, textAlign: "center" }}>
          {tableName}
        </h4>
        <p>
          No Data Present
        </p>
      </div>
    )
  }
  let dates = allAnswer?.map(data => (
    {
      label: moment(data?.created_at).format('MMM Do YYYY'), 
      value: data?.created_at
    }
  ))
  let answerData = allAnswer.find(answer => moment(answer.created_at).isSame(moment(selectedDate)))
  return (
    

      <div className={Styles.questionContainer}>
      <h4 style={{ color: Constants.primaryTheme, textAlign: "center" }}>
        {tableName}
      </h4>

      <div style={{width: '40%', alignSelf: 'start'}}>
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Date:</p>
          <Select
              options={dates}
              className={'w-50'}
              defaultValue={{label: moment(allAnswer?.[0]?.created_at).format('MMM Do YYYY'), value: allAnswer?.[0]?.created_at}}
              autoBlur={true}
              onChange={(val) => {
                setQuestionDate(val.value)
              }}
          />
        </div>
      </div>
        <div className={Styles.inviteRow}>
          <p className={Styles.inviteListHeader} style={{ width: "10%" }}>
            No.
          </p>
          <p className={Styles.inviteListHeader} style={{ width: "60%" }}>
            Questions
          </p>
          <p className={Styles.inviteListHeader} style={{ width: "25%" }}>
            Answers
          </p>
        </div>
        {renderQuestions(answerData)}
      <p style={{marginTop: 10, textAlign: 'start'}}>{answerData?.result?.message && `Result: ${answerData?.result?.message}`}</p>
      </div>
  )
}
