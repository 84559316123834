import React from 'react'
import Styles from './styles/styles.module.scss'
import AilaLogo from '../assets/images/aila_logo.png'

const OnboardingFooter = (props) => {
  return (
    <div
      style={{
        flex: 1,
        alignSelf: 'center',
        marginTop: '5%',
        color: '#808080',
        fontSize: 'larger',
      }}
    >
      <p>
        For booking assistance, please call{' '}
        <span
          style={{
            textDecoration: 'underline',
            textDecorationColor: 'blue',
            textDecorationThickness: '2px',
          }}
        >
          415-704-9601
        </span>{' '}
      </p>
    </div>
  )
}

export default OnboardingFooter
