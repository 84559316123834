import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBrain,
  faRunning,
  faCloudMoon,
  faAppleAlt,
  faCheck,
  faHospitalUser,
  faCapsules,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import GlobalStyles from '../../styles/global.module.scss'
import Styles from './styles/Progress.module.scss'
import Constants from '../../../values'
import PulseIcon from '../../../assets/images/pulse.png'
import FoodPlaceholder from '../../../assets/images/foodPlaceholder.png'
import VitalsProgress from './Vitals'
import Gastrointestinal from '../BodySystems/InnerTabs/Gastrointestinal'
import SymptomsProgress from './SymptomsProgress'
import Helpers from '../../../helpers/Global'
import PatientInfoStrip from '../../../components/PatientInfoStrip'
import Config from '../../../config'
import MedMonitor from '../MedMonitor/MedMonitor'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import StomachIcon from '../../../assets/images/stomach.png'
const axios = require('axios')
const FOOD_TAGS = [
  'Nuts',
  'Vegetables',
  'Soy',
  'Gluten',
  'Fruits',
  'Red Meat',
  'Diary',
  'Eggs',
]

const windowHeight = window.innerHeight
const customFoodImageModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: windowHeight / 1.6,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const manualAddModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    overlay: { zIndex: 1000 },
  },
  content: {
    width: '60vw',
    height: '80vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const DIGESTION_TYPES = {
  1: 'Separate hard lumps',
  2: 'Lumpy and sausage-like',
  3: 'Sausage shaped with cracks',
  4: 'Like a smooth soft snake',
  5: 'Soft blobs with clear cut edges',
  6: 'Mushy consistency with ragged edges',
  7: 'Liquid consistency with no solid pieces',
}

class Progress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loadingSymptomProgressData: true,
      loadingContent: false,
      selectedTab: 'progress',
      symptomProgressData: null,
      vitalsProgressData: null,
      vitalsLoading: true,
      vitalsReversed: false,
      behavioralData: null,
      nutritionData: null,
      loadingNutritionData: true,
      medMonitorDataArray: [],
      medications: [],
      medMonitorModalIsOpen: false,
      medMonitorData: [],
      completeGastroData: null,
      indexScreen: (props?.location?.state?.screen === 'symptoms' ? 2 : 1)
    }
    this.getSymptomProgressData = this.getSymptomProgressData.bind(this)
    this.getBehavioralScreeners = this.getBehavioralScreeners.bind(this)
    this.getGastroScreening = this.getGastroScreening.bind(this)
    this.getMedications = this.getMedications.bind(this)
    this.getProviderMedications = this.getProviderMedications.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    const toReturn = {}
    if (props.patient && props.patientCore && state.loading)
      toReturn.loading = false

    if (props.patientProgress && !state.vitalsProgressData) {
      toReturn.vitalsLoading = false
      const vitals = props.patientProgress.vitals || []
      vitals.forEach((each) => {
        each.date = moment(each.created_at).format('MM/DD/YYYY')
        const { sleep } = each
        each.sleep = sleep ? Number(sleep).toFixed(1) : 0
        each.steps = each.steps ? Math.round(each.steps) : 0
      })
      const newVitals = vitals.slice().reverse()
      toReturn.vitalsProgressData = newVitals
    }

    return toReturn
  }

  getBehavioralScreeners() {
    const { patient, token } = this.props

    // get joints screening data from backend
    const url = `${Config.BACKEND_URL}doctor/patient/screenings/behavioral`
    axios({
      method: 'get',
      url,
      headers: { x_firebase_id: patient.uid, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        data.depression.reverse()
        data.anxiety.reverse()
        this.setState({ behavioralData: data, loadingContent: false })
      })
      .catch((err) => {
        console.log('error when getting pain screening data', err)
      })
  }

  getGastroScreening() {
    let { patient, token } = this.props
    this.setState({loadingContent: true})
    //get joints screening data from backend
    let url = Config.BACKEND_URL + 'doctor/patient/screenings/gastro'
    axios({
      method: 'get',
      url: url,
      headers: {
        x_firebase_id: patient.uid,
        Authorization: 'JWT ' + token,
      },
    })
      .then(({ data }) => {
        console.log('got gastro data', data)
        data.crohns.reverse()
        data.colitis.reverse()
        this.setState({
          completeGastroData: data,
          loadingContent: false,
        })
      })
      .catch((err) => {
        console.log('error when getting pain screening data', err)
      })
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    this.getSymptomProgressData('monthly')
    this.getMedications()
  }

  onTabSelected(index) {
    let tab = null
    switch (index) {
      case 1:
        tab = 'progress'
        break
      case 2:
        tab = 'symptoms'
        break
      case 3:
        if (!this.state.behavioralData) {
          this.setState({ loadingContent: true })
          this.getBehavioralScreeners()
        }
        tab = 'behavioral'
        break
      case 4:
        tab = 'physical'
        break
      case 5:
        tab = 'sleep'
        break
      case 6:
        tab = 'nutrition'
        if (!this.state.nutritionData) this.getNutritionProgressData()
        break
      case 7:
        tab = 'medications'
        if (!this.state.nutritionData) this.getNutritionProgressData()
        break
      case 8:
        tab = 'gastro'
        if (!this.state.completeGastroData) this.getGastroScreening()
        break
      default:
        tab = 'progress'
    }

    this.setState({ selectedTab: tab, indexScreen: index })
  }

  getSymptomProgressData(timeline) {
    this.setState({ loadingSymptomProgressData: true })
    // get data
    const { patient, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}user/progress?timeline=${timeline}`
    axios({
      method: 'get',
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patient.uid },
      url,
    })
      .then(({ data }) => {
        if (data && data.progress) data.progress.reverse()
        self.setState({
          symptomProgressData: data,
          loadingSymptomProgressData: false,
        })
      })
      .catch((err) => {
        console.log('error when getting patient symptoms progress data', err)
      })
  }

  getMedications() {
    let { token, patient } = this.props
    let url = Config.BACKEND_URL + 'user/medications'
    let userMedications = {}
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: patient.uid,
      },
    })
      .then((response) => {
        console.log('got user medications data')
        userMedications = response.data
        if (userMedications)
          this.setState(
            {
              medications: userMedications,
            },
            () => this.getProviderMedications(),
          )
        else this.getProviderMedications()
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        this.getProviderMedications()
        console.log('error when getting medication data', err)
      })
  }

  async getProviderMedications() {
    let { patient, token } = this.props
    let { medications } = this.state

    try {
      const getMeds = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/MedicationRequest?patient=' + patient.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getMeds()
      let meds = response?.data
      if (meds) {
        this.setState({ providerMedications: meds })
      }
      let array1 = (medications && medications['active_medications']) || []
      let array2 = (medications && medications['inactive_medications']) || []
      let array3 = (meds && meds['active_medications_provider']) || []
      let array4 = (meds && meds['inactive_medications_provider']) || []
      let newArray = [...array1, ...array2, ...array3, ...array4]
      console.log('test: ', meds['med_monitor'])
      this.setState({
        medMonitorData: newArray,
        medMonitorDataArray: meds['med_monitor'] || [],
      })
    } catch (err) {
      console.log('error when getting meds data', err)
      let array1 = (medications && medications['active_medications']) || []
      let array2 = (medications && medications['inactive_medications']) || []
      let newArray = [...array1, ...array2]
      this.setState({ medMonitorData: newArray })
    }
  }

  getNutritionProgressData(timeline) {
    this.setState({ loadingNutritionData: true })
    // get data
    const { patient, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}doctors/patients/nutrition/progress`
    axios({
      method: 'get',
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patient.uid },
      url,
    })
      .then(({ data }) => {
        if (data) data.reverse()
        console.log('got nnutrition data')
        this.prepareNutritionData(data)
      })
      .catch((err) => {
        console.log('error when getting patient symptoms progress data', err)
      })
  }

  prepareNutritionData(data) {
    const nutritionData = []
    if (data && data.length > 0) {
      const progress = data
      const progressMap = {}
      progress.forEach((entry) => {
        if (entry.food_diary || entry.digestion || entry.drinks)
          progressMap[moment(entry.created_at).format('MM/DD/YYYY')] = entry
      })

      const nowDate = moment()
      let weekBeginning = nowDate.day(0)
      for (let i = 0; i <= 3; i++) {
        const weekArray = []
        let objectWithDate = { date: weekBeginning.toDate() }
        let nutritionObjectForDate =
          progressMap[weekBeginning.format('MM/DD/YYYY')] || {}
        let toPut = { ...objectWithDate, ...nutritionObjectForDate }
        weekArray.push(toPut)
        for (let j = 0; j < 6; j++) {
          const date = weekBeginning.add(1, 'days')
          objectWithDate = { date: date.toDate() }
          nutritionObjectForDate = progressMap[date.format('MM/DD/YYYY')] || {}
          toPut = { ...objectWithDate, ...nutritionObjectForDate }
          weekArray.push(toPut)
        }

        // because moment modifies original object when adding or subtracting, we need to go 6+7 days backward
        weekBeginning = weekBeginning.subtract(13, 'days')
        nutritionData.push(weekArray)
      }
    }

    this.setState({ nutritionData, loadingNutritionData: false })
  }

  renderBehavioralContent() {
    const { loadingSymptomProgressData, loadingContent } = this.state

    if (loadingSymptomProgressData || loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: 200,
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <Behavioral
        behavioralData={this.state.behavioralData}
        patientWellnessScreening={this.props.patientWellnessScreening}
        progressData={this.state.symptomProgressData}
      />
    )
  }

  renderSleepContent() {
    const { vitalsLoading } = this.state
    if (vitalsLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: 200,
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <Sleep
        patientCore={this.props.patientCore}
        vitalsProgressData={this.state.vitalsProgressData}
      />
    )
  }

  renderPhysicalContent() {
    const { vitalsLoading } = this.state
    if (vitalsLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: 200,
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <Physical
        patientCore={this.props.patientCore}
        vitalsProgressData={this.state.vitalsProgressData}
      />
    )
  }

  renderNutritionContent() {
    const { loadingSymptomProgressData, loadingNutritionData } = this.state
    if (loadingSymptomProgressData || loadingNutritionData) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: 200,
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <Nutrition
        patientWellnessScreening={this.props.patientWellnessScreening}
        patientCalculatedProgress={this.props.patientCalculatedProgress}
        nutritionData={this.state.nutritionData}
      />
    )
  }

  handleMedMonitorSave() {
    let { medMonitorData } = this.state
    const { mongoUser, token, patient } = this.props
    console.log(mongoUser?.firebase_id, medMonitorData, patient.uid)
    // send the data as array to store in the med_monitor data if score and monitor_note is present for med
    let medData = medMonitorData
      .filter((med) => med.score || med.monitor_note)
      .map((data) => {
        return {
          name: data.name,
          score: data.score || 0,
          monitor_note: data.monitor_note || 'N/A',
          created_at: moment().toISOString(),
        }
      })
    axios({
      method: 'put',
      url: `${Config.BACKEND_URL}fhir/MedicationMonitor`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser?.firebase_id,
      },
      data: {
        med_monitor: medData,
        firebase_id: patient.uid,
      },
    })
      .then(({ data }) => {
        console.log('successfully added user details to mongo backend')
        this.setState({ medMonitorModalIsOpen: false })
        this.getMedications()
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }
  renderMedMonitorModalContent() {
    let { medMonitorData } = this.state

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          style={{ position: 'absolute' }}
          onClick={() => this.setState({ medMonitorModalIsOpen: false })}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Medication Monitoring
        </h4>

        <div
          className={Styles.contentBackground}
          style={{ height: '80%', overflowY: 'scroll', width: '100%' }}
        >
          <div className={Styles.contentRow} style={{ padding: '10px 10px' }}>
            <h6
              className={Styles.tableHeader}
              style={{ width: '25%', textAlign: 'center' }}
            >
              Name
            </h6>
            <h6
              className={Styles.tableHeader}
              style={{ width: '10%', textAlign: 'center' }}
            >
              Dose
            </h6>
            {/*<h6 className={Styles.tableHeader}>Frequency</h6>*/}
            <h6
              className={Styles.tableHeader}
              style={{ width: '15%', textAlign: 'center' }}
            >
              Start
            </h6>
            <h6
              className={Styles.tableHeader}
              style={{ width: '15%', textAlign: 'center' }}
            >
              Status
            </h6>
            <h6
              className={Styles.tableHeader}
              style={{ width: '10%', textAlign: 'center' }}
            >
              Patient Response Score
            </h6>
            <h6
              className={Styles.tableHeader}
              style={{ width: '25%', textAlign: 'center' }}
            >
              Notes
            </h6>
          </div>
          {medMonitorData.map((x, i) => {
            let activeString = 'Active'
            if (x.end)
              activeString =
                'Inactive since ' + moment(x.end).format('MMM Do YYYY')
            return (
              <div key={i.toString()} className={Styles.contentRow}>
                <p style={{ width: '25%', textAlign: 'center' }}>
                  {x.dose?.quantity
                    ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit})`
                    : `${x.name}`}
                </p>
                {/*<p style={{width: '20%', textAlign: 'center'}}>{x.dose.quantity} {x.dose.unit}</p>*/}
                <p style={{ width: '10%', textAlign: 'center' }}>
                  {x.frequency?.quantity
                    ? `${x.frequency.quantity} / ${x.frequency.recurring}`
                    : '-'}
                </p>
                <p style={{ width: '15%', textAlign: 'center' }}>
                  {moment(x.start).format('MMM Do YYYY')}
                </p>
                <p style={{ width: '15%', textAlign: 'center' }}>
                  {activeString}
                </p>
                <div style={{ width: '10%', padding: 5 }}>
                  <Select
                    inputId="score"
                    options={[
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 },
                      { label: 5, value: 5 },
                      { label: 6, value: 6 },
                      { label: 7, value: 7 },
                      { label: 8, value: 8 },
                      { label: 9, value: 9 },
                      { label: 10, value: 10 },
                    ]}
                    value={[
                      {
                        label: x?.score || 'Select',
                        value: x?.score || 'Select',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = medMonitorData
                      temp[i].score = val.value
                      this.setState({ medMonitorData: temp })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
                <textarea
                  style={{ width: '25%', padding: 5 }}
                  rows="2"
                  cols="40"
                  onChange={(e) => {
                    let temp = medMonitorData
                    temp[i].monitor_note = e.target.value
                    this.setState({ medMonitorData: temp })
                  }}
                  value={x?.monitor_note || ''}
                />
              </div>
            )
          })}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => {
              this.handleMedMonitorSave()
            }}
            className={GlobalStyles.button}
            style={{ width: 300, margin: '4%' }}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  renderMedicationsContent() {
    const { loadingSymptomProgressData, loadingNutritionData } = this.state
    if (loadingSymptomProgressData || loadingNutritionData) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: 200,
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div style={{ width: '100%', marginTop: 10 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <h4
            style={{
              color: Constants.primaryTheme,
              width: '100%',
              textAlign: 'center',
            }}
          >
            Medication Therapy Management
          </h4>
          <FontAwesomeIcon
            // className={Styles.plusButtonNormal}
            // style={{ fontSize: '25px', margin: 10 }}
            style={{
              color: Constants.primaryTheme,
              fontSize: 25,
              position: 'absolute',
              right: 14,
              cursor: 'pointer',
            }}
            onClick={() => {
              this.setState({ medMonitorModalIsOpen: true })
            }}
            icon={faPlusCircle}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingLeft: 40,
            alignItems: 'center',
          }}
        >
          <MedMonitor medMonitorData={this.state.medMonitorDataArray} />

          <Modal
            ariaHideApp={false}
            isOpen={this.state.medMonitorModalIsOpen}
            onRequestClose={() =>
              this.setState({ medMonitorModalIsOpen: false })
            }
            style={manualAddModalStyles}
            contentLabel="Modal"
            // style={{zIndex: 2000}}
            // dialogClassName={Styles.taskModalContainer}
          >
            <div
              style={{
                height: '100%',
                width: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'scroll',
                overflow: 'auto',
                padding: 15,
              }}
            >
              {this.renderMedMonitorModalContent()}
            </div>
          </Modal>
        </div>
      </div>
    )
  }

  renderGastroIntestinal() {
    let { loadingContent } = this.state
    if (loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: 200,
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <Gastrointestinal
        patient={this.props.patient}
        gastroData={this.state.completeGastroData}
      />
    )
  }

  renderProgressContent() {
    const { vitalsLoading, vitalsProgressData } = this.state
    if (vitalsLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: 200,
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    if (!vitalsProgressData) return null

    return (
      <div style={{ height: '100%', width: '100%' }}>
        <VitalsProgress />
      </div>
    )
  }

  renderSymptomProgressContent() {
    return <SymptomsProgress />
  }

  renderContent() {
    const { selectedTab } = this.state
    switch (selectedTab) {
      case 'progress':
        return this.renderProgressContent()
      case 'symptoms':
        return this.renderSymptomProgressContent()
      case 'behavioral':
        return this.renderBehavioralContent()
      case 'physical':
        return this.renderPhysicalContent()
      case 'sleep':
        return this.renderSleepContent()
      case 'nutrition':
        return this.renderNutritionContent()
      case 'medications':
        return this.renderMedicationsContent()
      case 'gastro':
        return this.renderGastroIntestinal()
      default:
        return null
    }
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div>
            <PatientInfoStrip
              medications={this.props.medications}
              conditions={this.props.conditions}
              patient={this.props.patient}
              patientCore={this.props.patientCore}
              color={this.props.color}
              cohorts={this.props.mongoUser ? this.props.mongoUser.cohorts : []}
            />

            <div className={GlobalStyles.contentWrapper}>
              <Tabs onTabSelected={this.onTabSelected.bind(this)} indexScreen={this.state.indexScreen}/>
              {this.renderContent()}
            </div>
          </div>
        )}
      </div>
    )
  }
}

const Nutrition = (props) => {
  const DAYS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  const NUTRITION_DATA = [
    [
      '7/10',
      '8 glasses',
      false,
      true,
      'Normal',
      'Abnormal: Frequent, Painful',
      '8/10',
    ],
  ]

  const [weekDataIndex, setWeekDataIndex] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalImage, setModalImage] = useState(null)
  const [modalFoodEntry, setModalFoodEntry] = useState(null)
  const [modalHeader, setModalHeader] = useState(null)
  const [modalTags, setModalTags] = useState(null)

  const { patientWellnessScreening } = props
  const dietEntry = patientWellnessScreening
    ? patientWellnessScreening.nutrition.diet
    : []
  const dietElement = dietEntry.map((x, i) => (
    <span key={i.toString()}>
      {x}
      {i === dietEntry.length - 1 ? '' : ','}
    </span>
  ))
  let cigarette
  let drinking
  let coffee
  if (
    patientWellnessScreening &&
    patientWellnessScreening.lifestyle_habits &&
    patientWellnessScreening.lifestyle_habits.cigarette
  )
    cigarette =
      patientWellnessScreening.lifestyle_habits.cigarette === 'NA'
        ? 'N/A'
        : `${patientWellnessScreening.lifestyle_habits.cigarette} cigarettes / day`

  if (
    patientWellnessScreening &&
    patientWellnessScreening.lifestyle_habits &&
    patientWellnessScreening.lifestyle_habits.alcohol
  ) {
    const value = patientWellnessScreening.lifestyle_habits.alcohol
    switch (value.toLowerCase()) {
      case 'occasional':
        drinking = '1-2 drinks / week'
        break
      case 'moderate':
        drinking = '3-6 drinks / week'
        break
      case 'consistent':
        drinking = '7+ drinks / week'
        break
      default:
        drinking = 'N/A'
    }
  }

  if (
    patientWellnessScreening &&
    patientWellnessScreening.lifestyle_habits &&
    patientWellnessScreening.lifestyle_habits.coffee
  ) {
    const value = patientWellnessScreening.lifestyle_habits.coffee
    switch (value.toLowerCase()) {
      case 'low':
        coffee = '1-2 cups / day'
        break
      case 'moderate':
        coffee = '3-6 cups / day'
        break
      case 'high':
        coffee = '7+ cups / day'
        break
      default:
        coffee = 'N/A'
    }
  }

  function renderUrination(digestion, index) {
    if (!digestion || !digestion.urine) return null

    const { urine } = digestion
    if (!urine) return null

    const tags = urine.tags || []
    const tagsElement = tags.map((x, i) => (
      <span key={i.toString()}>
        {x} {i !== tags.length - 1 && ', '}
      </span>
    ))
    if (urine.type === 'Normal')
      return <p style={{ color: Constants.primaryTheme }}>Normal</p>
    return (
      <div
        data-tip
        data-for={`urination-${index}`}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p style={{ color: 'red' }}>Abnormal</p>
        {tags && tags.length > 0 && (
          <ReactTooltip
            id={`urination-${index}`}
            textColor="black"
            backgroundColor={Constants.contentBackground}
            effect="solid"
            className={Styles.headerHoverWrapper}
          >
            <p style={{ fontWeight: 'bold' }}>
              Tags:{' '}
              <span style={{ marginLeft: 4, fontWeight: 'normal' }}>
                {tagsElement}
              </span>
            </p>
          </ReactTooltip>
        )}
      </div>
    )
  }

  function renderInflammation(nutritionEntry, index) {
    if (!nutritionEntry.inflammation) return null

    const { inflammation, inflammations } = nutritionEntry

    return (
      <div
        data-tip
        data-for={`inflammation-${index}`}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p>{inflammation ? `${inflammation}/10` : '-'}</p>
        {inflammations && inflammations.length > 0 && (
          <ReactTooltip
            id={`inflammation-${index}`}
            textColor="black"
            backgroundColor={Constants.contentBackground}
            effect="solid"
            className={Styles.headerHoverWrapper}
          >
            <div
              style={{
                width: 300,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>
                Inflammation:{' '}
                {inflammations.map((x, i) => (
                  <span style={{ fontWeight: 'normal' }} key={i.toString()}>
                    {x}
                    {i !== inflammations.length - 1 && ', '}
                  </span>
                ))}
              </p>
            </div>
          </ReactTooltip>
        )}
      </div>
    )
  }

  function getBowelTypeColorAndNormalcy(type) {
    const YELLOW = '#ffae42'
    const GREEN = Constants.primaryTheme
    const RED = 'red'

    switch (type) {
      case 3:
      case 4:
        return { color: GREEN, normal: 'Normal' }
      case 2:
      case 5:
      case 6:
        return { color: YELLOW, normal: 'Abnormal' }
      case 1:
      case 7:
        return { color: RED, normal: 'Abnormal' }
      default:
        return { color: 'black', normal: '-' }
    }
  }

  function renderBowelInfo(digestion, index) {
    if (!digestion || !digestion.bowel) return null

    const { bowel } = digestion

    const tags = (bowel && bowel.tags) || []
    const tagsElement = tags.map((x, i) => (
      <span key={i.toString()} style={{ fontWeight: 'normal' }}>
        {x} {i !== tags.length - 1 && ', '}
      </span>
    ))
    const colorAndNormalcy = getBowelTypeColorAndNormalcy(bowel.type)

    return (
      <div data-tip data-for={`bowel-${index}`} style={{ width: '90%' }}>
        <p style={{ color: colorAndNormalcy.color }}>
          Type {bowel.type} -{' '}
          <span style={{ marginLeft: 2 }}>{colorAndNormalcy.normal}</span>
        </p>
        <p>{bowel.frequency} times/day</p>
        <ReactTooltip
          id={`bowel-${index}`}
          textColor="black"
          backgroundColor={Constants.contentBackground}
          effect="solid"
          className={Styles.headerHoverWrapper}
        >
          <div
            style={{
              width: 300,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <p>{DIGESTION_TYPES[bowel.type]}</p>
            <p style={{ marginTop: 2, fontWeight: 'bold' }}>
              Tags: {tagsElement}
            </p>
          </div>
        </ReactTooltip>
      </div>
    )
  }

  function getFoodTooltip(meal, index, tags) {
    const tooltipKey = `food-${meal}-${index}`
    const tagsElement =
      tags &&
      tags.map((x, i) => (
        <span style={{ fontWeight: 'normal' }} key={i.toString()}>
          {x}
          {i !== tags.length - 1 && ', '}
        </span>
      ))

    return (
      <ReactTooltip
        id={tooltipKey}
        textColor="black"
        backgroundColor={Constants.contentBackground}
        effect="solid"
        className={Styles.headerHoverWrapper}
      >
        <p>{meal}</p>
        <p style={{ fontWeight: 'bold' }}>Tags: {tagsElement}</p>
      </ReactTooltip>
    )
  }

  function renderFoodImages(food_diary, index) {
    if (!food_diary) return null

    const { breakfast, lunch, dinner, snack } = food_diary

    const allFoods = []
    if (breakfast && breakfast.length > 0) {
      // more than one entry for each meal
      breakfast.forEach((meal) => allFoods.push({ ...meal, meal: 'breakfast' }))
    }

    if (lunch && lunch.length > 0) {
      // more than one entry for each meal
      lunch.forEach((meal) => allFoods.push({ ...meal, meal: 'lunch' }))
    }

    if (dinner && dinner.length > 0) {
      // more than one entry for each meal
      dinner.forEach((meal) => allFoods.push({ ...meal, meal: 'dinner' }))
    }

    if (snack && snack.length > 0) {
      // more than one entry for each meal
      snack.forEach((meal) => allFoods.push({ ...meal, meal: 'snack' }))
    }

    let elements = []
    if (allFoods && allFoods.length > 0) {
      elements = allFoods.map((meal, i) => (
        <div
          onClick={() => {
            setModalHeader(meal.meal)
            setModalTags(meal.tags || [])
            setModalImage(meal.image_url || FoodPlaceholder)
            setModalFoodEntry(meal)
            setModalIsOpen(true)
          }}
          data-tip
          data-for={`food-${meal.meal}-${i}`}
          style={{ width: '33%' }}
        >
          <img
            src={meal.image_url || FoodPlaceholder}
            className={Styles.foodImage}
          />
          {getFoodTooltip(meal.meal, i, meal.tags || [])}
        </div>
      ))
    }
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {elements}
      </div>
    )
  }

  function renderDrinksEntry(drinksObject, key) {
    if (
      !drinksObject ||
      !drinksObject[key] ||
      !drinksObject[key].quantity ||
      drinksObject[key].quantity === 0
    )
      return '-'

    const drinks = drinksObject[key]
    return `${drinks.quantity} cups(${drinks.volume}oz)`
  }

  function renderTableRows() {
    const toReturn = []
    const { nutritionData } = props
    const weekData =
      nutritionData && nutritionData.length > 0
        ? nutritionData[weekDataIndex]
        : []
    weekData.forEach((nutritionEntry, index) => {
      const {
        eating,
        inflammation,
        inflammations,
        food_diary,
        digestion,
        date,
        drinks,
      } = nutritionEntry
      toReturn.push(
        <tr key={index.toString()}>
          <td style={{ width: '8%' }}>{moment(date).format('ddd (MM/DD)')}</td>
          <td style={{ width: '8%' }}>{eating ? `${eating}/10` : '-'}</td>
          <td style={{ width: '25%' }}>
            {renderFoodImages(food_diary, index)}
          </td>
          <td style={{ width: '10%' }}>{renderDrinksEntry(drinks, 'water')}</td>
          <td style={{ width: '8%' }}>
            {renderDrinksEntry(drinks, 'alcohol')}
          </td>
          <td style={{ width: '8%' }}>
            {renderDrinksEntry(drinks, 'caffeine')}
          </td>
          <td style={{ width: '13%' }}>{renderBowelInfo(digestion, index)}</td>
          <td style={{ width: '12%' }}>{renderUrination(digestion, index)}</td>
          <td style={{ width: '8%' }}>
            {renderInflammation(nutritionEntry, index)}
          </td>
        </tr>,
      )
    })

    // DAYS.forEach((day,index) => {
    //   toReturn.push(
    //     <tr>
    //       <td>{day}</td>
    //       <td>{index === 0 && NUTRITION_DATA[0][0]}</td>
    //       {
    //         index === 0 ?
    //           <td>
    //             <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%'}}>
    //               <img src={Food1} style={{height: 80, width: 80}} />
    //               <img src={Food2} style={{height: 80, width: 80}} />
    //               <img src={Food3} style={{height: 80, width: 80}} />
    //             </div>
    //           </td>
    //           :
    //           <td></td>
    //
    //       }
    //       <td>{index === 0 && NUTRITION_DATA[0][1]}</td>
    //       {index === 0 && NUTRITION_DATA[0][2] ?
    //         <td><FontAwesomeIcon icon={faCheck} style={{color: 'green', fontSize: 20}}/></td>
    //         :
    //         <td></td>
    //       }
    //       {index === 0 && NUTRITION_DATA[0][3] ?
    //         <td><FontAwesomeIcon icon={faCheck} style={{color: 'green', fontSize: 20}}/></td>
    //         :
    //         <td></td>
    //       }
    //       <td>{index === 0 && NUTRITION_DATA[0][4]}</td>
    //       <td>{index === 0 && NUTRITION_DATA[0][5]}</td>
    //       <td>{index === 0 && NUTRITION_DATA[0][6]}</td>
    //     </tr>
    //   )
    // })

    return toReturn
  }

  function renderTable() {
    return (
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th style={{ width: '8%' }}>Day</th>
            <th style={{ width: '8%' }}>Nutrition Score</th>
            <th style={{ width: '25%' }}>Food</th>
            <th style={{ width: '10%' }}>Water</th>
            <th style={{ width: '8%' }}>Alcohol</th>
            <th style={{ width: '8%' }}>Caffeine</th>
            <th style={{ width: '13%' }}>Bowel Movement</th>
            <th style={{ width: '12%' }}>Urination</th>
            <th style={{ width: '8%' }}>Inflammation Score</th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>
    )
  }

  function renderTooltipContent() {
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: Constants.contentBackground,
          borderRadius: 8,
          padding: '10px 10px',
          marginBottom: 20,
        }}
      >
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: 120 }}>
            Diet Score
          </p>
          <span>:</span>
          {props.patientCalculatedProgress && (
            <p className={Styles.contentRowValue}>
              {props.patientCalculatedProgress.healthyEatingPercentage}% of time
              healthy eating this past month
            </p>
          )}
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: 120 }}>
            Diet
          </p>
          <span>:</span>
          <p className={Styles.contentRowValue}>{dietElement}</p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: 120 }}>
            Preference
          </p>
          <span>:</span>
          <p className={Styles.contentRowValue}>
            {patientWellnessScreening &&
              patientWellnessScreening.nutrition.cooking_choice}
          </p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: 120 }}>
            Smoking
          </p>
          <span>:</span>
          <p className={Styles.contentRowValue}>{cigarette}</p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: 120 }}>
            Alcohol
          </p>
          <span>:</span>
          <p className={Styles.contentRowValue}>{drinking}</p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: 120 }}>
            Caffeine
          </p>
          <span>:</span>
          <p className={Styles.contentRowValue}>{coffee}</p>
        </div>
      </div>
    )
  }

  function onCloseModalRequested() {
    setModalIsOpen(false)
    setModalImage(null)
  }

  function renderFoodImageModal() {
    const tagsElement =
      modalTags &&
      modalTags.map((x, i) => (
        <span key={i.toString()} style={{ fontWeight: 'normal' }}>
          {x}
          {i !== modalTags.length - 1 && ', '}
        </span>
      ))
    const macro =
      modalFoodEntry && modalFoodEntry.macro ? modalFoodEntry.macro : {}
    const servings = (modalFoodEntry && modalFoodEntry.servings) || 1
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '90%',
          alignItems: 'center',
        }}
      >
        <p className={Styles.closeModalBtn} onClick={onCloseModalRequested}>
          X
        </p>
        <h4 style={{ color: 'black', textAlign: 'center' }}>{modalHeader}</h4>
        <h4
          style={{
            color: Constants.primaryTheme,
            textAlign: 'center',
            marginTop: 10,
          }}
        >
          {modalFoodEntry && modalFoodEntry.name}
        </h4>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p style={{ color: 'rgba(255, 170, 0, 1)' }}>Calories: </p>
            <p style={{ color: 'rgba(255, 170, 0, 1)', marginLeft: 10 }}>
              {macro.calories
                ? `${Number(macro.calories) * Number(servings)} kcal`
                : '- kcal'}
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p style={{ color: 'rgba(11, 148, 217, 1)' }}>Carbohydrates: </p>
            <p style={{ color: 'rgba(11, 148, 217, 1)', marginLeft: 10 }}>
              {macro.carbs
                ? `${Number(macro.carbs) * Number(servings)} g`
                : '- g'}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p style={{ color: 'rgba(112, 103, 207, 1)' }}>Protein: </p>
            <p style={{ color: 'rgba(112, 103, 207, 1)', marginLeft: 10 }}>
              {macro.protein
                ? `${Number(macro.protein) * Number(servings)} g`
                : '- g'}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p style={{ color: 'rgba(239, 118, 122, 1)' }}>Fat: </p>
            <p style={{ color: 'rgba(239, 118, 122, 1)', marginLeft: 10 }}>
              {macro.fat ? `${Number(macro.fat) * Number(servings)} g` : '- g'}
            </p>
          </div>
        </div>
        <p
          style={{
            fontWeight: 'bold',
            marginTop: 10,
            marginBottom: 20,
            width: '100%',
            textAlign: 'center',
          }}
        >
          Tags: {tagsElement}
        </p>
        <img src={modalImage} style={{ height: 300, width: 500 }} />
      </div>
    )
  }

  function renderDates() {
    const { nutritionData } = props
    if (!nutritionData || nutritionData.length === 0) return null
    console.log('got nutrition data', nutritionData)
    const weekData = nutritionData[weekDataIndex]
    const lastDate = weekData[weekData.length - 1].date
    const startDate = weekData[0].date
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        {weekDataIndex !== 3 && (
          <p
            onClick={() => setWeekDataIndex(weekDataIndex + 1)}
            style={{
              fontSize: 18,
              marginRight: 10,
              color: 'blue',
              cursor: 'pointer',
            }}
          >
            {'<<'}
          </p>
        )}
        <p>{moment(startDate).format('MMM Do (dddd)')}</p>
        <p style={{ marginLeft: 4, marginRight: 4 }}>-</p>
        <p>{moment(lastDate).format('MMM Do (dddd)')}</p>
        {weekDataIndex !== 0 && (
          <p
            onClick={() => setWeekDataIndex(weekDataIndex - 1)}
            style={{
              fontSize: 18,
              marginLeft: 10,
              color: 'blue',
              cursor: 'pointer',
            }}
          >
            {'>>'}
          </p>
        )}
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '10px 40px',
        maxWidth: '100%',
      }}
    >
      <h4
        data-tip
        data-for="header"
        style={{
          color: Constants.primaryTheme,
          width: '100%',
          textAlign: 'center',
        }}
      >
        Nutrition
      </h4>
      <ReactTooltip
        id="header"
        textColor="black"
        backgroundColor={Constants.contentBackground}
        effect="solid"
        className={Styles.headerHoverWrapper}
      >
        {renderTooltipContent()}
      </ReactTooltip>

      {renderDates()}
      {renderTable()}

      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={onCloseModalRequested}
        style={customFoodImageModalStyle}
        contentLabel="Modal"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
          }}
        >
          {renderFoodImageModal()}
        </div>
      </Modal>
    </div>
  )
}

const Sleep = (props) => {
  function calculateAverageSleep(vitals) {
    let total = 0
    let denom = 0
    vitals.forEach((each) => {
      if (each.sleep && each.sleep > 0) {
        total += Number(each.sleep)
        denom += 1
      }
    })

    const avg = denom > 0 ? (total / denom).toFixed(1) : 'N/A'
    return avg
  }

  function renderSleepChart() {
    const graphWidth = window.innerWidth / 3.1
    const chartData = props.vitalsProgressData || []
    return (
      <LineChart
        width={graphWidth}
        height={350}
        data={chartData}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Legend verticalAlign="top" />
        <Line
          dot={false}
          name="Sleep Duration(h)"
          type="monotone"
          dataKey="sleep"
          stroke={Constants.graphLineOne}
        />
        <XAxis
          dataKey="date"
          tickFormatter={(tickItem) => moment(tickItem).format('D-MMM')}
        />
        <YAxis domain={[0, 14]} />
        <Tooltip />
      </LineChart>
    )
  }

  function renderContent() {
    const { patientCore } = props
    let sleepStatement = null
    const statements = [
      'Have trouble falling asleep',
      'Wake up in the middle of the night',
    ]
    if (patientCore && patientCore.sleep) {
      sleepStatement = statements.includes(patientCore.sleep.statement)
        ? 'Yes'
        : 'No'
    }

    return (
      <div
        style={{
          width: '100%',
          borderRadius: 4,
          backgroundColor: Constants.contentBackground,
          padding: '10px 14px',
        }}
      >
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>Type</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>
            {patientCore.sleep && patientCore.sleep.habits}
          </p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>Average</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>
            {calculateAverageSleep(props.vitalsProgressData)} hrs past month
          </p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>Sleep Goal</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>
            {patientCore.sleep && patientCore.sleep.goal} hrs
          </p>
        </div>

        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>
            Highest energy during these times
          </p>
          <span>:</span>
          <p className={Styles.contentRowValue}>
            {patientCore.energy && patientCore.energy.timings}
          </p>
        </div>

        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>Trouble sleeping at night</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>{sleepStatement}</p>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '10px 40px',
      }}
    >
      <h4 data-tip data-for="header" style={{ color: Constants.primaryTheme }}>
        Sleep
      </h4>
      <ReactTooltip
        id="header"
        textColor="black"
        backgroundColor={Constants.contentBackground}
        effect="solid"
        className={Styles.headerHoverWrapper}
      >
        {renderContent()}
      </ReactTooltip>
      <div className={Styles.row} style={{ marginTop: 20 }}>
        <div style={{ width: '50%' }}>
          <div
            className={Styles.chartWrapper}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center',
                fontSize: 17,
                color: Constants.primaryTheme,
              }}
            >
              Sleep
            </p>
            {renderSleepChart()}
          </div>
        </div>
      </div>
    </div>
  )
}

const Physical = (props) => {
  const BMI_RANGES = [
    [0, 18.4],
    [18.5, 24.9],
    [25, 29.9],
    [30, 100],
  ]

  function getChart(lines, domain) {
    const graphWidth = window.innerWidth / 3.1
    const chartData = props.vitalsProgressData || []
    return (
      <LineChart
        width={graphWidth}
        height={350}
        data={chartData}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Legend verticalAlign="top" />
        {lines}
        <XAxis
          dataKey="date"
          tickFormatter={(tickItem) => moment(tickItem).format('D-MMM')}
        />
        <YAxis domain={domain} />
        <Tooltip />
      </LineChart>
    )
  }

  function renderStepsChart() {
    const lines = []
    lines.push(
      <Line
        dot={false}
        name="Steps"
        type="monotone"
        dataKey="steps"
        stroke={Constants.graphLineOne}
      />,
    )
    return getChart(lines, [0, 'dataMax + 2000'])
  }

  function renderContent() {
    const { patientCore } = props
    const bmiValues = Helpers.calculateBMIValues(
      patientCore.height,
      patientCore.weight_update || patientCore.weight,
      patientCore.gender,
      moment().diff(moment(patientCore.dob, 'DD/MM/YYYY'), 'years'),
    )
    let bmiRemark
    if (bmiValues.bmi >= BMI_RANGES[0][0] && bmiValues.bmi <= BMI_RANGES[0][1])
      bmiRemark = 'Underweight'
    else if (
      bmiValues.bmi >= BMI_RANGES[1][0] &&
      bmiValues.bmi <= BMI_RANGES[1][1]
    )
      bmiRemark = 'Normal/Healthy'
    else if (
      bmiValues.bmi >= BMI_RANGES[2][0] &&
      bmiValues.bmi <= BMI_RANGES[2][1]
    )
      bmiRemark = 'Overweight'
    else bmiRemark = 'Obese'

    const exercises =
      patientCore && patientCore.exercises ? patientCore.exercises : []
    const activities = exercises.map((x, index) => (
      <span key={index.toString()}>
        {x}
        {index === exercises.length - 1 ? '' : ', '}
      </span>
    ))
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: Constants.contentBackground,
          padding: '10px 14px',
        }}
      >
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>Physically able to exercise</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>
            {patientCore && patientCore.exercise_style
              ? patientCore.exercise_style.ability
              : 'N/A'}
          </p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>Activity level</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>
            {patientCore && patientCore.exercise_style
              ? patientCore.exercise_style.style
              : 'N/A'}
          </p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>Preferred Activities</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>{activities}</p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>Weight</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>
            {patientCore.weight_update || patientCore.weight} lbs
          </p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>BMI</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>
            {bmiValues.bmi} ({bmiRemark})
          </p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey}>Body Fat</p>
          <span>:</span>
          <p className={Styles.contentRowValue}>{bmiValues.bfp}%</p>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '10px 40px',
      }}
    >
      <h4 data-tip data-for="header" style={{ color: Constants.primaryTheme }}>
        Physical
      </h4>
      <ReactTooltip
        id="header"
        textColor="black"
        backgroundColor={Constants.contentBackground}
        effect="solid"
        className={Styles.headerHoverWrapper}
      >
        {renderContent()}
      </ReactTooltip>
      <div className={Styles.row} style={{ marginTop: 20 }}>
        <div style={{ width: '50%' }}>
          <div
            className={Styles.chartWrapper}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center',
                fontSize: 17,
                color: Constants.primaryTheme,
              }}
            >
              Activity
            </p>
            {renderStepsChart()}
          </div>
        </div>
      </div>
    </div>
  )
}

const Behavioral = (props) => {
  function getChart(chartData, lines, domain, depressionOrAnxiety) {
    const graphWidth = window.innerWidth / 3.1
    return (
      <LineChart
        width={graphWidth}
        height={350}
        data={chartData}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Legend verticalAlign="top" />
        {lines}
        <XAxis
          dataKey="created_at"
          tickFormatter={(tickItem) => moment(tickItem).format('D-MMM')}
        />
        <YAxis domain={domain} />
        {!depressionOrAnxiety ? (
          <Tooltip />
        ) : (
          <Tooltip
            content={({ active, payload, label }) => {
              const date = moment(label).format('D-MMM')
              let depressionScoreAndRemark
              let anxietyScoreAndRemark
              if (depressionOrAnxiety === 'depression')
                depressionScoreAndRemark =
                  (payload &&
                    payload.length > 0 &&
                    getDepressionScoreAndRemark(payload[0].payload)) ||
                  {}
              else
                anxietyScoreAndRemark =
                  (payload &&
                    payload.length > 0 &&
                    getAnxietyScoreAndRemark(payload[0].payload)) ||
                  {}
              return (
                <div
                  className={Styles.chartTooltip}
                  style={{ height: 120, width: 300 }}
                >
                  {depressionOrAnxiety === 'depression' ? (
                    <div style={{ width: '100%', padding: '0px 5px' }}>
                      <p>{depressionScoreAndRemark.date}</p>
                      <p
                        style={{
                          textAlign: 'left',
                          fontWeight: 'bold',
                          width: '100%',
                        }}
                      >
                        Depression(PHQ-9):{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 5 }}>
                          {depressionScoreAndRemark.score}
                        </span>
                      </p>
                      <p
                        style={{
                          textAlign: 'left',
                          fontWeight: 'bold',
                          width: '100%',
                        }}
                      >
                        Status:{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 5 }}>
                          {depressionScoreAndRemark.remark}
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div style={{ width: '100%', padding: '0px 5px' }}>
                      <p>{anxietyScoreAndRemark.date}</p>
                      <p
                        style={{
                          textAlign: 'left',
                          fontWeight: 'bold',
                          width: '100%',
                        }}
                      >
                        Anxiety(GAD-7):{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 5 }}>
                          {anxietyScoreAndRemark.score}
                        </span>
                      </p>
                      <p
                        style={{
                          textAlign: 'left',
                          fontWeight: 'bold',
                          width: '100%',
                        }}
                      >
                        Status:{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 5 }}>
                          {anxietyScoreAndRemark.remark}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              )
            }}
          />
        )}
      </LineChart>
    )
  }

  function calculateRelationshipScore(relationships) {
    let totalRelationshipPoints = 0
    let totalRelations = 0
    Object.values(relationships).forEach((score) => {
      if (typeof score === 'number') {
        totalRelationshipPoints += score
        totalRelations += 1
      } else {
        console.log('ignoring the relatiohsjp')
      }
    })
    const score =
      totalRelations > 0
        ? (totalRelationshipPoints / totalRelations) * 10
        : 'N/A'
    return score
  }

  function renderMoodStressChart() {
    const lines = []
    lines.push(
      <Line
        dot={false}
        name="Mood"
        type="monotone"
        dataKey="mood"
        stroke={Constants.graphLineOne}
      />,
    )
    lines.push(
      <Line
        dot={false}
        name="Stress"
        type="monotone"
        dataKey="stress"
        stroke={Constants.graphLineTwo}
      />,
    )
    const chartData = props.progressData.progress || []
    return getChart(chartData, lines, [0, 10])
  }

  function renderDepressionChart() {
    const lines = []
    const data = props.behavioralData ? props.behavioralData.depression : []
    lines.push(
      <Line
        dot={false}
        name="Depression Score"
        type="monotone"
        dataKey="phq9_score"
        stroke={Constants.graphLineOne}
      />,
    )
    lines.push(
      <ReferenceArea
        y1={0}
        y2={4}
        fill={Constants.graphReferenceColors[0]}
        fillOpacity={0.1}
      />,
    )
    lines.push(
      <ReferenceArea
        y1={4}
        y2={9}
        fill={Constants.graphReferenceColors[1]}
        fillOpacity={0.1}
      />,
    )
    lines.push(
      <ReferenceArea
        y1={9}
        y2={14}
        fill={Constants.graphReferenceColors[2]}
        fillOpacity={0.1}
      />,
    )
    lines.push(
      <ReferenceArea
        y1={14}
        y2={19}
        fill={Constants.graphReferenceColors[3]}
        fillOpacity={0.1}
      />,
    )
    lines.push(
      <ReferenceArea
        y1={19}
        fill={Constants.graphReferenceColors[4]}
        fillOpacity={0.1}
      />,
    )
    return getChart(data, lines, [0, 27], 'depression')
  }

  function renderAnxietyChart() {
    const lines = []
    const data = props.behavioralData ? props.behavioralData.anxiety : []
    lines.push(
      <Line
        dot={false}
        name="Anxiety Score"
        type="monotone"
        dataKey="anxietyScore"
        stroke={Constants.graphLineOne}
      />,
    )
    lines.push(
      <ReferenceArea
        y1={0}
        y2={4}
        fill={Constants.graphReferenceColors[0]}
        fillOpacity={0.1}
      />,
    )
    lines.push(
      <ReferenceArea
        y1={4}
        y2={9}
        fill={Constants.graphReferenceColors[1]}
        fillOpacity={0.1}
      />,
    )
    lines.push(
      <ReferenceArea
        y1={9}
        y2={14}
        fill={Constants.graphReferenceColors[2]}
        fillOpacity={0.1}
      />,
    )
    lines.push(
      <ReferenceArea
        y1={14}
        fill={Constants.graphReferenceColors[4]}
        fillOpacity={0.1}
      />,
    )
    return getChart(data, lines, [0, 24], 'anxiety')
  }

  function getAnxietyScoreAndRemark(screening) {
    if (!screening || Object.keys(screening).length === 0)
      return { score: 'N/A', remark: '-', date: '-' }

    let toReturn
    const date = moment(screening.created_at).format('MMM DD YYYY')
    if (screening.anxietyScore <= 4)
      toReturn = {
        score: screening.anxietyScore,
        remark: 'No Anxiety',
        date,
      }
    else if (screening.anxietyScore >= 5 && screening.anxietyScore <= 9)
      toReturn = {
        score: screening.anxietyScore,
        remark: 'Mild Anxiety',
        date,
      }
    else if (screening.anxietyScore >= 10 && screening.anxietyScore <= 14)
      toReturn = {
        score: screening.anxietyScore,
        remark: 'Moderate Anxiety',
        date,
      }
    else if (screening.anxietyScore >= 15)
      toReturn = {
        score: screening.anxietyScore,
        remark: 'Severe Anxiety',
        date,
      }
    else toReturn = { score: 'N/A', remark: '-', date: '-' }

    return toReturn
  }

  function getDepressionScoreAndRemark(screening) {
    if (!screening || Object.keys(screening).length === 0)
      return { score: 'N/A', remark: '-', date: '-' }

    let toReturn = {}
    const date = moment(screening.created_at).format('MMM DD YYYY')
    if (screening.phq9_score <= 4)
      toReturn = {
        score: screening.phq9_score,
        remark: 'No Depression',
        date,
      }
    else if (screening.phq9_score >= 5 && screening.phq9_score <= 9)
      toReturn = {
        score: screening.phq9_score,
        remark: 'Mild Depression',
        date,
      }
    else if (screening.phq9_score >= 10 && screening.phq9_score <= 14)
      toReturn = {
        score: screening.phq9_score,
        remark: 'Moderate Depression',
        date,
      }
    else if (screening.phq9_score >= 15 && screening.phq9_score <= 19)
      toReturn = {
        score: screening.phq9_score,
        remark: 'Moderately Severe Depression',
        date,
      }
    else if (screening.phq9_score >= 20 && screening.phq9_score <= 27)
      toReturn = {
        score: screening.phq9_score,
        remark: 'Severe Depression',
        date,
      }
    else toReturn = { score: 'N/A', remark: '-', date: '-' }

    if (screening.suicidal > 0)
      toReturn = {
        score: screening.phq9_score,
        remark: 'Severe Depression/Suicidal',
        date,
      }

    return toReturn
  }

  function renderScores() {
    const data = props.patientWellnessScreening
    const date = data ? moment(data.created_at) : ''
    const stress =
      data && data.stress
        ? data.stress
        : { about: [], events: [], management: [] }
    const relationships = data && data.relationships ? data.relationships : []
    const depressionScreens =
      props.behavioralData && props.behavioralData.depression
        ? props.behavioralData.depression
        : []
    const lastDepressionScreening =
      depressionScreens[depressionScreens.length - 1] || {}
    const depressionScoreAndRemark = getDepressionScoreAndRemark(
      lastDepressionScreening,
    )

    const anxietyScreens =
      props.behavioralData && props.behavioralData.anxiety
        ? props.behavioralData.anxiety
        : []
    const lastAnxietyScreening = anxietyScreens[anxietyScreens.length - 1] || {}
    const anxietyScoreAndRemark = getAnxietyScoreAndRemark(lastAnxietyScreening)

    const stress_about = stress.about.map((x, index) => (
      <span key={index.toString()}>
        {x}
        {index === data.stress.about.length - 1 ? '' : ', '}
      </span>
    ))
    const stress_events = stress.events.map((x, index) => (
      <span key={index.toString()}>
        {x}
        {index === data.stress.events.length - 1 ? '' : ', '}
      </span>
    ))
    const stress_management = stress.management.map((x, index) => (
      <span key={index.toString()}>
        {x}
        {index === data.stress.management.length - 1 ? '' : ', '}
      </span>
    ))
    return (
      <div
        style={{
          width: 400,
          backgroundColor: Constants.contentBackground,
          padding: '10px 14px',
        }}
      >
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: '30%' }}>
            Depression Score(PHQ-9)
          </p>
          <span>:</span>
          <p
            className={Styles.contentRowValue}
            style={{ width: '60%' }}
          >{`${depressionScoreAndRemark.score} (${depressionScoreAndRemark.remark}), ${depressionScoreAndRemark.date}`}</p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: '30%' }}>
            Anxiety Score(GAD-7)
          </p>
          <span>:</span>
          <p
            className={Styles.contentRowValue}
            style={{ width: '60%' }}
          >{`${anxietyScoreAndRemark.score} (${anxietyScoreAndRemark.remark}), ${anxietyScoreAndRemark.date}`}</p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: '30%' }}>
            Relationship Score
          </p>
          <span>:</span>
          {data && data.relationships ? (
            <p className={Styles.contentRowValue} style={{ width: '60%' }}>
              {calculateRelationshipScore(data.relationships.quality)}%
              <span style={{ fontSize: 12 }}>
                , screening taken on {date.format('MMM Do YYYY')}
              </span>
            </p>
          ) : (
            <p className={Styles.contentRowValue} style={{ width: '60%' }}>
              Screening not completed
            </p>
          )}
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: '30%' }}>
            Key Stressors
          </p>
          <span>:</span>
          <p className={Styles.contentRowValue} style={{ width: '60%' }}>
            {stress_about}
          </p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: '30%' }}>
            Past stressful events
          </p>
          <span>:</span>
          <p className={Styles.contentRowValue} style={{ width: '60%' }}>
            {stress_events}
          </p>
        </div>
        <div className={Styles.contentRow}>
          <p className={Styles.contentRowKey} style={{ width: '30%' }}>
            Stress relievers
          </p>
          <span>:</span>
          <p className={Styles.contentRowValue} style={{ width: '60%' }}>
            {stress_management}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '10px 40px',
      }}
    >
      {/* <h4 data-tip data-for="header" style={{ color: Constants.primaryTheme }}>
        Behavioral
      </h4>
      <ReactTooltip
        id="header"
        textColor="black"
        backgroundColor={Constants.contentBackground}
        effect="solid"
        className={Styles.headerHoverWrapper}
      >
        {renderScores()}
      </ReactTooltip>
      <div className={Styles.row} style={{ marginTop: 20 }}>
        <div style={{ width: '50%' }}>
          <div
            className={Styles.chartWrapper}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center',
                fontSize: 17,
                color: Constants.primaryTheme,
              }}
            >
              Mental Health
            </p>
            {renderMoodStressChart()}
          </div>
        </div>
      </div> */}

      <div className={Styles.row} style={{ marginTop: 60 }}>
        <div style={{ width: '50%' }}>
          <div
            className={Styles.chartWrapper}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center',
                fontSize: 17,
                color: Constants.primaryTheme,
              }}
            >
              Depression (PHQ-9)
            </p>
            {renderDepressionChart()}
          </div>
        </div>

        <div style={{ width: '50%' }}>
          <div
            className={Styles.chartWrapper}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center',
                fontSize: 17,
                color: Constants.primaryTheme,
              }}
            >
              Anxiety (GAD-7)
            </p>
            {renderAnxietyChart()}
          </div>
        </div>
      </div>
    </div>
  )
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(1)

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  function getTooltip(label) {
    return (
      <ReactTooltip
        id={label}
        textColor="black"
        backgroundColor={Constants.primaryThemeMedium}
        effect="solid"
      >
        <p style={{ color: 'white', fontSize: 18 }}>{label}</p>
      </ReactTooltip>
    )
  }

  function getTab(name, index, icon, fontAwesome) {
    return (
      <div
        data-tip
        data-for={name}
        onClick={() => {
          setActiveTab(index)
          props.onTabSelected(index)
        }}
        className={Styles.rowIcon}
        style={{
          backgroundColor:
            activeTab === index
              ? Constants.primaryTheme
              : Constants.primaryThemeDark,
        }}
      >
        {fontAwesome ? (
          <FontAwesomeIcon
            icon={icon}
            style={{ color: 'white', fontSize: 30 }}
          />
        ) : (
          <img src={icon} style={{ height: '75%', width: '75%' }} />
        )}
        {getTooltip(name)}
      </div>
    )
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
        padding: '20px 40px',
      }}
    >
      {getTab('Vitals Progress', 1, PulseIcon, false)}
      {getTab('Symptoms Progress', 2, faHospitalUser, true)}
      {getTab('Behavioral', 3, faBrain, true)}
      {getTab('Physical', 4, faRunning, true)}
      {getTab('Sleep', 5, faCloudMoon, true)}
      {getTab('Nutrition', 6, faAppleAlt, true)}
      {getTab('Medication Therapy Management', 7, faCapsules, true)}
      {getTab('Gastrointestinal System', 8, StomachIcon, false)}
    </div>
  )
}

const mapStateToProps = (state /* , ownProps */) => ({
  patient: state.patientReducer.patient,
  loggedIn: state.userReducer.loggedIn,
  patientCore: state.patientReducer.patientCore,
  token: state.authReducer.token,
  color: state.patientReducer.color,
  mongoUser: state.userReducer.mongoUser,
  patientWellnessScreening: state.patientReducer.patientWellnessScreening,
  patientProgress: state.patientReducer.patientProgress,
  medications: state.patientReducer.medications,
  conditions: state.patientReducer.conditions,
  patientCalculatedProgress: state.patientReducer.patientCalculatedProgress,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, null)(Progress)
