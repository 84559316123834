import React, { useState } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import FancyField from 'react-fancy-field'
import AilaLogo from '../../assets/images/aila_logo.png'
import { Link, useHistory } from 'react-router-dom'
import Config from '../../config'
import firebase from '../../services/firebase'
import { toast, ToastContainer } from 'react-toastify'

const axios = require('axios')

export default function AilaWebForgotPassword() {
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [token, setToken] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [error, setError] = useState(false)
  const [errorString, setErrorString] = useState('')
  const history = useHistory()

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  // Token from backend
  const getAuthToken = async (currentUser) => {
    if (!currentUser) {
      console.log('Invalid user')
      return { error: true }
    }

    let authUrl = `${Config.BACKEND_URL}authorization`
    try {
      let response = await axios({
        method: 'get',
        url: authUrl,
        headers: { x_firebase_id: currentUser.uid },
      })
      if (response.status === 200) {
        setToken(response.data.token)
        return { error: false }
      } else {
        console.log('error', response)
        return { error: true }
      }
    } catch (err) {
      console.log('error when getting auth token', err)
      return { error: true, errorString: 'error when getting auth token' }
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  // Firebase Login
  async function login(email, password) {
    var user = await firebase.auth().signInWithEmailAndPassword(email, password)
    var error = false
    if (user.user.uid) {
      setCurrentUser(user.user.uid)
      console.log('Logged in PATIENT')
    } else {
      error = true
      console.log('ERROR LOGGING IN')
    }
    return { userData: user, errorLogin: error }
  }

  async function forgotPassword(email) {
    if (!email || email.length === 0 || !validateEmail(email)) {
      setError(true)
      setErrorString('Please enter a valid email address')
      console.log('EMAIL ERROR')
      return
    }

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        toast.success('Password reset mail sent .Please check your email...')
        // alert('Password reset mail sent .Please check your email...')
      })
      .catch(function (e) {
        console.log(e)
      })
  }

  const onLoginPressed = async () => {
    if (!email || email.length === 0 || !validateEmail(email)) {
      setError(true)
      setErrorString('Please enter a valid email address')
      console.log('EMAIL ERROR')
      return
    }

    if (!pwd || pwd.length < 6) {
      setError(true)
      setErrorString('The password must be at least 6 characters')
      console.log('PWD ERROR')
      return
    }

    try {
      let { userData, errorLogin } = await login(email, pwd)
      if (!errorLogin) {
        history.push('/wix/aila-web-questions', {
          patient_id: userData.user.uid,
          email_id: email,
        })
        let tokenResp = await getAuthToken(userData.user)
        if (tokenResp.error === true) {
          setError(true)
          setErrorString('Token Error')
        } else {
          console.log('Success token auth')
        }
      } else {
        setError(true)
        setErrorString('You Do Not Have Access!!')
      }
    } catch (err) {
      setError(true)
      setErrorString(err.message)
      console.log(err)
      onFinishFailed(err.message)
    }
  }

  return (
    <div
      className={GlobalStyles.container}
      style={{
        overflow: 'hidden',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          margin: '50px',
        }}
      >
        <img
          alt="Aila Health"
          src={AilaLogo}
          style={{
            width: '15vh',
            height: '15vh',
            alignSelf: 'flex-start',
          }}
        />
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <div
            style={{
              width: '100%',
              alignSelf: 'center',
              marginTop: '5vh',
            }}
          >
            <p
              style={{
                fontSize: '30px',
                margin: 'auto',
                color: values.primaryThemeDark,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              Reset your password to your
              <br />
              account
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              position: 'relative',
              marginTop: '8vh',
              justifyContent: 'space-evenly',
            }}
          >
            <FancyField
              value={email}
              style={{ width: '500px' }}
              label="Email"
              required={false}
              name="emailInput"
              onChange={(val) => setEmail(val)}
              placeholder="Enter your email... "
            />
          </div>

          <p
            style={{
              marginTop: 20,
              fontSize: 12,
              color: 'red',
              visibility: error ? 'visible' : 'hidden',
              textAlign: 'center',
            }}
          >
            {errorString}
          </p>

          <Button
            onClick={() => forgotPassword(email)}
            className={GlobalStyles.button}
            style={{ marginTop: '6vh', width: 200, alignSelf: 'center' }}
            variant="primary"
          >
            {'Reset now'}
          </Button>

          <Link
            to={{
              pathname: `/signup/patient`,
            }}
          >
            <p
              style={{
                fontSize: '18px',
                marginTop: '8vh',
                color: values.primaryThemeDark,
                textDecoration: 'underline',
                cursor: 'pointer',
                textAlign: 'center',
              }}
            >
              I dont’t have an account
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}
