import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../../config'

class ChargeDetailsTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventId: props.eventId,
      totalTimeSpent: null,
      cpts: null,
      icds: null,
      billedAmount: null,
      allowedAmount: null,
      adjustedAmount: null,
      notCovered: null,
      deductible: null,
      copay: null,
      coinsurance: null,
      amountPaid: null,
    }
  }

  componentDidMount() {
    console.log('Mounted')
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}tooltip/get/user-details`,
      // url: `http://localhost:15000/tooltip/get/event-details`,
      params: {
        eventId: this.props.eventId,
      },
    }).then((res) => {
      if (res.data.success === false) {
        this.setState({ phone: 'ERRRO', loading: false })
        return console.log('error getting patient details')
      }
      this.setState({
        // phone: res.data?.data?.phone_number,
        // dob: res.data?.data?.dob,
        // cc: res.data?.data?.stripe?.customer_id,
        loading: false,
      })
    })
  }

  render() {
    if (this.state.loading) return <div>Loading...</div>
    return (
      <div>
        <div>Total Time Spent:{this.state.totalTimeSpent}</div>
        <div>CPT (s):{this.state.cpts}</div>
        <div>ICDs (s):{this.state.icds}</div>
        <div>Billed Amount:{this.state.billedAmount}</div>
        <div>Allowed Amount:{this.state.allowedAmount}</div>
        <div>Adjusted amount:{this.state.adjustedAmount}</div>
        <div>Not Covered/ Discount:{this.state.notCovered}</div>
        <div>Deductible:{this.state.deductible}</div>
        <div>Copay:{this.state.copay}</div>
        <div>Coinsurance: {this.state.coinsurance}</div>
        <div>Amount Paid by Plan:{this.state.amountPaid}</div>
      </div>
    )
  }
}

export default ChargeDetailsTooltip
