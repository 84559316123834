import React, { Component } from 'react'
import 'react-chat-elements/dist/main.css'
import GlobalStyles from '../../../styles/global.module.scss'
import Constants from '../../../../values'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import Config from '../../../../config'
import Styles from '../styles/uploadDocs.module.scss'
import { Link, NavLink } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faShare,
  faTrash,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'
import DateTimePicker from 'react-datetime-picker'
import Select from 'react-select'
import firebase from '../../../../services/firebase'
import FileViewer from '../../../../components/pdf/FileViewer'

import {
  getUnreadMessages,
  addPatientLP,
  addRpmPatient,
  setPatientCoreData,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
} from '../../../../redux/actions'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import { data } from 'branch-sdk'

const axios = require('axios')

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const sendFaxModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '40vw',
    height: '60vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class UploadDocs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDoc: false,
      modalLoading: false,
      loading: true,
      testPDF: '',
      testFileType: '',
      faxList: [],
      outboxList: [],
      showInbox: true, // I don't see where this is being set to a different value
      sendModal: false,
      modalSchedule: new Date(),
      modalError: false,
      modalFileName: '',
      fromFirebase: false,
      modalFileType: '',
      modalSenderEmail: '',
      modalRecieverFax: null,
      patientListOptions: [],
      // Initially, no file is selected
      selectedFile: null,
      forwardFax: false,
      superUser: false,

      careteam: [],
      careTeamNames: [],
      searchText: '',
      careTeamImgs: [],
      typeOfUpload: {},
    }
    this.getProviderDetails = this.getProviderDetails.bind(this)
    this.getInbox = this.getInbox.bind(this)
    this.getOutbox = this.getOutbox.bind(this)
    this.onModalHide = this.onModalHide.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.onSendPressed = this.onSendPressed.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.getProviderDetails()
    this.getInbox()
    this.getOutbox()
    this.preparePatientList()
  }

  async preparePatientList(data) {
    const { patientList } = this.props
    if (!patientList) return
    let patientListData = data || patientList
    const list = []
    Object.keys(patientListData).forEach((rpmColor) => {
      const patientsColorList = patientListData[rpmColor]
      patientsColorList.forEach((patient) => {
        list.push({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.uid,
        })
      })
    })

    this.setState({ patientListOptions: list, loading: false })
  }

  getPatientList() {
    // this.setState({loading: true})
    const { token, adminId, firebaseUser } = this.props
    const self = this
    const url =
      `${Config.BACKEND_URL}doctor/patients/list` +
      (self.state.superUser ? '?access=admin' : '')
    this.setState({ loading: true })
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          const { data } = response
          let total = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })
          self.props.addPatientList(data)
          self.preparePatientList(data)
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
        self.setState({ loading: false })
      })
  }

  getProviderDetails() {
    const { mongoUser, patient, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    this.setState({ loading: true })
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient.uid,
      },
    })
      .then(({ data }) => {
        if (data) {
          self.setState({ careteam: data })
          this.state.careteam.forEach((care) => {
            // if (care.doctor_id != mongoUser.firebase_id) {
            //   this.state.careTeamNames.push(
            //     `${care.first_name} ${care.last_name}`,
            //   )
            // }
            this.state.careTeamImgs.push({
              doctor_id: care.doctor_id,
              image_url: care.image_url,
              name: `${care.first_name} ${care.last_name}`,
            })
          })
        }
        // console.log('CARETEAM: ', this.state.careteam)
        // console.log('NAME OF CARETEAM: ', this.state.careTeamNames)
        // console.log('IMAGES OF CARETEAM: ', this.state.careTeamImgs)
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('error when getting messages from backend', err)
      })
  }

  //GET ALL SRFAX INBOX FAXES
  getInbox() {
    const { token, mongoUser, patient } = this.props
    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `upload/files`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
        patient_id: patient.uid,
      },
    }
    axios(config)
      .then(({ data }) => {
        console.log('INBOX:::', data)
        this.setState({ faxList: data }, () =>
          this.setState({ loading: false }),
        )
      })
      .catch((err) => {
        console.log('Error getting uploaded docs for the Patient ', err)
        toast.error('Could not fetch docs')
      })
  }

  //GET ALL SRFAX OUTBOX FAXES
  getOutbox() {
    const { token, mongoUser } = this.props

    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: Config.CHANGE_BACKEND_URL + `srfax/outbox`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log('OUTBOX::::', data.Result)
        this.setState({ outboxList: data.Result }, () =>
          this.setState({ loading: false }),
        )
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        toast.error('Could not fetch Faxes')
      })
  }

  saveFilesToMongo = (data) => {
    const { token, mongoUser, patient } = this.props

    let config = {
      method: 'post',
      url: Config.BACKEND_URL + `upload/files`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
      data: data,
    }
    axios(config)
      .then(({ data }) => {
        console.log('UPLOAD SEND RES::::', data)
        toast.success('File uploaded successfully')
        this.getInbox()
        this.getOutbox()
        this.onModalHide()
      })
      .catch((err) => {
        console.log(
          'Error sending uploads for the Patient ',
          err,
          err.errorMessage,
        )
        toast.error('Could not upload file')
      })
  }

  saveImageToFirebase = (uploadFile) => {
    const self = this
    const { token, mongoUser, patient } = this.props

    // setLoading(true)

    // licensedStates.forEach((license, index) => {
    // if (license.image) {
    const file = uploadFile
    // let temp = [...licensedStates]

    const storageRef = firebase.storage().ref()
    const uploadTask = storageRef
      .child(`user/documents/${patient.uid}/${file.name}`)
      .put(file)
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`${progress}%`)
      },
      function (err) {
        // Handle unsuccessful uploads
        console.log('error when uploading image', err)
        // self.saveUserToBackend(user)
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log(downloadURL)

          let result = {
            //   sSenderEmail: this.state.modalSenderEmail,
            //   sToFaxNumber: this.state.modalRecieverFax,
            fileName: self.state.modalFileName,
            fileType: self.state.modalFileType,
            fileContent: downloadURL,
            type: self.state.typeOfUpload?.label,
            uploadedBy: mongoUser.firebase_id,
            patient_id: patient.uid,
            active: true,
            firebase: true,
          }

          console.log('RESULT:::', result)
          self.saveFilesToMongo(result)

          // let newPair = { license_image: downloadURL }
          // temp[index] = { ...temp[index], ...newPair }
          // delete temp[index].image
          // delete temp[index].location
          // // console.log('temp:::', temp)
          // setLicensedStates(temp)
          // setLoading(false)

          // if (index === licensedStates.length - 1) saveUserToBackend(temp)

          // self.saveUserToBackend(user)
        })
      },
    )

    // })
  }

  renderFax = (fax) => {
    const { token, mongoUser } = this.props
    console.log(fax)

    this.setState({ modalLoading: true })

    this.setState(
      {
        testPDF: fax.fileContent,
        testFileType: fax.fileType,
        selectedFile: fax.fileContent,
        modalFileName: fax.fileName,
        fromFirebase: fax.firebase || false,
      },
      () => {
        this.setState({ modalLoading: false })
      },
    )

    // this.setState({ modalLoading: true })
    // let config = {
    //   method: 'get',
    //   url:
    //     Config.CHANGE_BACKEND_URL +
    //     `srfax/retrieve-fax?sFaxFileName=${FileName}&sDirection=${direction}`,
    //   headers: {
    //     Authorization: `JWT ${token}`,
    //     x_firebase_id: mongoUser.firebase_id,
    //   },
    // }
    // axios(config)
    //   .then(({ data }) => {
    //     // console.log(data.Result)
    //     this.setState(
    //       {
    //         testPDF: data.Result,
    //         selectedFile: data.Result,
    //         modalFileName: FileName,
    //       },
    //       () => {
    //         this.setState({ modalLoading: false })
    //       },
    //     )
    //   })
    //   .catch((err) => {
    //     console.log('Error getting Faxes content for the Patient ', err)
    //     toast.error('Could not fetch Faxes')
    //   })
  }

  onModalHide() {
    this.setState({
      openDoc: false,
      sendModal: false,
      forwardFax: false,
      modalSchedule: new Date(),
      modalError: false,
      testPDF: '',
      testFileType: '',
      modalFileName: '',
      modalSenderEmail: '',
      modalRecieverFax: null,
      // Initially, no file is selected
      selectedFile: null,
    })
  }
  // On file select (from the pop up)
  onFileChange(event) {
    // Update the state
    // console.log(event.target.files)
    if (event.target.files[0]) {
      console.log(event.target.files[0])

      this.setState({ selectedFile: event.target.files[0] })

      this.setState({
        modalFileName: event.target.files[0].name,
        modalFileType: event.target.files[0].type,
      })

      // const toBase64 = (file) =>
      //   new Promise((resolve, reject) => {
      //     const reader = new FileReader()
      //     reader.readAsDataURL(file)
      //     reader.onload = () => resolve(reader.result)
      //     reader.onerror = (error) => reject(error)
      //   })

      // toBase64(event.target.files[0])
      //   .then((data) => {
      //     // let result = data.substring(data.indexOf(', ') + 1)
      //     let result = data.split(',')[1]
      //     //   console.log(result)
      //     this.setState({ selectedFile: result })
      //   })
      //   .catch((err) => console.log(err))
      // console.log(await toBase64(event.target.files[0]))
    } else {
      this.setState({
        modalFileName: '',
        modalFileType: '',
        selectedFile: null,
      })
    }
  }

  onSendPressed() {
    const { token, mongoUser, patient } = this.props

    if (!this.state.selectedFile) {
      toast.warning('Please select file to upload')
      return
    }
    if (!this.state.typeOfUpload) {
      toast.warning('Please select type of upload')
      return
    }

    this.saveImageToFirebase(this.state.selectedFile)

    // let result = {
    //   //   sSenderEmail: this.state.modalSenderEmail,
    //   //   sToFaxNumber: this.state.modalRecieverFax,
    //   fileName: this.state.modalFileName,
    //   fileType: this.state.modalFileType,
    //   fileContent: this.state.selectedFile,
    //   type: this.state.typeOfUpload?.label,
    //   uploadedBy: mongoUser.firebase_id,
    //   patient_id: patient.uid,
    //   active: true,
    // }

    // console.log('RESULT:::', result)

    // let config = {
    //   method: 'post',
    //   url: !this.state.forwardFax
    //     ? Config.BACKEND_URL + `upload/files`
    //     : Config.BACKEND_URL +
    //       `srfax/forward?sDirection=${this.state.showInbox}`,
    //   headers: {
    //     Authorization: `JWT ${token}`,
    //     x_firebase_id: mongoUser.firebase_id,
    //   },
    //   data: result,
    // }
    // axios(config)
    //   .then(({ data }) => {
    //     console.log('UPLOAD SEND RES::::', data)
    //     toast.success('File uploaded successfully')
    //     this.getInbox()
    //     this.getOutbox()
    //     this.onModalHide()
    //   })
    //   .catch((err) => {
    //     console.log(
    //       'Error sending uploads for the Patient ',
    //       err,
    //       err.errorMessage,
    //     )
    //     toast.error('Could not upload file')
    //   })
  }

  sendFaxModal() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div
          style={{
            height: '90%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            onClick={() => this.onModalHide()}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            Upload Document
          </h4>
          {/*
          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Sender Email: </p>
            <input
              placeholder={'Please enter senders email address'}
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalSenderEmail}
              onChange={(e) =>
                this.setState({ modalSenderEmail: e.target.value })
              }
            />
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Recipient Fax: </p>
            <input
              placeholder={'Please enter recievers fax number'}
              className={Styles.addManualTextInput}
              type="number"
              value={this.state.modalRecieverFax}
              onChange={(e) =>
                this.setState({ modalRecieverFax: e.target.value })
              }
            />
          </div> */}
          {/* <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Schedule Date : </p>
            <DateTimePicker
              onChange={(val) => {
                this.setState({ modalSchedule: val, modalError: false })
              }}
              value={this.state.modalSchedule}
              className={Styles.addManualSelectInput}
            />
          </div> */}
          {!this.state.forwardFax && (
            <div className={Styles.addManualRow}>
              <p className={Styles.addManualQuestion}>Select file: </p>
              <input
                placeholder="Slect File to be Faxed"
                className={Styles.addManualSelectInput}
                type="file"
                onChange={this.onFileChange}
              />
            </div>
          )}

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>File Selected: </p>
            <input
              disabled
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalFileName}
            />
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Type: </p>
            <Select
              options={[
                { label: 'Imaging', value: 'Imaging' },
                { label: 'Labs', value: 'Labs' },
                { label: 'Consents', value: 'Consents' },
                { label: 'Prescriptions', value: 'Prescriptions' },
                {
                  label: 'Prior Authorizations',
                  value: 'Prior Authorizations',
                },
                { label: 'Patient Assistance', value: 'Patient Assistance' },
                { label: 'Medical Invoice', value: 'Medical Invoice' },
                { label: 'Others', value: 'Others' },
              ]}
              className={Styles.addSelectInput}
              autoBlur={true}
              onChange={(val) => {
                console.log(val)
                this.setState({ typeOfUpload: val })
              }}
              value={this.state.typeOfUpload}
              getOptionValue={(option) => option.label}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => this.onSendPressed()}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200, position: 'absolute', bottom: 20 }}
            variant="primary"
          >
            Upload
          </Button>
        </div>
      </div>
    )
  }

  renderDocuments(showInbox) {
    const { token, mongoUser } = this.props
    const faxes = showInbox ? this.state.faxList : this.state.outboxList

    const elements = faxes.map((fax, index) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '10px 0px',
        }}
      >
        <p
          style={{
            width: '5%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {index + 1}
        </p>
        <p
          style={{
            width: '17.5%',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {this.state.showInbox
            ? moment(fax.created_at).format('lll')
            : moment(fax.created_at).format('lll')}
        </p>

        <p
          style={{
            width: '20%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {this.state.careTeamImgs.map((e) =>
            e.doctor_id === fax.uploadedBy ? e.name : '',
          )}
        </p>
        <p
          style={{
            width: '30%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {fax.fileName}
        </p>

        <p
          style={{
            width: '20%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {fax.type}
        </p>

        <FontAwesomeIcon
          onClick={() => {
            this.setState({ openDoc: true })
            if (fax.pdf)
              this.setState({
                testPDF: fax.pdf,
                selectedFile: fax,
                modalFileName: fax.FileName,
              })
            else this.renderFax(fax)
          }}
          icon={faEye}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '20%',
            cursor: 'pointer',
          }}
        />
        {/* <FontAwesomeIcon
          onClick={() => {
            console.log('send fax')
            // this.setState({ forwardFax: true, sendModal: true })
            // this.renderFax(fax.FileName, this.state.showInbox)
          }}
          icon={faPaperPlane}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '10%',
            cursor: 'pointer',
          }}
        />
        <FontAwesomeIcon
          onClick={() => {
            console.log('delete fax')
            // this.setState({ forwardFax: true, sendModal: true })
            // this.renderFax(fax.FileName, this.state.showInbox)
          }}
          icon={faTrash}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '10%',
            cursor: 'pointer',
          }}
        /> */}
        {/*
        {this.state.showInbox && (
          <div style={{ width: '17.5%' }}>
            <Select
              placeholder="select patient"
              inputId="name"
              options={this.state.patientListOptions}
              value={
                fax.patient_id
                  ? this.state.patientListOptions.find(
                      (patient) => patient.value === fax.patient_id,
                    )
                  : []
              }
              isSearchable
              autoBlur
              onChange={(val) => {
                let config = {
                  method: 'put',
                  url: Config.BACKEND_URL + `srfax/patient`,
                  headers: {
                    Authorization: `JWT ${token}`,
                    x_firebase_id: mongoUser.firebase_id,
                  },
                  data: {
                    patient_id: val.value,
                    fax_id: fax._id,
                  },
                }
                axios(config)
                  .then(({ data }) => {
                    let tempFaxList = [...this.state.faxList]
                    tempFaxList[index].patient_id = val.value
                    this.setState({ faxList: tempFaxList }, () =>
                      this.setState({ loading: false }),
                    )
                  })
                  .catch((err) => {
                    console.log('Error getting Faxes for the Patient ', err)
                    toast.error('Problem while assigning the fax')
                  })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        )} */}
      </div>
    ))
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            borderBottom: '1px solid gray',
          }}
        >
          <h5
            style={{
              width: '5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Sl No.
          </h5>
          <h5
            style={{
              width: '17.5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            {/* Ahhh.... explain?*/}
            {this.state.showInbox ? `Uploaded Date` : `Uploaded Date`}
          </h5>
          <h5
            style={{
              width: '20%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Uploaded By
          </h5>
          <h5
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            File Name
          </h5>
          <h5
            style={{
              width: '20%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Upload Type
          </h5>
          {/* {!this.state.showInbox && (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Sender Email
            </h5>
          )}
          {this.state.showInbox ? (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              From FAX / Remote ID
            </h5>
          ) : (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Recipient Fax
            </h5>
          )} */}
          {/* <h5
            style={{
              width: '5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Pages
          </h5>
          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Status
          </h5> */}

          {/* {!this.state.showInbox && (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Reciever Fax
            </h5>
          )} */}

          <h5
            style={{
              width: '20%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            View
          </h5>
          {/* <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Forward
          </h5>
          {this.state.showInbox && (
            <h5
              style={{
                width: '17.5%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Assign
            </h5>
          )} */}
        </div>

        {elements}
      </div>
    )
  }

  render() {
    const { mongoUser, firebaseUser } = this.props
    let { superUser } = this.state
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          paddingBottom: '10px',
        }}
      >
        {/* <Header header={'Faxes'} /> */}

        <div
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            // justifyContent: 'center',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', position: 'absolute', right: 0 }}>
            <Button
              onClick={() => this.setState({ sendModal: true })}
              className={GlobalStyles.button}
              disabled={this.state.loading}
              style={{
                width: 200,
                marginRight: '2vw',
                right: 0,
                position: 'relative',
              }}
              variant="primary"
            >
              Upload
            </Button>
          </div>
        </div>

        {this.state.loading && (
          <div className={GlobalStyles.container} style={{ height: '100vh' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: '100%',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          </div>
        )}

        <div
          className={GlobalStyles.contentWrapper}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
            overflow: 'scroll',
            marginTop: '5%',
            // width: '100%',
          }}
        >
          {this.state.showInbox ? (
            this.state.faxList.length !== 0 ? (
              this.renderDocuments(this.state.showInbox)
            ) : (
              <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
                No uploaded documents
              </p>
            )
          ) : this.state.outboxList.length !== 0 ? (
            this.renderDocuments(this.state.showInbox)
          ) : (
            <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
              No uploaded documents
            </p>
          )}
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.openDoc}
          onRequestClose={() => this.onModalHide()}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          {
            this.state.modalLoading ? (
              <div
                className={GlobalStyles.container}
                style={{ height: '100vh' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '100%',
                    height: '100%',
                  }}
                >
                  <div className={GlobalStyles.loader} />
                </div>
              </div>
            ) : // <iframe
            //   style={{ height: '100%', width: '100%' }}
            //   src={`data:application/pdf;base64,${this.state.testPDF}`}
            // />

            this.state.fromFirebase ? (
              <FileViewer
                fileType={this.state.modalFileName.split('.').pop()}
                fileName={this.state.testPDF}
              />
            ) : (
              <FileViewer
                fileType={this.state.modalFileName.split('.').pop()}
                fileName={`data:${this.state.testFileType};base64,${this.state.testPDF}`}
              />
            )

            // this.state.testFileType === 'application/pdf' ||
            //   !this.state.testFileType ? (
            //   <iframe
            //     style={{ height: '100%', width: '100%' }}
            //     src={`data:application/pdf;base64,${this.state.testPDF}`}
            //   />
            // ) : (
            //   <div style={{ display: 'flex', justifyContent: 'center' }}>
            //     <img src={`data:image/png;base64,${this.state.testPDF}`} />
            //   </div>
            // )
          }
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.sendModal}
          onRequestClose={() => this.onModalHide()}
          style={sendFaxModalStyles}
          contentLabel="Modal"
        >
          {this.state.modalLoading ? (
            <div className={GlobalStyles.container} style={{ height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: '100%',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            </div>
          ) : (
            this.sendFaxModal()
          )}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,

    patient: state.patientReducer.patient,

    token: state.authReducer.token,

    patientList: state.patientReducer.patientList,

    mongoUser: state.userReducer.mongoUser,

    adminId: state.userReducer.adminId,

    patientCore: state.patientReducer.patientCore,

    unreadNotifications: state.userReducer.unreadNotifications,

    allNotifications: state.userReducer.allNotifications,

    firebaseUser: state.userReducer.firebaseUser,
  }
}

const mapDispatchToProps = {
  getUnreadMessages,
  addPatientLP,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocs)
