import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Config from '../../config'
import {
  addPatientList,
  fetchPatientCarePlanData,
  setAppointmentNotesData,
  toggleSidebar,
} from '../../redux/actions'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/cms.module.scss'
import moment from 'moment'
import addresser from 'addresser'

const axios = require('axios')

export default class FeeSchedule extends Component {
  constructor(props) {
    super(props)
    // let notesData = this.props?.carePlanNotesData
    // let reduxAppNotes = this.props?.appointmentNotesData

    this.state = {
      loading: false,
      // appointmentData: null,
      // patientId: this.props?.patientId || this.props?.patient?.uid,
      // communicationType: null,
      // //PRACTICE INFO - PROFILE
      // practice_id: Config.PRACTICE_ID,
      // practiceName: '',
      // address1: '',
      // address2: '',
      // city: '',
      // state: null,
      // addressComponents: null,
      // postalCode: '',
      // phoneNumber: '',
      // faxNumber: '',
      // taxEntity: '',
      // groupNpi: '',
      // serviceStates: null,
      // brandingColor: '',
      // instagramLink: '',
      // facebookLink: '',
      // twitterLink: '',
      // youtubeLink: '',
      // //LOGO UPLOAD
      // logoUploaded: false,
      // imageUploaded: false,
      // imageLocation: '',
      // image: null,
      // //ROLES & PERMISSIONS
      // // BUG: WTF is this?
      // rolesPermissions: [
      //   {
      //     first_name: 'test_ailahealth3',
      //     last_name: 'Tina1',
      //     email: 'test@ailahealth.com',
      //     user_type: 'Administrator',
      //   },
      //   {
      //     first_name: 'test_ailahealth3',
      //     last_name: 'Tina2',
      //     email: 'test2@ailahealth.com',
      //     user_type: 'Care Coordinator',
      //   },
      //   {
      //     first_name: 'test_ailahealth3',
      //     last_name: 'Tina6',
      //     email: 'test3@ailahealth.com',
      //     user_type: 'Physician (MD/DO)',
      //   },
      //   {
      //     first_name: 'test_ailahealth3',
      //     last_name: 'Tina5',
      //     email: 'test4@ailahealth.com',
      //     user_type: 'Administrator',
      //   },
      // ],
      // rolesModalOpen: false,
      // roleFistName: '',
      // roleLastName: '',
      // roleEmail: '',
      // supervisor: false,
      // role: null,
      // roleIndex: null,
      // selectedUser: null,
      // //BILLING & PAYOUTS
      // billingPayouts: {},
      // //POLICIES
      // policies: {},
      // //ACCEPTED INSURANCE
      // acceptedInsurance: [
      //   {
      //     isp: '11234',
      //     name: ['isp', 'Aetna California'],
      //   },
      //   {
      //     isp: '89562',
      //     name: ['isp', 'Blue Sheild Colorado'],
      //   },
      // ],
      // insuranceModalOpen: false,
      // searchName: '',
      // searchCity: '',
      // searchAddress: '',
      // searchState: '',
      // searchList: [],

      // //APPOINTMENT PRICING
      // productPricing: [],
      // productPricingModal: false,
      // selectedProduct: null,
      // visitIndex: null,
      // visitName: '',
      // visitBill: '',
      // visitLength: null,
      // visitType: null,
      // visitSpecialty: null,
      // visitCpt: '',
      // visitModifier: '',
      // visitDescription: '',

      // //EMAIL FIELDS
      // fromEmail: 'admin-donotreply@ailahealth.com',
      // ccEmail: '',
      // audience: null,
      // toEmails: null,
      // emailAddress: '',
      // cohortSelected: null,
      // template: null,
      // subject: '',
      // message: '',
      // //PHONE FIELDS
      // phoneType: null,
      // phonePatient: null,
      // phoneName: '',

      // phoneRequest: null,
      // phoneMessage: '',
      // providerMap: null,
      // patientList: [],
      // completePatientList: [],
      // providersList: [],
      // cohortOptions: [],
      // fileAttached: null,
      // scheduleDate: new Date(),

      // //VIEW OR CREATE COMMUNICATIONS
      // allCommunications: [],
      // viewCommunications: false,
      // searchName: '',
      // sortValue: true,
      // searchEmail: '',

      // //TASK MODAL
      // modalAddTaskIsOpen: false,

      // This is for the CMS 1500 form
      code: '',
      payerAddress: '',
      payerId: '',
      payerCity: '',
      payerType: null,
      patientFirstName: this.props.patientFirstName || '',
      patientLastName: this.props.patientLastName || '',
      patientMiddleName: this.props.patientMiddleName || '',
      patientDob: this.props.patientDob || null,
      patientGender: this.props.patientGender || '',
      patientAddress: this.props.patientAddress || '',
      patientAddress2: this.props.patientAddress2 || '',
      patientCity: this.props.patientCity || '',
      patientState: this.props.patientState || '',
      patientZip: this.props.patientZip || '',
      patientCountry: this.props.patientCountry || 'US',
      patientRelationship: this.props.patientRelationship || '',
      patientMartialStatus: this.props.patientMartialStatus || '',
      patientEmploymentStatus: this.props.patientEmploymentStatus || '',

      insuredIdNumber: '',
      insuredFirstName: '',
      insuredLastName: '',
      insuredMiddleName: '',
      insuredAddress: '',
      insuredAddress2: '',
      insuredCity: '',
      insuredState: '',
      insuredZip: '',
      insuredCountry: 'US',

      secondaryInsuredFirstName: '',
      secondaryInsuredLastName: '',
      secondaryInsuredMiddleName: '',
      secondaryInsuredPolicyNumber: '',
      secondaryInsuredDob: null,
      secondaryInsuredSex: null,
      primaryPaymentDate: null,
      secondaryPayerName: '',
      secondaryPayerId: '',
      secondaryPayerGroupName: '',
      secondaryPayerGroupNumber: '',
      secondaryPayerClaimId: '',
      secondaryMedicareTypeCode: '',
      secondaryPatientRelationship: '',

      patientConditionEmploymentStatus: null,
      patientConditionAutoAccident: null,
      patientConditionAutoAccidentState: null,
      patientConditionAutoAccidentCountry: 'US',
      patientConditionOtherAccident: null,
      claimCodes: null,

      insuredPolicyGroup: '',
      insuredDob: null,
      insuredSex: null,
      insuredEmployersName: '',
      insurancePlanName: '',

      supervisingProviderFirstName: '',
      supervisingProviderLastName: '',
      supervisingProviderMiddleName: '',
      supervisingProviderNpi: '',
      supervisingProviderId: '',

      dateOfCondition: '',
      addDateOfCondition: null,
      referringPhysicianFirstName: '',
      referringPhysicianLastName: '',
      referringPhysicianMiddleName: '',
      referringPhysicianNpi: '',
      referringPhysicianOtherId: '',

      claimNarrative: '',
      hospitalizationDateFrom: null,
      hospitalizationDateTo: null,

      resubmissionCode: '',
      priorAuthorizationNumber: '',
      referralNumber: '',
      cliaNumber: '',

      diagnosisA: '',
      diagnosisB: '',
      diagnosisC: '',
      diagnosisD: '',
      diagnosisE: '',
      diagnosisF: '',
      diagnosisG: '',
      diagnosisH: '',
      diagnosisI: '',
      diagnosisJ: '',
      diagnosisK: '',
      diagnosisL: '',

      fees: [
        {
          dateOfServiceStart: null,
          dateOfServiceEnd: null,
          bPos: '',
          CEmg: '',
          procedure: '',
          modifier1: '',
          modifier2: '',
          modifier3: '',
          modifier4: '',
          diagnosisReference: '',
          amountChanges: '',
          diagnosisUnits: '',
        },
      ],

      taxId: '',
      taxIdType: '',
      patientAccountNumber: '',
      acceptAssignment: null,
      totalCharge: '',
      totalAmountPaid: '',
      balanceDue: '',

      renderingProviderFirstName: '',
      renderingProviderLastName: '',
      renderingProviderMiddleName: '',
      renderingProviderNpi: '',
      renderingProviderId: '',
      renderingProviderTaxonomy: '',

      facilityName: '',
      facilityAddress: '',
      facilityAddress2: '',
      facilityCity: '',
      facilityState: '',
      facilityZip: '',
      facilityCountry: 'US',
      facilityNpi: '',
      facilityId: '',

      billingProviderName: '',
      billingProviderAddress: '',
      billingProviderAddress2: '',
      billingProviderCity: '',
      billingProviderCountry: 'US',
      billingProviderState: '',
      billingProviderZip: '',
      billingProviderNpi: '',
      billingProviderId: '',
      billingProviderTaxonomy: '',
      billingProviderPhone: '',
    }
    this.range = this.range.bind(this)
    this.getMonth = this.getMonth.bind(this)
    this.getYear = this.getYear.bind(this)
    this.submitCMS1500Form = this.submitCMS1500Form.bind(this)
    this.parseDate = this.parseDate.bind(this)
    this.parseInsuranceDetails = this.parseInsuranceDetails.bind(this)
  }

  componentDidMount() {
    if (this.props.eventId) {
      console.log('HELLO I GOT AN EVENT ID AND NEED TO FETCH DATA')
      axios({
        method: 'get',
        url: `${Config.BACKEND_URL}claims/get`,
        params: {
          eventId: this.props.eventId,
        },
      })
        .then(async (res) => {
          let claimDetails = res?.data?.data?.[0]

          claimDetails.patientDob = await this.parseDate(
            claimDetails.patientDob,
          )
          claimDetails.patientGender = {
            label: claimDetails?.patientGender,
            value: claimDetails?.patientGender?.[0],
          }

          let insuranceDetails = this.parseInsuranceDetails(
            claimDetails.insuranceData,
          )

          let parsedPatientAddress = null
          let parsedPatientAddress2 = null
          try {
            parsedPatientAddress = addresser.parseAddress(
              claimDetails.patientAddress,
            )
          } catch (err) {
            console.log('ERROR parsing address details: ', err)
          }
          try {
            parsedPatientAddress2 = addresser.parseAddress(
              claimDetails.patientAddress2,
            )
          } catch (err) {
            console.log('ERROR parsing address2 details: ', err)
          }
          claimDetails.patientCity =
            parsedPatientAddress?.placeName ||
            parsedPatientAddress2?.placeName ||
            ''
          claimDetails.patientState =
            parsedPatientAddress?.stateAbbreviation ||
            parsedPatientAddress2?.stateAbbreviation ||
            ''

          axios({
            method: 'get',
            url: `${Config.BACKEND_URL}facility/info`,
            params: {
              facilityState: claimDetails.patientState,
            },
          })
            .then((res) => {
              this.setState({ ...res?.data?.data })
            })
            .catch((err) => {
              console.log('ERROR5000: ', err)
              toast.error('Error when loading facility details!')
            })

          let parsedInsuredAddress = null
          let parsedInsuredAddress2 = null
          try {
            parsedInsuredAddress = addresser.parseAddress(
              claimDetails.insuredAddress,
            )
          } catch (err) {
            console.log('ERROR parsing insured address details: ', err)
          }
          try {
            parsedInsuredAddress2 = addresser.parseAddress(
              claimDetails.insuredAddress2,
            )
          } catch (err) {
            console.log('ERROR parsing insured address2 details: ', err)
          }
          claimDetails.insuredCity =
            parsedInsuredAddress?.placeName ||
            parsedInsuredAddress2?.placeName ||
            ''
          claimDetails.insuredState =
            parsedInsuredAddress?.stateAbbreviation ||
            parsedInsuredAddress2?.stateAbbreviation ||
            ''

          let icdData = claimDetails?.notes_data?.[0]?.careplan
          let cpts = claimDetails?.notes_data?.[0]?.cpt
          let diagRef = ''
          for (let i = 0; i < icdData?.length; i++) {
            console.log('careplan: ', i, ' ', icdData[i])
            let icdCode = icdData[i]?.icd_10?.icd10_code
            switch (i) {
              case 0: {
                claimDetails.diagnosisA = icdCode
                diagRef += 'A'
                break
              }
              case 1: {
                claimDetails.diagnosisB = icdCode
                diagRef += 'B'
                break
              }
              case 2: {
                claimDetails.diagnosisC = icdCode
                diagRef += 'C'
                break
              }
              case 3: {
                claimDetails.diagnosisD = icdCode
                diagRef += 'D'
                break
              }
              case 4: {
                claimDetails.diagnosisE = icdCode
                diagRef += 'E'
                break
              }
              case 5: {
                claimDetails.diagnosisF = icdCode
                diagRef += 'F'
                break
              }
              case 6: {
                claimDetails.diagnosisG = icdCode
                diagRef += 'G'
                break
              }
              case 7: {
                claimDetails.diagnosisH = icdCode
                diagRef += 'H'
                break
              }
              case 8: {
                claimDetails.diagnosisI = icdCode
                diagRef += 'I'
                break
              }
              case 9: {
                claimDetails.diagnosisJ = icdCode
                diagRef += 'J'
                break
              }
              case 10: {
                claimDetails.diagnosisK = icdCode
                diagRef += 'K'
                break
              }
              case 11: {
                claimDetails.diagnosisL = icdCode
                diagRef += 'L'
                break
              }
            }
          }
          let diagnosisObj = {
            dateOfServiceStart: null,
            dateOfServiceEnd: null,
            bPos: '',
            CEmg: '',
            procedure: '',
            modifier1: '',
            modifier2: '',
            modifier3: '',
            modifier4: '',
            diagnosisReference: '',
            amountChanges: '',
            diagnosisUnits: '',
          }
          diagnosisObj.diagnosisReference = diagRef
          diagnosisObj.dateOfServiceStart = moment(
            claimDetails.dateOfServiceStart,
          ).toDate()
          diagnosisObj.dateOfServiceEnd = moment(
            claimDetails.dateOfServiceEnd,
          ).toDate()
          diagnosisObj.procedure = cpts?.[0]?.cpt_code
          let fees = []
          fees.push(diagnosisObj)
          if (cpts?.length > 1) {
            for (let i = 1; i < cpts?.length; i++) {
              let diagnosisObjI = {
                dateOfServiceStart: moment(
                  claimDetails.dateOfServiceStart,
                ).toDate(),
                dateOfServiceEnd: moment(
                  claimDetails.dateOfServiceEnd,
                ).toDate(),
                bPos: '',
                CEmg: '',
                procedure: '',
                modifier1: '',
                modifier2: '',
                modifier3: '',
                modifier4: '',
                diagnosisReference: diagRef,
                amountChanges: '',
                diagnosisUnits: '',
              }
              diagnosisObjI.procedure = cpts[i]?.cpt_code
              fees.push(diagnosisObjI)
            }
          }
          claimDetails.fees = fees

          console.log('res: ', claimDetails)
          console.log('insDet: ', insuranceDetails)
          this.setState({ ...claimDetails, ...insuranceDetails })
        })
        .catch((err) => {
          console.log('ERROR4: ', err)
          toast.error(
            'Error when loading details, you might need to manually enter the data!',
          )
        })
    } else {
      console.log('YO I DID NOT GET AN EVENT ID AND NEED NOT FETCH ANY DATA')
    }
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}practice/info`,
    })
      .then((res) => {
        this.setState({ ...res?.data?.data })
      })
      .catch((err) => {
        console.log('ERROR5: ', err)
        toast.error('Error when loading practice details!')
      })
  }

  parseInsuranceDetails = (insuranceData) => {
    let pInsurance = Array.isArray(insuranceData)
      ? insuranceData?.find((ins) => ins.preference === 1) || null
      : insuranceData || null
    let sInsurance = Array.isArray(insuranceData)
      ? insuranceData?.find((ins) => ins.preference === 2)
      : null
    let pDetails = {}
    let sDetails = {}
    if (pInsurance) {
      pDetails.insuredIdNumber = pInsurance?.subscriber_id || null
      pDetails.insuredFirstName =
        pInsurance?.insured?.insured_first_name || null
      pDetails.insuredLastName = pInsurance?.insured?.insured_last_name || null
      pDetails.insuredAddress = pInsurance?.insured?.insured_address_1 || null
      pDetails.insuredAddress2 = pInsurance?.insured?.insured_address_2 || null
      pDetails.insuredCity = pInsurance?.insured?.insured_city || null
      pDetails.insuredState = pInsurance?.insured?.insured_state || null
      pDetails.insuredZip = pInsurance?.insured?.insured_zip || null
      pDetails.insuredCountry = pInsurance?.insured?.insured_country || 'US'
      pDetails.patientRelationship =
        pInsurance?.insured?.insured_relationship?.toLowerCase() == 'self'
          ? { label: 'SELF', value: '18' }
          : null
    }
    if (sDetails) {
      sDetails.secondaryInsuredFirstName =
        sInsurance?.insured_first_name || null
      sDetails.secondaryInsuredLastName = sInsurance?.insured_last_name || null
      // sDetails.secondaryInsuredMiddleName = sInsurance?.insured_middle_name || null
      // sDetails.secondaryInsuredPolicyNumber = sInsurance?.policy_number || null
      // sDetails.secondaryInsuredDob = sInsurance?.insured_dob || null
    }
    return { ...pDetails, ...sDetails }
  }

  parseDate = (date) => {
    console.log('got date', date)
    if (date === 'Invalid date') {
      toast.error("Patient's date of birth is invalid!")
      return new Date()
    }
    return moment(date, 'DD/MM/YYYY').toDate()
  }

  checkIfCanSubmit = () => {
    let {
      code,
      payerId,
      patientAccountNumber,
      patientFirstName,
      patientLastName,
      patientDob,
      patientAddress,
      patientCity,
      patientState,
      patientZip,
      patientGender,
      patientRelationship,
      insuredIdNumber,
      acceptAssignment,
      totalCharge,
      diagnosisA,
      billingProviderName,
      billingProviderAddress,
      billingProviderAddress2,
      billingProviderCity,
      billingProviderState,
      billingProviderZip,
      billingProviderNpi,
      billingProviderId,
      billingProviderPhone,
      taxId,
      taxIdType,
      fees, // check dateOfServiceStart, dateOfServiceEnd, diagnosisReference, amountChanges, diagnosisUnits
    } = this.state

    // DO: From the above code complete the entire code block
    if (!code) {
      toast.error('Please fill in the payer name')
      return false
    } else if (!payerId) {
      toast.error('Please fill in the payer id')
      return false
    } else if (!patientAccountNumber) {
      toast.error('Please fill in the patient account number')
      return false
    } else if (!patientFirstName) {
      toast.error('Please fill in the patient first name')
      return false
    } else if (!patientLastName) {
      toast.error('Please fill in the patient last name')
      return false
    } else if (!patientDob) {
      toast.error('Please fill in the patient date of birth')
      return false
    } else if (!patientAddress) {
      toast.error('Please fill in the patient address')
      return false
    } else if (!patientCity) {
      toast.error('Please fill in the patient city')
      return false
    } else if (!patientState) {
      toast.error('Please fill in the patient state')
      return false
    } else if (!patientZip) {
      toast.error('Please fill in the patient zip')
      return false
    } else if (!patientGender) {
      toast.error('Please fill in patient Sex')
      return false
    } else if (!patientRelationship) {
      toast.error('Please fill in patient Relationship')
      return false
    } else if (!insuredIdNumber) {
      toast.error('Please fill in insured Id Number')
      return false
    } else if (!acceptAssignment) {
      toast.error('Please fill in 27. accept assignment')
      return false
    } else if (!totalCharge) {
      toast.error('Please fill in total charge')
      return false
    } else if (!diagnosisA) {
      toast.error('Please fill in diagnosis A')
      return false
    } else if (!billingProviderName) {
      toast.error('Please fill in billing provider name')
      return false
    } else if (!billingProviderAddress) {
      toast.error('Please fill in billing provider address')
      return false
    } else if (!billingProviderAddress2) {
      toast.error('Please fill in billing provider address 2')
      return false
    } else if (!billingProviderCity) {
      toast.error('Please fill in billing provider city')
      return false
    } else if (!billingProviderState) {
      toast.error('Please fill in billing provider state')
      return false
    } else if (!billingProviderZip) {
      toast.error('Please fill in billing provider zip')
      return false
    } else if (!billingProviderNpi && !billingProviderId) {
      toast.error('Please fill in billing provider npi or ID')
      return false
    } else if (!billingProviderPhone) {
      toast.error('Please fill in billing provider phone')
      return false
    } else if (!taxId) {
      toast.error('Please fill in tax id')
      return false
    } else if (!taxIdType) {
      toast.error('Please fill in tax id type')
      return false
    } else {
      for (let i = 0; i < fees.length; i++) {
        // dateOfServiceStart, dateOfServiceEnd, diagnosisReference, amountChanges, diagnosisUnits
        if (!fees[i].dateOfServiceStart) {
          toast.error(
            'Please fill in date of service start for the diagnosis row ' +
              (i + 1),
          )
          return false
        }
        if (!fees[i].dateOfServiceEnd) {
          toast.error(
            'Please fill in date of service end for the diagnosis row ' +
              (i + 1),
          )
          return false
        }
        if (!fees[i].diagnosisReference) {
          toast.error(
            'Please fill in diagnosis reference for the diagnosis row ' +
              (i + 1),
          )
          return false
        }
        if (!fees[i].amountChanges) {
          toast.error(
            'Please fill in amount changes for the diagnosis row ' + (i + 1),
          )
          return false
        }
        if (!fees[i].diagnosisUnits) {
          toast.error(
            'Please fill in diagnosis units for the diagnosis row ' + (i + 1),
          )
          return false
        }
      }
    }
    return true
  }

  submitCMS1500Form = async () => {
    console.log('SUBMITTING CMS 1500 FORM')

    if (!this.checkIfCanSubmit()) {
      // toast.error('Please fill in all the required fields')
      return
    }

    let {
      code,
      payerAddress,
      payerId,
      payerCity,
      payerType,
      patientFirstName,
      patientLastName,
      patientMiddleName,
      patientDob,
      patientGender,
      patientAddress,
      patientAddress2,
      patientCity,
      patientState,
      patientZip,
      patientCountry,
      patientRelationship,
      patientMartialStatus,
      patientEmploymentStatus,
      insuredIdNumber,
      insuredFirstName,
      insuredLastName,
      insuredMiddleName,
      insuredAddress,
      insuredAddress2,
      insuredCity,
      insuredState,
      insuredZip,
      insuredCountry,
      secondaryInsuredFirstName,
      secondaryInsuredLastName,
      secondaryInsuredMiddleName,
      secondaryInsuredPolicyNumber,
      secondaryInsuredDob,
      secondaryInsuredSex,
      primaryPaymentDate,
      secondaryPayerName,
      secondaryPayerId,
      secondaryPayerGroupName,
      secondaryPayerGroupNumber,
      secondaryPayerClaimId,
      secondaryMedicareTypeCode,
      secondaryPatientRelationship,
      patientConditionEmploymentStatus,
      patientConditionAutoAccident,
      patientConditionAutoAccidentState,
      patientConditionAutoAccidentCountry,
      patientConditionOtherAccident,
      claimCodes,
      insuredPolicyGroup,
      insuredDob,
      insuredSex,
      insuredEmployersName,
      insurancePlanName,
      supervisingProviderFirstName,
      supervisingProviderLastName,
      supervisingProviderMiddleName,
      supervisingProviderNpi,
      supervisingProviderId,
      dateOfCondition,
      addDateOfCondition,
      referringPhysicianFirstName,
      referringPhysicianLastName,
      referringPhysicianMiddleName,
      referringPhysicianNpi,
      referringPhysicianOtherId,
      claimNarrative,
      hospitalizationDateFrom,
      hospitalizationDateTo,
      resubmissionCode,
      priorAuthorizationNumber,
      referralNumber,
      cliaNumber,
      diagnosisA,
      diagnosisB,
      diagnosisC,
      diagnosisD,
      diagnosisE,
      diagnosisF,
      diagnosisG,
      diagnosisH,
      diagnosisI,
      diagnosisJ,
      diagnosisK,
      diagnosisL,
      fees,
      taxId,
      taxIdType,
      patientAccountNumber,
      acceptAssignment,
      totalCharge,
      totalAmountPaid,
      balanceDue,
      renderingProviderFirstName,
      renderingProviderLastName,
      renderingProviderMiddleName,
      renderingProviderNpi,
      renderingProviderId,
      renderingProviderTaxonomy,
      facilityName,
      facilityAddress,
      facilityAddress2,
      facilityCity,
      facilityState,
      facilityZip,
      facilityCountry,
      facilityNpi,
      facilityId,
      billingProviderName,
      billingProviderAddress,
      billingProviderAddress2,
      billingProviderCity,
      billingProviderState,
      billingProviderZip,
      billingProviderNpi,
      billingProviderId,
      billingProviderTaxonomy,
      billingProviderPhone,
    } = this.state

    console.log('Here1')
    // Sanitize the data
    if (patientDob)
      patientDob =
        patientDob?.getFullYear() +
        '-' +
        (patientDob?.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        patientDob?.getDate().toString().padStart(2, '0')
    if (secondaryInsuredDob)
      secondaryInsuredDob =
        secondaryInsuredDob.getFullYear() +
        '-' +
        (secondaryInsuredDob.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        secondaryInsuredDob.getDate().toString().padStart(2, '0')
    if (hospitalizationDateFrom)
      hospitalizationDateFrom =
        hospitalizationDateFrom.getFullYear() +
        (hospitalizationDateFrom.getMonth() + 1).toString().padStart(2, '0') +
        hospitalizationDateFrom.getDate().toString().padStart(2, '0')
    if (hospitalizationDateTo)
      hospitalizationDateTo =
        hospitalizationDateTo.getFullYear() +
        (hospitalizationDateTo.getMonth() + 1).toString().padStart(2, '0') +
        hospitalizationDateTo.getDate().toString().padStart(2, '0')
    if (insuredDob)
      insuredDob =
        insuredDob.getFullYear() +
        (insuredDob.getMonth() + 1).toString().padStart(2, '0') +
        insuredDob.getDate().toString().padStart(2, '0')
    if (dateOfCondition)
      dateOfCondition =
        dateOfCondition.getFullYear() +
        '-' +
        (dateOfCondition.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        dateOfCondition.getDate().toString().padStart(2, '0')
    // let localDiagRef = ""
    for (let i = 0; i < fees.length; i++) {
      if (fees[i].dateOfServiceStart)
        fees[i].dateOfServiceStart =
          fees[i].dateOfServiceStart.getFullYear() +
          (fees[i].dateOfServiceStart.getMonth() + 1)
            .toString()
            .padStart(2, '0') +
          fees[i].dateOfServiceStart
            .getDate()
            .toString()
            .padStart(2, '0')
      if (fees[i].dateOfServiceEnd)
        fees[i].dateOfServiceEnd =
          fees[i].dateOfServiceEnd.getFullYear() +
          (fees[i].dateOfServiceEnd.getMonth() + 1)
            .toString()
            .padStart(2, '0') +
          fees[i].dateOfServiceEnd
            .getDate()
            .toString()
            .padStart(2, '0')
    }

    console.log('dagnosisList: ', fees)
    console.log('Here2')
    // Send data to the backend
    axios({
      method: 'post',
      url: `${Config.BACKEND_URL}claims/submit`,
      // url: `http://localhost:15000/claims/submit`,
      headers: {
        // Authorization: `JWT ${token}`,
        Authorization: `JWT `,
      },
      data: {
        code,
        payerAddress,
        payerId,
        payerCity,
        payerType: payerType?.value,
        patientFirstName,
        patientLastName,
        patientMiddleName,
        patientDob,
        patientGender: patientGender?.value,
        patientAddress,
        patientAddress2,
        patientCity,
        patientState,
        patientZip,
        patientCountry,
        patientRelationship: patientRelationship?.value,
        patientMartialStatus: patientMartialStatus?.value,
        patientEmploymentStatus: patientEmploymentStatus?.value,
        insuredIdNumber,
        insuredFirstName,
        insuredLastName,
        insuredMiddleName,
        insuredAddress,
        insuredAddress2,
        insuredCity,
        insuredState,
        insuredZip,
        insuredCountry,
        secondaryInsuredFirstName,
        secondaryInsuredLastName,
        secondaryInsuredMiddleName,
        secondaryInsuredPolicyNumber,
        secondaryInsuredDob,
        secondaryInsuredSex: secondaryInsuredSex?.value,
        primaryPaymentDate,
        secondaryPayerName,
        secondaryPayerId,
        secondaryPayerGroupName,
        secondaryPayerGroupNumber,
        secondaryPayerClaimId,
        secondaryMedicareTypeCode,
        secondaryPatientRelationship,
        patientConditionEmploymentStatus:
          patientConditionEmploymentStatus?.value,
        patientConditionAutoAccident: patientConditionAutoAccident?.value,
        patientConditionAutoAccidentState,
        patientConditionAutoAccidentCountry,
        patientConditionOtherAccident: patientConditionOtherAccident?.value,
        claimCodes,
        insuredPolicyGroup,
        insuredDob,
        insuredSex: insuredSex?.value,
        insuredEmployersName,
        insurancePlanName,
        supervisingProviderFirstName,
        supervisingProviderLastName,
        supervisingProviderMiddleName,
        supervisingProviderNpi,
        supervisingProviderId,
        dateOfCondition,
        addDateOfCondition,
        referringPhysicianFirstName,
        referringPhysicianLastName,
        referringPhysicianMiddleName,
        referringPhysicianNpi,
        referringPhysicianOtherId,
        claimNarrative,
        hospitalizationDateFrom,
        hospitalizationDateTo,
        resubmissionCode: resubmissionCode?.value,
        priorAuthorizationNumber,
        referralNumber,
        cliaNumber,
        diagnosisA,
        diagnosisB,
        diagnosisC,
        diagnosisD,
        diagnosisE,
        diagnosisF,
        diagnosisG,
        diagnosisH,
        diagnosisI,
        diagnosisJ,
        diagnosisK,
        diagnosisL,
        fees,
        taxId,
        taxIdType: taxIdType?.value,
        patientAccountNumber,
        acceptAssignment: acceptAssignment?.value,
        totalCharge,
        totalAmountPaid,
        balanceDue,
        renderingProviderFirstName,
        renderingProviderLastName,
        renderingProviderMiddleName,
        renderingProviderNpi,
        renderingProviderId,
        renderingProviderTaxonomy,
        facilityName,
        facilityAddress,
        facilityAddress2,
        facilityCity,
        facilityState,
        facilityZip,
        facilityCountry,
        facilityNpi,
        facilityId,
        billingProviderName,
        billingProviderAddress,
        billingProviderAddress2,
        billingProviderCity,
        billingProviderState,
        billingProviderZip,
        billingProviderNpi,
        billingProviderId,
        billingProviderTaxonomy,
        billingProviderPhone,
        eventId: this.props.eventId,
      },
    }).then((res) => {
      if (res?.data?.success == true) {
        toast.success('Successfully submitted claim')
      } else {
        toast.error('Something went wrong')
      }
    })
  }

  range = (start, end) => {
    var ans = []
    for (let i = start; i <= end; i++) {
      ans.push(i)
    }
    return ans
  }

  getMonth(date) {
    // console.log('DATEM:', date.getMonth())
    let date_num = date.getMonth()
    switch (date_num) {
      case 0:
        return 'January'
      case 1:
        return 'February'
      case 2:
        return 'March'
      case 3:
        return 'April'
      case 4:
        return 'May'
      case 5:
        return 'June'
      case 6:
        return 'July'
      case 7:
        return 'August'
      case 8:
        return 'September'
      case 9:
        return 'October'
      case 10:
        return 'November'
      case 11:
        return 'December'
      default: {
        console.log('ERROR1: Invalid Month')
        return 'January'
      }
      // case 'February':
      //   return 1
      // case 'March':
      //   return 2
      // case 'April':
      //   return 3
      // case 'May':
      //   return 4
      // case 'June':
      //   return 5
      // case 'July':
      //   return 6
      // case 'August':
      //   return 7
      // case 'September':
      //   return 8
      // case 'October':
      //   return 9
      // case 'November':
      //   return 10
      // case 'December':
      //   return 11
      // default: {
      //   console.log('ERROR2: Invalid Month')
      //   return 0
      // }
    }
  }

  getYear(date) {
    console.log('getYear:', date.getFullYear())
    return date.getFullYear()
  }

  render() {
    const years = this.range(1930, 2023, 1)
    // BUG WITH SELECTING JAN
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    // if (this.state.loading) {
    //   return (
    //     <div
    //       className={GlobalStyles.container}
    //       style={{ overflow: 'hidden', maxHeight: '100vh' }}
    //     >
    //       <div
    //         style={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'center',
    //           minWidth: '100%',
    //           height: '80vh',
    //         }}
    //       >
    //         <div className={GlobalStyles.loader} />
    //       </div>
    //     </div>
    //   )
    // }

    return (
      <div>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <h4
            style={{
              color: Constants.primaryTheme,
              width: '100%',
              marginBottom: 10,
              textAlign: 'center',
            }}
          >
            Fee Schedule
          </h4>
        </div>

        {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1, padding: '10px' }}>
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              <span>Claim Submission Date: NULL</span>
            </h5>
          </div>
          <div style={{ flex: 1, padding: '10px' }}>
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              Claim Status: NULL
            </h5>
          </div>
          <div style={{ flex: 1, padding: '10px' }}>
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              Clearing House #: NULL
            </h5>
          </div>
          <div style={{ flex: 1, padding: '10px' }}>
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              Claim #: NULL
            </h5>
          </div>
          <div style={{ flex: 1, padding: '10px' }}>
            <h5
              style={{
                color: Constants.primaryTheme,
                textAlign: 'left',
                // textDecoration: 'underline',
              }}
            >
              Cheque #: NULL
            </h5>
          </div>
        </div> */}

        {/* Form Elements */}
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Code</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Description</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Patient Type</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Min Time</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Max Time</p>
            </div>

            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Units</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Insurance Bill Rate</p>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
              <p className={Styles.studyEntryKey}>Rate w/ Discount</p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon
                onClick={() => {
                  let { fees } = this.state
                  fees.push({
                    dateOfServiceStart: null,
                    dateOfServiceEnd: null,
                    bPos: '',
                    CEmg: '',
                    procedure: '',
                    modifier1: '',
                    modifier2: '',
                    modifier3: '',
                    modifier4: '',
                    diagnosisReference: fees[0].diagnosisReference,
                    amountChanges: '',
                    diagnosisUnits: '',
                  })
                  this.setState({ fees })
                }}
                icon={faPlusCircle}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 18,
                  // right: 14,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
          {/* Row 16: 2 columns */}
          {this.state.fees.map((diagnosis, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderTop: '2px solid #20A892',
                }}
              >
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Code'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.code}
                    onChange={(e) =>
                      this.setState({ code: e.target.value })
                    }
                  />
                </div>
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Description'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.patientFirstName}
                    onChange={(e) => {
                      this.setState({ patientFirstName: e.target.value })
                    }}
                  />
                </div>
                <div
                  style={{ flex: 1, padding: '10px' }}
                  // style={{
                  //   width: '90px',
                  //   display: 'flex',
                  //   flexDirection: 'column',
                  //   alignItems: 'center',
                  //   justifyContent: 'center',
                  // }}
                >
                  <Select
                    placeholder="Select Type"
                    inputId="payerType"
                    options={[
                      { label: 'New', value: 'Primary' },
                      { label: 'Established', value: 'Secondary' },
                    ]}
                    value={this.state.payerType}
                    isMulti={false}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState({ payerType: val })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Min Time'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.patientLastName}
                    onChange={(e) => {
                      this.setState({ patientLastName: e.target.value })
                    }}
                  />
                </div>
                <div
                  style={{
                    width: '175px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    placeholder={'Max Time'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.patientMiddleName}
                    onChange={(e) => {
                      this.setState({ patientMiddleName: e.target.value })
                    }}
                  />
                </div>

                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Units'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.renderingProviderFirstName}
                    onChange={(e) => {
                      this.setState({
                        renderingProviderLastName: e.target.value,
                      })
                    }}
                  />
                </div>
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Insurance Bill Rate'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={diagnosis.amountChanges}
                    onChange={(e) => {
                      let { fees } = this.state
                      fees[index].amountChanges = e.target.value
                      this.setState({ fees })
                    }}
                  />
                </div>
                <div style={{ flex: 1, padding: '10px' }}>
                  <input
                    placeholder={'Rate w/ Discount'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={diagnosis.diagnosisUnits}
                    onChange={(e) => {
                      let { fees } = this.state
                      fees[index].diagnosisUnits = e.target.value
                      this.setState({ fees })
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => {
                      let { fees } = this.state
                      fees.push({
                        dateOfServiceStart: null,
                        dateOfServiceEnd: null,
                        bPos: '',
                        CEmg: '',
                        procedure: '',
                        modifier1: '',
                        modifier2: '',
                        modifier3: '',
                        modifier4: '',
                        diagnosisReference: fees[0].diagnosisReference,
                        amountChanges: '',
                        diagnosisUnits: '',
                      })
                      this.setState({ fees })
                    }}
                    icon={faPlusCircle}
                    style={{
                      color: Constants.primaryTheme,
                      fontSize: 18,
                      // right: 14,
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>

        <Button
          onClick={() => {
            console.log('Clicked on save!')
            // this.submitCMS1500Form()
          }}
          className={GlobalStyles.button}
          style={{
            width: 100,
            position: 'relative',
            margin: 20,
            float: 'right',
          }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    )
  }
}

// const mapStateToProps = (state /*, ownProps*/) => {
//   return {
//     loggedIn: state.userReducer.loggedIn,
//     token: state.authReducer.token,
//     docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
//     mongoUser: state.userReducer.mongoUser,
//     adminId: state.userReducer.adminId,
//     patientList: state.patientReducer.patientList,
//     doctorPatientData: state.appointmentReducer.patientData,
//     appointmentData: state.appointmentReducer.appointmentData,
//     patient: state.patientReducer.patient,
//     appointmentNotesData: state.appointmentReducer.appointmentNotesData,
//     openNotesModal: state.genericReducer.openNotesModal,
//   }
// }

// const mapDispatchToProps = {
//   setAppointmentNotesData,
//   fetchPatientCarePlanData,
//   addPatientList,
//   toggleSidebar,
// }

// export default connect(mapStateToProps, mapDispatchToProps)(CmsTab)
// export default CmsTab
