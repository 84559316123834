import Config from "../config";
const axios = require('axios');

const functions = {
	getParticleData: async (field, uid, token) => {
		let url = Config.BACKEND_URL + 'particle/data?field=' + field
		//get authorization token for all backend APIs
		return axios({
			method: 'get',
			url: url,
			headers: {x_firebase_id: uid, Authorization: 'JWT ' + token}
		})
	}
}

export default functions
