import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'

export const providerReferralsColumns = (invitePatientClicked) => {
  return [
    {
      Header: 'Referred Date',
      accessor: (obj) =>
        obj?.created_at ? moment(obj?.created_at).format('ll') : '',
      width: 200,
      sort: true,
    },

    {
      Header: 'Patient Name',
      accessor: (obj) => obj?.firstName + ' ' + obj?.lastName,
      //   Cell: ({ row }) => {
      //     let patientData = row?.original
      //     return (
      //       <Link
      //         onClick={() => {
      //           navigatetoPatientProfile(row?.original?.uid)
      //           onPatientNavLinkClicked('profile')
      //         }}
      //         to={{
      //           pathname: '/patient/profile',
      //           state: 'info',
      //           key: 2,
      //         }}
      //         className={Styles.link}
      //       >
      //         {patientData?.firstName || ''} {patientData?.lastName || ''}
      //       </Link>
      //     )
      //   },
      width: 300,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Phone',
      accessor: 'phone',
      width: 200,
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 250,
    },
    {
      Header: 'Insurance',
      accessor: (obj) => {
        return `${obj.payer}`
      },
      width: 200,
    },
    {
      Header: 'Member ID',
      accessor: (obj) => {
        return `${obj.insurance}`
      },
      width: 200,
    },
    {
      Header: 'Referring Provider',
      accessor: (obj) => {
        let providerData = obj?.providerData || null
        return `${providerData.firstName} ${providerData.lastName}`
      },
      width: 250,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      // sort: true,
    },
    {
      Header: 'Comments',
      accessor: 'comments',
      width: 200,
    },

    {
      Header: 'Invite Patient',
      accessor: 'patient_invite',
      Cell: ({ row }) => {
        return (
          <p
            aria-disabled={row.original.patient_id ? true : false}
            onClick={() => {
              console.log('invite patient')
              if (!row.original.patient_id) invitePatientClicked(row.original)
            }}
            className={Styles.link}
            style={{
              textDecoration: 'underline',
              color: row.original.patient_id ? '#20a892' : 'red',
            }}
          >
            {row.original.patient_id ? 'Invite Sent' : `Invite`}
          </p>
        )
      },
      width: 150,
    },
  ]
}
