import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../../config'

class ProviderDetailsTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      providerId: props.providerId,
      npi: null,
      loading: true,
    }
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}tooltip/get/provider-details`,
      // url: `http://localhost:15000/tooltip/get/provider-details`,
      params: {
        providerId: this.props.providerId,
      },
    }).then((res) => {
      if (res.data.success === false) {
        this.setState({ npi: 'ERROR', loading: false })
        return console.log('error getting patient details')
      }
      this.setState({
        npi: res.data?.data?.npi?.number || 'No NPI',
        loading: false,
      })
    })
  }

  render() {
    if (this.state.loading) return <div>Loading...</div>
    return (
      <div>
        <div>NPI:{this.state.npi}</div>
      </div>
    )
  }
}

export default ProviderDetailsTooltip
