import React, { Component } from "react";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";

class MyComponent extends Component {
  render() {
    return (
      <FileViewer
        fileType={this.props.fileType}
        filePath={this.props.fileName}
        errorComponent={CustomErrorComponent}
        onError={this.onError}
      />
    );
  }

  onError(e) {
    console.log(e, "error in file-viewer");
  }
}
export default MyComponent;
