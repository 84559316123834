import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import GlobalStyles from '../../../pages/styles/global.module.scss'
import Styles from './styles/Communities.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
//import images
import SkeletonIcon from '../../../assets/images/skeleton.png'
import HeartIcon from '../../../assets/images/heart.png'
import LungsIcon from '../../../assets/images/lungs.png'
import PainIcon from '../../../assets/images/pain.png'
import StomachIcon from '../../../assets/images/stomach.png'
import ReproductiveIcon from '../../../assets/images/reproductive.png'
import ImmuneIcon from '../../../assets/images/immune.png'
import EndocrineIcon from '../../../assets/images/kidney2.png'
import MentalHealthIcon from '../../../assets/images/mental_health_icon.png'
import { toggleSidebar } from '../../../redux/actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBookMedical,
  faUser,
  faCapsules,
  faDna,
  faViruses,
  faExclamationCircle,
  faCheckCircle,
  faClinicMedical,
  faCrosshairs,
  faPlusCircle,
  faHandHoldingMedical,
  faStethoscope,
  faBookReader,
  faNotesMedical,
  faClipboardList,
  faFileInvoice,
  faEdit,
  faWindowClose,
  faUserMd,
  faFileImport,
  faTrash,
  faAppleAlt,
  faBrain,
} from '@fortawesome/free-solid-svg-icons'
import Constants from '../../../values'
import Config from '../../../config'
import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import Header from '../../../components/Header.js'
import Select from 'react-select'

const TWILIO_VIDEO = require('twilio-video')

const moment = require('moment')
const axios = require('axios')

const CONFIRM_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '70%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}
const APPOINTMENT_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 440,
    height: 400,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const Communities = (props) => {
  const dispatch = useDispatch()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)
  //   const openNotesModal = useSelector(
  //     (state) => state.genericReducer?.openNotesModal,
  //   )
  const appointmentData = useSelector(
    (state) => state.appointmentReducer?.appointmentData,
  )

  const patient = useSelector((state) => state.patientReducer?.patient)
  const patientCore = useSelector((state) => state.patientReducer?.patientCore)
  const mongoUser = useSelector((state) => state.userReducer.mongoUser)
  const firebaseUser = useSelector((state) => state.userReducer.firebaseUser)

  const [allCircles, setAllCircles] = useState([])

  const [loading, setLoading] = useState(false)
  const [userCommunities, setUserCommunities] = useState([])
  const [otherCommunities, setOtherCommunities] = useState([])

  const [showAddModal, setShowAddModal] = useState(false)
  const [addedComminities, setAddedComminities] = useState(null)
  const [modalError, setModalError] = useState('')

  //NUTRITION CIRCLE PAID CHECK
  const [circleData, setCircleData] = useState(null)
  const [showPayment, setshowPayment] = useState(false)
  const [payNow, setPayNow] = useState(false)
  const [subscribe, setSubscribe] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (!patient && !firebaseUser) {
      history.push('/login')
    }
    setLoading(true)
    getCommunities()
    getCircleList()
  }, [])

  const getCommunities = () => {
    let config = {
      method: 'get',
      url: `${Config.BACKEND_URL}communities`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: firebaseUser ? firebaseUser?.uid : patient?.uid,
      },
    }

    axios(config)
      .then(({ data }) => {
        setUserCommunities(data.userCommunities)
        setOtherCommunities(data.otherCommunities)
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when getting communtieis', err)
        // toast.warning('Could not fetch communities, try again')
      })
  }

  const getCircleList = () => {
    let config = {
      method: 'get',
      url: `${Config.BACKEND_URL}circles`,
      headers: {
        x_firebase_id: firebaseUser ? firebaseUser?.uid : patient?.uid,
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    }
    axios(config)
      .then((response) => {
        if (response.data) {
          let circleArray = [
            ...response?.data?.recurringCircles,
            ...response?.data?.closedCircles,
            ...response?.data?.openCircles,
            ...response?.data?.circlesHosted,
          ].sort((first, second) => {
            return moment(first.scheduled_date).diff(
              moment(second.scheduled_date),
            )
          })
          setAllCircles(circleArray)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
        toast.warning('Could not fetch circles, try again')
      })
  }

  const modalClose = () => {
    setAddedComminities(null)
    setShowAddModal(false)
    setModalError('')
  }

  const addUserToCommunity = (addedComminities) => {
    if (addedComminities) {
      addedComminities.forEach((community) => {
        let config = {
          method: 'post',
          url: `${Config.BACKEND_URL}communities/user`,
          headers: {
            Authorization: `JWT ${token}`,
          },
          data: {
            firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
            community_id: community._id,
          },
        }

        axios(config)
          .then(({ data }) => {
            console.log('user added to:', community.title)
          })
          .catch((err) => {
            console.log('error when adding user to communities', err)
            toast.warning(
              'Something went wrong. Please try again after a while.',
            )

            setLoading(false)
          })
      })
      setLoading(true)
      setShowAddModal(false)
      setModalError('')
      getCommunities()
    } else {
      setModalError('Please select Communities to Join')
    }
  }

  const renderAddModal = () => {
    const getOptions = (list) => {
      list.forEach((item) => (item.label = item.title))
      return list
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            setShowAddModal(false)
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryThemeDark, textAlign: 'center' }}>
          Join Community
        </h4>

        {/* <p
          style={{
            fontSize: 18,
            margin: 'auto',
            color: Constants.primaryTheme,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
          }}
        >
          {`comm ?`}
        </p> */}
        <div style={{ width: '80%', margin: '10%' }}>
          <Select
            options={getOptions(otherCommunities)}
            placeholder={'Select Communities'}
            isMulti
            isClearable={false}
            onChange={(val, option) => {
              console.log(val)
              setAddedComminities(val)
            }}
            getOptionValue={(option) => option.title}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <p
          style={{
            fontSize: 14,
            margin: 'auto',
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            visibility:
              modalError.length !== 0 && !addedComminities
                ? 'visible'
                : 'hidden',
          }}
        >
          {modalError}
        </p>

        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            position: 'absolute',
            bottom: '5%',
          }}
        >
          <Button
            onClick={() => {
              console.log('added')
              addUserToCommunity()
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '1vh',
              width: 200,
            }}
            variant="primary"
          >
            {'Join'}
          </Button>
        </div>
      </div>
    )
  }

  // const renderCommunities = () => {
  //   let communitiesList =
  //     userCommunities && userCommunities.map((comm) => <div>{comm?.title}</div>)
  //   return (
  //     <div className={GlobalStyles.contentWrapper}>
  //       {/* <div style={{ position: 'absolute', top: 0, right: 0 }}> */}
  //       <FontAwesomeIcon
  //         onClick={() => {
  //           console.log('add comm')
  //           setShowAddModal(true)
  //           setModalError('')
  //         }}
  //         icon={faPlusCircle}
  //         style={{
  //           color: Constants.primaryTheme,
  //           fontSize: 25,
  //           right: 14,
  //           cursor: 'pointer',
  //           position: 'absolute',
  //           // top: 0,
  //           right: '2vw',
  //         }}
  //       />
  //       {/* </div> */}
  //       <div className={Styles.parentDiv}>
  //         {userCommunities && userCommunities.length ? (
  //           userCommunities.map((comm, index) => {
  //             return renderListItem(comm, index)
  //           })
  //         ) : (
  //           <h3>
  //             {' '}
  //             You are not part of any community. Join by clicking the '+' sign
  //             at the top.{' '}
  //           </h3>
  //         )}
  //       </div>

  //       {showAddModal && (
  //         <Modal
  //           ariaHideApp={false}
  //           isOpen={showAddModal}
  //           onRequestClose={() => {
  //             setShowAddModal(false)
  //           }}
  //           style={CONFIRM_MODAL_STYLES}
  //           contentLabel="Modal"
  //         >
  //           {renderAddModal()}
  //         </Modal>
  //       )}

  //       {/* {communitiesList} */}
  //     </div>
  //   )
  // }

  const renderCommunities = () => {
    let patientData = { ...firebaseUser, ...patientCore }
    const renderCirclesList = (circleData, index) => {
      return (
        <div
          key={index}
          // className={Styles.contentRow}
          className={Styles.contentBackground}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 10,
              }}
            >
              <h6 style={{ color: Constants.primaryThemeDark }}>
                {moment(circleData?.scheduled_date).format(
                  'MM/DD/YYYY hh:mm A',
                )}
                {/* {'\n '}
                Visit with {circleData?.first_name}{' '}
                {circleData?.last_name} */}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {` ${circleData?.title} ( ${circleData?.recurring}ly )`}
              </h6>
            </div>
            <Button
              className={GlobalStyles.button}
              disabled={loading}
              style={{
                // width: 200,
                // right: 0,
                // position: 'absolute',
                justifyContent: 'right',
                width: '10vw',
              }}
              variant="primary"
              onClick={() => {
                setCircleData(circleData)
                //CHECK FOR PROVIDER ACCESSING CIRCLES
                if (firebaseUser?.userType === 'admin') {
                  console.log('provider')
                  history.push(
                    firebaseUser
                      ? {
                          pathname: `/patient/circle`,
                          circle: circleData,
                          fromCommunity: 'true',
                          // patient: patient,
                        }
                      : {
                          pathname: '/patient/circle',
                          circle: circleData,
                          patient: patient,
                          fromCommunity: 'true',
                        },
                  )
                }
                //CHECK IF PATIENT HAS PAYED FOR THE CIRCLE/ IS SUBSCRIBED
                else if (
                  circleData.type === 'closed' &&
                  !patientData?.coaching?.active &&
                  !circleData?.users?.[patientData?.firebase_id]?.paid
                ) {
                  setshowPayment(true)
                  dispatch(toggleSidebar(true))
                  console.log('patient')
                  console.log('unpaid-paid-user', circleData?.users)
                } else {
                  console.log('patient')
                  console.log('paid-user', circleData?.users)
                  history.push(
                    firebaseUser
                      ? {
                          pathname: `/patient/circle`,
                          circle: circleData,
                          fromCommunity: 'true',
                          // patient: patient,
                        }
                      : {
                          pathname: '/patient/circle',
                          circle: circleData,
                          patient: patient,
                          fromCommunity: 'true',
                        },
                  )
                }
              }}
            >
              Join
            </Button>
          </div>
        </div>
      )
    }

    const renderListItem = (item, index, other) => {
      let image_url =
        item?.title === 'Autoimmune'
          ? HeartIcon
          : item?.title === 'Lupus'
          ? StomachIcon
          : item?.title === 'Inflammatory Bowel Disease(IBD)'
          ? EndocrineIcon
          : SkeletonIcon
      let font_icon =
        item?.title === 'Long Covid'
          ? faViruses
          : item?.title === 'Nutrition'
          ? faAppleAlt
          : item?.title === 'Mental Health'
          ? faBrain
          : faNotesMedical
      return (
        <div
          className={Styles.contentBackground}
          key={index}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '4%',
            cursor: 'pointer',
            backgroundColor: other ? '#F3F3F3' : Constants.primaryThemeMedium,
          }}
          onClick={() => {
            if (other) {
              addUserToCommunity([item])
            }
            history.push({
              pathname: `/patient/community/${item.title}`,
              communitId: item._id,
              community: item,
            })
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <div
              data-tip
              data-for={item?.title}
              className={Styles.rowIcon}
              style={{
                backgroundColor: Constants.primaryThemeDark,
              }}
            >
              {item?.title === 'Long Covid' ||
              item?.title === 'Nutrition' ||
              item?.title === 'Undiagnosed' ||
              item?.title === 'Mental Health' ? (
                <FontAwesomeIcon
                  icon={font_icon}
                  style={{ color: 'white', fontSize: 30 }}
                />
              ) : (
                <img
                  src={image_url}
                  style={{ height: '75%', width: '75%', alignSelf: 'center' }}
                />
              )}
            </div>
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 10,
              }}
            >
              <h5 style={{ color: Constants.primaryThemeDark }}>
                {item.title}
              </h5>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className={GlobalStyles.contentWrapper} style={{ display: 'flex' }}>
        <div
          style={{
            width: '50%',
            marginTop: 40,
            alignSelf: 'flex-start',
            padding: '0% 2%',
          }}
        >
          <h5 style={{ color: Constants.primaryTheme }}>All Circles</h5>

          <div style={{ height: '65vh', overflowY: 'scroll' }}>
            {allCircles.length !== 0 ? (
              allCircles.map((circleData, index) =>
                renderCirclesList(circleData, index),
              )
            ) : (
              <p style={{ textAlign: 'center', marginTop: 50 }}>
                You don’t have any circles scheduled, <br></br> please contact
                care provider
              </p>
            )}
          </div>
        </div>

        <div
          style={{
            width: '50%',
            marginTop: 40,
            alignSelf: 'flex-start',
            padding: '0% 2%',
          }}
        >
          <h5 style={{ color: Constants.primaryTheme }}>All Communities</h5>

          <div style={{ height: '65vh', overflowY: 'scroll' }}>
            {
              userCommunities &&
                userCommunities.length !== 0 &&
                userCommunities.map((comm, index) => {
                  return renderListItem(comm, index, false)
                })
              // : (
              //   <h3>
              //     {' '}
              //     You are not part of any community. Join by clicking the '+' sign
              //     at the top.{' '}
              //   </h3>
              // )
            }
            {otherCommunities &&
              otherCommunities.length !== 0 &&
              otherCommunities.map((comm, index) => {
                return renderListItem(comm, index, true)
              })}
          </div>
        </div>
      </div>
    )
  }

  const renderPaymentModal = () => {
    let patientData = { ...patientCore, ...firebaseUser }
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h4
          style={{
            color: Constants.primaryThemeDark,
            textAlign: 'center',
            marginBottom: '5%',
          }}
        >
          Paid Content
        </h4>

        {payNow || subscribe ? (
          payNow ? (
            <iframe
              id="payment_web"
              src={`${Config.WEB_HOST}stripe/resource-payment?patient_id=${
                patientData?.uid
              }&resource_type=circle&resource_id=${circleData._id}${
                patientData?.stripe?.customer_id
                  ? `&customer=${patientData?.stripe?.customer_id}`
                  : ''
              }`}
              title="payment"
              // style={{ height: '80vh', width: '60vh' }}
              style={{ height: '100%', width: '100%' }}
            />
          ) : (
            subscribe && (
              <iframe
                id="payment_web"
                src={`${Config.WEB_HOST}stripe/subscription?patient_id=${patientData.uid}&email=${patientData.email}`}
                title="payment"
                style={{ height: '100%', width: '100%' }}
              />
            )
          )
        ) : (
          <>
            <p
              style={{
                fontSize: '14',
                marginTop: '4vh',
                color: Constants.primaryTheme,
                textAlign: 'center',
                fontFamily: 'Avenir',
                wordWrap: 'break-word',
                position: 'relative',
              }}
            >
              {`Please pay $50/month to join the weekly circle or get subscription to view it for free.`}
            </p>
            <div
              style={{
                width: '80%',
                margin: '10px',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                onClick={() => {
                  setPayNow(true)
                }}
                className={GlobalStyles.button}
                style={{ width: 150 }}
                variant="primary"
              >
                Pay $50
              </Button>
              <Button
                onClick={() => {
                  setSubscribe(true)
                }}
                className={GlobalStyles.button}
                style={{ width: 150 }}
                variant="primary"
              >
                Get Subscription
              </Button>
            </div>
          </>
        )}
      </div>
    )
  }

  const renderLoader = () => (
    <div className={GlobalStyles.container} style={{ height: '100vh' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100%',
          height: '100%',
        }}
      >
        <div className={GlobalStyles.loader} />
      </div>
    </div>
  )

  const renderContent = () => (
    <div className={GlobalStyles.container}>
      <Header header="Communities" />
      {loading ? renderLoader() : renderCommunities()}
      {/*MODAL TO TELL USER TO ENTER CARD DETAILS */}
      <Modal
        ariaHideApp={false}
        onRequestClose={() => {
          setshowPayment(false)
          setSubscribe(false)
          setPayNow(false)
          dispatch(toggleSidebar(false))
        }}
        isOpen={showPayment}
        style={
          !subscribe && !payNow
            ? APPOINTMENT_MODAL_STYLES
            : CONFIRM_MODAL_STYLES
        }
        contentLabel="Modal"
      >
        {renderPaymentModal()}
      </Modal>
    </div>
  )

  //   if (loading) return renderLoader()
  return renderContent()
}

export default Communities
