import React, { Component } from 'react';
import firebase from '../../services/firebase.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Styles from './styles/LongCovid.module.scss';
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values';
import FancyField from 'react-fancy-field';
import AilaLogo from '../../assets/images/aila_logo.png';
export default class LoginCovid extends Component {
  constructor() {
    super();
    this.state = {
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
      q6: '',
      q7: '',
      q8: '',
      q9: '',
      q10: '',
      q11: '',
      q12: '',
      q13: '',
      q14: '',
      gender: '',
      ethnicity: '',
      postCovid: '',
      error: '',
      errorString: '',
      name: '',
      email: '',
      phNo: '',
      loading: false,
      onComplete: false,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('form data: ',event, this.state);

    const {email, phNo, name, gender, ethnicity, postCovid, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14} = this.state;
    if(
      !q1.length ||
      !q2.length ||
      !q3.length ||
      !q4.length ||
      !q5.length ||
      !q6.length ||
      !q7.length ||
      !q8.length ||
      !q9.length ||
      !q10.length ||
      !q11.length ||
      !q12.length ||
      !q13.length ||
      !q14.length
      ) {
      this.setState({
        error: true,
        errorString: 'Please Fill the Form'
      })
      return;
    }
    if(q13 ==='Yes' && (!name || name.length === 0)) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid name'
      })
      return;
    }

    if(q13 ==='Yes' && (!email || email.length === 0 || !this.validateEmail(email))) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid email address'
      })
      return;
    }

    if(q13 ==='Yes' && (!phNo || phNo.length < 10 || phNo.length >10)) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid phone number'
      })
      return;
    }

    if(q2 === 'Other' && gender.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please enter your Gender'
      })
      return;
    }

    if(q3 === 'Other' && ethnicity.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please enter your Ethnicity'
      })
      return;
    }

    if(q11 === 'Other' && postCovid.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please enter why no access to Clinic'
      })
      return;
    }

    this.setState({loading: true});

    var data = {
      ageWhenCovid: q1,
      gender: (q2 === 'Other') ? gender : q2,
      ethnicity: (q3 === 'Other') ? ethnicity : q3,
      testedCovid: q14,
      covidDiagnosis: q4,
      covidHospitalized: q5,
      covidSeverity: q6,
      expSymptoms: q7,
      docManageCovid: q8,
      pasc: q9,
      accessCare: q10,
      noAccessReason: (q11 === 'Other') ? postCovid : q11,
      virtualCare: q12,
      researchStudy: q13,
      userName: name,
      email: email,
      phoneNumber: phNo,
      created_at: new Date()
    }

    let self = this
    firebase.firestore().collection('entries_long_covid')
      .add(data)
      .then((res) => {
        console.log('successfully Added data', res);
        this.setState({
          q1: '',
          q2: '',
          q3: '',
          q4: '',
          q5: '',
          q6: '',
          q7: '',
          q8: '',
          q9: '',
          q10: '',
          q11: '',
          q12: '',
          q13: '',
          q14: '',
          gender: '',
          ethnicity: '',
          postCovid: '',
          error: '',
          errorString: '',
          name: '',
          email: '',
          phNo: '',
          loading: false,
          onComplete: true,
        })
      })
      .catch(function(error) {
        console.log('error::::::',error);
        self.setState({
          error: true,
          errorString: 'Error has occured while adding data',
          loading: false
        })
      });
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    const OnSuccess = () => {
      return(
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', paddingTop: 40}}>
          <img src={AilaLogo} style={{height: 120, width: 120}}/>
          <div style={{display: 'flex', flexDirection: 'column', padding: '20px 6px', borderRadius: 4, border: '1px solid gray', width: '90%', marginTop: 20}}>
            <svg className={GlobalStyles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className={GlobalStyles.checkmark__circle} cx="26" cy="26" r="25" fill="none"/>
              <path className={GlobalStyles.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
            <h5 style={{marginTop: 10,fontSize: 20,  textAlign: 'center', color: '#7e7e7e'}}>Thank you for Taking up the Survey!! You have successfully submitted the form.</h5>
          </div>
        </div>
      );
    }
    return (
      <div className={GlobalStyles.container} style={{overflow: 'hidden', margin: '0 auto', backgroundColor: 'white'}}>
      {
        this.state.onComplete ?
        <OnSuccess />
        :
        <div className={Styles.wrapper}>
        {
        this.state.loading ?
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
          <div className={GlobalStyles.loader} />
        </div>
        :
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%'}}>
          <p style={{fontSize: 40, marginTop: 20, fontWeight: 'bold', color: values.primaryThemeDark, fontFamily: 'Avenir-Heavy'}}>Long COVID Research Study</p>
          <p style={{fontSize: 18, marginTop: 20, color: values.primaryThemeDark, fontFamily: 'Avenir-light', textAlign: 'center'}}>
          The intention of this form is to gain interest in participation for future research studies on the topics of Long-COVID and Post-COVID Syndrome.
          Aila Health is currently pursuing several research projects with institutions around the United States. Please note that data provided in this
          document will be reserved only for research purposes with Aila Health and held secure in our private database. Should you have any questions regarding
          the integrity and/or nature of this form, please contact the Chief investigator, Shannon Sims at Shannon@ailahealth.com
          </p>
            <div >
              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>How old were you when you got COVID?</p>
              <Form.Group controlId="age" style={{margin: 10, marginLeft: 20}}>
                { [
                  '0-5', '0-6', '11-15', '16-20', '21-30', '31-40', '41-50',
                  '51-60', '61-70', '71-80', '81-90', '91 or older'
                  ].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      onClick={(val) => this.setState({q1: val.target.value})}
                      checked={this.state.q1 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>Which gender do you most identify with?</p>
              <Form.Group controlId="gender" style={{margin: 10, marginLeft: 20}}>
                { ['Female', 'Male', 'Nonbinary', 'Other'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q2: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q2 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
                { (this.state.q2 === 'Other') &&
                    <FancyField value={this.state.gender}
                              classes={Styles.textInput2}
                              label='Gender'
                              required={false}
                              name='gender'
                              onChange={(val) => this.setState({gender: val})}
                              placeholder='Enter your gender'/>}
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>What is your ethnicity?</p>
              <Form.Group controlId="ethnicity" style={{margin: 10, marginLeft: 20}}>
                { ['White', 'Black', 'Hispanic', 'Native American', 'Hawaiian/Pacific Islander', 'Asian', 'Other'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q3: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q3 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
                { (this.state.q3 === 'Other') &&
                    <FancyField value={this.state.ethnicity}
                              classes={Styles.textInput2}
                              label='Ethnicity'
                              required={false}
                              name='ethnicity'
                              onChange={(val) => this.setState({ethnicity: val})}
                              placeholder='Enter your Ethnicity'/>}
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>Have you been tested for COVID-19?  (PCR , Antigen, Antibody or T-Cell test, or a COVID diagnosis by a clinician)</p>
              <Form.Group controlId="diagnosis" style={{margin: 10, marginLeft: 20}}>
              { ['PCR', 'Antigen', 'Antibody', 'T-Cell test', 'I dont know what type of test', 'No'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q14: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q14 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>Did you receive a COVID-19 Diagnosis?</p>
              <Form.Group controlId="diagnosis" style={{margin: 10, marginLeft: 20}}>
              { ['Yes', 'No'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q4: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q4 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>Were you hospitalized as a result of a COVID-19 infection?</p>
              <Form.Group controlId="hospitalized" style={{margin: 10, marginLeft: 20}}>
              { ['Yes', 'No'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q5: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q5 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>
                Please rate the overall severity of your Long COVID symptoms on a scale of 1-10 (1 = least severe and 10 = most severe)
              </p>
              <Form.Group controlId="severity" style={{margin: 10, marginLeft: 20, }}>
                { ['1','2','3','4','5','6','7','8','9','10'].map(type => (
                  <Form.Check
                    type={'radio'}
                    label={type}
                    value={type}
                    onClick={(val) => this.setState({q6: val.target.value})}
                    style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                    checked={this.state.q6 === type}
                    classes={Styles.radioButton}
                  />
                ))
                }
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>For how long have you been experiencing Long COVID symptoms?</p>
              <Form.Group controlId="experiencing" style={{margin: 10, marginLeft: 20}}>
                { ['1-3 months', '3-6 months', 'More than 6 months'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q7: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q7 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>
              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>
                Please rate your level of agreement with the following statement: My regular doctor does not know how to manage my Long COVID Symptoms
              </p>
              <Form.Group controlId="doctor" style={{margin: 10, marginLeft: 20}}>
                { ['Strongly agree', 'Agree', 'Neutral', 'Disagree', 'Strongly disagree'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q8: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q8 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>
                Have you received a diagnosis of PASC (Post-Acute Sequela of Covid-19/ Post COVID Syndrome) from your healthcare provider?
              </p>
              <Form.Group controlId="PASC" style={{margin: 10, marginLeft: 20}}>
                { ['Yes', 'No'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q9: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q9 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>
                Have you accessed care at a Post COVID Clinic (i.e., a clinic that is specifically dedicated to supporting patient recovery from Long COVID).
              </p>
              <Form.Group controlId="postCovid" style={{margin: 10, marginLeft: 20}}>
                { ['Yes', 'No'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q10: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q10 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>

              {/* { this.state.q10 === 'No' && */}
              <>
                <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>
                  If no, why have you not accessed care at a Post COVID Clinic?
                </p>
                <Form.Group controlId="postCovid" style={{margin: 10, marginLeft: 20}}>
                  { [
                    'I didn’t know Post Covid Clinics existed',
                    'I live too far away from a Post Covid Clinic',
                    'I am managing my Long Covid symptoms with my regular doctor',
                    'I am managing my Long Covid symptoms on my own',
                    'Other',
                    ].map(type => (
                      <Form.Check
                        type={'radio'}
                        label={type}
                        value={type}
                        onClick={(val) => this.setState({q11: val.target.value})}
                        style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                        checked={this.state.q11 === type}
                        classes={Styles.radioButton}
                      />
                    ))
                  }
                  { (this.state.q11 === 'Other') &&
                      <FancyField value={this.state.postCovid}
                                classes={Styles.textInput2}
                                label='Post Covid Access'
                                required={false}
                                name='postCovid'
                                onChange={(val) => this.setState({postCovid: val})}
                                placeholder='Enter your Answer'/>}
                </Form.Group>
              </>
              {/* } */}

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>
                If no, are you interested in accessing care at a Virtual Post COVID Recovery Clinic?
              </p>
              <Form.Group controlId="virtual" style={{margin: 10, marginLeft: 20}}>
                { ['Yes', 'No'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q12: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q12 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>
                Would you be willing to participate in a research study about Long COVID? (No travel required)
              </p>
              <Form.Group controlId="research" style={{margin: 10, marginLeft: 20}}>
                { ['Yes', 'No'].map(type => (
                    <Form.Check
                      type={'radio'}
                      label={type}
                      value={type}
                      onClick={(val) => this.setState({q13: val.target.value})}
                      style={{color: values.primaryThemeDark, fontFamily: 'Avenir'}}
                      checked={this.state.q13 === type}
                      classes={Styles.radioButton}
                    />
                  ))
                }
              </Form.Group>

              <p style={{fontSize: 20, marginTop: 30, fontWeight: 'normal', color: values.primaryThemeDark}}>
                If you would like to be contacted about participating in a Long Covid study, please include your contact (name, email, phone number) information below.
              </p>

              <FancyField value={this.state.name}
                                classes={Styles.textInput1}
                                label='Name'
                                required={false}
                                name='name'
                                onChange={(val) => this.setState({name: val, error: false})}
                                placeholder='Enter your Name'/>
              <FancyField value={this.state.email}
                                classes={Styles.textInput1}
                                label='Email'
                                required={false}
                                name='email'
                                onChange={(val) => this.setState({email: val, error: false})}
                                placeholder='Enter your Email'/>
              <FancyField value={this.state.phNo}
                                classes={Styles.textInput1}
                                label='Phone Number'
                                type="number"
                                required={false}
                                name='name'
                                onChange={(val) => {
                                  if (val.length <= 10){
                                  this.setState({phNo: val, error: false})
                                  }
                                }}
                                placeholder='Enter your Phone Number'/>

            </div>

            <p style={{marginTop: 20,fontSize: 12, color: 'red', visibility: this.state.error ? 'visible' : 'hidden'}}>{this.state.errorString}</p>

            <Button
              onClick={this.handleSubmit.bind(this)}
              className={GlobalStyles.button}
              disabled={this.state.loading}
              style={{marginTop: 40, width: 200}}
              variant="primary"
            >
                {this.state.loading ? 'Loading....' : 'Submit'}
            </Button>
        </div>
        }
        </div>
      }
      </div>
    )
  }
}
