import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import {toast, ToastContainer} from "react-toastify";
import AilaLogo from "../../assets/images/aila_logo.png";
import Button from "react-bootstrap/Button";

export default class Cancel extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <div className={GlobalStyles.container} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white'}}>
        <img src={AilaLogo} style={{height: 80, width: 80}}/>
        <h5 style={{marginTop: 10, textAlign: 'center', color: '#7e7e7e'}}>Please close this window.</h5>


        <button
          onClick={() => window.ReactNativeWebView && window.ReactNativeWebView.postMessage('close')}
          className={GlobalStyles.button}
          style={{width: 200, marginTop: 40, borderRadius: 15, borderWidth: 0, height: 40, fontWeight: 'bold'}}
        >
          <p style={{color: 'white'}}>Close</p>
        </button>
      </div>
    )
  }
}
