import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Config from '../../config'
import Styles from '../Messages/styles/EducationMessages.module.scss'
import Constants from '../../values.js'
import { connect } from 'react-redux'
import { stopTimer } from '../../redux/actions'
import Select from 'react-select'
import FileViewer from '../../components/pdf/FileViewer'
import ReactHlsPlayer from 'react-hls-player'
import Modal from 'react-modal'

import { toggleSidebar } from '../../redux/actions'

let windowHeight = window.innerHeight

const axios = require('axios')

const customContentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '70%',
    height: windowHeight - 80,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}
class EducationMessages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      modalOption: {},
      modalSelectOption: {},
      fileCount: 1,
      numPages: null,
      pageNumber: 1,
      title: '',
      image_url: '',
      content_url: '',
      video_url: '',
      edu_content: [],
      finalMap: [],
      loading: true,
      selectedContent: [],
      selectedValue: '',
      medications: [],
      listEducation: [],
    }
    this.handleMultipleOptionsEducation =
      this.handleMultipleOptionsEducation.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    let { adminId, token } = this.props
    this.props.stopTimer(adminId, token)
    this.getEducationalContent()
  }

  getEducationalContent() {
    let { token } = this.props
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `education/content`,
      headers: {
        Authorization: 'JWT ' + token,
      },
    }
    this.setState({ loading: true })

    axios(config)
      .then(({ data }) => {
        const groupData = (data) => {
          let g = Object.entries(
            data.reduce(
              (r, c) => ((r[c.category] = [...(r[c.category] || []), c]), r),
              {},
            ),
          )
          return g.reduce(
            (r, c) => (r.mainlist.push({ category: c[0], sublist: c[1] }), r),
            { mainlist: [] },
          )
        }
        const finalMap1 = groupData(data)
        this.setState({ finalMap: finalMap1 })
        data.forEach((element) => {
          this.setState({
            edu_content: [
              ...this.state.edu_content,
              {
                id: element._id,
                category: element.category,
                title: element.title,
                image_url: element.image_url,
                video_url: element.video_url,
                content_url: element.content_url,
                premium: element.premium,
              },
            ],
          })
        })
        this.getEducationList()

        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('Error getting Educational content for the Patient ', err)
      })
  }

  getEducationList() {
    let data = this.state.finalMap.mainlist

    data.forEach((each) => {
      each.sublist.forEach((each1) => {
        this.setState({
          listEducation: [
            ...this.state.listEducation,
            { title: each1.title, isChecked: false },
          ],
        })
      })
    })
  }

  handleCloseModal() {
    this.props.toggleSidebar(false)
    this.setState({
      title: '',
      image_url: '',
      content_url: '',
      video_url: '',
      modalIsOpen: false,
    })
  }

  handleMultipleOptionsEducation(data) {
    this.props.parentCallback(this.state.selectedContent)

    if (data) {
      this.props.toggleSidebar(true)
      let tempData = data && data.option

      this.setState({
        title: tempData.label,
        image_url: tempData.image_url,
        content_url: tempData.content_url,
        video_url: tempData.video_url,
        modalIsOpen: true,
      })
    }
  }

  getContent(data) {
    let options = data
    let toReturn = []

    this.getUrlsMap = (data) => {
      let urlsMap = []
      if (Array.isArray(data)) {
        data.forEach((element, index) => {
          urlsMap.push({
            id: element._id,
            label: element.title,
            title: element.title,
            image_url: element.image_url,
            content_url: element.content_url,
            video_url: element.video_url,
          })
        })
      }
      return urlsMap
    }
    const groupData = (data) => {
      let g = Object.entries(
        data.reduce(
          (r, c) => ((r[c.subcategory] = [...(r[c.subcategory] || []), c]), r),
          {},
        ),
      )
      return g.reduce(
        (r, c) => (r.mainlist.push({ subcategory: c[0], sublist: c[1] }), r),
        { mainlist: [] },
      )
    }
    const finalMap1 = groupData(options)
    let data1 = finalMap1.mainlist
    data1.forEach((each) => {
      toReturn.push(
        <div style={{ width: '100%', marginTop: 20 }}>
          <h5 style={{ color: Constants.primaryTheme }}>{each.subcategory}</h5>
          <Select
            options={this.getUrlsMap(each.sublist)}
            placeholder={each.subcategory}
            isMulti
            isClearable={false}
            onChange={(val, option) => {
              // console.log(option)
              if (option.action === 'select-option') {
                this.setState(
                  {
                    selectedContent: [
                      ...this.state.selectedContent,
                      option.option,
                    ],
                  },
                  () => this.handleMultipleOptionsEducation(),
                )
              } else if (
                option.action === 'remove-value' ||
                option.action === 'pop-value'
              ) {
                this.setState(
                  {
                    selectedContent: this.state.selectedContent.filter(
                      (opt) => opt.id !== option.removedValue.id,
                    ),
                  },
                  () => this.handleMultipleOptionsEducation(),
                )
              }
            }}
            getOptionValue={(option) => option.label}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>,
      )
    })
    return toReturn
  }

  renderList() {
    let data = this.state.finalMap.mainlist

    this.getUrlsMap = (data) => {
      let urlsMap = []
      if (Array.isArray(data)) {
        data.forEach((element, index) => {
          urlsMap.push({
            id: element._id,
            label: element.title,
            title: element.title,
            image_url: element.image_url,
            content_url: element.content_url,
            video_url: element.video_url,
          })
        })
      }
      return urlsMap
    }
    let toReturn = []
    data.forEach((each) => {
      toReturn.push(
        <div
          style={{
            width: '40%',
            margin: 20,
            // visibility:
            //   each.category === 'Condition Education' ? 'hidden' : 'visible',
          }}
        >
          <h4 style={{ color: Constants.primaryTheme }}>{each.category}</h4>
          {each.category === 'Condition Education' ? (
            this.getContent(each.sublist)
          ) : (
            <Select
              options={this.getUrlsMap(each.sublist)}
              isSearchable={true}
              placeholder={each.category}
              isMulti
              isClearable={false}
              onChange={(val, option) => {
                // console.log(option)
                if (option.action === 'select-option') {
                  this.setState(
                    {
                      selectedContent: [
                        ...this.state.selectedContent,
                        option.option,
                      ],
                    },
                    () => this.handleMultipleOptionsEducation(option),
                  )
                } else if (
                  option.action === 'remove-value' ||
                  option.action === 'pop-value'
                ) {
                  this.setState(
                    {
                      selectedContent: this.state.selectedContent.filter(
                        (opt) => opt.id !== option.removedValue.id,
                      ),
                    },
                    () => this.handleMultipleOptionsEducation(),
                  )
                }
              }}
              getOptionValue={(option) => option.label}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          )}
        </div>,
      )
    })

    return toReturn
  }

  renderModalContent() {
    let { title, image_url, video_url, content_url } = this.state
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignContent: 'center',
        }}
      >
        <p className={Styles.closeModalBtn} onClick={this.handleCloseModal}>
          x
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          {title}
        </h4>
        {image_url && (
          <div
            style={{
              overflowBlock: 'hidden',
              marginTop: '10px',
              height: '30vh',
            }}
          >
            <FileViewer fileType="jpg" fileName={image_url} />
          </div>
        )}

        {video_url && (
          <div
            style={{
              overflow: 'hidden',
              marginTop: '20px',
              width: '80%',
              alignSelf: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <FileViewer fileType="m3u8" fileName={video_url} /> */}

            <ReactHlsPlayer
              src={video_url}
              autoPlay={true}
              controls={true}
              width="100%"
              height="auto"
            />
          </div>
        )}
        {content_url && (
          <iframe
            src={content_url + '#toolbar=0'}
            style={{ height: '80vh', width: '100%', margin: '20px 20px' }}
          ></iframe>
        )}
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={GlobalStyles.container} style={{ height: '100vh' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: '14px 20px 20px 20px',
          margin: '15px',
          backgroundColor: 'white',
          borderRadius: '20px',
        }}
      >
        {this.renderList()}

        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.handleCloseModal}
          style={customContentModalStyles}
          contentLabel="Modal"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {this.renderModalContent()}
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    adminId: state.userReducer.adminId,
  }
}

const mapDispatchToProps = { stopTimer, toggleSidebar }

export default connect(mapStateToProps, mapDispatchToProps)(EducationMessages)
