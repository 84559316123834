import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
import downloadapp from '../../assets/images/downloadapp.png'

const DOWNLOAD_LINK = 'https://www.ailahealth.com/ailahealthapp'

export default class AilaDownloadApp extends Component {
  constructor() {
    super()
    this.state = {
      download: false,
    }
    this.renderMainContent2 = this.renderMainContent2.bind(this)
    this.renderMainContent1 = this.renderMainContent1.bind(this)
  }

  renderMainContent1 = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            margin: 'auto',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '2vh',
          }}
        >
          You have successfully booked your onboarding appointment with Aila
          Health.
          <br />
          We look forward to having you as a part of
          <br />
          the Aila Community!
        </p>
      </div>
    )
  }

  renderMainContent2 = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            margin: 'auto',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '2vh',
          }}
        >
          You have successfully signed up to take control of your <br />
          symptoms with Aila Health.
          <br />
          <br />
          We look forward to having you as a part of
          <br />
          the Aila Community!
        </p>
      </div>
    )
  }
  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            alignSelf: 'center',
            marginTop: '-8vh',
          }}
        >
          <img
            alt="Aila Health"
            src={downloadapp}
            style={{
              width: '355px',
              height: '350px',
              alignSelf: 'center',
            }}
          />
        </div>
        {this.props?.location?.state?.patient_type
          ? this.renderMainContent1()
          : this.renderMainContent2()}

        <div
          style={{
            flex: 1,
            alignSelf: 'center',
          }}
        >
          <Button
            onClick={(e) => {
              this.props.history.push('/login')
              window.open(DOWNLOAD_LINK)
              // e.preventDefault()
              // window.location.href = DOWNLOAD_LINK
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '2vh',
              alignSelf: 'center',
            }}
            variant="primary"
          >
            {'Download the app now'}
          </Button>
        </div>
      </div>
    )
  }
}
