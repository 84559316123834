/* eslint-disable */
import moment from 'moment'
import React, { useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Constants from '../../../../values'
import GlobalStyles from '../../../styles/global.module.scss'
import Styles from '../styles/profile.module.scss'

import {} from '@fortawesome/free-regular-svg-icons'
import Button from 'react-bootstrap/Button'
import {
  connectCurrentRefinements,
  connectSearchBox,
} from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'
import Config from '../../../../config'

const axios = require('axios')

import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'

const allergiesConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'allergies',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigAllergies = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: allergiesConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      code: { raw: {} },
      display: { raw: {} },
      object_id: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

const ClearQuery = ({ refine }) => {
  const onClick = (ev) => {
    refine('')
    ev.stopPropagation()
  }
  return (
    <div onClick={onClick} style={{ display: 'none' }}>
      <ConnectedClearAllRefinements />
    </div>
  )
}

const ClearAllRefinements = ({ refine, items }) => {
  const onClick = () => refine(items)
  return (
    <button id="clearQuery" onClick={onClick}>
      ClearAll
    </button>
  )
}

const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
const ConnectedClearAllRefinements =
  connectCurrentRefinements(ClearAllRefinements)

export const RenderAddAllergiesManually = ({
  onManualAddModalHide,
  getProviderAllergies,
  currentAddModalData,
  patientId,
}) => {
  console.log('INSIDE::::::::', currentAddModalData)
  const token = useSelector((state) => state.authReducer.token)
  const patient = patientId
    ? { patient_id: patientId }
    : useSelector((state) => state.patientReducer.patient)
  const doctor_id = useSelector((state) => state.userReducer.adminId)
  const doctor_details = useSelector((state) => state.userReducer.mongoUser)
  const appointmentData = useSelector(
    (state) => state.appointmentReducer.appointmentData,
  )
  const showVideoModal = useSelector(
    (state) => state.genericReducer.showVideoModal,
  )

  const [allergy, setAllergy] = useState(
    currentAddModalData?.code?.coding
      ? {
          id: currentAddModalData?.code?.coding?.[0]?.code,
          name: currentAddModalData?.code?.coding?.[0]?.display,
        }
      : {},
  )
  const [clinicalStatus, setClinicalStatus] = useState(
    currentAddModalData?.clinicalStatus?.coding
      ? {
          code: currentAddModalData?.clinicalStatus?.coding?.[0]?.code,
          label: currentAddModalData?.clinicalStatus?.coding?.[0]?.display,
        }
      : {},
  )
  const [verificationStatus, setVerificationStatus] = useState(
    currentAddModalData?.verificationStatus?.coding
      ? {
          code: currentAddModalData?.verificationStatus?.coding?.[0]?.code,
          label: currentAddModalData?.verificationStatus?.coding?.[0]?.display,
        }
      : {},
  )
  // const [type, setType] = useState(currentAddModalData.type)
  const [category, setCategory] = useState(
    currentAddModalData?.category
      ? {
          code: currentAddModalData?.category[0],
          label: currentAddModalData?.category[0],
        }
      : {},
  )
  const [criticality, setCriticality] = useState(
    currentAddModalData?.criticality
      ? {
          code: currentAddModalData?.criticality,
          label: currentAddModalData?.criticality,
        }
      : {},
  )
  const [allergyStartDate, setAllergyStartDate] = useState(
    currentAddModalData?.onsetDateTime || '',
  )
  const [allergyLastOccurenceDate, setAllergyLastOccurenceDate] = useState(
    currentAddModalData?.lastOccurrence || '',
  )
  const [noteValue, setNoteValue] = useState(
    currentAddModalData?.note?.[0] || '',
  )

  const [conditionQuery, setConditionQuery] = useState('')

  const onClickSave = () => {
    if (!allergy?.name) {
      toast.error('Please select Allergy')
      return
    }

    let data = {
      patient_id: patient?.patient_id,
      doctor_id: doctor_id,
      name: allergy,
      resourceType: 'AllergyIntolerance',
      clinicalStatus: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical', // Identity of the terminology system
            code: clinicalStatus.code, // Symbol in syntax defined by the system
            display: clinicalStatus.label, // Representation defined by the system
          },
        ],
      },
      verificationStatus: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification', // Identity of the terminology system
            code: verificationStatus.code, // Symbol in syntax defined by the system
            display: verificationStatus.label, // Representation defined by the system
          },
        ],
      },
      code: {
        coding: [
          {
            system: 'http://snomed.info/sct', // Identity of the terminology system
            code: allergy.id, // Symbol in syntax defined by the system
            display: allergy.name, // Representation defined by the system
          },
        ],
      },

      // type: type,
      category: [category.code],
      criticality: criticality.label,
      patient: {
        reference: `Patient/${patient?.patient_id}`,
        // display: `${patient?.first_name} ${patient?.last_name}`
      },
      onsetDateTime: allergyStartDate,
      lastOccurrence: allergyLastOccurenceDate,
      recorder: {
        reference: `${doctor_details.user_type}/${doctor_id}`,
        // display: `${doctor_details.first_name} ${doctor_details.last_name}`
      },
      note: [noteValue],
      recordedDate: new Date(),
      ...(appointmentData?.event_id &&
        showVideoModal && { appointment_event_id: appointmentData?.event_id }),
    }

    console.log('SAVED DATA: ', data)
    let config = {
      method: currentAddModalData ? 'put' : 'post',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
      url:
        Config.BACKEND_URL +
        (currentAddModalData?._id
          ? `fhir/AllergyIntolerance/${currentAddModalData?._id}`
          : 'fhir/AllergyIntolerance'),
      data: data,
    }

    axios(config)
      .then((resp) => {
        onManualAddModalHide()
        getProviderAllergies()
        console.log('SAVED DATA: ', data)
      })
      .catch((err) => {
        console.log('error when getting saving provider goals data', err)
      })
  }
  const Hit = (props) => {
    let { hit } = props
    // console.log("HITS ALLERGIES: ", hit)
    const handleClick = () => {
      eventFire(document.getElementById('clearQuery'), 'click')
      setAllergy({ id: hit.Code, name: hit.Display })
      setConditionQuery('')
    }
    return (
      <div
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={(e) => {
          handleClick(e)
        }}
      >
        {hit.Code} : {hit.Display}
      </div>
    )
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div
        style={{
          height: '90%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => onManualAddModalHide()}
        >
          X
        </p>
        <p
          className={Styles.closeModalBtn}
          onClick={() => onManualAddModalHide()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Allergies
        </h4>

        <div className={Styles.addManualRow}>
          <div className={Styles.addManualQuestion}>
            {/* <InstantSearch
                indexName={'ALLERGIES'}
                searchClient={condnClient}
                onSearchStateChange={({ query }) => setConditionQuery(query)}
              >
                <SearchBox
                  translations={{
                    placeholder: 'Search allergies',
                  }}
                />
                <ClearQueryAndRefinements />
                {conditionQuery.length !== 0 && (
                  <div
                    style={{
                      overflow: 'auto',
                      height: 150,
                      backgroundColor: '#F1F1F1',
                    }}
                  >
                    <RefinementList limit={5} />
                    <Hits hitComponent={(props) => Hit(props)} />
                  </div>
                )}
              </InstantSearch> */}
            <SearchProvider config={esConfigAllergies}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'search_term',
                        }}
                        onSelectAutocomplete={(e) => {
                          setAllergy({ id: e.code.raw, name: e.display.raw })
                          setSearchTerm('')
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search allergies',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
          <p className={Styles.selectedPtag}>
            {allergy?.id ? `${allergy?.id} - ${allergy?.name}` : ''}
          </p>
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Clinical Status: </p>
          <Select
            options={[
              { code: 'active', label: 'Active' },
              { code: 'inactive', label: 'Inactive' },
              { code: 'resolved', label: 'Resolved' },
            ]}
            isSearchable={true}
            placeholder="Select status"
            onChange={(val) => setClinicalStatus(val)}
            value={clinicalStatus}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Verification Status: </p>
          <Select
            options={[
              { code: 'unconfirmed', label: 'Unconfirmed' },
              { code: 'confirmed', label: 'Confirmed' },
              { code: 'refuted', label: 'Refuted' },
              { code: 'entered-in-error', label: 'Entered in Error' },
            ]}
            isSearchable={true}
            placeholder="Select status"
            onChange={(val) => setVerificationStatus(val)}
            value={verificationStatus}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Category: </p>
          <Select
            options={[
              { code: 'food', label: 'Food' },
              { code: 'medication', label: 'Medication' },
              { code: 'environment', label: 'Environment' },
              { code: 'biological', label: 'Biologic' },
            ]}
            isSearchable={true}
            placeholder="Select category"
            onChange={(val) => setCategory(val)}
            value={category}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Criticality: </p>
          <Select
            options={[
              { code: 'low', label: 'Low' },
              { code: 'high', label: 'High' },
              { code: 'unable-to-access', label: 'Unable to access' },
            ]}
            isSearchable={true}
            placeholder="Select criticality"
            onChange={(val) => setCriticality(val)}
            value={criticality}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Start Date: </p>
          <input
            placeholder={
              'Please enter estimated or actual date the allergy began'
            }
            className={Styles.addManualTextInput}
            type="date"
            max={moment(new Date()).format('YYYY-MM-DD')}
            value={moment(allergyStartDate).format('YYYY-MM-DD')}
            onChange={(e) => setAllergyStartDate(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Last Occurence: </p>
          <input
            placeholder={
              'Please enter estimated date that the allergy last occured'
            }
            className={Styles.addManualTextInput}
            type="date"
            max={moment(new Date()).format('YYYY-MM-DD')}
            value={moment(allergyLastOccurenceDate).format('YYYY-MM-DD')}
            onChange={(e) => setAllergyLastOccurenceDate(e.target.value)}
          />
        </div>
        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Note: </p>
          <textarea
            placeholder={'Please enter notes about allergy'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '70%',
              // height: '40px',
            }}
            rows="2"
            type="text"
            value={noteValue}
            onChange={(e) => setNoteValue(e.target.value)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => onClickSave()}
          className={GlobalStyles.button}
          // disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}
