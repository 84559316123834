// active one which we use everywhere
import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import { toast, ToastContainer } from 'react-toastify'
import {
  faPhone,
  faBolt,
  faAppleAlt,
  faBrain,
  faMoon,
  faFireAlt,
  faCheckCircle,
  faCheck,
  faTimes,
  faGreaterThan,
} from '@fortawesome/free-solid-svg-icons'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import Config from '../../config'
import Styles from './styles/MobileSubscription.module.scss'
import Constants from '../../values'
import AilaLogo from '../../assets/images/aila_logo.png'
import CommImage from '../../assets/images/comm_1.png'
import firebase from '../../services/firebase.js'
import Modal from 'react-modal'
const queryString = require('query-string')
const moment = require('moment')
const axios = require('axios')

const TOS_LINK = 'https://www.portal.ailahealth.com/ccfm/tos'
const TEXT_COLOR = '#7E7E7E'

const CCFM_STATES = {
  az: true,
  md: true,
  nj: true,
  ny: true,
  or: true,
  va: true,
  vt: true,
  wa: true,
  mn: true,
}

const AILA_STATES = {
  co: true,
  ca: true,
  fl: true,
  tx: true,
}

const CCFM_VALUE_PROPS = [
  'Unlimited Messaging',
  'Dedicated Health Coach',
  'Personalized Nutrition Plans',
  'Mental Health Support',
  'Educational Content',
  'Mindfulness Program',
  'Long COVID Community Circles',
  'Includes 60 minute initial clinical evaluation and 30 minute follow up',
]

// const AILA_VALUE_PROPS = [
//   'Unlimited Messaging',
//   'Dedicated Health Coach',
//   'Personalized Nutrition Plans',
//   'Mental Health Support',
//   'Educational Content',
//   'Mindfulness Program',
//   'Patient Community Circles',
//   'Includes 1 clinical visit per month',
// ]

const AILA_VALUE_PROPS = [
  'Monthly Membership Fee',
  'Annual Membership Fee',
  // 'Nutrition Coaching',
  'Doctor Visit',
  'Health Coaching',
  'Personalized Meal Plans',
  'Patient Community',
  'Symptom Tracker',
]

const BASIC_PROPS = [
  '$0/Month',
  'None',
  // '$50',
  '$150',
  '$50',
  '-',
  'Included',
  'Included',
]

const MEMBER_PROPS = [
  '$30/Month',
  '$25/Month',
  // '$50',
  '$150',
  '1 Included',
  'Included',
  'Included',
  'Included',
]
const PREMIUM_PROPS = [
  '$200/Month',
  '$150/Month',
  // '$50',
  '1 Included',
  '1 Included',
  'Included',
  'Included',
  'Included',
]

export default class MobileSubsciption extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      token: null,
      patientId: null,
      patientDetails: null,
      patientSubscribed: false,
      subscriptionActive: false,
      subscriptionCancelledAtEndOfPeriod: false,
      patientEmail: null,
      state: null,
      medicare: false,
      firebaseUser: null,
      mongoUser: null,
      tosChecked: false,
      showCollapsible: {
        basic: { show: false },
        member: { show: false },
        premium: { show: false },
      },
      expandBasic: true,
      expandMember: true,
      expandPremium: true,
      event_id: '',
      setConfirmCustomModal: false,
      customModalValue: '',
      preferred_sub: '',
      connecting_page: '',
    }
    this.createCheckoutSession = this.createCheckoutSession.bind(this)
    this.getAuthToken = this.getAuthToken.bind(this)
    this.getUserDetails = this.getUserDetails.bind(this)
    this.getCustomerPortalUrl = this.getCustomerPortalUrl.bind(this)
    this.onPlanClicked = this.onPlanClicked.bind(this)
    this.getFirebaseUser = this.getFirebaseUser.bind(this)
    this.setupStatesBasedOnUser = this.setupStatesBasedOnUser.bind(this)
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search)
    console.log('parsed', parsed)
    const patientId = parsed.patient_id
    const patientEmail = parsed.email
    const pref_sub = parsed.pref_sub
    const connect = parsed.connecting_page
    if (parsed.state && parsed.state !== 'undefined') {
      this.setState({ state: parsed.state })
    }
    if (!patientId || !patientEmail) {
      console.log('missing info')
      toast.error('Missing information ')
    } else {
      this.setState({ event_id: parsed.event_id })
      this.getAuthToken(patientId, patientEmail)
      this.getFirebaseUser(patientId)
      if (pref_sub) {
        this.setState({ preferred_sub: pref_sub })
      }
      if (connect) {
        console.log(connect)
        this.setState({ connecting_page: connect })
      }
    }
  }

  async getFirebaseUser(uid) {
    firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get()
      .then((doc) => {
        console.log('got firebase user')
        this.setState({ firebaseUser: doc.data() }, () => {
          if (!this.state?.state?.length) this.setupStatesBasedOnUser()
        })
      })
      .catch((err) => {
        console.log('error when getting firebas euser', err)
      })
  }

  async getAuthToken(patientId, patientEmail) {
    const url = `${Config.BACKEND_URL}authorization`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_firebase_id: patientId },
        url,
      })
      const token = response.data && response.data.token
      this.setState({ token, patientId, patientEmail })
      this.getUserDetails(patientId, token)
    } catch (err) {
      console.log('error when auth token', err)
      toast.error('Error when getting authorization from backend')
    }
  }

  async getUserDetails(patientId, token) {
    const url = `${Config.BACKEND_URL}users`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_firebase_id: patientId, Authorization: `JWT ${token}` },
        url,
      })
      console.log('user id', response)
      const user = response.data
      this.setState({ patientDetails: user })
      // const { stripe } = user
      console.log('got user data', user)
      if (user?.medicare || user?.medicaid) {
        this.setState({ medicare: true })
      }
      if (
        user &&
        user?.stripe &&
        user?.stripe?.customer_id &&
        user?.stripe?.checkout_id &&
        user?.coaching
      ) {
        this.setState({ mongoUser: user })
        if (!this.state?.state?.length) {
          this.setupStatesBasedOnUser()
        }
        // patient has subscribed at least once in Aila's history
        const today = Math.floor(Date.now() / 1000)
        console.log('today is', new Date(today * 1000))
        if (today >= user?.stripe?.period_start && today <= user?.stripe?.period_end) {
          console.log('subscription active')
          // active subscription
          this.setState({ subscriptionActive: true })

          // if user has cancelled subscription at the end of this period, subscription flag will be false even though dates are active
          if (!user?.stripe?.subscription) {
            this.setState({ subscriptionCancelledAtEndOfPeriod: true })
          }
        }
        this.setState({ patientSubscribed: true, patientDetails: user })
      }
      this.setState({ loading: false })
    } catch (err) {
      console.log('error when getting user details', err)
      toast.error('Something went wrong')
    }
  }

  setupStatesBasedOnUser() {
    let { mongoUser, firebaseUser } = this.state
    if (
      !firebaseUser
      // || !mongoUser
    )
      return

    let { addressComponents, zipCodeData } = firebaseUser
    let state = null
    if (addressComponents && addressComponents.length > 0) {
      addressComponents.forEach((addressComponent) => {
        let types = addressComponent.types || []
        if (types.includes('administrative_area_level_1'))
          state = addressComponent.short_name
      })
      console.log('user state is', state)
    } else {
      state = zipCodeData?.state
    }
    this.setState({ state: state })

    // const { stripe } = mongoUser
    // if (stripe && stripe.customer_id && stripe.checkout_id) {
    //   // patient has subscribed at least once in Aila's history
    //   const today = Math.floor(Date.now() / 1000)
    //   console.log('today is', new Date(today * 1000))
    //   if (today >= stripe.period_start && today <= stripe.period_end) {
    //     console.log('subscription active')
    //     // active subscription
    //     this.setState({ subscriptionActive: true })

    //     // if user has cancelled subscription at the end of this period, subscription flag will be false even though dates are active
    //     if (!stripe.subscription) {
    //       this.setState({ subscriptionCancelledAtEndOfPeriod: true })
    //     }
    //   }
    //   this.setState({ patientSubscribed: true, patientDetails: mongoUser })
    // }

    this.setState({ loading: false })
  }

  async getCustomerPortalUrl() {
    this.setState({ loading: true })
    const { patientId, token } = this.state
    const url = `${Config.BACKEND_URL}stripe/customer-portal`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_patient_id: patientId, Authorization: `JWT ${token}` },
        url,
      })
      if (response.data && response.data.url) {
        this.setState({ loading: false })
        window.location.href = response.data.url
      }
    } catch (err) {
      console.log('error when getting customer portal url ', err)
      toast.error('Something went wrong')
    }
  }

  onPlanClicked(priceId, planType) {
    console.log('on plan clicked', priceId)
    this.setState({ setConfirmCustomModal: false, customModalValue: '' })
    // let { state, tosChecked } = this.state
    // if (CCFM_STATES[state?.toLowerCase()] && !tosChecked) {
    //   //if they are in ccfm states and haven't accepted the terms
    //   window.alert('Please read and accept terms of agreement')
    //   return
    // }
    this.createCheckoutSession(priceId, true, planType)
  }

  async createCheckoutSession(priceId, couponEnabled, planType) {
    const { token, patientId, patientEmail, state } = this.state
    const url = `${Config.BACKEND_URL}stripe/sessions`
    const stripePublishableKey = Config.STRIPE_PUBLISHABLE_API_KEY
    console.log(
      'requesting sessionf for price id',
      priceId,
      couponEnabled,
      planType,
    )
    this.setState({ loading: true })
    try {
      const stripe = await loadStripe(stripePublishableKey)
      let headerData = {
        Authorization: `JWT ${token}`,
        stripe_price_id: priceId,
        x_patient_id: patientId,
        email: patientEmail,
        couponEnabled: true,
        plan: planType,
        web_event_id: this.state.event_id,
      }
      // if (
      //   priceId === Config.STRIPE_CCFM_LONG_COVID_3MONTH &&
      //   CCFM_STATES[state?.toLowerCase()]
      // ) {
      //   headerData.ccfm = true
      // }
      const response = await axios({
        method: 'get',
        headers: headerData,
        url,
      })

      const { data } = response
      this.setState({ loading: false })
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId,
        })
        .then(() => {
          console.log('stripe success')
        })
        .catch((err) => {
          console.log('error when creating stripe session', err)
        })
    } catch (err) {
      console.log('error when creating session', err)
      this.setState({ loading: false })
    }
  }

  renderValueProps() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '6px 0px',
          borderRadius: 4,
          border: '1px solid gray',
          width: '96%',
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            width: '100%',
            textAlign: 'center',
            color: Constants.primaryTheme,
          }}
        >
          $99 / month
        </p>
        <p style={{ textAlign: 'center', color: '#7e7e7e' }}>or</p>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            width: '100%',
            textAlign: 'center',
            color: Constants.primaryTheme,
          }}
        >
          $250 / 3 months
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faBolt}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Exercise Recommendations
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faAppleAlt}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Nutrition and Meal Planning
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faBrain}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Mental Well-Being and Mindfulness Training
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faMoon}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Sleep Optimization Training
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faFireAlt}
                className={Styles.propIcon}
              />
            </div>

            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              Condition and Flare Management
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '45%',
              margin: '6px 2px',
              alignItems: 'center',
            }}
          >
            <div className={Styles.propIconWrapper}>
              <FontAwesomeIcon
                onClick={() =>
                  this.setState({ modalIsOpen: true, modalGoals: true })
                }
                icon={faPhone}
                className={Styles.propIcon}
              />
            </div>
            <p
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 13,
                color: TEXT_COLOR,
              }}
            >
              1 Virtual Visit and Unlimited Texting
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderNotAvailableContent() {
    if (this.state.preferred_sub) {
      this.onPlanClicked(Config.STRIPE_MEMBERSHIP_MONTHLY, 'membership')
      return
    }
    let windowWidth = window.innerWidth
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <h1
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 20,
            // fontSize: 19,
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Select your plan
        </h1>
        <h5
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 20,
            // fontSize: 19,
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Health Coaching Membership
        </h5>

        {/* <h6
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            // fontWeight: 'bold',
            marginBottom: 20,
            fontSize: 17,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Keep your Basic plan for free or subscribe to a Membership plan and
          <span style={{ color: 'red' }}> save up to 50% </span>
          on doctor’s visits and personalized health coaching
        </h6> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '60%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {this.renderValuePropsBoxAila(BASIC_PROPS, 'basic', false)}
          {this.renderValuePropsBoxAila(MEMBER_PROPS, 'member', true)}
          {/* {this.renderValuePropsBoxAila(PREMIUM_PROPS, 'premium', false)} */}
        </div>

        <p style={{ fontSize: 14, color: TEXT_COLOR }}>
          {/* * Your subscription will start on {start.format('Do MMM YYYY')} */}
          * Your subscription will start on {moment().format('Do MMM YYYY')} and
          will auto-renew each month. See{' '}
          <a
            href={Config.TOS_LINK}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecorationLine: 'underline' }}
          >
            Terms of Use
          </a>{' '}
          for further details.
          <br />* Credit card processing fee is applicable
        </p>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ailahealth.com/ailahealthapp"
        >
          <button
            onClick={null}
            className={GlobalStyles.button}
            style={{
              width: 200,
              marginTop: 40,
              borderRadius: 15,
              borderWidth: 0,
              height: 40,
              fontWeight: 'bold',
            }}
          >
            <p style={{ color: 'white' }}>Download Now</p>
          </button>
        </a> */}
      </div>
    )
  }

  renderValuePropsBoxAila = (valueProps, planType, showPopular) => {
    let windowWidth = window.innerWidth
    let elements = []
    elements =
      valueProps &&
      valueProps.map((x, i) => {
        if (AILA_VALUE_PROPS[i] === 'Doctor Visit' && planType !== 'premium') {
          return
        } else if (
          (AILA_VALUE_PROPS[i] === 'Annual Membership Fee' ||
            AILA_VALUE_PROPS[i] === 'Health Coaching') &&
          planType === 'basic'
        ) {
          return
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 8,
                justifyContent: 'space-between',
                borderBottom: '1px solid #DEDEDE',
              }}
            >
              {AILA_VALUE_PROPS[i]}
              {x === 'Included' ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className={Styles.propIcon}
                  style={{ marginRight: 10 }}
                />
              ) : x === '-' ? (
                <FontAwesomeIcon
                  icon={faTimes}
                  className={Styles.propIcon}
                  style={{ marginRight: 10, color: 'red' }}
                />
              ) : (
                <p style={{ color: Constants.primaryThemeDark }}>{x}</p>
              )}
            </div>
          )
        }
      })

    return (
      <div
        // onClick={() => {
        //   if (planType === 'basic') {
        //     this.setState({ expandBasic: !this.state.expandBasic })
        //   } else if (planType === 'member') {
        //     this.setState({ expandMember: !this.state.expandMember })
        //   } else if (planType === 'premium') {
        //     this.setState({ expandPremium: !this.state.expandPremium })
        //   }
        // }}
        className={Styles.valuePropsBoxAila}
        style={{
          margin: '2%',
          cursor: 'pointer',
          alignSelf: 'center',
          position: 'relative',
        }}
      >
        {/* <FontAwesomeIcon
          icon={faGreaterThan}
          className={Styles.propIcon1}
          style={{ top: 0, right: 0, position: 'absolute', margin: 10 }}
        /> */}
        <h4
          style={{
            color: '#6C6C6C',
            marginBottom: 10,
            // fontSize: 19,
            fontWeight: 'bold',
            // textDecoration: 'underline',
          }}
        >
          {planType === 'basic'
            ? 'Basic'
            : planType === 'member'
            ? 'Membership'
            : 'All Access'}
        </h4>

        {/* <h6
          style={{
            marginTop: 10,
            marginBottom: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {planType === 'member'
            ? '$30/Month'
            : planType === 'premium'
            ? '$200/Month'
            : 'Free'}
        </h6> */}

        {/* <p
          style={{
            fontSize: 14,
            color: TEXT_COLOR,
            marginTop: -10,
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          {planType === 'member'
            ? ''
            : planType === 'premium'
            ? '(Includes comprehensive care plan and doctor’s visits)'
            : '(Includes patient community)'}
        </p> */}

        <div
          style={{
            display:
              (planType === 'basic' && this.state.expandBasic) ||
              (planType === 'member' && this.state.expandMember) ||
              (planType === 'premium' && this.state.expandPremium)
                ? 'flex'
                : 'none',
            flexDirection: 'column',
            width: '100%',
            padding: '5% 2%',
          }}
        >
          {elements}

          {/* {planType === 'premium' && (
            <p
              style={{
                color: 'gray',
                fontSize: 14,
                width: '100%',
                marginTop: '6px',
              }}
            >
              * Subscribe and save 3 Months for $375
            </p>
          )} */}

          {/* {planType === 'premium' && (
            <button
              onClick={() => {
                if (planType === 'premium')
                  this.onPlanClicked(
                    Config.STRIPE_AILA_CLINIC_3MONTH,
                    'premium',
                  )
              }}
              className={GlobalStyles.button}
              style={{
                width: 200,
                margin: 'auto',
                borderRadius: 15,
                borderWidth: 0,
                height: 40,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p style={{ color: 'white' }}>Subscribe for 3 months</p>
            </button>
          )} */}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            display: 'table',
            textAlign: 'center',
            position: 'absolute',
            bottom: '5%',
          }}
        >
          {showPopular && (
            <div
              style={{
                backgroundColor: 'orange',
                width: 140,
                borderRadius: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 'auto',
                // visibility: showPopular ? 'visible' : 'hidden',
              }}
            >
              <p style={{ color: 'white' }}>Most Popular</p>
            </div>
          )}
          <button
            // disabled={planType === 'basic' ? true : false}
            onClick={() => {
              if (planType === 'basic' && this.state.connecting_page) {
                window.open(Config.WEB_HOST + this.state.connecting_page)
                window.top.location.replace(Config.WEB_HOST + 'login')
                // this.props.history.push(this.state.connecting_page)
              } else if (planType === 'member')
                this.setState({
                  setConfirmCustomModal: true,
                  customModalValue: 'member',
                })
              // this.onPlanClicked(
              //   Config.STRIPE_HEALTH_COACHING_3MONTH,
              //   'membership',
              // )
              else if (planType === 'premium') {
                this.setState({
                  setConfirmCustomModal: true,
                  customModalValue: 'premium',
                })
                // this.onPlanClicked(Config.STRIPE_AILA_CLINIC_1MONTH, 'premium')
              }
            }}
            className={GlobalStyles.button}
            style={{
              width: 200,
              margin: '20px auto',
              borderRadius: 15,
              borderWidth: 0,
              height: 40,
              fontWeight: 'bold',
              display: 'table-cell',
              verticalAlign: 'bottom',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ color: 'white' }}>
              {/* {planType === 'basic' ? 'Subscribed' : 'Subscribe'} */}
              Subscribe
            </p>
          </button>
        </div>
      </div>
    )
  }

  renderValuePropsBoxCCFM = (valueProps) => {
    let elements = []
    elements =
      valueProps &&
      valueProps.map((x) => (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={Styles.propIcon}
            style={{ marginRight: 10 }}
          />
          <p style={{ color: Constants.primaryThemeDark }}>{x}</p>
        </div>
      ))

    return (
      <div
        // onClick={() => {
        //   this.onPlanClicked(Config.STRIPE_CCFM_LONG_COVID_3MONTH, 'premium')
        // }}
        // className={Styles.valuePropsBoxCCFM}
        // style={{ width: '80%' }}
        className={Styles.valuePropsBoxAila}
        style={{
          margin: 20,
          cursor: 'pointer',
          alignSelf: 'center',
          position: 'relative',
        }}
      >
        <h5
          style={{
            color: Constants.primaryThemeDark,
            marginBottom: 10,
            fontSize: 19,
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          Long COVID Program
        </h5>

        <h5
          style={{
            color: 'gray',
            marginBottom: 20,
            fontSize: 19,
            fontWeight: 'bold',
          }}
        >
          $375 for 3 months
        </h5>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '0px 10px',
          }}
        >
          {elements}
        </div>

        <p
          style={{
            color: 'gray',
            fontSize: 14,
            marginTop: 20,
          }}
        >
          * Additional processing charges are applicable at checkout
        </p>

        <button
          onClick={() => {
            this.onPlanClicked(Config.STRIPE_CCFM_LONG_COVID_3MONTH, 'premium')
          }}
          className={GlobalStyles.button}
          style={{
            width: 200,
            margin: '20px auto',
            borderRadius: 15,
            borderWidth: 0,
            height: 40,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p style={{ color: 'white' }}>Subscribe</p>
        </button>
      </div>
    )
  }

  renderCCFMContent() {
    const start = moment()
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <h1
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 20,
            // fontSize: 19,
          }}
        >
          Select your plan
        </h1>

        <div className={Styles.valuePropsWrapper}>
          {this.renderValuePropsBoxCCFM(CCFM_VALUE_PROPS)}
          {this.renderValuePropsBoxAila(MEMBER_PROPS, 'member', true)}
        </div>

        <p style={{ fontSize: 14, color: TEXT_COLOR }}>
          * Your subscription will start on {start.format('Do MMM YYYY')}
          <br />* Processing fee is applicable
        </p>

        {/*<div*/}
        {/*  style={{*/}
        {/*    display: 'flex',*/}
        {/*    flexDirection: 'row',*/}
        {/*    alignItems: 'center',*/}
        {/*    marginTop: 20,*/}
        {/*    width: '80%',*/}
        {/*    justifyContent: 'center',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <input*/}
        {/*    type="checkbox"*/}
        {/*    style={{ marginRight: 10 }}*/}
        {/*    onChange={(checked) =>*/}
        {/*      this.setState({ tosChecked: !this.state.tosChecked })*/}
        {/*    }*/}
        {/*    checked={this.state.tosChecked}*/}
        {/*  />*/}
        {/*  <p style={{ color: Constants.primaryThemeDark }}>*/}
        {/*    We partner with California Center for Functional Medicine(CCFM) to*/}
        {/*    provide care in your state. Please read and accept the{' '}*/}
        {/*    <a href={TOS_LINK} target="_blank" rel="noopener noreferrer">*/}
        {/*      terms of agreement.*/}
        {/*    </a>*/}
        {/*  </p>*/}
        {/*</div>*/}
      </div>
    )
  }

  renderAilaContent() {
    let windowWidth = window.innerWidth
    const start = moment()

    let elements = []
    elements =
      AILA_VALUE_PROPS &&
      AILA_VALUE_PROPS.map((x) => (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={Styles.propIcon}
            style={{ marginRight: 10 }}
          />
          <p style={{ color: Constants.primaryThemeDark }}>{x}</p>
        </div>
      ))

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <h1
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 10,
            // fontSize: 17,
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Select your plan
        </h1>
        <h6
          style={{
            marginTop: 10,
            color: '#6C6C6C',
            // fontWeight: 'bold',
            marginBottom: 20,
            fontSize: 17,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Pay per visit with our Basic Plan or Enroll in one of our membership
          plans to work with our health coaches and get access to cash pay
          discounts.
        </h6>

        <div className={Styles.valuePropsWrapper}>
          {this.renderValuePropsBoxAila(BASIC_PROPS, 'basic', false)}
          {this.renderValuePropsBoxAila(MEMBER_PROPS, 'member', true)}
          {this.renderValuePropsBoxAila(PREMIUM_PROPS, 'premium', false)}
        </div>

        <p style={{ fontSize: 14, color: TEXT_COLOR }}>
          {/* * Your subscription will start on {start.format('Do MMM YYYY')} */}
          * Your subscription will start on {start.format('Do MMM YYYY')} and
          will auto-renew each month. See{' '}
          <a
            href={Config.TOS_LINK}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecorationLine: 'underline' }}
          >
            Terms of Use
          </a>{' '}
          for further details.
          <br />* Credit card processing fee is applicable
        </p>
      </div>
    )
  }

  renderExistingSubscriptionContent() {
    const {
      subscriptionActive,
      subscriptionCancelledAtEndOfPeriod,
      patientDetails,
    } = this.state
    const stripe = patientDetails.stripe || {}

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <h5
          style={{
            marginTop: 20,
            color: Constants.primaryThemeDark,
            marginBottom: 20,
            fontSize: 22,
            fontWeight: 'bold',
          }}
        >
          Aila Health Membership
        </h5>
        {subscriptionActive ? (
          subscriptionCancelledAtEndOfPeriod ? (
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p style={{ textAlign: 'center', fontSize: 18 }}>
                You have cancelled your subscription
              </p>
              <p style={{ textAlign: 'center', fontSize: 14 }}>
                Enjoy your benefits until your subscription ends on{' '}
                {stripe.period_end &&
                  moment(stripe.period_end * 1000).format('Do MMMM YYYY')}
                .{' '}
              </p>
            </div>
          ) : (
            <div
              style={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{ textAlign: 'center', fontSize: 18, color: TEXT_COLOR }}
              >
                Your subscription is active
              </p>
              <p
                style={{ textAlign: 'center', fontSize: 14, color: TEXT_COLOR }}
              >
                Next billing on:{' '}
                {stripe.period_end &&
                  moment(stripe.period_end * 1000).format('Do MMMM YYYY')}
              </p>
            </div>
          )
        ) : (
          <div
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p style={{ textAlign: 'center', fontSize: 18, color: TEXT_COLOR }}>
              Your subscription is not active
            </p>
            <p style={{ textAlign: 'center', color: TEXT_COLOR }}>
              It ended on{' '}
              {stripe.period_end &&
                moment(stripe.period_end * 1000).format('Do MMMM YYYY')}
            </p>
          </div>
        )}

        <button
          onClick={this.getCustomerPortalUrl}
          className={GlobalStyles.button}
          style={{
            width: 200,
            marginTop: 40,
            borderRadius: 15,
            borderWidth: 0,
            height: 40,
            fontWeight: 'bold',
          }}
        >
          <p style={{ color: 'white' }}>Manage Subscription</p>
        </button>
      </div>
    )
  }

  renderMissingStateContent() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <h5
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            marginBottom: 20,
            fontSize: 22,
            fontWeight: 'bold',
          }}
        >
          Aila Health Membership
        </h5>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div className={Styles.commImageWrapper}>
            <img src={CommImage} style={{ height: 125, width: 125 }} />
          </div>
        </div>

        <p
          style={{
            fontSize: 18,
            color: Constants.primaryThemeDark,
            width: '75%',
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          {`We cannot determine your state. Please update your address in the profile section of the Aila Health app.`}
        </p>
      </div>
    )
  }

  renderCustomModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h3
          style={{
            color: Constants.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
            height: '25%',
            padding: '4%',
          }}
        >
          Want to save ?
        </h3>

        <h5
          style={{
            flex: 1,
            color: Constants.primaryTheme,
            textAlign: 'center',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
            height: '50%',
          }}
        >
          Subscribe annually and save{' '}
          {this.state.customModalValue === 'premium' ? '25%' : '16%'}
          <br />
          {this.state.customModalValue === 'premium'
            ? '(Pay $150/Month with an annual membership)'
            : '(Pay $25/Month with an annual membership)'}
        </h5>

        <div className={Styles.modalButtonDiv}>
          <div
            style={{
              width: '40%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                if (this.state.customModalValue === 'premium')
                  this.onPlanClicked(Config.STRIPE_PREMIUM_MONTHLY, 'premium')
                else
                  this.onPlanClicked(
                    Config.STRIPE_MEMBERSHIP_MONTHLY,
                    'membership',
                  )
              }}
              // className={GlobalStyles.button}
              // style={{
              //   width: '100%',
              //   backgroundColor: 'gray',
              //   borderColor: 'gray',
              //   height: '80%',
              // }}
              className={GlobalStyles.button}
              style={{
                width: 200,
                // margin: '20px auto',
                borderRadius: 15,
                borderWidth: 0,
                height: 40,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'gray',
                borderColor: 'gray',
              }}
              variant="primary"
            >
              No thanks
            </Button>
          </div>

          <div
            style={{
              width: '40%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                if (this.state.customModalValue === 'premium')
                  this.onPlanClicked(Config.STRIPE_PREMIUM_ANNUALLY, 'premium')
                else
                  this.onPlanClicked(
                    Config.STRIPE_MEMBERSHIP_ANNUALLY,
                    'membership',
                  )
              }}
              // className={GlobalStyles.button}
              // style={{ width: '100%', height: '80%', borderColor: '#20A892' }}
              className={GlobalStyles.button}
              style={{
                width: 200,
                // margin: '20px auto',
                borderRadius: 15,
                borderWidth: 0,
                height: 40,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              variant="primary"
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderDeeplinkSubscriptionContent(deepLink) {
    const { patientDetails } = this.state
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <h5
          style={{
            marginTop: 20,
            color: Constants.primaryThemeDark,
            marginBottom: 20,
            fontSize: 22,
            fontWeight: 'bold',
          }}
        >
          Aila Health Membership
        </h5>

        <div
          style={{
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p style={{ textAlign: 'center', fontSize: 18, color: TEXT_COLOR }}>
            Your subscription is active
          </p>
          <p style={{ textAlign: 'center', fontSize: 18, color: TEXT_COLOR }}>
            Program : {deepLink}
          </p>
          <p style={{ textAlign: 'center', fontSize: 14, color: TEXT_COLOR }}>
            Start Date :{' '}
            {moment(patientDetails?.coaching?.start).format('Do MMMM YYYY')}
          </p>
        </div>
      </div>
    )
  }

  renderContent() {
    const { patientSubscribed, state, firebaseUser, medicare } = this.state
    //IF patient is through deeplinks programs
    if (firebaseUser?.rapilot) {
      return this.renderDeeplinkSubscriptionContent('RA Pilot')
    }
    if (firebaseUser?.dac) {
      return this.renderDeeplinkSubscriptionContent('DAC')
    }
    if (firebaseUser?.ccfm) {
      return this.renderDeeplinkSubscriptionContent('CCFM')
    }

    //If patient subscribed
    if (patientSubscribed) {
      return this.renderExistingSubscriptionContent()
    }

    //If patients state is missing
    if (!state || state.length === 0) return this.renderMissingStateContent()

    //MEDICARE/MEDICAID PATIENTS INSURANCES
    if (medicare) return this.renderNotAvailableContent()

    //Aila subscription plans
    if (AILA_STATES[state?.toLowerCase()]) {
      if (this.state.preferred_sub) {
        let isMembership =
          this.state.preferred_sub.toLowerCase() === 'membership'
        this.onPlanClicked(
          isMembership
            ? Config.STRIPE_MEMBERSHIP_MONTHLY
            : Config.STRIPE_PREMIUM_MONTHLY,
          isMembership ? 'membership' : 'premium',
        )
        return
      }
      return this.renderAilaContent()
    }
    return this.renderNotAvailableContent()
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          display: 'flex',
          //   flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 10,
          backgroundColor: 'white',
          //   overflow: 'scroll',
          justifyContent: 'center',
          //   width: window.innerWidth,
          width: '100%',
        }}
      >
        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              marginTop: 40,
            }}
          >
            <Loader
              type="Oval"
              color={Constants.primaryTheme}
              height={40}
              width={40}
            />
          </div>
        ) : (
          this.renderContent()
        )}
        {this.state.setConfirmCustomModal && (
          <Modal
            ariaHideApp={false}
            onRequestClose={() =>
              this.setState({
                setConfirmCustomModal: false,
                customModalValue: '',
              })
            }
            className={Styles.modalContent}
            isOpen={this.state.setConfirmCustomModal}
            style={{
              overlay: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.44)',
              },
            }}
            contentLabel="Modal"
          >
            {this.renderCustomModal()}
          </Modal>
        )}
      </div>
    )
  }
}
