import moment from 'moment'
import React, { Component, createRef } from 'react'
import { momentLocalizer } from 'react-big-calendar'

// import bootstrapPlugin from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import Button from 'react-bootstrap/Button'
// import 'bootstrap/dist/css/bootstrap.css'
import './styles/infusionDemo.css'

import 'react-datepicker/dist/react-datepicker.css'
import Styles from './styles/Appointments.module.scss'

import Fuse from 'fuse.js'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import Select from 'react-select'
import Config from '../../config.js'
import {
  addAppointmentData,
  addMongoUser,
  addPatient,
  addPatientLP,
  addPatientList,
  addRpmPatient,
  addVirgilE3,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientCoreDate,
  fetchPatientLabs,
  fetchPatientMedications,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  resetTimer,
  setPatientCoreData,
  startTimer,
  stopTimer,
  toggleSidebar,
  updateDoctorMongoUser,
  updatePatientData,
  updateSideNavBar,
  userLoggedIn,
} from '../../redux/actions'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import { toast } from 'react-toastify'
const localizer = momentLocalizer(moment)
const axios = require('axios')
const windowHeight = window.innerHeight

const APPOINTMENT_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 440,
    height: 450,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

// The reason the z index is so high is because fullcalendar render their calendar
// with a z-index value far greater than 999
const SCHEDULE_APPOINTMENT_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 2147483640,
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // zIndex: 2,
    zIndex: 2147483640,
  },
}

const SLOT_AVAILABLE_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '80%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const TIME_SLOTS = {
  Mon: [],
  Tue: [],
  Wed: [],
  Thu: [],
  Fri: [],
  Sat: [],
  Sun: [],
}

// BUG: once the infusion order is placed, and the chair number is changed.
// and goes back to the original changed number the events are not shown, just
// need to update the state Kappa
// BUG: needs to default back to chair 1 when the infusion center is changed
class InfusionDemo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      modalIsOpen: false,
      events: null,
      cancelledEvents: null,
      modalEvent: null,
      eventsFromNylas: null,
      eventsFromBackend: {},
      eventIdsFromBackend: [],
      ailaEventsSelected: true,
      showScheduleModal: false,
      showSlotAvailableModal: false,
      patientList: [],
      patientMap: {},
      providerMap: {},
      selectedSchedulePatient: null,
      selectedScheduleDoctor: null,
      scheduleModalLoading: false,
      patientCareTeam: [],
      careTeamSpeciality: [],
      searchSpeciality: null,
      newPatients: [],
      establishedPatients: [],
      coachingOnlyPatients: [],
      patientType: null,
      reload: false,
      selectedOverRideDate: null,
      axiosConfig: {},
      superUser: false,
      searchDate: new Date(),
      infusionScheduleModalSelected: false,
      appointmentType: null,
      selectInfo: null,
      selectedSchedulePatientId: null,
      infusionCenters: [],
      selectedInfusionCenter: null,
      orderingProvider: null,

      //
      something: 0,
      selectedChair: { value: 1, label: 1 },
      infusionAppointments: [],
      numberOfChairsInInfusionCenter: 0,
      selectedInfusionCenterInitialEvents: [],
      initialEventsPerChair: [],
      // initialEventsPerChair: {
      //   1: [
      //     {
      //       title: 'Patient 2 Chair 1',
      //       start: '2023-08-21T04:49:07.802Z',
      //       end: '2023-08-21T04:49:07.802Z',
      //     },
      //   ],
      //   2: [
      //     {
      //       title: 'Patient 2 Chair 2',
      //       start: '2023-08-09T21:49:07.802Z',
      //       end: '2023-08-09T23:49:07.802Z',
      //     },
      //   ],
      // },
    }

    this.calendarRef = createRef()

    this.onInfusionScheduleClicked = this.onInfusionScheduleClicked.bind(this)
    this.getPatientList = this.getPatientList.bind(this)
    this.getInfusionCenterInitialEvents =
      this.getInfusionCenterInitialEvents.bind(this)
    this.setInfusionCenterInitialEventsForSelectedChair =
      this.setInfusionCenterInitialEventsForSelectedChair.bind(this)
    // this.fuse = null
    // this.allCalendars = []
    // this.preparePatientList = this.preparePatientList.bind(this)
    // this.saveSlotsToBackend = this.saveSlotsToBackend.bind(this)
    // this.onModalDeleteEventPressed = this.onModalDeleteEventPressed.bind(this)
    // this.onVideoCallPressed = this.onVideoCallPressed.bind(this)
    // this.getPatientCareTeam = this.getPatientCareTeam.bind(this)
    // this.getAllProviders = this.getAllProviders.bind(this)
    // this.handleSlotAvailability = this.handleSlotAvailability.bind(this)
    // this.handleSlotDeletion = this.handleSlotDeletion.bind(this)
    // this.renderTimeSlots = this.renderTimeSlots.bind(this)
    // this.renderSlots = this.renderSlots.bind(this)
    // this.handleDateOverRideChange = this.handleDateOverRideChange.bind(this)
    // this.handleOverrideSlotAvailability =
    //   this.handleOverrideSlotAvailability.bind(this)
    // this.handleOverrideTimeSlotDeletion =
    //   this.handleOverrideTimeSlotDeletion.bind(this)
    // this.handleOverrideSlotDeletion = this.handleOverrideSlotDeletion.bind(this)
    // this.saveOverRideSlotsToBackend = this.saveOverRideSlotsToBackend.bind(this)
    // this.getAppointmentUrl = this.getAppointmentUrl.bind(this)
  }

  onInfusionScheduleClicked() {
    console.log('Setting infusion modal to true')
    this.setState({
      infusionScheduleModalSelected: true,
    })
    // this.props.toggleSidebar(true)
  }

  componentDidMount() {
    const { mongoUser } = this.props

    // if (!this.props.loggedIn) {
    //   this.props.history.push('/login')
    //   return
    // }
    this.getProviderList()
    this.getPatientList()
    this.getInfusionCenters()
    // this.getInitialEvents()
    // this.getAllProviders()
    // if (mongoUser && mongoUser.availability) {
    //   this.setState({
    //     slot_available: mongoUser.availability,
    //     slot_override: mongoUser?.availability_override || {},
    //   })
    // } else {
    //   this.setState({ slot_available: TIME_SLOTS })
    // }
  }

  // async getInfusionScheduledEvents() {
  //   const { token, adminId } = this.props

  //   axios({
  //     method: 'get',
  //     url,
  //     headers: {
  //       Authorization: `JWT ${token}`,
  //     },
  //   })
  // }

  getProviderList() {
    const { token, adminId } = this.props

    const url = `${Config.BACKEND_URL}infusion/providers/list`

    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => {
        let patientCareTeam = response.data.data
        this.setState({ patientCareTeam })
      })
      .catch((error) => {
        console.log('error when getting infusion centers list', error)
      })
  }

  async getInfusionCenterInitialEvents(infusionCenterId) {
    console.log("Hello I'm under the water")
    const { token, adminId } = this.props
    // const { selectedInfusionCenter } = this.state
    const url = `${Config.BACKEND_URL}infusion/appointments/calendar/list`
    console.log('Getting initial events for infusion center', infusionCenterId)
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        headers: { Authorization: `JWT ${token}` },
        params: {
          infid: infusionCenterId,
        },
      })
        .then((response) => {
          console.log('Got initial events', response.data)
          this.setState({
            selectedInfusionCenterInitialEvents: response.data.data,
          })
          resolve(response.data.data)
        })
        .catch((error) => {
          console.log('error when getting infusion centers list', error, token)
          reject(error)
        })
    })
  }

  async setInfusionCenterInitialEventsForSelectedChair(chairNumber) {
    console.log('Wamp', 'wamp')
    let { selectedInfusionCenterInitialEvents } = this.state
    console.log(
      'Setting initial events for chair1',
      chairNumber,
      selectedInfusionCenterInitialEvents,
    )
    // let initialEventsPerChair = selectedInfusionCenterInitialEvents.find((obj) => obj.chair_number === chairNumber)
    let initialEventsPerChair = selectedInfusionCenterInitialEvents.find(
      (obj) => obj._id === chairNumber,
    )
    console.log(
      'Setting initial events for chair',
      chairNumber,
      initialEventsPerChair,
    )
    if (initialEventsPerChair) {
      this.setState({ initialEventsPerChair: initialEventsPerChair.data })
    } else {
      this.setState({ initialEventsPerChair: [] })
    }
  }

  getInfusionCenters() {
    const { token, adminId } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}infusion/centers/list`
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          const { data } = response
          console.log(data)
          let infusionCenters = data.data
          infusionCenters = infusionCenters.map((item) => {
            return {
              label: item.name,
              value: item._id,
              ...item,
            }
          })
          console.log('->1', infusionCenters)
          self.setState({ infusionCenters })
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting infusion centers list', error, token)
        this.setState({ loading: false })
      })
  }

  getPatientList() {
    this.setState({ loading: true })
    const { token, adminId, firebaseUser, mongoUser } = this.props
    const self = this
    const url =
      `${Config.BACKEND_URL}infusion/patients/list?type=true` +
      (this.state.superUser ||
      mongoUser?.user_type?.toLowerCase() === 'administrator'
        ? '&access=admin'
        : '')
    axios({
      method: 'get',
      url,
      headers: { x_auth_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          const { data } = response
          console.log(data)
          let patientList = data.allPatients
          // console.log('->1', patientList)
          let establishedPatients = data.existingPatients
          let newPatients = data.existingPatients
          // console.log('->2', establishedPatients)
          self.setState({ patientList })
          self.setState({ establishedPatients })
          self.setState({ newPatients })
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
        this.setState({ loading: false })
      })
  }

  renderInfusionScheduleModal() {
    const {
      scheduleModalLoading,
      searchSpeciality,
      establishedPatients,
      patientType,
      newPatients,
      patientList,
    } = this.state

    //SEARCH BY EMAIL
    const options = {
      includeScore: true,
      keys: ['speciality'],
      useExtendedSearch: true,
    }

    this.fuse = new Fuse(this.state.patientCareTeam, options)

    let uniqueList = []

    if (searchSpeciality) {
      const results = this.fuse.search(`="${searchSpeciality?.label}"`)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
    } else {
      uniqueList = this.state.patientCareTeam
    }

    if (scheduleModalLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100%',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2147483647,
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Schedule Infusion Appointment
        </h4>
        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder="Select patient type"
            inputId="name"
            options={[
              { label: 'New Patient', value: 'New Patient' },
              { label: 'Established Patient', value: 'Established Patient' },
              { label: 'All Patients', value: 'All Patients' },
            ]}
            value={this.state.patientType}
            isSearchable
            autoBlur
            onChange={(val) => {
              this.setState({
                patientType: val,
                selectedScheduleDoctor: null,
              })
              // this.getPatientCareTeam(val.value)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder="Select patient"
            inputId="name"
            options={
              patientType?.value === 'Established Patient'
                ? establishedPatients
                : patientType?.value === 'New Patient'
                ? newPatients
                : patientType?.value === 'All Patients'
                ? patientList
                : patientList
            }
            value={this.state.selectedSchedulePatient}
            isSearchable
            autoBlur
            onChange={(val) => {
              this.setState({
                selectedSchedulePatient: val,
                // selectedSchedulePatientId: val.value,
                selectedScheduleDoctor: null,
              })
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder={'Appointment Type'}
            inputId="appointment_type"
            options={[
              {
                value: 'infusion_visit',
                label: 'Infusion Visit',
              },
              {
                value: 'in_office_visit',
                label: 'In Office Visit',
              },
            ]}
            value={this.state.appointmentType}
            isSearchable
            autoBlur
            onChange={(val) => {
              // console.log(val)
              this.setState({
                appointmentType: val,
              })
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
        <div style={{ width: '50%', marginTop: 10, marginBottom: 20 }}>
          <Select
            placeholder={'Drug'}
            inputId="drug_name"
            options={[
              { label: 'Actemra', value: 'Actemra' },
              { label: 'ACTH Testing', value: 'ACTH_Testing' },
              { label: 'Adakveo', value: 'Adakveo' },
              { label: 'Adhulem', value: 'Adhulem' },
              { label: 'Albumin', value: 'Albumin' },
              { label: 'Aldurazyme', value: 'Aldurazyme' },
              { label: 'Aralast', value: 'Aralast' },
              { label: 'Aranesp', value: 'Aranesp' },
              { label: 'Asceniv', value: 'Asceniv' },
              { label: 'Avsola', value: 'Avsola' },
              { label: 'Benlysta', value: 'Benlysta' },
              { label: 'Bivigam', value: 'Bivigam' },
              { label: 'Boniva', value: 'Boniva' },
              { label: 'Botox', value: 'Botox' },
              { label: 'Briumvi', value: 'Briumvi' },
              { label: 'Cabenuva', value: 'Cabenuva' },
              { label: 'Carimune', value: 'Carimune' },
              { label: 'Cathflo', value: 'Cathflo' },
              { label: 'Cerezyme', value: 'Cerezyme' },
              { label: 'Chemotherapeutics', value: 'Chemotherapeutics' },
              { label: 'Cimzia', value: 'Cimzia' },
              { label: 'Cinqair', value: 'Cinqair' },
              { label: 'Crysvita', value: 'Crysvita' },
              { label: 'Cytoxan', value: 'Cytoxan' },
              { label: 'DHE 45', value: 'DHE_45' },
              { label: 'Depacon (valproate)', value: 'Depacon_(valproate)' },
              { label: 'Elaprase', value: 'Elaprase' },
              { label: 'Elelso', value: 'Elelso' },
              { label: 'Entyvio', value: 'Entyvio' },
              { label: 'Epogen', value: 'Epogen' },
              { label: 'Evenity', value: 'Evenity' },
              { label: 'Evkeeza', value: 'Evkeeza' },
              { label: 'Exondys 51', value: 'Exondys_51' },
              { label: 'Fabrazyme', value: 'Fabrazyme' },
              { label: 'Fasenra', value: 'Fasenra' },
              { label: 'Feraheme', value: 'Feraheme' },
              { label: 'Ferrlecit', value: 'Ferrlecit' },
              { label: 'Flebogamma', value: 'Flebogamma' },
              { label: 'Fulphia', value: 'Fulphia' },
              { label: 'Gammagard', value: 'Gammagard' },
              { label: 'Gammagard Liquid', value: 'Gammagard_Liquid' },
              {
                label: 'Gammagard S/D (IgA <1 mcg/mL)',
                value: 'Gammagard_S/D_(IgA_<1_mcg/mL)',
              },
              { label: 'Gammaked', value: 'Gammaked' },
              { label: 'Gammaplex', value: 'Gammaplex' },
              { label: 'Gamunex-C', value: 'Gamunex-C' },
              { label: 'Givlaari', value: 'Givlaari' },
              { label: 'Glassia', value: 'Glassia' },
              { label: 'Granix', value: 'Granix' },
            ]}
            value={this.state.drugName}
            isSearchable
            autoBlur
            onChange={(val) => {
              this.setState({
                drugName: val,
              })
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder={'Ordering Provider'}
            inputId="ordering_provider"
            options={this.state.patientCareTeam}
            value={this.state.orderingProvider}
            isSearchable
            autoBlur
            onChange={(val) => {
              // console.log(val)
              this.setState({
                orderingProvider: val,
              })
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div style={{ marginTop: 10, marginBottom: 20, paddingBottom: '5%' }}>
          <Button
            onClick={() => {
              this.onInfusionAppointmentPlaced()
              this.onModalCloseRequested()
            }}
            className={GlobalStyles.button}
            disabled={false}
            style={{
              width: 250,
              // marginLeft: 10,
              backgroundColor: Constants.primaryTheme,
              textAlign: 'center',
            }}
            variant="primary"
            color={Constants.primaryTheme}
            backgroundColor={Constants.primaryTheme}
          >
            Schedule Infusion Appointment
          </Button>
        </div>
      </div>
    )
  }

  onModalCloseRequested() {
    this.setState({
      infusionScheduleModalSelected: false,
    })
  }

  renderContent() {
    const { mongoUser, firebaseUser } = this.props
    let { superUser } = this.state

    let eventsToShow = []
    if (this.state.ailaEventsSelected) eventsToShow = this.state.events || []
    else eventsToShow = this.state.eventsFromNylas || []
    return (
      <div className={GlobalStyles.contentWrapper}>
        {/* {mongoUser.nylas && ( */}
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => this.onScheduleClicked()}
            className={GlobalStyles.button}
            disabled={this.state.loading}
            style={{
              width: 250,
              marginLeft: 10,
              backgroundColor: Constants.infusionTheme,
            }}
            variant="primary"
            color={Constants.infusionTheme}
            backgroundColor={Constants.infusionTheme}
          >
            Schedule Infusion Appointments
          </Button>
        </div> */}
        <Modal
          ariaHideApp={false}
          onRequestClose={() => this.onModalCloseRequested()}
          isOpen={this.state.infusionScheduleModalSelected}
          style={SCHEDULE_APPOINTMENT_MODAL_STYLES}
          // style={{
          //   ...SCHEDULE_APPOINTMENT_MODAL_STYLES,
          //   zIndex: 999,
          // }}
          contentLabel="Modal"
          // className={}
        >
          {this.renderInfusionScheduleModal()}
        </Modal>

        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 60px',
            marginTop: 20,
            visibility:
              this.state.modalIsOpen ||
              this.state.showScheduleModal ||
              this.state.showSlotAvailableModal
                ? 'hidden'
                : 'visible',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h5 style={{ color: Constants.primaryTheme }}>
                Select chair number:
              </h5>
              <div
                style={{
                  // flexDirection: 'column',
                  justifyContent: 'left',
                  // marginLeft: 50,
                  width: 250,
                  marginBottom: 25,
                  marginLeft: 5,
                  // flexGrow: 1,
                  zIndex: 2147483640,
                }}
              >
                <Select
                  placeholder={`Select Chair number  ....`}
                  // isMulti
                  style={{
                    color: Constants.primaryTheme,
                    menu: (provided) => ({ ...provided, zIndex: 2147483640 }),
                  }}
                  options={this.state.chairsOptions}
                  value={this.state.selectedChair}
                  onChange={async (val) => {
                    // TODO: update set the infusion center initial events here
                    await this.setInfusionCenterInitialEventsForSelectedChair(
                      val.value,
                    )
                    // console.log('val:', val)
                    this.calendarRef.current.getApi().render()
                    // console.log(this.calendarRef.current)
                    // console.log(this.calendarRef.current.getApi())
                    this.setState({ selectedChair: val })
                    this.forceUpdate()
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingLeft: 50,
                }}
              >
                <h5 style={{ color: Constants.primaryTheme }}>
                  Select Infusion Center:
                </h5>
                <div
                  style={{
                    // flexDirection: 'column',
                    justifyContent: 'left',
                    // marginLeft: 50,
                    width: 250,
                    marginBottom: 25,
                    marginLeft: 5,
                    // flexGrow: 1,
                    zIndex: 2147483640,
                  }}
                >
                  <Select
                    placeholder={`Select Infusion Center`}
                    // isMulti
                    style={{
                      color: Constants.primaryTheme,
                      // menuPortalTarget={document.body},
                      // zIndex: 2147483640,
                      // menuPortal: (base) => ({ ...base, zIndex: 2147483640 }),
                      menu: (provided) => ({ ...provided, zIndex: 2147483640 }),
                    }}
                    // zIndex={2147483640}
                    options={this.state.infusionCenters}
                    value={this.state.selectedInfusionCenter}
                    onChange={async (val) => {
                      console.log('val:', val)
                      // this.calendarRef.current.getApi().render()
                      // console.log(this.calendarRef.current)
                      // console.log(this.calendarRef.current.getApi())
                      this.setState({ selectedInfusionCenter: val })
                      this.getInfusionCenterInitialEvents(val.value).then(
                        async () => {
                          console.log('set getInfusionCenterInitialEvents')
                          await this.setInfusionCenterInitialEventsForSelectedChair(
                            1, // because 1 is the default selected chair
                          )
                        },
                      )
                      console.log('done the next step also')
                      this.setState({
                        numberOfChairsInInfusionCenter: val.chairs,
                      })
                      let x = val.chairs
                      let chairsOptions = []
                      for (let i = 0; i < x; i++) {
                        chairsOptions.push({ value: i + 1, label: i + 1 })
                      }
                      console.log('val:', chairsOptions)
                      this.setState({ chairsOptions })
                      // this.forceUpdate()
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                // display: 'flex',
                /* Custom CSS to move time slots to the left */
                // flexGrow: 1,
                zIndex: 10,
                padding: '3em',
              }}
            >
              {/* <h1>Demo App</h1> */}
              {/* {console.log(
                this.state.selectedChair.value,
                this.state.initialEventsPerChair[
                  this.state.selectedChair.value
                ],
              )} */}
              {/* {this.state.infusionScheduleModalSelected ? null : ( */}
              <FullCalendar
                ref={this.calendarRef}
                // themeSystem="bootstrap"
                plugins={[
                  // bootstrapPlugin,
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                ]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                //   initialView="dayGridMonth"
                initialView="timeGridWeek"
                // initialView="timeGridDay"
                weekends={false} // Show weekends
                editable={true}
                selectable={true}
                // selectMirror={true}
                // dayMaxEvents={true}
                allDaySlot={false}
                // eventOverlap={false}
                // slotEventOverlap={false}
                slotDuration={'00:15:00'}
                slotMinTime={'08:00:00'}
                slotMaxTime={'18:00:00'}
                // initialEvents={
                events={
                  this.state.initialEventsPerChair
                  // this.state.initialEventsPerChair[
                  //   this.state.selectedChair.value
                  // ]
                }
                // events={this.infusionAppointments}
                // eventContent={this.renderEventContent}
                eventClick={this.handleEventClick}
                select={this.handleDateSelect}
                // BUG: Running infinitely idk why, need to fix
                //   eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
              />
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // When a time slot is selected
  handleDateSelect = (selectInfo) => {
    let { selectedChair, selectedInfusionCenter } = this.state
    if (!selectedChair || selectedChair === null) {
      alert('Please select a chair number')
      toast.error('Please select a chair number')
      return
    }
    if (!selectedInfusionCenter || selectedInfusionCenter === null) {
      console.log('Please select an infusion center')
      toast.error('Please select an infusion center')
      return
    }
    // let title = prompt('Please enter a new title for your event')

    console.log('h1')
    this.setState({ selectInfo })
    this.onInfusionScheduleClicked()
  }

  onInfusionAppointmentPlaced() {
    let {
      selectInfo,
      selectedSchedulePatient,
      drugName,
      appointmentType,
      selectedInfusionCenter,
    } = this.state
    let calendarApi = selectInfo.view.calendar

    let { initialEventsPerChair, selectedChair, something } = this.state

    let title = selectedSchedulePatient.label + ' - ' + drugName.label
    console.log(title, calendarApi)

    // calendarApi.unselect() // clear date selection

    console.log('h2')
    if (!selectedInfusionCenter) {
      console.log('Please select an infusion center')
      return
    }
    if (title) {
      let event = {
        id: String(something++), // TODO: Create a unique id for this
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      }

      calendarApi.addEvent(event)
      calendarApi.render()
      let startTimeAsEphoc = new Date(selectInfo.startStr).getTime()
      let endTimeAsEphoc = new Date(selectInfo.endStr).getTime()
      const toSendBackendHeaders = {
        title: title,
        start: startTimeAsEphoc,
        end: endTimeAsEphoc,
        drug: drugName.label,
        chair_number: selectedChair.value,
        appointment_type: appointmentType.value,
        patient_id: selectedSchedulePatient.value,
        infusion_center_id: selectedInfusionCenter.value, // TODO: This needs to change to infusion center id
        ordering_provider: this.state.orderingProvider.value,
      }
      console.log(toSendBackendHeaders)
      const url = `${Config.BACKEND_URL}infusion/appointment/create`
      axios({
        method: 'post',
        url,
        headers: {
          Authorization: `JWT ${this.props.token}`,
        },
        data: toSendBackendHeaders,
      })
        .then((res) => {
          // calendarApi.addEvent(event)
          console.log(this.calendarRef)
          console.log(this.calendarRef.current.getApi())
          this.calendarRef.current.render()
          this.calendarRef.current.getApi().render()
          calendarApi.render()
          something += 1
          this.setState({ something })
          this.setState({
            infusionAppointments: {
              ...this.state.infusionAppointments,
              ...event,
            },
          })
          initialEventsPerChair[selectedChair.value].push(event)
          // initialEventsPerChair[selectedChair.value] =temp
          // console.log(
          //   'Setting chair number',
          //   selectedChair.value,
          //   'as',
          //   initialEventsPerChair,
          // )
          this.setState({
            initialEventsPerChair,
          })
        })
        .catch((err) => {
          console.log(err)
        })
      // calendarApi.addEvent(event)
      // this.calendarRef.current.getApi().render()
    }
  }

  // Just for now deleting the event on click
  handleEventClick = (clickInfo) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'`,
      )
    ) {
      clickInfo.event.remove()
    }
  }

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    })
  }

  render() {
    return this.renderContent()
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  loggedIn: state.userReducer.loggedIn,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  mongoUser: state.userReducer.mongoUser,
  adminId: state.userReducer.adminId,
  patientList: state.patientReducer.patientList,
  firebaseUser: state.userReducer.firebaseUser,
})

const mapDispatchToProps = {
  stopTimer,
  updateSideNavBar,
  addRpmPatient,
  addPatientLP,
  addAppointmentData,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(InfusionDemo)
