import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Circle.module.scss'
import Constants from '../../values.js'
import Config from '../../config'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMicrophoneSlash,
  faVideoSlash,
  faPhone,
  faTrash,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
const TWILIO_VIDEO = require('twilio-video')
const axios = require('axios')
const windowWidth = window.innerWidth

class CircleVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      appointmentData: null,
      patientData: null,
      roomName: null,
      localStreamCreated: false,
      videoCallJoined: false,
      videoCallRoom: null,
      audioMuted: false,
      videoMuted: false,
      hideLocalMedia: false,
      remoteTrackAvailable: false,
      video: true,
    }
    this.createLocalVideoStream = this.createLocalVideoStream.bind(this)
    this.fetchTwilioToken = this.fetchTwilioToken.bind(this)
    this.getTwilioToken = this.getTwilioToken.bind(this)
    this.onRoomConnect = this.onRoomConnect.bind(this)
    this.onJoinCallPressed = this.onJoinCallPressed.bind(this)
    this.onClosePressed = this.onClosePressed.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.doctorPatientData && state.patientData && state.twilioToken)
      return { loading: false }

    return null
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    console.log('THIS IS CIRCLE INFO: ', this.props?.location?.circle)
    if (this.props?.location?.circle) {
      console.log(this.props)
      this.createLocalVideoStream()
      this.fetchTwilioToken(this.props?.location?.circle)
    }
  }

  // componentWillUnmount() {
  //   // this?.state?.videoCallRoom?.disconnect()
  // }

  // componentDidUpdate() {
  //   if(this.props?.location?.circle && !this.state.localStreamCreated)
  //     this.createLocalVideoStream()
  // }

  fetchTwilioToken(circle) {
    let { token, mongoUser } = this.props
    this.setState({ loading: true })
    let config = {
      method: 'post',
      url: `${Config.BACKEND_URL}twilio/group/room`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: this.props?.location?.patient
          ? this.props?.location?.patient?.uid
          : mongoUser?.firebase_id,
        room_name: circle._id,
      },
    }
    console.log('API CALLED: twilio/group/room')
    axios(config).then((res) => {
      // if the room is created or if the hos
      if ((res.data.isHost && res.data.room_created) || res.data.room_created) {
        console.log('GROUP ROOM CREATED (twilio/group/room)')
        this.getTwilioToken(circle._id, circle)
      }
      // if the room is not yet created
      else {
        this.setState({ loading: false })
        console.log(
          'Please wait',
          'Please wait for the host to start the circle',
        )
        toast.info('Please wait for the host to start the circle')
      }
    })
  }

  getTwilioToken(circle_id, circle) {
    let { token, mongoUser } = this.props
    console.log('API CALLED: twilio/group/token')
    let config = {
      method: 'get',
      url: `${Config.BACKEND_URL}twilio/group/token`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: this.props?.location?.patient
          ? this.props?.location?.patient?.uid
          : mongoUser?.firebase_id,
        room_name: circle_id,
      },
    }

    axios(config)
      .then((response) => {
        console.log(
          'TWILIO TOKEN RECEIVED FROM BACKEND from (twilio/group/token)',
          response?.data,
        )
        //   // setTwilioToken(response.data.token);
        // createLocalVideoStream(response.data.token, circle_id)
        this.onRoomConnect(response.data.token, circle_id)
        // setTwilioToken(response.data.token)
        this.setState({ twilioToken: response.data.token })
      })
      .catch((error) => {
        console.log('error when getting twilio token', error)
        this.setState({ loading: false })
      })
  }

  createLocalVideoStream() {
    let { twilioToken } = this.state
    if (!this.props?.location?.circle) {
      console.log('missing twilio token')
      toast.error('Cannot call patient right now')
      return
    }

    if (!TWILIO_VIDEO.isSupported) {
      console.log('not supported by browser')
      toast.error('Browser not supported')
      return
    }

    this.setState({ localStreamCreated: true })
    let self = this
    TWILIO_VIDEO.createLocalTracks({
      audio: true,
      video: { width: windowWidth / 2.5, height: 500 },
    }).then((tracks) => {
      console.log('created local track', tracks)
      const localMediaContainer = document.getElementById('local-media')
      tracks.forEach((track) => {
        localMediaContainer.appendChild(track.attach())
      })
      self.setState({ localStreamCreated: true })
    }).catch(err =>{
      toast.warning('Device not found')
      console.log('video in catch 1 error:', err)
      TWILIO_VIDEO.createLocalTracks({
        audio: true,
        video: false,
      }).then(tracks => {
        console.log('created local track inside modal in catch', tracks)
        self.setState({video: false})
        const localMediaContainer = document.getElementById('local-media');
        tracks.forEach(track => {
          localMediaContainer.appendChild(track.attach());
        })
        self.setState({ localStreamCreated: true })
      }).catch(err =>{
        console.log('video error:', err)
      })
    })
  }

  onRoomConnect(twilioToken, roomName) {
    console.log('FUNCTION CALLED: onRoomConnect (TWILIO_VIDEO.connect)')
    if (!twilioToken) {
      console.log('Token not present')
      return
    }
    console.log('TOKEN PRESENT:::', twilioToken)

    // let self = this
    // let {twilioToken} = this.state
    // let {videoPatientId, videoCallJoined, videoCallRoom} = this.state
    // if(videoCallJoined) {
    //   //call already joined.. user wants to disconnect
    //   if(!videoCallRoom)
    //     return

    //   console.log('disconnecting')
    //   videoCallRoom.disconnect()
    //   this.setState({ videoCallJoined: false, videoCallRoom: null})
    //   return
    // }

    // let {mongoUser, appointmentData} = this.props
    // if(!appointmentData || !appointmentData.patient_id) {
    //   toast.error('Cannot place the video call right now')
    //   return
    // }

    // console.log('appointment data', appointmentData)
    // let roomName = `${mongoUser.firebase_id}-${appointmentData.patient_id}`

    TWILIO_VIDEO.connect(twilioToken, {
      name: roomName,
      automaticSubscription: true,
      dominantSpeaker: true,
      video: this.state.video,
      audio: true,
    }).then((room) => {
      console.log('Connected to Room "%s"', room.name, room.sid)
      toast.success('Connected to room')
      this.setState({
        videoCallJoined: true,
        videoCallRoom: room,
        loading: false,
      })

      room.participants.forEach(participantConnected)
      room.on('participantConnected', participantConnected)

      room.on('dominantSpeakerChanged', handleDominantSpeaker)

      room.on('participantDisconnected', participantDisconnected)
      room.once('disconnected', (error) =>
        room.participants.forEach(participantDisconnected),
      )
    })

    function handleDominantSpeaker(participant) {
      console.log('Dominant participant: ', participant)
      if (!participant) {
        return
      }
      const domDiv = document.getElementById('dominant')
      participant.tracks.forEach((publication) => {
        // console.log('inside the dominant about if', )
        if (publication?.kind === 'video') {
          // console.log('inside the dominant inside if')
          // console.log('we can attach', JSON.stringify(publication?.track), JSON.stringify(publication?.track.attach()) )
          let wrapperDiv = document.createElement('div')
          wrapperDiv.style.cssText =
            'width: 350px; height: 350px; overflow: hidden'
          let dominantEle = publication?.track.attach()
          dominantEle.height = '450'
          dominantEle.width = '500'
          dominantEle.id = 'dominant'
          domDiv.parentNode.replaceChild(dominantEle, domDiv)
        }
      })
    }

    function participantConnected(participant) {
      console.log('Participant "%s" connected', participant.identity)
      toast.info('participant connected')

      const div = document.getElementById('remote-media')

      participant.tracks.forEach((publication) => {
        console.log(
          'existing tracks of remote participant',
          JSON.stringify(publication),
        )
        if (publication.isSubscribed) {
          trackPublished(div, publication, participant)
        }

        publication.on('subscribed', (track) => {
          console.log('new subscribed here', track)
          trackPublished(div, publication, participant)
        })

        publication.on('unsubscribed', (track) => {
          console.log('unpublished bevarc')
          if (track.kind !== 'data') {
            track.detach().forEach((element) => element.remove())
            // this.setState({remoteTrackAvailable: false})
          }
        })
      })

      participant.on('trackPublished', (publication) => {
        console.log('track published by remote participant')
        trackPublished(div, publication, participant)
      })

      participant.on('trackUnpublished', (publication) => {
        console.log(
          `RemoteParticipant ${participant.identity} unpublished a RemoteTrack: ${publication}`,
        )
      })
    }

    function participantDisconnected(participant) {
      console.log('Participant "%s" disconnected', participant.identity)
      toast.info('participant disconnected')
    }

    function trackPublished(div, publication, participant) {
      console.log(
        `RemoteParticipant ${
          participant.identity
        } published a RemoteTrack: ${JSON.stringify(publication)}`,
      )
      if (publication.isSubscribed && publication.kind !== 'data') {
        // console.log('we can attach', JSON.stringify(publication?.track), JSON.stringify(publication?.track.attach()) )
        let wrapperDiv = document.createElement('div')
        wrapperDiv.style.cssText =
          'width: 350px; height: 350px; overflow: hidden'
        let ele = publication.track.attach()
        ele.height = '250'
        ele.width = '250'
        wrapperDiv.appendChild(publication?.track.attach())
        div.appendChild(ele)
      }
    }

    function appendTrack(div, track) {
      console.log('appending track to div')
      div.appendChild(track.attach())
    }

    function trackUnsubscribed(track) {
      console.log('removing track from div')
      // track.detach().forEach(element => element.remove());
    }
  }

  onJoinCallPressed() {
    let { videoCallJoined, videoCallRoom } = this.state
    if (videoCallJoined) {
      //call already joined.. user wants to disconnect
      if (!videoCallRoom) return

      console.log('disconnecting')
      videoCallRoom.disconnect()
      this.setState({ videoCallRoom: null, videoCallJoined: false })
      return
    }
    if (this.props?.location?.circle) {
      this.fetchTwilioToken(this.props?.location?.circle)
    }
  }

  onClosePressed() {
    let { videoCallJoined, videoCallRoom } = this.state
    if (videoCallJoined) {
      if (!videoCallRoom) {
        this.props?.location?.fromCommunity === 'true'
          ? this.props.history.push('/patient/community')
          : this.props.history.push('/admin')
        return
      }
      console.log('disconnecting')
      videoCallRoom.disconnect()
      this.setState({ videoCallRoom: null, videoCallJoined: false })
      this.props?.location?.fromCommunity === 'true'
        ? this.props.history.push('/patient/community')
        : this.props.history.push('/admin')
      return
    }
    this.props?.location?.fromCommunity === 'true'
      ? this.props.history.push('/patient/community')
      : this.props.history.push('/admin')
  }

  onMuteVideoPressed() {
    let { videoMuted, videoCallRoom } = this.state
    if (!videoCallRoom) return
    if (videoMuted) {
      //if its muted, unmute it
      videoCallRoom.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable()
      })
    } else {
      videoCallRoom.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable()
      })
    }

    this.setState({ videoMuted: !videoMuted })
  }

  onMuteAudioPressed() {
    let { audioMuted, videoCallRoom } = this.state
    if (!videoCallRoom) return
    if (audioMuted) {
      //if its muted, unmute it
      videoCallRoom.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable()
      })
    } else {
      videoCallRoom.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable()
      })
    }
    this.setState({ audioMuted: !audioMuted })
  }

  renderVideoContent() {
    return (
      <div
        className={Styles.videoWrapper}
        style={{ width: this.state.videoCallJoined ? '140%' : '100%' }}
      >
        <p
          className={Styles.closeVideoBtn}
          onClick={() => {
            this.onClosePressed()
          }}
        >
          Close
        </p>

        <div style={{ marginTop: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
              }}
            >
              <div
                style={{
                  display: this.state.hideLocalMedia ? 'none' : 'inline',
                  marginRight: 20,
                }}
                id={'local-media'}
              ></div>
              <div style={{ height: 500, width: '100%', overflow: 'auto' }}>
                <div id="dominant" />
              </div>
            </div>

            {this.state.loading ? (
              <div
                className={GlobalStyles.container}
                style={{ overflow: 'hidden' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '100%',
                    height: 300,
                  }}
                >
                  <div className={GlobalStyles.loader} />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  overflow: 'auto',
                }}
              >
                <div
                  style={{
                    marginLeft: 20,
                    height: 300,
                    width: '100vw' / 2.5,
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'auto',
                  }}
                  id={'remote-media'}
                />
              </div>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              className={
                this.state.audioMuted
                  ? Styles.audioVideoBtnWrapperActive
                  : Styles.audioVideoBtnWrapper
              }
            >
              <FontAwesomeIcon
                onClick={this.onMuteAudioPressed.bind(this)}
                icon={faMicrophoneSlash}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  cursor: 'pointer',
                }}
              />
            </div>

            <div
              className={Styles.audioVideoBtnWrapper}
              style={{
                backgroundColor: this.state.videoCallJoined ? 'red' : 'white',
              }}
            >
              <FontAwesomeIcon
                onClick={this.onJoinCallPressed.bind(this)}
                icon={faPhone}
                style={{
                  color: this.state.videoCallJoined
                    ? 'white'
                    : Constants.primaryTheme,
                  fontSize: 20,
                  cursor: 'pointer',
                }}
              />
            </div>

            <div
              className={
                this.state.videoMuted
                  ? Styles.audioVideoBtnWrapperActive
                  : Styles.audioVideoBtnWrapper
              }
            >
              <FontAwesomeIcon
                onClick={this.onMuteVideoPressed.bind(this)}
                icon={faVideoSlash}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    // if(this.state.loading) {
    //   return (
    //     <div className={GlobalStyles.container} style={{overflow: 'hidden', maxHeight: '100vh'}}>
    //       <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: '80vh'}}>
    //         <div className={GlobalStyles.loader} />
    //       </div>
    //     </div>
    //   )
    // }
    return (
      <div
        className={GlobalStyles.container}
        style={{ overflowX: 'hidden', paddingBottom: '100px' }}
      >
        <Header
          header={
            this.props?.location?.circle?.title
              ? this.props?.location?.circle?.title
              : 'Circle Video'
          }
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: 10,
          }}
        >
          {this.renderVideoContent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    patient: state.patientReducer.patient,
  }
}

export default connect(mapStateToProps)(CircleVideo)
