import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../../config'

class PatientDetailsTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientId: props.patientId,
      phone: null,
      dob: null,
      eligibility: null,
      state: null,
      insId: null,
      loading: true,
    }
  }

  componentDidMount() {
    console.log('Mounted')
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}tooltip/get/user-details`,
      // url: `http://localhost:15000/tooltip/get/user-details`,
      params: {
        patientId: this.props.patientId,
      },
    }).then((res) => {
      if (res.data.success === false) {
        this.setState({ phone: 'ERRRO', loading: false })
        console.log('error getting patient details')
        return
      }
      console.log('Got data back for tooltip', res.data.data)
      let dob = res.data?.data?.dob
      if (dob) {
        dob = dob.split('T')[0]
        if (dob.includes('-')) {
          let iDob = dob.split('-')
          dob = iDob[1] + '/' + iDob[0] + '/' + iDob[2]
        } else if (dob.includes('/')) {
          let iDob = dob.split('/')
          dob = iDob[1] + '/' + iDob[0] + '/' + iDob[2]
        }
      }
      this.setState({
        phone: res.data?.data?.phone_number,
        dob: dob,
        cc: res.data?.data?.stripe?.customer_id,
        insurance: res.data?.data?.insurance_data,
        loading: false,
      })
    })
  }

  render() {
    if (this.state.loading) return <div>Loading...</div>
    return (
      <div>
        <div>Eligibility:{this.state.eligibility}</div>
        <div>ST:{this.state.state}</div>
        <div>PH:{this.state.phone}</div>
        <div>Insurance ID:{this.state.insId}</div>
        <div>DOB:{this.state.dob}</div>
        <div>CC on File:{this.state.cc ? 'Yes' : 'No'}</div>
        <div>Ins on File:{this.state.insurance ? 'Yes' : 'No'}</div>
      </div>
    )
  }
}

export default PatientDetailsTooltip
