import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Select from 'react-select'
import Config from '../../config'
import Styles from './styles/Roster.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import placeHolder from '../../assets/images/placeholder.png'
import { stateResidence } from '../../metadata'
import Constants from '../../values'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Table from '../../components/Table'
import { providerRosterColumns } from './Columns/providerRosterColumns'
import Switch from "react-switch";

const SLOT_AVAILABLE_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    // width: '80%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
  },
}

export default function ProviderRoster() {
  const history = useHistory()
  const token = useSelector((state) => state.authReducer.token)
  const patient = useSelector((state) => state.patientReducer.patient)
  const mongoUser = useSelector((state) => state.userReducer?.mongoUser)
  const userType = useSelector((state) => state.userReducer?.userType)
  const adminId = useSelector((state) => state.userReducer?.adminId)
  const loggedIn = useSelector((state) => state.userReducer.loggedIn)
  const [loading, setLoading] = useState(false)
  const [searchQueryString, setSearchQueryString] = useState('')
  const [speciality, setSpeciality] = useState(null)
  const [providersList, setProvidersList] = useState([])
  const [providersData, setProvidersData] = useState([])
  const [openAvailModal, setOpenAvailModal] = useState(false)
  const [selectedAvail, setSelectedAvail] = useState([])
  const [selectedOverride, setSelectedOverride] = useState({})

  useEffect(() => {
    if (!loggedIn) {
      history.push('/login')
      return
    }
    getProviderData()
  }, [])

  const getProviderData = () => {
    setLoading(true)

    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: adminId,
        Authorization: `JWT ${token}`,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        // console.log('got providers list', data)

        setProvidersData(data || [])
		setProvidersList(data || [])
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when getting all providers', err)
        setLoading(false)
      })
  }

  const updateProvider = (data) => {
    const url = `${Config.BACKEND_URL}providers`
    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: data,
    })
      .then(({ data }) => {
        console.log('successfully edited user in backend')
        getProviderData()
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  const closeModal = () => {
    setSelectedAvail([])
    setSelectedOverride([])
    setOpenAvailModal(false)
  }

  if(loading) {
    return (
      <div className={GlobalStyles.container} style={{ height: '100vh' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      </div>
    )
  }

  const renderAvailModal = () => {
    let timedifference = new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)
    return (
      <Modal
        ariaHideApp={true}
        onRequestClose={() => closeModal()}
        isOpen={openAvailModal}
        style={SLOT_AVAILABLE_MODAL_STYLES}
        contentLabel="Availability Modal"
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            onClick={() => {
              closeModal()
            }}
          >
            X
          </p>
          <h5 style={{ color: Constants.darkGray, textAlign: 'center' }}>
            {timedifference}
          </h5>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              borderTop: `1px solid ${Constants.darkGray}`,
              width: '100%',
            }}
          >
            <div
              style={{
                borderRight: `1px solid ${Constants.darkGray}`,
                paddingTop: 20,
                width: '50%',
              }}
            >
              <h4
                style={{ color: Constants.primaryTheme, textAlign: 'center' }}
              >
                Set your weekly hours
              </h4>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {renderTimeSlots()}
              </div>

              {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
								<Button
									onClick={() => this.saveSlotsToBackend(mongoUser)}
									className={GlobalStyles.button}
									// disabled={this.state.modalLoading}
									style={{ width: 200, marginTop: 20, marginBottom: 20 }}
									variant="primary"
								>
									Save
								</Button>
							</div> */}
            </div>
            <div style={{ paddingTop: 20, width: '50%' }}>
              <h4
                style={{ color: Constants.primaryTheme, textAlign: 'center' }}
              >
                Calendar exceptions
              </h4>
              <p
                style={{
                  color: Constants.darkGray,
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                Dates when your availability changes from your weekly hours
              </p>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {renderOverRideTimeSlots()}
                </div>
              </div>

            </div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderTimeSlots = () => {
    let slots = selectedAvail
    let toReturn = []

    Object.keys(slots).forEach((slot, index) => {
      toReturn.push(
        <div
          style={{
            display: 'flex',
            margin: '15px',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: '1px solid #DEDEDE',
            // borderTop:'1px solid #DEDEDE'
            // border-bottom: 1px solid #DEDEDE;
          }}
        >
          <div
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flex: 'auto',
            }}
          >
            <label class={Styles.checkContainer}>
              {slot}
              <input
                type="checkbox"
                checked={slots[slot].length !== 0 ? true : false}
                onClick={() => {
                  slots[slot].length !== 0
                    ? (slots[slot] = [])
                    : slots[slot].push({
                        start: 1635478200000,
                        end: 1635507000000,
                      })
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', flex: 'auto' }}
          >
            {renderSlots({
              slot: slots[slot],
              sday: slot,
            })}
          </div>
        </div>,
      )
    })

    return toReturn
  }

  const renderSlots = ({ slot, sday }) => {
    let returnSlots = []

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
      <Button
        onClick={onClick}
        ref={ref}
        className={Styles.dateInputButton}
        variant="primary"
      >
        {value}
      </Button>
    ))

    if (Array.isArray(slot)) {
      if (slot.length === 0) {
        returnSlots.push(
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              color: 'gray',
            }}
          >
            Unavailable
          </div>,
        )
      } else {
        slot.map((s, index) => {
          returnSlots.push(
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <DatePicker
                wrapperClassName={Styles.datePicker}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                selected={s.start}
                timeIntervals={15}
								disabled={true}
                onChange={(time) => {
                  // handleChange(
                  //   sday,
                  //   index,
                  //   time.valueOf(),
                  //   'start',
                  // )
                  // console.log(time)
                }}
                customInput={<CustomDateInput />}
                // locale="sv-sv"
              />
              <DatePicker
                wrapperClassName={Styles.datePicker}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                selected={s.end}
                timeIntervals={15}
								disabled={true}
                onChange={(time) => {
                  // handleChange(
                  //   sday,
                  //   index,
                  //   time.valueOf(),
                  //   'end',
                  // )
                  // console.log(time)
                }}
                // locale="sv-sv"
                customInput={<CustomDateInput />}
              />
            </div>,
          )
        })
      }
    }

    return returnSlots
  }

  const renderOverRideTimeSlots = () => {
    let slots = selectedOverride
    let toReturn = []

    Object.keys(slots).forEach((epochDate, index) => {
      let tempDateOfSlot = moment.unix(epochDate / 1000)
      if (tempDateOfSlot.isSameOrAfter(moment(), 'day'))
        // hide previous days data
        toReturn.push(
          <div
            style={{
              display: 'flex',
              margin: '15px',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1px solid #DEDEDE',
              // borderTop:'1px solid #DEDEDE'
              // border-bottom: 1px solid #DEDEDE;
            }}
          >
            <p>{epochDate && tempDateOfSlot.format('ll')}</p>
            <div
              style={{ display: 'flex', flexDirection: 'column', flex: 'auto' }}
            >
              {renderSlots({
                slot: slots[epochDate],
                sday: epochDate,
                type: 'override',
              })}
            </div>
            {/* <FontAwesomeIcon 
            icon={faPlus} 
            className={Styles.deleteIcon}
            onClick={() => {
              let slots = this.state.slot_override
              slots[epochDate].push({ start: moment(tempDateOfSlot).add(8,'hours').valueOf(), end: moment(tempDateOfSlot).add(9,'hours').valueOf() })
            }}
          />
          <FontAwesomeIcon
            icon={faMinusCircle}
            className={Styles.deleteIcon}
            onClick={() => this.handleOverrideSlotDeletion(epochDate, index)}
          /> */}
          </div>,
        )
    })

    return toReturn
  }
  const displayAvailability = (provider) => {
    setSelectedAvail(provider?.availability || [])
    setSelectedOverride(provider?.availability_override || {})
    setOpenAvailModal(true)
  }

	const TableHeader = () => {
		return (
			<div className={Styles.contentRow}>
				<h6
					className={Styles.mtableHeader}
				>
					Name
				</h6>
				<h6
					className={Styles.xtableHeader}
				>
					Email
				</h6>
				<h6
					className={Styles.mtableHeader}
				>
					Phone Number
				</h6>
				{/*<h6 className={Styles.tableHeader}>Frequency</h6>*/}
				<h6
					className={Styles.mtableHeader}
				>
					Licensed states
				</h6>
				<h6
					className={Styles.xtableHeader}
				>
					Specialization
				</h6>
				<h6
					className={Styles.mtableHeader}
				>
					User Type
				</h6>
				<h6
					className={Styles.mtableHeader}
				>
					Availability
				</h6>
				<h6
					className={Styles.smtableHeader}
				>
					Active
				</h6>
			</div>
		)
	}

	const TableContents = () => {
		let tableContentElement = []
		tableContentElement = providersList?.map((provider, index) => {
			return (
				<div key={index} className={Styles.contentRow}>
					<h7 className={Styles.mtableData}>
						{provider.first_name || ''} {provider.last_name || ''}
					</h7>
					<h7 className={Styles.xtableHeader}>
						{provider.email || ''}
					</h7>
					<h7 className={Styles.mtableHeader}>
						{provider.phoneNumber || ''}
					</h7>
					<h7 className={Styles.mtableData}>
						{	provider?.licensed_states?.length 
								? provider.licensed_states.map(state => (state?.abbr)).join()
								: 'N/A'
						}
					</h7>
					<h7 className={Styles.xtableHeader}>
						{provider.speciality}
					</h7>
					<h7 className={Styles.mtableData}>
						{provider.user_type}
					</h7>
					<h7 
						className={Styles.mtableData}
						style={{ color: '#20A892', textDecoration: 'underline', cursor: 'pointer' }}
						onClick={() => {
							setSelectedAvail(provider?.availability || [])
							setSelectedOverride(provider?.availability_override || {})
							setOpenAvailModal(true)
						}}
					>
						{ provider.inactive ? 'Inactive' : 'Availability' }
					</h7>
					<div 
						className={Styles.smtableData}
					>
						<Switch 
							onChange={(changed) => {
								let temp = providersList.slice()
								temp[index].inactive = !changed
								setProvidersList(temp)
								saveProviderData({ firebase_id: provider.firebase_id, inactive: !changed})
							}} 
							checked={!provider.inactive} 
						/>
					</div>
				</div>
			)
		})
		return tableContentElement
	}

	const saveProviderData = (toSaveObj) => {
		const url = `${Config.BACKEND_URL}providers`
		axios({
			method: 'put',
			url,
			headers: { Authorization: `JWT ${token}` },
			data: toSaveObj,
		})
		.then(({ data }) => {
			console.log('successfully update doctor to backend')
		})
		.catch((err) => {
			console.log('error when adding doctor data to backend', err)
		})
	}

	const SearchComponent = () => {
		return (
			<div className={Styles.row}>
				<p className={Styles.rowLabel}>Search</p>
				<div style={{ width: '30%' }}>
					<input
						placeholder="Search name or email"
						className={Styles.textInput}
						style={{ width: '100%' }}
						type="text"
						value={searchQueryString}
						onChange={(string) => {
							setSearchQueryString(string)
						}}
					/>
				</div>
			</div>
		)
	}

  // const FilterComponent = () => {
  // 	return (
  // 		<div className={Styles.row}>
  // 			<p className={Styles.rowLabel}>Filter</p>
  // 			<div style={{ width: '20%', paddingRight: '20px' }}>
  // 				<Select
  // 					inputId="membertype"
  // 					options={[
  // 						{ label: 'All access', value: 'premium' },
  // 						{ label: 'Membership', value: 'membership' },
  // 						{ label: 'Basic', value: 'basic' },
  // 						{ label: 'All', value: 'all' },
  // 					]}
  // 					value={memberType}
  // 					isSearchable
  // 					autoBlur
  // 					onChange={(val) => setMemberType(val)}
  // 					theme={(theme) => ({
  // 						...theme,
  // 						colors: {
  // 							...theme.colors,
  // 							primary25: '#20A89233',
  // 							primary: '#20A892',
  // 						},
  // 					})}
  // 				/>
  // 			</div>
  // 			<div style={{ width: '20%' }}>
  // 				<Select
  // 					inputId="state"
  // 					options={stateResidence}
  // 					value={patientState}
  // 					isSearchable
  // 					autoBlur
  // 					onChange={(val) => setPatientState(val)}
  // 					theme={(theme) => ({
  // 						...theme,
  // 						colors: {
  // 							...theme.colors,
  // 							primary25: '#20A89233',
  // 							primary: '#20A892',
  // 						},
  // 					})}
  // 				/>
  // 			</div>
  // 		</div>
  // 	)
  // }

  return (
    <div className={Styles.divider}>
      <h4 style={{ color: Constants.primaryTheme }}>Provider Roster</h4>
      {/* <SearchComponent/> */}
      {/* <FilterComponent/> */}
      {renderAvailModal()}
      <div
        style={{
          width: '100%',
          margin: 10,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {/* <div className={Styles.contentBackground}>
					<TableHeader/> 
					<TableContents/>
				</div> */}
        <div className={GlobalStyles.contentWrapper}>
          <Table
            columns={providerRosterColumns(providersList, displayAvailability, updateProvider)}
            data={providersList}
          />
        </div>
      </div>
    </div>
  )
}
