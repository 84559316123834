import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import FancyField from 'react-fancy-field'
import Button from 'react-bootstrap/Button'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Modal from 'react-modal'
import AilaLogo from '../../assets/images/aila_logo.png'
import moment from 'moment'

import {
  userLoggedIn,
  addAuthToken,
  addFirebaseAuthUser,
  fetchDoctorMongoUser,
  addUserType,
  addMongoUser,
  fetchTeamMongoUser,
  addAdminId,
  fetchCCFMPatientData,
  addFirebaseUser,
  addNavigationObject,
  fetchPatientProgressData,
  fetchPatientCoreDate,
  fetchPatientCareteam,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  addPatient,
  updateSideNavBar,
  getUnreadMessages,
} from '../../redux/actions'
import { firebase, analytics } from '../../services/firebase.js'
import Styles from './styles/Login.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import Config from '../../config'
import Constants from '../../values'
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null
const axios = require('axios')

const windowHeight = window.innerHeight
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: windowHeight / 1.5,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class Login extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      pwd: '',
      loading: false,
      error: '',
      errorString: '',
      modalLoading: false,
      modalIsOpen: false,
      resetEmail: '',
      modalError: false,
      modalErrorString: '',
      modalSuccess: false,
      modalSuccessString: '',
    }
    this.getAuthTokenAndNavigate = this.getAuthTokenAndNavigate.bind(this)
    this.getAuthTokenAndNavigateTeamMember =
      this.getAuthTokenAndNavigateTeamMember.bind(this)
    this.getAdminAccountDetailsAndNavigate =
      this.getAdminAccountDetailsAndNavigate.bind(this)
    this.getAuthTokenAndNavigatePatient =
      this.getAuthTokenAndNavigatePatient.bind(this)
    this.onLoginPressed = this.onLoginPressed.bind(this)
    this.firebaseAuthListener = null
    this.imei = null
  }

  componentDidMount() {
    this.props.addNavigationObject(this.props.history)
    const self = this
    this.getFingerPrint()
    this.firebaseAuthListener = firebase.auth().onAuthStateChanged((user) => {
      console.log('TESITNG: ', user?.uid)
      if (user?.uid) {
        this.setState({ loading: true })
        firebase
          .firestore()
          .collection('users')
          .doc(user?.uid)
          .get()
          .then((doc) => {
            if (doc && doc.data()) {
              const data = doc.data()
              console.log('user firebase data')
              self.props.addFirebaseUser(data)
              const { userType } = data
              self.props.addUserType(data.userType)
              if (userType && userType.includes('team')) {
                // its a team member account. get the admin account details
                self.getAuthTokenAndNavigateTeamMember(user)
                const { adminId } = data
                self.props.addAdminId(adminId)
              } else if (userType && userType === 'ccfm-patient') {
                self.getAuthTokenAndNavigatePatient(user)
                const { adminId } = data
                self.props.addAdminId(adminId)
              } else if (userType && userType === 'ccfm-client') {
                console.log('client')
                const { adminId } = data
                self.props.addFirebaseAuthUser(user)
                self.props.userLoggedIn(true)
                self.props.addAdminId(adminId)
                self.props.history.push('/wellness/dashboard')
              } else if (userType && userType.includes('admin')) {
                self.getAuthTokenAndNavigate(user)
                self.props.addAdminId(user.uid)
              } else {
                // self.setState({
                //   error: true,
                //   errorString:
                //     'We do not recognize this account. If you think this is an error, please contact support at support@ailahealth.com',
                //   loading: false,
                // })
                console.log('Patient Login')
                self.getAuthTokenAndNavigatePatientAila(user.uid, data)
                self.props.addAdminId(user.uid)
              }
            } else {
              console.log('no data')
              self.setState({
                error: true,
                errorString:
                  'We do not recognize this account. If you think this is an error, please contact support at support@ailahealth.com',
                loading: false,
              })
            }
          })
          .catch((err) => {
            console.log('error when checking for user in firebase', err)
          })
      } else {
        self.props.userLoggedIn(false)
      }
    })
  }

  async getFingerPrint() {
    try {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      const { visitorId } = result
      this.imei = visitorId
    } catch (err) {
      console.log('error when getting browser finger print', err)
    }
  }

  updateFcmToBackend(uid, token, fcmToken) {
    let url = Config.BACKEND_URL + 'providers'

    axios({
      method: 'put',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: uid },
      data: {
        firebase_id: uid,
        fcm_token: fcmToken,
      },
    })
      .then(({ data }) => {
        console.log('fcm updated')
      })
      .catch((err) => {
        console.log('error when updating fcm', err)
      })
  }

  getFcmToken(firebase_id, token) {
    console.log('getFcmToken:')
    messaging &&
      messaging
        .getToken({ vapidKey: Config.VAPID_KEY })
        .then((fcmToken) => {
          if (fcmToken) {
            // sendTokenToServer(fcmToken);
            console.log('fcm token: ', fcmToken)
            this.updateFcmToBackend(firebase_id, token, fcmToken)
          } else {
            // Show permission request.
            this.RequestPermission(firebase_id, token)
            // setTokenSentToServer(false);
          }
        })
        .catch((err) => {
          console.log('testing: ', err)
          // setTokenSentToServer(false);
        })
  }

  RequestPermission(firebase_id, token) {
    console.log('testing RequestPermission')
    messaging &&
      messaging
        .requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('have Permission')
            //calls method again and to sent token to server
            this.getFcmToken(firebase_id, token)
          } else {
            console.log('Permission Denied', permission)
          }
        })
        .catch(function (err) {
          console.log(err)
        })
  }

  componentWillUnmount() {
    this.firebaseAuthListener()
    this.firebaseAuthListener = undefined
  }

  onLoginPressed() {
    const { email, pwd } = this.state
    if (!email || email.length === 0 || !this.validateEmail(email)) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid email address',
      })
      return
    }

    if (!pwd || pwd.length < 6) {
      this.setState({
        error: true,
        errorString: 'The password must be at least 6 characters',
      })
      return
    }

    this.setState({ loading: true })

    const self = this

    firebase
      .auth()
      .signInWithEmailAndPassword(email, pwd)
      .then((user) => {
        // get the auth token for backend apis
        console.log('logged in')
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        self.setState({
          error: true,
          errorString:
            errorCode === 'auth/user-not-found'
              ? 'No user found'
              : errorMessage,
          loading: false,
        })
      })
  }

  async getAuthTokenAndNavigateTeamMember(user) {
    if (!user) {
      this.setState({
        loading: false,
        error: true,
        errorString: 'Invalid user',
      })
      return
    }

    const self = this
    const authUrl = `${Config.BACKEND_URL}authorization`
    try {
      // get authorization token for all backend APIs
      const response = await axios({
        method: 'get',
        url: authUrl,
        headers: {
          x_firebase_id: user.uid,
          x_user_type: 'provider',
          imei: this.imei,
        },
      })
      if (response.status === 200) {
        const { data } = response
        self.uid = user.uid
        self.token = data.token
        self.getFcmToken(user?.uid, data.token)
        self.props.addAuthToken(data.token)
        self.props.addFirebaseAuthUser(user)
        self.props.fetchTeamMongoUser(user.uid, data.token)
        // get admin account details
        this.getAdminAccountDetailsAndNavigate(user, data.token)
      } else {
        console.log('error', response)
      }
    } catch (err) {
      console.log('error when getting auth token', err)
      if (err && err.response && err.response.status === 409) {
        self.setState({
          loading: false,
          error: true,
          errorString:
            'We do not recognize this browser. Please contact support to enable authorization.',
        })
      } else {
        self.setState({
          loading: false,
          error: true,
          errorString: 'Error getting authorization for backend',
        })
      }
    }
  }

  async getAdminAccountDetailsAndNavigate(user, token) {
    const self = this
    console.log('getting admin details account')
    const adminDataUrl = `${Config.BACKEND_URL}providers/admin`
    try {
      const adminResponse = await axios({
        method: 'get',
        url: adminDataUrl,
        headers: { x_firebase_id: user.uid, Authorization: `JWT ${token}` },
      })

      if (adminResponse.status !== 200 || !adminResponse.data) {
        // no corresponding admin account info
        console.log('Admin might have revoked your status')
        self.setState({
          loading: false,
          error: true,
          errorString:
            'Administrator might have revoked your access. Contact support if you think this is an error.',
        })
        return
      }

      const adminUserData = adminResponse.data
      self.props.addMongoUser(adminUserData)
      self.props.userLoggedIn(true)
      // self.props.history.push('/patient/list')
      self.props.history.push('provider/home')
    } catch (err) {
      console.log('error when getting admin account details', err)
      self.setState({
        loading: false,
        error: true,
        errorString: 'Something went wrong. Please contact support.',
      })
    }
  }

  async getAuthTokenAndNavigatePatient(user) {
    const self = this
    const authUrl = `${Config.BACKEND_URL}authorization`
    try {
      // get authorization token for all backend APIs
      const response = await axios({
        method: 'get',
        url: authUrl,
        headers: {
          x_firebase_id: user.uid,
          x_user_type: 'patient',
          imei: this.imei,
        },
      })
      if (response.status === 200) {
        const { data } = response
        self.token = data.token
        self.getFcmToken(user?.uid, data.token)
        self.props.fetchCCFMPatientData(user.uid, data.token)
        self.props.addAuthToken(data.token)
        self.props.addFirebaseAuthUser(user)
        self.props.userLoggedIn(true)
        self.props.history.push('/wellness/dashboard')
      } else {
        console.log('error', response)
      }
    } catch (err) {
      console.log('error when getting auth token', err)
      if (err && err.response && err.response.status === 409) {
        self.setState({
          loading: false,
          error: true,
          errorString:
            'We do not recognize this browser. Please contact support to enable authorization.',
        })
      } else {
        self.setState({
          loading: false,
          error: true,
          errorString: 'Error getting authorization for backend',
        })
      }
    }
  }

  async getAuthTokenAndNavigate(user) {
    if (!user) {
      this.setState({
        loading: false,
        error: true,
        errorString: 'Invalid user',
      })
      return
    }

    console.log('getting auth token admin account')

    const self = this
    const authUrl = `${Config.BACKEND_URL}authorization`
    try {
      // get authorization token for all backend APIs
      const response = await axios({
        method: 'get',
        url: authUrl,
        headers: {
          x_firebase_id: user.uid,
          x_user_type: 'provider',
          imei: this.imei,
        },
      })
      if (response.status === 200) {
        const { data } = response
        self.uid = user.uid
        self.token = data.token
        self.getFcmToken(user?.uid, data.token)
        await self.props.fetchDoctorMongoUser(user.uid, data.token)
        self.props.addAuthToken(data.token)
        self.props.addFirebaseAuthUser(user)
        self.props.userLoggedIn(true)
        self.props.getUnreadMessages(user.uid, data.token, {
          created_at: moment(user?.metadata?.creationTime).format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
          ),
        })
        self.props.history.push('provider/home')
        // self.props.history.push('/patient/list')
        // self.props.history.push('/account')
      } else {
        console.log('error', response)
      }
    } catch (err) {
      console.log('error when getting auth token', err)
      if (err && err.response && err.response.status === 409) {
        self.setState({
          loading: false,
          error: true,
          errorString:
            'We do not recognize this browser. Please contact support to enable authorization.',
        })
      } else {
        self.setState({
          loading: false,
          error: true,
          errorString: 'Error getting authorization for backend',
        })
      }
    }
  }

  async getAuthTokenAndNavigatePatientAila(adminId, patient) {
    if (!adminId) {
      this.setState({
        loading: false,
        error: true,
        errorString: 'Invalid user',
      })
      return
    }

    console.log('getting auth token admin account..its a patient profile guys')

    analytics.logEvent('Web_Login')

    const self = this
    const authUrl = `${Config.BACKEND_URL}authorization`
    try {
      // get authorization token for all backend APIs
      const response = await axios({
        method: 'get',
        url: authUrl,
        headers: {
          x_firebase_id: adminId,
          // x_user_type: 'provider',
          // imei: this.imei,
        },
      })
      if (response.status === 200) {
        const { data } = response
        self.uid = adminId
        self.token = data.token
        self.getFcmToken(adminId, data.token)
        // self.props.fetchDoctorMongoUser(adminId, data.token)
        self.props.addAuthToken(data.token)
        self.props.addFirebaseAuthUser(adminId)
        self.props.userLoggedIn(true)
        self.props.fetchPatientProgressData(patient.uid, data.token, 'complete')
        self.props.fetchPatientCoreDate(patient.uid, data.token)
        self.props.fetchPatientCareteam(patient.uid, data.token)
        self.props.fetchPatientWellnessScreening(patient.uid, data.token)
        self.props.fetchPatientCalculateProgressData(patient.uid, data.token)
        self.props.fetchPatientConditions(patient.uid, data.token)
        self.props.fetchPatientMedications(patient.uid, data.token)
        self.props.fetchPatientLabs(patient.uid, data.token)
        self.props.addPatient(patient, 'gray')
        self.props.updateSideNavBar(!this.state.updateRender)
        console.log('navigating to patient profile')
        if (self.props?.location?.longCovidFlow) {
          self.props.userLoggedIn(false)
          self.props.history.push('/long-covid/questionnaire', {
            patient_id: patient.uid,
            email_id: patient.email,
            logged_in: true,
            token: data.token,
          })
        } else if (!patient?.firstName || !patient?.lastName) {
          //WHEN PATIENT DATA NOT SAVED IN MONGO DB
          self.props.userLoggedIn(false)
          console.log('patient mongo data does not exist')
          self.props.history.push('/wix/aila-web-questions', {
            patient_id: patient?.uid,
            email_id: patient?.email,
            token: data.token,
          })
          return
        } else {
          self.props.history.push('/patient/home')
        }
      } else {
        console.log('error', response)
      }
    } catch (err) {
      console.log('error when getting auth token', err)
      if (err && err.response && err.response.status === 409) {
        self.setState({
          loading: false,
          error: true,
          errorString:
            'We do not recognize this browser. Please contact support to enable authorization.',
        })
      } else {
        self.setState({
          loading: false,
          error: true,
          errorString: 'Error getting authorization for backend',
        })
      }
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  onModalSubmitPressed() {
    const { resetEmail } = this.state
    if (
      !resetEmail ||
      resetEmail.length === 0 ||
      !this.validateEmail(resetEmail)
    ) {
      this.setState({
        modalError: true,
        modalErrorString: 'Please enter a valid email',
      })
      return
    }

    this.setState({ modalLoading: true })

    firebase
      .auth()
      .sendPasswordResetEmail(resetEmail)
      .then(() => {
        this.setState({
          modalSuccess: true,
          modalSuccessString: 'Please check your inbox for instructions',
          modalLoading: false,
        })
      })
      .catch((err) => {
        console.log('error when sending password reset email', err)
        if (err && err.code === 'auth/user-not-found')
          this.setState({
            modalError: true,
            modalErrorString:
              'We could not find any account corresponding to the entered email',
            modalLoading: false,
          })
        else
          this.setState({
            modalError: true,
            modalErrorString: 'Something went wrong',
            modalLoading: false,
          })
      })
  }

  renderModal() {
    const { modalLoading } = this.state
    if (modalLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100%',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <h4 style={{ color: Constants.primaryTheme }}>Reset Password</h4>

        <div className={Styles.modalContentWrapper}>
          <input
            placeholder="Enter your email...."
            className={Styles.textInput2}
            type="text"
            value={this.state.resetEmail}
            onChange={(e) => {
              this.setState({ resetEmail: e.target.value, modalError: false })
            }}
          />

          {this.state.modalError && (
            <p style={{ fontSize: 12, color: 'red', marginTop: 10 }}>
              {this.state.modalErrorString}
            </p>
          )}

          {this.state.modalSuccess && (
            <p style={{ fontSize: 12, marginTop: 10 }}>
              {this.state.modalSuccessString}
            </p>
          )}
        </div>

        <Button
          onClick={this.onModalSubmitPressed.bind(this)}
          className={GlobalStyles.button}
          style={{ width: 200, position: 'absolute', bottom: 30 }}
          variant="primary"
        >
          Send
        </Button>
      </div>
    )
  }

  handleCloseModal() {
    this.setState({
      modalIsOpen: false,
    })
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          margin: '0 auto',
          backgroundColor: 'white',
        }}
      >
        <div className={Styles.wrapper}>
          <img src={AilaLogo} style={{ height: 100, width: 100 }} />

          <p style={{ fontSize: 20, marginTop: 20 }}>Welcome to Aila Health</p>

          {this.state.loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: 200,
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <FancyField
                value={this.state.email}
                classes={Styles.textInput}
                label="Email"
                disabled={this.state.loading}
                required={false}
                name="emailInput"
                autoFocus
                onChange={(val) => this.setState({ email: val, error: false })}
                placeholder="Enter your email... "
              />

              <FancyField
                value={this.state.pwd}
                classes={Styles.textInput}
                label="Password"
                disabled={this.state.loading}
                required={false}
                type="password"
                name="pwdInput"
                onChange={(val) => this.setState({ pwd: val, error: false })}
                placeholder="Enter your password... "
              />

              <p
                style={{
                  marginTop: 20,
                  fontSize: 12,
                  color: 'red',
                  visibility: this.state.error ? 'visible' : 'hidden',
                }}
              >
                {this.state.errorString}
              </p>

              <Button
                onClick={this.onLoginPressed}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{ marginTop: 40, width: 200 }}
                variant="primary"
              >
                {this.state.loading ? 'Loading....' : 'Sign In'}
              </Button>

              {/* <a href={uri} style={{marginTop: 10}}>Connect oura</a> */}

              <p style={{ marginTop: 10 }}>
                Forgot password?
                <span
                  onClick={() => this.setState({ modalIsOpen: true })}
                  style={{
                    color: '#007bff',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: 5,
                  }}
                >
                  Reset
                </span>
              </p>

              <p style={{ marginTop: 10 }}>
                Don't have an account?{' '}
                <Link to="/signup/patient">
                  <span style={{ textDecoration: 'underline' }}>Sign Up</span>
                </Link>
              </p>
            </div>
          )}

          <Modal
            ariaHideApp={false}
            isOpen={this.state.modalIsOpen}
            onRequestClose={() => this.handleCloseModal()}
            style={customModalStyles}
            contentLabel="Modal"
          >
            {this.renderModal()}
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  loggedIn: state.userReducer.loggedIn,
  updateRender: state.userReducer.render,
  token: state.authReducer.token,
})

const mapDispatchToProps = {
  addNavigationObject,
  userLoggedIn,
  addAuthToken,
  addFirebaseAuthUser,
  fetchDoctorMongoUser,
  addUserType,
  addMongoUser,
  fetchTeamMongoUser,
  addAdminId,
  fetchCCFMPatientData,
  addFirebaseUser,
  fetchPatientProgressData,
  fetchPatientCoreDate,
  fetchPatientCareteam,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  addPatient,
  updateSideNavBar,
  getUnreadMessages,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
