import React, { Component } from 'react'
import GlobalStyles from '../../../styles/global.module.scss'
import Config from '../../../../config'
import Styles from './styles/EducationProgress.module.scss'
import Constants from '../../../../values'
import { connect } from 'react-redux'
import { stopTimer } from '../../../../redux/actions'
const axios = require('axios')

class EducationProgress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      modalOption: {},
      modalSelectOption: {},
      fileCount: 1,
      numPages: null,
      pageNumber: 1,
      title: '',
      image_url: '',
      content_url: '',
      video_url: '',
      edu_content: [],
      finalMap: [],
      loading: true,
      listEducation: [],
    }
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    let { adminId, token } = this.props
    this.props.stopTimer(adminId, token)
    this.getEducationalContent()
  }

  getEducationalContent() {
    let { token } = this.props
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `education/content`,
      headers: {
        Authorization: 'JWT ' + token,
      },
    }
    this.setState({ loading: true })

    axios(config)
      .then(({ data }) => {
        const groupData = (data) => {
          let g = Object.entries(
            data.reduce(
              (r, c) => ((r[c.category] = [...(r[c.category] || []), c]), r),
              {},
            ),
          )
          return g.reduce(
            (r, c) => (r.mainlist.push({ category: c[0], sublist: c[1] }), r),
            { mainlist: [] },
          )
        }
        const finalMap1 = groupData(data)
        this.setState({ finalMap: finalMap1 })
        console.log('FINAL MAP: ', this.state.finalMap.mainlist)
        console.log('DATA RECIEVED FROM BACKEND:', data)
        data.forEach((element) => {
          this.setState({
            edu_content: [
              ...this.state.edu_content,
              {
                category: element.category,
                title: element.title,
                image_url: element.image_url,
                video_url: element.video_url,
                content_url: element.content_url,
                premium: element.premium,
              },
            ],
          })
        })
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('Error getting Educational content for the Patient ', err)
      })
  }

  getContent(data) {
    let options = data
    let toReturn = []

    for (let i = 0; i < options.length; i++) {
      let each = options[i]
      let { patient } = this.props

      this.getUrlsMap = () => {
        let urlsMap = []
        if (Array.isArray(each.content_url)) {
          each.content_url.forEach((element, index) => {
            urlsMap.push({
              label: `${each.title} Article ${index + 1}`,
              value: element,
            })
          })
        }
        return urlsMap
      }
      toReturn.push(
        <div key={i.toString()} style={{ marginTop: 10, marginBottom: 10 }}>
          <label class={Styles.checkContainer}>
            {each.title}
            <input
              type="checkbox"
              checked={
                each.patients?.[patient.uid]?.read
                  ? each.patients?.[patient.uid]?.read
                  : false
              }
              disabled={true}
            />
            <span class={Styles.checkmark}></span>
          </label>
        </div>,
      )
    }

    return toReturn
  }

  renderList() {
    let data = this.state.finalMap.mainlist

    let toReturn = []
    data.forEach((each) => {
      toReturn.push(
        <div style={{ width: '40%', margin: 20 }}>
          <h4 style={{ color: Constants.primaryTheme }}>{each.category}</h4>
          {this.getContent(each.sublist)}
        </div>,
      )
    })

    return toReturn
  }


  render() {
    if (this.state.loading) {
      return (
        <div className={GlobalStyles.container} style={{ height: '100vh' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div
        className={GlobalStyles.contentWrapper}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: '20px 50px',
        }}
      >
        {this.renderList()}
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    adminId: state.userReducer.adminId,
    patient: state.patientReducer.patient,
  }
}

const mapDispatchToProps = { stopTimer }

export default connect(mapStateToProps, mapDispatchToProps)(EducationProgress)
