import React, { Component, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import { addMongoUser, stopTimer } from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'

import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Credentialing.module.scss'
import Config from '../../config'
import FileViewer from '../../components/pdf/FileViewer'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles2 = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth - 200,
    height: windowHeight - 20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const Malpractice = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)

  const mongoUser = useSelector((state) => state.userReducer?.providerData)
  const userType = useSelector((state) => state.userReducer?.userType)
  const adminId = useSelector((state) => state.userReducer?.adminId)
  const firebaseAuthUser = useSelector(
    (state) => state.userReducer?.firebaseAuthUser,
  )

  const [loading, setLoading] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [docFile, setDocFile] = useState('')

  const [malpractice, setMalpractice] = useState(null)
  const [uploadingProof, setUploadingProof] = useState(false)

  //IDENTIFICATION DATA
  const [npi, setNpi] = useState(null)
  const [caqh, setCaqh] = useState('')
  const [accessData, setAccessData] = useState(false)
  const [dea, setDea] = useState('')
  const [cds, setCds] = useState('')
  const [medicareId, setMedicareId] = useState('')
  const [medicaidId, setMedicaidId] = useState('')
  const [attest, setAttest] = useState(false)

  useEffect(() => {
    setLoading(true)
    getProviderData()
    setLoading(false)
  }, [])

  const getProviderData = () => {
    setLoading(true)
    // console.log('mongo user::', adminId, userType, mongoUser)
    // console.log('firebaseuser::', firebaseAuthUser)

    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: mongoUser.firebase_id, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        console.log('got doctor mongo details', data)

        setMalpractice(data?.malpractice || null)

        setLoading(false)
        // dispatch(addMongoUser(data))
      })
      .catch((err) => {
        console.log('error when getting doctor mongo user Malpractice', err)
      })
  }

  const onSavePressed = () => {
    let returnData = {
      malpractice: malpractice,
    }
    // console.log(returnData)

    props.parentCallback(returnData)
  }

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    console.log('UPLOADING PROOF', uploadingProof)
    if (event?.target?.files[0]) {
      console.log(event?.target?.files[0])
      //   this.setState({
      //     modalFileName: event.target.files[0].name,
      //     modalFileType: event.target.files[0].type,
      //   })

      // if (event?.target?.files[0].type !== 'application/pdf') {
      //   toast.warning('Please upload PDF files')
      //   return
      // }
      setLoading(true)

      //SAVE IN FIRESTORE STORAGE
      const file = event?.target?.files[0]
      let storageUrl = `providers/${mongoUser.firebase_id}/${'Claim' + '_'+ file.name}`
      if(uploadingProof){
        storageUrl = `providers/${mongoUser.firebase_id}/${'Proof' + '_'+ file.name}`
      }

      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef
        .child(storageUrl)
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          setLoading(false)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log(downloadURL)

            let temp = { ...malpractice }
            if (uploadingProof) {
              temp.proof = downloadURL
            } else {
              temp.claims = downloadURL
            }
            setMalpractice(temp)
            setLoading(false)
          })
        },
      )
    }
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: '60vh', // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const renderContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          padding: '1% 5%',
        }}
      >
        {/* Proof of Liability Insurance */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Proof of Liability Insurance</p>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {malpractice?.proof && (
              <FontAwesomeIcon
                onClick={() => {
                  setDocFile(malpractice?.proof)
                  setOpenDoc(true)
                }}
                icon={faEye}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  width: '10%',
                  cursor: 'pointer',
                }}
              />
            )}
            <label
              htmlFor="proof_files"
              style={{
                textDecoration: 'underline',
                color: Constants.primaryTheme,
                cursor: 'pointer',
              }}
            >
              Upload PDF
            </label>
            <input
              id="proof_files"
              accept="application/pdf"
              style={{ display: 'none' }}
              type="file"
              onClick={() => {
                setUploadingProof(true)
              }}
              onChange={onFileChange}
            />
          </div>
        </div>

        {/* Malpractice Claims History  */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Malpractice Claims History</p>
          {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}> */}
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            {malpractice?.claims && (
              <FontAwesomeIcon
                onClick={() => {
                  setDocFile(malpractice?.claims)
                  setOpenDoc(true)
                }}
                icon={faEye}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  width: '10%',
                  cursor: 'pointer',
                }}
              />
            )}
            <label
              htmlFor="CLAIM_files"
              style={{
                textDecoration: 'underline',
                color: Constants.primaryTheme,
                cursor: 'pointer',
              }}
            >
              Upload your current/most recent Loss Run Report for your
              malpractice policy/policies
            </label>
            <input
              id="CLAIM_files"
              accept="application/pdf"
              style={{ display: 'none' }}
              type="file"
              onClick={() => {
                setUploadingProof(false)
              }}
              onChange={onFileChange}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginTop: '1%',
                justifyContent: 'flex-start',
              }}
            >
              <label className={Styles.checkContainer}>
                <p
                  style={{
                    color: Constants.darkGray,
                    width: '100%',
                    overflowWrap: 'break-word',
                    display: 'block',
                  }}
                >
                  Yes, my hospital and clinic privileges are in good standing
                </p>
                <input
                  type="checkbox"
                  checked={malpractice?.privileges}
                  onClick={() => {
                    let temp = { ...malpractice }
                    temp.privileges = !malpractice.privileges
                    setMalpractice(temp)
                  }}
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
          </div>
        </div>

        {/* Bio field */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>
            Please explain any history of any suspension or curtailment of
            hospital and clinic privileges
          </p>
          <div style={{ width: '100%' }}>
            <textarea
              placeholder="Enter..."
              cols="20"
              rows="4"
              style={{
                width: '100%',
                borderBlockColor: 'lightgray',
                borderRadius: '5px',
                resize: 'none',
              }}
              value={malpractice?.suspension}
              onChange={(e) => {
                let temp = { ...malpractice }
                temp.suspension = e.target.value
                setMalpractice(temp)
              }}
            ></textarea>
          </div>
        </div>

        {/* Bio field */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>
            Please explain any history of any sanctions from participating in
            Medicare and/or Medicaid/Medi-Cal
          </p>
          <div style={{ width: '100%' }}>
            <textarea
              placeholder="Enter..."
              cols="20"
              rows="4"
              style={{
                width: '100%',
                borderBlockColor: 'lightgray',
                borderRadius: '5px',
                resize: 'none',
              }}
              value={malpractice?.sanctions}
              onChange={(e) => {
                let temp = { ...malpractice }
                temp.sanctions = e.target.value
                setMalpractice(temp)
              }}
            ></textarea>
          </div>
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            onClick={onSavePressed}
            className={GlobalStyles.button}
            disabled={loading}
            style={{ width: 200 }}
            variant="primary"
          >
            {loading ? 'Loading....' : 'Save'}
          </Button>
          {/* {error && (
            <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
              {errorString}
            </p>
          )} */}
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={openDoc}
          onRequestClose={() => {
            setOpenDoc(false)
            setDocFile('')
          }}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          <FileViewer fileType="pdf" fileName={docFile} />

          {/* <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${docFile}`}
          /> */}
        </Modal>
      </div>
    )
  }

  if (loading) return renderLoader()
  return renderContent()
}

export default Malpractice
