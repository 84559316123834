import React, { Component } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import {
  stopTimer,
  addProviderData,
  fetchSpecificDoctorMongoUser,
  toggleSidebar,
} from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'
import SignaturePad from 'react-signature-canvas'

import { SPECIALITY_OPTIONS } from '../../metadata.js'
import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Account.module.scss'
import Config from '../../config'
import ReactHlsPlayer from 'react-hls-player'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth

const customContentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '70%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class Tutorials extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tutorials: [],
      modalIsOpen: false,
      modalOption: {},
      modalSelectOption: {},
      fileCount: 1,
      numPages: null,
      pageNumber: 1,
      title: '',
      image_url: '',
      content_url: '',
      video_url: '',
      edu_content: [],
      finalMap: [],
      loading: true,
      listEducation: [],
    }
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    let { adminId, token } = this.props
    this.props.stopTimer(adminId, token)
    this.setState({ loading: true })
    this.getTutorialsContent()
  }

  getTutorialsContent() {
    let { token } = this.props
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `provider-tutorials`,
      headers: {
        Authorization: 'JWT ' + token,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log('Provider tutorials:', data)
        this.setState({ tutorials: data })       
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('Error getting Educational content for the Patient ', err)
      })
  }

  updateReadTutorials(tutorial) {
    let { token, adminId } = this.props
    let config = {
      method: 'put',
      url: Config.BACKEND_URL + `provider-tutorials`,
      headers: {
        Authorization: 'JWT ' + token,
      },
      data: { tutorial: tutorial, provider: adminId },
    }
    axios(config)
      .then(({ data }) => {
        console.log('Tutorial read updated')
        this.getTutorialsContent()
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('Error getting Educational content for the Patient ', err)
      })
  }

  renderList() {
    let { adminId, token } = this.props
    let data = this.state.tutorials

    let toReturn = []
    data.forEach((each, i) => {
      toReturn.push(
        <div style={{ width: '40%', margin: 10 }}>
          <div key={i.toString()}>
            <label
              onClick={() => {
                this.setState({
                  title: each.title,
                  content_url: each.content_url,
                  modalIsOpen: true,
                })
                this.props.toggleSidebar(true)
                if (!each.providers?.[adminId]?.read) {
                  this.updateReadTutorials(each)
                }
              }}
              style={{
                cursor: 'pointer',
                color: Constants.primaryTheme,
                fontSize: 'larger',
              }}
              class={Styles.checkContainer}
            >
              {each.title}
              <input
                type="checkbox"
                checked={
                  each.providers?.[adminId]?.read
                    ? each.providers?.[adminId]?.read
                    : false
                }
                // disabled={true}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>      
        </div>,
      )
    })

    return toReturn
  }

  renderModalContent() {
    let { title, content_url } = this.state
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignContent: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.setState({ modalIsOpen: false, title: '', content_url: '' })
            this.props.toggleSidebar(false)
          }}
        >
          x
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          {title}
        </h4>

        {content_url && (
          <div
            style={{
              overflow: 'hidden',
              marginTop: '20px',
              width: '80%',
              alignSelf: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <FileViewer fileType="m3u8" fileName={video_url} /> */}

            <ReactHlsPlayer
              src={content_url}
              autoPlay={true}
              controls={true}
              width="100%"
              height="auto"
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        <Header header="Videos" />
        {this.state.loading ? (
          <div className={GlobalStyles.container} style={{ height: '100vh' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: '100%',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          </div>
        ) : (
          <div
            className={GlobalStyles.contentWrapper}
            style={{
              display: 'flex',
              flexDirection: 'column',
              // flexWrap: 'wrap',
              padding: '20px 50px',
              flexFlow: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {this.renderList()}

            <Modal
              ariaHideApp={false}
              isOpen={this.state.modalIsOpen}
              onRequestClose={() => {
                this.setState({ modalIsOpen: false, content_url: '' })
                this.props.toggleSidebar(false)
              }}
              style={customContentModalStyles}
              contentLabel="Modal"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {this.renderModalContent()}
              </div>
            </Modal>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  const { userReducer } = state
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    loggedInUser: userReducer.mongoUser,
    mongoUser: userReducer.providerData,
    userType: userReducer.userType,
    adminId: userReducer.adminId,
  }
}

const mapDispatchToProps = {
  addProviderData,
  stopTimer,
  fetchSpecificDoctorMongoUser,
  toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tutorials)
