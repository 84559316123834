import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'

export const payoutsColumns = (providersList, patientList) => {
  return [
    {
      Header: 'Patient Name',
      accessor: 'patientName',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Invoice ID',
      accessor: 'invoiceId',
      Cell: ({ row }) => {
        return (
          <Link
            onClick={() => {
              console.log('view invoice')
            }}
            // to={{
            //   pathname: '/patient/profile',
            //   state: 'info',
            //   key: 2,
            // }}
            className={Styles.link}
          >
            {row?.invoice_id || ''}
          </Link>
        )
      },
      width: 150,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      // obj.firstName && obj.lastName ? obj.firstName + ' ' + obj.lastName : '',
    },

    {
      Header: 'Provider Name',
      accessor: 'providerName',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Date of Service',
      accessor: (obj) =>
        obj?.date_of_service
          ? moment(obj?.date_of_service).format('ll')
          : moment(obj.date_of_service).format('ll'),
      width: 200,
      sort: true,
    },
    {
      Header: 'Product/ Service',
      accessor: (obj) => obj?.service,
      width: 200,
      sort: true,
    },
    {
      Header: 'Type/ Description',
      accessor: (obj) => obj?.status,
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="Statustype"
                placeholder="Select"
                options={[
                  { label: '15 Minute Visit', value: '15 Minute Visit' },
                  { label: '30 Minute Visit', value: '30 Minute Visit' },
                  { label: '45 Minute Visit', value: '45 Minute Visit' },
                  { label: '60 Minute Visit', value: '60 Minute Visit' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Amount',
      accessor: (obj) => (obj?.charge_amount ? obj?.charge_amount : 'Nil'),
      width: 200,
      sort: true,
    },
    {
      Header: 'Amount Paid',
      accessor: (obj) => (obj?.paid_amount ? obj?.paid_amount : 'Nil'),
      width: 200,
      sort: true,
    },
    {
      Header: 'Status',
      accessor: (obj) => obj?.status,
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="Statustype"
                placeholder="Select"
                options={[
                  { label: 'Succeeded', value: 'Succeeded' },
                  { label: 'Refunded', value: 'Refunded' },
                  { label: 'Payment Failed', value: 'Payment Failed' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
  ]
}
