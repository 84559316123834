import React, { useState, Component, useEffect } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import moment from 'moment'

import { loadStripe } from '@stripe/stripe-js'
import {
  addMongoUser,
  stopTimer,
  updateDoctorMongoUser,
  fetchDoctorMongoUser,
} from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'

import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Credentialing.module.scss'
import Config from '../../config'
import Identification from './Identification'
import Licensure from './Licensure'
import EducationCertification from './EducationCertification'
import Malpractice from './Malpractice'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customModalStyles2 = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth - 200,
    height: windowHeight - 20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

class Invoices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //tab index to render
      renderTabsIndex: 1,

      month: null,
      selectedMonth: null,
      sum1: 0,
      sum2: 0,
      total: 0,

      showConnectCalendar: true,
      speciality: null,
      degree: null,
      gender: null,
      languages: null,
      insurances: null,
      userDetails: null,
      loading: false,

      signLocation: '',
      signImage: null,
      signUploaded: false,
      signChanged: false,

      profileChanged: false,
      imageUploaded: false,
      imageLocation: '',
      image: null,
      error: false,
      errorString: '',
      saving: false,
      locations: [null],
      modalIsOpen: false,
      modalLoading: false,
      modalError: false,
      modalErrorString: '',
      modalNumber: '',
      organization: '',
      showIframe: false,
      region: '',
      states: null,
      licenseNumbers: null,
      statesAbbrs: null,
      verifiedFlags: null,
    }
    this.imageUploadRef = React.createRef()
    this.frontImageRef = React.createRef()
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    const { token, adminId } = this.props

    this.props.stopTimer(adminId, token)
  }

  componentWillMount() {
    let day = [],
      month = [],
      year = []

    let monthIndex = 1
    for (const monthName of moment.localeData().months()) {
      month.push({
        label: monthName,
        value: monthIndex,
      })
      monthIndex++
    }

    this.setState({
      month: month,
    })
  }

  onSavePressed() {
    const { mongoUser, token } = this.props
    const self = this
    self.setState({ loading: true })

    const data = {
      provider_id: mongoUser.firebase_id,
      clinical_time: self.state.sum1,
      non_clinical_time: self.state.sum2,
      total_time: Number(self.state.sum1) + Number(self.state.sum2),
      invoice_month: self.state.selectedMonth,
    }

    console.log('save pressed', data)

    const url = `${Config.BACKEND_URL}invoice/provider`
    axios({
      method: 'post',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: data,
    })
      .then(({ data }) => {
        console.log('saved invoice', data)
        // self.props.addMongoUser(user)
        toast.success('Invoice submitted successfully')
        // this.props.fetchDoctorMongoUser(this.props.adminId, this.props.token)
        self.setState({
          loading: false,
          saving: false,
          sum1: 0,
          sum2: 0,
          selectedMonth: null,
        })

        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        // self.setState({ loading: false, saving: false, imageUploaded: false })
      })
      .catch((err) => {
        self.setState({ loading: false })
        console.log('error when saving invoice', err)
        toast.error('Invoice submission failed, please try again')
      })

    // this.saveUserToBackend(userDetails)
  }

  saveUserToBackend(user) {
    console.log('user', user)
    const self = this
    const { token } = this.props
    const url = `${Config.BACKEND_URL}providers`
    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}` },
      data: user,
    })
      .then(({ data }) => {
        console.log('successfully added doctor to backend')
        self.props.addMongoUser(user)
        toast.success('Successfully updated profile')
        this.props.fetchDoctorMongoUser(this.props.adminId, this.props.token)
        this.setState({ renderTabsIndex: this.state.renderTabsIndex + 1 })

        if (self.state.imageUploaded) toast.success('Updated profile picture')
        self.setState({ loading: false, saving: false, imageUploaded: false })
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  handleCallback(data) {
    console.log(data, this.props.token)
    data.firebase_id = this.props.adminId
    this.saveUserToBackend(data)
    // this.props.updateDoctorMongoUser(
    //   data,
    //   this.props.mongoUser,
    //   this.props.token,
    // )
  }

  render() {
    const { userDetails } = this.state

    return (
      <div className={GlobalStyles.container}>
        <Header header="Invoices" />

        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minWidth: '100%',
              height: 400,
              alignItems: 'center',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div className={GlobalStyles.contentWrapper}>
            {/* <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              {this.renderAccountDetails()}
              {this.renderInfoCard()}
            </div> */}
            {/* <CredsTabs
              activeTab={this.state.renderTabsIndex}
              onTabSelected={(index) => {
                this.setState({ renderTabsIndex: index })
              }}
            /> */}

            {/* <div className={Styles.row}>
              <p className={Styles.rowLabel}>Number Of Hours</p>
              <div style={{ width: '50%' }}>
                <input
                  placeholder="Enter DEA License Number"
                  className={Styles.textInput}
                  style={{ width: '100%' }}
                  type="text"
                  disabled={true}
                  value={'50'}
                  //   onChange={(e) => {
                  //     const { userDetails } = this.state
                  //     userDetails.dea = e.target.value
                  //     this.setState({ userDetails })
                  //   }}
                />
              </div>
            </div> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
              }}
            >
              <div className={Styles.row}>
                <p className={Styles.rowLabel}>Select Month</p>
                <div style={{ width: '50%' }}>
                  <Select
                    inputId="months"
                    options={this.state.month}
                    value={this.state.selectedMonth}
                    isSearchable
                    autoBlur
                    onChange={(val) => this.setState({ selectedMonth: val })}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </div>

            <div className={Styles.row}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '80%',
                }}
              >
                <div
                  className={Styles.row}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <p className={Styles.rowLabel}></p>
                  <div style={{ width: '50%' }}>
                    <p className={Styles.rowLabel} style={{ width: '100%' }}>
                      {' '}
                      Number Of Hours
                    </p>
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Clinical Time</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Clinical Time"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      //   disabled={true}
                      value={this.state.sum1}
                      onChange={(e) => {
                        this.setState({ sum1: e.target.value })
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Non-Clinical Time</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Enter Non-Clinical Time"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      //   disabled={true}
                      value={this.state.sum2}
                      onChange={(e) => {
                        this.setState({ sum2: e.target.value })
                      }}
                    />
                  </div>
                </div>

                <div className={Styles.row}>
                  <p
                    style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}
                  ></p>
                </div>

                <div className={Styles.row}>
                  <p className={Styles.rowLabel}>Total</p>
                  <div style={{ width: '50%' }}>
                    <input
                      placeholder="Total"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      disabled={true}
                      value={Number(this.state.sum1) + Number(this.state.sum2)}
                      //   onChange={(e) => {
                      //     const { userDetails } = this.state
                      //     userDetails.dea = e.target.value
                      //     this.setState({ userDetails })
                      //   }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* {this.renderTabContent(this)} */}

            <div
              style={{
                width: '100%',
                marginTop: 60,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={this.onSavePressed.bind(this)}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{ width: 200 }}
                variant="primary"
              >
                {this.state.loading ? 'Loading....' : 'Submit'}
              </Button>
              {this.state.error && (
                <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
                  {this.state.errorString}
                </p>
              )}
            </div>

            {/* <Modal
              ariaHideApp={false}
              onRequestClose={() => this.onModalClose()}
              isOpen={this.state.modalIsOpen}
              style={customModalStyles}
              contentLabel="Modal"
            >
              {this.renderModal()}
            </Modal> */}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  const { userReducer } = state
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    mongoUser: userReducer.providerData,
    adminId: userReducer.adminId,
  }
}

const mapDispatchToProps = {
  addMongoUser,
  stopTimer,
  updateDoctorMongoUser,
  fetchDoctorMongoUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)