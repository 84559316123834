// active one which we use everywhere
import React, { Component } from 'react'
import GlobalStyles from '../../mobilePages/styles/global.module.scss'
import { toast, ToastContainer } from 'react-toastify'
import {
  faPhone,
  faBolt,
  faAppleAlt,
  faBrain,
  faMoon,
  faFireAlt,
  faCheckCircle,
  faCheck,
  faTimes,
  faGreaterThan,
} from '@fortawesome/free-solid-svg-icons'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import Config from '../../config'
import Styles from './TimeSlotsList.module.scss'
import Constants from '../../values'
import AilaLogo from '../../assets/images/aila_logo.png'
import CommImage from '../../assets/images/comm_1.png'
import firebase from '../../services/firebase.js'
import values from '../../values'
import { subscriptionPlans } from '../../metadata'
import Modal from 'react-modal'
const queryString = require('query-string')
const moment = require('moment')
const axios = require('axios')

export default class TimeSlotsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      token: null,
      patientId: null,
      patientDetails: null,
      patientEmail: null,
      state: null,
      firebaseUser: null,
      event_id: '',
      doctorId: '',
      selectedDate: '',
      availability: {},
      appointmentData: [],
      markedAvailableDates: {},
      showSlotAvailableModal: false,
      selectedDate: '',
      availDurationArr: [],
      selectedDuration: '',
      selectedTime: '',
      appointmentMonthsMap: {},
      patientDetails: '',
      doctorDetails: {},
    }
    this.getAuthToken = this.getAuthToken.bind(this)
    this.getUserDetails = this.getUserDetails.bind(this)
    this.getDoctorDetails = this.getDoctorDetails.bind(this)
    this.getAppointmentList = this.getAppointmentList.bind(this)
    this.formatAvailability = this.formatAvailability.bind(this)
    this.splitAvailTiming = this.splitAvailTiming.bind(this)
    this.getBookedAppointments = this.getBookedAppointments.bind(this)
    this.calculateAvailableDates = this.calculateAvailableDates.bind(this)
    this.onConfirmTimeClick = this.onConfirmTimeClick.bind(this)
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search)
    // console.log('parsed', parsed)
    const patientId = parsed.patient_id
    const doctorId = parsed.doctor_id
    const selectedDate = parsed.selected_date
    if (!patientId || !selectedDate || !doctorId) {
      console.log('missing info')
      toast.error('Missing information ')
    } else {
      this.setState({
        patientId: patientId,
        selectedDate: selectedDate,
        doctorId: doctorId,
      })
      this.getAuthToken(patientId)
    }
  }

  async getAuthToken(patientId) {
    const url = `${Config.BACKEND_URL}authorization`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_firebase_id: patientId },
        url,
      })
      const token = response.data && response.data.token
      this.setState({ token, patientId }, () => {
        this.getUserDetails(patientId, token)
        this.getDoctorDetails()
        this.getAppointmentList()
      })
    } catch (err) {
      console.log('error when auth token', err)
      toast.error('Error when getting authorization from backend')
    }
  }

  async getUserDetails(patientId, token) {
    const url = `${Config.BACKEND_URL}doctor/patients`
    try {
      const response = await axios({
        method: 'get',
        headers: {
          x_patient_id: patientId,
          Authorization: `JWT ${token}`,
        },
        url,
      })
      // console.log('user id', response)
      const user = response.data
      this.setState({ patientDetails: user })
      const { stripe } = user
      // console.log('got user data', user)
    } catch (err) {
      console.log('error when getting user details', err)
    }
  }

  getDoctorDetails() {
    var config = {
      method: 'get',
      url: `${Config.BACKEND_URL}providers`,
      headers: {
        Authorization: `JWT ${this.state.token}`,
        x_firebase_id: this.state.doctorId,
      },
    }

    axios(config).then(({ data }) => {
      if (data) {
        this.setState({ doctorDetails: data }, () => this.formatAvailability())
      }
    })
  }

  getAppointmentList() {
    var config = {
      method: 'get',
      url: `${Config.BACKEND_URL}patient/appointments`,
      headers: {
        Authorization: `JWT ${this.state.token}`,
        x_patient_id: this.state.patientId,
      },
    }

    axios(config)
      .then(({ data }) => {
        if (data) {
          let appointments = data || []
          let appointmentMonthsMap = {}
          // let appointmentMonthsMap = {
          //   'Jan2021': {
          //     healthCoachAppointmentsMonth: [],
          //     nonHealthCoachAppointmentsMonth = []
          //   },
          // }
          appointments.forEach((appointment) => {
            let monthName = moment(appointment.start).format('MMMYYYY')
            if (appointmentMonthsMap?.[monthName] === undefined) {
              appointmentMonthsMap[monthName] = {
                healthCoachAppointments: 0,
                nonHealthCoachAppointments: 0,
              }
            }
            if (appointment?.user_type?.toLowerCase() !== 'health coach') {
              ++appointmentMonthsMap[monthName]['nonHealthCoachAppointments']
            } else if (
              appointment?.user_type?.toLowerCase() === 'health coach'
            ) {
              ++appointmentMonthsMap[monthName]['healthCoachAppointments']
            }
          })
          this.setState({ appointmentMonthsMap: appointmentMonthsMap })
          // console.log('AAPPT MAP::', appointmentMonthsMap)
          // setAppointmentsMonthsMap(appointmentMonthsMap)
        }
      })
      .catch(function (error) {
        console.log('error when getting patient appointments', error)
      })
  }

  // formatting the available dates set up by providers to divide into 30 minutes chunks
  formatAvailability = () => {
    const availability = this.state.doctorDetails.availability
    this.setState({ loading: true })
    let availList = {}
    if (availability) {
      Object.keys(availability).forEach((avail) => {
        // avail = Mon, Tue, Wed ...
        availList[avail] = []
        if (availability?.[avail]?.length) {
          // In day object get the time from the array
          // availability[avail] &&
          //   availability[avail].forEach((time) => {
          //     let availTimeStart = moment(time?.start)
          //     let availTimeEnd = moment(time?.end)
          //     // splitting the block of time into 30 minutes chunks
          //     while (availTimeStart.diff(availTimeEnd) < 0) {
          //       availList[avail].push(moment(availTimeStart).format('HH:mm'))
          //       availTimeStart.add(30, 'minutes')
          //     }
          //   })
          availList[avail] = this.splitAvailTiming(
            availability[avail],
            availList[avail],
          )
          availList[avail] = availList[avail].sort(
            (a, b) => a.availFrom - b.availFrom,
          )
        }
      })
    }
    // console.log('FORMATED AVAIL: ', availList)
    this.setState({ loading: false, availableTimeList: availList })
    this.getBookedAppointments()
  }

  // splitting epoch start and end time to [hh:mm] array
  splitAvailTiming = (availTimeEpoch, availTimeArray) => {
    availTimeEpoch &&
      availTimeEpoch.length &&
      availTimeEpoch.forEach((time) => {
        let availTimeStart = moment(time?.start)
        let availTimeEnd = moment(time?.end)
        // splitting the block of time into 30 minutes chunks
        while (availTimeStart.diff(availTimeEnd) < 0) {
          availTimeArray.push({
            availFrom: moment(availTimeStart).format('HH:mm'),
            availTill: moment(availTimeStart)
              .add(15, 'minutes')
              .format('HH:mm'),
            availTillDuration: availTimeEnd.diff(availTimeStart, 'minutes'),
          })
          availTimeStart.add(15, 'minutes')
        }
      })
    return availTimeArray
  }

  getBookedAppointments = () => {
    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: `${Config.BACKEND_URL}providers/appointments`,
      headers: {
        Authorization: `JWT ${this.state.token}`,
        x_firebase_id: this.state.patientId,
        x_doctor_id: this.state.doctorId,
      },
      params: {
        startDate: moment().valueOf(),
      },
    }
    axios(config)
      .then(({ data }) => {
        this.setState({ loading: false, appointmentData: data })
        this.calculateAvailableDates()
      })
      .catch((err) => {
        console.log('error while fetching appointment data: ', err)
        this.setState({ loading: false })
        this.calculateAvailableDates()
      })
  }

  // calculating the available time for each day for the next 2 months

  calculateAvailableDates = () => {
    let availableDates = {}
    let currentDate = moment()
    let lastDate = moment().add(2, 'M')
    const availableTimeList = this.state.availableTimeList
    const appointmentData = this.state.appointmentData
    const availability_override =
      this.state.doctorDetails?.availability_override || {}
    // color code 2 months for available status
    while (currentDate.diff(lastDate) <= 0) {
      let tempAvaiList =
        availableTimeList?.[moment(currentDate).format('ddd')] || []
      // checking for overriding dates using epoch time and override the default time
      let overrideDate = Object.keys(availability_override)?.find((time) =>
        currentDate.isSame(moment.unix(time / 1000), 'day'),
      )
      if (overrideDate?.length) {
        let tempTimeArr = []
        tempAvaiList = this.splitAvailTiming(
          availability_override[overrideDate],
          tempTimeArr,
        )
      }
      let currentDayBookedAppointments =
        appointmentData?.filter((date) => {
          return moment(date.start).isSame(moment(currentDate), 'date')
        }) || []

      // present time should be less than the available time
      if (moment().isSame(moment(currentDate), 'day') && tempAvaiList.length) {
        tempAvaiList = tempAvaiList.filter(
          (time) => time.availFrom > moment().format('HH:mm'),
        )
      }

      // if the available slot is not available for that day
      if (tempAvaiList?.length === 0) {
        availableDates[moment(currentDate).format('YYYY-MM-DD')] = {
          disabled: true,
          disableTouchEvent: true,
        }
        currentDate.add(1, 'days')
        continue
      }

      // if there is no pre-booked appointments and there is slots available
      if (tempAvaiList.length !== 0 && appointmentData.length === 0) {
        availableDates[moment(currentDate).format('YYYY-MM-DD')] = {
          startingDay: true,
          color: 'red',
          endingDay: true,
          textColor: 'white',
          availSlot: tempAvaiList,
        }
        currentDate.add(1, 'days')
        continue
      }

      // to block the pre-booked appointment time slots from the provider available time slot
      if (currentDayBookedAppointments?.length) {
        currentDayBookedAppointments.forEach((appointmentTime) => {
          // removing the pre-booked appointment time slot
          tempAvaiList = tempAvaiList.filter(
            (item) =>
              !(
                moment(appointmentTime.start).format('HH:mm') <=
                  item.availFrom &&
                moment(appointmentTime.end).format('HH:mm') >= item.availTill
              ),
          )
        })
      }

      if (tempAvaiList.length) {
        availableDates[moment(currentDate).format('YYYY-MM-DD')] = {
          startingDay: true,
          color: 'green',
          endingDay: true,
          textColor: 'white',
          availSlot: tempAvaiList,
        }
      } else {
        availableDates[moment(currentDate).format('YYYY-MM-DD')] = {
          disabled: true,
          disableTouchEvent: true,
        }
      }

      currentDate.add(1, 'days')
      // else {
      //   currentDate.add(1, 'days')
      //   availableDates[(moment(currentDate).format('YYYY-MM-DD'))] = {disabled: true, disableTouchEvent: true}
      // }
    }
    this.setState({ markedAvailableDates: availableDates })
  }

  checkStripeDetails = () => {
    let { patientDetails } = this.state
    const user = patientDetails
    if (user?.stripe && user?.stripe?.customer_id) {
      console.log('stripe customer id present')
      return false
    } else {
      console.log('error : no stripe customer id ')
      return true
    }
  }

  onConfirmTimeClick = (selectedTime) => {
    let {
      appointmentMonthsMap,
      patientDetails,
      selectedDuration,
      doctorDetails,
    } = this.state

    // console.log('TIME SELECTED:::', selectedTime, typeof selectedTime, selectedDuration)
    // console.log(doctorDetails)
    // console.log(patientDetails)
    let data = {}
    data.start = moment(selectedTime).valueOf()
    data.end = moment(selectedTime).add(selectedDuration, 'minutes').valueOf()

    let monthName = moment(data?.start).format('MMMYYYY') // Jan2021, Feb2021, ...
    let docType =
      doctorDetails?.user_type?.toLowerCase() !== 'health coach'
        ? 'nonHealthCoachAppointments'
        : 'healthCoachAppointments'
    let appointmentCount
    if (doctorDetails?.user_type?.toLowerCase() !== 'health coach')
      appointmentCount = appointmentMonthsMap?.[monthName]?.[docType] || 0
    else appointmentCount = appointmentMonthsMap?.[monthName]?.[docType] || 0 // if there is no appointments booked for that month
    //  = appointmentMonthsMap?.[monthName]?.[docType] || 0
    console.log(appointmentCount) // if there is no appointments booked for that month
    let isPaid = false
    let isInsuranceSet =
      patientDetails?.insurance?.insurance_company ||
      patientDetails?.insurance?.[0]?.insurance_company
        ? true
        : false
    const userPremiumPlan =
      (patientDetails?.coaching?.active &&
        !patientDetails?.coaching?.hasOwnProperty('plan')) ||
      (patientDetails?.coaching?.active &&
        patientDetails?.coaching?.plan === subscriptionPlans.PREMIUM)
    if (
      doctorDetails?.user_type?.toLowerCase() !== 'health coach' &&
      doctorDetails?.user_type?.toLowerCase() !== 'wellness coach'
    ) {
      if (userPremiumPlan && appointmentCount === 0) {
        // if there is no health coach appointment for the month and coaching plan is premium set paid to true
        isPaid = true
        data.free_appointment = true
      }
      if (isInsuranceSet) {
        isPaid = true // if the insurance is set then treat the appointment as paid
        data.insurance_claimed = false // insurance not yet claimed, claim after appointment is over
      }
    } else if (
      doctorDetails?.user_type?.toLowerCase() !== 'wellness coach' &&
      (userPremiumPlan ||
        patientDetails?.coaching?.plan === subscriptionPlans.MEMBERSHIP) &&
      appointmentCount === 0
    ) {
      // for health coach
      isPaid = true
      // if there is no health coach appointment for the month and if the coaching plan is premium or membership set paid to true
      data.free_appointment = true
    }
    // if (isInsuranceSet) {
    //   isPaid = true // if the insurance is set then treat the appointment as paid
    //   data.insurance_claimed = false // insurance not yet claimed, claim after appointment is over
    // }
    // data.appointment_payment_paid = isPaid
    data.appointment_payment_paid = isPaid

    // to calculate the fee and send it to mobile app if the visit is not free
    if (
      doctorDetails?.user_type?.toLowerCase() !== 'health coach' &&
      doctorDetails?.user_type?.toLowerCase() !== 'wellness coach' &&
      !data?.free_appointment
    ) {
      // clinical visit fee
      switch (selectedDuration) {
        case 15:
          data.visit_fee = Config.AILA_CLINIC_15
          break
        case 30:
          data.visit_fee = Config.AILA_CLINIC_30
          break
        case 45:
          data.visit_fee = Config.AILA_CLINIC_45
          break
        case 60:
          data.visit_fee = Config.AILA_CLINIC_60
          break

        default:
          data.visit_fee = Config.AILA_CLINIC_30
          break
      }
    } else if (!data?.free_appointment) {
      // wellness and health coach fee
      switch (selectedDuration) {
        case 30:
          data.visit_fee = Config.AILA_COACH_30
          break
        case 60:
          data.visit_fee = Config.AILA_COACH_60
          break

        default:
          data.visit_fee = Config.AILA_COACH_30
          break
      }
    }
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(data))

    this.setState({
      selectedDate: '',
      selectedTime: '',
      showSlotAvailableModal: false,
    })
  }

  renderVisitDurationContent() {
    let durationArr = [
      { lable: '30 minutes', value: 30 },
      { lable: '60 minutes', value: 60 },
    ]
    // show 15 and 45 with 30 and 60 for clinicians only
    let docType = this.state.doctorDetails?.user_type
    if (
      docType?.toLowerCase() !== 'health coach' &&
      docType?.toLowerCase() !== 'wellness coach'
    ) {
      durationArr.splice(0, 0, { lable: '15 minutes', value: 15 })
      durationArr.splice(2, 0, { lable: '45 minutes', value: 45 })
    }

    let selectedDate = this.state.selectedDate

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingTop: 20,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.backModalBtn}
          onClick={() =>
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ close: true }),
            )
          }
        >
          {'<'}
        </p>
        <h4
          style={{
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            paddingTop: 20,
          }}
        >
          Schedule your appointment
        </h4>

        <h5 style={{ color: values.darkGray, textAlign: 'center' }}>
          {moment(selectedDate, 'YYYY-MM-DD').format('dddd')},{' '}
          {moment(selectedDate, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
        </h5>
        <h6 style={{ color: values.darkGray, textAlign: 'center' }}>
          {new Date()
            .toLocaleDateString(undefined, {
              day: '2-digit',
              timeZoneName: 'long',
            })
            .substring(4)}
        </h6>
        <h4
          style={{
            color: values.primaryTheme,
            textAlign: 'center',
            borderBottom: '1px solid #DEDEDE',
            width: '100%',
          }}
        >
          Select the Duration
        </h4>

        {durationArr.map((duration, index) => {
          return (
            <div
              style={{
                width: '50%',
                margin: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  this.setState({ selectedDuration: duration.value })
                }}
                className={Styles.button2}
                style={{
                  margin: '1vh',
                  width: 200,
                  color: '#20A892',
                  fontWeight: 'bold',
                  borderColor: '#20A892',
                }}
                variant="primary"
              >
                {duration.lable}
              </Button>
            </div>
          )
        })}
        {durationArr.length === 0 && (
          <div
            style={{
              width: '50%',
              margin: '10px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            No slots available for today
          </div>
        )}
      </div>
    )
  }

  renderSlotAvailableContent() {
    let { selectedDuration, markedAvailableDates, selectedDate } = this.state
    let timeArr =
      markedAvailableDates[selectedDate] &&
      markedAvailableDates[selectedDate].availSlot
        ? markedAvailableDates[selectedDate].availSlot
        : []
    timeArr = timeArr.filter(
      (time) => time.availTillDuration >= selectedDuration,
    )
    let currentDayBookedAppointments =
      this.state.appointmentData?.filter((date) => {
        return moment(date.start).isSame(moment(selectedDate), 'date')
      }) || []

    if (currentDayBookedAppointments?.length) {
      currentDayBookedAppointments.forEach((appointmentTime) => {
        // removing the pre-booked appointment time slot according to selected duration
        timeArr = timeArr.filter((item) => {
          let visitEndTime = moment(item.availFrom, 'HH:mm')
            .add(selectedDuration, 'minutes')
            .format('HH:mm')
          // console.log('TIME: ', item.availFrom, visitEndTime, moment(appointmentTime.start).format('HH:mm'))
          return !(
            (item.availFrom > moment(appointmentTime.start).format('HH:mm') &&
              visitEndTime <= moment(appointmentTime.end).format('HH:mm')) ||
            (item.availFrom < moment(appointmentTime.start).format('HH:mm') &&
              visitEndTime >= moment(appointmentTime.end).format('HH:mm')) ||
            (item.availFrom > moment(appointmentTime.start).format('HH:mm') &&
              item.availFrom < moment(appointmentTime.end).format('HH:mm')) ||
            (visitEndTime > moment(appointmentTime.start).format('HH:mm') &&
              visitEndTime < moment(appointmentTime.end).format('HH:mm'))
          )
        })
      })
    }

    // console.log('curr: ', currentDayBookedAppointments, timeArr)

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingTop: 20,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.backModalBtn}
          onClick={() =>
            this.setState({ selectedDuration: '', selectedTime: '' })
          }
        >
          {'<'}
        </p>
        <h4
          style={{
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            marginTop: 15,
          }}
        >
          Schedule your appointment
        </h4>

        <h5 style={{ color: values.darkGray, textAlign: 'center' }}>
          {moment(selectedDate, 'YYYY-MM-DD').format('dddd')},{' '}
          {moment(selectedDate, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
        </h5>
        <h6 style={{ color: values.darkGray, textAlign: 'center' }}>
          {new Date()
            .toLocaleDateString(undefined, {
              day: '2-digit',
              timeZoneName: 'long',
            })
            .substring(4)}
        </h6>
        <h4
          style={{
            color: values.primaryTheme,
            textAlign: 'center',
            borderBottom: '1px solid #DEDEDE',
            width: '100%',
          }}
        >
          Select a Time
        </h4>

        {timeArr.map((time, index) => {
          let displayTime = moment(
            selectedDate + ' ' + time.availFrom,
            'YYYY-MM-DD HH:mm',
          )
          return (
            <div
              style={{
                width: '50%',
                margin: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  this.setState({ selectedTime: displayTime.valueOf() })
                  this.onConfirmTimeClick(displayTime.valueOf())
                }}
                className={Styles.button2}
                style={{
                  margin: '1vh',
                  width: 200,
                  color: '#20A892',
                  fontWeight: 'bold',
                  borderColor: '#20A892',
                }}
                variant="primary"
              >
                {displayTime.format('hh:mm A')}
              </Button>

              {/* {this.state.selectedTime?.length !== 0 &&
                this.state.selectedTime === displayTime.valueOf() && (
                  <Button
                    onClick={() => {
                      this.onConfirmTimeClick(this.state.selectedTime)
                    }}
                    className={GlobalStyles.button}
                    style={{ width: 200, margin: '1vh' }}
                    variant="primary"
                  >
                    Confirm Time
                  </Button>
                )} */}
            </div>
          )
        })}
        {timeArr.length === 0 && (
          <div
            style={{
              width: '50%',
              margin: '10px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            No slots available for today
          </div>
        )}
      </div>
    )
  }

  renderContent() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          // marginTop: 10,
        }}
      >
        <div
          style={{
            // width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {this.state.selectedDuration
            ? this.renderSlotAvailableContent()
            : this.renderVisitDurationContent()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          display: 'flex',
          //   flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          //   overflow: 'scroll',
          justifyContent: 'center',
          //   width: window.innerWidth,
          width: '100%',
        }}
      >
        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              marginTop: 40,
            }}
          >
            <Loader
              type="Oval"
              color={Constants.primaryTheme}
              height={40}
              width={40}
            />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    )
  }
}
