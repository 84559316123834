import React, { Component } from 'react'
import Styles from '../Messages/styles/QuestionnaireMessages.module.scss'
import { connect } from 'react-redux'
import { stopTimer } from '../../redux/actions'

import * as Metadata from '../../metadata'

const PATIENTEXPERIENCES = Metadata.patientExperiences

class PatientExperienceMessages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listPatientExperience: [],
    }
    this.handleMultipleOptions = this.handleMultipleOptions.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    let { adminId, token } = this.props
    this.props.stopTimer(adminId, token)

    this.getPatientExperienceList()
  }

  getPatientExperienceList() {
    let data = PATIENTEXPERIENCES.map((list) => list.label)
    let tempData = []

    data.forEach((each) => {
      tempData.push({ title: each, isChecked: false })
    })
    this.setState({ listPatientExperience: tempData })
  }

  handleMultipleOptions(value) {
    let tempq = this.state.listPatientExperience

    tempq.forEach((item) => {
      if (item.title === value) item.isChecked = !item.isChecked
    })
    this.setState({ listPatientExperience: tempq })

    this.props.parentCallback(this.state.listPatientExperience)
  }

  renderList() {
    let toReturn = []
    let options = PATIENTEXPERIENCES

    for (let i = 0; i < options.length; i++) {
      let each = options[i]

      toReturn.push(
        <div style={{ width: '40%', margin: '20px' }}>
          <label class={Styles.checkContainer}>
            {each.label}
            <input
              type="checkbox"
              checked={each.isChecked}
              onClick={() => {
                console.log('CLOCKED QUESTIONNAIRE')
                this.handleMultipleOptions(each.label)
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
        </div>,
      )
    }

    return toReturn
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: '14px 20px 20px 20px',
          margin: '15px',
          backgroundColor: 'white',
          borderRadius: '20px',
        }}
      >
        {this.renderList()}
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    adminId: state.userReducer.adminId,
  }
}

const mapDispatchToProps = { stopTimer }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientExperienceMessages)
