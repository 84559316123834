import React, { Component } from 'react'
import Header from '../../components/Header'
import GlobalStyles from '../styles/global.module.scss'
import Config from '../../config'

import Styles from './styles/List.module.scss'
import Constants from '../../values.js'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import { stopTimer } from '../../redux/actions'

import FileViewer from '../../components/pdf/FileViewer'
import ReactHlsPlayer from 'react-hls-player'

let windowHeight = window.innerHeight
const axios = require('axios')

const customContentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '70%',
    height: windowHeight - 80,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      modalOption: {},
      modalSelectOption: {},
      fileCount: 1,
      numPages: null,
      pageNumber: 1,
      title: '',
      image_url: '',
      content_url: '',
      video_url: '',
      edu_content: [],
      finalMap: [],
      loading: true,
    }
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    let { adminId, token } = this.props
    this.props.stopTimer(adminId, token)
    this.getEducationalContent()
  }

  handleCloseModal() {
    this.setState({
      title: '',
      image_url: '',
      content_url: '',
      video_url: '',
      modalIsOpen: false,
      cohortModalOpen: false,
      contentModalOpen: false,
    })
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages: numPages })
  }

  getEducationalContent() {
    let { token } = this.props
    let self = this
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `education/content`,
      headers: {
        Authorization: 'JWT ' + token,
      },
    }
    this.setState({ loading: true })

    axios(config)
      .then(({ data }) => {
        const groupData = (data) => {
          let g = Object.entries(
            data.reduce(
              (r, c) => ((r[c.category] = [...(r[c.category] || []), c]), r),
              {},
            ),
          )
          return g.reduce(
            (r, c) => (r.mainlist.push({ category: c[0], sublist: c[1] }), r),
            { mainlist: [] },
          )
        }
        const finalMap1 = groupData(data)
        this.setState({ finalMap: finalMap1 })
        console.log('FINAL MAP: ', this.state.finalMap.mainlist)
        console.log('DATA RECIEVED FROM BACKEND:', data)
        data.forEach((element) => {
          this.setState({
            edu_content: [
              ...this.state.edu_content,
              {
                category: element.category,
                title: element.title,
                image_url: element.image_url,
                video_url: element.video_url,
                content_url: element.content_url,
                premium: element.premium,
              },
            ],
          })
        })
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('Error getting Educational content for the Patient ', err)
      })
  }

  getUrlsFromBackend(query) {
    let { token } = this.props
    let self = this
    let url = Config.BACKEND_URL + `education/content/each?title=${query}`

    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
      },
    })
      .then(({ data }) => {
        if (data) {
          self.setState({
            modalOption: data.title,
            title: data.title,
            image_url: data.image_url,
            content_url: data.content_url,
            video_url: data.video_url,
            modalIsOpen: true,
          })
        }
        console.log(data)
      })
      .catch((err) => {
        console.log('error when getting messages from backend', err)
      })
  }

  getContent(data) {
    let options = data
    let toReturn = []

    this.getUrlsMap = (data) => {
      let urlsMap = []
      if (Array.isArray(data)) {
        data.forEach((element, index) => {
          urlsMap.push({
            label: element.title,
            title: element.title,
            image_url: element.image_url,
            content_url: element.content_url,
            video_url: element.video_url,
          })
        })
      }
      return urlsMap
    }
    const groupData = (data) => {
      let g = Object.entries(
        data.reduce(
          (r, c) => ((r[c.subcategory] = [...(r[c.subcategory] || []), c]), r),
          {},
        ),
      )
      return g.reduce(
        (r, c) => (r.mainlist.push({ subcategory: c[0], sublist: c[1] }), r),
        { mainlist: [] },
      )
    }
    const finalMap1 = groupData(options)
    let data1 = finalMap1.mainlist
    data1.forEach((each) => {
      toReturn.push(
        <div style={{ width: '100%', marginTop: 20 }}>
          <h5 style={{ color: Constants.primaryTheme }}>{each.subcategory}</h5>
          <Select
            options={this.getUrlsMap(each.sublist)}
            placeholder={each.subcategory}
            onChange={(val) => {
              this.setState({
                title: val.label,
                image_url: val.image_url,
                content_url: val.content_url,
                video_url: val.video_url,
                modalIsOpen: true,
              })
            }}
            getOptionValue={(option) => option.label}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>,
      )
    })
    return toReturn
  }

  renderList() {
    let data = this.state.finalMap.mainlist

    this.getUrlsMap = (data) => {
      let urlsMap = []
      if (Array.isArray(data)) {
        data.forEach((element, index) => {
          urlsMap.push({
            label: element.title,
            title: element.title,
            image_url: element.image_url,
            content_url: element.content_url,
            video_url: element.video_url,
          })
        })
      }
      return urlsMap
    }
    let toReturn = []
    data.forEach((each) => {
      toReturn.push(
        <div
          style={{
            width: '40%',
            margin: 20,
            // visibility:
            //   each.category === 'Condition Education' ? 'hidden' : 'visible',
          }}
        >
          <h4 style={{ color: Constants.primaryTheme }}>{each.category}</h4>
          {each.category === 'Condition Education' ? (
            this.getContent(each.sublist)
          ) : (
            <Select
              options={this.getUrlsMap(each.sublist)}
              isSearchable={true}
              placeholder={each.category}
              onChange={(val) => {
                this.setState({
                  title: val.label,
                  image_url: val.image_url,
                  content_url: val.content_url,
                  video_url: val.video_url,
                  modalIsOpen: true,
                })
              }}
              getOptionValue={(option) => option.label}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          )}
        </div>,
      )
    })

    return toReturn
  }

  renderModalContent() {
    let { title, image_url, video_url, content_url } = this.state
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignContent: 'center',
        }}
      >
        <p className={Styles.closeModalBtn} onClick={this.handleCloseModal}>
          x
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          {title}
        </h4>
        {image_url && (
          <div
            style={{
              overflowBlock: 'hidden',
              marginTop: '10px',
              height: '30vh',
            }}
          >
            <FileViewer fileType="jpg" fileName={image_url} />
          </div>
        )}

        {video_url && (
          <div
            style={{
              overflow: 'hidden',
              marginTop: '20px',
              width: '80%',
              alignSelf: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <FileViewer fileType="m3u8" fileName={video_url} /> */}

            <ReactHlsPlayer
              src={video_url}
              autoPlay={true}
              controls={true}
              width="100%"
              height="auto"
            />
          </div>
        )}
        {content_url && (
          <iframe
            src={content_url + '#toolbar=0'}
            style={{ height: '80vh', width: '100%', margin: '20px 20px' }}
          ></iframe>
        )}
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={GlobalStyles.container} style={{ height: '100vh' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div className={GlobalStyles.container}>
        <Header header={'Content Library'} />

        <div
          className={GlobalStyles.contentWrapper}
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: '20px 50px',
          }}
        >
          {this.renderList()}
          <Modal
            ariaHideApp={false}
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.handleCloseModal}
            style={customContentModalStyles}
            contentLabel="Modal"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {this.renderModalContent()}
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    adminId: state.userReducer.adminId,
  }
}

const mapDispatchToProps = { stopTimer }

export default connect(mapStateToProps, mapDispatchToProps)(List)

//CODE PRESENT BEFORE FOR DOCTOR TO UPLOAD CONTENT LIBRARY
// renderList() {
//   let data = Metadata.contentList
//   let toReturn = []
//   data.forEach((each) => {
//     toReturn.push(
//       <div style={{ width: '40%', margin: 20 }}>
//         <h4 style={{ color: Constants.primaryTheme }}>{each.label}</h4>
//         {this.getContent(each)}
//       </div>,
//     )
//   })

//   return toReturn
// }

// renderModalContent() {
//   let option = this.state.modalOption;
//   let fileInputs = [];
//   for (let i = 0; i < this.state.fileCount; i++)
//     fileInputs.push(
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//         }}
//       >
//         <pre>{i + 1}. </pre>
//         <input
//           style={{ marginBottom: 10 }}
//           type="file"
//           id="file"
//           accept="image/*,.pdf,.doc,.docx,.txt"
//         />
//       </div>
//     );

//   return (
//     <div style={{ display: "flex", flexDirection: "column", width: "80%" }}>
//       <p className={Styles.closeModalBtn} onClick={this.handleCloseModal}>
//         X
//       </p>
//       <h4 style={{ color: Constants.primaryTheme, textAlign: "center" }}>
//         {option.label}
//       </h4>
//       <p style={{ fontSize: 14, textAlign: "center" }}>Upload Content</p>

//       <div
//         style={{
//           display: "flex",
//           marginTop: 60,
//           minWidth: "100%",
//           flexDirection: "column",
//           marginBottom: 40,
//         }}
//       >
//         <p
//           style={{
//             textAlign: "left",
//             color: Constants.primaryTheme,
//             marginBottom: 20,
//           }}
//         >
//           Tag content with
//         </p>
//         <Select
//           options={Metadata.contentList}
//           value={this.state.modalSelectOption}
//           isMulti={true}
//           isSearchable={true}
//           autoBlur={true}
//           onChange={(val) => this.setState({ modalSelectOption: val })}
//           theme={(theme) => ({
//             ...theme,
//             colors: {
//               ...theme.colors,
//               primary25: "#20A89233",
//               primary: "#20A892",
//             },
//           })}
//         />
//       </div>

//       <p
//         style={{
//           marginBottom: 6,
//           color: "#aeaeae",
//           fontSize: 14,
//           textAlign: "left",
//         }}
//       >
//         Select word documents(.doc/.docx, .odt, .pdf, .txt) or video
//         files(.mp4, .mov, .wmv)
//       </p>
//       {fileInputs}

//       <div
//         onClick={() => {
//           let current = this.state.fileCount;
//           this.setState({ fileCount: current + 1 });
//         }}
//         style={{
//           marginTop: 10,
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//           cursor: "pointer",
//         }}
//       >
//         <FontAwesomeIcon
//           icon={faPlusCircle}
//           style={{
//             color: Constants.primaryTheme,
//             fontSize: 20,
//             marginRight: 5,
//           }}
//         />
//         <p style={{ color: Constants.primaryTheme }}>add another</p>
//       </div>

//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           alignSelf: "center",
//           marginTop: 100,
//         }}
//       >
//         <Button
//           onClick={() => this.setState({ modalIsOpen: false })}
//           className={GlobalStyles.button}
//           disabled={this.state.loading}
//           style={{ width: 200 }}
//           variant="primary"
//         >
//           Upload
//         </Button>
//       </div>
//     </div>
//   );
// }
