import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment'
import {Collapse} from 'react-collapse';

import {useDispatch, useSelector} from 'react-redux'
import Styles from './styles/styles.module.scss'
import actionTypes from '../redux/actionTypes.js'
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComments} from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt, faNewspaper } from '@fortawesome/free-regular-svg-icons'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import AilaLogo from '../assets/images/aila_logo.png'
import PlaceHolderProfileImage from '../assets/images/placeholder.png'

import * as Metadata from '../metadata.js'

import Constants from '../values.js'
import Select from "react-select"
import Button from "react-bootstrap/Button";
import GlobalStyles from "../pages/styles/global.module.scss";
import Config from "../config";
import {updateSideNavBar} from "../redux/actions";
let windowHeight = window.innerHeight
const axios = require('axios');

let CONTENT_OPTIONS = Metadata.contentList

const customContentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)'
  },
  content : {
    width: '50%',
    height: (windowHeight/2)+100,
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

const customCohortModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)'
  },
  content : {
    width: '50%',
    height: (windowHeight/2),
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};


const PatientInfoStrip = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const token = useSelector(state => state.authReducer.token)
  const doctorId = useSelector(state => state.userReducer.firebaseAuthUser && state.userReducer.firebaseAuthUser.uid)
  const rpmOrders = useSelector(state => state.patientReducer.rpmOrders)

  let {patient, patientCore} = props
  let [stick, setStick] = useState(false)
  let [expanded, setExpanded] = useState(false)
  let [modalLoader, setModalLoader] = useState(false)
  let [modalOpen, setModalOpen] = useState(false)
  let [cohortModalOpen, setCohortModalOpen] = useState(false)
  let [contentValues, setContentValues] = useState([])
  let cohorts = props.patient && props.patient.cohorts ? props.patient.cohorts : []
  let values = []
  cohorts.forEach(option => {
    values.push({label: option, value: option})
  })
  let [cohortValues, setCohortValues] = useState(values)
  let [prevCohortValues, setPrevCohortValues] = useState(values)
  let doc_cohort_options = []
  props?.cohorts && props.cohorts.length && props.cohorts.forEach(cohort => {
    doc_cohort_options.push({label: cohort, value: cohort})
  })

  if(!patient || !patientCore)
    return null

  const handleCloseModal = () => {
    setModalOpen(false)
    setCohortModalOpen(false)
    setCohortValues(prevCohortValues)
  }

  const onModalSavePressed = () => {
    setModalLoader(true)
    let toSave = []
    cohortValues.forEach(option => {
      toSave.push(option.label)
    })

    let url = Config.BACKEND_URL + 'doctor/patients'
    axios({
      method: 'put',
      headers:{Authorization: 'JWT '+ token},
      url: url,
      data:{patient_id: patient.uid, doctor_id: doctorId, cohorts: toSave}
    }).then(({ data }) => {
      let temp = Object.assign({}, patient)
      temp.cohorts = toSave
      dispatch({
        type: actionTypes.ADD_PATIENT,
        payload: {patient: temp, color: props.color},
      })

      setModalLoader(false)
      setModalOpen(false)
      setCohortModalOpen(false)
      setPrevCohortValues(cohortValues)

    }).catch(err => {
      console.log('error when getting patient medications data', err)
    })

  }

  const onSelectAllClicked = (label) => {
    let options = []

    CONTENT_OPTIONS.forEach(option => {
      if(option.label === label)
        options = option.options
    })


    let temp = contentValues

    let currentOptions = [...temp]
    let currentLabelsObject = {}
    currentOptions.forEach(option => {
      currentLabelsObject[option.label] = true
    })

    options.forEach(option => {
      if(!currentLabelsObject[option.label])
        currentOptions.push(option)
    })

    setContentValues(currentOptions)

  }

  const formatGroupLabel = (data) =>{
    return (
      <div style={{height: 40, minWidth: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{fontSize: 18, marginRight: 30, color: "#066D6F"}}>{data.label}</p>
        <button
          onClick={() => onSelectAllClicked(data.label)}
          style={{padding: '4px 10px', border: '1px solid #d0d0d0', borderRadius: 6}}>
          <p style={{color: 'gray', marginRight: 5}}>Select All</p>
        </button>
      </div>
    )
  }

  const renderContentModal = () => {
    return (
      <div className={Styles.modalContentWrapper}>
        <p className={Styles.closeModalBtn} onClick={handleCloseModal}>X</p>
        <h4 style={{color: Constants.primaryTheme}}>Patient Content</h4>
        <p style={{position: 'relative', bottom: 8}}>Enroll patient in education content</p>

        <div style={{width: '90%', marginTop: 40}}>
          <Select
            formatGroupLabel={(data) => formatGroupLabel(data, true)}
            isSearchable={false}
            autoBlur={true}
            placeholder={'Select content...'}
            isMulti={true}
            options={CONTENT_OPTIONS}
            value={contentValues}
            onChange={(val) => setContentValues(val)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <Button
          onClick={onModalSavePressed}
          className={GlobalStyles.button}
          style={{width: 200, position: 'absolute', bottom: 20}}
          variant="primary"
        >Save</Button>
      </div>
    )

  }

  const capitalize = (string) => {
    let toReturn = ''
    string.split(' ').forEach(each => {
     toReturn += each.charAt(0).toUpperCase() + each.slice(1);
     toReturn += ' '
    })

    return toReturn
  }

  const renderCohortModal = () => {
    return (
      <div className={Styles.modalContentWrapper}>
        <p className={Styles.closeModalBtn} onClick={handleCloseModal}>X</p>
        <h4 style={{color: Constants.primaryTheme}}>Patient Cohort</h4>
        <p style={{position: 'relative', bottom: 8}}>Enroll patient in cohorts</p>
        {
          modalLoader ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%'}}>
              <div className={GlobalStyles.loader} />
            </div>
            :
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{width: '90%', marginTop: 40}}>
                <Select
                  isSearchable={false}
                  autoBlur={true}
                  placeholder={'Select cohorts...'}
                  isMulti={true}
                  options={doc_cohort_options}
                  value={cohortValues}
                  onChange={(val) => setCohortValues(val)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>

              <Button
                onClick={onModalSavePressed}
                className={GlobalStyles.button}
                style={{width: 200, position: 'absolute', bottom: 20}}
                variant="primary"
              >Save</Button>
            </div>
        }

      </div>
    )
  }

  let insurances

  if(patientCore.insurances)
    insurances = patientCore.insurances.map((x,index) => <span key={index.toString()}>{capitalize(x.insurance_provider)}{index === patientCore.insurances.length-1 ? '':','}</span>)

  function getIcons(animated) {
    return (
      <div className={animated ? Styles.iconsWrapperAnimated : Styles.iconsWrapper}>
        <FontAwesomeIcon
          onClick={() => {
            setModalOpen(true)
            setCohortModalOpen(true)
          }}
          icon={faUser}
          className={Styles.patientStripIcons}/>
        <FontAwesomeIcon
          onClick={() => {
            setModalOpen(true)
            setCohortModalOpen(false)
          }}
          icon={faNewspaper}
          className={Styles.patientStripIcons}/>
        <FontAwesomeIcon
          onClick={() => {
            history.push('/messages')
            dispatch({
              type: actionTypes.UPDATE_SIDENAV_RENDER,
              payload: true
            })
          }}
          icon={faComments}
          className={Styles.patientStripIcons}/>

      </div>
    )
  }

  function renderPatientInfo() {
    let patientCohorts =
      cohorts &&
      cohorts.map((x, index) => (
        <span key={index.toString()}>
          &nbsp;{x}
          {index === cohorts.length - 1 ? '' : ','}
        </span>
      ))

    //RPM ORDERS
    let devices = []
    rpmOrders?.length &&
      rpmOrders.forEach((item) => {
        item.lines.forEach((line) => {
          devices.push(line)
        })
      })

    return (
      <div className={Styles.patientInfoWrapper}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h5 style={{ color: '#20A892' }}>
            {patient.firstName} {patient.lastName}
          </h5>
          <div
            style={{
              height: 14,
              width: 14,
              background: props.color || 'green',
              borderRadius: '50%',
              marginLeft: 20,
            }}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ width: '35%', color: 'gray' }}>Age</p>
          <p>
            {patientCore.dob && moment().diff(moment(patientCore.dob, 'DD/MM/YYYY'), 'years')}
          </p>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ width: '35%', color: 'gray' }}>Gender</p>
          <p>{patientCore.gender}</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ width: '35%', color: 'gray' }}>Ethnicity</p>
          <p>{patientCore.ethnicity}</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ width: '35%', color: 'gray' }}>Language</p>
          <p>{patientCore.language}</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ width: '35%', color: 'gray' }}>Cohorts</p>
          <p>{patientCohorts}</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ width: '35%', color: 'gray' }}>Plan</p>
          <p>{patientCore?.coaching?.plan || 'Basic'}</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ width: '35%', color: 'gray' }}>Remote Monitoring</p>
          <p
            style={{
              width: '65%',
              // height: '50%',
              // overflow: 'auto',
            }}
          >
            {rpmOrders?.length > 0 ? 'Enrolled' : 'Eligible'}
          </p>
        </div>
      </div>
    )
  }

  function removeComma(text) {
    return text.replace(',', '')
  }

  function renderConditions() {
    let conditions = props.conditions
    let conditionsElement = conditions &&  conditions.map((x,index) => <span key={index.toString()} style={{textAlign: 'left', padding: '2px 4px'}}>{(removeComma(x.symptom))}{index !== conditions.length -1 && ', '}</span>)
    return (
      <div className={Styles.conditionsWrapper}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>Conditions</h5>
        <div style={{height: '90%'}}>
          <p>
            {conditionsElement}
          </p>
        </div>
      </div>
    )

  }

  function renderMedications() {
    let medicationsObject = props.medications
    let medicationsElement = null
    if(medicationsObject) {
      medicationsElement = medicationsObject.active_medications.map((x, i) => (<span>{x.name}{i !== medicationsObject.active_medications.length - 1 && ', '}</span>)
      )
    }
    return (
      <div className={Styles.medicationsWrapper}>
        <h5 style={{color: Constants.primaryTheme, textAlign: 'center'}}>Medications</h5>
        <div style={{height: '80%'}}>
          <p>{medicationsElement}</p>
        </div>
      </div>
    )
  }

  function renderExpandedContent() {
    return (
      <div className={Styles.expandedWrapper}>
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          {getProfilePicture(true)}
          {renderPatientInfo()}
          <div className={Styles.conditionsMedicationWrapper}>
            {renderConditions()}
            {renderMedications()}
          </div>
          <img src={AilaLogo} className={Styles.logoAnimated}/>
        </div>

        {props.userType && getIcons(true)}
        {/*{cohortValues && cohortValues.length > 0 && (*/}
        {/*  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
        {/*    <p style={{color: Constants.primaryTheme,fontWeight: 'bold', marginRight: 5}}>Patient Cohorts: </p>*/}
        {/*    {cohortValues.map((x, index) => <p key={index.toString()}>&nbsp;{x.label}{index === cohortValues.length-1 ? '':','}</p>)}*/}

        {/*  </div>*/}
        {/*)}*/}
      </div>

    )
  }

  function getProfilePicture(animated) {
    return (
      <div
        onClick={() => {
          if(!stick) {
            //if not clicked, then mouse over owuld have made it stick. So no need to expand
            setStick(true)
          } else {
            setStick(false)
            setExpanded(false)
          }
        }}
        onMouseLeave={() => {
          if(!stick) {
            //if its not clicked
            setExpanded(false)
          }
        }}
        onMouseEnter={() => {
          if(!stick) {
            //if its not clicked
            setExpanded(true)
          }

        }}
        style={{padding: 6, backgroundColor: props.color || 'green', borderRadius: '50%', cursor: 'pointer'}}>
        <img
          className={animated ? Styles.imageAnimationWrapper : Styles.imageWrapper}
          src={patient && patient.profilePictureURL ? patient.profilePictureURL : PlaceHolderProfileImage}/>
      </div>
    )
  }

  function renderCollapsedContent() {
    return (
      <div className={Styles.collapsedWrapper}>
        {getProfilePicture(false)}
        <h5 style={{marginLeft: 20, color: '#20A892'}}>{patient.firstName} {patient.lastName}</h5>
        <div style={{position: 'absolute', right: 15, display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'flex-end'}}>
            {props.userType && getIcons(false)}
          <img src={AilaLogo} className={Styles.logo}/>
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.patientInfoStripWrapperCollapsed}>

      <Collapse isOpened={true} initialStyle={{height: 80}}>
        <Modal
          onRequestClose={handleCloseModal}
          ariaHideApp={false}
          isOpen={modalOpen}
          style={cohortModalOpen ? customCohortModalStyles:customContentModalStyles}
          contentLabel="Modal"
        >
          {cohortModalOpen ? renderCohortModal() : renderContentModal()}
        </Modal>

        {
          expanded ?
            renderExpandedContent()
            :
            renderCollapsedContent()
        }
      </Collapse>


    </div>
  )
}

export default PatientInfoStrip
