import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import FancyField from 'react-fancy-field'
import { Link, useHistory } from 'react-router-dom'
import Styles from './styles/AilaWebSignup.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values'
import Constants from '../../values'
import AilaLogo from '../../assets/images/aila_logo.png'
import Config from '../../config'
import GoogleLogo from '../../assets/images/google.png'
import AppleLogo from '../../assets/images/apple.png'
import Footer from '../../components/Footer'
import ReactGA from 'react-ga';

import { useSelector, useDispatch } from 'react-redux'
import {
  addAuthToken,
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  fromWebFlow,
} from '../../redux/actions'

import {
  firebase,
  auth,
  googleProvider,
  appleProvider,
  analytics,
} from '../../services/firebase'
import TagManager from 'react-gtm-module'
const axios = require('axios')

export default function LongCovidSignup() {
  const [email, setEmail] = useState('')
  const [fname, setFname] = useState('')
  const [lname, setLname] = useState('')
  const [phone, setPhone] = useState()
  const [pwd, setPwd] = useState('')
  const [confirmpwd, setConfirmpwd] = useState('')
  const [token, setToken] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [termsChecked, setTermsChecked] = useState(false)
  const [terms2Checked, setTerms2Checked] = useState(false)
  const [error, setError] = useState(false)
  const [errorString, setErrorString] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  
  // ReactGA.initialize(Config.GOOGLE_ADS_ID);
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search, 'AW-607596551', 'long-covid');
  // }, [])
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: Config.GOOGLE_ADS_ID,
      dataLayer: {
          env: process.env.ENV,
          pageCategory: 'long-covid'
      }
    } 
  TagManager.initialize(tagManagerArgs)
  }, [])

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  // Token from backend
  const getAuthToken = async (currentUser) => {
    if (!currentUser) {
      console.log('Invalid user')
      return { error: true }
    }

    const authUrl = `${Config.BACKEND_URL}authorization`
    try {
      const response = await axios({
        method: 'get',
        url: authUrl,
        headers: { x_firebase_id: currentUser.uid },
      })
      if (response.status === 200) {
        setToken(response.data.token)
        dispatch(addAuthToken(response.data.token))

        return { error: false, tokenVal: response.data.token }
      }
      console.log('error', response)
      return { error: true }
    } catch (err) {
      console.log('error when getting auth token', err)
      return { error: true, errorString: 'error when getting auth token' }
    }
  }

  const saveDetailsToFirebase = (user_uid, google_id, oauthType) => {
    let ccfmDeeplink = localStorage.getItem('ccfm')
    let dacPilotDeeplink = localStorage.getItem('dac')
    let raPilotDeeplink = localStorage.getItem('rapilot')

    const toSaveObject = {
      email: google_id ? google_id : email.trim(),
      uid: user_uid,
      lastSignedIn: new Date(),
      platform: 'web',
      signup_platform: 'web',
      flow_type: 'long-covid',
      longCovid: true,
      phoneNumber: phone || "",
    }

    if (oauthType) {
      toSaveObject.oauthType = oauthType
    }

    if (ccfmDeeplink === 'yes') {
      toSaveObject.ccfm = true
      toSaveObject.userType = 'ccfm-patient'
    }

    if (dacPilotDeeplink === 'yes') {
      toSaveObject.dac = true
      toSaveObject.userType = 'dac-patient'
    }

    if (raPilotDeeplink === 'yes') {
      toSaveObject.rapilot = true
      toSaveObject.userType = 'ra-pilot-patient'
    }

    firebase
      .firestore()
      .collection('users')
      .doc(user_uid)
      .set(toSaveObject)
      .then((data) => {
        console.log('saved-firebase', data)
        analytics.logEvent('Web_Signup')
        firebase
          .firestore()
          .collection('users')
          .doc(user_uid)
          .get()
          .then(async (doc) => {
            if (doc && doc.data()) {
              const data = doc.data()
              // console.log('user', data)
              dispatch(addPatient(data, 'gray'))
              dispatch(addFirebaseUser(data))
              dispatch(addFirebaseAuthUser(user_uid))
              dispatch(fromWebFlow(true))
              setLoading(true)

              history.push('/long-covid/questionnaire', {
                patient_id: user_uid,
                email_id: data.email,
                token: token,
              })
              return
            }
          })
      })
      .catch((err) => {
        console.log('somethign went wrong when saving user to firestore', err)
      })
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  // Sign in with google
  const signinwithApple = async () => {
    auth
      .signInWithPopup(appleProvider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user

        console.log(user)

        setCurrentUser(user.uid)

        // console.log('google user logged in', user.uid)
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              setError(true)
              setErrorString(
                'The email address is already in use by another account.',
              )
              // console.log('doc already exists, so updating')
              //user data is already present in firestore, do not save again
              firebase
                .firestore()
                .collection('users')
                .doc(user.uid)
                .update({
                  lastSignedIn: new Date(),
                })
                .then(() => {
                  console.log('updated user last signed in ')
                })
                .catch(() =>
                  console.log('error when updating user last signed in'),
                )
              return
            } else {
              try {
                const tokenResp = await getAuthToken(user)
                if (tokenResp.error === true) {
                  setError(true)
                  setErrorString('Token Error')
                } else {
                  console.log('Success token auth: ', tokenResp)
                  setLoading(true)
                  saveDetailsToFirebase(user.uid, user.email, 'apple')
                }
              } catch (err) {
                setError(true)
                setErrorString(err.message)
                console.log(err)
                onFinishFailed(err.message)
              }
            }
          })

        // ...
      })
      .catch((error) => {
        console.log(error)
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.email
        // The credential that was used.
        // const credential = OAuthProvider.credentialFromError(error)

        // ...
      })
  }

  // Sign in with google
  const signin = async () => {
    googleProvider.setCustomParameters({
      prompt: 'select_account',
    })
    auth
      .signInWithPopup(googleProvider)
      .then((result) => {
        // console.log('USER: ', result)
        // console.log('TOKEN: ', result.credential.idToken)
        // console.log('EMAIL: ', result.user.email)
        let user = result.user
        setCurrentUser(user.uid)

        // console.log('google user logged in', user.uid)
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              setError(true)
              setErrorString(
                'The email address is already in use by another account.',
              )
              // console.log('doc already exists, so updating')
              //user data is already present in firestore, do not save again
              firebase
                .firestore()
                .collection('users')
                .doc(user.uid)
                .update({
                  lastSignedIn: new Date(),
                })
                .then(() => {
                  console.log('updated user last signed in ')
                })
                .catch(() =>
                  console.log('error when updating user last signed in'),
                )
              return
            } else {
              try {
                const tokenResp = await getAuthToken(user)
                if (tokenResp.error === true) {
                  setError(true)
                  setErrorString('Token Error')
                } else {
                  console.log('Success token auth: ', tokenResp)
                  setLoading(true)
                  saveDetailsToFirebase(user.uid, user.email, 'google')
                }
              } catch (err) {
                setError(true)
                setErrorString(err.message)
                console.log(err)
                onFinishFailed(err.message)
              }
            }
          })
      })
      .catch((error) => {
        console.log(error)
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.email
        // The credential that was used.
        // const credential = OAuthProvider.credentialFromError(error)

        // ...
      })
  }

  // Firebase Login
  async function signup(email, password) {
    const user = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
    let error = false
    if (user.user.uid) {
      setCurrentUser(user.user.uid)
      // console.log('Logged in PATIENT')
    } else {
      error = true
      console.log('ERROR LOGGING IN')
    }
    return { userData: user, errorLogin: error }
  }

  const onSignupPressed = async () => {
    if (!email || email.length === 0 || !validateEmail(email)) {
      setError(true)
      setErrorString('Please enter a valid email address')
      console.log('EMAIL ERROR')
      return
    }

    // if (!fname || fname.length === 0) {
    //   setError(true)
    //   setErrorString('Please enter your first name')
    //   console.log('FNAME ERROR')
    //   return
    // }
    // if (!lname || lname.length === 0) {
    //   setError(true)
    //   setErrorString('Please enter your last name')
    //   console.log('LNAME ERROR')
    //   return
    // }

    if (!pwd || pwd.length < 6) {
      setError(true)
      setErrorString('The password must be at least 6 characters')
      console.log('PWD ERROR')
      return
    }

    if (!confirmpwd || (confirmpwd.length < 6 && confirmpwd === pwd)) {
      setError(true)
      setErrorString('The confirm password must be same as your password')
      console.log('CONFIRMPWD ERROR')
      return
    }

    if (!phone || !phone.length) {
      setError(true)
      setErrorString('Please enter a valid phone number')
      console.log('PHONE ERROR')
      return
    }

    if (!termsChecked || !terms2Checked) {
      setError(true)
      setErrorString('Please read and accept the terms of use ')
      console.log('TERMS OF USE ERROR')
      return
    }

    try {
      const { userData, errorLogin } = await signup(email, pwd)
      if (!errorLogin) {
        const tokenResp = await getAuthToken(userData.user)
        if (tokenResp.error === true) {
          setError(true)
          setErrorString('Token Error')
        } else {
          // console.log('Success token auth: ', tokenResp)
          setLoading(true)
          saveDetailsToFirebase(userData.user.uid)
        }
      } else {
        setError(true)
        setErrorString('You Do Not Have Access!!')
      }
    } catch (err) {
      setError(true)
      setErrorString(err.message)
      console.log(err)
      onFinishFailed(err.message)
    }
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: '100vh',
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  if (loading) return renderLoader()

  return (
    <div
      className={GlobalStyles.container}
      style={{
        overflow: 'hidden',
        margin: '0 auto',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={Styles.container}>
        {/* <img
          alt="Aila Health"
          src={AilaLogo}
          style={{
            width: '15vh',
            height: '15vh',
            alignSelf: 'flex-start',
          }}
        /> */}

        <div className={Styles.subContainer}>
          <div
            style={{
              width: '80%',
              alignSelf: 'center',
              marginTop: '5vh',
            }}
          >
            <p className={Styles.heading}>
              Long Covid Assessment
              <br />
              <br />
            </p>
            <p
              className={Styles.heading}
              style={{
                fontSize: '28px',
              }}
            >
              Create a secure account so we can email a PDF
              <br />
              of your assessment
            </p>
          </div>

          <div className={Styles.mainField}>
            <div className={Styles.subField}>
              <FancyField
                value={email}
                label="Email"
                required={false}
                name="emailInput"
                //   autoFocus
                onChange={(val) => setEmail(val)}
                placeholder="Enter your email... "
              />
            </div>

            <div className={Styles.subField}>
              <FancyField
                value={phone}
                label="Phone Number"
                required={false}
                name="phoneInput"
                type={Number}
                onChange={(val) => setPhone(val)}
                placeholder="Enter your phone number... "
              />
            </div>
          </div>

          <div className={Styles.mainField} style={{ marginTop: '4vh' }}>
            <div className={Styles.subField}>
              <FancyField
                value={pwd}
                label="Password"
                required={false}
                type="password"
                name="pwdInput"
                onChange={(val) => setPwd(val)}
                placeholder="Create a secure password... "
              />
            </div>

            <div className={Styles.subField}>
              <FancyField
                value={confirmpwd}
                label="Confirm Password"
                required={false}
                type="password"
                name="pwdInput"
                onChange={(val) => setConfirmpwd(val)}
                placeholder="Confirm your password... "
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginTop: '8vh',
              justifyContent: 'flex-start',
            }}
          >
            <label className={Styles.checkContainer}>
              <p
                style={{
                  color: Constants.darkGray,
                  width: '100%',
                  overflowWrap: 'break-word',
                  display: 'block',
                }}
              >
                I consent to emails and SMS (which may be automated) regarding
                my treatment plan, as well as updates regarding Aila Health’s
                products and services (message and data rates may apply).
              </p>
              <input
                type="checkbox"
                checked={termsChecked}
                onClick={() => {
                  setTermsChecked(!termsChecked)
                }}
              />
              <span className={Styles.checkmark}></span>
            </label>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              // marginTop: '4vh',
              // marginLeft: '20vh',
              justifyContent: 'flex-start',
            }}
          >
            <label className={Styles.checkContainer}>
              <p
                style={{
                  color: Constants.darkGray,
                  wordWrap: 'break-word',
                  width: '100%',
                }}
              >
                By clicking get started, I have read and consent to the terms of
                Aila Health's{' '}
                <a
                  href={Config.PRIVACY_POLICY_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecorationLine: 'underline' }}
                >
                  HIPAA Notice, Privacy Policy
                </a>{' '}
                ,{' '}
                <a
                  href={Config.TOS_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecorationLine: 'underline' }}
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href={Config.TELEHEALTH_POLICY}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecorationLine: 'underline' }}
                >
                  Telehealth Consent
                </a>{' '}
              </p>
              <input
                type="checkbox"
                checked={terms2Checked}
                onClick={() => {
                  setTerms2Checked(!terms2Checked)
                }}
              />
              <span className={Styles.checkmark}></span>
            </label>
          </div>

          <p
            style={{
              marginTop: 20,
              fontSize: 12,
              color: 'red',
              visibility: error ? 'visible' : 'hidden',
              textAlign: 'center',
            }}
          >
            {errorString}
          </p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '35vw',
            }}
          >
            <Button
              className={Styles.button}
              onClick={() => signinwithApple()}
              style={{
                shadowColor: '#000',
                backgroundColor: '#fff',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.5,
                shadowRadius: 2,
                color: '#000000',
              }}
              variant="primary"
            >
              <img alt="Apple" src={AppleLogo} className={Styles.logo} />
              Sign in with Apple
            </Button>

            <Button
              className={Styles.button}
              onClick={() => signin()}
              style={{
                shadowColor: '#000',
                backgroundColor: '#fff',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.5,
                shadowRadius: 2,
                color: '#000000',
              }}
              variant="primary"
            >
              <img alt="Google" src={GoogleLogo} className={Styles.logo} />
              Sign in with Google
            </Button>
          </div>

          <Button
            onClick={() => {
              // history.push('/long-covid/questionnaire')
              onSignupPressed()
            }}
            className={Styles.button}
            // style={{
            //   marginTop: '5vh',
            //   width: '15vw',
            //   height: '6vh',
            //   alignSelf: 'center',
            // }}
            variant="primary"
          >
            Take Assessment
          </Button>

          <Link
            to={{
              pathname: `/login`,
              longCovidFlow: 'true'
            }}
          >
            <p
              style={{
                fontSize: '18px',
                marginTop: 30,
                color: values.primaryThemeDark,
                textDecoration: 'underline',
                cursor: 'pointer',
                textAlign: 'center',
              }}
            >
              I already have an account
            </p>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}
