// commented out
// displayed in video appointment screen start visit
import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Config from '../../config'
import {
  fetchPatientCarePlanData,
  setAppointmentNotesData,
} from '../../redux/actions'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/NotesModal.module.scss'
const axios = require('axios')

const iCD10Connector = new AppSearchAPIConnector({
  searchKey: 'search-fc41f31vp6rwpsbocuf72x6i',
  engineName: 'icd10-complete',
  endpointBase: 'https://aila-test.ent.us-east1.gcp.elastic-cloud.com',
})

const esConfigICD10 = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: iCD10Connector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      code: { raw: {} },
      display: { raw: {} },
      object_id: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

const conditionConnector = new AppSearchAPIConnector({
  searchKey: 'search-fc41f31vp6rwpsbocuf72x6i',
  // TODO: update name to cpt-codes
  engineName: 'conditions',
  endpointBase: 'https://aila-test.ent.us-east1.gcp.elastic-cloud.com',
})

const esConfigCPTCodes = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: conditionConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      description: { raw: {} },
      cpt_code: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

// const ClearAllRefinements = ({ refine, items }) => {
//   const onClick = () => refine(items)
//   return (
//     <button id="icdclear-video" onClick={onClick}>
//       ClearAll
//     </button>
//   )
// }

class NotesModal extends Component {
  constructor(props) {
    super(props)
    let notesData = this.props?.carePlanNotesData
    let reduxAppNotes = this.props?.appointmentNotesData
    console.log('TESTING NOTES DATA:: ', notesData)
    console.log('TESTING NOTES APP DATA:: ', this.props?.appointmentNotesData)
    let iterator =
      notesData?.icd_10?.length >= 1
        ? Math.max(
            notesData?.text?.length,
            notesData?.notes?.length,
            notesData?.icd_10?.length,
          )
        : 0
    let tempNotesList = []
    // for arranging the notes to display inside the modal
    for (let i = 0; i <= iterator - 1; i++) {
      ;(notesData?.icd_10 || notesData?.text) &&
        tempNotesList.push({
          icd_10: notesData?.icd_10?.[i],
          notes: notesData?.notes?.[i],
          text: notesData?.text?.[i],
        })
    }
    let tempIllness = notesData?.duration
      ? `Severity: ${notesData?.severity}/10,
Duration: ${notesData?.duration} ${notesData?.durationType},
Description: ${notesData?.description}`
      : ''

    let firstIcd,
      firstNote,
      firstCarePlan,
      reduxCpt,
      reduxChiefConcern,
      reduxIllness,
      reduxPhysicalExam,
      reduxNote_id,
      reduxAppointmentDate,
      reduxAppointmentId
    if (this.props.openNotesModal) {
      reduxAppNotes?.notesList?.length &&
        (tempNotesList = reduxAppNotes?.notesList)
      firstIcd = reduxAppNotes?.addIcd10
      firstNote = reduxAppNotes?.addNotes
      firstCarePlan = reduxAppNotes?.addCarePlan
      reduxAppNotes?.addCpt?.length && (reduxCpt = reduxAppNotes?.addCpt)
      reduxChiefConcern = reduxAppNotes?.chiefConcern
      reduxIllness = reduxAppNotes?.illness
      reduxPhysicalExam = reduxAppNotes?.physicalExam
      reduxNote_id = reduxAppNotes?.note_id
      reduxAppointmentDate = reduxAppNotes?.appointmentDate
      reduxAppointmentId = reduxAppNotes?.reduxAppointmentId
    }

    this.state = {
      loading: false,
      appointmentData: null,
      patientId: this.props?.patientId || this.props?.patient.uid,
      conditionsData: [],
      notes: '',
      notesSaved: [],
      addIcd10: firstIcd || {},
      addNotes: firstNote || '',
      addCarePlan: firstCarePlan || '',
      addCpt: reduxCpt || notesData?.cpt || [],
      notesList: tempNotesList || [],
      chiefConcern:
        reduxChiefConcern ||
        notesData?.chief_concern ||
        notesData?.reason ||
        '',
      illness: reduxIllness || notesData?.present_illness || tempIllness,
      physicalExam: reduxPhysicalExam || notesData?.physical_exam || '',
      note_id: reduxNote_id || notesData?.note_id || '',
      icd10query: '',
      cptquery: '',
      carePlanTemplate: [],
      doctor_details: this.props?.mongoUser,
      appointmentDate:
        reduxAppointmentDate ||
        notesData?.start ||
        notesData?.appointment_date ||
        null,
      appointment_event_id:
        reduxAppointmentId ||
        notesData?.event_id ||
        notesData?.appointment_event_id ||
        null,
      noteCreatedAt:
        notesData?.created_at && notesData?.note_id
          ? notesData?.created_at
          : new Date(),
      tempIllness: tempIllness,
      tempNotesListData: tempNotesList,
      tempNotesData: notesData,
      labErxOrdersData: {},
    }
    this.getConditionsData = this.getConditionsData.bind(this)
    this.getCarePlanTemplate = this.getCarePlanTemplate.bind(this)
    this.getLabErxOrders = this.getLabErxOrders.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.getCarePlanTemplate()
    this.getLabErxOrders()
  }

  getConditionsData() {
    let { token, doctorPatientData } = this.props
    let url = Config.BACKEND_URL + 'user/conditions'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: doctorPatientData.patient_id,
      },
    })
      .then((response) => {
        console.log('got conditions data')
        self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting conditions data', err)
        toast.error('Something went wrong')
      })
  }

  getAppointmentData() {
    let { token, patient, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'providers/patients/appointments'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_doctor_id: mongoUser.firebase_id,
        x_patient_id: patient.uid,
      },
    })
      .then((response) => {
        self.setState({ appointmentData: response.data })
      })
      .catch((err) => {
        console.log('error when getting appointment information', err)
        toast.error('Something went wrong')
      })
  }

  getCarePlanTemplate() {
    let { token, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'careplan-template'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: mongoUser.firebase_id,
      },
    })
      .then(({ data }) => {
        if (data?.physical_exam_template?.length) {
          let optionObjects = []
          // data?.physical_exam_template?.forEach(option => data.push({label: option, value: option}))
          optionObjects = data?.physical_exam_template.map((option) => {
            let keyLabel = Object.keys(option)[0].replace(/_/g, ' ')
            return {
              label: keyLabel.charAt(0).toUpperCase() + keyLabel.substring(1),
              value: Object.values(option)[0],
            }
          })
          self.setState({ carePlanTemplate: optionObjects })
        }
      })
      .catch((err) => {
        console.log('error when getting careplan template', err)
      })
  }

  getLabErxOrders() {
    let { token, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'appointment/orders'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: mongoUser.firebase_id,
        event_id: this.state.appointment_event_id,
      },
    })
      .then(({ data }) => {
        console.log('testing laborders; ', data)
        if (data) {
          self.setState({ labErxOrdersData: data })
        }
      })
      .catch((err) => {
        console.log('error when getting erx lab orders', err)
      })
  }

  render() {
    let { token, patient, docFirebaseAuthUser, mongoUser, navigateFrom } =
      this.props
    // console.log('TESTING NOTESLIST: ', this.state.notesList)
    // let isHealthCoach = true
    let isHealthCoach =
      mongoUser?.user_type === 'Health Coach' ||
      (!this.state.appointmentDate &&
        navigateFrom === 'notesModal' &&
        !this.state.notesList?.[0]?.icd_10?.icd10_code)
    const Hit = (props, type) => {
      let { hit } = props
      // let checked = handleChecked(q, hit.symptom)
      const handleClick = () => {
        if (type === 'cpt') {
          this.setState(
            (prevState) => ({
              addCpt: [
                ...prevState.addCpt,
                { cpt_code: hit.CPTCode, title: hit.CodeDescription },
              ],
              cptquery: '',
            }),
            () => saveAppointmentToRedux(),
          )
        } else {
          eventFire(document.getElementById('icdclear-video'), 'click')
          this.setState(
            {
              addIcd10: { icd10_code: hit.code, title: hit.display },
              icd10query: '',
            },
            () => saveAppointmentToRedux(),
          )
        }
      }
      return (
        <div
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={(e) => {
            handleClick(e)
          }}
        >
          {type === 'cpt'
            ? `${hit?.CPTCode}: ${hit?.CodeDescription}`
            : `${hit?.code}: ${hit?.display}`}
        </div>
      )
    }

    const handleNoteChange = (event, noteType) => {
      this.setState(
        {
          [noteType]: event.target.value,
        },
        () => saveAppointmentToRedux(),
      )
    }

    const saveAppointmentToRedux = () => {
      let {
        notesList,
        addCpt,
        chiefConcern,
        physicalExam,
        illness,
        appointmentDate,
        note_id,
        addNotes,
        addCarePlan,
        addIcd10,
        appointment_event_id,
      } = this.state
      let saveData = {
        chiefConcern,
        illness,
        physicalExam,
        appointmentDate,
        note_id,
        addCpt,
        notesList,
        addIcd10,
        addCarePlan,
        addNotes,
        appointment_event_id,
      }
      this.props.setAppointmentNotesData(saveData)
    }

    const clearNotes = () => {
      this.setState({
        addIcd10: {},
        addNotes: '',
        addCarePlan: '',
        addCpt: [],
        notesList: this.state?.tempNotesListData || [],
        chiefConcern: this.state?.tempNotesData?.reason || '',
        illness: this.state?.tempIllness,
        physicalExam: this.state?.tempNotesData?.physical_exam || '',
        note_id: '',
        icd10query: '',
        cptquery: '',
        appointmentDate: this.state?.tempNotesData?.start || null,
        appointment_event_id: this.state?.tempNotesData?.event_id || null,
        noteCreatedAt: new Date(),
      })
      // this.setState({
      //   addIcd10: {},
      //   addNotes: '',
      //   addCarePlan: '',
      //   addCpt: [],
      //   notesList: [],
      //   chiefConcern: '',
      //   illness: '',
      //   physicalExam: '',
      //   note_id: "",
      //   icd10query: '',
      //   cptquery: '',
      //   // carePlanTemplate: [],
      //   appointmentDate: null,
      //   noteCreatedAt: new Date()
      // })
      this.props.setAppointmentNotesData(null)
    }

    const noteSubmit = (isHealthCoach, saveType) => {
      let {
        notesList,
        addCpt,
        chiefConcern,
        physicalExam,
        illness,
        patientId,
        appointmentDate,
        note_id,
        doctor_details,
        noteCreatedAt,
        appointment_event_id,
      } = this.state

      let updateNotes = note_id.length ? true : false

      // unique id for each note
      note_id = note_id || docFirebaseAuthUser.uid + Date.now()

      // if(
      //   !isHealthCoach &&
      //   !addIcd10
      // ) {
      //   toast.error('Can\'t leave icd10 field empty, add icd10')
      //   return
      // }
      // if(
      //   !addNotes.length
      // ) {
      //   toast.error('Can\'t leave assessment field empty, add assessment')
      //   return
      // }
      // if(
      //   !addCarePlan.length
      // ) {
      //   toast.error('Can\'t leave careplan note field empty, add careplan note')
      //   return
      // }
      if (!notesList?.length) {
        toast.error('Please add notes')
        return
      }
      for (let i = 0; i < notesList?.length; i++) {
        if (!notesList?.[i]?.text?.length) {
          toast.error(
            "Can't leave careplan note field empty, add careplan note",
          )
          return
        }
      }
      if (!chiefConcern.length) {
        toast.error("Can't leave fields empty")
        return
      }

      let tempNotesList = cloneDeep(notesList)
      let patientCondn = []
      // let tempNotesList = JSON.parse(JSON.stringify(notesList))

      // tempNotesList.unshift({
      //   icd_10: addIcd10,
      //   notes: addNotes,
      //   text: addCarePlan,
      // })
      // {noteData?.icd_10?.icd10_code}: {noteData?.icd_10?.title}
      tempNotesList.forEach(
        (note, index) => (
          (note.note_id = note_id),
          (note.note_status = saveType),
          (note.chief_concern = chiefConcern),
          (note.present_illness = illness),
          (note.physical_exam = physicalExam),
          (note.cpt = addCpt),
          (note.created_at = noteCreatedAt),
          appointmentDate && (note.appointment_date = appointmentDate),
          appointment_event_id &&
            (note.appointment_event_id = appointment_event_id),
          note?.icd_10 &&
            patientCondn.push({
              patient_id: patientId,
              doctor_id: docFirebaseAuthUser.uid,
              resourceType: 'Condition',
              code: {
                coding: [
                  {
                    system: 'http://snomed.info/sct',
                    code: note?.icd_10?.icd10_code,
                    display: note?.icd_10?.title,
                  },
                ],
              },
              subject: {
                reference: `Patient/${patientId}`,
                // display: `${patient?.firstName} ${patient?.lastName}`
              },
              recorder: {
                reference: `${doctor_details.user_type}/${docFirebaseAuthUser.uid}`,
                // display: `${doctor_details.first_name} ${doctor_details.last_name}`
              },
              recordedDate: new Date(),
            })
        ),
      )

      console.log('updateNotes:::::', updateNotes, patientCondn)
      // request to add multiple condns to patient profile automatically
      axios({
        method: 'put',
        url: Config.BACKEND_URL + 'fhir/multiple-conditions',
        headers: {
          Authorization: 'JWT ' + token,
          x_doctor_id: docFirebaseAuthUser.uid,
        },
        data: patientCondn,
      })
        .then(() => console.log('patient conditions added automatically'))
        .catch((err) =>
          console.log('error when adding patient condn to profile: ', err),
        )

      axios({
        method: 'post',
        url: Config.BACKEND_URL + 'provider/patients/notes',
        headers: {
          Authorization: 'JWT ' + token,
          x_doctor_id: docFirebaseAuthUser.uid,
          update: updateNotes,
        },
        data: {
          patient_id: patientId,
          doctor_id: docFirebaseAuthUser.uid,
          note_id: note_id,
          notesArray: tempNotesList,
          cptArray: addCpt,
          chiefConcern: chiefConcern,
          illness: illness,
          physicalExam: physicalExam,
        },
      })
        .then((result) => {
          console.log('notes saved')
          const data = result.data
          // let notesSaved = this.state.notesSaved || []
          // notesList.forEach(careplan => notesSaved.push(careplan.text))
          this.setState(
            {
              // notesSaved: notesSaved,
              // notes: '',
              // addIcd10: {},
              // addNotes: '',
              // addCarePlan: '',
              // addCpt: [],
              // notesList: [],
              // chiefConcern: '',
              // illness: '',
              // physicalExam: '',
              // icd10query: '',
              // cptquery: '',
              note_id: note_id,
            },
            () => {
              saveAppointmentToRedux()
              this.props.fetchPatientCarePlanData({
                patient: this.props.patient,
                token: token,
                adminId: docFirebaseAuthUser.uid,
                patientId: patientId,
              })
            },
          )
          toast.success(`Assessment ${saveType}`)
          // this.setState({note_id: note_id})
          this.props?.navigateFrom === 'notesModal' && this.props.hideNotes()
          this.props?.navigateFrom === 'notesModal' &&
            this.props.getUserCarePlan()
        })
        .catch((err) => {
          console.log('error when updating notes', err)
        })
    }

    const renderLabsErxOrders = () => {
      let { labErxOrdersData } = this.state
      // console.log('labErxOrdersData', labErxOrdersData)
      let labOrdersElements = []
      let erxOrdersElements = []
      let labOrderData = labErxOrdersData?.lab_orders || []
      let erxOrders = labErxOrdersData.erx_orders || []
      if (labErxOrdersData) {
        labOrderData.length &&
          labOrderData.forEach((lab_orders) => {
            let tests = lab_orders?.tests || []
            tests.length &&
              tests.forEach((test) => {
                labOrdersElements.push(
                  <div
                    // onClick={() => this.onModalLabOrderClicked(x)}
                    // style={{cursor: 'pointer'}}
                    className={Styles.row}
                  >
                    <p className={Styles.entryText} style={{ width: '60%' }}>
                      {test.name} (lab)
                    </p>
                    <p className={Styles.entryText} style={{ width: '40%' }}>
                      {moment(lab_orders?.created_at).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </p>
                  </div>,
                )
              })
          })
        erxOrders.length &&
          erxOrders.forEach((lab_orders) => {
            let drug = lab_orders?.drug
            erxOrdersElements.push(
              <div
                // onClick={() => this.onModalLabOrderClicked(x)}
                // style={{cursor: 'pointer'}}
                className={Styles.row}
              >
                <p className={Styles.entryText} style={{ width: '60%' }}>
                  {drug?.name} (erx)
                </p>
                <p className={Styles.entryText} style={{ width: '40%' }}>
                  {moment(lab_orders?.created_at).format('YYYY-MM-DD HH:mm')}
                </p>
              </div>,
            )
          })
        console.log('labOrdersElements: ', labOrdersElements)
      }

      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ margin: 'auto', paddingBottom: 20, width: '40%' }}>
            <div className={Styles.row}>
              {labOrdersElements.length === 0 ? (
                <p className={Styles.headerText} style={{ width: '100%' }}>
                  No lab orders
                </p>
              ) : (
                <>
                  <p className={Styles.headerText} style={{ width: '60%' }}>
                    Test(s)
                  </p>
                  <p className={Styles.headerText} style={{ width: '40%' }}>
                    Date
                  </p>
                </>
              )}
            </div>
            {labOrdersElements}
          </div>
          <div style={{ margin: 'auto', paddingBottom: 20, width: '40%' }}>
            <div className={Styles.row}>
              {erxOrdersElements.length === 0 ? (
                <p className={Styles.headerText} style={{ width: '100%' }}>
                  No erx orders
                </p>
              ) : (
                <>
                  <p className={Styles.headerText} style={{ width: '60%' }}>
                    Drug(s)
                  </p>
                  <p className={Styles.headerText} style={{ width: '40%' }}>
                    Date
                  </p>
                </>
              )}
            </div>
            {erxOrdersElements}
          </div>
        </div>
      )
    }

    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div style={{ width: '100%' }}>
        {this.props?.navigateFrom === 'video' && (
          <>
            {/* <p className={Styles.closeModalBtn} onClick={() => this.props.hideNotes()}>X</p> */}
            <h2
              style={{
                color: Constants.primaryTheme,
                textAlign: 'center',
                margin: '10px 0px',
              }}
            >
              Add Assessment
            </h2>
          </>
        )}
        <div style={{ margin: '10px 20px', float: 'right' }}>
          <Button
            onClick={() => clearNotes()}
            className={Styles.saveButton}
            style={{ width: 140 }}
            variant="primary"
          >
            Clear notes
          </Button>
        </div>
        <div className={Styles.modalNotesRow}>
          <p className={Styles.modalNotesQuestion}>Chief Concern: </p>
          <input
            placeholder={'Please Enter Chief Concern'}
            className={Styles.textInput}
            type="text"
            value={this.state.chiefConcern}
            onChange={(e) =>
              this.setState({ chiefConcern: e.target.value }, () =>
                saveAppointmentToRedux(),
              )
            }
          />
        </div>
        {!isHealthCoach && (
          <>
            <div className={Styles.modalNotesRow}>
              <p className={Styles.modalNotesQuestion}>
                History of Present Illness:{' '}
              </p>
              <textarea
                rows="3"
                placeholder={'Please Enter Present Illness'}
                style={{
                  border: '1px solid #d0d0d0',
                  padding: '5px 20px',
                  borderRadius: '4px',
                  marginTop: '10px',
                  width: '67%',
                }}
                type="text"
                value={this.state.illness}
                onChange={(e) =>
                  this.setState({ illness: e.target.value }, () =>
                    saveAppointmentToRedux(),
                  )
                }
              />
            </div>

            <div className={Styles.modalNotesRow}>
              <p className={Styles.modalNotesQuestion}>Physical Exam: </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '67%',
                }}
              >
                {this.state.carePlanTemplate?.length !== 0 && (
                  <div>
                    <Select
                      placeholder={'Select template'}
                      options={this.state.carePlanTemplate}
                      value={{
                        label: 'Select Template',
                        value: 'Select Template',
                      }}
                      onChange={(val) => {
                        this.setState({ physicalExam: val.value }, () =>
                          saveAppointmentToRedux(),
                        )
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                )}
                <textarea
                  rows="3"
                  placeholder={'Please Enter Physical Exam'}
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    marginTop: '10px',
                  }}
                  type="text"
                  value={this.state.physicalExam}
                  onChange={(e) =>
                    this.setState({ physicalExam: e.target.value }, () =>
                      saveAppointmentToRedux(),
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
        ; ;
        ;<div className={Styles.modalNotesRow}>
          {!isHealthCoach && (
            // show ICD Search for everyone except health coach
            <div className={Styles.modalNotesCol}>
              <p className={Styles.modalNotesKey}>ICD 10</p>
              <div className={Styles.modalValueValue}>
                {/* <InstantSearch
                indexName={'ICD_10_COMPLETE'}
                searchClient={condnClient}
                onSearchStateChange={({ query }) => this.setState({icd10query: query})}
              >
                <SearchBox />
                <ClearQueryAndRefinements/>
                  { (this.state.icd10query && this.state.icd10query.length !==0) ? (
                      <div style={{overflow: 'auto', height: 150, backgroundColor: '#F1F1F1'}}>
                        <RefinementList limit={5} />
                        <Hits hitComponent={(props) => Hit(props)} />
                      </div>
                      ) : (
                        this.state?.addIcd10?.icd10_code &&
                        <p style={{padding: 5}}>{this.state?.addIcd10?.icd10_code}: {this.state?.addIcd10?.title}</p>
                      )

                  }
              </InstantSearch> */}
                <SearchProvider config={esConfigICD10}>
                  <WithSearch
                    mapContextToProps={({ wasSearched }) => ({
                      wasSearched,
                    })}
                  >
                    {({ wasSearched }) => {
                      // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                      return (
                        <div className="App">
                          <EsSearchBox
                            debounceLength={0}
                            autocompleteResults={{
                              titleField: 'search_term',
                            }}
                            onSelectAutocomplete={(e) => {
                              eventFire(
                                document.getElementById('icdclear-video'),
                                'click',
                              )
                              this.setState(
                                {
                                  addIcd10: {
                                    icd10_code: e.code.raw,
                                    title: e.display.raw,
                                  },
                                  icd10query: '',
                                },
                                () => saveAppointmentToRedux(),
                              )
                            }}
                            searchAsYouType={true}
                            inputView={({
                              getAutocomplete,
                              getInputProps,
                              getButtonProps,
                            }) => (
                              <>
                                <div className="sui-search-box__wrapper">
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search ICD10 codes',
                                    })}
                                  />
                                  {getAutocomplete()}
                                </div>
                              </>
                            )}
                          />
                        </div>
                      )
                    }}
                  </WithSearch>
                </SearchProvider>
              </div>
            </div>
          )}

          <div className={Styles.modalNotesCol}>
            <p className={Styles.modalNotesKey}>{`Assessment`}</p>
            <textarea
              className={Styles.modalValueValue}
              rows="3"
              cols="40"
              onChange={(e) => handleNoteChange(e, 'addNotes')}
              value={this.state.addNotes}
            ></textarea>
          </div>

          <div className={Styles.modalNotesCol}>
            <p className={Styles.modalNotesKey}>Care plan</p>
            <textarea
              className={Styles.modalValueValue}
              rows="3"
              cols="40"
              onChange={(e) => handleNoteChange(e, 'addCarePlan')}
              value={this.state.addCarePlan}
            ></textarea>
          </div>
          <div>
            <FontAwesomeIcon
              className={Styles.plusButton}
              onClick={() => {
                let { addIcd10, addNotes, addCarePlan } = this.state
                if (addIcd10.icd10_code) {
                  this.setState(
                    (prevState) => ({
                      notesList: [
                        {
                          icd_10: addIcd10,
                          notes: addNotes,
                          text: addCarePlan,
                        },
                        ...prevState.notesList,
                      ],
                      addIcd10: {},
                      addNotes: '',
                      addCarePlan: '',
                    }),
                    () => saveAppointmentToRedux(),
                  )
                } else {
                  this.setState(
                    (prevState) => ({
                      notesList: [
                        {
                          notes: addNotes,
                          text: addCarePlan,
                        },
                        ...prevState.notesList,
                      ],
                      addNotes: '',
                      addCarePlan: '',
                    }),
                    () => saveAppointmentToRedux(),
                  )
                }
              }}
              icon={faPlusCircle}
            />
          </div>
        </div>
        {
          this.state?.notesList?.length !== 0 && (
            <div
              className={Styles.addedNotesListContainer}
              style={{ width: '100%' }}
            >
              {this.state.notesList.map((noteData, index) => (
                <div
                  className={Styles.modalNotesRow}
                  style={{
                    backgroundColor: '#e8e8e8',
                    borderRadius: 20,
                    marginTop: 10,
                    alignItems: 'center',
                  }}
                >
                  {!isHealthCoach && (
                    <p
                      className={Styles.modalNotesCol}
                      style={{ textAlign: 'center' }}
                    >
                      {noteData?.icd_10?.icd10_code}: {noteData?.icd_10?.title}
                    </p>
                  )}
                  {/* <p className={Styles.modalNotesCol} style={{ textAlign: 'center' }}>
                  {noteData?.notes}
                </p> */}
                  <textarea
                    className={Styles.modalNotesCol}
                    rows="3"
                    cols="40"
                    onChange={(e) => {
                      let notes = this.state.notesList
                      notes[index].notes = e.target.value
                      this.setState({ notesList: notes }, () =>
                        saveAppointmentToRedux(),
                      )
                    }}
                    value={noteData?.notes}
                  />
                  <textarea
                    className={Styles.modalNotesCol}
                    rows="3"
                    cols="40"
                    onChange={(e) => {
                      let notes = this.state.notesList
                      notes[index].text = e.target.value
                      this.setState({ notesList: notes }, () =>
                        saveAppointmentToRedux(),
                      )
                    }}
                    value={noteData?.text}
                  />
                  {/* <p className={Styles.modalNotesCol} style={{ textAlign: 'center' }}>{noteData?.text}</p> */}
                  <FontAwesomeIcon
                    icon={faTrash}
                    className={Styles.deleteIcon}
                    onClick={() => {
                      let noteArray = this.state.notesList
                      noteArray.splice(index, 1)
                      this.setState({ notesList: noteArray }, () =>
                        saveAppointmentToRedux(),
                      )
                    }}
                  />
                </div>
              ))}
            </div>
          )
        }
        {
          !isHealthCoach && (
            // show CPT code for everyone except health coach
            <div
              className={Styles.modalNotesCPTRow}
              style={{ marginTop: 10, marginBottom: 20 }}
            >
              <div className={Styles.modalNotesCol}>
                <p className={Styles.modalNotesKey}>CPT</p>
                <div className={Styles.modalValueValue}>
                  {/* <InstantSearch
                searchClient={condnClient}
                onSearchStateChange={({ query }) => this.setState({cptquery: query})}
                indexName={'CPT_CODES'}
              >
                  <div style={{position: 'relative', zIndex: 2}}>
                    <SearchBox />
                    { (this.state.cptquery && this.state.cptquery.length !==0) &&
                      <div style={{overflow: 'auto', height: 150, backgroundColor: '#F1F1F1'}}>
                        <RefinementList limit={5} />
                        <Hits hitComponent={(props) => Hit(props, 'cpt')} />
                      </div>
                    }
                  </div>
              </InstantSearch> */}
                  <SearchProvider config={esConfigCPTCodes}>
                    <WithSearch
                      mapContextToProps={({ wasSearched }) => ({
                        wasSearched,
                      })}
                    >
                      {({ wasSearched }) => {
                        // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                        return (
                          <div className="App">
                            <EsSearchBox
                              debounceLength={0}
                              autocompleteResults={{
                                titleField: 'search_term',
                              }}
                              onSelectAutocomplete={(e) => {
                                this.setState(
                                  (prevState) => ({
                                    addCpt: [
                                      ...prevState.addCpt,
                                      {
                                        cpt_code: e.cpt_code.raw,
                                        title: e.description.raw,
                                      },
                                    ],
                                    cptquery: '',
                                  }),
                                  () => saveAppointmentToRedux(),
                                )
                              }}
                              searchAsYouType={true}
                              inputView={({
                                getAutocomplete,
                                getInputProps,
                                getButtonProps,
                              }) => (
                                <>
                                  <div className="sui-search-box__wrapper">
                                    <input
                                      {...getInputProps({
                                        placeholder: 'Search CPT codes',
                                      })}
                                    />
                                    {getAutocomplete()}
                                  </div>
                                  {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                                </>
                              )}
                            />
                          </div>
                        )
                      }}
                    </WithSearch>
                  </SearchProvider>
                </div>
              </div>
              {this.state.addCpt && this.state.addCpt.length !== 0 && (
                <div className={Styles.modalNotesCol}>
                  <p className={Styles.modalNotesKey}>Selected CPT</p>
                  <div
                    className={Styles.modalValueValue}
                    style={{ overflow: 'auto', width: '100%' }}
                  >
                    {this.state.addCpt.map((cpt, index) => (
                      <div
                        className={Styles.modalNotesRow}
                        style={{
                          justifyContent: 'space-between',
                          borderTop: '1px solid #D3D3D3',
                        }}
                      >
                        <p>
                          {index + 1}. {cpt.cpt_code}: {cpt.title}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() => {
                            let arr = this.state.addCpt.filter(
                              (data) => data.cpt_code !== cpt.cpt_code,
                            )
                            this.setState({ addCpt: arr }, () =>
                              saveAppointmentToRedux(),
                            )
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )
        }
        {!isHealthCoach && renderLabsErxOrders()}
        {
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              marginBottom: 20,
            }}
          >
            <Button
              onClick={() => noteSubmit(isHealthCoach, 'saved')}
              className={Styles.saveButton}
              style={{ width: 140 }}
              variant="primary"
            >
              Save
            </Button>
            <Button
              onClick={() => noteSubmit(isHealthCoach, 'completed')}
              className={GlobalStyles.button}
              style={{ width: 140 }}
              variant="primary"
            >
              Complete note
            </Button>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = { setAppointmentNotesData, fetchPatientCarePlanData }

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal)
