import React, { useState, useEffect } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import values from '../../values'
import Constants from '../../values'
import Select from 'react-select'
import DatePicker from '../../components/DatePicker'

import Button from 'react-bootstrap/Button'
import FancyField from 'react-fancy-field'
import AilaLogo from '../../assets/images/aila_logo.png'
import { useHistory, useLocation } from 'react-router-dom'
import Config from '../../config'
import { firebase, analytics } from '../../services/firebase'
import moment from 'moment'
import * as metadata from '../../metadata'

import { useSelector, useDispatch } from 'react-redux'
import {
  addAuthToken,
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  fetchPatientCoreDate,
} from '../../redux/actions'
import OnboardingFooter from '../../components/OnboardingFooter'

const axios = require('axios')

export default function AilaInputPatientDetails(props) {
  const token = useSelector((state) => state.authReducer.token)
  const patient = useSelector((state) => state.patientReducer.patient)

  //ADDRESS OF THE PATIENT
  const [fname, setFname] = useState('')
  const [lname, setLname] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState(null)
  const [addressComponents, setAddressComponents] = useState(null)
  const [postalCode, setpostalCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [locationSearch, setLocationSearch] = useState([null])
  const [gender, setGender] = useState('')
  const [languages, setLanguages] = useState('')
  const [dob, setDob] = useState(new Date())
  const [height, setHeight] = useState('')
  const [heightinch, setHeightinch] = useState()
  const [weight, setWeight] = useState('')
  const [error, setError] = useState(false)
  const [errorString, setErrorString] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!patient) {
      history.push('/signup/patient')
    }
  }, [])

  const storeUserFirebaseID = (firebaseID, token) => {
    var userInfoObject = {
      firebase_id: firebaseID,
      gender: gender.label,
      dob: dob,
      height:
        height && height > 0 ? Number(height) * 12 + Number(heightinch) : 0,
      weight: weight && weight.length !== 0 ? Math.round(Number(weight)) : 0,
      language: languages.label,
      weight_unit: 'lbs',
      height_unit: 'feet',
    }

    let ccfmDeeplink = localStorage.getItem('ccfm')
    let dacPilotDeeplink = localStorage.getItem('dac')
    let raPilotDeeplink = localStorage.getItem('rapilot')

    if (ccfmDeeplink === 'yes' || dacPilotDeeplink === 'yes') {
      userInfoObject.coaching = {
        active: true,
        start: moment().format('MM/DD/YYYY'),
        plan: metadata.subscriptionPlans.PREMIUM,
      }
    }
    if (raPilotDeeplink === 'yes') {
      userInfoObject.coaching = {
        active: true,
        start: moment().format('MM/DD/YYYY'),
        plan: metadata.subscriptionPlans.MEMBERSHIP,
      }
    }

    let temp = { ...userInfoObject }
    delete temp.firebase_id

    let config = {
      method: 'put',
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: firebaseID,
      },
      url: `${Config.BACKEND_URL}users`,
      data: temp,
    }
    axios(config)
      .then(() => {
        console.log('saved to mongo ')
        dispatch(fetchPatientCoreDate(firebaseID, token))
        console.log('saved')

        // history.push('/wix/aila/appointment-scheduler')
        history.push('/wix/aila/program', {
          programType: props?.location?.stateResidence,
        })
      })
      .catch((err) => {
        console.log('error when saving data', err)
      })
  }

  const storeUsertoMongo = (firebaseID, token, toSave, ifNew = false) => {
    // let data = {
    //   firebase_id: firebaseID,
    // }
    let config = {
      method: ifNew ? 'post' : 'put',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: firebaseID },
      url: `${Config.BACKEND_URL}users`,
      data: toSave,
    }
    axios(config)
      .then((resp) => {
        console.log('saved mongo data')
      })
      .catch((err) => {
        console.log('error when saving data', err)
      })
  }

  // Save details to firebase and to Mongo
  const saveDetailsToFirebase = (user_uid, token) => {
    let ccfmDeeplink = localStorage.getItem('ccfm')
    let dacPilotDeeplink = localStorage.getItem('dac')
    let raPilotDeeplink = localStorage.getItem('rapilot')

    // TODO: update this to snake case
    let toSaveObject = {
      email: patient.email,
      // firstName: fname.trim(),
      // lastName: lname.trim(),
      phoneNumber: patient?.phoneNumber ? patient?.phoneNumber : phoneNumber,
      // phone_number: patient?.phoneNumber ? patient?.phoneNumber : phoneNumber,
      zipcode:
        postalCode && postalCode.length > 0 ? Number(postalCode.trim()) : '',
      // zipcode:
      // postalCode && postalCode.length > 0 ? Number(postalCode.trim()) : '',
      streetAddress: address,
      // street_address: address,
      streetAddress2: address2 && address2.trim(),
      // street_address2: address2 && address2.trim(),
      addressComponents: addressComponents,
      // address_components: addressComponents,
      uid: user_uid,
      lastSignedIn: new Date(),
      // last_signed_in: new Date(),
      platform: 'web',
      signup_platform: 'web',
    }

    if (ccfmDeeplink === 'yes') {
      toSaveObject.ccfm = true
      toSaveObject.userType = 'ccfm-patient'
    }

    if (dacPilotDeeplink === 'yes') {
      toSaveObject.dac = true
      toSaveObject.userType = 'dac-patient'
    }

    if (raPilotDeeplink === 'yes') {
      toSaveObject.rapilot = true
      toSaveObject.userType = 'ra-pilot-patient'
    }

    firebase
      .firestore()
      .collection('users')
      .doc(user_uid)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          //user data is already present in firestore, do not save again
          firebase
            .firestore()
            .collection('users')
            .doc(user_uid)
            .update(toSaveObject)
            .then(() => {
              console.log('updated user last signed in ')
              dispatch(addPatient(toSaveObject, 'gray'))
              dispatch(addFirebaseUser(toSaveObject))
              dispatch(addFirebaseAuthUser(toSaveObject.uid))
              storeUserFirebaseID(user_uid, token)
              let temp = {
                ...toSaveObject,
              }
              temp.phone_number = temp.phoneNumber
              temp.street_address = temp.streetAddress
              temp.street_address2 = temp.streetAddress2
              temp.address_components = temp.addressComponents
              temp.last_signed_in = temp.lastSignedIn
              delete temp.phoneNumber
              delete temp.streetAddress
              delete temp.streetAddress2
              delete temp.addressComponents
              delete temp.lastSignedIn
              storeUsertoMongo(user_uid, token, temp)
            })
            .catch((err) =>
              console.log('error when updating user last signed in', err),
            )
          // return
        } else {
          firebase
            .firestore()
            .collection('users')
            .doc(user_uid)
            .set(toSaveObject)
            .then(() => {
              console.log('Saved user to firestore', toSaveObject)
              dispatch(addPatient(toSaveObject, 'gray'))
              dispatch(addFirebaseUser(toSaveObject))
              dispatch(addFirebaseAuthUser(toSaveObject.uid))
              storeUserFirebaseID(user_uid, token)
              let temp = {
                ...toSaveObject,
              }
              temp.phone_number = temp.phoneNumber
              temp.street_address = temp.streetAddress
              temp.street_address2 = temp.streetAddress2
              temp.address_components = temp.addressComponents
              temp.last_signed_in = temp.lastSignedIn
              delete temp.phoneNumber
              delete temp.streetAddress
              delete temp.streetAddress2
              delete temp.addressComponents
              delete temp.lastSignedIn
              storeUsertoMongo(user_uid, token, temp, true)
            })
            .catch((err) => {
              console.log(
                'something went wrong when saving user to firestore',
                err,
              )
            })
        }
      })
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const onSubmitPressed = async () => {
    // if (!fname || fname.length === 0) {
    //   setError(true)
    //   setErrorString('Please enter your first name')
    //   console.log('FNAME ERROR')
    //   return
    // }
    // if (!lname || lname.length === 0) {
    //   setError(true)
    //   setErrorString('Please enter your last name')
    //   console.log('LNAME ERROR')
    //   return
    // }

    if (!dob) {
      setError(true)
      setErrorString('Please enter your date of birth ')
      console.log('DOB ERROR')
      return
    }

    if (dob) {
      let now = moment()
      let dobMoment = moment(dob)
      if (now.diff(dobMoment, 'years') < 18) {
        setError(true)
        setErrorString(
          'We are not able to complete your booking request at this time. We are currently only accepting patients over 18 years old.',
        )
        console.log('AGE ERROR')
        return
      }
    }

    try {
      saveDetailsToFirebase(patient.uid, token)
    } catch (err) {
      setError(true)
      setErrorString(err.message)
      console.log(err)
      onFinishFailed(err.message)
    }
  }

  return (
    <div
      className={GlobalStyles.container}
      style={{
        overflow: 'hidden',
        margin: '0 auto',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',

          alignItems: 'center',
          marginTop: 50,
        }}
      >
        <img
          alt="Aila Health"
          src={AilaLogo}
          style={{
            width: '15vh',
            height: '15vh',
            alignSelf: 'flex-start',
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <div
            style={{
              width: '100%',
              alignSelf: 'center',
              marginTop: '5vh',
            }}
          >
            <p
              style={{
                fontSize: '30px',
                margin: 'auto',
                color: values.primaryThemeDark,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {/* Now lets get a few more details to personalize your care */}
              Now lets get a few more details to finalize your booking
            </p>
          </div>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '8vh',
              justifyContent: 'center',
            }}
          >
            <div style={{ width: '50%', margin: '20px' }}>
              <FancyField
                value={fname}
                label="First Name"
                name="fnameInput"
                onChange={(val) => setFname(val)}
                placeholder="First Name"
              />
            </div>

            <div style={{ width: '50%', margin: '20px' }}>
              <FancyField
                value={lname}
                label="Last Name"
                name="lnameInput"
                onChange={(val) => setLname(val)}
                placeholder="Last Name"
              />
            </div>
          </div> */}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '4vh',
              justifyContent: 'center',
            }}
          >
            <div style={{ width: '50%', margin: '20px' }}>
              <GooglePlacesAutocomplete
                apiKey={Config.GOOGLE_API_KEY}
                selectProps={{
                  placeholder: 'Search Address',
                  value: locationSearch,
                  onChange: (picked = null) => {
                    setLocationSearch(picked)

                    //get ADDRESS COMPONENT
                    geocodeByAddress(picked.label)
                      .then((details) => {
                        // 'details' is provided when fetchDetails = true
                        const zip = details[0]?.address_components.find(
                          (addressComponent) =>
                            addressComponent.types.includes('postal_code'),
                        )?.short_name
                        setAddressComponents(
                          details && details[0].address_components,
                        )
                        const address = picked.value.description || ''
                        setAddress(address)

                        setpostalCode(zip)
                        // onGooglePlaceSelected(picked.value, zip)
                      })
                      .catch((error) => console.error(error))
                  },
                }}
              />
            </div>

            <div style={{ width: '50%', margin: '20px' }}>
              <FancyField
                value={address2}
                label="Apt, Suite, Floor (Optional)"
                name="floorInput"
                onChange={(val) => setAddress2(val)}
                placeholder="Apt, Suite, Floor (Optional)"
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '4vh',
              justifyContent: 'left',
            }}
          >
            <div style={{ width: '50%', margin: '20px' }}>
              <FancyField
                value={postalCode}
                label="Zip Code"
                required={false}
                name="zip"
                onChange={(val) => setpostalCode(val)}
                placeholder="Zip Code"
              />
            </div>
          </div>

          <div
            style={{
              display: patient?.phoneNumber ? 'none' : 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '4vh',
              justifyContent: 'left',
            }}
          >
            <div style={{ width: '50%', margin: '20px' }}>
              <FancyField
                value={phoneNumber}
                label="Phone Number"
                required={false}
                name="phone"
                onChange={(val) => setPhoneNumber(val)}
                placeholder="Phone Number"
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '4vh',
              justifyContent: 'center',
            }}
          >
            <div style={{ width: '50%', margin: '20px' }}>
              <Select
                placeholder="Gender at birth "
                inputId="gender"
                options={[
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' },
                ]}
                value={gender}
                autoBlur
                onChange={(val) => setGender(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
            <div style={{ width: '50%', margin: '20px' }}>
              <Select
                placeholder="Primary Language Spoken "
                inputId="languages"
                options={[
                  { label: 'English', value: 'English' },
                  { label: 'Spanish', value: 'Spanish' },
                  { label: 'French', value: 'French' },
                  { label: 'Korean', value: 'Korean' },
                  { label: 'German', value: 'German' },
                  { label: 'Cantonese', value: 'Cantonese' },
                  { label: 'Japanese', value: 'Japanese' },
                ]}
                value={languages}
                isSearchable={false}
                autoBlur
                onChange={(val) => setLanguages(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '4vh',
              justifyContent: 'center',
            }}
          >
            <div style={{ width: '50%', margin: '20px' }}>
              <p
                style={{
                  color: Constants.lightGray,
                }}
              >
                Date of Birth (MM/DD/YY)
              </p>
              <DatePicker
                dateChange={(date) => {
                  setDob(moment.utc(date).format('DD/MM/YYYY'))
                }}
              />
            </div>

            <div style={{ width: '50%', margin: '20px' }}>
              <p
                style={{
                  color: Constants.lightGray,
                }}
              >
                Height
              </p>
              <input
                placeholder={'Feet'}
                type="number"
                id="height"
                name="height"
                min="1"
                max="10"
                value={height}
                onChange={(e) => {
                  let text = e.target.value
                  setHeight(text)
                }}
                style={{ width: 'inherit' }}
              />

              <input
                placeholder={'inches'}
                type="number"
                id="heightinch"
                name="heightinch"
                min="0"
                value={heightinch}
                onChange={(e) => {
                  let text = e.target.value
                  setHeightinch(text)
                }}
                style={{ width: 'inherit' }}
              />

              <p
                style={{
                  color: Constants.lightGray,
                }}
              >
                Weight
              </p>
              <input
                placeholder={'Pounds'}
                type="number"
                id="weight"
                name="weight"
                min="1"
                value={weight}
                onChange={(e) => {
                  let text = e.target.value
                  setWeight(text)
                }}
              />
            </div>
          </div>

          <p
            style={{
              marginTop: 20,
              fontSize: 12,
              color: 'red',
              visibility: error ? 'visible' : 'hidden',
              textAlign: 'center',
            }}
          >
            {errorString}
          </p>

          <Button
            onClick={() => {
              onSubmitPressed()
              analytics.logEvent('Web_Signup_Complete')
            }}
            className={GlobalStyles.button}
            style={{ marginTop: '8vh', width: 200, alignSelf: 'center' }}
            variant="primary"
          >
            {'Continue'}
          </Button>
          <OnboardingFooter />
        </div>
      </div>
    </div>
  )
}
