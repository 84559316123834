import React from "react";
import Styles from "./styles/styles.module.scss";
import AilaLogo from '../assets/images/aila_logo.png'

const Header = (props) => {
  return (
    <div className={Styles.patientInfoStripWrapper} style={{height: 100, paddingLeft: 40, backgroundColor: 'white'}}>
      <h3 style={{alignSelf: 'flex-end'}}>{props.header}</h3>

      <div style={{flexGrow: 1}}>
        <img src={AilaLogo} style={{height: 80, width: 80, float: 'right'}}/>
      </div>
    </div>
  )
}

export default Header
