import React, {Component, useState} from 'react'
import Select from 'react-select'
import {Collapse} from 'react-collapse';
import cookie from 'react-cookies'
import moment from 'moment'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat, faWeight, faFilter, faBed, faRunning, faFlask, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/Button";
import Header from '../../components/Header'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Dashboard.module.scss'
import {ReferenceLine, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import Helpers from "../../helpers/Global";
import {addFilledData, stopTimer, addSparseData, updateSideNavBar} from "../../redux/actions";
import {connect} from "react-redux";
import ReactTooltip from "react-tooltip";
import Config from "../../config";
import {ToastContainer, toast} from "react-toastify";
import styles from "../Admin/styles/admin.module.scss";

const axios = require('axios');
let windowHeight = window.innerHeight
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)'
  },
  content : {
    width: '60%',
    height: (windowHeight/1.2),
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

const COHORT_OPTIONS = [
  {label: 'Firefighter', value: 'Firefighter'},
  {label: 'RA', value: 'RA'},
  {label: 'Diabetes', value: 'Diabetes'},
  {label: 'COPD', value: 'COPD'},
  {label: 'CHF', value: 'CHF'}
]


const PARTICIPANTS = [
  {label: 'Participant 1', value: 1},
  {label: 'Participant 2', value: 2},
  {label: 'Participant 3', value: 3},
  {label: 'Participant 4', value: 4},
  {label: 'Participant 5', value: 5},
  {label: 'Participant 6', value: 6},
  {label: 'Participant 7', value: 7},
  {label: 'Participant 8', value: 8},
  {label: 'Participant 9', value: 9},
  {label: 'Participant 10', value: 10},
  {label: 'Participant 11', value: 11},
  {label: 'Participant 12', value: 12},
  {label: 'Participant 13', value: 13},
]

const SURVEY_QUESTIONS = [
  {label: 'Intensity of occupational demand', value: '1'},
  {label: 'Intensity of physical training', value: '2'},
  {label: 'Intensity of your recreational activity', value: '3'},
  {label: 'Stress', value: '4'},
  {label: 'Mood', value: '5'},
  {label: 'Pain', value: '6'},
  {label: 'Quality of sleep last night ', value: '7'},
  {label: 'Mental alertness', value: '8'},
  {label: 'Level of focus', value: '9'},
  {label: 'Level of energy', value: '10'},
  {label: 'Level of productivity', value: '11'},
  {label: 'Level of soreness', value: '12'},
  // {label: 'Level of hydration', value: '13'},
  {label: 'Compliance score', value: '14'},
  {label: 'Nutrition Compliance', value: '15'},
  {label: 'Hydration Compliance', value: '16'},
  {label: 'Fitness Program Compliance', value: '17'},
  {label: 'Stress Mastery and Breathing Program Compliance', value: '18'},
  {label: 'Sleep Compliance', value: '19'},
  {label: 'Metabolic Challenge, Fasting', value: 20}
]


const BIO_MARKER_OPTIONS = [
  {
    label: "Blood Sugars",
    options: [
      {label: 'Glucose', value: "glucose", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: 'Normal glucose levels are less than 100 mg/dL after not eating (fasting) for at least eight hours and less than 140 mg/dL two hours after eating.'},
      {label: 'HbA1c', value: "hba1c", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: '<5.7% = Normal,  5.7 - 6.5% = Pre-diabetes range, >6.5% = Diabetes range.'},
      {label: 'Insulin', value: "insulin", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: 'Insulin testing is used to assist in identifying causes of hypoglycemia. Autoimmunity to insulin or insulin receptor is associated with elevated insulin levels.'},
      {label: 'Uric Acid', value: "uricAcid", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: 'High uric acid level occurs when kidneys don\'t eliminate uric acid efficiently. Normal Uric acid levels are 2.4-6.0 mg/dL for women and 3.4-7.0 mg/dL for men.'},
    ]
  },
  {
    label: "Inflammation Markers",
    options: [
      {label: 'GGT', value: "ggt", unit: "Milligrams per Decilitre(mg/dL)", up: false, temp: true, desc: 'High levels of GGT in the blood may be a sign of liver disease or damage to the bile ducts. Normal GGT levels range from 8-61 U/L for men and 5-36 U/L for women.'},
      {label: 'CRP-hs', value: "crphs", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: 'hs-CRP indicates risk of heart disease. Less than 2.0 mg/L indicates lower risk of heart heart disease while values over 2.0 mg/L indicate higher risk of heart disease'},
      {label: 'Ferritin', value: "ferritin", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: 'Ferritin shows how much iron your body stores. The normal range for blood ferritin is 24 to 336 μg/L for men and 11 to 307 μg/L for women.'},
    ]
  },
  {
    label: "Cholesterol Markers",
    options: [
      {label: 'LDL Particle', value: "ldlParticleNumber", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: 'High levels of LDL-P are linked to the development of cardiovascular disease. Normal values are less than 1300. Optimal values are less than 1000.'},
      {label: 'Lp(a)-P', value: "lpap", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: 'Normal values are less than 30 mg/dL. There is evidence that the risk of heart disease may start to rise at 30 mg/dl (about 75 nmol/L) and the risk rises more steeply at levels of 50 mg/dL (about 125 nmol/L) and higher.'},
      {label: 'LDL', value: "ldl", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: 'High levels of  LDL (low-density lipoprotein), or “bad cholesterol”, raise the risk for heart disease and stroke. Normal values are below 130 mg/dL.'},
      {label: 'HDL', value: "hdl", unit: "Milligrams per Decilitre(mg/dL)", up: true, desc: 'High levels of HDL (high-density lipoprotein), or “good” cholesterol, can lower the risk for heart disease and stroke. Optimal values are above 45 mg/dL.'},
      {label: 'Triglycerides', value: "triglycerides", unit: "Milligrams per Decilitre(mg/dL)", up: false, desc: 'Triglycerides are fat in the blood. Normal levels are less than 150 mg/dL (or 1.7 mmol/L).'},
    ]
  },
  {
    label: "Vitamins & Minerals",
    options: [
      {label: '25-hydroxy-Vit D', value: "hydroxy", unit: "Milligrams per Decilitre(mg/dL)", up: true, desc: 'Normal levels of Vitamin D range from 50 nmol/L (20 ng/mL) - 125 nmol/L (50 ng/mL) Potential deficiency exists between 30 nmol/L (12 ng/mL) and 50 nmol/L (20 ng/mL). Vitamin D deficiency exists at levels less than 30 nmol/L (12 ng/mL).'},
      {label: 'Vitamin B12', value: "b12", unit: "Milligrams per Decilitre(mg/dL)", up: true, desc: 'B12 is critical in red blood cell production and nervous system function. Normal range is generally between 190 - 950 pg/mL.'}
    ]
  },
]

const TRACKING_OPTIONS = [
  {
    logo: faHeartbeat,
    label: "Cardio",
    options: [
      {label: 'Blood Pressure', value: "Blood Pressure"},
      {label: 'Heart Rate', value: "heartRate", unit: "Beats Per Minute (bpm)", unitShort: "bpm", up: false, desc: 'A normal heart rate for an adult is between 60-100 beats per minute a lower heart rate at rest implies more efficient heart function and better cardiovascular fitness.'},
      {label: 'Heart Rate Variability', value: "heartRateVariability", unit: "Milliseconds (ms)", unitShort: "ms", up: true, desc: 'Heart Rate Variability (HRV) is a measure of the variation in time between each heartbeat. This variation is controlled by the autonomic nervous system (ANS) and can be a marker for resilience and behavioral flexibility.'}
    ]
  },
  {
    label: "Sleep",
    logo: faBed,
    options: [
      {label: 'Total Sleep', value: "totalSleep", unit: 'Hours (h)', unitShort: "h", up: true, desc: 'Total amount of time asleep. The recommended range is 7-9 hours per night for adults.'},
      {label: 'Deep Sleep', value: "deepSleep", unit: 'Hours (h)', unitShort: "h", up: true, desc: 'In healthy adults, about 13 - 23% percent of sleep is deep sleep. So if someone sleeps for 8 hours/ night, that would be 1-1.8 hours/night.'},
      {label: 'Sleep Onset', value: "onsetSleep", unit: 'Minutes (m)', unitShort: "m", up: false, desc: 'Clock time between going to bed and falling asleep'},
      {label: 'Sleep Midpoint', value: "midPointSleep", unit: 'Hours (h)', unitShort: "h", up: false, desc: 'The midpoint of sleep is the clock time between sleep onset and waking up.'},
      {label: 'Time Awake', value: "awakeDuration", unit: 'Minute (m)', unitShort: "m", up: false, desc: 'Time awake is the time spent in bed before falling asleep and includes brief awakenings during the night.'},
      {label: 'Efficiency', value: "sleepEfficiencyScore", unit: 'Efficiency Score', unitShort: "", up: true, desc: 'Score of 85 or higher: Excellent night of sleep. Score of 70-84: Good night of sleep. Score under 70: Pay attention to sleep.'},
    ]
  },
  {
    logo: faRunning,
    label: "Activity",
    options: [
      {label: 'Daily Step Count', value: "steps", unit: "# steps / day", up: true, desc: 'A step count showcases the amount of steps an individual takes in a day.Recommended average is 10,000 steps (About 5 Miles)/day.'},
      {label: 'Exercise', value: "exercise", up: true},
    ]
  },
  {
    logo: faWeight,
    label: "Weight",
    options: [
      {label: 'Weight', value: "weight", unit: 'Pounds (lbs)', unitShort: 'lbs', up: false, desc: 'Maintaining a healthy weight lowers the risk of many chronic conditions, such as heart disease, stroke, diabetes, hypertension, and cancer.'},
      {label: 'Body Fat Percentage', value: "bodyFat", unit: '%', unitShort: '%', up: false, desc: 'Body fat percentage is the percentage of your body that consists of fat. Healthy body fat percentage ranges fall  between 10 – 20% for males and 18 – 28% for females.'},
      {label: 'Fat Body Mass', value: "fatBodyMass", unit: 'Pounds (lbs)', unitShot: 'lbs', up: false, desc: 'Fat body mass includes essential body fat and storage body fat.'},
      {label: 'Lean Body Mass', value: "leanBodyMass", unit: 'Pounds (lbs)', unitShot: 'lbs', up: true, desc: 'Lean Body Mass is the amount of lean tissue (muscles, bones, body water, organs, etc) in the body.'},
    ]
  },
]


const CHART_DATA = [
  {name: 'Date A', s1: 4, s2: 8},
  {name: 'Date B', s1: 7, s2: 9},
  {name: 'Date c', s1: 1, s2: 10},
  {name: 'Date c', s1: 9, s2: 6},
  {name: 'Date c', s1: 5, s2: 6},
  {name: 'Date c', s1: 7, s2: 4},
];

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trackingOptions: [],
      bioMarkerOptions: [],
      timeline: {label: 'Complete History', value: 'complete'},
      graphs: null,
      filtersOpened: false,
      loadingFilledData: true,
      filledData: null,
      sparseData: null,
      selectedCohort: null,
      showIndividualEntries: {},
      checked: false,
      movingAverages: {},
      modalIsOpen: false,
      firstName: '',
      lastName: '',
      email: '',
      accessType: null,
      modalError: false,
      modalErrorString: '',
      loading: false,
      participantMap: null,
      participantMapId: null,
      participantsList: [],
      selectedParticipant: null,
      isMember: true,
      cohortOptions: null,
      hideParticipantDropdown: false,
      clientFName: '',
      clientLName: '',
      clientEmail: '',
      selectedSurveyQuestion: SURVEY_QUESTIONS[0],
      surveyData: null,
      allSurveyData: null,
      surveyDateIndex: 0
    }
    this.sendClientInvite = this.sendClientInvite.bind(this)
    this.getSparseDataFromBackend = this.getSparseDataFromBackend.bind(this)
    this.getFilledDataFromBackend = this.getFilledDataFromBackend.bind(this)
    this.getChartData = this.getChartData.bind(this)
    this.onTimelineChanged = this.onTimelineChanged.bind(this)
    this.getMovingAverageChartDate = this.getMovingAverageChartDate.bind(this)
    this.prepareCohorts = this.prepareCohorts.bind(this)
    this.prepareDefaultOptions = this.prepareDefaultOptions.bind(this)
    this.prepareParticipantsList = this.prepareParticipantsList.bind(this)
    this.getSurveyAnswers = this.getSurveyAnswers.bind(this)
    this.getAllSurveyData = this.getAllSurveyData.bind(this)
  }

  componentDidMount() {
    if(!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    this.props.updateSideNavBar(!this.props.updateRender)
    let {firebaseUser} = this.props
    let {timeline} = this.state
    if(firebaseUser && firebaseUser.userType === 'ccfm-client') {
      //clients only get their cohort view
      this.setState({selectedCohort: {label: firebaseUser.cohort, value: firebaseUser.cohort}})
      this.getFilledDataFromBackend(timeline.value, firebaseUser.cohort)
      this.getSparseDataFromBackend()
      this.getSurveyAnswers(timeline.value, firebaseUser.cohort)
    } else if(firebaseUser && firebaseUser.userType === 'ccfm-patient') {
      this.getFilledDataFromBackend(timeline.value, firebaseUser.cohorts[0], firebaseUser.uid)
      this.setState({
        selectedParticipant: {label: firebaseUser.firstName + ' ' + firebaseUser.lastName, value: firebaseUser.uid},
        selectedCohort: {label: firebaseUser.cohorts[0], value: firebaseUser.cohorts[0]}
      })
      this.getSparseDataFromBackend()
      this.getSurveyAnswers(timeline.value, firebaseUser.cohorts[0], firebaseUser.uid)
    } else {
      this.prepareCohorts()
    }

    this.prepareDefaultOptions()

    // let {userType} = this.props
    // if(userType && userType === 'ccfm-patient') {
    //   this.props.addFilledData(null)
    //   this.setState({loadingFilledData: true})
    //   this.getFilledDataFromBackend("complete", 1)
    // } else {
    //   this.setState({loadingFilledData: true})
    //   this.getFilledDataFromBackend("complete")
    // }

    let {token, adminId} = this.props
    this.props.stopTimer(adminId, token)


    // let trackingOptions = cookie.load('trackingOptions')
    // let bioOptions = cookie.load('bioMarkerOptions')
    // let timeline = cookie.load('timeline')
    //
    // if(trackingOptions && trackingOptions.length > 0) {
    //   this.setState({trackingOptions: trackingOptions, timeline: timeline, checked: true})
    // }
    //
    // if(bioOptions && bioOptions.length > 0) {
    //   this.setState({bioMarkerOptions: bioOptions, timeline: timeline, checked: true})
    // }
  }

  static getDerivedStateFromProps(props, state) {
    let toReturn = {}
    if(props.filledData && props.sparseData) {
      toReturn = {
        loadingFilledData: false,
        filledData: props.filledData,
        sparseData: props.sparseData
      }

      if(props.filledData.participant && props.filledData.participant.length > 0) {
        //participant data is requested. so prepare that to overlay
        let data = props.filledData.participant
        let participantMap = {}
        data.forEach(each => {
          participantMap[each.created_at] = each
          toReturn.participantMapId = each.firebase_id
        })

        toReturn.participantMap = participantMap
      }


      if(props.userType && !(props.userType === 'ccfm-team' || props.userType === 'ccfm-admin')) {
        toReturn.hideParticipantDropdown = true
      }

      return toReturn
    }

    return null
  }

  prepareParticipantsList(selectedCohort) {
    let list = []
    let {patientList} = this.props
    if(!patientList || !selectedCohort)
      return

    Object.keys(patientList).forEach(color => {
      let patients = patientList[color]
      patients.forEach(patient => {
        let patientCohorts = patient.cohorts
        if(patientCohorts.includes(selectedCohort))
          list.push({
            label: patient.firstName + ' ' + patient.lastName,
            value: patient.uid
          })
      })
    })

    this.setState({participantsList: list})
  }

  prepareDefaultOptions() {
    let trackingOptions = []
    TRACKING_OPTIONS.forEach(group => {
      let options = group.options
      trackingOptions = trackingOptions.concat(options)
    })

    let bioMarkerOptions = []
    BIO_MARKER_OPTIONS.forEach(group => {
      let options = group.options
      bioMarkerOptions = bioMarkerOptions.concat(options)
    })
    this.setState({bioMarkerOptions, trackingOptions})

  }

  prepareCohorts() {
    let {mongoUser} = this.props
    let {timeline} = this.state
    if(mongoUser && mongoUser.cohorts) {
      let cohortOptions = []
      mongoUser.cohorts.forEach(each => {
        cohortOptions.push({label: each, value: each})
      })

      this.setState({cohortOptions, selectedCohort: cohortOptions[0]})
      this.prepareParticipantsList(cohortOptions[0] && cohortOptions[0].label)
      this.getFilledDataFromBackend(timeline.value, cohortOptions[0] && cohortOptions[0].label)
      this.getSparseDataFromBackend()
      this.getSurveyAnswers(timeline.value, cohortOptions[0] && cohortOptions[0].label)
      this.getAllSurveyData(timeline.value, cohortOptions[0] && cohortOptions[0].label)
    }
  }

  onTrackingOptionSelected(val) {
    this.setState({
      trackingOptions: val
    })

    // if(this.state.checked) {
    //   cookie.save('trackingOptions', val)
    // }

  }

  onBiomarkerOptionsChanged(val) {
    this.setState({bioMarkerOptions: val})
    // if(this.state.checked) {
    //   cookie.save('bioMarkerOptions', val)
    // }
  }

  onSelectAllClicked(label, bioMarker) {
    let options = []

    let completeOptions = TRACKING_OPTIONS
    if(bioMarker)
      completeOptions = BIO_MARKER_OPTIONS

    completeOptions.forEach(option => {
      if(option.label === label)
        options = option.options
    })

    let temp
    if(bioMarker)
      temp = this.state.bioMarkerOptions || []
    else
      temp = this.state.trackingOptions || []

    let currentOptions = [...temp]
    let currentLabelsObject = {}
    currentOptions.forEach(option => {
      currentLabelsObject[option.label] = true
    })

    options.forEach(option => {
      if(!currentLabelsObject[option.label])
        currentOptions.push(option)
    })

    if(bioMarker) {
      this.setState({
        bioMarkerOptions: currentOptions,
      })
      // if(this.state.checked) {
      //   cookie.save('bioMarkerOptions', currentOptions)
      // }
    } else {
      this.setState({
        trackingOptions: currentOptions,
      })

      // if(this.state.checked) {
      //   cookie.save('trackingOptions', currentOptions)
      // }
    }



  }

  getChartData(option) {
    let {filledData, participantMap} = this.state
    if(!filledData || !filledData.averages)
      return {
        data: []
      }

    let key = option.value

    if(!key)
      return {
        data: []
      }

    let dataObject = filledData.averages[key]
    let toReturn = []
    Object.keys(dataObject).forEach(date => {
      let momentDate = moment(date)
      let avg = dataObject[date].avg
      if(option.label === "Total Sleep" || option.label === 'Sleep Midpoint' || option.label === 'Deep Sleep')
        avg = Number(avg.toFixed(1))
      else
        avg = Math.round(avg)

      let avgData = {
        date: momentDate.format("MMM-D"),
        value: avg,
        actualDate: date
      }

      toReturn.push(avgData)
    })

    toReturn.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.actualDate) - new Date(b.actualDate);
    });

    return toReturn

  }

  onCohortChanged(val) {
    this.setState({ selectedCohort: val, selectedParticipant: null})
    let {selectedCohort, timeline} = this.state
    if(!selectedCohort || selectedCohort.value !== val.value) {
      this.props.addFilledData(null)
      this.setState({loadingFilledData: true, filledData: null, movingAverages: {}})
      this.prepareParticipantsList(val.value) //only show this cohort patients
      this.getFilledDataFromBackend(timeline.value, val.value)
      this.getSurveyAnswers(timeline.value, val.label)
      this.getAllSurveyData(timeline.value, val.label)
    }
  }

  onTimelineChanged(val) {
    this.props.addFilledData(null)
    this.setState({loadingFilledData: true, filledData: null, movingAverages: {}})
    let {selectedParticipant,selectedCohort} = this.state
    if(selectedParticipant) {
      this.getFilledDataFromBackend(val.value, selectedCohort.label, selectedParticipant.value)
      this.getSurveyAnswers(val.value, selectedCohort.label, selectedParticipant.value)
    } else if(this.props.participantId) {
      this.getFilledDataFromBackend(val.value, selectedCohort.label, this.props.participantId)
      this.getSurveyAnswers(val.value, selectedCohort.label, this.props.participantId)
    } else {
      this.getFilledDataFromBackend(val.value, selectedCohort.label)
      this.getSurveyAnswers(val.value, selectedCohort.label)
    }

    this.getAllSurveyData(val.value, selectedCohort.label)

    this.setState({timeline: val})
    // if(this.state.checked) {
    //   cookie.save('timeline', val)
    // }
  }

  getSurveyTableDays() {
    let {surveyDateIndex} = this.state
    let toReturn = []
    let buckets = 7
    let lowerLimit = buckets * surveyDateIndex
    let upperLimit = lowerLimit + 7

    let todayMoment = moment().subtract(lowerLimit, 'days')
    for(let i=lowerLimit; i<upperLimit; i++) {
      toReturn.push(todayMoment.subtract(1, 'days').format('MM/DD/YYYY'))
    }

    return toReturn.reverse()
  }

  getSparseDataFromBackend() {
    let self = this
    let cookieTimeline = cookie.load('timeline')
    let timeline= cookieTimeline ? cookieTimeline.value : 'complete'

    let sparseUrl = Config.BACKEND_URL + 'ccfm/sparse/real'

    axios.get(sparseUrl)
      .then(function (response) {
        // handle success
        if(response.status === 200) {
          let data = response.data
          self.props.addSparseData(data)
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting ccfm sparse data', error)
      })
  }

  getAllSurveyData(timeline, cohort, participant) {
    console.log('getting all survey data', timeline, cohort)
    let {adminId, firebaseUser} = this.props
    let self = this
    let url = Config.BACKEND_URL + 'ccfm/surveys/answers/all?timeline='+timeline
    //get population staticcs data
    axios({
      method: 'get',
      headers:{participant: participant, admin_id: adminId, cohort: cohort},
      url: url,
    }).then(function (response) {
      // handle success
      console.log('got all survey data', response.data)
      if(response.status === 200) {
        let data = response.data
        self.prepareSurveyTableData(data)
      }
    })
      .catch(function (error) {
        // handle error
        console.log('error when getting ccfm data', error)
      })
  }

  prepareSurveyTableData(data) {
    if(!data)
      return

    let toSave = {}
    data.forEach(entry => {
      let firebaseId = entry.firebase_id
      let dateKey = moment(entry.created_at).format('MM/DD/YYYY')
      let userObject = toSave[firebaseId] || {}
      userObject[dateKey] = true
      toSave[firebaseId] = userObject
    })

    this.setState({allSurveyData: toSave})
  }

  getSurveyAnswers(timeline, cohort, participant) {
    console.log('getting survey averages')
    let {adminId, firebaseUser} = this.props
    let self = this
    let url = Config.BACKEND_URL + 'ccfm/surveys/answers?timeline=' + timeline
    //get population staticcs data
    axios({
      method: 'get',
      headers:{participant: participant, admin_id: adminId, cohort: cohort},
      url: url,
    }).then(function (response) {
      // handle success
      if(response.status === 200) {
        let data = response.data
        console.log('got survey averages', data)
        self.setState({surveyData: data })
        if(firebaseUser.userType === 'ccfm-patient') {
          self.checkIfUserTookSurveyForTheDay(data)
        }
      }
    })
      .catch(function (error) {
        // handle error
        console.log('error when getting ccfm data', error)
      })
  }

  checkIfUserTookSurveyForTheDay(data) {
    let participantData = data["participant"]
    let todayDate = moment().utcOffset(0).format('MM/DD/YYYY')
    if(!participantData[todayDate]) {
      //user hasn't taken the survey today.. ask them to take it
      if(window.confirm(`Please take your daily survey`)) {
        this.props.history.push('/wellness/survey')
        this.props.updateSideNavBar(!this.props.updateRender)
      }
    }

  }

  getFilledDataFromBackend(timeline,cohort, participant) {
    let {adminId} = this.props
    let self = this
    let filledUrl = Config.BACKEND_URL + 'ccfm/filled/real?timeline=' + timeline
    //get population staticcs data
    axios({
      method: 'get',
      headers:{participant: participant, admin_id: adminId, cohort: cohort},
      url: filledUrl,
    }).then(function (response) {
        // handle success
        if(response.status === 200) {
          console.log('ot new data', response.data)
          let data = response.data
          self.props.addFilledData(data)
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting ccfm data', error)
      })
  }

  renderTableRows(data, option) {
    let toReturn = []
    let start
    let end
    let change
    Object.keys(data).forEach(id => {
      start = data[id].start
      end = data[id].end
      change = (typeof end === 'number' && typeof start === 'number') ? ((end - start)/start) * 100 : 'N/A'
      let color
      if(change > 0)
        color = option.up ? 'green' : 'red'
      else
        color = option.up ? 'red' : 'green'

      toReturn.push(
        <tr key={id}>
          <td style={{color: '#066D6F'}}>{id}</td>
          <td>{start.toFixed(1)}</td>
          <td>{end.toFixed(1)}</td>
          {
            typeof change === 'number' ?
              change > 0 ?
                <td style={{color: color}}>{change.toFixed(1)}% &uarr;</td>
                :
                <td style={{color: color}}>{change.toFixed(1)}% &darr;</td>
              :
              <td>N/A</td>
          }


        </tr>
      )

    })

    return toReturn

  }

  renderTable(option) {
    let data = this.state.sparseData[option.value]
    let avg = data.avg
    let startAvg = avg && avg.length > 0 ? avg[0].avg : 'N/A'
    let endAvg = avg && avg.length > 0 ? avg[1].avg : 'N/A'
    let change = (typeof startAvg === 'number' && typeof endAvg === 'number') ? ((endAvg - startAvg) / startAvg) * 100 : "N/A"
    let participantsData = data.participants
    let color
    if(change > 0)
      color = option.up ? 'green' : 'red'
    else
      color = option.up ? 'red' : 'green'

    let showParticipants = this.state.showIndividualEntries[option.label]
    return (
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h5
          data-tip
          data-for={option.label}
          style={{marginBottom: 20, color: '#066D6F', marginTop: 10}}>{option.label} - {option.unit}</h5>
        {this.renderToolTip(option, typeof change === 'number' && change.toFixed(1), typeof startAvg === 'number' && startAvg.toFixed(1), typeof endAvg === 'number' && endAvg.toFixed(1), color)}
        <table style={{width: '90%'}}>
          <thead>
            <tr>
              <th></th>
              <th>Start</th>
              <th>End</th>
              <th>Change</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{color: '#066D6F'}}>Total Average</td>
              <td>{typeof startAvg === 'number' && startAvg.toFixed(1)}</td>
              <td>{typeof endAvg === 'number' && endAvg.toFixed(1)}</td>
              {
                typeof change === 'number' ?
                  change > 0 ?
                    <td style={{color: color, fontWeight: 'bold', fontSize: 16}}>{change.toFixed(1)}% &uarr;</td>
                    :
                    <td style={{color: color, fontWeight: 'bold', fontSize: 16}}>{change.toFixed(1)}% &darr;</td>
                  :
                  <td>N/A</td>
              }
            </tr>
          </tbody>
          {
            showParticipants &&
            <tbody>
            {this.renderTableRows(participantsData, option)}
            </tbody>
          }
        </table>

        <div
          onClick={() => {
            let current = Object.assign({}, this.state.showIndividualEntries)
            current[option.label] = !current[option.label]
            this.setState({showIndividualEntries: current})
          }}
          style={{textAlign: 'center', marginTop: 6, cursor: 'pointer'}}>

          {
            data && data['participants'] &&
            (showParticipants ? <p style={{fontSize: 14}}>Hide Participants</p> : <p style={{fontSize: 14}}>Show Participants</p>)
          }
        </div>
      </div>
    )
  }

  getRowGraphs(label) {
    let index = -1
    switch(label) {
      case 'Cardio':
        index = 0
        break
      case 'Sleep':
        index = 1
        break
      case 'Activity':
        index = 2
        break
      case 'Weight':
        index = 3
        break
      default:
    }

    let graphs = []
    let trackingOptions = this.state.trackingOptions
    if(trackingOptions) {
      let options = TRACKING_OPTIONS[index].options
      let temp = {}
      options.forEach(option => {
        temp[option.label] = true
      })


      if(label === 'Weight') {
        trackingOptions.forEach(option => {
          if(temp[option.label] && this.state.sparseData && this.state.sparseData[option.value]) {
            graphs.push(
              <div key={option.label} className={Styles.tableWrapper}>
                {this.renderTable(option)}
              </div>
            )
          }
        })
      } else {
        //render graphs
        trackingOptions.forEach(option => {
          if(temp[option.label] && this.state.filledData && this.state.filledData.averages[option.value]) {
            graphs.push(
              <div key={option.label} className={Styles.graphWrapper}>
                {this.renderChart(option)}
              </div>
            )
          }

        })
      }


    }

    return graphs

  }

  renderCustomAxisTick(x, y, payload, label) {
    return (
      <div>same</div>
      );
  }

  renderCustomReferenceLabel() {
    return (
      <p style={{color: 'red'}}>Average</p>
    )
  }

  renderToolTip(option, change, start, end, color) {
    return (
      <ReactTooltip id={option.label}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}>
        <div style={{padding: '10px 5px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          <h6 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{option.label}</h6>
          <p style={{textAlign: 'center'}}>{option.desc}</p>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: 4}}>
            <p style={{fontWeight: 'bold', width: '50%', textAlign: 'center'}}>Starting Average</p>
            <p style={{textAlign: 'center', width: '50%'}}>{start}{option.unitShort}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <p style={{fontWeight: 'bold', width: '50%', textAlign: 'center'}}>Ending Average</p>
            <p style={{textAlign: 'center', width: '50%'}}>{end}{option.unitShort}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <p style={{fontWeight: 'bold', width: '50%', textAlign: 'center'}}>Goal</p>
            <p style={{textAlign: 'center', width: '50%'}}>{option.up ? 'Increase' : 'Decrease'}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <p style={{fontWeight: 'bold', width: '50%', textAlign: 'center'}}>% change</p>
            {
              change >= 0 ?
                <p style={{color: color, textAlign: 'center', fontWeight: 'bold', width: '50%' }}>{change}% &uarr;</p>
                :
                <p style={{color: color, textAlign: 'center', fontWeight: 'bold', width: '50%'}}>{change}% &darr;</p>
            }
          </div>
        </div>
      </ReactTooltip>
    )
  }

  getMovingAverageChartDate(option, data) {
    let {participantMap} = this.state
    let movingAverages = this.state.movingAverages[option.label]
    let delta
    switch(movingAverages) {
      case 1:
        delta = 1
        break
      case 2:
        delta = 3
        break
      case 3:
        delta = 7
        break
      default :
        delta = 3
    }

    if(delta === 1) {
      if(participantMap) {
        //if participant data is there, overlay it on the average
        data.forEach(entry => {
          let date = entry.actualDate
          let participantDataForDate = participantMap[date]
          if(participantDataForDate && participantDataForDate[option.value]) {
            //if the entry exists for that date, include it to the average object
            entry["participantData"] = this.getAverageRounded(option , participantDataForDate[option.value])
          }
        })
      }

      return data
    }


    let newToReturn = []

    let lastIndex = data.length - 1
    let total = data[lastIndex].value
    let participantTotal = 0
    let participantDenom = 0
    let lastDate = data[lastIndex].actualDate
    let participantLastEntryDate
    if(participantMap) {
      participantTotal = participantMap[lastDate] && participantMap[lastDate][option.value] ? participantMap[lastDate][option.value] : 0
      participantDenom = participantMap[lastDate] && participantMap[lastDate][option.value] ? 1 : 0
    }

    let denom = 1
    let avg
    let participantAvg = -1


    for(let i=lastIndex-1; i >= 0; i--) {
      let currentEntry = data[i]
      let date = currentEntry.actualDate
      let dateDiff = moment(data[lastIndex].actualDate).diff(moment(date), "days")

      if(dateDiff < delta) {
        total += currentEntry.value
        denom += 1
        if(participantMap) {
          let participantDataForCurrentDate = participantMap[date]
          if(participantDataForCurrentDate && participantDataForCurrentDate[option.value]) {
            //if the current vital is present for the participant on that date, add it to average
            participantTotal += participantDataForCurrentDate[option.value]
            participantDenom += 1
          }
        }
      } else {
        avg = total/denom
        let toPutDate = moment(data[lastIndex].actualDate)
        if(participantMap) {
          participantAvg = participantDenom > 0 ? participantTotal/participantDenom : -1
          let participantDataForLastEntryDate = participantMap[data[lastIndex].actualDate]
          if(participantDataForLastEntryDate && participantDataForLastEntryDate[option.value]) {
            //subtract if the last date of the current averages loop had a entry for patient as well
            participantTotal = participantTotal - participantDataForLastEntryDate[option.value]
            participantDenom -= 1
          }

          let participantDataForCurrentDate = participantMap[date]
          if(participantDataForCurrentDate && participantDataForCurrentDate[option.value]) {
            //if the current vital is present for the participant on that date, add it to average
            participantTotal +=  participantDataForCurrentDate[option.value]
            participantDenom += 1
          }


        }


        newToReturn.push({
          date: toPutDate.format("MMM-D"),
          value: this.getAverageRounded(option, avg),
          actualDate: toPutDate.toDate(),
          participantData: this.getAverageRounded(option, participantAvg)
        })
        total = total - data[lastIndex].value //remove the last entry and add te current entry for new average
        total = total + currentEntry.value
        lastIndex = lastIndex - 1
      }
    }

    newToReturn.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.actualDate) - new Date(b.actualDate);
    });


    return newToReturn


  }

  getAverageRounded(option, avg) {
    if(avg === -1)
      return null
    let newAvg
    if(option.label === "Total Sleep" || option.label === 'Sleep Midpoint' || option.label === 'Deep Sleep')
      newAvg = Number(avg.toFixed(1))
    else
      newAvg = Math.round(avg)

    return newAvg
  }

  getParticipantName() {
    if(this.state.selectedParticipant) {
      return this.state.selectedParticipant.label
    } else {
      return 'Participant ' + this.props.participantId
    }
  }

  renderChart(option) {
    let chartData = this.getChartData(option)
    let start = chartData[0].value
    let end = chartData[chartData.length - 1].value
    let change = ((end - start)/start) * 100
    let color
    if(change > 0)
      color = option.up ? 'green' : 'red'
    else
      color = option.up ? 'red' : 'green'

    let total = 0
    let count = 0
    chartData.forEach(each => {
      total += each.value
      count += 1
    })

    let avg = count > 0 ? (total / count) : 0.0

    if(option.label === "Total Sleep" || option.label === 'Sleep Midpoint' || option.label === 'Deep Sleep')
      avg = Number(avg.toFixed(1))
    else
      avg = Math.round(avg)

    chartData = this.getMovingAverageChartDate(option, chartData)

    let width = window.innerWidth - 270 //270 is the side navigation bar width
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <h5
          data-tip
          data-for={option.label}
          style={{color: '#066D6F', marginTop: 10, textAlign: 'center'}}>{`${option.label} - ${option.unit}`}</h5>
        {this.renderToolTip(option, Math.round(change), start, end, color)}


        <LineChart width={(width/2) - 100} height={300} data={chartData} margin={{ top: 5, right: 40, bottom: 5, left: 0 }}>
          <Line strokeWidth={2} dot={false} name={"Cohort Average"} type="monotone" dataKey="value" stroke={'#808080'} />
          {
            this.state.selectedParticipant && this.state.participantMap && this.state.selectedParticipant.value === this.state.participantMapId &&
            <Line strokeWidth={2} dot={false} name={this.getParticipantName()} type="monotone" dataKey="participantData" stroke={Constants.primaryTheme} />
          }
          <XAxis padding={{ top: 20 }} dataKey="date" type={"category"}/>
          <YAxis />
          <ReferenceLine y={avg} stroke="#a0a0a0" />
          <Tooltip />
          <Legend verticalAlign={"top"} align={'center'} height={40} wrapperStyle={{left: 40}}/>
        </LineChart>

        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10}}>
          <p style={{marginRight: 20, fontSize: 13}}>Simple Moving Average(SMA)</p>
          <MovingAveragesSelector onTabSelected={(index) => {
            let movingAverages = this.state.movingAverages
            movingAverages[option.label] = index
            this.setState({movingAverages})
          }}/>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div className={Styles.changeWrapper}>
            <h6 style={{color: '#808080'}}>Start Average: {start}{option.unitShort}</h6>
            <h6 style={{color: '#808080'}}>End Average: {end}{option.unitShort}</h6>
          </div>

          {
            change >= 0 ?
              <p style={{color: color, textAlign: 'center', position: 'relative', bottom: 4, fontWeight: 'bold', fontSize: 18}}>{Math.round(change)}% &uarr;</p>
              :
              <p style={{color: color, textAlign: 'center', position: 'relative', bottom: 4, fontWeight: 'bold', fontSize: 18}}>{Math.round(change)}% &darr;</p>
          }
        </div>
      </div>
    )

  }

  formatGroupLabel(data, bioMarker) {
    return (
      <div style={{height: 40, minWidth: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{fontSize: 18, marginRight: 30, color: "#066D6F"}}>{data.label}</p>
        <button
          onClick={() => this.onSelectAllClicked(data.label, bioMarker)}
          style={{padding: '4px 10px', border: '1px solid #d0d0d0', borderRadius: 6}}>
          <p style={{color: 'gray', marginRight: 5}}>Select All</p>
        </button>
      </div>
    )
  }

  renderRow(sectionLabel, logo) {
    let graphs = this.getRowGraphs(sectionLabel)

    return (
      <div key={sectionLabel} className={Styles.sectionDivider}>
        <div className={Styles.rowIcon}>
          <FontAwesomeIcon icon={logo} style={{color: 'white', fontSize: 40}}/>
          <p className={Styles.rowIconText}>{sectionLabel}</p>
        </div>

        <div className={Styles.graphRow}>
          {graphs}
        </div>
      </div>
    )
  }

  renderGraphRows() {
    let toReturn = []
    TRACKING_OPTIONS.forEach(section => {
      toReturn.push(this.renderRow(section.label, section.logo))
    })

    return toReturn

  }

  renderBiomarkerTableRows(options) {
    let sparseData = this.state.sparseData
    let currentSelectedOptions = this.state.bioMarkerOptions
    if(!currentSelectedOptions || currentSelectedOptions.length === 0)
      return

    let temp = {}
    currentSelectedOptions.forEach(userOption => {
      temp[userOption.label] = true
    })

    let toReturn = []
    if(currentSelectedOptions && currentSelectedOptions.length > 0) {

      options.forEach(test => {
        if(temp[test.label]) {
          let data = sparseData[test.value]
          let start = data && data.avg && Number(data.avg[0].avg.toFixed(2))
          let end = data && data.avg && Number(data.avg[1].avg.toFixed(2))
          let change = (typeof start === 'number' && typeof end === 'number') && Math.round(((end - start)/start) * 100)
          let color
          if(change > 0)
            color = test.up ? 'green' : 'red'
          else
            color = test.up ? 'red' : 'green'


          toReturn.push(
            <tr key={test.label}>
              <td
                data-tip
                data-for={test.label}
                style={{color: '#066D6F'}}>{test.label}
                {this.renderToolTip(test, change,start,end,color)}
              </td>
              <td>{start}</td>
              <td>{end}</td>
              {
                typeof change === 'number' ?
                  change > 0 ?
                    <td style={{color: color, fontWeight: 'bold', fontSize: 16}}>{change}% &uarr;</td>
                    :
                    <td style={{color: color, fontWeight: 'bold', fontSize: 16}}>{change}% &darr;</td>
                  :
                  <td>N/A</td>
              }
            </tr>
          )

        }
      })

    }

    return toReturn
  }

  renderBiomarkerTable(label, options) {
    return (
      <div key={label} className={Styles.tableWrapper}>
        <h5 style={{color: '#066D6F', marginTop: 10}}>{label}</h5>
        <table style={{width: '90%', marginTop: 20}}>
          <thead>
          <tr>
            <th>Test</th>
            <th>Start</th>
            <th>End</th>
            <th>Change</th>
          </tr>
          </thead>
          <tbody>
          {this.renderBiomarkerTableRows(options)}
          </tbody>
        </table>
      </div>
    )
  }

  renderBiomarkerTables() {
    let toReturn = []
    BIO_MARKER_OPTIONS.forEach(table => {
      toReturn.push(this.renderBiomarkerTable(table.label, table.options))
    })
    return toReturn
  }

  getSurveyChartData(data) {
    let {selectedSurveyQuestion, selectedParticipant} = this.state
    let surveyQuestionNumber = selectedSurveyQuestion.value
    let surveyAveragesForQuestion = data.averages[surveyQuestionNumber]
    if(!surveyAveragesForQuestion)
      return []

    let toReturn = Object.keys(surveyAveragesForQuestion).map(x => {
      let avgObject = surveyAveragesForQuestion[x]
      return {
        value: avgObject.avg,
        count: avgObject.count,
        date: x
      }
    })

    toReturn.sort((a,b) => {
      let aDate = moment(a.date, "MM/DD/YYYY")
      let bDate = moment(b.date, "MM/DD/YYYY")
      return aDate.toDate() - bDate.toDate()
    })

    if(selectedParticipant && data.participant !== null && Object.keys(data.participant).length > 0) {
      //particopant data should be overlaid
      let participantData = data.participant
      toReturn.forEach(x => {
        let date = x.date
        x.participant = participantData && participantData[date] ? participantData[date][surveyQuestionNumber] : 0
      })
    }

    return toReturn
  }

  renderSurveyGraph() {
    let {surveyData} = this.state
    if(!surveyData)
      return null

    let chartData = this.getSurveyChartData(surveyData)

    return (
      <div className={Styles.graphWrapper} style={{width: 1000, margin: '0 auto'}}>
        <LineChart width={900} height={400} data={chartData} margin={{ top: 5, right: 40, bottom: 5, left: 0 }}>
          <Line strokeWidth={2} dot={false} name={"Cohort Average"} type="monotone" dataKey="value" stroke={'#808080'} />
          {
            this.state.selectedParticipant &&
            <Line strokeWidth={2} dot={false} name={this.getParticipantName()} type="monotone" dataKey="participant" stroke={Constants.primaryTheme} />
          }
          <XAxis padding={{ top: 20 }} dataKey="date" type={"category"} tickFormatter={(tickItem) => {
            return moment(tickItem, "MM/DD/YYYY").format('MMM-DD')
          }}/>
          <YAxis domain={[0,10]}/>
          <Tooltip />
          <Legend verticalAlign={"top"} align={'center'} height={40} wrapperStyle={{left: 40}}/>
        </LineChart>
      </div>
    )
  }

  renderSurveyTable() {
    let dates = this.getSurveyTableDays()
    let {allSurveyData} = this.state
    if(!allSurveyData)
      return null

    let participantsList = this.state.participantsList || []
    let header = dates.map(x => <th>{x}</th>)
    header.unshift(<th>Participant</th>)

    let rows = participantsList.map(participant => {
      let uid = participant.value
      let name = participant.label
      let columns = []
      columns.push(<td>{name}</td>)
      dates.forEach(date => {
        if(allSurveyData[uid] && allSurveyData[uid][date])
          columns.push(<td>{`\u2713`}</td>)
        else
          columns.push(<td></td>)
      })

      return (
        <tr key={name}>
          {columns}
        </tr>
      )
    })

    return (
      <div className={Styles.graphWrapper} style={{width: 1000, marginTop: 100}}>
        <h5 style={{color: '#066D6F', marginTop: 10}}>Completed Daily Survey</h5>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 20}}>
          <p
            onClick={() => {
              let currentIndex = this.state.surveyDateIndex
              if(currentIndex !== 10)
                this.setState({surveyDateIndex: currentIndex + 1})
            }}
            style={{marginRight: 30, color: this.state.surveyDateIndex !== 10 ? 'blue' : 'gray', cursor: 'pointer'}}>{`< back`}</p>
          <p
            onClick={() => {
              let currentIndex = this.state.surveyDateIndex
              if(currentIndex !== 0)
                this.setState({surveyDateIndex: currentIndex - 1})
            }}
            style={{marginLeft: 30, color: this.state.surveyDateIndex !== 0 ? 'blue' : 'gray', cursor: 'pointer'}}>{`next >`}</p>
        </div>
        <div style={{width: '90%', overflowX: 'scroll', overflowY: 'scroll', height: 400, paddingBottom: 20}}>
          <table style={{width: '90%'}}>
            <thead>
            <tr>
              {header}
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderSurveyData() {
    return (
      <div className={Styles.sectionDivider}>
        <div className={Styles.rowIcon}>
          <FontAwesomeIcon icon={faFileAlt} style={{color: 'white', fontSize: 40}}/>
          <p className={Styles.rowIconText}>Daily Survey</p>
        </div>

        <div style={{marginTop: 12, display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 20, justifyContent: 'center'}}>
          <p style={{color: Constants.primaryTheme, marginRight: 10}}>Question</p>
          <div style={{width: 400}}>
            <Select
              isSearchable={true}
              autoBlur={true}
              placeholder={'Select survey question'}
              isMulti={false}
              options={SURVEY_QUESTIONS}
              value={this.state.selectedSurveyQuestion}
              onChange={(val) => this.setState({selectedSurveyQuestion: val})}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />

          </div>
        </div>

        {this.renderSurveyGraph()}


        {
          this.state.surveyData &&
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              {this.renderSurveyTable()}
            </div>
        }

      </div>
    )

  }

  renderBioMarkers() {
    return (
      <div className={Styles.sectionDivider}>
        <div className={Styles.rowIcon}>
          <FontAwesomeIcon icon={faFlask} style={{color: 'white', fontSize: 40}}/>
          <p className={Styles.rowIconText}>Biomarkers</p>
        </div>

        <div className={Styles.graphRow}>
          {this.renderBiomarkerTables()}
        </div>

      </div>
    )
  }

  onParticipantChanged(val) {
    this.setState({selectedParticipant: val})
    let {selectedParticipant, timeline, selectedCohort} = this.state
    if(!selectedParticipant || selectedParticipant.value !== val.value) {
      this.props.addFilledData(null)
      this.setState({loadingFilledData: true, filledData: null, movingAverages: {}})
      this.getFilledDataFromBackend(timeline.value,selectedCohort && selectedCohort.value, val.value)
      this.getSurveyAnswers(timeline.value, selectedCohort && selectedCohort.label, val.value)
    }
  }

  onChecked(event) {
    let checked = event.target.checked
    if(checked) {
      cookie.save('trackingOptions', this.state.trackingOptions)
      cookie.save('bioMarkerOptions', this.state.bioMarkerOptions)
      cookie.save('timeline', this.state.timeline)
    } else {
      cookie.save('trackingOptions', [])
      cookie.save('bioMarkerOptions', [])
    }

    this.setState({checked})
  }

  onModalSubmitPressed() {
    let {clientFName, clientLName, clientEmail} = this.state

    if(!clientEmail || clientEmail.length === 0 || !this.validateEmail(clientEmail)) {
      this.setState({modalError: true, modalErrorString: 'Please enter a valid email'})
      return
    }

    if(!clientFName || clientFName.length === 0 ) {
      this.setState({modalError: true, modalErrorString: 'Please enter a valid first name'})
      return
    }

    if(!clientLName || clientLName.length === 0 ) {
      this.setState({modalError: true, modalErrorString: 'Please enter a valid last name'})
      return
    }


    if(window.confirm(`Are you sure you want to send invite to ${clientEmail}?`)) {
      this.setState({loading: true, modalError: false, modalIsOpen: false})
      this.sendClientInvite()
    }
  }

  onDownloadAppClicked() {
    if(window.confirm(`We will send an email to download the Aila Health mobile app`)) {
      this.setState({loading: true, modalError: false, modalIsOpen: false})
      this.sendDownloadLink()
    }
  }

  sendDownloadLink() {
    let deepLink = 'https://ailahealth.page.link/TxVM' //created from firebase console
    let {firebaseUser, token} = this.props
    let dataToPut = {
      doctor_id: firebaseUser.adminId,
      email: firebaseUser.email,
      first_name: firebaseUser.firstName,
      link: deepLink
    }
    let self = this

    let url = Config.BACKEND_URL + 'patient/app/download'
    axios({
      method: 'post',
      headers:{Authorization: 'JWT ' + token},
      url: url,
      data: dataToPut
    }).then(function (response) {
      // handle success
      if(response.status === 200) {
        console.log('sent download link')
        window.alert('An email has been sent with the download link. Please use the same email ID to login and access your data.')
        self.setState({loading: false})
      }
    })
      .catch(function (error) {
        // handle error
        console.log('error when sending download link to user', error)
        toast.error('Something went wrong')
      })
  }

  async sendClientInvite() {
    let self = this
    let {mongoUser, token, adminId} = this.props
    let {clientFName, clientLName, clientEmail} = this.state
    let stringToEncode = adminId +'+'+clientEmail
    let encodedString = btoa(stringToEncode)
    let inviteLink = `${window.location.origin}/signup/ccfm/client?q=${encodedString}`
    let dataToPut = {
      admin_id: adminId,
      first_name: clientFName,
      last_name: clientLName,
      email: clientEmail,
      link: inviteLink,
      admin_name: mongoUser.first_name + ' ' + mongoUser.last_name,
      organization: mongoUser.organization,
      cohort: this.state.selectedCohort.label
    }

    try {
      let url = Config.BACKEND_URL + 'providers/clients/invites'
      axios({
        method: 'post',
        headers:{Authorization: 'JWT ' + token},
        url: url,
        data: dataToPut
      }).then(function (response) {
        // handle success
        if(response.status === 200) {
          console.log('sent invite to client')
          toast.success('Invite sent')
          self.setState({loading: false})
        }
      })
        .catch(function (error) {
          // handle error
          console.log('error when sending client invite', error)
        })
    }catch(err) {
      console.log('error when trying to send invite', err)
      toast.error('Couldn\'t send invite.')
      toast.error('If the problem persists, contact support')
    }
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  renderSelects() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', borderRadius: 20, padding: '10px 6px', backgroundColor: Constants.contentBackground}}>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 20, paddingLeft: 20, paddingRight: 20}}>
          <h6 style={{color: '#20A892'}}>Tracking</h6>
          <div style={{minWidth: '100%'}}>
            <Select
              isSearchable={false}
              autoBlur={true}
              formatGroupLabel={(data) => this.formatGroupLabel(data, false)}
              placeholder={'Select vitals....'}
              isMulti={true}
              options={TRACKING_OPTIONS}
              value={this.state.trackingOptions}
              onChange={this.onTrackingOptionSelected.bind(this)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div style={{width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 20, paddingLeft: 20, paddingRight: 20}}>
          <h6 style={{color: '#20A892'}}>Biomarkers</h6>
          <div style={{minWidth: '100%'}}>
            <Select
              isSearchable={false}
              autoBlur={true}
              formatGroupLabel={(data) => this.formatGroupLabel(data, true)}
              placeholder={'Select biomarkers....'}
              isMulti={true}
              options={BIO_MARKER_OPTIONS}
              value={this.state.bioMarkerOptions}
              onChange={(val) => this.onBiomarkerOptionsChanged(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', minWidth: '100%', paddingLeft: 20, paddingRight: 20}}>
          <div style={{width: '50%',marginRight: 40, display: 'flex', flexDirection: 'column'}}>
            <h6 style={{color: '#20A892'}}>Timeline</h6>
            <Select
              placeholder={'Select Timeline....'}
              options={[
                {label: 'Monthly', value: 'monthly'},
                {label: 'Quarterly', value: 'quarterly'},
                {label: 'Semi Annual', value: 'semiAnnual'},
                {label: 'Complete History', value: 'complete'},
              ]}
              value={this.state.timeline}
              onChange={(val) => this.onTimelineChanged(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          {
            !this.state.hideParticipantDropdown &&
            <div style={{width: '50%', marginLeft: 40, display: 'flex', flexDirection: 'column', marginBottom: 20}}>
              <h6 style={{color: '#20A892'}}>Cohort</h6>
              <Select
                placeholder={'Select Cohort....'}
                options={this.state.cohortOptions}
                value={this.state.selectedCohort}
                onChange={(val) => this.onCohortChanged(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          }
        </div>

        {
          !this.state.hideParticipantDropdown &&
          <div style={{width: '100%',marginLeft: 20, marginRight: 40, display: 'flex', flexDirection: 'column', marginBottom: 20}}>
            <h6 style={{color: '#20A892'}}>Participant</h6>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div style={{width: '45%'}}>
                <Select
                  isSearchable={false}
                  autoBlur={true}
                  placeholder={'Select participant....'}
                  isMulti={false}
                  options={this.state.participantsList}
                  value={this.state.selectedParticipant}
                  onChange={this.onParticipantChanged.bind(this)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
              {this.state.selectedParticipant &&
              <p
                onClick={() => this.setState({participantMap: null, selectedParticipant: null})}
                style={{marginLeft: 20, textDecoration: 'underline', cursor: 'pointer'}}>remove</p>
              }
            </div>
          </div>
        }


        {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20}}>*/}
        {/*  <input type="checkbox" style={{marginRight: 10}} onChange={this.onChecked.bind(this)} checked={this.state.checked}/>*/}
        {/*  <p>Save this search</p>*/}
        {/*</div>*/}
      </div>
    )
  }

  handleCloseModal() {
    this.setState({
      modalIsOpen: false
    })
  }

  renderClientModal() {
    return (
      <div style={{height: '100%', width: '100%', overflowY: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <p className={Styles.closeModalBtn} onClick={() => this.handleCloseModal()}>X</p>
        <h4 style={{color: Constants.primaryTheme}}>Share Dashboard</h4>

        <div style={{borderRadius: 4, backgroundColor: '#eaeaea', width: '80%', marginTop: 40, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px 40px'}}>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Client First Name</p>
            <input
              placeholder={'Enter first name....'}
              className={Styles.textInput}
              type="text"
              value={this.state.clientFName}
              onChange={(e) => {this.setState({clientFName: e.target.value})}}
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Client Last Name</p>
            <input
              placeholder={'Enter last name....'}
              className={Styles.textInput}
              type="text"
              value={this.state.clientLName}
              onChange={(e) => {this.setState({clientLName: e.target.value})}}
            />
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Client Email</p>
            <input
              placeholder={'Enter email address....'}
              className={Styles.textInput}
              type="text"
              value={this.state.clientEmail}
              onChange={(e) => {this.setState({clientEmail: e.target.value})}}
            />
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Cohort View</p>
            <p style={{padding: '5px 20px', borderRadius: 4, border: '1px solid #d0d0d0', width: '50%', backgroundColor: 'white'}}>{this.state.selectedCohort && this.state.selectedCohort.value}</p>
          </div>
        </div>

        {
          this.state.modalError &&
          <p style={{fontSize: 12, color: 'red'}}>{this.state.modalErrorString}</p>
        }

        <Button
          onClick={this.onModalSubmitPressed.bind(this)}
          className={GlobalStyles.button}
          style={{width: 200, marginTop: 20, position: 'absolute', bottom: 20}}
          variant="primary"
        >
          Send
        </Button>
      </div>
    )
  }

  renderModalContent() {
    return (
      <div style={{height: '100%', width: '100%', overflowY: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <p className={Styles.closeModalBtn} onClick={() => this.handleCloseModal()}>X</p>
        <h4 style={{color: Constants.primaryTheme}}>Invite User</h4>

        <div style={{borderRadius: 4, backgroundColor: '#eaeaea', width: '80%', marginTop: 40, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px 40px'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly', marginBottom: 10}}>
            <input
              placeholder={'Enter first name....'}
              className={Styles.textInput}
              type="text"
              value={this.state.firstName}
              onChange={(e) => {this.setState({firstName: e.target.value, modalError: false})}}
            />

            <input
              placeholder={'Enter last name....'}
              className={Styles.textInput}
              type="text"
              value={this.state.lastName}
              onChange={(e) => {this.setState({lastName: e.target.value, modalError: false})}}
            />
          </div>

          <div style={{width: '80%'}}>
            <input
              placeholder={'Enter email address....'}
              className={Styles.textInput2}
              type="text"
              value={this.state.email}
              onChange={(e) => {this.setState({email: e.target.value, modalError: false})}}
            />
          </div>

          <div style={{width: '80%', marginTop: 10}}>
            <Select
              isSearchable={false}
              autoBlur={true}
              placeholder={'Select user type....'}
              isMulti={false}
              options={[{label: 'Patient', value: 'patient'}, {label: 'Client', value: 'client'}]}
              value={this.state.userType}
              onChange={(val) => this.setState({userType: val})}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

          <div style={{width: '80%', marginTop: 10}}>
            <Select
              isSearchable={false}
              autoBlur={true}
              placeholder={'Select access....'}
              isMulti={false}
              options={COHORT_OPTIONS}
              value={this.state.accessType}
              onChange={(val) => this.setState({accessType: val})}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>

        </div>

        {
          this.state.modalError &&
          <p style={{fontSize: 12, color: 'red'}}>{this.state.modalErrorString}</p>
        }

        <Button
          onClick={this.onModalSubmitPressed.bind(this)}
          className={GlobalStyles.button}
          style={{width: 200, marginTop: 20, position: 'absolute', bottom: 20}}
          variant="primary"
        >
          Submit
        </Button>
      </div>
    )
  }


  render() {
    let {patientData, userType} = this.props
    let uri
    if(patientData)
      uri = Config.BACKEND_URL + 'oura/auth?state=' + patientData.firebase_id
    return (
      <div className={GlobalStyles.container}>
        <Header header={'Wellness Dashboard'} />
       
        {
          this.state.loadingFilledData || this.state.loading?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 400}}>
              <div className={GlobalStyles.loader} />
            </div>

            :
            <div className={GlobalStyles.contentWrapper}>
              {/*<div style={{marginBottom: 40, padding: '0px 40px'}}>*/}
              {/*  <Button*/}
              {/*    onClick={() => this.setState({modalIsOpen: true})}*/}
              {/*    className={GlobalStyles.button}*/}
              {/*    style={{width: 200}}*/}
              {/*    variant="primary"*/}
              {/*  >*/}
              {/*    Invite User*/}
              {/*  </Button>*/}
              {/*</div>*/}


              {patientData &&
              (
                !patientData.oura ?
                  <a href={uri} target="_blank" rel="noopener noreferrer">
                    <Button
                      onClick={null}
                      className={GlobalStyles.button}
                      disabled={this.state.loading}
                      style={{width: 200, marginTop: 10, marginBottom: 20, marginLeft: 20}}
                      variant="primary"
                    >
                      Sync Oura Data
                    </Button>
                  </a>
                  :
                  <p style={{marginTop: 10, marginBottom: 20, marginLeft: 20, color: 'gray'}}>Oura data is synced</p>
              )}

              {/*{patientData &&*/}
              {/*  <Button*/}
              {/*    onClick={this.onDownloadAppClicked.bind(this)}*/}
              {/*    className={GlobalStyles.button}*/}
              {/*    disabled={this.state.loading}*/}
              {/*    style={{width: 200, marginTop: 10, marginBottom: 20, marginLeft: 20}}*/}
              {/*    variant="primary"*/}
              {/*  >*/}
              {/*    Download Mobile App*/}
              {/*  </Button>*/}
              {/*}*/}

              {
                userType && userType.includes('admin') &&
                <Button
                  onClick={() => this.setState({modalIsOpen: true})}
                  className={GlobalStyles.button}
                  style={{width: 200, marginTop: 10, marginBottom: 20, marginLeft: 20}}
                  variant="primary"
                >
                  Share Dashboard
                </Button>

              }

              <div
                onClick={() => this.setState({filtersOpened: !this.state.filtersOpened})}
                style={{cursor: 'pointer', marginLeft: 20, marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 4, border: '1px dotted #c8c8c8', width: 140, height: 40, justifyContent: 'center'}}>
                <FontAwesomeIcon icon={faFilter} style={{color: 'gray', fontSize: 24, marginRight: 10}}/>
                {
                  this.state.filtersOpened ?
                    <p>Close Filters</p>
                    :
                    <p>Open Filters</p>
                }
              </div>

              <Collapse isOpened={this.state.filtersOpened}>
                {this.renderSelects()}
              </Collapse>



              {this.renderSurveyData()}
              {this.renderGraphRows()}
              {this.renderBioMarkers()}

              <Modal
                ariaHideApp={false}
                isOpen={this.state.modalIsOpen}
                onRequestClose={() => this.handleCloseModal()}
                style={customModalStyles}
                contentLabel="Modal"
              >
                {this.renderClientModal()}
              </Modal>
            </div>
        }

      </div>
    )
  }
}

const MovingAveragesSelector = (props) => {
  const [activeTab, setActiveTab] = useState(2)

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  return (
    <div style={{flexDirection: 'row', display: 'flex', borderWidth: 1, borderStyle: 'solid', borderColor: 'gray', width: 100, height: 26}}>
      <div
        onClick={() => onTabSelected(1)}
        className={Styles.tabItem}
        style={{backgroundColor: activeTab === 1 ? '#DCDCDC': ''}}>

        <p style={{fontSize: 13}}>1d</p>


      </div>
      <div
        onClick={() => onTabSelected(2)}
        className={Styles.tabItem}
        style={{backgroundColor: activeTab === 2 ? '#DCDCDC': ''}}>
        <p style={{fontSize: 13}}>3d</p>
      </div>
      <div
        onClick={() => onTabSelected(3)}
        className={Styles.tabItem}
        style={{backgroundColor: activeTab === 3 ? '#DCDCDC': '', borderRightWidth: 0}}>
        <p style={{fontSize: 13}}>7d</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    filledData: state.popReducer.filledData,
    sparseData: state.popReducer.sparseData,
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    timer: state.patientReducer.timer,
    updateRender: state.userReducer.render,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientData: state.popReducer.patientData,
    userType: state.userReducer.userType,
    patientList: state.patientReducer.patientList,
    firebaseUser: state.userReducer.firebaseUser
  }
}

const mapDispatchToProps = { addFilledData, stopTimer, addSparseData,updateSideNavBar }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)