import React, { useEffect, useState } from 'react'
import GlobalStyles from '../../styles/global.module.scss'
import Styles from '../styles/orders.module.scss'
import Constants from '../../../values.js'
import { toast } from 'react-toastify'
import Select from 'react-select'
import Modal from 'react-modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Config from '../../../config'
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import metadata from '../../../metadata'
import BSModal from 'react-bootstrap/Modal'
const axios = require('axios')

const customChangeModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '95%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customDrugModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '50%',
    height: '75%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

export default function ErxOrderModal({
  patientId,
  token,
  providerId,
  mongoUser,
  icds,
  patient,
  appointmentId,
}) {
  console.log('TESTING THE ERX: ', {
    icds,
    // patientId,
    // token,
    // providerId,
    // mongoUser,
    // patient,
    // appointmentId,
  })
  const [loading, setLoading] = useState(false)
  const [prescriber, setPrescriber] = useState(null)
  const [patientData, setPatientData] = useState(patient || {})
  const [erxUrl, setErxUrl] = useState('')
  const [instructions, setInstructions] = useState('')
  const [comments, setComments] = useState('')
  const [icdCodes, setIcdCodes] = useState([])
  const [icdSelectableCodes, setIcdSelectableCodes] = useState(
    icds.map(({ icd_10 }) => ({
      code: icd_10?.icd10_code,
      display: icd_10?.title,
    })) || [],
  )
  const [searchIcdDesc, setSeachIcdDesc] = useState('')
  const [searchIcdCode, setSearchIcdCode] = useState('')
  const [icdSeachResult, setIcdSeachResult] = useState('')
  const [quantity, setQuantity] = useState('')
  const [supply, setSupply] = useState('')
  const [refills, setRefills] = useState('')
  const [units, setUnits] = useState('')
  const [date, setDate] = useState(null)
  const [pharmacy, setPharmacy] = useState(null)
  const [daw, setDaw] = useState(false)
  const [fav, setFav] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalUrl, setModalUrl] = useState('')
  const [drugSearchString, setDrugSearchString] = useState('')
  const [drugModalOpen, setDrugModalOpen] = useState(false)
  const [pharmacyModalOpen, setPharmacyModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [drugsList, setDrugsList] = useState([])
  const [selectedDrug, setSelectedDrug] = useState(null)
  const [providerOptionsData, setProviderOptionsData] = useState([])
  const [rxForm, setRxForm] = useState(false)
  const [preAuthReq, setPreAuthReq] = useState(false)
  const [measurementUnit, setMeasurementUnit] = useState(null)
  const [pharmacySearchString, setPharmacySearchString] = useState('')
  const [selectedPharmacyCity, setSelectedPharmacyCity] = useState('')
  const [pharmacyList, setPharmacyList] = useState([])

  useEffect(() => {
    getAllProviderData()
  }, [])

  const selectTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#20A89233',
      primary: '#20A892',
    },
  })

  const getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: mongoUser?.firebase_id,
        Authorization: 'JWT ' + token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        let providerOptionList = []
        // let allProviderData = data.filter(doc => {
        //   if(doc?.change?.caregiver_id && mongoUser?.firebase_id)
        //     return true
        // }).reduce((data, doc) => {
        //   data[doc.user_type || 'Other'] = data[doc.user_type || 'Other'] || []
        //   data[doc.user_type || 'Other'].push({
        //     label: doc?.first_name + ' ' + doc?.last_name + (doc?.speciality ? ` (${doc?.speciality})` : ''),
        //     value: doc?.firebase_id,
        //     data: doc
        //   })
        //   return data
        // }, Object.create(null))
        // Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
        //   providerOptionList.push({
        //     label: userType,
        //     options: doctorArray,
        //   })
        // })
        // INFO: I have reverted this, if bug, then here
        providerOptionList = data
          ?.filter((doc) => {
            if (
              doc?.change?.caregiver_id &&
              mongoUser?.firebase_id === doc.firebase_id
            )
              return true
          })
          .map((obj) => {
            setPrescriber({
              label: obj.first_name + ' ' + obj.last_name,
              value: obj?.firebase_id,
              data: obj,
            })
            return {
              label: obj.first_name + ' ' + obj.last_name,
              value: obj?.change?.firebase_id,
              data: obj,
            }
          })
        setProviderOptionsData(providerOptionList)
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
    // .then(({ data }) => {
    //   let providerOptionList = data
    //     ?.filter((doc) => {
    //       if (doc?.change?.caregiver_id && doc.firebase_id === adminId) return true
    //     })
    //     .map((obj) => {
    //       return {
    //         label: obj.first_name + ' ' + obj.last_name,
    //         value: obj?.change?.caregiver_id,
    //       }
    //     })
    //   setProviderOptionsData(providerOptionList)
    // })
    // .catch((err) => {
    //   console.log('err while loading provider data: ', err)
    // })
  }

  const onDrugSearchPressed = () => {
    if (!drugSearchString || drugSearchString.length === 0) {
      toast.error('Enter drug name to search')
      return
    }

    setModalLoading(true)
    let url =
      Config.CHANGE_BACKEND_URL + 'change/drugs/search?q=' + drugSearchString
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token },
    })
      .then(({ data }) => {
        if (data?.RESULT?.OBJECT) {
          let drugs = []
          let content = data.RESULT.OBJECT
          console.log('content')
          if (Array.isArray(content)) {
            console.log('its a array')
            content.forEach((x) => drugs.push(getDrugInfo(x)))
          } else {
            console.log('its a object')
            drugs.push(getDrugInfo(content))
          }
          setDrugsList(drugs)
        } else {
          toast.error('No results found')
        }
        setModalLoading(false)
      })
      .catch((err) => {
        console.log('error when searching for drug', err)
        toast.error('Something went wrong. Please try again later.')
      })
  }

  const onModalCloseRequested = () => {
    setModalIsOpen(false)
    //if the physician placed the order in clinician, we need to store that in our system for tracking
    if (
      selectedDrug &&
      prescriber &&
      instructions &&
      measurementUnit &&
      patientId &&
      providerId
    ) {
      if (window.confirm('Did you place the order for this patient?'))
        saveDrugInOurSystem()
    }
  }

  const saveDrugInOurSystem = () => {
    setLoading(true)
    let url = Config.BACKEND_URL + 'patients/erx/orders'

    let data = {
      doctor_id: providerId,
      patient_id: patientId,
      drug: selectedDrug,
      instructions: instructions,
      measurement_unit: measurementUnit,
      comments: comments,
      quantity: quantity,
      days_of_supply: supply,
      refills: refills,
      daw: daw,
      pharmacy: patientData?.change?.pharmacy?.name,
      icd_code: icdCodes,
      prescriber: mongoUser
        ? `${mongoUser.first_name} ${mongoUser.last_name}`
        : null,
      patient_name: `${patientData?.firstName} ${patientData?.lastName}`,
      doctor_type: `${mongoUser.user_type}`,
      rx_start_form: rxForm,
      need_prior_auth: preAuthReq,
    }

    if (appointmentId) {
      //the rx is being ordered during the visit. Link it with the event id
      data['appointment_event_id'] = appointmentId
    }
    console.log('saved: ', data)
    axios({
      method: 'post',
      url: url,
      headers: { Authorization: 'JWT ' + token },
      data: data,
    })
      .then(() => {
        console.log('saved rx order')
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when saving in our system', err)
        toast.error('Error when saving information')
        toast.error('Contact support')
      })
  }

  const getDrugInfo = (content) => {
    return {
      name: content['name'][1],
      doseForm: content['dose_form'],
      id: content['id'],
    }
  }

  const onDrugSelected = (drug) => {
    setModalLoading(true)
    let url = Config.CHANGE_BACKEND_URL + 'change/drugs/validate?id=' + drug.id
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token },
    })
      .then(({ data }) => {
        if (data?.RESULT?.OBJECT?.id) {
          //its a valid drug
          setSelectedDrug(drug)
          setModalLoading(false)
          setDrugModalOpen(false)
          toast.success('The selected medication is valid')
        } else {
          toast.error('The selected medication is not valid')
          setModalLoading(false)
        }
      })
      .catch((err) => {
        console.log('error when checking validity of drug', err)
        toast.error('Could not verify validity of medication')
      })
  }

  const addIcdCode = (hit) => {
    let temp = [{ code: hit.code, display: hit.display }]
    setIcdCodes(temp)
  }
  const addSearchedIcdCode = (codeData) => {
    let temp = [
      {
        code: codeData.icd_10_cm_code,
        display: codeData.description,
      },
    ]
    setIcdCodes(temp)
  }

  const onNextClicked = () => {
    if (
      !patientId ||
      !prescriber ||
      !instructions ||
      !measurementUnit ||
      instructions.length === 0 ||
      !patientData?.change?.pharmacy?.name
    ) {
      toast.error('Please fill all the fields')
      return
    }

    if (!selectedDrug || !selectedDrug.id) {
      toast.error('Please search and select a medication')
      return
    }

    if (!patientData?.change?.pharmacy?.name) {
      toast.error('Please search and select a pharmacy')
      return
    }

    let url = `${Config.CHANGE_IFRAME_URL}?userid=${
      prescriber?.data?.change?.clinician_id || Config.CHANGE_USER_ID
    }&PW=${
      prescriber?.data?.change?.clinician_pwd || Config.CHANGE_PWD
    }&hdnBusiness=${
      Config.CHANGE_ORG_ID
    }&apiLogin=true&target=jsp/lab/person/PatientLookup.jsp&FromOrder=false&actionCommand=Search&FromRx=true&loadPatient=false&link=false`
    url =
      url +
      `&searchaccountId=${patientId}&sig=${instructions}&drugFdbId=${selectedDrug.id}`

    //&drugFdbId=${'154760'}
    if (prescriber)
      url = url + `&prescriberId=${prescriber?.data?.change?.caregiver_id}`

    if (supply && supply.length > 0) url = url + `&daySupply=${supply}`

    if (quantity && quantity.length > 0) url = url + `&quantity=${quantity}`

    if (refills && refills.length > 0) url = url + `&refill=${refills}`

    if (icdCodes && icdCodes.length > 0)
      url = url + `&icdCode=${icdCodes[0].code}`

    if (comments && comments.length > 0) url = url + `&comments=${comments}`

    url = url + `&daw=${daw ? 'y' : 'n'}`

    console.log('the url is', url)

    window.open(encodeURI(url), '_blank')
    setErxUrl(encodeURI(url))
    console.log('the url is', encodeURI(url))
    axios({
      method: 'post',
      url: Config.BACKEND_URL + 'rx-log',
      headers: { Authorization: 'JWT ' + token },
      data: {
        iframe_url: url,
        encoded_url: encodeURI(url),
        doctor_id: prescriber?.data?.firebase_id,
        patient_id: patientId,
      },
    })
      .then(() => {
        console.log('rx url saved')
      })
      .catch((err) => {
        console.log('error when saving rx url', err)
      })
    saveDrugInOurSystem()
    // setModalUrl(encodeURI(url))
    // setModalIsOpen(true)
  }

  const getIcdSearchResults = () => {
    console.log('searching for icd')
    axios({
      method: 'get',
      url:
        Config.CHANGE_BACKEND_URL +
        'change/icd-10?' +
        `icd_code_desc=${searchIcdDesc || ''}` +
        `&icd_code=${searchIcdCode || ''}`,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: providerId,
      },
    })
      .then(({ data }) => {
        console.log('search icd: ', data)
        let result = data?.RESULT?.OBJECT
        if (result) {
          setIcdSeachResult(Array.isArray(result) ? result : [result])
        }
      })
      .catch((err) => console.log('error when searching icd 10 code: ', err))
  }

  const renderIcdCodes = () => {
    let conditionsElement =
      icdSelectableCodes &&
      icdSelectableCodes.map(
        (condn, index) => (
          // condn?.code?.coding?.[0]?.display && !condn?.userEntered ?
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p
              key={index.toString()}
              style={{
                textAlign: 'left',
                padding: '2px 10px',
                fontSize: '16px',
              }}
            >
              {condn.code} : {condn.display}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{ marginRight: 10, cursor: 'pointer' }}
                onClick={() => {
                  console.log('add to icd', condn)
                  setIcdCodes([condn])
                }}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            </div>
          </div>
        ),
        // : null,
      )

    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', padding: '30px 20px' }}
      >
        {/* Code to select ICD's */}
        <div style={{ width: '100%' }}>
          <div className={Styles.modalNotesRow}>
            <p className={Styles.modalNotesRegularText}>
              List of Selected ICD-10 for the patient
            </p>
          </div>
          <div className={Styles.modalNotesRow}>
            <div
              className={Styles.contentBackground}
              style={{ height: 150, overflowY: 'scroll', width: '100%' }}
            >
              {conditionsElement}
            </div>
          </div>
          {/*  */}
          <p
            style={{
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              width: 145,
              marginRight: 5,
              marginTop: 10,
            }}
          >
            {'ICD-10 Code'}
          </p>
          <div className={Styles.modalNotesRow}>
            <input
              placeholder={'Enter ICD-10 Code Number'}
              className={Styles.modalInputStyle}
              style={{ marginRight: 5 }}
              type="text"
              value={searchIcdCode}
              onChange={(e) => {
                setSearchIcdCode(e.target.value)
                getIcdSearchResults()
              }}
            />
            <input
              placeholder={'Description'}
              className={Styles.modalInputStyle}
              type="text"
              value={searchIcdDesc}
              onChange={(e) => {
                getIcdSearchResults()
                setSeachIcdDesc(e.target.value)
              }}
            />
          </div>

          {icdSeachResult && icdSeachResult.length !== 0 ? (
            <div
              className={Styles.modalNotesRow}
              style={{
                overflow: 'auto',
                height: 150,
                // backgroundColor: '#F1F1F1',
                marginTop: '0px',
              }}
            >
              <ul class="list-group" style={{ width: '100%' }}>
                {icdSeachResult?.map((codeData) => (
                  <li
                    class="list-group-item list-group-item"
                    style={{ cursor: 'pointer', fontSize: '80%' }}
                    onClick={() => {
                      setSearchIcdCode('')
                      setSeachIcdDesc('')
                      setIcdSeachResult('')
                      console.log('codeData', codeData)
                      addSearchedIcdCode(codeData)
                    }}
                  >
                    {codeData.icd_10_cm_code}: {codeData.description}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {icdCodes.length !== 0 && (
            <div>
              <p style={{ marginTop: 30, color: Constants.primaryTheme }}>
                Selected ICD-10 Code
              </p>
              <div style={{ height: 160, overflow: 'auto', width: '50%' }}>
                {icdCodes.map((icd, index) => (
                  <div
                    style={{
                      justifyContent: 'space-between',
                      borderTop: '1px solid #D3D3D3',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '4px 10px',
                    }}
                  >
                    <p>
                      {index + 1}. {icd.code}: {icd.display}
                    </p>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className={Styles.deleteIcon}
                      onClick={() => {
                        let arr = icdCodes.filter(
                          (data) => data.code !== icd.code,
                        )
                        setIcdCodes(arr)
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderRestOfTheFields = () => {
    return (
      <div style={{ padding: '30px 10px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              width: 145,
              marginRight: 5,
            }}
          >
            Drug
          </p>
          <p style={{ alignSelf: 'center', paddingRight: 20 }}>
            {selectedDrug && selectedDrug.name}
          </p>
          <Button
            onClick={() => setDrugModalOpen(true)}
            className={GlobalStyles.button}
            style={{ width: 100 }}
            variant="primary"
          >
            Search
          </Button>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              width: 145,
              marginRight: 5,
            }}
          >
            Instructions
          </p>
          <input
            placeholder={'enter instructions for patient'}
            className={Styles.erxInstructionField}
            type="text"
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              width: 145,
              marginRight: 5,
            }}
          >
            Drug Measure Unit
          </p>
          <div style={{ width: 300 }}>
            <Select
              placeholder={'Select Drug Measure Unit'}
              isSearchable={true}
              options={metadata.MEASUREMENT_OPTIONS}
              value={measurementUnit}
              onChange={setMeasurementUnit}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <p
              style={{
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
                width: 145,
                marginRight: 5,
              }}
            >
              Quantity
            </p>
            <input
              placeholder={'enter quantity'}
              className={Styles.erxInputFields}
              type="number"
              value={quantity}
              onChange={(e) => {
                let text = e.target.value
                setQuantity(text)
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <p
              style={{
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
                marginRight: 20,
              }}
            >
              Days Supply
            </p>
            <input
              placeholder={'enter supply days'}
              className={Styles.erxInputFields}
              type="number"
              value={supply}
              onChange={(e) => {
                let text = e.target.value
                setSupply(text)
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <p
              style={{
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
                marginRight: 20,
              }}
            >
              Refills
            </p>
            <input
              placeholder={'enter refills'}
              className={Styles.erxInputFields}
              type="number"
              value={refills}
              onChange={(e) => {
                let text = e.target.value
                setRefills(text)
              }}
            />
          </div>

          {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 20}}>*/}
          {/*  <p style={{ alignSelf: 'center', color: Constants.primaryTheme, fontWeight: 'bold', marginRight: 20}}>Unit of Measure</p>*/}
          {/*  <div style={{width: 200}}>*/}
          {/*    <Select*/}
          {/*      placeholder={'select unit'}*/}
          {/*      isSearchable={true}*/}
          {/*      options={UNITS}*/}
          {/*      value={units}*/}
          {/*      onChange={setUnits}*/}
          {/*      theme={(theme) => selectTheme(theme)}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <label
              class={Styles.checkContainer}
              style={{
                width: '30%',
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
              }}
            >
              Daw
              <input
                type="checkbox"
                checked={daw}
                onChange={() => setDaw(!daw)}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: 20,
            }}
          >
            <label
              class={Styles.checkContainer}
              style={{
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
              }}
            >
              Save as Favorite
              <input
                type="checkbox"
                checked={fav}
                onChange={() => setFav(!fav)}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              width: 145,
              marginRight: 5,
            }}
          >
            Notes to Pharmacist
          </p>
          <input
            placeholder={'enter notes to pharmacist'}
            className={Styles.erxInstructionField}
            type="text"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </div>

        {/*<div style={{ display: 'flex', flexDirection: 'row', margin: 20}}>*/}
        {/*  <p style={{ alignSelf: 'center', color: Constants.primaryTheme, fontWeight: 'bold', marginRight: 20 }}>Effective Date</p>*/}
        {/*  <div style={{ display: 'flex', flexDirection: 'row', width: 250, margin: '0px 20px' }}>*/}
        {/*    <DateTimePicker*/}
        {/*      disableClock={true}*/}
        {/*      onChange={setDate}*/}
        {/*      value={date}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 20,
            marginTop: 30,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              width: 145,
              marginRight: 5,
            }}
          >
            Pharmacy
          </p>
          <p>{patientData?.change?.pharmacy?.name[1] || 'N/A'}</p>
          <Button
            onClick={() => setPharmacyModalOpen(true)}
            className={GlobalStyles.button}
            style={{ width: 100 }}
            variant="primary"
          >
            Search
          </Button>
        </div>

        <div className={Styles.inputDiv}>
          <div
            style={{
              width: '60%',
              margin: '0px 20px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <label
              class={Styles.checkContainer}
              style={{
                width: '50%',
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
              }}
            >
              Send Patient RX Start Form
              <input
                type="checkbox"
                checked={rxForm}
                onClick={() => {
                  setRxForm(!rxForm)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
            <label
              class={Styles.checkContainer}
              style={{
                width: '50%',
                alignSelf: 'center',
                color: Constants.primaryTheme,
                fontWeight: 'bold',
              }}
            >
              Needs Prior Authorization Request
              <input
                type="checkbox"
                checked={preAuthReq}
                onClick={() => {
                  setPreAuthReq(!preAuthReq)
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        </div>
        {erxUrl ? (
          <a
            href={erxUrl}
            target="_blank"
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              marginLeft: 20,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Place the order
          </a>
        ) : null}
      </div>
    )
  }

  const renderChangeIframeModal = () => {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={onModalCloseRequested}
        style={customChangeModalStyles}
        contentLabel="Modal"
      >
        <iframe
          src={modalUrl}
          title="erx"
          style={{ height: '100%', width: '100%' }}
        />
      </Modal>
    )
  }

  const renderDrugsList = () => {
    if (!drugSearchString || drugSearchString.length === 0) {
      return
    }

    if (!drugsList || drugsList.length === 0) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>no results</p>
        </div>
      )
    }

    let elements = drugsList.map((x) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: '1px solid #d0d0d0',
          padding: '8px 2px',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            width: '30%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => onDrugSelected(x)}
            className={GlobalStyles.button}
            style={{ width: 100 }}
            variant="primary"
          >
            Select
          </Button>
        </div>
        <p style={{ width: '50%', textAlign: 'center' }}>{x.name}</p>
        <p style={{ width: '20%', textAlign: 'center' }}>{x.doseForm}</p>
      </div>
    ))

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '1px solid #d0d0d0',
            padding: 10,
          }}
        >
          <p style={{ width: '30%' }}></p>
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Name
          </p>
          <p
            style={{
              width: '20%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Dose Form
          </p>
        </div>

        {elements}
      </div>
    )
  }

  const renderDrugsSearchModal = () => {
    return (
      // <Modal
      //   ariaHideApp={false}
      //   isOpen={drugModalOpen}
      //   onRequestClose={() => setDrugModalOpen(false)}
      //   style={customDrugModalStyles}
      //   contentLabel="Modal"
      // >
      <BSModal
        show={drugModalOpen}
        onHide={() => setDrugModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{zIndex: 2000}}
        // dialogClassName={Styles.taskModalContainer}
      >
        {modalLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              minHeight: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 10,
              height: '70vh',
              width: '100%',
              overflow: 'auto',
            }}
          >
            <p
              className={Styles.closeModalBtn}
              onClick={() => setDrugModalOpen(false)}
            >
              X
            </p>
            <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
              Drug Search
            </h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0px 10px',
                width: '80%',
              }}
            >
              <input
                placeholder={'search drugs'}
                className={Styles.labTextInput}
                type="textInput"
                value={drugSearchString}
                onChange={(e) => setDrugSearchString(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onDrugSearchPressed()
                  }
                }}
              />
            </div>

            {renderDrugsList()}
          </div>
        )}
      </BSModal>
    )
  }

  const onPharmacySearch = async () => {
    setModalLoading(true)
    if (pharmacySearchString.length === 0) {
      toast.error('Please enter pharmacy name or zipcode')
      setModalLoading(false)
      return
    }

    let config = {
      method: 'get',
      url: `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/zip`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patientId ? patientId : patientData?.uid,
      },
    }
    // if entered only zip code (ie, string contains only digits)
    if (/^\d+$/.test(pharmacySearchString)) {
      config.url = `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/zip`
      config.headers.zip = pharmacySearchString
    } else {
      if (selectedPharmacyCity.length === 0) {
        toast.error('Please enter city name')
        setModalLoading(false)
        return
      }
      config.url = `${Config.CHANGE_BACKEND_URL}change/pharmacies/search/name`
      config.headers.name = pharmacySearchString
      config.headers.city = selectedPharmacyCity
    }

    axios(config)
      .then(({ data }) => {
        console.log('got results', data)
        if (data) {
          setPharmacyList(data || [])
          setModalLoading(false)
        }
      })
      .catch(function (error) {
        setModalLoading(false)
        setPharmacyList([])
        toast.error(
          'Error occured while fetching the pharmacy details, please contact support',
        )
        console.log('error when searching pharmacy', error)
      })
  }

  const onPharmacySelected = (pharmacyObj) => {
    let data = { 'change.pharmacy': pharmacyObj }
    let config = {
      method: 'put',
      url: `${Config.BACKEND_URL}users`,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: patientId ? patientId : patientData?.uid,
      },
      data: data,
    }
    let tempPatient = patientData
    if (tempPatient.change) tempPatient['change']['pharmacy'] = pharmacyObj
    else tempPatient.change = { pharmacy: pharmacyObj }
    setPatientData(tempPatient)

    axios(config)
      .then(() => {
        console.log('saved pharmacy information in the backend')
        toast.success('Patient pharmacy data has be saved')
        setPharmacyList([])
        setPharmacySearchString('')
        setPharmacyModalOpen(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('error when saving info to backend', error)
        toast.error('Error while saving pharmacy info')
      })
  }

  const renderPharmacyList = () => {
    if (
      !pharmacySearchString ||
      pharmacySearchString.length === 0 ||
      pharmacyList.length === 0
    ) {
      return
    }

    if (
      !pharmacyList ||
      (pharmacyList.length !== 0 && pharmacyList[0] === null)
    ) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>no results</p>
        </div>
      )
    }

    let elements = pharmacyList.map((pharmacy) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: '1px solid #d0d0d0',
          padding: '8px 2px',
          cursor: 'pointer',
        }}
        onClick={() => onPharmacySelected(pharmacy)}
      >
        <p style={{ width: '50%', textAlign: 'center' }}>
          {pharmacy?.name?.[1]}
        </p>
        <p style={{ width: '50%', textAlign: 'center' }}>
          {pharmacy?.address_1}, {pharmacy?.city}
        </p>
      </div>
    ))

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '1px solid #d0d0d0',
            padding: 10,
          }}
        >
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Name
          </p>
          <p
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'gray',
            }}
          >
            Address
          </p>
        </div>

        {elements}
      </div>
    )
  }

  const renderPharmacySearchModal = () => {
    if (modalLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70vh',
            width: '100%',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }
    return (
      <BSModal
        show={pharmacyModalOpen}
        onHide={() => setPharmacyModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 10,
            height: '70vh',
            width: '100%',
            overflow: 'auto',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            onClick={() => setPharmacyModalOpen(false)}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            Pharmacy Search
          </h4>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0px 10px',
              width: '80%',
            }}
          >
            <input
              placeholder={'Enter pharmacy or zipcode'}
              className={Styles.textInput}
              style={{ width: '40%' }}
              type="textInput"
              value={pharmacySearchString}
              onChange={(e) => setPharmacySearchString(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onPharmacySearch()
                }
              }}
            />
            <input
              placeholder={'Enter city name'}
              className={Styles.textInput}
              style={{ width: '40%' }}
              type="textInput"
              value={selectedPharmacyCity}
              onChange={(e) => setSelectedPharmacyCity(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onPharmacySearch()
                }
              }}
            />
            <Button
              onClick={onPharmacySearch}
              className={GlobalStyles.button}
              style={{ marginLeft: 5, height: 40 }}
              variant="primary"
            >
              Search
            </Button>
          </div>

          {renderPharmacyList()}
        </div>
      </BSModal>
    )
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100%',
          minHeight: '100%',
        }}
      >
        <div className={GlobalStyles.loader} />
      </div>
    )
  } else {
    return (
      <div style={{ width: '100%', padding: '30px 0px', position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 20,
            paddingBottom: 40,
          }}
        >
          <p
            style={{
              alignSelf: 'center',
              color: Constants.primaryTheme,
              fontWeight: 'bold',
              width: 150,
            }}
          >
            Prescriber
          </p>
          <div style={{ width: 300 }}>
            <Select
              placeholder={'Select prescriber'}
              isSearchable={true}
              options={providerOptionsData}
              getOptionValue={(option) => option.label}
              value={prescriber}
              onChange={setPrescriber}
              theme={(theme) => selectTheme(theme)}
            />
          </div>
        </div>

        <div className={Styles.horizontalLine} />

        {renderIcdCodes()}

        <div className={Styles.horizontalLine} />

        {renderRestOfTheFields()}

        {renderChangeIframeModal()}

        {renderDrugsSearchModal()}

        {renderPharmacySearchModal()}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginBottom: 20,
          }}
        >
          <Button
            onClick={onNextClicked}
            className={GlobalStyles.button}
            style={{ width: 100 }}
            variant="primary"
          >
            Order
          </Button>
        </div>
      </div>
    )
  }
}
