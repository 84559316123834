import React, {useState, useEffect}from 'react'
import {getQuestionnaireResults} from './getQuestionnaireResults';
import QChartToolTip from './QChartToolTip';
import Constants from "../values";
import { getResultsColor } from './getResultsColor';
let GREEN_RANGE_COLOR = '#20A89266';

export const renderQuestionnaireResults = ({option, getScreeningData, setModalChartData, setShowChartModal}) => {
  let screeningData = getScreeningData(option?.path)
  let latestScore = screeningData?.length && screeningData.sort((first, second) => {
    return new Date(first.created_at) - new Date(second.created_at)
  }).reverse();
  // console.log('rendering: ', questionnaireData?.[option?.path], option)
  if(screeningData === null) {
    return null
  }
  return (
    <div
      onClick={() => {
        setModalChartData({data: screeningData, path: option?.path, name: option.label})
        setShowChartModal(true)
      }}
      data-tip
      data-for={option.path}
      key={option.path}
      style={{width: 180, marginRight: 10, marginLeft: 10, height: 120, alignItems: 'center', justifyContent: 'end', display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
      <div>
        {
          screeningData?.length ?
            <p style={{fontWeight: 'bold', fontSize: 16}}>{latestScore?.[0]?.[getQuestionnaireResults(option?.path)]?.toFixed(2)}</p>
            :
            <p style={{fontWeight: 'bold', fontSize: 16}}>-</p>
        }
        <p style={{fontSize: 12}}>{option.EXAMPLE_UCUM_UNITS || option.EXAMPLE_UNITS}</p>
      </div>
      <div style={{width: '90%', backgroundColor: screeningData?.length ? getResultsColor(option?.path, screeningData?.[0]?.[getQuestionnaireResults(option?.path)]?.toFixed(2), screeningData) : Constants.lightGray, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px 2px', borderRadius: 8, marginTop: 15}}>
        <p style={{textAlign: 'center'}}>{option.label}</p>
      </div>
      {/* {this.getTooltip(option, data.progress)} */}
      {(screeningData?.length !== 0) && <QChartToolTip data={screeningData} path={option.path}/>}


    </div>
  )
}