import React, { useState, useEffect } from 'react'
import GlobalStyles from '../../../pages/styles/global.module.scss'
import Styles from './styles/DoctorList.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMicrophoneSlash,
  faPhone,
  faVideoSlash,
  faMicrophone,
  faVideo,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import values from '../../../values'
import Config from '../../../config'
import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'

const TWILIO_VIDEO = require('twilio-video')

const moment = require('moment')
const axios = require('axios')

const CONFIRM_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '40%',
    height: '40%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const DoctorList = (props) => {
  const dispatch = useDispatch()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)
  //   const openNotesModal = useSelector(
  //     (state) => state.genericReducer?.openNotesModal,
  //   )
  const appointmentData = useSelector(
    (state) => state.appointmentReducer?.appointmentData,
  )

  const patient = useSelector((state) => state.patientReducer?.patient)
  const patientCore = useSelector((state) => state.patientReducer?.patientCore)


  const [loading, setLoading] = useState(false)
  const [drlistData, setDrListData] = useState([])
  const [twilloTokenLocal, setTwilloTokenLocal] = useState('')
  const [confirmModal, setConfirmModal] = useState(false)
  const [confirmCustomModal, setConfirmCustomModal] = useState(false)
  const [selectedDrName, setSelectedDrName] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [showWeb, setShowWeb] = useState(false)
  const [webViewUrl, setWebViewUrl] = useState('')
  const [doctorDetails, setDoctorDetails] = useState({})
  const [customModalDetails, setCustomModalDetails] = useState('')
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showDoctorModal, setShowDoctorModal] = useState(false)
  const [docDetails, setDocDetails] = useState({})
  const [userLocation, setUserLocation] = useState('')

  const [appointmentBooked, setAppointmentBooked] = useState(false)

  useEffect(() => {
    setLoading(true)
    getDoctorList()
  }, [])

  const getDoctorList = () => {
    var config = {
      method: 'get',
      url: `${Config.BACKEND_URL}patient/careteam/internal`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient.uid,
      },
    }

    axios(config)
      .then(function (response) {
        console.log('got patient internal team')
        setLoading(false)
        if (response.status === 200 && response.data) {
          if (patientCore?.medicaid) {
            setDrListData(
              response.data.filter((docs) => docs.user_type !== 'Physician'),
            )
          } else setDrListData(response.data)
        }
      })
      .catch(function (error) {
        console.log('error when getting patient care team', error)
        setLoading(false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.errorMessage &&
          error.response.data.errorMessage === 'Invalid Token'
        ) {
          toast.warning(error.response.data.errorMessage)
        } else {
          toast.warning(error.response.data.errorMessage)
        }
      })
  }

  const confirmFunction = async (lat, lon, resultAddress) => {
    setUserLocation({
      lat: lat,
      long: lon,
      state: resultAddress?.long_name,
      state_abbr: resultAddress?.short_name,
      showScheduler: true,
    })
    setConfirmCustomModal(false)
    props.parentCallback(docDetails)
    setDoctorDetails({})
  }

  // to get the address using lat and long
  const googleGeocodingApi = (
    lat,
    lon,
    licensedFullStateName,
    licensedAbbrStateName,
  ) => {
    var config = {
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&result_type=administrative_area_level_1&key=${Config.GOOGLE_API_KEY}`,
    }

    axios(config)
      .then((response) => {
        let resultAddress =
          response?.data?.results?.[0]?.address_components?.[0]
        if (
          licensedFullStateName.includes(
            resultAddress?.long_name.toLowerCase(),
          ) ||
          licensedAbbrStateName.includes(
            resultAddress?.short_name.toLowerCase(),
          )
        ) {
          setCustomModalDetails({
            title: 'Confirm your Location',
            body:
              `Please confirm that you will be in the state of ${resultAddress?.long_name} at the time of your appointment. ` +
              '\nAila Health Clinicians can only see patients in states where they are licensed to practice.',
            confirmFn: () => confirmFunction(lat, lon, resultAddress),
            // this.confirmFunction(lat, lon, resultAddress),

            confirmText: 'Confirm',
            rejectFn: () => {
              setConfirmCustomModal(false)
              setConfirmModal(false)
              setDocDetails({})
              setCustomModalDetails('')
              // props.parentCallback(null)
            },
            rejectText: 'Cancel',
            disableOptions: false,
          })
          setConfirmCustomModal(true)
        } else {
          setCustomModalDetails({
            title: 'Sorry',
            body: "We aren't providing clinical care in your state yet but you can still use the app to track your symptoms and connect with other patients in your community!",
            confirmFn: () => {
              setConfirmCustomModal(false)
              setConfirmModal(false)
              setDocDetails({})
              setCustomModalDetails('')
              // props.parentCallback(docDetails)
            },
            rejectFn: () => {
              setConfirmCustomModal(false)
              setConfirmModal(false)
              setDocDetails({})
              setCustomModalDetails('')
              // props.parentCallback(null)
            },
            rejectText: 'Ok',
            disableOptions: true,
          })
          setConfirmCustomModal(true)
        }
      })
      .catch(function (error) {
        // setLoading(false)
        console.log('error when getting address from google', error)
        // setLoading(false)
      })
  }

  const showPosition = (position) => {
    let geoValueLat = position.coords.latitude
    let geoValueLon = position.coords.longitude

    console.log(position)
    // this.setState({ lat: geoValueLat, lon: geoValueLon })

    // console.log('licensed_states: ', this.state.docInfo?.licensed_states)
    // if gps enabled request for lat and long
    if (docDetails?.licensed_states?.length) {
      let licensedFullStateName = docDetails?.licensed_states?.map((address) =>
        address?.state?.toLowerCase(),
      )
      let licensedAbbrStateName = docDetails?.licensed_states?.map((address) =>
        address?.abbr?.toLowerCase(),
      )
      // console.log('Array: ', licensedFullStateName, licensedAbbrStateName)

      // var lat = 36.7783
      // var lon = -119.4179

      googleGeocodingApi(
        // lat,
        // lon,
        geoValueLat,
        geoValueLon,
        licensedFullStateName,
        licensedAbbrStateName,
        1,
      )
    }
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, (error) => {
        console.log('eroor location :', error)
        window.alert(
          'Please check if your gps is enabled in the settings. Aila Health needs access to your location for enabling appointments',
        )
      })
    }
  }

  const appointmentCallBack = () => {
    setSelectedDrName('')
    setConfirmModal(false)
    // if (docDetails.user_type !== 'Health Coach') {
    //   getLocation()
    // } else {
    props.parentCallback(docDetails)
    setDoctorDetails({})
    // }
  }

  const doctorSelected = (doctor, index) => {
    setSelectedDrName(`${doctor.first_name} ${doctor.last_name}`)
    setDocDetails(doctor)
    setConfirmModal(true)
    console.log('Selected::', doctor)
    // props.parentCallback(doctor)
  }

  const renderListItem = (item, index) => {
    return (
      <div
        className={Styles.childDiv}
        style={{ cursor: 'pointer' }}
        onClick={() => doctorSelected(item, index)}
      >
        <img
          alt="Aila Health"
          src={item?.image_url ? item?.image_url : DocPlaceHolder}
          style={{
            width: '150px',
            height: '150px',
            display: 'block',
            margin: 'auto auto',
            borderRadius: '50%',
          }}
        />
        <p
          style={{
            fontSize: 18,
            margin: 'auto',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '2vh',
            textDecorationLine: 'underline',
          }}
        >
          {`${item.first_name} ${item.last_name}`}
        </p>
        <p
          style={{
            fontSize: 14,
            margin: 'auto',
            color: values.primaryTheme,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            // fontStyle: 'italic',
          }}
        >
          {item.user_type}
        </p>
        <p
          style={{
            fontSize: 14,
            margin: 'auto',
            color: values.primaryTheme,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            fontStyle: 'italic',
          }}
        >
          {item.speciality}
        </p>
      </div>
    )

    {
      /* <div
        key={index}
        className={Styles.contentRow}
        className={Styles.contentBackground}
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          margin: 10,
        }}
      >
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <img
            src={item?.image_url ? item?.image_url : DocPlaceHolder}
            height={50}
            width={50}
            style={{ borderRadius: '50%', margin: 10 }}
          />

          <h2>{`${item.first_name} ${item.last_name}`}</h2>
        </div>
      </div> */
    }
  }

  const renderCustomModal = () => {
    let customDetails = customModalDetails
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h4 style={{ color: values.primaryThemeDark, textAlign: 'center' }}>
          {customDetails.title}
        </h4>

        <p
          style={{
            fontSize: '14',
            marginTop: '4vh',
            color: values.primaryTheme,
            textAlign: 'center',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          {customDetails.body}
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            // marginTop: '4vh',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '50%',
              margin: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                customDetails.confirmFn && customDetails.confirmFn()
              }}
              className={GlobalStyles.button}
              // disabled={this.state.modalLoading}
              style={{ width: 200 }}
              variant="primary"
            >
              {customDetails.confirmText || 'Yes'}
            </Button>
          </div>

          <div
            style={{
              width: '50%',
              margin: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                customDetails.rejectFn && customDetails.rejectFn()
              }}
              className={GlobalStyles.button}
              // disabled={this.state.modalLoading}
              style={{ width: 200, backgroundColor: 'gray' }}
              variant="primary"
            >
              {customDetails.rejectText || 'Cancel'}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const renderConfirmModal = () => {
    console.log('Selected::', selectedDrName)
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            setSelectedDrName('')
            setConfirmModal(false)
            setDoctorDetails({})
          }}
        >
          X
        </p>
        <h4 style={{ color: values.primaryThemeDark, textAlign: 'center' }}>
          Confirmation
        </h4>

        <p
          style={{
            fontSize: 18,
            margin: 'auto',
            color: values.primaryTheme,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
          }}
        >
          {`Would you like to book an appointment with ${selectedDrName} ?`}
        </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <Button
            onClick={() => {
              setSelectedDrName('')
              setConfirmModal(false)
              setDoctorDetails({})
            }}
            className={Styles.button2}
            style={{
              margin: '1vh',
              width: 200,
              color: '#20A892',
              fontWeight: 'bold',
            }}
            variant="primary"
          >
            {'Cancel'}
          </Button>

          <Button
            onClick={() => {
              appointmentCallBack()
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '1vh',
              width: 200,
            }}
            variant="primary"
          >
            {'Yes'}
          </Button>
        </div>
      </div>
    )
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: 100, // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const renderContent = () => (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className={Styles.parentDiv}>
        {drlistData && drlistData.length ? (
          drlistData.map((doctor, index) => {
            return renderListItem(doctor, index)
          })
        ) : (
          <h3>No Doctors Assigned</h3>
        )}
      </div>
      {doctorDetails && (
        <Modal
          ariaHideApp={false}
          isOpen={confirmModal}
          onRequestClose={() => {
            setSelectedDrName('')
            setConfirmModal(false)
            setDoctorDetails({})
          }}
          style={CONFIRM_MODAL_STYLES}
          contentLabel="Modal"
        >
          {renderConfirmModal()}
        </Modal>
      )}

      {customModalDetails.length !== 0 && (
        <Modal
          ariaHideApp={false}
          isOpen={confirmCustomModal}
          style={CONFIRM_MODAL_STYLES}
          contentLabel="Modal"
        >
          {renderCustomModal()}
        </Modal>
      )}
    </div>
  )

  if (loading) return renderLoader()
  return renderContent()
}

export default DoctorList
