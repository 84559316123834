import React, {useState, useEffect} from 'react'
import Styles from "./Musculoskeletal.module.scss";
import {
	Legend,
	Line,
	LineChart,
	Tooltip,
	XAxis,
	YAxis,
	ReferenceLine,
	ReferenceArea,
	AreaChart,
	Label,
	CartesianGrid,
	Area
} from "recharts";
import Select from "react-select";
import axios from 'axios';
import moment from 'moment';
import Constants from "../../values";
import Config from "../../config";

let graphTempData = [];
let graphWidth = (window.innerWidth) / 2;

const JOINT_OPTIONS = [
	{key: 0, name: "Jaw Lock", checked: 0, dbName: 'temporomandibular', type: 'body'},
	{key: 1, name: "Cervical Spine", checked: 0, dbName: 'cervical_spine', type: 'spine'},
	{key: 2, name: "Sternoclavicular", checked: 0, dbName: 'sternoclavicular', type: 'body'},
	{key: 3, name: "Shoulder", checked: 0, dbName: 'shoulder', type: 'body'},
	{key: 4, name: "Elbow", checked: 0, dbName: 'elbow', type: 'body'},
	{key: 5, name: "Wrist", checked: 0, dbName: 'wrist', type: 'body'},
	{key: 6, name: "Sacroiliac", checked: 0, dbName: 'sacroiliac', type: 'body'}, 
	{key: 7, name: "Hip", checked: 0, dbName: 'hip', type: 'body'},
	{key: 8, name: "Knee", checked: 0, dbName: 'knee', type: 'body'},
	{key: 9, name: "Ankle", checked: 0, dbName: 'ankle', type: 'body'},
	{key: 10, name: "Thumb (PIP-1)", checked: 0, dbName: '1_pip', type: 'hand'},
	{key: 11, name: "Index Finger (PIP-2)", checked: 0, dbName: '2_pip', type: 'hand'},
	{key: 12, name: "Middle Finger (PIP-3)", checked: 0, dbName: '3_pip', type: 'hand'},
	{key: 13, name: "Ring Finger (PIP-4)", checked: 0, dbName: '4_pip', type: 'hand'},
	{key: 14, name: "Little Finger (PIP-5)", checked: 0, dbName: '5_pip', type: 'hand'},
	{key: 15, name: "Thumb (MCP-1)", checked: 0, dbName: '1_mcp', type: 'hand'},
	{key: 16, name: "Index Finger (MCP-2)", checked: 0, dbName: '2_mcp', type: 'hand'},
	{key: 17, name: "Middle Finger (MCP-3)", checked: 0, dbName: '3_mcp', type: 'hand'},
	{key: 18, name: "Ring Finger (MCP-4)", checked: 0, dbName: '4_mcp', type: 'hand'},
	{key: 19, name: "Little Finger (MCP-5)", checked: 0, dbName: '5_mcp', type: 'hand'},
	{key: 20, name: "Index Finger (DIP-2)", checked: 0, dbName: '2_dip', type: 'hand'},
	{key: 21, name: "Middle Finger (DIP-3)", checked: 0, dbName: '3_dip', type: 'hand'},
	{key: 22, name: "Ring Finger (DIP-4)", checked: 0, dbName: '4_dip', type: 'hand'},
	{key: 23, name: "Little Finger (DIP-5)", checked: 0, dbName: '5_dip', type: 'hand'},
	{key: 24, name: "Big Toe (PIP-1)", checked: 0, dbName: '1_tpip', type: 'foot'},
	{key: 25, name: "Long Toe (PIP-2)", checked: 0, dbName: '2_tpip', type: 'foot'},
	{key: 26, name: "Middle Toe (PIP-3)", checked: 0, dbName: '3_tpip', type: 'foot'},
	{key: 27, name: "Ring Toe (PIP-4)", checked: 0, dbName: '4_tpip', type: 'foot'},
	{key: 28, name: "Little Toe (PIP-5)", checked: 0, dbName: '5_tpip', type: 'foot'},
	{key: 29, name: "Big Toe (MTP-1)", checked: 0, dbName: '1_mtp', type: 'foot'},
	{key: 30, name: "Long Toe (MTP-2)", checked: 0, dbName: '2_mtp', type: 'foot'},
	{key: 31, name: "Middle Toe (MTP-3)", checked: 0, dbName: '3_mtp', type: 'foot'},
	{key: 32, name: "Ring Toe (MTP-4)", checked: 0, dbName: '4_mtp', type: 'foot'},
	{key: 33, name: "Little Toe (MTP-5)", checked: 0, dbName: '5_mtp', type: 'foot'},
	{key: 34, name: "Midfoot", checked: 0, dbName: 'midfoot', type: 'foot'},
	{key: 35, name: "Subtalar Joint", checked: 0, dbName: 'subtalar', type: 'foot'},
]

const getTendernessValue = (data, name) => {
  // console.log('TENDERNESS VALUES: ', data[name]?.tenderness)
  return data[name]?.tenderness
}

const getSwollenValue = (data, name) => {
  // console.log('Swollen VALUES: ', data[name]?.swollen)
  return data[name]?.swollen
}

const CustomTooltip = ({ active, payload, label }) => {
	if (active) {
		return (
			<div className={Styles.customTooltip}>
				<p className={Styles.tooleTipLabel}>{`Treatment: ${label}`}</p>
				{/* <p className="intro">{getIntroOfPage(label)}</p>
        <p className="desc">Anything you want can be displayed here.</p> */}
			</div>
		);
	}
	return null;
};

const renderChart = (data, graphWidth,title, unitString, getJointName, chartRange, setChartRange) =>{
  let chartData = data.filter((data) => moment(data.created_at).isSame(new Date(), chartRange.value))

  if(chartData.length === 0) {
    return (
      <div style={{display:'flex', justifyContent: 'center', textAlign: 'center', height: 350, alignItems: 'center'}}>
       <p>
         No Data Present
       </p>
      </div>
    )
  }
  
	return(
    <LineChart width={graphWidth} height={350} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
      <Line 
        dot={false} 
        name={'Tenderness'}  
        type="monotone" 
        dataKey={(data) => getTendernessValue(data, getJointName())}
        stroke={Constants.graphLineOne} 
      />

      <Line 
        dot={false} 
        name={'Swollen'}  
        type="monotone" 
        dataKey={(data) => getSwollenValue(data, getJointName())}
        stroke={Constants.graphLineThree} 
      />

      <XAxis dataKey="created_at" tickFormatter={(tickItem) => {
        return moment(tickItem).format('D-MMM')
      }}/>
      <YAxis
        label={{ value: 'Level', angle: -90, position: 'insideLeft', offset: 10}}
        domain={[0,10]}/>
      <Legend verticalAlign="top" height={36}/>
      <Tooltip content={({ active, payload, label }) => {
        let date = moment(label).format('D-MMM')
        return (
          <div className={Styles.painChartTooltip} style={{width: 240}}>
            <p>{date}</p>
            
            <div style={{width: '100%', padding: '0px 5px'}}>
              <p style={{fontWeight: 'bold', textAlign: 'left'}}>{payload[0]?.name}: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && payload[0]?.value}</span></p>
              <p style={{fontWeight: 'bold', textAlign: 'left'}}>{payload[1]?.name}: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && payload[1]?.value}</span></p>
            </div>
                
          </div>
        )
      }}/>
    </LineChart>
	)
}


export default function JointPainChart({token, patientId}) {
  const [jointKey, setJointKey] = useState(0)
  const [jointData, setJointData] = useState([])
  const [jointSide, setJointSide] = useState({label: 'Right', value: 'right'})
  const [chartRange, setChartRange] = useState({label: 'Year', value: 'year'})
  // const [jointName, setJointName] = useState('')
  const [loading, setLoading] = useState(false)

  const getData = () => {
    const url = Config.BACKEND_URL + 'screenings/joints'
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patientId, complete: true },
    })
      .then(({ data }) => {
        setJointData(data.reverse())
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when getting screening', err)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const getJointName = () => {
    if(JOINT_OPTIONS[jointKey].type === 'spine') {
      return 'cervical_spine'
    }
    else if(JOINT_OPTIONS[jointKey].type === 'body') {
      if(jointSide.value === 'right') {
        return `right_${JOINT_OPTIONS[jointKey].dbName}`
      } else {
        return `left_${JOINT_OPTIONS[jointKey].dbName}`
      }
    }
    else if(JOINT_OPTIONS[jointKey].type === 'hand') {
      if(jointSide.value === 'right') {
        return `rh_${JOINT_OPTIONS[jointKey].dbName}`
      } else {
        return `lh_${JOINT_OPTIONS[jointKey].dbName}`
      }
    }
    else if(JOINT_OPTIONS[jointKey].type === 'foot') {
      if(jointSide.value === 'right') {
        return `rf_${JOINT_OPTIONS[jointKey].dbName}`
      } else {
        return `lf_${JOINT_OPTIONS[jointKey].dbName}`
      }
    }
    else return ''
  }



  const onJointChange = (key) => {
    setJointKey(key)
  }

  return (
    <div className="row" style={{width: '100%', alignSelf: 'flex-start', paddingLeft: 30}}>
      <div className="col-sm-8">
      <div className={Styles.chartClassOld} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h5 style={{width: '100%', textAlign: 'center', color: Constants.primaryTheme}}>Joint Pain</h5>
        <div className={Styles.chartSelectReproductive}>
				<div style={{width: '100%', height: 30}}>
					<Select
						isSearchable={false}
						className={Styles.selectStyle}
						classNamePrefix={Styles.selectStyleInnerGraph}
						autoBlur={true}
						options={[
							{label: 'Year', value: 'year'},
							{label: 'Month', value: 'month'},
							{label: 'Week', value: 'week'}
						]}
						value={chartRange}
						onChange={(val) => {
							setChartRange(val)
						}}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary25: '#20A89233',
								primary: '#20A892',
							},
						})}
					/>
				</div>
			</div>
        {renderChart(jointData, graphWidth, 'Joint Pain', 'Severity', getJointName, chartRange, setChartRange)}
      </div>
      </div>
      <div className="col" >
        <div className="col">
        <div style={{width: 100, height: 30, marginBottom: 20}}>
					<Select
						isSearchable={false}
						className={Styles.selectStyle}
						classNamePrefix={Styles.selectStyleInnerGraph}
						autoBlur={true}
            defaultValue={{label: 'Right', value: 'right'}}
						options={[
							{label: 'Right', value: 'right'},
							{label: 'Left', value: 'left'},
						]}
						value={jointSide}
						onChange={(val) => {
							setJointSide(val)
						}}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary25: '#20A89233',
								primary: '#20A892',
							},
						})}
					/>
				</div>
        <div className="radioButton" style={{height: 350, overflowY: 'scroll', borderStyle: 'ridge', padding: 10}}>
          {
            JOINT_OPTIONS.map((radioData, index)=>{
              return(
                <label className={Styles.radioConatinerNew} key={index.toString()}>{radioData.name}
                  <input
                    type="radio"
                    checked={radioData.key === jointKey}
                    onChange={()=> {
                      onJointChange(radioData.key)
                    }}
                    name="joint"
                  />
                  <span className={Styles.checkmark}></span>
                </label>
              )
            })
          }
        </div>
        </div>

      </div>
      
    </div>
  )
}
