const types = {
  TEST: 'TEST',
  FROM_WEB_FLOW: 'FROM_WEB_FLOW',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  ADD_PATIENT: 'ADD_PATIENT',
  ADD_PATIENT_LANDING_PAGE: 'ADD_PATIENT_LANDING_PAGE',
  ADD_FILLED_DATA: 'ADD_FILLED_DATA',
  ADD_SPARSE_DATA: 'ADD_SPARSE_DATA',
  ADD_AUTH_TOKEN: 'ADD_AUTH_TOKEN',
  ADD_FIREBASE_AUTH_USER: 'ADD_FIREBASE_AUTH_USER',
  ADD_FIREBASE_USER: 'ADD_FIREBASE_USER',
  ADD_MONGO_USER: 'ADD_MONGO_USER',
  ADD_PROVIDER_DATA: 'ADD_PROVIDER_DATA',
  ADD_TEAM_MONGO_USER: 'ADD_TEAM_MONGO_USER',
  ADD_PATIENT_CORE_DATA: 'ADD_PATIENT_CORE_DATA',
  SET_PATIENT_CARETEAM: 'SET_PATIENT_CARETEAM',
  ADD_PATIENT_MEDICATIONS: 'ADD_PATIENT_MEDICATIONS',
  ADD_PATIENT_CONDITIONS: 'ADD_PATIENT_CONDITIONS',
  ADD_PATIENT_GOALS: 'ADD_PATIENT_GOALS',
  ADD_PATIENT_PROGRESS_DATA: 'ADD_PATIENT_PROGRESS_DATA',
  ADD_PATIENT_CALCULATED_PROGRESS_DATA: 'ADD_PATIENT_CALCULATED_PROGRESS_DATA',
  ADD_PATIENT_WELLNESS_SCREENING_DATA: 'ADD_PATIENT_WELLNESS_SCREENING_DATA',
  UPDATE_PATIENT_LAST_REVIEWED: 'UPDATE_PATIENT_LAST_REVIEWED',
  UPDATE_SIDENAV_RENDER: 'UPDATE_SIDENAV_RENDER',
  ADD_PATIENT_LIST: 'ADD_PATIENT_LIST',
  UPDATE_DOCTOR_MONGO: 'UPDATE_DOCTOR_MONGO',
  ADD_E3: 'ADD_E3',
  ADD_DOCTOR_INVITES: 'ADD_DOCTOR_INVITES',
  UPDATE_TIME: 'UPDATE_TIME',
  ADD_RPM_PATIENT_PROFILE: 'ADD_RPM_PATIENT_PROFILE',
  ADD_USER_TYPE: 'ADD_USER_TYPE',
  ADD_ADMIN_ID: 'ADD_ADMIN_ID',
  ADD_CCFM_PATIENT_DATA: 'ADD_CCFM_PATIENT_DATA',
  ADD_PATIENT_LABS: 'ADD_PATIENT_LABS',
  ADD_NAVIGATION_OBJECT: 'ADD_NAVIGATION_OBJECT',
  ADD_APPOINTMENT_DATA: 'ADD_APPOINTMENT_DATA',
  GET_CARDIOVASCULAR_DATA: 'GET_CARDIOVASCULAR_DATA',
  GET_UNREAD_NOTIFICATION: 'GET_UNREAD_NOTIFICATION',
  GET_ALL_NOTIFICATION: 'GET_ALL_NOTIFICATION',
  TOGGLE_SIDE_BAR: 'TOGGLE_SIDE_BAR',
  TOGGLE_VIDEO_MODAL: 'TOGGLE_VIDEO_MODAL',
  TOGGLE_PATIENT_VIDEO_CALL: 'TOGGLE_PATIENT_VIDEO_CALL',
  ADD_VIDEO_APPOINTMENT_DATA: 'ADD_VIDEO_APPOINTMENT_DATA',
  ADD_TWILIO_TOKEN: 'ADD_TWILIO_TOKEN',
  TOGGLE_NOTES_MODAL: 'TOGGLE_NOTES_MODAL',
  APPOINTMENT_NOTES_DATA: 'APPOINTMENT_NOTES_DATA',
  ADD_CAREPLAN_DATA: 'ADD_CAREPLAN_DATA',
  ADD_RPM_ORDERS: 'ADD_RPM_ORDERS',
}

export default types
