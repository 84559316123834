import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import Table from '../../components/Table'
import Config from '../../config'
import { stateResidence } from '../../metadata'
import {
  addMongoUser,
  addPatient,
  addPatientLP,
  addPatientList,
  addRpmPatient,
  addVirgilE3,
  fetchPatientCalculateProgressData,
  fetchPatientConditions,
  fetchPatientCoreDate,
  fetchPatientLabs,
  fetchPatientMedications,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchRpmOrders,
  resetTimer,
  setPatientCoreData,
  startTimer,
  stopTimer,
  toggleSidebar,
  updateDoctorMongoUser,
  updatePatientData,
  updateSideNavBar,
  userLoggedIn,
} from '../../redux/actions'
import Constants from '../../values'
import PatientInsurance from '../PatientWeb/PatientAccount/PatientInsurance'
import GlobalStyles from '../styles/global.module.scss'
import { patientAssistanceFormColumns } from './Columns/patientAssisntaceFormColumns'
import Styles from './styles/Roster.module.scss'

const insuranceAddModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}

const LIMIT = 5

function PatientAssistanceFormTable(props) {
  const history = useHistory()
  const token = useSelector((state) => state.authReducer.token)
  const { adminId, loggedIn, mongoUser, firebaseUser } = useSelector(
    (state) => state.userReducer,
  )
  const { patient, patientList: patientPropList } = useSelector(
    (state) => state.patientReducer,
  )
  const [loading, setLoading] = useState(false)
  const [speciality, setSpeciality] = useState(null)
  const [memberType, setMemberType] = useState(null)
  const [patientType, setPatientType] = useState(null)
  const [patientState, setPatientState] = useState(null)
  const [patientData, setPatientData] = useState([])
  const [allPatientData, setAllPatientData] = useState([])
  const [allProviderList, setAllProviderList] = useState([])
  const [allProviderData, setAllProviderData] = useState([])
  const [communities, setCommunities] = useState([])
  const [tags, setTags] = useState([])
  const [patientList, setPatientList] = useState(patientPropList || [])
  const [insuranceModalOpen, setInsuranceModalOpen] = useState(false)
  const [editInsurance, setEditInsurance] = useState(null)
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(5)
  const [lastId, setLastId] = useState(null)
  const [next, setNext] = useState(false)
  const selectInputRef = useRef()

  useEffect(() => {
    // if (!loggedIn) {
    //   history.push('/login')
    //   return
    // }
    // setLoading(true)
    // getAllProviderList()
    // getCommunities()
    // getTags()
    // getUserData()
    // if (!patientList.length) {
    //   getPatientList()
    // }
  }, [])

  const dispatch = useDispatch()

  // const handleScroll = (e) => {
  //   const bottom =
  //     Math.round(e.currentTarget.scrollHeight) -
  //       Math.round(e.currentTarget.scrollTop) ===
  //     Math.round(e.currentTarget.clientHeight)
  //   if (bottom) {
  //     console.log('+++++++++Reached bottom++++++++++')
  //     getUserData()
  //   }
  //   return
  // }

  const getPatientList = () => {
    // const url =
    //   `${Config.BACKEND_URL}doctor/patients/list` +
    //   (mongoUser?.user_type?.toLowerCase() === 'administrator'
    //     ? '?access=admin'
    //     : '')
    // axios({
    //   method: 'get',
    //   url,
    //   headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    // })
    //   .then((response) => {
    //     // handle success
    //     if (response.status === 200) {
    //       const { data } = response
    //       let total = []
    //       data &&
    //         Object.keys(data).forEach((color) => {
    //           total = total.concat(data[color])
    //         })
    //       setPatientList(data)
    //       props.addPatientList(data)
    //     }
    //   })
    //   .catch((error) => {
    //     // handle error
    //     console.log('error when getting patient list', error, token)
    //   })
  }

  const getUserData = () => {
    // setLoading(true)
    // let config = {
    //   method: 'get',
    //   url: Config.BACKEND_URL + 'roster/patient',
    //   headers: {
    //     x_firebase_id: adminId,
    //     Authorization: `JWT ${token}`,
    //     // record_id: lastId,
    //   },
    //   //   limit: limit, skip: skip },
    // }
    // if (lastId) {
    //   config.headers.record_id = lastId
    // }
    // axios(config)
    //   .then(({ data }) => {
    //     console.log('recieved Patient Records')
    //     let last_record = data && data[data.length - 1]
    //     last_record && setLastId(last_record._id)
    //     let tempData = data
    //     tempData.forEach((user) => {
    //       if (user.firebase_id === 'pPuqrbHhGrSjw6vqLGZ4jd0lkK62')
    //         console.log('user: ', user)
    //       let insurancePlanType = []
    //       let cashPay = 'Insurance Pay'
    //       if (user?.insurance_type?.hmo) insurancePlanType.push('HMO')
    //       if (user?.insurance_type?.medi_adv)
    //         insurancePlanType.push('Medicare Advantage')
    //       if (user?.insurance_type?.workers_comp)
    //         insurancePlanType.push('Workers comp')
    //       if (user?.insurance_type?.cashpay) {
    //         cashPay = 'Cash Pay'
    //       }
    //       if (user?.medicare) insurancePlanType.push('Medicare')
    //       if (user?.medicaid) insurancePlanType.push('Medicaid')
    //       insurancePlanType = insurancePlanType.toString()
    //       user.insurancePlanType = insurancePlanType
    //       user.cashPay = cashPay
    //     })
    //     setPatientData(tempData)
    //     setAllPatientData(tempData)
    //     // setPatientData(patientData.concat(data))
    //     // setAllPatientData(allPatientData.concat(data))
    //     setLoading(false)
    //     // console.log(patientData)
    //     // updateSuggesticData(data)
    //   })
    //   .catch((err) => {
    //     console.log('err when fetching all users data: ', err)
    //     setLoading(false)
    //   })
  }

  const getAllProviderList = async () => {
    // let config = {
    //   method: 'get',
    //   url: Config.BACKEND_URL + 'providers',
    //   headers: {
    //     x_firebase_id: adminId,
    //     Authorization: 'JWT ' + token,
    //     getallproviders: true,
    //   },
    // }
    // axios(config)
    //   .then(({ data }) => {
    //     let providerOptionList = []
    //     let allProviderData = data.reduce((data, doc) => {
    //       data[doc.user_type || 'Other'] = data[doc.user_type || 'Other'] || []
    //       data[doc.user_type || 'Other'].push({
    //         label: doc?.first_name + ' ' + doc?.last_name,
    //         value: doc?.firebase_id,
    //       })
    //       return data
    //     }, Object.create(null))
    //     Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
    //       providerOptionList.push({
    //         label: userType,
    //         options: doctorArray,
    //       })
    //     })
    //     setAllProviderList(providerOptionList)
    //     setAllProviderData(data)
    //   })
    //   .catch((err) => {
    //     console.log('err when fetching all provider data: ', err)
    //   })
  }

  const getCommunities = () => {
    // axios({
    //   method: 'get',
    //   url: Config.BACKEND_URL + 'communities',
    //   headers: {
    //     Authorization: `JWT ${token}`,
    //     x_firebase_id: adminId,
    //   },
    // })
    //   .then(({ data }) => {
    //     setCommunities([...data?.otherCommunities, ...data?.userCommunities])
    //   })
    //   .catch((err) => console.log('error when getting communtieis', err))
  }

  const getTags = () => {
    // axios({
    //   method: 'get',
    //   url: Config.BACKEND_URL + 'tags',
    //   headers: {
    //     Authorization: `JWT ${token}`,
    //     x_firebase_id: adminId,
    //   },
    // })
    //   .then(({ data }) => {
    //     console.log(data)
    //     setTags(data)
    //   })
    //   .catch((err) => console.log('error when getting communtieis', err))
  }

  // const filterByNameEmail = (query) => {
  //   let filteredData = allPatientData
  //   // setLoading(true)

  //   // if (query.length) {
  //   //   filteredData = filteredData.filter((user) => {
  //   //     let patientName = user.firstName + ' ' + user.lastName
  //   //     if (user?.email?.toLowerCase()?.includes(query?.toLowerCase()))
  //   //       return user
  //   //     else if (patientName?.toLowerCase()?.includes(query?.toLowerCase()))
  //   //       return user
  //   //   })
  //   // }
  //   if (patientState?.value) {
  //     filteredData = filteredData.filter((user) => {
  //       let state = null
  //       let { addressComponents, zipCodeData } = user
  //       if (addressComponents && addressComponents.length > 0) {
  //         addressComponents.forEach((addressComponent) => {
  //           let types = addressComponent.types || []
  //           if (types.includes('administrative_area_level_1'))
  //             state = addressComponent.short_name
  //         })
  //       } else {
  //         state = zipCodeData?.state
  //       }
  //       if (
  //         state &&
  //         state?.toLowerCase() === patientState?.abbreviation?.toLowerCase()
  //       )
  //         return user
  //     })
  //   }
  //   if (memberType?.value) {
  //     if ('all' !== memberType?.value) {
  //       filteredData = filteredData.filter((user) => {
  //         let type = user?.coaching?.active
  //           ? user?.coaching?.plan || 'membership'
  //           : 'basic'
  //         if (type === memberType?.value) return user
  //       })
  //     }
  //   }
  //   if (patientType?.value) {
  //     if (patientType?.value === 'new') {
  //       filteredData = filteredData.filter((user) => {
  //         if (
  //           user.appointments.length === 0 ||
  //           !user.appointments.filter(
  //             (appt) =>
  //               allProviderData.find(
  //                 (item) => item.firebase_id === appt.doctor_id,
  //               ).user_type === 'Physician',
  //           ).length
  //         )
  //           return user
  //       })
  //     } else if (patientType?.value === 'established') {
  //       filteredData = filteredData.filter((user) => {
  //         if (user?.appointments.length) {
  //           let temp = user.appointments.filter(
  //             (appt) =>
  //               allProviderData.find(
  //                 (item) => item.firebase_id === appt.doctor_id,
  //               ).user_type === 'Physician',
  //           )
  //           if (temp.length) return user
  //         }
  //       })
  //     }
  //   }
  //   setPatientData(filteredData)
  // }

  const clearFilter = () => {
    setPatientData(allPatientData)
    setMemberType(null)
    setPatientType(null)
    setPatientState(null)
  }

  const insuranceEdit = (patient) => {
    // console.log(patient)
    props.fetchPatientCoreDate(patient.uid, props.token)
    props.addPatient(patient)
    setEditInsurance(patient?.insurances)
    dispatch(toggleSidebar(true))
    setInsuranceModalOpen(true)
  }

  const addDoctor = (doctorID, patientID) => {
    // console.log('add doc: ', doctorID, patientID)
    // let config = {
    //   method: 'post',
    //   url: Config.BACKEND_URL + 'providers/patients',
    //   headers: {
    //     x_firebase_id: adminId,
    //     Authorization: 'JWT ' + token,
    //   },
    //   data: {
    //     doctor_id: doctorID,
    //     patient_id: patientID,
    //   },
    // }
    // axios(config)
    //   .then(() => {
    //     console.log('coach assigned')
    //     getUserData()
    //   })
    //   .catch((err) => console.log('error while assigning the coach: ', err))
  }

  const removeDoctor = (doctorID, patientID) => {
    // console.log('remove doc: ', doctorID, patientID)
    // let config = {
    //   method: 'delete',
    //   url: Config.BACKEND_URL + 'patients/providers',
    //   headers: {
    //     Authorization: 'JWT ' + token,
    //     x_firebase_id: patientID,
    //     'Content-Type': 'application/json',
    //   },
    //   data: {
    //     doctor_id: doctorID,
    //     patient_id: patientID,
    //   },
    // }
    // axios(config)
    //   .then(() => {
    //     console.log('coach removed')
    //     getUserData()
    //   })
    //   .catch((err) => console.log('error while deleting the coach: ', err))
  }

  const addCommunity = (communityID, patientID) => {
    // console.log('add COMMUNITY: ', communityID, patientID)
    // let config = {
    //   method: 'post',
    //   url: Config.BACKEND_URL + 'communities/user',
    //   headers: {
    //     Authorization: 'JWT ' + token,
    //   },
    //   data: {
    //     firebase_id: patientID,
    //     community_id: communityID,
    //   },
    // }
    // axios(config)
    //   .then(() => {
    //     console.log('COMMUNITY assigned')
    //     getCommunities()
    //     getUserData()
    //   })
    //   .catch((err) => console.log('error while assigning the COMMUNITY: ', err))
  }

  const removeCommunity = (communityID, patientID) => {
    // console.log('DELETE COMMUNITY: ', communityID, patientID)
    // let config = {
    //   method: 'delete',
    //   url: Config.BACKEND_URL + 'communities/user',
    //   headers: {
    //     Authorization: 'JWT ' + token,
    //     'Content-Type': 'application/json',
    //   },
    //   data: {
    //     firebase_id: patientID,
    //     community_id: communityID,
    //   },
    // }
    // axios(config)
    //   .then(() => {
    //     console.log('COMMUNITY DELETED')
    //     getCommunities()
    //     getUserData()
    //   })
    //   .catch((err) => console.log('error while DEL the COMMUNITY: ', err))
  }

  const addTags = (tagID, patientID) => {
    console.log('add tag: ', tagID, patientID)
    // let config = {
    //   method: 'post',
    //   url: Config.BACKEND_URL + 'tags/user',
    //   headers: {
    //     Authorization: 'JWT ' + token,
    //   },
    //   data: {
    //     firebase_id: patientID,
    //     tags_id: tagID,
    //   },
    // }
    // axios(config)
    //   .then(() => {
    //     console.log('Tag assigned')
    //     getTags()
    //     getUserData()
    //   })
    //   .catch((err) => console.log('error while assigning the Tag: ', err))
  }

  const removeTags = (tagID, patientID) => {
    console.log('DELETE Tag: ', tagID, patientID)
    // let config = {
    //   method: 'delete',
    //   url: Config.BACKEND_URL + 'tags/user',
    //   headers: {
    //     Authorization: 'JWT ' + token,
    //     'Content-Type': 'application/json',
    //   },
    //   data: {
    //     firebase_id: patientID,
    //     tags_id: tagID,
    //   },
    // }
    // axios(config)
    //   .then(() => {
    //     console.log('Tag DELETED')
    //     getTags()
    //     getUserData()
    //   })
    //   .catch((err) => console.log('error while DEL the Tag: ', err))
  }

  const navigatetoPatientProfile = (p_id) => {
    console.log('testing on click: ', patientList, p_id)
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (props.patientTimer && props.patientTimer !== 0)
              props.resetTimer(patient.uid, adminId, token)
            else props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            props.fetchPatientProgressData(patient.uid, props.token, timeline)
            props.fetchPatientCoreDate(patient.uid, props.token)
            props.fetchRpmOrders(patient, props.token)
            props.fetchPatientWellnessScreening(patient.uid, props.token)
            props.fetchPatientCalculateProgressData(patient.uid, props.token)
            props.fetchPatientConditions(patient.uid, props.token)
            props.fetchPatientMedications(patient.uid, props.token)
            props.fetchPatientLabs(patient.uid, props.token)
            props.addPatient(patient, colorcode)
            props.updateSideNavBar(!props.updateRender)
          }
        })
      }
    })
  }

  const onPatientNavLinkClicked = (lp) => {
    props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  const renderInsuranceModal = () => {
    let primary_insurance = Array.isArray(editInsurance)
      ? editInsurance?.find((ins) => ins.preference === 1) || null
      : editInsurance || null

    let secondary_insurance = Array.isArray(editInsurance)
      ? editInsurance?.find((ins) => ins.preference === 2)
      : {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            dispatch(toggleSidebar(false))
            setInsuranceModalOpen(false)
          }}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit Patient Insurance
        </h4>
        <PatientInsurance
          parentCallback={() => {
            dispatch(toggleSidebar(false))
            setInsuranceModalOpen(false)
            getUserData()
          }}
          primaryInsurance={primary_insurance}
          secondaryInsurance={secondary_insurance}
          fromProvider={true}
        />
      </div>
    )
  }

  if (loading) {
    return (
      <div className={GlobalStyles.container} style={{ height: '100vh' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.divider}>
      <h4 style={{ color: Constants.primaryTheme }}>Patient Assistance Form</h4>
      <div className={GlobalStyles.contentWrapper}>
        <Table
          columns={patientAssistanceFormColumns(
            allProviderData,
            allProviderList,
            communities,
            tags,
            addDoctor,
            removeDoctor,
            addCommunity,
            removeCommunity,
            navigatetoPatientProfile,
            onPatientNavLinkClicked,
            insuranceEdit,
            addTags,
            removeTags,
          )}
          data={[
            {
              patientName: 'Dwayne Bold',
              medication: 'Humira',
              pfa: 'Humira PAP',
              pharma_program: 'Humira PAP',
              status: 'Approved',
            },
            {
              patientName: 'Alex Chu',
              medication: 'Xeljanz',
              pfa: 'Xeljanz PAP',
              pharma_program: 'Xeljanz PAP',
              status: 'Declined',
            },
            {
              patientName: 'Adam John',
              medication: 'Tysabri',
              pfa: 'Tysabri PAP',
              pharma_program: 'Tysabri PAP',
              status: 'Approved',
            },
            {
              patientName: 'John West',
              medication: 'Humira',
              pfa: 'Humira PAP',
              pharma_program: 'Humira PAP',
              status: 'Approved',
            },
            {
              patientName: 'Claire Clarke',
              medication: 'Xeljanz',
              pfa: 'Xeljanz PAP',
              pharma_program: 'Xeljanz PAP',
              status: 'Pending',
            },
            // {
            //   patientName: 'Test',
            //   medication: 'Test1',
            //   pfa: 'pfa',
            //   pharma_program: 'pharma',
            //   status: 'status',
            // },
          ]}
        />
        {/* INSURANCE MODAL
        <Modal
          ariaHideApp={false}
          isOpen={insuranceModalOpen}
          onRequestClose={() => {
            dispatch(toggleSidebar(false))
            setInsuranceModalOpen(false)
            getUserData()
          }}
          style={insuranceAddModalStyles}
          contentLabel="Modal"
        >
          {renderInsuranceModal()}
        </Modal> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state /* , ownProps */) => ({
  patient: state.patientReducer.patient,
  patientLP: state.patientReducer.lp,
  loggedIn: state.userReducer.loggedIn,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  mongoUser: state.userReducer.mongoUser,
  e3: state.authReducer.e3,
  patientTimer: state.patientReducer.timer,
  rpmPatientId: state.patientReducer.rpmPatientId,
  adminId: state.userReducer.adminId,
  userType: state.userReducer.userType,
  updateRender: state.userReducer.render,
  firebaseUser: state.userReducer.firebaseUser,
})

const mapDispatchToProps = {
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  addPatientLP,
  fetchRpmOrders,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientAssistanceFormTable)
