/* eslint-disable */
import moment from 'moment'
import React, { useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Constants from '../../../../values'
import GlobalStyles from '../../../styles/global.module.scss'
import Styles from '../styles/profile.module.scss'

import {} from '@fortawesome/free-regular-svg-icons'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import Config from '../../../../config'

const axios = require('axios')

export const RenderAddFoodSensitivitiesManually = ({
  onManualAddModalHide,
  getProviderFoodSensitivities,
  currentAddModalData,
  patientId,
}) => {
  console.log('INSIDE::::::::', currentAddModalData)
  const token = useSelector((state) => state.authReducer.token)
  const patient = patientId
    ? { patient_id: patientId }
    : useSelector((state) => state.patientReducer.patient)
  const doctor_id = useSelector((state) => state.userReducer.adminId)
  const doctor_details = useSelector((state) => state.userReducer.mongoUser)
  const appointmentData = useSelector(
    (state) => state.appointmentReducer.appointmentData,
  )
  const showVideoModal = useSelector(
    (state) => state.genericReducer.showVideoModal,
  )

  const [food, setFood] = useState(
    currentAddModalData?.code?.coding
      ? {
          code: currentAddModalData?.code?.coding?.[0]?.code,
          label: currentAddModalData?.code?.coding?.[0]?.display,
        }
      : {},
  )
  const [clinicalStatus, setClinicalStatus] = useState(
    currentAddModalData?.clinicalStatus?.coding
      ? {
          code: currentAddModalData?.clinicalStatus?.coding?.[0]?.code,
          label: currentAddModalData?.clinicalStatus?.coding?.[0]?.display,
        }
      : {},
  )
  const [verificationStatus, setVerificationStatus] = useState(
    currentAddModalData?.verificationStatus?.coding
      ? {
          code: currentAddModalData?.verificationStatus?.coding?.[0]?.code,
          label: currentAddModalData?.verificationStatus?.coding?.[0]?.display,
        }
      : {},
  )
  const [criticality, setCriticality] = useState(
    currentAddModalData?.criticality
      ? {
          code: currentAddModalData?.criticality,
          label: currentAddModalData?.criticality,
        }
      : {},
  )
  const [foodStartDate, setFoodStartDate] = useState(
    currentAddModalData?.onsetDateTime || '',
  )
  const [foodLastOccurenceDate, setFoodLastOccurenceDate] = useState(
    currentAddModalData?.lastOccurrence || '',
  )
  const [noteValue, setNoteValue] = useState(
    currentAddModalData?.note?.[0] || '',
  )

  const onClickSave = () => {
    if (!food?.label) {
      toast.error('Please select Food Sensitivities')
      return
    }

    let data = {
      patient_id: patient?.patient_id,
      doctor_id: doctor_id,
      name: food,
      resourceType: 'FoodSensitivities',
      clinicalStatus: {
        coding: [
          {
            // system : "http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical", // Identity of the terminology system
            code: clinicalStatus.code, // Symbol in syntax defined by the system
            display: clinicalStatus.label, // Representation defined by the system
          },
        ],
      },
      verificationStatus: {
        coding: [
          {
            // system : "http://terminology.hl7.org/CodeSystem/allergyintolerance-verification", // Identity of the terminology system
            code: verificationStatus.code, // Symbol in syntax defined by the system
            display: verificationStatus.label, // Representation defined by the system
          },
        ],
      },
      code: {
        coding: [
          {
            // system : "http://snomed.info/sct", // Identity of the terminology system
            code: food.code, // Symbol in syntax defined by the system
            display: food.label, // Representation defined by the system
          },
        ],
      },
      criticality: criticality.label,
      patient: {
        reference: `Patient/${patient?.patient_id}`,
        // display: `${patient?.first_name} ${patient?.last_name}`
      },
      onsetDateTime: foodStartDate,
      lastOccurrence: foodLastOccurenceDate,
      recorder: {
        reference: `${doctor_details.user_type}/${doctor_id}`,
        // display: `${doctor_details.first_name} ${doctor_details.last_name}`
      },
      note: [noteValue],
      recordedDate: new Date(),
      ...(appointmentData?.event_id &&
        showVideoModal && { appointment_event_id: appointmentData?.event_id }),
    }

    let config = {
      method: currentAddModalData ? 'put' : 'post',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
      url:
        Config.BACKEND_URL +
        (currentAddModalData?._id
          ? `fhir/FoodSensitivity/${currentAddModalData?._id}`
          : 'fhir/FoodSensitivity'),
      data: data,
    }

    axios(config)
      .then((resp) => {
        onManualAddModalHide()
        getProviderFoodSensitivities()
        console.log('SAVED DATA: ', data)
      })
      .catch((err) => {
        console.log('error when getting saving provider sens food data', err)
      })
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div
        style={{
          height: '90%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => onManualAddModalHide()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Food Sensitivity
        </h4>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Food Sensitivity: </p>
          <Select
            options={[
              { code: 0, label: 'Gluten' },
              { code: 1, label: 'Sugar' },
              { code: 2, label: 'Alcohol' },
              { code: 3, label: 'Dairy' },
              { code: 4, label: 'Nuts' },
              { code: 5, label: 'Soy' },
              { code: 6, label: 'Fish' },
              { code: 7, label: 'Eggs' },
              { code: 10, label: 'Other' },
            ]}
            isSearchable={true}
            placeholder="Select food sensitivity"
            onChange={(val) => setFood(val)}
            value={food}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Clinical Status: </p>
          <Select
            options={[
              { code: 0, label: 'Active' },
              { code: 1, label: 'Inactive' },
              { code: 2, label: 'Resolved' },
            ]}
            isSearchable={true}
            placeholder="Select status"
            onChange={(val) => setClinicalStatus(val)}
            value={clinicalStatus}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Verification Status: </p>
          <Select
            options={[
              { code: 0, label: 'Unconfirmed' },
              { code: 1, label: 'Confirmed' },
              { code: 2, label: 'Refuted' },
              { code: 3, label: 'Entered-in-error' },
            ]}
            isSearchable={true}
            placeholder="Select status"
            onChange={(val) => setVerificationStatus(val)}
            value={verificationStatus}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Criticality: </p>
          <Select
            options={[
              { code: 'low', label: 'Low' },
              { code: 'high', label: 'High' },
              { code: 'unable-to-access', label: 'Unable to access' },
            ]}
            isSearchable={true}
            placeholder="Select criticality"
            onChange={(val) => setCriticality(val)}
            value={criticality}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Start Date: </p>
          <input
            placeholder={
              'Please enter estimated or actual date the sensitivity began'
            }
            className={Styles.addManualTextInput}
            type="date"
            max={moment(new Date()).format('YYYY-MM-DD')}
            value={moment(foodStartDate).format('YYYY-MM-DD')}
            onChange={(e) => setFoodStartDate(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Last Occurence: </p>
          <input
            placeholder={
              'Please enter estimated date that the sensitivity last occured'
            }
            className={Styles.addManualTextInput}
            type="date"
            max={moment(new Date()).format('YYYY-MM-DD')}
            value={moment(foodLastOccurenceDate).format('YYYY-MM-DD')}
            onChange={(e) => setFoodLastOccurenceDate(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Note: </p>
          <textarea
            placeholder={'Please enter notes about sesitivity'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '70%',
              // height: '40px',
            }}
            rows="2"
            type="text"
            value={noteValue}
            onChange={(e) => setNoteValue(e.target.value)}
          />
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => onClickSave()}
          className={GlobalStyles.button}
          // disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}
