import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { SPECIALITY_OPTIONS, userTypes } from '../../../metadata'

export const patientTimeSpentColumns = (
  monitoringTime,
  supportRequestTime,
  allCommunications,
  searchDate,
) => {
  return [
    {
      Header: 'Patient Name',
      accessor: (obj) => obj?.firstName + ' ' + obj?.lastName,
      //   Cell: ({ row }) => {
      //     let patientData = row?.original
      //     return (
      //       <Link
      //         onClick={() => {
      //           navigatetoPatientProfile(row?.original?.uid)
      //           onPatientNavLinkClicked('profile')
      //         }}
      //         to={{
      //           pathname: '/patient/profile',
      //           state: 'info',
      //           key: 2,
      //         }}
      //         className={Styles.link}
      //       >
      //         {patientData?.firstName || ''} {patientData?.lastName || ''}
      //       </Link>
      //     )
      //   },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
    },
    {
      Header: 'Number of Visits',
      accessor: (obj) => {
        let careplan = obj.careplan || []
        return careplan.length === 0 ? '-' : careplan.length
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Consult Time',
      accessor: (obj) => {
        let careplan = obj.careplan || []
        let consult_time = careplan.reduce(
          (a, b) =>
            a +
            (moment
              .unix(
                (b.appointment_end_date || b.appointment_data?.visit_end_time) /
                  1000,
              )
              .diff(
                moment.unix(
                  (b.appointment_date || b.appointment_data?.visit_start_time) /
                    1000,
                ),
                'minute',
              ) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Review of Records',
      accessor: (obj) => {
        let careplan = obj.careplan || []
        let consult_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.pre_review_time) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Documentation Time',
      accessor: (obj) => {
        let careplan = obj.careplan || []
        let consult_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.after_visit) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'RPM Time',
      accessor: (obj) => {
        let monitoring_log =
          monitoringTime.filter(
            (time) =>
              time.patient_id === obj?.firebase_id &&
              moment(time.created_at).isSame(moment(searchDate), 'month') &&
              moment(time.created_at).isSame(moment(searchDate), 'year'),
          ) || []
        let consult_time = monitoring_log.reduce(
          (a, b) => a + (Math.floor(Number(b?.time) / 60000) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'CCM Time',
      accessor: 'ccm_time',
      width: 200,
      sort: true,
    },
    {
      Header: 'Task Time',
      accessor: (obj) => {
        let support_tickets =
          supportRequestTime.filter(
            (time) =>
              time.patient_id === obj?.firebase_id &&
              moment(time.created_at).isSame(moment(searchDate), 'month') &&
              moment(time.created_at).isSame(moment(searchDate), 'year'),
          ) || []
        let consult_time = support_tickets.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Communications Time',
      accessor: (obj) => {
        let communications =
          allCommunications.filter(
            (time) =>
              time.patient_id === obj?.firebase_id &&
              moment(time.created_at).isSame(moment(searchDate), 'month') &&
              moment(time.created_at).isSame(moment(searchDate), 'year'),
          ) || []
        let consult_time = communications.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Total Time',
      accessor: (obj) => {
        let communications =
          allCommunications.filter(
            (time) =>
              time.patient_id === obj?.firebase_id &&
              moment(time.created_at).isSame(moment(searchDate), 'month') &&
              moment(time.created_at).isSame(moment(searchDate), 'year'),
          ) || []
        let communications_time = communications.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        let support_tickets =
          supportRequestTime.filter(
            (time) =>
              time.patient_id === obj?.firebase_id &&
              moment(time.created_at).isSame(moment(searchDate), 'month') &&
              moment(time.created_at).isSame(moment(searchDate), 'year'),
          ) || []
        let support_tickets_time = support_tickets.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        let monitoring_log =
          monitoringTime.filter(
            (time) =>
              time.patient_id === obj?.firebase_id &&
              moment(time.created_at).isSame(moment(searchDate), 'month') &&
              moment(time.created_at).isSame(moment(searchDate), 'year'),
          ) || []
        let monitoring_log_time = monitoring_log.reduce(
          (a, b) => a + (Math.floor(Number(b?.time) / 60000) || 0),
          0,
        )
        let careplan = obj.careplan || []
        let careplan_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.after_visit) || 0),
          0,
        )
        let records_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.pre_review_time) || 0),
          0,
        )
        let consult_time = careplan.reduce(
          (a, b) =>
            a +
            (moment
              .unix(
                (b.appointment_end_date || b.appointment_data?.visit_end_time) /
                  1000,
              )
              .diff(
                moment.unix(
                  (b.appointment_date || b.appointment_data?.visit_start_time) /
                    1000,
                ),
                'minute',
              ) || 0),
          0,
        )
        let total_time =
          consult_time +
          records_time +
          careplan_time +
          monitoring_log_time +
          support_tickets_time +
          communications_time
        return total_time === 0 ? '-' : total_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'No Show Time(in mins)',
      accessor: (obj) => {
        let sum = 0
        if (obj?.no_show_events)
          for (let i = 0; i < obj?.no_show_events.length; i++) {
            sum += obj?.no_show_events[i].time
          }
        return sum
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Revenue Hours',
      accessor: (obj) => {
        let communications =
          allCommunications.filter(
            (time) =>
              time.patient_id === obj?.firebase_id &&
              moment(time.created_at).isSame(moment(searchDate), 'month') &&
              moment(time.created_at).isSame(moment(searchDate), 'year'),
          ) || []
        let communications_time = communications.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        let support_tickets =
          supportRequestTime.filter(
            (time) =>
              time.patient_id === obj?.firebase_id &&
              moment(time.created_at).isSame(moment(searchDate), 'month') &&
              moment(time.created_at).isSame(moment(searchDate), 'year'),
          ) || []
        let support_tickets_time = support_tickets.reduce(
          (a, b) => a + (Number(b?.time_spent) || 0),
          0,
        )
        let monitoring_log =
          monitoringTime.filter(
            (time) =>
              time.patient_id === obj?.firebase_id &&
              moment(time.created_at).isSame(moment(searchDate), 'month') &&
              moment(time.created_at).isSame(moment(searchDate), 'year'),
          ) || []
        let monitoring_log_time = monitoring_log.reduce(
          (a, b) => a + (Math.floor(Number(b?.time) / 60000) || 0),
          0,
        )
        let careplan = obj.careplan || []
        let careplan_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.after_visit) || 0),
          0,
        )
        let records_time = careplan.reduce(
          (a, b) => a + (Number(b?.time_spent?.pre_review_time) || 0),
          0,
        )
        let consult_time = careplan.reduce(
          (a, b) =>
            a +
            (moment
              .unix(
                (b.appointment_end_date || b.appointment_data?.visit_end_time) /
                  1000,
              )
              .diff(
                moment.unix(
                  (b.appointment_date || b.appointment_data?.visit_start_time) /
                    1000,
                ),
                'minute',
              ) || 0),
          0,
        )
        let total_time =
          consult_time +
          records_time +
          careplan_time +
          monitoring_log_time +
          support_tickets_time +
          communications_time
        let invoice_hours =
          Math.floor(total_time / 60) + ':' + (total_time % 60)
        return invoice_hours === 0 ? '-' : invoice_hours
      },
      width: 200,
      sort: true,
    },
  ]
}
