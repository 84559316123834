import React, { Component } from 'react'
import 'react-chat-elements/dist/main.css'
import Header from '../../components/Header'
import GlobalStyles from '../styles/global.module.scss'
import Constants from '../../values.js'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import Config from '../../config'
import Styles from './styles/Notifications.module.scss'
import { Link, NavLink } from 'react-router-dom'
import moment from 'moment'
import {
  getUnreadMessages,
  addPatientLP,
  addRpmPatient,
  setPatientCoreData,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
} from '../../redux/actions'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'

const axios = require('axios')

const MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '70%',
    height: '90vh',
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      i: 0,
      patientList: [],
      loading: true,
      pchats: [],
      pmessages: [],
      careteam: [],
      showReadNotificationModal: false,
    }
    this.patientListFull = []
    this.unreadCareteamChat = []
    this.unreadMessages = []
    this.unreadChat = []
    this.providerListFull = []
    this.active_index_arr = []
    this.preparePatientList = this.preparePatientList.bind(this)
    this.getUnreadMessages = this.getUnreadMessages.bind(this)
    this.getUnreadCareteamChat = this.getUnreadCareteamChat.bind(this)
    this.getProviderNames = this.getProviderNames.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.preparePatientList()
    this.getUnreadMessages()
    // this.getUnreadCareteamChat()
    this.getProviderNames()
  }

  navigatetoCareplan(p_id) {
    let { mongoUser, token, adminId } = this.props
    let { patientList } = this.props
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients, index) => {
      if (colorCodedPatients.length > 0) {
        let result = colorCodedPatients.filter(
          (patient) => patient.uid === p_id,
        )
        console.log('RESUKT ARRAY : ', result)
        result.forEach((patient) => {
          let colorcode = Object.keys(patientList)[index]
          if (patient.uid === p_id) {
            this.props.updatePatientData(
              {
                doctor_id: adminId,
                patient_id: patient.uid,
                last_reviewed: new Date(),
              },
              token,
            )

            //if the doctor was already on on another patient page and just clicked on a new patient, reset time
            if (this.props.patientTimer && this.props.patientTimer !== 0)
              this.props.resetTimer(patient.uid, adminId, token)
            else this.props.startTimer(patient.uid)
            let preferences = patient.preferences
            let timeline = 'complete'
            if (preferences && preferences.timeline) {
              timeline = preferences.timeline.value
            }
            this.props.fetchPatientProgressData(
              patient.uid,
              this.props.token,
              timeline,
            )
            this.props.fetchPatientCoreDate(patient.uid, this.props.token)
            this.props.fetchPatientWellnessScreening(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientCalculateProgressData(
              patient.uid,
              this.props.token,
            )
            this.props.fetchPatientConditions(patient.uid, this.props.token)
            this.props.fetchPatientMedications(patient.uid, this.props.token)
            this.props.fetchPatientLabs(patient.uid, this.props.token)
            this.props.addPatient(patient, colorcode)
            this.props.updateSideNavBar(!this.props.updateRender)
          }
        })
      }
    })
  }

  onPatientNavLinkClicked(lp) {
    this.props.addPatientLP(lp)
    console.log('PATIENT LANDING PAGE: ', lp)
  }

  preparePatientList() {
    let { patientList } = this.props
    if (!patientList) return

    Object.values(patientList).forEach((colorCodedPatients) => {
      if (colorCodedPatients.length > 0) {
        colorCodedPatients.forEach((patient) => {
          this.patientListFull.push(patient)
        })
      }
    })
    console.log('PATIENT LIST FULL: ', this.patientListFull)
  }

  getUnreadMessages() {
    let { mongoUser, token, adminId } = this.props
    let { pmessages } = this.state
    let self = this
    let url = Config.BACKEND_URL + 'provider/messages'
    this.setState({ loading: true })
    this.props.getUnreadMessages(adminId, token, mongoUser)
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_doctor_id: adminId },
    })
      .then(({ data }) => {
        this.setState({ active_index_arr: data })
        data.forEach((m, index) => {
          if (m.unread_doc != 0) {
            this.setState({ pmessages: [...this.state.pmessages, m] })
          }
        })
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('error when getting messages from backend', err)
      })
  }

  getProviderNames() {
    let { token } = this.props
    let url = Config.BACKEND_URL + 'patients/providers'
    let patientList = [...this.patientListFull]
    patientList.forEach((patient) => {
      axios({
        method: 'get',
        url: url,
        headers: {
          Authorization: 'JWT ' + token,
          x_firebase_id: patient.uid,
        },
      })
        .then(({ data }) => {
          if (data) {
            data.forEach((p) => {
              patientList.push({
                pid: p.doctor_id,
                name: p.first_name + ' ' + p.last_name,
              })
            })
          }
        })
        .catch((err) => {
          console.log('error when getting messages from backend', err)
        })
    })
    this.providerListFull = patientList
    // console.log('PROVIDER LIST FULL: ', this.providerListFull)
  }

  getUnreadCareteamChat() {
    let { mongoUser, patient, token } = this.props
    let { pchats } = this.state
    let self = this
    let url = Config.BACKEND_URL + 'careteam/chat'
    this.setState({ loading: true })
    this.patientListFull.forEach((patient) => {
      axios({
        method: 'get',
        url: url,
        headers: {
          Authorization: 'JWT ' + token,
          x_patient_id: patient.uid,
        },
      })
        .then(({ data }) => {
          if (data) {
            if (data.providers[mongoUser.firebase_id].unread === true) {
              this.setState({ pchats: [...this.state.pchats, data] })
            }
          }
        })
        .catch((err) => {
          console.log('error when getting messages from backend', err)
        })
    })
  }

  readAllNotifications() {
    let { mongoUser, token, adminId, unreadNotifications } = this.props
    let unreadNotificationIdsArray = []

    unreadNotifications &&
      unreadNotifications.length &&
      unreadNotifications.forEach((notification) => {
        if (notification?.message && !notification?.read_by?.[adminId]) {
          unreadNotificationIdsArray.push(notification?._id)
        }
      })

    let config = {
      method: 'put',
      url: `${Config.BACKEND_URL}read-all-notifications`,
      headers: {
        x_firebase_id: mongoUser.firebase_id,
        Authorization: `JWT ${token}`,
      },
      data: {
        firebase_id: mongoUser.firebase_id,
        notificationIdArray: unreadNotificationIdsArray,
      },
    }

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          console.log('marked read')
          this.props.getUnreadMessages(adminId, token, mongoUser)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  markReadNotification(notification_id) {
    let { mongoUser, token, adminId } = this.props
    let config = {
      method: 'put',
      url: `${Config.BACKEND_URL}notifications`,
      headers: {
        x_firebase_id: mongoUser.firebase_id,
        Authorization: `JWT ${token}`,
      },
      data: {
        firebase_id: mongoUser.firebase_id,
        notification_id: notification_id,
      },
    }

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          console.log('marked read')
          this.props.getUnreadMessages(adminId, token, mongoUser)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  displayType(type) {
    switch (type) {
      case 'CircleList':
        return 'Live circle'

      case 'Appointments':
        return 'Appointment'

      case 'Careplan':
        return 'Care Team'

      default:
        return 'Notification'
    }
  }

  navigateRoute(notification) {
    console.log(notification?.type_no)
    switch (notification?.type_no) {
      case 'Patient Message':
        return (
          <NavLink
            style={{ color: Constants.primaryTheme }}
            activeStyle={{ color: 'black' }}
            to={{
              pathname: '/messages',
              ai: notification.puid,
              // this.state.active_index_arr.findIndex(
              //   (p) => p.patient_id === notification.puid,
              // ),
            }}
          >
            View
          </NavLink>
        )

      case 'Live circle':
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <NavLink
              onClick={() => this.markReadNotification(notification?._id)}
              style={{ color: Constants.primaryTheme }}
              activeStyle={{ color: 'black' }}
              to={{
                pathname: '/admin',
                state: 'events',
                key: 2,
              }}
            >
              View
            </NavLink>
            {!notification?.read && (
              <p
                onClick={() => this.markReadNotification(notification?._id)}
                style={{
                  color: Constants.primaryTheme,
                  marginLeft: 20,
                  cursor: 'pointer',
                }}
                className={Styles.clickableText}
              >
                Mark Read
              </p>
            )}
          </div>
        )

      case 'Appointment':
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <NavLink
              onClick={() => this.markReadNotification(notification?._id)}
              style={{ color: Constants.primaryTheme }}
              activeStyle={{ color: 'black' }}
              to={{
                pathname: '/appointments',
              }}
            >
              View
            </NavLink>
            {!notification?.read && (
              <p
                onClick={() => this.markReadNotification(notification?._id)}
                style={{
                  color: Constants.primaryTheme,
                  marginLeft: 20,
                  cursor: 'pointer',
                }}
                className={Styles.clickableText}
              >
                Mark Read
              </p>
            )}
          </div>
        )
      case 'Care Team':
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <NavLink
              onClick={() => {
                this.markReadNotification(notification?._id)
                this.navigatetoCareplan(notification.puid)
                this.onPatientNavLinkClicked('careplan')
                console.log('clicked')
              }}
              to={{
                pathname: '/patient/careplan',
                state: 'care-plan',
                key: 2,
              }}
              style={{ color: Constants.primaryTheme }}
              activeStyle={{ color: 'black' }}
            >
              View
            </NavLink>
            {!notification?.read && (
              <p
                onClick={() => this.markReadNotification(notification?._id)}
                style={{
                  color: Constants.primaryTheme,
                  marginLeft: 20,
                  cursor: 'pointer',
                }}
                className={Styles.clickableText}
              >
                Mark Read
              </p>
            )}
          </div>
        )

      case 'Care Team Message':
        return (
          <NavLink
            onClick={() => {
              this.navigatetoCareplan(notification.puid)
              this.onPatientNavLinkClicked('careplan')
              console.log('clicked')
            }}
            to={{
              pathname: '/patient/careplan',
              state: 'care-plan',
              key: 2,
            }}
            activeStyle={this.props.patient ? { color: 'black' } : {}}
            style={{ color: Constants.primaryTheme }}
          >
            View
          </NavLink>
        )

      default:
        return null
    }
  }

  displayNotifications() {
    let { pchats, pmessages } = this.state
    let { unreadNotifications, adminId } = this.props
    let patientName = ''
    let providerName = ''
    let notif = []
    let toReturn = []
    let unreadNotificationsArray = []
    let i = 0

    const displayDesc = (desc, type) => {
      switch (type) {
        case 'Appointments':
          return 'You have an upcoming appointment with your patient.'

        default:
          return desc
      }
    }

    unreadNotifications &&
      unreadNotifications.length &&
      unreadNotifications.forEach((notification) => {
        if (notification?.message && !notification?.read_by?.[adminId]) {
          unreadNotificationsArray.push(notification?._id)
          let data = {
            _id: notification?._id,
            created_at: notification.created_at,
            type_no: this.displayType(notification?.notification_type),
            desc: notification?.message?.title,
            // desc: notification?.message?.title + ', ' + displayDesc(notification?.message?.body, notification?.notification_type),
            puid: notification?.metadata?.patient_id || notification?.notification_type,
          }
          if(notification?.notification_type === 'Careplan') {
            let providerName, patientName
            let patient_id = notification.metadata?.patient_id
            this.patientListFull.map((e) =>
              e.uid === patient_id ? (patientName = e.firstName + ' ' + e.lastName) : '',
            )
            // this.providerListFull.map((e) =>
            //   e.pid === p.messages[p.messages.length - 1].sender_id
            //     ? (providerName = e.name)
            //     : '',
            // )
            data.desc = patientName + ' ' + notification?.message?.title
          }
          notif.push(data)
        }
      })
    // marking all notification in mongo as read
    // unreadNotificationsArray.length && this.readAllNotifications(unreadNotificationsArray)
    console.log('unreadNotifications: ', unreadNotifications)
    console.log('pchats: ', pchats)
    console.log('pmessages: ', pmessages)
    console.log('notif: ', notif)

    pchats.forEach((p) => {
      this.patientListFull.map((e) =>
        e.uid === p._id ? (patientName = e.firstName + ' ' + e.lastName) : '',
      )
      this.providerListFull.map((e) =>
        e.pid === p.messages[p.messages.length - 1].sender_id
          ? (providerName = e.name)
          : '',
      )
      notif.push({
        created_at: p.messages[p.messages.length - 1].created_at,
        type_no: 'Care Team Message',
        desc: `${patientName} care team has new messages from ${providerName}`,
        puid: p._id,
      })
    })
    pmessages.forEach((p) => {
      this.patientListFull.map((e) =>
        e.uid === p.patient_id
          ? (patientName = e.firstName + ' ' + e.lastName)
          : '',
      )
      notif.push({
        created_at: p.messages[p.messages.length - 1].created_at,
        type_no: 'Patient Message',
        desc: `${patientName} sent you a message `,
        puid: p.patient_id,
      })
    })
    notif.forEach((n) => {
      toReturn.push(
        <div
          key={i.toString()}
          className={Styles.inviteRow}
          style={{ backgroundColor: i % 2 === 0 ? '#e8e8e8' : '' }}
        >
          <p className={Styles.inviteListEntry} style={{ width: '20%' }}>
            {moment(n.created_at).format('MMM Do YYYY HH:mm A')}
          </p>
          <p className={Styles.inviteListEntry} style={{ width: '20%' }}>
            {n.type_no}
          </p>
          <p className={Styles.inviteListEntry} style={{ width: '35%' }}>
            {n.desc}
          </p>

          <p
            className={Styles.inviteListEntry}
            style={{ textAlign: 'center', fontSize: 17, width: '25%' }}
          >
            {this.navigateRoute(n)}
          </p>
        </div>,
      )
      i++
    })
    return toReturn
  }

  displayReadNotifications() {
    let { pchats, pmessages } = this.state
    let { allNotifications, adminId } = this.props
    let patientName = ''
    let providerName = ''
    let notif = []
    let toReturn = []
    let allNotificationsArray = []
    let i = 0

    const displayDesc = (desc, type) => {
      switch (type) {
        case 'Appointments':
          return 'You have an upcoming appointment with your patient.'

        default:
          return desc
      }
    }

    allNotifications &&
      allNotifications.length &&
      allNotifications.forEach((notification) => {
        if (notification?.message && notification?.read_by?.[adminId]) {
          allNotificationsArray.push(notification?._id)
          notif.push({
            _id: notification?._id,
            created_at: notification.created_at,
            type_no: this.displayType(notification?.notification_type),
            desc: notification?.message?.title,
            // desc: notification?.message?.title + ', ' + displayDesc(notification?.message?.body, notification?.notification_type),
            puid: notification?.notification_type,
            read: notification?.read_by?.[adminId],
          })
        }
      })
    // marking all notification in mongo as read
    // allNotificationsArray.length && this.readAllNotifications(allNotificationsArray)

    notif.forEach((n) => {
      toReturn.push(
        <div
          key={i.toString()}
          className={Styles.inviteRow}
          style={{ backgroundColor: i % 2 === 0 ? '#e8e8e8' : '' }}
        >
          <p className={Styles.inviteListEntry} style={{ width: '20%' }}>
            {moment(n.created_at).format('MMM Do YYYY HH:mm A')}
          </p>
          <p className={Styles.inviteListEntry} style={{ width: '20%' }}>
            {n.type_no}
          </p>
          <p className={Styles.inviteListEntry} style={{ width: '35%' }}>
            {n.desc}
          </p>

          <p
            className={Styles.inviteListEntry}
            style={{ textAlign: 'center', fontSize: 17, width: '25%' }}
          >
            {this.navigateRoute(n)}
          </p>
        </div>,
      )
      i++
    })
    return toReturn
  }

  renderContent() {
    return (
      <div className={Styles.listWrapper}>
        <div className={Styles.inviteRow}>
          <p className={Styles.inviteListHeader} style={{ width: '20%' }}>
            Date
          </p>
          <p className={Styles.inviteListHeader} style={{ width: '20%' }}>
            Type
          </p>
          <p className={Styles.inviteListHeader} style={{ width: '35%' }}>
            Description
          </p>
          <p className={Styles.inviteListHeader} style={{ width: '25%' }}>
            Activity
          </p>
        </div>
        {this.displayNotifications()}
      </div>
    )
  }

  readNotificationModal() {
    let { showReadNotificationModal } = this.state
    return (
      <Modal
        ariaHideApp={false}
        isOpen={showReadNotificationModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() =>
          this.setState({ showReadNotificationModal: false })
        }
        style={MODAL_STYLES}
        contentLabel="Modal"
      >
        <div style={{ height: 'auto', width: '100%' }}>
          <p
            className={GlobalStyles.closeModalBtn}
            onClick={() => this.setState({ showReadNotificationModal: false })}
          >
            X
          </p>
          <h4
            style={{
              color: Constants.primaryTheme,
              textAlign: 'center',
              marginTop: 30,
            }}
          >
            Read Notifications
          </h4>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              marginTop: 30,
            }}
          >
            <div className={Styles.listWrapper}>
              <div className={Styles.inviteRow}>
                <p className={Styles.inviteListHeader} style={{ width: '20%' }}>
                  Date
                </p>
                <p className={Styles.inviteListHeader} style={{ width: '20%' }}>
                  Type
                </p>
                <p className={Styles.inviteListHeader} style={{ width: '35%' }}>
                  Description
                </p>
                <p className={Styles.inviteListHeader} style={{ width: '25%' }}>
                  Activity
                </p>
              </div>
              {this.displayReadNotifications()}
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={GlobalStyles.container} style={{ height: '100vh' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          paddingBottom: '10px',
        }}
      >
        <Header header={'Notifications'} />

        <div
          className={GlobalStyles.contentWrapper}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div className={Styles.clearButton}>
            <Button
              onClick={() => this.setState({ showReadNotificationModal: true })}
              className={GlobalStyles.button}
              // disabled={this.state.modalLoading}
              style={{ width: 200, bottom: 20, marginRight: 10 }}
              variant="primary"
            >
              Read notifications
            </Button>

            <Button
              onClick={() => this.readAllNotifications()}
              className={GlobalStyles.button}
              // disabled={this.state.modalLoading}
              style={{ width: 200, bottom: 20 }}
              variant="primary"
            >
              Clear all
            </Button>
          </div>

          {this.renderContent()}
          {this.readNotificationModal()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,

    patient: state.patientReducer.patient,

    token: state.authReducer.token,

    patientList: state.patientReducer.patientList,

    mongoUser: state.userReducer.mongoUser,

    adminId: state.userReducer.adminId,

    patientCore: state.patientReducer.patientCore,

    unreadNotifications: state.userReducer.unreadNotifications,

    allNotifications: state.userReducer.allNotifications,
  }
}

const mapDispatchToProps = {
  getUnreadMessages,
  addPatientLP,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
