import React from 'react'
import Select from 'react-select'
import Styles from './styles/ReferralsTable.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { SPECIALITY_OPTIONS, userTypes } from '../../../metadata'
import { toast } from 'react-toastify'

export const referralsColumns = (onAdminSubmitPressed) => {
  return [
    {
      Header: 'Patient Name',
      accessor: (obj) => obj?.patient_name,
      // Cell: ({ row }) => {
      //   let patientData = row?.original
      //   return (
      //     <Link
      //       onClick={() => {
      //         navigatetoPatientProfile(row?.original?.patient_id)
      //         onPatientNavLinkClicked('profile')
      //       }}
      //       to={{
      //         pathname: '/patient/profile',
      //         state: 'info',
      //         key: 2,
      //       }}
      //       className={Styles.link}
      //     >
      //       {patientData?.patient_name || ''}
      //     </Link>
      //   )
      // },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
    },
    {
      Header: 'Referring Provider',
      accessor: (obj) => {
        let doctorData = obj?.doctor_data
        let doctorName = doctorData?.first_name + ' ' + doctorData?.last_name
        if (doctorData?.first_name && doctorData?.last_name) {
          return doctorName
        } else {
          toast.warning(
            'There is missing provider name for one or more records',
          )
          return ''
        }
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Created At',
      accessor: (obj) => {
        return moment(obj?.created_at).format('MM/DD/YYYY')
      },
    },
    {
      Header: 'Referral Type',
      accessor: (obj) => {
        let TYPES = [
          { label: 'To Physician/Provider', value: 'physician' },
          { label: 'Durable Medical Equipment', value: 'medical' },
          { label: 'Imaging/ Radiology', value: 'imaging' },
          { label: 'Specialty Pharmacy/Infusion', value: 'speciality' },
          { label: 'Patient Prior Auth', value: 'priorAuth' },
          { label: 'To Physician/Provider', value: 'physician' },
          {
            label: 'Patient needs to be admitted to the hospital',
            value: 'patient_hospital',
          },
          {
            label: 'Patient needs to be sent to urgent care',
            value: 'urgent_care',
          },
        ]
        let referralType = TYPES.find(
          (type) => type.value === obj?.referral_type,
        )
        return referralType?.label || '-'
      },
      width: 300,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="select-state"
                placeholder="Select"
                options={[
                  { label: 'To Physician/Provider', value: 'physician' },
                  { label: 'Durable Medical Equipment', value: 'medical' },
                  { label: 'Imaging/ Radiology', value: 'imaging' },
                  { label: 'Specialty Pharmacy/Infusion', value: 'speciality' },
                  { label: 'Patient Prior Auth', value: 'priorAuth' },
                  { label: 'To Physician/Provider', value: 'physician' },
                  {
                    label: 'Patient needs to be admitted to the hospital',
                    value: 'patient_hospital',
                  },
                  {
                    label: 'Patient needs to be sent to urgent care',
                    value: 'urgent_care',
                  },
                ]}
                value={
                  filterValue
                    ? {
                        label: filterValue,
                        value: filterValue,
                      }
                    : ''
                }
                // isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.label)
                  // setMemberType(val)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Send To',
      accessor: (obj) => {
        return obj?.referring_to
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Fax Number',
      accessor: (obj) => {
        return obj?.fax_number
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Phone Number',
      accessor: (obj) => {
        return obj?.phone
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Notes',
      accessor: (obj) => {
        return obj?.notes
      },
      width: 300,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Status',
      accessor: (obj) => {
        return obj?.referral_sent ? 'Completed' : 'In Progress'
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="select-state"
                placeholder="Select"
                options={[
                  { label: 'In Progress', value: 'In Progress' },
                  { label: 'Completed', value: 'Completed' },
                ]}
                value={
                  filterValue
                    ? {
                        label: filterValue,
                        value: filterValue,
                      }
                    : ''
                }
                // isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // setMemberType(val)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'View',
      accessor: 'View_referral',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              console.log('view', row.original)
              onAdminSubmitPressed(row.original)
            }}
            className={Styles.link}
            style={{
              textDecoration: 'underline',
              color: '#20a892',
              cursor: 'pointer',
            }}
          >
            View
          </p>
        )
      },
      width: 150,
    },
  ]
}
