import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { healthPlanStatus, stateResidence } from '../../../metadata'

export const contractsColumns = (
  providersList,
  patientList,
  openHealthPlan,
) => {
  return [
    {
      Header: 'Payer',
      accessor: 'health_plan_name',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'State',
      accessor: 'state',
      width: 150,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="select-state"
                placeholder="Select"
                options={stateResidence}
                value={
                  filterValue
                    ? {
                        label: filterValue,
                        value: filterValue,
                        abbreviation: filterValue,
                      }
                    : ''
                }
                // isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.abbreviation)
                  // setMemberType(val)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Customer Service',
      accessor: (obj) =>
        obj?.customer_service
          ? obj?.customer_service?.email + ' ' + obj.customer_service?.phone
          : '',
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Network Contact',
      accessor: 'contact_name',
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Reporting Deadline',
      accessor: (obj) =>
        obj?.reporting_deadline
          ? moment(obj?.reporting_deadline?.date).format('ll')
          : '-',
      width: 250,
      //   sort: true,
    },
    {
      Header: 'Prior Auth Phone',
      accessor: (obj) =>
        obj?.prior_auth
          ? 'Fax: ' +
            obj?.prior_auth?.fax +
            ' ' +
            '\n' +
            'Phone: ' +
            obj.prior_auth?.phone
          : '',
      width: 200,
    },
    {
      Header: 'Coverage Categories',
      accessor: (obj) => {
        let temp = obj?.coverage?.filter((each) => each.isChecked === true)
        return obj?.coverage
          ? temp
              ?.map((each, index) => {
                if (each.isChecked) return each.label + ''
              })
              .join(', ')
          : ''
      },
      width: 300,
    },
    {
      Header: 'Reimbursement Rate',
      accessor: 'reimbursment_rate',
      accessor: (obj) => {
        let temp = obj?.reimbursement_rate
        return obj?.reimbursement_rate
          ? `MD (Primary Care, Internal Medicine) :${
              temp?.primary_care?.percentage
                ? temp?.primary_care?.percentage + '%'
                : ''
            }
            MD (Psychiatrists) :${
              temp?.psychiatrist?.percentage
                ? temp?.psychiatrist?.percentage + '%'
                : ''
            }             
            Ph.D. (Psychologist) :${
              temp?.psychologist?.percentage
                ? temp?.psychologist?.percentage + '%'
                : ''
            }
            Mid-Level Providers (NP,PA,LCSW,LPC,etc) :${
              temp?.midlevel_providers?.percentage
                ? temp?.midlevel_providers?.percentage + '%'
                : ''
            }
            `
          : ''
      },
      // Cell: ({ row }) => {
      //   let temp = row?.original?.reimbursement_rate
      //   return (
      //     // <div
      //     //   style={{
      //     //     display: 'flex',
      //     //     flexDirection: 'column',
      //     //     fontWeight: 'normal',
      //     //     textAlign: 'left',
      //     //     all: 'inherit',
      //     //   }}
      //     // >
      //     <>
      //       <p>
      //         {' '}
      //         MD (Primary Care, Internal Medicine) :{' '}
      //         {temp?.primary_care?.percentage
      //           ? temp?.primary_care?.percentage + '%'
      //           : ''}
      //       </p>
      //       <p>
      //         {' '}
      //         MD (Psychiatrists) :{' '}
      //         {temp?.psychiatrist?.percentage
      //           ? temp?.psychiatrist?.percentage + '%'
      //           : ''}
      //       </p>
      //       <p>
      //         {' '}
      //         Ph.D. (Psychologist) :{' '}
      //         {temp?.psychologist?.percentage
      //           ? temp?.psychologist?.percentage + '%'
      //           : ''}
      //       </p>
      //       <p>
      //         {' '}
      //         Mid-Level Providers (NP,PA,LCSW,LPC,etc) :{' '}
      //         {temp?.midlevel_providers?.percentage
      //           ? temp?.midlevel_providers?.percentage + '%'
      //           : ''}
      //       </p>
      //     </>
      //     // </div>
      //   )
      // },
      width: 350,
      style: { whiteSpace: 'pre-line', textAlign: 'left' },
    },
    {
      Header: 'Contract',
      accessor: 'contract',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              console.log('view claim')
              openHealthPlan(row.original)
            }}
            className={Styles.link}
            style={{ textDecoration: 'underline' }}
          >
            View
          </p>
        )
      },
      width: 200,
    },
  ]
}
