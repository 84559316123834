//File commented for future use - fax under each patient
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Modal from 'react-modal'
import Constants, { borderRadius } from '../../../../values'
import Config from '../../../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import GlobalStyles from '../../../styles/global.module.scss'
import { useSelector } from 'react-redux'

const axios = require('axios')

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const Faxes = ({ patient }) => {
  const [openDoc, setOpenDoc] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [docPdf, setDocPdf] = useState(null)
  const [isFaxPresent, setIsFaxPresent] = useState(false)
  const [testPDF, setTestPDF] = useState('')
  const [faxList, setFaxList] = useState([])
  const token = useSelector((state) => state.authReducer.token)

  useEffect(() => {
    let filename = '20220106085912-277624-07973|919404434'
    setLoading(true)

    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `srfax/patient?patient_id=` + patient.firebase_id,
      headers: {
        Authorization: 'JWT ' + token,
      }
    }
    console.log('testing: ', patient)
    axios(config)
      .then(({ data }) => {
        console.log('FAX OF PATIENT: ', data)
        setFaxList(data)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
      })

    // let config = {
    //   method: 'get',
    //   // url: Config.BACKEND_URL + `srfax`,
    //   url: Config.BACKEND_URL + `srfax/retrieve-fax?sFaxFileName=${filename}`,

    //   // headers: {
    //   //   Authorization: 'JWT ' + token,
    //   // },
    // }
    // axios(config)
    //   .then(({ data }) => {
    //     console.log(data.Result)
    //     setTestPDF(data.Result)
    //   })
    //   .catch((err) => {
    //     console.log('Error getting Educational content for the Patient ', err)
    //   })

    if (patient.fax) setIsFaxPresent(true)
    else setIsFaxPresent(false)
  }, [patient])

  const renderFax = (FileName) => {
    setModalLoading(true)
    let config = {
      method: 'get',
      // url: Config.BACKEND_URL + `srfax`,
      url: Config.CHANGE_BACKEND_URL + `srfax/retrieve-fax?sFaxFileName=${FileName}`,

      // headers: {
      //   Authorization: 'JWT ' + token,
      // },
    }
    axios(config)
      .then(({ data }) => {
        console.log(data.Result)
        setTestPDF(data.Result)
        setModalLoading(false)
      })
      .catch((err) => {
        console.log('Error getting Faxes content for the Patient ', err)
      })
  }

  const renderDocuments = () => {
    const elements = faxList.map((fax, index) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '10px 0px',
        }}
      >
        <p
          style={{
            width: '5%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {index+1}
        </p>
        <p
          style={{
            width: '50%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {fax.CallerID} / {fax.RemoteID}
        </p>
        <p
          style={{
            width: '30%',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {moment.unix(fax.EpochTime).format('MMMM Do, YYYY')}
        </p>
        <FontAwesomeIcon
          onClick={() => {
            setOpenDoc(true)
            if(fax.pdf)
              setTestPDF(fax.pdf)
            else
              renderFax(fax.FileName)
          }}
          icon={faEye}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '20%',
            cursor: 'pointer',
          }}
        />
        {/* <p
          style={{
            width: '40%',
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          // onClick={() => {
          //   setOpenDoc(true)
          //   setDocPdf(fax.document)
          // }}
        >
          Open Document
        </p> */}
      </div>
    ))
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            borderBottom: '1px solid gray',
          }}
        >
          <h5
            style={{
              width: '5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Sl No.
          </h5>
          <h5
            style={{
              width: '50%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            From FAX / Remote ID
          </h5>
          <h5
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Imported Date
          </h5>
          <h5
            style={{
              width: '20%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            View
          </h5>
        </div>

        {elements}
      </div>
    )
  }

  if (loading) {
    return (
      <div className={GlobalStyles.container} style={{ height: '100vh' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '100%',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 40,
        overflow: 'auto',
      }}
    >
      {faxList.length !== 0 ? (
        renderDocuments()
      ) : (
        <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
          No imported documents
        </p>
      )}
      {/* {testPDF.length !== 0 && (
        <iframe
          style={{ height: '60vh', width: '100%' }}
          src={`data:application/pdf;base64,${testPDF}`}
        />
      )} */}

      <Modal
        ariaHideApp={false}
        isOpen={openDoc}
        onRequestClose={() => {
          setOpenDoc(false)
          setTestPDF('')
        }}
        style={customDocModalStyles}
        contentLabel="Modal"
      >
        {modalLoading ? (
          <div className={GlobalStyles.container} style={{ height: '100vh' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: '100%',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          </div>
        ) : (
          <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${testPDF}`}
          />
        )}
      </Modal>
    </div>
  )
}

export default Faxes
