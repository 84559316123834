import React from 'react'
import Select from 'react-select'
import Styles from './styles/ReferralsTable.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { SPECIALITY_OPTIONS, userTypes } from '../../../metadata'

export const renderOrdersMasterTableColumns = (
  navigatetoPatientProfile,
  onPatientNavLinkClicked,
  onViewOrderPressed,
  onViewClinicalReportClicked,
) => {
  return [
    {
      Header: 'Patient Name',
      accessor: (obj) => obj?.patientName,
      Cell: ({ row }) => {
        let patientData = row?.original
        return (
          <Link
            onClick={() => {
              navigatetoPatientProfile(row?.original?.patient_id)
              onPatientNavLinkClicked('profile')
            }}
            to={{
              pathname: '/patient/profile',
              state: 'info',
              key: 2,
            }}
            className={Styles.link}
          >
            {patientData?.patientName || ''}
          </Link>
        )
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
    },
    {
      Header: 'Date',
      accessor: 'created_at',
      // (obj) => {
      //   return moment(obj.created_at).format('MMM Do YYYY')
      // },
      // disableFilters: true,
      sortType: (a, b) => {
        return new Date(b.values.created_at) - new Date(a.values.created_at)
      },
      Cell: (row) => (
        <div style={{ textAlign: 'left' }}>
          {moment(row.value).format('MMM Do YYYY')}
        </div>
      ),
      width: 250,
      sort: true,
    },
    {
      Header: 'Ordering Provider',
      accessor: (obj) => {
        return (
          (obj?.doctor_data?.first_name || '') +
          ' ' +
          (obj?.doctor_data?.last_name || '')
        )
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Order Type',
      accessor: (obj) => {
        let TYPES = [
          { label: 'Labs', value: 'Labs' },
          { label: 'eRX', value: 'eRX' },
          // { label: 'Referrals', value: 'Referrals' },
          { label: 'RPM', value: 'RPM' },
          { label: 'To Physician/Provider', value: 'physician' },
          { label: 'Durable Medical Equipment', value: 'medical' },
          { label: 'Imaging/ Radiology', value: 'imaging' },
          { label: 'Specialty Pharmacy/Infusion', value: 'speciality' },
          { label: 'Patient Prior Auth', value: 'priorAuth' },
          { label: 'To Physician/Provider', value: 'physician' },
          {
            label: 'Patient needs to be admitted to the hospital',
            value: 'patient_hospital',
          },
          {
            label: 'Patient needs to be sent to urgent care',
            value: 'urgent_care',
          },
        ]
        let referralType = TYPES.find(
          (type) => type.value === (obj?.referral_type || obj?.order_type),
        )
        return referralType?.label || '-'
      },
      width: 300,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="select-state"
                placeholder="Select"
                options={[
                  { label: 'Labs', value: 'Labs' },
                  { label: 'eRX', value: 'eRX' },
                  // { label: 'Referrals', value: 'Referrals' },
                  { label: 'RPM', value: 'RPM' },
                  { label: 'To Physician/Provider', value: 'physician' },
                  { label: 'Durable Medical Equipment', value: 'medical' },
                  { label: 'Imaging/ Radiology', value: 'imaging' },
                  { label: 'Specialty Pharmacy/Infusion', value: 'speciality' },
                  { label: 'Patient Prior Auth', value: 'priorAuth' },
                  { label: 'To Physician/Provider', value: 'physician' },
                  {
                    label: 'Patient needs to be admitted to the hospital',
                    value: 'patient_hospital',
                  },
                  {
                    label: 'Patient needs to be sent to urgent care',
                    value: 'urgent_care',
                  },
                ]}
                value={
                  filterValue
                    ? {
                        label: filterValue,
                        value: filterValue,
                      }
                    : ''
                }
                // isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.label)
                  // setMemberType(val)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    // {
    //   Header: 'Referral Type',
    //   accessor: (obj) => {
    //     let TYPES = [
    //       { label: 'To Physician/Provider', value: 'physician' },
    //       { label: 'Durable Medical Equipment', value: 'medical' },
    //       { label: 'Imaging/ Radiology', value: 'imaging' },
    //       { label: 'Specialty Pharmacy/Infusion', value: 'speciality' },
    //       { label: 'Patient Prior Auth', value: 'priorAuth' },
    //       { label: 'To Physician/Provider', value: 'physician' },
    //       {
    //         label: 'Patient needs to be admitted to the hospital',
    //         value: 'patient_hospital',
    //       },
    //       {
    //         label: 'Patient needs to be sent to urgent care',
    //         value: 'urgent_care',
    //       },
    //     ]
    //     let referralType = TYPES.find(
    //       (type) => type.value === obj?.referral_type,
    //     )
    //     return referralType?.label || '-'
    //   },
    //   width: 300,
    //   sort: true,
    //   Filter: ({ column }) => {
    //     const { filterValue, setFilter } = column
    //     return (
    //       <span>
    //         <div style={{ width: '100%', fontWeight: 'normal' }}>
    //           <Select
    //             inputId="select-state"
    //             placeholder="Select"
    //             options={[
    //               { label: 'To Physician/Provider', value: 'physician' },
    //               { label: 'Durable Medical Equipment', value: 'medical' },
    //               { label: 'Imaging/ Radiology', value: 'imaging' },
    //               { label: 'Specialty Pharmacy/Infusion', value: 'speciality' },
    //               { label: 'Patient Prior Auth', value: 'priorAuth' },
    //               { label: 'To Physician/Provider', value: 'physician' },
    //               {
    //                 label: 'Patient needs to be admitted to the hospital',
    //                 value: 'patient_hospital',
    //               },
    //               {
    //                 label: 'Patient needs to be sent to urgent care',
    //                 value: 'urgent_care',
    //               },
    //             ]}
    //             value={
    //               filterValue
    //                 ? {
    //                     label: filterValue,
    //                     value: filterValue,
    //                   }
    //                 : ''
    //             }
    //             // isSearchable
    //             autoBlur
    //             onChange={(val) => {
    //               setFilter(val.label)
    //               // setMemberType(val)
    //               // filterByPatientType(val)
    //             }}
    //             theme={(theme) => ({
    //               ...theme,
    //               colors: {
    //                 ...theme.colors,
    //                 primary25: '#20A89233',
    //                 primary: '#20A892',
    //               },
    //             })}
    //           />
    //         </div>
    //       </span>
    //     )
    //   },
    // },
    // {
    //   Header: 'Send To',
    //   accessor: (obj) => {
    //     return obj?.referring_to
    //   },
    //   width: 250,
    //   sort: true,
    //   Filter: ({ column }) => {
    //     const { filterValue, setFilter } = column
    //     return (
    //       <span>
    //         <div style={{ width: '100%' }}>
    //           <input
    //             placeholder="Search"
    //             className={Styles.textInput}
    //             style={{ width: '100%' }}
    //             type="text"
    //             value={filterValue}
    //             onChange={(e) => {
    //               setFilter(e.target.value)
    //             }}
    //           />
    //         </div>
    //       </span>
    //     )
    //   },
    // },
    // {
    //   Header: 'Fax Number',
    //   accessor: (obj) => {
    //     return obj?.fax_number
    //   },
    //   width: 250,
    //   sort: true,
    //   Filter: ({ column }) => {
    //     const { filterValue, setFilter } = column
    //     return (
    //       <span>
    //         <div style={{ width: '100%' }}>
    //           <input
    //             placeholder="Search"
    //             className={Styles.textInput}
    //             style={{ width: '100%' }}
    //             type="text"
    //             value={filterValue}
    //             onChange={(e) => {
    //               setFilter(e.target.value)
    //             }}
    //           />
    //         </div>
    //       </span>
    //     )
    //   },
    // },
    // {
    //   Header: 'Phone Number',
    //   accessor: (obj) => {
    //     return obj?.phone
    //   },
    //   width: 250,
    //   sort: true,
    //   Filter: ({ column }) => {
    //     const { filterValue, setFilter } = column
    //     return (
    //       <span>
    //         <div style={{ width: '100%' }}>
    //           <input
    //             placeholder="Search"
    //             className={Styles.textInput}
    //             style={{ width: '100%' }}
    //             type="text"
    //             value={filterValue}
    //             onChange={(e) => {
    //               setFilter(e.target.value)
    //             }}
    //           />
    //         </div>
    //       </span>
    //     )
    //   },
    // },
    {
      Header: 'Notes',
      accessor: (obj) => {
        return obj?.notes ? obj?.notes : obj?.comments ? obj?.comments : '-'
      },
      width: 300,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    // {
    //   Header: 'Status',
    //   accessor: (obj) => {
    //     return obj?.referral_sent ? 'Completed' : 'In Progress'
    //   },
    //   width: 250,
    //   sort: true,
    //   Filter: ({ column }) => {
    //     const { filterValue, setFilter } = column
    //     return (
    //       <span>
    //         <div style={{ width: '100%', fontWeight: 'normal' }}>
    //           <Select
    //             inputId="select-state"
    //             placeholder="Select"
    //             options={[
    //               { label: 'In Progress', value: 'In Progress' },
    //               { label: 'Completed', value: 'Completed' },
    //             ]}
    //             value={
    //               filterValue
    //                 ? {
    //                     label: filterValue,
    //                     value: filterValue,
    //                   }
    //                 : ''
    //             }
    //             // isSearchable
    //             autoBlur
    //             onChange={(val) => {
    //               setFilter(val.value)
    //               // setMemberType(val)
    //               // filterByPatientType(val)
    //             }}
    //             theme={(theme) => ({
    //               ...theme,
    //               colors: {
    //                 ...theme.colors,
    //                 primary25: '#20A89233',
    //                 primary: '#20A892',
    //               },
    //             })}
    //           />
    //         </div>
    //       </span>
    //     )
    //   },
    // },
    {
      Header: 'View',
      accessor: 'View_referral',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              console.log('view', row.original)
              // if (row.original?.order_type === 'Labs') {
              //   onViewClinicalReportClicked(row.original)
              // } else {
              onViewOrderPressed(row.original)
              // }
            }}
            className={Styles.link}
            style={{
              textDecoration: 'underline',
              color: '#20a892',
              cursor: 'pointer',
            }}
          >
            View
          </p>
        )
      },
      width: 150,
    },
  ]
}
