import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Styles from './styles/styles.module.scss'
import Constants from '../values'
import Button from 'react-bootstrap/Button'
import GlobalStyles from '../pages/styles/global.module.scss'
import Config from '../config'
import Select from 'react-select'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { getChartReferenceLines } from './getChartReferenceLines'
import { getReadableToolTip } from './getQuestionnaireResults'
import {
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  AreaChart,
  Label,
  CartesianGrid,
  Area,
} from 'recharts'

const MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: '90vh',
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const ChartModal = ({
  data,
  path,
  name,
  getQuestionnaireResults,
  showChartModal,
  hideChartModal,
}) => {
  console.log('from chart modal', data, path)

  if (data.length === 0) {
    return null
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={showChartModal}
      // onAfterOpen={afterOpenModal}
      onRequestClose={hideChartModal}
      style={MODAL_STYLES}
      contentLabel="Modal"
    >
      <div style={{ height: '100%', width: '100%' }}>
        <p className={GlobalStyles.closeModalBtn} onClick={hideChartModal}>
          X
        </p>
        <h4
          style={{
            color: Constants.primaryTheme,
            textAlign: 'center',
            marginTop: 30,
          }}
        >
          {name} Chart
        </h4>
        {/* <ReactTooltip id={path}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: 30,
          }}
        >
          <LineChart
            width={750}
            height={700}
            data={data?.reverse()}
            margin={{ top: 20, right: 20, bottom: 5, left: 0 }}
          >
            <Legend verticalAlign="top" />
            <Line
              name={getReadableToolTip(path)}
              type="monotone"
              dataKey={getQuestionnaireResults(path)}
              stroke={'#606060'}
            />
            {path === 'globalhealth' && (
              <Line
                name={'Physical Score'}
                type="monotone"
                dataKey={'physical_score'}
                stroke={Constants.graphLineThree}
              />
            )}
            {getChartReferenceLines && getChartReferenceLines(path)}
            <XAxis
              dataKey="created_at"
              stroke={'#000000cc'}
              tickFormatter={(tickItem) => {
                return moment(tickItem).format('MMM-D')
              }}
            />
            <YAxis stroke={'#000000cc'} />
            <Tooltip labelFormatter={(t) => moment(t).format('MMM-D YYYY')} />
          </LineChart>
        </div>
        {/* </ReactTooltip> */}
      </div>
    </Modal>
  )
}

export default ChartModal
