import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import {connect} from "react-redux";
import Header from "../../components/Header";
import Config from "../../config";
import moment from 'moment'
import Constants from '../../values.js'
import Slider from 'react-input-slider';
import Button from "react-bootstrap/Button";
import {ToastContainer, toast} from "react-toastify";
import DateTimePicker from 'react-datetime-picker';

const axios = require('axios');

class Survey extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      questions: null,
      answers: {},
      selectedDate: new Date()
    }

    this.getSurveyQuestions = this.getSurveyQuestions.bind(this)
    this.prepareQuestions = this.prepareQuestions.bind(this)
    this.getAnswersForTheDay = this.getAnswersForTheDay.bind(this)
    this.preparePatientAnswers = this.preparePatientAnswers.bind(this)
  }

  componentDidMount() {
    if(!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.getSurveyQuestions()
  }

  getAnswersForTheDay(questions, date) {
    var timeAndDate = moment(date).set({hour: 0, minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD');
    console.log('getting survey answers for ', timeAndDate)
    let {token, docFirebaseAuthUser} = this.props
    let url = Config.BACKEND_URL + 'ccfm/patients/surveys/answers'
    let self = this

    axios({
      method: 'get',
      url: url,
      headers: {
        x_firebase_id: docFirebaseAuthUser.uid,
        Authorization: 'JWT ' + token,
        created_at: moment(timeAndDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      },
    })
      .then(function (response) {
        console.log('got survey answers', response.data)
        // handle success
        let data = response.data
        self.preparePatientAnswers(questions, data)
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient answers', error, token)
      })
  }

  preparePatientAnswers(questions, data) {
    if(!data) {
      this.setState({loading: false, answers: {}})
      return
    }

    let patientAnswersForDay = data.answers
    let {answers} = this.state
    Object.keys(questions).forEach(questionNumber => {
      answers[questionNumber] = patientAnswersForDay[questionNumber]
    })

    this.setState({answers, loading: false})
  }

  getSurveyQuestions() {
    let {adminId, token} = this.props
    let url = Config.BACKEND_URL + 'providers'
    let self = this

    axios({
      method: 'get',
      url: url,
      headers: {x_firebase_id: adminId, Authorization: 'JWT ' + token},
    })
      .then(function (response) {
        // handle success
        let data = response.data
        self.prepareQuestions(data)
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting questions', error, token)
      })
  }

  prepareQuestions(data) {
    if(!data || !data.survey)
      return

    let tempQuestions = data.survey
    delete tempQuestions['13']
    delete tempQuestions['14']
    this.setState({questions: tempQuestions})
    this.getAnswersForTheDay(tempQuestions, this.state.selectedDate)
  }

  onSliderValueChanged(questionNumber, value) {
    let {answers} = this.state
    answers[questionNumber] = value
    this.setState({answers})
  }

  onSubmitPressed() {
    let {answers, questions, selectedDate} = this.state
    if(!questions || !answers)
      return
    this.setState({loading: true})
    let {docFirebaseAuthUser, adminId, token} = this.props
    let answersToPut = {}
    Object.keys(questions).forEach(questionNumber => {
      answersToPut[questionNumber] = answers[questionNumber] || 1
    })

    let timeAndDate = moment(selectedDate).set({hour: 0, minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD');

    let toPut = {
      firebase_id: docFirebaseAuthUser.uid,
      doctor_id: adminId,
      answers: answersToPut,
      created_at: moment(timeAndDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    }

    let self = this
    let url = Config.BACKEND_URL + 'ccfm/patients/surveys/answers'

    axios({
      method: 'put',
      url: url,
      headers: {Authorization: 'JWT ' + token},
      data: toPut
    })
      .then(function (response) {
        // handle success
        self.setState({loading: false})
        toast.success("Successfully submitted your answers")
      })
      .catch(function (error) {
        // handle error
        console.log('error when posting survey answers', error)
        toast.error('Error when posting answers')
        self.setState({loading: false})
      })
  }

  onDateChanged(newDate) {
    console.log('selected data', newDate)
    this.setState({selectedDate: newDate})
    this.setState({loading: true})
    this.getAnswersForTheDay(this.state.questions, newDate)
  }


  renderSurvey() {
    let {selectedDate} = this.state
    let {questions, answers} = this.state
    let questionsElement = questions && Object.keys(questions).map((questionNumber,i) => {
      let question = questions[questionNumber]
      let answer = answers[questionNumber]
      return (
        <div key={i.toString()} style={{height: 140, width: '80%', backgroundColor: '#F5F5F5', borderRadius: 10, padding: '10px 20px', marginTop: 20}}>
          <p style={{textAlign: 'center', fontSize: 20, color: Constants.primaryTheme}}>{question}</p>
          <div style={{marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
            <p style={{fontWeight: 'bold', marginBottom: 10}}>{answer ? (answer) : 1}</p>
            <Slider
              styles={{
                track: {
                  backgroundColor: '#c8c8c8',
                  width: 400
                },
                active: {
                  backgroundColor: Constants.primaryTheme
                },
              }}
              axis="x"
              xmin={1}
              xmax={10}
              x={this.state.answers[questionNumber] || 1}
              onChange={({ x }) => this.onSliderValueChanged(questionNumber, x)}
            />
            <div style={{width: 400, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p>1-Low</p>
              <p>5-Medium</p>
              <p>10-High</p>
            </div>
          </div>
        </div>
      )
    })

    return (
      <div className={GlobalStyles.contentWrapper} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 20}}>
          <p style={{color: Constants.primaryTheme, fontWeight: 'bold', marginRight: 20}}>Survey Date</p>
          <DateTimePicker
            disableClock={true}
            clearIcon={null}
            onChange={this.onDateChanged.bind(this)}
            value={this.state.selectedDate}
          />
        </div>

        <p style={{textAlign: 'center', marginTop: 10, marginBottom: 10}}>Please rate the following on a 1-10 scale where 1 is low, 5 is medium and 10 is high</p>
        {questionsElement}

        <Button
          onClick={() => {
            if(window.confirm(`Are you sure you want to submit the answers?`)) {
              this.onSubmitPressed()
            }
          }}
          className={GlobalStyles.button}
          style={{width: 250, marginTop: 30}}
          variant="primary"
        >
          Save Answers
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        <Header header={'Survey'} />

        {
          this.state.loading ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 400}}>
              <div className={GlobalStyles.loader} />
            </div>
            :
            this.renderSurvey()
        }

      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    firebaseUser: state.userReducer.firebaseUser,
    adminId: state.userReducer.adminId,
  }
}

export default connect(
  mapStateToProps,
  null
)(Survey)
