import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Switch from 'react-switch'
import { healthPlanStatus, stateResidence } from '../../../metadata'

export const providerRosterColumns = (
  providersList,
  displayAvailability,
  updateProvider,
) => {
  return [
    {
      Header: 'Name',
      accessor: (obj) => obj?.first_name + ' ' + obj?.last_name || '',
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      width: 200,
      // sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'NPI',
      accessor: (obj) => obj?.npi?.number || '',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Specialization',
      accessor: 'speciality',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Licensed States',
      accessor: (provider) =>
        provider?.licensed_states?.length
          ? provider.licensed_states
              .map((state) => state?.abbr + ' : ' + state?.number)
              .join('\n')
          : 'N/A',
      width: 250,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="select-state"
                placeholder="Select"
                options={stateResidence}
                value={
                  filterValue
                    ? {
                        label: filterValue,
                        value: filterValue,
                        abbreviation: filterValue,
                      }
                    : ''
                }
                // isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.abbreviation)
                  // setMemberType(val)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
      style: { whiteSpace: 'pre-line', textAlign: 'left' },
    },
    {
      Header: 'DEA LICENSE',
      accessor: (obj) => obj?.dea || '',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'CAQH',
      accessor: (obj) => obj?.caqh || '',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'FCVS',
      accessor: (obj) => obj?.fcvs || '',
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Background Check',
      accessor: (obj) => {
        return (
          <Select
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
            placeholder="Select"
            autoBlur={true}
            onChange={(val) => {
              console.log(val)
              updateProvider({
                background_check: val.value,
                firebase_id: obj?.firebase_id,
              })
            }}
            value={
              obj?.background_check
                ? { label: obj?.background_check, value: obj?.background_check }
                : null
            }
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
      width: 200,
    },
    {
      Header: 'CME Fulfilled',
      accessor: (obj) => (obj?.cme?.length ? 'Yes' : 'No'),
      width: 200,
      //   sort: true,
      //   Filter: ({ column }) => {
      //     const { filterValue, setFilter } = column
      //     return (
      //       <span>
      //         <div style={{ width: '100%' }}>
      //           <input
      //             placeholder="Search"
      //             className={Styles.textInput}
      //             style={{ width: '100%' }}
      //             type="text"
      //             value={filterValue}
      //             onChange={(e) => {
      //               setFilter(e.target.value)
      //             }}
      //           />
      //         </div>
      //       </span>
      //     )
      //   },
    },

    {
      Header: 'User Type',
      accessor: 'user_type',
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Availability',
      accessor: 'provider_availability',
      Cell: ({ row }) => {
        let provider = row.original
        return (
          <p
            onClick={() => {
              console.log('view availablity')
              displayAvailability(row.original)
            }}
            className={Styles.link}
            style={{ textDecoration: 'underline' }}
          >
            {provider.inactive ? 'Inactive' : 'Availability'}
          </p>
        )
      },
      width: 200,
    },
    {
      Header: 'Active',
      accessor: 'provider_active',
      Cell: ({ row }) => {
        let provider = row.original
        return (
          <Switch
            onChange={(changed) => {
              updateProvider({
                inactive: !changed,
                firebase_id: provider?.firebase_id,
              })
            }}
            checked={!provider.inactive}
          />
        )
      },
      width: 200,
    },
  ]
}
