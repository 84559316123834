import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import GlobalStyles from '../../../pages/styles/global.module.scss'
import Styles from './styles/Community.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMicrophoneSlash,
  faPhone,
  faVideoSlash,
  faMicrophone,
  faVideo,
  faEdit,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import values from '../../../values'
import Config from '../../../config'
import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import Header from '../../../components/Header.js'
import Select from 'react-select'
import CommunityPosts from './CommunityPosts'
import CommunityChatRoom from './CommunityChatRoom'

const TWILIO_VIDEO = require('twilio-video')

const moment = require('moment')
const axios = require('axios')

const CONFIRM_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '40%',
    height: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const Community = (props) => {
  const dispatch = useDispatch()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)
  //   const openNotesModal = useSelector(
  //     (state) => state.genericReducer?.openNotesModal,
  //   )
  const appointmentData = useSelector(
    (state) => state.appointmentReducer?.appointmentData,
  )

  const patient = useSelector((state) => state.patientReducer?.patient)
  const mongoUser = useSelector((state) => state.userReducer.mongoUser)
  const firebaseUser = useSelector((state) => state.userReducer.firebaseUser)

  const [showTab, setShowTab] = useState(1)
  const [loading, setLoading] = useState(false)
  const [allCircles, setAllCircles] = useState([])
  const [newPostModalIsOpen, setNewPostModal] = useState(false)
  const [unReadMessagesCount, setUnReadMessagesCount] = useState(0)

  const [userCommunities, setUserCommunities] = useState([])
  const [otherCommunities, setOtherCommunities] = useState([])

  const [showAddModal, setShowAddModal] = useState(false)
  const [addedComminities, setAddedComminities] = useState(null)
  const [modalError, setModalError] = useState('')

  const history = useHistory()

  useEffect(() => {
    if (!patient && !firebaseUser) {
      history.push('/login')
    }

    if (!props?.location?.communitId) {
      history.push('/patient/community')
      console.log('inside')
    }
    setLoading(true)
    getCircleList()
    getOldMessages()
    return () => {
      setAllCircles([])
    }
  }, [])

  const getOldMessages = () => {
    var config = {
      method: 'get',
      url: `${Config.BACKEND_URL}communities/messages`,
      headers: {
        x_firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
        Authorization: `JWT ${token}`,
      },
      params: {
        community_id: props?.location?.communitId,
      },
    }

    let id = firebaseUser ? firebaseUser.uid : patient.uid

    axios(config)
      .then(({ data }) => {
        setLoading(false)
        setUnReadMessagesCount(data?.unread?.[id])
      })
      .catch((err) => {
        setLoading(false)
        console.log('error in fetching old messages', err)
      })
  }

  const getCircleList = () => {
    let config = {
      method: 'get',
      url: `${Config.BACKEND_URL}circles?community_id=${props?.location?.communitId}`,
      headers: {
        x_firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    }
    axios(config)
      .then((response) => {
        if (response.data) {
          let circleArray = [
            ...response?.data?.recurringCircles,
            ...response?.data?.closedCircles,
            ...response?.data?.openCircles,
            ...response?.data?.circlesHosted,
          ].sort((first, second) => {
            return moment(first.scheduled_date).diff(
              moment(second.scheduled_date),
            )
          })
          setAllCircles(circleArray)
          // console.log(response.data)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        toast.warning('Circles not found, please try again later!')
      })
  }

  const onCommunityTabSelected = (index) => {
    switch (index) {
      case 1:
        setShowTab(1)
        // this.setState({ showAilaImmunization: true })
        // this.getProviderImmunization()
        return
      case 2:
        setShowTab(2)
        // if (!this.state.externalImmunizations) this.getExternalImmunizations()
        // this.setState({ showAilaImmunization: false })
        return
      case 3:
        setShowTab(3)
        // if (!this.state.externalImmunizations) this.getExternalImmunizations()
        // this.setState({ showAilaImmunization: false })
        return
      default:
        setShowTab(1)
      // this.setState({ showAilaImmunization: true })
    }
  }
  const renderPosts = () => {
    return (
      <CommunityPosts
        communityId={props?.location?.communitId}
        community={props?.location?.community}
      />
    )
  }

  const renderGroupChat = () => {
    return (
      <CommunityChatRoom
        communityId={props?.location?.communitId}
        community={props?.location?.community}
      />
    )
  }

  const renderEventList = (circleList) => {
    let displayEvents = circleList.length ? (
      circleList.map((circle, i) => (
        <div
          key={i.toString()}
          className={Styles.inviteRow}
          style={{ backgroundColor: i % 2 === 0 ? '#e8e8e8' : '' }}
        >
          <p className={Styles.inviteListEntry} style={{ width: '10%' }}>
            {moment(circle.scheduled_date).format('MMM Do YYYY hh:mm A')}
          </p>
          <p className={Styles.inviteListHeader} style={{ width: '10%' }}></p>
          <p
            className={Styles.inviteListEntry}
            style={{ width: '30%', textAlign: 'start' }}
          >
            {circle.title}
          </p>
          {/* <p className={Styles.inviteListEntry} style={{width: '20%'}}>
          {circle.end ? moment(circle.end).format('MMM Do YYYY'): "-"}
        </p> */}
          <p className={Styles.inviteListEntry} style={{ width: '10%' }}>
            {circle.duration &&
              (circle.duration >= 60
                ? `${Math.floor(circle.duration / 60)}h ${
                    circle.duration % 60
                  }m`
                : `${circle.duration}m`)}
          </p>
          <p className={Styles.inviteListHeader} style={{ width: '10%' }}></p>
          <p className={Styles.inviteListEntry} style={{ width: '10%' }}>
            {circle.recurring ? `${circle.recurring}ly` : 'False'}
          </p>
          <p className={Styles.inviteListEntry} style={{ width: '10%' }}>
            <Link
              to={
                firebaseUser
                  ? {
                      pathname: '/patient/circle',
                      circle: circle,
                      fromCommunity: 'true',
                      // patient: patient,
                    }
                  : {
                      pathname: '/patient/circle',
                      circle: circle,
                      patient: patient,
                      fromCommunity: 'true',
                    }
              }
            >
              Join
            </Link>
          </p>
        </div>
      ))
    ) : (
      <div>No circles yet </div>
    )
    return (
      <div className={Styles.listWrapper} style={{ marginTop: '4%' }}>
        <div className={Styles.inviteRow}>
          <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
            Scheduled Date
          </p>
          <p className={Styles.inviteListHeader} style={{ width: '10%' }}></p>
          <p className={Styles.inviteListHeader} style={{ width: '30%' }}>
            Circle Title
          </p>
          <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
            Circle Duration
          </p>
          <p className={Styles.inviteListHeader} style={{ width: '10%' }}></p>
          <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
            Recurring
          </p>
          <p className={Styles.inviteListHeader} style={{ width: '10%' }}>
            Join
          </p>
        </div>

        {displayEvents}
      </div>
    )
  }

  const renderCircles = () => {
    return allCircles && renderEventList(allCircles)
  }

  const renderCommunity = () => {
    let communitiesList =
      userCommunities && userCommunities.map((comm) => <div>{comm?.title}</div>)
    return (
      <div className={GlobalStyles.contentWrapper}>
        {/* <div style={{ position: 'absolute', top: 0, right: 0 }}> */}
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'gray',
            width: '25%',
            height: 40,
            margin: 'auto',
          }}
        >
          <div
            onClick={() => onCommunityTabSelected(1)}
            className={Styles.tabItem}
            style={{
              backgroundColor: showTab === 1 ? values.primaryTheme : '',
              color: showTab === 1 ? 'white' : 'black',
              width: '100%',
            }}
          >
            <p>Circles</p>
          </div>

          <div
            onClick={() => onCommunityTabSelected(2)}
            className={Styles.tabItem}
            style={{
              backgroundColor: showTab === 2 ? values.primaryTheme : '',
              color: showTab === 2 ? 'white' : 'black',
              width: '100%',
            }}
          >
            <p>Forum</p>
          </div>

          <div
            onClick={() => onCommunityTabSelected(3)}
            className={Styles.tabItem}
            style={{
              backgroundColor: showTab === 3 ? values.primaryTheme : '',
              color: showTab === 3 ? 'white' : 'black',
              width: '100%',
            }}
          >
            <p>Chatroom</p>
          </div>
        </div>

        {showTab === 1
          ? renderCircles()
          : showTab === 2
          ? renderPosts()
          : renderGroupChat()}
      </div>
    )
  }

  const renderLoader = () => (
    <div className={GlobalStyles.container} style={{ height: '100vh' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100%',
          height: '100%',
        }}
      >
        <div className={GlobalStyles.loader} />
      </div>
    </div>
  )

  const renderContent = () => (
    <div className={GlobalStyles.container}>
      <Header header={`${props.match.params.community} Community`} />
      {loading ? renderLoader() : renderCommunity()}
    </div>
  )

  //   if (loading) return renderLoader()
  return renderContent()
}

export default Community
