import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Fuse from 'fuse.js'
import moment from 'moment'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import Config from '../../config'
import metadata from '../../metadata'
import {
  addPatientList,
  fetchPatientCarePlanData,
  setAppointmentNotesData,
} from '../../redux/actions'
import firebase from '../../services/firebase'
import Constants from '../../values.js'
import TaskModal from '../Patients/Careplan/TaskModal'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/admin.module.scss'

const axios = require('axios')

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

const ClearAllRefinements = ({ refine, items }) => {
  const onClick = () => refine(items)
  return (
    <button id="icdclear" onClick={onClick}>
      ClearAll
    </button>
  )
}

const CohortOptionList = [
  { label: 'Basic', value: 'basic' },
  { label: 'Basic 7 days', value: 'basic7' },
  { label: 'Basic 30 days', value: 'basic30' },
  { label: 'Membership', value: 'membership' },
  { label: 'All Access', value: 'premium' },
  { label: 'All Users', value: 'allusers' },
  { label: 'Long Covid', value: 'long_covid' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Texas', value: 'TX' },
]

class Communications extends Component {
  constructor(props) {
    super(props)
    let notesData = this.props?.carePlanNotesData
    let reduxAppNotes = this.props?.appointmentNotesData

    this.state = {
      loading: false,
      appointmentData: null,
      patientId: this.props?.patientId || this.props?.patient?.uid,
      communicationType: { label: 'Email', value: 'Email' },
      //EMAIL FIELDS
      fromEmail: 'admin-donotreply@ailahealth.com',
      ccEmail: '',
      bccEmail: '',
      audience: null,
      toEmails: null,
      emailAddress: '',
      cohortSelected: null,
      template: null,
      subject: '',
      timeSpent: 0,
      message: '',
      //PHONE FIELDS
      phoneType: null,
      phonePatient: null,
      phoneName: '',
      phoneNumber: '',
      toPhoneNumber: '',
      textSms: '',
      phoneRequest: null,
      phoneMessage: '',
      providerMap: null,
      patientList: [],
      completePatientList: [],
      providersList: [],
      cohortOptions: [],
      fileAttached: null,
      scheduleDate: new Date(),

      //VIEW OR CREATE COMMUNICATIONS
      allCommunications: [],
      viewCommunications: false,
      searchName: '',
      sortValue: true,
      searchEmail: '',

      //TASK MODAL
      modalAddTaskIsOpen: false,
    }
    this.fuse = null

    this.fileRef = React.createRef()
    this.getAllCommunications = this.getAllCommunications.bind(this)
    this.getAllProviders = this.getAllProviders.bind(this)
    this.getPatientList = this.getPatientList.bind(this)
    this.onSubmitPressed = this.onSubmitPressed.bind(this)
    this.hideTaskModal = this.hideTaskModal.bind(this)
    this.getUserTask = this.getUserTask(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    // console.log(this.props?.patientList)

    if (this.props.mongoUser) {
      let cohorts = this.props?.mongoUser?.cohorts || []
      let cohortObjects = []
      cohorts.forEach((cohort) =>
        cohortObjects.push({ label: cohort, value: cohort }),
      )
      this.setState({ cohortOptions: cohortObjects })
    }

    this.getPatientList()
    this.getAllProviders()
    this.getAllCommunications()
  }

  async getAllCommunications() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}communications/phone`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        // x_firebase_id: adminId,
        // getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        // console.log(data)

        this.setState({ allCommunications: data })
        // let docObj = {}
        // if (data) {
        //   data.forEach((x) => {
        //     docObj[x.firebase_id] = x
        //   })
        //   this.setState({
        //     providerMap: docObj,
        //   })
        // }
      })
      .catch((err) => {
        console.log('error when getting all communications', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }
  async getAllProviders() {
    const { token, adminId } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}providers`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
        getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        // console.log(data)
        let providersList = []
        data &&
          data.forEach((item) => {
            providersList.push({
              label: item?.first_name + ' ' + item?.last_name,
              value: item.email,
              email: item.email,
              phoneNumber: item.phoneNumber,
              ...item,
            })
          })

        this.setState({ providersList: providersList })
        // let docObj = {}
        // if (data) {
        //   data.forEach((x) => {
        //     docObj[x.firebase_id] = x
        //   })
        //   this.setState({
        //     providerMap: docObj,
        //   })
        // }
      })
      .catch((err) => {
        console.log('error when getting all providers', err)
        toast.error('Something went wrong')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  getPatientList() {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    const self = this
    const url =
      `${Config.BACKEND_URL}doctor/patients/list` +
      (self.state.superUser ||
      mongoUser?.user_type?.toLowerCase() === 'administrator'
        ? '?access=admin'
        : '')
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          const { data } = response
          let total = []
          let patientList = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })

          total &&
            total.forEach((item) => {
              patientList.push({
                label: item?.firstName + ' ' + item?.lastName,
                value: item?.email,
                email: item?.email,
                phoneNumber: item?.phoneNumber,
                firebase_id: item?.uid,
                ...item,
              })
            })

          console.log('PATIENT LIST::', patientList)

          self.setState({
            loading: false,
            // patientListData: data || {},
            patientList: data ? patientList : [], // default
            completePatientList: patientList,
          })
          self.props.addPatientList(data)
          //   if (self.props.rpmPatientId) {
          //     self.navigateToRpmPatientProfile(data)
          //   }
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
      })
  }

  hideTaskModal() {
    this.setState({ modalAddTaskIsOpen: false })
  }

  getUserTask() {
    const { token, adminId, patient } = this.props
    let url = Config.BACKEND_URL + 'support-request'
    axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: adminId,
        Authorization: `JWT ${token}`,
        clinical_support: true,
        patient_id: patient?.uid,
      },
    })
      .then(({ data }) => {
        this.setState({ taskList: data })
      })
      .catch((err) => console.log('err while fetching support list: ', err))
  }

  async onSubmitPressed() {
    const { token, adminId, mongoUser } = this.props
    const {
      communicationType,
      fromEmail,
      ccEmail,
      bccEmail,
      audience,
      toEmails,
      toPhoneNumber,
      textSms,
      emailAddress,
      cohortSelected,
      template,
      subject,
      timeSpent,
      message,
      fileAttached,

      //phone fields
      phoneMessage,
      phoneName,
      phoneNumber,
      phonePatient,
      phoneRequest,
      phoneType,
    } = this.state

    if (communicationType?.value === 'Email') {
      if (!fromEmail && !audience) {
        toast.warning('Please enter from email address and select audience')
        return
      }
      if (!toEmails && !emailAddress && !cohortSelected) {
        toast.warning('Please select/type valid email')
        return
      }
      if (!subject && !message) {
        toast.warning('Please enter subject and message')
        return
      }
    } else if (communicationType?.value === 'Phone') {
      if (!phoneType) {
        toast.warning('Please select phone type')
        return
      }
      if (!phoneName && !phoneNumber && !phonePatient) {
        toast.warning('Please select patient/ enter phone number')
        return
      }
      if (!phoneMessage) {
        toast.warning('Please enter message')
        return
      }
    }
    if (communicationType?.value === 'sms' && textSms.length === 0) {
      toast.warning('Please enter text message')
      return
    }

    // get all providers
    const url = `${Config.BACKEND_URL}communications/email`
    let returnData = {}
    if (communicationType?.value === 'Email') {
      returnData = {
        firebase_id: mongoUser?.firebase_id,
        patient_id: toEmails?.firebase_id,
        communication_type: communicationType?.value,
        from_email: fromEmail,
        cc_email: ccEmail,
        bcc_email: bccEmail,
        audience: audience?.value,
        to_emails: toEmails?.value || null,
        email_address: audience?.value === 'Other' ? emailAddress : '',
        cohort_selected:
          audience?.value === 'Cohort' ? cohortSelected?.value : null,
        template: null,
        subject: subject,
        time_spent: timeSpent,
        message: message,
        file_attached: fileAttached,
        //   scheduleDate: new Date(),
      }
    }
    if (communicationType?.value === 'Phone') {
      returnData = {
        firebase_id: mongoUser?.firebase_id,
        patient_id: phonePatient?.uid,
        communication_type: communicationType?.value,
        phone_type: phoneType?.value,
        phone_name:
          phoneType?.value === 'Existing Patient Call'
            ? phonePatient?.label
            : phoneName,
        phone_number:
          phoneType?.value === 'Existing Patient Call'
            ? phonePatient?.phoneNumber
            : phoneNumber,
        phone_request: phoneRequest?.value,
        phone_message: phoneMessage,
        time_spent: timeSpent,
        //   scheduleDate: new Date(),
      }
    }

    if (communicationType?.value === 'sms') {
      returnData = {
        firebase_id: mongoUser?.firebase_id,
        patient_id: phonePatient?.uid,
        communication_type: communicationType?.value,
        audience: audience?.value,
        phone_number:
          toPhoneNumber?.phoneNumber ||
          (audience?.value === 'Other' ? phoneNumber : ''),
        cohort_selected:
          audience?.value === 'Cohort' ? cohortSelected?.value : null,
        text_sms: textSms,
        time_spent: timeSpent,
      }
    }
    // console.log(returnData)

    axios({
      method: 'post',
      url: url,
      headers: {
        x_firebase_id: mongoUser.firebase_id,
        Authorization: 'JWT ' + token,
      },
      data: returnData,
    })
      .then(async ({ data }) => {
        console.log(data)
        toast.success('Successfully saved email')
        this.setState({
          audience: null,
          toEmails: null,
          emailAddress: '',
          cohortSelected: null,
          template: null,
          subject: '',
          timeSpent: 0,
          message: '',
          providerMap: null,

          phoneType: null,
          phonePatient: null,
          phoneName: '',
          phoneNumber: '',
          phoneRequest: null,
          phoneMessage: '',
          toPhoneNumber: '',
          textSms: '',

          // patientList: [],
          // completePatientList: [],
          // providersList: [],
          // cohortOptions: [],
          fileAttached: null,
          scheduleDate: new Date(),
        })
        // let docObj = {}
        // if (data) {
        //   data.forEach((x) => {
        //     docObj[x.firebase_id] = x
        //   })
        //   this.setState({
        //     providerMap: docObj,
        //   })
        // }
      })
      .catch((err) => {
        console.log('error when saving email', err)
        toast.error('Something went wrong, please try again')
        this.setState({ loading: false, showScheduleModal: false })
      })
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    let self = this

    // Update the state
    if (event?.target?.files[0]) {
      console.log(event?.target?.files[0])
      //   this.setState({
      //     modalFileName: event.target.files[0].name,
      //     modalFileType: event.target.files[0].type,
      //   })

      // if (event?.target?.files[0].type !== 'application/pdf') {
      //   toast.warning('Please upload PDF files')
      //   return
      // }

      this.setState({ loading: true })

      //SAVE IN FIRESTORE STORAGE
      const file = event?.target?.files[0]
      let storageUrl = `emailTemplates/${mongoUser.firebase_id}/${
        '_' + file.name
      }`
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef.child(storageUrl).put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          self.setState({ loading: false })
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // console.log(downloadURL)
            let temp = { ...self.state.fileAttached }
            temp.downloadURL = downloadURL
            temp.fileName = file.name
            self.setState({ fileAttached: temp, loading: false })
          })
        },
      )
    }
  }

  getConditionsData() {
    let { token, doctorPatientData } = this.props
    let url = Config.BACKEND_URL + 'user/conditions'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: doctorPatientData.patient_id,
      },
    })
      .then((response) => {
        console.log('got conditions data')
        self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting conditions data', err)
        toast.error('Something went wrong')
      })
  }

  getAppointmentData() {
    let { token, patient, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'providers/patients/appointments'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_doctor_id: mongoUser.firebase_id,
        x_patient_id: patient.uid,
      },
    })
      .then((response) => {
        self.setState({ appointmentData: response.data })
      })
      .catch((err) => {
        console.log('error when getting appointment information', err)
        toast.error('Something went wrong')
      })
  }

  getCarePlanTemplate() {
    let { token, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'careplan-template'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: mongoUser.firebase_id,
      },
    })
      .then(({ data }) => {
        if (data?.physical_exam_template?.length) {
          let optionObjects = []
          // data?.physical_exam_template?.forEach(option => data.push({label: option, value: option}))
          optionObjects = data?.physical_exam_template.map((option) => {
            let keyLabel = Object.keys(option)[0].replace(/_/g, ' ')
            return {
              label: keyLabel.charAt(0).toUpperCase() + keyLabel.substring(1),
              value: Object.values(option)[0],
            }
          })
          self.setState({ carePlanTemplate: optionObjects })
        }
      })
      .catch((err) => {
        console.log('error when getting careplan template', err)
      })
  }

  renderAddCommunications() {
    return (
      <>
        {this.state.communicationType?.value === 'Email' && (
          <>
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>From</p>
              <input
                placeholder={'Enter from email id'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.fromEmail}
                onChange={(e) => this.setState({ fromEmail: e.target.value })}
              />
            </div>
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>CC</p>
              <input
                placeholder={'Enter CC email id'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.ccEmail}
                onChange={(e) => this.setState({ ccEmail: e.target.value })}
              />
            </div>
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>BCC</p>
              <input
                placeholder={'Enter BCC email id'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.bccEmail}
                onChange={(e) => this.setState({ bccEmail: e.target.value })}
              />
            </div>

            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>To</p>
              <div style={{ width: '60%' }}>
                <Select
                  placeholder={'Select To'}
                  isMulti={false}
                  options={[
                    { label: 'Patient', value: 'Patient' },
                    { label: 'Cohort', value: 'Cohort' },
                    {
                      label: 'Aila Health Provider',
                      value: 'Aila Health Provider',
                    },
                    { label: 'Other', value: 'Other' },
                  ]}
                  value={this.state.audience}
                  onChange={(val) =>
                    this.setState({
                      audience: val,
                      toEmails: null,
                      emailAddress: '',
                      cohortSelected: null,
                    })
                  }
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
            {this.state.audience?.value === 'Patient' ||
            this.state.audience?.value === 'Aila Health Provider' ? (
              <div className={Styles.studyEntryRow}>
                <p className={Styles.studyEntryKey}>Email</p>
                <div style={{ width: '60%' }}>
                  <Select
                    placeholder={'Select Email'}
                    isMulti={false}
                    options={
                      this.state.audience?.value === 'Patient'
                        ? this.state.patientList
                        : this.state.providersList
                    }
                    value={this.state.toEmails}
                    onChange={(val) => this.setState({ toEmails: val })}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            ) : this.state.audience?.value === 'Cohort' ? (
              <div className={Styles.studyEntryRow}>
                <p className={Styles.studyEntryKey}>Cohort</p>
                <div style={{ width: '60%' }}>
                  <Select
                    placeholder={'Select Cohort'}
                    isMulti={false}
                    options={this.state.cohortOptions}
                    value={this.state.cohortSelected}
                    onChange={(val) => this.setState({ cohortSelected: val })}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            ) : (
              this.state.audience?.value === 'Other' && (
                <div className={Styles.studyEntryRow}>
                  <p className={Styles.studyEntryKey}>Email Address</p>
                  <input
                    placeholder={'Enter email address'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.emailAddress}
                    onChange={(e) =>
                      this.setState({ emailAddress: e.target.value })
                    }
                  />
                </div>
              )
            )}

            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Subject</p>
              <input
                placeholder={'Enter subject'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.subject}
                onChange={(e) => this.setState({ subject: e.target.value })}
              />
            </div>

            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Message</p>
              <textarea
                placeholder={'Enter email body'}
                style={{
                  width: '60%',
                  height: 300,
                  padding: '5px 20px',
                  resize: 'none',
                  borderColor: '#aeaeae',
                  borderRadius: 4,
                }}
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
              />
            </div>
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Time Spent</p>
              <input
                placeholder={'0 minutes'}
                className={Styles.studyEntryInput}
                type="number"
                value={this.state.timeSpent || 0 + ' minutes'}
                onChange={(e) => {
                  let time = e.target.value
                  this.setState({
                    timeSpent: time,
                  })
                }}
              />
            </div>
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Attachments</p>
              {/* <FontAwesomeIcon
                onClick={() => {
                  if (!this.state.ailaMessageSelected)
                    this.setState({ modalIsOpen: true })
                }}
                icon={faCalendarPlus}
                style={{
                  color: 'gray',
                  fontSize: 30,
                  marginRight: 10,
                  flexGrow: 1,
                  cursor: 'pointer',
                }}
              /> */}

              <input
                onChange={(e) => {
                  //   this.setState({ signChanged: true })
                  this.onFileChange(e)
                }}
                type="file"
                id={`file-attachment`}
                // ref={this.fileRef}
                // accept="image/*"
                style={{ display: 'none' }}
                multiple={false}
              />

              <div
                className={Styles.studyEntryRow}
                style={{
                  width: '60%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <FontAwesomeIcon
                  id={`file`}
                  title={`file`}
                  onClick={
                    () => document.getElementById(`file-attachment`).click()
                    // this.fileRef.current.click()
                  }
                  icon={faPaperclip}
                  style={{
                    color: 'gray',
                    fontSize: 30,
                    marginRight: 10,
                    flexGrow: 1,
                    cursor: 'pointer',
                    // position: 'absolute',
                    // marginLeft: '200px',
                  }}
                />
                {this.state.fileAttached?.fileName && (
                  <p
                    // onClick={() => this.fileRef.current.click()}
                    style={{
                      textDecoration: 'underline',
                      color: 'gray',
                      cursor: 'pointer',
                    }}
                  >
                    {this.state.fileAttached?.fileName}
                  </p>
                )}
              </div>

              {/* <FontAwesomeIcon
                  id={`file-attachment`}
                  title={`file-attachment`}
                  // onClick={() => {

                  // }}
                  icon={faPaperclip}
                  style={{
                    color: 'gray',
                    fontSize: 30,
                    marginRight: 10,
                    flexGrow: 1,
                    cursor: 'pointer',
                  }}
                />

                <input
                  id="file-attachment"
                  // accept="application/pdf"
                  style={{ display: 'none' }}
                  type="file"
                  onClick={() => {
                    console.log('file upload')
                  }}
                  onChange={this.onFileChange.bind(this)}
                /> */}
            </div>
          </>
        )}
        {this.state.communicationType?.value === 'sms' && (
          <>
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>From</p>
              <input
                placeholder={'(239) 666-6912'}
                className={Styles.studyEntryInput}
                disabled={true}
              />
            </div>

            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>To</p>
              <div style={{ width: '60%' }}>
                <Select
                  placeholder={'Select To'}
                  isMulti={false}
                  options={[
                    { label: 'Patient', value: 'Patient' },
                    { label: 'Cohort', value: 'Cohort' },
                    {
                      label: 'Aila Health Provider',
                      value: 'Aila Health Provider',
                    },
                    { label: 'Other', value: 'Other' },
                  ]}
                  value={this.state.audience}
                  onChange={(val) =>
                    this.setState({
                      audience: val,
                      toPhoneNumber: null,
                      phoneNumber: '',
                      cohortSelected: null,
                    })
                  }
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>
            {this.state.audience?.value === 'Patient' ||
            this.state.audience?.value === 'Aila Health Provider' ? (
              <div className={Styles.studyEntryRow}>
                <p className={Styles.studyEntryKey}>Phone</p>
                <div style={{ width: '60%' }}>
                  <Select
                    placeholder={'Select Phone Number'}
                    isMulti={false}
                    options={
                      this.state.audience?.value === 'Patient'
                        ? this.state.patientList
                        : this.state.providersList
                    }
                    value={{
                      ...this.state.toPhoneNumber,
                      label:
                        (this.state.toPhoneNumber?.label || '') +
                        (this.state.toPhoneNumber
                          ? '-' +
                            (this.state.toPhoneNumber?.phoneNumber || '-N/A')
                          : ''),
                    }}
                    onChange={(val) => {
                      console.log('onclicked select ph: ', val)
                      this.setState({ toPhoneNumber: val })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            ) : this.state.audience?.value === 'Cohort' ? (
              <div className={Styles.studyEntryRow}>
                <p className={Styles.studyEntryKey}>Cohort</p>
                <div style={{ width: '60%' }}>
                  <Select
                    placeholder={'Select Cohort'}
                    isMulti={false}
                    options={CohortOptionList}
                    value={this.state.cohortSelected}
                    onChange={(val) => this.setState({ cohortSelected: val })}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            ) : (
              this.state.audience?.value === 'Other' && (
                <div className={Styles.studyEntryRow}>
                  <p className={Styles.studyEntryKey}>Phone Number</p>
                  <input
                    placeholder={'Enter phone number'}
                    className={Styles.studyEntryInput}
                    type="text"
                    value={this.state.phoneNumber}
                    onChange={(e) =>
                      this.setState({ phoneNumber: e.target.value })
                    }
                  />
                </div>
              )
            )}

            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Text SMS</p>
              <input
                placeholder={'Enter SMS'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.textSms}
                onChange={(e) => this.setState({ textSms: e.target.value })}
              />
            </div>

            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Time Spent</p>
              <input
                placeholder={'0 minutes'}
                className={Styles.studyEntryInput}
                type="number"
                value={this.state.timeSpent || 0 + ' minutes'}
                onChange={(e) => {
                  let time = e.target.value
                  this.setState({
                    timeSpent: time,
                  })
                }}
              />
            </div>
          </>
        )}

        {this.state.communicationType?.value === 'Phone' && (
          <>
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Phone Type</p>
              <div style={{ width: '60%' }}>
                <Select
                  placeholder={'Select To'}
                  isMulti={false}
                  options={[
                    { label: 'New Patient Call', value: 'New Patient Call' },
                    {
                      label: 'Existing Patient Call',
                      value: 'Existing Patient Call',
                    },

                    { label: 'Other', value: 'Other' },
                  ]}
                  value={this.state.phoneType}
                  onChange={(val) => {
                    if (val.value === 'New Patient Call') {
                      this.setState({
                        phoneType: null,
                        phonePatient: null,
                        phoneName: '',
                        phoneRequest: null,
                        phoneMessage: '',
                      })

                      this.props.parentCallback({
                        success: true,
                      })
                    }
                    this.setState({
                      phoneType: val,
                      phonePatient: null,
                      phoneName: '',
                      phoneRequest: null,
                      phoneMessage: '',
                    })
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>

            {this.state.phoneType?.value === 'Existing Patient Call' ? (
              <div className={Styles.studyEntryRow}>
                <p className={Styles.studyEntryKey}>Patient Name</p>
                <div style={{ width: '60%' }}>
                  <Select
                    placeholder={'Select Patient'}
                    isMulti={false}
                    options={this.state.patientList}
                    value={this.state.phonePatient}
                    onChange={(val) => this.setState({ phonePatient: val })}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            ) : (
              this.state.phoneType?.value === 'Other' && (
                <>
                  <div className={Styles.studyEntryRow}>
                    <p className={Styles.studyEntryKey}>Name</p>
                    <input
                      placeholder={'Enter name'}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={this.state.phoneName}
                      onChange={(e) =>
                        this.setState({ phoneName: e.target.value })
                      }
                    />
                  </div>
                  <div className={Styles.studyEntryRow}>
                    <p className={Styles.studyEntryKey}>Phone Number</p>
                    <input
                      placeholder={'Enter phone number'}
                      className={Styles.studyEntryInput}
                      type="text"
                      value={this.state.phoneNumber}
                      onChange={(e) =>
                        this.setState({ phoneNumber: e.target.value })
                      }
                    />
                  </div>
                </>
              )
            )}

            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Request Type</p>
              <div style={{ width: '60%' }}>
                <Select
                  placeholder={'Select'}
                  isMulti={false}
                  options={metadata.taskTypes}
                  value={this.state.phoneRequest}
                  onChange={(val) =>
                    this.setState({
                      phoneRequest: val,
                    })
                  }
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            </div>

            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Message</p>
              <textarea
                placeholder={'Enter phone message'}
                style={{
                  width: '60%',
                  height: 300,
                  padding: '5px 20px',
                  resize: 'none',
                  borderColor: '#aeaeae',
                  borderRadius: 4,
                }}
                value={this.state.phoneMessage}
                onChange={(e) =>
                  this.setState({ phoneMessage: e.target.value })
                }
              />
            </div>
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Time Spent</p>
              <input
                placeholder={'0 minutes'}
                className={Styles.studyEntryInput}
                type="number"
                value={this.state.timeSpent || 0 + ' minutes'}
                onChange={(e) => {
                  let time = e.target.value
                  this.setState({
                    timeSpent: time,
                  })
                }}
              />
            </div>
          </>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            justifyContent: 'space-evenly',
          }}
        >
          {this.state.communicationType?.value === 'Phone' && (
            <Button
              onClick={() => this.setState({ modalAddTaskIsOpen: true })}
              className={GlobalStyles.button}
              style={{ width: 200, marginTop: 40 }}
              variant="primary"
              disabled={!this.state.communicationType}
            >
              Assign Task
            </Button>
          )}

          <Button
            onClick={() => this.onSubmitPressed()}
            className={GlobalStyles.button}
            style={{ width: 200, marginTop: 40 }}
            variant="primary"
            disabled={!this.state.communicationType}
          >
            {this.state.communicationType?.value === 'Phone' ? 'Save' : 'Send'}
          </Button>
        </div>
      </>
    )
  }

  sortListByDate() {
    const { allCommunications, sortValue } = this.state

    if (sortValue) {
      allCommunications.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at),
      )
    } else {
      allCommunications.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      )
    }
    this.setState({ sortValue: !sortValue })

    this.setState({ allCommunications })
  }

  TableHeader() {
    if (this.state.communicationType?.value === 'Email') {
      return (
        <div
          className={Styles.contentRow}
          onClick={this.sortListByDate.bind(this)}
          style={{
            cursor: 'pointer',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'center',
            // color: 'gray',
            // width: '15%',
            // textAlign: 'center',
          }}
        >
          <h6 className={Styles.smtableHeader}>
            Date <span>{this.state.sortValue ? `↓` : `↑`}</span>
          </h6>
          <h6 className={Styles.mtableHeader}>Sender</h6>
          <h6 className={Styles.mtableHeader}>Reciever</h6>
          <h6 className={Styles.mtableHeader}>Subject</h6>

          <h6 className={Styles.xtableHeader}>Message</h6>
          {/* <h6 className={Styles.mtableHeader}>Attachment</h6> */}
        </div>
      )
    } else if (this.state.communicationType?.value === 'Phone') {
      return (
        <div
          className={Styles.contentRow}
          onClick={this.sortListByDate.bind(this)}
          style={{
            cursor: 'pointer',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'center',
            // color: 'gray',
            // width: '15%',
            // textAlign: 'center',
          }}
        >
          <h6 className={Styles.smtableHeader}>
            Date <span>{this.state.sortValue ? `↓` : `↑`}</span>
          </h6>
          <h6 className={Styles.mtableHeader}>Phone Type</h6>
          <h6 className={Styles.mtableHeader}>Patient</h6>
          <h6 className={Styles.mtableHeader}>Request Type</h6>
          <h6 className={Styles.xtableHeader}>Message</h6>
        </div>
      )
    }
  }

  TableContents = () => {
    let {
      allCommunications,
      communicationType,
      patientList,
      searchEmail,
      searchName,
    } = this.state
    let tableContentElement = []
    let tempArray = []
    tempArray =
      allCommunications &&
      (communicationType?.value === 'Email'
        ? allCommunications?.filter(
            (comm) => comm?.communication_type === 'Email',
          )
        : allCommunications?.filter(
            (comm) => comm?.communication_type === 'Phone',
          ))

    //SEARCH BY EMAIL
    const options = {
      includeScore: true,
      keys: [
        'to_emails',
        'from_email',
        'email_address',
        'phone_name',
        'phone_number',
      ],
    }

    this.fuse = new Fuse(tempArray, options)

    let uniqueList = []

    if (searchEmail.length !== 0) {
      const results = this.fuse.search(searchEmail)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
      // console.log('SR::', searchResults)
    } else if (searchName.length !== 0) {
      const results = this.fuse.search(searchName)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
      // console.log('SR::', searchResults)
    } else {
      uniqueList = tempArray
    }

    tableContentElement = uniqueList.map((commData, index) => {
      if (communicationType?.value === 'Email') {
        return (
          <div key={index} className={Styles.contentRow}>
            <h7 className={Styles.smtableData}>
              {' '}
              {moment(commData?.created_at).format('MMM Do YYYY, HH:mm A')}
            </h7>
            <h7 className={Styles.mtableHeader}>
              {commData.from_email || ''}
              {/* <br />
              {commData?.cc_email && `CC: ${commData?.cc_email}`} */}
            </h7>
            <h7 className={Styles.mtableData}>
              {commData?.audience === 'Patient'
                ? commData?.to_emails
                : commData?.audience === 'Cohort'
                ? commData?.cohort_selected
                : commData?.audience === 'Aila Health Provider'
                ? commData?.to_emails
                : commData?.email_address || ''}
            </h7>
            <h7 className={Styles.mtableData}>{commData?.subject}</h7>

            <h7 className={Styles.xtableData}>
              {/* {commData?.message} */}

              <p
                data-tip
                data-for={index + 'message'}
                style={{
                  marginLeft: 20,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {commData?.message}
              </p>
              <ReactTooltip
                id={index + 'message'}
                multiline={true}
                place={'top'}
                effect={'solid'}
                clickable={true}
                className={Styles.tooltip}
              >
                {commData?.message}
              </ReactTooltip>
            </h7>
          </div>
        )
      } else if (communicationType?.value === 'Phone') {
        let patientPhone = ''
        let patientNum = ''
        if (commData?.phone_type === 'Other') {
          patientPhone = commData?.phone_name
          // + '\n\n' + commData?.phone_number
          patientNum = commData?.phone_number
        } else {
          let patient = patientList?.find(
            (item) => item.firebase_id === commData.patient_id,
          )
          patientPhone = patient?.label
          // + '\n\n' + patient?.phoneNumber
          patientNum = patient?.phoneNumber
        }
        return (
          <div key={index} className={Styles.contentRow}>
            <h7 className={Styles.smtableData}>
              {' '}
              {moment(commData?.created_at).format('MMM Do YYYY, HH:mm A')}
            </h7>
            <h7 className={Styles.mtableHeader}>
              {commData?.phone_type || ''}
            </h7>
            <h7 className={Styles.mtableHeader}>
              {patientPhone}
              <br />
              {patientNum}
              {/* {commData?.phone_type === 'Other'
                ? commData?.phone_name + <br /> + commData?.phone_number
                : patientList?.find(
                    (item) => item.firebase_id === commData.patient_id,
                  )?.label + <br />} */}
            </h7>

            <h7 className={Styles.mtableData}>
              {commData.phone_request || ''}
            </h7>
            <h7 className={Styles.xtableData}>
              {/* {commData.phone_message || ''} */}

              <p
                data-tip
                data-for={index + 'phone_message'}
                style={{
                  marginLeft: 20,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {commData?.phone_message}
              </p>
              <ReactTooltip
                id={index + 'phone_message'}
                multiline={true}
                place={'top'}
                effect={'solid'}
                clickable={true}
                className={Styles.tooltip}
              >
                {commData?.phone_message}
              </ReactTooltip>
            </h7>
          </div>
        )
      }
    })
    return tableContentElement
  }

  renderViewCommunications() {
    return (
      <div
        style={{
          width: '100%',
          margin: 10,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={Styles.contentBackground}>
          {this.TableHeader()}
          {this.TableContents()}
        </div>
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <h4
          style={{
            color: Constants.primaryTheme,
            width: '100%',
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          Communications
        </h4>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 20,
            marginBottom: 10,
            width: '100%',
          }}
        >
          <Button
            onClick={() => this.setState({ viewCommunications: false })}
            className={GlobalStyles.button}
            style={{ width: 200, marginRight: 40 }}
            variant="primary"
          >
            Add
          </Button>

          <Button
            onClick={() => {
              this.getAllCommunications()
              this.setState({ viewCommunications: true })
            }}
            className={GlobalStyles.button}
            style={{ width: 200 }}
            variant="primary"
          >
            View All
          </Button>
        </div>

        <div className={Styles.studyEntryRow}>
          <p className={Styles.studyEntryKey}>Communication</p>
          <div style={{ width: '60%' }}>
            <Select
              placeholder={'Select Type'}
              isMulti={false}
              options={[
                { label: 'Email', value: 'Email' },
                { label: 'Phone', value: 'Phone' },
                { label: 'SMS', value: 'sms' },
              ]}
              value={this.state.communicationType}
              onChange={(val) => this.setState({ communicationType: val })}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>
        {this.state.viewCommunications &&
          this.state.communicationType?.value === 'Email' && (
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Search Email</p>
              <input
                placeholder={'Enter email'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.searchEmail}
                onChange={(e) => this.setState({ searchEmail: e.target.value })}
              />
            </div>
          )}

        {this.state.viewCommunications &&
          this.state.communicationType?.value === 'Phone' && (
            <div className={Styles.studyEntryRow}>
              <p className={Styles.studyEntryKey}>Search Name/Phone</p>
              <input
                placeholder={'Enter name/phone'}
                className={Styles.studyEntryInput}
                type="text"
                value={this.state.searchName}
                onChange={(e) => this.setState({ searchName: e.target.value })}
              />
            </div>
          )}

        {this.state.viewCommunications
          ? this.renderViewCommunications()
          : this.renderAddCommunications()}

        <TaskModal
          modalIsOpen={this.state.modalAddTaskIsOpen}
          hideTaskModal={this.hideTaskModal}
          getUserTask={this.getUserTask}
        />
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  addPatientList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Communications)
