import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
export default class AilaWebConsentPolicies extends Component {
  constructor() {
    super()
    this.state = {
      patient_id: this.props?.location?.state?.patient_id
        ? this.props?.location?.state?.patient_id
        : '',
      programSelected: this.props?.location?.state?.planSelected
        ? this.props?.location?.state?.planSelected
        : '',
      planType: this.props?.location?.state?.stateResidence
        ? this.props?.location?.state?.stateResidence
        : 'CO',
    }
    this.renderMainContent = this.renderMainContent.bind(this)
  }

  renderMainContent = () => {
    return (
      <div
        style={{
          flex: 1,
          alignSelf: 'center',
          marginTop: '-8vh',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            margin: 'auto',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '2vh',
          }}
        >
          Consents and Policies
        </p>
      </div>
    )
  }
  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div>

        {this.renderMainContent()}

        <div
          style={{
            flex: 1,
            alignSelf: 'center',
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault()
              console.log('Patient ID:', this.state.patient_id)
              this.props.history.push('/wix/aila/appointment-scheduler', {
                patient_id: this.props?.location?.state?.patient_id,
                stateResidence: this.props?.location?.state?.programType,
                planSelected: this.state.programSelected,
              })
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '2vh',
              alignSelf: 'center',
              width: 200,
            }}
            variant="primary"
          >
            {'Schedule Appointment'}
          </Button>
        </div>
      </div>
    )
  }
}
