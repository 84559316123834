import React from 'react'
import GlobalStyles from '../styles/global.module.scss'
import AilaLogo from '../../assets/images/aila_logo.png'

const windowWidth = window.innerWidth
const webLogoStyle = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  position: 'fixed',
  left: 40,
  top: 20,
}

const mobileLogoStyle = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
}

const CCFM_TOS = () => (
  <div className={GlobalStyles.container} style={{ backgroundColor: 'white' }}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: 'fixed',
        left: 40,
        top: 20,
      }}
    >
      <div style={windowWidth > 600 ? webLogoStyle : mobileLogoStyle}>
        <img src={AilaLogo} style={{ height: 120, width: 120 }} />
      </div>
    </div>

    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '60px 20px',
      }}
    >
      <h4 style={{ fontWeight: 'bold', width: '60%', textAlign: 'center' }}>
        CALIFORNIA CENTER FOR FUNCTIONAL MEDICINE PRIVATE PRACTICE PATIENT
        AGREEMENT
      </h4>
      <p>Last Updated: October 18, 2020</p>

      <div
        style={{
          marginTop: 40,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '80%',
        }}
      >
        <p>
          This Private Practice Patient Agreement (“Agreement”) specifies the
          terms and conditions under which, you, the undersigned patient
          (“Patient”) may voluntarily elect to participate in the healthcare
          offerings made available by the Aila Health technology platform, along
          with California Center for Functional Medicine, a California
          professional corporation (“Practice”) which are summarized as follows:
          chronic illness care membership, informed by principles of both
          allopathic, and integrative/functional medicine, and made available on
          a routine regardless of medical condition or necessity basis along
          with a related electronic health data storage and communication
          facilitation platform plan to provide communication in support of
          patient exam health goals and patient education (“Health Data
          Communication Plan”). (Patient and Practice shall be referred to
          individually as “Party” or collective as the “Parties”). Practice has
          formally opted out of Medicare, and is out-of-network with any and all
          private or public health insurance plans (“Plan” or “Plans”). Patient
          shall pay privately and directly for all Services and will be unable
          to submit any Services fees to Medicare (or likely any Plan) for
          reimbursement.
        </p>

        <h5 style={{ marginTop: 40 }}>
          VACATIONS AND ILLNESS FOR PRACTICE HEALTHCARE PROFESSIONALS
        </h5>
        <p style={{ marginTop: 10 }}>
          Patient acknowledges that there may be times that Patient cannot
          contact the Practice healthcare professional due to vacations or
          illness, or due to Practice healthcare professional continuing medical
          education, or due to technical defects with either Patient’s or
          Practice’s electronic communication equipment. Patient acknowledges
          that, should a Practice healthcare professional become unavailable,
          Practice shall make every effort to give advance notice to Patient so
          that Services can be scheduled or delivered on another date and/or
          shall have a healthcare professional colleague cover the Practice. In
          cases of emergency or extended absence from the Practice by the
          Practice healthcare professional, contact information for a covering
          healthcare professional provider colleague will be provided.
        </p>

        <h5 style={{ marginTop: 40 }}>COMPLIANCE WITH LAW</h5>
        <p style={{ marginTop: 10 }}>
          Practice agrees to make Services available for Services Fees paid to
          the Practice via the Aila Health technology platform with the intent
          to comply with all applicable laws. This Agreement shall be governed
          by and construed in accordance with the laws of the State of
          California, excluding choice-of-law principles. If there is a change
          of any law, regulation or rule, federal, state or local, which affects
          the Agreement or the activities of either Party under the Agreement,
          or any change in judicial or administrative interpretation of any such
          law, regulation or rule, this Agreement shall be deemed modified so as
          to remain in compliance with such laws.
        </p>

        <h5 style={{ marginTop: 40 }}>PRACTICE IS NOT AN INSURER</h5>
        <p style={{ marginTop: 10 }}>
          Practice is not an insurance company and is not promising or
          delivering unlimited care or services for the Services Fees paid to
          the Practice via the Aila Health technology platform. Practice
          presumes that Patient is either eligible for Medicare or otherwise has
          a private or public Plan that provides health care coverage for
          essential healthcare services not covered by Services Fees paid to the
          Practice via the Aila Health technology platform.
        </p>

        <h5 style={{ marginTop: 40 }}>
          AGREEMENT ASSIGNMENT AND MODIFICATIONS
        </h5>
        <p style={{ marginTop: 10 }}>
          This Agreement may not be assigned to any other person by Patient.
          This Agreement replaces and supersedes all prior agreements of any
          kind, oral or in writing, between Patient and Practice. This Agreement
          may not be modified absent a writing signed by Patient and an
          authorized representative of Practice.
        </p>

        <h5 style={{ marginTop: 40 }}>MISCELLANEOUS PROVISIONS</h5>
        <p style={{ marginTop: 10 }}>
          The Client acknowledges that any dispute with respect to this
          Agreement shall be resolved through binding arbitration, in accordance
          with the arbitration rules and regulations of the American Health
          Lawyers Association, and will be conducted in Contra Costa County,
          California. The Patient and Practice agree to be bound by the
          arbitrator’s decision.
        </p>

        <h5 style={{ marginTop: 40, textAlign: 'center' }}>
          PATIENT ACKNOWLEDGES THAT HE/SHE HAS CAREFULLY READ THIS AGREEMENT,
          WAS AFFORDED SUFFICIENT OPPORTUNITY TO CONSULT WITH LEGAL COUNSEL OF
          HIS/HER CHOICE AND TO ASK QUESTIONS AND RECEIVE SATISFACTORY ANSWERS
          REGARDING THIS AGREEMENT, UNDERSTAND HIS/HER RESPECTIVE RIGHTS AND
          OBLIGATIONS UNDER IT, AND SIGNED IT OF HIS/HER OWN FREE WILL AND
          VOLITION.
        </h5>
      </div>

      <div
        style={{
          marginTop: 60,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '80%',
        }}
      >
        <h4 style={{ fontWeight: 'bold', width: '60%', textAlign: 'center' }}>
          NOTICE OF PRIVACY PRACTICES
        </h4>
        <p style={{ textAlign: 'center' }}>
          THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
          AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
          REVIEW IT CAREFULLY.
        </p>

        <p style={{ marginTop: 30, width: '100%' }}>Dear Patient:</p>
        <p>
          California Center for Functional Medicine, a California professional
          corporation (“we”, “us”, “our”, “Practice”), understands that patient
          (“you”, “your”) privacy is important. This Notice of Privacy Practices
          (“Notice”) applies to Practice, please also refer to the Aila Health
          Notice of Privacy Practices, as applicable.
        </p>

        <h5 style={{ marginTop: 40 }}>Protected Health Information</h5>
        <p style={{ marginTop: 10 }}>
          Protected health information (“PHI”) relates to information about you
          and your health, which could be used to identify you. Each time that
          you visit us, we create a medical record of your PHI and services that
          you receive, which will be stored and maintained in the Aila Health
          EHR by Aila Health, Inc.
        </p>

        <h5 style={{ marginTop: 40 }}>
          Our Obligations Regarding Your Protected Health Information
        </h5>
        <p style={{ marginTop: 10 }}>
          We recognize that information about you and your health is
          confidential, and we are committed to protecting this information.
          This Notice applies to all your health records that we create.
        </p>
        <p style={{ marginTop: 5 }}>
          We are required by law to preserve the privacy and security of your
          PHI. While there is no absolute guarantee of privacy, we are committed
          to protecting your privacy. We have established reasonable and
          appropriate measures to protect your PHI against unauthorized uses and
          disclosures.
        </p>
        <p style={{ marginTop: 5 }}>
          Federal law mandates that we make this Notice available to you, and
          that we make a good faith effort to obtain a signed document
          acknowledging your receipt of this Notice. We are also required to
          follow the terms of this Notice. In the event that we are involved in
          a breach of your PHI, we will immediately notify you.
        </p>

        <h5 style={{ marginTop: 40 }}>
          Notice Effective Date and Potential Changes
        </h5>
        <p style={{ marginTop: 10 }}>
          This Notice became effective on October 18, 2021, and it applies to
          health records that we create for you. We reserve the right to change
          this Notice after the effective date. We can change the terms of this
          Notice, and the changes will apply to all the information we have
          about you. The new Notice will be available upon request.
        </p>

        <h5 style={{ marginTop: 40 }}>
          How We May Disclose Your Protected Health Information
        </h5>
        <p style={{ marginTop: 10 }}>
          The laws of the state where Practice is located, and federal laws,
          allow disclosures of your PHI under certain circumstances. Some of
          these disclosures do not require your verbal or written permission.
          The following information describes how we may share your PHI. We may
          typically use or share your PHI in the following ways:
        </p>

        <h5 style={{ marginTop: 20, width: '100%' }}>Treat you</h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          We can use your PHI and share it with other professionals who are
          treating you.
        </p>
        <ul style={{ width: '100%' }}>
          <li>
            Example: A doctor treating you for an injury asks another doctor
            about your overall health condition.
          </li>
        </ul>

        <h5 style={{ marginTop: 20, width: '100%' }}>Run our organization</h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          We can use and share your PHI to run our practice, improve your care,
          and contact you when necessary.
        </p>
        <ul style={{ width: '100%' }}>
          <li>
            Example: We use health information about you to manage your
            treatment and services.
          </li>
        </ul>

        <h5 style={{ marginTop: 20, width: '100%' }}>
          Help with public health and safety issues
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          We can share your PHI for certain situations such as:
        </p>
        <ul style={{ width: '100%' }}>
          <li>Preventing disease;</li>
          <li>Helping with product recalls;</li>
          <li>Reporting adverse reactions to medications;</li>
          <li>Reporting suspected abuse, neglect, or domestic violence; and</li>
          <li>
            Preventing or reducing a serious threat to anyone’s health or
            safety.
          </li>
        </ul>

        <h5 style={{ marginTop: 20, width: '100%' }}>Perform research</h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          We can use or share your PHI for health research.
        </p>

        <h5 style={{ marginTop: 20, width: '100%' }}>Comply with the law</h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          We will share your PHI if state or federal laws require it, including
          with the Department of Health and Human Services if it wants to see
          that we are complying with federal privacy law.
        </p>

        <h5 style={{ marginTop: 20, width: '100%' }}>
          Respond to lawsuits and legal actions
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          We can share your PHI in response to a court or administrative order,
          or in response to a subpoena.
        </p>

        <h5 style={{ marginTop: 20, width: '100%' }}>
          How else can we use or share your PHI?
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          We are allowed or required to share your PHI in other ways – usually
          in ways that contribute to the public good, such as public health and
          research. We have to meet many conditions in the law before we can
          share your information for these purposes. We have not listed every
          use and disclosure in this Notice. For more information see:
        </p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html"
        >
          www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html.
        </a>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
            textDecoration: 'underline',
          }}
        >
          Use and Disclosure of Your PHI with Your Verbal Agreement
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          For certain health information, you can tell us your choices about
          what we share. If you have a clear preference for how we share your
          information in the situations described below, talk to us. Tell us
          what you want us to do, and we will follow your instructions.
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          In these cases, you have both the right and choice to tell us to:
        </p>
        <ul style={{ width: '100%' }}>
          <li>
            Share information with your family, close friends, or others
            involved in your care;
          </li>
          <li>Share information in a disaster relief situation; and</li>
          <li>Include your information in a hospital directory.</li>
        </ul>
        <p style={{ width: '100%' }}>
          If you are not able to tell us your preference, for example, if you
          are unconscious, we may go ahead and share your information if we
          believe it is in your best interest. We may also share your
          information when needed to lessen a serious and imminent threat to
          health or safety.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
            textDecoration: 'underline',
          }}
        >
          Use and Disclosure of Your PHI Requiring Your Written Permission
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          If there are situations that have not been described above, we will
          obtain your written permission. In these cases, we never share your
          PHI unless you give us written permission:
        </p>

        <ul style={{ width: '100%' }}>
          <li>Marketing purposes;</li>
          <li>Sale of your information; and</li>
          <li>Most sharing of psychotherapy notes.</li>
        </ul>

        <p style={{ width: '100%' }}>
          In the case of fundraising, we may contact you for fundraising
          efforts, but you can tell us not to contact you again.
        </p>
        <p style={{ width: '100%', marginTop: 5 }}>
          If you provide us with written permission, you may change your mind at
          any time. Please let us know in writing if you change your mind.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
            textDecoration: 'underline',
          }}
        >
          Your Rights Regarding Your PHI
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          You have the following rights regarding your PHI that is maintained by
          Aila Health, Inc. please refer to this{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ailahealth.com/privacy-policy"
          >
            privacy notice
          </a>{' '}
          for details.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          Get a copy of this Notice
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          You can ask for a paper copy of this Notice at any time, even if you
          have agreed to receive this Notice electronically. We will provide you
          with a paper copy promptly.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          Ask questions of file a complaint if you believe your rights are
          violated
        </h5>
        <ul style={{ width: '100%' }}>
          <li>
            If you have questions about this Notice or you believe that your
            rights are being violated, please contact us immediately.
          </li>
          <li>
            You can file a complaint with the U.S. Department of Health and
            Human Services Office for Civil Rights by sending a letter to 200
            Independence Avenue, S.W., Washington, D.C. 20201, calling
            1-877-696-6775, or visiting{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hhs.gov/ocr/privacy/hipaa/complaints"
            >
              www.hhs.gov/ocr/privacy/hipaa/complaints
            </a>
            .
          </li>
        </ul>
        <p style={{ marginTop: 5, width: '100%' }}>
          Please provide as much information as possible so that your concern or
          complaint can be thoroughly investigated. We will not retaliate
          against you for filing a complaint with us, or the Department of
          Health and Human Services.
        </p>
      </div>

      <div
        style={{
          marginTop: 60,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '80%',
        }}
      >
        <h4 style={{ fontWeight: 'bold', width: '60%', textAlign: 'center' }}>
          ELECTRONIC COMMUNICATIONS AGREEMENT
        </h4>
        <p style={{ marginTop: 5, width: '100%' }}>
          California Center for Functional Medicine, a California professional
          corporation (“we”, “us” or “Practice”), and the undersigned patient
          (“you” or “Patient”) enter into this Electronic Communications
          Agreement (“EC Agreement”) regarding the use of
          e-communications/transmissions, such as e-mail, mobile or cellular
          telephone,video conference, internet portal-enabled communications, or
          any other version of electronic communication (collectively
          “E-Communication”) with respect to Patient protected health
          information (“PHI”). (Practice and Patient are each individually
          referred to as “Party” or collectively as “Parties”).
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          PATIENT AUTHORIZATION DESPITE RISKS OF PRIVACY BREACH
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          While electronic communication platforms and services are commonly
          relied upon to achieve communication immediacy, there are risks that
          you acknowledge that are outside the control of the Practice. You
          authorize all forms of E-Communications that are exchanged between the
          Parties unless you instruct us otherwise in writing.
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          You acknowledge that the utilization of E-Communication is inherently
          risky and prone to unintentional release of data. E-Communications may
          incorporate or communicate references to your PHI with sensitive
          health and personal identification information included. You
          acknowledge that E-Communications lack any absolute guaranty of
          privacy and are subject to: system privacy failure, cookies and other
          tracking efforts, phishing attacks, hacking attacks, data breaches,
          unintended misdirections, misidentifications of senders/recipients,
          technology failures, and user errors.
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          You agree to undertake efforts to protect your privacy, which includes
          refraining from including sensitive information in E-Communications
          that you do not want to be at risk of any data security breach.
          Practice will undertake reasonable efforts to protect your privacy to
          the extent required by applicable laws. You authorize us to respond
          electronically to all E-Communications that appear to be provided by
          you, whether or not such communications arrive from the electronic
          contact information that you provide us.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          PATIENT MUST PROVIDE ACCURATE and UPDATED CONTACT INFORMATION
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          You agree to provide us with your accurate electronic contact
          information (mobile telephone number for phone calls and text
          messaging, email address, and any other applicable E-Communication
          contact information). You will immediately inform Aila Health of any
          changes or corrections to your electronic contact information as an
          effort to avoid misdirected E-Communications.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          PATIENT MUST NOT RELY ON ELECTRONIC COMMUNICATION IN EMERGENCIES: USE
          911 AND GET TO THE EMERGENCY ROOM
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          Practice does not guarantee that we will read your E-Communications
          immediately or within any specific amount of time. You agree not to
          utilize E-Communications to contact us regarding an emergency or
          time-sensitive situation, as there is too much risk that the
          communication response may be delayed, ineffective, untimely, or
          inadequate.{' '}
          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            You MUST call 911 in an emergency, and/or must immediately seek
            emergency medical attention.
          </span>
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          PRACTICE WILL COMPLY WITH HIPAA
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          The Practice values and appreciates your privacy and will take
          commercially reasonable steps to protect Patient’s privacy in
          compliance with the Health Insurance Portability and Accountability
          Act of 1996 and related laws (“HIPAA”).
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          We will obtain your express written or electronic consent (to the
          extent required by applicable law) if we are required or requested to
          forward your identifiable PHI to any third party other than as
          authorized in our Notice of Privacy Practices or as authorized or
          mandated by applicable law.
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          You hereby consent to the use of E-Communication of Patient’s
          information as we consider it helpful to coordinate care and schedule
          mobile visits with you and all parties responsible for providing or
          overseeing your care. You agree to identify individuals or entities
          authorized to receive your PHI from us in connection with authorized
          consulting, education, and all other aspects of your care, and we may
          share Patient’s PHI with such parties without additional written or
          electronic consent from you.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          PATIENT ACCEPTS RESPONSIBILITY FOR ELECTRONIC COMMUNICATION RISKS
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          You will hold Practice (and our owners, officers, directors, agents,
          and employees) harmless from and against any and all demands, claims,
          and damages to persons or property, losses, and liabilities, including
          reasonable attorney fees, arising out of or caused by E-Communication
          (whether encrypted or not) losses or disclosures caused by any of the
          risks outlined above, or caused by some person or entity other than
          Practice, or not directly caused by us. Patient acknowledges and
          understands that, at our discretion, E-Communication may or may not
          become part of your permanent medical record. Practice is not relieved
          by these terms from Practice’s obligations to comply with all
          applicable E-Communication laws.
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          You acknowledge that your failure to comply with the terms of this EC
          Agreement may result in our terminating the use of E-Communication
          methods with you and may result in the termination of your agreement
          for our services.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          ACKNOWLEDGMENT OF RECEIPT OF NOTICE OF PRIVACY PRACTICES
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          We are required to provide you a copy of our Notice of Privacy
          Practices, which states how we may or disclose your health
          information. You hereby acknowledge receipt of the Notice of Privacy
          Practices.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
          }}
        >
          ADDITIONAL TERMS
        </h5>
        <p style={{ marginTop: 5, width: '100%' }}>
          This EC Agreement will remain in effect until either Party provides
          written notice to the other Party revoking this EC Agreement or
          otherwise revoking consent to E-Communications between the Parties.
          Such revocation will occur thirty (30) calendar days after written
          notice of such revocation.
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          Revocation of this EC Agreement will preclude us from providing
          treatment information in an electronic format other than as authorized
          or mandated by applicable law or by you. Either Party may use a copy
          of this signed original EC Agreement for all present and future
          purposes.
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          Parties agree to take such action as is reasonably necessary to amend
          this EC Agreement from time to time as it is necessary for the Parties
          to comply with the requirements of the Privacy Rule, the Security
          Rule, and other provisions of HIPAA, or other applicable law. Parties
          further agree that this EC Agreement cannot be changed, modified or
          discharged except by an agreement in writing and signed by both
          Parties.
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          If any term of this EC Agreement is deemed invalid or in violation of
          any applicable law or public policy, the remaining terms of this EC
          Agreement shall remain in full force and effect, and this EC Agreement
          shall be deemed amended to conform to any applicable law. The
          construction, interpretation, and performance of this EC Agreement and
          all transactions under this EC Agreement shall be governed by the laws
          of the state where the Practice is located, excluding choice-of-law
          principles.
        </p>
        <p style={{ marginTop: 5, width: '100%' }}>
          Each participating patient (and authorized representative when
          applicable) is required to sign this EC Agreement. Your signature
          represents that you understand and agree to the terms and conditions
          described within this EC Agreement.
        </p>

        <h5
          style={{
            marginTop: 20,
            width: '100%',
            fontWeight: 'bold',
          }}
        >
          Practice
        </h5>
        <p style={{ marginTop: 5, width: '100%', fontWeight: 'bold' }}>
          CALIFORNIA CENTER FOR FUNCTIONAL MEDICINE
        </p>
        <p style={{ marginTop: 2, width: '100%' }}>
          A CALIFORNIA PROFESSIONAL CORPORATION
        </p>
      </div>
    </div>
  </div>
)

export default CCFM_TOS
