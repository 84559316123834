import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import Constants from '../../../values'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentEditable from 'react-contenteditable'

export const qualityManagementColumns = ({
  providersList,
  patientList,
  generatePdf,
  generateNotesPdf,
  navigatetoPatientProfile,
  onPatientNavLinkClicked,
  handleQualityReview,
  supervisor,
  openNotesEditModal,
  onInsuranceEditClicked,
  renderEditable,
  handleInputChange,
  getStripeChargeUrl,
  getStripeRefund,
  openStripeRefundModal,
  openStripeChargeModal,
}) => {
  return [
    {
      Header: 'Patient/ Client Name',
      // accessor: 'patientName',
      accessor: (obj) => {
        let patientData = obj
        return patientData?.firstName + patientData?.lastName
      },
      Cell: ({ row }) => {
        let patientData = row?.original
        return (
          <Link
            onClick={() => {
              navigatetoPatientProfile(row?.original?.uid)
              onPatientNavLinkClicked('profile')
            }}
            to={{
              pathname: '/patient/profile',
              state: 'info',
              key: 2,
            }}
            className={Styles.link}
          >
            {patientData?.firstName || ''} {patientData?.lastName || ''}
          </Link>
        )
      },
      width: 300,
      sort: true,
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Date of Service',
      accessor: (obj) =>
        obj?.start
          ? moment(obj?.start).format('ll')
          : moment(obj.start).format('ll'),
      width: 200,
      sort: true,
      // style: {
      //   position: 'sticky',
      //   left: 300,
      //   zIndex: 10,
      //   backgroundColor: '#F3F3F3',
      // },
    },
    {
      Header: 'Provider Name',
      accessor: (obj) => {
        let providerData = obj?.providerData || null
        return `${providerData?.first_name} ${providerData?.last_name}`
      },
      width: 300,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      sort: true,
    },
    // {
    //   Header: 'ICD-10',
    //   accessor: (obj) => {
    //     let icd_10_data = ''
    //     let tempArray = obj?.careplan?.careplan || []
    //     tempArray.forEach((icd, index) => {
    //       icd_10_data = icd_10_data.concat(
    //         icd?.icd_10?.icd10_code ? `${icd?.icd_10?.icd10_code}` : '',
    //         index < tempArray?.length - 1 && icd?.icd_10?.icd10_code
    //           ? `, `
    //           : '',
    //       )
    //     })
    //     return icd_10_data
    //   },
    //   width: 300,
    //   // sort: true,
    // },
    {
      Header: 'Visit Notes',
      accessor: (obj) => (obj?.careplan ? 'Notes' : 'Incomplete Notes'),
      Cell: ({ row }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p
              onClick={() => {
                console.log('view note', row.original)
                let careplanNote = row?.original?.careplan
                // careplanNote.doctor = row.original?.providerData
                row?.original?.careplan &&
                  openNotesEditModal(
                    { ...careplanNote, doctor: row.original?.providerData },
                    row?.original,
                  )
              }}
              className={Styles.link}
              style={{
                textDecoration: 'underline',
                color: !row?.original?.careplan && 'red',
              }}
            >
              {row?.original?.careplan ? 'Notes' : 'No Notes Found'}
            </p>
            {row?.original?.careplan && (
              <FontAwesomeIcon
                onClick={() => {
                  console.log('view note', row.original)
                  row?.original?.careplan &&
                    generateNotesPdf(row?.original?.careplan, row?.original)
                }}
                icon={faFilePdf}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
              />
            )}
          </div>
        )
      },
      width: 200,
    },
    {
      Header: 'Time Spent on Encounter',
      accessor: (obj) => {
        let consult_time =
          moment
            .unix(
              (obj?.careplan?.appointment_end_date || obj?.visit_end_time) /
                1000,
            )
            .diff(
              moment.unix(
                (obj?.careplan?.appointment_date || obj?.visit_start_time) /
                  1000,
              ),
              'minute',
            ) || 0
        return consult_time === 0 ? '-' : consult_time
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Error Type',
      accessor: (obj) => {
        let errorSet = obj?.errorType || []
        return (
          <Select
            options={[
              { label: 'Omission Errors', value: 'Omission Errors' },
              { label: 'Medical Omissions ', value: 'Medical Omissions ' },
              { label: 'Commission Errors', value: 'Commission Errors' },
              {
                label: 'Lack of Medicine Reconciliation ',
                value: 'Lack of Medicine Reconciliation ',
              },
              { label: 'Underdocumenting', value: 'Underdocumenting' },
              { label: 'Time Spent issue', value: 'Time Spent issue' },
              { label: 'Technical problems', value: 'Technical problems' },
              {
                label: 'Professional Appearance ',
                value: 'Professional Appearance ',
              },
              {
                label: 'CCM Compliance Items not addressed',
                value: 'CCM Compliance Items not addressed',
              },
              {
                label: 'Care quality gaps not addressed',
                value: 'Care quality gaps not addressed',
              },
              {
                label: 'Did not follow clinical guidelines',
                value: 'Did not follow clinical guidelines',
              },
              {
                label: 'Remote Monitoring not considered/ordered',
                value: 'Remote Monitoring not considered/ordered',
              },
              {
                label: 'No Quality issues found',
                value: 'No Quality issues found',
              },
            ]}
            // className={Styles.addSelectInput}
            isMulti
            autoBlur={true}
            onChange={(val) => {
              console.log(val)
              handleQualityReview(obj, {
                errorType: val,
              })
            }}
            value={errorSet}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
      width: 300,
    },
    {
      Header: 'Comments',
      accessor: (obj) =>
        obj?.med_director_comments ? obj?.med_director_comments : '',
      Cell: ({ row }) => {
        return (
          <ContentEditable
            html={
              row?.original?.med_director_comments
                ? row?.original?.med_director_comments
                : ''
            }
            onChange={(event) =>
              handleInputChange(row, 'med_director_comments', event)
            }
          />
        )
      },
      width: 200,
    },
    {
      Header: 'Quality Management Reviewed',
      accessor: (obj) => {
        return obj.reviewed || false
      },
      Cell: ({ row }) => {
        let reviewed = row?.original?.reviewed || false
        return (
          <div
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flex: 'auto',
            }}
          >
            <label style={{ color: '#20A892' }} class={Styles.checkContainer}>
              <input
                type="checkbox"
                checked={reviewed}
                disabled={!supervisor}
                onClick={() => {
                  // console.log('reviewed', obj)
                  handleQualityReview(row?.original, {
                    updateReviewed: true,
                    reviewed: !reviewed,
                  })
                }}
              />
              <span class={Styles.checkmark}></span>
            </label>
          </div>
        )
      },
      width: 200,
    },
    {
      Header: 'Send Task',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              // if (!row.original?.quality_task) {
                console.log('selected row:', row.original)
                handleQualityReview(row?.original, {
                  quality_task: true,
                })
              // }
            }}
            className={Styles.link}
            style={
              row.original?.quality_task
                ? { color: 'black' }
                : { textDecoration: 'underline' }
            }
          >
            {row.original?.quality_task ? 'Resend' : 'Send'}
          </p>
        )
      },
      width: 150,
    },
    {
      Header: 'Issue Resolved',
      accessor: (obj) =>
        obj?.issues_resolved ? 'Yes' + obj?.issues_resolved : '-',
      width: 150,
    },
    // {
    //   Header: 'Superbill',
    //   // accessor: 'Superbill',
    //   Cell: ({ row }) => {
    //     return (
    //       <p
    //         onClick={() => {
    //           console.log('view superbill', row.original)
    //           generatePdf(row.original)
    //         }}
    //         className={Styles.link}
    //         style={{ textDecoration: 'underline' }}
    //       >
    //         View
    //       </p>
    //     )
    //   },
    //   width: 150,
    // },
  ]
}
