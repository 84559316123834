// File being used for provider-patient video
import React, { useState, useEffect } from 'react'
import GlobalStyles from '../pages/styles/global.module.scss'
import Styles from './styles/styles.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMicrophoneSlash,
  faPhone,
  faVideoSlash,
  faMicrophone,
  faVideo,
  faEdit,
  faWindowMaximize,
  faWindowMinimize,
  faArrowUpRightfaArrowCircleDownAndArrow,
  faArrowDownLeftAndArrow,
} from '@fortawesome/free-solid-svg-icons'
import {
  FaExpandAlt,
  FaCompressAlt
} from 'react-icons/fa'
import Constants from '../values'
const TWILIO_VIDEO = require('twilio-video')

const VideoModal = (props) => {
  const dispatch = useDispatch()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const openNotesModal = useSelector(
    (state) => state.genericReducer?.openNotesModal,
  )
  const appointmentData = useSelector(
    (state) => state.appointmentReducer?.appointmentData,
  )
  const mongoUser = useSelector((state) => state.userReducer?.mongoUser)

  const [loading, setLoading] = useState(false)
  const [localStreamCreated, setLocalStreamCreated] = useState(false)
  const [audioMuted, setAudioMuted] = useState(false)
  const [videoMuted, setVideoMuted] = useState(false)
  const [videoMax, setVideoMax] = useState(false)
  const [tracks, setTracks] = useState([])
  const [videoCallJoined, setVideoCallJoined] = useState(false)
  const [videoCallRoom, setVideoCallRoom] = useState(null)
  const [remoteTrackAvailable, setRemoteTrackAvailable] = useState(false)

  useEffect(() => {
    createLocalVideoStream()
  }, [])

  const createLocalVideoStream = () => {
    if (!twilioToken) {
      console.log('missing twilio token')
      window.alert('Missing token')
      return
    }

    if (!TWILIO_VIDEO.isSupported) {
      console.log('not supported by browser')
      window.alert('Not supported')
      return
    }

    setLocalStreamCreated(true)
    TWILIO_VIDEO.createLocalTracks({
      audio: true,
      video: { width: 175 },
    })
      .then((tracks) => {
        console.log('created local track inside modal', tracks)
        const localMediaContainer = document.getElementById('local-media')
        tracks.forEach((track) => {
          localMediaContainer.appendChild(track.attach())
        })
        setLocalStreamCreated(true)
        setTracks(tracks)
        joinCall({ videoIsTrue: true })
      })
      .catch((err) => {
        toast.warning('Device not found')
        console.log('video in catch 1 error:', err)
        TWILIO_VIDEO.createLocalTracks({
          audio: true,
          video: false,
        })
          .then((tracks) => {
            console.log('created local track inside modal in catch', tracks)
            const localMediaContainer = document.getElementById('local-media')
            tracks.forEach((track) => {
              localMediaContainer.appendChild(track.attach())
            })
            setLocalStreamCreated(true)
            setTracks(tracks)
            joinCall({ videoIsTrue: false })
          })
          .catch((err) => {
            console.log('video error:', err)
          })
      })
  }

  const endCall = () => {
    if (!videoCallRoom) return

    console.log('disconnecting')
    videoCallRoom.disconnect()
    setVideoCallJoined(false)
    setVideoCallRoom(null)
    tracks && tracks.forEach((track) => track.stop())
    props.endCall()
    return
  }

  const joinCall = ({ videoIsTrue }) => {
    if (!appointmentData || !appointmentData.patient_id) {
      window.alert('Cannot place the video call right now')
      return
    }

    console.log('appointment data', appointmentData)
    let roomName = `${appointmentData.doctor_id}-${appointmentData.patient_id}`
    // let roomName = `${mongoUser.firebase_id}-${appointmentData.patient_id}`
    TWILIO_VIDEO.connect(twilioToken, {
      name: roomName,
      automaticSubscription: true,
      video: videoIsTrue,
      audio: true,
    })
      .then((room) => {
        console.log('Connected to Room "%s"', room.name)
        // window.alert('Connected to room')
        setVideoCallJoined(true)
        setVideoCallRoom(room)

        room.participants.forEach(participantConnected)
        room.on('participantConnected', participantConnected)

        room.on('participantDisconnected', participantDisconnected)
        room.once('disconnected', (error) =>
          room.participants.forEach(participantDisconnected),
        )
      })
      .catch((err) => {
        console.log('error in join call func:', err)
      })

    function participantConnected(participant) {
      console.log('Participant "%s" connected', participant.identity)
      // toast.info('participant connected')

      const div = document.getElementById('remote-media')

      participant.tracks.forEach((publication) => {
        console.log(
          'existing tracks of remote participant',
          JSON.stringify(publication),
        )
        if (publication.isSubscribed) {
          trackPublished(div, publication, participant)
        }

        publication.on('subscribed', (track) => {
          console.log('new subscribed here', track)
          trackPublished(div, publication, participant)
        })

        publication.on('unsubscribed', (track) => {
          console.log('unpublished bevarc')
          if (track.kind !== 'data') {
            track.detach().forEach((element) => element.remove())
            setRemoteTrackAvailable(false)
          }
        })
      })

      participant.on('trackPublished', (publication) => {
        console.log('track published by remote participant')
        trackPublished(div, publication, participant)
      })

      participant.on('trackUnpublished', (publication) => {
        console.log(
          `RemoteParticipant ${participant.identity} unpublished a RemoteTrack: ${publication}`,
        )
      })

      // document.body.appendChild(div);
    }

    function participantDisconnected(participant) {
      console.log('Participant "%s" disconnected', participant.identity)
      // toast.info('participant disconnected')
    }

    function trackPublished(div, publication, participant) {
      console.log(
        `RemoteParticipant ${
          participant.identity
        } published a RemoteTrack: ${JSON.stringify(publication)}`,
      )
      if (publication.isSubscribed && publication.kind !== 'data') {
        console.log('we can attach', publication.kind)
        setRemoteTrackAvailable(true)
        div.appendChild(publication.track.attach())
        if (publication.kind === 'video') {
          console.log('updating video styles')
          //updating styles
          let videoElements = document
            .getElementById('remote-media')
            .getElementsByTagName('video')
          // videoElements.forEach((element) => {
          //   element.style.height = '175px'
          // })
        }
      }
    }

    function appendTrack(div, track) {
      console.log('appending track to div')
      div.appendChild(track.attach())
    }

    function trackUnsubscribed(track) {
      console.log('removing track from div')
      // track.detach().forEach(element => element.remove());
    }
  }

  const onMuteVideoPressed = () => {
    if (!videoCallRoom) return
    if (videoMuted) {
      //if its muted, unmute it
      videoCallRoom.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable()
      })
      tracks &&
        tracks.forEach((track) => {
          if (track.kind === 'video') track.enable()
        })
    } else {
      videoCallRoom.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable()
      })
      tracks &&
        tracks.forEach((track) => {
          if (track.kind === 'video') track.disable()
        })
    }
    setVideoMuted(!videoMuted)
  }

  const onMuteAudioPressed = () => {
    if (!videoCallRoom) return

    if (audioMuted) {
      //if its muted, unmute it
      videoCallRoom.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable()
      })
    } else {
      videoCallRoom.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable()
      })
    }
    setAudioMuted(!audioMuted)
  }

  const renderVideoCallButtons = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          marginRight: 4,
        }}
      >
        <div
          className={
            audioMuted
              ? Styles.audioVideoBtnWrapperActive
              : Styles.audioVideoBtnWrapper
          }
        >
          <FontAwesomeIcon
            onClick={onMuteAudioPressed}
            icon={audioMuted ? faMicrophoneSlash : faMicrophone}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              cursor: 'pointer',
            }}
          />
        </div>

        <div
          className={Styles.audioVideoBtnWrapper}
          style={{
            backgroundColor: videoCallJoined ? 'red' : 'white',
          }}
        >
          <FontAwesomeIcon
            onClick={endCall}
            icon={faPhone}
            style={{
              color: videoCallJoined ? 'white' : Constants.primaryTheme,
              fontSize: 20,
              cursor: 'pointer',
            }}
          />
        </div>

        <div
          className={
            videoMuted
              ? Styles.audioVideoBtnWrapperActive
              : Styles.audioVideoBtnWrapper
          }
        >
          <FontAwesomeIcon
            onClick={onMuteVideoPressed}
            icon={videoMuted ? faVideoSlash : faVideo}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              cursor: 'pointer',
            }}
          />
        </div>

        <div
          className={
            openNotesModal
              ? Styles.audioVideoBtnWrapperActive
              : Styles.audioVideoBtnWrapper
          }
        >
          <FontAwesomeIcon
            onClick={() => props.showNotesModal()}
            icon={faEdit}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={
            videoMax
              ? Styles.audioVideoBtnWrapperActive
              : Styles.audioVideoBtnWrapper
          }
        >
          {
            videoMax 
            ? <FaCompressAlt
                size={20}
                onClick={() => {
                  if (videoMax) {
                    setVideoMax(false)
                  } else if (remoteTrackAvailable) {
                    setVideoMax(true)
                  }
                }}
                color={Constants.primaryTheme}
                style={{cursor: 'pointer'}}
              />
            : <FaExpandAlt
                size={20}
                onClick={() => {
                  if (videoMax) {
                    setVideoMax(false)
                  } else if (remoteTrackAvailable) {
                    setVideoMax(true)
                  }
                }}
                color={Constants.primaryTheme}
                style={{cursor: 'pointer'}}
              />
          }
        </div>
      </div>
    )
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: 100, // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const renderVideoStreams = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: videoMax ? '60vw' : 'auto',
          height: videoMax ? '80vh' : 'auto',
        }}
      >
        <div
          style={{
            display: remoteTrackAvailable ? 'inline' : 'none',
          }}
          id={'remote-media'}
        ></div>
        {/* style={{ height: '175px' }} */}
        <div id={'local-media'} style={{ height: '175px' }}></div>
      </div>
    )
  }

  const renderContent = () => (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {videoCallJoined && renderVideoCallButtons()}
      {renderVideoStreams()}
    </div>
  )

  if (loading) return renderLoader()
  return renderContent()
}

export default VideoModal
