import React from 'react'
import { Link } from 'react-router-dom'

const NotificationToast = ({ title, body, route }) => {
  return (
    <Link
      to={route}
    >
      <div>
        <h4>{title}</h4>
        <p>{body}</p>
      </div>

    </Link>
  )
}

export default NotificationToast;