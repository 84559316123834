import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'

export const referringProvidersColumn = (invitePatientClicked) => {
  return [
    {
      Header: 'Name',
      accessor: (obj) => obj?.firstName + ' ' + obj?.lastName,
      //   Cell: ({ row }) => {
      //     let patientData = row?.original
      //     return (
      //       <Link
      //         onClick={() => {
      //           navigatetoPatientProfile(row?.original?.uid)
      //           onPatientNavLinkClicked('profile')
      //         }}
      //         to={{
      //           pathname: '/patient/profile',
      //           state: 'info',
      //           key: 2,
      //         }}
      //         className={Styles.link}
      //       >
      //         {patientData?.firstName || ''} {patientData?.lastName || ''}
      //       </Link>
      //     )
      //   },
      width: 300,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Organization',
      accessor: 'organization',
      width: 200,
    },
    {
      Header: 'Specialty',
      accessor: (obj) => obj?.specialty?.label,
      width: 200,
    },
    {
      Header: 'Phone',
      accessor: 'phone',
      width: 200,
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 250,
    },
    {
      Header: 'Fax',
      accessor: 'fax',
      width: 200,
    },
    // {
    //   Header: 'City',
    //   accessor: 'city',
    //   width: 200,
    // },
    {
      Header: 'State',
      accessor: (obj) => obj?.state?.label,
      width: 200,
    },
    {
      Header: 'Patients Referred',
      accessor: (obj) => {
        return `${obj.patientInvites.length}`
      },
      width: 200,
      sort: true,
    },
  ]
}
