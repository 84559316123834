import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'

export const invoicesColumns = ({ getStripeChargeUrl, getStripeRefund }) => {
  return [
    {
      Header: 'Name',
      accessor: 'patientName',
      width: 200,
      sort: true,
      accessor: (obj) => {
        return obj?.patientData?.name || 'N/A'
      },
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Invoice ID',
      accessor: 'invoiceId',
      Cell: ({ row }) => {
        let invoice = row?.original?.invoice
        return (
          <div
            onClick={() => {
              console.log('view invoice')
              getStripeChargeUrl(invoice.charge)
            }}
            // to={{
            //   pathname: '/patient/profile',
            //   state: 'info',
            //   key: 2,
            // }}
            className={Styles.link}
          >
            {invoice?.id || ''}
          </div>
        )
      },
      width: 150,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      // obj.firstName && obj.lastName ? obj.firstName + ' ' + obj.lastName : '',
    },

    {
      Header: 'Provider Name',
      accessor: (obj) => {
        return (obj?.providerData?.first_name + ' ' + obj?.providerData?.last_name) || 'N/A'
      },
      width: 200,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Date of Service',
      accessor: (obj) =>
        obj?.appointmentStart
          ? moment(obj?.appointmentStart).format('lll')
          : 'N/A',
      width: 200,
      sort: true,
    },
    {
      Header: 'Date of Invoice',
      accessor: (obj) => {
        let invoice = obj?.invoice
        return moment(invoice?.created * 1000).format('ll')
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Days from Invoice Date',
      accessor: (obj) => obj?.invoice
          ? moment().diff(moment(obj?.invoice?.created * 1000), 'days')
          : 'N/A',
      width: 200,
      sort: true,
    },
    {
      Header: 'Status',
      accessor: (obj) => obj?.invoice?.status,
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="Statustype"
                placeholder="Select"
                options={[
                  { label: 'Paid', value: 'paid' },
                  { label: 'Open', value: 'open' },
                  { label: 'Draft', value: 'draft' },
                  { label: 'Uncollectible', value: 'uncollectible' },
                  { label: 'Void', value: 'void' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Charge Amount',
      accessor: (obj) => {
        let amount = parseFloat(obj?.invoice?.amount_due/100).toFixed(2)
        return (amount ? '$ ' + amount : 'N/A')
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Amount Paid',
      accessor: (obj) => {
        let amount = parseFloat(obj?.invoice?.amount_paid/100).toFixed(2)
        return (amount ? '$ ' + amount : 'N/A')
      },
      width: 200,
      sort: true,
    },
    {
      Header: 'Refund',
      accessor: 'refund',
      Cell: ({ row }) => {
        let invoice = row?.original?.invoice
        return (
          <div
            onClick={() => {
              if (window.confirm('Are you sure you want to refund?')) {
                if(!invoice?.refunded) {
                  if(invoice?.transfer_data?.destination)
                    getStripeRefund(invoice?.charge, true)
                  else
                    getStripeRefund(invoice?.charge, false)
                }
              }
              //  else {
                
              // }
            }}
            // to={{
            //   pathname: '/patient/profile',
            //   state: 'info',
            //   key: 2,
            // }}
            className={invoice?.refunded ? Styles.disabledCursor : Styles.link}
          >
            {invoice?.refunded ? 'Refunded' : 'Refund'}
          </div>
        )
      },
      width: 150,
    },
  ]
}
