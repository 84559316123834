import React, { Component, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import DatePicker from 'react-date-picker'

import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import { addMongoUser, stopTimer } from '../../redux/actions'
import firebase from '../../services/firebase.js'
import PlaceHolderProfileImage from '../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../metadata.js'

import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import Constants from '../../values.js'
import Styles from './styles/Credentialing.module.scss'
import Config from '../../config'
import FileViewer from '../../components/pdf/FileViewer'

const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const customModalStyles2 = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth - 200,
    height: windowHeight - 20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const EducationCertification = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)

  const mongoUser = useSelector((state) => state.userReducer?.providerData)
  const userType = useSelector((state) => state.userReducer?.userType)
  const adminId = useSelector((state) => state.userReducer?.adminId)
  const firebaseAuthUser = useSelector(
    (state) => state.userReducer?.firebaseAuthUser,
  )

  const [loading, setLoading] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [docFile, setDocFile] = useState('')
  const [docType, setDocType] = useState('')

  const [education, setEducation] = useState([])
  const [boards, setBoards] = useState([])
  //LICENSE DATA
  const [licensedStates, setLicensedStates] = useState(null)

  const [uploadingIndex, setUploadingIndex] = useState('')
  const [boardIndex, setBoardIndex] = useState(0)

  const [npi, setNpi] = useState(null)
  const [caqh, setCaqh] = useState('')
  const [accessData, setAccessData] = useState(false)
  const [dea, setDea] = useState('')
  const [cds, setCds] = useState('')
  const [medicareId, setMedicareId] = useState('')
  const [medicaidId, setMedicaidId] = useState('')
  const [attest, setAttest] = useState(false)

  useEffect(() => {
    setLoading(true)
    getProviderData()
    // setLoading(false)
  }, [])

  const getProviderData = () => {
    setLoading(true)
    // console.log('mongo user::', adminId, userType, mongoUser)
    // console.log('firebaseuser::', firebaseAuthUser)

    const url = `${Config.BACKEND_URL}providers`
    return axios({
      method: 'get',
      url,
      headers: { x_firebase_id: mongoUser.firebase_id, Authorization: `JWT ${token}` },
    })
      .then(({ data }) => {
        console.log('got doctor mongo details', data)

        setEducation(data?.education_certifications || null)
        setBoards(
          data?.education_certifications?.boards
            ? data?.education_certifications?.boards
            : [
                {
                  board_certificate:
                    data?.education_certifications?.board_certificate,
                  speciality: data?.education_certifications?.speciality,
                  expiration_date:
                    data?.education_certifications?.expiration_date || new Date(),
                  notes: data?.education_certifications?.notes,
                },
              ],
        )
        setLoading(false)
        // dispatch(addMongoUser(data))
      })
      .catch((err) => {
        console.log('error when getting doctor mongo user Education', err)
      })
  }

  const onSavePressed = () => {
    let returnData = {
      education_certifications: { ...education, boards: boards },
    }
    console.log(returnData)

    // let returnData = {
    //   npi: npi,
    //   //   caqh: caqh,
    //   //   accessData: accessData,
    //   dea: dea,
    //   //   cds: cds,
    //   //   medicaidId: medicaidId,
    //   //   medicareId: medicareId,
    //   //   attest: attest,
    // }
    props.parentCallback(returnData)
  }

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    if (event?.target?.files[0]) {
      console.log(event?.target?.files[0])

      // if (event?.target?.files[0].type !== 'application/pdf') {
      //   toast.warning('Please upload PDF files')
      //   return
      // }

      setLoading(true)

      //SAVE IN FIRESTORE STORAGE
      const file = event?.target?.files[0]
      let storageUrl = `providers/${mongoUser.firebase_id}/${
        uploadingIndex + '_' + file.name
      }`
      if (uploadingIndex === 'board')
        storageUrl = `providers/${mongoUser.firebase_id}/${
          uploadingIndex + boardIndex + '_' + file.name
        }`

      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef.child(storageUrl).put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          setLoading(false)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log(downloadURL)

            //SAVE EDUCATION
            let temp = { ...education }

            switch (uploadingIndex) {
              case 'degree':
                temp.degree_certificate = downloadURL
                temp.degree_certificate_name = file.name
                break

              case 'internship':
                temp.internship_certificate = downloadURL
                temp.internship_certificate_name = file.name
                break
              case 'residency':
                temp.residency_certificate = downloadURL
                temp.residency_certificate_name = file.name
                break
              case 'fellowship':
                temp.fellowship_certificate = downloadURL
                temp.fellowship_certificate_name = file.name
                break
              case 'board':
                let tempQ = [...boards]
                tempQ[boardIndex].board_certificate = downloadURL
                tempQ[boardIndex].board_certificate_name = file.name
                setBoards(tempQ)
                break
              default:
                console.log('no option')
            }
            setEducation(temp)
            setLoading(false)
          })
        },
      )
    }
  }

  const renderBoards = () => {
    return (
      boards &&
      boards?.map((item, index) => {
        return (
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Board Certification</p>
            <div
              style={{
                width: '40%',
                margin: '0% 0% 0% 5%',
              }}
            >
              <Select
                placeholder="Specialty"
                inputId="speciality"
                options={metadata.SPECIALITY_OPTIONS}
                value={
                  item?.speciality
                    ? {
                        label: item?.speciality,
                        value: item?.speciality,
                      }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  let temp = [...boards]
                  temp[index].speciality = val.value
                  setBoards(temp)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>

            <input
              placeholder="Enter Notes"
              className={Styles.textInput}
              style={{ width: '40%', margin: '0% 0% 0% 5%' }}
              type="text"
              value={item?.notes}
              onChange={(e) => {
                let temp = [...boards]
                temp[index].notes = e.target.value
                setBoards(temp)
              }}
            />
            <div
              style={{
                width: '40%',
                margin: '0% 0% 0% 5%',
              }}
            >
              <DatePicker
                // minDate={new Date('09/01/2019')}
                // format={'MMM-dd-y'}
                onChange={(date) => {
                  let tempQ = [...boards]
                  tempQ[index].expiration_date = date
                  setBoards(tempQ)
                  // console.log(date)
                }}
                value={item?.expiration_date ? new Date(item?.expiration_date) : new Date()}
                style={{ width: '40%', margin: '0% 0% 0% 5%' }}
              />
            </div>

            {item?.board_certificate && (
              <FontAwesomeIcon
                onClick={() => {
                  setDocFile(item?.board_certificate)
                  setDocType(
                    item?.board_certificate_name
                      ? item?.board_certificate_name.split('.').pop()
                      : 'pdf',
                  )
                  setOpenDoc(true)
                }}
                icon={faEye}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 20,
                  width: '10%',
                  cursor: 'pointer',
                }}
              />
            )}
            <label
              htmlFor={`board${index}`}
              style={{
                textDecoration: 'underline',
                color: Constants.primaryTheme,
                cursor: 'pointer',
                margin: '0% 0% 0% 5%',
              }}
            >
              Upload Certificate
            </label>
            <input
              id={`board${index}`}
              // accept="application/pdf"
              style={{ display: 'none' }}
              type="file"
              onClick={() => {
                console.log(index)
                setBoardIndex(index)
                setUploadingIndex('board')
              }}
              onChange={onFileChange}
            />
          </div>
        )
      })
    )
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: '60vh', // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const renderEducationCerts = () => {
    // console.log(education)
    return (
      //   education &&
      //   education?.map((education, index) => {
      //     return (
      <div>
        {/* Degree */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Medical Degree</p>

          <div style={{ width: '90%' }}>
            <Select
              inputId="degree"
              options={metadata.degreeOptions}
              value={
                education?.degree
                  ? { label: education?.degree, value: education?.degree }
                  : null
              }
              isSearchable
              autoBlur
              onChange={(val) => {
                let temp = { ...education }
                temp.degree = val.value
                setEducation(temp)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
          {education?.degree_certificate && (
            <FontAwesomeIcon
              onClick={() => {
                setDocFile(education?.degree_certificate)
                setDocType(
                  education?.degree_certificate_name
                    ? education?.degree_certificate_name.split('.').pop()
                    : 'pdf',
                )
                setOpenDoc(true)
              }}
              icon={faEye}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                width: '10%',
                cursor: 'pointer',
              }}
            />
          )}
          <label
            htmlFor="upload_degree"
            style={{
              textDecoration: 'underline',
              color: Constants.primaryTheme,
              cursor: 'pointer',
              margin: '0% 0% 0% 5%',
            }}
          >
            Upload Degree Certificate
          </label>
          <input
            id="upload_degree"
            // accept="application/pdf"
            style={{ display: 'none' }}
            type="file"
            onClick={() => {
              setUploadingIndex('degree')
            }}
            onChange={onFileChange}
          />
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Internship</p>
          <input
            placeholder="Enter Internship"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={education?.internship}
            onChange={(e) => {
              let temp = { ...education }
              temp.internship = e.target.value
              setEducation(temp)
            }}
          />
          {education?.internship_certificate && (
            <FontAwesomeIcon
              onClick={() => {
                setDocFile(education?.internship_certificate)
                setDocType(
                  education?.internship_certificate_name
                    ? education?.internship_certificate_name.split('.').pop()
                    : 'pdf',
                )
                setOpenDoc(true)
              }}
              icon={faEye}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                width: '10%',
                cursor: 'pointer',
              }}
            />
          )}
          <label
            htmlFor="internship"
            style={{
              textDecoration: 'underline',
              color: Constants.primaryTheme,
              cursor: 'pointer',
              margin: '0% 0% 0% 5%',
            }}
          >
            Upload Certificates of Completion
          </label>
          <input
            id="internship"
            // accept="application/pdf"
            style={{ display: 'none' }}
            type="file"
            onClick={() => {
              setUploadingIndex('internship')
            }}
            onChange={onFileChange}
          />
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Residency</p>
          <input
            placeholder="Enter Residency"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={education?.residency}
            onChange={(e) => {
              let temp = { ...education }
              temp.residency = e.target.value
              setEducation(temp)
            }}
          />
          {education?.residency_certificate && (
            <FontAwesomeIcon
              onClick={() => {
                setDocFile(education?.residency_certificate)
                setDocType(
                  education?.residency_certificate_name
                    ? education?.residency_certificate_name.split('.').pop()
                    : 'pdf',
                )
                setOpenDoc(true)
              }}
              icon={faEye}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                width: '10%',
                cursor: 'pointer',
              }}
            />
          )}
          <label
            htmlFor="residency"
            style={{
              textDecoration: 'underline',
              color: Constants.primaryTheme,
              cursor: 'pointer',
              margin: '0% 0% 0% 5%',
            }}
          >
            Upload Certificates of Completion
          </label>
          <input
            id="residency"
            // accept="application/pdf"
            style={{ display: 'none' }}
            type="file"
            onClick={() => {
              setUploadingIndex('residency')
            }}
            onChange={onFileChange}
          />
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Fellowship</p>
          <input
            placeholder="Enter Fellowship"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={education?.fellowship}
            onChange={(e) => {
              let temp = { ...education }
              temp.fellowship = e.target.value
              setEducation(temp)
            }}
          />
          {education?.fellowship_certificate && (
            <FontAwesomeIcon
              onClick={() => {
                setDocFile(education?.fellowship_certificate)
                setDocType(
                  education?.fellowship_certificate_name
                    ? education?.fellowship_certificate_name.split('.').pop()
                    : 'pdf',
                )
                setOpenDoc(true)
              }}
              icon={faEye}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                width: '10%',
                cursor: 'pointer',
              }}
            />
          )}
          <label
            htmlFor="fellowship"
            style={{
              textDecoration: 'underline',
              color: Constants.primaryTheme,
              cursor: 'pointer',
              margin: '0% 0% 0% 5%',
            }}
          >
            Upload Certificates of Completion
          </label>
          <input
            id="fellowship"
            // accept="application/pdf"
            style={{ display: 'none' }}
            type="file"
            onClick={() => {
              setUploadingIndex('fellowship')
            }}
            onChange={onFileChange}
          />
        </div>

        {renderBoards()}

        <div
          onClick={() => {
            let temp = [...boards]
            temp.push({
              board_certificate: '',
              board_certificate_type: '',
              speciality: null,
              expiration_date: new Date(),
              notes: '',
            })
            setBoards(temp)
          }}
          style={{
            cursor: 'pointer',
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginRight: 5,
            }}
          />
          <p style={{ color: Constants.primaryTheme }}>Add another</p>
        </div>

        {/* <div className={Styles.row}>
              <p className={Styles.rowLabel}>Notes</p>
              <input
                placeholder="Enter Notes"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={education?.notes}
                onChange={(e) => {
                  let temp = {...education}
                  temp.notes = e.target.value
                  setEducation(temp)
                }}
              />
            </div> */}

        <div className={Styles.row}>
          <p style={{ borderBottom: '1px solid #d0d0d0', width: '80%' }}></p>
        </div>
      </div>
    )
    //   })
    // )
  }

  const renderContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        {renderEducationCerts()}

        {/* <div
          onClick={() => {
            let temp = {...education}
            temp.push({
              degree: '',
              degree_certificate: '',
              internship: '',
              residency: '',
              residency_certificate: '',
              fellowship: '',
              fellowship_certificate: '',
              certification: '',
              board_certificate: '',
              internship_certificate: '',
            })
            setEducation(temp)
          }}
          style={{
            cursor: 'pointer',
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginRight: 5,
            }}
          />
          <p style={{ color: Constants.primaryTheme }}>Add Certifications</p>
        </div> */}

        <div
          style={{
            width: '100%',
            marginTop: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            onClick={onSavePressed}
            className={GlobalStyles.button}
            disabled={loading}
            style={{ width: 200 }}
            variant="primary"
          >
            {loading ? 'Loading....' : 'Save'}
          </Button>
          {/* {error && (
            <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
              {errorString}
            </p>
          )} */}
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={openDoc}
          onRequestClose={() => {
            setOpenDoc(false)
            setDocFile('')
          }}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          <FileViewer fileType={docType} fileName={docFile} />
          {/* <iframe
            style={{ height: '100%', width: '100%' }}
            src={`data:application/pdf;base64,${docFile}`}
          /> */}
        </Modal>
      </div>
    )
  }

  if (loading) return renderLoader()
  return renderContent()
}

export default EducationCertification
