import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Config from '../../config.js'
import {
  addProviderData,
  fetchSpecificDoctorMongoUser,
  stopTimer,
} from '../../redux/actions'
import values from '../../values'

import moment from 'moment'
// import DatePicker from '../../components/DatePicker'
import DatePicker from 'react-datepicker'
import DatePicker1 from 'react-multi-date-picker'
import Header from '../../components/Header'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Account.module.scss'
import Styles1 from './styles/Appointments.module.scss'

const axios = require('axios')

const localizer = momentLocalizer(moment)

class ProviderAvailability extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showConnectCalendar: true,
      speciality: null,
      degree: null,
      gender: null,
      languages: null,
      insurances: null,
      userDetails: null,
      loading: true,

      phoneNumber: '',
      dob: new Date(),
      birth_place: null,

      signLocation: '',
      signImage: null,
      signUploaded: false,
      signChanged: false,
      trimmedDataURL: null,

      profileChanged: false,
      imageUploaded: false,
      imageLocation: '',
      image: null,
      error: false,
      errorString: '',
      saving: false,
      locations: [null],
      modalIsOpen: false,
      modalLoading: false,
      modalError: false,
      modalErrorString: '',
      modalNumber: '',
      organization: '',
      showIframe: false,
      region: '',
      states: null,
      licenseNumbers: null,
      statesAbbrs: null,
      verifiedFlags: null,
      selectedProvider: null,
      providerOptionsData: [],
      allProviderData: [],
      // Availability
      showAvailabilityEditTypeModal: false,
      selectedSlotInfo: {},
      editAvailabilityType: '',
      availableExceptions: [],
      selectedAvailabilityEditDates: [],
      availableSlots: {
        Mon: [],
        Tue: [],
        Wed: [],
        Thu: [],
        Fri: [],
        Sat: [],
        Sun: [],
      },
      selectedAvailabilityDayOfWeek: null,
      events: [],
      editedSpecificDates: [],
    }
    this.sigPad = {}
    this.signRef = React.createRef()
    this.imageUploadRef = React.createRef()
    this.frontImageRef = React.createRef()
    this.getDayOfWeek = this.getDayOfWeek.bind(this)
    this.handleSlotAvailability = this.handleSlotAvailability.bind(this)
    this.getAppointmentAvailabilitySchedule =
      this.getAppointmentAvailabilitySchedule.bind(this)
    this.handleSlotDeletion = this.handleSlotDeletion.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.saveSpecificDatesToBackend = this.saveSpecificDatesToBackend.bind(this)
    this.saveSpecificDaysToBackend = this.saveSpecificDaysToBackend.bind(this)
    this.handleDateOverRideChange = this.handleDateOverRideChange.bind(this)
    this.handleOverrideSlotAvailability =
      this.handleOverrideSlotAvailability.bind(this)
    this.handleOverrideTimeSlotDeletion =
      this.handleOverrideTimeSlotDeletion.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.token && props.mongoUser) {
      let user = props.mongoUser
      const userLanguages = user.languages || []
      let mappedLang = userLanguages.map((x) => ({ label: x, value: x }))
      if (!userLanguages[0]?.label) user.languages = mappedLang
      const userStates = user.licensed_states || []
      const states = []
      const licenseNumbers = []
      const statesAbbrs = []
      const verifiedFlags = []
      if (userStates.length > 0) {
        userStates.forEach((x) => {
          states.push(x.state)
          licenseNumbers.push(x.number)
          statesAbbrs.push(x.abbr)
          verifiedFlags.push(x.verified)
        })
      }
      const speciality = user.speciality
        ? { label: user.speciality, value: user.speciality }
        : null
      // let country = user.country ? {label: user.country, value: user.country} : null
      const region = user.region
        ? { label: user.region, value: user.region }
        : null

      const degree = user.degree
        ? { label: user.degree, value: user.degree }
        : null

      const gender = user.gender
        ? { label: user.gender, value: user.gender }
        : null
      let insurances
      if (user.insurances) {
        insurances = user.insurances.map((x) => ({ label: x, value: x }))
      } else {
        insurances = []
      }
      const phoneNumber = user.phoneNumber || ''
      const dob = user.dob || new Date()
      const birth_place = user.birth_place || null

      return {
        loading: state.saving,
        userDetails: user,
        selectedProvider: user?.firebase_id,
        // languages: languages,
        speciality: speciality,
        phoneNumber: phoneNumber,
        dob: dob,
        region: region,
        degree: degree,
        insurances: insurances,
        gender: gender,
        locations: state.locations,
        birth_place: birth_place,
        organization: user.organization,
        states: states,
        licenseNumbers: licenseNumbers,
        statesAbbrs: statesAbbrs,
        verifiedFlags: verifiedFlags,
        showConnectCalendar: !(props.mongoUser && props.mongoUser.nylas),
      }
    }

    return null
  }

  componentDidMount() {
    // TODO: Add this code back in after testing
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    const { token, adminId, mongoUser } = this.props
    // this.getAllProviderData()
    // if specific provider is not chosen yet
    // this.props.fetchSpecificDoctorMongoUser(
    //   mongoUser?.firebase_id || adminId,
    //   token,
    // )
    this.getAppointmentAvailabilitySchedule()
    this.props.stopTimer(adminId, token)
  }

  getAppointmentAvailabilitySchedule() {
    const { token, loggedInUser } = this.props
    // console.log(token, loggedInUser.firebase_id)
    axios({
      method: 'get',
      url: `${Config.BACKEND_URL}provider/availability-schedule`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        provider_id: loggedInUser.firebase_id,
      },
    })
      .then((response) => {
        // BUG: Not rendering avail overrides for dates that have no avail
        // Eg: sun and sat provider is no avail, but adds override for one day.
        // console.log('response ', response)
        if (response?.data?.success) {
          let availableSlots = response.data.data.availability
          let availableExceptions = response.data.data.availability_override

          this.setState({ availableSlots })
          this.setState({ availableExceptions })

          let temp = Object.keys(availableExceptions)
            .map(Number)
            .sort(function (a, b) {
              return b - a
            }) // sorting in deceasing order

          let events = []
          let overrideAppliedOnDate = false

          const startDate = moment()
          const endDate = moment().add(3, 'months')

          let currentDate = startDate.clone()
          let startDateOO = moment().set({
            hour: 0,
            minute: 0,
            second: 0,
            milliseconds: 0,
          })
          let overrideDays = []
          temp.every((key) => {
            // console.log('EE->', startDate, moment(key))
            if (moment(key).isSameOrAfter(startDateOO)) {
              overrideDays.push(availableExceptions[key])
              return true
            }
            return false
          })
          overrideDays = overrideDays.reverse()
          console.log('overrideDays ', overrideDays)
          while (currentDate.isBefore(endDate)) {
            // console.log('currentDate ', currentDate)
            const dayOfWeek = currentDate.format('ddd')
            // console.log('dayOfWeek ', dayOfWeek)

            // Check if there are available time slots for the current day
            if (availableSlots.hasOwnProperty(dayOfWeek)) {
              availableSlots[dayOfWeek].forEach((slot) => {
                for (let overrideDay of overrideDays) {
                  if (currentDate.isSame(overrideDay[0].start, 'day')) {
                    console.log('overrideDay ', overrideDay)
                    if (!overrideAppliedOnDate) {
                      for (let overrideSlot of overrideDay) {
                        const inMomentTimeStart = moment(overrideSlot.start)
                        const inMomentTimeEnd = moment(overrideSlot.end)
                        const start = currentDate.clone().set({
                          hour: inMomentTimeStart.hour(),
                          minute: inMomentTimeStart.minute(),
                          second: inMomentTimeStart.second(),
                          milliseconds: inMomentTimeStart.milliseconds(),
                        })
                        const end = currentDate.clone().set({
                          milliseconds: inMomentTimeEnd.milliseconds(),
                          second: inMomentTimeEnd.second(),
                          minute: inMomentTimeEnd.minute(),
                          hour: inMomentTimeEnd.hour(),
                        })

                        const event = {
                          start: start.toDate(),
                          end: end.toDate(),
                          title: `${start.format('HH:mm')} - ${end.format(
                            'HH:mm',
                          )} Overridden`, // You can customize the title
                          ifOverrideSlot: true,
                        }

                        events.push(event)
                      }
                    }
                    overrideAppliedOnDate = true
                    return
                  }
                }
                const inMomentTimeStart = moment(slot.start)
                const inMomentTimeEnd = moment(slot.end)
                const start = currentDate.clone().set({
                  hour: inMomentTimeStart.hour(),
                  minute: inMomentTimeStart.minute(),
                  second: inMomentTimeStart.second(),
                  milliseconds: inMomentTimeStart.milliseconds(),
                })
                const end = currentDate.clone().set({
                  milliseconds: inMomentTimeEnd.milliseconds(),
                  second: inMomentTimeEnd.second(),
                  minute: inMomentTimeEnd.minute(),
                  hour: inMomentTimeEnd.hour(),
                })

                const event = {
                  start: start.toDate(),
                  end: end.toDate(),
                  title: `${start.format('HH:mm')} - ${end.format('HH:mm')}`, // You can customize the title
                  ifOverrideSlot: false,
                }

                events.push(event)
              })
            }
            currentDate.add(1, 'day')
            overrideAppliedOnDate = false
          }
          // console.log('events ', events)
          this.setState({ events })
        }
      })
      .catch((err) => {
        console.log('err ', err)
      })
  }

  onModalClose() {
    this.setState({
      modalNumber: null,
      modalError: false,
      modalLoading: false,
      modalIsOpen: false,
    })
  }

  getDayOfWeek(date) {
    var dayOfWeek = new Date(date).getDay()
    return isNaN(dayOfWeek)
      ? this.state.showAvailabilityEditTypeModal
        ? toast.error('Something went wrong! Contact support')
        : null
      : [
          'Sunday', // Sun
          'Monday', // Mon
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ][dayOfWeek]
  }

  renderVisitDurationContent() {
    let { selectedSlotInfo, selectedAvailabilityDayOfWeek } = this.state
    // let self = this
    // console.log('selectedSlotInfo ', selectedSlotInfo)
    let allEditDates = selectedSlotInfo.slots
    let slotStartDate = moment(selectedSlotInfo.start)
    // console.log('allEditDates ', allEditDates)
    // console.log('slotStartDate ', slotStartDate)

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingTop: 20,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2147483647,
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4
          style={{
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          My Availability Schedule
        </h4>

        {/* <h5 style={{ color: values.darkGray, textAlign: 'center' }}>
          {moment(selectedDate, 'YYYY-MM-DD').format('dddd')},{' '}
          {moment(selectedDate, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
        </h5> */}
        <h6 style={{ color: values.darkGray, textAlign: 'center' }}>
          You will be editing time in{' '}
          {new Date()
            .toLocaleDateString(undefined, {
              day: '2-digit',
              timeZoneName: 'long',
            })
            .substring(4)}{' '}
          timezone. If this is incorrect contact support.
        </h6>
        <h4
          style={{
            color: values.primaryTheme,
            textAlign: 'center',
            borderBottom: '1px solid #DEDEDE',
            width: '100%',
          }}
        >
          Select what time slots you want to edit
        </h4>

        <div
          style={{
            width: '50%',
            margin: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              console.log('Edit date')
              // this.setState({ selectedDuration: duration.value })
              this.setState({ editAvailabilityType: 'specificDates' })
            }}
            className={Styles.button2}
            style={{
              margin: '1vh',
              width: 200,
              color: '#20A892',
              fontWeight: 'bold',
            }}
            variant="primary"
          >
            Edit date(s)
          </Button>
        </div>

        <div
          style={{
            width: '50%',
            margin: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              console.log('Edit all DAW_OF_WEEK')
              this.setState({ editAvailabilityType: 'specificDaysOfWeek' })
              // this.setState({ selectedDuration: duration.value })
            }}
            className={Styles.button2}
            style={{
              margin: '1vh',
              width: 200,
              color: '#20A892',
              fontWeight: 'bold',
            }}
            variant="primary"
          >
            Edit all {selectedAvailabilityDayOfWeek}'s
          </Button>
        </div>
      </div>
    )
  }

  onModalCloseRequested() {
    this.setState({
      showAvailabilityEditTypeModal: false,
    })
  }

  renderEditSpecificDatesContent() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles1.closeModalBtn}
          onClick={() => this.onModalCloseRequested()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit specific dates
        </h4>

        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder="Select patient type"
            inputId="name"
            options={[
              { label: 'New Patient', value: 'New Patient' },
              { label: 'Established Patient', value: 'Established Patient' },
            ]}
            value={null}
            isSearchable
            autoBlur
            onChange={(val) => {
              this.setState({
                patientType: val,
                selectedScheduleDoctor: null,
              })
              // this.getPatientCareTeam(val.value)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
        <div style={{ width: '50%', marginTop: 10 }}>
          <Select
            placeholder="Select patient"
            inputId="name"
            options={[]}
            value={null}
            isSearchable
            autoBlur
            onChange={(val) => {
              // console.log(val)
              this.setState({
                selectedSchedulePatient: val,
                selectedScheduleDoctor: null,
                scheduleModalLoading: true,
              })
              this.getPatientCareTeam(val.value)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        {this.state.selectedSchedulePatient &&
          this.state.patientCareTeam &&
          this.state.patientCareTeam.length > 0 && (
            <div style={{ width: '50%', marginTop: 10 }}>
              <Select
                placeholder={'Select care team speciality'}
                inputId="speciality"
                options={this.state.careTeamSpeciality}
                value={this.state.searchSpeciality}
                isSearchable
                autoBlur
                onChange={(val) => {
                  // console.log(val)
                  this.setState({
                    searchSpeciality: val,
                  })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          )}

        {this.state.selectedSchedulePatient &&
          this.state.patientCareTeam &&
          this.state.patientCareTeam.length > 0 && (
            <div style={{ width: '50%', marginTop: 10, marginBottom: 20 }}>
              <Select
                placeholder={'Select care team member'}
                inputId="doctor_name"
                options={[]}
                value={null}
                isSearchable
                autoBlur
                onChange={(val) => {
                  // console.log(val)
                  this.setState({
                    selectedScheduleDoctor: val,
                    axiosConfig: {},
                    scheduleModalLoading: true,
                  })

                  this.getAppointmentUrl(
                    val.doctor.firebase_id,
                    this.state.selectedSchedulePatient.value,
                  )
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          )}
        {/* {this.state.selectedScheduleDoctor &&
          this.state.selectedSchedulePatient &&
          this.state.axiosConfig && (
            <div
              style={{ marginTop: 10, marginBottom: 20, paddingBottom: '5%' }}
            >
              <MyCalendar
                key={this.state.selectedScheduleDoctor.doctor}
                parentCallback={this.handleCalendarCallback}
                doctorDetails={this.state.selectedScheduleDoctor.doctor}
                axiosConfig={this.state.axiosConfig}
                patientId={this.state.selectedSchedulePatient.value}
                token={this.props.token}
                fromProvider={true}
              />
            </div>
          )} */}
      </div>
    )
  }

  renderSlots({ slot, sday, handleChange, handleDelete }) {
    let returnSlots = []

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
      <Button
        onClick={onClick}
        ref={ref}
        className={Styles1.dateInputButton}
        variant="primary"
      >
        {value}
      </Button>
    ))

    if (Array.isArray(slot)) {
      if (slot.length === 0) {
        returnSlots.push(
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              color: 'gray',
            }}
          >
            Click to add a time slot {'->'}
          </div>,
        )
      } else {
        slot.map((s, index) => {
          returnSlots.push(
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <DatePicker
                wrapperClassName={Styles1.datePicker}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                selected={s.start}
                timeIntervals={15}
                onChange={(time) => {
                  handleChange(sday, index, time.valueOf(), 'start')
                  // console.log(time)
                }}
                customInput={<CustomDateInput />}
                // locale="sv-sv"
              />
              <DatePicker
                wrapperClassName={Styles1.datePicker}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                selected={s.end}
                timeIntervals={15}
                onChange={(time) => {
                  handleChange(sday, index, time.valueOf(), 'end')
                  // console.log(time)
                }}
                // locale="sv-sv"
                customInput={<CustomDateInput />}
              />

              <FontAwesomeIcon
                icon={faTrash}
                className={Styles1.deleteIcon}
                onClick={() => handleDelete(sday, index)}
              />
            </div>,
          )
        })
      }
    }

    return returnSlots
  }

  handleSlotAvailability(sday, index, stime, endPoint) {
    console.log('EDIT ', sday, index, stime, endPoint)
    let temp = this.state.availableSlots
    if (endPoint === 'start') temp[sday][index].start = stime
    else if (endPoint === 'end') temp[sday][index].end = stime
    this.setState({ availableSlots: temp })
  }

  handleSlotDeletion(sday, index) {
    console.log('DELETE ', sday, index)
    let slots = this.state.availableSlots
    slots[sday].splice(index, 1)
    this.setState({ availableSlots: slots })
  }

  renderSlots1({ slot, sday, handleChange, handleDelete }) {
    let returnSlots = []

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
      <Button
        onClick={onClick}
        ref={ref}
        className={Styles1.dateInputButton}
        variant="primary"
      >
        {value}
      </Button>
    ))

    if (Array.isArray(slot)) {
      if (slot.length === 0) {
        returnSlots.push(
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              color: 'gray',
            }}
          >
            Click to add a time slot {'->'}
          </div>,
        )
      } else {
        slot.map((s, index) => {
          returnSlots.push(
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <DatePicker
                wrapperClassName={Styles1.datePicker}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                selected={s.start}
                timeIntervals={15}
                onChange={(time) => {
                  console.log('start', time.valueOf())
                  handleChange(sday, index, time.valueOf(), 'start')
                  // console.log(time)
                }}
                customInput={<CustomDateInput />}
                // locale="sv-sv"
              />
              <DatePicker
                wrapperClassName={Styles1.datePicker}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                selected={s.end}
                timeIntervals={15}
                onChange={(time) => {
                  console.log('end', time.valueOf())
                  handleChange(sday, index, time.valueOf(), 'end')
                  // console.log(time)
                }}
                // locale="sv-sv"
                customInput={<CustomDateInput />}
              />

              <FontAwesomeIcon
                icon={faTrash}
                className={Styles1.deleteIcon}
                onClick={() => handleDelete(sday, index)}
              />
            </div>,
          )
        })
      }
    }

    return returnSlots
  }

  // This is for specific dates
  handleDateChange = (dates) => {
    this.setState({ selectedAvailabilityEditDates: dates })
  }

  saveSpecificDatesToBackend() {
    console.log(1)
    // BUG: Need to check if dates and time slots are populated
    let { selectedAvailabilityEditDates, editedSpecificDates } = this.state
    let payload = []
    // selectedAvailabilityEditDates.forEach((date) => {
    //   console.log(moment(date, 'YYYY/MM/DD'))
    //   console.log(date.valueOf())
    // })
    selectedAvailabilityEditDates.forEach((date) => {
      console.log('-X->', date.valueOf())
      let dateAt0 = moment(date.valueOf()).startOf('day')
      // dateAt0.set({
      //   hour: 0,
      //   minute: 0,
      //   second: 0,
      //   milliseconds: 0,
      // })
      console.log('dateAt0', dateAt0)
      let slots = []
      editedSpecificDates.forEach((slot) => {
        let dateStart = moment(slot.start.valueOf())
        console.log('dateStart', dateStart)
        // slot.start = moment({
        let start = moment({
          year: dateAt0.year(),
          month: dateAt0.month(),
          date: dateAt0.date(),
          hour: dateStart.hour(),
          minute: dateStart.minute(),
          second: dateStart.second(),
          milliseconds: dateStart.milliseconds(),
        }).valueOf()
        let dateEnd = moment(slot.end.valueOf())
        console.log('dateEnd', dateEnd)
        // slot.end = moment({
        let end = moment({
          year: dateAt0.year(),
          month: dateAt0.month(),
          date: dateAt0.date(),
          hour: dateEnd.hour(),
          minute: dateEnd.minute(),
          second: dateEnd.second(),
          milliseconds: dateEnd.milliseconds(),
        }).valueOf()
        let finalData = { start: start, end: end }
        console.log('slot', finalData)
        slots.push(finalData)
      })
      console.log('slots', slots)
      let eachDatesEditedTimeSlots = {
        [dateAt0.valueOf()]: [...slots],
      }
      payload.push(eachDatesEditedTimeSlots)
    })
    console.log('payload', Array.isArray(payload), typeof payload, payload[0])
    // selectedAvailabilityEditDates.forEach((date) => {
    //   console.log('date ', date.format("YYYY-MM-DD'T'HH:mm:ss.SSSZ"))
    // })
    axios({
      method: 'patch',
      url: `${Config.BACKEND_URL}provider/availability-schedule`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
      },
      data: {
        provider_id: this.props.loggedInUser.firebase_id,
        availability_override: payload,
      },
    }).then((response) => {
      console.log('response ', response)
      if (response?.data?.success) {
        this.setState({ showAvailabilityEditTypeModal: false })
        this.getAppointmentAvailabilitySchedule() // Reload the data
        toast.success('Successfully updated')
      } else {
        toast.error('Something went wrong!')
      }
    })
  }

  handleOverrideSlotAvailability(sday, index, stime, endPoint) {
    // let temp = this.state.slot_override
    let { availableExceptions } = this.state
    let temp = this.state.editedSpecificDates
    console.log("I'm here 1", index, stime, endPoint)
    if (endPoint === 'start') temp[index].start = stime
    else if (endPoint === 'end') temp[index].end = stime
    this.setState({ editedSpecificDates: temp })
    // availableExceptions.push(temp)
    // this.setState({ availableExceptions })
  }

  handleOverrideTimeSlotDeletion(sday, index) {
    // let slots = this.state.slot_override
    let slots = this.state.editedSpecificDates
    console.log("I'm here 2", sday, index)
    slots.splice(index, 1)
    this.setState({ editedSpecificDates: slots })
    // this.setState({ availableExceptions: slots })
  }

  handleDateOverRideChange(date) {
    // Here date is the date chosen at 00 am local time.
    console.log('handleDateOverRideChange', date)
    // let date = moment(date)
    let newSlot = {
      // ...this.state.slot_override,
      ...this.state.availableExceptions,
      // Addition of 9 and 4 hours represents the default time slot used when i.e starts at 9am local to 4pm local
      // This can be edited by the provider
      [date.valueOf()]: [
        {
          start: date.add(9, 'hours').valueOf(),
          end: date.add(4, 'hours').valueOf(),
        },
      ],
    }

    console.log(
      'HEHEHEHE->',
      date.add(9, 'hours').valueOf(),
      date.add(4, 'hours').valueOf(),
    )
    this.setState({
      selectedOverRideDate: date, // BUG: I think this is still incorrect
      availableExceptions: newSlot,
    })
  }

  renderEditSpecificDatesContent1() {
    let { availableSlots, selectedAvailabilityDayOfWeek, editedSpecificDates } =
      this.state

    let slot = selectedAvailabilityDayOfWeek.substring(0, 3)

    let timeDifference = new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.onModalCloseRequested()
          }}
        >
          X
        </p>
        <h5 style={{ color: Constants.darkGray, textAlign: 'center' }}>
          {timeDifference}
        </h5>
        <div
          style={{
            // display: 'flex',
            // flexDirection: 'column',
            justifyContent: 'space-evenly',
            borderTop: `1px solid ${Constants.darkGray}`,
            width: '100%',
          }}
        >
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            Editing Specific Dates
          </h4>
          <div
            style={{
              paddingTop: 20,
              // width: '50%',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  margin: '25px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  minWidth: '400px',
                  // borderBottom: '1px solid #DEDEDE',
                  // borderTop:'1px solid #DEDEDE'
                  // border-bottom: 1px solid #DEDEDE;
                }}
              >
                <DatePicker1
                  multiple
                  placeholder="Click to select date(s)"
                  value={this.state.selectedAvailabilityEditDates}
                  onChange={(dates) => {
                    console.log('dates ', dates)
                    this.handleDateChange(dates)
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  margin: '15px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderBottom: '1px solid #DEDEDE',
                  // borderTop:'1px solid #DEDEDE'
                  // border-bottom: 1px solid #DEDEDE;
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 'auto',
                    width: '50%',
                    maxWidth: '400px',
                  }}
                >
                  {this.renderSlots1({
                    slot: editedSpecificDates,
                    sday: slot,
                    handleChange: this.handleOverrideSlotAvailability,
                    handleDelete: this.handleOverrideTimeSlotDeletion,
                  })}
                </div>

                <FontAwesomeIcon
                  icon={faPlus}
                  className={Styles.deleteIcon}
                  onClick={() => {
                    // TODO: Check that the dates are firstly selected
                    console.log('ADD ')
                    editedSpecificDates.push({
                      start: 1635478200000, // a random day at 9 am; coz these are the default values rendered
                      end: 1635507000000, // a random day at 4 pm
                    })
                    this.setState({ editedSpecificDates })
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={this.saveSpecificDatesToBackend}
                className={GlobalStyles.button}
                // disabled={this.state.modalLoading}
                style={{ width: 200, marginTop: 20, marginBottom: 20 }}
                variant="primary"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  saveSpecificDaysToBackend() {
    console.log(1)
    let { availableSlots, selectedAvailabilityDayOfWeek } = this.state
    // let slot = selectedAvailabilityDayOfWeek.substring(0, 3)
    // let temp = {}
    // temp[slot] = availableSlots[slot]
    // console.log('temp ', temp)
    axios({
      method: 'patch',
      url: `${Config.BACKEND_URL}provider/availability-schedule`,
      headers: {
        Authorization: `JWT ${this.props.token}`,
      },
      data: {
        provider_id: this.props.loggedInUser.firebase_id,
        availability: availableSlots,
        // day_of_week: selectedAvailabilityDayOfWeek,
      },
    })
      .then((response) => {
        console.log('response ', response)
        if (response?.data?.success) {
          this.setState({ showAvailabilityEditTypeModal: false })
          this.getAppointmentAvailabilitySchedule() // Reload the data
          toast.success('Successfully updated')
        } else {
          toast.error('Something went wrong!')
        }
      })
      .catch((err) => {
        toast.error("Couldn't update availability")
        console.log('Error when updating ', err)
      })
  }

  renderEditSpecificDaysOfWeekContent() {
    let { availableSlots, selectedAvailabilityDayOfWeek } = this.state

    let slot = selectedAvailabilityDayOfWeek.substring(0, 3)

    let timeDifference = new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            this.onModalCloseRequested()
          }}
        >
          X
        </p>
        <h5 style={{ color: Constants.darkGray, textAlign: 'center' }}>
          {timeDifference}
        </h5>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            borderTop: `1px solid ${Constants.darkGray}`,
            width: '100%',
          }}
        >
          <div
            style={{
              paddingTop: 20,
              // width: '50%',
            }}
          >
            <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
              Editing all {selectedAvailabilityDayOfWeek}'s
            </h4>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  margin: '15px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderBottom: '1px solid #DEDEDE',
                  // borderTop:'1px solid #DEDEDE'
                  // border-bottom: 1px solid #DEDEDE;
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 'auto',
                  }}
                >
                  {this.renderSlots({
                    slot: availableSlots[slot],
                    sday: slot,
                    handleChange: this.handleSlotAvailability,
                    handleDelete: this.handleSlotDeletion,
                  })}
                </div>

                <FontAwesomeIcon
                  icon={faPlus}
                  className={Styles.deleteIcon}
                  onClick={() => {
                    console.log('ADD ')
                    availableSlots[slot].push({
                      start: 1635478200000,
                      end: 1635507000000,
                    })
                    this.setState({ availableSlots })
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={this.saveSpecificDaysToBackend}
                className={GlobalStyles.button}
                style={{ width: 200, marginTop: 20, marginBottom: 20 }}
                variant="primary"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderContent() {
    // const { mongoUser, firebaseUser } = this.props
    let { editAvailabilityType } = this.state

    let eventsToShow = []
    if (this.state.ailaEventsSelected) eventsToShow = this.state.events || []
    else eventsToShow = this.state.eventsFromNylas || []
    return (
      <div className={GlobalStyles.contentWrapper}>
        <Modal
          // ariaHideApp={true}
          ariaHideApp={false}
          onRequestClose={() => this.onModalCloseRequested()}
          isOpen={this.state.showAvailabilityEditTypeModal}
          // style={SLOT_AVAILABLE_MODAL_STYLES}
          className={Styles.modalContent}
          // style={paymentModalStyles}

          style={{
            overlay: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.44)',
              zIndex: 999,
            },
            content: {
              width: '80%',
              height: '80%',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
            },
          }}
          contentLabel="Slot Modal"
        >
          {/* {this.state.selectedDuration
            ? this.renderSlotAvailableContent()
            :  */}
          {editAvailabilityType === 'specificDates'
            ? this.renderEditSpecificDatesContent1()
            : editAvailabilityType === 'specificDaysOfWeek'
            ? this.renderEditSpecificDaysOfWeekContent()
            : this.renderVisitDurationContent()}
        </Modal>

        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 60px',
            marginTop: 20,
            visibility:
              this.state.modalIsOpen ||
              this.state.showScheduleModal ||
              this.state.showSlotAvailableModal
                ? 'hidden'
                : 'visible',
          }}
        >
          <Calendar
            localizer={localizer}
            // defaultDate={new Date()}
            defaultView="month"
            startAccessor="start"
            endAccessor="end"
            selectable
            views={['month', 'week', 'day']}
            events={this.state.events}
            onSelectSlot={(slotInfo) => {
              console.log('slotInfo', slotInfo)
              this.setState({
                selectedAvailabilityDayOfWeek: this.getDayOfWeek(
                  slotInfo.start,
                ),
              })
              this.setState({
                editAvailabilityType: '',
                showAvailabilityEditTypeModal: true,
                selectedSlotInfo: slotInfo,
              })
            }}
            onSelectEvent={(event) => {
              console.log('event', event)
            }}
            style={{ height: '100vh' }}
          />
        </div>
      </div>
    )
  }

  render() {
    // const { userDetails } = this.state
    // userDetails = null

    return (
      <div className={GlobalStyles.container}>
        <Header header="My Availability" />

        {/* {!userDetails || this.state.loading || false ? ( */}
        {false ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minWidth: '100%',
              height: 400,
              alignItems: 'center',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  const { userReducer } = state
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    loggedInUser: userReducer.mongoUser,
    mongoUser: userReducer.providerData,
    userType: userReducer.userType,
    adminId: userReducer.adminId,
  }
}

const mapDispatchToProps = {
  addProviderData,
  stopTimer,
  fetchSpecificDoctorMongoUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderAvailability)
