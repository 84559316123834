import Helper from '../helpers/Global'

export const getQuestionnaireResults = (path) => {
  switch (path) {
    case 'anxiety':
      return 'anxietyScore'
    case 'mental':
      return 'phq9_score'
    case 'burnout':
      return 'score'
    case 'physical':
      return 'haqScore'
    case 'gastro':
      return 'harvey_bradshaw_index'
    case 'colitis':
      return 'colitisScore'
    case 'ace':
      return 'score'
    case 'globalhealth':
      return 'mental_score'
    case 'sleepapnea':
      return 'score'
    default:
      return ''
  }
}

export const getReadableToolTip = (path) => {
  switch (path) {
    case 'anxiety':
      return 'Anxiety (GAD-7)'
    case 'mental':
      return 'Mental Health (PHQ-9)'
    case 'burnout':
      return 'Burnout score'
    case 'physical':
      return 'Physical score'
    case 'gastro':
      return "Crohn's Progress (Harvey Bradshaw Index)"
    case 'colitis':
      return 'Colitis score'
    case 'ace':
      return 'Ace score'
    case 'globalhealth':
      return 'Mental score'
    case 'sleepapnea':
      return 'Sleep (STOP - BANG) score'
    default:
      return 'Score'
  }
}

// export const getResultSentences = (path, score, answerData) => {
//   if('mental' === path) {
//     if (answerData?.suicidal > 0 && key === 'phq9_score') {
//       return 'Severe Depression'
//     }
//     else if(key === 'phq9_score') {
//       return (`${score}, ${Helper.calculatePhq9(score)}`)
//     } else {
//       return `${score}`
//     }
//   }
//   else if('anxiety' === path && key === 'anxietyScore') {
//     return (`${score}, ${Helper.calculateAnxiety(score)}`)
//   }
//   else if('gastro' === path && key === 'harvey_bradshaw_index') {
//     return (`${score}, ${Helper.calculateGastro(score)}`)
//   }
//   else if('colitis' === path && key === 'colitisScore') {
//     return (`${score}, ${Helper.calculateColitis(score)}`)
//   }
//   else if('physical' === path && key === 'haqScore') {
//     return (`${Number(score).toFixed(2)}, ${Helper.calculateHaq(score)}`)
//   }
//   else {
//     return `${score}`
//   }
// }

export default {getQuestionnaireResults, getReadableToolTip}