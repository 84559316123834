import React, { Component } from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css' // Import Tippy styles
import ChargeDetailsTooltip from './chargeDetailsTooltip'

class ChargeDetailsHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadDetails: false,
    }
  }

  render() {
    if (this.props.noShow) {
      return <p style={{ color: 'red' }}>NO SHOW</p>
    }
    return (
      <Tippy
        content={
          this.state.loadDetails ? (
            <ChargeDetailsTooltip eventId={this.props.eventId} />
          ) : null
        }
        placement="right"
        delay={[200, 200]}
        onShow={(instance) => {
          console.log('is->', instance)
          this.setState({ loadDetails: true })
        }}
        onHide={(instance) => {
          console.log('ih->', instance)
          this.setState({ loadDetails: false })
        }}
      >
        <p>{this.props.billed_amount || '-'}</p>
      </Tippy>
    )
  }
}

export default ChargeDetailsHeader
