const helpers = {
  random_hex_color_code: function() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  },

  calculateBMIValues(height, weight, gender, age) {
    if(!height || !weight || !gender || !age)
      return {
        bmi: 0,
        bfp: 0
      }

    let bmi = (703 * weight/Math.pow(height, 2))
    let bfp = null
    switch(gender) {
      case 'Male':
        bfp = (1.2 * bmi) + (0.23 * age) - 16.2
        break
      case 'Female':
        bfp = (1.2 * bmi) + (0.23 * age) - 5.4
        break
      default:
        bfp = (1.2 * bmi) + (0.23 * age) - 16.2
    }

    return {
      bmi: bmi.toFixed(1),
      bfp: Math.round(bfp)
    }
  },
  calculatePhq9(score) {
    if(score >= 0 && score <= 4)
      return 'No Depression'
    if(score >= 5 && score <= 9)
      return 'Mild Depression'
    if(score >= 10 && score <= 14)
      return 'Moderate Depression'
    if(score >= 15 && score <= 19)
      return 'Moderately Severe Depression'
    if(score >= 20 && score <= 27)
      return 'Severe Depression'
    if(score >= 0 && score <= 4)
      return 'No Depression'
  },
  calculateAnxiety(score) {
    if(score >= 0 && score <= 4)
      return 'No Anxiety'
    if(score >= 5 && score <= 9)
      return 'Mild Anxiety'
    if(score >= 10 && score <= 14)
      return 'Moderate Anxiety'
    if(score >= 15)
      return 'Severe Anxiety'
  },
  calculateGastro(score) {
    if(score < 5)
      return 'Remission'
    if(score >= 5 && score <= 7)
      return 'Mild Disease'
    if(score >= 8 && score <= 16)
      return 'Moderate Disease'
    if(score > 16)
      return 'Severe Disease'
  },
  calculateColitis(score) {
    if(score < 5)
      return 'Remission'
    if(score >= 5 && score <= 10)
      return 'Moderate' // Should confirm with this
    if(score > 10)
      return 'Active'
  },
  calculateHaq(score) {
    if(score < 0.26)
      return 'Remission'
    if(score >= 0.26 && score <= 3.70)
      return 'Low '
    if(score >= 3.71 && score <= 7.99)
      return 'Moderate'
    if(score >= 8)
      return 'Severe'
  },
  calculateSleepApnea(score) {
    if(score <= 2.9)
      return 'Low risk'
    if(score >= 3 && score <= 4.9)
      return 'Moderate risk'
    if(score >= 5)
      return 'High risk'
  },
}

export default helpers
