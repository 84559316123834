import React, { Component } from 'react'
import GlobalStyles from '../../styles/global.module.scss'
import Styles from './styles/Careplan.module.scss'
import Constants from '../../../values.js'
import Config from '../../../config'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faPlusCircle,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Draggable from 'react-draggable'
import BSModal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Select from 'react-select'
import metadata from '../../../metadata'
import firebase from '../../../services/firebase'
import Modal from 'react-modal'
import FileViewer from '../../../components/pdf/FileViewer'
import EducationMessages from '../../Messages/EducationMessages'
import QuestionnaireMessages from '../../Messages/QuestionnaireMessages'
const axios = require('axios')

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const STATUS_OPTIONS = [
  { label: 'Not Started', value: 'not_started' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Completed', value: 'completed' },
]

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    )
  }
}

class PatientTaskModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      modalOpen: this.props?.modalIsOpen ? this.props?.modalIsOpen : false,
      providerOptionsData: [],
      allProviderList: [],
      dueDate: new Date(),
      priority: null,
      assignedDoctor: null,
      assignedProvider: null,
      taskType: null,
      rpmApproval: null,
      rpmNotes: '',
      desc: null,
      timeSpent: 0,
      support_id: null,
      taskStatus: 'not_started',
      taskNote: '',
      support_author: '',
      taskNotesList: [],
      fileAttached: [],
      openFile: false,
      docType: '',
      docFile: '',
      docFileName: '',
      uploadingIndex: 0,
      modalECIsOpen: false,
      toggleModalChecked: 'Content Library',
      listEducation: [],
      listQuestion: [],
      listPatientExperience: [],
      listTitles: [],
    }
    this.getAllProviderData = this.getAllProviderData.bind(this)
    this.createTask = this.createTask.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.getAllProviderData()
  }

  componentDidUpdate(prevProps) {
    // console.log('TESTING UPDATE: ', this.props?.supportData)

    if (this.props?.modalIsOpen !== prevProps?.modalIsOpen) {
      this.setState({ modalOpen: this.props?.modalIsOpen })
      this.getAllProviderData()
      // if(this.props?.supportData) {
      const supportData = this.props?.supportData
      let assignedData = supportData?.assigned_to
        ? this.state?.allProviderList?.find(
            (doc) => doc?.firebase_id === supportData?.assigned_to,
          )
        : null
      let assignedName =
        assignedData?.first_name + ' ' + assignedData?.last_name
      let requestedDoc = supportData?.doctor_id
        ? this.state?.allProviderList?.find(
            (doc) => doc?.firebase_id === supportData?.doctor_id,
          )
        : null
      let requestedName =
        requestedDoc?.first_name + ' ' + requestedDoc?.last_name
      console.log(assignedName, requestedName)
      this.setState({
        dueDate: supportData?.due_date || new Date(),
        priority:
          { label: supportData?.priority, value: supportData?.priority } ||
          null,
        assignedDoctor: requestedDoc
          ? { label: requestedName, value: supportData?.doctor_id }
          : null,
        taskType:
          { label: supportData?.subject, value: supportData?.subject } || null,
        assignedProvider: supportData?.assigned_to ? assignedName : null,
        desc: supportData?.description || null,
        timeSpent: supportData?.time_spent || 0,
        fileAttached: supportData?.file_attached || [],
        taskNotesList: supportData?.task_notes || [],
        support_id: supportData?._id || null,
        support_author: supportData?.firebase_id || null,
        taskStatus: supportData?.status
          ? supportData?.status
          : supportData?.completed_status
          ? 'completed'
          : 'not_started',
        rpmApproval: supportData?.rpm_approval || null,
        rpmNotes: supportData?.rpm_notes || '',
      })
      // }
    }
  }

  getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: this.props.adminId,
        Authorization: 'JWT ' + this.props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        let providerOptionList = []
        let allProviderData = data.reduce((data, doc) => {
          data[doc.user_type || 'Other'] = data[doc.user_type || 'Other'] || []
          data[doc.user_type || 'Other'].push({
            label: doc?.first_name + ' ' + doc?.last_name,
            value: doc?.firebase_id,
          })
          return data
        }, Object.create(null))
        Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
          providerOptionList.push({
            label: userType,
            options: doctorArray,
          })
        })
        this.setState({
          providerOptionsData: providerOptionList,
          allProviderList: data,
        })
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
  }

  createTask = () => {
    const {
      desc,
      dueDate,
      priority,
      assignedDoctor,
      taskType,
      taskStatus,
      support_id,
      taskNotesList,
      taskNote,
      timeSpent,
      fileAttached,
      rpmApproval,
      rpmNotes,
      listEducation,
      listQuestion,
    } = this.state
    if (
      !desc ||
      !dueDate ||
      // !priority?.value ||
      // !assignedDoctor?.value ||
      !taskType?.value
    ) {
      toast.error('Missing information. Please fill all the fields.')
      return
    }

    if (
      taskType?.screen === 'education' ||
      taskType?.screen === 'questionnaire'
    ) {
      if (taskType?.screen === 'education' && listEducation.length === 0) {
        toast.error('Please select education content')
      } else if (
        taskType?.screen === 'questionnaire' &&
        listQuestion.length === 0
      ) {
        toast.error('Please select questionnaire')
      }
    }
    if (
      taskType?.screen === 'education' ||
      taskType?.screen === 'questionnaire'
    ) {
      listEducation &&
        listEducation.forEach((edu) => {
          let data = {
            firebase_id: this?.props?.patient?.uid,
            subject: taskType?.value,
            description: desc + `${edu.title}`,
            doctor_id: assignedDoctor?.value,
            due_date: dueDate,
            screen: taskType?.screen,
            sub_screen: edu,
          }
          const config = {
            method: 'post',
            url: Config.BACKEND_URL + 'patient-tasks',
            headers: {
              x_firebase_id: this.props.adminId,
              Authorization: 'JWT ' + this.props.token,
            },
            data,
          }
          console.log('upload config: ', config)
          axios(config)
            .then(({ data }) => {
              let toastText = support_id ? 'Task Edited' : 'Task Created.'
              toast.success(toastText)
              this.setState({
                providerOptionsData: [],
                dueDate: new Date(),
                priority: null,
                assignedDoctor: null,
                taskType: null,
                desc: null,
                timeSpent: 0,
                taskStatus: 'not_started',
                rpmApproval: null,
                rpmNotes: '',
                assignedProvider: null,
                taskNote: '',
                taskNotesList: [],
                fileAttached: [],
                listEducation: [],
                listQuestion: [],
                listPatientExperience: [],
                listTitles: [],
              })
              // this.props.getPatientTask && this.props.getPatientTask()
              this.props.hideTaskModal()
            })
            .catch((err) => {
              console.log('err while creating task: ', err)
              toast.error('Something went wrong while creating task.')
            })
        })

      listQuestion &&
        listQuestion.forEach((questionnaire) => {
          let data = {
            firebase_id: this?.props?.patient?.uid,
            subject: taskType?.value,
            description:
              desc +
              `Submit - ${questionnaire.substring(
                0,
                questionnaire.indexOf('('),
              )}`,
            doctor_id: assignedDoctor?.value,
            due_date: dueDate,
            screen: taskType?.screen,
            sub_screen: questionnaire,
          }
          const config = {
            method: 'post',
            url: Config.BACKEND_URL + 'patient-tasks',
            headers: {
              x_firebase_id: this.props.adminId,
              Authorization: 'JWT ' + this.props.token,
            },
            data,
          }
          console.log('upload config: ', config)
          axios(config)
            .then(({ data }) => {
              let toastText = support_id ? 'Task Edited' : 'Task Created.'
              toast.success(toastText)
              this.setState({
                providerOptionsData: [],
                dueDate: new Date(),
                priority: null,
                assignedDoctor: null,
                taskType: null,
                desc: null,
                timeSpent: 0,
                taskStatus: 'not_started',
                rpmApproval: null,
                rpmNotes: '',
                assignedProvider: null,
                taskNote: '',
                taskNotesList: [],
                fileAttached: [],
                listEducation: [],
                listQuestion: [],
                listPatientExperience: [],
                listTitles: [],
              })
              // this.props.getPatientTask && this.props.getPatientTask()
              this.props.hideTaskModal()
            })
            .catch((err) => {
              console.log('err while creating task: ', err)
              toast.error('Something went wrong while creating task.')
            })
        })
    } else {
      let data = {
        firebase_id: this?.props?.patient?.uid,
        subject: taskType?.value,
        description: desc,
        doctor_id: assignedDoctor?.value,
        due_date: dueDate,
        screen: taskType?.screen,
      }
      const config = {
        method: 'post',
        url: Config.BACKEND_URL + 'patient-tasks',
        headers: {
          x_firebase_id: this.props.adminId,
          Authorization: 'JWT ' + this.props.token,
        },
        data,
      }
      console.log('upload config: ', config)
      axios(config)
        .then(({ data }) => {
          let toastText = support_id ? 'Task Edited' : 'Task Created.'
          toast.info(toastText)
          this.setState({
            providerOptionsData: [],
            dueDate: new Date(),
            priority: null,
            assignedDoctor: null,
            taskType: null,
            desc: null,
            timeSpent: 0,
            taskStatus: 'not_started',
            rpmApproval: null,
            rpmNotes: '',
            assignedProvider: null,
            taskNote: '',
            taskNotesList: [],
            fileAttached: [],
            listEducation: [],
            listQuestion: [],
            listPatientExperience: [],
            listTitles: [],
          })
          // this.props.getPatientTask && this.props.getPatientTask()
          this.props.hideTaskModal()
        })
        .catch((err) => {
          console.log('err while creating task: ', err)
          toast.error('Something went wrong while creating task.')
        })
    }
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    let self = this

    // Update the state
    if (event?.target?.files[0]) {
      console.log(event?.target?.files[0])
      this.setState({ loading: true })

      //SAVE IN FIRESTORE STORAGE
      const file = event?.target?.files[0]
      let storageUrl = `TaskUploads/${mongoUser.firebase_id}/${
        self.state.uploadingIndex + '_' + file.name
      }`
      const storageRef = firebase.storage().ref()
      const uploadTask = storageRef.child(storageUrl).put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          self.setState({ loading: false })
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // console.log(downloadURL)
            let temp = [...self.state.fileAttached]
            temp[self.state.uploadingIndex].downloadURL = downloadURL
            temp[self.state.uploadingIndex].fileName = file.name
            self.setState({ fileAttached: temp, loading: false })
          })
        },
      )
    }
  }

  handleCallbackEducation = (childData) => {
    console.log(childData)
    this.setState({ listEducation: childData })
  }

  handleCallbackQuestion = (childData) => {
    console.log(childData)
    let res = childData.filter((list) => list.isChecked === true)
    let result = res.map((list) => list.title)
    this.setState({ listQuestion: result })
  }

  renderModalSelectedEC() {
    switch (this.state.toggleModalChecked) {
      case 'Content Library':
        return (
          <EducationMessages parentCallback={this.handleCallbackEducation} />
        )

      case 'Questionnaires':
        return (
          <QuestionnaireMessages parentCallback={this.handleCallbackQuestion} />
        )

      // case 'Patient Experience':
      //   return <PatientExperienceMessages parentCallback={this.handleCallbackPatientExperience} />

      default:
        break
    }
  }

  renderModalEC() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.setState({ modalECIsOpen: false })}
        >
          X
        </p>
        <div
          style={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '50%',
              height: 40,
              marginTop: 20,
              border: '1px solid black',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              onClick={() =>
                this.setState({
                  toggleModalChecked: 'Content Library',
                  listQuestion: [],
                  listPatientExperience: [],
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.toggleModalChecked === 'Content Library'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.toggleModalChecked === 'Content Library'
                      ? 'white'
                      : 'black',
                }}
              >
                Content Library
              </p>
            </div>
            <div
              onClick={() =>
                this.setState({
                  toggleModalChecked: 'Questionnaires',
                  listEducation: [],
                  listTitles: [],
                  listPatientExperience: [],
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.toggleModalChecked === 'Questionnaires'
                    ? Constants.primaryTheme
                    : 'white',
                borderLeftWidth: 1,
                borderLeftColor: 'black',
                borderLeftStyle: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'black',
                borderRightStyle: 'solid',
              }}
            >
              <p
                style={{
                  color:
                    this.state.toggleModalChecked === 'Questionnaires'
                      ? 'white'
                      : 'black',
                }}
              >
                Questionnaires
              </p>
            </div>
            {/* <div
              onClick={() =>
                this.setState({
                  toggleModalChecked: 'Patient Experience',
                  listEducation: [],
                  listQuestion: [],
                })
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '33.3%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.toggleModalChecked === 'Patient Experience'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.toggleModalChecked === 'Patient Experience'
                      ? 'white'
                      : 'black',
                }}
              >
                Patient Experience
              </p>
            </div> */}
          </div>
        </div>

        <div style={{ width: '100%' }}>{this.renderModalSelectedEC()}</div>

        <div style={{ paddingBottom: '10%' }}>
          <Button
            onClick={() => this.setState({ modalECIsOpen: false })}
            className={GlobalStyles.button}
            // disabled={this.state.messageText.length === 0}
            style={{ width: 100, flexGrow: 1 }}
            variant="primary"
          >
            Add
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { support_id } = this.state
    // if(this.state.loading) {
    //   return (
    //     <div className={GlobalStyles.container} style={{overflow: 'hidden', maxHeight: '100vh'}}>
    //       <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: '80vh'}}>
    //         <div className={GlobalStyles.loader} />
    //       </div>
    //     </div>
    //   )
    // }

    if (this.state.openFile) {
      return (
        <Modal
          ariaHideApp={false}
          isOpen={this.state.openFile}
          onRequestClose={() => {
            this.setState({
              openFile: false,
              docFile: '',
              docType: '',
              docFileName: '',
            })
          }}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          <h3>Attachment : {this.state.docFileName}</h3>
          <Button
            className={GlobalStyles.button}
            style={{
              width: 100,
              position: 'absolute',
              right: 120,
              top: 10,
            }}
            variant="primary"
            href={this.state.docFile}
            color="transparent"
            target="_blank"
            download
          >
            Download
          </Button>

          <FileViewer
            fileType={this.state.docType}
            fileName={this.state.docFile}
          />
        </Modal>
      )
    }
    if (this.state.modalECIsOpen) {
      return (
        <Modal
          ariaHideApp={false}
          onRequestClose={() => this.setState({ modalECIsOpen: false })}
          isOpen={this.state.modalECIsOpen}
          style={MODAL_STYLES}
          contentLabel="Modal"
        >
          {this.renderModalEC()}
        </Modal>
      )
    }

    return (
      <BSModal
        dialogAs={DraggableModalDialog}
        show={this.state.modalOpen}
        onHide={() => this.props.hideTaskModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={Styles.taskModalContainer}
      >
        <BSModal.Header
          closeButton
          style={{
            cursor: 'pointer',
          }}
        >
          <BSModal.Title
            style={{
              cursor: 'pointer',
              textAlign: 'center',
              width: '100%',
            }}
          >
            Assign Patient Task
          </BSModal.Title>
        </BSModal.Header>
        <BSModal.Body>
          {this.state.loading ? (
            <div
              className={GlobalStyles.container}
              style={{ overflow: 'hidden', maxHeight: '100vh' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: '80vh',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            </div>
          ) : (
            <div
              style={{
                height: '70vh',
                width: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'scroll',
                overflow: 'auto',
              }}
            >
              {/* <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Priority: </p>
                <Select
                  options={[
                    { label: 'High', value: 'HIGH' },
                    { label: 'Medium', value: 'MEDIUM' },
                    { label: 'Low', value: 'LOW' },
                  ]}
                  className={Styles.addSelectInput}
                  autoBlur={true}
                  onChange={(val) => {
                    this.setState({ priority: val })
                  }}
                  value={this.state.priority}
                  getOptionValue={(option) => option.label}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                  isOptionDisabled={(option) =>
                    !(
                      !support_id ||
                      this.state.support_author === this.props.adminId ||
                      this.props?.mongoUser?.user_type?.toLowerCase() ===
                        'administrator'
                    )
                  }
                />
              </div> */}
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Provider: </p>
                <Select
                  options={this.state.providerOptionsData}
                  className={Styles.addSelectInput}
                  autoBlur={true}
                  onChange={(val) => {
                    this.setState({ assignedDoctor: val })
                  }}
                  value={this.state.assignedDoctor}
                  getOptionValue={(option) => option.label}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                  isOptionDisabled={(option) =>
                    !(
                      !support_id ||
                      this.state.support_author === this.props.adminId ||
                      this.props?.mongoUser?.user_type?.toLowerCase() ===
                        'administrator'
                    )
                  }
                />
              </div>

              {/* {this.state.assignedProvider ? (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>
                    Assigned Provider:{' '}
                  </p>
                  <p> {this.state.assignedProvider} </p>
                </div>
              ) : null} */}

              {/* <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Status: </p>
                <Select
                  options={STATUS_OPTIONS}
                  className={Styles.addSelectInput}
                  autoBlur={true}
                  isMulti={false}
                  disable
                  onChange={(val) => {
                    this.setState({ taskStatus: val.value })
                  }}
                  value={STATUS_OPTIONS.find(
                    (obj) => obj.value === this.state.taskStatus,
                  )}
                  select
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div> */}

              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Task Type: </p>
                <Select
                  options={metadata.patientTaskTypes}
                  className={Styles.addSelectInput}
                  autoBlur={true}
                  onChange={(val) => {
                    this.setState({ taskType: val, desc: val.description })
                  }}
                  value={this.state.taskType}
                  getOptionValue={(option) => option.label}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                  isOptionDisabled={(option) =>
                    !(
                      !support_id ||
                      this.state.support_author === this.props.adminId ||
                      this.props?.mongoUser?.user_type?.toLowerCase() ===
                        'administrator'
                    )
                  }
                />
              </div>
              {/* {this.state.taskType?.label === 'RPM Order' && (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>Approve RPM:</p>
                  {this.props?.mongoUser?.supervisor ? (
                    <Select
                      options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                      ]}
                      className={Styles.addSelectInput}
                      autoBlur={true}
                      onChange={(val) => {
                        this.setState({ rpmApproval: val })
                      }}
                      value={this.state.rpmApproval}
                      getOptionValue={(option) => option.label}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  ) : (
                    <p>
                      {this.state.rpmApproval?.label
                        ? this.state.rpmApproval?.label === 'No'
                          ? 'Not Approved'
                          : 'Approved'
                        : 'Not Approved'}
                    </p>
                  )}
                </div>
              )}
              {this.state.taskType?.label === 'RPM Order' && (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>RPM Notes:</p>
                  <textarea
                    placeholder={'Please enter notes to referring provider'}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      width: '70%',
                      // height: '40px',
                    }}
                    rows="4"
                    type="text"
                    value={this.state.rpmNotes || ''}
                    onChange={(e) =>
                      this.setState({ rpmNotes: e.target.value })
                    }
                    disabled={!this.props?.mongoUser?.supervisor}
                  />
                </div>
              )} */}

              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Due date: </p>
                <input
                  placeholder={'Please enter estimated or due date'}
                  className={Styles.addManualTextInput}
                  type="date"
                  min={moment(new Date()).format('YYYY-MM-DD')}
                  value={moment(this.state.dueDate).format('YYYY-MM-DD')}
                  onChange={(e) => this.setState({ dueDate: e.target.value })}
                />
              </div>
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Description: </p>
                <textarea
                  placeholder={'Please enter description of task'}
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '70%',
                    // height: '40px',
                  }}
                  rows="4"
                  type="text"
                  value={this.state.desc || ''}
                  onChange={(e) => this.setState({ desc: e.target.value })}
                  disabled={
                    !(
                      !support_id ||
                      this.state.support_author === this.props.adminId ||
                      this.props?.mongoUser?.user_type?.toLowerCase() ===
                        'administrator'
                    )
                  }
                />
              </div>
              {(this.state.taskType?.screen === 'education' ||
                this.state.taskType?.screen === 'questionnaire') && (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>Attach:</p>
                  <FontAwesomeIcon
                    onClick={() => {
                      this.setState({ modalECIsOpen: true })
                    }}
                    icon={faPaperclip}
                    style={{
                      color: 'gray',
                      fontSize: 30,
                      cursor: 'pointer',
                    }}
                  />
                  <br />
                </div>
              )}
              {this.state.listEducation.length !== 0
                ? this.state.listEducation.map(
                    (edu, index) => `${index + 1}. ${edu.title}\n`,
                  )
                : this.state.listQuestion.length !== 0
                ? this.state.listQuestion.join(',')
                : this.state.listPatientExperience.length !== 0
                ? this.state.listPatientExperience.join('\n')
                : ''}

              {/* <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Time Spent: </p>
                <input
                  placeholder={'0 minutes'}
                  className={Styles.textInput}
                  style={{
                    border: '1px solid #d0d0d0',
                    padding: '5px 20px',
                    borderRadius: '4px',
                    width: '70%',
                    // height: '40px',
                  }}
                  type="number"
                  value={this.state.timeSpent || 0 + ' minutes'}
                  onChange={(e) => {
                    let time = e.target.value
                    this.setState({
                      timeSpent: time,
                    })
                  }}
                />
              </div>
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Upload Document: </p>
                <input
                  onChange={(e) => {
                    let temp = [...this.state.fileAttached]
                    temp.push({})
                    this.setState({ fileAttached: temp })
                    this.setState({
                      uploadingIndex: this.state.fileAttached.length,
                    })
                    this.onFileChange(e)
                  }}
                  type="file"
                  id={`file-attachment`}
                  // ref={this.fileRef}
                  // accept="image/*"
                  style={{ display: 'none' }}
                  multiple={false}
                />
                <div
                  className={Styles.studyEntryRow}
                  style={{
                    width: '60%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <FontAwesomeIcon
                    id={`file`}
                    title={`file`}
                    onClick={() =>
                      document.getElementById(`file-attachment`).click()
                    }
                    icon={faPaperclip}
                    style={{
                      color: 'gray',
                      fontSize: 30,
                      marginRight: 10,
                      flexGrow: 1,
                      cursor: 'pointer',
                      // position: 'absolute',
                      // marginLeft: '200px',
                    }}
                  />
                  {this.state.fileAttached?.map((item, index) => (
                    <p
                      onClick={() => {
                        this.setState({ openFile: true })
                        this.setState({
                          docType: item?.fileName
                            ? item?.fileName.split('.').pop()
                            : 'pdf',
                        })
                        this.setState({
                          docFile: item?.downloadURL,
                          docFileName: item?.fileName,
                        })
                      }}
                      style={{
                        textDecoration: 'underline',
                        color: 'gray',
                        cursor: 'pointer',
                        margin: '2%',
                      }}
                    >
                      {index + 1} {item?.fileName}
                    </p>
                  ))}
                </div>
              </div>
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>Task Notes: </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '70%',
                  }}
                >
                  <textarea
                    placeholder={'Please enter notes about task'}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      width: '90%',
                      // height: '40px',
                    }}
                    rows="2"
                    type="text"
                    value={this.state.taskNote || ''}
                    onChange={(e) =>
                      this.setState({ taskNote: e.target.value })
                    }
                  />
                  <FontAwesomeIcon
                    className={Styles.plusButton}
                    onClick={() => {
                      if (this.state.taskNote.length) {
                        let tempNote = this.state.taskNotesList
                        tempNote.unshift({
                          author_id: this.props.adminId,
                          name:
                            (this.props?.mongoUser?.first_name || '') +
                            ' ' +
                            (this.props?.mongoUser?.last_name || ''),
                          note_text: this.state.taskNote,
                          created_at: new Date(),
                        })
                        this.setState({ taskNotesList: tempNote, taskNote: '' })
                      }
                    }}
                    icon={faPlusCircle}
                  />
                </div>
              </div>
              {this.state.taskNotesList?.map((note, index) => (
                <div className={Styles.addManualRow}>
                  <p className={Styles.addManualQuestion}>
                    {note.name} {`(${moment(note.created_at).format('ll')})`}:{' '}
                  </p>
                  <textarea
                    placeholder={'Please enter notes about task'}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      width: '70%',
                      // height: '40px',
                    }}
                    rows="2"
                    type="text"
                    value={note.note_text || ''}
                    onChange={(e) => {
                      let tempNote = this.state.taskNotesList
                      tempNote[index].note_text = e.target.value
                      tempNote[index].updated_at = new Date()
                      this.setState({ taskNotesList: tempNote })
                    }}
                    disabled={
                      !(
                        !support_id ||
                        note.author_id === this.props.adminId ||
                        this.props?.mongoUser?.user_type?.toLowerCase() ===
                          'administrator'
                      )
                    } // only let authors to edit their note
                  />
                </div>
              ))} */}
            </div>
          )}
        </BSModal.Body>
        <BSModal.Footer>
          <Button
            onClick={() => this.createTask()}
            className={GlobalStyles.button}
            // style={{width: 140, position: 'absolute', bottom: 10}}
            variant="primary"
          >
            {this.state.support_id ? 'Edit' : 'Create'}
          </Button>
        </BSModal.Footer>
      </BSModal>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PatientTaskModal)
