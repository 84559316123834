import React, { Component } from 'react'
import GlobalStyles from './styles/global.module.scss'
import Config from "../config";
import {toast, ToastContainer} from "react-toastify";
import AilaLogo from "../assets/images/aila_logo.png";
const queryString = require('query-string');
const axios = require('axios');

export default class NylasThankyouUrl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
    this.saveFailedAppointments = this.saveFailedAppointments.bind(this)
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    console.log('parsed', parsed)
    let symptomsString = parsed['symptoms']
    try {
      let symptomsArray = symptomsString ? symptomsString.split(',') : []
      let symptoms = []
      symptomsArray.forEach(each => {
        let tempArray = each.split('-')
        symptoms.push({
          name: tempArray[0],
          days: tempArray[1],
          severity: tempArray[2]
        })
      })

      let nylasUrl = 'https://schedule.nylas.com/'
      let rescheduleUrl = `${nylasUrl}${parsed['page_slug']}/reschedule/${parsed['edit_hash']}`
      let cancelUrl = `${nylasUrl}${parsed['page_slug']}/cancel/${parsed['edit_hash']}`

      let toSave = {
        patient_id: parsed['patient_id'],
        doctor_id: parsed['doctor_id'],
        reason: parsed['reason'],
        description: parsed['description'],
        symptoms: symptoms,
        medications: parsed['medications'],
        treatment: parsed['treatment'],
        treatment_description: parsed['treatment_description'],
        start: Number(parsed['start_time']) * 1000,
        end: Number(parsed['end_time']) * 1000,
        page_slug: parsed['page_slug'],
        edit_hash: parsed['edit_hash'],
        event_id: parsed['event_id'],
        prev_event_id: parsed['prev_event_id'] || '',
        reschedule_url: rescheduleUrl,
        cancel_url: cancelUrl,
        duration: parsed['duration'],
        durationType: parsed['durationType'],
        severity: parsed['severity'],
        userCurrentLocation: parsed['userCurrentLocation'],
        treatement: parsed['treatement']
      }

      window.ReactNativeWebView && window.ReactNativeWebView.postMessage('success')
      console.log('saving to backend', toSave)
      let self = this
      let url = Config.BACKEND_URL + 'appointments'
      axios({
        method: 'put',
        url: url,
        data: toSave
      }).then(({ data }) => {
        console.log('Saved appointment to backend')
        self.setState({loading: false})
      }).catch(err => {
        console.log('error when saving appointment to backend after nylas success', err)
        this.saveFailedAppointments(parsed)
      })
    }catch(err) {
      console.log('error when constructing appointments object', err)
      this.saveFailedAppointments(parsed)
    }

  }

  saveFailedAppointments(parsed) {
    let self = this
    let url = Config.BACKEND_URL + 'appointments/failed'
    axios({
      method: 'put',
      url: url,
      data: parsed
    }).then(({ data }) => {
      console.log('Saved appointment failed to backend')
      self.setState({loading: false})
    }).catch(err => {
      console.log('error when saving appointment failed to backend as well', err)
      self.setState({loading: false})
    })
  }

  render() {
    return (
      <div className={GlobalStyles.container}>

        {
          this.state.loading ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: '90vh'}}>
              <div className={GlobalStyles.loader} />
            </div>
            :
            <div style={{height: '80vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <img src={AilaLogo} style={{height: 100, width: 100, marginBottom: 20}}/>
              <svg className={GlobalStyles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className={GlobalStyles.checkmark__circle} cx="26" cy="26" r="25" fill="none"/>
                <path className={GlobalStyles.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
              <p style={{marginTop: 20, fontWeight: 'bold', fontSize: 20, color: '#7e7e7e', textAlign: 'center'}}>Your appointment is booked. You can close this window now.</p>
            </div>
        }

      </div>
    )
  }
}
