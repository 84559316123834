import React, { Component } from 'react'
import 'react-chat-elements/dist/main.css'
import Header from '../../components/Header'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './Home.module.scss'
import Constants from '../../values.js'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import Config from '../../config'
import { Link, NavLink } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EThree } from '@virgilsecurity/e3kit-browser'

import {
  faEye,
  faShare,
  faVideo,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons'
import DateTimePicker from 'react-datetime-picker'
import DocPlaceHolder from '../../assets/images/docPlaceholder.png'
import AppPreview from '../../assets/images/app_preview.png'
import GooglePlay from '../../assets/images/googlePlayIcon.png'
import AppleStore from '../../assets/images/appleStore.png'

import {
  getUnreadMessages,
  addPatientLP,
  addRpmPatient,
  setPatientCoreData,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientCareteam,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  toggleSidebar,
  addAppointmentData,
  addTwilioToken,
  togglePatientVideoCall,
} from '../../redux/actions'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import { data } from 'branch-sdk'

const axios = require('axios')

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const sendFaxModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '40vw',
    height: '60vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appointmentList: [],
      initializingVirgil: false,
    }
    this.getAppointments = this.getAppointments.bind(this)
    this.cancelAppointment = this.cancelAppointment.bind(this)
    this.initializeVirgil = this.initializeVirgil.bind(this)
    this.getVirgilTokenFromBackend = this.getVirgilTokenFromBackend.bind(this)
    this.restorePrivateKey = this.restorePrivateKey.bind(this)
    this.fetchTwilioToken = this.fetchTwilioToken.bind(this)
  }

  // componentDidUpdate(prevProps, prevState) {
  //   this.getAppointments()
  // }

  componentDidMount() {
    if (!this.props.loggedIn || !this.props.patient) {
      this.props.history.push('/login')
      return
    }
    if (!this.props.patient?.firstName || !this.props.patient?.lastName) {
      console.log('patient mongo data does not exist')
      this.props.toggleSidebar(true)
      this.props.history.replace('/wix/aila-web-questions', {
        patient_id: this.props.patient?.uid,
        email_id: this.props.patient?.email,
        token: this.props.token,
      })
      return
    }
    this.props.toggleSidebar(false)
    this.getAppointments()
    this.fetchTwilioToken()
    this.props.fetchPatientCareteam(this.props?.patient?.uid, this.props?.token)

    this.setState({ initializingVirgil: true })
    this.initializeVirgil()
  }

  async updateUserInBackend() {
    let { patient, token } = this.props
    try {
      let response = await axios({
        method: 'put',
        url: `${Config.BACKEND_URL}users`,
        headers: { x_firebase_id: patient.uid, Authorization: 'JWT ' + token },
        data: { virgil_registered: true },
      })
      console.log('user updated::', response)
      //   let tempUserInfo = Object.assign({}, userInfo, {
      //     virgil_registered: true,
      //   })
      this.props.fetchPatientCoreDate(patient.uid, token)
      this.props.fetchPatientCareteam(patient.uid, token)
      //   setUserInfo(tempUserInfo)
    } catch (err) {
      console.log('error when updating user', err)
    }
  }

  async getVirgilTokenFromBackend() {
    const { patient, token } = this.props
    const url = `${Config.BACKEND_URL}virgil-jwt`
    const response = await axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: patient.uid,
        Authorization: `JWT ${token}`,
      },
    })

    if (response && response.data && response.data.virgilToken)
      return response.data.virgilToken
    return null
  }

  async restorePrivateKey(eThree, mongoUser, token, adminId) {
    console.log('restoring private key')
    const hasLocalPrivateKey = await eThree.hasLocalPrivateKey()

    if (!hasLocalPrivateKey) {
      console.log('no local copy')
      try {
        await eThree.restorePrivateKey(adminId)
        console.log('restored private key')
      } catch (err) {
        console.log('error when restoring private key from backup', err)
      }
    }
    console.log('restored private key')

    // this.props.updateDoctorMongoUser({virgil_registered: false, firebase_id: mongoUser.firebase_id}, mongoUser, token)
  }

  initializeVirgil() {
    const { patient, patientCore, token, adminId } = this.props
    const self = this
    const user = { ...patient, ...patientCore }
    console.log('initializing virgil')
    EThree.initialize(this.getVirgilTokenFromBackend)
      .then((eThree) => {
        // register user, encrypt, decrypt, etc.
        console.log('successful intialization e3')
        if (!user.virgil_registered) {
          eThree
            .register()
            .then(() => {
              console.log('successfully registered user with virigl')
              self.updateUserInBackend()
              eThree
                .backupPrivateKey(adminId)
                .then(() => console.log('success'))
                .catch((e) => console.error('error: ', e))
            })
            .catch((err) => {
              console.error('error when registering: ', err)
              if (err.name === 'IdentityAlreadyExistsError') {
                self.updateUserInBackend()
                self.restorePrivateKey(eThree, user, token, adminId)
              }
              // self.deleteUser(eThree, mongoUser, token)

              // eThree.rotatePrivateKey()
              //   .then(() => {
              //     console.log('successully rotated private key')
              //     self.props.updateDoctorMongoUser({virgil_registered: true, firebase_id: mongoUser.firebase_id}, mongoUser, token)
              //   })
              //   .catch(e => console.error('error: ', e));
            })
        } else {
          self.restorePrivateKey(eThree, user, token, adminId)
        }
        self.props.addVirgilE3(eThree)
      })
      .catch((err) => {
        console.log('error when intializing virgil SDK', err)
      })
  }

  getAppointments() {
    const { token, patient } = this.props
    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + `patient/appointments`,
      headers: {
        Authorization: `JWT ${token}`,
        x_patient_id: patient.uid,
      },
    }
    axios(config)
      .then(({ data }) => {
        //SHOW UPCOMING APPOINTMENTS ONLY
        let res = data.filter((appt) => new Date(appt.start) >= new Date())

        //CODE TO SORT APPOINTMENTS
        // let res = res1.sort((a, b) => new Date(a.start) - new Date(b.start))

        this.setState({ appointmentList: res }, () =>
          this.setState({ loading: false }),
        )
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        toast.error('Could not fetch Faxes')
      })
  }

  fetchTwilioToken() {
    let { token, patient } = this.props
    let url = Config.BACKEND_URL + 'twilio/token'
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: 'JWT ' + token, x_firebase_id: patient.uid },
    })
      .then((result) => {
        const { token } = result.data
        console.log('got twilio token')
        this.setState({ twilioToken: token })
        this.props.addTwilioToken(token)
      })
      .catch((err) => {
        console.log('error when getting twilio token', err)
        if (
          window.confirm(
            'Something went wrong. If the problem persists, please contact support.',
          )
        ) {
          // this.props.history.push('/appointments')
        } else {
          // this.props.history.push('/appointments')
        }
      })
  }

  //DELETE EVENT IN GOOGLE CALENDAR AFTER APPOINTMENT SCHEDULED
  deleteEvent(event_id, access_token, event) {
    var gapi = window.gapi

    const CALENDAR_ID = event?.google_calendar_id
    gapi.load('client:auth2', () => {
      console.log('loading client')

      gapi.client.load('calendar', 'v3', () => {
        console.log('google calendar signed in')
        try {
          gapi.auth.setToken({
            access_token: access_token?.access_token,
          })
          var request = gapi.client.calendar.events.delete({
            calendarId: CALENDAR_ID,
            eventId: event_id,
          })

          request.execute((event) => {
            console.log('EVENT DELETED:::', event)
          })
        } catch (error) {
          console.log('Event DELETION failed', error)
        }
      })
    })
  }

  async cancelAppointment(appointmentData) {
    let data = {
      event_id: appointmentData.event_id,
    }

    if (!appointmentData || !appointmentData.event_id) return

    this.setState({ loading: true })
    const url = `${Config.BACKEND_URL}appointments`
    try {
      const response = await axios({
        method: 'delete',
        url,
        headers: {
          Authorization: `JWT ${this.props.token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
      this.getAppointments()

      if (response.data.success) {
        toast.success('Appointment cancelled successfully')
        this.getAppointments()
        if (response?.data?.access_token && appointmentData?.google_event_id) {
          this.deleteEvent(
            appointmentData?.google_event_id,
            response?.data?.access_token,
            appointmentData,
          )
        }
      }
    } catch (err) {
      console.log('error when deleting calendar event', err)
      this.setState({ loading: false })
      toast.error('Error when deleting calendar event')
    }
  }

  onVideoCallPressed(appointmentData, patientData) {
    // this.props.togglePatientVideoCall(true)
    this.props.history.push('/patient/appointments', {
      appointment_details: appointmentData,
    })
  }

  renderAppointments() {
    const patient = this.props?.patient

    let appointmentList = this.state.appointmentList
    const renderAppointmentList = (appointmentData, index) => {
      return (
        <div
          key={index}
          // className={Styles.contentRow}
          className={Styles.contentBackground}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 10,
          }}
        >
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <img
              src={
                appointmentData?.image_url
                  ? appointmentData?.image_url
                  : DocPlaceHolder
              }
              height={80}
              width={80}
              style={{ borderRadius: '50%', margin: 10 }}
            />
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 10,
              }}
            >
              <h6 style={{ color: Constants.primaryThemeDark }}>
                {moment
                  .unix(appointmentData?.start / 1000)
                  .format('MMM Do YYYY, HH:mm A')}
                {/* {'\n '}
                Visit with {appointmentData?.first_name}{' '}
                {appointmentData?.last_name} */}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                Visit with {appointmentData?.first_name}{' '}
                {appointmentData?.last_name}
              </h6>
              <h6 style={{ color: Constants.primaryTheme }}>
                {appointmentData?.user_type}
              </h6>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  padding: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                  backgroundColor: Constants.primaryTheme,
                  borderRadius: '50%',
                  marginRight: 2,
                }}
              >
                <FontAwesomeIcon
                  onClick={() => {
                    this.props.addAppointmentData(appointmentData, patient)

                    this.onVideoCallPressed(appointmentData, patient)
                  }}
                  icon={faVideo}
                  style={{ color: 'white', fontSize: 20, cursor: 'pointer' }}
                />
              </div>
              Start
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  padding: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                  backgroundColor: 'red',
                  borderRadius: '50%',
                }}
              >
                <FontAwesomeIcon
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to cancel your appointment?',
                      )
                    ) {
                      this.cancelAppointment(appointmentData)
                    }
                  }}
                  icon={faMinusCircle}
                  style={{ color: 'white', fontSize: 20, cursor: 'pointer' }}
                />
              </div>
              Cancel
            </div>
          </div>
        </div>
      )
    }

    return (
      <div style={{ width: '50%', marginTop: 40, alignSelf: 'flex-start' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <h5 style={{ color: Constants.primaryTheme }}>
            Upcoming Appointments
          </h5>
          <Button
            onClick={() => this.props.history.push('/patient/appointments')}
            className={GlobalStyles.button}
            disabled={this.state.loading}
            style={{
              width: 200,
              right: 0,
              position: 'absolute',
            }}
            variant="primary"
          >
            Schedule Appointment
          </Button>
        </div>
        <div style={{ overflowY: 'scroll', height: '60vh' }}>
          {appointmentList.length !== 0 ? (
            appointmentList.map((appointmentData, index) =>
              renderAppointmentList(appointmentData, index),
            )
          ) : (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              You don’t have any appointments scheduled, <br></br> book an
              appointment now
            </p>
          )}
        </div>
        <div className={Styles.contentBackground} style={{ marginTop: 50 }}>
          <h5
            style={{
              color: Constants.primaryTheme,
              textAlign: 'center',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            Track and store all of your health information in one app
          </h5>
          <img
            src={AppPreview}
            height={250}
            width={250}
            className={Styles.centerImage}
          />
          <ul style={{ marginTop: 20 }}>
            <li>Connect all your data in one secure place.</li>
            <li>
              Track your symptoms, nutrition, digestion, and mood all in one
              platform so we can identify trends.
            </li>
            <li>
              Generate personalized insights about you and support you as you
              manage your chronic illness.
            </li>
          </ul>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'row',
              margin: 20,
            }}
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.ailahealth"
              target="_blank"
            >
              <img src={GooglePlay} height={36} />
            </a>
            <a
              href="https://apps.apple.com/us/app/aila-health/id1525982613"
              target="_blank"
            >
              <img src={AppleStore} height={40} />
            </a>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const patient = this.props?.patient

    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          paddingBottom: '10px',
        }}
      >
        <Header
          header={patient && `Hi ${patient.firstName} ${patient.lastName} !`}
        />

        {this.state.loading && (
          <div className={GlobalStyles.container} style={{ height: '100vh' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: '100%',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          </div>
        )}

        <div
          className={GlobalStyles.contentWrapper}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
            overflow: 'scroll',
            // width: '100%',
          }}
        >
          {this.renderAppointments()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,

    patient: state.patientReducer.patient,

    token: state.authReducer.token,

    patientList: state.patientReducer.patientList,

    mongoUser: state.userReducer.mongoUser,

    adminId: state.userReducer.adminId,

    patientCore: state.patientReducer.patientCore,

    unreadNotifications: state.userReducer.unreadNotifications,

    allNotifications: state.userReducer.allNotifications,
  }
}

const mapDispatchToProps = {
  getUnreadMessages,
  addPatientLP,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientCareteam,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  toggleSidebar,
  addAppointmentData,
  addTwilioToken,
  togglePatientVideoCall,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
