import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'
import DocPlaceHolder from '../../assets/images/docPlaceholder.png'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Referrals.module.scss'

import Config from '../../config'
import { SPECIALITY_OPTIONS } from '../../metadata.js'

const axios = require('axios')

// const ORDER_STATUSES = {
//   C: 'Corrected',
//   E: 'Entered',
//   F: 'Final Reported',
//   I: 'Inactive',
//   NA: 'Results Received',
//   P: 'Partial Reported',
//   R: 'Ready to Transmit',
//   T: 'Transmitted',
//   TX: 'Transmission Error',
//   X: 'Error',
// }
// const PLACEHOLDER_IMAGE =
//   'https://firebasestorage.googleapis.com/v0/b/aila-health-b469c.appspot.com/o/images%2Fplaceholder.png?alt=media&token=89bc348e-7ccd-4e35-a291-50c6e21a3b6a'
// let windowHeight = window.innerHeight
// let windowWidth = window.innerWidth

// const eventFire = (el, etype) => {
//   if (el.fireEvent) {
//     el.fireEvent('on' + etype)
//   } else {
//     var evObj = document.createEvent('Events')
//     evObj.initEvent(etype, true, false)
//     el.dispatchEvent(evObj)
//   }
// }

// const ClearAllRefinements = ({ refine, items }) => {
//   const onClick = () => refine(items)
//   return (
//     <button id="clearQuery" onClick={onClick}>
//       ClearAll
//     </button>
//   )
// }

// const customPatientAssignModalStyle = {
//   overlay: {
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.44)',
//     zIndex: 10,
//   },
//   content: {
//     zIndex: 10,
//     width: '75%',
//     height: '60%',
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }

// const PROVIDERS = [
//   {
//     label: 'Alison Shmerling',
//     value: '4015227895',
//     clinicianId: 'iazlt523',
//     clinicianPwd: 'iazlt523',
//   },
// ]

// const UNITS = [
//   { label: 'Bag', value: 'Bag' },
//   { label: 'Bar', value: 'Bar' },
//   { label: 'Capsules', value: 'Capsules' },
//   { label: 'Canister', value: 'Canister' },
//   { label: 'Cartridge', value: 'Cartridge' },
//   { label: 'Pills', value: 'Pills' },
//   { label: 'Strip', value: 'Strip' },
//   { label: 'Swab', value: 'Swab' },
//   { label: 'Syringe', value: 'Syringe' },
//   { label: 'Tube', value: 'Tube' },
//   { label: 'Vial', value: 'Vial' },
// ]

// const PHARMACIES = [
//   {
//     label: 'Bannockburn Pharmacy[6798 PYLE RD, BETHESDA (301)644-2418]',
//     value: '1',
//   },
//   {
//     label:
//       'Shollenberger Pharmacy[2002 S. MCDOWELL BLVD EXT, PETALUMA (707)984-5571]',
//     value: '2',
//   },
// ]

// const customDrugModalStyles = {
//   overlay: {
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.44)',
//     zIndex: 10,
//   },
//   content: {
//     zIndex: 10,
//     width: '50%',
//     height: '75%',
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }

// const customModalStyles = {
//   overlay: {
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.44)',
//     zIndex: 10,
//   },
//   content: {
//     zIndex: 10,
//     width: 800,
//     height: 800,
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }

// const customChangeModalStyles = {
//   overlay: {
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.44)',
//     zIndex: 10,
//   },
//   content: {
//     zIndex: 10,
//     width: '80%',
//     height: '95%',
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }

const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#20A89233',
    primary: '#20A892',
  },
})

const Econsults = (props) => {
  const patientCarePlanData = useSelector(
    (state) => state.patientReducer.patientCarePlanData,
  )

  const [patientCareTeam, setPatientCareTeam] = useState([])
  const [careTeamSpeciality, setCareTeamSpeciality] = useState([])
  const [selectedClinician, setSelectedClinician] = useState(null)
  const [requestedDate, setRequestedDate] = useState(null)
  const [noteValue, setNoteValue] = useState('')
  const [requestType, setRequestType] = useState('')
  const [loading, setLoading] = useState(false)
  const [speciality, setSpeciality] = useState(null)
  const [extraSpecialty, setExtraSpecialty] = useState('')
  const [showNext, setShowNext] = useState(false)
  const [selectedNote, setSelectedNote] = useState([])
  const [allProviderList, setAllProviderList] = useState([])

  const [adminAccess, setAdminAccess] = useState(false)
  const history = useHistory()
  let fuse = null

  useEffect(() => {
    console.log('PATIENT INFO : ', props?.patient, props?.patientId)
    getUserCarePlan()
    props?.patientId && getAllProviderData()
    props?.patientId && getPatientCareTeam()
    if (props?.mongoUser?.user_type === 'Administrator') setAdminAccess(true)
  }, [props?.patientId])

  const saveEconsult = () => {
    let tempSelectedNotes = selectedNote.filter(
      (item) => item.isChecked === true,
    )

    let data = {
      request_type: requestType,
      patient_id: props?.patientId,
      patient_name: props?.patient?.firstName + ' ' + props?.patient?.lastName,
      speciality_requested: speciality?.label,
      clinician_requested: selectedClinician?.firebase_id,
      doctor_id: props?.providerId,
      requested_date: requestedDate,
      notes: noteValue,
      selected_note: tempSelectedNotes.map((item) => {
        return {
          _id: item?._id,
          appointment_date: item?.appointment_date,
          appointment_event_id: item?.appointment_event_id,
        }
      }),
    }
    console.log(data)
    let config = {
      method: 'post',
      url: Config.BACKEND_URL + `e-consult`,

      headers: {
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
      },
      data: data,
    }

    axios(config)
      .then(({ data }) => {
        console.log('Saved e-consult in backend')
        toast.success('E-Consult requested')
        setRequestType('')
        setRequestedDate(null)
        setSelectedClinician(null)
        setCareTeamSpeciality(null)
        setNoteValue('')
      })
      .catch((err) => {
        console.log('Error saving referral to backend', err)
        toast.error('Could not save e-consult')
      })
  }

  const getUserCarePlan = () => {
    let url = Config.BACKEND_URL + 'careplan'
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + props.token,
        patient_id: props?.patientId,
        x_doctor_id: props?.providerId,
      },
    })
      .then(({ data }) => {
        if (data) {
          // console.log('PATIENT CAREPLAN : ', data)
          let temp = data?.filter((obj) => obj?.note_status !== 'archive') || []
          setSelectedNote(temp.map((obj) => ({ ...obj, isChecked: false })))
        }
      })
      .catch((err) => {
        console.log('error when getting user care plan', err)
      })
  }

  const getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: props.providerId,
        Authorization: 'JWT ' + props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        data && setAllProviderList(data)
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
  }

  const getPatientCareTeam = () => {
    // get the care team members
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    const doctorList = []

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${props.token}`,
        x_firebase_id: props?.patientId,
      },
      url,
    })
      .then(async ({ data }) => {
        if (data) {
          let specialityArray = data
            .filter((item) => item.speciality !== undefined)
            .map((item) => ({
              label: `${item.speciality}`,
              value: `${item.speciality}`,
            }))

          //GET UNIQUE EMAIL IDS - NO MULTIPLE COPIES
          function getUniqueListBy(arr, key) {
            return [...new Map(arr.map((item) => [item[key], item])).values()]
          }

          const uniqueSpecialities = getUniqueListBy(specialityArray, 'label')
          setCareTeamSpeciality(uniqueSpecialities)

          let providerOptionList = []
          let allProviderData = data.reduce((data, doc) => {
            data[doc.user_type || 'Other'] =
              data[doc.user_type || 'Other'] || []
            data[doc.user_type || 'Other'].push({
              label:
                doc?.first_name +
                ' ' +
                doc?.last_name +
                (doc?.speciality ? ` (${doc?.speciality})` : ''),
              value: doc?.firebase_id,
            })
            return data
          }, Object.create(null))
          Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
            providerOptionList.push({
              label: userType,
              options: doctorArray,
            })
          })
          setPatientCareTeam(providerOptionList)
        }
      })
      .catch((err) => {
        console.log('error when getting the care team for patient', err)
      })
  }

  const renderCarePlanNotes = () => {
    // let carePlanData1 = patientCarePlanData || []
    let carePlanData1 = selectedNote || []

    return (
      <>
        {carePlanData1 &&
          carePlanData1?.map((carePlanData) => {
            return (
              // latest notes from the provider
              <div
                className={Styles.contentRow}
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <label class={Styles.checkContainer}>
                    {/* {carePlanData.name} */}
                    <input
                      type="checkbox"
                      checked={carePlanData.isChecked}
                      onClick={() => {
                        let tempq = [...selectedNote]
                        tempq.forEach((item) => {
                          if (item._id === carePlanData._id)
                            item.isChecked = !item.isChecked
                        })
                        setSelectedNote(tempq)
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                  </label>

                  <img
                    src={
                      carePlanData?.doctor?.image_url
                        ? carePlanData?.doctor?.image_url
                        : DocPlaceHolder
                    }
                    height={50}
                    width={50}
                    style={{ borderRadius: '50%', margin: 10 }}
                  />
                  <h6 style={{ color: Constants.primaryTheme }}>
                    {carePlanData?.doctor?.first_name}{' '}
                    {carePlanData?.doctor?.last_name} {', '}
                    {carePlanData?.appointment_date
                      ? moment(carePlanData?.appointment_date).format(
                          'MMM Do YYYY',
                        )
                      : moment(carePlanData?.created_at, 'YYYY-MM-DD').format(
                          'MMM Do YYYY',
                        )}
                  </h6>
                </div>
                <div style={{ width: '100%' }} className={Styles.carePlanRow}>
                  <div style={{ width: '80%' }}>
                    <h6 style={{ color: Constants.primaryTheme }}></h6>
                    {carePlanData?.careplan &&
                      carePlanData?.careplan.map((note_data, index) => (
                        <p style={{ marginLeft: '10%' }}>
                          <b>{note_data?.icd_10?.title?.replace(/"/g, '')}</b> -{' '}
                          {note_data.assessment}
                        </p>
                      ))}
                  </div>

                  {
                    // carePlanData?.careplan?.icd_10 &&
                    <div style={{ width: '80%' }}>
                      <p
                        style={{
                          textDecoration: 'underline',
                          textDecorationColor:
                            carePlanData?.note_status === 'completed' ||
                            !carePlanData?.note_id
                              ? Constants.primaryTheme
                              : 'red',
                          color:
                            carePlanData?.note_status === 'completed' ||
                            !carePlanData?.note_id
                              ? Constants.primaryTheme
                              : 'red',
                          // cursor: 'pointer',
                          textAlign: 'left',
                          marginLeft: '10%',
                        }}
                      >
                        {carePlanData?.note_status === 'completed' ||
                        !carePlanData?._id
                          ? carePlanData?.appointment_date
                            ? 'Complete Notes from visit'
                            : 'Notes'
                          : 'Incomplete note'}
                      </p>
                    </div>
                  }
                </div>
              </div>
            )
          })}
      </>
    )
  }

  const renderContent = () => {
    let { addressComponents, zipCodeData } = props?.patient
    let state = null
    if (addressComponents && addressComponents.length > 0) {
      addressComponents.forEach((addressComponent) => {
        let types = addressComponent.types || []
        if (types.includes('administrative_area_level_1'))
          state = addressComponent.short_name
      })
    } else {
      state = zipCodeData?.state
    }

    const options = {
      includeScore: true,
      keys: ['speciality'],
      useExtendedSearch: true,
    }

    // fuse = new Fuse(patientCareTeam, options)

    let uniqueList = patientCareTeam

    // if (speciality?.value !== 'Others') {
    //   const results = fuse.search(`="${speciality?.label}"`)
    //   // const searchResults = []
    //   results.forEach((result) => {
    //     uniqueList.push(result.item)
    //   })
    //   if (!results.length) {
    //     uniqueList = patientCareTeam
    //   }
    // } else {
    //   uniqueList = patientCareTeam
    // }

    return (
      <>
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Subject/ Request Type</p>
          <div
            style={{
              width: '30%',
              margin: '0px 20px',
            }}
          >
            <input
              placeholder={'Subject/ Request Type'}
              className={Styles.textInput}
              type="text"
              value={requestType}
              onChange={(e) => {
                let text = e.target.value
                setRequestType(text)
              }}
            />
          </div>
        </div>
        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Patient Name</p>
          <div
            style={{
              width: '30%',
              margin: '0px 20px',
            }}
          >
            <input
              placeholder={'Patient Name'}
              className={Styles.textInput}
              type="text"
              value={props?.patient?.firstName + ' ' + props?.patient?.lastName}
              disabled={true}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Patient State</p>
          <div
            style={{
              width: '30%',
              margin: '0px 20px',
            }}
          >
            <input
              placeholder={'Patient State'}
              className={Styles.textInput}
              type="text"
              value={state}
              disabled={true}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Specialty Requested</p>
          <div
            style={{
              width: '25%',
              margin: '0px 20px',
            }}
          >
            <Select
              //   style={{ width: 100 }}
              options={SPECIALITY_OPTIONS}
              value={speciality}
              autoBlur={true}
              onChange={(val) => setSpeciality(val)}
              theme={(theme) => selectTheme(theme)}
            />
          </div>

          <div
            style={{
              width: '15%',
              // margin: '0px 20px',
              visibility:
                speciality && speciality.value === 'Other'
                  ? 'visible'
                  : 'hidden',
            }}
          >
            <input
              placeholder={'Enter Specialty'}
              className={Styles.textInput}
              type="text"
              value={extraSpecialty}
              onChange={(e) => {
                let text = e.target.value
                setExtraSpecialty(text)
              }}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Selected Clinician</p>
          <div
            style={{
              width: '25%',
              margin: '0px 20px',
            }}
          >
            <Select
              placeholder={'Select Clinician'}
              inputId="doctor_name"
              options={uniqueList}
              value={selectedClinician}
              isSearchable
              autoBlur
              getOptionValue={(option) => option.label}
              onChange={(val) => {
                console.log(val)
                setSelectedClinician(val)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Requested Response Due Date</p>
          <div style={{ width: '30%', margin: '0px 20px' }}>
            <input
              placeholder={'Requested Response Due Date'}
              className={Styles.textInput}
              type="date"
              min={moment(new Date()).format('YYYY-MM-DD')}
              value={moment(requestedDate).format('YYYY-MM-DD')}
              onChange={(e) => setRequestedDate(e.target.value)}
            />
          </div>
        </div>

        <div className={Styles.inputDiv}>
          <p className={Styles.inputQuestion}>Patient Case Notes/ Question</p>
          <div
            style={{
              width: '50%',
              margin: '0px 20px',
            }}
          >
            <textarea
              placeholder={'Please enter notes'}
              style={{
                border: '1px solid #d0d0d0',
                padding: '5px 20px',
                borderRadius: '4px',
                width: '100%',
                // height: '40px',
              }}
              rows="2"
              type="text"
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '5% 1%',
            borderTopWidth: 1,
            borderTopColor: Constants.primaryTheme,
            borderTopStyle: 'solid',
          }}
        >
          <div className={Styles.inputDiv}>
            <p className={Styles.inputQuestion}>Upload Files/ Select Note</p>

            {patientCarePlanData && patientCarePlanData.length != 0 ? (
              <div
                className={Styles.contentBackground}
                style={{ height: 500, overflowY: 'scroll', width: '100%' }}
              >
                {renderCarePlanNotes()}
              </div>
            ) : (
              `No Notes Found`
            )}
          </div>
        </div>
      </>
    )
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100%',
          minHeight: '100%',
        }}
      >
        <div className={GlobalStyles.loader} />
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
          borderTopWidth: 1,
          borderTopColor: Constants.primaryTheme,
          borderTopStyle: 'solid',
        }}
      >
        {renderContent()}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => setShowNext(false)}
            className={GlobalStyles.button}
            style={{
              width: 100,
              marginTop: 60,
              alignSelf: 'flex-start',
              visibility: showNext ? 'visible' : 'hidden',
            }}
            variant="primary"
          >
            Back
          </Button>

          <Button
            onClick={() => {
              saveEconsult()
            }}
            className={GlobalStyles.button}
            style={{ width: 200, marginTop: 60, alignSelf: 'flex-end' }}
            variant="primary"
          >
            Request E-Consult
          </Button>
        </div>
      </div>
    )
  }
}

export default Econsults
