/* eslint-disable */
import React, { useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Constants from '../../../../values'
import GlobalStyles from '../../../styles/global.module.scss'
import Styles from '../styles/profile.module.scss'

import {} from '@fortawesome/free-regular-svg-icons'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import Config from '../../../../config'
import * as MetaData from '../../../../metadata'

const axios = require('axios')

import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'

const iCD10Connector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'icd10-complete',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigICD10 = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: iCD10Connector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      code: { raw: {} },
      display: { raw: {} },
      object_id: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

// const MedicationForms = MetaData.medicationForms
// const GoalDesc = MetaData.goalDesc

// const eventFire = (el, etype) => {
//   if (el.fireEvent) {
//     el.fireEvent('on' + etype)
//   } else {
//     var evObj = document.createEvent('Events')
//     evObj.initEvent(etype, true, false)
//     el.dispatchEvent(evObj)
//   }
// }

// const ClearQuery = ({ refine }) => {
//   const onClick = (ev) => {
//     refine('')
//     ev.stopPropagation()
//   }
//   return (
//     <div onClick={onClick} style={{ display: 'none' }}>
//       <ConnectedClearAllRefinements />
//     </div>
//   )
// }

// const ClearAllRefinements = ({ refine, items }) => {
//   const onClick = () => refine(items)
//   return (
//     <button id="clearQuery" onClick={onClick}>
//       ClearAll
//     </button>
//   )
// }

// const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
// const ConnectedClearAllRefinements =
//   connectCurrentRefinements(ClearAllRefinements)

export const RenderAddFamilyHistoryManually = ({
  onManualAddModalHide,
  currentAddModalData,
  getFamilyHistory,
  patientId,
}) => {
  const token = useSelector((state) => state.authReducer.token)
  const patient = patientId
    ? { patient_id: patientId }
    : useSelector((state) => state.patientReducer.patient)
  const doctor_id = useSelector((state) => state.userReducer.adminId)
  const doctor_details = useSelector((state) => state.userReducer.mongoUser)
  const appointmentData = useSelector(
    (state) => state.appointmentReducer.appointmentData,
  )
  const showVideoModal = useSelector(
    (state) => state.genericReducer.showVideoModal,
  )

  const [familyHistoryData, setFamilyHistoryData] = useState(
    currentAddModalData?.relations || [],
  )
  const [conditionQuery, setConditionQuery] = useState('')
  const [condition, setCondition] = useState('')
  const [selectedFamily, setSelectedFamily] = useState('')

  const onClickSave = () => {
    if (!familyHistoryData?.length) {
      toast.error('Please add family history')
      return
    }

    let data = JSON.stringify({
      firebase_id: patient?.patient_id,
      relations: familyHistoryData,
    })

    let config = {
      method: 'put',
      url: `${Config.BACKEND_URL}screenings/family`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then((resp) => {
        onManualAddModalHide()
        getFamilyHistory(patient?.patient_id, token)
      })
      .catch((err) => {
        console.log('error when getting saving provider goals data', err)
      })

    onManualAddModalHide()
  }

  // const Hit = (props) => {
  //   let { hit } = props
  //   const handleClick = () => {
  //     eventFire(document.getElementById('clearQuery'), 'click')
  //     setCondition({ id: hit?.code, name: hit?.display })
  //     setConditionQuery('')
  //   }
  //   return (
  //     <div
  //       style={{ width: '100%', cursor: 'pointer' }}
  //       onClick={(e) => {
  //         handleClick(e)
  //       }}
  //     >
  //       {hit?.code} : {hit?.display}
  //     </div>
  //   )
  // }

  const getFamilyHistoryFormatted = () => {
    if (!familyHistoryData) return null
    let toReturn = []
    familyHistoryData.forEach((each) => {
      let conditions = []
      if (each.conditions)
        conditions = each.conditions.map((x, index) => (
          <span key={index.toString()} style={{ width: '80%' }}>
            {x}
            {index === each.conditions.length - 1 ? '' : ', '}
          </span>
        ))
      toReturn.push(
        <div
          key={each.relationship}
          className={Styles.contentRow}
          style={{ padding: '10px 10px' }}
        >
          <p className={Styles.contentRowKey}>{each.relationship}:</p>
          <p style={{ marginLeft: 5, overflowWrap: 'break-word' }}>
            {conditions}
          </p>
        </div>,
      )
    })

    return toReturn
  }

  const renderAddedFamilyHistory = () => {
    let entries = []
    entries = getFamilyHistoryFormatted()
    return (
      <div style={{ width: '100%', margin: 10, position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme }}>Added Family History</h5>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {entries}
        </div>
      </div>
    )
  }

  const onPlusClick = () => {
    if (!selectedFamily?.value) {
      toast.error('Please select family member')
      return
    }
    if (!condition?.name) {
      toast.error('Please select condition')
      return
    }
    let tempFamilyData = [...familyHistoryData]
    let tempFamilyIndex = familyHistoryData.findIndex(
      (data) => data.relationship === selectedFamily.value,
    )
    if (tempFamilyIndex !== -1) {
      tempFamilyData[tempFamilyIndex].conditions.push(condition.name)
      // tempFamilyData[tempFamilyIndex].conditions = removeDup(tempFamilyData[tempFamilyIndex].conditions)
      setFamilyHistoryData(tempFamilyData)
    } else {
      setFamilyHistoryData((oldFam) => [
        ...oldFam,
        { relationship: selectedFamily.value, conditions: [condition.name] },
      ])
    }
    setSelectedFamily('')
    setCondition('')
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div
        style={{
          height: '90%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => onManualAddModalHide()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Family History
        </h4>

        <div className={Styles.addManualRow} style={{ marginTop: 30 }}>
          <div className={Styles.addManualQuestion} style={{ width: '60%' }}>
            {/* <InstantSearch
                indexName={'ICD_10_COMPLETE'}
                searchClient={condnClient}
                onSearchStateChange={({ query }) => setConditionQuery(query)}
              >
                <SearchBox
                  translations={{
                    placeholder: 'Search conditions',
                  }}
                />
                <ClearQueryAndRefinements />
                {conditionQuery.length !== 0 && (
                  <div
                    style={{
                      overflow: 'auto',
                      height: 150,
                      backgroundColor: '#F1F1F1',
                    }}
                  >
                    <RefinementList limit={5} />
                    <Hits hitComponent={(props) => Hit(props)} />
                  </div>
                )}
              </InstantSearch> */}
            <SearchProvider config={esConfigICD10}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'search_term',
                        }}
                        onSelectAutocomplete={(e) => {
                          setCondition({
                            id: e?.code.raw,
                            name: e?.display.raw,
                          })
                          setSearchTerm('')
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search Conditions',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                            {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
            <p className={Styles.addManualQuestion} style={{ width: '50%' }}>
              {condition?.id ? `${condition?.id} - ${condition?.name}` : ''}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              width: '40%',
            }}
          >
            <Select
              options={[
                { label: 'Grandfather', value: 'Grandfather' },
                { label: 'Grandmother', value: 'Grandmother' },
                { label: 'Mother', value: 'Mother' },
                { label: 'Father', value: 'Father' },
                { label: 'Son', value: 'Son' },
                { label: 'Daughter', value: 'Daughter' },
                { label: 'Sister', value: 'Sister' },
                { label: 'Brother', value: 'Brother' },
                { label: 'Aunt', value: 'Aunt' },
                { label: 'Uncle', value: 'Uncle' },
              ]}
              className={Styles.addSelectInput}
              autoBlur={true}
              onChange={(val) => {
                setSelectedFamily(val)
              }}
              placeholder={'Select family member'}
              value={selectedFamily}
              getOptionValue={(option) => option.label}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
            <div style={{ right: 5 }}>
              <FontAwesomeIcon
                onClick={() => onPlusClick()}
                icon={faPlusCircle}
                style={{
                  color: Constants.primaryTheme,
                  fontSize: 35,
                  right: 10,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '70%',
            marginTop: 50,
          }}
        >
          {familyHistoryData.length !== 0 && renderAddedFamilyHistory()}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => onClickSave()}
          className={GlobalStyles.button}
          // disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}
