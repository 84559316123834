import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import GlobalStyles from '../../../pages/styles/global.module.scss'
import Styles from './styles/CommunityPosts.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import firebase from '../../../services/firebase'
import Form from 'react-bootstrap/Form'

import {
  faEllipsisV,
  faShare,
  faEllipsisH,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
  faMicrophoneSlash,
  faPhone,
  faVideoSlash,
  faMicrophone,
  faVideo,
  faEdit,
  faThumbsUp,
  faComment,
  faPaperPlane,
} from '@fortawesome/free-regular-svg-icons'
import { toggleSidebar } from '../../../redux/actions'
import values from '../../../values'
import Config from '../../../config'
import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import Header from '../../../components/Header.js'
import Select from 'react-select'
import PlaceHolderProfileImage from '../../../assets/images/placeholder.png'

const TWILIO_VIDEO = require('twilio-video')

const moment = require('moment')
const axios = require('axios')

const CONFIRM_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    overflow: 'hidden',
  },
}

const ADD_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    overflow: 'hidden',
  },
}

const CommunityPosts = (props) => {
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)
  //   const openNotesModal = useSelector(
  //     (state) => state.genericReducer?.openNotesModal,
  //   )
  const appointmentData = useSelector(
    (state) => state.appointmentReducer?.appointmentData,
  )

  const patient = useSelector((state) => state.patientReducer?.patient)
  const mongoUser = useSelector((state) => state.userReducer.mongoUser)
  const firebaseUser = useSelector((state) => state.userReducer.firebaseUser)

  const [loading, setLoading] = useState(true)

  //COMMENT MODAL DATA
  const [showAddModal, setShowAddModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [newComment, setNewComment] = useState('')
  const [modalLoading, setModalLoading] = useState(false)

  //ADD POST MODAL
  const [addPostModal, setAddPostModal] = useState(false)
  const [newPostTitle, setNewPostTitle] = useState('')
  const [newPostDesc, setNewPostDesc] = useState('')

  //REPORTING POST MODAL
  const [reportPostModal, setReportPostModal] = useState(false)
  const [reportTitle, setReportTitle] = useState('')

  const [reportingComment, setReportingComment] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)

  const [reportingPostId, setReportingPostId] = useState('')
  const [tempPostDetails, setTempPostDetails] = useState({})

  //POSTS
  const [posts, setPosts] = useState([])
  const [usersList, setUsersList] = useState({})
  const [commentUsersList, setCommentUsersList] = useState({})

  const [modalPostDetails, setModalPostDetails] = useState(null)
  const [modalComment, setModalComment] = useState('')
  const [arrivingFromNotification, setArrivingFromNotification] =
    useState(false)
  const [anonymous, setAnonymous] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!patient && !firebaseUser) {
      history.push('/login')
    }

    if (!props?.communityId) {
      history.push('/patient/community')
      console.log('inside')
    }
    setLoading(true)
    getPostsForCommunity()
  }, [])

  const getPostsForCommunity = () => {
    let config = {
      method: 'get',
      url: `${Config.BACKEND_URL}communities/posts`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
      },
      params: {
        community_id: props?.communityId,
      },
    }

    axios(config)
      .then(({ data }) => {
        setPosts(data)
        getPostCreatorsDetails(data)
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when getting posts', err)
      })
  }

  const getCommentUsersDetails = (post) => {
    let users = {}
    if (post?.comments?.length) {
      setModalLoading(true)
      post.comments.forEach((comment, index) => {
        firebase
          .firestore()
          .collection('users')
          .doc(comment.firebase_id)
          .get()
          .then((doc) => {
            let data = doc.data() || {}
            users[data.uid] = data
            let toUpdate = Object.assign({}, commentUsersList, users)
            setCommentUsersList(toUpdate)
          })
          .catch((err) => {
            console.log('error when getting user details', err)
          })

        if (index === post?.comments?.length - 1) {
          setModalLoading(false)
        }
      })
    }
  }

  const getPostCreatorsDetails = (posts) => {
    if (!posts || !posts.length) {
      setLoading(false)
      return
    }

    let users = {}
    posts.forEach((post, index) => {
      firebase
        .firestore()
        .collection('users')
        .doc(post.firebase_id)
        .get()
        .then((doc) => {
          let data = doc.data() || {}
          users[data.uid] = data
          let toUpdate = Object.assign({}, usersList, users)
          setUsersList(toUpdate)
          // if (modalPostDetails) {
          //   console.log('should set modal user info');
          //   // this can be reached when user clicks on community related notification callbacks
          //   let userInfo = users[modalPostDetails.firebase_id];
          //   let modalPostDetails = Object.assign(
          //     {},
          //     modalPostDetails,
          //     userInfo,
          //   );
          //   setModalPostDetails(modalPostDetails);
          // }
        })
        .catch((err) => {
          console.log('error when getting user details', err)
        })

      if (index === posts.length - 1 && !arrivingFromNotification) {
        setLoading(false)
      }
    })
  }

  const onLikeButtonPressed = (post) => {
    setLoading(true)
    let postAuthor = usersList[post?.firebase_id]
    let url
    let id = firebaseUser ? firebaseUser.uid : patient.uid
    if (post.likes[id]) {
      url = `${Config.BACKEND_URL}communities/posts/${post._id}/unlike`
    } else {
      url = `${Config.BACKEND_URL}communities/posts/${post._id}/like`
    }

    let config = {
      method: 'post',
      url: url,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data: {
        firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
        postAuthorId: post?.firebase_id,
        community: props?.community,
        likedAuthorName: `${postAuthor?.firstName}`,
      },
    }

    axios(config)
      .then(({ data }) => {
        setLoading(true)
        getPostsForCommunity()
      })
      .catch((err) => {
        setLoading(false)
        toast.warning('Something went wrong. Please try again after a while')
        // Alert.alert(
        //   'Error',
        //   'Something went wrong. Please try again after a while',
        // );
        console.log('error when setting like/unlike on post', err)
      })
  }

  const onNewCommentAdded = () => {
    if (!newComment || newComment.length === 0) {
      toast.warning('Please enter a comment to post')
      return
    }
    let postAuthor = usersList[modalData?.firebase_id]

    setLoading(true)
    let config = {
      method: 'post',
      url: `${Config.BACKEND_URL}communities/posts/${modalData._id}/comments`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data: {
        firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
        text: newComment,
        community: props?.community,
        postAuthorId: modalData?.firebase_id,
        commentAuthorName: firebaseUser
          ? `${firebaseUser?.firstName}`
          : `${patient?.firstName}`,
        commentAuthorImageUrl: firebaseUser
          ? firebaseUser?.profilePictureURL
          : patient?.profilePictureURL,
      },
    }

    axios(config)
      .then(({ data }) => {
        let id = firebaseUser ? firebaseUser?.uid : patient?.uid
        let tempPostDetails = Object.assign({}, modalData)
        tempPostDetails.comments.push({
          firebase_id: firebaseUser ? firebaseUser?.uid : patient?.uid,
          text: newComment,
          created_at: new Date(),
        })
        let tempUsersList = Object.assign({}, usersList, {
          [id]: firebaseUser ? firebaseUser : patient,
        })
        setUsersList(tempUsersList)
        setLoading(true)
        setNewComment('')
        getPostsForCommunity && getPostsForCommunity()
      })
      .catch((err) => {
        setLoading(false)
        toast.warning('Something went wrong. Please try again after a while')
        console.log('error when setting like/unlike on post', err)
      })
  }

  const reportContent = () => {
    if (reportTitle.length === 0) {
      toast.warning('Please select reason to report the Content')
      return
    }

    setLoading(true)

    let type =
      reportTitle === 'Report inappropriate content'
        ? 'Adult_content'
        : reportTitle === 'Report misinformation'
        ? 'Misinformation'
        : reportTitle === 'Report spam, suspicious or fake'
        ? 'Spam'
        : reportTitle === 'Report harrasment or hateful speech'
        ? 'Harassment'
        : ''

    let data = reportingComment
      ? {
          post_id: modalData._id,
          comment_index: commentIndex,
        }
      : {
          post_id: modalData._id,
        }

    let reportData = {
      type: type,
      priority: 'HIGH',
      subject: 'Reporting Post',
      description: data,
      firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
      name: firebaseUser
        ? `${firebaseUser?.firstName}`
        : `${patient?.firstName}`,
      email: firebaseUser ? firebaseUser.email : patient.email,
      report: true,
    }

    let config = {
      method: 'post',
      url: `${Config.BACKEND_URL}patient/report`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
      data: reportData,
    }

    axios(config)
      .then(() => {
        toast.success('You have successfully reported.')
        setReportPostModal(false)
        setCommentIndex(null)
        setReportingComment(false)
        dispatch(toggleSidebar(false))
        setReportTitle('')
        getPostsForCommunity()
      })
      .catch((err) => {
        setLoading(false)
        console.log('error when submitting support ticket', err)
        toast.warning('Please try again later')
      })
  }

  const onNewPostButtonPressed = () => {
    if (!newPostTitle || newPostTitle.length === 0) {
      toast.warning('Please enter a title for the post')
      return
    }

    if (!newPostDesc || newPostDesc.length === 0) {
      toast.warning('Please enter the details for the post')
      return
    }

    setLoading(true)

    let config

    // it is present if the post already exists to update
    // if (tempPostDetails?._id) {
    //   config = {
    //     method: 'put',
    //     url: `${PROD_BACKEND_URL}communities/posts/${tempPostDetails?._id}`,
    //     headers: {
    //       Authorization: `JWT ${token}`,
    //     },
    //     data: {
    //       firebase_id: user,
    //       title: newPostTitle,
    //       description: newPostDesc,
    //     },
    //   }
    // } else {
    // used to post a new post
    config = {
      method: 'post',
      url: `${Config.BACKEND_URL}communities/posts`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data: {
        firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
        title: newPostTitle,
        description: newPostDesc,
        community_id: props?.community?._id,
        post_author_image_url: firebaseUser
          ? firebaseUser?.profilePictureURL
          : patient?.profilePictureURL,
        post_author_name: firebaseUser
          ? `${firebaseUser?.firstName}`
          : `${patient?.firstName}`,
        ...(anonymous && {
          post_anonymous: anonymous,
        }), // this field is added only if the value is true (remove)
      },
    }
    // }

    axios(config)
      .then(({ data }) => {
        setAddPostModal(false)
        setNewPostDesc('')
        setNewPostTitle('')
        setAnonymous(false)
        getPostsForCommunity()
      })
      .catch((err) => {
        setLoading(false)
        toast.warning('Something went wrong. Please try again after a while')

        console.log('error when adding new post', err)
      })
  }

  const renderIndividualPost = (post) => {
    let postAuthor = usersList[post.firebase_id]
    let postAuthorImageUrl =
      postAuthor && postAuthor.profilePictureURL && !post.post_anonymous
        ? postAuthor.profilePictureURL
        : PlaceHolderProfileImage

    let id = firebaseUser ? firebaseUser.uid : patient.uid

    return (
      <div className={Styles.listWrapper} style={{ marginTop: '2%' }}>
        <div className={Styles.studyEntryRow}>
          <img
            src={postAuthorImageUrl}
            className={Styles.postImage}
            //   style={{
            //     height: 150,
            //     width: 150,
            //     objectFit: 'cover',
            //     borderRadius: '50%',
            //   }}
            alt="No Image"
          />

          <p
            style={{
              color: values.primaryTheme,
              marginTop: 10,
              //   fontWeight: 'bold',
              fontSize: 'larger',
              margin: '0px 2%',
            }}
          >
            {post.post_anonymous
              ? 'Anonymous'
              : (postAuthor?.firstName || '')}
            <br />
            <span style={{ color: 'gray', fontSize: 'medium' }}>
              {moment(post.created_at).fromNow()}
            </span>
          </p>
          <FontAwesomeIcon
            onClick={() => {
              console.log('report post')
              setModalData(post)
              setReportPostModal(true)
              setCommentIndex(null)
              setReportingComment(false)
              dispatch(toggleSidebar(true))
              //   setShowAddModal(true)
              // setModalError('')
            }}
            icon={faEllipsisV}
            style={{
              //   color: values.primaryTheme,
              color: '#696969',
              fontSize: 25,
              //   right: 14,
              cursor: 'pointer',
              //   position: 'absolute',
              // top: 0,
              //   right: '2vw',
              marginLeft: 'auto',
            }}
          />
        </div>
        <div className={Styles.studyEntryRow}>
          <p
            style={{
              color: values.primaryTheme,
              marginTop: 10,
              fontWeight: 'bold',
              fontSize: 'larger',
            }}
          >
            {post.title}
            <br />
            <span
              style={{
                color: 'gray',
                fontSize: 'medium',
                fontWeight: 'normal',
              }}
            >
              {post.description.trim()}
            </span>
          </p>
        </div>
        <div
          className={Styles.studyEntryRow}
          style={{ justifyContent: 'space-around' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon
              onClick={() => {
                console.log('post liked')
                onLikeButtonPressed(post)
                // setShowAddModal(true)
                // setModalError('')
              }}
              icon={faThumbsUp}
              style={{
                //   color: values.primaryTheme,
                color: post.likes[id] ? values.primaryTheme : '#696969',
                fontSize: 25,
                //   right: 14,
                cursor: 'pointer',
                //   position: 'absolute',
                // top: 0,
                //   right: '2vw',
                //   marginLeft: 'auto',
              }}
            />{' '}
            {Object.keys(post?.likes || {}).length}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon
              onClick={() => {
                console.log('post comment')
                getCommentUsersDetails(post)
                setModalData(post)
                setShowAddModal(true)
                dispatch(toggleSidebar(true))
                // setShowAddModal(true)
                // setModalError('')
              }}
              icon={faComment}
              style={{
                //   color: values.primaryTheme,
                color: '#696969',
                fontSize: 25,
                //   right: 14,
                cursor: 'pointer',
                //   position: 'absolute',
                // top: 0,
                //   right: '2vw',
                //   marginLeft: 'auto',
              }}
            />{' '}
            {post?.comments?.length}
          </div>
          {/* <FontAwesomeIcon
            onClick={() => {
              console.log('report comment')
              let deeplinkUrl = `https://ailahealth.app.link/comm-post?title=${props?.community?.title.replace(
                ' ',
                '+',
              )}&comm_id=${props?.community?._id}&post_id=${post?._id}`
              navigator.clipboard.writeText(deeplinkUrl)
              toast.success('Link copied')
              //   setModalError('')
            }}
            icon={faShare}
            style={{
              //   color: values.primaryTheme,
              color: '#696969',
              fontSize: 25,
              //   right: 14,
              cursor: 'pointer',
              //   position: 'absolute',
              // top: 0,
              //   right: '2vw',
              //   marginLeft: 'auto',
            }}
          /> */}
        </div>
      </div>
    )
  }

  const renderReportPost = () => {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            setReportPostModal(false)
            dispatch(toggleSidebar(false))
            setCommentIndex(null)
            setReportingComment(false)
          }}
        >
          X
        </p>
        <h4 style={{ color: values.primaryThemeDark, textAlign: 'center' }}>
          Report Post
        </h4>

        <div
          style={{
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            height: '80%',
            padding: '2%',
            fontSize: 'larger',
          }}
        >
          {[
            'Report inappropriate content',
            'Report misinformation',
            'Report spam, suspicious or fake',
            'Report harrasment or hateful speech',
          ].map((type) => (
            <Form.Check
              type={'radio'}
              label={type}
              value={type}
              onClick={(val) => {
                setReportTitle(val.target.value)
              }}
              style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
              checked={reportTitle === type}
              classes={Styles.radioButton}
            />
          ))}
        </div>

        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            position: 'absolute',
            bottom: '5%',
          }}
        >
          <Button
            onClick={() => {
              console.log('report pressed')
              reportContent()
              //   onNewPostButtonPressed()
              //   addUserToCommunity()
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '1vh',
              width: 200,
            }}
            variant="primary"
          >
            {'Report'}
          </Button>
        </div>
      </div>
    )
  }

  const renderAddPost = () => {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            setAddPostModal(false)
            dispatch(toggleSidebar(false))
          }}
        >
          X
        </p>
        <h4 style={{ color: values.primaryThemeDark, textAlign: 'center' }}>
          Add New Post
        </h4>

        {/* <p
          style={{
            fontSize: 18,
            margin: 'auto',
            color: values.primaryTheme,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
          }}
        >
          {`comm ?`}
        </p> */}
        <div
          style={{
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            height: '80%',
            padding: '2%',
          }}
        >
          <input
            placeholder={'Enter title for the post...'}
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            onChange={(e) => {
              setNewPostTitle(e.target.value)
            }}
          />

          <textarea
            placeholder={'Enter the details...'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '100%',
              // height: '40px',
            }}
            rows="5"
            type="text"
            value={newPostDesc}
            onChange={(e) => {
              setNewPostDesc(e.target.value)
            }}
          />

          <label class={Styles.checkContainer}>
            Post Anonymously
            <input
              type="checkbox"
              checked={anonymous}
              onClick={() => {
                console.log('clocked anonymous')
                setAnonymous(!anonymous)
              }}
            />
            <span class={Styles.checkmark}></span>
          </label>
        </div>

        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            position: 'absolute',
            bottom: '5%',
          }}
        >
          <Button
            onClick={() => {
              console.log('add post clicked')
              onNewPostButtonPressed()
              //   addUserToCommunity()
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '1vh',
              width: 200,
            }}
            variant="primary"
          >
            {'Add Post'}
          </Button>
        </div>
      </div>
    )
  }

  const renderCommentModal = () => {
    let post = modalData

    let postAuthor = usersList[post.firebase_id]
    let postAuthorImageUrl =
      postAuthor && postAuthor.profilePictureURL && !post.post_anonymous
        ? postAuthor.profilePictureURL
        : PlaceHolderProfileImage

    let id = firebaseUser ? firebaseUser.uid : patient.uid

    let commentsElement = post?.comments?.map((comment, index) => {
      let commentAuthor = commentUsersList[comment.firebase_id]
      let commentAuthorImage =
        commentAuthor &&
        commentAuthor.profilePictureURL &&
        !comment?.post_anonymous
          ? commentAuthor.profilePictureURL
          : PlaceHolderProfileImage
      return (
        <div
          style={{
            borderBottom: '1px solid #DEDEDE',
            width: '75%',
            padding: '2% 0%',
          }}
        >
          <div className={Styles.studyEntryRow}>
            <img
              src={commentAuthorImage}
              className={Styles.postImage}
              //   style={{
              //     height: 150,
              //     width: 150,
              //     objectFit: 'cover',
              //     borderRadius: '50%',
              //   }}
              alt="No Image"
            />

            <p
              style={{
                color: values.primaryTheme,
                marginTop: 10,
                //   fontWeight: 'bold',
                fontSize: 'larger',
                margin: '0px 2%',
              }}
            >
              {comment?.comment_anonymous
                ? 'Anonymous'
                : (commentAuthor?.firstName || '')}
              <span style={{ color: 'gray', fontSize: 'small' }}>
                · {moment(comment?.created_at).fromNow()}
              </span>
              <br />
              <span style={{ color: 'gray', fontSize: 'medium' }}>
                {comment?.text}
              </span>
            </p>
            <FontAwesomeIcon
              onClick={() => {
                console.log('report comment')
                setReportPostModal(true)
                setReportingComment(true)
                setCommentIndex(index)
                dispatch(toggleSidebar(true))
                // setShowAddModal(true)
                // setModalError('')
              }}
              icon={faEllipsisH}
              style={{
                //   color: values.primaryTheme,
                color: '#696969',
                fontSize: 25,
                //   right: 14,
                cursor: 'pointer',
                //   position: 'absolute',
                // top: 0,
                //   right: '2vw',
                marginLeft: 'auto',
              }}
            />
          </div>
          {/* <div className={Styles.studyEntryRow}>
            <p
              style={{
                marginTop: 10,
                color: 'gray',
                fontSize: 'medium',
                fontWeight: 'normal',
              }}
            >
              {comment?.text}
              <br />
            </p>
          </div> */}
        </div>
      )
    })

    return (
      <div
        className={Styles.hideScrollBar}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '15%',
          //   position: 'relative',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => {
            setShowAddModal(false)
            setModalData(null)
            dispatch(toggleSidebar(false))
          }}
        >
          X
        </p>
        <h4 style={{ color: values.primaryThemeDark, textAlign: 'center' }}>
          Post
        </h4>
        <div className={Styles.listWrapper} style={{ marginTop: '2%' }}>
          <div className={Styles.studyEntryRow}>
            <img
              src={postAuthorImageUrl}
              className={Styles.postImage}
              //   style={{
              //     height: 150,
              //     width: 150,
              //     objectFit: 'cover',
              //     borderRadius: '50%',
              //   }}
              alt="No Image"
            />

            <p
              style={{
                color: values.primaryTheme,
                marginTop: 10,
                //   fontWeight: 'bold',
                fontSize: 'larger',
                margin: '0px 2%',
              }}
            >
              {post.post_anonymous
                ? 'Anonymous'
                : (postAuthor?.firstName || '')}
              <br />
              <span style={{ color: 'gray', fontSize: 'medium' }}>
                {moment(post.created_at).fromNow()}
              </span>
            </p>
            <FontAwesomeIcon
              onClick={() => {
                console.log('report post')
                setModalData(post)
                setReportPostModal(true)
                setCommentIndex(null)
                setReportingComment(false)
                dispatch(toggleSidebar(true))
              }}
              icon={faEllipsisV}
              style={{
                //   color: values.primaryTheme,
                color: '#696969',
                fontSize: 25,
                //   right: 14,
                cursor: 'pointer',
                //   position: 'absolute',
                // top: 0,
                //   right: '2vw',
                marginLeft: 'auto',
              }}
            />
          </div>
          <div className={Styles.studyEntryRow}>
            <p
              style={{
                color: values.primaryTheme,
                marginTop: 10,
                fontWeight: 'bold',
                fontSize: 'larger',
              }}
            >
              {post.title}
              <br />
              <span
                style={{
                  color: 'gray',
                  fontSize: 'medium',
                  fontWeight: 'normal',
                }}
              >
                {post.description.trim()}
              </span>
            </p>
          </div>
          <div
            className={Styles.studyEntryRow}
            style={{ justifyContent: 'space-around' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon
                onClick={() => {
                  console.log('post liked')
                  onLikeButtonPressed(post)
                  // setShowAddModal(true)
                  // setModalError('')
                }}
                icon={faThumbsUp}
                style={{
                  //   color: values.primaryTheme,
                  color: post.likes[id] ? values.primaryTheme : '#696969',
                  // color: '#696969',
                  fontSize: 25,
                  //   right: 14,
                  cursor: 'pointer',
                  //   position: 'absolute',
                  // top: 0,
                  //   right: '2vw',
                  //   marginLeft: 'auto',
                }}
              />{' '}
              {Object.keys(post?.likes || {}).length}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon
                onClick={() => {
                  console.log('post comment')
                  // setShowAddModal(true)
                  // setModalError('')
                }}
                icon={faComment}
                style={{
                  //   color: values.primaryTheme,
                  color: '#696969',
                  fontSize: 25,
                  //   right: 14,
                  cursor: 'pointer',
                  //   position: 'absolute',
                  // top: 0,
                  //   right: '2vw',
                  //   marginLeft: 'auto',
                }}
              />{' '}
              {post?.comments?.length}
            </div>

            {/* <FontAwesomeIcon
              onClick={() => {
                console.log('share post')
                let deeplinkUrl = `https://ailahealth.app.link/comm-post?title=${props?.community?.title.replace(
                  ' ',
                  '+',
                )}&comm_id=${props?.community?._id}&post_id=${post?._id}`
                navigator.clipboard.writeText(deeplinkUrl)
                toast.success('Link copied')

                // setModalError('')
              }}
              icon={faShare}
              style={{
                //   color: values.primaryTheme,
                color: '#696969',
                fontSize: 25,
                //   right: 14,
                cursor: 'pointer',
                //   position: 'absolute',
                // top: 0,
                //   right: '2vw',
                //   marginLeft: 'auto',
              }}
            /> */}
          </div>
        </div>
        <div
          className={Styles.studyEntryRow}
          style={{
            color: 'gray',
            // borderTop: '1px solid #DEDEDE',
            borderBottom: '1px solid #DEDEDE',
            width: '75%',
            padding: '2%',
          }}
        >
          {' '}
          Comments ({post?.comments?.length})
        </div>
        {commentsElement}
      </div>
    )
  }
  const renderPosts = () => {
    if (!posts || posts.length === 0) {
      return (
        <div style={{ marginTop: '4%', width: '100%' }}>
          There are no posts yet in this community. Create one by clicking on
          the Add Post Button
        </div>
      )
    }
    let postsElements = posts.map((post) => renderIndividualPost(post))
    return (
      <div
        style={{
          marginTop: '4%',
          width: '100%',
          height: '75vh',
          overflowY: 'auto',
        }}
      >
        {postsElements}
      </div>
    )
  }

  const renderLoader = () => (
    <div
      className={GlobalStyles.container}
      style={{ height: '100vh', width: '100%' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '100%',
          height: '100%',
        }}
      >
        <div className={GlobalStyles.loader} />
      </div>
    </div>
  )

  const renderContent = () => (
    <div style={{ display: 'flex' }}>
      {/* <Header header={props.match.params.community} /> */}

      <Button
        onClick={() => {
          console.log('add post')
          setAddPostModal(true)
          dispatch(toggleSidebar(true))
          //   setShowAddModal(true)
          // setModalError('')
        }}
        className={GlobalStyles.button}
        disabled={loading}
        style={{
          // width: 200,
          // right: 0,
          // position: 'absolute',
          // justifyContent: 'right',
          width: '10vw',
          right: '4%',
          cursor: 'pointer',
          position: 'absolute',
          right: '2vw',
          // margin: '-4% 0%',
        }}
        variant="primary"
      >
        Add Post
      </Button>
      {/* <FontAwesomeIcon
        onClick={() => {
          console.log('add post')
          setAddPostModal(true)
          dispatch(toggleSidebar(true))
          //   setShowAddModal(true)
          // setModalError('')
        }}
        icon={faPlusCircle}
        style={{
          color: values.primaryTheme,
          fontSize: 25,
          right: '4%',
          cursor: 'pointer',
          position: 'absolute',
          // top: 0,
          right: '2vw',
        }}
      /> */}
      {loading ? renderLoader() : renderPosts()}
      {showAddModal && modalData && (
        <Modal
          ariaHideApp={false}
          isOpen={showAddModal}
          onRequestClose={() => {
            setShowAddModal(false)
            dispatch(toggleSidebar(false))
          }}
          style={CONFIRM_MODAL_STYLES}
          contentLabel="Modal"
        >
          {modalLoading ? renderLoader() : renderCommentModal()}
          {/* {renderCommentModal()} */}
          <div className={Styles.bottomInputWrapper}>
            {/* <input
            type="file"
            id="file"
            ref={this.fileUploaderRef}
            style={{ display: 'none' }}
          /> */}
            <input
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter') this.onSubmitPressed()
              // }}
              style={{
                position: 'relative',
                top: '20%',
                flexGrow: 4,
                borderRadius: 4,
              }}
              placeholder="Add a comment..."
              className={Styles.textInput}
              type="text"
              value={newComment}
              onChange={(e) => {
                setNewComment(e.target.value)
              }}
            />
            <FontAwesomeIcon
              onClick={() => {
                console.log('post comment')
                onNewCommentAdded()
                // setShowAddModal(true)
                // setModalError('')
              }}
              icon={faPaperPlane}
              style={{
                color: values.primaryTheme,
                // color: '#696969',
                fontSize: 25,
                //   right: 14,
                cursor: 'pointer',
                padding: '2%',
                //   position: 'absolute',
                // top: 0,
                //   right: '2vw',
                //   marginLeft: 'auto',
              }}
            />
          </div>
        </Modal>
      )}

      {addPostModal && (
        <Modal
          ariaHideApp={false}
          isOpen={addPostModal}
          onRequestClose={() => {
            setAddPostModal(false)
            dispatch(toggleSidebar(false))
          }}
          style={ADD_MODAL_STYLES}
          contentLabel="Modal"
        >
          {modalLoading ? renderLoader() : renderAddPost()}
        </Modal>
      )}

      {reportPostModal && (
        <Modal
          ariaHideApp={false}
          isOpen={reportPostModal}
          onRequestClose={() => {
            setReportPostModal(false)
            setCommentIndex(null)
            setReportingComment(false)
            dispatch(toggleSidebar(false))
          }}
          style={ADD_MODAL_STYLES}
          contentLabel="Modal"
        >
          {modalLoading ? renderLoader() : renderReportPost()}
        </Modal>
      )}
    </div>
  )

  //   if (loading) return renderLoader()
  return renderContent()
}

export default CommunityPosts
