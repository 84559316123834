import React, { useEffect, useState } from 'react'
import Constants from '../../../values.js'
import Styles from './styles/Careplan.module.scss'
import Config from '../../../config'
import GlobalStyles from '../../styles/global.module.scss'
import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'
import {useSelector} from "react-redux";
import moment from 'moment'
import axios from 'axios'

export default function AppointmentList() {
  const token = useSelector(state => state.authReducer.token)
	const patient = useSelector(state => state.patientReducer.patient)
  const [loading, setLoading] = useState(false)
  const [appointmentList, setAppointmentList] = useState([])

  const getAppointmentList = () => {
    setLoading(true)
    let config = {
      method: 'get',
      url: Config.BACKEND_URL + 'patient/appointments',
      headers: {
        Authorization: `JWT ${token}`,
        x_patient_id: patient?.patient_id
      }
    }
    axios(config)
    .then(({data}) => {
      let appData = data
      setLoading(false)
      console.log('appData', appData)
      if(appData.length){
        appData.sort((a, b) => {
          return a.start - b.start;
        })
        console.log('appData: ',appData)
        setAppointmentList(appData?.reverse())
      }
    })
    .catch((err) => {
      setLoading(false)
      console.log('error when fetching appointmentlist: ', err)
    })
  } 

  useEffect(() => {
    getAppointmentList()
  }, [])

  const renderAppointmentList = (appointmentData, index) => {
    return (
      <div
        key={index}
        className={Styles.contentRow}
        style={{ flexDirection: "column", alignItems: "flex-start" }}
      >
        <div
          style={{ flexDirection: 'row', alignItems: 'center', display: 'flex'}}
          >
          <img
            src={appointmentData?.image_url ? appointmentData?.image_url : DocPlaceHolder}
            height={50}
            width={50}
            style={{borderRadius: '50%', margin: 10}}
          />
          <h6 style={{ color: Constants.primaryTheme }}>
            {appointmentData?.first_name} {appointmentData?.last_name} {appointmentData?.speciality ? (`(${appointmentData?.speciality})` || '') : ''} {', '}
            {moment.unix(appointmentData?.start/1000).format('MMM Do YYYY, HH:mm A')}
          </h6>
        </div>
        {appointmentData?.follow_up?.no_show && <h6 style={{color:'red', alignSelf:'flex-end'}}>No Show</h6>}
    </div>
    )
  }

  return (
    <div style={{ width: '100%', marginTop: 40 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <h5 style={{ color: Constants.primaryTheme }}>All Appointments</h5>
      </div>
      <div
        className={Styles.contentBackground}
        style={{ height: 500, overflowY: 'scroll' }}
      >
        { (appointmentList.length !== 0)
          ? appointmentList.map((appointmentData, index) => 
            renderAppointmentList(appointmentData, index)
          )
          : (
            <p style={{textAlign: 'center', marginTop: 50}}>No upcoming appointment</p>
          )
        }
      </div>
    </div>
  )
}
