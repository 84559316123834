import React, { Component } from 'react'
import GlobalStyles from './styles/global.module.scss'
import {Link} from 'react-router-dom'

export default class NotFoundPage extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        <h2>Unknown URL</h2>

       <Link to='/login'>
        <p>Go Home</p>
       </Link>

      </div>
    )
  }
}
