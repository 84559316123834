module.exports = {
    cptList: [
        {
            "CPTCode": 99202,
            "CodeDescription": "New Patient - Outpatient visit involving evaluation and management. The visit involves straightforward medical decision making and/or the provider spends 15–29 minutes of total time on the encounter on a single date.",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99203,
            "CodeDescription": "New Patient - Outpatient visit involving evaluation and management. The visit involves a low level of medical decision making and/or the provider spends 30–44 minutes of total time on the encounter on a single date.",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99204,
            "CodeDescription": "New Patient - Outpatient visit involving evaluation and management. The visit involves a moderate level of medical decision making and/or the provider spends 45–59 minutes of total time on the encounter on a single date.",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99205,
            "CodeDescription": "New Patient - Outpatient visit involving evaluation and management. The visit involves a high level of medical decision making and/or the provider spends 60–74 minutes of total time on the encounter on a single date.",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99211,
            "CodeDescription": "Established outpatient visit for the evaluation and management of an established patient may not require the presence of a physician or other qualified health care professional.  the presenting problem or problems should be minimal. Typically, 5 minutes are spent performing or supervising services such as blood pressure checks.",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99212,
            "CodeDescription": "Established outpatient visit is for the evaluation and management of an established patient, and requires at least two of these three key components be present in the medical record: A problem focused history, A problem focused examination; Straightforward medical decision making",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99213,
            "CodeDescription": "Establisheoutpatient visit requires at least two\nout of these three key components to be present in the medical record: An expanded problem focused history, An expanded problem focused examination, Medical decision making of low complexity. Usually, the presenting problem or problems are of low to moderate severity",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99214,
            "CodeDescription": "Established outpatient visit; Remember that details are actually the particulars, or symptoms individually, as they relate to the entire clinical picture. Usually the presenting problems are of moderate to high severity. detailed assessment requires at least two out of these three key components to be listed in the medical record: A detailed history, A detailed examination, Medical decision making of moderate complexity; 30 -39 Minutes",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99215,
            "CodeDescription": "Established outpatient visit; 40-54 Minutes. This code requires at least two out of these three components o A comprehensive history o A detailed examination o Medical decision making of high complexity",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99416,
            "CodeDescription": "Established Patient-Prolonged clinical staff services during an E/M services, each additional 30 min.",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99417,
            "CodeDescription": "Established Patient- Prolonged E/M time with or without direct patient contact beyond the required time of the primary services when the primary service has been selected using total time, each 15 minutes of total time; (List separately in addition to codes 99205, 99215)",
            "CodeType": "Visit Codes",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": "G0438",
            "CodeDescription": "New Patient - Includes personalized prevention plan of service, initial visit; require a list of current medical providers, a health risk assessment, and an assessment of cognitive function. The content of the health risk assessment is usually in the screening for the Welcome to Medicare visit.",
            "CodeType": "Preventive Care",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": "G0439",
            "CodeDescription": "Established Patient- Includes personalized prevention plan of service, subsequent visit: require a list of current medical providers, a health risk assessment, and an assessment of cognitive function. The content of the health risk assessment is usually in the screening for the Welcome to Medicare visit.",
            "CodeType": "Preventive Care",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99385,
            "CodeDescription": "The provider performs a well–patient visit for a patient who is between the ages of 18 and 39.",
            "CodeType": "Preventive Care",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99386,
            "CodeDescription": "The provider performs a well–patient visit for a patient who is between the ages of 40 and 64.",
            "CodeType": "Preventive Care",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99387,
            "CodeDescription": "The provider performs a well–patient visit for a patient who is 65 years or older.",
            "CodeType": "Preventive Care",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99394,
            "CodeDescription": "The provider performs an established well–patient visit for an adolescent who is between the ages of 12 and 17.",
            "CodeType": "Preventive Care",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99395,
            "CodeDescription": "The provider performs an established well–patient visit for a patient who is between the ages of 18 and 39.",
            "CodeType": "Preventive Care",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99396,
            "CodeDescription": "The provider performs an established well–patient visit for a patient who is between the ages of 40 and 64.",
            "CodeType": "Preventive Care",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99446,
            "CodeDescription": "Interprofessional telephone/Internet/electronic health assessment and management service provided by a consultative physician, including a verbal and written report to the patient’s treating/requesting physician or other qualified health care professional; 5-10 minutes of medical consultative discussion and review",
            "CodeType": "e-Consult",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99447,
            "CodeDescription": "Interprofessional telephone/Internet/electronic health assessment and management service provided by a consultative physician, including a verbal and written report to the patient’s treating/requesting physician or other qualified health care professional; 11-20 minutes of medical consultative discussion and review",
            "CodeType": "e-Consult",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99448,
            "CodeDescription": "Interprofessional telephone/Internet/electronic health assessment and management service provided by a consultative physician, including a verbal and written report to the patient’s treating/requesting physician or other qualified health care professional; 21-30 minutes of medical consultative discussion and review",
            "CodeType": "e-Consult",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99449,
            "CodeDescription": "Interprofessional telephone/Internet/electronic health assessment and management service provided by a consultative physician, including a verbal and written report to the patient’s treating/requesting physician or other qualified health care professional; 31 minutes of medical consultative discussion and review",
            "CodeType": "e-Consult",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99451,
            "CodeDescription": "Interprofessional telephone/Internet/electronic health assessment and management service provided by a consultative physician, including a verbal and written report to the patient’s treating/requesting physician or other qualified health care professional; 5-10 minutes of medical consultative discussion and review",
            "CodeType": "e-Consult",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99452,
            "CodeDescription": "Interprofessional telephone/Internet/electronic health record referral service(s) provided by a treating/ requesting physician or other qualified health care professional, 30 minutes",
            "CodeType": "e-Consult",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99421,
            "CodeDescription": "Online digital evaluation and management service, for an established patient, for up to 7 days, cumulative time during the 7 days; Asynchronous 5–10 minutes",
            "CodeType": "Communications",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99422,
            "CodeDescription": "Online digital evaluation and management service, for an established patient, for up to 7 days cumulative time during the 7 days; Aysnchronous 11– 20 minutes",
            "CodeType": "Communications",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 99423,
            "CodeDescription": "Online digital evaluation and management service, for an established patient, for up to 7 days, cumulative time during the 7 days; Asynchronous 21 or more minutes.",
            "CodeType": "Communications",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": "G2012",
            "CodeDescription": "Brief communication technology-based service, e.g. virtual check-in, by a physician or other qualified health care professional who can report evaluation and management services, provided to an established patient, not originating from a related e/m service provided within the previous 7 days nor leading to an e/m service or procedure within the next 24 hours or soonest available appointment; 5-10 minutes of medical discussion",
            "CodeType": "Communications",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 98970,
            "CodeDescription": "Online digital evaluation and management service, for an established patient, for up to 7 days, cumulative time during the 7 days; Asynchronous 5–10 minutes",
            "CodeType": "Communications",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98971,
            "CodeDescription": "Online digital evaluation and management service, for an established patient, for up to 7 days cumulative time during the 7 days; Aysnchronous 11– 20 minutes",
            "CodeType": "Communications",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98972,
            "CodeDescription": "Online digital evaluation and management service, for an established patient, for up to 7 days, cumulative time during the 7 days; Asynchronous 21 or more minutes.",
            "CodeType": "Communications",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98966,
            "CodeDescription": "Telephone assessment and management services provided by a qalified nonphysician healthcare professional to an established patient, parent, or quardian not originating from a related assessment and management service provided within the previous 7 days nor leading to an assessment and management service or procedure within the next 24 hours or soonest available appointment; 5-10 minutes of medical discussion",
            "CodeType": "Communications",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98967,
            "CodeDescription": "Telephone assessment and management services provided by a qalified nonphysician healthcare professional to an established patient, parent, or quardian not originating from a related assessment and management service provided within the previous 7 days nor leading to an assessment and management service or procedure within the next 24 hours or soonest available appointment; 11-20 minutes of Telephone medical discussion",
            "CodeType": "Communications",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98968,
            "CodeDescription": "Telephone assessment and management services provided by a qalified nonphysician healthcare professional to an established patient, parent, or quardian not originating from a related assessment and management service provided within the previous 7 days nor leading to an assessment and management service or procedure within the next 24 hours or soonest available appointment; 21-30 minutes of Telephone medical discussion",
            "CodeType": "Communications",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 90791,
            "CodeDescription": "New Patient Psychiatric Diagnostic Interview; 16-90 Minutes",
            "CodeType": "Mental Health",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 90832,
            "CodeDescription": "Individual psychotherapy services rendered for 30 minutes",
            "CodeType": "Mental Health",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 90834,
            "CodeDescription": "Individual psychotherapy for 45 minutes in an outpatient setting",
            "CodeType": "Mental Health",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 90837,
            "CodeDescription": "Individual psychotherapy for 60 minutes in an outpatient setting",
            "CodeType": "Mental Health",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 96127,
            "CodeDescription": "Brief emotional/behavioral assessment (e.g., depression inventory, attentiondeficit/hyperactivity disorder scale), with scoring and documentation, per standardized instrument",
            "CodeType": "Mental Health",
            "PerformedBy": "MD/QHP"
        },
        {
            "CPTCode": 97802,
            "CodeDescription": "Initial nutrition assessment and intervention, individual, face-to-face with the patient, each 15 minutes (For 30 Minutes, select 2 Units of 97803 For 60 Minutes, select 4 Units of Code 97803)",
            "CodeType": "Nutrition",
            "PerformedBy": "Nutritionist/ Licensed Dietician"
        },
        {
            "CPTCode": 97803,
            "CodeDescription": "Follow up nutrition visit, individual, face-to-face with the patient, each 15 minutes (For 30 Minutes add 2 Units of 97803 For 60 Minutes, add 4 Units of Code 97803)",
            "CodeType": "Nutrition",
            "PerformedBy": "Nutritionist/ Licensed Dietician"
        },
        {
            "CPTCode": 97804,
            "CodeDescription": "For a group nutritioon visit (2 or more individuals), 30 minutes per unit",
            "CodeType": "Nutrition",
            "PerformedBy": "Nutritionist/ Licensed Dietician"
        },
        {
            "CPTCode": 97161,
            "CodeDescription": "Initial Consultation-Per participant, 30-minute 1:1 initial consultation with a licensed physical therapist",
            "CodeType": "Physical Therapy",
            "PerformedBy": ""
        },
        {
            "CPTCode": 97162,
            "CodeDescription": "Medicare payment policy requires Functional Reporting, using G-codes and severity modifiers, when an evaluative procedure is furnished and billed. This notification adds the eight new codes for PT and OT evaluations and re-evaluations – 97161, 97162, 97163, 97164, 97165, 97166, 97167, and 97168 – to the procedure code list of evaluative procedures that necessitate Functional Reporting. A severity modifier (CH – CN) is required to accompany each functional G-code (G8978-G8999, G9158-9176, and G9186) on the same line of service.",
            "CodeType": "Physical Therapy",
            "PerformedBy": ""
        },
        {
            "CPTCode": 97150,
            "CodeDescription": "Can be used to report group settings, but not to exceed a maximum number of 6 individuals",
            "CodeType": "Physical Therapy",
            "PerformedBy": ""
        },
        {
            "CPTCode": "0591T",
            "CodeDescription": "Health and Well-being Coaching face-to-face; individual, initial assessment",
            "CodeType": "Coaching",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": "0592T",
            "CodeDescription": "Health and Well-being Coaching individual, follow-up session, at least 30 minutes",
            "CodeType": "Coaching",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": "0593T",
            "CodeDescription": "Health Coaching Group (two or more individuals), at least 30 minutes",
            "CodeType": "Coaching",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98962,
            "CodeDescription": "Self Management Training, Education and training fo patient self- management by a qualified, non physician health care provessional using a standardized curriculum, face-to-face with the patient (could be caregiver/family) each 30 min. 5-8 patients",
            "CodeType": "Coaching",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98970,
            "CodeDescription": "Qualified non-physician health care professional online digital assessment and management, for an established patient, for up to 7 days, cumulative time during the 7 days, 5-10 minutes",
            "CodeType": "Coaching",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98971,
            "CodeDescription": "Qualified non-physician health care professional online digital assessment and management, for an established patient, for up to 7 days, cumulative time during the 7 days, 11-20 minutes",
            "CodeType": "Coaching",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98975,
            "CodeDescription": "Initial set-up and patient education on use of equipment for remote monitoring of therapy adherence",
            "CodeType": "RPM",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98976,
            "CodeDescription": "Supply of device with scheduled recording and/or programmed alert) transmission for remote monitoring of respiratory system, each 30 days",
            "CodeType": "RPM",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98977,
            "CodeDescription": "Supply of device with scheduled recording and/or programmed alert) transmission for remote monitoring of musculoskeletal system, each 30 days",
            "CodeType": "RPM",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98980,
            "CodeDescription": "Remote therapeutic monitoring treatment management services by health care professional time in calendar month requiring at least one interactive communication with the patient or caregiver; first 20 minutes",
            "CodeType": "RPM",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
        {
            "CPTCode": 98981,
            "CodeDescription": "Remote therapeutic monitoring treatment management services by health care professional time in calendar month requiring at least one interactive communication with the patient or caregiver; each additional 20 minutes",
            "CodeType": "RPM",
            "PerformedBy": "Non-Physician HCP/ Coach"
        },
    ]
}