import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import GlobalStyles from '../pages/styles/global.module.scss'
import Constants from '../values'
import Styles from './styles/styles.module.scss'
import Config from '../config'

const connector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'loinc-codes',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfig = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: connector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      long_common_name: { raw: {} },
      shortname: { raw: {} },
    },
  },
}

const MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: '90%',
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}

const LoincSearchModal = (props) => {
  const [selectedLabs, setSelectedLabs] = useState([])
  useEffect(() => {
    setSelectedLabs(props?.selectedLabs || [])
  }, [props])

  // TODO: use this function to add a lab
  const onLabAdded = (entry) => {
    let temp = [...selectedLabs]
    let present = false
    temp.forEach((each) => {
      if (each.loinc_num === entry.loinc_num) present = true
    })

    if (!present) {
      temp.push(entry)
      setSelectedLabs(temp)
    }
  }

  const onLabDeleted = (index) => {
    let temp = [...selectedLabs]
    temp.splice(index, 1)
    setSelectedLabs(temp)
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.showModal}
      // onAfterOpen={afterOpenModal}
      onRequestClose={props.hideModal}
      style={MODAL_STYLES}
      contentLabel="Modal"
    >
      <div className={Styles.searchModalContentWrapper}>
        <p className={GlobalStyles.closeModalBtn} onClick={props.hideModal}>
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Search Biomarkers
        </h4>

        <div className={Styles.selectedLabsWrapper}>
          {selectedLabs &&
            selectedLabs.map((x, i) => {
              return (
                <div key={i.toString()} className={Styles.selectedLabEntry}>
                  <p>{x.shortname}</p>
                  <p
                    onClick={() => onLabDeleted(i)}
                    className={Styles.selectedLabEntryDelete}
                  >
                    X
                  </p>
                </div>
              )
            })}
        </div>

        <div className={Styles.searchElementsWrapper}>
          {/* <InstantSearch searchClient={searchClient} indexName={'LOINC_CODES'}>
            <div style={{ position: 'absolute', width: '75%' }}>
              <SearchBox />
              <div
                style={{
                  overflowY: 'scroll',
                  height: 300,
                  backgroundColor: '#F1F1F1',
                }}
              >
                <RefinementList limit={10} attribute={'LOINC_CODES'} />
                <Hits hitComponent={(props) => Hit(props, onLabAdded)} />
              </div>
            </div>
            {/* <CustomStateResults/>}
          </InstantSearch> */}
          <SearchProvider config={esConfig}>
            <WithSearch
              mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                wasSearched,
                setSearchTerm,
              })}
            >
              {({ wasSearched, setSearchTerm }) => {
                // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                return (
                  <div className="App">
                    <EsSearchBox
                      debounceLength={0}
                      autocompleteResults={{
                        titleField: 'long_common_name',
                      }}
                      onSelectAutocomplete={(e) => {
                        onLabAdded({
                          component: e.component.raw,
                          example_ucum_units: e.example_ucum_units.raw,
                          example_units: e.example_units.raw,
                          loinc_num: e.loinc_num.raw,
                          long_common_name: e.long_common_name.raw,
                          order_obs: e.order_obs.raw,
                          shortname: e.shortname.raw,
                        })
                        setSearchTerm('')
                      }}
                      searchAsYouType={true}
                      inputView={({
                        getAutocomplete,
                        getInputProps,
                        getButtonProps,
                      }) => (
                        <>
                          <div className="sui-search-box__wrapper">
                            <input
                              {...getInputProps({
                                placeholder: 'Search LONIC codes',
                              })}
                            />
                            {getAutocomplete()}
                          </div>
                        </>
                      )}
                    />
                  </div>
                )
              }}
            </WithSearch>
          </SearchProvider>
        </div>

        <Button
          onClick={() => props.onLabsSelected(selectedLabs)}
          className={GlobalStyles.button}
          style={{ width: 140, position: 'absolute', bottom: 10 }}
          variant="primary"
        >
          Add
        </Button>
      </div>
    </Modal>
  )
}

const Hit = (props, onLabAdded) => {
  let { hit } = props

  const handleClick = () => {
    onLabAdded(hit)
  }

  return (
    <>
      <div
        onClick={(e) => handleClick(e)}
        className={Styles.searchResultWrapper}
      >
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <p style={{ fontWeight: 'bold', width: '22%' }}>Short Name</p>
          <p style={{ marginRight: 8 }}>:</p>
          <p style={{ width: '75%' }}>{hit.SHORTNAME}</p>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <p style={{ fontWeight: 'bold', width: '22%' }}>Long Name</p>
          <p style={{ marginRight: 8 }}>:</p>
          <p style={{ width: '75%' }}>{hit.LONG_COMMON_NAME}</p>
        </div>
      </div>
      <hr />
    </>
  )
}

export default LoincSearchModal
