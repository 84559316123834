import React, { useState, useEffect } from 'react'
import GlobalStyles from '../../../pages/styles/global.module.scss'
import Styles from './styles/PreVisitIntake.module.scss'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMicrophoneSlash,
  faPhone,
  faVideoSlash,
  faMicrophone,
  faVideo,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import values from '../../../values'
import Config from '../../../config.js'
import Select from 'react-select'
import Slider from 'react-rangeslider'
import Button from 'react-bootstrap/Button'
import * as metadata from '../../../metadata'

import 'react-rangeslider/lib/index.css'
import '../../../pages/wix/rangeSlider.css'

import { fetchPatientCoreDate } from '../../../redux/actions'
const axios = require('axios')

const TWILIO_VIDEO = require('twilio-video')

const PreVisitIntake = (props) => {
  const dispatch = useDispatch()
  let history = useHistory()
  const [loading, setLoading] = useState(false)
  const patient = useSelector((state) => state.patientReducer?.patient)
  const patientCore = useSelector((state) => state.patientReducer?.patientCore)

  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)
  const doctorDetails = props.doctorDetails
  const rescheduleData = props.dataPassed

  const [error, setError] = useState(false)
  const [errorString, setErrorString] = useState('')

  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [visitReason, setVisitReason] = useState('')
  const [medicationData, setMedicationData] = useState(null)
  const [confirmMedication, setConfirmMedication] = useState(false)
  const [duration, setDuration] = useState(null)
  const [durationType, setDurationType] = useState(null)
  const [desc, setDesc] = useState('')
  const [historyIllness, setHistoryIllness] = useState('')
  const [appointmentBooked, setAppointmentBooked] = useState(false)
  const [severity, setSeverity] = useState(0)
  const [treatment, setTreatment] = useState(null)
  const [treatmentext, setTreatmentText] = useState('')
  const [appointmentDetails, setAppointmentDetails] = useState(null)
  const [axiosConfig, setAxiosConfig] = useState({})

  //GUARANTOR INFORMATION
  const [change, setChange] = useState({
    guarantor: {
      // guarantor_id: '', // from changehealthcare
      guarantor_address_1:
        patientCore?.change?.guarantor?.guarantor_address_1 || '',
      guarantor_city: patientCore?.change?.guarantor?.guarantor_city || '',
      guarantor_state: patientCore?.change?.guarantor?.guarantor_state || '',
      guarantor_zip: patientCore?.change?.guarantor?.guarantor_zip || '',
      guarantor_first_name:
        patientCore?.change?.guarantor?.guarantor_first_name || '',
      guarantor_last_name:
        patientCore?.change?.guarantor?.guarantor_last_name || '',
      guarantor_sex: patientCore?.change?.guarantor?.guarantor_sex || '',
      guarantor_relationship:
        patientCore?.change?.guarantor?.guarantor_relationship || '',
      guarantor_home_phone:
        patientCore?.change?.guarantor?.guarantor_home_phone || '',
    },
  })

  useEffect(() => {
    getMedications()
    if (props.reschedule || props.joinVideoCall) {
      setVisitReason(rescheduleData.reason)
      setDuration(rescheduleData.duration)
      setDurationType({ label: rescheduleData.durationType, value: rescheduleData.durationType })
      setDesc(rescheduleData.description)
      setHistoryIllness(rescheduleData.historyIllness)
      setSeverity(rescheduleData?.severity)
      setTreatment(
        rescheduleData?.treatement ? { label: 'Yes', value: 'Yes' } : { label: 'No', value: 'No' },
      )
      setTreatmentText(rescheduleData?.treatement)
    }
  }, [])

  const getMedications = () => {
    setLoading(true)
    var config = {
      method: 'get',
      url: `${Config.BACKEND_URL}user/medications`,
      headers: {
        x_firebase_id: patient.uid,
        Authorization: 'JWT ' + token,
      },
    }
    axios(config)
      .then((response) => {
        setLoading(false)
        if (response.data != null) {
          setMedicationData(response.data)
          console.log(response.data)
        }
      })
      .catch(function (error) {
        console.log('error while fetching medication ', error)
      })
  }

  const getAddressDetails = (patientData) => {
    let { addressComponents, zipcode } = patientData
    let state = null
    let address_1 = null
    let city = null
    let zip = null
    if (addressComponents && addressComponents.length > 0) {
      addressComponents.forEach((addressComponent) => {
        let types = addressComponent.types || []
        if (types.includes('street_number'))
          address_1 = addressComponent.long_name
        if (types.includes('route'))
          address_1 = address_1
            ? address_1 + addressComponent.long_name
            : addressComponent.long_name

        if (types.includes('administrative_area_level_1'))
          state = addressComponent.short_name
        if (types.includes('locality')) city = addressComponent.long_name
        if (types.includes('postal_code')) {
          zip = addressComponent.long_name
        }
        else{
          zip = zipcode
        }
      })
    }

    return { address_1, city, state, zip }
  }

  const populateSelfData = () => {
    const patientData = { ...patient, ...patientCore }

    let temp = { ...change }
    temp.guarantor.guarantor_first_name = patientData?.firstName
    temp.guarantor.guarantor_last_name = patientData?.lastName
    temp.guarantor.guarantor_sex = patientData?.gender
    temp.guarantor.guarantor_home_phone = patientData?.phoneNumber
    //ADDRESS DETAILS
    let address = getAddressDetails(patientData)
    temp.guarantor.guarantor_address_1 = address.address_1
    temp.guarantor.guarantor_city = address.city
    temp.guarantor.guarantor_state = address.state
    temp.guarantor.guarantor_zip = address.zip
    setChange(temp)
  }

  const renderIntakeForm = () => {
    const horizontalLabels = {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
    }

    let disableGuarantor =
      change?.guarantor?.guarantor_relationship === 'Self' ? true : false

    return (
      <div style={{ width: '100%', padding: '20px 40px' }}>
        {/* license no */}

        {/* PATIENT NAME */}
        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Care Team Member:</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Care Team Member"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={`${doctorDetails?.first_name} ${doctorDetails?.last_name}`}
              disabled
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Primary Reason for Visit:</p>
          <div style={{ width: '50%' }}>
            <input
              placeholder="Enter Primary Reason"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={visitReason}
              onChange={(e) => {
                setVisitReason(e.target.value)
              }}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>
            How long have you had your symptoms?
          </p>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
            <input
              placeholder={'Number'}
              type="number"
              id="height"
              name="height"
              min="1"
              max="100"
              value={duration}
              onChange={(e) => {
                setDuration(e.target.value)
              }}
              // onChange={(e) => {
              //   let text = e.target.value
              //   setHeight(text)
              // }}
              className={Styles.textInput}
              style={{ width: '50%' }}
            />{' '}
            <div style={{ width: '50%' }}>
              <Select
                placeholder="Select Duration"
                inputId="DaysDuration"
                options={[
                  { label: 'Days', value: 'Days' },
                  { label: 'Weeks', value: 'Weeks' },
                  { label: 'Months', value: 'Months' },
                  { label: 'Years', value: 'Years' },
                ]}
                value={durationType}
                isSearchable={false}
                autoBlur
                onChange={(val) => {
                  // console.log(val)
                  setDurationType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>How severe are your symptoms?</p>
          <div style={{ width: '50%' }}>
            <Slider
              min={0}
              max={10}
              step={1}
              labels={horizontalLabels}
              value={severity}
              orientation="horizontal"
              onChange={(val) => {
                // console.log(val)
                setSeverity(val)
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: 20,
              }}
            >
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 'normal',
                  color: values.primaryTheme,
                  width: 120,
                  textAlign: 'center',
                  marginLeft: -45,
                }}
              >
                0 - Mild
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 'normal',
                  color: values.primaryTheme,
                  width: 100,
                  textAlign: 'center',
                  marginRight: -40,
                }}
              >
                10 - Severe
              </p>
            </div>
          </div>
        </div>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>
            Give a brief description of your current problem for the doctor:
          </p>
          <div style={{ width: '50%' }}>
            <textarea
              placeholder="Enter description..."
              cols="20"
              rows="5"
              style={{
                width: '100%',
                borderBlockColor: 'lightgray',
                borderRadius: '5px',
                resize: 'none',
                borderColor: 'lightgray',
              }}
              value={desc}
              onChange={(e) => {
                setDesc(e.target.value)
              }}
            ></textarea>
          </div>
        </div>

        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>
            Give a brief history of your current problem for the doctor:
          </p>
          <div style={{ width: '50%' }}>
            <textarea
              placeholder="Enter history..."
              cols="20"
              rows="5"
              style={{
                width: '100%',
                borderBlockColor: 'lightgray',
                borderRadius: '5px',
                resize: 'none',
                borderColor: 'lightgray',
              }}
              value={historyIllness}
              onChange={(e) => {
                setHistoryIllness(e.target.value)
              }}
            ></textarea>
          </div>
        </div> */}

        {/* <div className={Styles.row}>
          <p className={Styles.rowLabel}>
            Confirm your current medications and dosing:
          </p>
          <div style={{ width: '50%', display: 'flex' }}>
            {medicationData && medicationData.active_medications.length
              ? medicationData.active_medications.map((meds) => {
                  return (
                    <input
                      placeholder="Care Team Member"
                      className={Styles.textInput}
                      style={{ width: '100%' }}
                      type="text"
                      value={`${meds.name} Dosage: ${meds.dose.quantity} ${meds.dose.unit} `}
                      disabled
                    />
                  )
                })
              : 'No active medications present, please update '}
          </div>
        </div> */}

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>
            Have you tried any treatment to resolve the problem?
          </p>
          <div style={{ width: '50%' }}>
            <Select
              placeholder="Please select"
              inputId="treatment"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              value={treatment}
              isSearchable={false}
              autoBlur
              onChange={(val) => {
                // console.log(val)
                setTreatment(val)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>
        {treatment && treatment.value === 'Yes' && (
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Please specify:</p>
            <div style={{ width: '50%' }}>
              <textarea
                placeholder="Specify the treatment ..."
                cols="20"
                rows="5"
                style={{
                  width: '100%',
                  borderBlockColor: 'lightgray',
                  borderRadius: '5px',
                  resize: 'none',
                  borderColor: 'lightgray',
                }}
                value={treatmentext}
                onChange={(e) => {
                  setTreatmentText(e.target.value)
                }}
              ></textarea>
            </div>
          </div>
        )}

        {/*GUARANTOR INFORMATION*/}

        <p
          className={Styles.rowLabel}
          style={{
            fontSize: 'large',
            fontStyle: 'bold',
            width: '100%',
            marginTop: '5%',
          }}
        >
          Guarantor Information
        </p>

        <div className={Styles.row}>
          <p className={Styles.rowLabel}>Relationship</p>
          <div style={{ width: '50%' }}>
            <Select
              inputId="secondaryrelationship"
              options={metadata.RELATIONSHIP_OPTIONS}
              value={
                change?.guarantor?.guarantor_relationship &&
                !change?.guarantor?.guarantor_relationship.label
                  ? {
                      label: change?.guarantor?.guarantor_relationship,
                      value: change?.guarantor?.guarantor_relationship,
                    }
                  : change?.guarantor?.guarantor_relationship
              }
              autoBlur
              onChange={(val) => {
                let temp = { ...change }
                temp.guarantor.guarantor_relationship = val.label
                setChange(temp)
                if (val.label === 'Self') {
                  populateSelfData(2)
                } else {
                  let temp = { ...change }
                  temp.guarantor.guarantor_first_name = ''
                  temp.guarantor.guarantor_last_name = ''
                  temp.guarantor.guarantor_address_1 = ''
                  temp.guarantor.guarantor_city = ''
                  temp.guarantor.guarantor_state = null
                  temp.guarantor.guarantor_zip = ''
                  temp.guarantor.guarantor_sex = null
                  temp.guarantor.guarantor_home_phone = ''
                  setChange(temp)
                }
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />

            {/* <input
                    placeholder="Enter Insurance Provider"
                    className={Styles.textInput}
                    style={{ width: '100%' }}
                    type="text"
                    value={change?.insurance_provider}
                    onChange={(e) => {
                    let temp = {...change}
                      temp.insurance_provider = e.target.value
                      setChange(temp)
                    }}
                  /> */}
            {/* 
                <FontAwesomeIcon
                  onClick={() => {
                    this.props.toggleSidebar(true)
                    this.setState({ showInsuranceModal: true })
                  }}
                  icon={faEdit}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 20,
                    marginLeft: 10,
                    cursor: 'pointer',
                  }}
                /> */}
          </div>
        </div>
        <div style={{ display: disableGuarantor ? 'none' : 'block' }}>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>First Name</p>
            <div style={{ width: '20%', marginRight: '2%' }}>
              <input
                placeholder="First Name"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                disabled={disableGuarantor}
                value={change?.guarantor?.guarantor_first_name}
                onChange={(e) => {
                  let temp = { ...change }
                  temp.guarantor.guarantor_first_name = e.target.value
                  setChange(temp)
                }}
              />
            </div>
            <p className={Styles.rowLabel}>Last Name</p>
            <div style={{ width: '20%' }}>
              <input
                placeholder="Last Name"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                disabled={disableGuarantor}
                value={change?.guarantor?.guarantor_last_name}
                onChange={(e) => {
                  let temp = { ...change }
                  temp.guarantor.guarantor_last_name = e.target.value
                  setChange(temp)
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Gender</p>
            <div style={{ width: '50%' }}>
              <Select
                placeholder="Select Gender"
                inputId="secondarygender"
                isDisabled={disableGuarantor}
                options={metadata.GENDER_OPTIONS}
                value={
                  change?.guarantor?.guarantor_sex &&
                  !change?.guarantor?.guarantor_sex.label
                    ? {
                        label: change?.guarantor?.guarantor_sex,
                        value: change?.guarantor?.guarantor_sex,
                      }
                    : change?.guarantor?.guarantor_sex
                }
                autoBlur
                onChange={(val) => {
                  let temp = { ...change }
                  temp.guarantor.guarantor_sex = val.label
                  setChange(temp)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Phone</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder="Enter Insured Phone"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                disabled={disableGuarantor}
                value={change?.guarantor?.guarantor_home_phone}
                onChange={(e) => {
                  let temp = { ...change }
                  temp.guarantor.guarantor_home_phone = e.target.value
                  setChange(temp)
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Address</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder="Enter Insured Address"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                disabled={disableGuarantor}
                value={change?.guarantor?.guarantor_address_1}
                onChange={(e) => {
                  let temp = { ...change }
                  temp.guarantor.guarantor_address_1 = e.target.value
                  setChange(temp)
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>City</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder="Enter Insured City"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                disabled={disableGuarantor}
                value={change?.guarantor?.guarantor_city}
                onChange={(e) => {
                  let temp = { ...change }
                  temp.guarantor.guarantor_city = e.target.value
                  setChange(temp)
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>Zip</p>
            <div style={{ width: '50%' }}>
              <input
                placeholder="Enter Insured Zip"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                disabled={disableGuarantor}
                value={change?.guarantor?.guarantor_zip}
                onChange={(e) => {
                  let temp = { ...change }
                  temp.guarantor.guarantor_zip = e.target.value
                  setChange(temp)
                }}
              />
            </div>
          </div>

          <div className={Styles.row}>
            <p className={Styles.rowLabel}>State</p>
            <div style={{ width: '50%' }}>
              <Select
                placeholder="Select State"
                inputId="secondarystate"
                isDisabled={disableGuarantor}
                options={metadata.stateResidence}
                value={
                  change?.guarantor?.guarantor_state &&
                  !change?.guarantor?.guarantor_state.label
                    ? {
                        label: change?.guarantor?.guarantor_state,
                        value: change?.guarantor?.guarantor_state,
                      }
                    : change?.guarantor?.guarantor_state
                }
                isMulti={false}
                isSearchable
                autoBlur
                onChange={(val) => {
                  let temp = { ...change }
                  temp.guarantor.guarantor_state = val.abbreviation
                  setChange(temp)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: 100, // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const saveGuarantor = () => {
    let patientData = { ...patientCore }
    const mongoData = {
      change: { ...patientData?.change, ...change },
    }
    // patientData.change = { ...patientData?.change, ...change }

    const url = `${Config.BACKEND_URL}users`

    // console.log('mongo data::', mongoData, url)

    axios({
      method: 'put',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patient.uid },
      data: mongoData,
    })
      .then(({ data }) => {
        console.log('successfully added user details to mongo backend')
        dispatch(fetchPatientCoreDate(patient?.uid, token))
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  const onSubmit = () => {
    if (!doctorDetails || !patient) {
      //cannot book apppointmnet wihtout the ids
      toast.warning(
        'Calendar Error : Cannot book an appointment with the selected care team member, Please contact support if the problem persists.',
      )
      return
    }
    if (
      visitReason.length === 0 ||
      duration === null ||
      durationType === null
    ) {
      setError(true)
      setErrorString('Missing fields, Please fill the missing fields')
      return
    } else if (
      !change?.guarantor?.guarantor_relationship ||
      !change?.guarantor?.guarantor_city ||
      !change?.guarantor?.guarantor_state ||
      !change?.guarantor?.guarantor_zip ||
      !change?.guarantor?.guarantor_first_name ||
      !change?.guarantor?.guarantor_last_name ||
      !change?.guarantor?.guarantor_sex ||
      !change?.guarantor?.guarantor_home_phone
    ) {
      setError(true)
      setErrorString(
        'Missing Guarantor fields, Please fill all the missing fields',
      )
      window.alert('Please fill out all your details before your appointment.')
      history.replace('/patient/account')
      return
    } else {
      saveGuarantor()
      let medicationList = medicationData?.active_medications
        .map((medication) => medication.name)
        .join(', ')
      let data = {
        doctor_id: doctorDetails?.firebase_id,
        patient_id: patient.uid,
        symptoms: [],
        reason: visitReason,
        description: desc,
        medications: medicationList,
        duration: duration,
        durationType: durationType.value,
        severity: severity,
        // userCurrentLocation:
        //   userLocation?.lat +
        //   ', ' +
        //   userLocation?.long +
        //   ', ' +
        //   userLocation?.state,
        treatement: treatmentext,
        through_api: true,
        historyIllness: historyIllness,
      }

      let config = props.reschedule
          ? {
              method: 'put',
              url: `${Config.BACKEND_URL}appointments`,
              headers: {
                Authorization: `JWT ${token}`,
                'Content-Type': 'application/json',
              },
              data: { ...data, prev_event_id: props.prev_event_id, google_event_id: rescheduleData?.google_event_id },
            }
          : props.joinVideoCall
          ? {
              method: 'put',
              url: `${Config.BACKEND_URL}appointment`,
              headers: {
                Authorization: `JWT ${token}`,
                'Content-Type': 'application/json',
              },
              data: { ...data, event_id: props.prev_event_id , google_event_id: rescheduleData?.google_event_id},
            }
          : {
              method: 'post',
              url: `${Config.BACKEND_URL}appointments`,
              headers: {
                Authorization: `JWT ${token}`,
                'Content-Type': 'application/json',
              },
              data: data,
            }
      setAxiosConfig(config)
      console.log(config)

      props.parentCallback({
        success: true,
        axiosConfig: config,
        joinVideoCall: props.joinVideoCall,
      })
    }
  }

  const renderContent = () => (
    <div className={GlobalStyles.contentWrapper}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {renderIntakeForm()}
      </div>

      <div
        style={{
          width: '100%',
          marginTop: 60,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {error && (
          <p
            style={{
              marginTop: 4,
              marginBottom: 10,
              fontSize: 14,
              color: 'red',
            }}
          >
            {errorString}
          </p>
        )}
        <Button
          onClick={() => onSubmit()}
          className={GlobalStyles.button}
          //   disabled={this.state.loading}
          style={{
            width: 200,
            margin: 10,
            //   position: 'absolute',
            //   left: '50%',
            //   right: '50%',
          }}
          variant="primary"
        >
          Continue
        </Button>
      </div>
    </div>
  )

  if (loading) return renderLoader()
  return renderContent()
}

export default PreVisitIntake
