import React, { useState } from 'react'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import { EThree } from '@virgilsecurity/e3kit-browser'
import {
  faAppleAlt,
  faTrash,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BSModal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Styles from './styles/List.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import placeHolder from '../../assets/images/placeholder.png'
import Fuse from 'fuse.js'

import {
  addRpmPatient,
  setPatientCoreData,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  fetchRpmOrders
} from '../../redux/actions'
import firebase from '../../services/firebase.js'
import Config from '../../config.js'
import Constants from '../../values'
import AppointmentNotes from '../Appointments/AppointmentNotes'
const axios = require('axios')

const YELLOW = '#ffae42'

const RED_PATIENT_LIST = [
  {
    profilePictureURL: require('../../assets/images/woman.jpeg'),
    firstName: 'Isabell',
    lastName: 'Drangano',
    last_reviewed: '2020-08-30T12:00:04.102Z',
    cohorts: ['Diabetes'],
    time: 5000,
  },
  {
    profilePictureURL: require('../../assets/images/man photo.jpeg'),
    firstName: 'Jason',
    lastName: 'Gough',
    last_reviewed: '2020-08-30T12:00:04.102Z',
    cohorts: ['Diabetes'],
    time: 3000,
  },
]

const YELLOW_PATIENT_LIST = [
  {
    profilePictureURL: require('../../assets/images/man1.jpg'),
    firstName: 'Paul',
    lastName: 'Hitman',
    last_reviewed: '2020-08-30T12:00:04.102Z',
    cohorts: ['Autoimmune, Diabetes'],
    time: 2000,
  },
  {
    profilePictureURL: require('../../assets/images/woman1.jpg'),
    firstName: 'Rachel',
    lastName: 'Carandagn',
    last_reviewed: '2020-08-28T12:00:04.102Z',
    cohorts: ['Arthritis'],
    time: 6000,
  },
  {
    profilePictureURL: require('../../assets/images/woman2.jpg'),
    firstName: 'Isabella',
    lastName: 'Garcia',
    last_reviewed: '2020-08-10T12:00:04.102Z',
    cohorts: ['Diabetes'],
    time: 643,
  },
]

const GREEN_PATIENT_LIST = [
  {
    profilePictureURL: require('../../assets/images/man2.jpg'),
    firstName: 'Jacob',
    lastName: 'Matthew',
    last_reviewed: '2020-07-30T12:00:04.102Z',
    cohorts: ['Diabetes'],
    time: 5000,
  },
  {
    profilePictureURL: require('../../assets/images/woman.jpeg'),
    firstName: 'Debra',
    lastName: 'Mongo',
    last_reviewed: '2020-08-28T12:00:04.102Z',
    cohorts: ['Diabetes'],
    time: 12312,
  },
]

class List extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      patientListColor: 'gray',
      loading: true,
      modalOpen: false,
      patientModal: false,
      modalPatientRows: [
        {
          name: '',
          email: '',
          error: false,
          errorString: '',
        },
      ],
      modalNew: true,
      modalLoading: false,
      modalError: false,
      modalErrorString: '',
      doctorInvites: null,
      patientListData: [],
      patientList: [],
      completePatientList: [],
      cohortsToAdd: [''],
      cohortModalError: false,
      cohortModalErrorString: '',
      shortDynamicLink: null,
      checkedIn: false,
      initializingVirgil: false,
      patientLoginTimeStamp: {},
      patientAppointmentData: {},
      superUser: false,
      searchName: '',
    }
    this.fuse = null
    this.onModalClosePressed = this.onModalClosePressed.bind(this)
    this.onPatientCheckedIn = this.onPatientCheckedIn.bind(this)
    this.getVirgilTokenFromBackend = this.getVirgilTokenFromBackend.bind(this)
    this.initializeVirgil = this.initializeVirgil.bind(this)
    this.restorePrivateKey = this.restorePrivateKey.bind(this)
    this.getPatientList = this.getPatientList.bind(this)
    this.getAllPatientLoginTime = this.getAllPatientLoginTime.bind(this)
    this.getAllPatientAppointmentData =
      this.getAllPatientAppointmentData.bind(this)
    this.navigateToRpmPatientProfile =
      this.navigateToRpmPatientProfile.bind(this)
    this.onPatientClicked = this.onPatientClicked.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    const { token, adminId } = this.props
    this.props.stopTimer(adminId, token)

    this.getPatientList()
    this.getAllPatientLoginTime()
    this.getAllPatientAppointmentData()
    this.props.setPatientCoreData(null)
  }

  getPatientList() {
    const { token, adminId, firebaseUser, mongoUser } = this.props
    const self = this
    const url =
      `${Config.BACKEND_URL}doctor/patients/list` +
      (self.state.superUser ||
      mongoUser?.user_type?.toLowerCase() === 'administrator'
        ? '?access=admin'
        : '')
    axios({
      method: 'get',
      url,
      headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          const { data } = response
          let total = []
          data &&
            Object.keys(data).forEach((color) => {
              total = total.concat(data[color])
            })
          self.setState({
            loading: false,
            patientListData: data || {},
            patientList: data ? total : [], // default
            completePatientList: total,
          })
          self.props.addPatientList(data)
          if (self.props.rpmPatientId) {
            self.navigateToRpmPatientProfile(data)
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log('error when getting patient list', error, token)
      })
  }

  getAllPatientLoginTime() {
    const { token, adminId, firebaseUser } = this.props
    let url = Config.BACKEND_URL + 'admin/portal/logintimestamp'
    axios({
      method: 'get',
      url,
      headers: { x_firebase_id: adminId, Authorization: `JWT ${token}` },
    }).then(({ data }) => {
      if (data?.timestamp) {
        this.setState({ patientLoginTimeStamp: data?.timestamp })
      }
    })
  }

  getAllPatientAppointmentData() {
    const { token, adminId } = this.props
    let url = Config.BACKEND_URL + 'admin/portal/userappointment'
    axios({
      method: 'get',
      url,
      headers: { x_firebase_id: adminId, Authorization: `JWT ${token}` },
    }).then(({ data }) => {
      if (data?.appointment) {
        this.setState({ patientAppointmentData: data?.appointment })
      }
    })
  }

  componentDidUpdate() {
    const { mongoUser, token, e3, userType } = this.props
    if (
      !userType.includes('ccfm') &&
      mongoUser &&
      token &&
      !e3 &&
      !this.state.initializingVirgil
    ) {
      this.setState({ initializingVirgil: true })
      this.initializeVirgil()
    }
  }

  navigateToRpmPatientProfile(patientList) {
    const { rpmPatientId } = this.props
    let rpmPatient = null
    Object.values(patientList).forEach((colorCodedPatients) => {
      if (colorCodedPatients.length > 0) {
        colorCodedPatients.forEach((patient) => {
          if (patient.uid === rpmPatientId) rpmPatient = patient
        })
      }
    })
    this.onPatientClicked(rpmPatient, 'red')
  }

  initializeVirgil() {
    const { mongoUser, token, adminId } = this.props
    const self = this
    console.log('initializing virgil')
    EThree.initialize(this.getVirgilTokenFromBackend)
      .then((eThree) => {
        // register user, encrypt, decrypt, etc.
        console.log('successful intialization e3')
        if (!mongoUser.virgil_registered) {
          eThree
            .register()
            .then(() => {
              console.log('successfully registered user with virigl')
              self.props.updateDoctorMongoUser(
                { virgil_registered: true, firebase_id: adminId },
                mongoUser,
                token,
              )
              eThree
                .backupPrivateKey(adminId)
                .then(() => console.log('success'))
                .catch((e) => console.error('error: ', e))
            })
            .catch((err) => {
              console.error('error when registering: ', err)
              if (err.name === 'IdentityAlreadyExistsError') {
                self.props.updateDoctorMongoUser(
                  { virgil_registered: true, firebase_id: adminId },
                  mongoUser,
                  token,
                )
                self.restorePrivateKey(eThree, mongoUser, token, adminId)
              }
              // self.deleteUser(eThree, mongoUser, token)

              // eThree.rotatePrivateKey()
              //   .then(() => {
              //     console.log('successully rotated private key')
              //     self.props.updateDoctorMongoUser({virgil_registered: true, firebase_id: mongoUser.firebase_id}, mongoUser, token)
              //   })
              //   .catch(e => console.error('error: ', e));
            })
        } else {
          self.restorePrivateKey(eThree, mongoUser, token, adminId)
        }
        self.props.addVirgilE3(eThree)
      })
      .catch((err) => {
        console.log('error when intializing virgil SDK', err)
      })
  }

  async deleteUser(eThree, mongoUser, token) {
    try {
      await eThree.cleanup()
      await eThree.rotatePrivateKey()
    } catch (err) {
      console.log('error when deleting user', err)
    }
  }

  async restorePrivateKey(eThree, mongoUser, token, adminId) {
    console.log('restoring private key')
    const hasLocalPrivateKey = await eThree.hasLocalPrivateKey()

    if (!hasLocalPrivateKey) {
      console.log('no local copy')
      try {
        await eThree.restorePrivateKey(adminId)
        console.log('restored private key')
      } catch (err) {
        console.log('error when restoring private key from backup', err)
      }
    }
    console.log('restored private key')
    // this.props.updateDoctorMongoUser({virgil_registered: false, firebase_id: mongoUser.firebase_id}, mongoUser, token)
  }

  async getVirgilTokenFromBackend() {
    const { mongoUser, token } = this.props
    const url = `${Config.BACKEND_URL}virgil-jwt`
    const response = await axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: mongoUser.firebase_id,
        Authorization: `JWT ${token}`,
      },
    })

    if (response && response.data && response.data.virgilToken)
      return response.data.virgilToken
    return null
  }

  onModalClosePressed() {
    this.setState({
      modalOpen: false,
      patientModal: false,
    })
  }

  onAddPatientClicked() {
    this.setState({
      modalOpen: true,
      patientModal: true,
      modalPatientRows: [
        {
          name: '',
          email: '',
          error: false,
          errorString: '',
        },
      ],
    })
  }

  onPatientCheckedIn(patientList, index, checked) {
    const patient = patientList[index]
    firebase
      .firestore()
      .collection('users')
      .doc(patient.uid)
      .update({
        checkedIn: checked,
      })
      .then(() => {
        // Update the same in the mongo
        // const { token } = this.props
        // axios({
        //   method: 'put', // I think I can assume that a patient must exist prior
        //   url: `${Config.BACKEND_URL}users`,
        //   headers: {
        //     Authorization: `JWT ${token}`,
        //     x_firebase_id: patient.uid,
        //   },
        //   data: {
        //     checked_in: checked,
        //   },
        // })
        patient.checkedIn = checked
        patientList[index] = patient
        const color = this.state.patientListColor
        const { patientListData } = this.state
        if (color === 'red') patientListData.red = patientList
        else if (color === YELLOW) patientListData.yellow = patientList
        else if (color === 'green') patientListData.green = patientList

        this.setState({ patientListData, patientList })
        toast.success('Updated successfully')
      })
      .catch((err) => {
        console.log('error when updating doc', err)
      })
  }

  onPatientClicked(patient, color) {
    const { mongoUser, token, adminId } = this.props
    this.props.updatePatientData(
      {
        doctor_id: adminId,
        patient_id: patient.uid,
        last_reviewed: new Date(),
      },
      token,
    )

    // if the doctor was already on on another patient page and just clicked on a new patient, reset time
    if (this.props.patientTimer && this.props.patientTimer !== 0)
      this.props.resetTimer(patient.uid, adminId, token)
    else this.props.startTimer(patient.uid)
    const { preferences } = patient
    let timeline = 'complete'
    if (preferences && preferences.timeline) {
      timeline = preferences.timeline.value
    }
    this.props.fetchPatientProgressData(patient.uid, this.props.token, timeline)
    this.props.fetchPatientCoreDate(patient.uid, this.props.token)
    this.props.fetchRpmOrders(patient, this.props.token)
    this.props.fetchPatientWellnessScreening(patient.uid, this.props.token)
    this.props.fetchPatientCalculateProgressData(patient.uid, this.props.token)
    this.props.fetchPatientConditions(patient.uid, this.props.token)
    this.props.fetchPatientMedications(patient.uid, this.props.token)
    this.props.fetchPatientLabs(patient.uid, this.props.token)
    this.props.addPatient(patient, color)
    this.props.updateSideNavBar(!this.props.updateRender)
    const patientLp = this.props.patientLP
    if (this.props.rpmPatientId) {
      this.props.addRpmPatient(null)
      this.props.history.push('/patient/progress')
    } else {
      this.props.history.push('/patient/profile')
    }
  }

  sortListByReview() {
    const { patientList } = this.state

    patientList.sort(
      (a, b) => new Date(a.last_reviewed) - new Date(b.last_reviewed),
    )

    this.setState({ patientList })
  }

  sortListByName() {
    const { patientList } = this.state

    patientList.sort(function (a, b) {
      if (a.lastName < b.lastName) {
        return -1
      }
      if (a.lastName > b.lastName) {
        return 1
      }
      return 0
    })

    this.setState({ patientList })
  }

  renderTimeTrackingData(time) {
    if (typeof time === 'number') {
      const timeInMins = Math.round(time / 60) // cos time is tracked in seconds
      return `${timeInMins} mins`
    }

    return 'N/A'
  }

  // assigns patient color
  getPatientColor = (patientId) => {
    let color = 'gray'
    if (
      this.state.patientListData.green.some(
        (patient) => patient.uid === patientId,
      )
    )
      color = 'green'
    if (
      this.state.patientListData.yellow.some(
        (patient) => patient.uid === patientId,
      )
    )
      color = 'yellow'
    if (
      this.state.patientListData.red.some(
        (patient) => patient.uid === patientId,
      )
    )
      color = 'red'
    return color
  }

  renderPatients() {
    const toReturn = []
    let list
    let color
    const {
      patientList,
      patientListColor,
      patientLoginTimeStamp,
      patientAppointmentData,
      searchName,
    } = this.state

    if (!patientList) return null

    const options = {
      includeScore: true,
      keys: ['firstName', 'lastName'],
    }

    this.fuse = new Fuse(patientList, options)

    let uniqueList = []

    if (searchName.length !== 0) {
      const results = this.fuse.search(searchName)
      // const searchResults = []
      results.forEach((result) => {
        uniqueList.push(result.item)
      })
      // console.log('SR::', searchResults)
    } else {
      uniqueList = patientList
    }

    uniqueList.forEach((patient, index) => {
      let patientColor = this.getPatientColor(patient?.uid)
      let timeStampArr = patientLoginTimeStamp?.[patient?.uid]?.timestamps || []
      // formatting the date to remove multiple logins on same day
      let uniqueDateArray = timeStampArr?.map((date) =>
        moment(date).format('MM-DD-YYYY'),
      )
      let monthLogin =
        uniqueDateArray &&
        uniqueDateArray?.filter(
          (data, index) =>
            moment().isSame(moment(data, 'MM-DD-YYYY'), 'month') &&
            uniqueDateArray.indexOf(data) === index,
        )
      let engageRate = monthLogin && (monthLogin.length / moment().date()) * 100
      let lastAppointmentDate =
        patientAppointmentData?.[patient?.uid]?.latest_start_appointment_date
      let diffLastApp =
        lastAppointmentDate &&
        moment().diff(
          moment.unix(lastAppointmentDate / 1000, 'MM/DD/YYYY'),
          'days',
        )
      const cohorts = patient.cohorts.map((x, index) => (
        <p key={x} style={{ whiteSpace: 'noWrap' }}>
          &nbsp;{x}
          {index === patient.cohorts.length - 1 ? '' : ','}
        </p>
      ))
      toReturn.push(
        <div
          key={index.toString()}
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 80,
            marginTop: 10,
            height: 60,
            alignItems: 'center',
          }}
        >
          <div
            onClick={() => {
              this.onPatientClicked(patient, patientColor, index)
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '30%',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <img
              src={
                patient.profilePictureURL
                  ? patient.profilePictureURL
                  : placeHolder
              }
              style={{ height: 50, width: 50 }}
            />
            <div
              style={{
                marginLeft: 40,
                marginRight: 20,
                height: 14,
                width: 14,
                background: patientColor,
                borderRadius: '50%',
              }}
            />
            <p style={{ fontWeight: 'bold', color: '#20A892' }}>
              {patient.firstName} {patient.lastName}
            </p>
          </div>

          <div style={{ width: '15%', textAlign: 'center' }}>
            <p>
              {patient?.last_reviewed
                ? moment(patient?.last_reviewed).format('MMM DD YYYY')
                : ''}
            </p>
          </div>

          <div
            style={{
              width: '15%',
              textAlign: 'center',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {(diffLastApp > 30 || diffLastApp === undefined) && (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className={Styles.warningIcon}
              />
            )}
            <p>
              {lastAppointmentDate
                ? moment.unix(lastAppointmentDate / 1000).format('MMM DD YYYY')
                : 'N/A'}
            </p>
          </div>

          <div style={{ width: '10%', textAlign: 'center' }}>
            <p>{engageRate?.toFixed(2)} %</p>
          </div>

          <div
            style={{
              width: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input
              checked={patient.checkedIn}
              onChange={(e) =>
                this.onPatientCheckedIn(
                  patientList,
                  patientList.findIndex((i) => i.uid === patient.uid),
                  e.target.checked,
                )
              }
              type="checkbox"
              style={{ color: 'red' }}
            />
          </div>

          <div style={{ width: '10%', textAlign: 'center' }}>
            {patient.cohorts && cohorts}
          </div>
          <div style={{ width: '10%', textAlign: 'center' }}>No</div>
          <div style={{ width: '10%', textAlign: 'center' }}></div>

          <div style={{ width: '10%', textAlign: 'center' }}>
            <p>{this.renderTimeTrackingData(patient.time_amount)}</p>
          </div>
        </div>,
      )
    })

    return toReturn
  }

  onTabSelected(index) {
    if (!index) return

    switch (index) {
      case 1:
        this.setState({
          patientListColor: 'red',
          patientList: this.state.patientListData.red,
        })
        break
      case 2:
        this.setState({
          patientListColor: YELLOW,
          patientList: this.state.patientListData.yellow,
        })
        break
      case 3:
        this.setState({
          patientListColor: 'green',
          patientList: this.state.patientListData.green,
        })
        break
      case 4:
        this.setState({
          patientListColor: 'gray',
          patientList: this.state.completePatientList,
        })
        break
      default:
    }
  }

  renderInviteList() {
    const data = this.state.doctorInvites
    if (!data) {
      this.getDoctorInvitesList()
      return
    }

    const entries = []
    data.invites_sent.forEach((invite) => {
      invite.accepted = false
      entries.push(invite)
    })
    data.invites_accepted.forEach((invite) => {
      invite.accepted = true
      entries.push(invite)
    })

    const displayEntries = entries.map((x, i) => (
      <div
        className={Styles.inviteRow}
        style={{
          padding: '6px 4px',
          backgroundColor: i % 2 === 0 ? '#e8e8e8' : 'white',
        }}
      >
        <div className={Styles.inviteName}>{x.name}</div>
        <div className={Styles.inviteEmail}>{x.email}</div>
        <div className={Styles.inviteDate}>
          {moment(x.created_at).format('MMM Do YYYY')}
        </div>
        <div className={Styles.inviteStatus}>
          {x.accepted ? 'Accepted' : 'Pending'}
        </div>
      </div>
    ))

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          marginTop: 10,
          overflowY: 'scroll',
        }}
      >
        <div className={Styles.inviteRow}>
          <div
            className={Styles.inviteName}
            style={{ color: Constants.primaryTheme }}
          >
            Patient Name
          </div>
          <div
            className={Styles.inviteEmail}
            style={{ color: Constants.primaryTheme }}
          >
            Email
          </div>
          <div
            className={Styles.inviteDate}
            style={{ color: Constants.primaryTheme }}
          >
            Invite Sent
          </div>
          <div
            className={Styles.inviteStatus}
            style={{ color: Constants.primaryTheme }}
          >
            Status
          </div>
        </div>

        {displayEntries}
        {this.state.modalError && (
          <p style={{ fontSize: 12, color: 'red', marginTop: 4 }}>
            {this.state.modalErrorString}
          </p>
        )}
      </div>
    )
  }

  render() {
    if (this.state.loading)
      return (
        <div className={GlobalStyles.container} style={{ height: '100vh' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )

    return (
      <div className={GlobalStyles.container}>
        <Header header="My Patients" />

        {/* <div> */}
        {/*  <Letters /> */}
        {/* </div> */}

        {/* <Modal */}
        {/*  ariaHideApp={false} */}
        {/*  onRequestClose={() => this.onModalClosePressed()} */}
        {/*  isOpen={this.state.modalOpen} */}
        {/*  style={this.state.patientModal ? customAddPatientModalStyles : customAddCohortModalStyles} */}
        {/*  contentLabel="Modal" */}
        {/* > */}
        {/*  {this.renderModal()} */}
        {/* </Modal> */}
        <div className={GlobalStyles.contentWrapper}>
          <Tabs onTabSelected={this.onTabSelected.bind(this)} />
          {this.props.firebaseUser?.superUser && (
            <Button
              onClick={() => {
                this.setState({ superUser: !this.state.superUser }, () =>
                  this.getPatientList(),
                )
              }}
              className={GlobalStyles.button}
              style={{ width: 150, marginLeft: 100, marginTop: 10 }}
              variant="primary"
            >
              {this.state.superUser ? 'Super User' : 'Provider'}
            </Button>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 40,
              marginLeft: 80,
              borderColor: 'gray',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              paddingBottom: 10,
            }}
          >
            <div style={{ color: 'gray', width: '30%', textAlign: 'center' }}>
              <input
                placeholder="Search Name"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={this.state.searchName}
                onChange={(e) => {
                  this.setState({ searchName: e.target.value })
                }}
              />
            </div>
            {/* <div
              onClick={this.sortListByName.bind(this)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                color: 'gray',
                width: '30%',
                textAlign: 'center',
              }}
            >
              <p style={{ marginRight: 4 }}>Name</p>
              <span>↓</span>
            </div> */}
            <div
              onClick={this.sortListByReview.bind(this)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                color: 'gray',
                width: '15%',
                textAlign: 'center',
              }}
            >
              <p style={{ marginRight: 4 }}>Last Reviewed</p>
              <span>↓</span>
            </div>
            <div style={{ color: 'gray', width: '15%', textAlign: 'center' }}>
              <p>Last Appointment</p>
            </div>
            <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
              <p>Patient Engagement Rate</p>
            </div>
            <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
              <p>Checked In</p>
            </div>
            <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
              <p>Cohort</p>
            </div>
            <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
              <p>RPM?</p>
            </div>
            <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
              <p>Device Status</p>
            </div>
            <div style={{ color: 'gray', width: '10%', textAlign: 'center' }}>
              <p>Time</p>
            </div>
          </div>
          <div style={{ height: '80vh', overflowY: 'auto' }}>
            {this.renderPatients()}
          </div>
        </div>
      </div>
    )
  }
}

const Letters = (props) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const [activeLetter, setActiveLetter] = useState('A')

  const renderLetters = () => {
    const toReturn = []
    for (let i = 0; i < letters.length; i++) {
      const letter = letters[i]
      toReturn.push(
        <p
          key={letter}
          onClick={() => setActiveLetter(letter)}
          style={{
            marginRight: 10,
            color: activeLetter === letter ? '#20A892' : 'gray',
            fontSize: 20,
            cursor: 'pointer',
          }}
        >
          {letter}
        </p>,
      )
    }
    return toReturn
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 100,
        marginTop: 20,
      }}
    >
      {renderLetters()}
    </div>
  )
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(4)

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray',
        width: 600,
        marginLeft: 100,
        height: 40,
        marginTop: 40,
      }}
    >
      <div
        onClick={() => onTabSelected(1)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 1 ? '#DCDCDC' : '' }}
      >
        <p style={{ color: 'red' }}>Needs Attention</p>
      </div>
      <div
        onClick={() => onTabSelected(2)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 2 ? '#DCDCDC' : '' }}
      >
        <p style={{ color: 'orange' }}>Watch</p>
      </div>
      <div
        onClick={() => onTabSelected(3)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 3 ? '#DCDCDC' : '' }}
      >
        <p style={{ color: '#20A892' }}>Normal</p>
      </div>

      <div
        onClick={() => onTabSelected(4)}
        className={Styles.tabItem}
        style={{ backgroundColor: activeTab === 4 ? '#DCDCDC' : '' }}
      >
        <p style={{ color: 'gray' }}>All</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state /* , ownProps */) => ({
  patient: state.patientReducer.patient,
  patientLP: state.patientReducer.lp,
  loggedIn: state.userReducer.loggedIn,
  token: state.authReducer.token,
  docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
  mongoUser: state.userReducer.mongoUser,
  e3: state.authReducer.e3,
  patientTimer: state.patientReducer.timer,
  rpmPatientId: state.patientReducer.rpmPatientId,
  adminId: state.userReducer.adminId,
  userType: state.userReducer.userType,
  updateRender: state.userReducer.render,
  firebaseUser: state.userReducer.firebaseUser,
})

const mapDispatchToProps = {
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
  fetchRpmOrders
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
