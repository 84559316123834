import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import { toast, ToastContainer } from 'react-toastify'
import {
  faPhone,
  faBolt,
  faAppleAlt,
  faBrain,
  faMoon,
  faFireAlt,
  faCheckCircle,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import Config from '../../config'
import Styles from './styles/MobileSubscription.module.scss'
import Constants from '../../values'
import AilaLogo from '../../assets/images/aila_logo.png'
import CommImage from '../../assets/images/comm_1.png'
import { subscriptionPlans } from '../../metadata'
const queryString = require('query-string')
const moment = require('moment')
const axios = require('axios')


export default class SubscriptionAppointment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      token: null,
      patientId: null,
      patientDetails: null,
      patientSubscribed: false,
      subscriptionActive: false,
      subscriptionCancelledAtEndOfPeriod: false,
      patientEmail: null,
      state: null,
      plan: null,
      event_id: null,
    }
    this.createCheckoutSession = this.createCheckoutSession.bind(this)
    this.getAuthToken = this.getAuthToken.bind(this)
    this.getUserDetails = this.getUserDetails.bind(this)
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search)
    console.log('parsed', parsed)
    const patientId = parsed.patient_id
    const patientEmail = parsed.email
    const { event_id } = parsed
    const { state } = parsed
    // const { plan } = parsed
    if (!patientId || !patientEmail) {
      console.log('missing info')
      toast.error('Missing information ')
    } else {
      this.getAuthToken(patientId, patientEmail)
      this.setState({ state, event_id })
    }
  }

  async getAuthToken(patientId, patientEmail) {
    const url = `${Config.BACKEND_URL}authorization`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_firebase_id: patientId },
        url,
      })
      const token = response.data && response.data.token
      this.setState({ token, patientId, patientEmail })
      this.createCheckoutSession(token, patientId, patientEmail)
      this.getUserDetails(patientId, token)
    } catch (err) {
      console.log('error when auth token', err)
      toast.error('Error when getting authorization from backend')
    }
  }

  async getUserDetails(patientId, token) {
    const url = `${Config.BACKEND_URL}users`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_firebase_id: patientId, Authorization: `JWT ${token}` },
        url,
      })
      console.log('user id', response)
      const user = response.data
      const { stripe } = user
      console.log('got user data', user)
      if (user && stripe && stripe.customer_id && stripe.checkout_id) {
        // patient has subscribed at least once in Aila's history
        const today = Math.floor(Date.now() / 1000)
        console.log('today is', new Date(today * 1000))
        if (today >= stripe.period_start && today <= stripe.period_end) {
          console.log('subscription active')
          // active subscription
          this.setState({ subscriptionActive: true })

          // if user has cancelled subscription at the end of this period, subscription flag will be false even though dates are active
          if (!stripe.subscription) {
            this.setState({ subscriptionCancelledAtEndOfPeriod: true })
          }
        }
        this.setState({ patientSubscribed: true, patientDetails: user })
      }
      this.setState({ loading: false })
    } catch (err) {
      console.log('error when getting user details', err)
      toast.error('Something went wrong')
    }
  }

  async createCheckoutSession(token, patientId, patientEmail) {
    const url = `${Config.BACKEND_URL}stripe/sessions`
    const stripePublishableKey = Config.STRIPE_PUBLISHABLE_API_KEY
    this.setState({ loading: true })
    try {
      const stripe = await loadStripe(stripePublishableKey)
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `JWT ${token}`,
          x_patient_id: patientId,
          email: patientEmail,
          cardSetup: true
        },
        url,
      })

      const { data } = response
      this.setState({ loading: false })
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId,
        })
        .then(() => {
          console.log('stripe success')
        })
        .catch((err) => {
          console.log('error when creating stripe session', err)
        })
    } catch (err) {
      console.log('error when creating session', err)
      this.setState({ loading: false })
    }
  }


  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          display: 'flex',
          //   flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 10,
          backgroundColor: 'white',
          //   overflow: 'scroll',
          justifyContent: 'center',
          //   width: window.innerWidth,
          width: '100%',
        }}
      >
        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              marginTop: 40,
            }}
          >
            <Loader
              type="Oval"
              color={Constants.primaryTheme}
              height={40}
              width={40}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    )
  }
}
