import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Select from 'react-select'
import Config from '../../config'
import Styles from './styles/Roster.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import Header from '../../components/Header'
import placeHolder from '../../assets/images/placeholder.png'
import Button from 'react-bootstrap/Button'
import { stateResidence } from '../../metadata'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import { useTable, useSortBy, usePagination } from 'react-table'
import Constants from '../../values'

export const rosterColumns = (
  allProviderData,
  allProviderList,
  communities,
  tags,
  addDoctor,
  removeDoctor,
  addCommunity,
  removeCommunity,
  navigatetoPatientProfile,
  onPatientNavLinkClicked,
  insuranceEdit,
  addTags,
  removeTags,
) => {
  return [
    {
      Header: 'Patient Name',
      accessor: 'patientName',
      Cell: ({ row }) => {
        return (
          <Link
            onClick={() => {
              navigatetoPatientProfile(row.original.firebase_id)
              onPatientNavLinkClicked('profile')
            }}
            to={{
              pathname: '/patient/profile',
              state: 'info',
              key: 2,
            }}
            className={Styles.link}
          >
            {row?.original.firstName || ''} {row?.original.lastName || ''}
          </Link>
        )
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      // obj.firstName && obj.lastName ? obj.firstName + ' ' + obj.lastName : '',
    },
    {
      id: 'Patient Type',
      Header: 'Patient Type',
      accessor: (obj) =>
        obj.medicaid
          ? 'Others (Medicare)'
          : obj.appointments.length === 0 ||
            !obj.appointments.filter(
              (appt) =>
                allProviderData.find(
                  (item) => item.firebase_id === appt.doctor_id,
                )?.user_type === 'Physician',
            ).length
          ? 'New Patient'
          : 'Established',
      width: 150,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <Select
                inputId="patienttype"
                placeholder="Select"
                options={[
                  { label: 'New Patient', value: 'New Patient' },
                  { label: 'Established', value: 'Established' },
                  // { label: 'Not Scheduled', value: 'not_scheduled' },
                  { label: 'Others', value: 'Others' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.label)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'State',
      accessor: 'stateResidence',
      width: 150,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            {/* Select State:{' '} */}
            <div style={{ width: '100%' }}>
              <Select
                inputId="select-state"
                placeholder="Select"
                options={stateResidence}
                value={
                  filterValue
                    ? {
                        label: filterValue,
                        value: filterValue,
                        abbreviation: filterValue,
                      }
                    : ''
                }
                // isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.abbreviation)
                  // setMemberType(val)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 300,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      sort: true,
    },
    {
      id: 'Clinical Patient',
      Header: 'Clinical Patient',
      accessor: (obj) =>
        obj.appointments.length === 0 ||
        !obj.appointments.filter(
          (appt) =>
            allProviderData.find((item) => item.firebase_id === appt.doctor_id)
              ?.user_type === 'Physician',
        ).length
          ? 'No'
          : 'Yes',
      width: 150,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <Select
                inputId="clinical_patient"
                placeholder="Select"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.label)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Membership',
      accessor: (obj) =>
        obj?.coaching?.active ? obj?.coaching?.plan || 'membership' : 'basic',
      width: 150,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <Select
                inputId="membertype"
                placeholder="Select"
                options={[
                  { label: 'premium', value: 'premium' },
                  { label: 'membership', value: 'membership' },
                  { label: 'basic', value: 'basic' },
                  // { label: 'All', value: 'all' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Member Since',
      accessor: (obj) =>
        obj?.coaching?.active
          ? moment(obj?.coaching?.start).format('ll')
          : moment(obj.created_at).format('ll'),
      width: 200,
      sort: true,
    },
    {
      Header: 'Cash pay/Insurance pay',
      accessor: 'cashPay',
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <Select
                inputId="cashPay"
                placeholder="Select"
                options={[
                  { label: 'Cash Pay', value: 'Cash Pay' },
                  { label: 'Insurance Pay', value: 'Insurance Pay' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'ID Added',
      accessor: (obj) =>
        obj?.id_front_image_url || obj?.id_front_image_url ? 'Yes' : 'No',
      width: 150,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <Select
                inputId="IDaddedd"
                placeholder="Select"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Payment Details Added',
      accessor: (obj) =>
        obj?.stripe && obj?.stripe?.customer_id ? 'Yes' : 'No',
      width: 150,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <Select
                inputId="IDaddedd"
                placeholder="Select"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Insurance',
      accessor: (obj) => (
        <>
          {obj?.insurances?.length
            ? obj?.insurances
                .map((insurance) => insurance.insurance_provider)
                .join(',')
            : '-'}

          <br />
          {obj?.insurances?.length
            ? obj?.insurances
                .map((insurance) =>
                  insurance.preference === 1 && insurance.benefits
                    ? 'Copay:$' +
                      insurance.benefits?.copay +
                      ' ' +
                      'Coinsurance:' +
                      insurance.benefits?.coinsurance +
                      '% '
                    : '',
                )
                .join('')
            : ''}
          <br />
          <p
            onClick={() => {
              insuranceEdit(obj)
            }}
            style={{
              color: Constants.primaryTheme,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Edit
          </p>
        </>
      ),
      width: 300,
      //   Filter: ({ column }) => {
      //     const { filterValue, setFilter } = column
      //     return (
      //       <span>
      //         Search:{' '}
      //         <div style={{ width: '100%' }}>
      //           <input
      //             placeholder="Search"
      //             className={Styles.textInput}
      //             style={{ width: '100%' }}
      //             type="text"
      //             value={filterValue || ''}
      //             onChange={(e) => {
      //               setFilter(e.target.value)
      //             }}
      //           />
      //         </div>
      //       </span>
      //     )
      //   },
    },

    {
      Header: 'Primary Insurance Plan',
      accessor: 'insurancePlanType',
      width: 300,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      sort: true,
    },

    {
      Header: 'Program',
      accessor: (obj) => {
        let programType = '-'
        if (obj.dac) programType = 'Dac-Patient'
        if (obj.rapilot) programType = 'Ra-Patient'
        if (obj.ccfm) programType = 'CCFM'
        return programType
      },
      width: 150,
    },
    // {
    //   id: 'Risk Alignment',
    //   Header: 'Risk Alignment',
    //   accessor: (obj) => {
    //     let temp = Object.assign({}, ...obj?.color_code)
    //     // if (temp) {
    //     let rpmValues = Object.values(temp)
    //     if (rpmValues.includes('red')) return 'Needs Attention'
    //     else if (rpmValues.includes('yellow')) return 'Watch'
    //     else return 'Normal'
    //     // }
    //   },
    //   Cell: ({ row }) => {
    //     let temp = Object.assign({}, ...row?.original?.color_code)
    //     // if (temp) {
    //     let rpmValues = Object.values(temp)
    //     if (rpmValues.includes('red'))
    //       return <p style={{ color: 'red' }}> Needs Attention</p>
    //     else if (rpmValues.includes('yellow'))
    //       return <p style={{ color: '#ffae42' }}>Watch</p>
    //     else return <p style={{ color: 'green' }}> Normal</p>
    //   },
    //   width: 150,
    //   Filter: ({ column }) => {
    //     const { filterValue, setFilter } = column
    //     return (
    //       <span>
    //         <div style={{ width: '100%' }}>
    //           <Select
    //             inputId="patienttype"
    //             placeholder="Select"
    //             options={[
    //               { label: 'Needs Attention', value: 'Needs Attention' },
    //               { label: 'Watch', value: 'Watch' },
    //               // { label: 'Not Scheduled', value: 'not_scheduled' },
    //               {
    //                 label: 'Normal',
    //                 value: 'Normal',
    //               },
    //               {
    //                 label: 'All',
    //                 value: 'All',
    //               },
    //             ]}
    //             value={
    //               filterValue
    //                 ? { label: filterValue, value: filterValue }
    //                 : null
    //             }
    //             isSearchable
    //             autoBlur
    //             onChange={(val) => {
    //               if (val.value === 'All') {
    //                 setFilter(null)
    //               } else setFilter(val.label)
    //               // filterByPatientType(val)
    //             }}
    //             theme={(theme) => ({
    //               ...theme,
    //               colors: {
    //                 ...theme.colors,
    //                 primary25: '#20A89233',
    //                 primary: '#20A892',
    //               },
    //             })}
    //           />
    //         </div>
    //       </span>
    //     )
    //   },
    // },
    {
      Header: 'Providers',
      accessor: (obj) => {
        let assignedProvider = allProviderData
          ?.filter((provider) => {
            return obj?.doctor_patients?.includes(provider.firebase_id)
          })
          .map((option) => ({
            label: option?.first_name + ' ' + option?.last_name,
            value: option?.firebase_id,
          }))
        return (
          <Select
            options={allProviderList}
            // className={Styles.addSelectInput}
            isMulti
            autoBlur={true}
            onChange={(val) => {
              console.log(val)
              let difference = []
              if (val?.length > assignedProvider?.length) {
                difference = val?.filter((x) => !assignedProvider?.includes(x)) // calculates diff
                addDoctor(difference[0].value, obj.firebase_id)
              } else {
                difference = assignedProvider?.filter((x) => !val?.includes(x)) // calculates diff
                removeDoctor(difference[0].value, obj.firebase_id)
              }
            }}
            value={assignedProvider}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },

      width: 300,
    },

    {
      Header: 'Cohort',
      accessor: (obj) => {
        let assignedCommunities = communities?.filter((community) => {
          return community?.users?.[obj.firebase_id]
        })

        return (
          <Select
            options={communities}
            // className={Styles.addSelectInput}
            isMulti
            autoBlur={true}
            onChange={(val) => {
              let difference = []
              if (val.length > assignedCommunities.length) {
                difference = val.filter((x) => !assignedCommunities.includes(x)) // calculates diff
                addCommunity(difference[0]._id, obj.firebase_id)
              } else {
                difference = assignedCommunities.filter((x) => !val.includes(x)) // calculates diff
                removeCommunity(difference[0]._id, obj.firebase_id)
              }
            }}
            getOptionValue={(option) => option._id}
            getOptionLabel={(option) => option.title}
            value={assignedCommunities}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
      width: 300,
    },
    {
      Header: 'Tags',
      accessor: (obj) => {
        let assignedTags = tags?.filter((tag) => {
          return tag?.users?.[obj.firebase_id]
        })

        return assignedTags
      },
      Cell: ({ row }) => {
        let obj = row?.original
        let assignedTags = tags?.filter((tag) => {
          return tag?.users?.[obj.firebase_id]
        })

        return (
          <Select
            options={tags}
            // className={Styles.addSelectInput}
            isMulti
            autoBlur={true}
            onChange={(val) => {
              let difference = []
              if (val?.length > assignedTags?.length) {
                difference = val?.filter((x) => !assignedTags?.includes(x)) // calculates diff
                addTags(difference[0]._id, obj.firebase_id)
              } else {
                difference = assignedTags?.filter((x) => !val?.includes(x)) // calculates diff
                removeTags(difference[0]._id, obj.firebase_id)
              }
            }}
            getOptionValue={(option) => option._id}
            getOptionLabel={(option) => option.title}
            value={assignedTags}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <Select
            options={tags}
            // className={Styles.addSelectInput}
            // isMulti
            autoBlur={true}
            getOptionValue={(option) => option._id}
            getOptionLabel={(option) => option.title}
            value={filterValue}
            onChange={(val) => {
              setFilter(val)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
      width: 300,
    },
    {
      Header: 'Last Appointment Date',
      accessor: (obj) => {
        let lastAppointment = obj?.appointments?.length
          ? obj.appointments.reduce((acc, app) =>
              app.start > acc.start && moment().valueOf() - app.start > 0
                ? app
                : acc,
            )
          : null
        return lastAppointment?.start
          ? moment.unix(lastAppointment?.start / 1000).format('MMM DD YYYY')
          : '-'
      },
      width: 250,
    },
    {
      Header: 'Next Appointment Date',
      accessor: (obj) => {
        let nextAppointment = obj?.appointments?.length
          ? obj.appointments.filter((appt) =>
              moment(appt.start).isAfter(moment()),
            )[0]
          : null

        return nextAppointment?.start
          ? moment.unix(nextAppointment?.start / 1000).format('MMM DD YYYY')
          : '-'
      },
      width: 250,
    },

    {
      Header: 'Days since last engagement',
      accessor: (obj) => {
        // find last logged in
        let latestLogin = obj?.login_timestamps[0]?.timestamps?.length
          ? obj?.login_timestamps[0]?.timestamps?.slice(-1)[0]
          : null
        return latestLogin
          ? moment().diff(latestLogin, 'days')
            ? moment().diff(latestLogin, 'days') + ' days ago'
            : 'Today'
          : '-'
      },
      width: 150,
    },
    {
      Header: 'Engagement Rate',
      accessor: (obj) => {
        // find engagement rate
        let referenceDate = moment().subtract(30, 'days').format('MM-DD-YYYY')
        let uniqueDateArray =
          obj?.login_timestamps[0]?.timestamps &&
          obj.login_timestamps[0]?.timestamps?.map((date) =>
            moment(date).format('MM-DD-YYYY'),
          )
        let monthLogin =
          uniqueDateArray &&
          uniqueDateArray.filter(
            (data, index) =>
              moment(data).isSameOrAfter(referenceDate) &&
              moment(data).isSameOrAfter(
                moment(obj.created_at).format('MM-DD-YYYY'),
              ) &&
              uniqueDateArray.indexOf(data) === index, // filter date to obtain past 30 days and remove duplicate dates
          )
        let dateDiff =
          moment()
            .startOf('day')
            .diff(moment(obj.created_at).startOf('day'), 'day') + 1
        // if the created_at is less than 30 divide by diff
        return dateDiff < 30
          ? monthLogin && (monthLogin.length / dateDiff) * 100
          : monthLogin && (monthLogin.length / 30) * 100
      },
      width: 150,
    },
    {
      Header: 'Sign-up Origin',
      accessor: (obj) => {
        return (
          <>
            {obj.longCovid && <p>Long-Covid</p>}
            {obj?.marketingTitle && <p>{obj?.marketingTitle}</p>}
            <p>{obj?.platform}</p>
          </>
        )
      },
      width: 150,
    },
  ]
}
