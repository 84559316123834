import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
import MyCalendar from '../../components/appointment/MyCalendar'
import Config from '../../config'
import Constants from '../../values'
import Modal from 'react-modal'
import { firebase, analytics } from '../../services/firebase'
import {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  addAppointmentData,
} from '../../redux/actions'
import { connect } from 'react-redux'
import Styles from './styles/AilaCalendarAppointments.module.scss'
import OnboardingFooter from '../../components/OnboardingFooter'

const axios = require('axios')

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 600,
    height: '40vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingRight: '20px',
    paddingLeft: '20px',
  },
}

class AilaCalendarAppointment extends Component {
  constructor() {
    super()
    this.state = {
      patient_id: '',
      programSelected: '',
      planType: true,
      lat: '',
      lon: '',
      token: '',
      docInfo: '',
      setConfirmCustomModal: false,
      setCustomModalDetails: '',
      showCardModal: false,
      selectedDrName: '',
      accessToken: '',
      doctorDetails: '',
      userLocation: '',
      showScheduler: false,
      openAppointmentUrl: '',
      showPaymentModal: false,
      showSuccessModal: false,
      axiosConfig: {},
      loading: true,
      event_id: '',
      patientDetails: '',
    }
    this.renderMainContent = this.renderMainContent.bind(this)
    this.onDoctorClicked = this.onDoctorClicked.bind(this)
    this.getAppointmentUrl = this.getAppointmentUrl.bind(this)
    this.confirmFunction = this.confirmFunction.bind(this)
    this.getLocation = this.getLocation.bind(this)
    this.googleGeocodingApi = this.googleGeocodingApi.bind(this)
    this.getAuthToken = this.getAuthToken.bind(this)
    this.getUserDetails = this.getUserDetails.bind(this)
    this.getProviderDetails = this.getProviderDetails.bind(this)
  }

  componentDidMount() {
    if (!this.props.patient) {
      this.props.history.push('/signup/patient')
      return
    }

    let tokenResp = this.getAuthToken()

    if (tokenResp.error === true) {
      console.log('Token not accessed')
    } else {
      console.log('Success token auth')
    }
  }

  checkStripeDetails = () => {
    let { patientDetails } = this.state
    const user = patientDetails
    if (user?.stripe && user?.stripe?.customer_id) {
      console.log('stripe customer id present')
      return false
    } else {
      console.log('error : no stripe customer id ')
      return true
    }
  }

  handleCalendarCallback = (childData) => {
    let { patientDetails } = this.state
    let self = this
    console.log(childData)
    // if (!childData.success) {
    //   this.setState({ showPaymentModal: true })
    // } else {
    analytics.logEvent('Web_Signup_Appointment_Booked')

    // let src = `/wix/aila-download?patient_type=true`
    // window.open(src, '', 'toolbar=0,status=0,width=1200,height=600')

    // this.props.history.push('/login')
    if (this.props?.location?.state?.flowType === 'LongCovid') {
      this.props.history.push('/long-covid/previsit')
    } else {
      this.props.history.push('/wix/aila/patient-details', {
        stateResidence: this.props?.location?.state?.stateResidence,
      })
    }
    // }
  }

  confirmFunction = async (lat, lon, resultAddress) => {
    this.setState({
      userLocation: {
        lat: lat,
        long: lon,
        state: resultAddress?.long_name,
        state_abbr: resultAddress?.short_name,
        showScheduler: true,
      },
      setConfirmCustomModal: false,
    })
  }

  removeLocalStorage = () => {
    try {
      localStorage.removeItem('ccfm')
      localStorage.removeItem('dac')
      localStorage.removeItem('rapilot')
      localStorage.clear()
      return true
    } catch (e) {
      return false
    }
  }

  getAppointmentUrl = () => {
    let { patient } = this.props
    let { patientDetails } = this.state

    let data = {
      doctor_id: this.state.docInfo.firebase_id,
      patient_id: patient.uid,
      symptoms: [],
      through_api: true,
      appointment_payment_paid: false,
      patient_onboarding: true,
    }

    if (patientDetails?.coaching?.active) {
      data.appointment_payment_paid = true
      this.removeLocalStorage()
    }

    let config = {
      method: 'post',
      url: `${Config.BACKEND_URL}appointments`,
      headers: {
        Authorization: `JWT ${this.state.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    this.setState({ axiosConfig: config })

    this.setState({ loading: false })
  }

  // to get the address using lat and long
  googleGeocodingApi = (
    lat,
    lon,
    licensedFullStateName,
    licensedAbbrStateName,
  ) => {
    var config = {
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&result_type=administrative_area_level_1&key=${Config.GOOGLE_API_KEY}`,
    }

    axios(config)
      .then((response) => {
        let resultAddress =
          response?.data?.results?.[0]?.address_components?.[0]
        if (
          licensedFullStateName.includes(
            resultAddress?.long_name.toLowerCase(),
          ) ||
          licensedAbbrStateName.includes(
            resultAddress?.short_name.toLowerCase(),
          )
        ) {
          this.getUserDetails()

          let apptUrl = this.getAppointmentUrl()

          // console.log('state matched')
          this.setState({
            setCustomModalDetails: {
              title: 'Confirm your Location',
              body:
                `Please confirm that you will be in the state of ${resultAddress?.long_name} at the time of your appointment. ` +
                '\nAila Health Clinicians can only see patients in states where they are licensed to practice.',
              confirmFn: () =>
                this.confirmFunction(lat, lon, resultAddress, apptUrl),
              // this.confirmFunction(lat, lon, resultAddress),

              confirmText: 'Confirm',
              rejectFn: () => {
                // console.log('modal closed')
                this.props.history.push('/wix/aila-download', {
                  patient_type: true,
                })
              },
              rejectText: 'Cancel',
              disableOptions: false,
            },
            setConfirmCustomModal: true,
          })
        } else {
          // console.log('state does not match')
          this.setState({
            setCustomModalDetails: {
              title: 'Sorry',
              body:
                'Doctors can only see patients in states. ' +
                "\nUnfortunately, we haven't arrived in your state yet. Please stay tuned as we expand into new states. " +
                '\nIn the meantime, please continue to use the app and connect with the community.',
              confirmFn: () => {
                // console.log('modal closed')
                this.props.history.push('/wix/aila-download', {
                  patient_type: false,
                })
              },
              rejectFn: () => {
                // console.log('modal closed')
                this.props.history.push('/wix/aila-download', {
                  patient_type: false,
                })
              },
              rejectText: 'Ok',
              disableOptions: true,
            },
            setConfirmCustomModal: true,
          })
        }
      })
      .catch(function (error) {
        // setLoading(false)
        console.log('error when getting address from google', error)
        // setLoading(false)
      })
  }

  showPosition = (position) => {
    let geoValueLat = position.coords.latitude
    let geoValueLon = position.coords.longitude

    this.setState({ lat: geoValueLat, lon: geoValueLon })

    // console.log('licensed_states: ', this.state.docInfo?.licensed_states)
    // if gps enabled request for lat and long
    if (this.state.docInfo?.licensed_states?.length) {
      let licensedFullStateName = this.state.docInfo?.licensed_states?.map(
        (address) => address?.state?.toLowerCase(),
      )
      let licensedAbbrStateName = this.state.docInfo?.licensed_states?.map(
        (address) => address?.abbr?.toLowerCase(),
      )
      // console.log('Array: ', licensedFullStateName, licensedAbbrStateName)

      var lat = 36.7783
      var lon = -119.4179

      this.googleGeocodingApi(
        // lat,
        // lon,
        geoValueLat,
        geoValueLon,
        licensedFullStateName,
        licensedAbbrStateName,
        1,
      )
      this.onDoctorClicked()
    }
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition)
    }
  }

  onDoctorClicked = () => {
    let doc = this.state.docInfo
    this.setState({
      selectedDrName: `${doc.first_name} ${doc.last_name}`,
      doctorDetails: doc,
    })
  }

  // Token from backend
  getProviderDetails = async (provider_uid) => {
    let { token } = this.props
    const url = `${Config.BACKEND_URL}providers`

    try {
      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: provider_uid,
        },
      })
      if (response.status === 200) {
        // console.log('DOC INFO :', response)
        this.setState({ docInfo: response.data })
        // if (response?.data?.user_type !== 'Health Coach') {
        //   this.getLocation()
        // } else {
        this.getUserDetails()

        this.getAppointmentUrl()
        // }

        return { error: false }
      } else {
        console.log('error', response)
        return { error: true }
      }
    } catch (err) {
      console.log('error when getting doc details', err)
      return { error: true, errorString: 'error when getting doc details' }
    }
  }

  // Token from backend
  getAuthToken = async () => {
    let { patient } = this.props
    if (!patient) {
      console.log('Invalid user')
      return { error: true }
    }

    let authUrl = `${Config.BACKEND_URL}authorization`
    try {
      let response = await axios({
        method: 'get',
        url: authUrl,
        headers: { x_firebase_id: patient.uid },
      })
      if (response.status === 200) {
        const token = response.data && response.data.token
        this.setState({ token: response.data.token })
        this.getUserDetails()
        if (patient?.longCovid) {
          // this.getProviderDetails(Config.APPOINTMENT_BOOKING_DOCTOR_ID)
          this.getProviderDetails(this.props?.location?.state?.provider_id)
        } else {
          // this.getProviderDetails(Config.APPOINTMENT_BOOKING_PROVIDER_ID)
          this.getProviderDetails(this.props?.location?.state?.provider_id)
        }

        return { error: false }
      } else {
        console.log('error', response)
        return { error: true }
      }
    } catch (err) {
      console.log('error when getting auth token', err)
      return { error: true, errorString: 'error when getting auth token' }
    }
  }

  async getUserDetails() {
    let { patient, token } = this.props
    const url = `${Config.BACKEND_URL}users`
    try {
      const response = await axios({
        method: 'get',
        headers: { x_firebase_id: patient.uid, Authorization: `JWT ${token}` },
        url,
      })
      // console.log('user id', response)
      const user = response.data
      this.setState({ patientDetails: user })
      const { stripe } = user
      // console.log('got user data', user)
    } catch (err) {
      console.log('error when getting user details', err)
    }
  }

  renderPaymentModal() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          Please enter you payment details to
          <br />
          schedule your appointment
        </p>

        <div
          style={{
            flex: 1,
            alignSelf: 'center',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          {this.state.showCardModal && (
            <iframe
              id="payment_web"
              src={`${Config.WEB_HOST}stripe/card-details?patient_id=${this.props.patient.uid}&email=${this.props.patient.email}`}
              title="cardDetails"
              style={{ height: 700, width: '100%' }}
            />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '4vh',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                width: '50%',
                margin: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  this.setState({ showCardModal: true })
                  console.log('CONFIRMED MODAL YES !')
                }}
                className={GlobalStyles.button}
                disabled={this.state.modalLoading}
                style={{ width: 200 }}
                variant="primary"
              >
                Enter Now
              </Button>
            </div>

            <div
              style={{
                width: '50%',
                margin: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  let src = `/wix/aila-download`
                  window.open(
                    src,
                    '',
                    'toolbar=0,status=0,width=1200,height=600',
                  )
                  this.props.history.push('/login')
                }}
                className={GlobalStyles.button}
                disabled={this.state.modalLoading}
                style={{ width: 200, backgroundColor: 'gray' }}
                variant="primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCustomModal() {
    let customDetails = this.state.setCustomModalDetails
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          {customDetails.title}
        </p>

        <p
          style={{
            fontSize: '20px',
            // marginTop: '4vh',
            margin: 'auto',
            color: values.primaryTheme,
            textAlign: 'center',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            position: 'relative',
          }}
        >
          {customDetails.body}
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            // marginTop: '4vh',
            margin: 'auto',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '50%',
              margin: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                customDetails.confirmFn && customDetails.confirmFn()
                // console.log('CONFIRMED MODAL YES !')
              }}
              className={GlobalStyles.button}
              disabled={this.state.modalLoading}
              style={{ width: 200 }}
              variant="primary"
            >
              {customDetails.confirmText || 'Yes'}
            </Button>
          </div>

          <div
            style={{
              width: '50%',
              margin: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                customDetails.rejectFn && customDetails.rejectFn()
                // console.log('CONFIRMED MODAL CANCEL !')
              }}
              className={GlobalStyles.button}
              disabled={this.state.modalLoading}
              style={{ width: 200, backgroundColor: 'gray' }}
              variant="primary"
            >
              {customDetails.rejectText || 'Cancel'}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderMainContent = () => {
    return (
      <div
        className={Styles.heading}
        // style={{
        //   flex: 1,
        //   alignSelf: 'center',
        //   marginTop: '-8vh',
        //   width: '100%',
        // }}
      >
        <p
          style={{
            fontSize: '30px',
            margin: 'auto',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '2vh',
          }}
        >
          Schedule your onboarding appointment
        </p>
        <p
          style={{
            fontSize: '20px',
            margin: 'auto',
            color: values.darkGray,
            textAlign: 'center',
            // fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '2vh',
          }}
        >
          {/* Click on the date to select your first appointment time
           */}
          Click on the date highlighted in green to select your appointment
        </p>
      </div>
    )
  }
  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div>

        {this.renderMainContent()}

        {this.state.loading ||
        this.state.setConfirmCustomModal ||
        this.state.showPaymentModal ? (
          <div className={GlobalStyles.container} style={{ height: '100vh' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: '100%',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          </div>
        ) : (
          <MyCalendar
            parentCallback={this.handleCalendarCallback}
            doctorDetails={this.state.docInfo}
            axiosConfig={this.state.axiosConfig}
            patientId={this.props.patient.uid}
            token={this.props.token}
            fromWebFlow={true}
          />
        )}

        {/* <div
          style={{
            width: '100%',
            margin: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              let src = `/wix/aila-download`
              window.open(src, '', 'toolbar=0,status=0,width=1200,height=600')
              this.props.history.push('/login')
            }}
            className={GlobalStyles.button}
            disabled={this.state.modalLoading}
            style={{ width: 200, backgroundColor: 'gray' }}
            variant="primary"
          >
            Skip
          </Button>
        </div> */}

        <OnboardingFooter />

        {this.state.setCustomModalDetails.length !== 0 && (
          <Modal
            ariaHideApp={false}
            isOpen={this.state.setConfirmCustomModal}
            // style={customModalStyles}
            className={Styles.modal1Content}
            // style={paymentModalStyles}

            style={{
              overlay: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.44)',
              },
            }}
            contentLabel="Modal"
          >
            {this.renderCustomModal()}
          </Modal>
        )}
        {this.state.showPaymentModal && (
          <Modal
            ariaHideApp={false}
            isOpen={this.state.showPaymentModal}
            // style={paymentModalStyles}
            className={Styles.modal2Content}
            // style={paymentModalStyles}

            style={{
              overlay: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.44)',
              },
            }}
            contentLabel="Modal"
          >
            {this.renderPaymentModal()}
          </Modal>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    patient: state.patientReducer.patient,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
  }
}

const mapDispatchToProps = {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  addAppointmentData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AilaCalendarAppointment)
