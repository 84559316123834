/* eslint-disable */
import moment from 'moment'
import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import PatientInfoStrip from '../../../components/PatientInfoStrip'
import {
  addPatient,
  fetchPatientCarePlanData,
  fetchPatientConditions,
  fetchPatientCoreDate,
  getUnreadMessages,
  toggleSidebar,
} from '../../../redux/actions'
import Constants from '../../../values'
import GlobalStyles from '../../styles/global.module.scss'
import Styles from './styles/profile.module.scss'
import PaymentTab from '../../Admin/PaymentTab'
import {} from '@fortawesome/free-regular-svg-icons'
import {
  faBookMedical,
  faBookReader,
  faCapsules,
  faCheckCircle,
  faClipboardList,
  faCloudUploadAlt,
  faCashRegister,
  faCrosshairs,
  faDna,
  faDownload,
  faEdit,
  faExclamationCircle,
  faFileImport,
  faFileInvoice,
  faHandHoldingMedical,
  faNotesMedical,
  faPlusCircle,
  faStethoscope,
  faTrash,
  faUser,
  faUserMd,
  faViruses,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Axios from 'axios'
import Handlebars from 'handlebars'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'
import Table from '../../../components/Table'
import Config from '../../../config'
import APICallers from '../../../helpers/APIs'
import Helpers from '../../../helpers/Global'
import NotesPdf from '../../../helpers/PdfTemplates/NotesPdf.txt'
import VisitNotesPdf from '../../../helpers/PdfTemplates/VisitNotesPdf.txt'
import * as MetaData from '../../../metadata'
import questionMetadata from '../../../questionMetadata'
import NotesModal from '../../Appointments/NotesModal'
import { referralsColumns } from '../../Orders/Columns/referralsColumns'
import styles from '../../Orders/styles/axle.module.scss'
import PatientAccount from '../../PatientWeb/PatientAccount/PatientAccount'
import PatientInsurance from '../../PatientWeb/PatientAccount/PatientInsurance'
import PatientPCP from '../../PatientWeb/PatientAccount/PatientPCP'
import AppointmentList from '../Careplan/AppointmentList'
import CareteamChat from '../Careplan/CareteamChat'
import CommunicationsList from '../Careplan/CommunicationsList'
import EducationProgress from '../Careplan/InnerTabs/EducationProgress'
import Orders from '../Careplan/InnerTabs/Orders'
import Screeners from '../Careplan/InnerTabs/Screeners'
import MessageChat from '../Careplan/MessageChat'
import PatientTaskModal from '../Careplan/PatientTaskModal'
import RpmMonitoringLog from '../Careplan/RpmMonitoringLog'
import TaskModal from '../Careplan/TaskModal'
import Faxes from './InnerTabs/Faxes'
import UploadDocs from './InnerTabs/UploadDocs'
import { RenderAddAllergiesManually } from './Modals/AddAllergiesModal'
import { RenderAddFamilyHistoryManually } from './Modals/AddFamilyHistoryModal'
import { RenderAddFoodSensitivitiesManually } from './Modals/AddFoodSensitivitiesModal'
import { RenderAddMedicationsManually } from './Modals/AddMedicationsModal'
import { RenderAddVitaminsManually } from './Modals/AddVitaminsModal'
import { RenderAddConditionsManually } from './Modals/ConditionsModal'

import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import { first } from 'lodash'
import { select } from 'react-cookies'

const conditionConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'cpt-codes',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigCPTCode = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: conditionConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      description: { raw: {} },
      cpt_code: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

const performerRolesConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'performer-roles',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigPerformerRoles = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: performerRolesConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      code: { raw: {} },
      display: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

const bodySitesConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'body-sites',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigBodySites = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: bodySitesConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      code: { raw: {} },
      display: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

const loincCodesConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'loinc-codes',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigLoincCodes = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: loincCodesConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      long_common_name: { raw: {} },
      shortname: { raw: {} },
    },
    // search_fields: {
  },
}

const immunizationConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'immunizations',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigImmunization = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: immunizationConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      code: { raw: {} },
      display: { raw: {} },
      object_id: { raw: {} },
    },
    // search_fields: {
  },
}

const axios = require('axios')

const MedicationForms = MetaData.medicationForms
const GoalDesc = MetaData.goalDesc
// const condnClient = {
//   search(requests) {
//     if (requests[0].params.query === '') {
//       return []
//     }
//     return searchClient.search(requests)
//   },
// }

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

// const ClearQuery = ({ refine }) => {
//   const onClick = (ev) => {
//     refine('')
//     ev.stopPropagation()
//   }
//   return (
//     <div onClick={onClick} style={{ display: 'none' }}>
//       <ConnectedClearAllRefinements />
//     </div>
//   )
// }

// const ClearAllRefinements = ({ refine, items }) => {
//   const onClick = () => refine(items)
//   return (
//     <button id="clearQuery" onClick={onClick}>
//       ClearAll
//     </button>
//   )
// }

// const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
// const ConnectedClearAllRefinements =
//   connectCurrentRefinements(ClearAllRefinements)

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: 600,
    height: '50vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const manualAddModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60vw',
    height: '80vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}
const insuranceAddModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}

const downloadNotesStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const providerModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '30vw',
    height: '40vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const HEALTH_MODULES = [
  'Diabetes Prevention',
  'Heart Diseases Prevention',
  'Nutrition',
  'Stress Management',
]

const AQI_BUCKETS = [
  { low: 0, high: 50, desc: 'Good', color: 'green' },
  { low: 51, high: 100, desc: 'Moderate', color: 'yellow' },
  {
    low: 101,
    high: 150,
    desc: 'Unhealthy fpr Sensitive Groups',
    color: 'orange',
  },
  { low: 151, high: 200, desc: 'Unhealthy', color: 'red' },
  { low: 201, high: 300, desc: 'Very Unhealthy', color: 'purple' },
  { low: 301, high: 500, desc: 'Hazardous', color: 'maroon' },
]

const PROVIDER_GOALS = [
  { text: 'sleep_goal', value: '' },
  { text: 'weight_goal', value: '' },
  { text: 'step_goal', value: '' },
  { text: 'meditation_goal', value: '' },
  { text: 'exercise_goal', value: '' },
  { text: 'weight_loss_goal', value: '' },
  { text: 'change_habits', value: '' },
]
class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      healthModules: HEALTH_MODULES,
      loading: true,
      loadingContent: false,
      selectedTab: props?.location?.state ? props?.location?.state : 'info',
      rareDisease: null,
      loadingSDOHContent: false,
      SDOHData: null,
      modalIsOpen: false,
      modalGoals: false,
      modalText: '',
      modalValue: '',
      modalLoading: false,
      patientGoals: null,
      providerGoals: [],
      error: false,
      errorString: '',
      ailaCareTeam: null,
      externalCareTeam: null,
      showAilaCareTeam: true,
      showAilaConditions: true,
      showAilaImmunization: true,
      externalConditions: null,
      providerConditions: [],
      providerAllergies: [],
      providerFoodSensitivities: [],
      providerImmunization: [],
      providerMedications: [],
      showAilaMedications: true,
      externalMedications: null,
      externalImmunizations: null,
      showAilaProcedureObservation: true,
      providerProcedures: [],
      externalProcedures: null,
      providerObservations: [],
      externalObservations: null,
      careplan: null,
      notesFromVisit: {},
      addIcd10: {},
      addNotes: '',
      addCarePlan: '',
      addCpt: [],
      notesList: [],
      taskList: [],
      patientTaskList: [],
      modalAddNotesIsOpen: false,
      modalAddTaskIsOpen: false,
      modalSupportData: null,
      modalPatientTaskIsOpen: false,
      modalPatientTaskData: null,
      loadingTask: true,
      manualAddModalIsOpen: false,
      manualAddState: '',
      currentAddModalData: null,
      currentElementIndex: null,
      currentAllUserData: null,
      insuranceModalIsOpen: false,
      editPatientProfileModal: false,
      editPatientPCP: false,
      wellnessData: null,
      // contactInfoModalIsOpen: false,
      // emergencyContactModalIsOpen: false,
    }
    this.getRareDisease = this.getRareDisease.bind(this)
    this.getSDOHData = this.getSDOHData.bind(this)
    this.onModalSaveClicked = this.onModalSaveClicked.bind(this)
    this.getPatientGoals = this.getPatientGoals.bind(this)
    // this.getProviderGoals = this.getProviderGoals.bind(this)
    this.getAilaCareTeam = this.getAilaCareTeam.bind(this)
    this.getExternalConditions = this.getExternalConditions.bind(this)
    this.getProviderConditions = this.getProviderConditions.bind(this)
    this.getProviderAllergies = this.getProviderAllergies.bind(this)
    this.getProviderFoodSensitivities =
      this.getProviderFoodSensitivities.bind(this)
    this.getProviderImmunization = this.getProviderImmunization.bind(this)
    this.getProviderMedications = this.getProviderMedications.bind(this)
    this.getWellnessScreening = this.getWellnessScreening.bind(this)
    this.getExternalMedications = this.getExternalMedications.bind(this)
    this.getExternalCareTeam = this.getExternalCareTeam.bind(this)
    this.getExternalImmunizations = this.getExternalImmunizations.bind(this)
    this.getProviderProcedures = this.getProviderProcedures.bind(this)
    this.getExternalProcedures = this.getExternalProcedures.bind(this)
    this.getProviderObservations = this.getProviderObservations.bind(this)
    this.getExternalObservations = this.getExternalObservations.bind(this)
    this.getUserTask = this.getUserTask(this)
    this.getPatientTask = this.getPatientTask.bind(this)
    this.hideNotesModal = this.hideNotesModal.bind(this)
    this.hideTaskModal = this.hideTaskModal.bind(this)
    this.onManualAddClicked = this.onManualAddClicked.bind(this)
    this.onManualAddModalHide = this.onManualAddModalHide.bind(this)
    this.onInsuranceEditClicked = this.onInsuranceEditClicked.bind(this)
    // this.onContactInfoEditClicked = this.onContactInfoEditClicked.bind(this)
    // this.onEmergencyContactEditClicked =
    //   this.onEmergencyContactEditClicked.bind(this)
    this.patientProfileEdit = this.patientProfileEdit.bind(this)
    this.patientPCPEdit = this.patientPCPEdit.bind(this)
  }

  static getDerivedStateFromProps(props) {
    if (props.patientCore && props.conditions && props.patient)
      return { loading: false }

    return null
  }

  componentDidMount() {
    let { patient, token, adminId } = this.props
    this.props.getUnreadMessages(
      this.props.adminId,
      this.props.token,
      this.props.mongoUser,
    )
    // console.log('LINKED DTAB  TAB CARE PLAN : ', this.props?.location?.state)
    // console.log('SELECTED TAB CARE PLAN : ', this.state.selectedTab)
    if (!this.props.patient) {
      this.props.history.push('/patient/list')
      return
    }
    if (this?.props?.location?.state === 'care-plan') {
      console.log('called in componentDidMount: ', patient, token, adminId)
      this.props.fetchPatientCarePlanData({ patient, token, adminId })
      this.props.fetchPatientCoreDate(patient.uid, token)
      this.getProviderConditions()
      this.getProviderAllergies()
      this.getProviderMedications()
      this.getPatientGoals()
      this.getWellnessScreening()
      this.getPatientTask()
    }
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max))
  }

  getAilaCareTeam() {
    console.log('getting aila care team')
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    let self = this
    let url = Config.BACKEND_URL + 'patient/careteam/internal'
    axios({
      method: 'get',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: patient.uid },
      url: url,
    })
      .then(({ data }) => {
        self.setState({ ailaCareTeam: data, loadingContent: false })
        console.log('got aila care team', data)
      })
      .catch((err) => {
        console.log('error when getting patient goals data', err)
      })
  }

  async getExternalObservations() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      let response = await APICallers.getParticleData(
        'observations',
        patient.uid,
        token,
      )
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let observations = response.data.observations || []
        this.setState({ externalObservations: observations })
      } else {
        toast.info('Patient external records not available')
        this.setState({ externalObservations: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting particle observations data', err)
      toast.error('Something went wrong when getting observations')
      this.setState({ loadingContent: false })
    }
  }

  async getExternalProcedures() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      let response = await APICallers.getParticleData(
        'procedures',
        patient.uid,
        token,
      )
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let procedures = response.data.procedures || []
        this.setState({ externalProcedures: procedures })
      } else {
        toast.info('Patient external records not available')
        this.setState({ externalProcedures: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting particle procedures data', err)
      toast.error('Something went wrong when getting procedures')
      this.setState({ loadingContent: false })
    }
  }

  async getExternalImmunizations() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      let response = await APICallers.getParticleData(
        'immunizations',
        patient.uid,
        token,
      )
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let immunizations = response.data.immunizations || []
        this.setState({ externalImmunizations: immunizations })
      } else {
        toast.info('Patient external records not available')
        this.setState({ externalImmunizations: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting particle immunizations data', err)
      toast.error('Something went wrong when getting immunizations')
      this.setState({ loadingContent: false })
    }
  }

  async getExternalCareTeam() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      let response = await APICallers.getParticleData(
        'care_team',
        patient.uid,
        token,
      )
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let careTeam = response.data.care_team || []
        this.setState({ externalCareTeam: careTeam })
      } else {
        toast.info('Patient external records not available')
        this.setState({ externalCareTeam: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting particle care team data', err)
      toast.error('Something went wrong when getting external care team')
      this.setState({ loadingContent: false })
    }
  }

  async getExternalMedications() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      let response = await APICallers.getParticleData(
        'medications',
        patient.uid,
        token,
      )
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let medications = response.data.medications || []
        this.setState({ externalMedications: medications })
      } else {
        toast.info('Patient external records not available')
        this.setState({ externalMedications: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting particle medications data', err)
      toast.error('Something went wrong when getting external medications')
      this.setState({ loadingContent: false })
    }
  }

  async getExternalConditions() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      let response = await APICallers.getParticleData(
        'conditions',
        patient?.uid,
        token,
      )
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let conditions = response.data.conditions || []
        this.setState({ externalConditions: conditions })
      } else {
        toast.info('Patient external records not available')
        this.setState({ externalConditions: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting particle conditions data', err)
      toast.error('Something went wrong when getting external conditions')
      this.setState({ loadingContent: false })
    }
  }

  async getProviderConditions() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      const getCondition = async () => {
        let url = Config.BACKEND_URL + 'fhir/Condition?patient=' + patient?.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient?.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getCondition()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let conditions = response.data || []
        this.setState({ providerConditions: conditions })
      } else {
        this.setState({ providerConditions: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting particle conditions data', err)
      toast.error('Something went wrong when getting external conditions')
      this.setState({ loadingContent: false })
    }
  }

  async getProviderAllergies() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      const getAllergy = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/AllergyIntolerance?patient=' + patient?.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient?.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getAllergy()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let allergies = response?.data || []
        this.setState({ providerAllergies: allergies })
      } else {
        this.setState({ providerAllergies: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting allergies data', err)
      toast.error('Something went wrong when getting allergies')
      this.setState({ loadingContent: false })
    }
  }

  async getProviderFoodSensitivities() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      const getFood = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/FoodSensitivity?patient=' + patient.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getFood()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let foods = response.data || []
        this.setState({ providerFoodSensitivities: foods })
      } else {
        this.setState({ providerFoodSensitivities: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting food sense data', err)
      toast.error('Something went wrong when getting allergies')
      this.setState({ loadingContent: false })
    }
  }

  async getProviderImmunization() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      const getImmu = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/Immunization?patient=' + patient.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getImmu()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let immu = response.data || []
        this.setState({ providerImmunization: immu })
      } else {
        this.setState({ providerImmunization: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting immuni data', err)
      toast.error('Something went wrong when getting allergies')
      this.setState({ loadingContent: false })
    }
  }

  async getProviderMedications() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      const getMeds = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/MedicationRequest?patient=' + patient.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getMeds()
      if (response?.data) {
        console.log('testubg::: ', response.data)
        //if there is something in data, then the particle records for the patient has been pulled at least once
        // let meds1 = response.data.active_medications_provider || []
        // let meds2 = response.data.inactive_medications_provider || []
        // let meds = meds1.concat(meds2)
        let meds = response.data
        this.setState({ providerMedications: meds })
      } else {
        this.setState({ providerMedications: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting meds data', err)
      toast.error('Something went wrong when getting meds')
      this.setState({ loadingContent: false })
    }
  }

  async getWellnessScreening() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      const getWellData = async () => {
        let url = Config.BACKEND_URL + 'screenings/wellness'
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getWellData()
      if (response?.data) {
        // console.log('testing wellness::: ', response.data)
        //if there is something in data, then the particle records for the patient has been pulled at least once
        // let meds1 = response.data.active_medications_provider || []
        // let meds2 = response.data.inactive_medications_provider || []
        // let meds = meds1.concat(meds2)
        let wellData = response.data
        this.setState({ wellnessData: wellData })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting meds data', err)
      toast.error('Something went wrong when getting meds')
      this.setState({ loadingContent: false })
    }
  }

  async getProviderProcedures() {
    console.log('PROCEDURE CALLED::::')
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      const getProcedure = async () => {
        let url = Config.BACKEND_URL + 'fhir/Procedure?patient=' + patient.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getProcedure()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let procedure = response.data || []
        this.setState({ providerProcedures: procedure })
      } else {
        this.setState({ providerProcedures: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting provider procedure data', err)
      toast.error('Something went wrong when getting provider procedure')
      this.setState({ loadingContent: false })
    }
  }

  async getProviderObservations() {
    this.setState({ loadingContent: true })
    let { patient, token } = this.props
    try {
      const getObservation = async () => {
        let url = Config.BACKEND_URL + 'fhir/Observation?patient=' + patient.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getObservation()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let observation = response.data || []
        this.setState({ providerObservations: observation })
      } else {
        this.setState({ providerObservations: [] })
      }
      this.setState({ loadingContent: false })
    } catch (err) {
      console.log('error when getting provider observation data', err)
      toast.error('Something went wrong when getting provider observation')
      this.setState({ loadingContent: false })
    }
  }

  getPatientGoals() {
    // this.setState({ loadingContent: true })
    let { patient, token } = this.props
    let self = this
    let url = Config.BACKEND_URL + 'patients/goals'
    axios({
      method: 'get',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: patient.uid },
      url: url,
    })
      .then(({ data }) => {
        // console.log('getting patient goals', data)
        self.setState({ patientGoals: data })
        let tempData = []
        if (!patient || !patient.goals || patient.goals.length === 0) {
          if (Object.keys(data).length === 0) {
            tempData = PROVIDER_GOALS.reverse()
          } else {
            Object.keys(data).forEach((element) => {
              if (element === 'free_form_goals') {
                return
              }
              tempData.push({ text: element, value: data[element] })
            })
          }
        } else {
          let data = patient.goals.map((x) => ({
            text: x.text,
            value: x.value,
            created_at: x.created_at,
          }))
          data.forEach((element) => {
            tempData.push(element)
          })
        }
        this.setState({
          providerGoals: tempData,
          // loadingContent: false
        })
      })
      .catch((err) => {
        console.log('error when getting patient goals data', err)
      })
  }

  getUserTask() {
    const { token, adminId, patient } = this.props
    // console.log('testing called support: ', patient)
    this.setState({ loadingTask: true })
    let url = Config.BACKEND_URL + 'support-request'
    axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: adminId,
        Authorization: `JWT ${token}`,
        clinical_support: true,
        patient_id: patient?.uid,
      },
    })
      .then(({ data }) => {
        // console.log('TESTIN: ', data)
        this.setState({ taskList: data, loadingTask: false })
      })
      .catch((err) => {
        console.log('err while fetching support list: ', err)
        this.setState({ loadingTask: false })
      })
  }

  getPatientTask() {
    const { token, adminId, patient } = this.props
    // console.log('testing called support: ', patient)
    this.setState({ loadingTask: true })
    let url = Config.BACKEND_URL + 'patient-tasks'
    axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: adminId,
        Authorization: `JWT ${token}`,
        patient_id: patient?.uid,
      },
    })
      .then(({ data }) => {
        console.log('PATIENT TASKS: ', data)
        this.setState({ patientTaskList: data, loadingTask: false })
      })
      .catch((err) => {
        console.log('err while fetching patient task list: ', err)
        this.setState({ loadingTask: false })
      })
  }

  hideNotesModal() {
    this.setState({ modalAddNotesIsOpen: false })
  }

  hideTaskModal() {
    this.getPatientTask()
    this.setState({
      modalAddTaskIsOpen: false,
      modalSupportData: null,
      modalPatientTaskIsOpen: false,
      modalPatientTaskData: null,
    })
  }

  async getSDOHData() {
    let { patient } = this.props
    if (!patient || !patient.zipcode) {
      this.setState({ SDOHData: {} })
    }

    let flags = [false, false]

    try {
      let url = Config.SDOH_HOST + 'api/v1/aqi?zipcode=' + patient.zipcode
      let aqiResponse = await axios({ method: 'get', url: url })
      url = Config.SDOH_HOST + 'api/v1/low_income?zipcode=' + patient.zipcode
      let lowIncomeResponse = await axios({ method: 'get', url: url })
      let SDOHData = this.state.SDOHData || {}
      SDOHData.aqi = aqiResponse.data && aqiResponse.data.AQI
      SDOHData.lowIncome =
        lowIncomeResponse.data && lowIncomeResponse.data.low_income
      flags[0] = true

      this.setState({ SDOHData, loadingSDOHContent: !(flags[0] && flags[1]) })
    } catch (err) {
      console.log('error when getting SDOH data', err)
    }

    //let this call happen in parallel coz its a big one
    let url = Config.SDOH_HOST + 'api/v1/aarp_data?zipcode=' + patient.zipcode
    axios({
      method: 'get',
      url: url,
    })
      .then(({ data }) => {
        console.log('aarp data', data)
        let scores = data['Score']
        let scoreDescs = data['Score Description']
        // console.log(`${metrics[44]} - ${metricDescs[44]} - ${scores[29]} - ${scoreDescs[29]}`)
        // console.log(`${metrics[45]} - ${metricDescs[45]} - ${scores[30]} - ${scoreDescs[30]}`)
        let SDOHData = this.state.SDOHData || {}
        SDOHData['foodDesert'] = { score: scores[5], desc: scoreDescs[5] }
        SDOHData['parks'] = { score: scores[6], desc: scoreDescs[6] }
        SDOHData['transit'] = { score: scores[14], desc: scoreDescs[14] }
        SDOHData['crime'] = { score: scores[12], desc: scoreDescs[12] }
        SDOHData['hospitalization'] = {
          score: scores[29],
          desc: scoreDescs[29],
        }
        SDOHData['patientSatisfaction'] = {
          score: scores[30],
          desc: scoreDescs[30],
        }
        SDOHData['housing'] = { score: scores[2], desc: scoreDescs[2] }
        flags[1] = true
        this.setState({ SDOHData, loadingSDOHContent: !(flags[0] && flags[1]) })
      })
      .catch((err) => {
        console.log('error when getting aarp info', err)
        flags[1] = true
        this.setState({ loadingSDOHContent: !(flags[0] && flags[1]) })
      })
  }

  getRareDisease(id) {
    let { token } = this.props
    let self = this
    let url = Config.BACKEND_URL + 'rare-diseases/' + id
    axios({
      method: 'get',
      headers: { Authorization: 'JWT ' + token },
      url: url,
    })
      .then(({ data }) => {
        if (data) self.setState({ rareDisease: data, loadingContent: false })
      })
      .catch((err) => {
        console.log('error when getting rare disease info', err)
      })
  }

  onTabSelected(index) {
    let tab = null
    switch (index) {
      case 1:
        tab = 'info'
        break
      case 2:
        tab = 'conditions'
        let { patientCore } = this.props
        let autoImmune =
          patientCore && patientCore.pre_existing
            ? patientCore.pre_existing.auto_immune
            : {}
        this.getProviderConditions()
        this.getProviderAllergies()
        this.getProviderFoodSensitivities()
        if (
          autoImmune?.user_entry &&
          autoImmune?.user_entry.length > 0 &&
          !this.state.rareDisease
        ) {
          //if the user has eneterd auto immune ID and rare disease name is not available, get it form backedn
          this.setState({ loadingContent: true })
          this.getRareDisease(autoImmune?.user_entry)
        }
        break
      case 3:
        tab = 'medications'
        this.getProviderMedications()
        break
      case 4:
        tab = 'immunizations'
        if (!this.state.externalImmunizations) this.getExternalImmunizations()
        this.getProviderImmunization()
        break
      case 5:
        if (!this.state.providerObservations?.length)
          this.getProviderObservations()
        if (!this.state.providerProcedures?.length) this.getProviderProcedures()
        tab = 'procedures-observations'
        break
      case 6:
        tab = 'genetics'
        break
      case 7:
        if (!this.state.SDOHData) {
          this.setState({ loadingSDOHContent: true })
          this.getSDOHData()
        }
        tab = 'social'
        break
      case 8:
        tab = 'risks'
        break
      case 9:
        tab = 'goals'
        if (!this.state.patientGoals) this.getPatientGoals()
        // if (this.state.providerGoals.length === 0) this.getProviderGoals()
        break
      case 10:
        tab = 'care-plan'
        let { patient, token, adminId } = this.props
        console.log('called in switch case 10: ', patient, token, adminId)
        this.props.fetchPatientCarePlanData({ patient, token, adminId })
        this.props.fetchPatientCoreDate(patient.uid, token)
        this.getProviderConditions()
        this.getProviderAllergies()
        this.getProviderMedications()
        this.getPatientGoals()
        this.getWellnessScreening()
        break
      case 11:
        tab = 'education'
        break
      case 12:
        tab = 'care-team'
        if (!this.state.ailaCareTeam) this.getAilaCareTeam()
        break
      case 13:
        tab = 'screeners'
        break
      case 14:
        tab = 'orders'
        break
      case 15:
        tab = 'fax'
        break
      case 16:
        tab = 'upload-documents'
        break
      case 17:
        tab = 'referrals'
        break
      // case 18:
      //   tab = 'payments'
      //   break
      default:
        tab = 'info'
    }

    this.setState({ selectedTab: tab })
  }

  renderConditionsContent() {
    let {
      loadingContent,
      showAilaConditions,
      externalConditions,
      providerConditions,
      providerAllergies,
      providerFoodSensitivities,
    } = this.state
    if (loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {/* <AilaExternalTabs
          onTabSelected={this.onConditionsTabSelected.bind(this)}
          activeTab={showAilaConditions ? 1 : 2}
        /> */}
        {showAilaConditions ? (
          <Conditions
            patientCore={this.props.patientCore}
            patient={this.props.patient}
            conditions={this.props.conditions}
            rareDisease={this.state.rareDisease}
            onManualAddClicked={this.onManualAddClicked}
            providerConditions={providerConditions}
            providerAllergies={providerAllergies}
            providerFoodSensitivities={providerFoodSensitivities}
          />
        ) : (
          <ExternalConditions conditions={externalConditions} />
        )}
      </div>
    )
  }

  renderInfoContent() {
    let { loadingContent } = this.state
    if (loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }
    return (
      <InfoTab
        patient={this.props.patient}
        patientCore={this.props.patientCore}
        onInsuranceEditClicked={this.onInsuranceEditClicked}
        // onContactInfoEditClicked={this.onContactInfoEditClicked}
        // onEmergencyContactEditClicked={this.onEmergencyContactEditClicked}
        patientProfileEdit={this.patientProfileEdit}
        patientPCPEdit={this.patientPCPEdit}
      />
    )
  }

  renderMedicationsContent() {
    let {
      loadingContent,
      showAilaMedications,
      externalMedications,
      providerMedications,
    } = this.state
    if (loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {/* <AilaExternalTabs
          onTabSelected={this.onMedicationsTabSelected.bind(this)}
          activeTab={showAilaMedications ? 1 : 2}
        /> */}
        {showAilaMedications ? (
          <MedicationsTab
            patient={this.props.patient}
            patientCore={this.props.patientCore}
            medications={this.props.medications}
            providerMedications={providerMedications}
            onManualAddClicked={this.onManualAddClicked}
          />
        ) : (
          <ExternalMedications medications={externalMedications} />
        )}
      </div>
    )
  }

  renderGeneticsContent() {
    return (
      <GeneticsTab
        patient={this.props.patient}
        patientCore={this.props.patientCore}
      />
    )
  }

  renderReferralsTab() {
    return (
      <ReferralsTab
        patient={this.props.patient}
        patientCore={this.props.patientCore}
      />
    )
  }

  renderPaymentTab() {
    return (
      <div className={styles.divider}>
        <PaymentTab patient={this.props.patient} />
      </div>
    )
  }

  renderSocialContent() {
    let { loadingContent, loadingSDOHContent } = this.state
    if (loadingContent || loadingSDOHContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <SocialTab
        patient={this.props.patient}
        patientCore={this.props.patientCore}
        sdohData={this.state.SDOHData || {}}
      />
    )
  }

  renderRisksContent() {
    return (
      <Risks
        patient={this.props.patient}
        patientCore={this.props.patientCore}
      />
    )
  }

  renderFaxesTab() {
    let { patientCore } = this.props
    return <Faxes patient={patientCore} />
  }

  renderUploadDocumentsTab() {
    let { patientCore } = this.props
    return <UploadDocs patient={patientCore} />
  }

  renderOrdersTab() {
    let { patientCore, token, patientList } = this.props
    return (
      <Orders patient={patientCore} token={token} patientList={patientList} />
    )
  }

  renderTabContent() {
    let { selectedTab } = this.state
    switch (selectedTab) {
      case 'info':
        return this.renderInfoContent()
      case 'conditions':
        return this.renderConditionsContent()
      case 'medications':
        return this.renderMedicationsContent()
      case 'genetics':
        return this.renderGeneticsContent()
      case 'social':
        return this.renderSocialContent()
      case 'risks':
        return this.renderRisksContent()
      case 'goals':
        return this.renderGoalsContent()
      case 'care-plan':
        return this.renderCarePlan()
      case 'education':
        return this.renderEducationContent()
      case 'care-team':
        return this.renderCareTeam()
      case 'immunizations':
        return this.renderImmunizations()
      case 'procedures-observations':
        return this.renderProceduresObservations()
      case 'screeners':
        return this.renderScreenersTab()
      case 'orders':
        return this.renderOrdersTab()
      case 'fax':
        return this.renderFaxesTab()
      case 'upload-documents':
        return this.renderUploadDocumentsTab()
      case 'referrals':
        return this.renderReferralsTab()
      // case 'payments':
      //   return this.renderPaymentTab()
      default:
        return null
    }
  }

  onModalSaveClicked() {
    if (!this.state.modalText || this.state.modalText.length === 0) {
      this.setState({ modalError: true })
      return
    }
    this.setState({ modalLoading: true })

    let text = this.state.modalText
    let value = this.state.modalValue ? this.state.modalValue : ''
    let toSave = {}
    let { patient, token, adminId } = this.props
    let providerGoals = this.state.providerGoals

    let self = this
    let url
    if (this.state.modalGoals) {
      providerGoals.push({ text: text, value: value })
      this.setState({ providerGoals: providerGoals }, () => {
        toSave.goals = this.state.providerGoals
      })
      toSave.goals = providerGoals
      url = Config.BACKEND_URL + 'doctor/patient/goals-update'
    } else {
      toSave.plan = text
      url = Config.BACKEND_URL + 'doctor/patient/careplan'
    }
    axios({
      method: 'put',
      headers: {
        Authorization: 'JWT ' + token,
        x_patient_id: patient.uid,
        x_doctor_id: adminId,
      },
      url: url,
      data: toSave,
    })
      .then(({ data }) => {
        let patient = this.props.patient
        if (this.state.modalGoals) {
          console.log('Added provider goal successfully.')
          patient.goals = this.state.providerGoals
          this.props.addPatient(patient)
          this.getPatientGoals()
          // this.getProviderGoals()
        } else {
          let plan = patient.careplan || []
          plan.push({ text: text, created_at: new Date() })
          patient.careplan = plan
        }
        self.props.addPatient(patient)
        self.setState({
          modalLoading: false,
          modalGoals: false,
          modalText: '',
          modalValue: '',
          modalIsOpen: false,
          goalmodalIsOpen: false,
        })
      })
      .catch((err) => {
        console.log('error when getting saving provider goals data', err)
      })
  }

  renderModalContent() {
    let date = moment().format('MMM Do YYYY')
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() =>
            this.setState({
              modalIsOpen: false,
              modalGoals: false,
              modalText: '',
            })
          }
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          {this.state.modalGoals ? 'Add Goal' : 'Add Care Plan'}
        </h4>
        {!this.state.modalGoals && (
          <h5 style={{ textAlign: 'center' }}>{date}</h5>
        )}

        {this.state.modalLoading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <textarea
            placeholder={'Enter your text here.....'}
            style={{
              width: '90%',
              height: 100,
              padding: '5px 20px',
              resize: 'none',
              borderColor: '#aeaeae',
              borderRadius: 10,
              marginTop: 40,
            }}
            value={this.state.modalText}
            onChange={(e) =>
              this.setState({ modalText: e.target.value, modalError: false })
            }
          />
        )}

        {this.state.modalError && (
          <p style={{ fontSize: 12, marginTop: 4, color: ' red' }}>
            Please enter the text in the field
          </p>
        )}

        <Button
          onClick={() => this.onModalSaveClicked()}
          className={GlobalStyles.button}
          disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    )
  }

  onInsuranceEditClicked(val) {
    this.props.toggleSidebar(val)
    this.setState({ insuranceModalIsOpen: val })
  }

  // onContactInfoEditClicked(val) {
  //   this.props.toggleSidebar(val)
  //   this.setState({ contactInfoModalIsOpen: val })
  // }

  // onEmergencyContactEditClicked(val) {
  //   this.props.toggleSidebar(val)
  //   this.setState({ emergencyContactModalIsOpen: val })
  // }

  patientProfileEdit(val) {
    this.setState({ editPatientProfileModal: val })
  }
  patientPCPEdit(val) {
    this.setState({ editPatientPCP: val })
  }

  onManualAddClicked(addName, data, index, allData) {
    if (addName === 'goals') {
      this.setState({ modalIsOpen: true, modalGoals: true })
    }
    if (data) {
      this.setState({
        manualAddModalIsOpen: true,
        manualAddState: addName,
        currentAddModalData: data,
        currentElementIndex: index,
        currentAllUserData: allData,
      })
    } else {
      this.setState({
        manualAddModalIsOpen: true,
        manualAddState: addName,
        currentAddModalData: null,
        currentElementIndex: null,
        currentAllUserData: null,
      })
    }
  }

  onManualAddModalHide() {
    this.setState({ manualAddModalIsOpen: false })
  }

  renderManualAddModalContent() {
    switch (this.state.manualAddState) {
      case 'conditions':
        return (
          <RenderAddConditionsManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderConditions={this.getProviderConditions}
            currentAddModalData={this.state.currentAddModalData}
            currentElementIndex={this.state.currentElementIndex}
            currentAllUserData={this.state.currentAllUserData}
            getPatientConditions={this.props.fetchPatientConditions}
          />
        )
      case 'allergies':
        return (
          <RenderAddAllergiesManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderAllergies={this.getProviderAllergies}
            currentAddModalData={this.state.currentAddModalData}
          />
        )
      case 'foodsensitivities':
        return (
          <RenderAddFoodSensitivitiesManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderFoodSensitivities={this.getProviderFoodSensitivities}
            currentAddModalData={this.state.currentAddModalData}
          />
        )
      case 'immunizations':
        return (
          <RenderAddImmunizationsManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderImmunization={this.getProviderImmunization}
            currentAddModalData={this.state.currentAddModalData}
          />
        )
      case 'medications':
        return (
          <RenderAddMedicationsManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderMedications={this.getProviderMedications}
            currentAddModalData={this.state.currentAddModalData}
          />
        )
      case 'vitamins':
        return (
          <RenderAddVitaminsManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderMedications={this.getProviderMedications}
            currentAddModalData={this.state.currentAddModalData}
          />
        )
      // case 'goals':
      //   return (
      //     <RenderAddGoalsManually
      //       onManualAddModalHide={this.onManualAddModalHide}
      //       getProviderGoals={this.getProviderGoals}
      //       currentAddModalData={this.state.currentAddModalData}
      //     />
      //   )
      case 'procedures':
        return (
          <RenderAddProceduresManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderProcedures={this.getProviderProcedures}
            currentAddModalData={this.state.currentAddModalData}
          />
        )
      case 'observations':
        return (
          <RenderAddObservationsManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderObservations={this.getProviderObservations}
            currentAddModalData={this.state.currentAddModalData}
          />
        )
      case 'familyHistory':
        return (
          <RenderAddFamilyHistoryManually
            onManualAddModalHide={this.onManualAddModalHide}
            getFamilyHistory={this.props.fetchPatientCoreDate}
            currentAddModalData={this.state.currentAddModalData}
          />
        )

      default:
        return null
    }
  }

  renderEducationContent() {
    // let {healthModules} = this.state
    // if(!healthModules)
    //   return null
    // let content = healthModules.map((x, index) =>
    //   <div key={x} className={Styles.contentRow}>
    //     <div style={{width: 150, height: 20, border: '1px solid gray', marginRight: 20, borderRadius: 5}}>
    //       <div style={{borderRadius: 5, backgroundColor: Constants.primaryTheme, width: this.getRandomInt(150), height: '100%'}}>
    //       </div>
    //     </div>
    //     <p>{x}</p>
    //   </div>
    // )
    // return (
    //   <div style={{width: '40%', margin: 20}}>
    //     <h5 style={{color: Constants.primaryTheme}}>Health Modules Progress</h5>
    //     <div className={Styles.contentBackground} style={{height: 240, overflowY: 'scroll'}}>
    //       {content}
    //     </div>
    //   </div>
    // )
    return <EducationProgress />
  }

  renderDocInfoRow(label, value) {
    return (
      <div className={Styles.contentRow} style={{ width: '100%' }}>
        <p className={Styles.rowKey} style={{ width: '30%', marginRight: 4 }}>
          {label}
        </p>
        {value ? <p className={Styles.rowValue}>{value}</p> : <p>-</p>}
      </div>
    )
  }

  onMedicationsTabSelected(index) {
    switch (index) {
      case 1:
        this.setState({ showAilaMedications: true })
        this.getProviderMedications()
        return
      case 2:
        if (!this.state.externalMedications) this.getExternalMedications()
        this.setState({ showAilaMedications: false })
        return
      default:
        this.setState({ showAilaMedications: true })
    }
  }

  onConditionsTabSelected(index) {
    switch (index) {
      case 1:
        this.setState({ showAilaConditions: true })
        this.getProviderConditions()
        this.getProviderAllergies()
        this.getProviderFoodSensitivities()
        return
      case 2:
        if (!this.state.externalConditions) this.getExternalConditions()
        this.setState({ showAilaConditions: false })
        return
      default:
        this.setState({ showAilaConditions: true })
    }
  }
  onImmunizationTabSelected(index) {
    switch (index) {
      case 1:
        this.setState({ showAilaImmunization: true })
        this.getProviderImmunization()
        return
      case 2:
        if (!this.state.externalImmunizations) this.getExternalImmunizations()
        this.setState({ showAilaImmunization: false })
        return
      default:
        this.setState({ showAilaImmunization: true })
    }
  }

  onProcedureObservationTabSelected(index) {
    switch (index) {
      case 1:
        this.setState({ showAilaProcedureObservation: true })
        if (!this.state.providerProcedures?.length) this.getProviderProcedures()
        if (!this.state.providerObservations?.length)
          this.getProviderObservations()
        return
      case 2:
        if (!this.state.externalProcedures) this.getExternalProcedures()
        if (!this.state.externalObservations) this.getExternalObservations()
        this.setState({ showAilaProcedureObservation: false })
        return
      default:
        if (!this.state.providerProcedures?.length) this.getProviderProcedures()
        if (!this.state.providerObservations?.length)
          this.getProviderObservations()
        this.setState({ showAilaProcedureObservation: true })
    }
  }

  onCareTeamTabSelected(index) {
    switch (index) {
      case 1:
        this.setState({ showAilaCareTeam: true })
        return
      case 2:
        if (!this.state.externalCareTeam) this.getExternalCareTeam()
        this.setState({ showAilaCareTeam: false })
        return
      default:
        this.setState({ showAilaCareTeam: true })
    }
  }

  renderProceduresObservations() {
    let {
      loadingContent,
      externalProcedures,
      externalObservations,
      providerObservations,
      providerProcedures,
      showAilaProcedureObservation,
    } = this.state
    if (loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {/* <AilaExternalTabs
          onTabSelected={this.onProcedureObservationTabSelected.bind(this)}
          activeTab={showAilaProcedureObservation ? 1 : 2}
        /> */}
        {showAilaProcedureObservation ? (
          <ProviderProceduresObservations
            procedures={providerProcedures}
            observations={providerObservations}
            onManualAddClicked={this.onManualAddClicked}
          />
        ) : (
          <ExternalProceduresObservations
            procedures={externalProcedures}
            observations={externalObservations}
          />
        )}
      </div>
    )
  }

  renderScreenersTab() {
    let { patient, token } = this.props
    return <Screeners patientId={patient?.uid} token={token} />
  }

  renderImmunizations() {
    let {
      loadingContent,
      externalImmunizations,
      providerImmunization,
      showAilaImmunization,
    } = this.state
    if (loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {/* <AilaExternalTabs
          onTabSelected={this.onImmunizationTabSelected.bind(this)}
          activeTab={showAilaImmunization ? 1 : 2}
        /> */}
        {showAilaImmunization ? (
          <Immunizations
            patientCore={this.props.patientCore}
            patient={this.props.patient}
            immunizations={providerImmunization}
            onManualAddClicked={this.onManualAddClicked}
          />
        ) : (
          <ExternalImmunizations
            patientCore={this.props.patientCore}
            patient={this.props.patient}
            immunizations={externalImmunizations}
            onManualAddClicked={this.onManualAddClicked}
          />
        )}
      </div>
    )
  }

  renderCareTeam() {
    let { loadingContent, showAilaCareTeam, externalCareTeam } = this.state
    if (loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <CareTeamTabs
          onTabSelected={this.onCareTeamTabSelected.bind(this)}
          activeTab={showAilaCareTeam ? 1 : 2}
        />
        {showAilaCareTeam ? (
          this.renderAilaCareTeam()
        ) : (
          <ExternalCareTeam careTeam={externalCareTeam} />
        )}
      </div>
    )
  }

  renderAilaCareTeam() {
    let { ailaCareTeam } = this.state
    if (!ailaCareTeam) return null

    let doctorElements = []
    ailaCareTeam.forEach((doc) => {
      let name = doc.first_name + ' ' + doc.last_name
      doctorElements.push(
        <div className={Styles.docImageWrapper}>
          <img
            src={
              doc.image_url && doc.image_url.length
                ? doc.image_url
                : DocPlaceHolder
            }
            height={100}
            width={100}
            style={{ borderRadius: '50%', marginBottom: 4 }}
          />
          <div style={{ width: '100%', marginLeft: 10 }}>
            {this.renderDocInfoRow('Name', name)}
            {this.renderDocInfoRow('Profile', doc.user_type)}
            {this.renderDocInfoRow('Speciality', doc.speciality)}
            {this.renderDocInfoRow('Sub Speciality', doc.sub_speciality)}
          </div>
        </div>,
      )
    })

    return (
      <div
        style={{
          width: '80%',
          margin: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {doctorElements}
      </div>
    )
  }

  handleInsuranceCallback = (childData) => {
    console.log('callback::', childData)
    const self = this
    const { token, patient } = this.props
    if (childData) {
      self.setState({ showInsuranceModal: false }, () => {
        self.props.toggleSidebar(false)
        self.setState({
          error: false,
          errorString: '',
          loading: true,
          saving: true,
        })
        self.props.fetchPatientCoreDate(patient.uid, token)

        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        self.setState({ loading: false, saving: false })
        const userInsurance = Array.isArray(patient.insurance)
          ? patient.insurance[0]
          : patient.insurance || null
        this.setState({ userInsurance: userInsurance }, () => {
          console.log('updated user insurance')
        })
      })
    } else {
      self.setState({ insuranceModalIsOpen: false })
      self.props.toggleSidebar(false)
    }
  }

  renderPatientProfileModal() {
    const { patientCore } = this.props
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.patientProfileEdit(false)}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit Patient Profile
        </h4>
        <PatientAccount fromProvider={true} />
      </div>
    )
  }

  renderPatientPCPModal() {
    const { patientCore } = this.props
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.patientPCPEdit(false)}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit Patient PCP Profile
        </h4>
        <PatientPCP fromProvider={true} />
      </div>
    )
  }

  renderInsuranceModal() {
    const { patientCore } = this.props

    let primary_insurance = Array.isArray(patientCore.insurance)
      ? patientCore.insurance?.find((ins) => ins.preference === 1) || null
      : patientCore.insurance || null

    let secondary_insurance = Array.isArray(patientCore.insurance)
      ? patientCore.insurance?.find((ins) => ins.preference === 2)
      : {}

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => this.onInsuranceEditClicked(false)}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Edit Patient Insurance
        </h4>
        <PatientInsurance
          parentCallback={this.handleInsuranceCallback.bind(this)}
          primaryInsurance={primary_insurance}
          secondaryInsurance={secondary_insurance}
          fromProvider={true}
        />
      </div>
    )
  }

  // renderContactInfoModal() {
  //   const { patientCore } = this.props

  //   let primary_insurance = Array.isArray(patientCore.insurance)
  //     ? patientCore.insurance?.find((ins) => ins.preference === 1) || null
  //     : patientCore.insurance || null

  //   let secondary_insurance = Array.isArray(patientCore.insurance)
  //     ? patientCore.insurance?.find((ins) => ins.preference === 2)
  //     : {}

  //   return (
  //     <div
  //       style={{
  //         height: '100%',
  //         width: '100%',
  //         display: 'flex',
  //         flexDirection: 'column',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <p
  //         className={Styles.closeModalBtn}
  //         onClick={() => this.onContactInfoEditClicked(false)}
  //       >
  //         X
  //       </p>
  //       <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
  //         Edit Patient Contact Information
  //       </h4>
  //       <PatientInsurance
  //         parentCallback={this.handleInsuranceCallback.bind(this)}
  //         primaryInsurance={primary_insurance}
  //         secondaryInsurance={secondary_insurance}
  //         fromProvider={true}
  //       />
  //     </div>
  //   )
  // }

  renderGoalsModalContent() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() =>
            this.setState({
              goalmodalIsOpen: false,
              modalGoals: false,
              modalText: '',
            })
          }
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Goal
        </h4>
        {this.state.modalLoading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '100%',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div className={Styles.contentRow} style={{ marginTop: 60 }}>
            <input
              placeholder={'Goal Name'}
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              onChange={(e) =>
                this.setState({ modalText: e.target.value, modalError: false })
              }
            />
            <p style={{ marginRight: 20 }}>:</p>
            <input
              placeholder={'Goal Value'}
              className={Styles.textInput}
              style={{ width: '50%' }}
              type="text"
              onChange={(e) =>
                this.setState({ modalValue: e.target.value, modalError: false })
              }
            />
          </div>
        )}
        {this.state.modalError && (
          <p style={{ fontSize: 12, marginTop: 4, color: ' red' }}>
            Please enter the text in the field
          </p>
        )}
        <Button
          onClick={() => this.onModalSaveClicked()}
          className={GlobalStyles.button}
          disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    )
  }

  generatePdf(selectedNote) {
    let { token, conditions, patientCore, medications } = this.props
    let {
      providerMedications,
      providerAllergies,
      wellnessData,
      providerGoals,
    } = this.state
    console.log('cond::: ', selectedNote)
    let patient = { ...this.props?.patient, ...this.props?.patientCore } || {}
    let doctor = selectedNote.doctor || {}
    let data = {}
    let noteData = {}
    let icd_10_data = ''
    let cpt_data = ''
    let amended_cpt_data = ''
    let conditions_data = ''
    let medication_data = ''
    let allergies_data = ''
    let family_history_data = ''
    let goals_list = ''
    let referrals_requested = ''
    let time_spent = ''

    // INSURANCE
    let patientInsuranceData = patient?.insurance
    if (patientInsuranceData?.length > 1)
      patientInsuranceData = patientInsuranceData.find(
        (insurance) => insurance?.preference === 1,
      )

    // CONDITIONS
    let conditionsData = conditions || []
    // filter out user entered data
    conditionsData = conditionsData.filter(
      (userCondn) =>
        !this.state.providerConditions?.some(
          (prodCondn) =>
            prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
        ),
    )
    let newConditions = conditions?.concat(this.state.providerConditions)

    newConditions.forEach((condn, index) => {
      conditions_data = conditions_data.concat(
        index + 1,
        '. ',
        condn.symptom
          ? condn.symptom
          : condn?.code?.coding?.[0]?.display?.replace(/"/g, ''),
        condn?.code?.coding?.[0]?.display && !condn?.userEntered
          ? ' (Doctor Reported) '
          : '',

        index !== newConditions?.length && '\n',
      )
    })

    // Workers comp data
    // TODO: Edit this to be in the outmost part of the collection, not nested
    let ifWorkersCompData = patient?.insurance_type?.workers_comp || false
    let patientWorkersCompData = patient?.workers_comp_data || {}
    let wc_date_of_injury = patientWorkersCompData?.date_of_injury || 'N/A'
    let wc_claim = patientWorkersCompData?.claim_number || 'N/A'
    let wc_carrier = patientWorkersCompData?.wc_carrier || 'N/A'
    let wc_employer = patientWorkersCompData?.employer || 'N/A'
    let wc_adjuster = patientWorkersCompData?.adjuster || 'N/A'
    let wc_ur = patientWorkersCompData?.ur || 'N/A' // BUG: This doesn't exist
    let wc_attorney = patientWorkersCompData?.attorney || 'N/A'
    let wc_ncm = patientWorkersCompData?.ncm || 'N/A'
    // let wc_phone = workersCompData?.phone || 'N/A'
    console.log('wc data::: ', patientWorkersCompData)
    let pdfWCData = {
      wc_date_of_injury,
      wc_claim,
      wc_carrier,
      wc_employer,
      wc_adjuster,
      wc_ur,
      wc_attorney,
      wc_ncm,
    }

    console.log('noteId:::', selectedNote?.id)

    //ALLERGIES
    let allergies =
      patientCore && patientCore.allergies ? patientCore?.allergies : []
    let newAllergies = providerAllergies?.concat(allergies)
    newAllergies.forEach((allergy, index) => {
      allergies_data = allergies_data.concat(
        index + 1,
        '. ',
        allergy?.code?.coding?.[0]?.display ||
          (allergy?.type &&
            (allergy?.name?.length ? allergy?.name?.join() : allergy?.type)) ||
          allergy,
        index !== newAllergies?.length && '\n',
      )
    })

    //FAMILY HISTORY
    let familyScreening =
      patientCore && patientCore?.screening_family
        ? patientCore?.screening_family?.relations
        : []

    familyScreening.forEach((each, index) => {
      let conditions = []
      if (each.conditions)
        conditions = each.conditions.map(
          (x, index) =>
            `${x} ${index === each.conditions.length - 1 ? '' : ', '}`,
        )
      family_history_data = family_history_data.concat(
        index + 1,
        '. ',
        each.relationship,
        ' : ',
        conditions,
        index !== familyScreening?.length && '\n',
      )
    })

    //MEDICATION
    let array1 = (medications && medications['active_medications']) || []
    let array2 =
      (providerMedications &&
        providerMedications['active_medications_provider']) ||
      []

    let array3 = (medications && medications['inactive_medications']) || []
    let array4 =
      (providerMedications &&
        providerMedications['inactive_medications_provider']) ||
      []

    let finalArray = array4.concat(array3.concat(array2.concat(array1)))
    // medication_data = finalArray

    finalArray.forEach((x, index) => {
      console.log(
        ':-:',
        moment(x.start),
        moment(selectedNote.created_at),
        typeof x.start,
        typeof selectedNote.created_at,
        x.start < selectedNote.created_at,
      )
      if (moment(x.start) > moment(selectedNote.created_at)) return
      medication_data = medication_data.concat(
        index + 1,
        '. ',
        x.dose?.quantity
          ? // ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit}) : `
            `${x.name} (qty:${x.dose?.quantity}) : `
          : `${x.name} : `,

        x.frequency?.quantity
          ? `${x.frequency.quantity} / ${x.frequency.recurring} : `
          : '- : ',
        moment(x.start).format('MMM Do YYYY') + ' : ',
        x.end ? 'Inactive' : 'Active',
        index !== finalArray?.length && '\n',
      )
    })

    //NOTES DATA TO BE SHOWN IN THE PDF
    noteData = {
      selected_note: 'visible',
      chief_concern: selectedNote?.chief_concern,
      present_illness: selectedNote?.present_illness,
      physical_exam: selectedNote?.physical_exam,
    }

    let tempArray1 = selectedNote?.careplan || []
    tempArray1.forEach((careplan, index) => {
      icd_10_data = icd_10_data.concat(
        index + 1,
        '. ',
        careplan?.icd_10?.icd10_code,
        ' : ',
        careplan?.icd_10?.title,
        ' : ',
        careplan?.assessment,
        ' : ',
        careplan?.note,
        index !== tempArray1.length && '\n',
        // '\n',
      )
    })
    noteData.icd_10 = icd_10_data

    let tempArray2 = selectedNote?.cpt || []
    tempArray2.forEach(function (cpt, index) {
      cpt_data = cpt_data.concat(
        index + 1,
        '. ',
        cpt?.cpt_code,
        ' : ',
        cpt?.title,
        index !== tempArray2.length && '\n',
        // '\n',
      )
    })

    let tempAmendCptArray = selectedNote?.amended_cpt || []
    tempAmendCptArray.forEach(function (cpt, index) {
      amended_cpt_data = amended_cpt_data.concat(
        index + 1,
        '. ',
        cpt?.cpt_code,
        ' : ',
        cpt?.title,
        index !== tempAmendCptArray.length && '\n',
        // '\n',
      )
    })

    let socialHistory =
      questionMetadata['screenings/wellness']['lifestyle_habits.cigarette'] +
      `: ${wellnessData?.lifestyle_habits?.cigarette || 'N/A'}\n` +
      questionMetadata['screenings/wellness']['lifestyle_habits.alcohol'] +
      `: ${wellnessData?.lifestyle_habits?.alcohol || 'N/A'}\n` +
      questionMetadata['screenings/wellness']['lifestyle_habits.drugs'] +
      `: ${wellnessData?.lifestyle_habits?.drugs || 'N/A'}\n` +
      questionMetadata['screenings/wellness']['relationships.marital_status'] +
      `: ${wellnessData?.relationships?.marital_status || 'N/A'}\n`

    noteData.cpt_codes = cpt_data
    // if(selectedNote?.amended_cpt)
    noteData.amended_cpt_codes = amended_cpt_data
    noteData.authorization_number = selectedNote?.authorization_number
    noteData.diagnostic_review = selectedNote?.diagnostic_review?.text
      ? selectedNote?.diagnostic_review?.text
      : selectedNote?.diagnostic_review

    let bmiValues = Helpers.calculateBMIValues(
      patientCore.height,
      patientCore.weight_update || patientCore.weight,
      patientCore.gender,
      moment().diff(moment(patientCore.dob, 'DD/MM/YYYY'), 'years'),
    )

    providerGoals.forEach(function (entry, index) {
      goals_list = goals_list.concat(
        index + 1,
        '. ',
        entry.text === 'sleep_goal'
          ? 'Sleep'
          : entry.text === 'weight_goal'
          ? 'Weight'
          : entry.text === 'step_goal'
          ? 'Steps'
          : entry.text === 'meditation_goal'
          ? 'Meditation'
          : entry.text === 'exercise_goal'
          ? 'Exercise'
          : entry.text === 'weight_loss_goal'
          ? 'Weight Loss'
          : entry.text === 'change_habits'
          ? 'Habits'
          : entry?.text || 'N/A',
        ' : ',
        entry.value || 'N/A',
        index !== providerGoals.length && '\n',
        // '\n',
      )
    })

    let follow_up = selectedNote?.followupData?.follow_up_value
      ? `Requested follow up in ${selectedNote?.followupData?.follow_up_value} ${selectedNote?.followupData?.follow_up_unit?.label}`
      : 'N/A'

    referrals_requested =
      (selectedNote?.followupData?.refer_specialty_arr?.join(', ') ||
        selectedNote?.followupData?.refer_specialty?.label ||
        'N/A') +
      '\n' +
      (selectedNote?.followupData?.referral_suggested || '')

    time_spent = `
    Video Visit Start Time: ${moment
      .unix(selectedNote.appointment_date / 1000)
      .format('lll')} ${new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)} \n
    Video Visit End Time: ${moment
      .unix(selectedNote.appointment_end_date / 1000)
      .format('lll')} ${new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
      .substring(4)} \n
    Video Consult Minutes: ${moment
      .unix(selectedNote.appointment_end_date / 1000)
      .diff(
        moment.unix(selectedNote.appointment_date / 1000),
        'minute',
      )} minutes \n
    Pre-Visit Review of Records: ${
      selectedNote?.time_spent?.pre_review_time || 0
    } minutes \n
    After Visit Documentation: ${
      selectedNote?.time_spent?.after_visit || 0
    } minutes \n
    Provider Collaboration: ${
      selectedNote?.time_spent?.provider_colab || 0
    } minutes \n
    Total Time Spent: ${
      Number(
        moment
          .unix(selectedNote.appointment_end_date / 1000)
          .diff(moment.unix(selectedNote.appointment_date / 1000), 'minute') ||
          0,
      ) +
      Number(selectedNote.time_spent?.pre_review_time || 0) +
      Number(selectedNote.time_spent?.after_visit || 0) +
      Number(selectedNote.time_spent?.provider_colab || 0)
    } minutes\n
    Interpreter Present at Visit: N/A\n
    Method of visit: ${selectedNote.time_spent?.method_of_visit || 'N/A'}
    `
    let provider_credentials =
      doctor?.degree === 'Medical Doctor'
        ? 'MD'
        : doctor?.degree === 'Doctor of Osteopathic Medicine'
        ? 'DO'
        : ''
    let careplan_note = ''
    let assessment_note = ''
    if (selectedNote?.careplan?.length) {
      let tempArray = selectedNote?.careplan || []
      tempArray.forEach((icd, index) => {
        if (icd?.assessment)
          assessment_note = assessment_note.concat(
            icd?.assessment,
            index !== tempArray?.length && '\n',
          )
        if (icd?.note)
          careplan_note = careplan_note.concat(
            icd?.note,
            index !== tempArray?.length && '\n',
          )
      })
    }
    if (selectedNote?.assessment_note)
      assessment_note = selectedNote?.assessment_note
    if (selectedNote?.careplan_note) careplan_note = selectedNote?.careplan_note
    console.log('npi', doctor)
    data = {
      //PATIENT INFORMATION
      date_of_service: `${moment
        .unix(selectedNote.appointment_date / 1000)
        .format('MM/DD/YYYY')}`,
      patient_name: patient?.firstName + ' ' + patient?.lastName,
      patient_dob: moment(patient?.dob, 'DD/MM/YYYY').format('MM/DD/YYYY'),
      patient_gender: patient?.gender,
      patient_address: patient?.streetAddress,
      patient_phone: patient?.phoneNumber || 'N/A',
      patient_email: patient?.email || 'N/A',
      insurance_provider:
        patientInsuranceData?.insurance_provider ||
        patientInsuranceData?.insurance_company?.insurance_name ||
        'N/A',
      insurance_plan: patientInsuranceData?.plan_type || 'N/A',
      insurance_member: patientInsuranceData?.member_name || 'N/A',
      insurance_subscriber: patientInsuranceData?.subscriber_id || 'N/A',
      insurance_group: patientInsuranceData?.group_number || 'N/A',
      height: patientCore.height
        ? `${Math.floor(patientCore.height / 12)} ft ${
            patientCore.height % 12
          } in`
        : 'N/A',
      weight:
        (patientCore?.weight_update || patientCore?.weight || 'N/A') + ' lbs',
      bmi: bmiValues?.bmi || 'N/A',
      social_history: socialHistory,
      diagnostic_review: selectedNote?.diagnostic_review?.no_review
        ? 'There are no studies to review at today’s visit'
        : selectedNote?.diagnostic_review?.text || 'N/A',
      goals_list,
      follow_up,
      auth_request: selectedNote?.followupData?.requested_auth || 'N/A',
      referrals_requested,
      time_spent,
      assessment_note,
      careplan_note,
      provider_chief_concern: selectedNote?.provider_chief_concern || '',
      provider_illness: selectedNote?.provider_illness || '',
      //PROVIDER INFORMATION
      provider_signature: doctor?.sign_image,
      provider_name: doctor?.first_name + ' ' + doctor?.last_name,
      provider_speciality: doctor?.speciality,
      provider_npi: doctor?.npi?.number,
      provider_credentials,
      medication_data: medication_data,
      allergies_data: allergies_data,
      conditions_data: conditions_data,
      family_history_data: family_history_data,
      careplan: [{}], // Hack to just make it easier to deploy faster
      // careplan: [{}] || selectedNote.careplan,
      icd10s: selectedNote?.careplan?.map((x) => x?.icd_10?.icd10_code) || '-',
      other_items_addressed: 'N/A',
      method_of_visit:
        selectedNote?.time_spent?.method_of_visit || 'Telehealth - Video Visit',
      // PCP Info
      pcp_doc_credentials:
        patientCore?.pcp?.first_name + ' ' + patientCore?.pcp?.last_name ||
        'N/A',
      pcp_phone: patientCore?.pcp?.phone_number || 'N/A',
      pcp_fax: patientCore?.pcp?.fax || 'N/A',
      // BUG: This needs to read data form correct place
      phq9_data: 'N/A',
      gad_data: 'N/A',
      waist_circumference: 'N/A',
      bp: 'N/A',
      hr: 'N/A',
      o2_saturation: 'N/A',
      rpm_devices_ordered: 'N/A',
      address_confirmed: 'N/A',
      rpm_eligible: 'N/A',
      ccm_eligible: 'N/A',
      complex_eligible: 'N/A',
      date_time: `${moment(selectedNote.updated_at).format(
        'MM/DD/YYYY HH:MM:SS',
      )}`,
    }
    console.log('TESTING: ', data)
    console.log(
      'The appointment event id is:',
      selectedNote?.appointment_event_id || null,
    )
    console.log('Token', token)
    Axios({
      method: 'get',
      url: `${Config.BACKEND_URL}careplan/orders`,
      // url: `${Config.BACKEND_URL}health`,
      headers: {
        Authorization: 'JWT ' + token,
        'Content-Type': 'application/json',
      },
      params: {
        appointment_event_id: selectedNote?.appointment_event_id || null,
      },
    })
      .then((careplanAdditionalData) => {
        console.log('careplanAdditionalData', careplanAdditionalData)
        let allOrdersData = careplanAdditionalData?.data
        let ordersData = {
          lab_orders:
            allOrdersData?.lab_orders?.length > 0
              ? allOrdersData?.lab_orders
                  ?.map((x) => {
                    // TODO: I think there can be only 1 test per order as of now
                    return (
                      x?.tests[0]?.name +
                      ' on ' +
                      moment(x.created_at).format('MMMM Do YYYY')
                    )
                  })
                  .join('\n')
              : 'N/A',
          rx_orders:
            allOrdersData?.rx_orders?.length > 0
              ? allOrdersData?.rx_orders
                  ?.map((x) => {
                    return (
                      x?.drug?.name +
                      ' quantity: ' +
                      x?.quantity +
                      ' on ' +
                      moment(x.created_at).format('MMMM Do YYYY')
                    )
                  })
                  .join('\n')
              : 'N/A',
          infusion_orders:
            allOrdersData?.infusion_orders?.length > 0
              ? allOrdersData?.infusion_orders
              : 'N/A',
          imaging_orders:
            allOrdersData?.imaging_orders?.length > 0
              ? allOrdersData?.imaging_orders
                  ?.map((x) => {
                    return (
                      x?.order_type +
                      ' on ' +
                      moment(x.created_at).format('MMMM Do YYYY')
                    )
                  })
                  .join('\n')
              : 'N/A',
          dme_orders:
            allOrdersData?.dme_orders?.length > 0
              ? allOrdersData?.dme_orders
              : 'N/A',
        }
        // Confirm this
        // VisitNotes is the careplan note for a normal visit,
        // Notes is the careplan note for a async visit I think
        Axios(selectedNote.appointment_date ? VisitNotesPdf : NotesPdf).then(
          (res) => {
            try {
              let templateHtml = Handlebars.compile(res.data.toString())
              let bodyHtml = templateHtml({
                ...data,
                ...noteData,
                conditions_data,
                ...pdfWCData,
                ...ordersData,
              })
              let url = Config.CHANGE_BACKEND_URL + `pdf/generate`
              let postData = {
                htmlContent: bodyHtml,
              }

              //API REQUEST TO GET PDF BASE64 RIGHT FORMAT FROM BACKEND - PUPPETEER
              axios({
                method: 'post',
                headers: {
                  Authorization: 'JWT ' + token,
                  x_firebase_id: patient.uid,
                },
                url: url,
                data: postData,
              })
                .then(({ data }) => {
                  console.log('got data back from the pdf generator')
                  // Insert a link that allows the user to download the PDF file
                  let firstName = patient?.firstName || patient?.first_name
                  let lastName = patient?.lastName || patient?.last_name
                  let dateOfNoteCreated = moment(
                    selectedNote?.created_at,
                  ).format('MM-DD-YYYY')
                  // console.log(
                  //   'Downloading data:',
                  //   firstName,
                  //   lastName,
                  //   dateOfNoteCreated,
                  // )
                  var link = document.createElement('a')
                  link.innerHTML = 'Download PDF file'
                  link.download = `Patient Note ${firstName?.charAt(
                    0,
                  )}${lastName.charAt(0)} ${dateOfNoteCreated}.pdf`
                  link.href =
                    'data:application/octet-stream;base64,' + data.result
                  // document.body.appendChild(link);
                  link.click()
                  console.log('downloaded')
                  toast.success('Note downloaded')
                })
                .catch((err) => {
                  console.log('error 111', err)
                  toast.error('Could not download Notes, please try again')
                })
            } catch (err) {
              console.log('error generating pdf', err)
            }
          },
        )
      })
      .catch((err) => {
        console.log('error here:', err)
      })
  }

  renderCarePlanNotes() {
    // let { careplan } = this.state;
    let patientCarePlanData = this.props?.patientCarePlanData || []
    // console.log('testing patientCarePlanData: ', patientCarePlanData)
    return (
      <>
        {patientCarePlanData?.map((carePlanData, index) => {
          return (
            // latest notes from the provider
            <div
              key={index}
              className={Styles.contentRow}
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <img
                  src={
                    carePlanData?.doctor?.image_url
                      ? carePlanData?.doctor?.image_url
                      : DocPlaceHolder
                  }
                  height={50}
                  width={50}
                  style={{ borderRadius: '50%', margin: 10 }}
                />
                <h6 style={{ color: Constants.primaryTheme }}>
                  {carePlanData?.doctor?.first_name}{' '}
                  {carePlanData?.doctor?.last_name}{' '}
                  {carePlanData?.doctor?.speciality
                    ? `(${carePlanData?.doctor?.speciality})` || ''
                    : ''}{' '}
                  {', '}
                  {carePlanData?.appointment_date
                    ? moment(carePlanData?.appointment_date).format(
                        'MMM Do YYYY',
                      )
                    : moment(carePlanData?.created_at, 'YYYY-MM-DD').format(
                        'MMM Do YYYY',
                      )}
                </h6>
              </div>
              <div style={{ width: '100%' }} className={Styles.carePlanRow}>
                <div style={{ width: '80%' }}>
                  <h6 style={{ color: Constants.primaryTheme }}></h6>
                  {carePlanData?.careplan &&
                    carePlanData?.careplan?.map((careplan_note, index) => (
                      <p key={index} style={{ marginLeft: 20 }}>
                        <b>{careplan_note?.icd_10?.title?.replace(/"/g, '')}</b>{' '}
                        - {careplan_note.note || ''}
                      </p>
                    ))}
                </div>
                {
                  // carePlanData?.careplan?.icd_10 &&
                  <div style={{ width: '20%' }}>
                    <p
                      style={{
                        textDecoration: 'underline',
                        textDecorationColor:
                          carePlanData?.note_status === 'completed' ||
                          !carePlanData?.note_id
                            ? Constants.primaryTheme
                            : 'red',
                        color:
                          carePlanData?.note_status === 'completed' ||
                          !carePlanData?.note_id
                            ? Constants.primaryTheme
                            : 'red',
                        cursor: 'pointer',
                        textAlign: 'center',
                      }}
                      onClick={() => {
                        // CHECK: Whats happening here? Why are we checking for _id?
                        // The code is same irrespective
                        console.log(
                          'carePlanData: ',
                          // careplanData?.authorization_number,
                          //   carePlanData,
                        )
                        if (carePlanData?._id) {
                          this.setState({
                            modalAddNotesIsOpen: true,
                            notesFromVisit: carePlanData,
                          })
                        } else {
                          this.setState({
                            modalAddNotesIsOpen: true,
                            notesFromVisit: carePlanData,
                          })
                        }
                      }}
                    >
                      {carePlanData?.note_status === 'completed' ||
                      !carePlanData?._id
                        ? carePlanData?.appointment_date
                          ? 'Notes from visit'
                          : 'Notes'
                        : 'Incomplete note'}
                    </p>
                  </div>
                }
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    color: Constants.primaryTheme,
                    fontSize: 18,
                    // position: 'absolute',
                    // right: 5,
                    // top: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.generatePdf(carePlanData)
                  }}
                />
              </div>
            </div>
          )
        })}
      </>
    )
  }

  renderCarePlan() {
    let { patient, token, adminId } = this.props
    let { loadingContent } = this.state
    if (loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    if (!patient || !patient.careplan) return null
    let careplan = []

    let temp = {}
    patient.careplan.forEach((each) => {
      let date = moment(each.created_at).format('YYYY-MM-DD')
      let array = temp[date] || []
      array.push(each)
      temp[date] = array
    })

    Object.keys(temp).forEach((dateKey) => {
      let array = temp[dateKey]
      let entries = array.map((x) => (
        <p style={{ overflowWrap: 'break-word', marginLeft: 20 }}>- {x.text}</p>
      ))
      careplan.push(
        <div
          className={Styles.contentRow}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <h6 style={{ color: Constants.primaryTheme }}>
            {moment(dateKey, 'YYYY-MM-DD').format('MMM Do YYYY')}
          </h6>
          {entries}
        </div>,
      )
    })

    return (
      <div
        style={{
          width: '100%',
          margin: 10,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <NotesModal
          modalIsOpen={this.state.modalAddNotesIsOpen}
          hideNotesModal={this.hideNotesModal}
          getUserCarePlan={() =>
            this.props.fetchPatientCarePlanData({ patient, token, adminId })
          }
          carePlanNotesData={this.state.notesFromVisit}
          patientId={patient.uid}
        />
        <div style={{ width: '50%', margin: 20 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <h5 style={{ color: Constants.primaryTheme }}>Care Plan</h5>
            <FontAwesomeIcon
              onClick={() =>
                this.setState({ modalAddNotesIsOpen: true, notesFromVisit: {} })
              }
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                position: 'absolute',
                right: 5,
                top: 10,
                cursor: 'pointer',
              }}
            />
          </div>
          <div
            className={Styles.contentBackground}
            style={{ height: 500, overflowY: 'scroll' }}
          >
            {this.renderCarePlanNotes()}
          </div>
          <MessageChat />
          <AppointmentList />
          <RpmMonitoringLog />
        </div>
        <div
          style={{
            width: '50%',
            margin: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <CareteamChat /> {/* Care Team Chats */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <h5 style={{ color: Constants.primaryTheme }}>
              Care Team Task List
            </h5>
            <FontAwesomeIcon
              onClick={() =>
                this.setState({
                  modalAddTaskIsOpen: true,
                  modalSupportData: null,
                })
              }
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                position: 'absolute',
                right: 5,
                top: 10,
                cursor: 'pointer',
              }}
            />
          </div>
          <div
            className={Styles.contentBackground}
            style={{ height: 500, overflowY: 'scroll' }}
          >
            {this.state.loadingTask ? (
              <div className={GlobalStyles.container}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div className={GlobalStyles.loader} />
                </div>
              </div>
            ) : (
              this.state.taskList.map((support, index) => (
                <div
                  key={index}
                  className={Styles.contentRow}
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <div
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <h6 style={{ color: Constants.primaryTheme }}>
                      Raised by: {support?.name} {`(${support?.userType})`}{' '}
                      <br />
                      {support?.due_date
                        ? 'Due date: ' +
                          moment(support?.due_date).format('MMM DD YYYY')
                        : ''}
                    </h6>
                    <p
                      style={{
                        textDecoration: 'underline',
                        textDecorationColor: Constants.primaryTheme,
                        color:
                          support.status === 'in_progress'
                            ? 'orange'
                            : support.status === 'completed'
                            ? Constants.primaryTheme
                            : 'red',
                        cursor: 'pointer',
                        textTransform: 'capitalize',
                      }}
                      onClick={() => {
                        this.setState({
                          modalAddTaskIsOpen: true,
                          modalSupportData: support,
                        })
                      }}
                    >
                      {support?.status?.replace('_', ' ') || 'not started'}
                    </p>
                  </div>
                  <div style={{ width: '100%' }}>
                    <p style={{ marginLeft: 10 }}>
                      Subject - {support?.subject}
                    </p>
                  </div>
                  <div style={{ width: '100%' }}>
                    <h6 style={{ color: Constants.primaryTheme }}></h6>
                    <p
                      data-tip
                      data-for={index + 'desc'}
                      style={{
                        marginLeft: 10,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {support?.description}
                    </p>
                    <ReactTooltip
                      id={index + 'desc'}
                      multiline={true}
                      place={'top'}
                      effect={'solid'}
                      clickable={true}
                      className={Styles.tooltip}
                    >
                      {support?.description}
                    </ReactTooltip>
                  </div>
                </div>
              ))
            )}
          </div>
          <TaskModal
            supportData={this.state.modalSupportData}
            modalIsOpen={this.state.modalAddTaskIsOpen}
            hideTaskModal={this.hideTaskModal}
            getUserTask={this.getUserTask}
          />
          <CommunicationsList />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'relative',
              marginTop: 40,
            }}
          >
            <h5 style={{ color: Constants.primaryTheme }}>Patient Task List</h5>
            <FontAwesomeIcon
              onClick={() =>
                this.setState({
                  modalPatientTaskIsOpen: true,
                  modalPatientTaskData: null,
                })
              }
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                position: 'absolute',
                right: 5,
                top: 10,
                cursor: 'pointer',
              }}
            />
          </div>
          <div
            className={Styles.contentBackground}
            style={{ height: 500, overflowY: 'scroll' }}
          >
            {this.state.loadingTask ? (
              <div className={GlobalStyles.container}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div className={GlobalStyles.loader} />
                </div>
              </div>
            ) : (
              this.state.patientTaskList.map((support, index) => (
                <div
                  className={Styles.contentRow}
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <div
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <h6 style={{ color: Constants.primaryTheme }}>
                      {/* Raised by: {support?.name} {`(${support?.userType})`}{' '}
                      <br /> */}
                      {support?.due_date
                        ? 'Due date: ' +
                          moment(support?.due_date).format('MMM DD YYYY')
                        : ''}
                    </h6>
                    <p
                      style={{
                        // textDecoration: 'underline',
                        textDecorationColor: Constants.primaryTheme,
                        color: support.checkedIn
                          ? Constants.primaryTheme
                          : 'red',
                        // cursor: 'pointer',
                        textTransform: 'capitalize',
                      }}
                      // onClick={() => {
                      //   this.setState({
                      //     modalPatientTaskIsOpen: true,
                      //     modalPatientTaskData: support,
                      //   })
                      // }}
                    >
                      {support?.checkedIn ? 'Done' : 'Not Started'}
                    </p>
                  </div>
                  <div style={{ width: '100%' }}>
                    <p style={{ marginLeft: 10 }}>
                      Subject - {support?.subject}
                    </p>
                  </div>
                  <div style={{ width: '100%' }}>
                    <h6 style={{ color: Constants.primaryTheme }}></h6>
                    <p
                      data-tip
                      data-for={index + 'desc'}
                      style={{
                        marginLeft: 10,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {support?.description}
                    </p>
                    <ReactTooltip
                      id={index + 'desc'}
                      multiline={true}
                      place={'top'}
                      effect={'solid'}
                      clickable={true}
                      className={Styles.tooltip}
                    >
                      {support?.description}
                    </ReactTooltip>
                  </div>
                </div>
              ))
            )}
          </div>
          <PatientTaskModal
            supportData={this.state.modalPatientTaskData}
            modalIsOpen={this.state.modalPatientTaskIsOpen}
            hideTaskModal={this.hideTaskModal}
            // getPatientTask={this.getPatientTask}
          />
        </div>
      </div>
    )
  }

  onDeleteGoalsPressed(delete_index) {
    let { providerGoals } = this.state
    let { patient, token, adminId } = this.props
    let toSave = {}
    this.setState({ error: false, errorString: '' })

    // console.log('DEL INDEX: ', delete_index)

    let temp = [...providerGoals]
    temp.splice(delete_index, 1)
    this.setState({ providerGoals: temp })
    toSave.goals = temp

    // console.log('TOSAVE GOALS: ', toSave.goals)
    // console.log('PROV GOALS:', providerGoals)

    let url = Config.BACKEND_URL + 'doctor/patient/goals-update'

    axios({
      method: 'put',
      headers: {
        Authorization: 'JWT ' + token,
        x_patient_id: patient.uid,
        x_doctor_id: adminId,
      },
      url: url,
      data: toSave,
    })
      .then(({ data }) => {
        patient.goals = this.state.providerGoals
        this.props.addPatient(patient)
        this.getPatientGoals()
        // this.getProviderGoals()
        // console.log('Updated goals in the backend successfully')
      })
      .catch((err) => {
        console.log('error when getting saving provider goals data', err)
      })
  }

  onSaveGoalsPressed() {
    let { providerGoals } = this.state
    let { patient, token, adminId } = this.props
    let toSave = {}
    this.setState({ error: false, errorString: '' })
    this.setState({ loadingContent: true })

    let url = Config.BACKEND_URL + 'doctor/patient/goals-update'
    toSave.goals = providerGoals

    axios({
      method: 'put',
      headers: {
        Authorization: 'JWT ' + token,
        x_patient_id: patient.uid,
        x_doctor_id: adminId,
      },
      url: url,
      data: toSave,
    })
      .then(({ data }) => {
        patient.goals = this.state.providerGoals
        this.props.addPatient(patient)
        this.getPatientGoals()
        this.setState({ loadingContent: false })
        // this.getProviderGoals()
        // console.log('Updated goals in the backend successfully')
      })
      .catch((err) => {
        console.log('error when getting saving provider goals data', err)
      })
  }

  renderProviderGoals() {
    let { providerGoals } = this.state
    if (!providerGoals) providerGoals = []
    if (providerGoals.length === 0) {
      // this.getProviderGoals()
      this.getPatientGoals
    }

    // providerGoals.reverse()
    let providergoals = providerGoals.map((entry, index) => (
      <div className={Styles.contentRow}>
        <p className={Styles.rowKey}>
          {entry.text === 'sleep_goal'
            ? 'Sleep'
            : entry.text === 'weight_goal'
            ? 'Weight'
            : entry.text === 'step_goal'
            ? 'Steps'
            : entry.text === 'meditation_goal'
            ? 'Meditation'
            : entry.text === 'exercise_goal'
            ? 'Exercise'
            : entry.text === 'weight_loss_goal'
            ? 'Weight Loss'
            : entry.text === 'change_habits'
            ? 'Habits'
            : entry.text}
        </p>
        <p style={{ marginRight: 5 }}>:</p>
        <div className={Styles.rowValue} style={{ width: '70%' }}>
          {/* <p>{entry.value ? entry.value : ''}</p> */}
          <input
            placeholder={'Goal'}
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={entry.value ? entry.value : ''}
            onChange={(e) => {
              let providerGoals = this.state.providerGoals
              providerGoals.forEach((g) => {
                if (g.text === entry.text) {
                  g.value = e.target.value
                }
              })
              this.setState({ providerGoals: providerGoals })
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 10,
            marginRight: 5,
          }}
        >
          <div
            onClick={this.onDeleteGoalsPressed.bind(this, index)}
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        </div>
      </div>
    ))

    return (
      <div style={{ width: '50%', margin: 10 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <h5 style={{ color: Constants.primaryTheme }}>Provider Goals</h5>
          <FontAwesomeIcon
            onClick={() =>
              this.setState({ goalmodalIsOpen: true, modalGoals: true })
            }
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              position: 'absolute',
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 340, overflowY: 'scroll' }}
        >
          {providergoals}
        </div>
        <div
          style={{
            width: '100%',
            marginTop: 20,
            marginRight: 10,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={this.onSaveGoalsPressed.bind(this)}
            className={GlobalStyles.button}
            disabled={this.state.loading}
            style={{ width: 100 }}
            variant="primary"
          >
            {this.state.loading ? 'Loading....' : 'Update'}
          </Button>
          {this.state.error && (
            <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>
              {this.state.errorString}
            </p>
          )}
        </div>
      </div>
    )
  }

  renderPatientGoals() {
    let { loadingContent, providerGoals, patientGoals } = this.state
    // let { patientGoals } = this.state
    if (!patientGoals) patientGoals = {}

    let habits = patientGoals['change_habits'] || []
    let habitsElement = habits.map((x, i) => (
      <span>
        {x}
        {i !== habits.length - 1 ? ', ' : ''}
      </span>
    ))
    let freeFormGoals = patientGoals['free_form_goals'] || []
    let freeFormElement = freeFormGoals.map((x) => (
      <div className={Styles.contentRow}>
        <p className={Styles.rowKey}>{x.title}</p>
        <p style={{ marginRight: 5 }}>:</p>
        <p className={Styles.rowValue}>{x.description}</p>
      </div>
    ))

    return (
      <div style={{ width: '50%', margin: 10 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Patient Goals</h5>
        <div
          className={Styles.contentBackground}
          style={{ height: 340, overflowY: 'scroll' }}
        >
          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Sleep</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['sleep_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['sleep_goal']} hrs
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Weight</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['weight_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['weight_goal']} lbs
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Steps</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['step_goal'] ? (
              <p className={Styles.rowValue}>{patientGoals['step_goal']}</p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Meditation</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['meditation_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['meditation_goal']} min
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Exercise</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['exercise_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['exercise_goal']} min
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Weight Loss</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['weight_loss_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['weight_loss_goal']}
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Habits</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['change_habits'] && patientGoals['change_habits'] ? (
              <p className={Styles.rowValue}>{habitsElement}</p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          {freeFormElement}
        </div>
      </div>
    )
  }
  renderGoalsContent() {
    let { loadingContent, providerGoals, patientGoals } = this.state

    if (this.state.loadingContent) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%',
            height: '60vh',
          }}
        >
          <div className={GlobalStyles.loader} />
        </div>
      )
    }

    return (
      // <div
      //   style={{
      //     width: '100%',
      //     margin: 10,
      //     display: 'flex',
      //     flexDirection: 'row',
      //   }}
      // >
      <div className={Styles.tabContentWrapper}>
        <div
          style={{ display: 'flex', flexDirection: 'row', minWidth: '100%' }}
        >
          {/* {this.renderPatientGoals()} */}
          {/* <ProviderGoals
          patientCore={this.props.patientCore}
          patient={this.props.patient}
          providerGoals={providerGoals}
          patientGoals={patientGoals}
          onManualAddClicked={this.onManualAddClicked}
          renderProviderGoals={this.renderProviderGoals.bind(this)}
        /> */}
          {this.renderPatientGoals()}
          {this.renderProviderGoals()}
        </div>
      </div>
      // </div>
    )
  }

  renderMainContent() {
    return (
      <div className={GlobalStyles.contentWrapper}>
        <Tabs
          onTabSelected={this.onTabSelected.bind(this)}
          tabIndex={this.state.selectedTab === 'care-plan' ? 10 : null}
        />

        {this.renderTabContent()}
      </div>
    )
  }

  render() {
    if (this.state.loading)
      return (
        <div className={GlobalStyles.container}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '100vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )

    return (
      <div className={GlobalStyles.container}>
        <PatientInfoStrip
          medications={this.props.medications}
          conditions={this.props.conditions}
          patient={this.props.patient}
          patientCore={this.props.patientCore}
          color={this.props.color}
          cohorts={this.props.mongoUser ? this.props.mongoUser.cohorts : []}
        />

        {this.renderMainContent()}

        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onRequestClose={() =>
            this.setState({
              modalIsOpen: false,
              modalGoals: false,
              modalText: '',
            })
          }
          style={customModalStyles}
          contentLabel="Modal"
        >
          {this.renderModalContent()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.manualAddModalIsOpen}
          onRequestClose={() => this.setState({ manualAddModalIsOpen: false })}
          style={manualAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderManualAddModalContent()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.goalmodalIsOpen}
          onRequestClose={() =>
            this.setState({
              goalmodalIsOpen: false,
              modalGoals: false,
              modalText: '',
            })
          }
          style={providerModalStyles}
          contentLabel="Modal"
        >
          {this.renderGoalsModalContent()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.insuranceModalIsOpen}
          onRequestClose={() => this.onInsuranceEditClicked(false)}
          style={insuranceAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderInsuranceModal()}
        </Modal>

        {/* <Modal
          ariaHideApp={false}
          isOpen={this.state.contactInfoModalIsOpen}
          onRequestClose={() => this.onContactInfoEditClicked(false)}
          style={insuranceAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderPatientProfileModal()}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.emergencyContactModalIsOpen}
          onRequestClose={() => this.onEmergencyContactEditClicked(false)}
          style={insuranceAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderPatientProfileModal()}
        </Modal> */}

        <Modal
          ariaHideApp={false}
          isOpen={this.state.editPatientProfileModal}
          onRequestClose={() => this.patientProfileEdit(false)}
          style={manualAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderPatientProfileModal()}
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.editPatientPCP}
          onRequestClose={() => this.patientPCPEdit(false)}
          style={manualAddModalStyles}
          contentLabel="Modal"
        >
          {this.renderPatientPCPModal()}
        </Modal>
      </div>
    )
  }
}

const ProviderProceduresObservations = ({
  procedures,
  observations,
  onManualAddClicked,
}) => {
  const [toDisplayProcedures, setToDisplayProcedures] = useState(null)
  const [toDisplayObservations, setToDisplayObservations] = useState(null)

  useEffect(() => {
    setToDisplayProcedures(procedures || [])
    setToDisplayObservations(observations || [])
  }, [procedures, observations])

  const renderObservationsElements = () => {
    let elements = toDisplayObservations.map((x, index) => {
      let type = x?.category?.[0].coding?.[0]?.display
      let coding = x?.code?.coding ? x.code.coding : []
      let name = coding.map((x) => (
        <span style={{ display: 'block' }}>{x.display}</span>
      ))
      let status = x?.status
      let date = x?.effectiveDateTime
        ? moment(x.effectiveDateTime).format('YYYY-MM-DD h:mm:ss A')
        : ''
      let lastUpdatedDate = x?.meta?.lastUpdated
        ? moment(x.meta.lastUpdated).format('YYYY-MM-DD h:mm:ss A')
        : 'N/A'
      let value = x?.valueQuantity
        ? `${x?.valueQuantity?.value} ${x?.valueQuantity?.unit}`
        : ''
      let outcome = x?.valueCodeableConcept?.text
        ? x?.valueCodeableConcept.text
        : ''
      // let outcome = valueCoding.map(x => <span style={{display: 'block'}}>{x.display}</span>)
      return (
        <div className={Styles.contentRow}>
          <p className={Styles.externalTableEntry} style={{ width: '30%' }}>
            {name}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '8%' }}>
            {type}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '7%' }}>
            {status}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '12%' }}>
            {date}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '10%' }}>
            {value}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '15%' }}>
            {outcome}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '12%' }}>
            {lastUpdatedDate}
          </p>
          <div
            className={Styles.externalTableEntry}
            onClick={() => onManualAddClicked('observations', x)}
            style={{ cursor: 'pointer', width: '5%' }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        </div>
      )
    })

    return (
      <div
        className={Styles.contentBackground}
        style={{ padding: 10, width: '100%' }}
      >
        <div className={Styles.contentRow}>
          <h6 className={Styles.externalTableHeader} style={{ width: '30%' }}>
            Name
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '8%' }}>
            Type
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '7%' }}>
            Status
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '12%' }}>
            Date
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '10%' }}>
            value
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '15%' }}>
            Outcome
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '12%' }}>
            Last Updated
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '5%' }}>
            Edit
          </h6>
        </div>

        <div style={{ height: 300, overflowY: 'scroll' }}>{elements}</div>
      </div>
    )
  }

  const renderProceduresElements = () => {
    let elements = toDisplayProcedures.map((x, index) => {
      let name = x?.code?.coding?.[0]?.display
      let reason = x.reasonCode?.[0]?.text
      let status = x?.status
      let period = x?.performedPeriod || {}
      let startDate = period
        ? moment(period.start).format('YYYY-MM-DD h:mm:ss A')
        : ''
      let endDate = period
        ? moment(period.end).format('YYYY-MM-DD h:mm:ss A')
        : ''
      let lastUpdatedDate = x?.meta?.lastUpdated
        ? moment(x.meta.lastUpdated).format('YYYY-MM-DD h:mm:ss A')
        : 'N/A'
      return (
        <div className={Styles.contentRow}>
          <p className={Styles.externalTableEntry} style={{ width: '25%' }}>
            {name}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '25%' }}>
            {reason}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '8%' }}>
            {status}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '12%' }}>
            {startDate}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '12%' }}>
            {endDate}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '12%' }}>
            {lastUpdatedDate}
          </p>
          <div
            className={Styles.externalTableEntry}
            onClick={() => onManualAddClicked('procedures', x)}
            style={{ cursor: 'pointer', width: '5%' }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        </div>
      )
    })

    return (
      <div
        className={Styles.contentBackground}
        style={{ padding: 10, width: '100%' }}
      >
        <div className={Styles.contentRow}>
          <h6 className={Styles.externalTableHeader} style={{ width: '25%' }}>
            Name
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '25%' }}>
            Reason
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '8%' }}>
            Status
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '12%' }}>
            Start
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '12%' }}>
            End
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '12%' }}>
            Last Updated
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '5%' }}>
            Edit
          </h6>
        </div>

        <div style={{ height: 300, overflowY: 'scroll' }}>{elements}</div>
      </div>
    )
  }

  return (
    <div style={{ width: '100%', margin: 10 }}>
      <div style={{ position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme }}>Procedures</h5>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() => onManualAddClicked('procedures')}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        {toDisplayProcedures?.length > 0 ? (
          renderProceduresElements()
        ) : (
          <p style={{ marginTop: 30, width: '100%', textAlign: 'center' }}>
            Nothing to show
          </p>
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme, marginTop: 10 }}>
          Observations
        </h5>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() => onManualAddClicked('observations')}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        {toDisplayObservations?.length > 0 ? (
          renderObservationsElements()
        ) : (
          <p style={{ marginTop: 30, width: '100%', textAlign: 'center' }}>
            Nothing to show
          </p>
        )}
      </div>
    </div>
  )
}

const ExternalProceduresObservations = ({ procedures, observations }) => {
  const [toDisplayProcedures, setToDisplayProcedures] = useState(null)
  const [toDisplayObservations, setToDisplayObservations] = useState(null)

  useEffect(() => {
    setToDisplayProcedures(procedures || [])
    setToDisplayObservations(observations || [])
  }, [procedures, observations])

  const renderObservationsElements = () => {
    let elements = toDisplayObservations.map((x) => {
      let type = x.resource?.category?.[0].coding?.[0]?.display
      let coding = x.resource?.code?.coding ? x.resource.code.coding : []
      let name = coding.map((x) => (
        <span style={{ display: 'block' }}>{x.display}</span>
      ))
      let status = x.resource?.status
      let date = x.resource?.effectiveDateTime
        ? moment(x.resource.effectiveDateTime).format('YYYY-MM-DD h:mm:ss A')
        : ''
      let lastUpdatedDate = x.resource?.meta?.lastUpdated
        ? moment(x.resource.meta.lastUpdated).format('YYYY-MM-DD h:mm:ss A')
        : 'N/A'
      let value = x.resource?.valueQuantity
        ? `${x.resource.valueQuantity.value} ${x.resource.valueQuantity.unit}`
        : ''
      let valueCoding = x.resource?.valueCodeableConcept?.coding
        ? x.resource.valueCodeableConcept.coding
        : []
      let outcome = valueCoding.map((x) => (
        <span style={{ display: 'block' }}>{x.display}</span>
      ))
      return (
        <div className={Styles.contentRow}>
          <p className={Styles.externalTableEntry} style={{ width: '30%' }}>
            {name}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '8%' }}>
            {type}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '7%' }}>
            {status}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '12%' }}>
            {date}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '15%' }}>
            {value}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '15%' }}>
            {outcome}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '12%' }}>
            {lastUpdatedDate}
          </p>
        </div>
      )
    })

    return (
      <div
        className={Styles.contentBackground}
        style={{ padding: 10, width: '100%' }}
      >
        <div className={Styles.contentRow}>
          <h6 className={Styles.externalTableHeader} style={{ width: '30%' }}>
            Name
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '8%' }}>
            Type
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '7%' }}>
            Status
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '12%' }}>
            Date
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '15%' }}>
            value
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '15%' }}>
            Outcome
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '12%' }}>
            Last Updated
          </h6>
        </div>

        <div style={{ height: 300, overflowY: 'scroll' }}>{elements}</div>
      </div>
    )
  }

  const renderProceduresElements = () => {
    let elements = toDisplayProcedures.map((x) => {
      let name = x.resource?.code?.text
      let reason = x.resource?.reasonReference?.[0]?.display
      let status = x.resource?.status
      let period = x.resource?.performedPeriod || {}
      let startDate = period
        ? moment(period.start).format('YYYY-MM-DD h:mm:ss A')
        : ''
      let endDate = period
        ? moment(period.end).format('YYYY-MM-DD h:mm:ss A')
        : ''
      let lastUpdatedDate = x.resource?.meta?.lastUpdated
        ? moment(x.resource.meta.lastUpdated).format('YYYY-MM-DD h:mm:ss A')
        : 'N/A'
      return (
        <div className={Styles.contentRow}>
          <p className={Styles.externalTableEntry} style={{ width: '25%' }}>
            {name}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '25%' }}>
            {reason}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '10%' }}>
            {status}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '13%' }}>
            {startDate}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '13%' }}>
            {endDate}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '13%' }}>
            {lastUpdatedDate}
          </p>
        </div>
      )
    })

    return (
      <div
        className={Styles.contentBackground}
        style={{ padding: 10, width: '100%' }}
      >
        <div className={Styles.contentRow}>
          <h6 className={Styles.externalTableHeader} style={{ width: '25%' }}>
            Name
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '25%' }}>
            Reason
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '10%' }}>
            Status
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '13%' }}>
            Start
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '13%' }}>
            End
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '13%' }}>
            Last Updated
          </h6>
        </div>

        <div style={{ height: 300, overflowY: 'scroll' }}>{elements}</div>
      </div>
    )
  }

  return (
    <div style={{ width: '100%', margin: 10 }}>
      <div style={{ position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme }}>Procedures</h5>
        {toDisplayProcedures?.length > 0 ? (
          renderProceduresElements()
        ) : (
          <p style={{ marginTop: 30, width: '100%', textAlign: 'center' }}>
            Nothing to show
          </p>
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme, marginTop: 10 }}>
          Observations
        </h5>
        {toDisplayObservations?.length > 0 ? (
          renderObservationsElements()
        ) : (
          <p style={{ marginTop: 30, width: '100%', textAlign: 'center' }}>
            Nothing to show
          </p>
        )}
      </div>
    </div>
  )
}

const ExternalConditions = ({ conditions }) => {
  const [toDisplayConditions, setToDisplayConditions] = useState(null)
  useEffect(() => {
    setToDisplayConditions(conditions || [])
  }, [conditions])

  const renderConditionsElements = () => {
    let elements = toDisplayConditions.map((x) => {
      let text = x.resource?.code?.text || 'N/A'
      let status = x.resource?.clinicalStatus?.coding || [{}]
      let active = status[0].code === 'active'
      let startDate = x.resource?.onsetDateTime
        ? moment(x.resource.onsetDateTime).format('MMM Do YYYY')
        : 'N/A'
      let endDate = active
        ? ''
        : moment(x.resource?.abatementDateTime).format('MMM Do YYYY')
      let lastUpdatedDate = x.resource?.meta?.lastUpdated
        ? moment(x.resource.meta.lastUpdated).format('MMM Do YYYY')
        : 'N/A'
      return (
        <div className={Styles.contentRow}>
          <p className={Styles.externalTableEntry} style={{ width: '30%' }}>
            {text}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '10%' }}>
            {status[0].code}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '20%' }}>
            {startDate}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '20%' }}>
            {endDate}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '20%' }}>
            {lastUpdatedDate}
          </p>
        </div>
      )
    })

    return (
      <div
        className={Styles.contentBackground}
        style={{ padding: 10, width: '100%' }}
      >
        <div className={Styles.contentRow}>
          <h6 className={Styles.externalTableHeader} style={{ width: '30%' }}>
            Name
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '10%' }}>
            Status
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '20%' }}>
            Onset Date
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '20%' }}>
            Abatement Date
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '20%' }}>
            Last Updated
          </h6>
        </div>

        <div style={{ height: 400, overflowY: 'scroll' }}>{elements}</div>
      </div>
    )
  }

  return (
    <div style={{ width: '100%', margin: 10 }}>
      <h5 style={{ color: Constants.primaryTheme }}>Conditions</h5>
      {toDisplayConditions?.length > 0 ? (
        renderConditionsElements()
      ) : (
        <p style={{ marginTop: 30, width: '100%', textAlign: 'center' }}>
          Nothing to show
        </p>
      )}
    </div>
  )
}

const ExternalCareTeam = ({ careTeam }) => {
  const [toDisplayCareTeam, setToDisplayCareTeam] = useState(null)
  useEffect(() => {
    setToDisplayCareTeam(careTeam || [])
  }, [careTeam])

  const renderRow = (label, value) => {
    return (
      <div className={Styles.contentRow} style={{ width: '100%' }}>
        <p className={Styles.rowKey} style={{ width: 240, marginRight: 4 }}>
          {label}
        </p>
        {value ? <p className={Styles.rowValue}>{value}</p> : <p>-</p>}
      </div>
    )
  }

  const renderCareTeamElements = () => {
    let elements = toDisplayCareTeam.map((x) => {
      let organization = x.resource?.managingOrganization
        ? x.resource.managingOrganization[0]
        : {}
      let active = x.resource?.status === 'active'
      let dates = x.resource?.period || {}
      let reasonCode = x.resource?.reasonCode || []
      let lastUpdatedDate = x.resource?.meta?.lastUpdated
        ? moment(x.resource.meta.lastUpdated).format('MMM Do YYYY')
        : 'N/A'
      let participants = x.resource?.participant || []
      let provider = participants[1] ? participants[1].member?.display : 'N/A'

      return (
        <div
          className={Styles.externalConditionsElement}
          style={{ height: 250, width: '80%' }}
        >
          {active ? (
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: 'green',
                fontSize: 25,
                position: 'absolute',
                top: -5,
                right: -5,
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{
                color: 'red',
                fontSize: 25,
                position: 'absolute',
                top: -5,
                right: -5,
              }}
            />
          )}
          {renderRow('Healthcare Organization', organization.display)}
          {renderRow('Healthcare professional', provider)}
          {renderRow(
            'Reason',
            reasonCode?.length > 0 ? reasonCode[0].text : 'N/A',
          )}
          {renderRow(
            'Dates',
            `${moment(dates.start).format('MMM Do YYYY')} - ${
              dates.end ? moment(dates.end).format('MMM Do YYYY') : ''
            }`,
          )}
          {renderRow('Last Updated', lastUpdatedDate)}
        </div>
      )
    })

    return (
      <div
        style={{ flexDirection: 'column', alignItems: 'center' }}
        className={Styles.externalConditionsWrapper}
      >
        {elements}
      </div>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {toDisplayCareTeam?.length > 0 ? (
        renderCareTeamElements()
      ) : (
        <p>Nothing to show</p>
      )}
    </div>
  )
}

const ExternalMedications = ({ medications }) => {
  const [toDisplayMedications, setToDisplayMedications] = useState(null)
  useEffect(() => {
    setToDisplayMedications(medications || [])
  }, [medications])

  const renderMedicationsElements = () => {
    let elements = toDisplayMedications.map((x) => {
      let effectiveDate = x.resource?.effectiveDateTime
        ? moment(x.resource.effectiveDateTime).format('MMM Do YYYY')
        : 'N/A'
      let lastUpdatedDate = x.resource?.meta?.lastUpdated
        ? moment(x.resource.meta.lastUpdated).format('MMM Do YYYY')
        : 'N/A'
      return (
        <div className={Styles.contentRow}>
          <p className={Styles.externalTableEntry} style={{ width: '40%' }}>
            {x.resource?.medicationCodeableConcept?.text}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '20%' }}>
            {effectiveDate}
          </p>
          <p className={Styles.externalTableEntry} style={{ width: '20%' }}></p>
          <p className={Styles.externalTableEntry} style={{ width: '20%' }}>
            {lastUpdatedDate}
          </p>
        </div>
      )
    })
    return (
      <div
        className={Styles.contentBackground}
        style={{ padding: 10, width: '100%' }}
      >
        <div className={Styles.contentRow}>
          <h6 className={Styles.externalTableHeader} style={{ width: '40%' }}>
            Name
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '20%' }}>
            Start
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '20%' }}>
            End
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '20%' }}>
            Last Updated
          </h6>
        </div>

        <div style={{ height: 400, overflowY: 'scroll' }}>{elements}</div>
      </div>
    )
  }

  return (
    <div style={{ width: '100%', margin: 10 }}>
      <h5 style={{ color: Constants.primaryTheme }}>Medications</h5>
      {toDisplayMedications?.length > 0 ? (
        renderMedicationsElements()
      ) : (
        <p style={{ marginTop: 30, width: '100%', textAlign: 'center' }}>
          Nothing to show
        </p>
      )}
    </div>
  )
}

const RenderAddProceduresManually = ({
  onManualAddModalHide,
  getProviderProcedures,
  currentAddModalData,
}) => {
  const token = useSelector((state) => state.authReducer.token)
  const patient = useSelector((state) => state.patientReducer.patient)
  const doctor_id = useSelector((state) => state.userReducer.adminId)
  const doctor_details = useSelector((state) => state.userReducer.mongoUser)
  const appointmentData = useSelector(
    (state) => state.appointmentReducer.appointmentData,
  )
  const showVideoModal = useSelector(
    (state) => state.genericReducer.showVideoModal,
  )
  // should be replaced with CPT codes
  const [procedureName, setProcedureName] = useState(
    currentAddModalData?.code?.coding?.[0]
      ? {
          id: currentAddModalData?.code?.coding?.[0].code,
          name: currentAddModalData?.code?.coding?.[0].display,
        }
      : {},
  )
  const [procedureQuery, setProcedureQuery] = useState('')
  const [noteValue, setNoteValue] = useState(
    currentAddModalData?.note?.[0] || '',
  )
  // const [complicationValue, setComplicationValue] = useState('')
  const [performerRole, setPerformerRole] = useState(
    currentAddModalData?.performer?.[0]?.function?.[0]?.coding
      ? {
          id: currentAddModalData?.performer?.[0]?.function?.[0]?.coding?.[0]
            ?.code,
          name: currentAddModalData?.performer?.[0]?.function?.[0]?.coding?.[0]
            ?.code,
        }
      : {},
  )
  const [performerQuery, setPerformerQuery] = useState('')
  const [bodysite, setBodysite] = useState(
    currentAddModalData?.bodySite?.[0]?.coding?.[0]
      ? {
          id: currentAddModalData?.bodySite?.[0]?.coding?.[0]?.code,
          name: currentAddModalData?.bodySite?.[0]?.coding?.[0]?.display,
        }
      : [],
  )
  const [bodysiteQuery, setBodysiteQuery] = useState('')
  const [procedureStartDate, setProcedureStartDate] = useState(
    currentAddModalData?.performedPeriod?.start
      ? currentAddModalData?.performedPeriod?.start
      : '',
  )
  const [procedureEndDate, setProcedureEndDate] = useState(
    currentAddModalData?.performedPeriod?.end
      ? currentAddModalData?.performedPeriod?.end
      : '',
  )
  const [clinicalStatus, setClinicalStatus] = useState(
    currentAddModalData?.status
      ? {
          value: currentAddModalData?.status,
          label: currentAddModalData?.status,
        }
      : '',
  )
  const [actorValue, setActorValue] = useState(
    currentAddModalData?.performer?.[0]?.actor?.reference
      ? {
          value:
            currentAddModalData?.performer?.[0]?.actor?.reference.split('/')[0],
          label:
            currentAddModalData?.performer?.[0]?.actor?.reference.split('/')[0],
        }
      : '',
  )
  const [reasonCode, setReasonCode] = useState(
    currentAddModalData?.reasonCode?.[0]?.text || '',
  )

  const [searchConditionValue, setSearchConditionValue] = useState('')

  const onClickSave = () => {
    // console.log('testing adding procedure')

    if (!procedureName?.name) {
      toast.error('Please select Procedure')
      return
    }

    let data = {
      patient_id: patient?.patient_id,
      doctor_id: doctor_id,
      resourceType: 'Procedure',
      code: {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: procedureName.id,
            display: procedureName.name,
          },
        ],
      },
      status: clinicalStatus?.value,
      reasonCode: [
        {
          text: reasonCode,
        },
      ],
      performedPeriod: {
        start: new Date(procedureStartDate),
        end: new Date(procedureEndDate),
      },
      recorder: {
        reference: `${doctor_details.user_type}/${doctor_id}`,
        // display: `${doctor_details.first_name} ${doctor_details.last_name}`
      },
      asserter: {
        reference: `${doctor_details.user_type}/${doctor_id}`,
        // display: `${doctor_details.first_name} ${doctor_details.last_name}`
      },
      subject: {
        reference: `Patient/${patient?.patient_id}`,
        // display: `${patient?.firstName} ${patient?.lastName}`
      },
      performer: [
        {
          // should be replaced with https://www.hl7.org/fhir/valueset-performer-role.html
          function: [
            {
              coding: [
                {
                  system: 'http://snomed.info/sct',
                  code: performerRole?.id,
                  display: performerRole?.name,
                },
              ],
            },
          ],
          actor: {
            reference:
              actorValue.value === 'patient'
                ? `Patient/${patient?.patient_id}`
                : `${doctor_details.user_type}/${doctor_id}`,
          },
        },
      ],
      bodySite: [
        {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: bodysite?.id,
              display: bodysite?.name,
            },
          ],
        },
      ],
      note: [noteValue],
      ...(appointmentData?.event_id &&
        showVideoModal && { appointment_event_id: appointmentData?.event_id }),
    }

    let config = {
      method: currentAddModalData ? 'put' : 'post',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
      url:
        Config.BACKEND_URL +
        (currentAddModalData?._id
          ? `fhir/Procedure/${currentAddModalData?._id}`
          : 'fhir/Procedure'),
      data: data,
    }

    axios(config)
      .then((resp) => {
        onManualAddModalHide()
        getProviderProcedures()
        console.log('SAVED DATA: ', data)
      })
      .catch((err) => {
        console.log('error when getting saving provider goals data', err)
      })

    console.log('SAVED DATA: ', data)
    onManualAddModalHide()
  }

  // const Hit = (props) => {
  //   let { hit } = props
  //   const handleClick = () => {
  //     eventFire(document.getElementById('clearQuery'), 'click')
  //     setProcedureName({ id: hit.CPTCode, name: hit.CodeDescription })
  //     setProcedureQuery('')
  //   }
  //   return (
  //     <div
  //       style={{ width: '100%', cursor: 'pointer' }}
  //       onClick={(e) => {
  //         handleClick(e)
  //       }}
  //     >
  //       {hit.CPTCode} : {hit.CodeDescription}
  //     </div>
  //   )
  // }

  // const BodyHit = (props) => {
  //   let { hit } = props
  //   const handleClick = () => {
  //     setBodysiteQuery('')
  //     eventFire(document.getElementById('clearQuery'), 'click')
  //     setBodysite({ id: hit.Code, name: hit.Display })
  //   }
  //   return (
  //     <div
  //       style={{ width: '100%', cursor: 'pointer' }}
  //       onClick={(e) => {
  //         handleClick(e)
  //       }}
  //     >
  //       {hit.Code} : {hit.Display}
  //     </div>
  //   )
  // }

  // const PerformerHit = (props) => {
  //   let { hit } = props
  //   const handleClick = () => {
  //     eventFire(document.getElementById('clearQuery'), 'click')
  //     setPerformerRole({ id: hit?.Code, name: hit?.Display })
  //     setPerformerQuery('')
  //   }
  //   return (
  //     <div
  //       style={{ width: '100%', cursor: 'pointer' }}
  //       onClick={(e) => {
  //         handleClick(e)
  //       }}
  //     >
  //       {hit?.Code} : {hit?.Display}
  //     </div>
  //   )
  // }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div
        style={{
          height: '90%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => onManualAddModalHide()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Procedures
        </h4>

        <div className={Styles.addManualRow}>
          <div className={Styles.addManualQuestion}>
            <SearchProvider config={esConfigCPTCode}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'search_term',
                        }}
                        searchAsYouType={true}
                        onSelectAutocomplete={(e) => {
                          console.log('SELECTING condition', e)
                          // setProcedureName({ id: e.id.raw, name: e.title.raw })
                          setProcedureName({
                            id: e.cpt_code.raw,
                            name: e.description.raw,
                          })
                          setSearchTerm('')
                        }}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search Conditions',
                                })}
                                // onChange={(e) => {
                                //   console.log('CHANGING-X', e)
                                // }}
                              />
                              {getAutocomplete()}
                            </div>
                            {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
            {/* <InstantSearch
              indexName={'CPT_CODES'}
              searchClient={condnClient}
              onSearchStateChange={({ query }) => setProcedureQuery(query)}
            >
              <SearchBox
                translations={{
                  placeholder: 'Search conditions',
                }}
              />
              <ClearQueryAndRefinements />
              {procedureQuery.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => Hit(props)} />
                </div>
              )}
            </InstantSearch> */}
          </div>
          <p className={Styles.selectedPtag}>
            {procedureName?.id
              ? `${procedureName?.id} - ${procedureName?.name}`
              : ''}
          </p>
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Reason: </p>
          <input
            placeholder={'Please enter reason for procedure'}
            className={Styles.addManualTextInput}
            type="text"
            value={reasonCode}
            onChange={(e) => setReasonCode(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Procedure start date: </p>
          <input
            placeholder={
              'Please enter estimated or actual date the procedure began'
            }
            className={Styles.addManualTextInput}
            type="datetime-local"
            max={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
            value={moment(procedureStartDate).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => setProcedureStartDate(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Procedure end date: </p>
          <input
            placeholder={'Please enter estimated date the procedure ended'}
            className={Styles.addManualTextInput}
            type="datetime-local"
            max={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
            value={moment(procedureEndDate).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => setProcedureEndDate(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Status: </p>
          <Select
            options={[
              { label: 'Preparation', value: 'preparation' },
              { label: 'In progress', value: 'in-progress' },
              { label: 'Not done', value: 'not-done' },
              { label: 'On hold', value: 'on-hold' },
              { label: 'Stopped', value: 'stopped' },
              { label: 'Completed', value: 'completed' },
              { label: 'Entered in error', value: 'entered-in-error' },
              { label: 'Unknown', value: 'unknown' },
            ]}
            className={Styles.addSelectInput}
            autoBlur={true}
            onChange={(val) => {
              setClinicalStatus(val)
            }}
            value={clinicalStatus}
            getOptionValue={(option) => option.label}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Function performed by: </p>
          <Select
            options={[
              { label: 'Practitioner', value: 'practitioner' },
              // { label: 'Organization', value: 'organization' },
              { label: 'Patient', value: 'patient' },
              // { label: 'RelatedPerson', value: 'relatedPerson' },
              // { label: 'Device', value: 'device' },
            ]}
            className={Styles.addSelectInput}
            autoBlur={true}
            onChange={(val) => {
              setActorValue(val)
            }}
            value={actorValue}
            getOptionValue={(option) => option.label}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <div className={Styles.addManualQuestion}>
            {/* <InstantSearch
              indexName={'PERFORMER_ROLES'}
              searchClient={condnClient}
              onSearchStateChange={({ query }) => setPerformerQuery(query)}
            >
              <SearchBox
                translations={{
                  placeholder: 'Search performer roles',
                }}
              />
              <ClearQueryAndRefinements />
              {performerQuery.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => PerformerHit(props)} />
                </div>
              )}
            </InstantSearch> */}
            <SearchProvider config={esConfigPerformerRoles}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'search_term',
                        }}
                        onSelectAutocomplete={(e) => {
                          // setPerformerRole({ id: e.id.raw, name: e.name.raw })
                          setPerformerRole({
                            id: e.code.raw,
                            name: e.display.raw,
                          })
                          setSearchTerm('')
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search perfomer roles',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
          <p className={Styles.selectedPtag}>
            {performerRole?.id
              ? `${performerRole?.id} - ${performerRole?.name}`
              : 'Select involvement of performer in procedure'}
          </p>
        </div>

        <div className={Styles.addManualRow}>
          <div className={Styles.addManualQuestion}>
            {/* <InstantSearch
              indexName={'BODY_SITES'}
              searchClient={condnClient}
              onSearchStateChange={({ query }) => setBodysiteQuery(query)}
            >
              <SearchBox
                translations={{
                  placeholder: 'Search body sites',
                }}
              />
              <ClearQueryAndRefinements />
              {bodysiteQuery.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => BodyHit(props)} />
                </div>
              )}
            </InstantSearch> */}
            <SearchProvider config={esConfigBodySites}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'search_term',
                        }}
                        onSelectAutocomplete={(e) => {
                          // setBodysite({ id: e.id.raw, name: e.name.raw })
                          setBodysite({ id: e.code.raw, name: e.display.raw })
                          setSearchTerm('')
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search body sites',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
          <p className={Styles.selectedPtag}>
            {bodysite?.id ? `${bodysite?.id} - ${bodysite?.name}` : ''}
          </p>
        </div>

        {/* <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Complications: </p>
          <textarea
            placeholder={'Please enter complications of the procedure'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '70%',
              // height: '40px',
            }}
            rows="2"
            type="text"
            value={complicationValue}
            onChange={(e) => setComplicationValue(e.target.value)}
          />
        </div> */}

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Note: </p>
          <textarea
            placeholder={'Please enter notes about condition'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '70%',
              // height: '40px',
            }}
            rows="2"
            type="text"
            value={noteValue}
            onChange={(e) => setNoteValue(e.target.value)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => onClickSave()}
          className={GlobalStyles.button}
          // disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const RenderAddObservationsManually = ({
  onManualAddModalHide,
  currentAddModalData,
  getProviderObservations,
}) => {
  const token = useSelector((state) => state.authReducer.token)
  const patient = useSelector((state) => state.patientReducer.patient)
  const doctor_id = useSelector((state) => state.userReducer.adminId)
  const doctor_details = useSelector((state) => state.userReducer.mongoUser)
  const appointmentData = useSelector(
    (state) => state.appointmentReducer.appointmentData,
  )
  const showVideoModal = useSelector(
    (state) => state.genericReducer.showVideoModal,
  )
  const [observation, setObservation] = useState(
    currentAddModalData?.code?.coding?.[0]
      ? {
          id: currentAddModalData?.code?.coding?.[0].code,
          name: currentAddModalData?.code?.coding?.[0].display,
        }
      : {},
  )
  const [observationQuery, setObservationQuery] = useState('')
  const [noteValue, setNoteValue] = useState(
    currentAddModalData?.note?.[0] || '',
  )
  const [effectiveDate, setEffectiveDate] = useState(
    currentAddModalData?.effectiveDateTime || '',
  )
  const [clinicalStatus, setClinicalStatus] = useState(
    currentAddModalData?.status
      ? {
          value: currentAddModalData?.status,
          label: currentAddModalData?.status,
        }
      : '',
  )
  const [valueQuantity, setValueQuantity] = useState(
    currentAddModalData?.valueQuantity?.value || '',
  )
  const [valueQuantityUnit, setValueQuantityUnit] = useState(
    currentAddModalData?.valueQuantity?.unit || '',
  )
  const [valueCodeableConcept, setValueCodeableConcept] = useState(
    currentAddModalData?.valueCodeableConcept?.text || '',
  )
  const [category, setCategory] = useState(
    currentAddModalData?.category?.[0]?.coding
      ? {
          value: currentAddModalData?.category?.[0]?.coding?.[0]?.code,
          label: currentAddModalData?.category?.[0]?.coding?.[0]?.display,
        }
      : [],
  )
  const [bodysite, setBodysite] = useState(
    currentAddModalData?.bodySite?.[0]?.coding?.[0]
      ? {
          id: currentAddModalData?.bodySite?.[0]?.coding?.[0]?.code,
          name: currentAddModalData?.bodySite?.[0]?.coding?.[0]?.display,
        }
      : [],
  )
  const [bodysiteQuery, setBodysiteQuery] = useState('')

  const onClickSave = () => {
    // console.log('testing adding observation')

    if (!observation?.name) {
      toast.error('Please select Observation')
      return
    }

    let data = {
      patient_id: patient?.patient_id,
      doctor_id: doctor_id,
      resourceType: 'Observation',
      code: {
        coding: [
          {
            system: 'http://loinc.org',
            code: observation?.id,
            display: observation?.name,
          },
        ],
      },
      status: clinicalStatus?.value,
      effectiveDateTime: new Date(effectiveDate),
      recorder: {
        reference: `${doctor_details.user_type}/${doctor_id}`,
        // display: `${doctor_details.first_name} ${doctor_details.last_name}`
      },
      subject: {
        reference: `Patient/${patient?.patient_id}`,
        display: `${patient?.firstName} ${patient?.lastName}`,
      },
      category: [
        {
          coding: [
            {
              system:
                'https://terminology.hl7.org/2.1.0/CodeSystem-observation-category.html',
              code: category.value,
              display: category.label,
            },
          ],
        },
      ],
      valueQuantity: {
        value: valueQuantity,
        unit: valueQuantityUnit,
        system: 'http://unitsofmeasure.org',
        code: valueQuantityUnit,
      },
      valueCodeableConcept: {
        text: valueCodeableConcept,
      },
      bodySite: [
        {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: bodysite?.id,
              display: bodysite?.name,
            },
          ],
        },
      ],
      note: [noteValue],
      issued: new Date(),
      ...(appointmentData?.event_id &&
        showVideoModal && { appointment_event_id: appointmentData?.event_id }),
    }

    let config = {
      method: currentAddModalData ? 'put' : 'post',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
      url:
        Config.BACKEND_URL +
        (currentAddModalData?._id
          ? `fhir/Observation/${currentAddModalData?._id}`
          : 'fhir/Observation'),
      data: data,
    }

    axios(config)
      .then((resp) => {
        onManualAddModalHide()
        getProviderObservations()
        console.log('SAVED DATA: ', data)
      })
      .catch((err) => {
        console.log('error when getting saving provider goals data', err)
      })

    console.log('SAVED DATA: ', data)
    onManualAddModalHide()
  }

  // const Hit = (props) => {
  //   let { hit } = props
  //   const handleClick = () => {
  //     eventFire(document.getElementById('clearQuery'), 'click')
  //     setObservation({ id: hit.LOINC_NUM, name: hit.SHORTNAME })
  //     setObservationQuery('')
  //   }
  //   return (
  //     <div
  //       style={{ width: '100%', cursor: 'pointer' }}
  //       onClick={(e) => {
  //         handleClick(e)
  //       }}
  //     >
  //       {hit?.LOINC_NUM} : {hit?.SHORTNAME}
  //     </div>
  //   )
  // }

  // const BodyHit = (props) => {
  //   let { hit } = props
  //   const handleClick = () => {
  //     setBodysiteQuery('')
  //     eventFire(document.getElementById('clearQuery'), 'click')
  //     setBodysite({ id: hit.Code, name: hit.Display })
  //   }
  //   return (
  //     <div
  //       style={{ width: '100%', cursor: 'pointer' }}
  //       onClick={(e) => {
  //         handleClick(e)
  //       }}
  //     >
  //       {hit.Code} : {hit.Display}
  //     </div>
  //   )
  // }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div
        style={{
          height: '90%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => onManualAddModalHide()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Observations
        </h4>

        <div className={Styles.addManualRow}>
          <div className={Styles.addManualQuestion}>
            {/* <InstantSearch
              indexName={'LOINC_CODES'}
              searchClient={condnClient}
              onSearchStateChange={({ query }) => setObservationQuery(query)}
            >
              <SearchBox
                translations={{
                  placeholder: 'Search observations',
                }}
              />
              <ClearQueryAndRefinements />
              {observationQuery.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => Hit(props)} />
                </div>
              )}
            </InstantSearch> */}
            <SearchProvider config={esConfigLoincCodes}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'long_common_name',
                        }}
                        onSelectAutocomplete={(e) => {
                          console.log('Selected LOINC code: ', e)
                          setObservation({
                            id: e?.loinc_num.raw,
                            name: e?.shortname.raw,
                          })
                          setSearchTerm('')
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search LOINC codes',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
          <p className={Styles.selectedPtag}>
            {observation?.id ? `${observation?.id} - ${observation?.name}` : ''}
          </p>
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Effective date: </p>
          <input
            placeholder={
              'Please enter estimated or actual date the procedure began'
            }
            className={Styles.addManualTextInput}
            type="datetime-local"
            max={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
            value={moment(effectiveDate).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => setEffectiveDate(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Status: </p>
          <Select
            options={[
              { label: 'Registered', value: 'registered' },
              { label: 'Preliminary', value: 'preliminary' },
              { label: 'Final', value: 'final' },
              { label: 'Amended', value: 'amended' },
            ]}
            className={Styles.addSelectInput}
            autoBlur={true}
            onChange={(val) => {
              setClinicalStatus(val)
            }}
            value={clinicalStatus}
            getOptionValue={(option) => option.label}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Category: </p>
          <Select
            options={[
              { label: 'Social History', value: 'social-history' },
              { label: 'Vital Signs', value: 'vital-signs' },
              { label: 'Imaging', value: 'imaging' },
              { label: 'Laboratory', value: 'laboratory' },
              { label: 'Procedure', value: 'procedure' },
              { label: 'Survey', value: 'survey' },
              { label: 'Exam', value: 'exam' },
              { label: 'Therapy', value: 'therapy' },
              { label: 'Activity', value: 'activity' },
            ]}
            className={Styles.addSelectInput}
            autoBlur={true}
            onChange={(val) => {
              setCategory(val)
            }}
            value={category}
            getOptionValue={(option) => option.label}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <div className={Styles.addManualQuestion}>
            {/* <InstantSearch
              indexName={'BODY_SITES'}
              searchClient={condnClient}
              onSearchStateChange={({ query }) => setBodysiteQuery(query)}
            >
              <SearchBox
                translations={{
                  placeholder: 'Search body sites',
                }}
              />
              <ClearQueryAndRefinements />
              {bodysiteQuery.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => BodyHit(props)} />
                </div>
              )}
            </InstantSearch> */}
            <SearchProvider config={esConfigBodySites}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'search_term',
                        }}
                        onSelectAutocomplete={(e) => {
                          setBodysite({ id: e?.code.raw, name: e?.display.raw })
                          setSearchTerm('')
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search body sites',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
          <p className={Styles.selectedPtag}>
            {bodysite?.id ? `${bodysite?.id} - ${bodysite?.name}` : ''}
          </p>
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Value: </p>
          <input
            placeholder={'Please enter value derived from observation'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '50%',
              // height: '40px',
            }}
            type="text"
            value={valueQuantity}
            onChange={(e) => setValueQuantity(e.target.value)}
          />
          <input
            placeholder={'Unit ( %, mg, etc...)'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '19%',
              // height: '40px',
            }}
            type="text"
            value={valueQuantityUnit}
            onChange={(e) => setValueQuantityUnit(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Outcome: </p>
          <input
            placeholder={'Please enter outcome of the observation'}
            className={Styles.addManualTextInput}
            type="text"
            value={valueCodeableConcept}
            onChange={(e) => setValueCodeableConcept(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Note: </p>
          <textarea
            placeholder={'Please enter notes about observation'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '70%',
              // height: '40px',
            }}
            rows="2"
            type="text"
            value={noteValue}
            onChange={(e) => setNoteValue(e.target.value)}
          />
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => onClickSave()}
          className={GlobalStyles.button}
          // disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const RenderAddImmunizationsManually = ({
  onManualAddModalHide,
  getProviderImmunization,
  currentAddModalData,
}) => {
  console.log('INSIDE::::::::', currentAddModalData)
  const token = useSelector((state) => state.authReducer.token)
  const patient = useSelector((state) => state.patientReducer.patient)
  const doctor_id = useSelector((state) => state.userReducer.adminId)
  const doctor_details = useSelector((state) => state.userReducer.mongoUser)
  const appointmentData = useSelector(
    (state) => state.appointmentReducer.appointmentData,
  )
  const showVideoModal = useSelector(
    (state) => state.genericReducer.showVideoModal,
  )
  const [immunization, setImmunization] = useState(
    currentAddModalData?.vaccineCode?.coding
      ? {
          id: currentAddModalData?.vaccineCode?.coding?.[0]?.code,
          name: currentAddModalData?.vaccineCode?.coding?.[0]?.display,
        }
      : {},
  )
  const [clinicalStatus, setClinicalStatus] = useState(
    currentAddModalData?.status
      ? {
          value: currentAddModalData?.status,
          label: currentAddModalData?.status,
        }
      : {},
  )
  const [occurrenceDateTime, setOccurrenceDateTime] = useState(
    currentAddModalData?.occurrenceDateTime || '',
  )
  // const [location, setLocation] = useState('')
  const [doseQuantity, setDoseQuantity] = useState(
    currentAddModalData?.doseQuantity ? currentAddModalData.doseQuantity : 1,
  )
  const [actorValue, setActorValue] = useState(
    currentAddModalData?.performer?.[0]?.actor?.reference
      ? {
          value:
            currentAddModalData?.performer?.[0]?.actor?.reference.split('/')[0],
          label:
            currentAddModalData?.performer?.[0]?.actor?.reference.split('/')[0],
        }
      : '',
  )
  // const [functionValue, setFunctionValue] = useState('')

  const [performerRole, setPerformerRole] = useState(
    currentAddModalData?.performer?.[0]?.function?.[0]?.coding
      ? {
          id: currentAddModalData?.performer?.[0]?.function?.[0]?.coding?.[0]
            ?.code,
          name: currentAddModalData?.performer?.[0]?.function?.[0]?.coding?.[0]
            ?.display,
        }
      : {},
  )
  const [performerQuery, setPerformerQuery] = useState('')

  const horizontalLabels = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  }
  const [noteValue, setNoteValue] = useState(
    currentAddModalData?.note?.[0] || '',
  )

  const [conditionQuery, setConditionQuery] = useState('')

  const onClickSave = () => {
    if (!immunization?.name) {
      toast.error('Please select Immunization')
      return
    }
    let data = {
      patient_id: patient?.patient_id,
      doctor_id: doctor_id,
      name: immunization,
      resourceType: 'Immunization',
      status: clinicalStatus.code, // R!  completed | entered-in-error | not-done
      vaccineCode: {
        coding: [
          {
            system: 'http://hl7.org/fhir/sid/cvx', // Identity of the terminology system
            code: immunization.id, // Symbol in syntax defined by the system
            display: immunization.name, // Representation defined by the system
          },
        ],
      }, // R!  Vaccine product administered
      patient: {
        reference: `Patient/${patient?.patient_id}`,
        // display: `${patient?.first_name} ${patient?.last_name}`,
      }, // R!  Who was immunized

      occurrenceDateTime: occurrenceDateTime,
      performer: [
        {
          // should be replaced with https://www.hl7.org/fhir/valueset-performer-role.html
          function: [
            {
              coding: [
                {
                  system: 'http://snomed.info/sct',
                  code: performerRole?.id,
                  display: performerRole?.name,
                },
              ],
            },
          ],
          actor: {
            reference:
              actorValue.value === 'patient'
                ? `Patient/${patient?.patient_id}`
                : `${doctor_details.user_type}/${doctor_id}`,
          },
        },
      ],
      recorder: {
        reference: `${doctor_details.user_type}/${doctor_id}`,
        // display: `${doctor_details.first_name} ${doctor_details.last_name}`
      },
      note: [noteValue],
      recordedDate: new Date(),
      ...(appointmentData?.event_id &&
        showVideoModal && { appointment_event_id: appointmentData?.event_id }),
    }
    console.log('SAVED DATA: ', data)

    let config = {
      method: currentAddModalData ? 'put' : 'post',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
      url:
        Config.BACKEND_URL +
        (currentAddModalData?._id
          ? `fhir/Immunization/${currentAddModalData?._id}`
          : 'fhir/Immunization'),
      data: data,
    }

    axios(config)
      .then((resp) => {
        onManualAddModalHide()
        getProviderImmunization()
        console.log('SAVED DATA: ', data)
      })
      .catch((err) => {
        console.log('error when getting saving provider goals data', err)
      })
  }
  const Hit = (props) => {
    let { hit } = props
    const handleClick = () => {
      eventFire(document.getElementById('clearQuery'), 'click')
      setImmunization({ id: hit.Code, name: hit.Display })
      setConditionQuery('')
    }
    return (
      <div
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={(e) => {
          handleClick(e)
        }}
      >
        {hit.Code} : {hit.Display}
      </div>
    )
  }

  const PerformerHit = (props) => {
    let { hit } = props
    const handleClick = () => {
      eventFire(document.getElementById('clearQuery'), 'click')
      setPerformerRole({ id: hit?.Code, name: hit?.Display })
      setPerformerQuery('')
    }
    return (
      <div
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={(e) => {
          handleClick(e)
        }}
      >
        {hit?.Code} : {hit?.Display}
      </div>
    )
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div
        style={{
          height: '90%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <p
          className={Styles.closeModalBtn}
          onClick={() => onManualAddModalHide()}
        >
          X
        </p>
        <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
          Add Immunization
        </h4>

        {/* <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Immunization: </p>
          <input
            placeholder={'Enter the immunization'}
            className={Styles.addManualTextInput}
            type="text"
            value={immunization}
            onChange={(val) => setImmunization(val.target.value)}
          />
        </div> */}

        <div className={Styles.addManualRow}>
          <div className={Styles.addManualQuestion}>
            {/* <InstantSearch
              indexName={'IMMUNIZATIONS'}
              searchClient={condnClient}
              onSearchStateChange={({ query }) => setConditionQuery(query)}
            >
              <SearchBox
                translations={{
                  placeholder: 'Search immunization',
                }}
              />
              <ClearQueryAndRefinements />
              {conditionQuery.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => Hit(props)} />
                </div>
              )}
            </InstantSearch> */}
            <SearchProvider config={esConfigImmunization}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'display',
                        }}
                        inputProps={{
                          placeholder: 'Search immunization',
                        }}
                        onSelectAutocomplete={(e) => {
                          setImmunization({
                            id: e?.code.raw,
                            name: e?.display.raw,
                          })
                          setSearchTerm('')
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search immunization',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
          <p className={Styles.selectedPtag}>
            {immunization?.id
              ? `${immunization?.id} - ${immunization?.name}`
              : ''}
          </p>
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Status: </p>
          <Select
            options={[
              { code: 'completed', label: 'Completed' },
              { code: 'entered-in-error', label: 'Entered in error' },
              { code: 'not-done', label: 'Not done' },
            ]}
            isSearchable={true}
            placeholder="Select status"
            onChange={(val) => setClinicalStatus(val)}
            value={clinicalStatus}
            getOptionValue={(option) => option.label}
            className={Styles.addSelectInput}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Occurence Date: </p>
          <input
            placeholder={'Please enter the vaccine administration date'}
            className={Styles.addManualTextInput}
            type="date"
            max={moment(new Date()).format('YYYY-MM-DD')}
            value={moment(occurrenceDateTime).format('YYYY-MM-DD')}
            onChange={(e) => setOccurrenceDateTime(e.target.value)}
          />
        </div>

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Function performed by: </p>
          <Select
            options={[
              { label: 'Ordering Provider', value: 'OP' },
              { label: 'Administering Provider', value: 'AP' },

              // { label: 'Practitioner', value: 'practitioner' },
              // { label: 'Organization', value: 'organization' },
              // { label: 'Patient', value: 'patient' },
              // { label: 'RelatedPerson', value: 'relatedPerson' },
              // { label: 'Device', value: 'device' },
            ]}
            className={Styles.addSelectInput}
            autoBlur={true}
            onChange={(val) => {
              setActorValue(val)
            }}
            value={actorValue}
            getOptionValue={(option) => option.label}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>

        <div className={Styles.addManualRow}>
          <div className={Styles.addManualQuestion}>
            {/* <InstantSearch
              indexName={'PERFORMER_ROLES'}
              searchClient={condnClient}
              onSearchStateChange={({ query }) => setPerformerQuery(query)}
            >
              <SearchBox
                translations={{
                  placeholder: 'Search performer roles',
                }}
              />
              <ClearQueryAndRefinements />
              {performerQuery.length !== 0 && (
                <div
                  style={{
                    overflow: 'auto',
                    height: 150,
                    backgroundColor: '#F1F1F1',
                  }}
                >
                  <RefinementList limit={5} />
                  <Hits hitComponent={(props) => PerformerHit(props)} />
                </div>
              )}
            </InstantSearch> */}
            <SearchProvider config={esConfigPerformerRoles}>
              <WithSearch
                mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                  wasSearched,
                  setSearchTerm,
                })}
              >
                {({ wasSearched, setSearchTerm }) => {
                  // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                  return (
                    <div className="App">
                      <EsSearchBox
                        debounceLength={0}
                        autocompleteResults={{
                          titleField: 'search_term',
                        }}
                        onSelectAutocomplete={(e) => {
                          setPerformerRole({
                            id: e?.code.raw,
                            name: e?.display.raw,
                          })
                          setSearchTerm('')
                        }}
                        searchAsYouType={true}
                        inputView={({
                          getAutocomplete,
                          getInputProps,
                          getButtonProps,
                        }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: 'Search performer roles',
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                }}
              </WithSearch>
            </SearchProvider>
          </div>
          <p className={Styles.selectedPtag}>
            {performerRole?.id
              ? `${performerRole?.id} - ${performerRole?.name}`
              : ''}
          </p>
        </div>

        {/* <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Location: </p>
          <input
            placeholder={'Please enter where immunization occurred'}
            className={Styles.addManualTextInput}
            type="text"
            value={location}
            onChange={(val) => setLocation(val.target.value)}
          />
        </div> */}

        {/* <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Dose Quantity: </p>
          <div className={Styles.addSliderInput}>
            <Slider
              min={1}
              max={10}
              step={1}
              labels={horizontalLabels}
              value={doseQuantity}
              orientation="horizontal"
              onChange={(val) => setDoseQuantity(val)}
            />
          </div>
        </div> */}

        <div className={Styles.addManualRow}>
          <p className={Styles.addManualQuestion}>Note: </p>
          <textarea
            placeholder={'Please enter notes about immunization'}
            style={{
              border: '1px solid #d0d0d0',
              padding: '5px 20px',
              borderRadius: '4px',
              width: '70%',
              // height: '40px',
            }}
            rows="2"
            type="text"
            value={noteValue}
            onChange={(e) => setNoteValue(e.target.value)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => onClickSave()}
          className={GlobalStyles.button}
          // disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const RenderAddGoalsManually = ({
  onManualAddModalHide,
  getProviderGoals,
  currentAddModalData,
}) => {
  console.log('INSIDE::::::::', currentAddModalData)
  const token = useSelector((state) => state.authReducer.token)
  const patient = useSelector((state) => state.patientReducer.patient)
  const doctor_id = useSelector((state) => state.userReducer.adminId)
  const doctor_details = useSelector((state) => state.userReducer.mongoUser)
  const appointmentData = useSelector(
    (state) => state.appointmentReducer.appointmentData,
  )
  const showVideoModal = useSelector(
    (state) => state.genericReducer.showVideoModal,
  )

  const [goalQuery, setgoalQuery] = useState('')
  const [clinicalStatus, setClinicalStatus] = useState(
    currentAddModalData?.lifecycleStatus
      ? {
          code: currentAddModalData?.lifecycleStatus,
          label: currentAddModalData?.lifecycleStatus,
        }
      : {},
  )
  const [priority, setPriority] = useState(
    currentAddModalData?.priority?.coding
      ? {
          code: currentAddModalData?.priority?.coding?.[0]?.code,
          label: currentAddModalData?.priority?.coding?.[0]?.display,
        }
      : {},
  )
  const [desc, setDesc] = useState(
    currentAddModalData?.description?.coding
      ? {
          code: currentAddModalData?.description?.coding?.[0]?.code,
          label: currentAddModalData?.description?.coding?.[0]?.display,
        }
      : {},
  )
  const [noteValue, setNoteValue] = useState(
    currentAddModalData?.note?.[0] || '',
  )

  const onClickSave = () => {
    if (!desc?.label) {
      toast.error('Please select Goal')
      return
    }

    let goals = {
      patient_id: patient?.patient_id,
      doctor_id: doctor_id,
      resourceType: 'Goal',
      name: desc?.label ? desc.label : 'NA',
      AILA_ID: desc?.code ? desc.code : 'NA',
      lifecycleStatus: clinicalStatus.code, // R!  proposed | planned | accepted | active | on-hold | completed | cancelled | entered-in-error | rejected
      priority: {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/goal-priority',
            code: priority.code,
            display: priority.label,
          },
        ],
        text: priority.label,
      }, // high-priority | medium-priority | low-priority
      description: {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: desc.code,
            display: desc.label,
          },
        ],
        text: desc.label,
      }, // R!  Code or text describing goal
      subject: {
        reference: `Patient/${patient?.patient_id}`,
        // display: `${patient?.first_name} ${patient?.last_name}`
      },
      recorder: {
        reference: `${doctor_details.user_type}/${doctor_id}`,
        // display: `${doctor_details.first_name} ${doctor_details.last_name}`
      },
      note: [noteValue],
      recordedDate: new Date(),
      ...(appointmentData?.event_id &&
        showVideoModal && { appointment_event_id: appointmentData?.event_id }),
    }

    let config = {
      method: currentAddModalData ? 'put' : 'post',
      headers: { Authorization: 'JWT ' + token, x_firebase_id: doctor_id },
      url:
        Config.BACKEND_URL +
        (currentAddModalData?._id
          ? `fhir/Goal/${currentAddModalData?._id}`
          : 'fhir/Goal'),
      data: goals,
    }

    axios(config)
      .then((resp) => {
        onManualAddModalHide()
        // getProviderGoals()
        console.log('SAVED DATA: ', goals)
      })
      .catch((err) => {
        console.log('error when getting saving provider goals data', err)
      })
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
      }}
    >
      <p
        className={Styles.closeModalBtn}
        onClick={() => onManualAddModalHide()}
      >
        X
      </p>
      <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
        Add goal
      </h4>

      <div className={Styles.addManualRow}>
        <p className={Styles.addManualQuestion}>Goal: </p>
        <Select
          options={GoalDesc}
          isSearchable={true}
          placeholder="Select goal"
          onChange={(val) => setDesc(val)}
          value={desc}
          getOptionValue={(option) => option.label}
          className={Styles.addSelectInput}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#20A89233',
              primary: '#20A892',
            },
          })}
        />
      </div>

      <div className={Styles.addManualRow}>
        <p className={Styles.addManualQuestion}>Status: </p>
        <Select
          options={[
            { code: 'proposed', label: 'Proposed' },
            { code: 'planned', label: 'Planned' },
            { code: 'accepted', label: 'Accepted' },
            { code: 'active', label: 'Active' },
            { code: 'on-hold', label: 'On-hold' },
            { code: 'completed', label: 'Completed' },
            { code: 'cancelled', label: 'Cancelled' },
            { code: 'entered-in-error', label: 'Entered in Error' },
            { code: 'rejected', label: 'Rejected' },
          ]}
          isSearchable={true}
          placeholder="Select status"
          onChange={(val) => setClinicalStatus(val)}
          value={clinicalStatus}
          getOptionValue={(option) => option.label}
          className={Styles.addSelectInput}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#20A89233',
              primary: '#20A892',
            },
          })}
        />
      </div>

      <div className={Styles.addManualRow}>
        <p className={Styles.addManualQuestion}>Priority: </p>
        <Select
          options={[
            { code: 'high-priority', label: 'High Priority' },
            { code: 'medium-priority', label: 'Medium Priority' },
            { code: 'low-priority', label: 'Low Priority' },
          ]}
          isSearchable={true}
          placeholder="Select priority"
          onChange={(val) => setPriority(val)}
          value={priority}
          getOptionValue={(option) => option.label}
          className={Styles.addSelectInput}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#20A89233',
              primary: '#20A892',
            },
          })}
        />
      </div>

      <div className={Styles.addManualRow}>
        <p className={Styles.addManualQuestion}>Note: </p>
        <textarea
          placeholder={'Please enter Information about the goal'}
          style={{
            border: '1px solid #d0d0d0',
            padding: '5px 20px',
            borderRadius: '4px',
            width: '70%',
            // height: '40px',
          }}
          rows="2"
          type="text"
          value={noteValue}
          onChange={(e) => setNoteValue(e.target.value)}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => onClickSave()}
          className={GlobalStyles.button}
          // disabled={this.state.modalLoading}
          style={{ width: 200, position: 'absolute', bottom: 20 }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const ProviderGoals = (props) => {
  let {
    patientCore,
    patient,
    onManualAddClicked,
    providerGoals,
    patientGoals,
    renderProviderGoals,
  } = props

  // let { providerGoals } = this.state

  // function renderProviderGoals() {
  //   if (!providerGoals) providerGoals = []
  //   // providerGoals.reverse()

  //   let providergoalElement =
  //     providerGoals &&
  //     providerGoals.map((goal, index) => (
  //       <div
  //         className={Styles.contentRow}
  //         key={index}
  //         style={{ position: 'relative' }}
  //       >
  //         <p
  //           key={index.toString()}
  //           style={{ textAlign: 'left', padding: '2px 10px' }}
  //         >
  //           {goal?.description?.coding?.[0]?.display}
  //         </p>
  //         {goal?.description?.coding?.[0]?.display && (
  //           <div
  //             onClick={() => onManualAddClicked('goals', goal)}
  //             style={{ position: 'absolute', right: 40, cursor: 'pointer' }}
  //           >
  //             <FontAwesomeIcon
  //               icon={faEdit}
  //               style={{ color: Constants.primaryTheme, fontSize: 20 }}
  //             />
  //           </div>
  //         )}
  //       </div>
  //     ))

  //   return (
  //     <div style={{ width: '50%', margin: 10 }}>
  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'row',
  //           alignItems: 'center',
  //           position: 'relative',
  //         }}
  //       >
  //         <h5 style={{ color: Constants.primaryTheme }}>Provider Goals</h5>

  //         <div style={{ position: 'absolute', top: 0, right: 5 }}>
  //           <FontAwesomeIcon
  //             onClick={() => onManualAddClicked('goals')}
  //             icon={faPlusCircle}
  //             style={{
  //               color: Constants.primaryTheme,
  //               fontSize: 18,
  //               right: 14,
  //               cursor: 'pointer',
  //             }}
  //           />
  //         </div>
  //       </div>
  //       <div
  //         className={Styles.contentBackground}
  //         style={{ height: 340, overflowY: 'scroll' }}
  //       >
  //         {providergoalElement}
  //       </div>
  //     </div>
  //   )
  // }

  // function renderProviderGoals() {
  //   // let { patient } = this.props
  //   if (!patient || !patient.goals) return null

  //   let entries = patient.goals.map((x, index) => (
  //     <div className={Styles.contentRow} key={index.toString()}>
  //       {x.text}
  //     </div>
  //   ))

  //   entries.reverse()

  //   return (
  //     <div style={{ width: '50%', margin: 10 }}>
  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'row',
  //           alignItems: 'center',
  //           position: 'relative',
  //         }}
  //       >
  //         <h5 style={{ color: Constants.primaryTheme }}>Provider Goals</h5>
  //         <FontAwesomeIcon
  //           onClick={() =>
  //             // this.setState({ modalIsOpen: true, modalGoals: true })
  //             onManualAddClicked('goals')
  //           }
  //           icon={faPlusCircle}
  //           style={{
  //             color: Constants.primaryTheme,
  //             fontSize: 18,
  //             position: 'absolute',
  //             right: 14,
  //             cursor: 'pointer',
  //           }}
  //         />
  //       </div>
  //       <div
  //         className={Styles.contentBackground}
  //         style={{ height: 340, overflowY: 'scroll' }}
  //       >
  //         {entries}
  //       </div>
  //     </div>
  //   )
  // }

  function renderPatientGoals() {
    // let { patientGoals } = this.state
    if (!patientGoals) patientGoals = {}

    let habits = patientGoals['change_habits'] || []
    let habitsElement = habits.map((x, i) => (
      <span>
        {x}
        {i !== habits.length - 1 ? ', ' : ''}
      </span>
    ))
    let freeFormGoals = patientGoals['free_form_goals'] || []
    let freeFormElement = freeFormGoals.map((x) => (
      <div className={Styles.contentRow}>
        <p className={Styles.rowKey}>{x.title}</p>
        <p style={{ marginRight: 5 }}>:</p>
        <p className={Styles.rowValue}>{x.description}</p>
      </div>
    ))

    return (
      <div style={{ width: '50%', margin: 10 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Patient Goals</h5>
        <div
          className={Styles.contentBackground}
          style={{ height: 340, overflowY: 'scroll' }}
        >
          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Sleep</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['sleep_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['sleep_goal']} hrs
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Weight</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['weight_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['weight_goal']} lbs
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Steps</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['step_goal'] ? (
              <p className={Styles.rowValue}>{patientGoals['step_goal']}</p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Meditation</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['meditation_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['meditation_goal']} min
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Exercise</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['exercise_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['exercise_goal']} min
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Weight Loss</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['weight_loss_goal'] ? (
              <p className={Styles.rowValue}>
                {patientGoals['weight_loss_goal']}
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.rowKey}>Habits</p>
            <p style={{ marginRight: 5 }}>:</p>
            {patientGoals['change_habits'] && patientGoals['change_habits'] ? (
              <p className={Styles.rowValue}>{habitsElement}</p>
            ) : (
              <p>N/A</p>
            )}
          </div>

          {freeFormElement}
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.tabContentWrapper}>
      <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%' }}>
        {renderPatientGoals()}
        {/* BRO I THINK I DID STUFF THATS NOT EVEN THERE */}
        {/* {renderProviderGoals()} */}
      </div>
    </div>
  )
}

const Conditions = (props) => {
  let {
    patientCore,
    patient,
    onManualAddClicked,
    providerConditions,
    providerAllergies,
    providerFoodSensitivities,
  } = props
  // console.log('PROPS: ', props)
  function renderPatientConditions() {
    let conditions = props.conditions || []
    // filter out user entered data
    conditions = conditions.filter(
      (userCondn) =>
        !providerConditions?.some(
          (prodCondn) =>
            prodCondn?.code?.coding?.[0]?.display === userCondn?.symptom,
        ),
    )
    let newConditions = conditions?.concat(providerConditions)
    let conditionsElement =
      newConditions &&
      newConditions.map((condn, index) => (
        <div
          className={Styles.contentRow}
          key={index}
          style={{ justifyContent: 'space-between' }}
        >
          <p
            key={index.toString()}
            style={{ textAlign: 'left', padding: '2px 10px' }}
          >
            {condn.symptom ||
              condn?.code?.coding?.[0]?.display?.replace(/"/g, '')}
          </p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {condn?.code?.coding?.[0]?.display && !condn?.userEntered ? (
              <div style={{ marginRight: 10 }}>
                <FontAwesomeIcon
                  icon={faUserMd}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            ) : (
              <p>(user reported)</p>
            )}
            {(condn?.code?.coding?.[0]?.display || condn.symptom) && (
              <div
                onClick={() =>
                  onManualAddClicked('conditions', condn, index, conditions)
                }
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            )}
          </div>
        </div>
      ))
    return (
      <div style={{ width: '50%', margin: 10, position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme }}>Conditions</h5>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() => onManualAddClicked('conditions')}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {conditionsElement}
        </div>
      </div>
    )
  }

  function getFamilyHistoryFormatted(relations) {
    if (!relations) return null
    let toReturn = []
    relations.forEach((each) => {
      let conditions = []
      if (each.conditions)
        conditions = each.conditions.map((x, index) => (
          <span key={index.toString()} style={{ width: '80%' }}>
            {x}
            {index === each.conditions.length - 1 ? '' : ', '}
          </span>
        ))
      toReturn.push(
        <div
          key={each.relationship}
          className={Styles.contentRow}
          style={{ padding: '10px 10px' }}
        >
          <p className={Styles.contentRowKey}>{each.relationship}:</p>
          <p style={{ marginLeft: 5, overflowWrap: 'break-word' }}>
            {conditions}
          </p>
        </div>,
      )
    })

    return toReturn
  }

  function renderFamilyHistory() {
    let { patientCore } = props
    let familyScreening = patientCore && patientCore.screening_family
    let entries = []
    if (familyScreening) {
      entries = getFamilyHistoryFormatted(familyScreening.relations)
    }
    return (
      <div style={{ width: '50%', margin: 10, position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme }}>Family History</h5>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() => onManualAddClicked('familyHistory', familyScreening)}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {entries}
        </div>
      </div>
    )
  }

  function renderPatientUndiagnosedConditions() {
    let { patientCore, rareDisease } = props
    let pre_existing =
      patientCore && patientCore.pre_existing ? patientCore.pre_existing : {}
    let autoImmune = pre_existing.auto_immune || {}
    let livingChoice
    switch (pre_existing.undiagnosed_conditions) {
      case true:
        livingChoice = 'Yes'
        break
      case false:
        livingChoice = 'No'
        break
      default:
        livingChoice = 'N/A'
    }

    let userChoice
    switch (autoImmune.user_choice) {
      case true:
        userChoice = 'Yes'
        break
      case false:
        userChoice = 'No'
        break
      default:
        userChoice = 'N/A'
    }

    let undiagnosedConditionName
    if (autoImmune.dont_know) {
      undiagnosedConditionName = 'Not Sure'
    } else {
      undiagnosedConditionName = rareDisease && rareDisease.symptom
    }

    return (
      <div style={{ width: '50%', margin: 10, paddingRight: 20 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Undiagnosed</h5>
        <div className={Styles.contentBackground} style={{ height: 240 }}>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>
              Living with an undiagnosed condition
            </p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{livingChoice}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>
              Suspected to have rare disease
            </p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{userChoice}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Condition</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{undiagnosedConditionName}</p>
          </div>
        </div>
      </div>
    )
  }

  function renderAllergies() {
    let allergies =
      patientCore && patientCore.allergies ? patientCore?.allergies : []
    let newAllergies = providerAllergies?.concat(allergies)
    console.log('NEW ALL: ', newAllergies)
    let allergiesElement = newAllergies
      ? newAllergies?.map((allergy, index) => (
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p key={index} style={{ textAlign: 'left', padding: '2px 10px' }}>
              {allergy?.code?.coding?.[0]?.display ||
                (allergy?.type &&
                  (allergy?.name?.length
                    ? allergy?.name?.join()
                    : allergy?.type)) ||
                allergy}
            </p>
            {allergy?.code?.coding?.[0]?.display && (
              <div
                onClick={() => onManualAddClicked('allergies', allergy)}
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            )}
          </div>
        ))
      : []
    // console.log('testing: ', allergiesElement)
    return (
      <div style={{ width: '50%', margin: 10, position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme }}>Allergies</h5>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() => onManualAddClicked('allergies')}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {allergiesElement}
        </div>
      </div>
    )
  }

  function renderFoodSensitivities() {
    let foods =
      patientCore && patientCore.food_sensitivities
        ? patientCore.food_sensitivities.foods
        : []
    let newFoods = providerFoodSensitivities.concat(foods)
    let foodsElement =
      newFoods &&
      newFoods.map((food, index) => (
        <div
          className={Styles.contentRow}
          key={index}
          style={{ justifyContent: 'space-between' }}
        >
          <p
            key={index.toString()}
            style={{ textAlign: 'left', padding: '2px 10px' }}
          >
            {food?.code?.coding?.[0]?.display || food}
          </p>
          {food?.code?.coding?.[0]?.display && (
            <div
              onClick={() => onManualAddClicked('foodsensitivities', food)}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ color: Constants.primaryTheme, fontSize: 20 }}
              />
            </div>
          )}
        </div>
      ))
    return (
      <div style={{ width: '50%', margin: 10, position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme }}>Food Sensitivities</h5>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() => onManualAddClicked('foodsensitivities')}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {foodsElement}
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.tabContentWrapper}>
      <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%' }}>
        {renderPatientConditions()}
        {renderFamilyHistory()}
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%' }}>
        {renderAllergies()}
        {renderFoodSensitivities()}
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%' }}>
        {renderPatientUndiagnosedConditions()}
      </div>
    </div>
  )
}

const Immunizations = (props) => {
  let { immunizations, onManualAddClicked } = props

  console.log('IMMU PROPS: ', immunizations)

  if (!immunizations || immunizations.length === 0) {
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme, marginBottom: 10 }}>
          Immunizations
        </h5>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <FontAwesomeIcon
            onClick={() => onManualAddClicked('immunizations')}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <p style={{ marginTop: 40 }}>Nothing to display</p>
      </div>
    )
  }

  let elements = immunizations.map((x) => {
    let vaccineCode = x.vaccineCode || {}
    return (
      <div className={Styles.contentRow}>
        <h6 className={Styles.externalTableEntry} style={{ width: '30%' }}>
          {vaccineCode?.coding?.[0].display}
        </h6>
        <h6 className={Styles.externalTableEntry} style={{ width: '10%' }}>
          {vaccineCode?.coding?.[0].code}
        </h6>
        <h6 className={Styles.externalTableEntry} style={{ width: '10%' }}>
          {x?.status}
        </h6>
        <h6 className={Styles.externalTableEntry} style={{ width: '25%' }}>
          {x?.occurrenceDateTime &&
            moment(x.occurrenceDateTime).format('MMM Do YYYY')}
        </h6>
        <h6 className={Styles.externalTableEntry} style={{ width: '25%' }}>
          {x?.meta?.lastUpdated &&
            moment(x.meta.lastUpdated).format('MMM Do YYYY')}
        </h6>
        {vaccineCode?.coding?.[0]?.display && (
          <div
            onClick={() => onManualAddClicked('immunizations', x)}
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        )}
      </div>
    )
  })

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <h5 style={{ color: Constants.primaryTheme, marginBottom: 10 }}>
        Immunizations
      </h5>
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <FontAwesomeIcon
          onClick={() => onManualAddClicked('immunizations')}
          icon={faPlusCircle}
          style={{
            color: Constants.primaryTheme,
            fontSize: 18,
            right: 14,
            cursor: 'pointer',
          }}
        />
      </div>
      <div
        className={Styles.contentBackground}
        style={{ padding: 10, width: '100%' }}
      >
        <div className={Styles.contentRow}>
          <h6 className={Styles.externalTableHeader} style={{ width: '30%' }}>
            Name
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '10%' }}>
            Code
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '10%' }}>
            Status
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '25%' }}>
            Date
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '25%' }}>
            Last Updated
          </h6>
        </div>

        <div style={{ height: 400, overflowY: 'scroll' }}>{elements}</div>
      </div>
    </div>
  )
}

const ExternalImmunizations = (props) => {
  let { immunizations, onManualAddClicked } = props

  if (!immunizations || immunizations.length === 0) {
    return (
      <div style={{ width: '100%' }}>
        <h5 style={{ color: Constants.primaryTheme, marginBottom: 10 }}>
          Immunizations
        </h5>
        <p style={{ marginTop: 40 }}>Nothing to display</p>
      </div>
    )
  }

  let elements = immunizations.map((x) => {
    let vaccineCode = x.resource?.vaccineCode || {}
    return (
      <div className={Styles.contentRow}>
        <h6 className={Styles.externalTableEntry} style={{ width: '30%' }}>
          {vaccineCode?.coding?.[0].display}
        </h6>
        <h6 className={Styles.externalTableEntry} style={{ width: '10%' }}>
          {vaccineCode?.coding?.[0].code}
        </h6>
        <h6 className={Styles.externalTableEntry} style={{ width: '10%' }}>
          {x.resource?.status}
        </h6>
        <h6 className={Styles.externalTableEntry} style={{ width: '25%' }}>
          {x.resource?.occurrenceDateTime &&
            moment(x.resource.occurrenceDateTime).format('MMM Do YYYY')}
        </h6>
        <h6 className={Styles.externalTableEntry} style={{ width: '25%' }}>
          {x.resource?.meta?.lastUpdated &&
            moment(x.resource.meta.lastUpdated).format('MMM Do YYYY')}
        </h6>
      </div>
    )
  })

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <h5 style={{ color: Constants.primaryTheme, marginBottom: 10 }}>
        Immunizations
      </h5>
      {/* <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <FontAwesomeIcon
          onClick={() => onManualAddClicked('immunizations')}
          icon={faPlusCircle}
          style={{
            color: Constants.primaryTheme,
            fontSize: 18,
            right: 14,
            cursor: 'pointer',
          }}
        />
      </div> */}
      <div
        className={Styles.contentBackground}
        style={{ padding: 10, width: '100%' }}
      >
        <div className={Styles.contentRow}>
          <h6 className={Styles.externalTableHeader} style={{ width: '30%' }}>
            Name
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '10%' }}>
            Code
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '10%' }}>
            Status
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '25%' }}>
            Date
          </h6>
          <h6 className={Styles.externalTableHeader} style={{ width: '25%' }}>
            Last Updated
          </h6>
        </div>

        <div style={{ height: 400, overflowY: 'scroll' }}>{elements}</div>
      </div>
    </div>
  )
}

const Risks = () => {
  function renderRisksData() {
    return (
      <div style={{ width: '100%', margin: 10 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Risks</h5>
        <div className={Styles.contentBackground}>
          <div className={Styles.contentRow}>
            <p
              className={Styles.contentRowKey}
              style={{ width: '50%', textAlign: 'center' }}
            >
              Risk for ED Visit
            </p>
            <p style={{ textAlign: 'center', width: '50%' }}>Low</p>
          </div>
          <div className={Styles.contentRow}>
            <p
              className={Styles.contentRowKey}
              style={{ width: '50%', textAlign: 'center' }}
            >
              Kidney Disease Risk
            </p>
            <p style={{ textAlign: 'center', width: '50%' }}>
              None or Slight[05/05/2020]
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p
              className={Styles.contentRowKey}
              style={{ width: '50%', textAlign: 'center' }}
            >
              Fall Risk
            </p>
            <p style={{ textAlign: 'center', width: '50%' }}>Low</p>
          </div>
          <div className={Styles.contentRow}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className={Styles.redDot} />
              <p
                className={Styles.contentRowKey}
                style={{ textAlign: 'center' }}
              >
                Diabetes Risk
              </p>
            </div>
            <p style={{ textAlign: 'center', width: '50%' }}>
              Pre-diabetes[07/01/2020]
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p
              className={Styles.contentRowKey}
              style={{ width: '50%', textAlign: 'center' }}
            >
              10 Year ASCVD Risk
            </p>
            <p style={{ textAlign: 'center', width: '50%' }}>10%</p>
          </div>
          <div className={Styles.contentRow}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className={Styles.redDot} />
              <p
                className={Styles.contentRowKey}
                style={{ textAlign: 'center' }}
              >
                Depression Risk
              </p>
            </div>
            <p style={{ textAlign: 'center', width: '50%' }}>
              PHQ-9 Moderate Depression[05/05/2020]
            </p>
          </div>
          <div className={Styles.contentRow}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className={Styles.redDot} />
              <p
                className={Styles.contentRowKey}
                style={{ textAlign: 'center' }}
              >
                COVID-19 Screen
              </p>
            </div>
            <p style={{ textAlign: 'center', width: '50%' }}>
              Referred to doctor visit[05/05/2020]
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p
              className={Styles.contentRowKey}
              style={{ width: '50%', textAlign: 'center' }}
            >
              Breast Cancer Risk Score
            </p>
            <p style={{ textAlign: 'center', width: '50%' }}>N/A</p>
          </div>
          <div className={Styles.contentRow}>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className={Styles.redDot} />
              <p
                className={Styles.contentRowKey}
                style={{ textAlign: 'center' }}
              >
                COPD Risk
              </p>
            </div>

            <p style={{ textAlign: 'center', width: '50%' }}>
              5/10, requires HCP review
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p
              className={Styles.contentRowKey}
              style={{ width: '50%', textAlign: 'center' }}
            >
              Colorectal Cancer Risk
            </p>
            <p style={{ textAlign: 'center', width: '50%' }}>
              Higher than average
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p
              className={Styles.contentRowKey}
              style={{ width: '50%', textAlign: 'center' }}
            >
              Morbid Obesity
            </p>
            <p style={{ textAlign: 'center', width: '50%' }}>
              High[07/01/2020]
            </p>
          </div>
        </div>
      </div>
    )
  }

  return <div className={Styles.tabContentWrapper}>{renderRisksData()}</div>
}

const SocialTab = (props) => {
  function renderSocialDeterminantsInfo() {
    let { patientCore } = props
    let socialScreening = patientCore.screening_social || {}
    let maritalStatus =
      patientCore.relationships && patientCore.relationships.marital_status
    return (
      <div style={{ width: '100%', margin: 10 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Social Determinants</h5>
        <div className={Styles.contentBackground} style={{ height: 140 }}>
          <div className={Styles.contentRow} style={{ padding: '10px 10px' }}>
            <h6 className={Styles.tableHeader}>Employment Status</h6>
            <h6 className={Styles.tableHeader}>Occupation</h6>
            <h6 className={Styles.tableHeader}>Education Level</h6>
            <h6 className={Styles.tableHeader}>Marital Status</h6>
            <h6 className={Styles.tableHeader}>Number in Household</h6>
          </div>
          <div className={Styles.contentRow} style={{ padding: '10px 10px' }}>
            <p style={{ width: '20%', textAlign: 'center' }}>
              {Object.keys(socialScreening).length !== 0
                ? socialScreening.employment
                : '-'}
            </p>
            <p style={{ width: '20%', textAlign: 'center' }}>
              {Object.keys(socialScreening).length !== 0
                ? socialScreening.occupation
                : '-'}
            </p>
            <p style={{ width: '20%', textAlign: 'center' }}>
              {Object.keys(socialScreening).length !== 0
                ? socialScreening.education
                : '-'}
            </p>
            <p style={{ width: '20%', textAlign: 'center' }}>
              {Object.keys(socialScreening).length !== 0 ? maritalStatus : '-'}
            </p>
            <p style={{ width: '20%', textAlign: 'center' }}>
              {Object.keys(socialScreening).length !== 0
                ? socialScreening.household
                : '-'}
            </p>
          </div>
        </div>
      </div>
    )
  }

  function getCheckOrCross(flag, flag2) {
    return flag || flag2 ? '\u2713' : '\u2717'
  }

  function getContentRow(screening, access, label, key2) {
    return (
      <div className={Styles.contentRow}>
        <p
          className={Styles.contentRowKey}
          style={{ width: '50%', textAlign: 'center' }}
        >
          {label}
        </p>
        <p style={{ textAlign: 'center', width: '50%' }}>
          {Object.keys(screening).length !== 0
            ? getCheckOrCross(access.includes(label), access.includes(key2))
            : '-'}
        </p>
      </div>
    )
  }

  function getNeighborhoodContentRow(
    label,
    displayCheckOrCross,
    checkOrCrossFlag,
    stringToDisplay,
    description,
  ) {
    return (
      <div className={Styles.contentRow}>
        <p
          className={Styles.contentRowKey}
          style={{ width: '50%', textAlign: 'center' }}
        >
          {label}
        </p>
        {displayCheckOrCross ? (
          <p style={{ textAlign: 'center', width: '50%' }}>
            {getCheckOrCross(checkOrCrossFlag)}
          </p>
        ) : (
          <div style={{ width: '50%' }}>
            <p style={{ textAlign: 'center', fontSize: 14 }}>
              {stringToDisplay}
            </p>
            <p style={{ textAlign: 'center', fontSize: 12, color: 'gray' }}>
              {description}
            </p>
          </div>
        )}
      </div>
    )
  }

  function renderNeighborhoodContent() {
    let { sdohData } = props
    console.log('sdoh data', sdohData)
    let aqiString
    if (sdohData.aqi) {
      let aqiNumber = sdohData.aqi
      aqiString = `${aqiNumber}/500`
      AQI_BUCKETS.forEach((bucket) => {
        if (aqiNumber >= bucket.low && aqiNumber <= bucket.high) {
          aqiString += ` (${bucket.desc})`
        }
      })
    } else {
      aqiString = 'N/A'
    }

    let foodDesert = sdohData['foodDesert'] || {}
    let parks = sdohData['parks'] || {}
    let transit = sdohData['transit'] || {}
    let crime = sdohData['crime'] || {}
    let hospitalization = sdohData['hospitalization'] || {}
    let patientSatisfaction = sdohData['patientSatisfaction'] || {}
    let housing = sdohData['housing'] || {}

    return (
      <div style={{ width: '50%', margin: 10 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Neighborhood</h5>
        <div
          className={Styles.contentBackground}
          style={{ height: 500, overflowY: 'scroll', paddingBottom: 10 }}
        >
          {getNeighborhoodContentRow('Low Income', true, sdohData['lowIncome'])}
          {getNeighborhoodContentRow(
            'Average Housing Cost',
            false,
            false,
            housing.score,
            housing.desc,
          )}
          {getNeighborhoodContentRow(
            'Food Desert',
            false,
            false,
            foodDesert.score,
            foodDesert.desc,
          )}
          {getNeighborhoodContentRow('Air Quality', false, false, aqiString)}
          {getNeighborhoodContentRow(
            'Preventable Hospitalization Rate',
            false,
            false,
            hospitalization.score,
            hospitalization.desc,
          )}
          {getNeighborhoodContentRow(
            'Patient Satisfaction',
            false,
            false,
            patientSatisfaction.score,
            patientSatisfaction.desc,
          )}
          {getNeighborhoodContentRow(
            'Park Access',
            false,
            false,
            parks.score,
            parks.desc,
          )}
          {getNeighborhoodContentRow(
            'Public Transportation',
            false,
            false,
            transit.score,
            transit.desc,
          )}
          {getNeighborhoodContentRow(
            'Crime Rate',
            false,
            false,
            crime.score,
            crime.desc,
          )}
        </div>
      </div>
    )
  }

  function renderNeedsContent() {
    let { patientCore } = props
    let socialScreening =
      patientCore && patientCore.screening_social
        ? patientCore.screening_social
        : {}
    let access = socialScreening.access || []
    return (
      <div style={{ width: '50%', margin: 10 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Needs Assessment</h5>
        <div className={Styles.contentBackground} style={{ height: 500 }}>
          {getContentRow(socialScreening, access, 'Housing')}
          {getContentRow(socialScreening, access, 'Food')}
          {getContentRow(socialScreening, access, 'Transportation')}
          {getContentRow(socialScreening, access, 'Clothing')}
          {getContentRow(socialScreening, access, 'Medical Care', 'Healthcare')}
          <div className={Styles.contentRow}>
            <p
              className={Styles.contentRowKey}
              style={{ width: '50%', textAlign: 'center' }}
            >
              Money for Bills
            </p>
            <p style={{ textAlign: 'center', width: '50%' }}>
              {Object.keys(socialScreening).length !== 0
                ? getCheckOrCross(access.includes('Money to pay bills'))
                : '-'}
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.tabContentWrapper}>
      {renderSocialDeterminantsInfo()}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: 30,
        }}
      >
        {renderNeedsContent()}
        {renderNeighborhoodContent()}
      </div>
    </div>
  )
}

const GeneticsTab = (props) => {
  function renderGeneticsInfo() {
    let { patientCore } = props
    let data = patientCore && patientCore.genetics ? patientCore.genetics : []
    let links = data.map((x) => (
      <div className={Styles.contentRow}>
        <p style={{ width: '50%', textAlign: 'center' }}>{x.name}</p>
        <div style={{ width: '50%', textAlign: 'center' }}>
          <a href={x.link} target={'_blank'} rel="noopener noreferrer">
            Report
          </a>
        </div>
      </div>
    ))

    return (
      <div style={{ width: '50%', margin: 10 }}>
        <h5 style={{ color: Constants.primaryTheme }}>Genetics</h5>
        <div className={Styles.contentBackground} style={{ height: 240 }}>
          <div className={Styles.contentRow} style={{ padding: '10px 10px' }}>
            <p className={Styles.tableHeader} style={{ width: '50%' }}>
              Test
            </p>
            <p className={Styles.tableHeader} style={{ width: '50%' }}>
              File
            </p>
          </div>
          {links}
        </div>
      </div>
    )
  }
  return (
    <div className={Styles.tabContentWrapper}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {/* {renderFamilyHistory()} */}
        {renderGeneticsInfo()}
      </div>
    </div>
  )
}

const ReferralsTab = (props) => {
  return (
    <div className={Styles.tabContentWrapper}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '5% 1%',
        }}
      >
        <Table columns={referralsColumns()} data={[]} />
      </div>
    </div>
  )
}

const MedicationsTab = (props) => {
  let { medications, onManualAddClicked, providerMedications } = props

  function getMedicationsOrVitaminsRow(isMedications, active) {
    let key1, key2
    // console.log("Meds: ", providerMedications)

    if (isMedications) {
      if (active) {
        key1 = 'active_medications'
        key2 = 'active_medications_provider'
      } else {
        key1 = 'inactive_medications'
        key2 = 'inactive_medications_provider'
      }
    } else {
      if (active) {
        key1 = 'active_vitamins'
        key2 = 'active_vitamins_provider'
      } else {
        key1 = 'inactive_vitamins'
        key2 = 'inactive_vitamins_provider'
      }
    }

    let array1 = (medications && medications[key1]) || []
    let array2 = (providerMedications && providerMedications[key2]) || []
    let newArray = array2.concat(array1)
    let toReturn = newArray.map((x, i) => {
      let activeString = 'Active'
      if (!active)
        activeString = 'Inactive since ' + moment(x.end).format('MMM Do YYYY')
      return (
        <div key={i.toString()} className={Styles.contentRow}>
          <p style={{ width: '35%', textAlign: 'center' }}>
            {x.dose?.quantity
              ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit})`
              : `${x.name}`}
          </p>
          {/*<p style={{width: '20%', textAlign: 'center'}}>{x.dose.quantity} {x.dose.unit}</p>*/}
          <p style={{ width: '15%', textAlign: 'center' }}>
            {x.frequency?.quantity
              ? `${x.frequency.quantity} / ${x.frequency.recurring}`
              : '-'}
          </p>
          <p style={{ width: '20%', textAlign: 'center' }}>
            {moment(x.start).format('MMM Do YYYY')}
          </p>
          <p style={{ width: '20%', textAlign: 'center' }}>{activeString}</p>

          {x.doctor_id && (
            <p
              onClick={() => {
                isMedications
                  ? onManualAddClicked('medications', x)
                  : onManualAddClicked('vitamins', x)
              }}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ color: Constants.primaryTheme, fontSize: 20 }}
              />
            </p>
          )}
        </div>
      )
    })

    return toReturn
  }

  function renderMedicationsOrVitamins(isMedications) {
    let activeElement
    let inactiveElement

    activeElement = getMedicationsOrVitaminsRow(isMedications, true)
    inactiveElement = getMedicationsOrVitaminsRow(isMedications, false)

    return (
      <div style={{ width: '100%', margin: 10, position: 'relative' }}>
        <h5 style={{ color: Constants.primaryTheme }}>
          {isMedications ? 'Medications' : 'Vitamins'}
        </h5>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <FontAwesomeIcon
            onClick={() => {
              isMedications
                ? onManualAddClicked('medications')
                : onManualAddClicked('vitamins')
            }}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 340, overflowY: 'scroll' }}
        >
          <div className={Styles.contentRow} style={{ padding: '10px 10px' }}>
            <h6
              className={Styles.tableHeader}
              style={{ width: '35%', textAlign: 'center' }}
            >
              Name
            </h6>
            <h6
              className={Styles.tableHeader}
              style={{ width: '15%', textAlign: 'center' }}
            >
              Dose
            </h6>
            {/*<h6 className={Styles.tableHeader}>Frequency</h6>*/}
            <h6
              className={Styles.tableHeader}
              style={{ width: '20%', textAlign: 'center' }}
            >
              Start
            </h6>
            <h6
              className={Styles.tableHeader}
              style={{ width: '20%', textAlign: 'center' }}
            >
              Status
            </h6>
          </div>
          {activeElement}
          {inactiveElement}
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.tabContentWrapper}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {renderMedicationsOrVitamins(true)}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: 40,
        }}
      >
        {renderMedicationsOrVitamins(false)}
      </div>
    </div>
  )
}

const InfoTab = (props) => {
  let {
    patient,
    patientCore,
    onInsuranceEditClicked,
    patientProfileEdit,
    patientPCPEdit,
  } = props

  function renderPatientPersonalInfo() {
    return (
      <div style={{ width: '50%', margin: 10 }}>
        <h5
          style={{
            color: Constants.primaryTheme,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          Profile{' '}
          <div
            className={Styles.externalTableEntry}
            onClick={() => patientProfileEdit(true)}
            style={{ cursor: 'pointer', width: '5%' }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        </h5>
        <div className={Styles.contentBackground} style={{ height: 240 }}>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Name</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {patient.firstName + ' ' + patient.lastName}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Gender</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{patientCore.gender}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Date Of Birth</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {moment.utc(patientCore.dob, 'DD/MM/YYYY').format('MMM DD YYYY')}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Ethnicity</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{patientCore.ethnicity}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Language</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{patientCore.language}</p>
          </div>

          {/*<div className={Styles.contentRow}>*/}
          {/*  <p className={Styles.contentRowKey}>Time Spent on Patient</p>*/}
          {/*  <span>:</span>*/}
          {/*  <p className={Styles.contentRowValue}>{getTimeInMinutes(patient.time_amount)} ({moment().format('MMM YYYY')})</p>*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }

  function renderPatientPCP() {
    return (
      <div style={{ width: '100%', margin: 10 }}>
        <h5
          style={{
            color: Constants.primaryTheme,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          Primary Care Contact{' '}
          <div
            className={Styles.externalTableEntry}
            onClick={() => patientPCPEdit(true)}
            style={{ cursor: 'pointer', width: '5%' }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        </h5>
        <div className={Styles.contentBackground}>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>PCP Name</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {(patientCore?.pcp?.first_name || '') +
                ' ' +
                (patientCore?.pcp?.last_name || '')}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Address</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {patientCore?.pcp?.address || ''}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Phone</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {patientCore?.pcp?.phone_number || ''}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Cell</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {patientCore?.pcp?.cell || ''}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Fax</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {patientCore?.pcp?.fax || ''}
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Email</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {patientCore?.pcp?.email || ''}
            </p>
          </div>

          {/*<div className={Styles.contentRow}>*/}
          {/*  <p className={Styles.contentRowKey}>Time Spent on Patient</p>*/}
          {/*  <span>:</span>*/}
          {/*  <p className={Styles.contentRowValue}>{getTimeInMinutes(patient.time_amount)} ({moment().format('MMM YYYY')})</p>*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }

  function renderPatientEmergencyContactInfo() {
    return (
      <div style={{ width: '100%', margin: 10 }}>
        <h5
          style={{
            color: Constants.primaryTheme,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          Emergency Contact Information{' '}
          <div
            className={Styles.externalTableEntry}
            onClick={() => patientProfileEdit(true)}
            style={{ cursor: 'pointer', width: '5%' }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        </h5>
        <div className={Styles.contentBackground} style={{ height: 240 }}>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Emergency Contact Name</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {patient.emergencyContactName}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Emergency Contact Number</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {patient.emergencyContactPhone}
            </p>
          </div>
        </div>
      </div>
    )
  }

  function renderPatientContactInfo() {
    return (
      <div style={{ width: '50%', margin: 10 }}>
        <h5
          style={{
            color: Constants.primaryTheme,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          Contact Information
          <div
            className={Styles.externalTableEntry}
            onClick={() => {
              console.log('WHAT????')
              patientProfileEdit(true)
            }}
            style={{ cursor: 'pointer', width: '5%' }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        </h5>
        <div className={Styles.contentBackground} style={{ height: 240 }}>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Email</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{patient.email}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Patient ID</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{patient.uid}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Phone Number</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{patient.phoneNumber}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Address</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {patient.streetAddress} {patient.zipcode}
            </p>
          </div>
        </div>
      </div>
    )
  }

  function renderInsuranceInfo() {
    let insurance = Array.isArray(patientCore?.insurance)
      ? patientCore?.insurance?.find((ins) => ins.preference === 1) ||
        patientCore?.insurance[0]
      : patientCore?.insurance || null
    let cashpay = patientCore?.cashpay || false
    let patientCC = patientCore?.stripe || null
    let patientCCStatus = 'No CC Info'
    if (patientCC?.customer_id?.length > 0) {
      console.log('patientCC', patientCC)
      patientCCStatus = 'Valid'
    } else if (
      patientCC?.customer_id !== null &&
      patientCC?.period_end < moment().unix()
    ) {
      patientCCStatus = 'Expired'
    }
    return (
      <div style={{ width: '50%', margin: 10 }}>
        <h5
          style={{
            color: Constants.primaryTheme,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          Insurance Information{' '}
          <div
            className={Styles.externalTableEntry}
            onClick={() => onInsuranceEditClicked(true)}
            style={{ cursor: 'pointer', width: '5%' }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: Constants.primaryTheme, fontSize: 20 }}
            />
          </div>
        </h5>

        <div className={Styles.contentBackground}>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Provider</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {insurance?.insurance_company?.insurance_name ||
                insurance?.insurance_provider}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Member Name</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{insurance?.member_name}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Plan type</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{insurance?.plan_type}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Member/Policy ID</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{insurance?.subscriber_id}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Group Number</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{insurance?.group_number}</p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Cash Pay</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {cashpay === true ? 'Yes' : 'No'}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Medicare</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {insurance?.medicare === true ? 'Yes' : 'N/A'}
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Medicaid</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {insurance?.medicaid === true ? 'Yes' : 'N/A'}
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>HMO</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {insurance?.hmo === true ? 'Yes' : 'N/A'}
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Worker's Comp</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {insurance?.workers_comp === true ? 'Yes' : 'N/A'}
            </p>
          </div>
          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Medicare Advantage</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>
              {insurance?.medi_adv === true ? 'Yes' : 'N/A'}
            </p>
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Image</p>
            <span style={{ marginRight: 5 }}>:</span>
            {insurance?.front_image_url && (
              <a
                href={insurance?.front_image_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                front
              </a>
            )}
            {insurance?.back_image_url && (
              <a
                href={insurance.back_image_url}
                target="_blank"
                style={{ marginLeft: 20 }}
                rel="noopener noreferrer"
              >
                back
              </a>
            )}
          </div>

          <div className={Styles.contentRow}>
            <p className={Styles.contentRowKey}>Credit Card Status</p>
            <span>:</span>
            <p className={Styles.contentRowValue}>{patientCCStatus}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.tabContentWrapper}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {renderPatientPersonalInfo()}
        {renderPatientContactInfo()}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: 20,
        }}
      >
        {renderInsuranceInfo()}
        <div style={{ width: '50%' }}>
          {renderPatientEmergencyContactInfo()}
          {renderPatientPCP()}
        </div>
      </div>
    </div>
  )
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(
    props?.tabIndex ? props?.tabIndex : 1,
  )

  function getTooltip(label) {
    return (
      <ReactTooltip
        id={label}
        textColor="black"
        backgroundColor={Constants.primaryThemeMedium}
        effect="solid"
      >
        <p style={{ color: 'white', fontSize: 18 }}>{label}</p>
      </ReactTooltip>
    )
  }

  function getTab(name, index, icon, fontAwesome) {
    return (
      <div
        data-tip
        data-for={name}
        onClick={() => {
          setActiveTab(index)
          props.onTabSelected(index)
        }}
        className={Styles.rowIcon}
        style={{
          backgroundColor:
            activeTab === index
              ? Constants.primaryTheme
              : Constants.primaryThemeDark,
        }}
      >
        {fontAwesome ? (
          <FontAwesomeIcon
            icon={icon}
            style={{ color: 'white', fontSize: 30 }}
          />
        ) : (
          <img src={icon} style={{ height: '75%', width: '75%' }} />
        )}
        {getTooltip(name)}
      </div>
    )
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
        padding: '20px 20px',
      }}
    >
      {getTab('Profile', 1, faUser, true)}
      {getTab('Conditions', 2, faBookMedical, true)}
      {getTab('Medications', 3, faCapsules, true)}
      {getTab('Immunizations', 4, faViruses, true)}
      {getTab('Procedures & Observations', 5, faNotesMedical, true)}
      {getTab('Genetics', 6, faDna, true)}
      {/*{getTab("Social Determinants Of Health", 7, faHouseUser, true)}*/}
      {/*{getTab("Risks", 8, faExclamationTriangle, true)}*/}
      {getTab('Goals', 9, faCrosshairs, true)}
      {getTab('Care Plan', 10, faHandHoldingMedical, true)}
      {getTab('Education', 11, faBookReader, true)}
      {getTab('Care Team', 12, faStethoscope, true)}
      {getTab('Questionnaire', 13, faClipboardList, true)}
      {getTab('Orders', 14, faFileInvoice, true)}
      {getTab('External Faxed Documents', 15, faFileImport, true)}
      {getTab('Upload Documents', 16, faCloudUploadAlt, true)}
      {/* {getTab('Referrals', 17, faUserFriends, true)} */}
      {/* {getTab('Payments', 18, faCashRegister, true)} */}
    </div>
  )
}

const CareTeamTabs = (props) => {
  const [activeTab, setActiveTab] = useState(1)

  useEffect(() => {
    setActiveTab(props.activeTab)
  }, [props.activeTab])

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray',
        width: 300,
        height: 40,
        marginTop: 10,
      }}
    >
      <div
        onClick={() => onTabSelected(1)}
        className={styles.tabItem}
        style={{
          backgroundColor: activeTab === 1 ? '#DCDCDC' : '',
          width: 220,
        }}
      >
        <p>Aila Care Team</p>
      </div>

      <div
        onClick={() => onTabSelected(2)}
        className={styles.tabItem}
        style={{
          backgroundColor: activeTab === 2 ? '#DCDCDC' : '',
          width: 220,
        }}
      >
        <p>External Care Team</p>
      </div>
    </div>
  )
}

const AilaExternalTabs = (props) => {
  const [activeTab, setActiveTab] = useState(1)

  useEffect(() => {
    setActiveTab(props.activeTab)
  }, [props.activeTab])

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray',
        width: 300,
        height: 40,
        marginTop: 10,
      }}
    >
      <div
        onClick={() => onTabSelected(1)}
        className={styles.tabItem}
        style={{
          backgroundColor: activeTab === 1 ? '#DCDCDC' : '',
          width: 200,
        }}
      >
        <p>Aila</p>
      </div>

      <div
        onClick={() => onTabSelected(2)}
        className={styles.tabItem}
        style={{
          backgroundColor: activeTab === 2 ? '#DCDCDC' : '',
          width: 200,
        }}
      >
        <p>External Records</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    patient: state.patientReducer.patient,
    patientCore: state.patientReducer.patientCore,
    patientProgress: state.patientReducer.patientProgress,
    medications: state.patientReducer.medications,
    conditions: state.patientReducer.conditions,
    goals: state.patientReducer.goals,
    color: state.patientReducer.color,
    patientWellnessScreening: state.patientReducer.patientWellnessScreening,
    patientCalculatedProgress: state.patientReducer.patientCalculatedProgress,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    token: state.authReducer.token,
    appointmentData: state.appointmentReducer.appointmentData,
    showVideoModal: state.genericReducer.showVideoModal,
    patientCarePlanData: state.patientReducer.patientCarePlanData,
  }
}

const mapDispatchToProps = {
  addPatient,
  getUnreadMessages,
  fetchPatientConditions,
  fetchPatientCarePlanData,
  fetchPatientCoreDate,
  toggleSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
