import React, { Component } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
// https://stripe.com/docs/js/including
import { loadStripe } from '@stripe/stripe-js'
import {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
} from '../../../redux/actions'
import firebase from '../../../services/firebase.js'
import PlaceHolderProfileImage from '../../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../../metadata.js'

import GlobalStyles from '../../styles/global.module.scss'
import Header from '../../../components/Header'
import Button from 'react-bootstrap/Button'
import Styles from './styles/PatientAccount.module.scss'
import Config from '../../../config'

import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import { method } from 'lodash'

const moment = require('moment')
const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}
const TEXT_COLOR = '#7E7E7E'

class PatientCardFunctions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      extraData: this.props,
      loading: true,
      cardDetails: null,
      stripeCustomerId: this.props.patientCore.stripe?.customer_id,
      responseCardDetails: [
        {
          id: 'pm_1KU7esL8GbHIX9UzEODYqHSk',
          brand: 'visa',
          last4: 'XXXX',
          exp_month: null,
          exp_year: null,
          card_funding_type: 'credit',
        },
      ],
      patient_email: this.props.patient.email,
      patient_id: this.props.patient.uid,
      // addCardUrl: "https://portal.ailahealth.com/stripe/card-details?patient_id="+this.props.patient.uid+"&email="+this.props.patient.email,
      addCardUrl: `${Config.WEB_HOST}stripe/card-details?patient_id=${this.props.patient.uid}&email=${this.props.patient.email}`,
      show: false,
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)
  }

  // BUG: When the webpage is refreshed it does not load anything.
  // I dont think the erorr is in this function, need to see where it is.
  componentDidMount() {
    console.log("Has this account logged in?", this.props.loggedIn)
    if (!this.props.loggedIn) {
      // What does this do?
      this.props.history.push('/login')
      return
    }
    this.getTableData() // I dont think this is the error
  }

  getTableData = () => {
    if (
      this.state.stripeCustomerId == null ||
      this.state.stripeCustomerId == undefined
    ) {
      console.log('Customer ID is null or undefined')
      this.setState({loading: false})
      return
    }
    axios({
      method: 'get',
      // url: `${Config.API_URL}/api/v1/patient/${patient?.uid}/payments`,

      url: `${Config.BACKEND_URL}/stripe/get-all-card-details`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        customer_id: this.state.stripeCustomerId,
      },
    })
      .then((response) => {
        // console.log('response111111111111111111111111111111111111111111111111111111->', response)
        this.state.loading = false
        this.setState({ loading: false })
        // this.setState({ cardDetails: response.data })
        // const userData = this.props.

        let cardDetails = response.data?.res_customer_card_details
        console.log(
          'cardDetails11111111111111111111111111111111111111111111111->',
          cardDetails,
        )
        this.setState({ responseCardDetails: cardDetails })
        let something = []
        let slno = 0
        for (let cardDetail in this.state.responseCardDetails) {
          console.log('cardDetail->', cardDetail)
          something.push(
            <>
              <tr key={slno}>
                <td>{slno}</td>
                <td>{cardDetail.last4}</td>
                <td>
                  {cardDetail.expiry_month}/{cardDetail.expiry_year}
                </td>
                <td>EDIT</td>
              </tr>
            </>,
          )
          slno += 1
        }
        console.log(
          'something00000000000000000000000000000000000000000000000000->',
          ...something,
        )
        // let jsxElement = (something)=> {
        //   return (...something)
        // };

        let x = this.setState({
          cardDetails: (
            <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Last 4 digits</th>
                    <th>Expiry</th>
                    <th>Operation</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>100</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr> */}
                  <>{something}</>
                  {/* <tr>
                    <td>2</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr> */}
                </tbody>
              </Table>
            </>
          ),
        })
        console.log(
          'x55555555555555555555555555555555555555555555555555555555555555555555->',
          x,
        )
      })
      .catch((error) => {
        console.log('error->', error)
      })
  }

  // This is the code to delete the card details
  handleClose(param) {
    this.setState({ show: false })
  }

  handleShow(param) {
    console.log("The param is:", param)
    this.setState({ currentSelectedCard: param.id })
    this.setState({ show: true })
  }

  handleCloseAndDelete = () => {
    // console.log('cardId->', cardId)
    console.log("Deleting card:", this.state.currentSelectedCard)
    axios({
      method: 'post',
      url: `${Config.BACKEND_URL}/stripe/delete-customer-card`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // customer_id: this.state.stripeCustomerId,
        card_id: this.state.currentSelectedCard,
      },
    })
      .then((response) => {
        console.log('response->', response)
        this.getTableData()
      })
      .catch((error) => {
        console.log('error->', error)
      })
  }

  render() {
    const { patient } = this.props
    const { patientCore } = this.props
    // let state_residence = null

    // console.log('this.prop->', this.props)
    // console.log('patient->', patient)
    // console.log('patientCore->', patientCore)
    // console.log('patientId->', patient?.uid)

    // let addressComponents = patient?.addressComponents
    // if (addressComponents && addressComponents.length > 0) {
    //   let state = null
    //   addressComponents.forEach((addressComponent) => {
    //     let types = addressComponent.types || []
    //     if (types.includes('administrative_area_level_1'))
    //       state = addressComponent.short_name
    //   })
    //   state_residence = state
    // }

    return (
      <div className={GlobalStyles.container}>
        <Header header="My Payments" />

        {this.state.loading ? (
          <div
            style={{
              // What other styles can be added here?
              // display: 'flex',
              display: 'flex',
              justifyContent: 'center',
              minWidth: '100%',
              height: 400,
              alignItems: 'center',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div className={GlobalStyles.contentWrapper}>
            <div
              style={{
                flex: 1,
                alignSelf: 'center',
                width: '100%',
                overflow: 'hidden',
                height: '75vh',
              }}
            >
              {/* <>{this.state.cardDetails}</> */}
              <>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Last 4 digits</th>
                      <th>Expiry</th>
                      <th>Operation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.responseCardDetails.map((cardDetail, index) => {
                      return (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{cardDetail.last4}</td>
                          <td>
                            {cardDetail.exp_month}/{cardDetail.exp_year}
                          </td>
                          <td>
                            <>
                              <Button
                                variant="secondary"
                                // onClick={() => this.handleShow("tarun")}
                                onClick={() => this.handleShow(cardDetail)}
                              >
                                DELETE CARD
                              </Button>

                              <Modal
                                show={this.state.show}
                                onHide={this.handleClose}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    Are you sure you want to delete the card?
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <p>
                                    Card details will be deleted permanently.
                                  </p>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={this.handleClose}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="secondary"
                                    onClick={this.handleCloseAndDelete}
                                  >
                                    Delete card
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </>
              <Button href={this.state.addCardUrl} variant="secondary">
                ADD CARD
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  const { userReducer } = state
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser:
      userReducer.userType && userReducer.userType.includes('team')
        ? userReducer.teamMongoUser
        : userReducer.mongoUser,
    adminMongoUser: userReducer.mongoUser,
    patient: state.patientReducer.patient,
    userType: userReducer.userType,
    adminId: userReducer.adminId,
    patientCore: state.patientReducer.patientCore,
  }
}

const mapDispatchToProps = {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientCardFunctions)
