import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
import downloadapp from '../../assets/images/downloadapp.png'
import Styles from './styles/AilaWebPayment.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Constants from '../../values'
import {
  faPhone,
  faBolt,
  faAppleAlt,
  faBrain,
  faMoon,
  faFireAlt,
  faCheckCircle,
  faCheck,
  faTimes,
  faGreaterThan,
} from '@fortawesome/free-solid-svg-icons'
import Config from '../../config'

import {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
  fromWebFlow,
} from '../../redux/actions'
import { connect } from 'react-redux'
import OnboardingFooter from '../../components/OnboardingFooter'

const DOWNLOAD_LINK = 'https://ailahealth.app.link/web-signup'
const TEXT_COLOR = '#7E7E7E'
const moment = require('moment')

const AILA_VALUE_PROPS = [
  'Membership Fee',
  'Specialist Visit',
  'Mental Health Visit',
  'Personal Nutritionist Visit',
  'Personalized Meal Plans',
  'Group Nutrition Coaching',
  'Group Mental Health Support',
  'Education Resources',
  'Patient Community',
  'Symptom Tracker',
]

const BASIC_PROPS = [
  'None',
  '$125',
  '$100',
  '$100',
  '-',
  '-',
  '-',
  '-',
  'Included',
  'Included',
]

const MEMBER_PROPS = [
  '$15/Month',
  '$75',
  '$49',
  '$49',
  'Included',
  'Included',
  'Included',
  'Included',
  'Included',
  'Included',
]
const PREMIUM_PROPS = [
  '$150/Month',
  'Included',
  'Included',
  'Included',
  'Included',
  'Included',
  'Included',
  'Included',
  'Included',
  'Included',
]

class AilaWebPayment extends Component {
  constructor() {
    super()
    this.state = {
      patient_id: this.props?.location?.state?.patient_id
        ? this.props?.location?.state?.patient_id
        : '',
      programSelected: '',
      planType: this.props?.location?.state?.programType
        ? this.props?.location?.state?.programType
        : 'CO',
    }
    this.renderMainContent = this.renderMainContent.bind(this)
    this.renderCCFMPlan = this.renderCCFMPlan.bind(this)
    // this.renderAilaPlan = this.renderAilaPlan.bind(this)
    this.renderOtherStatePlan = this.renderOtherStatePlan.bind(this)
    this.renderIframe = this.renderIframe.bind(this)
  }

  componentDidMount() {
    if (!this.props.patient) {
      this.props.history.push('/signup/patient')
      return
    }
  }

  renderCCFMPlan = () => {
    return (
      <div className={Styles.parentDiv}>
        <div
          className={Styles.childDiv}
          onClick={() => this.setState({ programSelected: 'Autoimmune' })}
          style={{
            backgroundColor:
              this.state.programSelected === 'Autoimmune'
                ? '#20A89244'
                : 'whitesmoke',
            cursor: 'pointer',
          }}
        >
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginTop: '2vh',
              textDecorationLine: 'underline',
            }}
          >
            Autoimmune Program
          </p>

          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginBottom: '2vh',
            }}
          >
            $375 for 3 months
          </p>
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              marginLeft: 20,
              color: values.primaryThemeDark,
              textAlign: 'left',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Unlimited Messaging
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Dedicated Health Coach
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Personalized Nutrition Plans
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Mental Health Support
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Educational Content
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Mindfulness Program
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Autoimmune Community Circles
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Includes 60 minute initial clinical evaluation and 30 minute follow
            up
            <br />
            <br />
            <br />
            *Labs and Rx billed separately
            <br />
          </p>
        </div>

        <div
          className={Styles.childDiv}
          onClick={() => this.setState({ programSelected: 'LongCovid' })}
          style={{
            backgroundColor:
              this.state.programSelected === 'LongCovid'
                ? '#20A89244'
                : 'whitesmoke',
            cursor: 'pointer',
          }}
        >
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginTop: '2vh',
              textDecorationLine: 'underline',
            }}
          >
            Long Covid Program
          </p>

          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginBottom: '2vh',
            }}
          >
            $375 for 3 months
          </p>
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              marginLeft: 20,
              color: values.primaryThemeDark,
              textAlign: 'left',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Unlimited Messaging
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Dedicated Health Coach
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Personalized Nutrition Plans
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Mental Health Support
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Educational Content
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Mindfulness Program
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Long Covid Community Circles
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 20,
                marginRight: 10,
              }}
            />
            Includes 60 minute initial clinical evaluation and 30 minute follow
            up
            <br />
            <br />
            <br />
            *Labs and Rx billed separately
            <br />
          </p>
        </div>
      </div>
    )
  }

  renderValuePropsBoxAila = (valueProps, planType, showPopular) => {
    let windowWidth = window.innerWidth
    let elements = []
    elements =
      valueProps &&
      valueProps.map((x, i) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 8,
            justifyContent: 'space-between',
            borderBottom: '1px solid #DEDEDE',
          }}
        >
          {AILA_VALUE_PROPS[i]}
          {x === 'Included' ? (
            <FontAwesomeIcon
              icon={faCheck}
              className={Styles.propIcon}
              style={{ marginRight: 10 }}
            />
          ) : x === '-' ? (
            <FontAwesomeIcon
              icon={faTimes}
              className={Styles.propIcon}
              style={{ marginRight: 10, color: 'red' }}
            />
          ) : (
            <p style={{ color: Constants.primaryThemeDark }}>{x}</p>
          )}
        </div>
      ))

    return (
      <div
        onClick={() => {
          if (planType === 'basic') {
            this.setState({ expandBasic: !this.state.expandBasic })
          } else if (planType === 'member') {
            this.setState({ expandMember: !this.state.expandMember })
          } else if (planType === 'premium') {
            this.setState({ expandPremium: !this.state.expandPremium })
          }
        }}
        className={Styles.valuePropsBoxAila}
        style={{
          margin: 20,
          cursor: 'pointer',
          alignSelf: 'center',
          position: 'relative',
        }}
      >
        {/* <FontAwesomeIcon
          icon={faGreaterThan}
          className={Styles.propIcon1}
          style={{ top: 0, right: 0, position: 'absolute', margin: 10 }}
        /> */}
        <h5
          style={{
            color: '#6C6C6C',
            marginBottom: 10,
            fontSize: 19,
            fontWeight: 'bold',
            // textDecoration: 'underline',
          }}
        >
          {planType === 'basic'
            ? 'Basic'
            : planType === 'member'
            ? 'Membership'
            : 'All Access'}
        </h5>

        <h6
          style={{
            marginTop: 10,
            marginBottom: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {planType === 'member'
            ? '$30/Month'
            : planType === 'premium'
            ? '$200/Month'
            : 'Free'}
        </h6>

        <p
          style={{
            fontSize: 14,
            color: TEXT_COLOR,
            marginTop: -10,
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          {planType === 'member'
            ? ''
            : planType === 'premium'
            ? '(Includes comprehensive care plan and doctor’s visits)'
            : '(Includes patient community)'}
        </p>

        <div
          style={{
            display:
              (planType === 'basic' && this.state.expandBasic) ||
              (planType === 'member' && this.state.expandMember) ||
              (planType === 'premium' && this.state.expandPremium)
                ? 'flex'
                : 'none',
            flexDirection: 'column',
            width: '100%',
            padding: '0px 10px',
          }}
        >
          {elements}

          {/* {planType === 'premium' && (
            <p
              style={{
                color: 'gray',
                fontSize: 14,
                width: '100%',
                marginTop: '6px',
              }}
            >
              * Subscribe and save 3 Months for $375
            </p>
          )} */}

          <button
            disabled={planType === 'basic' ? true : false}
            onClick={() => {
              if (planType === 'member')
                this.setState({
                  setConfirmCustomModal: true,
                  customModalValue: 'member',
                })
              // this.onPlanClicked(
              //   Config.STRIPE_HEALTH_COACHING_3MONTH,
              //   'membership',
              // )
              else if (planType === 'premium') {
                this.setState({
                  setConfirmCustomModal: true,
                  customModalValue: 'premium',
                })
                // this.onPlanClicked(Config.STRIPE_AILA_CLINIC_1MONTH, 'premium')
              }
            }}
            className={GlobalStyles.button}
            style={{
              width: 200,
              margin: '20px auto',
              borderRadius: 15,
              borderWidth: 0,
              height: 40,
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ color: 'white' }}>
              {planType === 'basic' ? 'Subscribed' : 'Subscribe'}
              {/* Subscribe */}
            </p>
          </button>

          {/* {planType === 'premium' && (
            <button
              onClick={() => {
                if (planType === 'premium')
                  this.onPlanClicked(
                    Config.STRIPE_AILA_CLINIC_3MONTH,
                    'premium',
                  )
              }}
              className={GlobalStyles.button}
              style={{
                width: 200,
                margin: 'auto',
                borderRadius: 15,
                borderWidth: 0,
                height: 40,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p style={{ color: 'white' }}>Subscribe for 3 months</p>
            </button>
          )} */}
        </div>

        {showPopular && (
          <div
            style={{
              backgroundColor: 'orange',
              width: 140,
              borderRadius: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // marginTop: 20,
              // visibility: showPopular ? 'visible' : 'hidden',
            }}
          >
            <p style={{ color: 'white' }}>Most Popular</p>
          </div>
        )}
      </div>
    )
  }

  renderAilaContent() {
    let windowWidth = window.innerWidth
    const start = moment()

    let elements = []
    elements =
      AILA_VALUE_PROPS &&
      AILA_VALUE_PROPS.map((x) => (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={Styles.propIcon}
            style={{ marginRight: 10 }}
          />
          <p style={{ color: Constants.primaryThemeDark }}>{x}</p>
        </div>
      ))

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <h1
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 10,
            // fontSize: 17,
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Select your plan
        </h1>
        {/* <h6
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            // fontWeight: 'bold',
            marginBottom: 20,
            fontSize: 17,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Keep your Basic plan for free or subscribe to a Membership plan and
          <span style={{ color: 'red' }}> save 50% </span>
          on doctor’s visits and personalized health coaching
        </h6> */}

        <div className={Styles.valuePropsWrapper}>
          {this.renderValuePropsBoxAila(MEMBER_PROPS, 'member', true)}
          {this.renderValuePropsBoxAila(PREMIUM_PROPS, 'premium', false)}
          {this.renderValuePropsBoxAila(BASIC_PROPS, 'basic', false)}
        </div>

        <p style={{ fontSize: 14, color: TEXT_COLOR }}>
          * Your subscription will start on {start.format('Do MMM YYYY')}
          <br />* Processing fee is applicable
        </p>
      </div>
    )
  }

  renderNotAvailableContent() {
    if (this.state.preferred_sub) {
      this.onPlanClicked(Config.STRIPE_MEMBERSHIP_MONTHLY, 'membership')
      return
    }
    let windowWidth = window.innerWidth
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <h1
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 20,
            // fontSize: 19,
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Select your plan
        </h1>
        <h5
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            marginBottom: 20,
            fontSize: 22,
            fontWeight: 'bold',
          }}
        >
          Health Coaching Membership
        </h5>

        {/* <h6
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            // fontWeight: 'bold',
            marginBottom: 20,
            fontSize: 17,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Keep your Basic plan for free or subscribe to a Membership plan and
          <span style={{ color: 'red' }}> save up to 50% </span>
          on doctor’s visits and personalized health coaching
        </h6> */}

        {/* <div className={Styles.valuePropsWrapper}> */}
        {this.renderValuePropsBoxAila(MEMBER_PROPS, 'member', true)}
        {/* {this.renderValuePropsBoxAila(PREMIUM_PROPS, 'premium', false)} */}
        {/* {this.renderValuePropsBoxAila(BASIC_PROPS, 'basic', false)} */}
        {/* </div> */}

        <p style={{ fontSize: 14, color: TEXT_COLOR }}>
          * Your subscription will start on {moment().format('Do MMM YYYY')}
          <br />* Processing fee is applicable
        </p>

        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ailahealth.com/ailahealthapp"
        >
          <button
            onClick={null}
            className={GlobalStyles.button}
            style={{
              width: 200,
              marginTop: 40,
              borderRadius: 15,
              borderWidth: 0,
              height: 40,
              fontWeight: 'bold',
            }}
          >
            <p style={{ color: 'white' }}>Download Now</p>
          </button>
        </a> */}
      </div>
    )
  }

  renderOtherStatePlan = () => {
    let windowWidth = window.innerWidth
    const start = moment()

    let elements = []
    elements =
      AILA_VALUE_PROPS &&
      AILA_VALUE_PROPS.map((x) => (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={Styles.propIcon}
            style={{ marginRight: 10 }}
          />
          <p style={{ color: Constants.primaryThemeDark }}>{x}</p>
        </div>
      ))

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <h5
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            marginBottom: 20,
            fontSize: 22,
            fontWeight: 'bold',
          }}
        >
          Health Coaching Membership
        </h5>
        <h6
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            // fontWeight: 'bold',
            marginBottom: 20,
            fontSize: 17,
            flexWrap: 'wrap',
            width: windowWidth - windowWidth / 5,
            textAlign: 'center',
          }}
        >
          Keep your Basic plan for free or subscribe to a Membership plan and
          <span style={{ color: 'red' }}> save up to 50% </span>
          on doctor’s visits and personalized health coaching
        </h6>

        <div className={Styles.valuePropsWrapper} style={{ width: '90%' }}>
          {this.renderValuePropsBoxAila(MEMBER_PROPS, 'member', true)}
          {/* {this.renderValuePropsBoxAila(PREMIUM_PROPS, 'premium', false)} */}
          {this.renderValuePropsBoxAila(BASIC_PROPS, 'basic', false)}
        </div>

        <p style={{ fontSize: 14, color: TEXT_COLOR }}>
          * Your subscription will start on {start.format('Do MMM YYYY')}
        </p>
      </div>
    )
  }

  renderIframe = () => {
    let windowWidth = window.innerWidth
    let self = this

    let src
    if (this.props.fromWebFlow)
      src = `${Config.WEB_HOST}stripe/subscription?patient_id=${this.props?.patient?.uid}&email=${this.props?.patient?.email}&state=${this.props?.location?.state?.programType}&fromWeb=${this.props.fromWebFlow}`
    else
      src = `${Config.WEB_HOST}stripe/subscription?patient_id=${this.props?.patient?.uid}&email=${this.props?.patient?.email}&state=${this.props?.location?.state?.programType}`

    var child = window.open(src, '', 'toolbar=0,status=0,width=1200,height=600')
    child.onload = function () {
      child.onunload = function () {
        console.log('child closed')
        // self.props.history.push('/wix/aila/patient-details')
        if (self.props?.location?.state?.flowType === 'LongCovid') {
          console.log('from::', self.props?.location?.state?.flowType)
          self.props.history.push('/long-covid/previsit')
        } else {
          self.props.history.push('/wix/aila/patient-details')
        }
      }
    }
  }

  renderMainContent = () => {
    return (
      <div
        className={Styles.heading}
        // style={{
        //   flex: 1,
        //   alignSelf: 'center',
        //   marginTop: '-8vh',
        // }}
      >
        <h1
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 10,
            // fontSize: 17,
          }}
        >
          Select your plan
        </h1>
      </div>
    )
  }
  // render() {
  //   return (
  //     <div
  //       className={GlobalStyles.container}
  //       style={{
  //         overflow: 'hidden',
  //         backgroundColor: 'white',
  //         display: 'flex',
  //         flexDirection: 'column',
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'row',
  //           alignItems: 'left',
  //           alignSelf: 'left',
  //           marginTop: '1vh',
  //         }}
  //       >
  //         <img
  //           alt="Aila Health"
  //           src={AilaLogo}
  //           style={{
  //             width: '15vh',
  //             height: '15vh',
  //             alignSelf: 'flex-start',
  //           }}
  //         />
  //       </div>

  //       {this.renderMainContent()}

  //       {/* {this.props?.location?.state?.programType === 'AZ' ||
  //       this.props?.location?.state?.programType === 'NJ' ||
  //       this.props?.location?.state?.programType === 'NY' ||
  //       this.props?.location?.state?.programType === 'OR' ||
  //       this.props?.location?.state?.programType === 'VA' ||
  //       this.props?.location?.state?.programType === 'VT' ||
  //       this.props?.location?.state?.programType === 'WA' ||
  //       this.props?.location?.state?.programType === 'MN'
  //         ? this.renderCCFMPlan()
  //         : this.renderAilaPlan()} */}

  //       {this.props?.location?.state?.programType === 'CO' ||
  //       this.props?.location?.state?.programType === 'CA' ||
  //       this.props?.location?.state?.programType === 'TX' ||
  //       this.props?.location?.state?.programType === 'FL'
  //         ? this.renderAilaPlan()
  //         : this.renderNotAvailableContent()}
  //         {/* : this.renderOtherStatePlan()} */}

  //       {/* {this.renderIframe()} */}

  //       <div
  //         style={{
  //           flex: 1,
  //           alignSelf: 'center',
  //         }}
  //       >
  //         <Button
  //           onClick={(e) => {
  //             e.preventDefault()
  //             if (
  //               this.state.programSelected === 'membership' ||
  //               this.state.programSelected === 'premium'
  //             ) {
  //               this.renderIframe()
  //             } else {
  //               if (this.props?.location?.state?.flowType === 'LongCovid') {
  //                 console.log('from::', this.props?.location?.state?.flowType)
  //                 this.props.history.push('/long-covid/previsit')
  //               } else {
  //                 this.props.history.push('/wix/aila/patient-details')
  //               }
  //             }
  //           }}
  //           className={GlobalStyles.button}
  //           style={{
  //             fontWeight: 'bold',
  //             margin: '2vh',
  //             alignSelf: 'center',
  //             width: 200,
  //           }}
  //           variant="primary"
  //         >
  //           {'Subscribe'}
  //         </Button>
  //         {/* <Button
  //           onClick={(e) => {
  //             e.preventDefault()
  //             if (this.props?.location?.state?.flowType === 'LongCovid') {
  //               console.log('from::', this.props?.location?.state?.flowType)
  //               this.props.history.push('/long-covid/previsit')
  //             } else {
  //               this.props.history.push('/wix/aila/patient-details')
  //             }
  //           }}
  //           className={GlobalStyles.button}
  //           style={{
  //             fontWeight: 'bold',
  //             margin: '2vh',
  //             alignSelf: 'center',
  //             width: 200,
  //           }}
  //           variant="primary"
  //         >
  //           {'Continue'}
  //         </Button> */}
  //       </div>
  //     </div>
  //   )
  // }
  render() {
    const { patient } = this.props
    let state_residence = null
    if (this.props?.location?.state?.programType) {
      state_residence = this.props?.location?.state?.programType
    } else {
      let addressComponents = patient?.addressComponents
      if (addressComponents && addressComponents.length > 0) {
        let state = null
        addressComponents.forEach((addressComponent) => {
          let types = addressComponent.types || []
          if (types.includes('administrative_area_level_1'))
            state = addressComponent.short_name
        })
        state_residence = state
      }
    }

    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div>
        <div className={GlobalStyles.contentWrapper}>
          <div
            style={{
              flex: 1,
              alignSelf: 'center',
              width: '100%',
              overflow: 'hidden',
              height: '100vh',
            }}
          >
            <iframe
              id="payment_web"
              src={`${Config.WEB_HOST}stripe/subscription?patient_id=${
                patient?.uid
              }&email=${
                patient?.email
              }&state=${state_residence}&connecting_page=${
                this.props?.location?.state?.flowType === 'LongCovid'
                  ? 'long-covid/previsit'
                  : 'wix/aila-download'
              }`}
              title="payment"
              style={{ height: '100%', width: '100%' }}
            />
          </div>
        </div>

        {/* <div
          style={{
            flex: 1,
            alignSelf: 'center',
            marginTop: '5%',
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault()
              if (this.props?.location?.state?.flowType === 'LongCovid') {
                console.log('from::', this.props?.location?.state?.flowType)
                this.props.history.push('/long-covid/previsit')
              } else {
                this.props.history.push('/wix/aila-download', {
                  patient_type: false,
                })
              }
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '2vh',
              alignSelf: 'center',
              width: 200,
            }}
            variant="primary"
          >
            {'Skip'}
          </Button>
        </div> */}
        <OnboardingFooter />
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    patient: state.patientReducer.patient,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
    fromWebFlow: state.userReducer.fromWebFlow,
  }
}

const mapDispatchToProps = {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(AilaWebPayment)
