import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import {
  userLoggedIn,
  addAuthToken,
  addFirebaseAuthUser,
  addMongoUser,
  addUserType,
  addAdminId,
  addFirebaseUser,
} from '../../redux/actions'
import firebase from '../../services/firebase.js'
import AilaLogo from '../../assets/images/aila_logo.png'
import * as Metadata from '../../metadata.js'

import Constants from '../../values.js'

import FancyField from 'react-fancy-field'
import Button from 'react-bootstrap/Button'
import Styles from './styles/Signup.module.scss'
import GlobalStyles from '../styles/global.module.scss'
import Config from '../../config'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const axios = require('axios')

const TOS_LINK = 'https://www.ailahealth.com/termsofuse'

// THIS IS THE PROVIDER SIGNUP PAGE
class Signup extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      pwd: '',
      zip: '',
      loading: false,
      error: '',
      errorString: '',
      tosChecked: false,
      fName: '',
      lName: '',
      gender: 'Female',
      userType: null,
    }
    this.imei = null
    this.getAuthTokenAndNavigate = this.getAuthTokenAndNavigate.bind(this)
  }

  componentDidMount() {
    let self = this
    this.getFingerPrint()
  }

  async getFingerPrint() {
    try {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      const visitorId = result.visitorId
      this.imei = visitorId
    } catch (err) {
      console.log('error when getting browser finger print', err)
    }
  }

  onCreateAccountPressed() {
    const { email, pwd, lName, fName, userType } = this.state

    if (!fName || fName.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid first name',
      })
      return
    }

    if (!lName || lName.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid last name',
      })
      return
    }

    if (!email || email.length === 0 || !this.validateEmail(email)) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid email address',
      })
      return
    }

    if (!pwd || pwd.length < 6) {
      this.setState({
        error: true,
        errorString: 'The password must be at least 6 characters',
      })
      return
    }

    if (!userType) {
      this.setState({
        error: true,
        errorString: 'Select a user type from the dropdown',
      })
      return
    }

    // if(!zip || zip.length === 0) {
    //   this.setState({
    //     error: true,
    //     errorString: 'Please enter a valid zipcode'
    //   })
    //   return;
    // }

    if (!this.state.tosChecked) {
      this.setState({
        error: true,
        errorString: 'Please read and accept the terms of use',
      })
      return
    }

    this.setState({ loading: true })

    let self = this
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, pwd)
      .then((result) => {
        console.log('result', result)
        let user = result.user
        let uid = user.uid

        // Wait why is this being set to admin?
        let userToPut = { userType: 'admin', uid: uid }
        // TODO: Wait did'nt I write code to add this user to mongo?
        // uid,email
        // TODO: Dont think this is being used as it only stores only the uid and userType
        // TODO: Check with prathik if this is correct
        firebase
          .firestore()
          .collection('users')
          .doc(uid)
          .set(userToPut)
          .then(() => {
            console.log('successfully created new user')
            self.props.addFirebaseUser(userToPut)
            self.props.addUserType('admin')
            self.props.addAdminId(uid)
            // Here the Provider is getting added to the backend
            self.getAuthTokenAndNavigate(user)
          })
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code
        if (errorCode === 'auth/email-already-in-use') {
          self.setState({
            error: true,
            errorString: 'Email address already in use',
            loading: false,
          })
        }
      })
  }

  addProviderToBackend(user, token) {
    let self = this
    let url = Config.BACKEND_URL + 'providers'
    axios({
      method: 'post',
      url: url,
      headers: { Authorization: 'JWT ' + token },
      data: user,
    })
      .then(({ data }) => {
        console.log('successfully added doctor to backend')
        self.props.addMongoUser(user)
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  async getAuthTokenAndNavigate(user) {
    if (!user) {
      this.setState({
        loading: false,
        error: true,
        errorString: 'Invalid user',
      })
      return
    }

    let self = this
    let authUrl = Config.BACKEND_URL + 'authorization'
    try {
      //get authorization token for all backend APIs
      // Add provider to the backend
      let response = await axios({
        method: 'get',
        url: authUrl,
        headers: {
          x_firebase_id: user.uid,
          x_user_type: 'provider',
          imei: this.imei,
        },
      })
      if (response.status === 200) {
        let data = response.data
        self.addProviderToBackend(
          {
            firebase_id: user.uid,
            email: this.state.email,
            first_name: this.state.fName,
            last_name: this.state.lName,
            gender: this.state.gender,
            user_type: this.state.userType.label,
            languages: ['English'],
            cohorts: [],
          },
          data.token,
        )
        self.props.userLoggedIn(true)
        self.props.addAuthToken(data.token)
        self.props.addFirebaseAuthUser(user)
        self.props.history.push('/patient/list')
      } else {
        console.log('error', response)
      }
    } catch (err) {
      console.log('error when getting auth token', err)
      self.setState({
        loading: false,
        error: true,
        errorString: 'Error getting authorization for backend',
      })
    }
  }

  validateEmail(email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          margin: '0 auto',
          backgroundColor: 'white',
        }}
      >
        <div className={Styles.wrapper}>
          <img src={AilaLogo} style={{ height: 100, width: 100 }} />

          <p style={{ fontSize: 20, marginTop: 20 }}>Welcome to Aila Health</p>

          {this.state.loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: 200,
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '60%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <FancyField
                  value={this.state.fName}
                  classes={Styles.textInput2}
                  label="First Name"
                  disabled={this.state.loading}
                  required={false}
                  name="fName"
                  autoFocus={true}
                  onChange={(val) =>
                    this.setState({ fName: val, error: false })
                  }
                  placeholder="Enter your first name... "
                />

                <FancyField
                  value={this.state.lName}
                  classes={Styles.textInput2}
                  label="Last Name"
                  disabled={this.state.loading}
                  required={false}
                  name="lName"
                  onChange={(val) =>
                    this.setState({ lName: val, error: false })
                  }
                  placeholder="Enter your last name... "
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <FancyField
                  value={this.state.email}
                  classes={Styles.textInput2}
                  label="Email"
                  disabled={this.state.loading}
                  required={false}
                  name="emailInput"
                  onChange={(val) =>
                    this.setState({ email: val, error: false })
                  }
                  placeholder="Enter your email... "
                />

                <FancyField
                  value={this.state.pwd}
                  classes={Styles.textInput2}
                  label="Password"
                  disabled={this.state.loading}
                  required={false}
                  type="password"
                  name="pwdInput"
                  onChange={(val) => this.setState({ pwd: val, error: false })}
                  placeholder="Enter your password... "
                />
              </div>
              {/*<div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', marginTop: 20}}>*/}
              {/*  <FancyField value={this.state.zip}*/}
              {/*              classes={Styles.textInput2}*/}
              {/*              label='Zipcode'*/}
              {/*              disabled={this.state.loading}*/}
              {/*              required={false}*/}
              {/*              name='zipInput'*/}
              {/*              onChange={(val) => this.setState({zip: val, error: false})}*/}
              {/*              placeholder='Enter your zipcode... '/>*/}

              {/*</div>*/}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Tabs
                    onTabSelected={(index) => {
                      if (index === 1) this.setState({ gender: 'Female' })
                      else this.setState({ gender: 'Male' })
                    }}
                  />
                </div>

                <div style={{ width: '50%', padding: '0px 20px' }}>
                  <Select
                    placeholder={'Select user type'}
                    isSearchable={false}
                    options={Metadata.userTypes}
                    value={this.state.userType}
                    onChange={(val) => this.setState({ userType: val })}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>

              <p
                style={{
                  marginTop: 20,
                  fontSize: 12,
                  color: 'red',
                  visibility: this.state.error ? 'visible' : 'hidden',
                }}
              >
                {this.state.errorString}
              </p>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 20,
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <input
                  type="checkbox"
                  style={{ marginRight: 10 }}
                  onChange={(checked) => this.setState({ tosChecked: checked })}
                  checked={this.state.tosChecked}
                />
                <p style={{ color: Constants.primaryTheme }}>
                  I accept the{' '}
                  <a
                    href={Config.PRIVACY_POLICY_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    href={Config.TOS_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </a>
                </p>
              </div>

              <Button
                onClick={this.onCreateAccountPressed.bind(this)}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{ marginTop: 40, width: 200 }}
                variant="primary"
              >
                {this.state.loading ? 'Loading....' : 'Create Account'}
              </Button>

              <p style={{ marginTop: 10 }}>
                Have an account?{' '}
                <Link to="/login">
                  <span style={{ textDecoration: 'underline' }}>Login</span>
                </Link>
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(1)

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#aeaeae',
        width: 300,
        height: 40,
        borderRadius: 10,
      }}
    >
      <div
        onClick={() => onTabSelected(1)}
        className={Styles.tabItem}
        style={{
          backgroundColor: activeTab === 1 ? Constants.primaryTheme : '',
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      >
        <p style={{ color: activeTab === 1 ? 'white' : 'black' }}>Female</p>
      </div>
      <div
        onClick={() => onTabSelected(2)}
        className={Styles.tabItem}
        style={{
          backgroundColor: activeTab === 2 ? Constants.primaryTheme : '',
          borderRightWidth: 0,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <p style={{ color: activeTab === 2 ? 'white' : 'black' }}>Male</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedIn,
  addAuthToken,
  addFirebaseAuthUser,
  addMongoUser,
  addUserType,
  addAdminId,
  addFirebaseUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
