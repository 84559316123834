import React, { useState } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/ProviderReferralForm.module.scss'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import values from '../../values'
import Constants from '../../values'
import Select from 'react-select'
// import DatePicker from 'react-date-picker'
import Button from 'react-bootstrap/Button'
import FancyField from 'react-fancy-field'
import AilaLogo from '../../assets/images/aila_logo.png'
import { useHistory, useLocation } from 'react-router-dom'
import Config from '../../config'
import firebase from '../../services/firebase'
import moment from 'moment'
import * as metadata from '../../metadata'
import DatePicker1 from '../../components/DatePicker'
import DatePicker from 'react-date-picker'
const TEXT_COLOR = '#7E7E7E'
const axios = require('axios')

export default function ProviderReferralForm(props) {
  const [patientID, setPatientID] = useState(props?.location?.state?.patient_id)
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [errorString, setErrorString] = useState('')

  const [patientFname, setPatientFname] = useState('')
  const [patientLname, setPatientLname] = useState('')
  const [patientEmail, setPatientEmail] = useState('')
  const [patientPhone, setPatientPhone] = useState('')
  const [patientState, setPatientState] = useState('')
  const [patientSpecialty, setPatientSpecialty] = useState(null)
  const [programs, setPrograms] = useState([
    { name: 'Autoimmune', isChecked: false },
    { name: 'Long Covid', isChecked: false },
    { name: 'Remote Patient Monitoring', isChecked: false },
  ])
  const [patientDob, setPatientDob] = useState(new Date())
  const [patientPayer, setPatientPayer] = useState('')
  const [patientInsurance, setPatientInsurance] = useState('')
  const [patientComments, setPatientComments] = useState('')

  const [providerFname, setProviderFname] = useState('')
  const [providerLname, setProviderLname] = useState('')
  const [providerEmail, setProviderEmail] = useState('')
  const [providerPhone, setProviderPhone] = useState('')
  const [providerFax, setProviderFax] = useState('')
  const [providerSpecialty, setProviderSpecialty] = useState(null)
  const [providerState, setProviderState] = useState('')
  const [authNumber, setAuthNumber] = useState('')
  const [providerOrganization, setProviderOrganization] = useState('')
  const [gender, setGender] = useState(null)
  const [econsult, setEconsult] = useState(false)
  const [econsultConsent, setEconsultConsent] = useState(false)
  const [requestType, setRequestType] = useState('')
  const [requestedDate, setRequestedDate] = useState(null)

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onSubmitPressed = async () => {
    setError(false)
    setErrorString('')
    if (
      !patientFname ||
      patientFname.length === 0 ||
      !providerFname ||
      providerFname.length === 0
    ) {
      setError(true)
      setErrorString('Please enter the first name')
      console.log('FNAME ERROR')
      return
    }
    if (
      !patientLname ||
      patientLname.length === 0 ||
      !providerLname ||
      providerLname.length === 0
    ) {
      setError(true)
      setErrorString('Please enter the last name')
      console.log('LNAME ERROR')
      return
    }

    if (
      !patientEmail ||
      patientEmail.length === 0 ||
      !validateEmail(patientEmail) ||
      !providerEmail ||
      providerEmail.length === 0 ||
      !validateEmail(providerEmail)
    ) {
      setError(true)
      setErrorString('Please enter a valid email')
      console.log('EMAIL ERROR')
      return
    }

    if (!patientState || patientState.length === 0) {
      setError(true)
      setErrorString('Please select the state of residence ')
      console.log('STATE RESIDENCE ERROR')
      return
    }

    if (!patientDob) {
      setError(true)
      setErrorString('Please enter valid date of birth ')
      console.log('DOB ERROR')
      return
    }

    if (patientDob) {
      let now = moment()
      let dobMoment = moment(patientDob)
      if (now.diff(dobMoment, 'years') < 18) {
        setError(true)
        setErrorString('Restricted, The patient must be at least 18 years old.')
        console.log('AGE ERROR')
        return
      }
    }

    if (
      !patientPhone ||
      patientPhone.length === 0 ||
      !providerPhone ||
      providerPhone.length === 0
    ) {
      setError(true)
      setErrorString('Please enter a valid phone number')
      console.log('PHONE ERROR')
      return
    }

    try {
      let data = {
        patient: {
          email: patientEmail,
          firstName: patientFname,
          lastName: patientLname,
          phone: patientPhone,
          state: patientState,
          specialty: patientSpecialty,
          programs: programs
            .filter((item) => {
              if (item.isChecked === true) return item.name
            })
            .map((item) => item.name),
          dob: patientDob,
          payer: patientPayer,
          insurance: patientInsurance,
          comments: patientComments,
          authNumber,
        },
        e_consult: {
          econsult: econsult,
          econsultConsent: econsultConsent,
          requestType: requestType,
          requestedDate: requestedDate,
        },
        provider: {
          firstName: providerFname,
          lastName: providerLname,
          email: providerEmail,
          phone: providerPhone,
          fax: providerFax,
          organization: providerOrganization,
          state: providerState,
          specialty: providerSpecialty,
        },
        created_at: moment.utc().format(),
      }

      let config = {
        method: 'post',
        // headers: { Authorization: 'JWT ' + token, x_firebase_id: firebaseID },
        url: `${Config.BACKEND_URL}provider-referrals`,
        data: data,
      }
      axios(config)
        .then((resp) => {
          console.log('saved mongo data')
        })
        .catch((err) => {
          console.log('error when saving data', err)
        })
    } catch (err) {
      setError(true)
      setErrorString(err.message)
      console.log(err)
      onFinishFailed(err.message)
    }
  }

  const renderFormValueInputBox = (userType) => {
    if (userType === 'patient') {
      return (
        <div className={Styles.valuePropsBoxAila}>
          <h2
            style={{
              color: Constants.primaryThemeDark,
              marginBottom: 10,
              fontWeight: 'bold',
            }}
          >
            Patient Information
          </h2>

          <div className={Styles.valueInputDivWrapper}>
            <div className={Styles.valueInputBox}>
              <FancyField
                value={patientFname}
                label="First Name *"
                name="patientFnameInput"
                onChange={(val) => setPatientFname(val)}
                placeholder="First Name"
              />
            </div>

            <div className={Styles.valueInputBox}>
              <FancyField
                value={patientLname}
                label="Last Name *"
                name="patientLnameInput"
                onChange={(val) => setPatientLname(val)}
                placeholder="Last Name"
              />
            </div>
          </div>

          <div className={Styles.valueInputDivWrapper}>
            <div className={Styles.valueInputBox}>
              <FancyField
                value={patientEmail}
                label="Email *"
                required={false}
                name="emailInput"
                //   autoFocus
                onChange={(val) => setPatientEmail(val)}
                placeholder="Email"
              />
            </div>

            <div className={Styles.valueInputBox}>
              <FancyField
                value={patientPhone}
                label="Phone Number *"
                required={false}
                name="phoneInput"
                type={Number}
                onChange={(val) => setPatientPhone(val)}
                placeholder="Phone Number"
              />
            </div>
          </div>
          <div className={Styles.valueInputDivWrapper}>
            <div className={Styles.valueInputBox} style={{ marginTop: '5%' }}>
              <Select
                placeholder="State *"
                inputId="patientStateInput"
                options={metadata.stateResidence}
                value={patientState}
                isSearchable
                autoBlur
                onChange={(val) => setPatientState(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>

            <div
              className={Styles.valueInputBox}
              style={{ marginBottom: '5%' }}
            >
              <p
                style={{
                  color: '#696969',
                  fontWeight: 100,
                }}
              >
                Date of Birth *
              </p>
              {/* <DatePicker
                onChange={(date) => {
                  setPatientDob(date)
                  console.log(date)
                }}
                value={patientDob}
              /> */}
              <DatePicker1
                dateChange={(date) => {
                  setPatientDob(date)
                  console.log(date)
                }}
              />
            </div>
          </div>
          <div className={Styles.valueInputDivWrapper}>
            <div
              className={Styles.valueInputBox}
              style={{ marginTop: '5%', width: '100%' }}
            >
              <p
                style={{
                  color: '#696969',
                  fontWeight: 100,
                }}
              >
                Gender at Birth
              </p>
              <Select
                placeholder="Gender"
                inputId="gender"
                options={[
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' },
                ]}
                value={gender}
                autoBlur
                onChange={(val) => setGender(val)}
                isSearchable
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div className={Styles.valueInputDivWrapper}>
            <div
              className={Styles.valueInputBox}
              style={{ marginTop: '5%', width: '100%' }}
            >
              <p
                style={{
                  color: '#696969',
                  fontWeight: 100,
                }}
              >
                Requested specialty for referral
              </p>
              <Select
                placeholder="Specialty"
                inputId="patientSpecialtyInput"
                options={metadata.SPECIALITY_OPTIONS}
                value={patientSpecialty}
                isSearchable
                autoBlur
                onChange={(val) => setPatientSpecialty(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          <div className={Styles.valueInputDivWrapper}>
            <div
              className={Styles.valueInputBox}
              style={{ marginTop: '5%', width: '100%' }}
            >
              <p
                style={{
                  color: '#696969',
                  fontWeight: 100,
                }}
              >
                Interested in which programs:
              </p>
              {programs.map((type) => {
                return (
                  <>
                    <label
                      class={Styles.checkContainer}
                      style={{
                        color: '#696969',
                        fontWeight: 100,
                      }}
                    >
                      {type.name}
                      <input
                        type="checkbox"
                        checked={type.isChecked}
                        onClick={() => {
                          // console.log('clocked')
                          let tempq = [...programs]
                          tempq.forEach((item) => {
                            if (item.name === type.name)
                              item.isChecked = !item.isChecked
                          })
                          setPrograms(tempq)
                        }}
                      />
                      <span class={Styles.checkmark}></span>
                    </label>
                  </>
                )
              })}
            </div>
          </div>

          <div
            className={Styles.valueInputDivWrapper}
            style={{ justifyContent: 'start' }}
          >
            <div className={Styles.valueInputBox}>
              <FancyField
                value={authNumber}
                // label="Authorization Number"
                name="auth_number"
                onChange={(val) => setAuthNumber(val)}
                placeholder="Authorization Number"
              />
            </div>
          </div>

          <div className={Styles.valueInputDivWrapper}>
            <div className={Styles.valueInputBox}>
              <FancyField
                value={patientPayer}
                label="Insurance Carrier"
                name="patientPayerInput"
                onChange={(val) => setPatientPayer(val)}
                placeholder="Insurance Carrier"
              />
            </div>

            <div className={Styles.valueInputBox}>
              <FancyField
                value={patientInsurance}
                label="Insurance Number"
                name="patientInsuranceInput"
                onChange={(val) => setPatientInsurance(val)}
                placeholder="Insurance Number"
              />
            </div>
          </div>

          <div className={Styles.valueInputDivWrapper}>
            <div
              className={Styles.valueInputBox}
              style={{ marginTop: '5%', width: '100%' }}
            >
              <label
                class={Styles.checkContainer}
                style={{
                  color: '#696969',
                  fontWeight: 100,
                }}
              >
                Request e-consult
                <input
                  type="checkbox"
                  checked={econsult}
                  onClick={() => {
                    setEconsult(!econsult)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
              <label
                class={Styles.checkContainer}
                style={{
                  color: '#696969',
                  fontWeight: 100,
                }}
              >
                Patient gave verbal consent to receive e-consult and video visit with specialist
                <input
                  type="checkbox"
                  checked={econsultConsent}
                  onClick={() => {
                    setEconsultConsent(!econsultConsent)
                  }}
                />
                <span class={Styles.checkmark}></span>
              </label>
            </div>
          </div>
          <div
            className={Styles.valueInputDivWrapper}
            style={{ display: econsult ? 'block' : 'none' }}
          >
            <div className={Styles.valueInputBox}>
              <FancyField
                value={requestType}
                label="Subject/ Request Type"
                name="requestType"
                onChange={(val) => setRequestType(val)}
                placeholder="Subject/ Request Type"
              />
            </div>
          </div>

          <div
            className={Styles.valueInputDivWrapper}
            style={{ display: econsult ? 'block' : 'none' }}
          >
            <div
              className={Styles.valueInputBox}
              style={{ marginBottom: '5%' }}
            >
              <p
                style={{
                  color: '#696969',
                  fontWeight: 100,
                }}
              >
                Requested Response Due Date
              </p>
              <DatePicker
                onChange={(date) => {
                  setRequestedDate(date)
                  console.log(date)
                }}
                value={requestedDate}
              />
            </div>
          </div>
          <div
            className={Styles.valueInputDivWrapper}
            style={{ display: econsult ? 'block' : 'none' }}
          >
            <div className={Styles.valueInputBox} style={{ width: '100%' }}>
              <p
                style={{
                  color: Constants.primaryThemeDark,
                }}
              >
                Patient Case Notes/ Question
              </p>

              <textarea
                placeholder={'Please enter your notes'}
                style={{
                  width: '100%',
                  height: '10vh',
                  padding: '5px 20px',
                  resize: 'none',
                  borderColor: '#aeaeae',
                  borderRadius: 4,
                }}
                value={patientComments}
                onChange={(e) => {
                  console.log(e.target.value)
                  setPatientComments(e.target.value)
                }}
              />
            </div>
          </div>

          <div
            className={Styles.valueInputDivWrapper}
            style={{ display: !econsult ? 'block' : 'none' }}
          >
            <div className={Styles.valueInputBox} style={{ width: '100%' }}>
              <p
                style={{
                  color: Constants.primaryThemeDark,
                }}
              >
                Comments
              </p>

              <textarea
                placeholder={'Enter your message here.....'}
                style={{
                  width: '100%',
                  height: '10vh',
                  padding: '5px 20px',
                  resize: 'none',
                  borderColor: '#aeaeae',
                  borderRadius: 4,
                }}
                value={patientComments}
                onChange={(e) => {
                  console.log(e.target.value)
                  setPatientComments(e.target.value)
                }}
              />
            </div>
          </div>

          <p
            style={{
              fontSize: 14,
              color: TEXT_COLOR,
              margin: 10,
              textAlign: 'left',
            }}
          >
            This referral form complies with HIPAA.
          </p>
        </div>
      )
    } else {
      return (
        <div className={Styles.valuePropsBoxAila}>
          <h2
            style={{
              color: Constants.primaryThemeDark,
              marginBottom: 10,
              fontWeight: 'bold',
            }}
          >
            Referring Provider Information
          </h2>
          <div className={Styles.valueInputDivWrapper}>
            <div className={Styles.valueInputBox}>
              <FancyField
                value={providerFname}
                label="First Name *"
                name="providerFnameInput"
                onChange={(val) => setProviderFname(val)}
                placeholder="First Name"
              />
            </div>

            <div className={Styles.valueInputBox}>
              <FancyField
                value={providerLname}
                label="Last Name *"
                name="providerLnameInput"
                onChange={(val) => setProviderLname(val)}
                placeholder="Last Name"
              />
            </div>
          </div>

          <div className={Styles.valueInputDivWrapper}>
            <div className={Styles.valueInputBox}>
              <FancyField
                value={providerEmail}
                label="Email *"
                required={false}
                name="emailInput"
                //   autoFocus
                onChange={(val) => setProviderEmail(val)}
                placeholder="Email"
              />
            </div>

            <div className={Styles.valueInputBox}>
              <FancyField
                value={providerOrganization}
                label="Organization"
                name="providerOrganizationInput"
                onChange={(val) => setProviderOrganization(val)}
                placeholder="Organization"
              />
            </div>
          </div>
          <div className={Styles.valueInputDivWrapper}>
            <div className={Styles.valueInputBox}>
              <FancyField
                value={providerPhone}
                label="Phone Number *"
                required={false}
                name="phoneInput"
                type={Number}
                onChange={(val) => setProviderPhone(val)}
                placeholder="Phone Number"
              />
            </div>

            <div className={Styles.valueInputBox}>
              <FancyField
                value={providerFax}
                label="Fax Number"
                required={false}
                name="faxInput"
                type={Number}
                onChange={(val) => setProviderFax(val)}
                placeholder="Fax Number"
              />
            </div>
          </div>

          <div className={Styles.valueInputDivWrapper}>
            <div className={Styles.valueInputBox} style={{ marginTop: '5%' }}>
              <p
                style={{
                  color: '#696969',
                  fontWeight: 100,
                }}
              >
                Specialty
              </p>
              <Select
                placeholder="Specialty"
                inputId="providerSpecialtyInput"
                options={metadata.SPECIALITY_OPTIONS}
                value={providerSpecialty}
                isSearchable
                autoBlur
                onChange={(val) => setProviderSpecialty(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>

            <div className={Styles.valueInputBox} style={{ marginTop: '5%' }}>
              <p
                style={{
                  color: '#696969',
                  fontWeight: 100,
                }}
              >
                State
              </p>
              <Select
                placeholder="State *"
                inputId="providerStateInput"
                options={metadata.stateResidence}
                value={providerState}
                isSearchable
                autoBlur
                onChange={(val) => setProviderState(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  const renderFormContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <div className={Styles.valuePropsWrapper} style={{ width: '90%' }}>
          {renderFormValueInputBox('patient')}
          {renderFormValueInputBox('provider')}
        </div>
      </div>
    )
  }

  const renderMainContent = () => {
    return (
      <div
        style={{
          flex: 1,
          alignSelf: 'center',
          marginTop: '-8vh',
        }}
      >
        <h1
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 10,
            // fontSize: 17,
          }}
        >
          Aila Health Provider Referral Form
        </h1>
      </div>
    )
  }

  return (
    <div
      className={GlobalStyles.container}
      style={{
        overflow: 'hidden',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'left',
          alignSelf: 'left',
          marginTop: '1vh',
        }}
      >
        <img
          alt="Aila Health"
          src={AilaLogo}
          style={{
            width: '15vh',
            height: '15vh',
            alignSelf: 'flex-start',
          }}
        />
      </div>

      {renderMainContent()}
      {renderFormContent()}

      <div
        style={{
          flex: 1,
          alignSelf: 'center',
        }}
      >
        <h5
          style={{
            marginTop: 10,
            color: Constants.primaryThemeDark,
            fontWeight: 'bold',
            marginBottom: 10,
            // fontSize: 17,
          }}
        >
          Fax medical records to (720) 500-9759
        </h5>
      </div>

      <p
        style={{
          marginTop: 20,
          fontSize: 12,
          color: 'red',
          visibility: error ? 'visible' : 'hidden',
          textAlign: 'center',
        }}
      >
        {errorString}
      </p>

      <div
        style={{
          flex: 1,
          alignSelf: 'center',
        }}
      >
        <Button
          onClick={() => onSubmitPressed()}
          className={GlobalStyles.button}
          style={{
            fontWeight: 'bold',
            margin: '2vh',
            alignSelf: 'center',
            width: 200,
          }}
          variant="primary"
        >
          {'Submit Referral'}
        </Button>
      </div>
    </div>
  )
}
