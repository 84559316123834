import React, {useEffect, useState} from "react";
import Styles from "../styles/BodySystems.module.scss";
import {
	Legend,
	Line,
	LineChart,
	Tooltip,
	XAxis,
	YAxis,
	ReferenceLine,
	ReferenceArea,
	AreaChart,
	Label,
	CartesianGrid,
	Area
} from "recharts";
import moment from "moment";
import Constants from "../../../../values";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import LoincSearchModal from "../../../../components/LoincSearchModal";
import QuestionnaireModal from "../../../../components/QuestionnaireModal";
import ChartModal from "../../../../components/ChartModal";
import QChartToolTip from "../../../../components/QChartToolTip";
import {getQuestionnaireResults} from "../../../../components/getQuestionnaireResults";
import {renderQuestionnaireResults} from "../../../../components/renderQuestionnaireResults";
import GlobalStyles from "../../../styles/global.module.scss";
import Button from "react-bootstrap/Button";
import axios from 'axios'
import {useSelector} from "react-redux";
import Config from "../../../../config";
import GeneralChart from "../../../../components/musculoSkeletal/GeneralChart";

let selectValue = 'Month';

let GREEN_RANGE_COLOR = '#20A89266'

const EndocrineSystem = (props) => {
	let {onChangeSelect, vitalData} = props
	const [showSearchModal, setShowSearchModal] = useState(false)
	const [selectedLabs, setSelectedLabs] = useState([])
	const [checked, setChecked] = useState(false)
	const [selectedOptions, setSelectedOptions] = useState([])
	const token = useSelector(state => state.authReducer.token)
	const patient = useSelector(state => state.patientReducer.patient)
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false)
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([])
  const [questionnaireChecked, setQuestionnaireChecked] = useState(false)
  const [anxietyScreening, setAnxietyScreening] = useState(null)
  const [mentalHealthScreening, setMentalHealthScreening] = useState(null)
  const [crohnsScreening, setCrohnsScreening] = useState(null)
  const [colitisScreening, setColitisScreening] = useState(null)
  const [physicalScreening, setPhysicalScreening] = useState(null)
  const [burnoutScreening, setBurnoutScreening] = useState(null)
	const [aceScreening, setAceScreening] = useState(null)
  const [sleepapneaScreening, setSleepapneaScreening] = useState(null)
  const [globalhealthScreening, setGlobalhealthScreening] = useState(null)
  const [showChartModal, setShowChartModal] = useState(false)
  const [modalChartData, setModalChartData] = useState({data: [], path: '', name: ''})
	let graphWidth = (window.innerWidth) / 2;
	let stockName = 'Glucose Level';
	let subtitleName = 'mg/dL';
	let title = 'Glucose Level';

	let graphTempData = [];

	useEffect(() => {
		let patient = props.patient
		if(patient?.body_systems_preferences?.endocrine?.length > 0) {
			setSelectedLabs(patient.body_systems_preferences.endocrine)
			setChecked(true)
		} else {
			setChecked(false)
		}

	},[props.patient])

	const onLabsSelected = (entries) => {
		setSelectedLabs(entries || [])
		setShowSearchModal(false)
		if(checked) {
			props.updatePreferences({
				'body_systems_preferences.endocrine': entries || []
			})
		}
	}

	const onChecked = (e) => {
		let data
		if(e.target.checked) {
			//save the preferences
			data = {
				'body_systems_preferences.endocrine': selectedLabs || []
			}
			onQuestionnaireChecked(true)
		} else {
			data = {
				'body_systems_preferences.endocrine': []
			}
			onQuestionnaireChecked(false)
		}
		setChecked(e.target.checked)
		props.updatePreferences(data)
	}

	useEffect(() => {
    let patient = props.patient
    if(patient?.questionnaire_preferences?.endocrine?.length > 0) {
      setSelectedQuestionnaire(patient.questionnaire_preferences.endocrine)
      setQuestionnaireChecked(true)
    } else {
      setQuestionnaireChecked(false)
    }

  },[props.patient])

  useEffect(() => {
    getAllScreeners()
  }, [selectedQuestionnaire])

  const getAllScreeners = () => {
    selectedQuestionnaire && selectedQuestionnaire.forEach((screener) => {
      getScreening(screener.path)
    })
  }

  const getScreening = (path) => {
    const url = Config.BACKEND_URL + 'screenings/' + path
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: props.patient.patient_id, complete: true },
    })
      .then(({ data }) => {
        setData(path, data)
      })
      .catch((err) => {
        console.log('error when getting screening', err)
      })
  }

  const setData = (path, data) => {
    switch (path) {
      case 'anxiety':
        setAnxietyScreening(data)
        break
      case 'mental':
        setMentalHealthScreening(data)
        break
      case 'gastro':
        setCrohnsScreening(data)
        break
      case 'colitis':
        setColitisScreening(data)
        break
      case 'physical':
        setPhysicalScreening(data)
        break
      case 'burnout':
        setBurnoutScreening(data)
        break
			case 'ace':
				setAceScreening(data)
				break
			case 'sleepapnea':
				setSleepapneaScreening(data)
				break
			case 'globalhealth':
				setGlobalhealthScreening(data)
				break
      default:
    }
  }

  const getScreeningData = (path) => {
    switch (path) {
      case 'anxiety':
        return anxietyScreening
      case 'mental':
        return mentalHealthScreening
      case 'gastro':
        return crohnsScreening
      case 'colitis':
        return colitisScreening
      case 'physical':
        return physicalScreening
      case 'burnout':
        return burnoutScreening
			case 'ace':
				return aceScreening
			case 'sleepapnea':
				return sleepapneaScreening
			case 'globalhealth':
				return globalhealthScreening
      default:
        return null
    }
  }

  const hideChartModal = () => {
    setShowChartModal(false)
  }

  const onQuestionnaireSelected = (entries) => {
    setSelectedQuestionnaire(entries || [])
    setShowQuestionnaireModal(false)
    if(checked) {
      props.updatePreferences({
        'questionnaire_preferences.endocrine': entries || []
      })
    }
  }

  const onQuestionnaireChecked = (isChecked) => {
    let data
    if(isChecked) {
      //save the preferences
      data = {
        'questionnaire_preferences.endocrine': selectedQuestionnaire || []
      }
    } else {
      data = {
        'questionnaire_preferences.endocrine': []
      }
    }
    // setQuestionnaireChecked(e.target.checked)
    props.updatePreferences(data)
  }

	const renderNewLabValue = (option) => {
		return (
      <div
        // onClick={() => this.setState({modalIsOpen: true, modalOption: option, modalProgress: data.progress})}
        data-tip
        data-for={option.label}
        key={option.label}
        style={{
          width: 180,
          marginRight: 10,
          marginLeft: 10,
          height: 120,
          alignItems: 'center',
          justifyContent: 'end',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
          marginBottom: 10,
        }}
      >
        <div>
          {option.val ? (
            <p style={{ fontWeight: 'bold', fontSize: 16 }}>{option.val}</p>
          ) : (
            <p style={{ fontWeight: 'bold', fontSize: 16 }}>-</p>
          )}
          <p style={{ fontSize: 12 }}>
            {option.example_ucum_units || option.example_units}
          </p>
        </div>
        <div
          style={{
            width: '90%',
            backgroundColor: option.val
              ? GREEN_RANGE_COLOR
              : Constants.lightGray,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            borderRadius: 8,
            marginTop: 15,
          }}
        >
          <p style={{ textAlign: 'center' }}>{option.shortname}</p>
        </div>
        {/* {this.getTooltip(option, data.progress)} */}
        <ChartTooltip option={option} />
      </div>
    )
	}

	return (
		<div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>

			<p style={{width: '100%', fontWeight: 'bold', color: Constants.primaryTheme, textAlign: 'center', marginBottom: 20, fontSize: Constants.headerSize}}>Endocrine System</p>

			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 10, marginBottom: 10}}>
				<input type="checkbox" style={{marginRight: 10}} onChange={onChecked} checked={checked}/>
				<p>Save this search</p>
			</div>

			<LoincSearchModal
				selectedLabs={selectedLabs}
				onLabsSelected={onLabsSelected}
				showModal={showSearchModal}
				hideModal={() => setShowSearchModal(false)}/>

			<QuestionnaireModal
        selectedQuestionnaire={selectedQuestionnaire}
        onQuestionnaireSelected={onQuestionnaireSelected}
        showModal={showQuestionnaireModal}
        hideModal={() => setShowQuestionnaireModal(false)}/>
      <ChartModal
        data={modalChartData.data}
        path={modalChartData.path}
        name={modalChartData.name}
        getQuestionnaireResults={getQuestionnaireResults}
        showChartModal={showChartModal}
        hideChartModal={hideChartModal}
      />

			<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
				<p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 100}}>Biomarkers</p>
				<Button
					onClick={() => setShowSearchModal(true)}
					className={GlobalStyles.button}
					style={{width: 200, marginLeft: 20}}
					variant="primary"
				>
					Add Biomarkers
				</Button>
			</div>

			<div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
				{
					selectedLabs && selectedLabs.map(item => {
						return renderNewLabValue(item)
					})
				}
			</div>

			{/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10, marginTop: 50}}>
				<input type="checkbox" style={{marginRight: 10}} onChange={onQuestionnaireChecked} checked={questionnaireChecked}/>
				<p>Save this search</p>
			</div> */}
			<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 30}}>
				<p style={{fontWeight: 'bold', color: Constants.primaryTheme, width: 140}}>Questionnaires</p>
				<Button
					onClick={() => setShowQuestionnaireModal(true)}
					className={GlobalStyles.button}
					style={{width: 200, marginLeft: 20}}
					variant="primary"
				>
					Add Questionnaire
				</Button>
      </div>
      <div style={{flexDirection: 'row', display: 'flex', marginTop: 10, flexWrap: 'wrap', alignSelf: "flex-start"}}>
        {
          selectedQuestionnaire && selectedQuestionnaire.map(item => {
            return renderQuestionnaireResults({option: item, getScreeningData, setModalChartData, setShowChartModal})
          })
        }
      </div>

			<p style={{fontWeight: 'bold', color: Constants.primaryTheme, marginTop: 30, marginBottom: 20}}>Patient Reported Outcomes</p>

			<div className="row" style={{width: '100%', alignSelf: 'flex-start', paddingLeft: 30}}>
				{/* <div className="col-sm-10"> */}
					<GeneralChart
						token={token}
						patientId={patient?.patient_id}
						chartWidth={'74%'}
						// questionName={'physical'}
						scoreName={'glucose'}
						lineName={'Glucose Level'}
						yAxisName={'mg/dL'}
						chartName={'Glucose Level'}
						graphData={vitalData}
						// domainRange={[0, 20]}
					/>
				{/* </div> */}
				<div className="col-sm-2">
					<div className="squareButton">
						<div className={Styles.rowDirectionBox}>
							<label className={Styles.squareConatiner}>
								Show Treatment Start
								<input
									type="checkbox"
									name="checkbox"
								/>
								<span className={Styles.checkmark}></span>
							</label>
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

export default EndocrineSystem

const renderChart = (data, graphWidth, stockName, subtitleName, title, onChangeSelect) =>{
	return(
		<div id="chart" className={Styles.reproductiveChartClass} style={{alignSelf: "flex-start"}}>
			<div className={Styles.yAxisLabelR2}>
				<p className={Styles.yAxisLabelPR2}>
					{ title }
				</p>
			</div>
			<div className={Styles.chartSelectReproductive}>
				<div style={{width: '100%', height: 30}}>
					<Select
						isSearchable={false}
						className={Styles.selectStyle}
						classNamePrefix={Styles.selectStyleInnerGraph}
						autoBlur={true}
						placeholder={'Month'}
						options={[
							{label: 'Year', value: 'Year'},
							{label: 'Month', value: 'Month'},
							{label: 'Day', value: 'Day'}
						]}
						value={selectValue}
						onChange={(val) => {
							onChangeSelect(val)
							selectValue = val
						}}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary25: '#20A89233',
								primary: '#20A892',
							},
						})}
					/>
				</div>
			</div>

			{/* <div className={Styles.chartSelectReproductive}>
        <div className={Styles.chartSelectReproductiveCurrent} tabindex="1">
          {
            selectArray.map((item)=>{
              return(
                <div className={Styles.chartSelectReproductiveValue}>
                  <input className={Styles.chartSelectReproductiveInput}  type="radio" id={item.id} value={item.value} name={item.name} checked={item.value == selectValue ? true : false}  />
                  <p className={Styles.chartSelectReproductiveInputText}>{item.name}</p>
                </div>
              )
            })
          }
            <img className={Styles.chartSelectReproductiveIcon}
            src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"/>
        </div>
        <ul className={Styles.chartSelectReproductiveList}>
          <li onClick={()=>{ onChangeSelect('Year'); setSelectValue = 'Year'; selectValue=1}}>
            <label className={Styles.chartSelectReproductiveOption}  for="1" aria-hidden="aria-hidden">Year</label>
          </li>
          <li onClick={()=>{onChangeSelect('Month'); setSelectValue = 'Month'; selectValue=2}}>
            <label className={Styles.chartSelectReproductiveOption} for="2" aria-hidden="aria-hidden">Month</label>
          </li>
          <li onClick={()=>{onChangeSelect('Day'); setSelectValue = 'Day'; selectValue=3}}>
            <label className={Styles.chartSelectReproductiveOption} for="3" aria-hidden="aria-hidden">Day</label>
          </li>
        </ul>
      </div> */}

			<AreaChart
				width={graphWidth} height={280} data={data}
				margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
			>
				{/* <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#20A892" stopOpacity={0.5}/>
            <stop offset="95%" stopColor="#20A892" stopOpacity={0.5}/>
          </linearGradient>
        </defs> */}
				<XAxis
					// label={{ value: 'Time(Seconds)', offset: 0, position:"insideBottom"}}
					dataKey="created_at"
					padding={{ left: 0, right: 40 }}
					axisLine={true}
					tickLine={true}
				>
				</XAxis>
				<YAxis
					label={{ value: subtitleName, angle: -90, position: 'insideLeft', offset:6 }}
					domain={[0, 160]}
					axisLine={true}
					tickLine={true}
				>
				</YAxis>
				{/* <CartesianGrid vertical={false} /> */}
				<Tooltip
					content={<CustomTooltip />}
					contentStyle={{ width: 100, height: 50, borderRadius: 20 }}
					// viewBox={{x: 0, y: 0, width: 400, height: 100 }}
				/>
				<Area
					type="monotone"
					dataKey='value'
					stroke={Constants.graphLineOne}
					fillOpacity={0} fill="url(#colorPv)"
					dat={true}
				/>
			</AreaChart>
		</div>
	)
}

const CustomTooltip = ({ active, payload, label }) => {
	if (active) {
		return (
			<div className={Styles.customTooltip}>
				<p className={Styles.tooleTipLabel}>{`Treatment: ${label}`}</p>
				{/* <p className="intro">{getIntroOfPage(label)}</p>
        <p className="desc">Anything you want can be displayed here.</p> */}
			</div>
		);
	}
	return null;
};

const ChartTooltip = ({option}) => {
	const [chartData, setChartData] = useState([])

	useEffect(() => {
		let temp = []
		temp.push({date: moment().toDate(), value: 3})
		temp.push({date: moment().subtract(2, 'days').toDate(), value: 5})
		temp.push({date: moment().subtract(2, 'weeks').toDate(), value: 8})
		temp.push({date: moment().subtract(3, 'weeks').toDate(), value: 4})
		setChartData(temp)
	},[option])

	return (
		<ReactTooltip id={option.label}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}>
			<div>
				<LineChart width={500} height={400} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
					<Legend verticalAlign="top"/>
					<Line name={'Value'} type="monotone" dataKey="value" stroke={'#606060'} />
					<XAxis dataKey="date" stroke={'#000000cc'}  tickFormatter={(tickItem) => {
						return moment(tickItem).format('MMM-D')
					}}/>
					<YAxis stroke={'#000000cc'}/>
					<Tooltip />
				</LineChart>
			</div>
		</ReactTooltip>
	)
}
