import React from 'react'
import Constants from "../values";
import { getQuestionnaireResults } from './getQuestionnaireResults';

export const getResultsColor = (path, score, screeningData) => {
  console.log('pathsajsd: ', path, score, screeningData?.[0]?.[getQuestionnaireResults(path)]?.toFixed(2))

  switch (path) {
    case 'anxiety':
      if(score<= 4)
        return Constants.graphReferenceColors[0]
      if(score>4 && score<= 9)
        return Constants.graphReferenceColors[1]
      if(score>9 && score<= 14)
        return Constants.graphReferenceColors[3]
      if(score>14)
        return Constants.graphReferenceColors[4]
 
    case 'mental':
      if(score<= 4)
        return Constants.graphReferenceColors[0]
      if(score>4 && score<= 9)
        return Constants.graphReferenceColors[1]
      if(score>9 && score<= 14)
        return Constants.graphReferenceColors[2]
      if(score>14 && score<= 19)
        return Constants.graphReferenceColors[3]
      if(score>19 && score<= 27)
        return Constants.graphReferenceColors[4]
      if(score>27)
        return Constants.graphReferenceColors[5]
      
    case 'haqScore':
      if(score<= 0.25)
        return Constants.graphReferenceColors[0]
      if(score>0.25 && score<= 3.70)
        return Constants.graphReferenceColors[1]
      if(score>3.70 && score<= 8)
        return Constants.graphReferenceColors[2]
      if(score>8)
        return Constants.graphReferenceColors[4]

    case 'physical':
      if(score<= 0.25)
        return Constants.graphReferenceColors[0]
      if(score>0.25 && score<= 3.70)
        return Constants.graphReferenceColors[1]
      if(score>3.70 && score<= 8)
        return Constants.graphReferenceColors[2]
      if(score>8)
        return Constants.graphReferenceColors[4]

    case 'gastro':
      if(score<= 4)
        return Constants.graphReferenceColors[0]
      if(score>4 && score<= 7)
        return Constants.graphReferenceColors[1]
      if(score>7 && score<= 16)
        return Constants.graphReferenceColors[2]
      if(score>16)
        return Constants.graphReferenceColors[4]

    case 'colitis':
      if(score<= 5)
        return Constants.graphReferenceColors[0]
      if(score>5 && score<= 10)
        return Constants.graphReferenceColors[2]
      if(score>10)
        return Constants.graphReferenceColors[4]

    case 'sleepapnea':
      if(score<= 2.99)
        return Constants.graphReferenceColors[0]
      if(score>=3 && score<= 4.99)
        return Constants.graphReferenceColors[2]
      if(score>4.99)
        return Constants.graphReferenceColors[4]

    case 'burnout':
      if(score<= 18)
        return Constants.graphReferenceColors[0]
      if(score>18 && score<= 32)
        return Constants.graphReferenceColors[1]
      if(score>32 && score<= 49)
        return Constants.graphReferenceColors[2]
      if(score>49 && score<= 59)
        return Constants.graphReferenceColors[3]
      if(score>59)
        return Constants.graphReferenceColors[4]

    case 'ace':
      if(score<= 2.99)
        return Constants.graphReferenceColors[0]
      if(score>2.99 && score<= 4.99)
        return Constants.graphReferenceColors[2]
      if(score>4.99)
        return Constants.graphReferenceColors[4]

    default:
      return Constants.lightGray
  }
}