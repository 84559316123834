import React, { Component } from 'react'
import PatientDetailsTooltip from './patientDetailsTooltip'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css' // Import Tippy styles

class PatientDetailsHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadDetails: false,
    }
  }

  render() {
    return (
      <Tippy
        content={
          this.state.loadDetails ? (
            <PatientDetailsTooltip patientId={this.props.patientId} />
          ) : null
        }
        placement="right"
        delay= {[200, 200]}
        onShow={(instance) => {
          console.log('is->', instance)
          this.setState({ loadDetails: true })
        }}
        onHide={(instance) => {
          console.log('ih->', instance)
          this.setState({ loadDetails: false })
        }}
      >
        <p>
          {this.props.firstName || 'ERROR'}{' '}
          {this.props.lastName || '(OR DELETED)'}
        </p>
      </Tippy>
    )
  }
}

export default PatientDetailsHeader
