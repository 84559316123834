import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import 'react-datepicker/dist/react-datepicker.css'
import DateTimePicker from 'react-datetime-picker'
import FancyField from 'react-fancy-field'
import { ArrowBackOutline } from 'react-ionicons'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { Link } from 'react-router-dom'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import AilaLogo from '../../assets/images/aila_logo.png'
import firebase from '../../services/firebase.js'
import values from '../../values'
import GlobalStyles from '../styles/global.module.scss'
import './rangeSlider.css'
import Styles from './styles/AilaWebQues.module.scss'
import Config from '../../config'

import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'

const conditionConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  // THIS IS THE ACTUAL CONDITIONS INDEX, DONT CHANGE
  engineName: 'conditions',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigConditions = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: conditionConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      object_id: { raw: {} },
      symptom: { raw: {} },
      description: { raw: {} },
      node: { raw: {} },
    },
  },
}

const medicationsConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'medications',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigMedications = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: medicationsConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      aila_id: { raw: {} },
      drug_name: { raw: {} },
      object_id: { raw: {} },
    },
  },
}

const vitaminsConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  engineName: 'vitamins',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigVitamins = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: vitaminsConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      object_id: { raw: {} },
      name: { raw: {} },
    },
  },
}



// const condnClient = {
//   search(requests) {
//     if (requests[0].params.query === '') {
//       return []
//     }
//     return searchClient.search(requests)
//   },
// }

const questionStyle = {
  marginTop: 80,
  fontSize: 20,
  wordWrap: 'break-word',
  overflow: 'hidden',
}

const triggerNames = [
  { name: 'Stress', isChecked: false },
  { name: 'Allergies', isChecked: false },
  { name: 'Injury', isChecked: false },
  { name: 'Surgery', isChecked: false },
  { name: 'Weather/ Air pressure', isChecked: false },
  { name: 'Physical exertion', isChecked: false },
  { name: 'Cold or other viral infection', isChecked: false },
  { name: 'Cigarette Smoke', isChecked: false },
  { name: 'Ultraviolet (UV) light exposure', isChecked: false },
  { name: 'Menstruation', isChecked: false },
  { name: 'Gluten', isChecked: false },
  { name: 'Dairy', isChecked: false },
  { name: 'Eggs', isChecked: false },
  { name: 'Nightshade Vegetables (e.g. eggplant)', isChecked: false },
  { name: 'Legumes/ Beans', isChecked: false },
  { name: 'Refined sugar or sweets', isChecked: false },
  { name: 'Alcohol', isChecked: false },
  { name: 'Caffeine', isChecked: false },
  { name: 'Lack of sleep', isChecked: false },
  { name: 'Other, Please specify', isChecked: false },
]

export default class LoginCovid extends Component {
  constructor() {
    super()
    this.state = {
      q0: '',
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
      q6: 1,
      q7: '',
      q8: '',
      q9: '',
      q10: triggerNames,
      q11: '',
      q12: '',
      q13: 1,
      q14: 1,
      q15: '',
      q16: '',
      q17: '',
      autoCondn: '',
      triggers: '',
      gender: '',
      ethnicity: '',
      error: '',
      errorString: '',
      name: localStorage.getItem('name') || '',
      email: localStorage.getItem('email') || '',
      phNo: localStorage.getItem('phNo') || '',
      loading: false,
      onComplete: false,
      cronicCondn: [],
      earlySymp: [],
      mainSymp: [],
      medications: [],
      suplements: [],
      docId: '',
      searchQuery: {
        chronic: '',
        early: '',
        main: '',
        medi: '',
        sup: '',
      },
    }
    this.handleMultipleOptions = this.handleMultipleOptions.bind(this)
    this.handleMultipleOptionsMedication =
      this.handleMultipleOptionsMedication.bind(this)
    this.handleSendEmail = this.handleSendEmail.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()
    const {
      email,
      phNo,
      name,
      ethnicity,
      q0,
      q1,
      q2,
      q3,
      q4,
      q5,
      q6,
      q7,
      q8,
      q9,
      q10,
      q11,
      q12,
      q13,
      q14,
      q15,
      q16,
      q17,
      cronicCondn,
      autoCondn,
      mainSymp,
      earlySymp,
      suplements,
      medications,
      triggers,
    } = this.state
    if (
      !q1.length ||
      !q2.length ||
      !q3.length ||
      !q6 ||
      !phNo.length ||
      !name.length ||
      !email.length
    ) {
      this.setState({
        error: true,
        errorString: 'Please Fill the Form',
      })
      return
    }

    if (moment().diff(q0, 'years') <= 17) {
      this.setState({
        error: true,
        errorString: 'You must be atleast 18 years of age to submit',
      })
      return
    }

    if (!email || email.length === 0 || !this.validateEmail(email)) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid email address',
      })
      return
    }

    if (!phNo || phNo.length < 10 || phNo.length > 10) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid phone number',
      })
      return
    }

    this.setState({ loading: true })

    var triggerList = []
    this.state.q10.forEach((val) => {
      if (val.isChecked) {
        if (val.name === 'Other, Please specify') {
          triggerList.push(triggers)
        } else {
          triggerList.push(val.name)
        }
      }
    })

    var data = {
      dob: q0,
      zipCode: q1,
      gender: q2,
      ethnicity: q3 === 'Other' ? ethnicity : q3,
      diagnosedAutoImmDisease: this.props.location.diseaseInfo,
      cronicCondn: cronicCondn,
      qualityLife: q6,
      timeDisease: q7,
      earlySymp: earlySymp,
      mainSymp: mainSymp,
      bigTrigger: triggerList,
      medication: medications,
      suplements: suplements,
      alternativeTreatments: q17,
      userName: name,
      email: email,
      phoneNumber: phNo,
      created_at: new Date(),
      emailSent: 'Not opt',
    }
    localStorage.setItem('name', name)
    localStorage.setItem('email', email)
    localStorage.setItem('phNo', phNo)

    let self = this
    firebase
      .firestore()
      .collection('aila_questionnaire')
      .add(data)
      .then((res) => {
        console.log('successfully Added data', res.id)
        this.setState({
          q0: '',
          q1: '',
          q2: '',
          q3: '',
          q4: this.props.match.params.diseaseName, // params passed from the Intro Screen
          q5: '',
          q6: 1,
          q7: '',
          q8: '',
          q9: '',
          q10: [
            { name: 'Stress', isChecked: false },
            { name: 'Allergies', isChecked: false },
            { name: 'Injury', isChecked: false },
            { name: 'Surgery', isChecked: false },
            { name: 'Weather/ Air pressure', isChecked: false },
            { name: 'Physical exertion', isChecked: false },
            { name: 'Cold or other viral infection', isChecked: false },
            { name: 'Cigarette Smoke', isChecked: false },
            { name: 'Ultraviolet (UV) light exposure', isChecked: false },
            { name: 'Menstruation', isChecked: false },
            { name: 'Gluten', isChecked: false },
            { name: 'Dairy', isChecked: false },
            { name: 'Eggs', isChecked: false },
            { name: 'Nightshade Vegetables (e.g. eggplant)', isChecked: false },
            { name: 'Legumes/ Beans', isChecked: false },
            { name: 'Refined sugar or sweets', isChecked: false },
            { name: 'Alcohol', isChecked: false },
            { name: 'Caffeine', isChecked: false },
            { name: 'Lack of sleep', isChecked: false },
            { name: 'Other, Please specify', isChecked: false },
          ],
          q11: '',
          q12: '',
          q13: 1,
          q14: 1,
          q15: '',
          q16: '',
          q17: '',
          autoCondn: '',
          triggers: '',
          gender: '',
          ethnicity: '',
          error: '',
          errorString: '',
          loading: false,
          onComplete: true,
          cronicCondn: [],
          earlySymp: [],
          mainSymp: [],
          medications: [],
          suplements: [],
          docId: res.id,
        })
      })
      .catch(function (error) {
        console.log('error::::::', error)
        self.setState({
          error: true,
          errorString: 'Error has occured while adding data',
          loading: false,
        })
      })
  }

  validateEmail(email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  // to handle the change of slider values
  handleSliderOnChange = (value, state) => {
    switch (state) {
      case 'q6':
        this.setState({
          q6: value,
        })
        break
      case 'q13':
        this.setState({
          q13: value,
        })
        break
      case 'q14':
        this.setState({
          q14: value,
        })
        break

      default:
        break
    }
  }

  // Handle multiple option selection checkbox
  handleMultipleOptions(value) {
    let tempq = this.state.q10
    tempq.forEach((item) => {
      if (item.name === value) item.isChecked = !item.isChecked
    })
    this.setState({ q10: tempq })
  }

  handleMultipleOptionsMedication(value) {
    let tempq = this.state.medications
    tempq.forEach((item) => {
      if (item.name === value) item.isChecked = !item.isChecked
    })
    this.setState({ medications: tempq })
  }

  // updating the firestore emailSent field to trigger the background listener
  handleSendEmail({ fireStoreId }) {
    alert('Please check your email for the app link')
    firebase
      .firestore()
      .collection('aila_questionnaire')
      .doc(fireStoreId)
      .update({ emailSent: false })
  }

  render() {
    const horizontalLabels = {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
    }

    // to display the names of selected medication
    const placeHolderMedication = this.state.medications
      .map((data, index) => {
        if (index === 0) {
          return data.name
        } else {
          return `, ${data.name}`
        }
      })
      .join('')

    // to display the names of suplements
    const placeHolderSup = this.state.suplements
      .map((data, index) => {
        if (index === 0) {
          return data.name
        } else {
          return `, ${data.name}`
        }
      })
      .join('')

    // name of condition passed from the intro screen
    const diseaseName = this.props.match.params.diseaseName
    const questionSet = [
      'Date of Birth:',
      'Zip Code:',
      'What gender were you at birth:',
      'Please indicate your ethnicity',
      'Have you been diagnosed with any of the following autoimmune diseases? (Select all that apply)',
      'Are you living with any other chronic conditions?',
      'Chronic illnesses impact everyone differently, how would you rate your current overall quality of life?',
      `When thinking about your ${diseaseName}, How long ago were you diagnosed?`,
      `What were your early symptoms of ${diseaseName}`,
      `What are your main symptoms of ${diseaseName} now?`,
      `What are the biggest triggers to your ${diseaseName} symptom flare ups?`,
      `What medications have you taken for your  ${diseaseName}?`,
      `What supplements have you taken for your  ${diseaseName}?`,
      `How effective was ${
        placeHolderMedication.length === 0
          ? 'medications'
          : placeHolderMedication
      } in managing your ${diseaseName}? on a scale of 1-10 (1 = Not at all effective and 10 = Most effective)`,
      `How effective was ${
        placeHolderSup.length === 0 ? 'supplements' : placeHolderSup
      } in managing your ${diseaseName}? on a scale of 1-10 (1 = Not at all effective and 10 = Most effective)`,
      `Did you experience any side effects of being on medications?`,
      'If you answered yes, what were they?',
      `Are there any alternative therapies or treatments you used to manage your ${diseaseName}?`,
    ]

    // to render after completion of the survey
    const OnSuccess = () => {
      const urlSocial = 'https://www.ailahealth.com/community-insights-survey'
      const socialTitle = `I am supporting the chronic illness community by sharing my experiences with the symptoms and treatments for my disease. Together we can figure out which treatments works best and share insights today! Click the link to support the chronic illness community.`
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            paddingTop: 40,
          }}
        >
          <img src={AilaLogo} style={{ height: 120, width: 120 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '20px 6px',
              borderRadius: 4,
              border: '1px solid gray',
              width: '90%',
              marginTop: 20,
            }}
          >
            <svg
              className={GlobalStyles.checkmark}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className={GlobalStyles.checkmark__circle}
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className={GlobalStyles.checkmark__check}
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <h5
              style={{
                marginTop: 10,
                fontSize: 20,
                textAlign: 'center',
                color: '#7e7e7e',
              }}
            >
              Thank you for completing the chronic illness survey!
            </h5>
            <div style={{ alignSelf: 'center', width: '70%', marginTop: 30 }}>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 'normal',
                  color: values.primaryThemeDark,
                  textAlign: 'center',
                }}
              >
                Once we have enough responses we will share the insights on a
                dashboard. Share this survey with other patients in your
                community so we can increase awareness of autoimmune diseases
                and develop collective insight on how to manage them.
              </p>
            </div>
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <EmailShareButton
                url={urlSocial}
                subject={'Support the chronic illness community!'}
                body={socialTitle}
                openShareDialogOnClick={true}
                onClick={() => {}}
                // title={socialTitle}
              >
                <EmailIcon size={32} style={{ margin: 10 }} round />
              </EmailShareButton>

              <TwitterShareButton url={urlSocial} title={socialTitle}>
                <TwitterIcon size={32} style={{ margin: 10 }} round />
              </TwitterShareButton>

              <WhatsappShareButton url={urlSocial} title={socialTitle}>
                <WhatsappIcon size={32} style={{ margin: 10 }} round />
              </WhatsappShareButton>

              {/* <LinkedinShareButton
                url={urlSocial}
                title={socialTitle}
              >
                <LinkedinIcon
                  size={32}
                  style={{margin: 10}}
                  round />
              </LinkedinShareButton> */}

              <FacebookShareButton
                url={urlSocial}
                // title={socialTitle}
                quote={socialTitle}
                hashtag={'#Cronicillness'}
              >
                <FacebookIcon size={32} style={{ margin: 10 }} round />
              </FacebookShareButton>
            </div>
            <div style={{ alignSelf: 'center', width: '70%', marginTop: 30 }}>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 'normal',
                  color: values.primaryThemeDark,
                  textAlign: 'center',
                }}
              >
                If you want to connect with a community of chronic illness
                warriors and manage your health all in one place, download the
                Aila Health App
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 30,
              }}
            >
              <div
                style={{ textAlign: 'center', marginLeft: 10, marginRight: 10 }}
              >
                <Button
                  onClick={() =>
                    this.handleSendEmail({ fireStoreId: this.state.docId })
                  }
                  className={GlobalStyles.button}
                  disabled={this.state.loading}
                  style={{ marginTop: 40, width: 200 }}
                  variant="primary"
                >
                  Download App
                </Button>
              </div>
              <div
                style={{ textAlign: 'center', marginLeft: 10, marginRight: 10 }}
              >
                <Link to={'/wix/aila-questionnaire-intro'}>
                  <Button
                    className={GlobalStyles.button}
                    disabled={this.state.loading}
                    style={{ marginTop: 40, width: 200 }}
                    variant="primary"
                  >
                    Take another Survey
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    }

    // to handle the Selection of symptoms, cronic, earlySymptoms, main symptoms, drugname and suplements from algolia
    const handleHitClick = (hit, q) => {
      if (q === 'q5') {
        this.setState({ q5: hit.symptom })
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.cronicCondn.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ cronicCondn: arr })
        } else {
          this.setState({
            cronicCondn: [
              ...this.state.cronicCondn,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 'q8') {
        this.setState({ q8: hit.symptom })
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.earlySymp.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ earlySymp: arr })
        } else {
          this.setState({
            earlySymp: [
              ...this.state.earlySymp,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 'q9') {
        this.setState({ q9: hit.symptom })
        if (handleChecked(q, hit.symptom)) {
          let arr = this.state.mainSymp.filter(
            (data) => data.name !== hit.symptom,
          )
          this.setState({ mainSymp: arr })
        } else {
          this.setState({
            mainSymp: [
              ...this.state.mainSymp,
              { name: hit.symptom, id: hit.node },
            ],
          })
        }
      }
      if (q === 'q11') {
        this.setState({ q11: hit.DrugName })
        if (handleChecked(q, hit.DrugName)) {
          let arr = this.state.medications.filter(
            (data) => data.name !== hit.DrugName,
          )
          this.setState({ medications: arr })
        } else {
          this.setState({
            medications: [
              ...this.state.medications,
              { name: hit.DrugName, id: hit.AILA_ID, isChecked: false },
            ],
          })
        }
      }
      if (q === 'q12') {
        this.setState({ q12: hit.name })
        if (handleChecked(q, hit.name)) {
          let arr = this.state.suplements.filter(
            (data) => data.name !== hit.name,
          )
          this.setState({ suplements: arr })
        } else {
          this.setState({
            suplements: [
              ...this.state.suplements,
              { name: hit.name, id: hit.objectID },
            ],
          })
        }
      }
    }

    // to handle the list of selected conditions from algolia
    const handleChecked = (q, name) => {
      if (q === 'q5') {
        return this.state.cronicCondn.some((data) => data.name === name)
      }
      if (q === 'q8') {
        return this.state.earlySymp.some((data) => data.name === name)
      }
      if (q === 'q9') {
        return this.state.mainSymp.some((data) => data.name === name)
      }
      if (q === 'q11') {
        return this.state.medications.some((data) => data.name === name)
      }
      if (q === 'q12') {
        return this.state.suplements.some((data) => data.name === name)
      }
    }

    // to display the list of results in algolia searchbox
    // const Hit = (props, q, type) => {
    //   let { hit } = props
    //   let checked = handleChecked(q, hit.symptom)
    //   return (
    //     <div
    //       style={{ width: '100%', cursor: 'pointer' }}
    //       onClick={() => {
    //         handleHitClick(hit, q)
    //       }}
    //     >
    //       {hit.symptom}
    //     </div>
    //   )
    // }

    // to display the list of results in algolia searchbox
    // const HitMedications = (props, q) => {
    //   let { hit } = props
    //   let checked = handleChecked(q, hit.DrugName)
    //   return (
    //     <div
    //       style={{ width: '100%', cursor: 'pointer' }}
    //       onClick={() => {
    //         handleHitClick(hit, q)
    //       }}
    //     >
    //       {hit.DrugName}
    //     </div>
    //   )
    // }

    // to display the list of results in algolia searchbox
    // const HitSuplements = (props, q) => {
    //   let { hit } = props
    //   let checked = handleChecked(q, hit.name)
    //   return (
    //     <div
    //       style={{ width: '100%', cursor: 'pointer' }}
    //       onClick={() => {
    //         handleHitClick(hit, q)
    //       }}
    //     >
    //       {hit.name}
    //     </div>
    //   )
    // }

    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          margin: '0 auto',
          backgroundColor: 'white',
        }}
      >
        {this.state.onComplete ? (
          // render after completion of survey
          <OnSuccess />
        ) : (
          <div className={Styles.wrapper}>
            {this.state.loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: 200,
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '90%',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    marginTop: 10,
                    alignSelf: 'flex-start',
                  }}
                >
                  <Link to={'/wix/aila-questionnaire-intro'}>
                    <ArrowBackOutline
                      color={'#066D6F'}
                      height="30px"
                      width="30px"
                    />
                  </Link>
                </div>

                <p
                  style={{
                    fontSize: 40,
                    marginTop: 20,
                    fontWeight: 'bold',
                    color: values.primaryThemeDark,
                    fontFamily: 'Avenir-Heavy',
                  }}
                >
                  Welcome!
                </p>
                <div
                  style={{ marginTop: 30, width: '70%', textAlign: 'center' }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      color: values.primaryThemeDark,
                      fontFamily: 'Avenir',
                      marginTop: 10,
                    }}
                  >
                    Hello! Thanks for your interest in sharing your experience
                    with the chronic illness community!
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      color: values.primaryThemeDark,
                      fontFamily: 'Avenir',
                      marginTop: 20,
                    }}
                  >
                    We keep all of your data in secure, HIPAA compliant
                    databases so your information stays private.
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      color: values.primaryThemeDark,
                      fontFamily: 'Avenir',
                      marginTop: 20,
                    }}
                  >
                    Once you share your experience with your chronic illness,
                    your information will be anonymized and added to a secure
                    database of patients living with your condition, so we can
                    share what worked for other patients and what did not.
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      color: values.primaryThemeDark,
                      fontFamily: 'Avenir',
                      marginTop: 20,
                    }}
                  >
                    At the end of the questionnaire, create an account to be
                    able to login to the secure platform and see your data
                    compared to the group of respondents living with your
                    condition, which will be available once we have enough
                    participants who have completed the survey.
                  </p>
                </div>
                <div className={Styles.questionContainer}>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[0]}
                  </p>
                  <Form.Group
                    controlId="date"
                    style={{ margin: 10, marginLeft: 0, width: '30%' }}
                  >
                    <DateTimePicker
                      format={'MMM-dd-y'}
                      onChange={(val) => this.setState({ q0: val })}
                      value={this.state.q0}
                    />
                    {/*<DatePicker*/}
                    {/*  showMonthDropdown*/}
                    {/*  showYearDropdown*/}
                    {/*  className={Styles.textInput2}*/}
                    {/*  selected={this.state.q0}*/}
                    {/*  onChange={(date) => this.setState({q0: date})}*/}
                    {/*  placeholderText="Select date"*/}
                    {/*/>*/}
                  </Form.Group>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[1]}
                  </p>
                  <Form.Group
                    controlId="zipcode"
                    style={{ margin: 10, marginLeft: 0, width: '30%' }}
                  >
                    <FancyField
                      value={this.state.q1}
                      classes={Styles.textInput2}
                      label="Zip Code"
                      required={false}
                      name="zip"
                      onChange={(val) => this.setState({ q1: val })}
                      placeholder="Enter Zip Code"
                    />
                  </Form.Group>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[2]}
                  </p>
                  <Form.Group
                    controlId="gender"
                    style={{ margin: 10, marginLeft: 20 }}
                  >
                    {['Male', 'Female'].map((type) => (
                      <Form.Check
                        type={'radio'}
                        label={type}
                        value={type}
                        onClick={(val) =>
                          this.setState({ q2: val.target.value })
                        }
                        style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                        checked={this.state.q2 === type}
                        classes={Styles.radioButton}
                      />
                    ))}
                  </Form.Group>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[3]}
                  </p>
                  <Form.Group
                    controlId="ethnicity"
                    style={{ margin: 10, marginLeft: 20 }}
                  >
                    {[
                      'White',
                      'Black',
                      'Hispanic',
                      'Native American',
                      'Hawaiian/Pacific Islander',
                      'Asain',
                      'Other',
                    ].map((type) => (
                      <Form.Check
                        type={'radio'}
                        label={type}
                        value={type}
                        onClick={(val) =>
                          this.setState({ q3: val.target.value })
                        }
                        style={{ color: 'rgb(23,61,68)', fontFamily: 'Avenir' }}
                        checked={this.state.q3 === type}
                        classes={Styles.radioButton}
                      />
                    ))}
                    {this.state.q3 === 'Other' && (
                      <div style={{ width: '30%' }}>
                        <FancyField
                          value={this.state.ethnicity}
                          classes={Styles.textInput2}
                          label="Ethnicity"
                          required={false}
                          name="ethnicity"
                          onChange={(val) => this.setState({ ethnicity: val })}
                          placeholder="Enter your Ethnicity"
                        />
                      </div>
                    )}
                  </Form.Group>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[5]}
                  </p>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Group
                      controlId="cronic condn"
                      style={{ margin: 10, marginLeft: 20, width: '40%' }}
                    >
                      {/* <InstantSearch
                    searchClient={condnClient}
                    indexName={'CONDITIONS'}
                    onSearchStateChange={({ query }) => this.setState(prev => ({searchQuery: {...prev.searchQuery, chronic: query}}))}
                  >
                    <SearchBox />
                    { (this.state.searchQuery.chronic && this.state.searchQuery.chronic.length !==0) &&
                      <div style={{overflow: 'auto', height: 180, marginTop: 20}}>
                        <RefinementList limit={5} />
                        <Hits hitComponent={(props) => Hit(props, 'q5', 'cronicCondn')} />
                      </div>
                    }
                  </InstantSearch> */}
                      <SearchProvider config={esConfigConditions}>
                        <WithSearch
                          mapContextToProps={({
                            wasSearched,
                            setSearchTerm,
                          }) => ({
                            wasSearched,
                            setSearchTerm,
                          })}
                        >
                          {({ wasSearched, setSearchTerm }) => {
                            // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                            return (
                              <div className="App">
                                <EsSearchBox
                                  debounceLength={0}
                                  autocompleteResults={{
                                    titleField: 'symptom',
                                  }}
                                  searchAsYouType={true}
                                  onSelectAutocomplete={(e) => {
                                    // Q5
                                    this.setState({ q5: e.symptom.raw })
                                    if (handleChecked('q5', e.symptom.raw)) {
                                      let arr = this.state.cronicCondn.filter(
                                        (data) => data.name !== e.symptom.raw,
                                      )
                                      this.setState({ cronicCondn: arr })
                                    } else {
                                      this.setState({
                                        cronicCondn: [
                                          ...this.state.cronicCondn,
                                          {
                                            name: e.symptom.raw,
                                            id: e.node.raw,
                                          },
                                        ],
                                      })
                                    }
                                    setSearchTerm('')
                                  }}
                                  inputView={({
                                    getAutocomplete,
                                    getInputProps,
                                    getButtonProps,
                                  }) => (
                                    <>
                                      <div className="sui-search-box__wrapper">
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Search Conditions',
                                          })}
                                        />
                                        {getAutocomplete()}
                                      </div>
                                      {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                                    </>
                                  )}
                                />
                              </div>
                            )
                          }}
                        </WithSearch>
                      </SearchProvider>
                    </Form.Group>
                    {this.state.cronicCondn.length !== 0 && (
                      <div
                        style={{
                          fontSize: 15,
                          marginTop: 20,
                          fontWeight: 'normal',
                          color: values.primaryThemeDark,
                          marginLeft: 30,
                        }}
                      >
                        Added:
                        <br />{' '}
                        {this.state.cronicCondn.map((data, index) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 10,
                              }}
                            >
                              <p>
                                {index + 1}. {data.name + ' '}
                              </p>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={Styles.deleteIcon}
                                onClick={() =>
                                  handleHitClick({ symptom: data.name }, 'q5')
                                }
                              />
                              <br />
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[6]}
                  </p>
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: 'normal',
                      color: values.primaryThemeDark,
                    }}
                  >
                    On a scale of 1-10 (1 = Very Low Quality of Life and 10 =
                    Very High Quality of Life)
                  </p>
                  <div
                    style={{
                      margin: 10,
                      marginLeft: 20,
                      marginTop: 70,
                      marginBottom: 70,
                      width: '60%',
                    }}
                  >
                    <Form.Group controlId="QL">
                      <Slider
                        min={1}
                        max={10}
                        step={1}
                        labels={horizontalLabels}
                        value={this.state.q6}
                        orientation="horizontal"
                        onChange={(val) => this.handleSliderOnChange(val, 'q6')}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          paddingTop: 20,
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 'normal',
                            color: values.primaryThemeDark,
                            width: 120,
                            textAlign: 'center',
                            marginLeft: -45,
                          }}
                        >
                          Very Low Quality of Life
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 'normal',
                            color: values.primaryThemeDark,
                            width: 120,
                            textAlign: 'center',
                            marginRight: -50,
                          }}
                        >
                          Very High Quality of Life
                        </p>
                      </div>
                    </Form.Group>
                  </div>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[7]}
                  </p>
                  <Form.Group
                    controlId="date"
                    style={{ margin: 10, marginLeft: 0, width: '30%' }}
                  >
                    <DateTimePicker
                      format={'MMM-dd-y'}
                      onChange={(val) => this.setState({ q7: val })}
                      value={this.state.q7}
                    />
                  </Form.Group>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[8]}
                  </p>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Group
                      controlId="earlySym"
                      style={{ margin: 10, marginLeft: 20, width: '40%' }}
                    >
                      {/* <InstantSearch
                        searchClient={condnClient}
                        indexName={'CONDITIONS'}
                        onSearchStateChange={({ query }) =>
                          this.setState((prev) => ({
                            searchQuery: { ...prev.searchQuery, early: query },
                          }))
                        }
                      >
                        <SearchBox />
                        {this.state.searchQuery.early.length !== 0 && (
                          <div
                            style={{
                              overflow: 'auto',
                              height: 180,
                              marginTop: 20,
                            }}
                          >
                            <RefinementList limit={5} />
                            <InfiniteHits
                              hitComponent={(props) =>
                                Hit(props, 'q8', 'earlySymp')
                              }
                            />
                          </div>
                        )}
                      </InstantSearch> */}
                      <SearchProvider config={esConfigConditions}>
                        <WithSearch
                          mapContextToProps={({
                            wasSearched,
                            setSearchTerm,
                          }) => ({
                            wasSearched,
                            setSearchTerm,
                          })}
                        >
                          {({ wasSearched, setSearchTerm }) => {
                            // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                            return (
                              <div className="App">
                                <EsSearchBox
                                  debounceLength={0}
                                  autocompleteResults={{
                                    titleField: 'symptom',
                                  }}
                                  searchAsYouType={true}
                                  onSelectAutocomplete={(e) => {
                                    // Q8
                                    this.setState({ q8: e.symptom.raw })
                                    if (handleChecked('q8', e.symptom.raw)) {
                                      let arr = this.state.earlySymp.filter(
                                        (data) => data.name !== e.symptom.raw,
                                      )
                                      this.setState({ earlySymp: arr })
                                    } else {
                                      this.setState({
                                        earlySymp: [
                                          ...this.state.earlySymp,
                                          {
                                            name: e.symptom.raw,
                                            id: e.node.raw,
                                          },
                                        ],
                                      })
                                    }
                                    setSearchTerm('')
                                  }}
                                  inputView={({
                                    getAutocomplete,
                                    getInputProps,
                                    getButtonProps,
                                  }) => (
                                    <>
                                      <div className="sui-search-box__wrapper">
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Search Conditions',
                                          })}
                                        />
                                        {getAutocomplete()}
                                      </div>
                                      {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                                    </>
                                  )}
                                />
                              </div>
                            )
                          }}
                        </WithSearch>
                      </SearchProvider>
                    </Form.Group>
                    {this.state.earlySymp.length !== 0 && (
                      <div
                        style={{
                          fontSize: 15,
                          marginTop: 20,
                          fontWeight: 'normal',
                          color: values.primaryThemeDark,
                          marginLeft: 30,
                        }}
                      >
                        Added:
                        <br />{' '}
                        {this.state.earlySymp.map((data, index) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 10,
                              }}
                            >
                              <p>
                                {index + 1}. {data.name + ' '}
                              </p>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={Styles.deleteIcon}
                                onClick={() =>
                                  handleHitClick({ symptom: data.name }, 'q8')
                                }
                              />
                              <br />
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[9]}
                  </p>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Group
                      controlId="mainSym"
                      style={{ margin: 10, marginLeft: 20, width: '40%' }}
                    >
                      {/* <InstantSearch
                        searchClient={condnClient}
                        indexName={'CONDITIONS'}
                        onSearchStateChange={({ query }) =>
                          this.setState((prev) => ({
                            searchQuery: { ...prev.searchQuery, main: query },
                          }))
                        }
                      >
                        <SearchBox />
                        {this.state.searchQuery.main.length !== 0 && (
                          <div
                            style={{
                              overflow: 'auto',
                              height: 180,
                              marginTop: 20,
                            }}
                          >
                            <RefinementList limit={5} />
                            <InfiniteHits
                              hitComponent={(props) =>
                                Hit(props, 'q9', 'mainSymp')
                              }
                            />
                          </div>
                        )}
                      </InstantSearch> */}
                      <SearchProvider config={esConfigConditions}>
                        <WithSearch
                          mapContextToProps={({
                            wasSearched,
                            setSearchTerm,
                          }) => ({
                            wasSearched,
                            setSearchTerm,
                          })}
                        >
                          {({ wasSearched, setSearchTerm }) => {
                            // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                            return (
                              <div className="App">
                                <EsSearchBox
                                  debounceLength={0}
                                  autocompleteResults={{
                                    titleField: 'symptom',
                                  }}
                                  searchAsYouType={true}
                                  onSelectAutocomplete={(e) => {
                                    // Q9
                                    this.setState({ q9: e.symptom.raw })
                                    if (handleChecked('q9', e.symptom.raw)) {
                                      let arr = this.state.mainSymp.filter(
                                        (data) => data.name !== e.symptom.raw,
                                      )
                                      this.setState({ mainSymp: arr })
                                    } else {
                                      this.setState({
                                        mainSymp: [
                                          ...this.state.mainSymp,
                                          {
                                            name: e.symptom.raw,
                                            id: e.node.raw,
                                          },
                                        ],
                                      })
                                    }
                                    setSearchTerm('')
                                  }}
                                  inputView={({
                                    getAutocomplete,
                                    getInputProps,
                                    getButtonProps,
                                  }) => (
                                    <>
                                      <div className="sui-search-box__wrapper">
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Search Conditions',
                                          })}
                                        />
                                        {getAutocomplete()}
                                      </div>
                                      {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                                    </>
                                  )}
                                />
                              </div>
                            )
                          }}
                        </WithSearch>
                      </SearchProvider>
                    </Form.Group>
                    {this.state.mainSymp.length !== 0 && (
                      <div
                        style={{
                          fontSize: 15,
                          marginTop: 20,
                          fontWeight: 'normal',
                          color: values.primaryThemeDark,
                          marginLeft: 30,
                        }}
                      >
                        Added:
                        <br />{' '}
                        {this.state.mainSymp.map((data, index) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 10,
                              }}
                            >
                              <p>
                                {index + 1}. {data.name + ' '}
                              </p>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={Styles.deleteIcon}
                                onClick={() =>
                                  handleHitClick({ symptom: data.name }, 'q9')
                                }
                              />
                              <br />
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[10]}
                  </p>
                  <Form.Group
                    controlId="triggers"
                    style={{ margin: 10, marginLeft: 20 }}
                  >
                    {this.state.q10.map((type) => {
                      return (
                        <>
                          <label class={Styles.checkContainer}>
                            {type.name}
                            <input
                              type="checkbox"
                              checked={type.isChecked}
                              onClick={() => {
                                console.log('clocekd')
                                this.handleMultipleOptions(type.name)
                              }}
                            />
                            <span class={Styles.checkmark}></span>
                          </label>
                          {type.name === 'Other, Please specify' &&
                            type.isChecked && (
                              <div style={{ width: '30%' }}>
                                <FancyField
                                  value={this.state.triggers}
                                  classes={Styles.textInput2}
                                  label="Triggers"
                                  required={false}
                                  name="triggers"
                                  onChange={(val) =>
                                    this.setState({ triggers: val })
                                  }
                                  placeholder="Enter your triggers"
                                />
                              </div>
                            )}
                        </>
                      )
                    })}
                  </Form.Group>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[11]}
                  </p>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Group
                      controlId="medication"
                      style={{ margin: 10, marginLeft: 20, width: '40%' }}
                    >
                      {/* <InstantSearch
                        searchClient={condnClient}
                        indexName={'MEDICATIONS'}
                        onSearchStateChange={({ query }) =>
                          this.setState((prev) => ({
                            searchQuery: { ...prev.searchQuery, medi: query },
                          }))
                        }
                      >
                        <SearchBox />
                        {this.state.searchQuery.medi.length !== 0 && (
                          <div
                            style={{
                              overflow: 'auto',
                              height: 180,
                              marginTop: 20,
                            }}
                          >
                            <RefinementList limit={5} />
                            <InfiniteHits
                              hitComponent={(props) =>
                                HitMedications(props, 'q11')
                              }
                            />
                          </div>
                        )}
                      </InstantSearch> */}
                      <SearchProvider config={esConfigMedications}>
                        <WithSearch
                          mapContextToProps={({
                            wasSearched,
                            setSearchTerm,
                          }) => ({
                            wasSearched,
                            setSearchTerm,
                          })}
                        >
                          {({ wasSearched, setSearchTerm }) => {
                            // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                            return (
                              <div className="App">
                                <EsSearchBox
                                  debounceLength={0}
                                  autocompleteResults={{
                                    titleField: 'drug_name',
                                  }}
                                  onSelectAutocomplete={(e) => {
                                    // Q11
                                    this.setState({ q11: e.drug_name.raw })
                                    if (handleChecked('q11', e.drug_name.raw)) {
                                      let arr = this.state.medications.filter(
                                        (data) => data.name !== e.drug_name.raw,
                                      )
                                      this.setState({ medications: arr })
                                    } else {
                                      this.setState({
                                        medications: [
                                          ...this.state.medications,
                                          {
                                            name: e.drug_name.raw,
                                            id: e.aila_id.raw,
                                            isChecked: false,
                                          },
                                        ],
                                      })
                                    }
                                    setSearchTerm('')
                                  }}
                                  searchAsYouType={true}
                                  inputView={({
                                    getAutocomplete,
                                    getInputProps,
                                    getButtonProps,
                                  }) => (
                                    <>
                                      <div className="sui-search-box__wrapper">
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Search Medications',
                                          })}
                                        />
                                        {getAutocomplete()}
                                      </div>
                                      {/* <input
                                 {...getButtonProps({
                                     "data-custom-attr": "some value"
                                 })}
                             /> */}
                                    </>
                                  )}
                                />
                              </div>
                            )
                          }}
                        </WithSearch>
                      </SearchProvider>
                    </Form.Group>
                    {this.state.medications.length !== 0 && (
                      <div
                        style={{
                          fontSize: 15,
                          marginTop: 20,
                          fontWeight: 'normal',
                          color: values.primaryThemeDark,
                          marginLeft: 30,
                        }}
                      >
                        Added:
                        <br />{' '}
                        {this.state.medications.map((data, index) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 10,
                              }}
                            >
                              <p>
                                {index + 1}. {data.name + ' '}
                              </p>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={Styles.deleteIcon}
                                onClick={() =>
                                  handleHitClick({ DrugName: data.name }, 'q11')
                                }
                              />
                              <br />
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[12]}
                  </p>
                  ; ; ;
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Group
                      controlId="suplements"
                      style={{ margin: 10, marginLeft: 20, width: '40%' }}
                    >
                      {/* <InstantSearch
                        searchClient={condnClient}
                        indexName={'VITAMINS'}
                        onSearchStateChange={({ query }) =>
                          this.setState((prev) => ({
                            searchQuery: { ...prev.searchQuery, sup: query },
                          }))
                        }
                      >
                        <SearchBox />
                        {this.state.searchQuery.sup.length !== 0 && (
                          <div
                            style={{
                              overflow: 'auto',
                              height: 180,
                              marginTop: 20,
                            }}
                          >
                            <RefinementList limit={5} />
                            <InfiniteHits
                              hitComponent={(props) =>
                                HitSuplements(props, 'q12')
                              }
                            />
                          </div>
                        )}
                      </InstantSearch> */}
                      <SearchProvider config={esConfigVitamins}>
                        <WithSearch
                          mapContextToProps={({
                            wasSearched,
                            setSearchTerm,
                          }) => ({
                            wasSearched,
                            setSearchTerm,
                          })}
                        >
                          {({ wasSearched, setSearchTerm }) => {
                            // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                            return (
                              <div className="App">
                                <EsSearchBox
                                  debounceLength={0}
                                  autocompleteResults={{
                                    titleField: 'name',
                                  }}
                                  onSelectAutocomplete={(e) => {
                                    // Q12
                                    this.setState({ q12: e.name.raw })
                                    if (handleChecked('q12', e.name.raw)) {
                                      let arr = this.state.suplements.filter(
                                        (data) => data.name !== e.name.raw,
                                      )
                                      this.setState({ suplements: arr })
                                    } else {
                                      this.setState({
                                        suplements: [
                                          ...this.state.suplements,
                                          {
                                            name: e.name.raw,
                                            id: e.object_id.raw,
                                          },
                                        ],
                                      })
                                    }
                                    setSearchTerm('')
                                  }}
                                  searchAsYouType={true}
                                  inputView={({
                                    getAutocomplete,
                                    getInputProps,
                                    getButtonProps,
                                  }) => (
                                    <>
                                      <div className="sui-search-box__wrapper">
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Search Vitamins',
                                          })}
                                        />
                                        {getAutocomplete()}
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            )
                          }}
                        </WithSearch>
                      </SearchProvider>
                    </Form.Group>
                    {this.state.suplements.length !== 0 && (
                      <div
                        style={{
                          fontSize: 15,
                          marginTop: 20,
                          fontWeight: 'normal',
                          color: values.primaryThemeDark,
                          marginLeft: 30,
                        }}
                      >
                        Added:
                        <br />{' '}
                        {this.state.suplements.map((data, index) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 10,
                              }}
                            >
                              <p>
                                {index + 1}. {data.name + ' '}
                              </p>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={Styles.deleteIcon}
                                onClick={() =>
                                  handleHitClick({ name: data.name }, 'q12')
                                }
                              />
                              <br />
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                  {this.state.medications.map((data) => {
                    return (
                      <>
                        <p
                          className={Styles.questionsFont}
                          style={questionStyle}
                        >
                          {`How effective was ${data.name} in managing your ${diseaseName}?`}
                        </p>
                        <div
                          style={{
                            margin: 10,
                            marginLeft: 20,
                            marginTop: 70,
                            marginBottom: 70,
                            width: '60%',
                          }}
                        >
                          <Form.Group controlId="QL">
                            <Slider
                              min={1}
                              max={10}
                              step={1}
                              labels={horizontalLabels}
                              value={data.effective}
                              orientation="horizontal"
                              onChange={(val) => {
                                this.handleSliderOnChange(val, 'q13')
                                this.setState((prevState) => ({
                                  medications: prevState.medications.map(
                                    (medi) =>
                                      medi.id === data.id
                                        ? { ...medi, effective: val }
                                        : medi,
                                  ),
                                }))
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingTop: 20,
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 'normal',
                                  color: values.primaryThemeDark,
                                  width: 120,
                                  textAlign: 'center',
                                  marginLeft: -45,
                                }}
                              >
                                Not at all effective
                              </p>
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 'normal',
                                  color: values.primaryThemeDark,
                                  width: 100,
                                  textAlign: 'center',
                                  marginRight: -40,
                                }}
                              >
                                Most effective
                              </p>
                            </div>
                          </Form.Group>
                        </div>
                      </>
                    )
                  })}
                  {this.state.suplements.map((data) => {
                    // console.log(data, this.state.suplements)
                    return (
                      <>
                        <p
                          className={Styles.questionsFont}
                          style={questionStyle}
                        >
                          {`How effective was ${data.name} in managing your ${diseaseName}?`}
                        </p>
                        <div
                          style={{
                            margin: 10,
                            marginLeft: 20,
                            marginTop: 70,
                            marginBottom: 70,
                            width: '60%',
                          }}
                        >
                          <Form.Group controlId="QL">
                            <Slider
                              min={1}
                              max={10}
                              step={1}
                              labels={horizontalLabels}
                              value={data.effective}
                              orientation="horizontal"
                              onChange={(val) => {
                                this.handleSliderOnChange(val, 'q14')
                                this.setState((prevState) => ({
                                  suplements: prevState.suplements.map((sup) =>
                                    sup.id === data.id
                                      ? { ...sup, effective: val }
                                      : sup,
                                  ),
                                }))
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingTop: 20,
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 'normal',
                                  color: values.primaryThemeDark,
                                  width: 120,
                                  textAlign: 'center',
                                  marginLeft: -45,
                                }}
                              >
                                Not at all effective
                              </p>
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 'normal',
                                  color: values.primaryThemeDark,
                                  width: 100,
                                  textAlign: 'center',
                                  marginRight: -40,
                                }}
                              >
                                Most effective
                              </p>
                            </div>
                          </Form.Group>
                        </div>
                      </>
                    )
                  })}
                  <p className={Styles.questionsFont} style={questionStyle}>
                    {questionSet[17]}
                  </p>
                  <Form.Group
                    controlId="otherTreatments"
                    style={{ margin: 10, marginLeft: 0 }}
                  >
                    <FancyField
                      value={this.state.q17}
                      classes={Styles.textInput2}
                      label="Therapies or Treatments"
                      required={false}
                      name="alterTreat"
                      onChange={(val) => this.setState({ q17: val })}
                      placeholder="Enter your alternative therapies or treatments (ie, Yoga, Meditation, etc...)"
                    />
                  </Form.Group>
                  {this.state.medications.length !== 0 && (
                    <p className={Styles.questionsFont} style={questionStyle}>
                      {questionSet[15]}
                    </p>
                  )}
                  <Form.Group
                    controlId="triggers"
                    style={{ margin: 10, marginLeft: 20 }}
                  >
                    {this.state.medications.map((type) => {
                      return (
                        <>
                          <label class={Styles.checkContainer}>
                            {type.name}
                            <input
                              type="checkbox"
                              checked={type.isChecked}
                              onClick={() => {
                                console.log('clocekd')
                                this.handleMultipleOptionsMedication(type.name)
                                console.log(this.state.medications)
                              }}
                            />
                            <span class={Styles.checkmark}></span>
                          </label>
                          {type.isChecked && (
                            <div style={{ width: '30%' }}>
                              <FancyField
                                value={type.sideEffects}
                                classes={Styles.textInput2}
                                label="Side Effects"
                                required={false}
                                name="side_effects"
                                onChange={(val) =>
                                  this.setState((prevState) => ({
                                    medications: prevState.medications.map(
                                      (med) =>
                                        med.id === type.id
                                          ? { ...med, sideEffects: val }
                                          : med,
                                    ),
                                  }))
                                }
                                placeholder="Enter your side effects"
                              />
                            </div>
                          )}
                        </>
                      )
                    })}
                  </Form.Group>
                  <p className={Styles.questionsFont} style={questionStyle}>
                    Create your account to view your results now
                  </p>
                  <FancyField
                    value={this.state.name}
                    classes={Styles.textInput2}
                    label="Name"
                    required={false}
                    name="name"
                    onChange={(val) =>
                      this.setState({ name: val, error: false })
                    }
                    placeholder="Enter your Name"
                  />
                  <FancyField
                    value={this.state.email}
                    classes={Styles.textInput2}
                    label="Email"
                    required={false}
                    name="email"
                    onChange={(val) =>
                      this.setState({ email: val, error: false })
                    }
                    placeholder="Enter your Email"
                  />
                  <FancyField
                    value={this.state.phNo}
                    classes={Styles.textInput2}
                    label="Phone Number"
                    type="number"
                    required={false}
                    name="phno"
                    onChange={(val) => {
                      if (val.length <= 10) {
                        this.setState({ phNo: val, error: false })
                      }
                    }}
                    placeholder="Enter your Phone Number"
                  />
                </div>

                <p
                  style={{
                    marginTop: 20,
                    fontSize: 12,
                    color: 'red',
                    visibility: this.state.error ? 'visible' : 'hidden',
                  }}
                >
                  {this.state.errorString}
                </p>

                <Button
                  onClick={this.handleSubmit.bind(this)}
                  className={GlobalStyles.button}
                  disabled={this.state.loading}
                  style={{ marginTop: 40, width: 200 }}
                  variant="primary"
                >
                  {this.state.loading ? 'Loading....' : 'Submit'}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
