import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
import AlisonProfile from '../../assets/images/AlisonProfile.png'
import AllieProfile from '../../assets/images/AllieProfile.png'
import JeniferProfile1 from '../../assets/images/JeniferProfile1.png'
import JillHershmodified from '../../assets/images/JillHershmodified.png'
import MeganProfile from '../../assets/images/MeganProfile.png'
import ShannonProfile from '../../assets/images/ShannonProfile.png'
import SunjyaProfile from '../../assets/images/SunjyaProfile.png'
import TraceyProfile from '../../assets/images/TraceyProfile.png'
import Styles from './styles/AilaWebCareteam.module.scss'
import metadata from '../../metadata'
import Config from '../../config'
import DocPlaceHolder from '../../assets/images/docPlaceholder.png'
import { ToastContainer, toast } from 'react-toastify'

import {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
} from '../../redux/actions'
import { connect } from 'react-redux'
import OnboardingFooter from '../../components/OnboardingFooter'
const axios = require('axios')

class AilaWebCareteam extends Component {
  constructor() {
    super()
    this.state = {
      patient_id: this.props?.location?.state?.patient_id
        ? this.props?.location?.state?.patient_id
        : '',
      // 'yS1B3DTzUXPmPbQMrks3CorgCcc2',

      careteamType: this.props?.location?.state?.stateResidence
        ? this.props?.location?.state?.stateResidence
        : 'CO',
      completePatientList: [],
      providersList: [],
      loading: false,
    }
    this.renderMainContent = this.renderMainContent.bind(this)
    this.renderCareteamMembers1 = this.renderCareteamMembers1.bind(this)
    this.renderCareteamMembers2 = this.renderCareteamMembers2.bind(this)
    this.onSubmitPressed = this.onSubmitPressed.bind(this)
    this.getAllProviders = this.getAllProviders.bind(this)
  }

  componentDidMount() {
    if (!this.props.patient) {
      this.props.history.push('/signup/patient')
      return
    }
    this.setState({ loading: true })
    // this.getAllProviders()
    this.getPatientCareTeam()
  }

  async getPatientCareTeam() {
    const { token, patient } = this.props
    // get the care team members
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    const doctorList = []

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        // Authorization: `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNPSzJEVHZISEllVWlUcnRwVkFZTW8ya2Y0bTEiLCJpYXQiOjE2ODI2ODg1NTZ9.qEVpPk2UMGpS82NDlBL4RWETmv_7jhhwWpP7Ns1Zn28`,

        x_firebase_id: patient?.uid,
        patient_state:  this.props?.location?.state?.stateResidence
        // x_firebase_id: 'yS1B3DTzUXPmPbQMrks3CorgCcc2',
        // patient_state: 'NY',
      },
      url,
    })
      .then(async ({ data }) => {
        if (data) {
          console.log(data)
          data && this.setState({ providersList: data, loading: false })
        }
      })
      .catch((err) => {
        console.log('error when getting the care team for patient', err)
      })
  }

  async getAllProviders() {
    const { token, patient } = this.props

    // get all providers
    const url = `${Config.BACKEND_URL}providers`

    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: patient?.uid,
        getallproviders: true,
      },
      url,
    })
      .then(async ({ data }) => {
        console.log(data)
        let providersList = []
        data &&
          // data.forEach((item) => {
          //   providersList.push({
          //     label: item?.first_name + ' ' + item?.last_name,
          //     value: item.email,
          //     ...item,
          //   })
          // })

          this.setState({ providersList: data, loading: false })

        // let docObj = {}
        // if (data) {
        //   data.forEach((x) => {
        //     docObj[x.firebase_id] = x
        //   })
        //   this.setState({
        //     providerMap: docObj,
        //   })
        // }
      })
      .catch((err) => {
        console.log('error when getting all providers', err)
        // toast.error('Something went wrong')
        this.setState({ loading: false })
      })
  }

  renderCareteamMembers1 = () => {
    return (
      <div className={Styles.parentDiv}>
        {this.state.providersList &&
          this.state.providersList?.map((provider, index) => {
            if (provider?.user_type !== 'Administrator')
              // return (
              //   <div
              //     className={Styles.childDiv}
              //     style={{ cursor: 'pointer' }}
              //     onClick={() => {
              //       console.log(provider)
              //       this.props.history.push('/wix/aila/appointment-scheduler', {
              //         patient_id: this.props?.patient?.uid,
              //         email_id: this.props?.patient?.email,
              //         provider_id: provider?.firebase_id,
              //         stateResidence:
              //           this.props?.location?.state?.stateResidence,
              //         flowType: this.props?.location?.state?.flowType,
              //       })
              //     }}
              //   >
              //     <img
              //       alt="Aila Health"
              //       src={
              //         provider?.image_url ? provider?.image_url : DocPlaceHolder
              //       }
              //       // style={{
              //       //   width: '200px',
              //       //   height: '200px',
              //       //   display: 'block',
              //       //   margin: 'auto auto',
              //       // }}
              //       style={{
              //         width: '200px',
              //         height: '200px',
              //         // width: '150px',
              //         // height: '150px',
              //         display: 'block',
              //         margin: 'auto auto',
              //         borderRadius: '50%',
              //       }}
              //     />
              //     <p
              //       style={{
              //         fontSize: '20px',
              //         margin: 'auto',
              //         color: values.primaryThemeDark,
              //         textAlign: 'center',
              //         fontWeight: 'bold',
              //         fontFamily: 'Avenir',
              //         wordWrap: 'break-word',
              //         marginTop: '2vh',
              //         textDecorationLine: 'underline',
              //       }}
              //     >
              //       {provider?.first_name + ' ' + provider?.last_name}
              //     </p>
              //     {/* <p
              //     style={{
              //       fontSize: '20px',
              //       margin: 'auto',
              //       color: values.primaryThemeDark,
              //       textAlign: 'center',
              //       fontWeight: 'bold',
              //       fontFamily: 'Avenir',
              //       wordWrap: 'break-word',
              //       fontStyle: 'italic',
              //     }}
              //   >
              //     {provider?.speciality}
              //   </p> */}
              //     <p
              //       style={{
              //         fontSize: '20px',
              //         margin: 'auto',
              //         color: values.primaryTheme,
              //         textAlign: 'center',
              //         fontWeight: 'bold',
              //         fontFamily: 'Avenir',
              //         wordWrap: 'break-word',
              //         // fontStyle: 'italic',
              //       }}
              //     >
              //       {provider.user_type}
              //     </p>
              //     <p
              //       style={{
              //         fontSize: '20px',
              //         margin: 'auto',
              //         color: values.primaryTheme,
              //         textAlign: 'center',
              //         fontWeight: 'bold',
              //         fontFamily: 'Avenir',
              //         wordWrap: 'break-word',
              //         fontStyle: 'italic',
              //       }}
              //     >
              //       {provider.speciality}
              //     </p>
              //   </div>
              // )
              return (
                <div
                  key={index}
                  // className={Styles.contentRow}
                  className={Styles.contentBackground}
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    console.log(provider)
                    this.props.history.push('/wix/aila/appointment-scheduler', {
                      patient_id: this.props?.patient?.uid,
                      email_id: this.props?.patient?.email,
                      provider_id: provider?.firebase_id,
                      stateResidence:
                        this.props?.location?.state?.stateResidence,
                      flowType: this.props?.location?.state?.flowType,
                    })
                  }}
                >
                  <div
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      overflowWrap: 'anywhere',
                    }}
                  >
                    <img
                      src={
                        provider?.image_url
                          ? provider?.image_url
                          : DocPlaceHolder
                      }
                      height={80}
                      width={80}
                      style={{ borderRadius: '50%', margin: 10 }}
                    />
                    <div
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        margin: 10,
                      }}
                    >
                      <h4 style={{ color: values.primaryThemeDark }}>
                        {provider?.first_name + ' ' + provider?.last_name}
                      </h4>
                      <h5 style={{ color: values.primaryTheme }}>
                        {provider?.speciality}
                      </h5>
                    </div>
                  </div>
                </div>
              )
          })}
      </div>
    )
    // return (
    //   <div className={Styles.parentDiv}>
    //     <div className={Styles.childDiv}>
    //       <img
    //         alt="Aila Health"
    //         src={ShannonProfile}
    //         style={{
    //           width: '200px',
    //           height: '200px',
    //           display: 'block',
    //           margin: 'auto auto',
    //         }}
    //       />
    //       <p
    //         style={{
    //           fontSize: '20px',
    //           margin: 'auto',
    //           color: values.primaryThemeDark,
    //           textAlign: 'center',
    //           fontWeight: 'bold',
    //           fontFamily: 'Avenir',
    //           wordWrap: 'break-word',
    //           marginTop: '2vh',
    //           textDecorationLine: 'underline',
    //         }}
    //       >
    //         Shannon Sims, PhD
    //       </p>
    //       <p
    //         style={{
    //           fontSize: '20px',
    //           margin: 'auto',
    //           color: values.primaryThemeDark,
    //           textAlign: 'center',
    //           fontWeight: 'bold',
    //           fontFamily: 'Avenir',
    //           wordWrap: 'break-word',
    //           fontStyle: 'italic',
    //         }}
    //       >
    //         Integrative Health Coach
    //       </p>
    //     </div>

    //     <div className={Styles.childDiv}>
    //       <img
    //         alt="Aila Health"
    //         src={AlisonProfile}
    //         style={{
    //           width: '200px',
    //           height: '200px',
    //           display: 'block',
    //           margin: 'auto auto',
    //         }}
    //       />
    //       <p
    //         style={{
    //           fontSize: '20px',
    //           margin: 'auto',
    //           color: values.primaryThemeDark,
    //           textAlign: 'center',
    //           fontWeight: 'bold',
    //           fontFamily: 'Avenir',
    //           wordWrap: 'break-word',
    //           marginTop: '2vh',
    //           textDecorationLine: 'underline',
    //         }}
    //       >
    //         Dr. Alison Shmerling
    //       </p>
    //       <p
    //         style={{
    //           fontSize: '20px',
    //           margin: 'auto',
    //           color: values.primaryThemeDark,
    //           textAlign: 'center',
    //           fontWeight: 'bold',
    //           fontFamily: 'Avenir',
    //           wordWrap: 'break-word',
    //           fontStyle: 'italic',
    //         }}
    //       >
    //         Primary Care Physician
    //       </p>
    //     </div>
    //     <div className={Styles.childDiv}>
    //       <img
    //         alt="Aila Health"
    //         src={JeniferProfile1}
    //         style={{
    //           width: '200px',
    //           height: '200px',
    //           display: 'block',
    //           margin: 'auto auto',
    //         }}
    //       />
    //       <p
    //         style={{
    //           fontSize: '20px',
    //           margin: 'auto',
    //           color: values.primaryThemeDark,
    //           textAlign: 'center',
    //           fontWeight: 'bold',
    //           fontFamily: 'Avenir',
    //           wordWrap: 'break-word',
    //           marginTop: '2vh',
    //           textDecorationLine: 'underline',
    //         }}
    //       >
    //         Jenifer Tharan RDN
    //       </p>
    //       <p
    //         style={{
    //           fontSize: '20px',
    //           margin: 'auto',
    //           color: values.primaryThemeDark,
    //           textAlign: 'center',
    //           fontWeight: 'bold',
    //           fontFamily: 'Avenir',
    //           wordWrap: 'break-word',
    //           fontStyle: 'italic',
    //         }}
    //       >
    //         Chronic Pain and Anti-Inflammatory Nutrition
    //       </p>
    //     </div>
    //     {/* <div className={Styles.childDiv}>
    //       <img
    //         alt="Aila Health"
    //         src={JillHershmodified}
    //         style={{
    //           width: '200px',
    //           height: '200px',
    //           display: 'block',
    //           margin: 'auto auto',
    //         }}
    //       />
    //       <p
    //         style={{
    //           fontSize: '20px',
    //           margin: 'auto',
    //           color: values.primaryThemeDark,
    //           textAlign: 'center',
    //           fontWeight: 'bold',
    //           fontFamily: 'Avenir',
    //           wordWrap: 'break-word',
    //           marginTop: '2vh',
    //           textDecorationLine: 'underline',
    //         }}
    //       >
    //         Jill Hersh, PsyD
    //       </p>
    //       <p
    //         style={{
    //           fontSize: '20px',
    //           margin: 'auto',
    //           color: values.primaryThemeDark,
    //           textAlign: 'center',
    //           fontWeight: 'bold',
    //           fontFamily: 'Avenir',
    //           wordWrap: 'break-word',
    //           fontStyle: 'italic',
    //         }}
    //       >
    //         Mental Health
    //       </p>
    //     </div> */}
    //   </div>
    // )
  }

  renderCareteamMembers2 = () => {
    return (
      <div className={Styles.parentDiv}>
        <div className={Styles.childDiv}>
          <img
            alt="Aila Health"
            src={SunjyaProfile}
            style={{
              width: '200px',
              height: '200px',
              display: 'block',
              margin: 'auto auto',
            }}
          />
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginTop: '2vh',
              textDecorationLine: 'underline',
            }}
          >
            Sunjya Schweig, MD
          </p>
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              fontStyle: 'italic',
            }}
          >
            Integrative Medicine
          </p>
        </div>

        <div className={Styles.childDiv}>
          <img
            alt="Aila Health"
            src={MeganProfile}
            style={{
              width: '200px',
              height: '200px',
              display: 'block',
              margin: 'auto auto',
            }}
          />
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginTop: '2vh',
              textDecorationLine: 'underline',
            }}
          >
            Megan Anderson APN
          </p>
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              fontStyle: 'italic',
            }}
          >
            APN
          </p>
        </div>
        <div className={Styles.childDiv}>
          <img
            alt="Aila Health"
            src={TraceyProfile}
            style={{
              width: '200px',
              height: '200px',
              display: 'block',
              margin: 'auto auto',
            }}
          />
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginTop: '2vh',
              textDecorationLine: 'underline',
            }}
          >
            Tracey O’Shea, NP
          </p>
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              fontStyle: 'italic',
            }}
          >
            Chronic
          </p>
        </div>
        <div className={Styles.childDiv}>
          <img
            alt="Aila Health"
            src={AllieProfile}
            style={{
              width: '200px',
              height: '200px',
              display: 'block',
              margin: 'auto auto',
            }}
          />
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginTop: '2vh',
              textDecorationLine: 'underline',
            }}
          >
            Allie Nowak, PA-C
          </p>
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              fontStyle: 'italic',
            }}
          >
            M
          </p>
        </div>
        <div className={Styles.childDiv}>
          <img
            alt="Aila Health"
            src={ShannonProfile}
            style={{
              width: '200px',
              height: '200px',
              display: 'block',
              margin: 'auto auto',
            }}
          />
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginTop: '2vh',
              textDecorationLine: 'underline',
            }}
          >
            Shannon Sims, PhD
          </p>
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              fontStyle: 'italic',
            }}
          >
            Integrative Health Coach
          </p>
        </div>
        <div className={Styles.childDiv}>
          <img
            alt="Aila Health"
            src={JeniferProfile1}
            style={{
              width: '200px',
              height: '200px',
              display: 'block',
              margin: 'auto auto',
            }}
          />
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              marginTop: '2vh',
              textDecorationLine: 'underline',
            }}
          >
            Jenifer Tharan RD
          </p>
          <p
            style={{
              fontSize: '20px',
              margin: 'auto',
              color: values.primaryThemeDark,
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Avenir',
              wordWrap: 'break-word',
              fontStyle: 'italic',
            }}
          >
            Anti-Inflammatory Nutrition
          </p>
        </div>
      </div>
    )
  }

  onSubmitPressed = () => {
    const ccfmDeeplink = localStorage.getItem('ccfm')
    const dacPilotDeeplink = localStorage.getItem('dac')
    const raPilotDeeplink = localStorage.getItem('rapilot')

    if (dacPilotDeeplink === 'yes') {
      this.props.history.push('/wix/aila/patient-details', {
        stateResidence: this.props?.location?.state?.stateResidence,
        planSelected: metadata.subscriptionPlans.PREMIUM,
        fName: this.props?.location?.state.fName,
        lName: this.props?.location?.state.lName,
      })
    } else if (raPilotDeeplink === 'yes') {
      this.props.history.push('/wix/aila/patient-details', {
        stateResidence: this.props?.location?.state?.stateResidence,
        planSelected: metadata.subscriptionPlans.MEMBERSHIP,
        fName: this.props?.location?.state.fName,
        lName: this.props?.location?.state.lName,
      })
    } else if (ccfmDeeplink === 'yes') {
      this.props.history.push('/wix/aila/patient-details', {
        stateResidence: this.props?.location?.state?.stateResidence,
        planSelected: metadata.subscriptionPlans.PREMIUM,
        fName: this.props?.location?.state.fName,
        lName: this.props?.location?.state.lName,
      })
    } else {
      this.props.history.push('/wix/aila/program', {
        fName: this.props?.location?.state.fName,
        lName: this.props?.location?.state.lName,
        programType: this.props?.location?.state?.stateResidence,
      })
    }
  }

  renderMainContent = () => {
    return (
      <div
        style={{
          flex: 1,
          alignSelf: 'center',
          marginTop: '-8vh',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            margin: 'auto',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '2vh',
          }}
        >
          {' '}
          Please select a preferred provider with whom you wish to schedule a
          meeting.
          {/* Select a provider you want to meet with, {this.props?.location?.state?.fName || 'Rose'} */}
          {/* Meet your care team, {this.props?.location?.state?.fName} <br /> We
          work together to support you. */}
        </p>
      </div>
    )
  }
  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div>
        {this.renderMainContent()}
        {/* {this.props?.location?.state?.stateResidence === 'CO'
          ? this.renderCareteamMembers1()
          : this.renderCareteamMembers2()} */}

        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: 200,
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          this.renderCareteamMembers1()
        )}

        <OnboardingFooter />

        {/* <div
          style={{
            flex: 1,
            alignSelf: 'center',
            marginTop: '4vh',
          }}
        >
          <Button
            onClick={() => this.onSubmitPressed()}
            // onClick={(e) => {
            //   // e.preventDefault()

            //   if (dacPilotDeeplink === 'yes') {
            //     this.props.history.push('/wix/aila/patient-details', {
            //       patient_id: this.props?.location?.state?.patient_id,
            //       email_id: this.props?.location?.state?.email_id,
            //       stateResidence: this.props?.location?.state?.stateResidence,
            //       planSelected: metadata.subscriptionPlans.PREMIUM,
            //       fName: this.props?.location?.state.fName,
            //       lName: this.props?.location?.state.lName,
            //     })
            //   }
            //   if (ccfmDeeplink === 'yes') {
            //     this.props.history.push('/wix/aila/patient-details', {
            //       patient_id: this.props?.location?.state?.patient_id,
            //       email_id: this.props?.location?.state?.email_id,
            //       stateResidence: this.props?.location?.state?.stateResidence,
            //       planSelected: metadata.subscriptionPlans.PREMIUM,
            //       fName: this.props?.location?.state.fName,
            //       lName: this.props?.location?.state.lName,
            //     })
            //   }
            //   if (raPilotDeeplink === 'yes') {
            //     this.props.history.push('/wix/aila/patient-details', {
            //       patient_id: this.props?.location?.state?.patient_id,
            //       email_id: this.props?.location?.state?.email_id,
            //       stateResidence: this.props?.location?.state?.stateResidence,
            //       planSelected: metadata.subscriptionPlans.MEMBERSHIP,
            //       fName: this.props?.location?.state.fName,
            //       lName: this.props?.location?.state.lName,
            //     })
            //   }

            //   this.props.history.push('/wix/aila/program', {
            //     patient_id: this.props?.location?.state?.patient_id,
            //     email_id: this.props?.location?.state?.email_id,
            //     fName: this.props?.location?.state.fName,
            //     lName: this.props?.location?.state.lName,
            //     programType: this.props?.location?.state?.stateResidence,
            //   })
            // }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '2vh',
              alignSelf: 'center',
            }}
            variant="primary"
          >
            {'Continue'}
          </Button> 
        </div>*/}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    patient: state.patientReducer.patient,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
  }
}

const mapDispatchToProps = {
  addFirebaseUser,
  addFirebaseAuthUser,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(AilaWebCareteam)
