import React, { useEffect, useState, useMemo, useRef } from 'react'

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
} from 'react-table'
import Styles from './styles/styles.module.scss'
import Button from 'react-bootstrap/Button'
import GlobalStyles from '../pages/styles/global.module.scss'
import Select from 'react-select'

const Table = ({ columns, data }) => {
  const selectInputRef = useRef()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize, globalFilter, filters },
    setAllFilters,
    setFilter,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // initialState: { pageIndex: 0 },
      initialState: { hiddenColumns: ['Patient Type'] },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  // const firstPageRows = rows.slice(0, 20)

  return (
    <>
      {/* <pre>
          <code>
            {JSON.stringify(
              {
                pageIndex,
                pageSize,
                pageCount,
                canNextPage,
                canPreviousPage,
              },
              null,
              2,
            )}
          </code>
        </pre> */}

      <div className={Styles.tableRow}>
        <p
          className={Styles.rowLabel}
          style={{ width: 'auto', marginRight: '2%' }}
        >
          Search
        </p>
        <div style={{ width: '30%' }}>
          <input
            placeholder="Search"
            className={Styles.textInput}
            style={{ width: '100%' }}
            type="text"
            value={globalFilter}
            onChange={(e) => {
              setGlobalFilter(e.target.value)
            }}
          />
        </div>

        <Button
          onClick={() => {
            setAllFilters([])
            // selectInputRef.current.select.clearValue()
          }}
          className={GlobalStyles.button}
          style={{
            // width: 100,
            marginLeft: 'auto',
          }}
          variant="primary"
        >
          Clear Filters
        </Button>
      </div>
      {/* <span>
        <div className={Styles.row}>
          <p className={Styles.rowLabel} style={{ width: 'auto' }}>
            Patient Type
          </p>
          <div style={{ width: '30%' }}>
            <Select
              ref={selectInputRef}
              inputId="patient-type"
              placeholder="Select"
              options={[
                { label: 'New Patient', value: 'New Patient' },
                { label: 'Established', value: 'Established' },
                // { label: 'Not Scheduled', value: 'not_scheduled' },
                {
                  label: 'Others (Medicare/Medicaid)',
                  value: 'Others (Medicare/Medicaid)',
                },
              ]}
              // value={}
              isSearchable
              autoBlur
              onChange={(val) => {
                val && setFilter('Patient Type', val?.label)
                // setPatientType(val)
                // console.log(filters)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>
      </span> */}

      <div style={{ overflow: 'scroll', width: '73vw', margin: 'auto' }}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <>
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          style: {
                            width: column.width,
                            minWidth: column.width,
                            ...column.style,
                            // display: column.id === 'Patient Type' ? 'none' : '',
                          },
                        }),
                      )}
                      key={index}
                    >
                      {column.render('Header')}
                      <span style={{ marginLeft: '2em' }}>
                        {column.sort && column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : column.sort
                          ? ' ↑'
                          : ''}
                      </span>

                      {/* <span>
                      {' '}
                      {column.canFilter && column.Filter
                        ? column.render('Filter')
                        : null}
                    </span>
                    {/* Add a sort direction indicator */}
                      {/*<span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                    */}
                    </th>
                  ))}
                </tr>
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      {...column.getHeaderProps({
                        style: {
                          width: column.width,
                          minWidth: column.width,
                          ...column.style,
                          // display: column.id === 'Patient Type' ? 'none' : '',
                        },
                      })}
                      key={index}
                    >
                      {/* {column.render('Header')} */}
                      <span>
                        {' '}
                        {column.canFilter && column.Filter
                          ? column.render('Filter')
                          : null}
                      </span>
                      {/* Add a sort direction indicator */}
                      {/* <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : '↑'}
                      </span> */}
                    </th>
                  ))}
                </tr>
              </>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            width: cell.column.width,
                            minWidth: cell.column.width,
                            ...cell.column.style,
                            // display:
                            //   cell.column.id === 'Patient Type' ? 'none' : '',
                          },
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <br />
      {/* <div>Showing the first 20 results of {rows.length} rows</div> */}
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination" style={{ flexDirection: 'column' }}>
        <div className="pagination" style={{ flexDirection: 'row' }}>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>{' '}
        </div>

        <br />
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        {/* <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '} */}
        {/* <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select> */}
        <div style={{ width: '20%' }}>
          <Select
            inputId="pageSize"
            placeholder="Page Size"
            options={[
              { label: 10, value: 10 },
              { label: 20, value: 20 },
              { label: 30, value: 30 },
              { label: 40, value: 40 },
              { label: 50, value: 50 },
            ]}
            value={pageSize && { label: pageSize, value: pageSize }}
            // isSearchable
            autoBlur
            onChange={(val) => {
              setPageSize(Number(val.value))
              // setMemberType(val)
              // filterByPatientType(val)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
      </div>
    </>
  )
}

export default Table
