import React from 'react'
import moment from 'moment'
import Select from 'react-select'

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '33.33%',
  }),
}

//DATE PICKER
export default class DatePicker extends React.Component {
  constructor(props) {
    super(props)
    const { dayLabel, monthLabel, yearLabel, defaultDate } = props

    this.state = {
      day: null,
      month: null,
      year: null,
      selectDay: defaultDate
        ? {
            label: moment(defaultDate).date(),
            value: moment(defaultDate).date(),
          }
        : dayLabel,
      selectMonth: defaultDate
        ? {
            label: moment().month(moment(defaultDate).month()).format('MMMM'),
            value: moment(defaultDate).month() + 1,
          }
        : monthLabel,
      selectYear: defaultDate
        ? {
            label: moment(defaultDate).year(),
            value: moment(defaultDate).year(),
          }
        : yearLabel,
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    return (
      this.state.selectDay !== nextState.selectDay ||
      this.state.selectMonth !== nextState.selectMonth ||
      this.state.selectYear !== nextState.selectYear
    )
  }

  componentWillMount() {
    let day = [],
      month = [],
      year = []

    const pad = (n) => {
      return n < 10 ? '0' + n : n
    }

    for (let i = 1; i <= 31; i++) {
      day.push({
        label: this.props.padDay ? pad(i) : i,
        value: this.props.padDay ? pad(i) : i,
      })
    }

    let monthIndex = 1
    for (const monthName of moment.localeData().months()) {
      month.push({
        label: monthName,
        value: monthIndex,
      })
      monthIndex++
    }

    for (let i = this.props.maxYear; i >= this.props.minYear; i--) {
      year.push({ label: i, value: i })
    }

    this.setState({
      day: day,
      month: month,
      year: year,
    })
  }

  changeDate(e, type) {
    this.setState({
      [type]: e,
    })
    this.checkDate(e, type)
  }

  getDate(date) {
    if (moment(date).isValid()) {
      return moment.utc(date).format()
    } else {
      return undefined
    }
  }

  finalDateResult() {
    if (
      this.isSelectedAllDropdowns(
        this.state.selectDay.value,
        this.state.selectMonth.value,
        this.state.selectYear.value,
      )
    ) {
      const dateObject = {
        year: this.state.selectYear.value,
        month: this.state.selectMonth.value - 1,
        day: this.state.selectDay.value,
      }
      this.props.dateChange(this.getDate(dateObject))
    } else {
      this.props.dateChange(undefined)
    }
  }

  checkDate(value, type) {
    let { selectDay, selectMonth, selectYear } = this.state

    if (type === 'selectDay') {
      this.setState({ selectDay: value }, () => this.finalDateResult())
      selectDay = value
    }
    if (type === 'selectMonth') {
      this.setState({ selectMonth: value }, () => this.finalDateResult())
      selectMonth = value
    }
    if (type === 'selectYear') {
      this.setState({ selectYear: value }, () => this.finalDateResult())
      selectYear = value
    }
  }

  isSelectedAllDropdowns(selectDay, selectMonth, selectYear) {
    if (selectDay === '' || selectMonth === '' || selectYear === '') {
      return false
    }
    return (
      selectDay !== this.props.dayLabel &&
      selectMonth !== this.props.monthLabel &&
      selectYear !== this.props.yearLabel
    )
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Select
          styles={customStyles}
          placeholder="Month"
          inputId="monthInput"
          options={this.state.month}
          value={this.state.selectMonth}
          isSearchable
          autoBlur
          onChange={(val) => {
            console.log(val)
            this.changeDate(val, 'selectMonth')
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#20A89233',
              primary: '#20A892',
            },
          })}
        />

        <Select
          styles={customStyles}
          placeholder="Day"
          inputId="dayInput"
          options={this.state.day}
          value={this.state.selectDay}
          isSearchable
          autoBlur
          onChange={(val) => this.changeDate(val, 'selectDay')}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#20A89233',
              primary: '#20A892',
            },
          })}
        />

        <Select
          styles={customStyles}
          placeholder="Year"
          inputId="yearInput"
          options={this.state.year}
          value={this.state.selectYear}
          isSearchable
          autoBlur
          onChange={(val) => this.changeDate(val, 'selectYear')}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#20A89233',
              primary: '#20A892',
            },
          })}
        />
      </div>
    )
  }
}

DatePicker.defaultProps = {
  dayLabel: 'Day',
  minYear: 1801,
  maxYear: moment().year(),
  monthLabel: 'Month',
  padDay: false,
  padMonth: false,
  selectDay: '',
  selectMonth: '',
  selectYear: '',
  yearLabel: 'Year',
  useMonthNames: false,
}
