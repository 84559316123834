// Current Wellness Dashboard
import React, {Component, useState} from 'react'
import Select from 'react-select'
import cookie from 'react-cookies'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat, faWeight, faHeadSideVirus, faBed, faRunning, faFlask, faFileAlt, faBrain } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/Button";
import Header from '../../components/Header'
import Constants from '../../values.js'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/Dashboard.module.scss'
import {ReferenceLine, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import Helpers from "../../helpers/Global";
import {addFilledData, stopTimer, updateSideNavBar} from "../../redux/actions";
import {connect} from "react-redux";
import ReactTooltip from "react-tooltip";
import Config from "../../config";
import {ToastContainer, toast} from "react-toastify";
import LungsIcon from "../../assets/images/lungs.png";
import styles from "../Admin/styles/admin.module.scss";

const axios = require('axios');

const TRACKING_OPTIONS = [
  {
    logo: faHeartbeat,
    label: "Cardio",
    options: [
      {label: 'Heart Rate', value: "heartRate", unit: "Beats Per Minute (bpm)", unitShort: "bpm", up: false, desc: 'A normal heart rate for an adult is between 60-100 beats per minute a lower heart rate at rest implies more efficient heart function and better cardiovascular fitness.'},
      {label: 'Heart Rate Variability', value: "heartRateVariability", unit: "Milliseconds (ms)", unitShort: "ms", up: true, desc: 'Heart Rate Variability (HRV) is a measure of the variation in time between each heartbeat. This variation is controlled by the autonomic nervous system (ANS) and can be a marker for resilience and behavioral flexibility.'},
      {label: 'Temperature', value: "temperature", up: false, unit: 'Fahrenheit'},
    ]
  },
  {
    logo: LungsIcon,
    label: "Respiratory",
    options: [
      {label: 'Respiratory Rate', value: "respirationRate", unit: "Breaths Per Minute", unitShort: "bpm", up: false, desc: 'A normal heart rate for an adult is between 60-100 beats per minute a lower heart rate at rest implies more efficient heart function and better cardiovascular fitness.'},
      {label: 'Oxygen Saturation', value: "oxygenSaturation", unit: "%", unitShort: "ms", up: true, desc: 'Heart Rate Variability (HRV) is a measure of the variation in time between each heartbeat. This variation is controlled by the autonomic nervous system (ANS) and can be a marker for resilience and behavioral flexibility.'}
    ]
  },
  // {
  //   logo: faWeight,
  //   label: "Weight",
  //   options: [
  //     {label: 'Weight', value: "weight", unit: 'Pounds (lbs)', unitShort: 'lbs', up: false, desc: 'Maintaining a healthy weight lowers the risk of many chronic conditions, such as heart disease, stroke, diabetes, hypertension, and cancer.'},
  //     {label: 'Body Fat Percentage', value: "bodyFat", unit: '%', unitShort: '%', up: false, desc: 'Body fat percentage is the percentage of your body that consists of fat. Healthy body fat percentage ranges fall  between 10 – 20% for males and 18 – 28% for females.'},
  //     {label: 'Fat Body Mass', value: "fatBodyMass", unit: 'Pounds (lbs)', unitShot: 'lbs', up: false, desc: 'Fat body mass includes essential body fat and storage body fat.'},
  //     {label: 'Lean Body Mass', value: "leanBodyMass", unit: 'Pounds (lbs)', unitShot: 'lbs', up: true, desc: 'Lean Body Mass is the amount of lean tissue (muscles, bones, body water, organs, etc) in the body.'},
  //   ]
  // },
  {
    label: "Sleep",
    logo: faBed,
    options: [
      {label: 'Total Sleep', value: "sleep", unit: 'Hours (h)', unitShort: "h", up: true, desc: 'Total amount of time asleep. The recommended range is 7-9 hours per night for adults.'},
    ]
  },
  {
    logo: faRunning,
    label: "Activity",
    options: [
      {label: 'Daily Step Count', value: "steps", unit: "# steps / day", up: true, desc: 'A step count showcases the amount of steps an individual takes in a day.Recommended average is 10,000 steps (About 5 Miles)/day.'},
      {label: 'Activity Time', value: "activityTime", up: true, unit: 'Minutes per day'},
      {label: 'Resting Calories', value: "restingCalories", up: true, unit: 'Kilocalorie (kcal)'},
      {label: 'Active Calories', value: "activeCalories", up: true, unit: 'Kilocalorie (kcal)'},
    ]
  },
  // {
  //   logo: faBrain,
  //   label: "Mental Health",
  //   options: [
  //     {label: 'PHQ-9', value: "phq9_score", unit: "", up: true, desc: ''},
  //   ]
  // }

]

class AilaDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trackingOptions: [],
      timeline: {label: 'Complete History', value: 'complete'},
      graphs: null,
      loadingFilledData: true,
      filledData: null,
      filledPhq: null,
      selectedCohort: null,
      showIndividualEntries: {},
      checked: false,
      movingAverages: {},
      firstName: '',
      lastName: '',
      email: '',
      accessType: null,
      loading: false,
      participantMap: null,
      participantMapId: null,
      participantsList: [],
      selectedParticipant: null,
      isMember: true,
      cohortOptions: null,
      hideParticipantDropdown: false,
      clientFName: '',
      clientLName: '',
      clientEmail: '',
    }
    this.getFilledDataFromBackend = this.getFilledDataFromBackend.bind(this)
    this.getFilledVitalProgFromBackend = this.getFilledVitalProgFromBackend.bind(this)
    this.getFilledPhqFromBackend = this.getFilledPhqFromBackend.bind(this)
    this.getChartData = this.getChartData.bind(this)
    this.onTimelineChanged = this.onTimelineChanged.bind(this)
    this.getMovingAverageChartDate = this.getMovingAverageChartDate.bind(this)
    this.prepareCohorts = this.prepareCohorts.bind(this)
    this.prepareDefaultOptions = this.prepareDefaultOptions.bind(this)
    this.prepareParticipantsList = this.prepareParticipantsList.bind(this)
  }

  componentDidMount() {
    if(!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }

    this.props.updateSideNavBar(!this.props.updateRender)
    this.prepareCohorts()


    this.prepareDefaultOptions()

    // let {userType} = this.props
    // if(userType && userType === 'ccfm-patient') {
    //   this.props.addFilledData(null)
    //   this.setState({loadingFilledData: true})
    //   this.getFilledDataFromBackend("complete", 1)
    // } else {
    //   this.setState({loadingFilledData: true})
    //   this.getFilledDataFromBackend("complete")
    // }

    let {token, adminId} = this.props
    this.props.stopTimer(adminId, token)

  }

  static getDerivedStateFromProps(props, state) {
    let toReturn = {}
    if(props.filledData) {
      toReturn = {
        loadingFilledData: false,
        filledData: props.filledData,
      }

      if(props.filledData.participant && props.filledData.participant.length > 0) {
        //participant data is requested. so prepare that to overlay
        let data = props.filledData.participant
        let participantMap = {}
        data.forEach(each => {
          participantMap[each.created_at] = each
          toReturn.participantMapId = each.firebase_id
        })

        toReturn.participantMap = participantMap
      }


      if(props.userType && !(props.userType === 'ccfm-team' || props.userType === 'ccfm-admin')) {
        toReturn.hideParticipantDropdown = true
      }

      return toReturn
    }

    return null
  }

  prepareParticipantsList(selectedCohort) {
    let list = []
    let {patientList} = this.props
    if(!patientList)
      return

    Object.keys(patientList).forEach(color => {
      let patients = patientList[color]
      patients.forEach(patient => {
        let patientCohorts = patient.cohorts
        if((selectedCohort === 'All'))
          list.push({
            label: patient.firstName + ' ' + patient.lastName,
            value: patient.uid
          })
        else if(selectedCohort && patientCohorts.includes(selectedCohort))
          list.push({
            label: patient.firstName + ' ' + patient.lastName,
            value: patient.uid
          })
      })
    })

    this.setState({participantsList: list})
  }

  prepareDefaultOptions() {
    let trackingOptions = []
    TRACKING_OPTIONS.forEach(group => {
      let options = group.options
      trackingOptions = trackingOptions.concat(options)
    })

    this.setState({trackingOptions})

  }

  prepareCohorts() {
    const { token, adminId } = this.props
    let {timeline} = this.state
    // getting cohort options
    const url = `${Config.BACKEND_URL}cohorts`
    axios({
      method: 'get',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        Authorization: `JWT ${token}`,
        x_firebase_id: adminId,
      },
      url,
    })
      .then(({ data }) => {
        let tempCohortList = [{
          label: 'All', value: 'All'
        }]
        data && data.forEach(cohort => {
          tempCohortList.push({
            label: cohort.name, value: cohort.name
          })
        })
        this.setState({cohortOptions: tempCohortList, selectedCohort: tempCohortList[0]})
        this.prepareParticipantsList(tempCohortList[0] && tempCohortList[0].label)
        this.getFilledDataFromBackend(timeline.value, tempCohortList[0] && tempCohortList[0].label)
      })
      .catch((err) => {
        console.log('error when getting the cohort options', err)
        toast.error('Something went wrong')
      })
  }

  // prepareCohorts() {
  //   let {mongoUser} = this.props
  //   let {timeline} = this.state
  //   if(mongoUser && mongoUser.cohorts) {
  //     let cohortOptions = []
  //     mongoUser.cohorts.forEach(each => {
  //       cohortOptions.push({label: each, value: each})
  //     })

  //     this.setState({cohortOptions, selectedCohort: cohortOptions[0]})
  //     this.prepareParticipantsList(cohortOptions[0] && cohortOptions[0].label)
  //     this.getFilledDataFromBackend(timeline.value, cohortOptions[0] && cohortOptions[0].label)
  //   }
  // }

  onTrackingOptionSelected(val) {
    this.setState({
      trackingOptions: val
    })

    // if(this.state.checked) {
    //   cookie.save('trackingOptions', val)
    // }

  }


  onSelectAllClicked(label, bioMarker) {
    let options = []

    let completeOptions = TRACKING_OPTIONS

    completeOptions.forEach(option => {
      if(option.label === label)
        options = option.options
    })

    let temp
    temp = this.state.trackingOptions || []

    let currentOptions = [...temp]
    let currentLabelsObject = {}
    currentOptions.forEach(option => {
      currentLabelsObject[option.label] = true
    })

    options.forEach(option => {
      if(!currentLabelsObject[option.label])
        currentOptions.push(option)
    })


    this.setState({
      trackingOptions: currentOptions,
    })

  }

  getChartData(option, data) {
    let {filledData, filledPhq} = this.state
    if(!filledData || !filledData.averages)
      return {
        data: []
      }

    let key = option.value

    if(!key)
      return {
        data: []
      }

    let dataObject = filledData?.averages?.[key]
    if(option.value === 'phq9_score') {
      console.log("Data: ", data)
      dataObject = data.averages[key]
    }
    let toReturn = []
    Object.keys(dataObject).forEach(date => {
      let momentDate = moment(date)
      let avg = dataObject[date].avg
      if(dataObject[date].count > 0) {
        //only count if there is at least one patient data for the day
        if(option.label === "Total Sleep" || option.label === 'Sleep Midpoint' || option.label === 'Deep Sleep')
          avg = Number(avg.toFixed(1))
        else
          avg = Math.round(avg)

        let avgData = {
          date: momentDate.format("MMM-D"),
          value: avg,
          actualDate: date
        }

        toReturn.push(avgData)
      }
    })

    toReturn.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.actualDate) - new Date(b.actualDate);
    });

    return toReturn

  }

  onCohortChanged(val) {
    this.setState({ selectedCohort: val, selectedParticipant: null})
    let {selectedCohort, timeline} = this.state
    if(!selectedCohort || selectedCohort.value !== val.value) {
      this.props.addFilledData(null)
      this.setState({loadingFilledData: true, filledData: null, movingAverages: {}})
      this.prepareParticipantsList(val.value) //only show this cohort patients
      this.getFilledDataFromBackend(timeline.value, val.value)
    }
  }

  onTimelineChanged(val) {
    this.props.addFilledData(null)
    this.setState({loadingFilledData: true, filledData: null, movingAverages: {}})
    let {selectedParticipant,selectedCohort} = this.state
    if(selectedParticipant) {
      this.getFilledDataFromBackend(val.value, selectedCohort.label, selectedParticipant.value)
    } else if(this.props.participantId) {
      this.getFilledDataFromBackend(val.value, selectedCohort.label, this.props.participantId)
    } else {
      this.getFilledDataFromBackend(val.value, selectedCohort.label)
    }

    this.setState({timeline: val})
    // if(this.state.checked) {
    //   cookie.save('timeline', val)
    // }
  }

  getFilledDataFromBackend(timeline,cohort, participant) {
    this.getFilledVitalProgFromBackend(timeline,cohort, participant)
    this.getFilledPhqFromBackend(timeline,cohort, participant)
  }

  getFilledVitalProgFromBackend(timeline,cohort, participant) {
    console.log('getting new data', timeline, cohort, participant)
    let {adminId, token} = this.props
    let self = this
    let filledUrl = Config.BACKEND_URL + 'population/progress?timeline=' + timeline
    //get population staticcs data
    axios({
      method: 'get',
      headers:{x_patient_id: participant, admin_id: adminId, cohort: cohort, Authorization: 'JWT ' + token},
      url: filledUrl,
    }).then(function (response) {
      // handle success
      if(response.status === 200) {
        console.log('ot new data', response.data)
        let data = response.data
        self.props.addFilledData(data)
      }
    })
      .catch(function (error) {
        // handle error
        console.log('error when getting ccfm data ailadashboard', error)
      })
  }

  getFilledPhqFromBackend(timeline,cohort, participant) {
    console.log('getting new phq data', timeline, cohort, participant)
    let {adminId, token} = this.props
    let self = this
    let filledUrl = Config.BACKEND_URL + 'population/phq?timeline=' + timeline
    //get population staticcs data
    axios({
      method: 'get',
      headers:{x_patient_id: participant, admin_id: adminId, cohort: cohort, Authorization: 'JWT ' + token},
      url: filledUrl,
    }).then(function (response) {
      // handle success
      if(response.status === 200) {
        console.log('ot new data', response.data)
        let data = response.data
        this.setState({filledPhq: data})
      }
    })
      .catch(function (error) {
        // handle error
        console.log('error when getting ccfm data ailadashboard', error)
      })
  }


  getRowGraphs(label, data) {
    let index = -1
    switch(label) {
      case 'Cardio':
        index = 0
        break
      case 'Respiratory':
        index = 1
        break
      case 'Sleep':
        index = 2
        break
      case 'Activity':
        index = 3
        break
      case 'Mental Health':
        index = 4
        break
      default:
    }

    let graphs = []
    let trackingOptions = this.state.trackingOptions
    if(trackingOptions) {
      let options = TRACKING_OPTIONS[index].options
      let temp = {}
      options.forEach(option => {
        temp[option.label] = true
      })



      //render graphs
      trackingOptions.forEach(option => {
        console.log('render graphs: ', this.state.filledPhq, option.label, data)
        if(option.value === 'phq9_score' && temp[option.label] && data && data.averages[option?.value]) {
          graphs.push(
            <div key={option.label} className={Styles.graphWrapper}>
              {this.renderChart(option, data)}
            </div>
          )
        }
        if(temp[option.label] && this.state.filledData && this.state.filledData.averages[option?.value]) {
          graphs.push(
            <div key={option.label} className={Styles.graphWrapper}>
              {this.renderChart(option)}
            </div>
          )
        }

      })

    }

    return graphs

  }

  renderCustomAxisTick(x, y, payload, label) {
    return (
      <div>same</div>
    );
  }

  renderCustomReferenceLabel() {
    return (
      <p style={{color: 'red'}}>Average</p>
    )
  }

  renderToolTip(option, change, start, end, color) {
    return (
      <ReactTooltip id={option.label}  textColor='black' backgroundColor='white' effect='solid' className={Styles.hoverChatWrapper}>
        <div style={{padding: '10px 5px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          <h6 style={{color: Constants.primaryTheme, textAlign: 'center'}}>{option.label}</h6>
          <p style={{textAlign: 'center'}}>{option.desc}</p>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: 4}}>
            <p style={{fontWeight: 'bold', width: '50%', textAlign: 'center'}}>Starting Average</p>
            <p style={{textAlign: 'center', width: '50%'}}>{start}{option.unitShort}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <p style={{fontWeight: 'bold', width: '50%', textAlign: 'center'}}>Ending Average</p>
            <p style={{textAlign: 'center', width: '50%'}}>{end}{option.unitShort}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <p style={{fontWeight: 'bold', width: '50%', textAlign: 'center'}}>Goal</p>
            <p style={{textAlign: 'center', width: '50%'}}>{option.up ? 'Increase' : 'Decrease'}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <p style={{fontWeight: 'bold', width: '50%', textAlign: 'center'}}>% change</p>
            {
              change >= 0 ?
                <p style={{color: color, textAlign: 'center', fontWeight: 'bold', width: '50%' }}>{change}% &uarr;</p>
                :
                <p style={{color: color, textAlign: 'center', fontWeight: 'bold', width: '50%'}}>{change}% &darr;</p>
            }
          </div>
        </div>
      </ReactTooltip>
    )
  }

  getMovingAverageChartDate(option, data) {
    let {participantMap} = this.state
    let movingAverages = this.state.movingAverages[option.label]
    let delta
    switch(movingAverages) {
      case 1:
        delta = 1
        break
      case 2:
        delta = 3
        break
      case 3:
        delta = 7
        break
      default :
        delta = 1
    }

    console.log('delta is', delta)

    if(delta === 1) {
      if(participantMap) {
        //if participant data is there, overlay it on the average
        data.forEach(entry => {
          let date = entry.actualDate
          let participantDataForDate = participantMap[date]
          if(participantDataForDate && participantDataForDate[option?.value]) {
            //if the entry exists for that date, include it to the average object
            entry["participantData"] = this.getAverageRounded(option , participantDataForDate[option?.value])
          }
        })
      }

      return data
    }


    let newToReturn = []

    let lastIndex = data.length - 1
    let total = data[lastIndex]?.value
    let participantTotal = 0
    let participantDenom = 0
    let lastDate = data[lastIndex].actualDate
    let participantLastEntryDate
    if(participantMap) {
      participantTotal = participantMap[lastDate] && participantMap[lastDate][option?.value] ? participantMap[lastDate][option?.value] : 0
      participantDenom = participantMap[lastDate] && participantMap[lastDate][option?.value] ? 1 : 0
    }

    let denom = 1
    let avg
    let participantAvg = -1


    for(let i=lastIndex-1; i >= 0; i--) {
      let currentEntry = data[i]
      let date = currentEntry.actualDate
      let dateDiff = moment(data[lastIndex].actualDate).diff(moment(date), "days")

      if(dateDiff < delta) {
        total += currentEntry?.value
        denom += 1
        if(participantMap) {
          let participantDataForCurrentDate = participantMap[date]
          if(participantDataForCurrentDate && participantDataForCurrentDate[option?.value]) {
            //if the current vital is present for the participant on that date, add it to average
            participantTotal += participantDataForCurrentDate[option?.value]
            participantDenom += 1
          }
        }
      } else {
        avg = total/denom
        let toPutDate = moment(data[lastIndex].actualDate)
        if(participantMap) {
          participantAvg = participantDenom > 0 ? participantTotal/participantDenom : -1
          let participantDataForLastEntryDate = participantMap[data[lastIndex].actualDate]
          if(participantDataForLastEntryDate && participantDataForLastEntryDate[option?.value]) {
            //subtract if the last date of the current averages loop had a entry for patient as well
            participantTotal = participantTotal - participantDataForLastEntryDate[option?.value]
            participantDenom -= 1
          }

          let participantDataForCurrentDate = participantMap[date]
          if(participantDataForCurrentDate && participantDataForCurrentDate[option?.value]) {
            //if the current vital is present for the participant on that date, add it to average
            participantTotal +=  participantDataForCurrentDate[option?.value]
            participantDenom += 1
          }


        }


        newToReturn.push({
          date: toPutDate.format("MMM-D"),
          value: this.getAverageRounded(option, avg),
          actualDate: toPutDate.toDate(),
          participantData: this.getAverageRounded(option, participantAvg)
        })
        total = total - data[lastIndex]?.value //remove the last entry and add te current entry for new average
        total = total + currentEntry?.value
        lastIndex = lastIndex - 1
      }
    }

    newToReturn.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.actualDate) - new Date(b.actualDate);
    });


    return newToReturn


  }

  getAverageRounded(option, avg) {
    if(avg === -1)
      return null
    let newAvg
    if(option.label === "Total Sleep" || option.label === 'Sleep Midpoint' || option.label === 'Deep Sleep')
      newAvg = Number(avg.toFixed(1))
    else
      newAvg = Math.round(avg)

    return newAvg
  }

  getParticipantName() {
    if(this.state.selectedParticipant) {
      return this.state.selectedParticipant.label
    } else {
      return 'Participant ' + this.props.participantId
    }
  }

  renderChart(option, data) {
    let chartData = this.getChartData(option, data)
    let start = chartData[0]?.value
    let end = chartData[chartData.length - 1]?.value
    let change = ((end - start)/start) * 100
    let color
    if(change > 0)
      color = option.up ? 'green' : 'red'
    else
      color = option.up ? 'red' : 'green'

    let total = 0
    let count = 0
    chartData.forEach(each => {
      total += each?.value
      count += 1
    })

    let avg = count > 0 ? (total / count) : 0.0

    if(option.label === "Total Sleep" || option.label === 'Sleep Midpoint' || option.label === 'Deep Sleep')
      avg = Number(avg.toFixed(1))
    else
      avg = Math.round(avg)

    chartData = this.getMovingAverageChartDate(option, chartData)

    let width = window.innerWidth - 270 //270 is the side navigation bar width
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <h5
          data-tip
          data-for={option.label}
          style={{color: '#066D6F', marginTop: 10, textAlign: 'center'}}>{`${option.label} - ${option.unit}`}</h5>
        {this.renderToolTip(option, Math.round(change), start, end, color)}


        <LineChart width={(width/2) - 100} height={300} data={chartData} margin={{ top: 5, right: 40, bottom: 5, left: 0 }}>
          <Line strokeWidth={2} dot={false} name={"Cohort Average"} type="monotone" dataKey="value" stroke={'#808080'} />
          {
            this.state.selectedParticipant && this.state.participantMap && this.state.selectedParticipant?.value === this.state.participantMapId &&
            <Line strokeWidth={2} dot={false} name={this.getParticipantName()} type="monotone" dataKey="participantData" stroke={Constants.primaryTheme} />
          }
          <XAxis padding={{ top: 20 }} dataKey="date" type={"category"}/>
          <YAxis />
          <ReferenceLine y={avg} stroke="#a0a0a0" />
          <Tooltip />
          <Legend verticalAlign={"top"} align={'center'} height={40} wrapperStyle={{left: 40}}/>
        </LineChart>

        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10}}>
          <p style={{marginRight: 20, fontSize: 13}}>Simple Moving Average(SMA)</p>
          <MovingAveragesSelector onTabSelected={(index) => {
            let movingAverages = this.state.movingAverages
            movingAverages[option.label] = index
            this.setState({movingAverages})
          }}/>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div className={Styles.changeWrapper}>
            <h6 style={{color: '#808080'}}>Start Average: {start}{option.unitShort}</h6>
            <h6 style={{color: '#808080'}}>End Average: {end}{option.unitShort}</h6>
          </div>

          {
            change >= 0 ?
              <p style={{color: color, textAlign: 'center', position: 'relative', bottom: 4, fontWeight: 'bold', fontSize: 18}}>{Math.round(change)}% &uarr;</p>
              :
              <p style={{color: color, textAlign: 'center', position: 'relative', bottom: 4, fontWeight: 'bold', fontSize: 18}}>{Math.round(change)}% &darr;</p>
          }
        </div>
      </div>
    )

  }

  formatGroupLabel(data, bioMarker) {
    return (
      <div style={{height: 40, minWidth: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <p style={{fontSize: 18, marginRight: 30, color: "#066D6F"}}>{data.label}</p>
        <button
          onClick={() => this.onSelectAllClicked(data.label, bioMarker)}
          style={{padding: '4px 10px', border: '1px solid #d0d0d0', borderRadius: 6}}>
          <p style={{color: 'gray', marginRight: 5}}>Select All</p>
        </button>
      </div>
    )
  }

  renderRow(sectionLabel, logo, data) {
    let graphs = this.getRowGraphs(sectionLabel, data)

    return (
      <div key={sectionLabel} className={Styles.sectionDivider}>
        <div className={Styles.rowIcon}>
          {
            sectionLabel === 'Respiratory' ?
              <img src={LungsIcon} style={{height: '50%', width: '50%', alignSelf: 'center'}} />
              :
              <FontAwesomeIcon icon={logo} style={{color: 'white', fontSize: 40}}/>
          }
          <p className={Styles.rowIconText}>{sectionLabel}</p>
        </div>

        <div className={Styles.graphRow}>
          {graphs}
        </div>
      </div>
    )
  }

  renderGraphRows() {
    let toReturn = []
    TRACKING_OPTIONS.forEach(section => {
      toReturn.push(this.renderRow(section.label, section.logo))
    })

    return toReturn

  }
  renderPHQGraphRows() {
    console.log("TESTING::: ", this.state.filledPhq)
    let toReturn = []
    TRACKING_OPTIONS.forEach(section => {
      toReturn.push(this.renderRow(section.label, section.logo, this.state.filledPhq))
    })

    return toReturn

  }

  onParticipantChanged(val) {
    this.setState({selectedParticipant: val})
    let {selectedParticipant, timeline, selectedCohort} = this.state
    if(!selectedParticipant || selectedParticipant.value !== val?.value) {
      this.props.addFilledData(null)
      this.setState({loadingFilledData: true, filledData: null, movingAverages: {}})
      this.getFilledDataFromBackend(timeline.value,selectedCohort && selectedCohort.value, val.value)
    }
  }

  onChecked(event) {
    let checked = event.target.checked
    if(checked) {
      cookie.save('trackingOptions', this.state.trackingOptions)
      cookie.save('timeline', this.state.timeline)
    } else {
      cookie.save('trackingOptions', [])
    }

    this.setState({checked})
  }


  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  renderSelects() {
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 20, paddingLeft: 20, paddingRight: 20}}>
          <h6 style={{color: '#20A892'}}>Vitals</h6>
          <div style={{minWidth: '100%'}}>
            <Select
              isSearchable={false}
              autoBlur={true}
              formatGroupLabel={(data) => this.formatGroupLabel(data, false)}
              placeholder={'Select vitals....'}
              isMulti={true}
              options={TRACKING_OPTIONS}
              value={this.state.trackingOptions}
              onChange={this.onTrackingOptionSelected.bind(this)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        </div>


        <div style={{display: 'flex', flexDirection: 'row', minWidth: '100%', paddingLeft: 20, paddingRight: 20}}>
          <div style={{width: '50%',marginRight: 40, display: 'flex', flexDirection: 'column'}}>
            <h6 style={{color: '#20A892'}}>Timeline</h6>
            <Select
              placeholder={'Select Timeline....'}
              options={[
                {label: 'Monthly', value: 'monthly'},
                {label: 'Quarterly', value: 'quarterly'},
                {label: 'Semi Annual', value: 'semiAnnual'},
                {label: 'Complete History', value: 'complete'},
              ]}
              value={this.state.timeline}
              onChange={(val) => this.onTimelineChanged(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>


            <div style={{width: '50%', marginLeft: 40, display: 'flex', flexDirection: 'column', marginBottom: 20}}>
              <h6 style={{color: '#20A892'}}>Cohort</h6>
              <Select
                placeholder={'Select Cohort....'}
                options={this.state.cohortOptions}
                value={this.state.selectedCohort}
                onChange={(val) => this.onCohortChanged(val)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>

        </div>

          <div style={{width: '100%',marginLeft: 20, marginRight: 40, display: 'flex', flexDirection: 'column', marginBottom: 20}}>
            <h6 style={{color: '#20A892'}}>Participant</h6>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div style={{width: '45%'}}>
                <Select
                  isSearchable={false}
                  autoBlur={true}
                  placeholder={'Select participant....'}
                  isMulti={false}
                  options={this.state.participantsList}
                  value={this.state.selectedParticipant}
                  onChange={this.onParticipantChanged.bind(this)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
              {this.state.selectedParticipant &&
              <p
                onClick={() => this.setState({participantMap: null, selectedParticipant: null})}
                style={{marginLeft: 20, textDecoration: 'underline', cursor: 'pointer'}}>remove</p>
              }
            </div>
          </div>



        {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20}}>*/}
        {/*  <input type="checkbox" style={{marginRight: 10}} onChange={this.onChecked.bind(this)} checked={this.state.checked}/>*/}
        {/*  <p>Save this search</p>*/}
        {/*</div>*/}
      </div>
    )
  }

  render() {
    let {patientData, userType} = this.props
    let uri
    if(patientData)
      uri = Config.BACKEND_URL + 'oura/auth?state=' + patientData.firebase_id
    return (
      <div className={GlobalStyles.container}>
        <Header header={'Population Dashboard'} />
        
        {
          this.state.loadingFilledData || this.state.loading?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 400}}>
              <div className={GlobalStyles.loader} />
            </div>

            :
            <div className={GlobalStyles.contentWrapper}>

              {this.renderSelects()}
              {this.renderGraphRows()}
              {/* {this.state.filledPhq ? this.renderPHQGraphRows() : null} */}

            </div>
        }

      </div>
    )
  }
}

const MovingAveragesSelector = (props) => {
  const [activeTab, setActiveTab] = useState(1)

  const onTabSelected = (index) => {
    setActiveTab(index)
    props.onTabSelected(index)
  }

  return (
    <div style={{flexDirection: 'row', display: 'flex', borderWidth: 1, borderStyle: 'solid', borderColor: 'gray', width: 100, height: 26}}>
      <div
        onClick={() => onTabSelected(1)}
        className={Styles.tabItem}
        style={{backgroundColor: activeTab === 1 ? '#DCDCDC': ''}}>

        <p style={{fontSize: 13}}>1d</p>


      </div>
      <div
        onClick={() => onTabSelected(2)}
        className={Styles.tabItem}
        style={{backgroundColor: activeTab === 2 ? '#DCDCDC': ''}}>
        <p style={{fontSize: 13}}>3d</p>
      </div>
      <div
        onClick={() => onTabSelected(3)}
        className={Styles.tabItem}
        style={{backgroundColor: activeTab === 3 ? '#DCDCDC': '', borderRightWidth: 0}}>
        <p style={{fontSize: 13}}>7d</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    filledData: state.popReducer.filledData,
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    timer: state.patientReducer.timer,
    updateRender: state.userReducer.render,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientData: state.popReducer.patientData,
    userType: state.userReducer.userType,
    patientList: state.patientReducer.patientList,
    firebaseUser: state.userReducer.firebaseUser
  }
}

const mapDispatchToProps = { addFilledData, stopTimer, updateSideNavBar }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AilaDashboard)
