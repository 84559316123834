import React, {useState, useEffect}from 'react'
import Modal from "react-modal";
import Styles from "./styles/styles.module.scss";
import Constants from "../values";
import Button from "react-bootstrap/Button";
import GlobalStyles from "../pages/styles/global.module.scss";
import Config from "../config";
import Select from 'react-select';
import moment from 'moment';

const MODAL_STYLES = {
	overlay: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.44)'
	},
	content : {
		width: '50%',
		height: '65vh',
		padding: 0,
		top                   : '50%',
		left                  : '50%',
		right                 : 'auto',
		bottom                : 'auto',
		marginRight           : '-50%',
		transform             : 'translate(-50%, -50%)',
		zIndex: 2
	}
};

const Options = [
  { label: 'Anxiety (GAD-7)', path: 'anxiety' },
  { label: 'Mental Health (PHQ-9)', path: 'mental' },
  { label: 'Burnout', path: 'burnout' },
  { label: 'Physical Function (HAQ-II)', path: 'physical' },
  { label: "Crohn's Progress (Harvey Bradshaw Index)", path: 'gastro' },
  { label: 'Colitis Progress (P-SCCAI)', path: 'colitis' },
  { label: 'Global Health (PROM-10)', path: 'globalhealth' },
  { label: 'ACE (Adverse Childhood Events)', path: 'ace' },
  { label: 'Sleep Apnea (STOP - BANG)', path: 'sleepapnea' },
]

const QuestionnaireModal = (props) => {
	const [selectedQuestionnaire, setQuestionnaire] = useState([])

  useEffect(() => {
		setQuestionnaire(props?.selectedQuestionnaire || [])
	}, [props])

	const onQuestionAdded = (entry) => {
		let temp = [...selectedQuestionnaire]
		let present = false
		temp.forEach(each => {
			if(each.path === entry.path)
				present = true
		})

		if(!present) {
			temp.push(entry)
			setQuestionnaire(temp)
		}
	}

	const onQuestionnaireDeleted = (index) => {
		let temp = [...selectedQuestionnaire]
		temp.splice(index,1)
		setQuestionnaire(temp)
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.showModal}
			// onAfterOpen={afterOpenModal}
			onRequestClose={props.hideModal}
			style={MODAL_STYLES}
			contentLabel="Modal"
		>
			<div className={Styles.searchModalContentWrapper}>
				<p className={GlobalStyles.closeModalBtn} onClick={props.hideModal}>X</p>
				<h4 style={{color: Constants.primaryTheme, textAlign: 'center', marginBottom: 20}}>Search Questionnaire</h4>


				<div className={Styles.selectedLabsWrapper}>
					{selectedQuestionnaire && selectedQuestionnaire.map((x,i) => {
						return (
							<div key={i.toString()} className={Styles.selectedLabEntry}>
								<p>{x.label}</p>
								<p
									onClick={() => onQuestionnaireDeleted(i)}
									className={Styles.selectedLabEntryDelete}>X</p>
							</div>
						)
					})}
				</div>


				<div className={Styles.searchElementsWrapper}>
          <div style={{width: '100%', alignSelf: 'center', marginTop: 50}}>
              <div className={Styles.row}>
                <p className={Styles.rowLabel}>Questionnaire:</p>
                <Select
                    options={Options}
                    className={'w-50'}
                    autoBlur={true}
                    onChange={(val) => {
                      onQuestionAdded(val)
                    }}
										getOptionValue={option => option.label}
                />
              </div>
            </div>
				</div>


				<Button
					onClick={() => props.onQuestionnaireSelected(selectedQuestionnaire)}
					className={GlobalStyles.button}
					style={{width: 140, position: 'absolute', bottom: 10}}
					variant="primary"
				>
					Add
				</Button>

			</div>
		</Modal>
	)
}


export default QuestionnaireModal;
