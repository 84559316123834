import React, {Component} from 'react'
import firebase from '../../services/firebase.js'
import AilaLogo from '../../assets/images/aila_logo.png'
import * as Metadata from '../../metadata.js'

import Constants from '../../values.js'

import FancyField from 'react-fancy-field';
import Button from 'react-bootstrap/Button';
import Styles from './styles/Signup.module.scss'
import values from '../../values';
import GlobalStyles from '../styles/global.module.scss'
import Select from "react-select";

const TOS_LINK = 'https://www.ailahealth.com/termsofuse'

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      pNo: '',
      zip: '',
      loading: false,
      error: '',
      errorString: '',
      tosChecked: false,
      fName: '',
      lName: '',
      userResidence: null,
      healthInsurance: null,
      healthInsuranceName: null,
      onComplete: false,
    }
  }

  onSubmitPressed() {
    const {email, pNo, lName, fName, userResidence, zip, healthInsurance, healthInsuranceName} = this.state

    if(!fName || fName.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid first name'
      })
      return;
    }

    if(!lName || lName.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid last name'
      })
      return;
    }

    if(!email || email.length === 0 || !this.validateEmail(email)) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid email address'
      })
      return;
    }

    if(!pNo || pNo.length < 10 || pNo.length >10) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid phone number'
      })
      return;
    }

    if(!userResidence) {
      this.setState({
        error: true,
        errorString: 'Select State of Residence from the dropdown'
      })
      return
    }

    if(!zip || zip.length === 0) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid zipcode'
      })
      return;
    }

    if(!healthInsurance) {
      this.setState({
        error: true,
        errorString: 'Select Health insurance from the dropdown'
      })
      return;
    }

    if((healthInsurance.value === 'Yes') && (!healthInsuranceName || healthInsuranceName.length === 0)) {
      this.setState({
        error: true,
        errorString: 'Please enter a valid Health Insurance Provider'
      })
      return;
    }

    if(!this.state.tosChecked) {
      this.setState({
        error: true,
        errorString: 'Please read and accept the terms of use'
      })
      return;
    }

    this.setState({loading: true})

    var data = {
      firstName: this.state.fName,
      lastName: this.state.lName,
      email: this.state.email,
      phoneNumber: this.state.pNo,
      zipCode: this.state.zip,
      stateResidence: this.state.userResidence.value,
      healthInsurance: this.state.healthInsuranceName
    }

    console.log('data:::', data);

    let self = this
      firebase.firestore().collection('entries_coaching')
        .add(data)
        .then((res) => {
          console.log('successfully Added data', res);
          this.setState({
            email: '',
            pNo: '',
            zip: '',
            loading: false,
            error: '',
            errorString: '',
            tosChecked: false,
            fName: '',
            lName: '',
            userResidence: null,
            healthInsurance: null,
            healthInsuranceName: null,
            onComplete: true,
          })
        })
        .catch(function(error) {
          console.log('error::::::',error);
          self.setState({
            error: true,
            errorString: 'Error has occured while adding data',
            loading: false
          })
        });
  }


  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    const OnSuccess = () => {
      return(
        <div style={{display: 'flex', flexDirection: 'column', padding: '20px 6px', borderRadius: 4, border: '1px solid gray', width: '90%', marginTop: 20}}>
          <svg className={GlobalStyles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className={GlobalStyles.checkmark__circle} cx="26" cy="26" r="25" fill="none"/>
            <path className={GlobalStyles.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>
          <h5 style={{marginTop: 10,fontSize: 20,  textAlign: 'center', color: '#7e7e7e'}}>Thank you for Signing up!! You have successfully submitted the form.</h5>
        </div>
      );
    }

    return (
      <div className={GlobalStyles.container} style={{overflow: 'hidden', margin: '0 auto', backgroundColor: 'white'}}>
        <div className={Styles.wrapper}>
        <img src={AilaLogo} style={{height: 120, width: 120}}/>

        {
          this.state.onComplete ?
          <OnSuccess/>
          :
        (<>
          <p style={{fontSize: 20, marginTop: 20, fontWeight: 'bold', color: values.primaryThemeDark, fontFamily: 'Avenir-Heavy'}}>To start, please provide your details:</p>

          {
            this.state.loading ?
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 200}}>
                <div className={GlobalStyles.loader} />
              </div>
              :
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '60%'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20, width: '50%'}}>
                  <FancyField value={this.state.fName}
                              classes={Styles.textInput1}
                              label='First Name'
                              disabled={this.state.loading}
                              required={false}
                              name='fName'
                              autoFocus={true}
                              onChange={(val) => this.setState({fName: val, error: false})}
                              placeholder='Enter your first name... '/>

                  <FancyField value={this.state.lName}
                              classes={Styles.textInput1}
                              label='Last Name'
                              disabled={this.state.loading}
                              required={false}
                              name='lName'
                              onChange={(val) => this.setState({lName: val, error: false})}
                              placeholder='Enter your last name... '/>
                </div>

                  <FancyField value={this.state.email}
                              classes={Styles.textInput2}
                              label='Email'
                              disabled={this.state.loading}
                              required={false}
                              name='emailInput'
                              onChange={(val) => this.setState({email: val, error: false})}
                              placeholder='Enter your email... '/>

                  <FancyField value={this.state.pNo}
                              classes={Styles.textInput2}
                              label='Phone Number'
                              disabled={this.state.loading}
                              required={false}
                              type="number"
                              name='pnoInput'
                              onChange={(val) => {
                                if (val.length <= 10){
                                this.setState({pNo: val, error: false})
                                }
                              }}
                              placeholder='Enter your phone number... '/>

                 <FancyField value={this.state.zip}
                              classes={Styles.textInput2}
                              label='Zipcode'
                              disabled={this.state.loading}
                              required={false}
                              type="number"
                              name='zipInput'
                              onChange={(val) => {
                                if(val.length <= 6){
                                this.setState({zip: val, error: false})
                                }
                            }}
                             placeholder='Enter your zipcode... '/>


                  <div style={{width: '50%', padding: '20px 0px'}}>
                    <Select
                      placeholder={'Select State of residence'}
                      isSearchable={false}
                      style={{fontFamily:'Avenir'}}
                      options={Metadata.stateResidence}
                      value={this.state.userResidence}
                      onChange={(val) => this.setState({userResidence: val})}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>

                  <div style={{width: '50%', padding: '20px 0px'}}>
                    <Select
                      placeholder={'Do you have health insurance?'}
                      isSearchable={false}
                      style={{fontFamily:'Avenir'}}
                      options={[
                        {label: 'Yes', value: 'Yes'},
                        {label: 'No', value: 'No'},
                      ]}
                      value={this.state.healthInsurance}
                      onChange={(val) => this.setState({healthInsurance: val})}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                  { (this.state.healthInsurance && this.state.healthInsurance.value === 'Yes') &&
                  <FancyField value={this.state.healthInsuranceName}
                             classes={Styles.textInput2}
                             label='Health Insurance'
                             disabled={this.state.loading}
                             required={false}
                             name='healthInsurance'
                             onChange={(val) => this.setState({healthInsuranceName: val, error: false})}
                             placeholder='Who is your insurance provider?'/>}

                <p style={{marginTop: 20,fontSize: 12, color: 'red', visibility: this.state.error ? 'visible' : 'hidden'}}>{this.state.errorString}</p>


                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, width: '100%', justifyContent: 'center'}}>
                  <input type="checkbox" style={{marginRight: 10, color: values.primaryTheme}} onChange={(checked) => this.setState({tosChecked: checked})} checked={this.state.tosChecked}/>
                  <p style={{color: Constants.primaryTheme}}>I accept the <a href={TOS_LINK} target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href={TOS_LINK} target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
                </div>

                <Button
                  onClick={this.onSubmitPressed.bind(this)}
                  className={GlobalStyles.button}
                  disabled={this.state.loading}
                  style={{marginTop: 40, width: 200}}
                  variant="primary"
                >
                  {this.state.loading ? 'Loading....' : 'Submit'}
                </Button>

              </div>
          }
          </>
        )}
        </div>
      </div>
    )
  }
}

export default Signup;


