import React from 'react'
import Constants from "../values";
import {
  ReferenceArea,
} from "recharts";

export const getChartReferenceLines = (path) => {
  let toReturn = []
  switch (path) {
    case 'anxiety':
      toReturn.push(<ReferenceArea y1={0} y2={4} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={4} y2={9} fill={Constants.graphReferenceColors[1]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={9} y2={14} fill={Constants.graphReferenceColors[3]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={14} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
      return toReturn

    case 'mental':
      toReturn.push(<ReferenceArea y1={0} y2={4} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={4} y2={9} fill={Constants.graphReferenceColors[1]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={9} y2={14} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={14} y2={19} fill={Constants.graphReferenceColors[3]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={19} y2={27} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={27} fill={Constants.graphReferenceColors[5]} fillOpacity={0.1}/>)
      return toReturn
      
    case 'haqScore':
      toReturn.push(<ReferenceArea y1={0} y2={0.25} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={0.25} y2={3.70} fill={Constants.graphReferenceColors[1]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={3.70} y2={8} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={8} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
      return toReturn

    case 'physical':
      toReturn.push(<ReferenceArea y1={0} y2={0.25} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={0.25} y2={3.70} fill={Constants.graphReferenceColors[1]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={3.70} y2={8} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={8} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
      return toReturn

    case 'gastro':
      toReturn.push(<ReferenceArea y1={0} y2={4} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={4} y2={7} fill={Constants.graphReferenceColors[1]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={7} y2={16} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={16} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
      return toReturn

    case 'colitis':
      toReturn.push(<ReferenceArea y1={0} y2={5} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={5} y2={10} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={10} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
      return toReturn

    case 'sleepapnea':
      toReturn.push(<ReferenceArea y1={0} y2={3} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={3} y2={5} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={5} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
      return toReturn

    case 'ace':
      toReturn.push(<ReferenceArea y1={0} y2={3} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={3} y2={5} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={5} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
      return toReturn

    case 'burnout':
      toReturn.push(<ReferenceArea y1={0} y2={18} fill={Constants.graphReferenceColors[0]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={18} y2={32} fill={Constants.graphReferenceColors[2]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={32} y2={49} fill={Constants.graphReferenceColors[3]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={49} y2={59} fill={Constants.graphReferenceColors[4]} fillOpacity={0.1}/>)
      toReturn.push(<ReferenceArea y1={59} fill={Constants.graphReferenceColors[5]} fillOpacity={0.1}/>)
      return toReturn

    default:
      return toReturn
  }
}