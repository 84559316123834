import React, { Component } from 'react'
import Linkify from 'react-linkify'
import { connect } from 'react-redux'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import GlobalStyles from '../../styles/global.module.scss'

import TextInput from 'react-autocomplete-input'
// import "react-autocomplete-input/dist/bundle.css";
import './styles/Custom-autocomplete-input.css'
import Button from 'react-bootstrap/Button'
import Constants from '../../../values.js'
import Styles from './styles/Careplan.module.scss'
import Config from '../../../config'

import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'

const axios = require('axios')

class CareteamChat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      messageText: '',
      messages: [],
      selectedOption: null,
      careteam: [],
      careTeamData: [],
      searchText: '',
      careTeamImgs: [],
    }

    this.getProviderDetails = this.getProviderDetails.bind(this)
    this.getAllProviderData = this.getAllProviderData.bind(this)
    this.getMessagesFromBackend = this.getMessagesFromBackend.bind(this)
    this.getMessages = this.getMessages.bind(this)
    this.renderMessages = this.renderMessages.bind(this)
    this.setProviderRead = this.setProviderRead.bind(this)
  }

  componentDidMount() {
    this.getProviderDetails()
    this.getAllProviderData()
    this.getMessagesFromBackend()
    this.renderMessages()
    this.setProviderRead()
  }

  getAllProviderData() {
    const { mongoUser, patient, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}providers`
    this.setState({ loading: true })
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient.uid,
        getallproviders: true
      },
    })
      .then(({ data }) => {
        if (data) {
          data.forEach((provider) => {
            this.state.careTeamImgs.push({
              doctor_id: provider.firebase_id,
              image_url: provider.image_url,
              name: `${provider.first_name} ${provider.last_name}`,
            })
          })
        }
        // console.log('CARETEAM: ', this.state.careteam)
        // console.log('NAME OF CARETEAM: ', this.state.careTeamData)
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('error when getting providers list from backend', err)
      })
  }

  getProviderDetails() {
    const { mongoUser, patient, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    this.setState({ loading: true })
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: patient.uid,
      },
    })
      .then(({ data }) => {
        if (data) {
          self.setState({ careteam: data })
          this.state.careteam.forEach((care) => {
            if (care.doctor_id != mongoUser.firebase_id) {
              this.state.careTeamData.push({
                name: `${care.first_name} ${care.last_name}`,
                doctor_id: care.doctor_id
              })
            }
          })
        }
        // console.log('CARETEAM: ', this.state.careteam)
        // console.log('NAME OF CARETEAM: ', this.state.careTeamData)
        // console.log('IMAGES OF CARETEAM: ', this.state.careTeamImgs)
        // this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('error when getting careteam list from backend', err)
      })
  }

  getMessagesFromBackend() {
    const { patient, token } = this.props
    const self = this
    const url = `${Config.BACKEND_URL}careteam/chat`
    // this.setState({ loading: true })
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_patient_id: patient.uid,
      },
    })
      .then(({ data }) => {
        if (data) {
          self.setState({ messages: data.messages })
        }
        // console.log(data)
        // this.setState({ loading: false })
      })
      .catch((err) => {
        console.log('error when getting messages from backend', err)
      })
  }

  setProviderRead() {
    const { mongoUser, patient, token } = this.props
    const self = this
    // console.log('SENDING BACKEND: ', this.state.messageText)
    const url = `${Config.BACKEND_URL}careteam/chat/read`
    axios({
      method: 'put',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_doctor_id: mongoUser.firebase_id,
        x_patient_id: patient.uid,
      },
    })
      .then(({ data }) => {
        if (data) {
          console.log('updated provider seen')
        }
      })
      .catch((err) => {
        console.log('error when posting messages from backend', err)
      })
  }

  sendMessages() {
    const { mongoUser, patient, token } = this.props
    const self = this
    // console.log('SENDING BACKEND: ', this.state.messageText)
    let tagged_providers = this.state.careTeamData.filter((careteam) => this.state.messageText.includes(careteam.name))
    const url = `${Config.BACKEND_URL}careteam/chat`
    axios({
      method: 'put',
      url,
      data: {
        sender_id: mongoUser.firebase_id,
        message: this.state.messageText,
        tagged_providers
      },
      headers: {
        Authorization: `JWT ${token}`,
        x_doctor_id: mongoUser.firebase_id,
        x_patient_id: patient.uid,
      },
    })
      .then(({ data }) => {
        if (data) {
          // console.log(data)
          console.log('Message sent')
          this.state.messageText = ''
          this.getMessagesFromBackend()
        }
      })
      .catch((err) => {
        console.log('error when posting messages from backend', err)
      })
  }

  getMessages(messages) {
    const { mongoUser } = this.props
    // console.log('MONGO USER : ', mongoUser)

    const toReturn = []
    let i = 0
    messages.forEach(async (message) => {
      toReturn.push(
        <div key={i.toString()}>
          <div
            className={
              message.sender_id === mongoUser.firebase_id
                ? Styles.messageWrapperRight
                : Styles.messageWrapperLeft
            }
          >
            {message.sender_id != mongoUser.firebase_id ? (
              this.state.careTeamImgs.map((e) =>
                e.doctor_id === message.sender_id ? (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      height: 40,
                      width: 40,
                      borderRadius: '50%',
                      // backgroundColor: Constants.primaryThemeFadedDeep,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={e.image_url ? e.image_url : DocPlaceHolder}
                      height={40}
                      width={40}
                      style={{ borderRadius: '50%', marginBottom: 4 }}
                    />
                  </div>
                ) : (
                  <></>
                ),
              )
            ) : (
              <></>
            )}
            <div
              className={
                message.sender_id === mongoUser.firebase_id
                  ? Styles.messageRight
                  : Styles.messageLeft
              }
            >
              {message.sender_id != mongoUser.firebase_id ? (
                this.state.careTeamImgs.map((e) =>
                  e.doctor_id === message.sender_id ? (
                    <p
                      style={{
                        fontSize: 14,
                        textAlign: 'left',
                        color: 'gray',
                      }}
                    >
                      {/* {console.log("NAME: ", e.name)} */}
                      {e.name}
                    </p>
                  ) : (
                    <></>
                  ),
                )
              ) : (
                <></>
              )}

              <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                <Linkify>{message.message}</Linkify>
              </p>
              <p style={{ fontSize: 14, textAlign: 'right', color: 'gray' }}>
                {moment(message.created_at).format('LLL')}
              </p>
            </div>

            {message.sender_id === mongoUser.firebase_id ? (
              this.state.careTeamImgs.map((e) =>
                e.doctor_id === message.sender_id ? (
                  <div
                    style={{
                      marginTop: 15,
                      // marginRight: 10,
                      height: 40,
                      width: 40,
                      borderRadius: '50%',
                      // backgroundColor: Constants.primaryThemeFadedDeep,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={e.image_url ? e.image_url : DocPlaceHolder}
                      height={40}
                      width={40}
                      style={{ borderRadius: '50%', marginBottom: 4 }}
                    />
                  </div>
                ) : (
                  <></>
                ),
              )
            ) : (
              <></>
            )}
          </div>
        </div>,
      )
      i++
    })
    return toReturn
  }

  renderMessages() {
    const { messages } = this.state

    return (
      <div>
        {messages && messages.length > 0 ? (
          this.getMessages(messages)
        ) : (
          <div>
            <p>Be the first to message the team.....</p>
          </div>
        )}
      </div>
    )
  }

  onHandleChange(e) {
    this.setState({
      messageText: e,
    })
  }

  onHandleSubmit(e) {
    e.preventDefault()
    this.sendMessages()
    const msg = this.state.messageText
    this.setState({
      messageText: '',
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <div
            className={Styles.messagesWrapper}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: 400,
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div style={{ width: '100%', marginBottom: 40 }}>
        <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <h5 style={{ color: Constants.primaryTheme }}>Care Team Chats</h5>
      </div>
        <div className={Styles.messagesWrapper} style={{ height: 440 }}>
          {this.renderMessages()}
        </div>
        <div className={Styles.bottomInputWrapper}>
          <TextInput
            style={{
              borderRadius: 10,
              margin: '2',
              width: '230%',
              height: '100%',
              resize: 'none',
            }}
            placeholder="Enter your text here..."
            value={this.state.messageText}
            options={this.state.careTeamData.map((data) => data.name)}
            onChange={this.onHandleChange.bind(this)}
          />
          {/* <Button
            onClick={this.onHandleSubmit.bind(this)}
            className={GlobalStyles.button}
            disabled={this.state.messageText.length === 0}
            // style={{ marginLeft: "30%" }}
            style={{ marginRight: 20 }}
            variant="primary"
          >
            Send
          </Button> */}

          <FontAwesomeIcon
            onClick={this.onHandleSubmit.bind(this)}
            icon={faPaperPlane}
            style={{
              color: 'gray',
              marginRight: 20,
              fontSize: 30,
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => ({
  patient: state.patientReducer.patient,

  token: state.authReducer.token,

  mongoUser: state.userReducer.mongoUser,
})

export default connect(mapStateToProps, null)(CareteamChat)