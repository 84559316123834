import React, { Component } from 'react'
import GlobalStyles from './styles/global.module.scss'
import Config from "../config";
import {toast, ToastContainer} from "react-toastify";
const queryString = require('query-string');
const axios = require('axios');

export default class NylasCallback extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    let firebaseId = parsed['state']
    let accessToken = parsed['access_token']

    if(!firebaseId) {
      console.log('missing state param')
      this.props.history.push('/login')
    }

    if(!accessToken) {
      console.log('missing access token')
      this.props.history.push('/login')
    }

    //save the access token for the provider in db
    let self = this
    let url = Config.BACKEND_URL + 'providers/nylas/token'
    axios({
      method: 'post',
      headers:{x_doctor_id: firebaseId, access_token: accessToken},
      url: url,
    }).then(({ data }) => {
      toast.success('Success')
      console.log('saved token to backend')
      self.props.history.push('/login')
    }).catch(err => {
      console.log('error when updating user nylas access token', err)
      toast.error('Something went wrong')
      self.props.history.push('/login')
    })
  }

  render() {
    return (
      <div className={GlobalStyles.container}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: '90vh'}}>
          <div className={GlobalStyles.loader} />
        </div>
      </div>
    )
  }
}
