import React from 'react'
import Select from 'react-select'
import Styles from './styles/ReferralsTable.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { SPECIALITY_OPTIONS, userTypes } from '../../../metadata'
import ReactSelect from 'react-select'

export const renderInfusionAppointmentsMasterTableColumns = (
  navigatetoPatientProfile,
  onPatientNavLinkClicked,
  onViewOrderPressed,
) => {
  return [
    {
      Header: 'Patient Name',
      accessor: (obj) => obj?.patientName,
      // Cell: ({ row }) => {
      //   let rowData = row?.original
      //   return (
      //     // <Link
      //     //   onClick={() => {
      //     //     navigatetoPatientProfile(row?.original?.patient_id)
      //     //     onPatientNavLinkClicked('profile')
      //     //   }}
      //     //   // to={{
      //     //   //   pathname: '/patient/profile',
      //     //   //   state: 'info',
      //     //   //   key: 2,
      //     //   // }}
      //     //   className={Styles.link}
      //     // >
      //     { patientData: rowData?.patientName || 'ERROR' }
      //     // </Link>
      //   )
      // },
      width: 250,
      // sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%' }}>
      //         <input
      //           placeholder="Search"
      //           className={Styles.textInput}
      //           style={{ width: '100%' }}
      //           type="text"
      //           value={filterValue}
      //           onChange={(e) => {
      //             setFilter(e.target.value)
      //           }}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
    },
    {
      Header: 'Order Date',
      accessor: (obj) => {
        return moment(obj.created_at).format('MMM Do YYYY')
      },
      // Cell: (row) => (
      //   <div style={{ textAlign: 'left' }}>
      //     {moment(row.value).format('MMM Do YYYY')}
      //   </div>
      // ),
      width: 250,
      // sort: true,
    },
    {
      Header: 'Ordering Provider',
      accessor: (obj) => {
        return (
          (obj?.providerData?.first_name || '') +
          ' ' +
          (obj?.providerData?.last_name || '')
        )
      },
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search Provider"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Order Type',
      accessor: (obj) => {
        let appointment_type = obj?.appointment_type
        if (appointment_type)
          if (appointment_type === 'infusion_visit') {
            return 'Infusion Appointment'
          } else {
            return 'ERROR'
          }
        else return '-'
      },
      width: 300,
      // sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%', fontWeight: 'normal' }}>
      //         <Select
      //           inputId="select-state"
      //           placeholder="Select"
      //           options={[
      //             { label: 'Labs', value: 'Labs' },
      //             { label: 'eRX', value: 'eRX' },
      //             // { label: 'Referrals', value: 'Referrals' },
      //             { label: 'RPM', value: 'RPM' },
      //             { label: 'To Physician/Provider', value: 'physician' },
      //             { label: 'Durable Medical Equipment', value: 'medical' },
      //             { label: 'Imaging/ Radiology', value: 'imaging' },
      //             { label: 'Specialty Pharmacy/Infusion', value: 'speciality' },
      //             { label: 'Patient Prior Auth', value: 'priorAuth' },
      //             { label: 'To Physician/Provider', value: 'physician' },
      //             {
      //               label: 'Patient needs to be admitted to the hospital',
      //               value: 'patient_hospital',
      //             },
      //             {
      //               label: 'Patient needs to be sent to urgent care',
      //               value: 'urgent_care',
      //             },
      //           ]}
      //           value={
      //             filterValue
      //               ? {
      //                   label: filterValue,
      //                   value: filterValue,
      //                 }
      //               : ''
      //           }
      //           // isSearchable
      //           autoBlur
      //           onChange={(val) => {
      //             setFilter(val.label)
      //             // setMemberType(val)
      //             // filterByPatientType(val)
      //           }}
      //           theme={(theme) => ({
      //             ...theme,
      //             colors: {
      //               ...theme.colors,
      //               primary25: '#20A89233',
      //               primary: '#20A892',
      //             },
      //           })}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
    },
    // TODO: Need to get this working - I think the pdf's go here
    {
      Header: 'Notes',
      accessor: (obj) => {
        return obj.notes ? obj.notes : '-'
      },
      width: 300,
      // sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%' }}>
      //         <input
      //           placeholder="Search"
      //           className={Styles.textInput}
      //           style={{ width: '100%' }}
      //           type="text"
      //           value={filterValue}
      //           onChange={(e) => {
      //             setFilter(e.target.value)
      //           }}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
    },
    {
      Header: 'Authorization Number',
      accessor: (obj) => {
        return obj?.authorization_number
      },
      width: 250,
      // sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%' }}>
      //         <input
      //           placeholder="Search"
      //           className={Styles.textInput}
      //           style={{ width: '100%' }}
      //           type="text"
      //           value={filterValue}
      //           onChange={(e) => {
      //             setFilter(e.target.value)
      //           }}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 250,
      Cell: ({ row }) => {
        // console.log('roworgstatus', row?.original?.status)
        let options = [
          { label: 'Authorization Not Submitted', value: 'not_submitted' },
          { label: 'Authorization Pending', value: 'pending' },
          { label: 'Authorization Approved', value: 'approved' },
          { label: 'Authorization Denied', value: 'denied' },
          {
            label: 'Appointment Scheduled',
            value: 'appointment_scheduled',
          },
          {
            label: 'Appointment Not Scheduled',
            value: 'appointment_not_scheduled',
          },
        ]
        let selectedOption = options.find(
          (option) => option.value === row?.original?.status,
        )

        return (
          <ReactSelect
            options={options}
            value={selectedOption}
            // isSearchable
            autoBlur
            onChange={(val) => {
              // setFilter(val.value)
              // setMemberType(val)
              // filterByPatientType(val)
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
      // sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%', fontWeight: 'normal' }}>
      //         <Select
      //           inputId="select-state"
      //           placeholder="Select"
      //           options={[
      //             { label: 'In Progress', value: 'In Progress' },
      //             { label: 'Completed', value: 'Completed' },
      //           ]}
      //           value={
      //             filterValue
      //               ? {
      //                   label: filterValue,
      //                   value: filterValue,
      //                 }
      //               : ''
      //           }
      //           // isSearchable
      //           autoBlur
      //           onChange={(val) => {
      //             setFilter(val.value)
      //             // setMemberType(val)
      //             // filterByPatientType(val)
      //           }}
      //           theme={(theme) => ({
      //             ...theme,
      //             colors: {
      //               ...theme.colors,
      //               primary25: '#20A89233',
      //               primary: '#20A892',
      //             },
      //           })}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
    },
    // TODO: This still needs to be discussed
    {
      Header: 'Response',
      accessor: (obj) => {
        return obj?.response ? 'Completed' : 'In Progress'
      },
      width: 250,
      sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%', fontWeight: 'normal' }}>
      //         <Select
      //           inputId="select-state"
      //           placeholder="Select"
      //           options={[
      //             { label: 'In Progress', value: 'In Progress' },
      //             { label: 'Completed', value: 'Completed' },
      //           ]}
      //           value={
      //             filterValue
      //               ? {
      //                   label: filterValue,
      //                   value: filterValue,
      //                 }
      //               : ''
      //           }
      //           // isSearchable
      //           autoBlur
      //           onChange={(val) => {
      //             setFilter(val.value)
      //             // setMemberType(val)
      //             // filterByPatientType(val)
      //           }}
      //           theme={(theme) => ({
      //             ...theme,
      //             colors: {
      //               ...theme.colors,
      //               primary25: '#20A89233',
      //               primary: '#20A892',
      //             },
      //           })}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
    },
    {
      Header: 'View',
      accessor: 'drug',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              // console.log('view', row.original)
              // if (row.original?.order_type === 'Labs') {
              //   onViewClinicalReportClicked(row.original)
              // } else {
              // onViewOrderPressed(row.original)
              // }
            }}
            className={Styles.link}
            style={{
              textDecoration: 'underline',
              color: '#20a892',
              cursor: 'pointer',
            }}
          >
            View Order
          </p>
        )
      },
      width: 150,
    },
    {
      Header: 'Infusion Appointment',
      accessor: 'start',
      Cell: ({ row }) => {
        return (
          <p
            onClick={() => {
              console.log('view', row.original)
              // if (row.original?.order_type === 'Labs') {
              //   onViewClinicalReportClicked(row.original)
              // } else {
              onViewOrderPressed(row.original)
              // }
            }}
            className={Styles.link}
            style={{
              textDecoration: 'underline',
              color: '#20a892',
              cursor: 'pointer',
            }}
          >
            View
          </p>
        )
      },
      width: 250,
    },
  ]
}
