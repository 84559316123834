import React, { Component } from 'react'
import GlobalStyles from '../styles/global.module.scss'
import values from '../../values'
import Button from 'react-bootstrap/Button'
import AilaLogo from '../../assets/images/aila_logo.png'
import Config from '../../config'
export default class AilaPremiumPopup extends Component {
  constructor() {
    super()
    this.state = {
      patient_id: this.props?.location?.state?.patient_id
        ? this.props?.location?.state?.patient_id
        : '',
      patient_email: this.props?.location?.state?.email_id
        ? this.props?.location?.state?.email_id
        : '',
      stateResidence: this.props?.location?.state?.stateResidence,
      planSelected: this.props?.location?.state?.planSelected,
    }
    this.renderMainContent = this.renderMainContent.bind(this)
    this.renderPaymentIframe = this.renderPaymentIframe.bind(this)
    this.bindEvents = this.bindEvents.bind(this)
  }
  componentDidMount() {
    this.bindEvents()
  }

  bindEvents = () => {
    console.log('inside BindEvents')
    var test = true

    window.addEventListener('message', (event) => {
      // IMPORTANT: check the origin of the data!
      // if (event.origin.startsWith('http://localhost:3002')) {
      // The data was sent from your site.
      // Data sent with postMessage is stored in event.data:
      console.log('Message from iFRAME ', event.data)
      if (event.data === 'getStarted') {
        this.props.history.push('/wix/aila-download', {
          patient_id: this.props?.location?.state?.patient_id,
          email_id: this.props?.location?.state?.email_id,
          stateResidence: this.props?.location?.state?.stateResidence,
          planSelected: this.props?.location?.state?.planSelected,
          patient_type: true,
        })
      }

      // } else {
      // The data was NOT sent from your site!
      // Be careful! Do not use it. This else branch is
      // here just for clarity, you usually shouldn't need it.
      // return
      // }/
    })
  }

  renderPaymentIframe = () => {
    return (
      <div
        style={{
          flex: 1,
          alignSelf: 'center',
          width: '60vh',
          overflow: 'hidden',
        }}
      >
        <iframe
          id="payment_web"
          src={`${Config.WEB_HOST}stripe/subscription-new?patient_id=${this.props?.location?.state?.patient_id}&email=${this.props?.location?.state?.email_id}&state=${this.props?.location?.state?.stateResidence}`}
          title="payment"
          style={{ height: '80vh', width: '60vh' }}
        />
        {/* )} */}
      </div>
    )
  }

  renderMainContent = () => {
    return (
      <div
        style={{
          flex: 1,
          alignSelf: 'center',
          marginTop: '-8vh',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            margin: 'auto',
            color: values.primaryThemeDark,
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: 'Avenir',
            wordWrap: 'break-word',
            marginTop: '2vh',
          }}
        >
          Confirm your booking by entering
          <br />
          your payment details
        </p>
      </div>
    )
  }
  render() {
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            alignSelf: 'left',
            marginTop: '1vh',
          }}
        >
          <img
            alt="Aila Health"
            src={AilaLogo}
            style={{
              width: '15vh',
              height: '15vh',
              alignSelf: 'flex-start',
            }}
          />
        </div>

        {this.renderMainContent()}

        {this.renderPaymentIframe()}

        <div
          style={{
            flex: 1,
            alignSelf: 'center',
            position: 'relative',
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault()
              this.props.history.push('/wix/aila-download', {
                patient_id: this.props?.location?.state?.patient_id,
                email_id: this.props?.location?.state?.email_id,
                stateResidence: this.props?.location?.state?.stateResidence,
                planSelected: this.props?.location?.state?.planSelected,
                patient_type: true,
              })
            }}
            className={GlobalStyles.button}
            style={{
              fontWeight: 'bold',
              margin: '2vh',
              alignSelf: 'center',
              width: 200,
            }}
            variant="primary"
          >
            {'Submit'}
          </Button>
        </div>
      </div>
    )
  }
}
