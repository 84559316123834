import React, { Component } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
} from '../../../redux/actions'
import firebase from '../../../services/firebase.js'
import PlaceHolderProfileImage from '../../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../../metadata.js'

import GlobalStyles from '../../styles/global.module.scss'
import Header from '../../../components/Header'
import Constants from '../../../values.js'
import Styles from './styles/PatientAccount.module.scss'
import Config from '../../../config'
import PatientInsurance from './PatientInsurance'

const moment = require('moment')
const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth
const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: windowWidth / 2,
    height: windowHeight / 2,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}
const TEXT_COLOR = '#7E7E7E'

class PatientSubscription extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
  }

  render() {
    const { patient } = this.props
    let state_residence = null

    let addressComponents = patient?.addressComponents
    if (addressComponents && addressComponents.length > 0) {
      let state = null
      addressComponents.forEach((addressComponent) => {
        let types = addressComponent.types || []
        if (types.includes('administrative_area_level_1'))
          state = addressComponent.short_name
      })
      state_residence = state
    }

    return (
      <div className={GlobalStyles.container}>
        <Header header="My Subscription" />

        {this.state.loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minWidth: '100%',
              height: 400,
              alignItems: 'center',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        ) : (
          <div className={GlobalStyles.contentWrapper}>
            <div
              style={{
                flex: 1,
                alignSelf: 'center',
                width: '100%',
                overflow: 'hidden',
                height: '100vh',
              }}
            >
              <iframe
                id="payment_web"
                src={`${Config.WEB_HOST}stripe/subscription?patient_id=${patient?.uid}&email=${patient?.email}&state=${state_residence}`}
                title="payment"
                style={{ height: '100%', width: '100%' }}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  const { userReducer } = state
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    firebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser:
      userReducer.userType && userReducer.userType.includes('team')
        ? userReducer.teamMongoUser
        : userReducer.mongoUser,
    adminMongoUser: userReducer.mongoUser,
    patient: state.patientReducer.patient,
    userType: userReducer.userType,
    adminId: userReducer.adminId,
    patientCore: state.patientReducer.patientCore,
  }
}

const mapDispatchToProps = {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientSubscription)
