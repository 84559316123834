import React, {Component} from 'react'
import {addUserType, userLoggedIn, addTeamMongoUser, addMongoUser, addAuthToken, addFirebaseAuthUser, addAdminId, addFirebaseUser} from '../../redux/actions'
import GlobalStyles from '../styles/global.module.scss'
import Constants from '../../values.js'
import Styles from "./styles/CCFMTeamSignup.module.scss";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import AilaLogo from '../../assets/images/aila_logo.png'
import FancyField from "react-fancy-field";
import firebase from "../../services/firebase";
import Config from "../../config";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
const axios = require('axios');
const queryString = require('query-string');

const TOS_LINK = 'https://www.ailahealth.com/termsofuse'


class CCFMClientSignup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      email: '',
      pwd: '',
      error: false,
      errorString: '',
      tosChecked: false,
      adminData: {},
      invite: {}
    }
    this.imei = null
    this.firebaseAuthListener = null
    this.getAuthTokenAndNavigate = this.getAuthTokenAndNavigate.bind(this)
    this.getInviteDetails = this.getInviteDetails.bind(this)
    this.updateClientInvite = this.updateClientInvite.bind(this)
  }

  componentDidMount() {
    let self = this
    const parsed = queryString.parse(this.props.location.search);
    if(!parsed || Object.keys(parsed).length !== 1) {
      console.log('missing query params')
      this.props.history.push('/login')
      return
    }

    let encodedString = parsed['q']
    if(!encodedString) {
      console.log('missing encoded string data')
      this.props.history.push('/login')
      return
    }

    let decodedString = atob(encodedString)
    let info = decodedString.split('+')
    if(!info || info.length !== 2) {
      console.log('incorrect encoded string')
      this.props.history.push('/login')
      return
    }

    this.getInviteDetails(info[0], info[1])

    this.getFingerPrint()

  }

  getInviteDetails(adminId, email) {
    this.setState({loading: true})
    let self = this
    let url = Config.BACKEND_URL + 'providers/clients/invites'
    axios({
      method: 'get',
      url: url,
      headers: {x_firebase_id: adminId, email: email}
    }).then(response => {
      let data = response.data
      self.setState({adminData: data.admin, invite: data.invite, loading: false})
    }).catch(err => {
      console.log('error when getting client invitation details', err)
      self.props.history.push('/login')
    })
  }

  async getFingerPrint() {
    try {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId;
      this.imei = visitorId
    }catch(err) {
      console.log('error when getting browser finger print', err)
    }
  }

  async getAuthTokenAndNavigate(user) {
    if(!user) {
      this.setState({loading: false, error: true, errorString: 'Invalid user'})
      return
    }

    let {adminData, invite} = this.state
    let self = this
    let authUrl = Config.BACKEND_URL + 'authorization'
    try {
      //get authorization token for all backend APIs
      let response = await axios({
        method: 'get',
        url: authUrl,
        headers: {x_firebase_id: user.uid, x_user_type: 'provider', imei: this.imei}
      })
      if(response.status === 200) {
        let data = response.data
        self.props.addAuthToken(data.token)
        self.props.addFirebaseAuthUser(user)
        self.updateClientInvite({
          email: invite.email,
          admin_id: adminData.uid
        }, data.token)
      } else {
        console.log('error', response)
      }
    }catch(err) {
      console.log('error when getting auth token', err)
      self.setState({loading: false, error: true, errorString: 'Error getting authorization for backend'})
    }
  }

  updateClientInvite(data, token) {
    let self = this
    let url = Config.BACKEND_URL + 'providers/clients/invites'
    axios({
      method: 'put',
      url: url,
      headers: {Authorization: 'JWT ' + token},
      data: data
    }).then(({ data }) => {
      console.log('successfully updated client invite')
      self.props.userLoggedIn(true)
      self.props.history.push('/wellness/dashboard')
    }).catch(err => {
      console.log('error when adding doctor data to backend', err)
    })
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onSignupPressed() {
    let {pwd, tosChecked, adminData, invite} = this.state
    if(!pwd || pwd.length < 6) {
      this.setState({error: true, errorString: 'Password must be at least 6 characters long'})
      return;
    }

    if(!tosChecked) {
      this.setState({error: true, errorString: 'Please accept terms of use'})
      return;
    }

    this.setState({loading: true, error: false})

    //add user to firebase
    let self = this
    firebase.auth().createUserWithEmailAndPassword(invite.email, pwd)
      .then((result) => {
        let user = result.user
        let uid = user.uid
        console.log('user created')

        let userToPut = {
          userType: 'ccfm-client',
          uid: uid,
          adminId: adminData.uid,
          cohort: invite.cohort,
          firstName: invite.first_name,
          lastName: invite.last_name
        }

        firebase.firestore().collection('users').doc(uid)
          .set(userToPut)
          .then(() => {
            console.log('successfully added client member')
            self.props.addFirebaseUser(userToPut)
            self.props.addUserType('ccfm-client')
            self.props.addAdminId(adminData.uid)
            self.getAuthTokenAndNavigate(user)
          }).catch(err => {
            console.log('error when adding user to firestore', err)
        })
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        if(errorCode === 'auth/email-already-in-use') {
          self.setState({
            error: true,
            errorString: 'Email address already in use',
            loading: false
          })
        }
      });
  }


  renderContent() {
    let {adminData, invite} = this.state
    return (
      <div style={{display: 'flex', flexDirection: 'column', padding: '40px 100px', alignItems: 'center'}}>
        <div style={{marginTop: 100, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '60%'}}>
          <img src={AilaLogo} style={{height: 100, width: 100, marginBottom: 40}}/>

          <p style={{marginBottom: 10}}>{`You have been invited to view ${invite.cohort} wellness dashboard by ${invite.organization}`}</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '80%', marginBottom: 20}}>
            <FancyField value={invite.first_name}
                        classes={Styles.textInput2}
                        label='First name'
                        required={false}
                        name='fName'
                        onChange={null}
                        placeholder='Enter first name... '/>
            <FancyField value={invite.last_name}
                        classes={Styles.textInput2}
                        label='Email'
                        required={false}
                        name='lName'
                        onChange={null}
                        placeholder='Enter your last name... '/>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '80%', marginBottom: 20}}>
            <FancyField value={invite.email}
                        classes={Styles.textInput2}
                        label='Email'
                        required={false}
                        name='email'
                        onChange={null}
                        placeholder='Enter email address... '/>

            <FancyField value={this.state.pwd}
                        classes={Styles.textInput2}
                        label='Password'
                        disabled={this.state.loading}
                        required={false}
                        type="password"
                        autoFocus={true}
                        name='pwdInput'
                        onChange={(val) => this.setState({pwd: val, error: false})}
                        placeholder='Enter your password... '/>

          </div>


          {
            this.state.error &&
            <p style={{color: 'red', fontSize: 12, marginTop: 10}}>{this.state.errorString}</p>
          }

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, width: '100%', justifyContent: 'center'}}>
            <input type="checkbox" style={{marginRight: 10}} onChange={(checked) => this.setState({tosChecked: checked})} checked={this.state.tosChecked}/>
            <p style={{color: Constants.primaryTheme}}>I accept the <a href={TOS_LINK} target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href={TOS_LINK} target="_blank" rel="noopener noreferrer">Terms of Use</a></p>
          </div>

          <Button
            onClick={this.onSignupPressed.bind(this)}
            className={GlobalStyles.button}
            style={{width: 200, marginTop: 20}}
            variant="primary"
          >
            Sign Up
          </Button>

        </div>

      </div>
    )
  }

  render() {
    if(this.state.loading) {
      return (
        <div className={GlobalStyles.container}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: '100vh'}}>
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }
    return (
      <div className={GlobalStyles.container} style={{backgroundColor: 'white'}}>
        {this.renderContent()}
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
  }
}

const mapDispatchToProps = { addUserType, userLoggedIn, addTeamMongoUser, addMongoUser, addAuthToken, addFirebaseAuthUser, addAdminId, addFirebaseUser}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CCFMClientSignup)
