import React, { Component } from 'react'
import 'react-chat-elements/dist/main.css'
import Header from '../../components/Header'
import GlobalStyles from '../styles/global.module.scss'
import Styles1 from './styles/Referrals.module.scss'
import Constants from '../../values.js'
import Config from '../../config'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
// import Config from '../../config'
import Styles from './styles/Faxes.module.scss'
import { Link, NavLink } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import PaymentTab from '../Admin/PaymentTab'
import styles from '../Admin/styles/admin.module.scss'
import DatePicker from 'react-datepicker'
import {
  faEye,
  faShare,
  faArchive,
  faFileArchive,
  faCheckCircle,
  faCross,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons'
import DateTimePicker from 'react-datetime-picker'
// import Select from 'react-select'
import { FaInfo } from 'react-icons/fa'
import {
  BarChart,
  Bar,
  Cell,
  ReferenceLine,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  Pie,
  PieChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ComposedChart,
  Area,
  Scatter,
} from 'recharts'

import {
  getUnreadMessages,
  addPatientLP,
  addRpmPatient,
  setPatientCoreData,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  addMongoUser,
  updateDoctorMongoUser,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
} from '../../redux/actions'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import { data } from 'branch-sdk'
// import Table from '../../components/Table'
import Table from 'react-bootstrap/Table'

const axios = require('axios')

const customDocModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '80vw',
    height: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const sendFaxModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    zIndex: 10,
  },
  content: {
    zIndex: 10,
    width: '40vw',
    height: '60vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class ProductAnalytics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDoc: false,
      modalLoading: false,
      testPDF: '',
      faxList: [],
      outboxList: [],
      showInbox: true,
      selectedTab: 'Basic',
      dataTimeGroupingType: 'Daily',
      sendModal: false,
      modalSchedule: new Date(),
      modalError: false,
      modalFileName: '',
      modalSenderEmail: '',
      modalRecieverFax: '',
      patientListOptions: [],
      // Initially, no file is selected
      selectedFile: null,
      forwardFax: false,
      superUser: false,
      loading: false,

      // These are states for the analytics data

      avgCliniciansSeen: 'nil',
      totalNumberOfAppUsers: 'nil',
      totalClinicalPatients: 'nil',
      numberOfDiagnosticCodes: 'nil',
      numberOfMedications: 'nil',
      numberOfAppointments: 'nil',
      noAppointmentsNoShow: [
        {
          date: '2020-01-01',
          count: 1,
        },
      ],
      numberOfCoachingVisits: 45,
      numberOfReferralRequested: 'nil',
      numberOfPremiumUsers: { allAccess: 'nil', membership: 'nil' },
      numberOfPatientsWhoPayByCash: 'nil',
      // numberOfMembershipUsers: 17,
      // numberOfAllAccessUsers: 4,
      percentOfPatientsFollowedUp: 'nil',
      percentOfPatientsWithSocialHealthRiskFactor: 'nil',
      percentOfPatientsWithMentalHealthIssue: 'nil',
      appointmentsByUsers: 'nil', // this is a list of objects

      costPerAcquisition: 'nil',
      customerAcquisitionCost: 'nil',
      lifetimeValue: 'nil',
      averageRevenuePerUser: 'nil',
      monthlyRecurringRevenue: 'nil',
      returnOnInvestment: 'nil',
      stickiness: 0.1,
      averageSessionLength: 'nil',
      activationRate: '15.59days',

      dailyActiveUsers: 'nil',
      // churnRate: 'nil',
      churnRate: 41,
      engagementRate: 'nil',
      // retentionRate: 'nil',
      retentionRate: 59,
      projectedRevenue: 'nil',
      totalBookedRevenue: 30015.69,
      pcRevenue: 14554.34,
      incRevenue: 15461.35,
      allAccessRevenue: 11513.78,
      coachingMembershipRevenue: 11666.43,
      recurringRevenue: 8605.24,

      currentShowingProvider: 'Kristen Harvey',

      noDailyMemberships: [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ],
      pieChartData1: [
        { name: '1 doctor', value: 190, fill: 'red' },
        { name: '2 doctors', value: 54, fill: 'orange' },
        { name: '3 doctors', value: 15, fill: 'blue' },
        { name: '4 doctors', value: 9, fill: 'green' },
        { name: '5 doctors', value: 4, fill: 'pink' },
        { name: '6 doctors', value: 1, fill: 'black' },
      ],
      pieChartData: [
        {
          name: 'Group A',
          value: 400,
        },
        {
          name: 'Group B',
          value: 300,
        },
        {
          name: 'Group C',
          value: 300,
        },
        {
          name: 'Group D',
          value: 200,
        },
        {
          name: 'Group E',
          value: 278,
        },
        {
          name: 'Group F',
          value: 189,
        },
      ],
      lineChartData: [
        { name: 0, value: 0 },
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
        { name: 4, value: 4 },
        { name: 5, value: 5 },
        { name: 6, value: 6 },
        { name: 7, value: 7 },
        { name: 8, value: 8 },
        { name: 9, value: 9 },
        { name: 10, value: 10 },
        { name: 11, value: 11 },
        { name: 12, value: 12 },
        { name: 13, value: 13 },
        { name: 14, value: 14 },
        { name: 15, value: 15 },
        { name: 16, value: 16 },
        { name: 17, value: 17 },
        { name: 18, value: 18 },
        { name: 19, value: 19 },
        { name: 20, value: 20 },
        { name: 21, value: 21 },
        { name: 22, value: 22 },
        { name: 23, value: 23 },
        { name: 24, value: 24 },
        { name: 25, value: 25 },
      ],
      allPaymentData: [],
      paymentData: [
        // {
        //   "date": "Invalid date",
        //   "total": "0.00",
        //   "aila_inc": "0.00",
        //   "pc_inc": "0.00",
        //   "all_access": "0.00",
        //   "membership": "0.00",
        //   "appointment": "0.00"
        // },
        {
          date: '2021-04-01',
          total: '350.00',
          aila_inc: '350.00',
          pc_inc: '0.00',
          all_access: '350.00',
          membership: '0.00',
          appointment: '0.00',
        },
        {
          date: '2021-05-01',
          total: '0.00',
          aila_inc: '0.00',
          pc_inc: '0.00',
          all_access: '0.00',
          membership: '0.00',
          appointment: '0.00',
        },
        {
          date: '2021-06-01',
          total: '0.00',
          aila_inc: '0.00',
          pc_inc: '0.00',
          all_access: '0.00',
          membership: '0.00',
          appointment: '0.00',
        },
        {
          date: '2021-07-01',
          total: '99.00',
          aila_inc: '99.00',
          pc_inc: '0.00',
          all_access: '0.00',
          membership: '99.00',
          appointment: '0.00',
        },
        {
          date: '2021-08-01',
          total: '250.00',
          aila_inc: '250.00',
          pc_inc: '0.00',
          all_access: '0.00',
          membership: '250.00',
          appointment: '0.00',
        },
        {
          date: '2021-09-01',
          total: '500.00',
          aila_inc: '500.00',
          pc_inc: '0.00',
          all_access: '0.00',
          membership: '500.00',
          appointment: '0.00',
        },
        {
          date: '2021-10-01',
          total: '990.00',
          aila_inc: '990.00',
          pc_inc: '0.00',
          all_access: '950.00',
          membership: '40.00',
          appointment: '0.00',
        },
        {
          date: '2021-11-01',
          total: '0.00',
          aila_inc: '0.00',
          pc_inc: '0.00',
          all_access: '0.00',
          membership: '0.00',
          appointment: '0.00',
        },
        {
          date: '2021-12-01',
          total: '280.00',
          aila_inc: '280.00',
          pc_inc: '0.00',
          all_access: '0.00',
          membership: '280.00',
          appointment: '0.00',
        },
        {
          date: '2022-01-01',
          total: '530.00',
          aila_inc: '530.00',
          pc_inc: '0.00',
          all_access: '0.00',
          membership: '530.00',
          appointment: '0.00',
        },
        {
          date: '2022-02-01',
          total: '1032.01',
          aila_inc: '1032.01',
          pc_inc: '0.00',
          all_access: '150.00',
          membership: '657.01',
          appointment: '0.00',
        },
        {
          date: '2022-03-01',
          total: '1337.56',
          aila_inc: '796.78',
          pc_inc: '540.78',
          all_access: '541.30',
          membership: '1208.52',
          appointment: '540.78',
        },
        {
          date: '2022-04-01',
          total: '1785.38',
          aila_inc: '498.36',
          pc_inc: '1287.02',
          all_access: '773.02',
          membership: '1656.34',
          appointment: '514.00',
        },
        {
          date: '2022-05-01',
          total: '1483.21',
          aila_inc: '686.40',
          pc_inc: '796.81',
          all_access: '684.58',
          membership: '1071.36',
          appointment: '487.23',
        },
        {
          date: '2022-06-01',
          total: '779.79',
          aila_inc: '495.96',
          pc_inc: '283.83',
          all_access: '154.79',
          membership: '393.69',
          appointment: '282.08',
        },
        {
          date: '2022-07-01',
          total: '1712.99',
          aila_inc: '398.67',
          pc_inc: '1314.32',
          all_access: '1314.32',
          membership: '296.40',
          appointment: '102.27',
        },
        {
          date: '2022-08-01',
          total: '1288.48',
          aila_inc: '722.87',
          pc_inc: '565.61',
          all_access: '361.07',
          membership: '570.56',
          appointment: '356.85',
        },
        {
          date: '2022-09-01',
          total: '2078.11',
          aila_inc: '1359.45',
          pc_inc: '718.66',
          all_access: '154.79',
          membership: '431.85',
          appointment: '719.27',
        },
        {
          date: '2022-10-01',
          total: '2646.46',
          aila_inc: '1253.66',
          pc_inc: '1392.80',
          all_access: '747.58',
          membership: '632.98',
          appointment: '1265.90',
        },
        {
          date: '2022-11-01',
          total: '1774.52',
          aila_inc: '1000.57',
          pc_inc: '773.95',
          all_access: '206.28',
          membership: '638.89',
          appointment: '929.35',
        },
        {
          date: '2022-12-01',
          total: '2622.09',
          aila_inc: '1229.29',
          pc_inc: '1392.80',
          all_access: '206.28',
          membership: '660.41',
          appointment: '1755.40',
        },
        {
          date: '2023-01-01',
          total: '3989.49',
          aila_inc: '974.96',
          pc_inc: '3014.53',
          all_access: '2446.86',
          membership: '613.28',
          appointment: '929.35',
        },
        {
          date: '2023-02-01',
          total: '895.86',
          aila_inc: '586.28',
          pc_inc: '309.58',
          all_access: '206.28',
          membership: '328.20',
          appointment: '361.38',
        },
        {
          date: '2023-03-01',
          total: '1207.56',
          aila_inc: '897.98',
          pc_inc: '309.58',
          all_access: '206.28',
          membership: '536.91',
          appointment: '309.58',
        },
        {
          date: '2023-04-01',
          total: '2383.18',
          aila_inc: '529.11',
          pc_inc: '1854.07',
          all_access: '2060.35',
          membership: '271.03',
          appointment: '51.80',
        },
      ],
      stackedBarCharData: [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ],
      noCancelledAppointments: [
        {
          date: '2022-07-18',
          count: 1,
        },
        {
          date: '2022-09-29',
          count: 1,
        },
        {
          date: '2022-11-30',
          count: 3,
        },
        {
          date: '2022-10-25',
          count: 2,
        },
        {
          date: '2023-01-25',
          count: 3,
        },
        {
          date: '2023-01-17',
          count: 1,
        },
        {
          date: '2023-01-10',
          count: 1,
        },
        {
          date: '2022-07-05',
          count: 3,
        },
        {
          date: '2022-11-01',
          count: 1,
        },
        {
          date: '2022-11-16',
          count: 4,
        },
        {
          date: '2022-12-21',
          count: 8,
        },
        {
          date: '2022-11-07',
          count: 1,
        },
        {
          date: '2023-04-05',
          count: 1,
        },
        {
          date: '2023-03-15',
          count: 2,
        },
        {
          date: '2022-08-15',
          count: 2,
        },
        {
          date: '2022-09-28',
          count: 3,
        },
        {
          date: '2022-12-19',
          count: 1,
        },
        {
          date: '2022-06-14',
          count: 1,
        },
        {
          date: '2023-02-17',
          count: 1,
        },
        {
          date: '2022-11-02',
          count: 2,
        },
        {
          date: '2022-10-06',
          count: 1,
        },
        {
          date: '2022-06-29',
          count: 2,
        },
        {
          date: '2022-08-09',
          count: 1,
        },
        {
          date: '2023-03-08',
          count: 3,
        },
        {
          date: '2022-07-19',
          count: 2,
        },
        {
          date: '2022-07-06',
          count: 4,
        },
        {
          date: '2023-02-22',
          count: 4,
        },
        {
          date: '2022-11-08',
          count: 1,
        },
        {
          date: '2023-01-28',
          count: 1,
        },
        {
          date: '2022-09-21',
          count: 6,
        },
        {
          date: '2023-04-12',
          count: 1,
        },
        {
          date: '2023-02-15',
          count: 3,
        },
        {
          date: '2022-03-04',
          count: 2,
        },
        {
          date: '2022-09-07',
          count: 3,
        },
        {
          date: '2022-09-30',
          count: 1,
        },
        {
          date: '2022-12-27',
          count: 1,
        },
        {
          date: '2023-03-29',
          count: 1,
        },
        {
          date: '2023-02-03',
          count: 1,
        },
        {
          date: '2023-01-24',
          count: 2,
        },
        {
          date: '2023-01-18',
          count: 7,
        },
        {
          date: '2023-01-11',
          count: 5,
        },
        {
          date: '2022-12-28',
          count: 2,
        },
        {
          date: '2022-07-20',
          count: 2,
        },
        {
          date: '2022-11-29',
          count: 1,
        },
        {
          date: '2022-09-15',
          count: 1,
        },
        {
          date: '2022-08-03',
          count: 7,
        },
        {
          date: '2022-08-29',
          count: 4,
        },
        {
          date: '2022-09-27',
          count: 1,
        },
        {
          date: '2022-10-19',
          count: 4,
        },
        {
          date: '2022-06-15',
          count: 2,
        },
        {
          date: '2022-10-10',
          count: 2,
        },
        {
          date: '2022-07-22',
          count: 1,
        },
        {
          date: '2022-08-30',
          count: 1,
        },
        {
          date: '2022-07-25',
          count: 2,
        },
        {
          date: '2023-04-06',
          count: 1,
        },
        {
          date: '2023-04-20',
          count: 1,
        },
        {
          date: '2022-07-26',
          count: 1,
        },
        {
          date: '2022-08-24',
          count: 2,
        },
        {
          date: '2022-10-04',
          count: 2,
        },
        {
          date: '2022-12-07',
          count: 3,
        },
        {
          date: '2022-08-18',
          count: 1,
        },
        {
          date: '2023-03-01',
          count: 1,
        },
        {
          date: '2023-03-10',
          count: 4,
        },
        {
          date: '2023-03-24',
          count: 1,
        },
        {
          date: '2022-10-05',
          count: 4,
        },
        {
          date: '2023-03-23',
          count: 1,
        },
        {
          date: '2022-08-25',
          count: 1,
        },
        {
          date: '2022-05-30',
          count: 10,
        },
        {
          date: '2022-08-01',
          count: 7,
        },
        {
          date: '2023-04-14',
          count: 1,
        },
        {
          date: '2022-03-02',
          count: 1,
        },
        {
          date: '2022-12-15',
          count: 1,
        },
        {
          date: '2023-03-17',
          count: 1,
        },
        {
          date: '2023-04-07',
          count: 1,
        },
        {
          date: '2022-10-12',
          count: 1,
        },
        {
          date: '2023-01-06',
          count: 1,
        },
        {
          date: '2022-05-18',
          count: 2,
        },
        {
          date: '2022-05-25',
          count: 2,
        },
        {
          date: '2022-05-23',
          count: 9,
        },
        {
          date: '2022-05-02',
          count: 2,
        },
        {
          date: '2022-12-14',
          count: 2,
        },
        {
          date: '2023-01-04',
          count: 6,
        },
        {
          date: '2023-03-07',
          count: 2,
        },
        {
          date: '2022-12-06',
          count: 1,
        },
        {
          date: '2023-04-04',
          count: 1,
        },
        {
          date: '2023-04-21',
          count: 1,
        },
        {
          date: '2022-08-08',
          count: 4,
        },
        {
          date: '2022-09-26',
          count: 2,
        },
        {
          date: '2022-12-01',
          count: 1,
        },
        {
          date: '2022-09-08',
          count: 1,
        },
        {
          date: '2022-11-09',
          count: 6,
        },
        {
          date: '2023-02-01',
          count: 9,
        },
        {
          date: '2023-04-11',
          count: 2,
        },
        {
          date: '2023-01-13',
          count: 1,
        },
        {
          date: '2022-12-02',
          count: 1,
        },
        {
          date: '2022-07-13',
          count: 2,
        },
        {
          date: '2023-02-14',
          count: 1,
        },
        {
          date: '2022-08-31',
          count: 6,
        },
        {
          date: '2023-02-24',
          count: 1,
        },
        {
          date: '2022-12-09',
          count: 1,
        },
        {
          date: '2023-01-27',
          count: 1,
        },
        {
          date: '2023-02-25',
          count: 2,
        },
        {
          date: '2022-09-05',
          count: 5,
        },
        {
          date: '2022-08-22',
          count: 2,
        },
        {
          date: '2023-01-03',
          count: 1,
        },
        {
          date: '2023-02-07',
          count: 1,
        },
        {
          date: '2022-09-12',
          count: 6,
        },
        {
          date: '2022-09-01',
          count: 1,
        },
        {
          date: '2022-06-20',
          count: 2,
        },
        {
          date: '2023-02-21',
          count: 2,
        },
        {
          date: '2023-02-20',
          count: 1,
        },
        {
          date: '2022-08-10',
          count: 4,
        },
        {
          date: '2022-12-30',
          count: 1,
        },
        {
          date: '2022-03-07',
          count: 5,
        },
        {
          date: '2022-03-03',
          count: 2,
        },
        {
          date: '2023-04-22',
          count: 1,
        },
        {
          date: '2022-06-22',
          count: 3,
        },
        {
          date: '2022-03-16',
          count: 1,
        },
        {
          date: '2022-08-27',
          count: 1,
        },
        {
          date: '2022-09-14',
          count: 1,
        },
        {
          date: '2023-02-16',
          count: 1,
        },
        {
          date: '2023-02-08',
          count: 6,
        },
        {
          date: '2023-03-03',
          count: 1,
        },
        {
          date: '2023-03-22',
          count: 2,
        },
        {
          date: '2023-05-12',
          count: 1,
        },
        {
          date: '2022-07-27',
          count: 1,
        },
        {
          date: '2022-08-17',
          count: 1,
        },
        {
          date: '2022-07-04',
          count: 5,
        },
      ],
      averageRevenuePerAppointment: [
        { date: '2022-09-29', avg: 150.0, total: 150 },
        { date: '2022-10-05', avg: 50.0, total: 50 },
        { date: '2022-10-11', avg: 112.5, total: 225 },
        { date: '2022-10-12', avg: 50.0, total: 50 },
        { date: '2022-10-16', avg: 150.0, total: 150 },
        { date: '2022-10-17', avg: 50.0, total: 150 },
        { date: '2022-10-18', avg: 150.0, total: 450 },
        { date: '2022-10-22', avg: 75.0, total: 75 },
        { date: '2022-10-25', avg: 150.0, total: 300 },
        { date: '2022-10-26', avg: 50.0, total: 150 },
        { date: '2022-11-01', avg: 150.0, total: 300 },
        { date: '2022-11-02', avg: 50.0, total: 50 },
        { date: '2022-11-11', avg: 150.0, total: 150 },
        { date: '2022-11-15', avg: 225.0, total: 900 },
        { date: '2022-11-16', avg: 100.0, total: 200 },
        { date: '2022-11-18', avg: 150.0, total: 150 },
        { date: '2022-11-20', avg: 150.0, total: 150 },
        { date: '2022-11-21', avg: 150.0, total: 150 },
        { date: '2022-11-22', avg: 300.0, total: 300 },
        { date: '2022-11-23', avg: 50.0, total: 50 },
        { date: '2022-11-29', avg: 91.67, total: 275 },
        { date: '2022-11-30', avg: 50.0, total: 150 },
        { date: '2022-12-02', avg: 300.0, total: 300 },
        { date: '2022-12-05', avg: 50.0, total: 50 },
        { date: '2022-12-06', avg: 150.0, total: 450 },
        { date: '2022-12-07', avg: 83.33, total: 250 },
        { date: '2022-12-13', avg: 125.0, total: 500 },
        { date: '2022-12-14', avg: 150.0, total: 150 },
        { date: '2022-12-15', avg: 50.0, total: 50 },
        { date: '2022-12-20', avg: 50.0, total: 50 },
        { date: '2022-12-21', avg: 50.0, total: 50 },
        { date: '2022-12-22', avg: 50.0, total: 50 },
        { date: '2022-12-23', avg: 112.5, total: 225 },
        { date: '2022-12-24', avg: 150.0, total: 150 },
        { date: '2022-12-28', avg: 90.0, total: 450 },
        { date: '2023-01-03', avg: 150.0, total: 150 },
        { date: '2023-01-04', avg: 100.0, total: 400 },
        { date: '2023-01-06', avg: 116.67, total: 350 },
        { date: '2023-01-11', avg: 150.0, total: 450 },
        { date: '2023-01-13', avg: 150.0, total: 150 },
        { date: '2023-01-15', avg: 150.0, total: 150 },
        { date: '2023-01-18', avg: 100.0, total: 400 },
        { date: '2023-01-20', avg: 150.0, total: 300 },
        { date: '2023-01-21', avg: 150.0, total: 150 },
        { date: '2023-01-24', avg: 300.0, total: 300 },
        { date: '2023-01-25', avg: 110.0, total: 1100 },
        { date: '2023-01-27', avg: 150.0, total: 300 },
        { date: '2023-01-28', avg: 150.0, total: 150 },
        { date: '2023-01-31', avg: 225.0, total: 225 },
        { date: '2023-02-01', avg: 150.0, total: 300 },
        { date: '2023-02-03', avg: 225.0, total: 225 },
        { date: '2023-02-04', avg: 150.0, total: 150 },
        { date: '2023-02-07', avg: 150.0, total: 600 },
        { date: '2023-02-08', avg: 125.0, total: 500 },
        { date: '2023-02-10', avg: 116.67, total: 350 },
        { date: '2023-02-14', avg: 150.0, total: 150 },
        { date: '2023-02-15', avg: 150.0, total: 450 },
        { date: '2023-02-17', avg: 150.0, total: 450 },
        { date: '2023-02-20', avg: 150.0, total: 150 },
        { date: '2023-02-21', avg: 150.0, total: 600 },
        { date: '2023-02-22', avg: 100.0, total: 400 },
        { date: '2023-02-24', avg: 100.0, total: 100 },
        { date: '2023-02-26', avg: 150.0, total: 150 },
        { date: '2023-02-28', avg: 150.0, total: 150 },
        { date: '2023-03-01', avg: 150.0, total: 750 },
        { date: '2023-03-03', avg: 150.0, total: 450 },
        { date: '2023-03-04', avg: 150.0, total: 300 },
        { date: '2023-03-07', avg: 187.5, total: 375 },
        { date: '2023-03-08', avg: 100.0, total: 200 },
        { date: '2023-03-14', avg: 150.0, total: 150 },
        { date: '2023-03-15', avg: 150.0, total: 600 },
        { date: '2023-03-17', avg: 150.0, total: 600 },
        { date: '2023-03-21', avg: 150.0, total: 150 },
        { date: '2023-03-22', avg: 116.67, total: 700 },
        { date: '2023-03-24', avg: 150.0, total: 150 },
        { date: '2023-03-25', avg: 150.0, total: 150 },
        { date: '2023-03-29', avg: 225.0, total: 450 },
        { date: '2023-03-30', avg: 50.0, total: 50 },
        { date: '2023-03-31', avg: 150.0, total: 450 },
        { date: '2023-04-04', avg: 150.0, total: 150 },
        { date: '2023-04-05', avg: 125.0, total: 500 },
        { date: '2023-04-07', avg: 125.0, total: 375 },
        { date: '2023-04-12', avg: 125.0, total: 500 },
        { date: '2023-04-14', avg: 150.0, total: 150 },
        { date: '2023-04-17', avg: 225.0, total: 225 },
        { date: '2023-04-18', avg: 225.0, total: 225 },
        { date: '2023-04-19', avg: 100.0, total: 600 },
        { date: '2023-04-20', avg: 150.0, total: 300 },
        { date: '2023-04-21', avg: 150.0, total: 300 },
        { date: '2023-04-26', avg: 130.0, total: 650 },
        // { date: '2023-04-28', avg: '150.00', total: 600 },
        // { date: '2023-05-02', avg: '150.00', total: 150 },
        // { date: '2023-05-03', avg: '100.00', total: 200 },
        // { date: '2023-05-19', avg: '150.00', total: 150 },
        // { date: '2023-05-31', avg: '50.00', total: 50 },
      ],
      totalAppointments: [
        { date: '2021-04-14', count: 2 },
        { date: '2021-04-15', count: 1 },
        { date: '2021-04-16', count: 1 },
        { date: '2021-05-17', count: 1 },
        { date: '2021-05-20', count: 1 },
        { date: '2021-05-27', count: 1 },
        { date: '2021-06-03', count: 1 },
        { date: '2021-06-08', count: 1 },
        { date: '2021-06-14', count: 2 },
        { date: '2021-06-15', count: 1 },
        { date: '2021-06-21', count: 1 },
        { date: '2021-06-24', count: 1 },
        { date: '2021-06-28', count: 2 },
        { date: '2021-06-29', count: 1 },
        { date: '2021-07-01', count: 2 },
        { date: '2021-07-08', count: 2 },
        { date: '2021-07-12', count: 1 },
        { date: '2021-07-26', count: 2 },
        { date: '2021-07-29', count: 1 },
        { date: '2021-08-19', count: 1 },
        { date: '2021-08-26', count: 1 },
        { date: '2021-09-09', count: 1 },
        { date: '2021-09-30', count: 1 },
        { date: '2021-10-14', count: 1 },
        { date: '2021-10-15', count: 2 },
        { date: '2021-10-16', count: 1 },
        { date: '2021-10-21', count: 1 },
        { date: '2021-10-25', count: 1 },
        { date: '2021-10-27', count: 1 },
        { date: '2021-11-08', count: 1 },
        { date: '2021-11-10', count: 1 },
        { date: '2021-11-13', count: 1 },
        { date: '2021-11-15', count: 1 },
        { date: '2021-11-22', count: 2 },
        { date: '2021-11-24', count: 1 },
        { date: '2021-11-29', count: 1 },
        { date: '2021-12-06', count: 2 },
        { date: '2021-12-27', count: 2 },
        { date: '2022-01-11', count: 3 },
        { date: '2022-01-12', count: 5 },
        { date: '2022-01-13', count: 1 },
        { date: '2022-01-14', count: 1 },
        { date: '2022-01-17', count: 1 },
        { date: '2022-01-18', count: 2 },
        { date: '2022-01-19', count: 1 },
        { date: '2022-01-24', count: 2 },
        { date: '2022-01-25', count: 1 },
        { date: '2022-01-26', count: 4 },
        { date: '2022-01-31', count: 1 },
        { date: '2022-02-01', count: 1 },
        { date: '2022-02-02', count: 1 },
        { date: '2022-02-04', count: 1 },
        { date: '2022-02-08', count: 1 },
        { date: '2022-02-09', count: 1 },
        { date: '2022-02-15', count: 4 },
        { date: '2022-02-16', count: 2 },
        { date: '2022-02-21', count: 2 },
        { date: '2022-02-22', count: 3 },
        { date: '2022-02-23', count: 2 },
        { date: '2022-03-01', count: 2 },
        { date: '2022-03-02', count: 3 },
        { date: '2022-03-09', count: 2 },
        { date: '2022-03-10', count: 1 },
        { date: '2022-03-14', count: 1 },
        { date: '2022-03-15', count: 2 },
        { date: '2022-03-16', count: 3 },
        { date: '2022-03-21', count: 2 },
        { date: '2022-03-22', count: 1 },
        { date: '2022-03-23', count: 2 },
        { date: '2022-03-24', count: 1 },
        { date: '2022-03-28', count: 4 },
        { date: '2022-03-29', count: 3 },
        { date: '2022-03-30', count: 2 },
        { date: '2022-03-31', count: 3 },
        { date: '2022-04-02', count: 1 },
        { date: '2022-04-04', count: 2 },
        { date: '2022-04-05', count: 2 },
        { date: '2022-04-06', count: 3 },
        { date: '2022-04-11', count: 3 },
        { date: '2022-04-12', count: 2 },
        { date: '2022-04-13', count: 3 },
        { date: '2022-04-18', count: 3 },
        { date: '2022-04-19', count: 2 },
        { date: '2022-04-20', count: 2 },
        { date: '2022-04-21', count: 1 },
        { date: '2022-04-22', count: 1 },
        { date: '2022-04-25', count: 2 },
        { date: '2022-04-26', count: 2 },
        { date: '2022-04-27', count: 6 },
        { date: '2022-05-02', count: 5 },
        { date: '2022-05-04', count: 2 },
        { date: '2022-05-06', count: 1 },
        { date: '2022-05-09', count: 3 },
        { date: '2022-05-10', count: 1 },
        { date: '2022-05-11', count: 2 },
        { date: '2022-05-16', count: 5 },
        { date: '2022-05-17', count: 1 },
        { date: '2022-05-18', count: 5 },
        { date: '2022-05-23', count: 1 },
        { date: '2022-05-25', count: 7 },
        { date: '2022-05-26', count: 1 },
        { date: '2022-05-31', count: 1 },
        { date: '2022-06-01', count: 3 },
        { date: '2022-06-06', count: 3 },
        { date: '2022-06-07', count: 2 },
        { date: '2022-06-08', count: 5 },
        { date: '2022-06-10', count: 1 },
        { date: '2022-06-13', count: 2 },
        { date: '2022-06-15', count: 7 },
        { date: '2022-06-20', count: 2 },
        { date: '2022-06-21', count: 4 },
        { date: '2022-06-22', count: 6 },
        { date: '2022-06-24', count: 1 },
        { date: '2022-06-27', count: 2 },
        { date: '2022-06-29', count: 5 },
        { date: '2022-07-05', count: 4 },
        { date: '2022-07-06', count: 6 },
        { date: '2022-07-11', count: 4 },
        { date: '2022-07-12', count: 1 },
        { date: '2022-07-13', count: 4 },
        { date: '2022-07-18', count: 1 },
        { date: '2022-07-19', count: 1 },
        { date: '2022-07-20', count: 4 },
        { date: '2022-07-25', count: 4 },
        { date: '2022-07-26', count: 1 },
        { date: '2022-07-27', count: 3 },
        { date: '2022-07-29', count: 1 },
        { date: '2022-08-02', count: 2 },
        { date: '2022-08-03', count: 1 },
        { date: '2022-08-05', count: 1 },
        { date: '2022-08-08', count: 4 },
        { date: '2022-08-10', count: 2 },
        { date: '2022-08-15', count: 2 },
        { date: '2022-08-17', count: 2 },
        { date: '2022-08-18', count: 1 },
        { date: '2022-08-19', count: 1 },
        { date: '2022-08-22', count: 4 },
        { date: '2022-08-24', count: 9 },
        { date: '2022-08-29', count: 2 },
        { date: '2022-08-31', count: 4 },
        { date: '2022-09-01', count: 2 },
        { date: '2022-09-06', count: 1 },
        { date: '2022-09-07', count: 4 },
        { date: '2022-09-08', count: 1 },
        { date: '2022-09-09', count: 2 },
        { date: '2022-09-12', count: 3 },
        { date: '2022-09-14', count: 4 },
        { date: '2022-09-15', count: 4 },
        { date: '2022-09-19', count: 4 },
        { date: '2022-09-26', count: 3 },
        { date: '2022-09-28', count: 4 },
        { date: '2022-09-29', count: 2 },
        { date: '2022-10-03', count: 3 },
        { date: '2022-10-05', count: 3 },
        { date: '2022-10-10', count: 5 },
        { date: '2022-10-11', count: 2 },
        { date: '2022-10-12', count: 5 },
        { date: '2022-10-16', count: 1 },
        { date: '2022-10-17', count: 4 },
        { date: '2022-10-18', count: 3 },
        { date: '2022-10-19', count: 4 },
        { date: '2022-10-22', count: 1 },
        { date: '2022-10-25', count: 2 },
        { date: '2022-10-26', count: 4 },
        { date: '2022-11-01', count: 2 },
        { date: '2022-11-02', count: 5 },
        { date: '2022-11-11', count: 1 },
        { date: '2022-11-15', count: 4 },
        { date: '2022-11-16', count: 6 },
        { date: '2022-11-18', count: 1 },
        { date: '2022-11-20', count: 1 },
        { date: '2022-11-21', count: 1 },
        { date: '2022-11-22', count: 1 },
        { date: '2022-11-23', count: 7 },
        { date: '2022-11-29', count: 3 },
        { date: '2022-11-30', count: 5 },
        { date: '2022-12-02', count: 1 },
        { date: '2022-12-05', count: 1 },
        { date: '2022-12-06', count: 3 },
        { date: '2022-12-07', count: 6 },
        { date: '2022-12-08', count: 1 },
        { date: '2022-12-13', count: 4 },
        { date: '2022-12-14', count: 7 },
        { date: '2022-12-15', count: 1 },
        { date: '2022-12-20', count: 2 },
        { date: '2022-12-21', count: 1 },
        { date: '2022-12-22', count: 1 },
        { date: '2022-12-23', count: 2 },
        { date: '2022-12-24', count: 1 },
        { date: '2022-12-28', count: 8 },
        { date: '2023-01-03', count: 1 },
        { date: '2023-01-04', count: 6 },
        { date: '2023-01-06', count: 3 },
        { date: '2023-01-10', count: 2 },
        { date: '2023-01-11', count: 5 },
        { date: '2023-01-13', count: 1 },
        { date: '2023-01-15', count: 1 },
        { date: '2023-01-17', count: 2 },
        { date: '2023-01-18', count: 4 },
        { date: '2023-01-20', count: 2 },
        { date: '2023-01-21', count: 1 },
        { date: '2023-01-24', count: 1 },
        { date: '2023-01-25', count: 13 },
        { date: '2023-01-27', count: 2 },
        { date: '2023-01-28', count: 1 },
        { date: '2023-01-31', count: 1 },
        { date: '2023-02-01', count: 4 },
        { date: '2023-02-03', count: 1 },
        { date: '2023-02-04', count: 1 },
        { date: '2023-02-07', count: 4 },
        { date: '2023-02-08', count: 5 },
        { date: '2023-02-10', count: 3 },
        { date: '2023-02-14', count: 2 },
        { date: '2023-02-15', count: 7 },
        { date: '2023-02-17', count: 3 },
        { date: '2023-02-20', count: 1 },
        { date: '2023-02-21', count: 4 },
        { date: '2023-02-22', count: 6 },
        { date: '2023-02-24', count: 1 },
        { date: '2023-02-26', count: 1 },
        { date: '2023-02-28', count: 1 },
        { date: '2023-03-01', count: 7 },
        { date: '2023-03-03', count: 3 },
        { date: '2023-03-04', count: 2 },
        { date: '2023-03-07', count: 2 },
        { date: '2023-03-08', count: 6 },
        { date: '2023-03-14', count: 2 },
        { date: '2023-03-15', count: 4 },
        { date: '2023-03-17', count: 4 },
        { date: '2023-03-21', count: 1 },
        { date: '2023-03-22', count: 7 },
        { date: '2023-03-24', count: 1 },
        { date: '2023-03-25', count: 1 },
        { date: '2023-03-29', count: 3 },
        { date: '2023-03-30', count: 1 },
        { date: '2023-03-31', count: 3 },
        { date: '2023-04-04', count: 1 },
        { date: '2023-04-05', count: 7 },
        { date: '2023-04-07', count: 3 },
        { date: '2023-04-12', count: 7 },
        { date: '2023-04-14', count: 1 },
        { date: '2023-04-17', count: 1 },
        { date: '2023-04-18', count: 2 },
        { date: '2023-04-19', count: 7 },
        { date: '2023-04-20', count: 2 },
        { date: '2023-04-21', count: 2 },
        { date: '2023-04-26', count: 7 },
        { date: '2023-04-28', count: 4 },
        { date: '2023-05-02', count: 1 },
        { date: '2023-05-03', count: 4 },
        { date: '2023-05-17', count: 2 },
        { date: '2023-05-19', count: 1 },
        { date: '2023-05-24', count: 1 },
        { date: '2023-05-31', count: 1 },
      ],
      allProviders: [
        // 'CCFM Admin',
        // 'Sunjya Schweig, MD',
        // 'Sunjya Schweig, MD',
        // 'Aila Health Support',
        // 'Aila Health Support',
        'Laura Montgomery',
        'Sunjya Schweig',
        'Megan  Anderson',
        'Dennis Bergkamp',
        'Jack Warner',
        'Naren Nagarajappa',
        'Shannon Sims',
        'Katie Comptom',
        'Alison Shmerling',
        'Heather Berens',
        'Jenifer Tharani',
        'Sammy Smith',
        'Abel  Romero',
        'Danielle Kassouf',
        'Sujata Bajaj',
        'Zahra Rehman',
        'Mira Olson',
        'Haywood Hall',
        'Alice Perlowski',
        'Kristen Harvey',
        'Anna Chacon',
        'Dolly Roy',
        'Billing Administrator',
        'Desiree Werland',
        'Sowmya Oommen',
        'Lynhdiem Bui',
      ],
      allProvidersForSelect: [
        // 'CCFM Admin',
        // 'Sunjya Schweig, MD',
        // 'Sunjya Schweig, MD',
        // 'Aila Health Support',
        // 'Aila Health Support',
        { label: 'Laura Montgomery', value: 'Laura Montgomery' },
        { label: 'Sunjya Schweig', value: 'Sunjya Schweig' },
        { label: 'Megan  Anderson', value: 'Megan  Anderson' },
        { label: 'Dennis Bergkamp', value: 'Dennis Bergkamp' },
        { label: 'Jack Warner', value: 'Jack Warner' },
        { label: 'Naren Nagarajappa', value: 'Naren Nagarajappa' },
        { label: 'Shannon Sims', value: 'Shannon Sims' },
        { label: 'Katie Comptom', value: 'Katie Comptom' },
        { label: 'Alison Shmerling', value: 'Alison Shmerling' },
        { label: 'Heather Berens', value: 'Heather Berens' },
        { label: 'Jenifer Tharani', value: 'Jenifer Tharani' },
        { label: 'Sammy Smith', value: 'Sammy Smith' },
        { label: 'Abel  Romero', value: 'Abel  Romero' },
        { label: 'Danielle Kassouf', value: 'Danielle Kassouf' },
        { label: 'Sujata Bajaj', value: 'Sujata Bajaj' },
        { label: 'Zahra Rehman', value: 'Zahra Rehman' },
        { label: 'Mira Olson', value: 'Mira Olson' },
        { label: 'Haywood Hall', value: 'Haywood Hall' },
        { label: 'Alice Perlowski', value: 'Alice Perlowski' },
        { label: 'Kristen Harvey', value: 'Kristen Harvey' },
        { label: 'Anna Chacon', value: 'Anna Chacon' },
        { label: 'Dolly Roy', value: 'Dolly Roy' },
        { label: 'Billing Administrator', value: 'Billing Administrator' },
        { label: 'Desiree Werland', value: 'Desiree Werland' },
        { label: 'Sowmya Oommen', value: 'Sowmya Oommen' },
        { label: 'Lynhdiem Bui', value: 'Lynhdiem Bui' },
      ],
      currentShowingProviderLineChartData: [
        { date: '2022-09-29', count: 1, total_visit_fee: 150 },
        { date: '2022-10-18', count: 1, total_visit_fee: 150 },
        { date: '2022-11-11', count: 1, total_visit_fee: 150 },
        { date: '2022-11-18', count: 1, total_visit_fee: 150 },
        { date: '2022-12-07', count: 1, total_visit_fee: 150 },
        { date: '2022-12-23', count: 2, total_visit_fee: 225 },
        { date: '2022-12-28', count: 1, total_visit_fee: 150 },
        { date: '2023-01-04', count: 2, total_visit_fee: 300 },
        { date: '2023-01-06', count: 2, total_visit_fee: 300 },
        { date: '2023-01-11', count: 3, total_visit_fee: 450 },
        { date: '2023-01-13', count: 1, total_visit_fee: 150 },
        { date: '2023-01-18', count: 1, total_visit_fee: 150 },
        { date: '2023-01-20', count: 2, total_visit_fee: 300 },
        { date: '2023-01-25', count: 4, total_visit_fee: 600 },
        { date: '2023-01-27', count: 2, total_visit_fee: 300 },
        { date: '2023-02-08', count: 3, total_visit_fee: 450 },
        { date: '2023-02-10', count: 2, total_visit_fee: 300 },
        { date: '2023-02-15', count: 2, total_visit_fee: 300 },
        { date: '2023-02-17', count: 1, total_visit_fee: 150 },
        { date: '2023-02-22', count: 2, total_visit_fee: 300 },
        { date: '2023-03-01', count: 3, total_visit_fee: 450 },
        { date: '2023-03-03', count: 2, total_visit_fee: 300 },
        { date: '2023-03-15', count: 3, total_visit_fee: 450 },
        { date: '2023-03-17', count: 2, total_visit_fee: 300 },
        { date: '2023-03-22', count: 4, total_visit_fee: 600 },
        { date: '2023-03-24', count: 1, total_visit_fee: 150 },
        { date: '2023-03-29', count: 2, total_visit_fee: 450 },
        { date: '2023-03-31', count: 2, total_visit_fee: 300 },
        { date: '2023-04-05', count: 2, total_visit_fee: 300 },
        { date: '2023-04-07', count: 2, total_visit_fee: 225 },
        { date: '2023-04-12', count: 3, total_visit_fee: 450 },
        { date: '2023-04-19', count: 2, total_visit_fee: 300 },
        { date: '2023-04-21', count: 2, total_visit_fee: 300 },
        { date: '2023-04-26', count: 4, total_visit_fee: 600 },
        { date: '2023-04-28', count: 2, total_visit_fee: 300 },
        { date: '2023-05-03', count: 1, total_visit_fee: 150 },
      ],
      allProviderMetadata: {
        'Sunjya Schweig, MD': [],
        'Aila Health Support': [],
        'Laura Montgomery': [],
        'Sunjya Schweig': [],
        'Megan  Anderson': [],
        'Dennis Bergkamp': [],
        'Jack Warner': [],
        'Naren Nagarajappa': [],
        'Shannon Sims': [
          { date: '2022-10-05', count: 1, total_visit_fee: 50 },
          { date: '2022-10-12', count: 1, total_visit_fee: 50 },
          { date: '2022-10-17', count: 1, total_visit_fee: 50 },
          { date: '2022-10-26', count: 3, total_visit_fee: 150 },
          { date: '2022-11-02', count: 1, total_visit_fee: 50 },
          { date: '2022-11-16', count: 1, total_visit_fee: 50 },
          { date: '2022-11-23', count: 1, total_visit_fee: 50 },
          { date: '2022-11-30', count: 3, total_visit_fee: 150 },
          { date: '2022-12-07', count: 2, total_visit_fee: 100 },
          { date: '2022-12-28', count: 3, total_visit_fee: 150 },
          { date: '2023-01-04', count: 2, total_visit_fee: 100 },
          { date: '2023-01-18', count: 2, total_visit_fee: 100 },
          { date: '2023-01-25', count: 3, total_visit_fee: 150 },
          { date: '2023-02-08', count: 1, total_visit_fee: 50 },
          { date: '2023-02-22', count: 2, total_visit_fee: 100 },
          { date: '2023-03-08', count: 1, total_visit_fee: 50 },
          { date: '2023-03-22', count: 2, total_visit_fee: 100 },
          { date: '2023-04-05', count: 1, total_visit_fee: 50 },
          { date: '2023-04-12', count: 1, total_visit_fee: 50 },
          { date: '2023-04-19', count: 2, total_visit_fee: 100 },
          { date: '2023-04-26', count: 1, total_visit_fee: 50 },
          { date: '2023-05-03', count: 1, total_visit_fee: 50 },
          { date: '2023-05-31', count: 1, total_visit_fee: 50 },
        ],
        'Katie Comptom': [],
        'Alison Shmerling': [],
        'Heather Berens': [
          { date: '2022-10-11', count: 2, total_visit_fee: 225 },
          { date: '2022-10-18', count: 2, total_visit_fee: 300 },
          { date: '2022-10-25', count: 2, total_visit_fee: 300 },
          { date: '2022-11-01', count: 2, total_visit_fee: 300 },
          { date: '2022-11-15', count: 4, total_visit_fee: 900 },
          { date: '2022-11-16', count: 1, total_visit_fee: 150 },
          { date: '2022-11-22', count: 1, total_visit_fee: 300 },
          { date: '2022-11-29', count: 2, total_visit_fee: 225 },
          { date: '2022-12-06', count: 3, total_visit_fee: 450 },
          { date: '2022-12-13', count: 3, total_visit_fee: 450 },
          { date: '2022-12-14', count: 1, total_visit_fee: 150 },
          { date: '2022-12-28', count: 1, total_visit_fee: 150 },
          { date: '2023-01-03', count: 1, total_visit_fee: 150 },
          { date: '2023-01-18', count: 1, total_visit_fee: 150 },
          { date: '2023-01-24', count: 1, total_visit_fee: 300 },
          { date: '2023-01-25', count: 1, total_visit_fee: 150 },
          { date: '2023-01-31', count: 1, total_visit_fee: 225 },
          { date: '2023-02-01', count: 2, total_visit_fee: 300 },
          { date: '2023-02-07', count: 4, total_visit_fee: 600 },
          { date: '2023-02-14', count: 1, total_visit_fee: 150 },
          { date: '2023-02-15', count: 1, total_visit_fee: 150 },
          { date: '2023-02-21', count: 4, total_visit_fee: 600 },
          { date: '2023-02-28', count: 1, total_visit_fee: 150 },
          { date: '2023-03-01', count: 2, total_visit_fee: 300 },
          { date: '2023-03-07', count: 2, total_visit_fee: 375 },
          { date: '2023-03-08', count: 1, total_visit_fee: 150 },
          { date: '2023-03-21', count: 1, total_visit_fee: 150 },
          { date: '2023-04-04', count: 1, total_visit_fee: 150 },
          { date: '2023-04-05', count: 1, total_visit_fee: 150 },
          { date: '2023-04-18', count: 1, total_visit_fee: 225 },
          { date: '2023-04-19', count: 1, total_visit_fee: 150 },
          { date: '2023-05-02', count: 1, total_visit_fee: 150 },
        ],
        'Jenifer Tharani': [
          { date: '2022-10-17', count: 2, total_visit_fee: 100 },
          { date: '2022-12-05', count: 1, total_visit_fee: 50 },
          { date: '2022-12-13', count: 1, total_visit_fee: 50 },
          { date: '2022-12-15', count: 1, total_visit_fee: 50 },
          { date: '2022-12-21', count: 1, total_visit_fee: 50 },
          { date: '2022-12-22', count: 1, total_visit_fee: 50 },
          { date: '2023-01-06', count: 1, total_visit_fee: 50 },
          { date: '2023-01-25', count: 1, total_visit_fee: 50 },
          { date: '2023-02-10', count: 1, total_visit_fee: 50 },
          { date: '2023-02-24', count: 1, total_visit_fee: 100 },
          { date: '2023-03-30', count: 1, total_visit_fee: 50 },
          { date: '2023-04-19', count: 1, total_visit_fee: 50 },
        ],
        'Sammy Smith': [],
        'Abel  Romero': [],
        'Danielle Kassouf': [],
        'Sujata Bajaj': [],
        'Zahra Rehman': [
          { date: '2022-12-02', count: 1, total_visit_fee: 300 },
          { date: '2023-02-17', count: 2, total_visit_fee: 300 },
          { date: '2023-03-17', count: 2, total_visit_fee: 300 },
          { date: '2023-03-31', count: 1, total_visit_fee: 150 },
          { date: '2023-04-07', count: 1, total_visit_fee: 150 },
          { date: '2023-04-14', count: 1, total_visit_fee: 150 },
          { date: '2023-04-28', count: 2, total_visit_fee: 300 },
          { date: '2023-05-19', count: 1, total_visit_fee: 150 },
        ],
        'Mira Olson': [],
        'Haywood Hall': [],
        'Alice Perlowski': [],
        'Kristen Harvey': [
          { date: '2022-09-29', count: 1, total_visit_fee: 150 },
          { date: '2022-10-18', count: 1, total_visit_fee: 150 },
          { date: '2022-11-11', count: 1, total_visit_fee: 150 },
          { date: '2022-11-18', count: 1, total_visit_fee: 150 },
          { date: '2022-12-07', count: 1, total_visit_fee: 150 },
          { date: '2022-12-23', count: 2, total_visit_fee: 225 },
          { date: '2022-12-28', count: 1, total_visit_fee: 150 },
          { date: '2023-01-04', count: 2, total_visit_fee: 300 },
          { date: '2023-01-06', count: 2, total_visit_fee: 300 },
          { date: '2023-01-11', count: 3, total_visit_fee: 450 },
          { date: '2023-01-13', count: 1, total_visit_fee: 150 },
          { date: '2023-01-18', count: 1, total_visit_fee: 150 },
          { date: '2023-01-20', count: 2, total_visit_fee: 300 },
          { date: '2023-01-25', count: 4, total_visit_fee: 600 },
          { date: '2023-01-27', count: 2, total_visit_fee: 300 },
          { date: '2023-02-08', count: 3, total_visit_fee: 450 },
          { date: '2023-02-10', count: 2, total_visit_fee: 300 },
          { date: '2023-02-15', count: 2, total_visit_fee: 300 },
          { date: '2023-02-17', count: 1, total_visit_fee: 150 },
          { date: '2023-02-22', count: 2, total_visit_fee: 300 },
          { date: '2023-03-01', count: 3, total_visit_fee: 450 },
          { date: '2023-03-03', count: 2, total_visit_fee: 300 },
          { date: '2023-03-15', count: 3, total_visit_fee: 450 },
          { date: '2023-03-17', count: 2, total_visit_fee: 300 },
          { date: '2023-03-22', count: 4, total_visit_fee: 600 },
          { date: '2023-03-24', count: 1, total_visit_fee: 150 },
          { date: '2023-03-29', count: 2, total_visit_fee: 450 },
          { date: '2023-03-31', count: 2, total_visit_fee: 300 },
          { date: '2023-04-05', count: 2, total_visit_fee: 300 },
          { date: '2023-04-07', count: 2, total_visit_fee: 225 },
          { date: '2023-04-12', count: 3, total_visit_fee: 450 },
          { date: '2023-04-19', count: 2, total_visit_fee: 300 },
          { date: '2023-04-21', count: 2, total_visit_fee: 300 },
          { date: '2023-04-26', count: 4, total_visit_fee: 600 },
          { date: '2023-04-28', count: 2, total_visit_fee: 300 },
          { date: '2023-05-03', count: 1, total_visit_fee: 150 },
        ],
        'Anna Chacon': [
          { date: '2022-10-16', count: 1, total_visit_fee: 150 },
          { date: '2022-10-22', count: 1, total_visit_fee: 75 },
          { date: '2022-11-20', count: 1, total_visit_fee: 150 },
          { date: '2022-12-24', count: 1, total_visit_fee: 150 },
          { date: '2023-01-15', count: 1, total_visit_fee: 150 },
          { date: '2023-01-21', count: 1, total_visit_fee: 150 },
          { date: '2023-01-28', count: 1, total_visit_fee: 150 },
          { date: '2023-02-04', count: 1, total_visit_fee: 150 },
          { date: '2023-02-26', count: 1, total_visit_fee: 150 },
          { date: '2023-03-04', count: 2, total_visit_fee: 300 },
          { date: '2023-03-25', count: 1, total_visit_fee: 150 },
        ],
        'Dolly Roy': [
          { date: '2022-11-21', count: 1, total_visit_fee: 150 },
          { date: '2023-01-25', count: 1, total_visit_fee: 150 },
          { date: '2023-02-03', count: 1, total_visit_fee: 225 },
          { date: '2023-02-20', count: 1, total_visit_fee: 150 },
          { date: '2023-03-03', count: 1, total_visit_fee: 150 },
          { date: '2023-03-14', count: 1, total_visit_fee: 150 },
          { date: '2023-03-15', count: 1, total_visit_fee: 150 },
          { date: '2023-04-17', count: 1, total_visit_fee: 225 },
          { date: '2023-04-20', count: 2, total_visit_fee: 300 },
        ],
        'Desiree Werland': [
          { date: '2022-11-29', count: 1, total_visit_fee: 50 },
          { date: '2022-12-20', count: 1, total_visit_fee: 50 },
        ],
        'Sowmya Oommen': [],
        'Lynhdiem Bui': [],
      },

      currentShowingProviderNoAppointmentsLineChartInfo: [
        { date: '2022-08-17', appointments: 1, total_visit_fee: 0 },
        { date: '2022-08-18', appointments: 1, total_visit_fee: 0 },
        { date: '2022-08-24', appointments: 3, total_visit_fee: 0 },
        { date: '2022-08-31', appointments: 2, total_visit_fee: 0 },
        { date: '2022-09-01', appointments: 2, total_visit_fee: 0 },
        { date: '2022-09-08', appointments: 1, total_visit_fee: 0 },
        { date: '2022-09-15', appointments: 2, total_visit_fee: 0 },
        { date: '2022-09-28', appointments: 1, total_visit_fee: 0 },
        { date: '2022-09-29', appointments: 2, total_visit_fee: 150 },
        { date: '2022-10-18', appointments: 1, total_visit_fee: 150 },
        { date: '2022-11-11', appointments: 1, total_visit_fee: 150 },
        { date: '2022-11-18', appointments: 1, total_visit_fee: 150 },
        { date: '2022-12-07', appointments: 1, total_visit_fee: 150 },
        { date: '2022-12-23', appointments: 2, total_visit_fee: 225 },
        { date: '2022-12-28', appointments: 1, total_visit_fee: 150 },
        { date: '2023-01-04', appointments: 2, total_visit_fee: 300 },
        { date: '2023-01-06', appointments: 2, total_visit_fee: 300 },
        { date: '2023-01-11', appointments: 3, total_visit_fee: 450 },
        { date: '2023-01-13', appointments: 1, total_visit_fee: 150 },
        { date: '2023-01-18', appointments: 1, total_visit_fee: 150 },
        { date: '2023-01-20', appointments: 2, total_visit_fee: 300 },
        { date: '2023-01-25', appointments: 4, total_visit_fee: 600 },
        { date: '2023-01-27', appointments: 2, total_visit_fee: 300 },
        { date: '2023-02-08', appointments: 3, total_visit_fee: 450 },
        { date: '2023-02-10', appointments: 2, total_visit_fee: 300 },
        { date: '2023-02-15', appointments: 2, total_visit_fee: 300 },
        { date: '2023-02-17', appointments: 1, total_visit_fee: 150 },
        { date: '2023-02-22', appointments: 2, total_visit_fee: 300 },
        { date: '2023-03-01', appointments: 3, total_visit_fee: 450 },
        { date: '2023-03-03', appointments: 2, total_visit_fee: 300 },
        { date: '2023-03-15', appointments: 3, total_visit_fee: 450 },
        { date: '2023-03-17', appointments: 2, total_visit_fee: 300 },
        { date: '2023-03-22', appointments: 4, total_visit_fee: 600 },
        { date: '2023-03-24', appointments: 1, total_visit_fee: 150 },
        { date: '2023-03-29', appointments: 2, total_visit_fee: 450 },
        { date: '2023-03-31', appointments: 2, total_visit_fee: 300 },
        { date: '2023-04-05', appointments: 2, total_visit_fee: 300 },
        { date: '2023-04-07', appointments: 2, total_visit_fee: 225 },
        { date: '2023-04-12', appointments: 3, total_visit_fee: 450 },
        { date: '2023-04-19', appointments: 2, total_visit_fee: 300 },
        { date: '2023-04-21', appointments: 2, total_visit_fee: 300 },
        { date: '2023-04-26', appointments: 4, total_visit_fee: 600 },
        { date: '2023-04-28', appointments: 1, total_visit_fee: 150 },
        { date: '2023-05-03', appointments: 2, total_visit_fee: 300 },
        { date: '2023-05-10', appointments: 1, total_visit_fee: 150 },
        { date: '2023-05-17', appointments: 1, total_visit_fee: 0 },
      ],
      allProvidersNumberOfAppointmentsInfo: {
        'CCFM Admin': [],
        'Sunjya Schweig, MD': [],
        'Aila Health Support': [],
        'Laura Montgomery': [],
        'Sunjya Schweig': [],
        'Megan  Anderson': [],
        'Dennis Bergkamp': [],
        'Jack Warner': [],
        'Naren Nagarajappa': [
          { date: '2021-04-14', appointments: 2, total_visit_fee: 0 },
          { date: '2021-04-15', appointments: 1, total_visit_fee: 0 },
          { date: '2021-04-16', appointments: 1, total_visit_fee: 0 },
          { date: '2021-10-21', appointments: 1, total_visit_fee: 0 },
          { date: '2022-04-26', appointments: 1, total_visit_fee: 0 },
        ],
        'Shannon Sims': [
          { date: '2021-05-17', appointments: 1, total_visit_fee: 0 },
          { date: '2021-05-20', appointments: 1, total_visit_fee: 0 },
          { date: '2021-05-27', appointments: 1, total_visit_fee: 0 },
          { date: '2021-06-03', appointments: 1, total_visit_fee: 0 },
          { date: '2021-06-08', appointments: 1, total_visit_fee: 0 },
          { date: '2021-06-14', appointments: 2, total_visit_fee: 0 },
          { date: '2021-06-15', appointments: 1, total_visit_fee: 0 },
          { date: '2021-06-21', appointments: 1, total_visit_fee: 0 },
          { date: '2021-06-24', appointments: 1, total_visit_fee: 0 },
          { date: '2021-06-28', appointments: 2, total_visit_fee: 0 },
          { date: '2021-06-29', appointments: 1, total_visit_fee: 0 },
          { date: '2021-07-01', appointments: 2, total_visit_fee: 0 },
          { date: '2021-07-08', appointments: 2, total_visit_fee: 0 },
          { date: '2021-07-12', appointments: 1, total_visit_fee: 0 },
          { date: '2021-07-26', appointments: 2, total_visit_fee: 0 },
          { date: '2021-07-29', appointments: 1, total_visit_fee: 0 },
          { date: '2021-08-19', appointments: 1, total_visit_fee: 0 },
          { date: '2021-08-26', appointments: 1, total_visit_fee: 0 },
          { date: '2021-09-09', appointments: 1, total_visit_fee: 0 },
          { date: '2021-09-30', appointments: 1, total_visit_fee: 0 },
          { date: '2021-10-14', appointments: 1, total_visit_fee: 0 },
          { date: '2021-10-25', appointments: 1, total_visit_fee: 0 },
          { date: '2021-10-27', appointments: 1, total_visit_fee: 0 },
          { date: '2021-11-08', appointments: 1, total_visit_fee: 0 },
          { date: '2021-11-10', appointments: 1, total_visit_fee: 0 },
          { date: '2021-11-15', appointments: 1, total_visit_fee: 0 },
          { date: '2021-11-22', appointments: 2, total_visit_fee: 0 },
          { date: '2021-11-24', appointments: 1, total_visit_fee: 0 },
          { date: '2021-11-29', appointments: 1, total_visit_fee: 0 },
          { date: '2021-12-06', appointments: 2, total_visit_fee: 0 },
          { date: '2021-12-27', appointments: 2, total_visit_fee: 0 },
          { date: '2022-01-11', appointments: 1, total_visit_fee: 0 },
          { date: '2022-01-12', appointments: 3, total_visit_fee: 0 },
          { date: '2022-01-13', appointments: 1, total_visit_fee: 0 },
          { date: '2022-01-14', appointments: 1, total_visit_fee: 0 },
          { date: '2022-01-17', appointments: 1, total_visit_fee: 0 },
          { date: '2022-01-19', appointments: 1, total_visit_fee: 0 },
          { date: '2022-01-26', appointments: 2, total_visit_fee: 0 },
          { date: '2022-01-31', appointments: 1, total_visit_fee: 0 },
          { date: '2022-02-04', appointments: 1, total_visit_fee: 0 },
          { date: '2022-02-09', appointments: 1, total_visit_fee: 0 },
          { date: '2022-02-16', appointments: 1, total_visit_fee: 0 },
          { date: '2022-02-21', appointments: 2, total_visit_fee: 0 },
          { date: '2022-02-23', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-01', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-02', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-09', appointments: 2, total_visit_fee: 0 },
          { date: '2022-03-10', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-14', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-16', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-21', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-23', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-24', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-28', appointments: 3, total_visit_fee: 0 },
          { date: '2022-03-30', appointments: 2, total_visit_fee: 0 },
          { date: '2022-03-31', appointments: 1, total_visit_fee: 0 },
          { date: '2022-04-04', appointments: 2, total_visit_fee: 0 },
          { date: '2022-04-06', appointments: 2, total_visit_fee: 0 },
          { date: '2022-04-11', appointments: 3, total_visit_fee: 0 },
          { date: '2022-04-13', appointments: 3, total_visit_fee: 0 },
          { date: '2022-04-18', appointments: 3, total_visit_fee: 0 },
          { date: '2022-04-20', appointments: 1, total_visit_fee: 0 },
          { date: '2022-04-25', appointments: 2, total_visit_fee: 0 },
          { date: '2022-04-27', appointments: 5, total_visit_fee: 0 },
          { date: '2022-05-02', appointments: 3, total_visit_fee: 0 },
          { date: '2022-05-04', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-09', appointments: 2, total_visit_fee: 0 },
          { date: '2022-05-11', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-16', appointments: 4, total_visit_fee: 0 },
          { date: '2022-05-18', appointments: 3, total_visit_fee: 0 },
          { date: '2022-05-25', appointments: 5, total_visit_fee: 0 },
          { date: '2022-06-01', appointments: 3, total_visit_fee: 0 },
          { date: '2022-06-06', appointments: 3, total_visit_fee: 0 },
          { date: '2022-06-08', appointments: 4, total_visit_fee: 0 },
          { date: '2022-06-13', appointments: 2, total_visit_fee: 0 },
          { date: '2022-06-15', appointments: 4, total_visit_fee: 0 },
          { date: '2022-06-20', appointments: 2, total_visit_fee: 0 },
          { date: '2022-06-22', appointments: 2, total_visit_fee: 0 },
          { date: '2022-06-27', appointments: 2, total_visit_fee: 0 },
          { date: '2022-06-29', appointments: 1, total_visit_fee: 0 },
          { date: '2022-07-06', appointments: 5, total_visit_fee: 0 },
          { date: '2022-07-11', appointments: 4, total_visit_fee: 0 },
          { date: '2022-07-13', appointments: 3, total_visit_fee: 0 },
          { date: '2022-07-18', appointments: 1, total_visit_fee: 0 },
          { date: '2022-07-20', appointments: 2, total_visit_fee: 0 },
          { date: '2022-07-25', appointments: 3, total_visit_fee: 0 },
          { date: '2022-07-27', appointments: 3, total_visit_fee: 0 },
          { date: '2022-08-03', appointments: 1, total_visit_fee: 0 },
          { date: '2022-08-08', appointments: 3, total_visit_fee: 0 },
          { date: '2022-08-10', appointments: 2, total_visit_fee: 0 },
          { date: '2022-08-15', appointments: 2, total_visit_fee: 0 },
          { date: '2022-08-17', appointments: 1, total_visit_fee: 0 },
          { date: '2022-08-22', appointments: 4, total_visit_fee: 0 },
          { date: '2022-08-24', appointments: 6, total_visit_fee: 0 },
          { date: '2022-08-29', appointments: 1, total_visit_fee: 0 },
          { date: '2022-08-31', appointments: 2, total_visit_fee: 0 },
          { date: '2022-09-07', appointments: 4, total_visit_fee: 0 },
          { date: '2022-09-12', appointments: 3, total_visit_fee: 0 },
          { date: '2022-09-14', appointments: 4, total_visit_fee: 0 },
          { date: '2022-09-19', appointments: 4, total_visit_fee: 0 },
          { date: '2022-09-26', appointments: 3, total_visit_fee: 0 },
          { date: '2022-09-28', appointments: 3, total_visit_fee: 0 },
          { date: '2022-10-03', appointments: 3, total_visit_fee: 0 },
          { date: '2022-10-05', appointments: 3, total_visit_fee: 50 },
          { date: '2022-10-10', appointments: 4, total_visit_fee: 0 },
          { date: '2022-10-12', appointments: 5, total_visit_fee: 50 },
          { date: '2022-10-17', appointments: 2, total_visit_fee: 50 },
          { date: '2022-10-19', appointments: 4, total_visit_fee: 0 },
          { date: '2022-10-26', appointments: 4, total_visit_fee: 150 },
          { date: '2022-11-02', appointments: 5, total_visit_fee: 50 },
          { date: '2022-11-16', appointments: 5, total_visit_fee: 50 },
          { date: '2022-11-23', appointments: 7, total_visit_fee: 50 },
          { date: '2022-11-30', appointments: 5, total_visit_fee: 150 },
          { date: '2022-12-07', appointments: 5, total_visit_fee: 100 },
          { date: '2022-12-14', appointments: 6, total_visit_fee: 0 },
          { date: '2022-12-28', appointments: 6, total_visit_fee: 150 },
          { date: '2023-01-04', appointments: 4, total_visit_fee: 100 },
          { date: '2023-01-11', appointments: 2, total_visit_fee: 0 },
          { date: '2023-01-18', appointments: 2, total_visit_fee: 100 },
          { date: '2023-01-25', appointments: 6, total_visit_fee: 150 },
          { date: '2023-02-01', appointments: 2, total_visit_fee: 0 },
          { date: '2023-02-08', appointments: 2, total_visit_fee: 50 },
          { date: '2023-02-15', appointments: 4, total_visit_fee: 0 },
          { date: '2023-02-22', appointments: 4, total_visit_fee: 100 },
          { date: '2023-03-01', appointments: 2, total_visit_fee: 0 },
          { date: '2023-03-08', appointments: 5, total_visit_fee: 50 },
          { date: '2023-03-22', appointments: 3, total_visit_fee: 100 },
          { date: '2023-03-29', appointments: 1, total_visit_fee: 0 },
          { date: '2023-04-05', appointments: 3, total_visit_fee: 50 },
          { date: '2023-04-12', appointments: 4, total_visit_fee: 50 },
          { date: '2023-04-19', appointments: 3, total_visit_fee: 100 },
          { date: '2023-04-26', appointments: 3, total_visit_fee: 50 },
          { date: '2023-05-03', appointments: 4, total_visit_fee: 50 },
          { date: '2023-05-17', appointments: 3, total_visit_fee: 0 },
          { date: '2023-05-24', appointments: 1, total_visit_fee: 0 },
          { date: '2023-05-31', appointments: 2, total_visit_fee: 50 },
        ],
        'Katie Comptom': [],
        'Alison Shmerling': [
          { date: '2021-10-15', appointments: 2, total_visit_fee: 0 },
          { date: '2021-10-16', appointments: 1, total_visit_fee: 0 },
          { date: '2021-11-13', appointments: 1, total_visit_fee: 0 },
          { date: '2022-01-24', appointments: 1, total_visit_fee: 0 },
          { date: '2022-02-15', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-01', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-02', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-21', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-28', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-31', appointments: 2, total_visit_fee: 0 },
          { date: '2022-04-06', appointments: 1, total_visit_fee: 0 },
          { date: '2022-04-12', appointments: 1, total_visit_fee: 0 },
          { date: '2022-04-21', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-02', appointments: 2, total_visit_fee: 0 },
          { date: '2022-05-09', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-26', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-31', appointments: 1, total_visit_fee: 0 },
        ],
        'Heather Berens': [
          { date: '2022-01-11', appointments: 2, total_visit_fee: 0 },
          { date: '2022-01-12', appointments: 2, total_visit_fee: 0 },
          { date: '2022-01-18', appointments: 2, total_visit_fee: 0 },
          { date: '2022-01-24', appointments: 1, total_visit_fee: 0 },
          { date: '2022-01-25', appointments: 1, total_visit_fee: 0 },
          { date: '2022-01-26', appointments: 2, total_visit_fee: 0 },
          { date: '2022-02-01', appointments: 1, total_visit_fee: 0 },
          { date: '2022-02-02', appointments: 1, total_visit_fee: 0 },
          { date: '2022-02-08', appointments: 1, total_visit_fee: 0 },
          { date: '2022-02-15', appointments: 3, total_visit_fee: 0 },
          { date: '2022-02-16', appointments: 1, total_visit_fee: 0 },
          { date: '2022-02-22', appointments: 3, total_visit_fee: 0 },
          { date: '2022-02-23', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-02', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-15', appointments: 2, total_visit_fee: 0 },
          { date: '2022-03-16', appointments: 2, total_visit_fee: 0 },
          { date: '2022-03-22', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-23', appointments: 1, total_visit_fee: 0 },
          { date: '2022-03-29', appointments: 3, total_visit_fee: 0 },
          { date: '2022-04-05', appointments: 2, total_visit_fee: 0 },
          { date: '2022-04-19', appointments: 2, total_visit_fee: 0 },
          { date: '2022-04-20', appointments: 1, total_visit_fee: 0 },
          { date: '2022-04-26', appointments: 1, total_visit_fee: 0 },
          { date: '2022-04-27', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-04', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-10', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-11', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-17', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-18', appointments: 2, total_visit_fee: 0 },
          { date: '2022-05-25', appointments: 2, total_visit_fee: 0 },
          { date: '2022-06-07', appointments: 2, total_visit_fee: 0 },
          { date: '2022-06-08', appointments: 1, total_visit_fee: 0 },
          { date: '2022-06-15', appointments: 2, total_visit_fee: 0 },
          { date: '2022-06-21', appointments: 4, total_visit_fee: 0 },
          { date: '2022-06-22', appointments: 4, total_visit_fee: 0 },
          { date: '2022-06-29', appointments: 3, total_visit_fee: 0 },
          { date: '2022-07-05', appointments: 4, total_visit_fee: 0 },
          { date: '2022-07-06', appointments: 1, total_visit_fee: 0 },
          { date: '2022-10-11', appointments: 2, total_visit_fee: 225 },
          { date: '2022-10-18', appointments: 2, total_visit_fee: 300 },
          { date: '2022-10-25', appointments: 2, total_visit_fee: 300 },
          { date: '2022-11-01', appointments: 2, total_visit_fee: 300 },
          { date: '2022-11-15', appointments: 4, total_visit_fee: 900 },
          { date: '2022-11-16', appointments: 1, total_visit_fee: 150 },
          { date: '2022-11-22', appointments: 1, total_visit_fee: 300 },
          { date: '2022-11-29', appointments: 2, total_visit_fee: 225 },
          { date: '2022-12-06', appointments: 3, total_visit_fee: 450 },
          { date: '2022-12-13', appointments: 3, total_visit_fee: 450 },
          { date: '2022-12-14', appointments: 1, total_visit_fee: 150 },
          { date: '2022-12-20', appointments: 1, total_visit_fee: 0 },
          { date: '2022-12-28', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-03', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-10', appointments: 1, total_visit_fee: 0 },
          { date: '2023-01-17', appointments: 2, total_visit_fee: 0 },
          { date: '2023-01-18', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-24', appointments: 1, total_visit_fee: 300 },
          { date: '2023-01-25', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-31', appointments: 1, total_visit_fee: 225 },
          { date: '2023-02-01', appointments: 2, total_visit_fee: 300 },
          { date: '2023-02-07', appointments: 4, total_visit_fee: 600 },
          { date: '2023-02-14', appointments: 2, total_visit_fee: 150 },
          { date: '2023-02-15', appointments: 1, total_visit_fee: 150 },
          { date: '2023-02-21', appointments: 4, total_visit_fee: 600 },
          { date: '2023-02-28', appointments: 1, total_visit_fee: 150 },
          { date: '2023-03-01', appointments: 2, total_visit_fee: 300 },
          { date: '2023-03-07', appointments: 2, total_visit_fee: 375 },
          { date: '2023-03-08', appointments: 1, total_visit_fee: 150 },
          { date: '2023-03-14', appointments: 1, total_visit_fee: 0 },
          { date: '2023-03-21', appointments: 1, total_visit_fee: 150 },
          { date: '2023-04-04', appointments: 1, total_visit_fee: 150 },
          { date: '2023-04-05', appointments: 2, total_visit_fee: 150 },
          { date: '2023-04-18', appointments: 2, total_visit_fee: 225 },
          { date: '2023-04-19', appointments: 1, total_visit_fee: 150 },
          { date: '2023-05-02', appointments: 1, total_visit_fee: 150 },
        ],
        'Jenifer Tharani': [
          { date: '2022-04-02', appointments: 1, total_visit_fee: 0 },
          { date: '2022-04-12', appointments: 1, total_visit_fee: 0 },
          { date: '2022-04-22', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-06', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-16', appointments: 1, total_visit_fee: 0 },
          { date: '2022-05-23', appointments: 1, total_visit_fee: 0 },
          { date: '2022-06-10', appointments: 1, total_visit_fee: 0 },
          { date: '2022-06-15', appointments: 1, total_visit_fee: 0 },
          { date: '2022-06-24', appointments: 1, total_visit_fee: 0 },
          { date: '2022-06-29', appointments: 1, total_visit_fee: 0 },
          { date: '2022-07-12', appointments: 1, total_visit_fee: 0 },
          { date: '2022-07-13', appointments: 1, total_visit_fee: 0 },
          { date: '2022-07-20', appointments: 2, total_visit_fee: 0 },
          { date: '2022-07-26', appointments: 1, total_visit_fee: 0 },
          { date: '2022-08-02', appointments: 2, total_visit_fee: 0 },
          { date: '2022-09-06', appointments: 1, total_visit_fee: 0 },
          { date: '2022-09-15', appointments: 2, total_visit_fee: 0 },
          { date: '2022-10-10', appointments: 1, total_visit_fee: 0 },
          { date: '2022-10-17', appointments: 2, total_visit_fee: 100 },
          { date: '2022-12-05', appointments: 1, total_visit_fee: 50 },
          { date: '2022-12-13', appointments: 1, total_visit_fee: 50 },
          { date: '2022-12-15', appointments: 1, total_visit_fee: 50 },
          { date: '2022-12-21', appointments: 1, total_visit_fee: 50 },
          { date: '2022-12-22', appointments: 1, total_visit_fee: 50 },
          { date: '2023-01-06', appointments: 1, total_visit_fee: 50 },
          { date: '2023-01-25', appointments: 1, total_visit_fee: 50 },
          { date: '2023-02-10', appointments: 1, total_visit_fee: 50 },
          { date: '2023-02-24', appointments: 1, total_visit_fee: 100 },
          { date: '2023-03-30', appointments: 1, total_visit_fee: 50 },
          { date: '2023-04-19', appointments: 1, total_visit_fee: 50 },
          { date: '2023-05-03', appointments: 1, total_visit_fee: 50 },
          { date: '2023-05-18', appointments: 1, total_visit_fee: 50 },
        ],
        'Sammy Smith': [],
        'Abel  Romero': [],
        'PC Admin': [],
        'Danielle Kassouf': [
          { date: '2022-07-19', appointments: 1, total_visit_fee: 0 },
          { date: '2022-07-25', appointments: 1, total_visit_fee: 0 },
          { date: '2022-08-08', appointments: 1, total_visit_fee: 0 },
          { date: '2022-08-29', appointments: 1, total_visit_fee: 0 },
        ],
        'Sujata Bajaj': [],
        'Zahra Rehman': [
          { date: '2022-08-19', appointments: 1, total_visit_fee: 0 },
          { date: '2022-09-09', appointments: 2, total_visit_fee: 0 },
          { date: '2022-12-02', appointments: 1, total_visit_fee: 300 },
          { date: '2023-02-17', appointments: 2, total_visit_fee: 300 },
          { date: '2023-03-17', appointments: 2, total_visit_fee: 300 },
          { date: '2023-03-31', appointments: 1, total_visit_fee: 150 },
          { date: '2023-04-07', appointments: 1, total_visit_fee: 150 },
          { date: '2023-04-14', appointments: 1, total_visit_fee: 150 },
          { date: '2023-04-28', appointments: 2, total_visit_fee: 300 },
          { date: '2023-05-19', appointments: 1, total_visit_fee: 150 },
        ],
        'Mira Olson': [],
        'Haywood Hall': [
          { date: '2022-07-29', appointments: 1, total_visit_fee: 0 },
          { date: '2022-08-05', appointments: 1, total_visit_fee: 0 },
        ],
        'Alice Perlowski': [],
        'Aila Admin': [],
        'Kristen Harvey': [
          { date: '2022-08-17', appointments: 1, total_visit_fee: 0 },
          { date: '2022-08-18', appointments: 1, total_visit_fee: 0 },
          { date: '2022-08-24', appointments: 3, total_visit_fee: 0 },
          { date: '2022-08-31', appointments: 2, total_visit_fee: 0 },
          { date: '2022-09-01', appointments: 2, total_visit_fee: 0 },
          { date: '2022-09-08', appointments: 1, total_visit_fee: 0 },
          { date: '2022-09-15', appointments: 2, total_visit_fee: 0 },
          { date: '2022-09-28', appointments: 1, total_visit_fee: 0 },
          { date: '2022-09-29', appointments: 2, total_visit_fee: 150 },
          { date: '2022-10-18', appointments: 1, total_visit_fee: 150 },
          { date: '2022-11-11', appointments: 1, total_visit_fee: 150 },
          { date: '2022-11-18', appointments: 1, total_visit_fee: 150 },
          { date: '2022-12-07', appointments: 1, total_visit_fee: 150 },
          { date: '2022-12-23', appointments: 2, total_visit_fee: 225 },
          { date: '2022-12-28', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-04', appointments: 2, total_visit_fee: 300 },
          { date: '2023-01-06', appointments: 2, total_visit_fee: 300 },
          { date: '2023-01-11', appointments: 3, total_visit_fee: 450 },
          { date: '2023-01-13', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-18', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-20', appointments: 2, total_visit_fee: 300 },
          { date: '2023-01-25', appointments: 4, total_visit_fee: 600 },
          { date: '2023-01-27', appointments: 2, total_visit_fee: 300 },
          { date: '2023-02-08', appointments: 3, total_visit_fee: 450 },
          { date: '2023-02-10', appointments: 2, total_visit_fee: 300 },
          { date: '2023-02-15', appointments: 2, total_visit_fee: 300 },
          { date: '2023-02-17', appointments: 1, total_visit_fee: 150 },
          { date: '2023-02-22', appointments: 2, total_visit_fee: 300 },
          { date: '2023-03-01', appointments: 3, total_visit_fee: 450 },
          { date: '2023-03-03', appointments: 2, total_visit_fee: 300 },
          { date: '2023-03-15', appointments: 3, total_visit_fee: 450 },
          { date: '2023-03-17', appointments: 2, total_visit_fee: 300 },
          { date: '2023-03-22', appointments: 4, total_visit_fee: 600 },
          { date: '2023-03-24', appointments: 1, total_visit_fee: 150 },
          { date: '2023-03-29', appointments: 2, total_visit_fee: 450 },
          { date: '2023-03-31', appointments: 2, total_visit_fee: 300 },
          { date: '2023-04-05', appointments: 2, total_visit_fee: 300 },
          { date: '2023-04-07', appointments: 2, total_visit_fee: 225 },
          { date: '2023-04-12', appointments: 3, total_visit_fee: 450 },
          { date: '2023-04-19', appointments: 2, total_visit_fee: 300 },
          { date: '2023-04-21', appointments: 2, total_visit_fee: 300 },
          { date: '2023-04-26', appointments: 4, total_visit_fee: 600 },
          { date: '2023-04-28', appointments: 1, total_visit_fee: 150 },
          { date: '2023-05-03', appointments: 2, total_visit_fee: 300 },
          { date: '2023-05-10', appointments: 1, total_visit_fee: 150 },
          { date: '2023-05-17', appointments: 1, total_visit_fee: 0 },
        ],
        'Anna Chacon': [
          { date: '2022-10-16', appointments: 1, total_visit_fee: 150 },
          { date: '2022-10-22', appointments: 1, total_visit_fee: 75 },
          { date: '2022-11-20', appointments: 1, total_visit_fee: 150 },
          { date: '2022-12-24', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-15', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-21', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-28', appointments: 1, total_visit_fee: 150 },
          { date: '2023-02-04', appointments: 1, total_visit_fee: 150 },
          { date: '2023-02-26', appointments: 1, total_visit_fee: 150 },
          { date: '2023-03-04', appointments: 2, total_visit_fee: 300 },
          { date: '2023-03-25', appointments: 1, total_visit_fee: 150 },
        ],
        'Dolly Roy': [
          { date: '2022-11-21', appointments: 1, total_visit_fee: 150 },
          { date: '2023-01-25', appointments: 1, total_visit_fee: 150 },
          { date: '2023-02-03', appointments: 1, total_visit_fee: 225 },
          { date: '2023-02-20', appointments: 1, total_visit_fee: 150 },
          { date: '2023-03-03', appointments: 1, total_visit_fee: 150 },
          { date: '2023-03-14', appointments: 1, total_visit_fee: 150 },
          { date: '2023-03-15', appointments: 1, total_visit_fee: 150 },
          { date: '2023-04-17', appointments: 1, total_visit_fee: 225 },
          { date: '2023-04-20', appointments: 2, total_visit_fee: 300 },
        ],
        'Billing Administrator': [],
        'Desiree Werland': [
          { date: '2022-11-29', appointments: 1, total_visit_fee: 50 },
          { date: '2022-12-08', appointments: 1, total_visit_fee: 0 },
          { date: '2022-12-20', appointments: 1, total_visit_fee: 50 },
          { date: '2023-01-10', appointments: 1, total_visit_fee: 0 },
        ],
        'Sowmya Oommen': [],
        'Lynhdiem Bui': [],
      },
      testProviderData: {
        'Kristen Harvey': {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          count: 15,
          all_tickets: [
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-05-01T22:42:33.618Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-05-01T22:43:56.894Z',
              due_date_as_date: '2023-05-01T22:42:33.618Z',
              created_at_as_string: '2023-05-01',
              due_date_as_string: '2023-05-01',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-05-01T18:41:13.720Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-05-01T18:42:32.663Z',
              due_date_as_date: '2023-05-01T18:41:13.720Z',
              created_at_as_string: '2023-05-01',
              due_date_as_string: '2023-05-01',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-05-01T17:27:21.815Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-05-01T17:28:53.370Z',
              due_date_as_date: '2023-05-01T17:27:21.815Z',
              created_at_as_string: '2023-05-01',
              due_date_as_string: '2023-05-01',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-05-01T17:13:46.751Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-05-01T17:15:52.829Z',
              due_date_as_date: '2023-05-01T17:13:46.751Z',
              created_at_as_string: '2023-05-01',
              due_date_as_string: '2023-05-01',
            },
            {
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-04-20T18:37:47.424Z',
              due_date: '2023-04-21T18:37:47.424Z',
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              status: 'not_started',
              due_date_as_date: '2023-04-21T18:37:47.424Z',
              created_at_as_string: '2023-04-20',
              due_date_as_string: '2023-04-21',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-04-18T20:37:12.711Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-04-18T20:38:49.849Z',
              due_date_as_date: '2023-04-18T20:37:12.711Z',
              created_at_as_string: '2023-04-18',
              due_date_as_string: '2023-04-18',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-04-07T23:20:40.598Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-04-07T23:23:22.349Z',
              due_date_as_date: '2023-04-07T23:20:40.598Z',
              created_at_as_string: '2023-04-07',
              due_date_as_string: '2023-04-07',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-04-07T17:52:22.096Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-04-07T17:53:53.304Z',
              due_date_as_date: '2023-04-07T17:52:22.096Z',
              created_at_as_string: '2023-04-07',
              due_date_as_string: '2023-04-07',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-04-07T15:53:30.339Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-04-07T15:54:28.258Z',
              due_date_as_date: '2023-04-07T15:53:30.339Z',
              created_at_as_string: '2023-04-07',
              due_date_as_string: '2023-04-07',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-04-06T16:34:08.505Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-04-06T16:35:10.837Z',
              due_date_as_date: '2023-04-06T16:34:08.505Z',
              created_at_as_string: '2023-04-06',
              due_date_as_string: '2023-04-06',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-03-30T21:43:31.013Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-03-30T21:44:56.970Z',
              due_date_as_date: '2023-03-30T21:43:31.013Z',
              created_at_as_string: '2023-03-30',
              due_date_as_string: '2023-03-30',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-03-29T20:35:11.763Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-03-29T20:35:48.964Z',
              due_date_as_date: '2023-03-29T20:35:11.763Z',
              created_at_as_string: '2023-03-29',
              due_date_as_string: '2023-03-29',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-03-29T16:00:17.240Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-03-29T16:01:26.801Z',
              due_date_as_date: '2023-03-29T16:00:17.240Z',
              created_at_as_string: '2023-03-29',
              due_date_as_string: '2023-03-29',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-03-28T18:16:55.117Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-03-28T18:18:33.258Z',
              due_date_as_date: '2023-03-28T18:16:55.117Z',
              created_at_as_string: '2023-03-28',
              due_date_as_string: '2023-03-28',
            },
            {
              doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
              due_date: '2023-03-13T18:42:07.235Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-03-13T18:43:21.950Z',
              due_date_as_date: '2023-03-13T18:42:07.235Z',
              created_at_as_string: '2023-03-13',
              due_date_as_string: '2023-03-13',
            },
          ],
        },

        'Haywood Hall': {
          doctor_id: 'Vd7UEMgOPcdkpc2nUvfr9EB8qNv1',
          count: 3,
          all_tickets: [
            {
              doctor_id: 'Vd7UEMgOPcdkpc2nUvfr9EB8qNv1',
              due_date: '2023-02-10T23:57:23.287Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-02-10T23:59:25.305Z',
              due_date_as_date: '2023-02-10T23:57:23.287Z',
              created_at_as_string: '2023-02-10',
              due_date_as_string: '2023-02-10',
            },
            {
              doctor_id: 'Vd7UEMgOPcdkpc2nUvfr9EB8qNv1',
              due_date: '2023-02-10T23:55:55.084Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-02-10T23:56:52.329Z',
              due_date_as_date: '2023-02-10T23:55:55.084Z',
              created_at_as_string: '2023-02-10',
              due_date_as_string: '2023-02-10',
            },
            {
              doctor_id: 'Vd7UEMgOPcdkpc2nUvfr9EB8qNv1',
              due_date: '2023-02-10T23:44:58.828Z',
              status: 'not_started',
              firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
              created_at: '2023-02-10T23:53:31.008Z',
              due_date_as_date: '2023-02-10T23:44:58.828Z',
              created_at_as_string: '2023-02-10',
              due_date_as_string: '2023-02-10',
            },
          ],
        },
      },
      currentShowingProviderTasksLineChartInfo: [
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-05-01T22:42:33.618Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-05-01T22:43:56.894Z',
          due_date_as_date: '2023-05-01T22:42:33.618Z',
          created_at_as_string: '2023-05-01',
          due_date_as_string: '2023-05-01',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-05-01T18:41:13.720Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-05-01T18:42:32.663Z',
          due_date_as_date: '2023-05-01T18:41:13.720Z',
          created_at_as_string: '2023-05-01',
          due_date_as_string: '2023-05-01',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-05-01T17:27:21.815Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-05-01T17:28:53.370Z',
          due_date_as_date: '2023-05-01T17:27:21.815Z',
          created_at_as_string: '2023-05-01',
          due_date_as_string: '2023-05-01',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-05-01T17:13:46.751Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-05-01T17:15:52.829Z',
          due_date_as_date: '2023-05-01T17:13:46.751Z',
          created_at_as_string: '2023-05-01',
          due_date_as_string: '2023-05-01',
        },
        {
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-04-20T18:37:47.424Z',
          due_date: '2023-04-21T18:37:47.424Z',
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          status: 'not_started',
          due_date_as_date: '2023-04-21T18:37:47.424Z',
          created_at_as_string: '2023-04-20',
          due_date_as_string: '2023-04-21',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-04-18T20:37:12.711Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-04-18T20:38:49.849Z',
          due_date_as_date: '2023-04-18T20:37:12.711Z',
          created_at_as_string: '2023-04-18',
          due_date_as_string: '2023-04-18',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-04-07T23:20:40.598Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-04-07T23:23:22.349Z',
          due_date_as_date: '2023-04-07T23:20:40.598Z',
          created_at_as_string: '2023-04-07',
          due_date_as_string: '2023-04-07',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-04-07T17:52:22.096Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-04-07T17:53:53.304Z',
          due_date_as_date: '2023-04-07T17:52:22.096Z',
          created_at_as_string: '2023-04-07',
          due_date_as_string: '2023-04-07',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-04-07T15:53:30.339Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-04-07T15:54:28.258Z',
          due_date_as_date: '2023-04-07T15:53:30.339Z',
          created_at_as_string: '2023-04-07',
          due_date_as_string: '2023-04-07',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-04-06T16:34:08.505Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-04-06T16:35:10.837Z',
          due_date_as_date: '2023-04-06T16:34:08.505Z',
          created_at_as_string: '2023-04-06',
          due_date_as_string: '2023-04-06',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-03-30T21:43:31.013Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-03-30T21:44:56.970Z',
          due_date_as_date: '2023-03-30T21:43:31.013Z',
          created_at_as_string: '2023-03-30',
          due_date_as_string: '2023-03-30',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-03-29T20:35:11.763Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-03-29T20:35:48.964Z',
          due_date_as_date: '2023-03-29T20:35:11.763Z',
          created_at_as_string: '2023-03-29',
          due_date_as_string: '2023-03-29',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-03-29T16:00:17.240Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-03-29T16:01:26.801Z',
          due_date_as_date: '2023-03-29T16:00:17.240Z',
          created_at_as_string: '2023-03-29',
          due_date_as_string: '2023-03-29',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-03-28T18:16:55.117Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-03-28T18:18:33.258Z',
          due_date_as_date: '2023-03-28T18:16:55.117Z',
          created_at_as_string: '2023-03-28',
          due_date_as_string: '2023-03-28',
        },
        {
          doctor_id: 'JG0p4F2cBIgs9kwfwDXnNiPXVNQ2',
          due_date: '2023-03-13T18:42:07.235Z',
          status: 'not_started',
          firebase_id: 'SIJ1P0oXzAVFRejPigG3DJyGrTu2',
          created_at: '2023-03-13T18:43:21.950Z',
          due_date_as_date: '2023-03-13T18:42:07.235Z',
          created_at_as_string: '2023-03-13',
          due_date_as_string: '2023-03-13',
        },
      ],
    }
    this.getInbox = this.getInbox.bind(this)
    this.getOutbox = this.getOutbox.bind(this)
    this.onModalHide = this.onModalHide.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.onSendPressed = this.onSendPressed.bind(this)
  }

  getDataFromApiAndSetState = (apiEndpoint, stateName) => {
    // BUG: This might be a bug later when the code gets changed for realtime updates
    // if (this.state.stateName !== 'nil') {
    //   let stringStateName = stateName.toString()
    //   let x = [stateName]
    //   console.log('this.state.stateName', this.state.x)
    //   console.log(
    //     `The state ${stateName} is already set, so not fetching again`,
    //   )
    //   return
    // }
    axios
      .get(apiEndpoint)
      .then((res) => {
        // console.log('res.data', res.data)
        console.log(
          `Fetched data from ${apiEndpoint} and set ${stateName} to ${res.data.data}`,
        )
        this.setState({ [stateName]: res.data.data }) // Nice hack for this
        // console.log('The states are:', this.state)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getAllData = () => {
    // const TEST_BACKEND_URL = 'http://localhost:15000'
    const TEST_BACKEND_URL = Config.BACKEND_URL
    // this.setState({ loading: true })
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/appointments-by-users',
      'appointmentsByUsers',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/total-app-users',
      'totalNumberOfAppUsers',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/total-clinical-patients',
      'totalClinicalPatients',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v2/admin/product-analytics/avg-clinician-seen',
      'avgCliniciansSeen',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/avg-no-diagnostic-codes',
      'numberOfDiagnosticCodes',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/avg-no-medication',
      'numberOfMedications',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/avg-no-appointments',
      'numberOfAppointments',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/avg-no-coaching-visits',
      'numberOfCoachingVisits',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL +
        '/v1/admin/product-analytics/avg-no-referral-requested',
      'numberOfReferralRequested',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/appointments-no-show',
      'noAppointmentsNoShow',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL +
        '/v1/admin/product-analytics/percent-patients-followed-up',
      'percentOfPatientsFollowedUp',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/percent-shr-factor', // shr = social health risk
      'percentOfPatientsWithSocialHealthRiskFactor',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL +
        '/v1/admin/product-analytics/percent-mental-health-issue',
      'percentOfPatientsWithMentalHealthIssue',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL +
        '/v1/admin/product-analytics/premium-users-distribution',
      'numberOfPremiumUsers',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/patients-payment-cash',
      'numberOfPatientsWhoPayByCash',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/stripe/v1/payment-list',
      // 'allPaymentData',
      'paymentData',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL +
        '/v1/admin/product-analytics/cancelled-appointments-chart',
      'noCancelledAppointments',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL +
        '/v1/admin/product-analytics/avg-revenue-per-appointment',
      'avgRevenuePerAppointment',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/total-appointments-chart',
      'totalAppointments',
    )
    this.getDataFromApiAndSetState(
      TEST_BACKEND_URL + '/v1/admin/product-analytics/daily-active-users',
      'dailyActiveUsers',
    )
    this.setState({ loading: false })
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    // this.getInbox()
    // this.preparePatientList()
    this.getAllData()
    this.setState({ loading: false })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.paymentData !== prevState.paymentData) {
      let totalBookedRevenue = this.sum(this.state.paymentData, 'total')
      totalBookedRevenue = totalBookedRevenue.toFixed(2)
      let incRevenue = this.sum(this.state.paymentData, 'aila_inc')
      incRevenue = incRevenue.toFixed(2)
      let pcRevenue = this.sum(this.state.paymentData, 'pc_inc')
      pcRevenue = pcRevenue.toFixed(2)
      let allAccessRevenue = this.sum(this.state.paymentData, 'all_access')
      allAccessRevenue = allAccessRevenue.toFixed(2)
      let coachingMembershipRevenue = this.sum(
        this.state.paymentData,
        'membership',
      )
      coachingMembershipRevenue = coachingMembershipRevenue.toFixed(2)
      let recurringRevenue = this.sum(this.state.paymentData, 'appointment')
      recurringRevenue = recurringRevenue.toFixed(2)
      this.setState({ totalBookedRevenue })
      this.setState({ incRevenue })
      this.setState({ pcRevenue })
      this.setState({ allAccessRevenue })
      this.setState({ coachingMembershipRevenue })
      this.setState({ recurringRevenue })
    }
  }

  async preparePatientList(data) {
    const { patientList } = this.props
    if (!patientList) return
    let patientListData = data || patientList
    const list = []
    Object.keys(patientListData).forEach((rpmColor) => {
      const patientsColorList = patientListData[rpmColor]
      patientsColorList.forEach((patient) => {
        list.push({
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.uid,
        })
      })
    })

    this.setState({ patientListOptions: list })
  }

  //   getPatientList() {
  //     // this.setState({loading: true})
  //     const { token, adminId, firebaseUser } = this.props
  //     const self = this // Why is this being done?
  //     const url =
  //       `${Config.BACKEND_URL}doctor/patients/list` +
  //       (self.state.superUser ? '?access=admin' : '')
  //     axios({
  //       method: 'get',
  //       url,
  //       headers: { x_doctor_id: adminId, Authorization: `JWT ${token}` },
  //     })
  //       .then(function (response) {
  //         // handle success
  //         self.setState({ loading: false })
  //         if (response.status === 200) {
  //           const { data } = response
  //           let total = []
  //           data &&
  //             Object.keys(data).forEach((color) => {
  //               total = total.concat(data[color])
  //             })
  //           self.props.addPatientList(data)
  //           self.preparePatientList(data)
  //         }
  //       })
  //       .catch(function (error) {
  //         // handle error
  //         console.log('error when getting patient list', error, token)
  //         self.setState({ loading: false })
  //       })
  //   }

  //GET ALL SRFAX INBOX FAXES
  getInbox(archive) {
    alert('Running UNNECESSARY FUNCTION getInbox')
    return
    const { token, mongoUser } = this.props
    const { selectedTab } = this.state
    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: Config.CHANGE_BACKEND_URL + `srfax/inbox`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log('INBOX:::', data)
        selectedTab === 'Inbox'
          ? this.setState(
              {
                faxList: data.filter(
                  (item) =>
                    !item.hasOwnProperty('archive') || item?.archive === false,
                ),
              },
              () => this.setState({ loading: false }),
            )
          : this.setState(
              {
                faxList: data.filter((item) => item.archive === true),
              },
              () => this.setState({ loading: false }),
            )

        // if (archive) {
        //   this.setState({ faxList: data }, () =>
        //     this.setState({ loading: false }),
        //   )
        // } else {
        //   this.setState(
        //     {
        //       faxList: data.filter(
        //         (item) =>
        //           !item.hasOwnProperty('archive') || item?.archive === false,
        //       ),
        //     },
        //     () => this.setState({ loading: false }),
        //   )
        // }
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        this.setState({ loading: false })
        toast.error('Could not fetch Faxes')
      })
  }

  //GET ALL SRFAX OUTBOX FAXES
  getOutbox() {
    alert('Running UNNECESSARY FUNCTION getOutbox')
    return
    const { token, mongoUser } = this.props

    this.setState({ loading: true })
    let config = {
      method: 'get',
      url: Config.CHANGE_BACKEND_URL + `srfax/outbox`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log('OUTBOX::::', data.Result)
        this.setState({ outboxList: data.Result }, () =>
          this.setState({ loading: false }),
        )
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        this.setState({ loading: false })
        toast.error('Could not fetch Faxes')
      })
  }

  renderFax = (FileName, direction) => {
    const { token, mongoUser } = this.props

    this.setState({ modalLoading: true })
    let config = {
      method: 'get',
      url:
        Config.CHANGE_BACKEND_URL +
        `srfax/retrieve-fax?sFaxFileName=${FileName}&sDirection=${direction}`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
    }
    axios(config)
      .then(({ data }) => {
        // console.log(data.Result)
        this.setState(
          {
            testPDF: data.Result,
            selectedFile: data.Result,
            modalFileName: FileName,
          },
          () => {
            this.setState({ modalLoading: false })
          },
        )
      })
      .catch((err) => {
        console.log('Error getting Faxes content for the Patient ', err)
        toast.error('Could not fetch Faxes')
      })
  }

  onModalHide() {
    this.setState({
      openDoc: false,
      sendModal: false,
      forwardFax: false,
      modalSchedule: new Date(),
      modalError: false,
      testPDF: '',
      modalFileName: '',
      modalSenderEmail: '',
      modalRecieverFax: '',
      // Initially, no file is selected
      selectedFile: null,
    })
  }
  // On file select (from the pop up)
  onFileChange(event) {
    // Update the state
    // console.log(event.target.files)
    if (event.target.files[0]) {
      //   console.log(event.target.files[0])
      this.setState({ modalFileName: event.target.files[0].name })

      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })

      toBase64(event.target.files[0])
        .then((data) => {
          // let result = data.substring(data.indexOf(', ') + 1)
          let result = data.split(',')[1]
          //   console.log(result)
          this.setState({ selectedFile: result })
        })
        .catch((err) => console.log(err))
      // console.log(await toBase64(event.target.files[0]))
    } else {
      this.setState({ modalFileName: '', selectedFile: null })
    }
  }

  onSendPressed() {
    alert('Running UNNECESSARY FUNCTION onSendPressed')
    return
    const { token, mongoUser } = this.props
    let faxNumber = this.state.modalRecieverFax.replaceAll('-', '')
    if (faxNumber?.length < 10) {
      console.log(faxNumber)
      toast.error('Please enter correct fax number')
      return
    }
    let result = {
      sSenderEmail: this.state.modalSenderEmail,
      sToFaxNumber: '1' + faxNumber,
      sFileName_1: this.state.modalFileName,
      sFileContent_1: this.state.selectedFile,
    }

    // console.log('RESULT:::', result)

    let config = {
      method: 'post',
      url: !this.state.forwardFax
        ? Config.CHANGE_BACKEND_URL + `srfax/outbox`
        : Config.CHANGE_BACKEND_URL +
          `srfax/forward?sDirection=${this.state.showInbox}`,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: mongoUser.firebase_id,
      },
      data: result,
    }
    axios(config)
      .then(({ data }) => {
        console.log('SRFAX SEND RES::::', data)
        toast.success('Fax queued successfully')
        this.getInbox()
        this.getOutbox()
        this.onModalHide()
      })
      .catch((err) => {
        console.log('Error getting Faxes for the Patient ', err)
        toast.error('Could not send Fax')
      })
  }

  sendFaxModal() {
    return alert('NO MODAL LUL')
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div
          style={{
            height: '90%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            onClick={() => this.onModalHide()}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            {this.state.forwardFax ? `Forward Fax` : `Send Fax`}
          </h4>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Sender Email: </p>
            <input
              placeholder={'Please enter senders email address'}
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalSenderEmail}
              onChange={(e) =>
                this.setState({ modalSenderEmail: e.target.value })
              }
            />
          </div>

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Recipient Fax: </p>
            <input
              placeholder={'Please enter recievers fax number'}
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalRecieverFax}
              onChange={(e) =>
                this.setState({ modalRecieverFax: e.target.value })
              }
            />
          </div>
          {/* <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>Schedule Date : </p>
            <DateTimePicker
              onChange={(val) => {
                this.setState({ modalSchedule: val, modalError: false })
              }}
              value={this.state.modalSchedule}
              className={Styles.addManualSelectInput}
            />
          </div> */}
          {!this.state.forwardFax && (
            <div className={Styles.addManualRow}>
              <p className={Styles.addManualQuestion}>Select file: </p>
              <input
                placeholder="Slect File to be Faxed"
                className={Styles.addManualSelectInput}
                type="file"
                onChange={this.onFileChange}
              />
            </div>
          )}

          <div className={Styles.addManualRow}>
            <p className={Styles.addManualQuestion}>File Selected: </p>
            <input
              disabled
              className={Styles.addManualTextInput}
              type="text"
              value={this.state.modalFileName}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => this.onSendPressed()}
            className={GlobalStyles.button}
            // disabled={this.state.modalLoading}
            style={{ width: 200, position: 'absolute', bottom: 20 }}
            variant="primary"
          >
            Send Fax
          </Button>
        </div>
      </div>
    )
  }

  renderDocuments(showInbox, selectedTab) {
    alert('Running UNNECESSARY FUNCTION renderDocuments')
    return
    const { token, mongoUser } = this.props
    const faxes = showInbox ? this.state.faxList : this.state.outboxList
    // const faxes =
    //   selectedTab === 'Inbox'
    //     ? this.state.faxList.filter(
    //         (item) =>
    //           !item.hasOwnProperty('archive') || item?.archive === false,
    //       )
    //     : selectedTab === 'Outbox'
    //     ? this.state.outboxList
    //     : this.state.faxList.filter((item) => item.archive === true)

    const elements = faxes.map((fax, index) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '10px 0px',
        }}
      >
        <p
          style={{
            width: '5%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {index + 1}
        </p>
        <p
          style={{
            width: '17.5%',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {this.state.showInbox
            ? moment(fax.Date).format('lll')
            : moment(fax.DateSent).format('lll')}
        </p>

        {!this.state.showInbox && (
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {fax.SenderEmail}
          </p>
        )}

        {this.state.showInbox ? (
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {fax.CallerID} / {fax.RemoteID}
          </p>
        ) : (
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {fax.ToFaxNumber}
          </p>
        )}

        <p
          style={{
            width: '5%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {fax.Pages}
        </p>

        <p
          style={{
            width: '10%',
            textAlign: 'center',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {this.state.showInbox ? fax.ReceiveStatus : fax.SentStatus}
        </p>

        <FontAwesomeIcon
          onClick={() => {
            this.setState({ openDoc: true })
            if (fax.pdf)
              this.setState({
                testPDF: fax.pdf,
                selectedFile: fax,
                modalFileName: fax.FileName,
              })
            else this.renderFax(fax.FileName, this.state.showInbox)
          }}
          icon={faEye}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '10%',
            cursor: 'pointer',
          }}
        />
        <FontAwesomeIcon
          onClick={() => {
            this.setState({ forwardFax: true, sendModal: true })
            this.renderFax(fax.FileName, this.state.showInbox)
          }}
          icon={faShare}
          style={{
            color: Constants.primaryTheme,
            fontSize: 20,
            width: '10%',
            cursor: 'pointer',
          }}
        />
        {this.state.showInbox && (
          <div style={{ width: '17.5%' }}>
            {/* lab, prior auth, e-consult, patient request, pathology, imaging, prescriptions, referral, health history, other */}
            <Select
              placeholder="select fax type"
              inputId="faxtype"
              options={[
                { label: 'Lab', value: 'Lab' },
                { label: 'Prior auth', value: 'Prior auth' },
                { label: 'E-consult', value: 'E-consult' },
                { label: 'Patient request', value: 'Patient request' },
                { label: 'Pathology', value: 'Pathology' },
                { label: 'Imaging', value: 'Imaging' },
                { label: 'Prescriptions', value: 'Prescriptions' },
                { label: 'Referral', value: 'Referral' },
                { label: 'Health history', value: 'Health history' },
                { label: 'Other', value: 'Other' },
              ]}
              value={
                fax.faxType ? { label: fax.faxType, value: fax.faxType } : []
              }
              isSearchable
              autoBlur
              onChange={(val) => {
                let config = {
                  method: 'put',
                  url: Config.BACKEND_URL + `srfax/archive?fax_id=${fax._id}`,
                  headers: {
                    Authorization: `JWT ${token}`,
                    x_firebase_id: mongoUser.firebase_id,
                  },
                  data: {
                    faxType: val.value,
                    // fax_id: fax._id,
                  },
                }
                axios(config)
                  .then(({ data }) => {
                    let tempFaxList = [...this.state.faxList]
                    tempFaxList[index].faxType = val.value
                    this.setState({ faxList: tempFaxList }, () =>
                      this.setState({ loading: false }),
                    )
                    if (val.value === 'Referral') {
                      window.open(
                        'https://portal.ailahealth.com/wix/aila/provider-referral',
                        '_blank',
                      )
                    }
                  })
                  .catch((err) => {
                    console.log('Error getting Faxes for the Patient ', err)
                    toast.error('Problem while assigning the fax')
                  })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        )}

        {this.state.showInbox && (
          <FontAwesomeIcon
            onClick={() => {
              let config = {
                method: 'put',
                url: Config.BACKEND_URL + `srfax/archive?fax_id=${fax._id}`,
                headers: {
                  Authorization: `JWT ${token}`,
                  x_firebase_id: mongoUser.firebase_id,
                },
                data: {
                  archive: !fax.archive,
                  // fax_id: fax._id,
                },
              }
              axios(config)
                .then(({ data }) => {
                  let tempFaxList = [...this.state.faxList]
                  tempFaxList[index].archive = !tempFaxList[index]?.archive

                  selectedTab === 'Inbox'
                    ? this.setState(
                        {
                          faxList: tempFaxList.filter(
                            (item) =>
                              !item.hasOwnProperty('archive') ||
                              item?.archive === false,
                          ),
                        },
                        () => this.setState({ loading: false }),
                        toast.success('Fax moved to Archive'),
                      )
                    : this.setState(
                        {
                          faxList: tempFaxList.filter(
                            (item) => item.archive === true,
                          ),
                        },
                        () => this.setState({ loading: false }),
                        toast.success('Fax unarchived'),
                      )

                  // this.setState(
                  //   { faxList: tempFaxList },
                  //   () => this.setState({ loading: false }),
                  //   toast.success('Fax moved to Archive'),
                  // )
                })
                .catch((err) => {
                  console.log('Error getting Faxes for the Patient ', err)
                  toast.error('Problem while assigning the fax')
                })
            }}
            icon={selectedTab === 'Inbox' ? faCheckCircle : faMinusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              width: '10%',
              cursor: 'pointer',
            }}
          />
        )}

        {this.state.showInbox && (
          <div style={{ width: '17.5%' }}>
            <Select
              placeholder="select patient"
              inputId="name"
              options={this.state.patientListOptions}
              value={
                fax.patient_id
                  ? this.state.patientListOptions.find(
                      (patient) => patient.value === fax.patient_id,
                    )
                  : []
              }
              isSearchable
              autoBlur
              onChange={(val) => {
                let config = {
                  method: 'put',
                  url: Config.BACKEND_URL + `srfax/patient`,
                  headers: {
                    Authorization: `JWT ${token}`,
                    x_firebase_id: mongoUser.firebase_id,
                  },
                  data: {
                    patient_id: val.value,
                    fax_id: fax._id,
                  },
                }
                axios(config)
                  .then(({ data }) => {
                    let tempFaxList = [...this.state.faxList]
                    tempFaxList[index].patient_id = val.value
                    this.setState({ faxList: tempFaxList }, () =>
                      this.setState({ loading: false }),
                    )
                  })
                  .catch((err) => {
                    console.log('Error getting Faxes for the Patient ', err)
                    toast.error('Problem while assigning the fax')
                  })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        )}
      </div>
    ))
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            borderBottom: '1px solid gray',
          }}
        >
          <h5
            style={{
              width: '5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Sl No.
          </h5>
          <h5
            style={{
              width: '17.5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            {this.state.showInbox ? `Imported Date` : `Date Sent`}
          </h5>
          {!this.state.showInbox && (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Sender Email
            </h5>
          )}
          {this.state.showInbox ? (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              From FAX / Remote ID
            </h5>
          ) : (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Recipient Fax
            </h5>
          )}
          <h5
            style={{
              width: '5%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Pages
          </h5>
          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Status
          </h5>

          {/* {!this.state.showInbox && (
            <h5
              style={{
                width: '30%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Reciever Fax
            </h5>
          )} */}

          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            View
          </h5>
          <h5
            style={{
              width: '10%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: Constants.darkGray,
            }}
          >
            Forward
          </h5>
          {this.state.showInbox && (
            <h5
              style={{
                width: '17.5%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Fax Type
            </h5>
          )}
          {this.state.showInbox && (
            <h5
              style={{
                width: '10%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              {selectedTab === 'Inbox' ? 'Archive' : 'Unarchive'}
              {/* Archive */}
            </h5>
          )}
          {this.state.showInbox && (
            <h5
              style={{
                width: '17.5%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: Constants.darkGray,
              }}
            >
              Assign
            </h5>
          )}
        </div>

        {elements}
      </div>
    )
  }

  setStartDate = (dataStartDate) => {
    this.setState({ dataStartDate: dataStartDate })
    if (this.state.dataEndDate) {
      let newRenderData = this.state.allPaymentData.filter((payment) => {
        // console.log(
        //   'Comparing',
        //   dataStartDate,
        //   payment.date,
        //   this.state.dataEndDate,
        //   moment(payment.date, 'YYYY-MM-DD') >=
        //     moment(dataStartDate, 'YYYY-MM-DD') &&
        //     moment(payment.date, 'YYYY-MM-DD') <=
        //       moment(this.state.dataEndDate, 'YYYY-MM-DD'),
        // )
        return (
          moment(payment.date, 'YYYY-MM-DD') >=
            moment(dataStartDate, 'YYYY-MM-DD') &&
          moment(payment.date, 'YYYY-MM-DD') <=
            moment(this.state.dataEndDate, 'YYYY-MM-DD')
        )
      })
      // console.log('newRenderData', newRenderData)
      this.setState({ paymentData: newRenderData })
    }
  }

  sum = (items, prop) => {
    return items.reduce(function (a, b) {
      return a + Number(b[prop])
    }, 0)
  }

  setEndDate = (dataEndDate) => {
    this.setState({ dataEndDate: dataEndDate })
    if (this.state.dataStartDate) {
      let newRenderData = this.state.allPaymentData.filter((payment) => {
        // console.log(
        //   'Comparing',
        //   this.state.dataStartDate,
        //   payment.date,
        //   endDate,
        //   moment(payment.date, 'YYYY-MM-DD') >=
        //     moment(this.state.dataStartDate, 'YYYY-MM-DD') &&
        //     moment(payment.date, 'YYYY-MM-DD') <= moment(endDate, 'YYYY-MM-DD'),
        // )
        return (
          moment(payment.date, 'YYYY-MM-DD') >=
            moment(this.state.dataStartDate, 'YYYY-MM-DD') &&
          moment(payment.date, 'YYYY-MM-DD') <=
            moment(dataEndDate, 'YYYY-MM-DD')
        )
      })
      // console.log('newRenderData', newRenderData)
      this.setState({ paymentData: newRenderData })
      /*
        {
          date: '2023-04-01',
          total: '2383.18',
          aila_inc: '529.11',
          pc_inc: '1854.07',
          all_access: '2060.35',
          membership: '271.03',
          appointment: '51.80',
        },
        totalBookedRevenue: 30015.69,
        pcRevenue: 14554.34,
        incRevenue: 15461.35,
        allAccessRevenue: 11513.78,
        coachingMembershipRevenue: 11666.43,
        recurringRevenue: 8605.24,
      */
      // Take sum of the total field in newRenderData
      let totalBookedRevenue = this.sum(newRenderData, 'total')
      //   console.log(
      //     'totalBookedRevenue',
      //     totalBookedRevenue,
      //     typeof totalBookedRevenue,
      //   )
      totalBookedRevenue = totalBookedRevenue.toFixed(2)
      let incRevenue = this.sum(newRenderData, 'aila_inc')
      //   console.log(
      //     'IncRevenue',
      //     incRevenue,
      //     incRevenue.toPrecision(2),
      //     typeof incRevenue,
      //   )
      incRevenue = incRevenue.toFixed(2)
      let pcRevenue = this.sum(newRenderData, 'pc_inc')
      pcRevenue = pcRevenue.toFixed(2)
      let allAccessRevenue = this.sum(newRenderData, 'all_access')
      allAccessRevenue = allAccessRevenue.toFixed(2)
      let coachingMembershipRevenue = this.sum(newRenderData, 'membership')
      coachingMembershipRevenue = coachingMembershipRevenue.toFixed(2)
      let recurringRevenue = this.sum(newRenderData, 'appointment')
      recurringRevenue = recurringRevenue.toFixed(2)
      this.setState({ totalBookedRevenue })
      this.setState({ incRevenue })
      this.setState({ pcRevenue })
      this.setState({ allAccessRevenue })
      this.setState({ coachingMembershipRevenue })
      this.setState({ recurringRevenue })
      // console.log('sumTotalBookedRevenue', sumTotalBookedRevenue)
    }
  }

  // renderTestAnalytics1 = () => {
  //   return (
  //     <div className={styles.divider}>
  //       <PaymentTab
  //         parentCallback={this.handleCommunicationsCallback.bind(this)}
  //       />
  //     </div>
  //   )
  // }

  renderTestAnalytics1 = () => {
    return (
      <span>
        <div style={{ width: '100%', fontWeight: 'normal' }}>
          <DatePicker
            selected={null}
            onChange={(date) => {
              let x = date ? moment(date).format('ll') : ''
              console.log('date:', x)
            }}
            placeholderText="Select Date"
          />
        </div>
      </span>
    )
  }

  renderTestAnalytics = () => {
    return (
      <div>
        <h1 style={{ textAlign: 'center' }}>Daily Memberships</h1>
        {/* <ResponsiveContainer width="100%" height="75vh"> */}
        <BarChart
          width={1000}
          height={500}
          data={[
            {
              name: 'Page A',
              uv: 4000,
              pv: 2400,
              amt: 2400,
            },
            {
              name: 'Page B',
              uv: 3000,
              pv: 1398,
              amt: 2210,
            },
            {
              name: 'Page C',
              uv: 2000,
              pv: 9800,
              amt: 2290,
            },
            {
              name: 'Page D',
              uv: 2780,
              pv: 3908,
              amt: 2000,
            },
            {
              name: 'Page E',
              uv: 1890,
              pv: 4800,
              amt: 2181,
            },
            {
              name: 'Page F',
              uv: 2390,
              pv: 3800,
              amt: 2500,
            },
            {
              name: 'Page G',
              uv: 3490,
              pv: 4300,
              amt: 2100,
            },
          ]}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="uv" fill="#8884d8" />
        </BarChart>
        {/* </ResponsiveContainer> */}
        <h1 style={{ textAlign: 'center' }}>Members by Type</h1>
        {/* <ResponsiveContainer width="100%" height="75vh"> */}
        <BarChart
          width={1000}
          height={500}
          data={[
            {
              name: 'Page A',
              uv: 4000,
              pv: 2400,
              amt: 2400,
            },
            {
              name: 'Page B',
              uv: 3000,
              pv: 1398,
              amt: 2210,
            },
            {
              name: 'Page C',
              uv: 2000,
              pv: 9800,
              amt: 2290,
            },
            {
              name: 'Page D',
              uv: 2780,
              pv: 3908,
              amt: 2000,
            },
            {
              name: 'Page E',
              uv: 1890,
              pv: 4800,
              amt: 2181,
            },
            {
              name: 'Page F',
              uv: 2390,
              pv: 3800,
              amt: 2500,
            },
            {
              name: 'Page G',
              uv: 3490,
              pv: 4300,
              amt: 2100,
            },
          ]}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="uv" stackId={'a'} fill="#8884d8" />
          <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
        </BarChart>
      </div>
    )
  }

  renderAppointmentsAnalytics = () => {
    return (
      <div>
        <div>
          <h1 style={{ textAlign: 'center' }}>Visit Volume</h1>
          <ComposedChart
            width={1000}
            height={500}
            data={this.state.totalAppointments}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Area
              type="monotone"
              dataKey="amt"
              fill="#8884d8"
              stroke="#8884d8"
            /> */}
            <Bar dataKey="count" barSize={20} fill="#413ea0" />
            {/* <Line type="monotone" dataKey="uv" stroke="#ff7300" /> */}
            {/* <Scatter dataKey="cnt" fill="red" /> */}
          </ComposedChart>
        </div>
        <div>
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Appointments No show
          </h1>
          <LineChart
            width={1000}
            height={500}
            data={this.state.noAppointmentsNoShow}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="count"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </div>
        <div>
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Appointments Cancelled
          </h1>
          <LineChart
            width={1000}
            height={500}
            data={this.state.noCancelledAppointments}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="count"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
          </LineChart>
        </div>
        <div>
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Average Revenue Per Appointment
          </h1>
          <LineChart
            width={1000}
            height={500}
            data={this.state.averageRevenuePerAppointment}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis dataKey="total" type="number" domain={[0, 1000]} />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="avg"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="total" stroke="#82ca9d" />
          </LineChart>
        </div>
      </div>
    )
  }

  renderBasicAnalytics = () => {
    const SearchComponent = () => {
      return (
        <div className={Styles.row}>
          {/* <p className={Styles.rowLabel}>Search</p> */}
          <div style={{ width: '30%' }}>
            <input
              placeholder="Search metric name"
              className={Styles.textInput}
              style={{ width: '100%' }}
              type="text"
              value={this.state.searchQueryString}
              onChange={(e) => {
                this.setSearchQueryString(e.target.value)
              }}
            />
          </div>
          <Button
            onClick={
              () => {
                console.log(
                  'this.state.searchQueryString',
                  this.state.searchQueryString,
                )
                // setPatientData([])
                // setAllPatientData([])
                // getUserData()
              }
              // filterByNameEmail(searchQueryString)
            }
            className={GlobalStyles.button}
            style={{
              width: 100,
              marginLeft: 20,
            }}
            variant="primary"
          >
            Go
          </Button>
          <Button
            onClick={
              () => console.log('Clearing the filter')
              //clearFilter()
            }
            className={GlobalStyles.button}
            style={{
              width: 100,
              marginLeft: 20,
            }}
            variant="primary"
          >
            Clear
          </Button>
        </div>
      )
    }
    return (
      <div>
        <div
          style={{
            width: 1000,
            margin: 'auto',
          }}
        >
          <SearchComponent />
          <div className={Styles1.inputDiv}>
            <p className={Styles1.inputQuestion}>Start Date:</p>
            <div style={{ width: 160, margin: '0px 20px' }}>
              <input
                placeholder={'Start Date'}
                className={Styles1.textInput}
                type="date"
                // min={moment(new Date()).format('YYYY-MM-DD')}
                // value={moment(this.state.dataStartDate).format(
                //   'YYYY-MM-DD',
                // )}
                onChange={(e) => this.setStartDate(e.target.value)}
              />
            </div>
            {/* </div> */}
            {/* <div className={Styles1.inputDiv}> */}
            <p className={Styles1.inputQuestion}>End Date:</p>
            <div style={{ width: 160, margin: '0px 20px' }}>
              <input
                placeholder={'End Date'}
                className={Styles1.textInput}
                type="date"
                // min={moment(new Date()).format('YYYY-MM-DD')}
                // value={moment(this.state.dataStartDate).format(
                //   'YYYY-MM-DD',
                // )}
                onChange={(e) => this.setEndDate(e.target.value)}
              />
            </div>
          </div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Metric Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr key="0">
                <td>1</td>
                <td>Total number of users on the app</td>
                <td>{this.state.totalNumberOfAppUsers}</td>
              </tr>
              <tr key="1">
                <td>2</td>
                <td>Average number of clinicians seen/patient</td>
                <td>{this.state.avgCliniciansSeen}</td>
              </tr>
              <tr key="2">
                <td>3</td>
                <td>Average number of diagnostic codes</td>
                <td>{this.state.numberOfDiagnosticCodes}</td>
              </tr>
              <tr key="3">
                <td>4</td>
                <td>Average number of medications/patient</td>
                <td>{this.state.numberOfMedications}</td>
              </tr>
              <tr key="4">
                <td>5</td>
                <td>Average number of appointments/patients</td>
                <td>{this.state.numberOfAppointments}</td>
              </tr>
              <tr key="5">
                <td>6</td>
                <td>Total number of coaching visits</td>
                <td>{this.state.numberOfCoachingVisits}</td>
              </tr>
              <tr key="6">
                <td>7</td>
                <td>Average number of referral requested</td>
                <td>{this.state.numberOfReferralRequested}</td>
              </tr>
              <tr key="7">
                <td>8</td>
                <td>Percent of patients who followed up for second visit</td>
                <td>{this.state.percentOfPatientsFollowedUp}%</td>
              </tr>
              <tr key="8">
                <td>9</td>
                <td>Percent of patients with social health risk factor</td>
                <td>
                  {this.state.percentOfPatientsWithSocialHealthRiskFactor}%
                </td>
              </tr>
              <tr key="9">
                <td>10</td>
                <td>Total number of clinical patients on the app</td>
                <td>{this.state.totalClinicalPatients}</td>
              </tr>
              <tr key="10">
                <td>11</td>
                <td>Percent of patients with mental health issue</td>
                <td>{this.state.percentOfPatientsWithMentalHealthIssue}%</td>
              </tr>
              <tr key="11">
                <td>12</td>
                <td>Number of patients with memberships</td>
                <td>{this.state.numberOfPremiumUsers.membership}</td>
              </tr>
              <tr key="12">
                <td>13</td>
                <td>Number of patients with all access</td>
                <td>{this.state.numberOfPremiumUsers.allAccess}</td>
              </tr>
              <tr key="13">
                <td>14</td>
                <td>Churn rate</td>
                <td>{this.state.churnRate}%</td>
              </tr>
              {/* <tr key="14">
                      <td>15</td>
                      <td>Engagement rate</td>
                      <td>{this.state.engagementRate}%</td>
                    </tr> */}
              <tr key="15">
                <td>16</td>
                <td>Retention rate</td>
                <td>{this.state.retentionRate}%</td>
              </tr>
              {/* <tr key="16">
                      <td>17</td>
                      <td>Projected Revenue</td>
                      <td>{this.state.projectedRevenue}</td>
                    </tr> */}
              <tr key="17">
                <td>18</td>
                <td>Total booked revenue</td>
                <td>${this.state.totalBookedRevenue}</td>
              </tr>
              <tr key="18">
                <td>19</td>
                <td>PC revenue</td>
                <td>${this.state.pcRevenue}</td>
              </tr>
              <tr key="19">
                <td>20</td>
                <td>Inc revenue</td>
                <td>${this.state.incRevenue}</td>
              </tr>
              <tr key="20">
                <td>21</td>
                <td>All access revenue</td>
                <td>${this.state.allAccessRevenue}</td>
              </tr>
              <tr key="21">
                <td>22</td>
                <td>Coaching revenue</td>
                <td>${this.state.coachingMembershipRevenue}</td>
              </tr>
              <tr key="22">
                <td>23</td>
                <td>Recurring revenue</td>
                <td>${this.state.recurringRevenue}</td>
              </tr>
              <tr key="23">
                <td>24</td>
                <td>Number of patients who pay by cash</td>
                <td>{this.state.numberOfPatientsWhoPayByCash}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* <h1
          style={{
            textAlign: 'center',
            marginTop: '5%',
          }}
        >
          Distribution of number of clinicians seen per patient
        </h1>
        <div
          id="pieChart"
          style={{
            flex: 1,
            // alignSelf: 'center',
            width: '100%',
            //   overflow: 'hidden',
            height: '75vh',
            // height: '100%',
            display: 'block',
            margin: 'auto',
            // justifyContent: 'center',
            // alignItems: 'center',
            // center all elements in this div
            // textAlign: 'center',
            // alignItems: 'center',
            // display: 'flex',
          }}
        >
          <PieChart width={730} height={550}>
            <Pie
              data={this.state.pieChartData1}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              // fill={['#FFCE04', '#000000']}
              fill="#82ca9d"
              label
            />
            <Tooltip />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
        </div> */}
        {/* <div
          style={{
            //   width: 730,
            height: '50vh',
          }}
        >
          <h1
            style={{
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            Line Char of Payment data
          </h1>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={730}
              height={50}
              data={this.state.paymentData}
              // data={this.state.pieChartData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="date" />
              <YAxis dataKey="total" type="number" domain={[0, 4000]} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Line
                name="total"
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
              />
              <Line
                name="aila_inc"
                type="monotone"
                dataKey="aila_inc"
                stroke="#82ca9d"
              />
              <Line
                name="pc_inc"
                type="monotone"
                dataKey="pc_inc"
                stroke="red"
              />
              <Line
                name="all_access"
                type="monotone"
                dataKey="all_access"
                stroke="#000000"
              />
              <Line
                name="membership"
                type="monotone"
                dataKey="membership"
                stroke="green"
              />
              <Line
                name="appointment"
                type="monotone"
                dataKey="appointment"
                stroke="blue"
              />
              <Legend verticalAlign="top" height={40} />
            </LineChart>
          </ResponsiveContainer>
        </div> */}
      </div>
    )
  }

  renderUserAnalytics() {
    return (
      <div>
        <h1
          style={{
            width: 1500,
            textAlign: 'center',
            marginTop: '5%',
          }}
        >
          Distribution of number of doctors seen per patient
        </h1>
        <div
          id="pieChart"
          style={{
            flex: 1,
            // alignSelf: 'center',
            width: '100%',
            //   overflow: 'hidden',
            height: '75vh',
            // height: '100%',
            display: 'block',
            margin: 'auto',
            // justifyContent: 'center',
            // alignItems: 'center',
            // center all elements in this div
            // textAlign: 'center',
            // alignItems: 'center',
            // display: 'flex',
          }}
        >
          {/* Flex direction row */}
          <PieChart width={730} height={550}>
            <Pie
              data={this.state.pieChartData1}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              // fill={['#FFCE04', '#000000']}
              fill="#82ca9d"
              label
            />
            <Tooltip />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
          {/* <PieChart width={730} height={500}>
                      <Pie
                        data={this.state.pieChartData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={150}
                        fill="#82ca9d"
                      />
                      <Tooltip />
                      <Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="middle"
                      />
                    </PieChart> */}
          {/* <PieChart width={730} height={250}>
                      <Pie
                        data={this.state.pieChartData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={75}
                        fill="#82ca9d"
                      />
                      <Tooltip />
                      <Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="middle"
                      />
                    </PieChart> */}
        </div>
        <div
          style={{
            //   width: 730,
            height: '50vh',
          }}
        >
          {/* <LineChart
                      width={730}
                      height={250}
                      data={this.state.lineChartData}
                      // data={this.state.pieChartData}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="name" />
                      <YAxis dataKey="value" />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" height={36} />
                      <Line
                        name="pv of pages"
                        type="monotone"
                        dataKey="key"
                        stroke="#8884d8"
                      />
                      <Line
                        name="uv of pages"
                        type="monotone"
                        dataKey="value"
                        stroke="#82ca9d"
                      />
                    </LineChart> */}
          <h1
            style={{
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            Line Char of Payment data
          </h1>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={730}
              height={50}
              data={this.state.paymentData}
              // data={this.state.pieChartData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="date" />
              <YAxis dataKey="total" type="number" domain={[0, 4000]} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Line
                name="total"
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
              />
              <Line
                name="aila_inc"
                type="monotone"
                dataKey="aila_inc"
                stroke="#82ca9d"
              />
              <Line
                name="pc_inc"
                type="monotone"
                dataKey="pc_inc"
                stroke="red"
              />
              <Line
                name="all_access"
                type="monotone"
                dataKey="all_access"
                stroke="#000000"
              />
              <Line
                name="membership"
                type="monotone"
                dataKey="membership"
                stroke="green"
              />
              <Line
                name="appointment"
                type="monotone"
                dataKey="appointment"
                stroke="blue"
              />
              <Legend verticalAlign="top" height={40} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }

  renderAppAnalytics = () => {
    return (
      <div>
        <div
          style={{
            width: 1000,
            margin: 'auto',
          }}
        >
          {/* <SearchComponent /> */}
          {/* <div className={Styles1.inputDiv}>
            <p className={Styles1.inputQuestion}>Start Date:</p>
            <div style={{ width: 160, margin: '0px 20px' }}>
              <input
                placeholder={'Start Date'}
                className={Styles1.textInput}
                type="date"
                // min={moment(new Date()).format('YYYY-MM-DD')}
                // value={moment(this.state.dataStartDate).format(
                //   'YYYY-MM-DD',
                // )}
                onChange={(e) => this.setStartDate(e.target.value)}
              />
            </div>
            {/* </div> */}
          {/* <div className={Styles1.inputDiv}> }
            <p className={Styles1.inputQuestion}>End Date:</p>
            <div style={{ width: 160, margin: '0px 20px' }}>
              <input
                placeholder={'End Date'}
                className={Styles1.textInput}
                type="date"
                // min={moment(new Date()).format('YYYY-MM-DD')}
                // value={moment(this.state.dataStartDate).format(
                //   'YYYY-MM-DD',
                // )}
                onChange={(e) => this.setEndDate(e.target.value)}
              />
            </div>
          </div> */}
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Metric Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr key="0">
                <td>1</td>
                <td>DAU</td>
                <td>{this.state.dailyActiveUsers}</td>
              </tr>
              <tr key="1">
                <td>2</td>
                <td>MAU</td>
                <td>{this.state.monthlyActiveUsers}</td>
              </tr>
              <tr key="2">
                <td>3</td>
                <td>Retention Rate</td>
                <td>{this.state.retentionRate}%</td>
              </tr>
              <tr key="3">
                <td>4</td>
                <td>Churn Rate</td>
                <td>{this.state.churnRate}%</td>
              </tr>
              <tr key="4">
                <td>5</td>
                <td>Cost per acquisition</td>
                <td>{this.state.costPerAcquisition}</td>
              </tr>
              <tr key="5">
                <td>6</td>
                <td>Customer acquisition cost</td>
                <td>{this.state.customerAcquisitionCost}</td>
              </tr>
              <tr key="6">
                <td>7</td>
                <td>Lifetime value</td>
                <td>{this.state.lifetimeValue}</td>
              </tr>
              <tr key="7">
                <td>8</td>
                <td>Average revenue per user</td>
                <td>{this.state.averageRevenuePerUser}</td>
              </tr>
              <tr key="8">
                <td>9</td>
                <td>Monthly Recurring Revenue</td>
                <td>{this.state.monthlyRecurringRevenue}</td>
              </tr>
              <tr key="9">
                <td>10</td>
                <td>Return On Investment</td>
                <td>{this.state.returnOnInvestment}</td>
              </tr>
              <tr key="10">
                <td>11</td>
                <td>Trial to Subscription/Activation Rate</td>
                <td>{this.state.activationRate}</td>
              </tr>
              <tr key="11">
                <td>12</td>
                <td>Daily Sessions Per Daily Active User</td>
                <td>{'nil'}</td>
              </tr>
              <tr key="12">
                <td>13</td>
                <td>Stickiness</td>
                <td>{this.state.stickiness}</td>
              </tr>
              <tr key="13">
                <td>14</td>
                <td>Average Session Length</td>
                <td>{this.state.averageSessionLength}</td>
              </tr>
              {/* <tr key="14">
                    <td>15</td>
                    <td>Engagement rate</td>
                    <td>{this.state.engagementRate}%</td>
                  </tr> */}
              <tr key="15">
                <td>16</td>
                <td>Engagement rate</td>
                <td>{this.state.engagementRate}%</td>
              </tr>
              {/* <tr key="16">
                    <td>17</td>
                    <td>Projected Revenue</td>
                    <td>{this.state.projectedRevenue}</td>
                  </tr> */}
              {/* <tr key="17">
                <td>18</td>
                <td>Activations Rate</td>
                <td>${this.state.totalBookedRevenue}</td>
              </tr> */}
              {/* <tr key="18">
                <td>19</td>
                <td></td>
                <td>${this.state.pcRevenue}</td>
              </tr>
              <tr key="19">
                <td>20</td>
                <td>Inc revenue</td>
                <td>${this.state.incRevenue}</td>
              </tr>
              <tr key="20">
                <td>21</td>
                <td>All access revenue</td>
                <td>${this.state.allAccessRevenue}</td>
              </tr>
              <tr key="21">
                <td>22</td>
                <td>Coaching revenue</td>
                <td>${this.state.coachingMembershipRevenue}</td>
              </tr>
              <tr key="22">
                <td>23</td>
                <td>Recurring revenue</td>
                <td>${this.state.recurringRevenue}</td>
              </tr>
              <tr key="23">
                <td>24</td>
                <td>Number of patients who pay by cash</td>
                <td>{this.state.numberOfPatientsWhoPayByCash}</td>
              </tr> */}
            </tbody>
          </Table>
        </div>
        {/* <h1
        style={{
          textAlign: 'center',
          marginTop: '5%',
        }}
      >
        Distribution of number of clinicians seen per patient
      </h1>
      <div
        id="pieChart"
        style={{
          flex: 1,
          // alignSelf: 'center',
          width: '100%',
          //   overflow: 'hidden',
          height: '75vh',
          // height: '100%',
          display: 'block',
          margin: 'auto',
          // justifyContent: 'center',
          // alignItems: 'center',
          // center all elements in this div
          // textAlign: 'center',
          // alignItems: 'center',
          // display: 'flex',
        }}
      >
        <PieChart width={730} height={550}>
          <Pie
            data={this.state.pieChartData1}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={150}
            // fill={['#FFCE04', '#000000']}
            fill="#82ca9d"
            label
          />
          <Tooltip />
          <Legend layout="vertical" align="right" verticalAlign="middle" />
        </PieChart>
      </div> */}
        {/* <div
        style={{
          //   width: 730,
          height: '50vh',
        }}
      >
        <h1
          style={{
            justifyContent: 'center',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          Line Char of Payment data
        </h1>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={730}
            height={50}
            data={this.state.paymentData}
            // data={this.state.pieChartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="date" />
            <YAxis dataKey="total" type="number" domain={[0, 4000]} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Line
              name="total"
              type="monotone"
              dataKey="total"
              stroke="#8884d8"
            />
            <Line
              name="aila_inc"
              type="monotone"
              dataKey="aila_inc"
              stroke="#82ca9d"
            />
            <Line
              name="pc_inc"
              type="monotone"
              dataKey="pc_inc"
              stroke="red"
            />
            <Line
              name="all_access"
              type="monotone"
              dataKey="all_access"
              stroke="#000000"
            />
            <Line
              name="membership"
              type="monotone"
              dataKey="membership"
              stroke="green"
            />
            <Line
              name="appointment"
              type="monotone"
              dataKey="appointment"
              stroke="blue"
            />
            <Legend verticalAlign="top" height={40} />
          </LineChart>
        </ResponsiveContainer>
      </div> */}
      </div>
    )
  }

  setCurrentShowingProvider = (val) => {
    console.log('Setting value to:', val.value.trim(), '|')
    this.setState({ currentShowingProvider: val.value })
    this.setState({
      currentShowingProviderLineChartData:
        this.state.allProviderMetadata[val.value.trim()],
    })

    this.setState({
      currentShowingProviderNoAppointmentsLineChartInfo:
        this.state.allProvidersNumberOfAppointmentsInfo[val.value.trim()],
    })

    if (this.state.testProviderData[val.value.trim()]) {
      this.setState({
        currentShowingProviderTasksLineChartInfo:
          this.state.testProviderData[val.value.trim()]['all_tickets'],
      })
    } else {
      this.setState({
        currentShowingProviderTasksLineChartInfo: [],
      })
    }
  }

  renderProvidersAnalytics = () => {
    return (
      <div>
        <div>
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Revenue Per Provider
          </h1>
          <Select
            placeholder={'Select Provider....'}
            options={this.state.allProvidersForSelect}
            value={this.state.currentShowingProvider}
            onChange={(val) => this.setCurrentShowingProvider(val)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
          <LineChart
            width={1000}
            height={500}
            data={
              // this.state.allProviderMetadata[this.state.currentShowingProvider]
              this.state.currentShowingProviderLineChartData
            }
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="count"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="total_visit_fee" stroke="#82ca9d" />
          </LineChart>
        </div>
        <div>
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Appointments Per Provider
          </h1>
          <LineChart
            width={1000}
            height={500}
            data={this.state.currentShowingProviderNoAppointmentsLineChartInfo}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="appointments"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            {/* <Line type="monotone" dataKey="total_visit_fee" stroke="#82ca9d" /> */}
          </LineChart>
        </div>
        {/* <div>
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Provider Capacity
          </h1>
          <LineChart
            width={1000}
            height={500}
            data={this.state.currentShowingProviderTasksLineChartInfo}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
        </div>
        <div>
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Time Spent by Type
          </h1>
          <LineChart
            width={1000}
            height={500}
            data={this.state.currentShowingProviderTasksLineChartInfo}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="due_date_as_string" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
        </div> */}
        {/* <div>
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Patients Per Provider
          </h1>
          <LineChart
            width={1000}
            height={500}
            data={[
              {
                name: 'Page A',
                uv: 4000,
                pv: 2400,
                amt: 2400,
              },
              {
                name: 'Page B',
                uv: 3000,
                pv: 1398,
                amt: 2210,
              },
              {
                name: 'Page C',
                uv: 2000,
                pv: 9800,
                amt: 2290,
              },
              {
                name: 'Page D',
                uv: 2780,
                pv: 3908,
                amt: 2000,
              },
              {
                name: 'Page E',
                uv: 1890,
                pv: 4800,
                amt: 2181,
              },
              {
                name: 'Page F',
                uv: 2390,
                pv: 3800,
                amt: 2500,
              },
              {
                name: 'Page G',
                uv: 3490,
                pv: 4300,
                amt: 2100,
              },
            ]}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
        </div>
        <div>
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Tasks completed on time
          </h1>
          <LineChart
            width={1000}
            height={500}
            data={this.state.currentShowingProviderTasksLineChartInfo}
            // data={[
            //   {
            //     name: 'Page A',
            //     uv: 4000,
            //     pv: 2400,
            //     amt: 2400,
            //   },
            //   {
            //     name: 'Page B',
            //     uv: 3000,
            //     pv: 1398,
            //     amt: 2210,
            //   },
            //   {
            //     name: 'Page C',
            //     uv: 2000,
            //     pv: 9800,
            //     amt: 2290,
            //   },
            //   {
            //     name: 'Page D',
            //     uv: 2780,
            //     pv: 3908,
            //     amt: 2000,
            //   },
            //   {
            //     name: 'Page E',
            //     uv: 1890,
            //     pv: 4800,
            //     amt: 2181,
            //   },
            //   {
            //     name: 'Page F',
            //     uv: 2390,
            //     pv: 3800,
            //     amt: 2500,
            //   },
            //   {
            //     name: 'Page G',
            //     uv: 3490,
            //     pv: 4300,
            //     amt: 2100,
            //   },
            // ]}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
        </div> */}
      </div>
    )
  }

  // Will write this later on
  updateDataToWeekly = () => {
    let {
      currentShowingProviderTasksLineChartInfo,
      paymentData, // Payment data
      totalAppointments, // Visit volume
      noAppointmentsNoShow, // Appointments no show
      noCancelledAppointments, // Appointments cancelled
      averageRevenuePerAppointment, // Average Revenue per Appointment
      currentShowingProviderLineChartData, // Revenue Per Provider
      currentShowingProviderNoAppointmentsLineChartInfo, // Appointments per Provider
    } = this.state

    // Update the data to weekly
    let a = {}
    noAppointmentsNoShow.map((item) => {
      // item.date = moment(item.date).format('YYYY-MM-DD')
      let week = item.date.split('-').slice(0, 2).join('-')
      a[week] = a[week] ? a[week] + item.count : item.count
    })
    console.log(a)
  }

  updateDataToMonthly = () => {
    let {
      currentShowingProviderTasksLineChartInfo,
      paymentData, // Payment data
      totalAppointments, // Visit volume
      noAppointmentsNoShow, // Appointments no show
      noCancelledAppointments, // Appointments cancelled
      averageRevenuePerAppointment, // Average Revenue per Appointment
      currentShowingProviderLineChartData, // Revenue Per Provider
      currentShowingProviderNoAppointmentsLineChartInfo, // Appointments per Provider
    } = this.state

    // Update the data to weekly
    let chartData = []
    let monthlyDateValueMapping = {}
    noAppointmentsNoShow.map((item) => {
      let week = item.date.split('-').slice(0, 2).join('-')
      monthlyDateValueMapping[week] = monthlyDateValueMapping[week]
        ? monthlyDateValueMapping[week] + item.count
        : item.count
    })
    for (let key in monthlyDateValueMapping) {
      chartData.push({
        date: key,
        count: monthlyDateValueMapping[key],
      })
    }
    this.setState({ noAppointmentsNoShow: chartData })
    //
    chartData = []
    monthlyDateValueMapping = {}
    totalAppointments.map((item) => {
      let week = item.date.split('-').slice(0, 2).join('-')
      monthlyDateValueMapping[week] = monthlyDateValueMapping[week]
        ? monthlyDateValueMapping[week] + item.count
        : item.count
    })
    for (let key in monthlyDateValueMapping) {
      chartData.push({
        date: key,
        count: monthlyDateValueMapping[key],
      })
    }
    this.setState({ totalAppointments: chartData })
    //
    chartData = []
    monthlyDateValueMapping = {}
    noCancelledAppointments.map((item) => {
      let week = item.date.split('-').slice(0, 2).join('-')
      monthlyDateValueMapping[week] = monthlyDateValueMapping[week]
        ? monthlyDateValueMapping[week] + item.count
        : item.count
    })
    for (let key in monthlyDateValueMapping) {
      chartData.push({
        date: key,
        count: monthlyDateValueMapping[key],
      })
    }
    this.setState({ noCancelledAppointments: chartData })
    //
    chartData = []
    monthlyDateValueMapping = {}
    let monthlyDateValueMapping1 = {}
    let monthlyDateValueMapping2 = {}
    averageRevenuePerAppointment.map((item) => {
      let week = item.date.split('-').slice(0, 2).join('-')
      monthlyDateValueMapping[week] = monthlyDateValueMapping[week]
        ? monthlyDateValueMapping[week] + item.avg
        : item.avg
      monthlyDateValueMapping1[week] = monthlyDateValueMapping1[week]
        ? monthlyDateValueMapping1[week] + item.total
        : item.total
      monthlyDateValueMapping2[week] = monthlyDateValueMapping2[week]
        ? monthlyDateValueMapping2[week] + 1
        : 1
    })
    for (let key in monthlyDateValueMapping) {
      chartData.push({
        date: key,
        avg: (
          monthlyDateValueMapping[key] / monthlyDateValueMapping2[key]
        ).toFixed(2),
        total: monthlyDateValueMapping1[key],
      })
    }
    this.setState({ averageRevenuePerAppointment: chartData })
    //
    chartData = []
    monthlyDateValueMapping = {}
    monthlyDateValueMapping1 = {}
    currentShowingProviderLineChartData.map((item) => {
      let week = item.date.split('-').slice(0, 2).join('-')
      monthlyDateValueMapping[week] = monthlyDateValueMapping[week]
        ? monthlyDateValueMapping[week] + item.count
        : item.count
      monthlyDateValueMapping1[week] = monthlyDateValueMapping1[week]
        ? monthlyDateValueMapping1[week] + item.total_visit_fee
        : item.total_visit_fee
    })
    for (let key in monthlyDateValueMapping) {
      chartData.push({
        date: key,
        count: monthlyDateValueMapping[key],
        total_visit_fee: monthlyDateValueMapping1[key],
      })
    }
    console.log('chartData', chartData)
    this.setState({ currentShowingProviderLineChartData: chartData })
    //
    chartData = []
    monthlyDateValueMapping = {}
    currentShowingProviderNoAppointmentsLineChartInfo.map((item) => {
      let week = item.date.split('-').slice(0, 2).join('-')
      monthlyDateValueMapping[week] = monthlyDateValueMapping[week]
        ? monthlyDateValueMapping[week] + item.appointments
        : item.appointments
    })
    for (let key in monthlyDateValueMapping) {
      chartData.push({
        date: key,
        appointments: monthlyDateValueMapping[key],
      })
    }
    this.setState({
      currentShowingProviderNoAppointmentsLineChartInfo: chartData,
    })
  }

  changeDateGrouping = (val) => {
    console.log('Updating data grouping to: ', val)
    if (val == 'Daily') {
      this.setState({ dataTimeGroupingType: 'Daily' })
      // } else if (val == 'Weekly') {
      //   this.setState({ dataTimeGroupingType: 'Weekly' })
      //   this.updateDataToWeekly()
    } else if (val == 'Monthly') {
      this.setState({ dataTimeGroupingType: 'Monthly' })
      this.updateDataToMonthly()
    }
  }

  render() {
    // const { mongoUser, firebaseUser } = this.props
    // let { superUser } = this.state
    return (
      <div
        className={GlobalStyles.container}
        style={{
          overflow: 'hidden',
          paddingBottom: '10px',
        }}
      >
        <Header header={'Analytics'} />

        <div
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            // justifyContent: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: 500,
              height: 40,
              border: '1px solid black',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '2vw',
            }}
          >
            {/* Start of one fundamental tab */}
            <div
              onClick={() =>
                this.setState(
                  { selectedTab: 'Test' },
                  // () =>this.getInbox(false),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Test'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color: this.state.selectedTab === 'Test' ? 'white' : 'black',
                }}
              >
                Test
              </p>
            </div>
            {/* End of one fundamental tab */}
            <div
              onClick={() =>
                this.setState(
                  { selectedTab: 'Basic' },
                  // () =>this.getInbox(false),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Basic'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color: this.state.selectedTab === 'Basic' ? 'white' : 'black',
                }}
              >
                Basic
              </p>
            </div>
            <div
              onClick={() =>
                this.setState(
                  { selectedTab: 'Users' },
                  // () =>this.getOutbox(),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Users'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color: this.state.selectedTab === 'Users' ? 'white' : 'black',
                }}
              >
                Users
              </p>
            </div>
            <div
              onClick={() =>
                this.setState(
                  { selectedTab: 'Appointments' },
                  // () =>this.getInbox(true),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Appointments'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.selectedTab === 'Appointments'
                      ? 'white'
                      : 'black',
                }}
              >
                Appointments
              </p>
            </div>
            <div
              onClick={() =>
                this.setState(
                  { selectedTab: 'Providers' },
                  // () =>this.getInbox(true),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'Providers'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.selectedTab === 'Providers' ? 'white' : 'black',
                }}
              >
                Providers
              </p>
            </div>
            <div
              onClick={() =>
                this.setState(
                  { selectedTab: 'App' },
                  // () =>this.getInbox(true),
                )
              }
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.selectedTab === 'App'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color: this.state.selectedTab === 'App' ? 'white' : 'black',
                }}
              >
                App
              </p>
            </div>
          </div>
          <div
            style={{
              width: 300,
              height: 40,
              border: '1px solid black',
              borderRadius: 4,
              // display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '2vw',
              display: 'flex',
              position: 'absolute',
              right: 10,
            }}
          >
            {/* {firebaseUser?.superUser && (
              <Button
                onClick={() => {
                  this.setState({ superUser: !superUser, loading: true }, () =>
                    this.getPatientList(),
                  )
                }}
                className={GlobalStyles.button}
                disabled={this.state.loading}
                style={{
                  width: 200,
                  marginRight: '2vw',
                  right: 0,
                  position: 'relative',
                }}
                variant="primary"
              >
                {superUser ? 'Super User' : 'Provider'}
              </Button>
            )} */}
            {/* ;; */}
            <div
              onClick={() => {
                this.setState(
                  { dataTimeGroupingType: 'Daily' },
                  // () =>this.getInbox(false),
                )
                this.changeDateGrouping('Daily')
              }}
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.dataTimeGroupingType === 'Daily'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.dataTimeGroupingType === 'Daily'
                      ? 'white'
                      : 'black',
                }}
              >
                Daily
              </p>
            </div>
            {/* <div
              onClick={() => {
                this.setState(
                  { dataTimeGroupingType: 'Weekly' },
                  // () =>this.getInbox(false),
                )
                this.changeDateGrouping('Weekly')
              }}
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.dataTimeGroupingType === 'Weekly'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.dataTimeGroupingType === 'Weekly'
                      ? 'white'
                      : 'black',
                }}
              >
                Weekly
              </p>
            </div> */}
            <div
              onClick={() => {
                this.setState(
                  { dataTimeGroupingType: 'Monthly' },
                  // () =>this.getInbox(false),
                )
                this.changeDateGrouping('Monthly')
              }}
              style={{
                borderRadius: 4,
                cursor: 'pointer',
                width: '50%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.dataTimeGroupingType === 'Monthly'
                    ? Constants.primaryTheme
                    : 'white',
              }}
            >
              <p
                style={{
                  color:
                    this.state.dataTimeGroupingType === 'Monthly'
                      ? 'white'
                      : 'black',
                }}
              >
                Monthly
              </p>
            </div>
            {/* <Button
              onClick={() => this.setState({ sendModal: true })} // TODO: Change this to refresh the data in the page
              className={GlobalStyles.button}
              disabled={this.state.loading}
              style={{
                width: 200,
                marginRight: '2vw',
                right: 0,
                position: 'relative',
              }}
              variant="primary"
            >
              Refresh
            </Button> */}
          </div>
        </div>

        {this.state.loading ? (
          <div className={GlobalStyles.container} style={{ height: '100vh' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                height: '100%',
              }}
            >
              <div className={GlobalStyles.loader} />
            </div>
          </div>
        ) : (
          <div
            className={GlobalStyles.contentWrapper}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100vh',
              overflow: 'scroll',
              // width: '100%',
            }}
          >
            {this.state.selectedTab == 'Test' ? (
              //   <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
              //     Test
              //   </p>
              this.renderTestAnalytics1()
            ) : this.state.selectedTab == 'Basic' ? (
              //   <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
              //     Basic
              //   </p>
              this.renderBasicAnalytics()
            ) : this.state.selectedTab == 'Users' ? (
              //   <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
              //     Users
              //   </p>
              this.renderUserAnalytics()
            ) : this.state.selectedTab == 'Appointments' ? (
              //   <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
              //     Appointments
              //   </p>
              this.renderAppointmentsAnalytics()
            ) : this.state.selectedTab == 'Providers' ? (
              //   <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
              //     Providers
              //   </p>
              this.renderProvidersAnalytics()
            ) : this.state.selectedTab == 'App' ? (
              //   <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
              //     Providers
              //   </p>
              this.renderAppAnalytics()
            ) : (
              <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
                ERROR!
              </p>
            )}
            {/* <p style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
              No Data
            </p> */}
          </div>
        )}

        {/* <Modal
          ariaHideApp={false}
          isOpen={this.state.openDoc}
          onRequestClose={() => this.onModalHide()}
          style={customDocModalStyles}
          contentLabel="Modal"
        >
          {this.state.modalLoading ? (
            <div className={GlobalStyles.container} style={{ height: '100vh' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                import Table from 'react-bootstrap/Table'  height: '100%',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            </div>
          ) : (
            <iframe
              style={{ height: '100%', width: '100%' }}
              src={`data:application/pdf;base64,${this.state.testPDF}`}
            />
          )}
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.sendModal}
          onRequestClose={() => this.onModalHide()}
          style={sendFaxModalStyles}
          contentLabel="Modal"
        >
          {this.state.modalLoading ? (
            <div className={GlobalStyles.container} style={{ height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '100%',
                  height: '100%',
                }}
              >
                <div className={GlobalStyles.loader} />
              </div>
            </div>
          ) : (
            this.sendFaxModal()
          )}
        </Modal> */}
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,

    patient: state.patientReducer.patient,

    token: state.authReducer.token,

    patientList: state.patientReducer.patientList,

    mongoUser: state.userReducer.mongoUser,

    adminId: state.userReducer.adminId,

    patientCore: state.patientReducer.patientCore,

    unreadNotifications: state.userReducer.unreadNotifications,

    allNotifications: state.userReducer.allNotifications,

    firebaseUser: state.userReducer.firebaseUser,
  }
}

const mapDispatchToProps = {
  getUnreadMessages,
  addPatientLP,
  updateSideNavBar,
  stopTimer,
  resetTimer,
  startTimer,
  addVirgilE3,
  updateDoctorMongoUser,
  addMongoUser,
  addPatient,
  userLoggedIn,
  fetchPatientCoreDate,
  fetchPatientProgressData,
  fetchPatientWellnessScreening,
  fetchPatientCalculateProgressData,
  updatePatientData,
  addPatientList,
  setPatientCoreData,
  addRpmPatient,
  fetchPatientConditions,
  fetchPatientMedications,
  fetchPatientLabs,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAnalytics)
