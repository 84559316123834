import firebase from 'firebase'
import 'firebase/analytics'
//prod firebase project

const firebaseConfig = {
  apiKey: 'AIzaSyAlmct1SHhXm2OgLlUfShrHZjNFj3fvDNM',
  authDomain: 'aila-health-b469c.firebaseapp.com',
  databaseURL: 'https://aila-health-b469c.firebaseio.com',
  projectId: 'aila-health-b469c',
  storageBucket: 'aila-health-b469c.appspot.com',
  messagingSenderId: '1081552649172',
  appId: '1:1081552649172:web:6c20d8ae4274888b037441',
  measurementId: 'G-HZRSECPGTX',
}

//dev firebase project
// const firebaseConfig = {
//   apiKey: "AIzaSyAHgTOPI-aA0YpxV3C5a2wpjQvetQyZiqY",
//   authDomain: "aila-health-dev.firebaseapp.com",
//   databaseURL: "https://aila-health-dev.firebaseio.com",
//   projectId: "aila-health-dev",
//   storageBucket: "aila-health-dev.appspot.com",
//   messagingSenderId: "392996477993",
//   appId: "1:392996477993:web:f8c03ed9dc8de31bb5d038",
//   measurementId: "G-M72GRS8CLD"
// };
firebase.initializeApp(firebaseConfig)
const analytics = firebase.analytics()

var auth = firebase.auth()
var googleProvider = new firebase.auth.GoogleAuthProvider()
var appleProvider = new firebase.auth.OAuthProvider('apple.com')
export { auth, googleProvider, appleProvider, analytics, firebase }

export default firebase
