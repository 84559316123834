import React, { Component, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faStar,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faEdit,
  faSearch,
  faArrowDown,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'
import Select from 'react-select'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { loadStripe } from '@stripe/stripe-js'
import {
  addMongoUser,
  stopTimer,
  toggleSidebar,
  addFirebaseUser,
  fetchPatientCoreDate,
  addPatient,
} from '../../../redux/actions'
import firebase from '../../../services/firebase.js'
import PlaceHolderProfileImage from '../../../assets/images/placeholder.png'
import metadata, * as Metadata from '../../../metadata.js'

import GlobalStyles from '../../styles/global.module.scss'
import Header from '../../../components/Header'
import Constants from '../../../values.js'
import Styles from './styles/PatientAccount.module.scss'
import Config from '../../../config'

const moment = require('moment')
const axios = require('axios')
const windowHeight = window.innerHeight
const windowWidth = window.innerWidth

const medicModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '40%',
    height: '60%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
}

const paymentModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '75%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    zIndex: 2,
  },
}
const TEXT_COLOR = '#7E7E7E'

const PatientIdentity = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)
  //   const openNotesModal = useSelector(
  //     (state) => state.genericReducer?.openNotesModal,
  //   )
  const appointmentData = useSelector(
    (state) => state.appointmentReducer?.appointmentData,
  )

  const patient = useSelector((state) => state.patientReducer?.patient)
  const patientCore = useSelector((state) => state.patientReducer?.patientCore)

  const frontImageRef = React.createRef()
  const backImageRef = React.createRef()

  const frontImageRef1 = React.createRef()
  const backImageRef1 = React.createRef()

  const [loading, setLoading] = useState(false)

  const [idFront, setIdFront] = useState(null)
  const [idBack, setIdBack] = useState(null)

  //INSURANCE IMAGE DATA
  //PRIMARY INSURANCE
  const [insuranceBack, setInsuranceBack] = useState(false)
  const [backLocation, setBackLocation] = useState('')
  const [backImage, setBackImage] = useState(null)
  const [backUploaded, setBackUploaded] = useState(false)
  const [frontLocation, setFrontLocation] = useState('')
  const [frontImage, setFrontImage] = useState(null)
  const [frontUploaded, setFrontUploaded] = useState(false)
  const [insuranceFront, setInsuranceFront] = useState(false)

  useEffect(() => {
    setLoading(true)

    const patientData = { ...patient, ...patientCore }
    setIdFront(patientData?.id_front_image_url || null)
    setIdBack(patientData?.id_back_image_url || null)
    setLoading(false)
  }, [])

  const updateUserInsurance = (id, front, back) => {
    const mongoData = {
      id_front_image_url: id === 1 ? front : id === 2 ? front : idFront,
      id_back_image_url: id === 1 ? back : id === 3 ? front : idBack,
    }

    const mongo_url = `${Config.BACKEND_URL}users`

    console.log('mongo data::', mongoData, mongo_url)

    axios({
      method: 'put',
      url: mongo_url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patient.uid },
      data: mongoData,
    })
      .then(({ data }) => {
        console.log('successfully added user details to mongo backend')
        // self.props.addMongoUser(user)
        setLoading(false)
        toast.success('Successfully updated Identity Verification')

        dispatch(fetchPatientCoreDate(patient?.uid, token))

        // if (self.state.imageUploaded) toast.success('Updated profile picture')
        // self.setState({ loading: false, saving: false })

        // , imageUploaded: false })
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  const renderLoader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        height: 100, // do not change this
      }}
    >
      <div className={GlobalStyles.loader} />
    </div>
  )

  const getRandomString = (len) => {
    var result = []
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (let i = 0; i < len; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength)),
      )
    }
    return result.join('')
  }

  const saveImageToFirebase = (insurance) => {
    if (frontUploaded && backUploaded) {
      let frontURL = ''
      let backURL = ''
      const file = frontImage

      const storageRef = firebase.storage().ref()
      const fileExtension = frontImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/identity/${patient.uid}/${
            frontUploaded
              ? `front-${getRandomString(10)}`
              : `back-${getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // pinsurance.front_image_url = downloadURL
            // setPrimaryInsurance(pinsurance)
            console.log('front url:', downloadURL)
            frontURL = downloadURL
            setIdFront(downloadURL)
            console.log(idFront, idBack)
            // console.log('PRIMARY FRONT:', downloadURL)

            const file = backImage
            const storageRef = firebase.storage().ref()
            const fileExtension = backImage.name.split('.').pop()

            const uploadTask = storageRef
              .child(
                `user/identity/${patient.uid}/${
                  frontUploaded
                    ? `front-${getRandomString(10)}`
                    : `back-${getRandomString(10)}`
                }.${fileExtension}`,
              )
              .put(file)
            uploadTask.on(
              'state_changed',
              function (snapshot) {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log(`${progress}%`)
              },
              function (err) {
                // Handle unsuccessful uploads
                console.log('error when uploading image', err)
                // self.saveUserToBackend(user)
              },
              function () {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(function (downloadURL) {
                    // let pinsurance = { ...primaryInsurance }
                    // pinsurance.back_image_url = downloadURL
                    console.log('back url:', downloadURL)
                    backURL = downloadURL

                    setIdBack(downloadURL)
                    console.log(idFront, idBack)

                    // setPrimaryInsurance(pinsurance)
                    updateUserInsurance(1, frontURL, backURL)
                    // console.log('PRIMARY BACK:', downloadURL)
                  })
              },
            )
            // updateUserInsurance(1, pinsurance)
          })
        },
      )
    } else if (frontUploaded) {
      const file = frontImage
      let frontURL = ''

      const storageRef = firebase.storage().ref()
      const fileExtension = frontImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/identity/${patient.uid}/${
            frontUploaded
              ? `front-${getRandomString(10)}`
              : `back-${getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('front url:', downloadURL)

            setIdFront(downloadURL)
            // let pinsurance = { ...primaryInsurance }
            // pinsurance.front_image_url = downloadURL
            // setPrimaryInsurance(pinsurance)
            updateUserInsurance(2, downloadURL)
          })
        },
      )
    } else if (backUploaded) {
      const file = backImage
      const storageRef = firebase.storage().ref()
      const fileExtension = backImage.name.split('.').pop()

      const uploadTask = storageRef
        .child(
          `user/identity/${patient.uid}/${
            frontUploaded
              ? `front-${getRandomString(10)}`
              : `back-${getRandomString(10)}`
          }.${fileExtension}`,
        )
        .put(file)
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`${progress}%`)
        },
        function (err) {
          // Handle unsuccessful uploads
          console.log('error when uploading image', err)
          // self.saveUserToBackend(user)
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('back url:', downloadURL)

            setIdBack(downloadURL)
            // let pinsurance = { ...primaryInsurance }
            // pinsurance.back_image_url = downloadURL
            // setPrimaryInsurance(pinsurance)
            updateUserInsurance(3, downloadURL)
          })
        },
      )
    }
  }

  const onImageChange = (e, insuranceFront, insuranceBack, insurance) => {
    const file = e.target.files[0] || false

    if (insurance === 1) {
      if (
        file &&
        window.confirm(`Are you sure you want to upload ${file.name}?`)
      ) {
        // this.setState({imageUploaded: true, imageLocation: })

        const reader = new FileReader()

        reader.onload = function (e) {
          const image = e.target.result

          if (insuranceFront) {
            console.log('confirmed upload - insurance front')

            setFrontLocation(image)
            setFrontUploaded(true)
            setFrontImage(file)
            setInsuranceFront(false)
          } else if (insuranceBack) {
            console.log('confirmed upload - insurance back')

            setBackLocation(image)
            setBackUploaded(true)
            setBackImage(file)
            setInsuranceBack(false)
          }
        }

        reader.readAsDataURL(file) // convert to base64 string
      } else {
        console.log('ignored')
      }
    }
  }

  const savePressed = () => {
    if (!frontUploaded || !backUploaded) {
      toast.warning('Please upload Government ID')
      return
    }

    setLoading(true)

    if (frontUploaded || backUploaded) {
      saveImageToFirebase()
    }
  }

  const renderContent = () => {
    //PRIMARY INSURANCE IMAGES
    let frontImage
    if (frontUploaded) frontImage = frontLocation
    else frontImage = idFront || null

    let backImage
    if (backUploaded) backImage = backLocation
    else backImage = idBack || null

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1% 5%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '100%', padding: '20px 20px' }}>
            {/*PRIMARY INSURANCE */}
            <p
              className={Styles.rowLabel}
              style={{ fontSize: 'large', fontStyle: 'bold', width: '100%' }}
            >
              We also require a government issued identifiable photo ID (United
              States issued Driver’s License or Identification card)
              <br />
              <br />
              This must include:
              <br />
              First Name
              <br /> Last Name
              <br />
              Date of birth Expiration date (for legal purposes your ID cannot
              be expired)
              <br />
              Unobstructed photo of your face
            </p>

            <div className={Styles.row}>
              <p className={Styles.rowLabel}>Government Issued ID</p>
              <div
                style={{ width: '50%', display: 'flex', flexDirection: 'row' }}
              >
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {frontImage && (
                    <img
                      src={frontImage}
                      style={{
                        height: '100%',
                        width: '90%',
                        objectFit: 'cover',
                      }}
                      alt="No Image"
                    />
                  )}

                  <input
                    onChange={(e) => {
                      setInsuranceFront(true)

                      console.log('upload FRONT clicked')

                      onImageChange(e, true, false, 1)
                    }}
                    type="file"
                    id="file"
                    ref={frontImageRef}
                    accept="image/*"
                    style={{ display: 'none' }}
                    multiple={false}
                  />
                  <p
                    onClick={() => frontImageRef.current.click()}
                    style={{
                      textDecoration: 'underline',
                      color: 'gray',
                      cursor: 'pointer',
                    }}
                  >
                    Upload Front
                  </p>
                </div>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {backImage && (
                    <img
                      src={backImage}
                      style={{
                        height: '100%',
                        width: '90%',
                        objectFit: 'cover',
                      }}
                      alt="No Image"
                    />
                  )}

                  <input
                    onChange={(e) => {
                      setInsuranceBack(true)

                      console.log('upload BACK clicked')

                      onImageChange(e, false, true, 1)
                    }}
                    type="file"
                    id="file"
                    ref={backImageRef}
                    accept="image/*"
                    style={{ display: 'none' }}
                    multiple={false}
                  />
                  <p
                    onClick={() => {
                      backImageRef.current.click()
                    }}
                    style={{
                      textDecoration: 'underline',
                      color: 'gray',
                      cursor: 'pointer',
                    }}
                  >
                    Upload Back
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <p
          onClick={() => {
            console.log('Show Secondary')
            setShowSecondaryInsurance(!showSecondaryInsurance)
          }}
          style={{
            textDecoration: 'underline',
            color: Constants.primaryTheme,
            cursor: 'pointer',
            marginLeft: 'auto',
          }}
        >
          {showSecondaryInsurance ? `` : `Secondary Insurance`}
          <FontAwesomeIcon
            icon={showSecondaryInsurance ? faArrowUp : faArrowDown}
            style={{
              color: Constants.primaryTheme,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          />
        </p> */}

        <Button
          onClick={() => savePressed()}
          className={GlobalStyles.button}
          style={{ width: 200, margin: '5% auto' }}
          variant="primary"
        >
          Save
        </Button>
      </div>
    )
  }

  if (loading) return renderLoader()
  return renderContent()
}

export default PatientIdentity
