import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

export const rpmOrdersColumns = (updateDeviceID) => {
  return [
    {
      Header: 'Date',
      accessor: (obj) =>
        obj?.created_at ? moment(obj?.created_at).format('ll') : '-',
      width: 200,
      sort: true,
    },
    {
      Header: 'Devices',
      accessor: (obj) => {
        let tests = obj.lines || []
        let testElements = tests.map((test) => (
          <li>
            {test?.line_name || 'Item'} : {test?.quantity || test?.qty}
          </li>
        ))
        return testElements
      },
      width: 200,
      style: { textAlign: 'left' },
      sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%' }}>
      //         <input
      //           placeholder="Search"
      //           className={Styles.textInput}
      //           style={{ width: '100%' }}
      //           type="text"
      //           value={filterValue}
      //           onChange={(e) => {
      //             setFilter(e.target.value)
      //           }}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
    },

    {
      Header: 'Device ID',
      accessor: (obj) => {
        let deviceIds = obj.device_ids || []

        return (
          <>
            <div className={Styles.addManualRow}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <FontAwesomeIcon
                  className={Styles.plusButton}
                  onClick={() => {
                    console.log('save presed')
                    updateDeviceID(obj, true)
                  }}
                  icon={faPlusCircle}
                />
              </div>
            </div>
            {deviceIds?.map((device, index) => (
              <div className={Styles.addManualRow}>
                <p className={Styles.addManualQuestion}>{device}</p>
              </div>
            ))}
          </>
        )
      },
      width: 200,
      style: { textAlign: 'left' },
      sort: true,
      // Filter: ({ column }) => {
      //   const { filterValue, setFilter } = column
      //   return (
      //     <span>
      //       <div style={{ width: '100%' }}>
      //         <input
      //           placeholder="Search"
      //           className={Styles.textInput}
      //           style={{ width: '100%' }}
      //           type="text"
      //           value={filterValue}
      //           onChange={(e) => {
      //             setFilter(e.target.value)
      //           }}
      //         />
      //       </div>
      //     </span>
      //   )
      // },
    },

    {
      Header: 'Status',
      accessor: 'status',
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
  ]
}
