import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import GlobalStyles from '../../../pages/styles/global.module.scss'
import Styles from './styles/CommunityChatRoom.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import firebase from '../../../services/firebase'
import Form from 'react-bootstrap/Form'
import socketIOClient from 'socket.io-client'
import Linkify from 'react-linkify'

import {
  faEllipsisV,
  faShare,
  faEllipsisH,
  faPlusCircle,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'
import {
  faMicrophoneSlash,
  faPhone,
  faVideoSlash,
  faMicrophone,
  faVideo,
  faEdit,
  faThumbsUp,
  faComment,
} from '@fortawesome/free-regular-svg-icons'
import { toggleSidebar } from '../../../redux/actions'
import values from '../../../values'
import Config from '../../../config'
import DocPlaceHolder from '../../../assets/images/docPlaceholder.png'
import Button from 'react-bootstrap/Button'
import Modal from 'react-modal'
import Header from '../../../components/Header.js'
import Select from 'react-select'
import PlaceHolderProfileImage from '../../../assets/images/placeholder.png'

const TWILIO_VIDEO = require('twilio-video')

const moment = require('moment')
const axios = require('axios')

const CONFIRM_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '60%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    overflow: 'hidden',
  },
}

const ADD_MODAL_STYLES = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
  },
  content: {
    width: '50%',
    height: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    overflow: 'hidden',
  },
}

const CommunityChatRoom = (props) => {
  const twilioToken = useSelector((state) => state.genericReducer?.twilioToken)
  const token = useSelector((state) => state.authReducer.token)
  //   const openNotesModal = useSelector(
  //     (state) => state.genericReducer?.openNotesModal,
  //   )
  const appointmentData = useSelector(
    (state) => state.appointmentReducer?.appointmentData,
  )

  const patient = useSelector((state) => state.patientReducer?.patient)
  const mongoUser = useSelector((state) => state.userReducer.mongoUser)
  const firebaseUser = useSelector((state) => state.userReducer.firebaseUser)

  const [loading, setLoading] = useState(true)

  const [socket, _setSocket] = useState(null)
  const [messageIndex, _setMessageIndex] = useState(0)
  const [messagesList, _setMessagesList] = useState([])
  const [messageText, setMessageText] = useState('')

  const history = useHistory()
  const dispatch = useDispatch()

  // to get the latest state values for the sockets
  const messagesListRef = useRef(messagesList)
  const setMessagesList = (data) => {
    messagesListRef.current = data
    _setMessagesList(data)
  }

  // to get the latest state values for the sockets
  const messageIndexRef = useRef(messageIndex)
  const setMessageIndex = (data) => {
    messageIndexRef.current = data
    _setMessageIndex(data)
  }

  const socketRef = useRef(socket)
  const setSocket = (data) => {
    socketRef.current = data
    _setSocket(data)
  }

  useEffect(() => {
    if (!patient && !firebaseUser) {
      history.push('/login')
    }

    if (!props?.communityId) {
      history.push('/patient/community')
      console.log('inside')
    }
    setLoading(true)
    prepareSocket()
    getOldMessages()
    // setUnReadMessagesCount(0)
  }, [])

  //   useEffect(() => {
  //     prepareSocket();
  //     getOldMessages();
  //     setUnReadMessagesCount(0);
  //     return () => {
  //       socketRef?.current && socketRef?.current.disconnect();
  //     };
  //   }, []);

  const scrollToBottom = () => {
    // if (this.dummyRef.current)
    //   this.dummyRef.current.scrollIntoView({ behavior: 'smooth' })

    var elem = document.getElementById('scrollingDiv')
    if (elem) {
      elem.scrollTop = elem.scrollHeight
    }
  }

  const getOldMessages = () => {
    setLoading(true)
    var config = {
      method: 'get',
      url: `${Config.BACKEND_URL}communities/messages`,
      headers: {
        x_firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
        Authorization: `JWT ${token}`,
      },
      params: {
        community_id: props?.communityId,
      },
    }

    axios(config)
      .then(({ data }) => {
        setLoading(false)
        if (data) {
          formatMessages(data?.messages)
          updateUnreadMessages()
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log('error in fetching old messages', err)
      })
  }

  const updateUnreadMessages = () => {
    let config = {
      method: 'put',
      url: `${Config.BACKEND_URL}communities/messages/unread`,
      headers: {
        x_firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
        Authorization: 'JWT ' + token,
      },
      params: {
        community_id: props?.communityId,
      },
    }

    axios(config)
      .then((data) => console.log('unread updated'))
      .catch((err) => {
        console.log('error when updating user', err)
      })
  }

  const formatMessages = (data) => {
    let list = []
    for (let i = 0; i < data.length; i++) {
      let message = data[i]
      list.push({
        _id: i + 1000,
        text: message.message,
        createdAt: message.created_at,
        user: {
          _id: message.sender_id,
          name: message.sender_name,
          avatar: message.sender_image_url || PlaceHolderProfileImage,
        },
      })
    }
    // list.reverse()
    setMessagesList(list)
    scrollToBottom()
  }

  const prepareSocket = () => {
    const socket = socketIOClient(Config.CHAT_ENDPOINT)
    setSocket(socket)

    socket.on('connect', () => {
      console.log('connected to chat server')
      socket.emit('login', {
        firebase_id: firebaseUser ? firebaseUser.uid : patient.uid,
      })
    })

    socket.on('connecting', () => {
      console.log('connecting to chat server....')
    })

    socket.on('connect_failed', () => {
      console.log('connection to chat server failed :(')
    })

    let eventNameToListen = `${props?.communityId}Message`

    socket.on(eventNameToListen, async (data) => {
      console.log('recieved a new message', data, messagesListRef.current)
      // if (patient.uid === data.sender_id) {
      //   return
      // } else {
      let newMessage = {
        // _id: messageIndexRef.current.toString(),
        text: data.message,
        createdAt: data.created_at,
        user: {
          _id: data.sender_id,
          name: data.sender_name,
          avatar: data.sender_image_url || PlaceHolderProfileImage,
        },
      }
      // let tempList = [newMessage, ...messagesListRef.current]
      let tempList = messagesListRef.current
      tempList.push(newMessage)

      setMessageIndex(messageIndexRef.current + 1)
      setMessagesList(tempList)
      updateUnreadMessages()
      setMessageText('')
      scrollToBottom()
      // }
    })
  }

  const onSend = (messages) => {
    console.log('send pressed: ')
    // setMessagesList([...messagesList, ...messagesListRef.current])
  }

  const onMessageSendPressed = async (messages) => {
    // let message = messages[0]
    let message = messageText

    try {
      let newMessageToSend = {
        sender_id: firebaseUser ? firebaseUser.uid : patient.uid,
        message: message,
        community_id: props?.communityId,
        sender_name: firebaseUser
          ? firebaseUser?.firstName 
          : patient?.firstName ,
        sender_image_url: firebaseUser
          ? firebaseUser?.profilePictureURL
          : patient?.profilePictureURL,
      }

      onSend(messages)
      socket && socket.emit('CommunityMessage', newMessageToSend)
    } catch (err) {
      let error = err.toString()
      console.log('error when encrypting text with virgil', err)
      if (error.includes('UsersNotFoundError')) {
        // Alert.alert('User Error', 'Cannot send encrypted message to this user')
      } else {
        // Alert.alert('Unknown Error', 'Cannot send message right now')
      }
    }
  }

  const getMessages = (messages) => {
    // const { mongoUser } = this.props

    const toReturn = []
    let i = 0
    let id = firebaseUser ? firebaseUser?.uid : patient?.uid
    messages.forEach(async (message) => {
      toReturn.push(
        <div key={i.toString()}>
          <div
            className={
              message.user._id === id
                ? Styles.messageWrapperRight
                : Styles.messageWrapperLeft
            }
          >
            {message.user._id != id ? (
              //   this.state.careTeamImgs.map((e) =>
              //     e.doctor_id === message.user._id ? (
              <div
                style={{
                  marginTop: 15,
                  marginRight: 10,
                  height: 60,
                  width: 60,
                  borderRadius: '50%',
                  // backgroundColor: Constants.primaryThemeFadedDeep,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={
                    message.user.avatar
                      ? message.user.avatar
                      : PlaceHolderProfileImage
                  }
                  height={60}
                  width={60}
                  style={{ borderRadius: '50%', marginBottom: 4 }}
                />
              </div>
            ) : (
              //     ) : (
              //       <></>
              //     ),
              //   )
              <></>
            )}
            <div
              className={
                message.user._id === id
                  ? Styles.messageRight
                  : Styles.messageLeft
              }
            >
              {message.user._id != id ? (
                // this.state.careTeamImgs.map((e) =>
                //   e.doctor_id === message.user._id ? (
                <p
                  style={{
                    fontSize: 14,
                    textAlign: 'left',
                    color: 'gray',
                  }}
                >
                  {/* {console.log("NAME: ", e.name)} */}
                  {message.user.name}
                </p>
              ) : (
                //   ) : (
                //     <></>
                //   ),
                // )
                <></>
              )}

              <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                <Linkify>{message.text}</Linkify>
              </p>
              <p style={{ fontSize: 14, textAlign: 'right', color: 'gray' }}>
                {moment(message.createdAt).format('LLL')}
              </p>
            </div>

            {message.user._id === id ? (
              //   this.state.careTeamImgs.map((e) =>
              //     e.doctor_id === message.user._id ? (
              <div
                style={{
                  marginTop: 15,
                  marginLeft: 10,
                  height: 60,
                  width: 60,
                  borderRadius: '50%',
                  // backgroundColor: Constants.primaryThemeFadedDeep,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={
                    message.user.avatar
                      ? message.user.avatar
                      : PlaceHolderProfileImage
                  }
                  height={60}
                  width={60}
                  style={{ borderRadius: '50%', marginBottom: 4 }}
                />
              </div>
            ) : (
              //     ) : (
              //       <></>
              //     ),
              //   )
              <></>
            )}
          </div>
        </div>,
      )
      i++
    })
    return toReturn
  }

  const renderContent = () => {
    return (
      <div style={{ margin: '2% auto', width: '80%' }}>
        <div id="scrollingDiv" className={Styles.messagesWrapper}>
          {messagesList && messagesList.length > 0 ? (
            getMessages(messagesList)
          ) : (
            <div>
              <p>Be the first to message the team.....</p>
            </div>
          )}
        </div>
        <div className={Styles.bottomInputWrapper}>
          {/* <input
          type="file"
          id="file"
          ref={this.fileUploaderRef}
          style={{ display: 'none' }}
        /> */}
          <input
            onKeyDown={(e) => {
              if (e.key === 'Enter') onMessageSendPressed()
            }}
            style={{
              position: 'relative',
              top: '20%',
              flexGrow: 4,
              borderRadius: 4,
            }}
            placeholder="Enter your message..."
            className={Styles.textInput}
            type="text"
            value={messageText}
            onChange={(e) => {
              // if (this.state.ailaMessageSelected) return
              // this.setState({ messageText: e.target.value })
              setMessageText(e.target.value)
            }}
          />

          {/* <FontAwesomeIcon onClick={() => this.fileUploaderRef.current.click()} icon={faFileMedical} style={{color: 'gray', fontSize: 30, marginRight: 10, flexGrow: 1, cursor: 'pointer'}}/> */}
          {/* <FontAwesomeIcon
          onClick={() => {
            if (!this.state.ailaMessageSelected)
              this.setState({ modalIsOpen: true })
          }}
          icon={faCalendarPlus}
          style={{
            color: 'gray',
            fontSize: 30,
            marginRight: 10,
            flexGrow: 1,
            cursor: 'pointer',
          }}
        />
        <FontAwesomeIcon
          onClick={() => {
            if (!this.state.ailaMessageSelected)
              this.setState({ modalECIsOpen: true })
          }}
          icon={faPaperclip}
          style={{
            color: 'gray',
            fontSize: 30,
            marginRight: 10,
            flexGrow: 1,
            cursor: 'pointer',
          }}
        /> */}

          <FontAwesomeIcon
            onClick={() => {
              onMessageSendPressed()
              // if (!this.state.ailaMessageSelected)
              //   this.setState({ modalIsOpen: true })
            }}
            icon={faPaperPlane}
            style={{
              color: 'gray',
              fontSize: 30,
              marginRight: 10,
              flexGrow: 1,
              cursor: 'pointer',
            }}
          />
          {/* <Button
          onClick={
            console.log('send')
            // this.onSubmitPressed.bind(this)
          }
          className={GlobalStyles.button}
          // disabled={this.state.messageText.length === 0}
          style={{ width: 100, flexGrow: 1 }}
          variant="primary"
        >
          Send
        </Button> */}
        </div>
      </div>
    )
  }

  return renderContent()
}

export default CommunityChatRoom
