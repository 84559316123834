import {
  faArrowCircleDown,
  faArrowCircleUp,
  faEdit,
  faPlusCircle,
  faTrash,
  faUserMd,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debounce } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import BSModal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import { Behavioral } from '../../components/Charts/BehaviourChart/BehaviourChart'
import Config from '../../config'
import { cptList } from '../../helpers/CPTList'
import Helpers from '../../helpers/Global'
import {
  APPLY_DURING_VISIT,
  OPTIONS_EDUCATION,
  OPTIONS_EMPLOYMENT,
  OPTIONS_ETHNICITY,
  RPM_Eligible_ICD,
  SPECIALITY_OPTIONS,
} from '../../metadata'
import {
  fetchPatientCarePlanData,
  fetchPatientCoreDate,
  setAppointmentNotesData,
} from '../../redux/actions'
import Constants from '../../values.js'
import Screeners from '../Patients/Careplan/InnerTabs/Screeners'
import MedMonitor from '../Patients/MedMonitor/MedMonitor'
import { RenderAddAllergiesManually } from '../Patients/Profile/Modals/AddAllergiesModal'
import { RenderAddFamilyHistoryManually } from '../Patients/Profile/Modals/AddFamilyHistoryModal'
import { RenderAddFoodSensitivitiesManually } from '../Patients/Profile/Modals/AddFoodSensitivitiesModal'
import { RenderAddMedicationsManually } from '../Patients/Profile/Modals/AddMedicationsModal'
import { RenderAddVitaminsManually } from '../Patients/Profile/Modals/AddVitaminsModal'
import ERXTab from '../Orders/Modals/ErxOrderModal'
import { RenderAddConditionsManually } from '../Patients/Profile/Modals/ConditionsModal'
import SymptomsProgress from '../Patients/Progress/SymptomsProgress'
import GlobalStyles from '../styles/global.module.scss'
import Styles from './styles/AppointmentNotes.module.scss'

import {
  SearchBox as EsSearchBox,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'

const conditionConnector = new AppSearchAPIConnector({
  searchKey: Config.ES_SEARCH_KEY,
  // TODO: update name to cpt-codes
  engineName: 'cpt-codes',
  endpointBase: Config.ES_ENDPOINT_KEY,
})

const esConfigCPTCodes = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: conditionConnector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      description: { raw: {} },
      cpt_code: { raw: {} },
      search_term: { raw: {} },
    },
  },
}

const axios = require('axios')

// const manualAddModalStyles = {
//   overlay: {
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 0.44)',
//     overlay: { zIndex: 1000 },
//   },
//   content: {
//     width: '60vw',
//     height: '80vh',
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }

// const searchClient = algoliasearch(
//   Config.ALGOLIA_APP_ID,
//   Config.ALGOLIA_API_KEY,
// )

let cptOptionList = []
let allCPTData = cptList.reduce((data, cpt) => {
  data[cpt.CodeType || 'Other'] = data[cpt.CodeType || 'Other'] || []
  data[cpt.CodeType || 'Other'].push({
    label: cpt?.CPTCode + ': ' + cpt?.CodeDescription,
    value: cpt?.CPTCode,
    CPTCode: cpt?.CPTCode,
    CodeDescription: cpt?.CodeDescription,
  })
  return data
}, Object.create(null))
Object.entries(allCPTData).forEach(([cptType, cptArray]) => {
  cptOptionList.push({
    label: cptType,
    options: cptArray,
  })
})

// const condnClient = {
//   search(requests) {
//     if (requests[0].params.query === '') {
//       return []
//     }
//     return searchClient.search(requests)
//   },
// }

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype)
  } else {
    var evObj = document.createEvent('Events')
    evObj.initEvent(etype, true, false)
    el.dispatchEvent(evObj)
  }
}

// const ClearQuery = ({ refine }) => {
//   const onClick = (ev) => {
//     refine('')
//     ev.stopPropagation()
//   }
//   return (
//     <div onClick={onClick} style={{ display: 'none' }}>
//       <ConnectedClearAllRefinements />
//     </div>
//   )
// }

// const ClearAllRefinements = ({ refine, items }) => {
//   const onClick = () => refine(items)
//   return (
//     <button id="icdclear" onClick={onClick}>
//       ClearAll
//     </button>
//   )
// }

const AccessEligilityGuide = () => {
  return (
    <>
      <div style={{ width: '30%', textAlign: 'center', marginTop: 10 }}>
        <p
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textDecoration: 'underline',
            color: Constants.primaryTheme,
            cursor: 'pointer',
          }}
          data-tip
          data-for={'access_eligibility_guide'}
        >
          Access Eligibility Guide
        </p>
      </div>

      <ReactTooltip
        id={'access_eligibility_guide'}
        multiline={true}
        place={'top'}
        effect={'solid'}
        clickable={true}
        className={Styles.tooltip}
      >
        To be eligible, a patient must:
        <br />
        <ul>
          <li>Have two or more chronic conditions.</li>
          <li>
            Have conditions that are expected to last at least 12 months or
            until death of the patient.
          </li>
          <li>
            Have conditions that place the patient at significant risk of death,
            acute exacerbation (i.e. worsening of condition), decompensation
            (i.e. organ failure), or functional decline.
          </li>
          <li>
            Agree to Aila Health having access to their health data provided in
            the application
          </li>
          <li>
            Agree to use the Aila Health platform as close to daily as possible
          </li>
          <li>
            Agree to speaking with HCP every 30 days about their usage of Aila
            Health platform, symptoms/outcomes, etc.
          </li>
        </ul>
      </ReactTooltip>
    </>
  )
}

const DiscussionGuide = (type) => {
  return (
    <>
      <div style={{ width: '30%', textAlign: 'center', marginTop: 10 }}>
        <p
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textDecoration: 'underline',
            color: Constants.primaryTheme,
            cursor: 'pointer',
          }}
          data-tip
          data-for={`access_${type}_discussion_guide`}
        >
          Access Monitoring Discussion Guide
        </p>
      </div>

      <ReactTooltip
        id={`access_${type}_discussion_guide`}
        multiline={true}
        place={'top'}
        effect={'solid'}
        clickable={true}
        className={Styles.tooltip}
      >
        {type} Discussion Guide
        <br />
        The language below is intended to be a guide for conversations seeking
        verbal consent for {type} Enrollment.
        <br />
        Our clinic provides Remote Patient Monitoring Services. Your dedicated
        care team will review your records and may contact you if needed. They
        may also connect with you about how they are working for you and your
        health. This also means that the care team in the practice will share
        information about your health with me to make sure we can talk about
        everything when we meet again.
        <br />
        Some other benefits include:
        <br />
        <ul>
          {/* <li>
            Be at least 65 years of age (because of Medicare
            Reimbursement) This provides preventative care keeps
            patients healthy and out of the hospital.
          </li> */}
          <li>
            A 24/7/365 nurse line reduces the likelihood that a patient would go
            to the emergency room for a non-urgent issue.
          </li>
          <li>
            The 24/7 care management access allows patients to ask questions
            directly to qualified health care professionals, without having to
            go in for an office visit.
          </li>
          <li>
            The care team can help assist with finding less-expensive
            prescription options.
          </li>
        </ul>
        <br />
        You may stop the remote monitoring services at any time (effective the
        end of calendar month)
        <br />
        <ul>
          <li>
            Do you have any questions about the remote monitoring services?
          </li>
          <li>Do you agree to receive the remote monitoring services?</li>
          <li>
            Do you understand that a monthly fee could apply to the remote
            monitoring services?
          </li>
          <li>
            Only 1 practitioner can furnish and bill remote monitoring services
            during a calendar month.
          </li>

          <li>Are you enrolled in any other remote monitoring programs?</li>
          <li>How do you prefer to be contacted?</li>
        </ul>
        Document in patient's medical record that you explained the required
        information and whether they accepted or declined services.
      </ReactTooltip>
    </>
  )
}

const defaultPhysicalExam =
  'Eyes:\nEar, Nose and Throat:\nNeck:\nCardiovascular:\nPulmonary:\nAbdomen:\nMusculoskeletal:\nSkin:\nNeurologic:\nPsychiatric:\nConstitutional:'

// const ClearQueryAndRefinements = connectSearchBox(ClearQuery)
// const ConnectedClearAllRefinements =
//   connectCurrentRefinements(ClearAllRefinements)

class NotesModal extends Component {
  constructor(props) {
    super(props)
    this.dragItem = React.createRef()
    this.dragOverItem = React.createRef()
    let notesData = this.props?.carePlanNotesData
    let reduxAppNotes = this.props?.appointmentNotesData
    // console.log('TESTING NOTES DATA careplannotes:: ', notesData)
    // console.log('TESTING NOTES APP DATA Redux :: ', this.props?.appointmentNotesData)
    let tempNotesList = notesData?.careplan || []

    let tempIllness = notesData?.duration
      ? `Patient reported:\nSeverity: ${notesData?.severity}/10,
Duration: ${notesData?.duration} ${notesData?.durationType},
Description: ${notesData?.description}\n`
      : ''

    let firstIcd,
      firstNote,
      firstCarePlan,
      reduxCpt,
      reduxAmendCpt,
      reduxChiefConcern,
      reduxProviderChiefConcern,
      reduxIllness,
      reduxProviderIllness,
      reduxReviewSystems,
      reduxObjectiveNote,
      reduxAssessmentNote,
      reduxCareplanNote,
      reduxNoIcdRequired,
      reduxPhysicalExam,
      reduxDiagnosticReview,
      reduxPriorAuthRx,
      reduxApplyVisit,
      reduxTimeSpent,
      reduxRpm,
      reduxCcm,
      reduxComplexCare,
      reduxDoctor,
      reduxNote_id,
      reduxAppointmentDate,
      reduxAppointmentEndDate,
      reduxFollowupData,
      reduxAppointmentId,
      reduxReferCoach
    if (this.props.openNotesModal) {
      reduxAppNotes?.notesList?.length &&
        (tempNotesList = reduxAppNotes?.notesList)
      firstIcd = reduxAppNotes?.addIcd10
      firstNote = reduxAppNotes?.addNotes
      firstCarePlan = reduxAppNotes?.addCarePlan
      reduxAppNotes?.addCpt?.length && (reduxCpt = reduxAppNotes?.addCpt)
      reduxAppNotes?.addAmendCpt?.length &&
        (reduxAmendCpt = reduxAppNotes?.addAmendCpt)
      reduxChiefConcern = reduxAppNotes?.chiefConcern
      reduxProviderChiefConcern = reduxAppNotes?.providerChiefConcern
      reduxIllness = reduxAppNotes?.illness
      reduxProviderIllness = reduxAppNotes?.providerIllness
      reduxReviewSystems = reduxAppNotes?.reviewSystems
      reduxObjectiveNote = reduxAppNotes?.objectiveNote
      reduxAssessmentNote = reduxAppNotes?.assessmentNote
      reduxCareplanNote = reduxAppNotes?.careplanNote
      reduxNoIcdRequired = reduxAppNotes?.noIcdRequired
      reduxPhysicalExam = reduxAppNotes?.physicalExam
      reduxDiagnosticReview = reduxAppNotes?.diagnosticReview
      reduxPriorAuthRx = reduxAppNotes?.priorAuthRx
      reduxApplyVisit = reduxAppNotes?.applyVisit
      reduxTimeSpent = reduxAppNotes?.timeSpent
      reduxRpm = reduxAppNotes?.rpm
      reduxCcm = reduxAppNotes?.ccm
      reduxComplexCare = reduxAppNotes?.complex_care
      reduxDoctor = reduxAppNotes?.doctor
      reduxNote_id = reduxAppNotes?.note_id
      reduxAppointmentDate = reduxAppNotes?.appointmentDate
      reduxAppointmentEndDate = reduxAppNotes?.appointmentEndDate
      reduxAppointmentId = reduxAppNotes?.reduxAppointmentId
      reduxFollowupData = reduxAppNotes?.followupData
      reduxReferCoach = reduxAppNotes?.refer_coach
    }

    this.state = {
      loading: false,
      appointmentData: null,
      patientId: this.props?.patientId || this.props?.patient?.uid,
      conditionsData: [],
      notes: '',
      notesSaved: [],
      addIcd10: firstIcd || {},
      addNotes: firstNote || '',
      addCarePlan: firstCarePlan || '',
      addCpt: reduxCpt || notesData?.cpt || [],
      addAmendCpt:
        reduxAmendCpt || notesData?.amended_cpt || notesData?.cpt || [],
      notesList: tempNotesList || [],
      authorizationNumber: notesData?.authorization_number || '',
      chiefConcern:
        reduxChiefConcern ||
        notesData?.chief_concern ||
        notesData?.reason ||
        '',
      providerChiefConcern:
        reduxProviderChiefConcern ||
        notesData?.provider_chief_concern ||
        notesData?.provider_reason ||
        '',
      heightFeet: '',
      heightInches: '',
      weightLbs: '',
      gender: '',
      ethnicity: '',
      sexual_orientation: '',
      pronoun: '',
      illness: reduxIllness || notesData?.present_illness || tempIllness,
      providerIllness:
        reduxProviderIllness || notesData?.provider_illness || '',
      reviewSystems: reduxReviewSystems || notesData?.review_systems || '',
      objectiveNote: reduxObjectiveNote || notesData?.objective_note || '',
      assessmentNote: reduxAssessmentNote || notesData?.assessment_note || '',
      careplanNote: reduxCareplanNote || notesData?.careplan_note || '',
      noIcdRequired: reduxNoIcdRequired || notesData?.no_icd_required || '',
      physicalExam:
        reduxPhysicalExam || notesData?.physical_exam || defaultPhysicalExam,
      diagnosticReview:
        reduxDiagnosticReview || notesData?.diagnostic_review || '',
      priorAuthRx: reduxPriorAuthRx ||
        notesData?.prior_auth_rx || { label: 'No', value: 'no' },
      applyVisit: reduxApplyVisit || notesData?.apply_visit || [],
      timeSpent: reduxTimeSpent || notesData?.time_spent || {},
      rpm: reduxRpm || notesData?.rpm || {},
      ccm: reduxCcm || notesData?.ccm || {},
      complex_care: reduxComplexCare || notesData?.complex_care || {},
      provider: reduxDoctor || notesData?.doctor,
      conditionsData: [],
      // props note_id is given preference than redux note_id
      note_id: reduxNote_id || notesData?.note_id || '',
      icd10query: '',
      cptquery: '',
      carePlanTemplate: [],
      behavioralData: {},
      socialData: {},
      isFirstSocial: false,
      doctor_details: this.props?.mongoUser,
      appointmentDate:
        // reduxAppointmentDate ||
        notesData?.appointment_date ||
        // notesData?.start ||
        null,
      appointmentEndDate:
        // reduxAppointmentEndDate ||
        notesData?.appointment_end_date ||
        // notesData?.end ||
        null,
      appointment_event_id:
        reduxAppointmentId ||
        notesData?.event_id ||
        notesData?.appointment_event_id ||
        null,
      noteCreatedAt:
        notesData?.created_at && notesData?.note_id
          ? notesData?.created_at
          : new Date(),
      careplan_id: notesData?._id,
      tempIllness: tempIllness,
      tempNotesListData: tempNotesList,
      tempNotesData: notesData,
      labErxOrdersData: {},
      followupData: reduxFollowupData || notesData?.followupData || null,
      updateFollowUp: false,
      icdSeachResult: [],
      icd_code_desc: '',
      icd_code: '',
      providerOptionsData: [],
      allProviderList: [],
      providerMedications: [],
      providerAllergies: [],
      providerFoodSensitivities: [],
      medications: [],
      patientCore: {},
      manualAddModalIsOpen: false,
      manualAddState: '',
      currentAddModalData: null,
      coachingPopModalIsOpen: false,
      rpmPopModalIsOpen: false,
      medMonitorModalIsOpen: false,
      rxOrderModalIsOpen: false,
      medMonitorData: [],
      medMonitorDataArray: [],
      rpmEligibleCode: [],
      careTeamData: [],
      referCoach: reduxReferCoach || notesData?.refer_coach || {},
      activeAccordions: ['0', '3', '4', '5', '6', '9', '15', '16'],
      currentNote: '',
      amendedNotesList: [],
    }
    this.getConditionsData = this.getConditionsData.bind(this)
    this.getMedications = this.getMedications.bind(this)
    this.getProviderMedications = this.getProviderMedications.bind(this)
    this.getProviderAllergies = this.getProviderAllergies.bind(this)
    this.getProviderFoodSensitivities =
      this.getProviderFoodSensitivities.bind(this)
    this.getPatientCoreData = this.getPatientCoreData.bind(this)
    this.savePatientCore = debounce(this.savePatientCore.bind(this), 5000)
    this.getCarePlanTemplate = this.getCarePlanTemplate.bind(this)
    this.getBehavioralScreeners = this.getBehavioralScreeners.bind(this)
    this.getSocialScreeners = this.getSocialScreeners.bind(this)
    this.saveSocialHealth = debounce(this.saveSocialHealth.bind(this), 5000)
    this.getLabErxOrders = this.getLabErxOrders.bind(this)
    this.getAllProviderData = this.getAllProviderData.bind(this)
    this.getCareTeamDetails = this.getCareTeamDetails.bind(this)
    this.getIcdSearchResults = debounce(
      this.getIcdSearchResults.bind(this),
      500,
    )
    this.noteSubmit = this.noteSubmit.bind(this)
    this.submitFollowUpStatus = this.submitFollowUpStatus.bind(this)
    this.saveAppointmentToRedux = this.saveAppointmentToRedux.bind(this)
    this.autoSaveNote = debounce(this.autoSaveNote.bind(this), 3000)
    this.autoSaveFollowUp = debounce(this.autoSaveFollowUp.bind(this), 3000)
    this.handleSort = this.handleSort.bind(this)
    this.onManualAddClicked = this.onManualAddClicked.bind(this)
    this.onManualAddModalHide = this.onManualAddModalHide.bind(this)
    this.getAppointmentData = this.getAppointmentData.bind(this)
    this.setActiveAccordions = this.setActiveAccordions.bind(this)
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push('/login')
      return
    }
    this.getCarePlanTemplate()
    this.getLabErxOrders()
    this.getAllProviderData()
    this.getCareTeamDetails()
    this.getConditionsData()
    this.getMedications()
    // this.getProviderMedications()
    this.getProviderAllergies()
    this.getProviderFoodSensitivities()
    this.getPatientCoreData()
    this.getBehavioralScreeners()
    this.getSocialScreeners()
    this.getAppointmentData()
  }

  getAppointmentData() {
    let { token, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'appointment'
    if (this.state.appointment_event_id)
      axios({
        method: 'get',
        url: url,
        headers: {
          Authorization: 'JWT ' + token,
          x_firebase_id: mongoUser.firebase_id,
          event_id: this.state.appointment_event_id,
        },
      })
        .then(({ data }) => {
          if (data.visit_start_time && !this.state.appointmentDate)
            this.setState({ appointmentDate: data?.visit_start_time })
          if (data.visit_end_time && !this.state.appointmentEndDate)
            this.setState({ appointmentEndDate: data?.visit_end_time })
        })
        .catch((err) => {
          console.log('error when getting appointment information', err)
          toast.error('Something went wrong')
        })
  }

  getCarePlanTemplate() {
    let { token, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'careplan-template'
    let self = this
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: mongoUser.firebase_id,
      },
    })
      .then(({ data }) => {
        if (data?.physical_exam_template?.length) {
          let optionObjects = []
          // data?.physical_exam_template?.forEach(option => data.push({label: option, value: option}))
          optionObjects = data?.physical_exam_template.map((option) => {
            let keyLabel = Object.keys(option)[0].replace(/_/g, ' ')
            return {
              label: keyLabel.charAt(0).toUpperCase() + keyLabel.substring(1),
              value: Object.values(option)[0],
            }
          })
          self.setState({ carePlanTemplate: optionObjects })
        }
      })
      .catch((err) => {
        console.log('error when getting careplan template', err)
      })
  }

  getBehavioralScreeners() {
    const { patient, token } = this.props

    // get joints screening data from backend
    const url = `${Config.BACKEND_URL}doctor/patient/screenings/behavioral`
    axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: this.state.patientId,
        Authorization: `JWT ${token}`,
      },
    })
      .then(({ data }) => {
        data.depression.reverse()
        data.anxiety.reverse()
        this.setState({ behavioralData: data })
        // console.log('BEH: ', data, this.state.patientId)
      })
      .catch((err) => {
        console.log('error when getting pain screening data', err)
      })
  }

  getSocialScreeners() {
    const { patient, token } = this.props

    // get joints screening data from backend
    const url = `${Config.BACKEND_URL}screenings/social`
    axios({
      method: 'get',
      url,
      headers: {
        x_firebase_id: this.state.patientId,
        Authorization: `JWT ${token}`,
      },
    })
      .then(({ data }) => {
        if (data) this.setState({ socialData: data, isFirstSocial: false })
        else this.setState({ isFirstSocial: true })
      })
      .catch((err) => {
        console.log('error when getting pain screening data', err)
      })
  }

  getLabErxOrders() {
    let { token, mongoUser } = this.props
    let url = Config.BACKEND_URL + 'appointment/orders'
    // Does this need to be done here, isnt it just going to refer to the same this? This is not nested.
    let self = this
    console.log(
      'getting ALL lab orders for event_id',
      this.state.appointment_event_id,
    )
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: mongoUser.firebase_id,
        event_id: this.state.appointment_event_id,
      },
    })
      .then(({ data }) => {
        // console.log('testing laborders; ', data)
        if (data) {
          self.setState({ labErxOrdersData: data })
        }
      })
      .catch((err) => {
        console.log('error when getting erx lab orders', err)
      })
  }

  getIcdSearchResults = () => {
    let { icd_code_desc, icd_code } = this.state
    let { token, docFirebaseAuthUser } = this.props
    console.log('searching for icd')
    axios({
      method: 'get',
      url:
        Config.CHANGE_BACKEND_URL +
        'change/icd-10?' +
        `icd_code_desc=${icd_code_desc || ''}` +
        `&icd_code=${icd_code || ''}`,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: docFirebaseAuthUser?.uid,
      },
    })
      .then(({ data }) => {
        console.log('search icd: ', data)
        let result = data?.RESULT?.OBJECT
        if (result) {
          this.setState({
            icdSeachResult: Array.isArray(result) ? result : [result],
          })
        }
      })
      .catch((err) => console.log('error when searching icd 10 code: ', err))
  }

  getAllProviderData = () => {
    axios({
      method: 'get',
      url: Config.BACKEND_URL + 'providers',
      headers: {
        x_firebase_id: this.props.adminId,
        Authorization: 'JWT ' + this.props.token,
        getallproviders: true,
      },
    })
      .then(({ data }) => {
        let providerOptionList = []
        let allProviderData = data.reduce((data, doc) => {
          data[doc.user_type || 'Other'] = data[doc.user_type || 'Other'] || []
          data[doc.user_type || 'Other'].push({
            label: doc?.first_name + ' ' + doc?.last_name,
            value: doc?.firebase_id,
          })
          return data
        }, Object.create(null))
        Object.entries(allProviderData).forEach(([userType, doctorArray]) => {
          providerOptionList.push({
            label: userType,
            options: doctorArray,
          })
        })
        this.setState({
          providerOptionsData: providerOptionList,
          allProviderList: data,
        })
      })
      .catch((err) => {
        console.log('err while loading provider data: ', err)
      })
  }

  getCareTeamDetails() {
    const { token } = this.props
    const url = `${Config.BACKEND_URL}patient/careteam/internal`
    // this.setState({ loading: true })
    axios({
      method: 'get',
      url,
      headers: {
        Authorization: `JWT ${token}`,
        x_firebase_id: this.state.patientId,
      },
    })
      .then(({ data }) => {
        if (data) {
          let team = []
          data.forEach((care) => {
            if (care.hasOwnProperty('careplan'))
              team.push({
                name: `${care.first_name} ${care.last_name}`,
                doctor_id: care.doctor_id,
                user_type: care.user_type,
              })
          })
          this.setState({ careTeamData: team })
        }
      })
      .catch((err) => {
        console.log('error when getting careteam list from backend', err)
      })
  }

  getConditionsData() {
    let { token, patient } = this.props
    let url = Config.BACKEND_URL + 'user/conditions'
    let url1 =
      Config.BACKEND_URL + 'fhir/Condition?patient=' + this.state.patientId
    let self = this
    let userConditions = []
    let providerConditions = []
    // console.log(url1)
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: this.state.patientId,
      },
    })
      .then((response) => {
        console.log('got user conditions data')
        userConditions = response.data
        self.setState({
          conditionsData: userConditions.concat(providerConditions),
        })
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting user conditions data', err)
        toast.error('Something went wrong')
      })

    axios({
      method: 'get',
      url: url1,
      headers: {
        x_firebase_id: patient?.uid,
        Authorization: 'JWT ' + token,
      },
    })
      .then((response) => {
        console.log('got prov conditions data')
        providerConditions = response.data
        self.setState({
          conditionsData: userConditions.concat(providerConditions),
        })
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting fhir conditions data', err)
        toast.error('Something went wrong')
      })
  }

  getPatientCoreData() {
    let { token, patient } = this.props
    let url = Config.BACKEND_URL + 'doctor/patients'
    let patientData = {}
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_patient_id: this.state.patientId,
      },
    })
      .then((response) => {
        console.log('got user medications data')
        patientData = response.data
        const heightFeet =
          patientData?.height_unit === 'centimeter'
            ? Math.round(patientData?.height / 2.54)
            : Math.floor(patientData?.height / 12)

        const heightInches =
          patientData?.height_unit !== 'centimeter'
            ? Math.round(patientData?.height % 12)
            : 0

        const weightLbs =
          patientData?.weight_unit === 'kilograms'
            ? Math.round(patientData?.weight * 2.20462)
            : patientData?.weight
        this.setState({
          patientCore: patientData,
          heightFeet,
          heightInches,
          weightLbs,
          sexual_orientation: patientData?.sexual_orientation || null,
          gender: patientData?.gender,
          pronoun: patientData?.pronoun,
          ethnicity: patientData?.ethnicity,
        })
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        console.log('error when getting patientCore data', err)
      })
  }

  savePatientCore() {
    const { token, patient } = this.props
    const {
      heightFeet,
      heightInches,
      weightLbs,
      patientId,
      ethnicity,
      pronoun,
      sexual_orientation,
      gender,
    } = this.state
    if (!patientId) return

    let mongoData = {
      ...((heightFeet || heightInches) && {
        height: +(heightFeet * 12) + +heightInches,
      }),
      ...((heightFeet || heightInches) && { height_unit: 'feet' }),
      ...((heightFeet || heightInches) && { weight_unit: 'lbs' }),
      ...(weightLbs && { weight: weightLbs }),
      ...(ethnicity && { ethnicity: ethnicity }),
      ...(gender && { gender: gender }),
      ...(sexual_orientation && { sexual_orientation: sexual_orientation }),
      ...(pronoun && { pronoun: pronoun }),
      uid: patientId,
      // ethnicity: userDetails?.ethnicity,
      // gender: userDetails?.gender,
    }
    axios({
      method: 'put',
      url: `${Config.BACKEND_URL}users`,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patientId },
      data: mongoData,
    })
      .then(({ data }) => {
        console.log('successfully added user details to mongo backend')
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }
  saveSocialHealth() {
    const { token, patient } = this.props
    let { socialData, isFirstSocial, patientId } = this.state
    if (!patientId || !socialData) return

    if (this.state.ethnicity) socialData.ethnicity = this.state.ethnicity
    let config = {
      method: isFirstSocial ? 'post' : 'put',
      url: `${Config.BACKEND_URL}screenings/social`,
      headers: { Authorization: `JWT ${token}` },
      data: {
        firebase_id: patientId,
        ...socialData,
      },
    }
    axios(config)
      .then(({ data }) => {
        console.log('successfully added user details to mongo backend')
      })
      .catch((err) => {
        console.log('error when adding doctor data to backend', err)
      })
  }

  getMedications() {
    let { token, patient } = this.props
    let url = Config.BACKEND_URL + 'user/medications'
    let userMedications = {}
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'JWT ' + token,
        x_firebase_id: this.state.patientId,
      },
    })
      .then((response) => {
        console.log('got user medications data')
        userMedications = response.data
        if (userMedications)
          this.setState(
            {
              medications: userMedications,
            },
            () => this.getProviderMedications(),
          )
        else this.getProviderMedications()
        // self.setState({ conditionsData: response.data })
      })
      .catch((err) => {
        this.getProviderMedications()
        console.log('error when getting medication data', err)
        toast.error('Something went wrong')
      })
  }

  async getProviderMedications() {
    let { patient, token } = this.props
    let { medications } = this.state

    try {
      const getMeds = async () => {
        let url =
          Config.BACKEND_URL +
          'fhir/MedicationRequest?patient=' +
          this.state.patientId
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: this.state.patientId,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getMeds()
      let meds = response?.data
      if (meds) {
        this.setState({ providerMedications: meds })
      }
      let array1 = (medications && medications['active_medications']) || []
      let array2 = (medications && medications['inactive_medications']) || []
      let array3 = (meds && meds['active_medications_provider']) || []
      let array4 = (meds && meds['inactive_medications_provider']) || []
      let newArray = [...array1, ...array2, ...array3, ...array4]
      console.log('test: ', meds['med_monitor'])
      this.setState({
        medMonitorData: newArray,
        medMonitorDataArray: meds['med_monitor'] || [],
      })
    } catch (err) {
      console.log('error when getting meds data', err)
      let array1 = (medications && medications['active_medications']) || []
      let array2 = (medications && medications['inactive_medications']) || []
      let newArray = [...array1, ...array2]
      this.setState({ medMonitorData: newArray })
    }
  }

  async getProviderAllergies() {
    let { patient, token } = this.props
    try {
      const getAllergy = async () => {
        let url =
          Config.BACKEND_URL + 'fhir/AllergyIntolerance?patient=' + patient?.uid
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: patient?.uid,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getAllergy()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let allergies = response?.data || []
        this.setState({ providerAllergies: allergies })
      }
    } catch (err) {
      console.log('error when getting allergies data', err)
    }
  }

  async getProviderFoodSensitivities() {
    let { patient, token } = this.props
    try {
      const getFood = async () => {
        let url =
          Config.BACKEND_URL +
          'fhir/FoodSensitivity?patient=' +
          this.state.patientId
        return axios({
          method: 'get',
          url: url,
          headers: {
            x_firebase_id: this.state.patientId,
            Authorization: 'JWT ' + token,
          },
        })
      }
      let response = await getFood()
      if (response?.data) {
        //if there is something in data, then the particle records for the patient has been pulled at least once
        let foods = response.data || []
        this.setState({ providerFoodSensitivities: foods })
      }
    } catch (err) {
      console.log('error when getting food sense data', err)
    }
  }

  getFamilyHistoryFormatted(relations) {
    if (!relations) return null
    let toReturn = []
    relations.forEach((each) => {
      let conditions = []
      if (each.conditions)
        conditions = each.conditions.map((x, index) => (
          <span key={index.toString()} style={{ width: '80%' }}>
            {x}
            {index === each.conditions.length - 1 ? '' : ', '}
          </span>
        ))
      toReturn.push(
        <div
          key={each.relationship}
          className={Styles.contentRow}
          style={{ padding: '10px 10px' }}
        >
          <p className={Styles.contentRowKey} style={{ width: '30%' }}>
            {each.relationship}:
          </p>
          <p
            style={{ marginLeft: 5, overflowWrap: 'break-word', width: '70%' }}
          >
            {conditions}
          </p>
        </div>,
      )
    })

    return toReturn
  }

  onManualAddClicked(addName, data, index, allData) {
    if (addName === 'goals') {
      this.setState({ modalIsOpen: true, modalGoals: true })
    }
    if (data) {
      this.setState({
        manualAddModalIsOpen: true,
        manualAddState: addName,
        currentAddModalData: data,
      })
    } else {
      this.setState({
        manualAddModalIsOpen: true,
        manualAddState: addName,
        currentAddModalData: null,
      })
    }
  }

  onManualAddModalHide() {
    this.setState({ manualAddModalIsOpen: false })
  }

  setActiveAccordions(val) {
    console.log('ACTIVE:', val)
    this.setState({
      activeAccordions: val,
    })
  }

  renderManualAddModalContent() {
    switch (this.state.manualAddState) {
      case 'conditions':
        return (
          <RenderAddConditionsManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderConditions={this.getConditionsData}
            currentAddModalData={this.state.currentAddModalData}
            getPatientConditions={this.props.fetchPatientConditions}
            patientId={this.state.patientId}
          />
        )
      case 'allergies':
        return (
          <RenderAddAllergiesManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderAllergies={this.getProviderAllergies}
            currentAddModalData={this.state.currentAddModalData}
            getPatientConditions={this.props.fetchPatientConditions}
            patientId={this.state.patientId}
          />
        )
      case 'foodsensitivities':
        return (
          <RenderAddFoodSensitivitiesManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderFoodSensitivities={this.getProviderFoodSensitivities}
            currentAddModalData={this.state.currentAddModalData}
            patientId={this.state.patientId}
          />
        )
      case 'medications':
        return (
          <RenderAddMedicationsManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderMedications={this.getProviderMedications}
            currentAddModalData={this.state.currentAddModalData}
            patientId={this.state.patientId}
          />
        )
      case 'vitamins':
        return (
          <RenderAddVitaminsManually
            onManualAddModalHide={this.onManualAddModalHide}
            getProviderMedications={this.getProviderMedications}
            currentAddModalData={this.state.currentAddModalData}
            patientId={this.state.patientId}
          />
        )
      // case 'goals':
      //   return (
      //     <RenderAddGoalsManually
      //       onManualAddModalHide={this.onManualAddModalHide}
      //       getProviderGoals={this.getProviderGoals}
      //       currentAddModalData={this.state.currentAddModalData}
      //     />
      //   )
      case 'familyHistory':
        return (
          <RenderAddFamilyHistoryManually
            onManualAddModalHide={this.onManualAddModalHide}
            getFamilyHistory={this.getPatientCoreData}
            currentAddModalData={this.state.currentAddModalData}
            patientId={this.state.patientId}
          />
        )

      default:
        return null
    }
  }

  renderBehavioralContent() {
    const { behavioralData } = this.state

    if (behavioralData) {
      return <Behavioral behavioralData={this.state.behavioralData} />
    } else {
      return null
    }
  }

  renderPatientConditions() {
    let conditions = this.state.conditionsData || []
    let conditionsElement =
      conditions &&
      conditions.map((condn, index) => (
        <div
          className={Styles.contentRow}
          key={index}
          style={{ justifyContent: 'space-between' }}
        >
          <p
            key={index.toString()}
            style={{ textAlign: 'left', padding: '2px 10px' }}
          >
            {condn.symptom ||
              condn?.code?.coding?.[0]?.display?.replace(/"/g, '')}
          </p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {condn?.code?.coding?.[0]?.display && !condn?.userEntered ? (
              <div style={{ marginRight: 10 }}>
                <FontAwesomeIcon
                  icon={faUserMd}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            ) : (
              <p>(user reported)</p>
            )}
            {(condn?.code?.coding?.[0]?.display || condn.symptom) && (
              <div
                onClick={() =>
                  this.onManualAddClicked(
                    'conditions',
                    condn,
                    index,
                    conditions,
                  )
                }
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            )}
          </div>
        </div>
      ))
    return (
      <div style={{ width: '48%', position: 'relative' }}>
        <p className={Styles.modalNotesHeaderText}>Conditions</p>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() => this.onManualAddClicked('conditions')}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {conditionsElement}
        </div>
      </div>
    )
  }

  renderICDOptions() {
    let conditions = this.state.conditionsData || []
    let conditionsElement =
      conditions &&
      conditions.map((condn, index) =>
        condn?.code?.coding?.[0]?.display && !condn?.userEntered ? (
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p
              key={index.toString()}
              style={{
                textAlign: 'left',
                padding: '2px 10px',
                fontSize: '16px',
              }}
            >
              {condn.symptom ||
                condn?.code?.coding?.[0]?.display?.replace(/"/g, '')}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{ marginRight: 10, cursor: 'pointer' }}
                onClick={() => {
                  this.setState(
                    (prevState) => ({
                      notesList: [
                        {
                          icd_10: {
                            icd10_code: condn?.code?.coding?.[0]?.code,
                            title: condn?.code?.coding?.[0]?.display?.replace(
                              /"/g,
                              '',
                            ),
                          },
                        },
                        ...prevState.notesList,
                      ],
                      addIcd10: {},
                      addNotes: '',
                      icd_code: '',
                      icd_code_desc: '',
                      icdSeachResult: [],
                      addCarePlan: '',
                    }),
                    () => {
                      this.saveAppointmentToRedux()
                      this.autoSaveNote()
                    },
                  )
                }}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            </div>
          </div>
        ) : null,
      )

    return (
      <>
        <div className={Styles.modalNotesRow}>
          <p className={Styles.modalNotesRegularText}>
            List of Previously Selected ICD-10
          </p>
        </div>
        <div className={Styles.modalNotesRow}>
          <div
            className={Styles.contentBackground}
            style={{ height: 150, overflowY: 'scroll', width: '100%' }}
          >
            {conditionsElement}
          </div>
        </div>
      </>
    )
  }

  renderCPTOptions() {
    let { mongoUser } = this.props

    const handleClick = (cpt) => {
      if (
        mongoUser?.user_type !== 'Administrator' &&
        mongoUser?.user_type !== 'Billing'
      ) {
        this.setState(
          (prevState) => ({
            addCpt: [
              ...prevState.addCpt,
              { cpt_code: cpt.CPTCode, title: cpt.CodeDescription },
            ],
          }),
          () => {
            this.autoSaveNote()
            this.saveAppointmentToRedux()
          },
        )
      } else {
        this.setState(
          (prevState) => ({
            addAmendCpt: [
              ...prevState.addAmendCpt,
              { cpt_code: cpt.CPTCode, title: cpt.CodeDescription },
            ],
          }),
          () => {
            this.autoSaveNote()
            this.saveAppointmentToRedux()
          },
        )
      }
    }

    let conditionsElement = cptList.map((cpt, index) => (
      <div
        className={Styles.contentRow}
        key={index}
        style={{ justifyContent: 'space-between' }}
      >
        <p
          key={index.toString()}
          style={{ textAlign: 'left', padding: '2px 10px' }}
        >
          {cpt.CPTCode}: {cpt.CodeDescription}
        </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{ marginRight: 10, cursor: 'pointer' }}
            onClick={() => handleClick(cpt)}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{ color: Constants.primaryTheme, fontSize: 25 }}
            />
          </div>
        </div>
      </div>
    ))

    return (
      <div className={Styles.modalNotesRow}>
        <div style={{ marginTop: 10, width: '100%', zIndex: 4 }}>
          <Select
            inputId="suggestedCpt"
            options={cptOptionList}
            getOptionValue={(option) => option.label}
            // value={}
            isSearchable
            autoBlur
            onChange={(val) => {
              handleClick(val)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        </div>
      </div>
    )
  }

  renderFamilyHistory() {
    let { patientCore } = this.state
    let familyScreening = patientCore && patientCore.screening_family
    let entries = []
    if (familyScreening) {
      entries = this.getFamilyHistoryFormatted(familyScreening.relations)
    }
    return (
      <div style={{ width: '48%', position: 'relative' }}>
        <p className={Styles.modalNotesHeaderText}>Family History</p>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() =>
              this.onManualAddClicked('familyHistory', familyScreening)
            }
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {entries}
        </div>
      </div>
    )
  }

  renderAllergies() {
    let { patientCore, providerAllergies } = this.state
    let allergies =
      patientCore && patientCore.allergies ? patientCore?.allergies : []
    let newAllergies = providerAllergies?.concat(allergies)
    let allergiesElement = newAllergies
      ? newAllergies?.map((allergy, index) => (
          <div
            className={Styles.contentRow}
            key={index}
            style={{ justifyContent: 'space-between' }}
          >
            <p key={index} style={{ textAlign: 'left', padding: '2px 10px' }}>
              {allergy?.code?.coding?.[0]?.display ||
                (allergy?.type &&
                  (allergy?.name?.length
                    ? allergy?.name?.join()
                    : allergy?.type)) ||
                allergy}
            </p>
            {allergy?.code?.coding?.[0]?.display && (
              <div
                onClick={() => this.onManualAddClicked('allergies', allergy)}
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </div>
            )}
          </div>
        ))
      : []
    return (
      <div style={{ width: '48%', position: 'relative' }}>
        <p className={Styles.modalNotesHeaderText}>Allergies</p>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() => this.onManualAddClicked('allergies')}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {allergiesElement}
        </div>
      </div>
    )
  }

  renderFoodSensitivities() {
    let { patientCore, providerFoodSensitivities } = this.state
    let foods =
      patientCore && patientCore.food_sensitivities
        ? patientCore.food_sensitivities.foods
        : []
    let newFoods = providerFoodSensitivities.concat(foods)
    let foodsElement =
      newFoods &&
      newFoods.map((food, index) => (
        <div
          className={Styles.contentRow}
          key={index}
          style={{ justifyContent: 'space-between' }}
        >
          <p
            key={index.toString()}
            style={{ textAlign: 'left', padding: '2px 10px' }}
          >
            {food?.code?.coding?.[0]?.display || food}
          </p>
          {food?.code?.coding?.[0]?.display && (
            <div
              onClick={() => this.onManualAddClicked('foodsensitivities', food)}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ color: Constants.primaryTheme, fontSize: 20 }}
              />
            </div>
          )}
        </div>
      ))
    return (
      <div style={{ width: '48%', position: 'relative' }}>
        <p className={Styles.modalNotesHeaderText}>Food Sensitivities</p>
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <FontAwesomeIcon
            onClick={() => this.onManualAddClicked('foodsensitivities')}
            icon={faPlusCircle}
            style={{
              color: Constants.primaryTheme,
              fontSize: 18,
              right: 14,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          className={Styles.contentBackground}
          style={{ height: 240, overflowY: 'scroll' }}
        >
          {foodsElement}
        </div>
      </div>
    )
  }

  renderMedications() {
    let { medications, providerMedications } = this.state
    function getMedicationsOrVitaminsRow(
      isMedications,
      active,
      onManualAddClicked,
    ) {
      let key1, key2
      // console.log("Meds: ", providerMedications)

      if (isMedications) {
        if (active) {
          key1 = 'active_medications'
          key2 = 'active_medications_provider'
        } else {
          key1 = 'inactive_medications'
          key2 = 'inactive_medications_provider'
        }
      } else {
        if (active) {
          key1 = 'active_vitamins'
          key2 = 'active_vitamins_provider'
        } else {
          key1 = 'inactive_vitamins'
          key2 = 'inactive_vitamins_provider'
        }
      }

      let array1 = (medications && medications[key1]) || []
      let array2 = (providerMedications && providerMedications[key2]) || []
      let newArray = array2.concat(array1)
      let toReturn = newArray.map((x, i) => {
        let activeString = 'Active'
        if (!active)
          activeString = 'Inactive since ' + moment(x.end).format('MMM Do YYYY')
        return (
          <div key={i.toString()} className={Styles.contentRow}>
            <p style={{ width: '35%', textAlign: 'center' }}>
              {x.dose?.quantity
                ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit})`
                : `${x.name}`}
            </p>
            {/*<p style={{width: '20%', textAlign: 'center'}}>{x.dose.quantity} {x.dose.unit}</p>*/}
            <p style={{ width: '15%', textAlign: 'center' }}>
              {x.frequency?.quantity
                ? `${x.frequency.quantity} / ${x.frequency.recurring}`
                : '-'}
            </p>
            <p style={{ width: '20%', textAlign: 'center' }}>
              {moment(x.start).format('MMM Do YYYY')}
            </p>
            <p style={{ width: '20%', textAlign: 'center' }}>{activeString}</p>
            {x.doctor_id && (
              <p
                onClick={() => {
                  isMedications
                    ? onManualAddClicked('medications', x)
                    : onManualAddClicked('vitamins', x)
                }}
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ color: Constants.primaryTheme, fontSize: 20 }}
                />
              </p>
            )}
          </div>
        )
      })

      return toReturn
    }

    function renderMedicationsOrVitamins(isMedications, onManualAddClicked) {
      let activeElement
      let inactiveElement
      activeElement = getMedicationsOrVitaminsRow(
        isMedications,
        true,
        onManualAddClicked,
      )
      inactiveElement = getMedicationsOrVitaminsRow(
        isMedications,
        false,
        onManualAddClicked,
      )

      return (
        <div style={{ width: '100%', position: 'relative' }}>
          <p className={Styles.modalNotesHeaderText}>
            {isMedications ? 'Medications' : 'Vitamins'}
          </p>
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <FontAwesomeIcon
              onClick={() => {
                isMedications
                  ? onManualAddClicked('medications')
                  : onManualAddClicked('vitamins')
              }}
              icon={faPlusCircle}
              style={{
                color: Constants.primaryTheme,
                fontSize: 18,
                right: 14,
                cursor: 'pointer',
              }}
            />
          </div>
          <div
            className={Styles.contentBackground}
            style={{ height: 340, overflowY: 'scroll' }}
          >
            <div className={Styles.contentRow} style={{ padding: '10px 10px' }}>
              <h6
                className={Styles.tableHeader}
                style={{ width: '35%', textAlign: 'center' }}
              >
                Name
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '15%', textAlign: 'center' }}
              >
                Dose
              </h6>
              {/*<h6 className={Styles.tableHeader}>Frequency</h6>*/}
              <h6
                className={Styles.tableHeader}
                style={{ width: '20%', textAlign: 'center' }}
              >
                Start
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '20%', textAlign: 'center' }}
              >
                Status
              </h6>
            </div>
            {activeElement}
            {inactiveElement}
          </div>
        </div>
      )
    }

    return (
      <div
        className={Styles.modalNotesRow}
        style={{ justifyContent: 'space-between' }}
      >
        <div style={{ width: '48%', position: 'relative' }}>
          {renderMedicationsOrVitamins(true, this.onManualAddClicked)}
        </div>

        <div style={{ width: '48%', position: 'relative' }}>
          {renderMedicationsOrVitamins(false, this.onManualAddClicked)}
        </div>
      </div>
    )
  }

  saveAppointmentToRedux = () => {
    let {
      notesList,
      addCpt,
      addAmendCpt,
      chiefConcern,
      providerChiefConcern,
      physicalExam,
      diagnosticReview,
      illness,
      providerIllness,
      reviewSystems,
      objectiveNote,
      assessmentNote,
      careplanNote,
      noIcdRequired,
      appointmentDate,
      appointmentEndDate,
      note_id,
      addNotes,
      addCarePlan,
      addIcd10,
      appointment_event_id,
      followupData,
      priorAuthRx,
      applyVisit,
      timeSpent,
      rpm,
      ccm,
      complex_care,
      careplanAmendedNote,
      authorizationNumber,
    } = this.state
    let saveData = {
      chiefConcern,
      providerChiefConcern,
      illness,
      providerIllness,
      reviewSystems,
      objectiveNote,
      assessmentNote,
      careplanNote,
      noIcdRequired,
      noIcdRequired,
      physicalExam,
      diagnosticReview,
      appointmentDate,
      appointmentEndDate,
      note_id,
      addCpt,
      addAmendCpt,
      notesList,
      addIcd10,
      addCarePlan,
      addNotes,
      appointment_event_id,
      followupData,
      priorAuthRx,
      applyVisit,
      timeSpent,
      rpm,
      ccm,
      complex_care,
      // Added this
      authorizationNumber,
      careplanAmendedNote,
    }
    // save to redux only for visit notes opened using video call buttons or if the note id match with video note id
    // Why is this logic here?
    if (
      this.props?.comingFrom === 'visit_video' ||
      this.props?.appointmentNotesData?.note_id === note_id
    ) {
      console.log('Saving data to the redux store')
      this.props.setAppointmentNotesData(saveData)
    }
  }

  autoSaveNote = () => {
    console.log('AUTO SAVE NOTES called::')
    let { mongoUser } = this.props
    let isHealthCoach =
      mongoUser?.user_type === 'Health Coach' ||
      mongoUser?.user_type === 'Wellness Coach' ||
      !this.state.appointmentDate
    this.noteSubmit(isHealthCoach, 'saved')
  }

  handleSort = () => {
    let _notesList = [...this.state.notesList]

    //remove and save the dragged item content
    const draggedItemContent = _notesList.splice(this.dragItem.current, 1)[0]

    //switch the position
    _notesList.splice(this.dragOverItem.current, 0, draggedItemContent)

    //reset the position ref
    this.dragItem.current = null
    this.dragOverItem.current = null

    //update the actual array
    this.setState({ notesList: _notesList }, () => {
      this.saveAppointmentToRedux()
      this.autoSaveNote()
    })
  }

  autoSaveFollowUp = () => {
    console.log('AUTO SAVE FOLLOW-UP called::')
    this.submitFollowUpStatus()
  }

  noteSubmit = (isHealthCoach, saveType) => {
    console.log('NOTES SUBMIT CALLED::: ')
    let {
      token,
      patient,
      docFirebaseAuthUser,
      mongoUser,
      navigateFrom,
      appointmentData,
    } = this.props
    let {
      notesList,
      addCpt,
      addAmendCpt,
      chiefConcern,
      providerChiefConcern,
      physicalExam,
      diagnosticReview,
      illness,
      providerIllness,
      reviewSystems,
      objectiveNote,
      assessmentNote,
      careplanNote,
      noIcdRequired,
      patientId,
      appointmentDate,
      appointmentEndDate,
      note_id,
      doctor_details,
      noteCreatedAt,
      appointment_event_id,
      followupData,
      priorAuthRx,
      applyVisit,
      timeSpent,
      updateFollowUp,
      addCarePlan,
      addIcd10,
      addNotes,
      rpm,
      ccm,
      complex_care,
      referCoach,
      careTeamData,
      authorizationNumber,
      careplanAmendedNote,
    } = this.state

    // if (followupData && appointment_event_id && updateFollowUp) {
    //   this.submitFollowUpStatus()
    // }
    console.log('NOTE ID: ', note_id)
    console.log('appointment_event_id:', appointment_event_id)

    let updateNotes = note_id.length ? true : false
    // unique id for each note
    if (!note_id.length) {
      note_id = note_id || docFirebaseAuthUser?.uid + Date.now()
      this.setState({ note_id: note_id }, () => {
        this.saveAppointmentToRedux()
      })
    }

    // if(!referCoach.value && saveType === 'completed' && !careTeamData.filter((doc) => doc.user_type === 'Health Coach').length) {
    //   toast.error('Please Select Refer to Coaching')
    //   return
    // }
    if (addNotes.length && addCarePlan.length) {
      if (
        !addIcd10.icd10_code &&
        saveType === 'completed' &&
        !followupData?.no_show
      ) {
        toast.error('Please add ICD-10 code')
        return
      }
      notesList = [
        {
          icd_10: addIcd10,
          // assessment: addNotes,
          // note: addCarePlan,
        },
        ...notesList,
      ]
    }
    // else if((addNotes.length || addCarePlan.length) && isHealthCoach) {
    //     notesList = [
    //       {
    //         assessment: addNotes,
    //         note: addCarePlan,
    //       },
    //       ...notesList,
    //     ]
    // }
    if (
      !notesList?.length &&
      saveType === 'completed' &&
      !followupData?.no_show
    ) {
      toast.error('Please add icd codes')
      return
    }
    // for (let i = 0; i < notesList?.length; i++) {
    //   if (!notesList?.[i]?.note?.length && saveType === 'completed') {
    //     toast.error(
    //       "Can't leave careplan note field empty, add careplan note",
    //     )
    //     return
    //   }
    // }
    if (!addCpt.length && saveType === 'completed' && !followupData?.no_show) {
      toast.error('Please add cpt code')
      return
    }
    // if (!chiefConcern.length && saveType === 'completed') {
    //   toast.error("Can't leave chief concern empty")
    //   return
    // }
    if (
      !providerChiefConcern.length &&
      saveType === 'completed' &&
      !followupData?.no_show
    ) {
      toast.error("Can't leave provider chief concern empty")
      return
    }
    if (
      appointment_event_id?.length &&
      !followupData?.follow_up_value &&
      !followupData?.no_follow_up &&
      saveType === 'completed' &&
      !followupData?.no_show
    ) {
      toast.error("Can't leave Follow Up In field empty")
      return
    }

    let tempNotesList = cloneDeep(notesList)
    let patientCondn = []
    // let tempNotesList = JSON.parse(JSON.stringify(notesList))

    // tempNotesList.unshift({
    //   icd_10: addIcd10,
    //   notes: addNotes,
    //   text: addCarePlan,
    // })
    // {noteData?.icd_10?.icd10_code}: {noteData?.icd_10?.title}
    let isEligibleRpm = false
    let eligibleCodes = []
    tempNotesList.forEach((note, index) => {
      if (
        note?.icd_10 &&
        !patient?.rpm &&
        RPM_Eligible_ICD.some((icd) => icd === note?.icd_10?.icd10_code)
      ) {
        isEligibleRpm = true
        eligibleCodes.push(note?.icd_10)
      }
      note?.icd_10 &&
        patientCondn.push({
          patient_id: patientId,
          doctor_id: docFirebaseAuthUser?.uid,
          resourceType: 'Condition',
          code: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: note?.icd_10?.icd10_code,
                display: note?.icd_10?.title,
              },
            ],
          },
          subject: {
            reference: `Patient/${patientId}`,
            // display: `${patient?.firstName} ${patient?.lastName}`
          },
          recorder: {
            reference: `${doctor_details.user_type}/${docFirebaseAuthUser?.uid}`,
            // display: `${doctor_details.first_name} ${doctor_details.last_name}`
          },
          recordedDate: new Date(),
        })
    })

    console.log('updateNotes:::::', updateNotes, this.state.careplan_id)
    // request to add multiple condns to patient profile automatically
    if (patientCondn?.length)
      axios({
        method: 'put',
        url: Config.BACKEND_URL + 'fhir/multiple-conditions',
        headers: {
          Authorization: 'JWT ' + token,
          x_doctor_id: docFirebaseAuthUser?.uid,
        },
        data: patientCondn,
      })
        .then(() => console.log('patient conditions added automatically'))
        .catch((err) =>
          console.log('error when adding patient condn to profile: ', err),
        )

    let careplanConfig = {
      method: 'put',
      url: Config.BACKEND_URL + 'provider/patients/notes',
      headers: {
        Authorization: 'JWT ' + token,
        x_doctor_id: docFirebaseAuthUser?.uid,
      },
      data: {
        cpt: addCpt,
        chief_concern: chiefConcern,
        provider_chief_concern: providerChiefConcern,
        present_illness: illness,
        provider_illness: providerIllness,
        review_systems: reviewSystems,
        objective_note: objectiveNote,
        assessment_note: assessmentNote,
        careplan_note: careplanNote,
        no_icd_required: noIcdRequired,
        physical_exam: physicalExam,
        diagnostic_review: diagnosticReview,
        prior_auth_rx: priorAuthRx,
        apply_visit: applyVisit,
        time_spent: timeSpent,
        careplan: tempNotesList,
        note_status: saveType,
        refer_coach: referCoach,
        followupData: followupData,
        appointment_date: appointmentDate,
        appointment_end_date: appointmentEndDate,
        rpm,
        ccm,
        complex_care,
        authorization_number: authorizationNumber,
        careplan_amended_note: careplanAmendedNote,
      },
    }
    if (
      mongoUser?.user_type === 'Administrator' ||
      mongoUser?.user_type === 'Billing'
    ) {
      careplanConfig.data.amended_cpt = addAmendCpt
    }
    if (!updateNotes) {
      careplanConfig.method = 'post'
      careplanConfig.data.patient_id = patientId
      careplanConfig.data.doctor_id = docFirebaseAuthUser?.uid
      careplanConfig.data.note_id = note_id
      if (appointment_event_id) {
        careplanConfig.data.appointment_date = appointmentDate
        careplanConfig.data.appointment_end_date = appointmentEndDate
        careplanConfig.data.appointment_event_id = appointment_event_id
      }
    } else {
      careplanConfig.headers.careplan_id = note_id
    }
    console.log('careplanConfig: ', careplanConfig)
    axios(careplanConfig)
      .then((result) => {
        // console.log('notes saved')
        // clearNotes()
        saveType === 'completed' && toast.success(`Assessment ${saveType}`)
        this.props?.fetchPatientCarePlanData &&
          this.props.fetchPatientCarePlanData({
            patient: this.props.patient,
            token: token,
            adminId: docFirebaseAuthUser?.uid,
            patientId: patientId,
          })
        // dont clear if the notes is opened using visit modal
        // if(this.props?.comingFrom !== 'visit_video')
        //   this.props.setAppointmentNotesData(null)
        //   },
        // )
        // this.setState({note_id: note_id})
        // this.props?.navigateFrom === 'notesModal' && (saveType === 'completed') && this.props.hideNotes()
        // console.log('coming from::: ', this.props?.comingFrom)
        if (this.props?.comingFrom === 'billing') {
          this.props.getUserCarePlan && this.props.getUserCarePlan()
        }
        // if(saveType === 'completed' &&
        //   referCoach?.value === 'yes' &&
        //   !referCoach.assigned_coach &&
        //   !careTeamData.filter((doc) => doc.user_type === 'Health Coach').length
        //   )
        //   this.setState({coachingPopModalIsOpen: true})
        // console.log('RPM ELIGIBLE: ', patient?.rpm, isEligibleRpm, saveType)
        if (isEligibleRpm && saveType === 'completed') {
          this.setState({
            rpmPopModalIsOpen: true,
            rpmEligibleCode: eligibleCodes,
          })
        }
      })
      .catch((err) => {
        console.log('error when updating notes', err)
      })
  }

  submitFollowUpStatus = () => {
    let {
      token,
      patient,
      docFirebaseAuthUser,
      mongoUser,
      navigateFrom,
      appointmentData,
    } = this.props
    let { patientId, appointment_event_id, followupData } = this.state
    console.log('FOLLOW UP DATA : ', {
      event_id: appointment_event_id,
      ...{ follow_up: followupData },
    })

    axios({
      method: 'put',
      url: `${Config.BACKEND_URL}appointment`,
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        event_id: appointment_event_id,
        ...{ follow_up: followupData },
      },
    })
      .then((result) => {
        console.log('updated no show')
        // toast.success('Saved No Show and Follow Up Data')
        this.setState({ updateFollowUp: false })
        // this.setState({ followupData: null })
        this.saveAppointmentToRedux()
        this.props.fetchPatientCarePlanData({
          patient: this.props.patient,
          token: token,
          adminId: docFirebaseAuthUser?.uid,
          patientId: patientId,
        })

        if (
          'follow_up_value' in followupData &&
          'follow_up_unit' in followupData
          // followupData?.follow_up_value &&
          // followupData?.follow_up_unit?.value
        ) {
          //CREATE ADMIN TASK
          let data = {
            priority: 'MEDIUM',
            subject: followupData?.refer_specialty
              ? `Need Appointment with ${followupData?.refer_specialty?.label}`
              : 'Need Appointment',
            description: `Please contact patient and schedule follow up visit with
               ${
                 followupData?.refer_specialty
                   ? followupData?.refer_specialty?.label
                   : this.props.mongoUser?.first_name +
                     ' ' +
                     this.props.mongoUser?.last_name
               }
               in ${followupData?.follow_up_value} ${
              followupData?.follow_up_unit?.label
            }.
               ${
                 followupData?.doctor_followup_note
                   ? 'Doctor Note: ' + followupData?.doctor_followup_note
                   : ''
               }`,
            firebase_id: this.props.mongoUser?.firebase_id,
            doctor_id: Config.ADMIN_ID,
            name:
              (this.props.mongoUser?.first_name || '') +
              ' ' +
              (this.props.mongoUser?.last_name || ''),
            email: this.props.mongoUser?.email,
            clinical_support: true,
            patient_id: patientId,
            due_date:
              moment()
                .add(
                  followupData?.follow_up_value,
                  followupData?.follow_up_unit?.value,
                )
                .format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
            userType: this.props.mongoUser.user_type,
            assigned_to: Config.ADMIN_ID,
          }

          // console.log('TASK ::', data)
          axios({
            method: 'post',
            url: Config.BACKEND_URL + 'patient/support',
            headers: {
              x_firebase_id: this.props.mongoUser?.firebase_id,
              Authorization: 'JWT ' + token,
            },
            data,
          })
            .then(({ data }) => {
              console.log('Success: Admin Task created')
              // toast.info('Task Created.\n It will take 48-72 hrs for the response.')
              // this.setState({
              //     providerOptionsData: [],
              //     dueDate: new Date(),
              //     priority: null,
              //     assignedProvider: null,
              //     taskType: null,
              //     desc: null,
              // })
              // this.props.hideTaskModal()
              // this.props.getUserTask()
            })
            .catch((err) => {
              console.log('err while creating task: ', err)
              // toast.error('Something went wrong while creating task.')
            })
        }
      })
      .catch((err) => {
        console.log('error when saving no show data', err)
        toast.error('Cannot save Follow Up Data, please try again')
      })
  }

  render() {
    let { token, patient, docFirebaseAuthUser, mongoUser, navigateFrom } =
      this.props
    // console.log('TESTING NOTESLIST: ', this.state.notesList)
    // let isHealthCoach = true
    let isHealthCoach =
      mongoUser?.user_type === 'Health Coach' ||
      mongoUser?.user_type === 'Wellness Coach' ||
      !this.state.appointmentDate
    // &&
    // navigateFrom === 'notesModal' &&
    // !this.state.notesList?.[0]?.icd_10?.icd10_code
    // const Hit = (props, type) => {
    //   let { hit } = props
    //   // let checked = handleChecked(q, hit.symptom)
    //   const handleClick = () => {
    //     console.log('tap:', mongoUser?.user_type)
    //     if (
    //       type === 'cpt' &&
    //       mongoUser?.user_type !== 'Administrator' &&
    //       mongoUser?.user_type !== 'Billing'
    //     ) {
    //       this.setState(
    //         (prevState) => ({
    //           addCpt: [
    //             ...prevState.addCpt,
    //             { cpt_code: hit.CPTCode, title: hit.CodeDescription },
    //           ],
    //           cptquery: '',
    //         }),
    //         () => {
    //           this.autoSaveNote()
    //           this.saveAppointmentToRedux()
    //         },
    //       )
    //     } else if (
    //       mongoUser?.user_type === 'Administrator' ||
    //       mongoUser?.user_type === 'Billing'
    //     ) {
    //       this.setState(
    //         (prevState) => ({
    //           addAmendCpt: [
    //             ...prevState.addAmendCpt,
    //             { cpt_code: hit.CPTCode, title: hit.CodeDescription },
    //           ],
    //           cptquery: '',
    //         }),
    //         () => {
    //           this.autoSaveNote()
    //           this.saveAppointmentToRedux()
    //         },
    //       )
    //     } else {
    //       eventFire(document.getElementById('icdclear'), 'click')
    //       this.setState(
    //         {
    //           addIcd10: { icd10_code: hit.code, title: hit.display },
    //           icd10query: '',
    //         },
    //         () => this.saveAppointmentToRedux(),
    //       )
    //     }
    //   }
    //   return (
    //     <div
    //       style={{ width: '100%', cursor: 'pointer' }}
    //       onClick={(e) => {
    //         handleClick(e)
    //       }}
    //     >
    //       {type === 'cpt'
    //         ? `${hit?.CPTCode}: ${hit?.CodeDescription}`
    //         : `${hit?.code}: ${hit?.display}`}
    //     </div>
    //   )
    // }
    const handleNoteChange = (event, noteType) => {
      this.setState(
        {
          [noteType]: event.target.value,
        },
        () => {
          this.autoSaveNote()
          this.saveAppointmentToRedux()
        },
      )
    }

    const clearNotes = () => {
      this.setState({
        addIcd10: {},
        addNotes: '',
        addCarePlan: '',
        addCpt: [],
        addAmendCpt: [],
        notesList: this.state?.tempNotesListData || [],
        chiefConcern: this.state?.tempNotesData?.reason || '',
        providerChiefConcern: this.state?.tempNotesData?.provider_reason || '',
        illness: this.state?.tempIllness,
        providerIllness: '',
        reviewSystems: '',
        objectiveNote: '',
        assessmentNote: '',
        careplanNote: '',
        noIcdRequired: false,
        physicalExam: this.state?.tempNotesData?.physical_exam || '',
        diagnosticReview: this.state?.tempNotesData?.diagnostic_review || '',
        priorAuthRx: { label: 'No', value: 'no' },
        applyVisit: [],
        timeSpent: {},
        note_id: '',
        icd10query: '',
        cptquery: '',
        appointmentDate: this.state?.tempNotesData?.start || null,
        appointmentEndDate: this.state?.tempNotesData?.end || null,
        appointment_event_id: this.state?.tempNotesData?.event_id || null,
        noteCreatedAt: new Date(),
        followupData: null,
      })
      this.props.setAppointmentNotesData(null)
    }

    const createAdminTask = () => {
      let { patientCore, provider, appointmentDate } = this.state
      let { token } = this.props
      // console.log('patientCore: ', patientCore)
      console.log('firebase_id', patientCore?.firebase_id)
      console.log('phone_number', patientCore?.phone_number)
      console.log(
        'doctor name',
        provider?.first_name + ' ' + provider?.last_name,
      )
      console.log('date', appointmentDate)
      console.log('token', token)
      console.log('->', this.props.mongoUser?.firebase_id)
      let patientName = patientCore?.first_name + ' ' + patientCore?.last_name
      let phoneNumber = patientCore?.phone_number
      let doctorName = provider?.first_name + ' ' + provider?.last_name
      let date = moment(appointmentDate).format('ll')
      let taskData = {
        priority: 'MEDIUM',
        subject: 'Patient No-Show Please Reschedule',
        description: `Call ${patientName} at ${phoneNumber} to Reschedule visit with ${doctorName}. The patient missed appointment with Care Member on ${date}.`,
        firebase_id: this.props.mongoUser?.firebase_id,
        doctor_id: Config.ADMIN_ID,
        name:
          (this.props.mongoUser?.first_name || '') +
          ' ' +
          (this.props.mongoUser?.last_name || ''),
        email: this.props.mongoUser?.email,
        clinical_support: true,
        patient_id: patientCore?.firebase_id,
        due_date: moment().add(1, 'd').format('YYYY-MM-DD'),
        userType: this.props.mongoUser.user_type,
        assigned_to: Config.ADMIN_ID,
      }

      // console.log('TASK ::', data)
      axios({
        method: 'post',
        url: Config.BACKEND_URL + 'patient/support',
        headers: {
          x_firebase_id: this.props.mongoUser?.firebase_id,
          Authorization: 'JWT ' + token,
        },
        data: taskData,
      })
        .then(({ data }) => {
          console.log('Success: Admin Task created')
        })
        .catch((err) => {
          console.log('err while creating task: ', err)
        })
    }

    const onNoShowClick = () => {
      let alertSent =
        "You've selected no-show for this patient, please confirm that this patient is a no-show.  If the patient was a no-show, there will be an email and billing sent to the patient regarding payment and rescheduling. You cannot undo this!"
      if (this.state.followupData?.no_show) {
        return
        // alertSent =
        //   "You've selected NOT a no-show for this patient, please confirm that this patient is not a no-show."
      }
      if (window.confirm(alertSent)) {
        let temp = { ...this.state.followupData }
        temp.no_show = !this.state.followupData?.no_show
        this.setState(
          {
            followupData: temp,
            updateFollowUp: true,
          },
          () => {
            this.autoSaveFollowUp()
            this.autoSaveNote()
            createAdminTask()
          },
        )
      }
    }

    // BUG: No lab orders are showing up - i think i've fixed this check again
    const renderLabsErxOrders = () => {
      let { labErxOrdersData } = this.state
      // console.log('labErxOrdersData', labErxOrdersData)
      let labOrdersElements = []
      let erxOrdersElements = []
      let labOrderData = labErxOrdersData?.lab_orders || []
      let erxOrders = labErxOrdersData.erx_orders || []
      console.log('labErxOrdersData', labErxOrdersData)
      // console.log('labOrderData', labOrderData)

      // We are adding elements to labOrdersData, why are we checking if there was erx lab orders made?
      if (labErxOrdersData) {
        labOrderData.length &&
          labOrderData.forEach((lab_orders) => {
            let tests = lab_orders?.tests || []
            tests.length &&
              tests.forEach((test) => {
                labOrdersElements.push(
                  <div
                    // onClick={() => this.onModalLabOrderClicked(x)}
                    // style={{cursor: 'pointer'}}
                    className={Styles.row}
                  >
                    <p className={Styles.entryText} style={{ width: '60%' }}>
                      {test.name} (lab)
                    </p>
                    <p className={Styles.entryText} style={{ width: '40%' }}>
                      {moment(lab_orders?.created_at).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </p>
                  </div>,
                )
              })
          })
        erxOrders.length &&
          erxOrders.forEach((lab_orders) => {
            let drug = lab_orders?.drug
            erxOrdersElements.push(
              <div
                // onClick={() => this.onModalLabOrderClicked(x)}
                // style={{cursor: 'pointer'}}
                className={Styles.row}
              >
                <p className={Styles.entryText} style={{ width: '60%' }}>
                  {drug?.name} (erx)
                </p>
                <p className={Styles.entryText} style={{ width: '40%' }}>
                  {moment(lab_orders?.created_at).format('YYYY-MM-DD HH:mm')}
                </p>
              </div>,
            )
          })
        // console.log('labOrdersElements: ', labOrdersElements)
      }

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '30px',
          }}
        >
          <div className={Styles.modalNotesRowSingle} style={{ padding: 0 }}>
            <p
              className={Styles.modalNotesQuestionSingle}
              style={{ marginLeft: 0 }}
            >
              Lab Orders:{' '}
            </p>
          </div>
          <div style={{ margin: 'auto', paddingBottom: 20, width: '90%' }}>
            <Link
              to={{
                pathname: '/orders',
                state: 'labs',
                patient_id: this.state.patientId,
                // This is the variable that links the lab orders to the careplan note
                orders_appointment_event_id: this.state.appointment_event_id,
                note_icds: this.state.notesList,
                key: 2,
              }}
            >
              <FontAwesomeIcon
                className={Styles.plusButton}
                style={{
                  display: 'flex',
                  float: 'right',
                  fontSize: '20px',
                  top: '30px',
                }}
                onClick={() => {
                  console.log("Clicked on 'Add Lab Order' going to orders page")
                }}
                icon={faPlusCircle}
              />
            </Link>
            <div className={Styles.row}>
              {labOrdersElements.length === 0 ? (
                <p
                  className={Styles.modalNotesHeaderText}
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  <Link
                    className={Styles.modalNotesHeaderText}
                    onClick={() => {}}
                    to={{
                      pathname: '/orders',
                      state: 'labs',
                      patient_id: this.state.patientId,
                      // This is the variable that links the lab orders to the careplan note
                      orders_appointment_event_id:
                        this.state.appointment_event_id,
                      note_icds: this.state.notesList,
                      key: 2,
                    }}
                  >
                    No lab orders
                  </Link>
                </p>
              ) : (
                <>
                  <p
                    className={Styles.modalNotesHeaderText}
                    style={{ width: '60%' }}
                  >
                    Test(s)
                  </p>
                  <p
                    className={Styles.modalNotesHeaderText}
                    style={{ width: '40%' }}
                  >
                    Date
                  </p>
                </>
              )}
            </div>
            {labOrdersElements}
          </div>
          <div className={Styles.modalNotesRowSingle} style={{ padding: 0 }}>
            <p
              className={Styles.modalNotesQuestionSingle}
              style={{ marginLeft: 0 }}
            >
              Rx Orders:{' '}
            </p>
          </div>
          <div style={{ margin: 'auto', paddingBottom: 20, width: '90%' }}>
            <div
              // to={{
              //   pathname: '/orders',
              //   state: 'erx',
              //   patient_id: this.state.patientId,
              //   key: 2,
              // }}
              onClick={() => {
                console.log('EE:', this.state.notesList)
                this.setState({
                  rxOrderModalIsOpen: true,
                })
              }}
            >
              <FontAwesomeIcon
                className={Styles.plusButton}
                style={{
                  display: 'flex',
                  float: 'right',
                  fontSize: '20px',
                  top: '30px',
                }}
                onClick={() => {
                  // Higher order element has the set state to open modal
                  console.log()
                }}
                icon={faPlusCircle}
              />
            </div>
            <div className={Styles.row}>
              {erxOrdersElements.length === 0 ? (
                <p
                  className={Styles.modalNotesHeaderText}
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  <div
                    className={Styles.modalNotesHeaderText}
                    onClick={() => {
                      console.log('OO:', this.state.notesList)
                      this.setState({
                        rxOrderModalIsOpen: true,
                      })
                    }}
                  >
                    No erx orders
                  </div>
                </p>
              ) : (
                <>
                  <p
                    className={Styles.modalNotesHeaderText}
                    style={{ width: '60%' }}
                  >
                    Drug(s)
                  </p>
                  <p
                    className={Styles.modalNotesHeaderText}
                    style={{ width: '40%' }}
                  >
                    Date
                  </p>
                </>
              )}
            </div>
            {erxOrdersElements}
          </div>
        </div>
      )
    }

    if (this.state.loading) {
      return (
        <div
          className={GlobalStyles.container}
          style={{ overflow: 'hidden', maxHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )
    }

    const renderRPM = () => {
      return (
        <>
          <div className={Styles.modalNotesRow}>
            <p className={Styles.modalNotesQuestion}> </p>
            <div
              style={{
                width: '67%',
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <p
                style={{ width: '13%', textAlign: 'center', marginTop: '10px' }}
              >
                RPM
              </p>
              <p
                style={{ width: '13%', textAlign: 'center', marginTop: '10px' }}
              >
                CCM
              </p>
              <p
                style={{ width: '13%', textAlign: 'center', marginTop: '10px' }}
              >
                Complex Care
              </p>
            </div>
          </div>
          <div className={Styles.modalNotesRow}>
            <p className={Styles.modalNotesQuestion}>
              Eligible for remote monitoring?{' '}
            </p>
            <div
              style={{
                width: '67%',
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.rpm?.is_eligible || false}
                  onClick={() => {
                    let temp = this.state.rpm
                    temp.is_eligible = !temp.is_eligible
                    this.setState(
                      {
                        rpm: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.ccm?.is_eligible || false}
                  onClick={() => {
                    let temp = this.state.ccm
                    temp.is_eligible = !temp.is_eligible
                    this.setState(
                      {
                        ccm: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.complex_care?.is_eligible || false}
                  onClick={() => {
                    let temp = this.state.complex_care
                    temp.is_eligible = !temp.is_eligible
                    this.setState(
                      {
                        complex_care: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              {AccessEligilityGuide()}
            </div>
          </div>
          <div className={Styles.modalNotesRow}>
            <p className={Styles.modalNotesQuestion}>
              Explained the required information about RPM, CCM, or Complex Care
              Management *:{' '}
            </p>
            <div
              style={{
                width: '67%',
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.rpm?.is_explained || false}
                  onClick={() => {
                    let temp = this.state.rpm
                    temp.is_explained = !temp.is_explained
                    this.setState(
                      {
                        rpm: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.ccm?.is_explained || false}
                  onClick={() => {
                    let temp = this.state.ccm
                    temp.is_explained = !temp.is_explained
                    this.setState(
                      {
                        ccm: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.complex_care?.is_explained || false}
                  onClick={() => {
                    let temp = this.state.complex_care
                    temp.is_explained = !temp.is_explained
                    this.setState(
                      {
                        complex_care: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              {DiscussionGuide('RPM')}
            </div>
          </div>
          <div className={Styles.modalNotesRow}>
            <p className={Styles.modalNotesQuestion}>
              Received Patient Consent for RPM Services*:{' '}
            </p>
            <div
              style={{
                width: '67%',
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.rpm?.is_received_consent || false}
                  onClick={() => {
                    let temp = this.state.rpm
                    temp.is_received_consent = !temp.is_received_consent
                    this.setState(
                      {
                        rpm: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.ccm?.is_received_consent || false}
                  onClick={() => {
                    let temp = this.state.ccm
                    temp.is_received_consent = !temp.is_received_consent
                    this.setState(
                      {
                        ccm: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={
                    this.state.complex_care?.is_received_consent || false
                  }
                  onClick={() => {
                    let temp = this.state.complex_care
                    temp.is_received_consent = !temp.is_received_consent
                    this.setState(
                      {
                        complex_care: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
            </div>
          </div>
          <div className={Styles.modalNotesRow}>
            <p className={Styles.modalNotesQuestion}>
              Enroll Patient in remote monitoring program? (select one):{' '}
            </p>
            <div
              style={{
                width: '67%',
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.rpm?.is_enrolled || false}
                  onClick={() => {
                    let temp = this.state.rpm
                    temp.is_enrolled = !this.state.rpm?.is_enrolled
                    this.setState(
                      {
                        rpm: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.ccm?.is_enrolled || false}
                  onClick={() => {
                    let temp = this.state.ccm
                    temp.is_enrolled = !this.state.ccm?.is_enrolled
                    this.setState(
                      {
                        ccm: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
              <label
                style={{ color: '#20A892', margin: '20px' }}
                class={Styles.checkContainer}
              >
                <input
                  type="checkbox"
                  checked={this.state.complex_care?.is_enrolled || false}
                  onClick={() => {
                    let temp = this.state.complex_care
                    temp.is_enrolled = !this.state.complex_care?.is_enrolled
                    this.setState(
                      {
                        complex_care: temp,
                      },
                      () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      },
                    )
                  }}
                />
                <span class={Styles.checkmark}></span>
                Yes
              </label>
            </div>
          </div>
          <div className={Styles.modalNotesRow}>
            <div className={Styles.modalNotesQuestion}>
              <p className={Styles.modalNotesHeaderText}>
                Detailed History of Condition:
              </p>
            </div>
            <div className={Styles.modalNotesAnswer}>
              <textarea
                rows="1"
                placeholder={
                  'Detailed history of condition-Nature and severity of condition'
                }
                className={Styles.modalInputStyle}
                type="text"
                value={this.state.rmp?.detailed_history}
                onChange={(e) => {
                  let temp = this.state.rpm
                  temp.detailed_history = e.target.value
                  this.setState({ rmp: temp }, () => {
                    this.saveAppointmentToRedux()
                    this.autoSaveNote()
                  })
                }}
              />
            </div>
          </div>
          <div className={Styles.modalNotesRow}>
            <div className={Styles.modalNotesQuestion}>
              <p className={Styles.modalNotesHeaderText}>
                Goals to be achieved by RPM, CCM, or Complex Care Management:
              </p>
            </div>
            <div className={Styles.modalNotesAnswer}>
              <textarea
                rows="1"
                placeholder={
                  'Detailed history of condition-Nature and severity of condition'
                }
                className={Styles.modalInputStyle}
                type="text"
                value={this.state.rmp?.goals}
                onChange={(e) => {
                  let temp = this.state.rpm
                  temp.goals = e.target.value
                  this.setState({ rmp: temp }, () => {
                    this.saveAppointmentToRedux()
                    this.autoSaveNote()
                  })
                }}
              />
            </div>
          </div>
        </>
      )
    }

    const renderCCM = () => {
      return (
        <>
          <div className={Styles.modalNotesRow}>
            <div className={Styles.modalNotesQuestion}>
              <p className={Styles.modalNotesHeaderText}>
                Referring Physician:
              </p>
            </div>
            <div className={Styles.modalNotesAnswer}>
              <input
                placeholder={'Physician Name'}
                className={Styles.modalInputStyle}
                style={{ width: '100%' }}
                disabled={true}
                type="text"
                value={
                  this.state.ccm.referring_phy ||
                  (this.state?.provider?.first_name ||
                    this.props.mongoUser?.first_name ||
                    '') +
                    ' ' +
                    (this.state?.provider?.last_name ||
                      this.props.mongoUser?.last_name ||
                      '')
                }
              />
            </div>
          </div>
          <div className={Styles.modalNotesRow}>
            <div className={Styles.modalNotesQuestion}>
              <p className={Styles.modalNotesHeaderText}>Assign Monitor:</p>
            </div>
            <div className={Styles.modalNotesAnswer}>
              {/* <div style={{width: '100%'}}> */}
              <Select
                inputId="interpreter"
                options={this.state.providerOptionsData}
                value={this.state.ccm?.assigned_monitor}
                isSearchable
                autoBlur
                onChange={(val) => {
                  let temp = { ...this.state.assigned_monitor }
                  temp.assigned_monitor = val
                  this.setState(
                    {
                      assigned_monitor: temp,
                    },
                    () => {
                      this.saveAppointmentToRedux()
                      this.autoSaveNote()
                    },
                  )
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
              {/* </div> */}
            </div>
          </div>

          <div className={Styles.modalNotesRow}>
            <div className={Styles.modalNotesQuestion}>
              <p className={Styles.modalNotesHeaderText}>
                Preferred mode of contact:
              </p>
            </div>
            <div className={Styles.modalNotesAnswer}>
              <Select
                inputId="interpreter"
                options={[
                  { label: 'Email', value: 'Email' },
                  { label: 'Phone', value: 'Phone' },
                ]}
                value={this.state.ccm?.preferred_mode}
                isSearchable
                autoBlur
                onChange={(val) => {
                  let temp = { ...this.state.preferred_mode }
                  temp.preferred_mode = val
                  this.setState(
                    {
                      preferred_mode: temp,
                    },
                    () => {
                      this.saveAppointmentToRedux()
                      this.autoSaveNote()
                    },
                  )
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>

          {/* <div className={Styles.modalNotesRowSingle}>
            <p className={Styles.modalNotesQuestionSingle}>Comprehensive Care Plan:</p>
          </div>
          <div
            className={Styles.modalValueValue}
            style={{ overflow: 'auto', alignSelf: 'start', marginLeft: 30 }}
          >
          {
            this.state?.conditionsData.map((x, index) => (
              <span
                key={index.toString()}
                style={{ textAlign: 'left', padding: '2px 4px' }}
              >
                {index + 1}
                {'. '}
                {(x.code ? x.code.coding[0].display : x.symptom)?.replace(',', '')}{' '}
                {x.code && (
                  <FontAwesomeIcon
                    icon={faUserMd}
                    style={{ color: Constants.primaryTheme, fontSize: 'larger' }}
                  />
                )}
                {index !== this.state.conditionsData.length - 1 && ' '} <br />
              </span>
            ))
          }
          </div> */}
        </>
      )
    }

    let BMI =
      Helpers.calculateBMIValues(
        this.state.patientCore.height,
        this.state.patientCore.weight_update || this.state.patientCore.weight,
        this.state.patientCore.gender,
        moment().diff(
          moment(this.state?.patientCore?.dob, 'DD/MM/YYYY'),
          'years',
        ),
      ) || 'N/A'

    const renderCoachingPopContent = () => {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            style={{ position: 'absolute' }}
            onClick={() => this.setState({ coachingPopModalIsOpen: false })}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            Refer to Coaching
          </h4>
          <div className={Styles.modalNotesRow}>
            <p className={Styles.modalNotesQuestion}>Reason for referral: </p>
            <textarea
              rows="1"
              placeholder={'Please Enter Reason'}
              style={{
                border: '1px solid #d0d0d0',
                padding: '5px 20px',
                borderRadius: '4px',
                marginTop: '10px',
                width: '67%',
              }}
              type="text"
              value={this.state.referCoach.reason}
              onChange={(e) => {
                let temp = { ...this.state.referCoach }
                temp.reason = e.target.value
                this.setState({ referCoach: temp })
              }}
            />
          </div>
          <div className={Styles.modalNotesRow}>
            <p className={Styles.modalNotesQuestion}>Assign Health Coach: </p>
            <div style={{ width: '67%', marginTop: 10 }}>
              <Select
                inputId="interpreter"
                options={this.state.providerOptionsData.filter(
                  (doc) => doc.label === 'Health Coach',
                )}
                value={this.state.referCoach?.assigned_coach}
                isSearchable
                autoBlur
                onChange={(val) => {
                  let temp = { ...this.state.referCoach }
                  temp.assigned_coach = val
                  this.setState({
                    referCoach: temp,
                  })
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                this.setState({ coachingPopModalIsOpen: false })
                this.noteSubmit()
              }}
              className={GlobalStyles.button}
              style={{ width: 300, margin: '4%' }}
              variant="primary"
            >
              Refer
            </Button>
          </div>
        </div>
      )
    }

    const handleMedMonitorSave = () => {
      let { medMonitorData, patientId } = this.state
      const { docFirebaseAuthUser } = this.props
      // console.log(docFirebaseAuthUser?.uid, medMonitorData, patientId)
      // send the data as array to store in the med_monitor data if score and monitor_note is present for med
      let medData = medMonitorData
        .filter((med) => med.score || med.monitor_note)
        .map((data) => {
          return {
            name: data.name,
            score: data.score || 0,
            monitor_note: data.monitor_note || 'N/A',
            created_at: moment().toISOString(),
          }
        })
      axios({
        method: 'put',
        url: `${Config.BACKEND_URL}fhir/MedicationMonitor`,
        headers: {
          Authorization: `JWT ${token}`,
          x_firebase_id: docFirebaseAuthUser?.uid,
        },
        data: {
          med_monitor: medData,
          firebase_id: patientId,
        },
      })
        .then(({ data }) => {
          console.log('successfully added user details to mongo backend')
          this.setState({ medMonitorModalIsOpen: false })
          this.getMedications()
        })
        .catch((err) => {
          console.log('error when adding doctor data to backend', err)
        })
    }
    const renderMedMonitorModalContent = () => {
      let { medMonitorData } = this.state

      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            style={{ position: 'absolute' }}
            onClick={() => this.setState({ medMonitorModalIsOpen: false })}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            Medication Monitoring
          </h4>

          <div
            className={Styles.contentBackground}
            style={{ height: '80%', overflowY: 'scroll', width: '100%' }}
          >
            <div className={Styles.contentRow} style={{ padding: '10px 10px' }}>
              <h6
                className={Styles.tableHeader}
                style={{ width: '25%', textAlign: 'center' }}
              >
                Name
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '10%', textAlign: 'center' }}
              >
                Dose
              </h6>
              {/*<h6 className={Styles.tableHeader}>Frequency</h6>*/}
              <h6
                className={Styles.tableHeader}
                style={{ width: '15%', textAlign: 'center' }}
              >
                Start
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '15%', textAlign: 'center' }}
              >
                Status
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '10%', textAlign: 'center' }}
              >
                Patient Response Score
              </h6>
              <h6
                className={Styles.tableHeader}
                style={{ width: '25%', textAlign: 'center' }}
              >
                Notes
              </h6>
            </div>
            {medMonitorData.map((x, i) => {
              let activeString = 'Active'
              if (x.end)
                activeString =
                  'Inactive since ' + moment(x.end).format('MMM Do YYYY')
              return (
                <div key={i.toString()} className={Styles.contentRow}>
                  <p style={{ width: '25%', textAlign: 'center' }}>
                    {x.dose?.quantity
                      ? `${x.name} (${x.dose?.quantity} ${x.dose?.unit})`
                      : `${x.name}`}
                  </p>
                  {/*<p style={{width: '20%', textAlign: 'center'}}>{x.dose.quantity} {x.dose.unit}</p>*/}
                  <p style={{ width: '10%', textAlign: 'center' }}>
                    {x.frequency?.quantity
                      ? `${x.frequency.quantity} / ${x.frequency.recurring}`
                      : '-'}
                  </p>
                  <p style={{ width: '15%', textAlign: 'center' }}>
                    {moment(x.start).format('MMM Do YYYY')}
                  </p>
                  <p style={{ width: '15%', textAlign: 'center' }}>
                    {activeString}
                  </p>
                  <div style={{ width: '10%', padding: 5 }}>
                    <Select
                      inputId="score"
                      options={[
                        { label: 1, value: 1 },
                        { label: 2, value: 2 },
                        { label: 3, value: 3 },
                        { label: 4, value: 4 },
                        { label: 5, value: 5 },
                        { label: 6, value: 6 },
                        { label: 7, value: 7 },
                        { label: 8, value: 8 },
                        { label: 9, value: 9 },
                        { label: 10, value: 10 },
                      ]}
                      value={[
                        {
                          label: x?.score || 'Select',
                          value: x?.score || 'Select',
                        },
                      ]}
                      isSearchable
                      autoBlur
                      onChange={(val) => {
                        let temp = medMonitorData
                        temp[i].score = val.value
                        this.setState({ medMonitorData: temp })
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                  <textarea
                    style={{ width: '25%', padding: 5 }}
                    rows="2"
                    cols="40"
                    onChange={(e) => {
                      let temp = medMonitorData
                      temp[i].monitor_note = e.target.value
                      this.setState({ medMonitorData: temp })
                    }}
                    value={x?.monitor_note || ''}
                  />
                </div>
              )
            })}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                handleMedMonitorSave()
              }}
              className={GlobalStyles.button}
              style={{ width: 300, margin: '4%' }}
              variant="primary"
            >
              Save
            </Button>
          </div>
        </div>
      )
    }

    const renderRPMEligiblePopContent = () => {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            className={Styles.closeModalBtn}
            style={{ position: 'absolute' }}
            onClick={() => this.setState({ rpmPopModalIsOpen: false })}
          >
            X
          </p>
          <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
            Refer to RPM
          </h4>
          <div className={Styles.modalNotesRow}>
            <p>
              Patient has RPM-eligible diagnosis (
              {this.state.rpmEligibleCode.map(
                (code) => code.icd10_code + '-' + code.title + ',',
              )}
              ), please consider ordering RPM devices for this patient.{' '}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link
              to={{
                pathname: '/orders',
                state: 'rpmorders',
                patient_id: this.state.patientId,
                key: 2,
              }}
            >
              <Button
                onClick={() => {
                  this.setState({ rpmPopModalIsOpen: false })
                }}
                className={GlobalStyles.button}
                style={{ width: 300, margin: '4%' }}
                variant="primary"
              >
                Order RPM Now
              </Button>
            </Link>
            <Button
              onClick={() => {
                this.setState({ rpmPopModalIsOpen: false })
              }}
              className={GlobalStyles.button}
              style={{ width: 300, margin: '4%' }}
              variant="primary"
            >
              No, Complete Note
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div style={{ width: '100%' }}>
        {/* <Modal
          ariaHideApp={false}
          isOpen={this.state.manualAddModalIsOpen}
          onRequestClose={() => this.setState({ manualAddModalIsOpen: false })}
          style={manualAddModalStyles}
          contentLabel="Modal"
        > */}
        <BSModal
          show={this.state.manualAddModalIsOpen}
          onHide={() => this.setState({ manualAddModalIsOpen: false })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{zIndex: 2000}}
          // dialogClassName={Styles.taskModalContainer}
        >
          <div
            style={{
              height: '70vh',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'scroll',
              overflow: 'auto',
              padding: 15,
            }}
          >
            {this.renderManualAddModalContent()}
          </div>
          {/* </Modal> */}
        </BSModal>

        <BSModal
          show={this.state.coachingPopModalIsOpen}
          onHide={() => this.setState({ coachingPopModalIsOpen: false })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{zIndex: 2000}}
          // dialogClassName={Styles.taskModalContainer}
        >
          <div
            style={{
              height: '70vh',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'scroll',
              overflow: 'auto',
              padding: 15,
            }}
          >
            {renderCoachingPopContent()}
          </div>
          {/* </Modal> */}
        </BSModal>

        <BSModal
          show={this.state.rpmPopModalIsOpen}
          onHide={() => this.setState({ rpmPopModalIsOpen: false })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{zIndex: 2000}}
          // dialogClassName={Styles.taskModalContainer}
        >
          <div
            style={{
              height: '50vh',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'scroll',
              overflow: 'auto',
              padding: 15,
            }}
          >
            {renderRPMEligiblePopContent()}
          </div>
          {/* </Modal> */}
        </BSModal>

        <BSModal
          show={this.state.medMonitorModalIsOpen}
          onHide={() => this.setState({ medMonitorModalIsOpen: false })}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{zIndex: 2000}}
          // dialogClassName={Styles.taskModalContainer}
        >
          <div
            style={{
              height: '70vh',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'scroll',
              overflow: 'auto',
              padding: 15,
            }}
          >
            {renderMedMonitorModalContent()}
          </div>
          {/* </Modal> */}
        </BSModal>
        <BSModal
          show={this.state.rxOrderModalIsOpen}
          onHide={() => this.setState({ rxOrderModalIsOpen: false })}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{zIndex: 2000}}
          // dialogClassName={Styles.taskModalContainer}
        >
          <div
            style={{
              height: '70vh',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'scroll',
              overflow: 'auto',
              padding: 15,
            }}
          >
            <p
              className={Styles.closeModalBtn}
              style={{ position: 'absolute' }}
              onClick={() => this.setState({ rxOrderModalIsOpen: false })}
            >
              X
            </p>
            <h4 style={{ color: Constants.primaryTheme, textAlign: 'center' }}>
              RX Order
            </h4>
            <ERXTab
              patientId={this.state.patientId}
              token={this.props.token}
              providerId={this.props.adminId}
              mongoUser={this.props.mongoUser}
              icds={this.state.notesList}
              patient={{
                ...this.state.patientCore,
                ...this.props.patient,
              }}
              appointmentId={this.state.appointment_event_id}
            />
          </div>
          {/* </Modal> */}
        </BSModal>

        <div style={{ margin: '10px 20px', overflow: 'auto' }}>
          <div style={{ float: 'left' }}>
            <input
              type="text"
              className={Styles.modalInputStyle}
              placeholder="Enter authorization number"
              value={this.state.authorizationNumber}
              onChange={(e) => {
                this.setState({ authorizationNumber: e.target.value }, () => {
                  console.log('The authorization number is', e.target.value)
                  this.saveAppointmentToRedux()
                  this.autoSaveNote()
                })
              }}
            />
          </div>
          <div style={{ float: 'right' }}>
            <Button
              onClick={() => clearNotes()}
              className={Styles.saveButton}
              style={{ width: 140 }}
              variant="primary"
            >
              Clear notes
            </Button>
          </div>
        </div>

        {this.state.appointmentDate && (
          <div className={Styles.modalNotesRow}>
            <div className={Styles.modalNotesQuestion}>
              <label style={{ color: 'red' }} className={Styles.checkContainer}>
                No Show
                {/* <p className={Styles.modalNotesQuestion}>No Show </p> */}
                <input
                  type="checkbox"
                  checked={this.state.followupData?.no_show || false}
                  onClick={() => onNoShowClick()}
                />
                <span className={Styles.checkmark}></span>
              </label>
            </div>
            <hr />
          </div>
        )}

        <hr />
        <Accordion
          defaultActiveKey={this.state.activeAccordions}
          alwaysOpen
          onSelect={(e) => this.setActiveAccordions(e)}
        >
          <Accordion.Item eventKey="0">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              SUBJECTIVE
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('0')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>
                  Chief Complaint/Chief Concern:
                </p>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Patient Reported:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Please Enter Chief Concern'}
                    className={Styles.modalInputStyle}
                    type="text"
                    value={this.state.chiefConcern}
                    onChange={(e) =>
                      this.setState({ chiefConcern: e.target.value }, () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      })
                    }
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Provider Reported:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Please Enter Chief Concern'}
                    // className={Styles.modalNotesQuestionSingle}
                    className={Styles.modalInputStyle}
                    type="text"
                    value={this.state.providerChiefConcern}
                    onChange={(e) =>
                      this.setState(
                        { providerChiefConcern: e.target.value },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }
                  />
                </div>
              </div>

              <hr />

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>
                  History of Illness
                </p>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Patient Reported:
                  </p>
                </div>

                <div className={Styles.modalNotesAnswer}>
                  <textarea
                    rows="2"
                    placeholder={'Please Enter Present Illness'}
                    className={Styles.modalInputStyle}
                    type="text"
                    value={this.state.illness}
                    onChange={(e) =>
                      this.setState({ illness: e.target.value }, () => {
                        this.autoSaveNote()
                        this.saveAppointmentToRedux()
                      })
                    }
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Provider Reported:
                  </p>
                </div>

                <div className={Styles.modalNotesAnswer}>
                  <textarea
                    rows="2"
                    placeholder={'Please Enter Present Illness'}
                    className={Styles.modalInputStyle}
                    type="text"
                    value={this.state.providerIllness}
                    onChange={(e) =>
                      this.setState({ providerIllness: e.target.value }, () => {
                        this.autoSaveNote()
                        this.saveAppointmentToRedux()
                      })
                    }
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <hr />

          <Accordion.Item eventKey="1">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Past Medical History/ Problem List:
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('1')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div
                className={Styles.modalNotesRow}
                style={{ justifyContent: 'space-between' }}
              >
                {this.renderPatientConditions()}
                {this.renderFamilyHistory()}
              </div>
              <div
                className={Styles.modalNotesRow}
                style={{ justifyContent: 'space-between' }}
              >
                {this.renderAllergies()}
                {this.renderFoodSensitivities()}
              </div>
              {this.renderMedications()}
            </Accordion.Body>
          </Accordion.Item>

          <hr />

          <Accordion.Item eventKey="2">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Smoking & Substance Use History:
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('2')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>Smoking:</p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="smoke"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.smoking || '',
                        value: this.state.socialData?.smoking || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.smoking = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>Alcohol Use:</p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.alcohol || '',
                        value: this.state.socialData?.alcohol || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.alcohol = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Illicit Drug Use:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.illicit_drug || '',
                        value: this.state.socialData?.illicit_drug || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.illicit_drug = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <hr />

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>
                  Review of Systems:
                </p>
              </div>
              <div className={Styles.modalNotesRow}>
                <textarea
                  rows="2"
                  placeholder={'Type review systems'}
                  className={Styles.modalInputStyle}
                  type="text"
                  value={this.state.reviewSystems}
                  onChange={(e) =>
                    this.setState({ reviewSystems: e.target.value }, () => {
                      this.autoSaveNote()
                      this.saveAppointmentToRedux()
                    })
                  }
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <hr />

          <Accordion.Item eventKey="3">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              OBJECTIVE
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('3')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>Vitals:</p>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>Height:</p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      placeholder={'Feet'}
                      type="number"
                      id="height"
                      name="height"
                      min="1"
                      max="10"
                      value={this.state.heightFeet}
                      onChange={(e) => {
                        this.setState(
                          {
                            heightFeet: e.target.value,
                          },
                          () => this.savePatientCore(),
                        )
                      }}
                      className={Styles.modalInputStyle}
                      style={{ width: '45%' }}
                    />{' '}
                    ft.{' '}
                    <input
                      placeholder={'inches'}
                      type="number"
                      id="heightinch"
                      name="heightinch"
                      min="0"
                      value={this.state.heightInches}
                      onChange={(e) => {
                        this.setState(
                          {
                            heightInches: e.target.value,
                          },
                          () => this.savePatientCore(),
                        )
                      }}
                      className={Styles.modalInputStyle}
                      style={{ width: '45%' }}
                    />{' '}
                    in.
                  </div>
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>Weight (lbs):</p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Enter Weight in Lbs'}
                    className={Styles.modalInputStyle}
                    type="number"
                    value={this.state.weightLbs || '-'}
                    onChange={(e) => {
                      this.setState(
                        {
                          weightLbs: e.target.value,
                        },
                        () => this.savePatientCore(),
                      )
                    }}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>BMI:</p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'0 minutes'}
                    className={Styles.modalInputStyle}
                    style={{ width: '100%' }}
                    disabled
                    type="number"
                    value={BMI.bmi || 'N/A'}
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>Objective Note:</p>
              </div>
              <div className={Styles.modalNotesRow}>
                <textarea
                  rows="2"
                  placeholder={'Type review systems'}
                  className={Styles.modalInputStyle}
                  type="text"
                  value={this.state.objectiveNote}
                  onChange={(e) =>
                    this.setState({ objectiveNote: e.target.value }, () => {
                      this.autoSaveNote()
                      this.saveAppointmentToRedux()
                    })
                  }
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <hr />

          <Accordion.Item eventKey="4">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Physical Examination:
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('4')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRowSelect}>
                {this.state.carePlanTemplate?.length !== 0 && (
                  <Select
                    placeholder={'Select template'}
                    options={this.state.carePlanTemplate}
                    value={{
                      label: 'Select Template',
                      value: 'Select Template',
                    }}
                    onChange={(val) => {
                      this.setState({ physicalExam: val.value }, () => {
                        this.saveAppointmentToRedux()
                        this.autoSaveNote()
                      })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                )}
              </div>
              <div className={Styles.modalNotesRow}>
                <textarea
                  rows="3"
                  placeholder={'Please Enter Physical Exam'}
                  className={Styles.modalInputStyle}
                  type="text"
                  value={this.state.physicalExam}
                  onChange={(e) =>
                    this.setState({ physicalExam: e.target.value }, () => {
                      this.saveAppointmentToRedux()
                      this.autoSaveNote()
                    })
                  }
                />
              </div>

              <hr />

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>
                  Diagnostic studies reviewed:
                </p>
              </div>
              <div className={Styles.modalNotesRow}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <textarea
                    rows="3"
                    placeholder={'Please Enter Diagnostic study review'}
                    className={Styles.modalInputStyle}
                    style={{ width: '60%' }}
                    type="text"
                    value={this.state.diagnosticReview.text}
                    onChange={(e) => {
                      // let temp = this.state.diagnosticReview
                      // temp.text = e.target.value
                      this.setState(
                        { diagnosticReview: { text: e.target.value } },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                  />
                  <label
                    style={{
                      color: '#20A892',
                      margin: '20px',
                      alignSelf: 'center',
                    }}
                    class={Styles.checkContainer}
                  >
                    <input
                      type="checkbox"
                      checked={this.state.diagnosticReview?.no_review || false}
                      onClick={() => {
                        let temp = this.state.diagnosticReview
                        temp.no_review = !this.state.diagnosticReview?.no_review
                        this.setState(
                          {
                            diagnosticReview: temp,
                          },
                          () => {
                            this.saveAppointmentToRedux()
                            this.autoSaveNote()
                          },
                        )
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                    There are no studies to review at today’s visit
                  </label>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <hr />

          <Accordion.Item eventKey="5">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              ASSESSMENT
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('5')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <hr />

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>ICD-10</p>
              </div>

              <div className={Styles.modalNotesRow}>
                <label
                  style={{ color: 'black', alignSelf: 'center' }}
                  class={Styles.checkContainer}
                >
                  <input
                    type="checkbox"
                    checked={this.state.noIcdRequired || false}
                    onClick={() => {
                      let temp = this.state.noIcdRequired
                      temp = !this.state.noIcdRequired
                      this.setState(
                        {
                          noIcdRequired: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                  />
                  <span class={Styles.checkmark}></span>
                  No Known Problems
                </label>
              </div>

              {this.state.conditionsData?.length
                ? this.renderICDOptions()
                : null}

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>Search ICD 10</p>
              </div>

              <div className={Styles.modalNotesRow}>
                <input
                  placeholder={'Enter ICD-10 Code Number'}
                  className={Styles.modalInputStyle}
                  style={{ marginRight: 5 }}
                  type="text"
                  value={this.state.icd_code}
                  onChange={(e) =>
                    this.setState({ icd_code: e.target.value }, () =>
                      this.getIcdSearchResults(),
                    )
                  }
                />
                <input
                  placeholder={'Description'}
                  className={Styles.modalInputStyle}
                  type="text"
                  value={this.state.icd_code_desc}
                  onChange={(e) => {
                    this.setState({ icd_code_desc: e.target.value }, () =>
                      this.getIcdSearchResults(),
                    )
                  }}
                />
              </div>

              {this.state.icdSeachResult &&
              this.state.icdSeachResult.length !== 0 ? (
                <div
                  className={Styles.modalNotesRow}
                  style={{
                    overflow: 'auto',
                    height: 150,
                    // backgroundColor: '#F1F1F1',
                    marginTop: '0px',
                  }}
                >
                  <ul class="list-group" style={{ width: '100%' }}>
                    {this.state.icdSeachResult?.map((codeData) => (
                      <li
                        class="list-group-item list-group-item"
                        style={{ cursor: 'pointer', fontSize: '80%' }}
                        onClick={() => {
                          this.setState(
                            (prevState) => ({
                              notesList: [
                                {
                                  icd_10: {
                                    icd10_code: codeData.icd_10_cm_code,
                                    title: codeData.description,
                                  },
                                },
                                ...prevState.notesList,
                              ],
                              addIcd10: {},
                              addNotes: '',
                              icd_code: '',
                              icd_code_desc: '',
                              icdSeachResult: [],
                              addCarePlan: '',
                            }),
                            () => {
                              this.saveAppointmentToRedux()
                              this.autoSaveNote()
                            },
                          )
                        }}
                      >
                        {codeData.icd_10_cm_code}: {codeData.description}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              {this.state?.notesList?.length !== 0 && (
                <div
                // className={Styles.addedNotesListContainer}
                // style={{ width: '100%' }}
                >
                  {this.state.notesList.map((noteData, index) => (
                    <div className={Styles.modalNotesRow}>
                      <div
                        // className={Styles.modalNotesRow}
                        style={{
                          width: '100%',
                          backgroundColor: '#e8e8e8',
                          borderRadius: 20,
                          padding: 20,
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'move',
                        }}
                        draggable
                        onDragStart={(e) => (this.dragItem.current = index)}
                        onDragEnter={(e) => (this.dragOverItem.current = index)}
                        onDragEnd={(e) => this.handleSort()}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <p className={Styles.modalNotesRegularText}>
                          {noteData?.icd_10?.icd10_code}:{' '}
                          {noteData?.icd_10?.title}
                        </p>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={Styles.deleteIcon}
                          onClick={() => {
                            let noteArray = this.state.notesList
                            noteArray.splice(index, 1)
                            this.setState({ notesList: noteArray }, () => {
                              this.autoSaveNote()
                              this.saveAppointmentToRedux()
                            })
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>Assessment:</p>
              </div>
              <div className={Styles.modalNotesRow}>
                <textarea
                  rows="2"
                  placeholder={'Type your assessment'}
                  className={Styles.modalInputStyle}
                  type="text"
                  value={this.state.assessmentNote}
                  onChange={(e) =>
                    this.setState({ assessmentNote: e.target.value }, () => {
                      this.autoSaveNote()
                      this.saveAppointmentToRedux()
                    })
                  }
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <hr />

          <Accordion.Item eventKey="6">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Plan
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('6')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>Care Plan:</p>
              </div>
              <div className={Styles.modalNotesRow}>
                <textarea
                  rows="2"
                  placeholder={'Type your care plan note'}
                  className={Styles.modalInputStyle}
                  type="text"
                  value={this.state.careplanNote}
                  onChange={(e) =>
                    this.setState({ careplanNote: e.target.value }, () => {
                      this.autoSaveNote()
                      this.saveAppointmentToRedux()
                    })
                  }
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <hr />

          <Accordion.Item eventKey="7">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Orders
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('7')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              {renderLabsErxOrders()}

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>Imaging Orders:</p>
              </div>
              <div
                className={Styles.modalNotesRow}
                style={{ marginTop: 0, padding: 0 }}
              >
                <div style={{ margin: 'auto', width: '90%' }}>
                  <Link
                    to={{
                      pathname: '/orders',
                      state: 'referrals',
                      patient_id: this.state.patientId,
                      // Linking the order with the careplan note
                      orders_appointment_event_id:
                        this.state.appointment_event_id,
                      note_icds: this.state.notesList,
                      referral_type: {
                        label: 'Imaging/ Radiology',
                        value: 'imaging',
                      },
                      key: 3,
                    }}
                  >
                    <FontAwesomeIcon
                      className={Styles.plusButton}
                      style={{
                        display: 'flex',
                        float: 'right',
                        fontSize: '20px',
                        top: '30px',
                      }}
                      onClick={() => {
                        console.log(
                          "Clicked on 'Add Imaging Order' going to orders page",
                        )
                      }}
                      icon={faPlusCircle}
                    />
                  </Link>
                  <div className={Styles.row}>
                    <p
                      className={Styles.modalNotesHeaderText}
                      style={{ width: '100%', textAlign: 'center' }}
                    >
                      <Link
                        className={Styles.modalNotesHeaderText}
                        onClick={() => {}}
                        to={{
                          pathname: '/orders',
                          state: 'referrals',
                          patient_id: this.state.patientId,
                          // This is the variable that links the lab orders to the careplan note
                          orders_appointment_event_id:
                            this.state.appointment_event_id,
                          note_icds: this.state.notesList,
                          referral_type: {
                            label: 'Imaging/ Radiology',
                            value: 'imaging',
                          },
                          key: 4,
                        }}
                      >
                        Place order
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

              {/* DME */}
              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>DME Orders:</p>
              </div>
              <div
                className={Styles.modalNotesRow}
                style={{ marginTop: 0, padding: 0 }}
              >
                <div style={{ margin: 'auto', width: '90%' }}>
                  <Link
                    to={{
                      pathname: '/orders',
                      state: 'referrals',
                      patient_id: this.state.patientId,
                      // This is the variable that links the lab orders to the careplan note
                      orders_appointment_event_id:
                        this.state.appointment_event_id,
                      note_icds: this.state.notesList,
                      referral_type: {
                        label: 'Durable Medical Equipment',
                        value: 'medical',
                      },
                      key: 3,
                    }}
                  >
                    <FontAwesomeIcon
                      className={Styles.plusButton}
                      style={{
                        display: 'flex',
                        float: 'right',
                        fontSize: '20px',
                        top: '30px',
                      }}
                      onClick={() => {
                        console.log()
                      }}
                      icon={faPlusCircle}
                    />
                  </Link>
                  <div className={Styles.row}>
                    <p
                      className={Styles.modalNotesHeaderText}
                      style={{ width: '100%', textAlign: 'center' }}
                    >
                      <Link
                        className={Styles.modalNotesHeaderText}
                        onClick={() => {}}
                        to={{
                          pathname: '/orders',
                          state: 'referrals',
                          patient_id: this.state.patientId,
                          // This is the variable that links the lab orders to the careplan note
                          orders_appointment_event_id:
                            this.state.appointment_event_id,
                          note_icds: this.state.notesList,
                          referral_type: {
                            label: 'Durable Medical Equipment',
                            value: 'medical',
                          },
                          key: 4,
                        }}
                      >
                        Place order
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <hr />
          <Accordion.Item eventKey="8">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Referrals
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('8')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Refer To: (can select multiple)
                  </p>
                </div>
                <div
                  className={Styles.modalNotesAnswer}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <div style={{ width: '100%' }}>
                    <Select
                      style={{ width: '90%' }}
                      inputId="speciality"
                      options={SPECIALITY_OPTIONS}
                      // value={this.state.followupData?.refer_specialty}
                      isSearchable
                      autoBlur
                      onChange={(val) => {
                        let temp = { ...this.state.followupData }
                        if (
                          temp?.refer_specialty_arr?.length &&
                          !temp?.refer_specialty_arr.includes(val.value)
                        )
                          temp.refer_specialty_arr.push(val.value)
                        else if (!temp.refer_specialty_arr?.length)
                          temp.refer_specialty_arr = [val.value]
                        this.setState(
                          {
                            followupData: temp,
                            updateFollowUp: true,
                          },
                          () => this.autoSaveFollowUp(),
                        )
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                  {/* <FontAwesomeIcon
                className={Styles.plusButtonNormal}
                style={{ fontSize: '25px' }}
                onClick={() => {
                  // let temp = { ...this.state.followupData }
                  // if(temp?.refer_specialty_arr?.length && !temp?.refer_specialty_arr.includes(val.value))
                  //   temp.refer_specialty_arr.push(val.value)
                  // else
                  //   temp.refer_specialty_arr = [val.value]
                  // this.setState({
                  //   followupData: temp,
                  //   updateFollowUp: true,
                  // }, () => this.autoSaveFollowUp())
                }}
                icon={faPlusCircle}
              /> */}
                  <p style={{ margin: 10 }}>
                    {this.state.followupData?.refer_specialty_arr?.join(', ') ||
                      this.state.followupData?.refer_specialty?.value ||
                      ''}
                  </p>
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Additional referrals suggested:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <textarea
                    rows="1"
                    placeholder={'Please Enter Additional Referrals'}
                    className={Styles.modalInputStyle}
                    type="text"
                    value={this.state.followupData?.referral_suggested}
                    onChange={(e) => {
                      let temp = { ...this.state.followupData }
                      temp.referral_suggested = e.target.value
                      this.setState(
                        { followupData: temp, updateFollowUp: true },
                        () => {
                          this.saveAppointmentToRedux()
                        },
                      )
                    }}
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                {' '}
                {this.state.careTeamData?.filter(
                  (doc) => doc?.user_type === 'Health Coach',
                ).length ? (
                  <>
                    <div className={Styles.modalNotesQuestion}>
                      <p className={Styles.modalNotesRegularText}>
                        Selected Health Coach:
                      </p>
                    </div>
                    <div className={Styles.modalNotesAnswer}>
                      <p className={Styles.modalNotesRegularText}>
                        {this.state.careTeamData
                          .filter((doc) => doc.user_type === 'Health Coach')
                          .map((u) => u.name)
                          .join(', ')}
                      </p>
                    </div>
                  </>
                ) : (
                  <label
                    style={{ color: 'black', alignSelf: 'center' }}
                    class={Styles.checkContainer}
                  >
                    <input
                      type="checkbox"
                      checked={
                        this.state.referCoach?.is_coach_referral || false
                      }
                      onClick={() => {
                        let temp = this.state.referCoach

                        temp.is_coach_referral =
                          !this.state.referCoach?.is_coach_referral
                        this.setState(
                          {
                            referCoach: temp,
                          },
                          () => {
                            this.saveAppointmentToRedux()
                            this.autoSaveNote()
                          },
                        )
                      }}
                    />
                    <span class={Styles.checkmark}></span>
                    Refer to Coach
                  </label>
                )}
              </div>

              {!this.state.careTeamData?.filter(
                (doc) => doc?.user_type === 'Health Coach',
              )?.length && this.state.referCoach?.is_coach_referral ? (
                <>
                  <div className={Styles.modalNotesRow}>
                    <div className={Styles.modalNotesQuestion}>
                      <p className={Styles.modalNotesRegularText}>
                        Select Health Coach:
                      </p>
                    </div>
                    <div className={Styles.modalNotesAnswer}>
                      <Select
                        inputId="health_coach"
                        options={this.state.providerOptionsData.filter(
                          (doc) => doc.label === 'Health Coach',
                        )}
                        value={this.state.referCoach?.assigned_coach}
                        isSearchable
                        autoBlur
                        onChange={(val) => {
                          let temp = { ...this.state.referCoach }
                          temp.assigned_coach = val
                          this.setState(
                            {
                              referCoach: temp,
                            },
                            () => {
                              this.saveAppointmentToRedux()
                              this.autoSaveNote()
                            },
                          )
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#20A89233',
                            primary: '#20A892',
                          },
                        })}
                      />
                    </div>
                  </div>
                </>
              ) : null}

              {this.state.referCoach?.is_coach_referral ? (
                <>
                  <div className={Styles.modalNotesRow}>
                    <p className={Styles.modalNotesRegularText}>
                      Search ICD 10 for health coach
                    </p>
                  </div>

                  <div className={Styles.modalNotesRow}>
                    <input
                      placeholder={'Enter ICD-10 Code Number'}
                      className={Styles.modalInputStyle}
                      style={{ marginRight: 5 }}
                      type="text"
                      value={this.state.icd_code}
                      onChange={(e) =>
                        this.setState({ icd_code: e.target.value }, () =>
                          this.getIcdSearchResults(),
                        )
                      }
                    />
                    <input
                      placeholder={'Description'}
                      className={Styles.modalInputStyle}
                      type="text"
                      value={this.state.icd_code_desc}
                      onChange={(e) => {
                        this.setState({ icd_code_desc: e.target.value }, () =>
                          this.getIcdSearchResults(),
                        )
                      }}
                    />
                  </div>
                </>
              ) : null}

              {this.state.referCoach?.is_coach_referral &&
              this.state.icdSeachResult &&
              this.state.icdSeachResult.length !== 0 ? (
                <div
                  className={Styles.modalNotesRow}
                  style={{
                    overflow: 'auto',
                    height: 150,
                    // backgroundColor: '#F1F1F1',
                    marginTop: '0px',
                  }}
                >
                  <ul class="list-group" style={{ width: '100%' }}>
                    {this.state.icdSeachResult?.map((codeData) => (
                      <li
                        class="list-group-item list-group-item"
                        style={{ cursor: 'pointer', fontSize: '80%' }}
                        onClick={() => {
                          let temp = { ...this.state.referCoach }
                          temp.assigned_icd = {
                            icd10_code: codeData.icd_10_cm_code,
                            title: codeData.description,
                          }
                          this.setState(
                            {
                              icd_code: '',
                              icd_code_desc: '',
                              icdSeachResult: [],
                              referCoach: temp,
                            },
                            () => {
                              this.saveAppointmentToRedux()
                              this.autoSaveNote()
                            },
                          )
                        }}
                      >
                        {codeData.icd_10_cm_code}: {codeData.description}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              {this.state?.referCoach?.assigned_icd && (
                <div
                // className={Styles.addedNotesListContainer}
                // style={{ width: '100%' }}
                >
                  <div className={Styles.modalNotesRow}>
                    <div
                      // className={Styles.modalNotesRow}
                      style={{
                        width: '100%',
                        backgroundColor: '#e8e8e8',
                        borderRadius: 20,
                        padding: 20,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className={Styles.modalNotesRegularText}>
                        {this.state?.referCoach?.assigned_icd?.icd10_code}:{' '}
                        {this.state?.referCoach?.assigned_icd?.title}
                      </p>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={Styles.deleteIcon}
                        onClick={() => {
                          let temp = { ...this.state.referCoach }
                          temp.assigned_icd = null
                          this.setState({ referCoach: temp }, () => {
                            this.autoSaveNote()
                            this.saveAppointmentToRedux()
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* <div className={Styles.modalNotesRow}>
          <p className={Styles.modalNotesQuestion}>Refer to coaching*: </p>
          {
            (this.state.careTeamData.filter((doc) => doc.user_type === 'Health Coach')).length
            ? (
              <div style={{ width: '67%', marginTop: 10 }}>{this.state.careTeamData.filter((doc) => doc.user_type === 'Health Coach').map(u => u.name).join(', ')}</div>
            )
            : (
              <div style={{ width: '67%', marginTop: 10 }}>
                <Select
                  inputId="interpreter"
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                  ]}
                  value={this.state.referCoach}
                  isSearchable
                  autoBlur
                  onChange={(val) => {
                    this.setState({
                      referCoach: val,
                    }, () => {
                      this.autoSaveNote()
                      this.saveAppointmentToRedux()
                    })
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#20A89233',
                      primary: '#20A892',
                    },
                  })}
                />
              </div>
            )
          }
        </div> */}
            </Accordion.Body>
          </Accordion.Item>

          <hr />
          <Accordion.Item eventKey="9">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Next Steps / Follow ups
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('9')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Schedule Follow up in:
                  </p>
                </div>
                <div
                  className={Styles.modalNotesAnswer}
                  style={{ flexDirection: 'column' }}
                >
                  <input
                    placeholder={'Enter Value'}
                    className={Styles.textInput}
                    style={{ width: '100%' }}
                    type="text"
                    value={this.state.followupData?.follow_up_value}
                    onChange={(e) => {
                      let text = e.target.value
                      let temp = { ...this.state.followupData }
                      temp.follow_up_value = text
                      // if (this.isNumber(text))
                      this.setState(
                        { followupData: temp, updateFollowUp: true },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveFollowUp()
                        },
                      )
                    }}
                  />

                  <div
                    style={{
                      // border: '1px solid #d0d0d0',
                      // padding: '5px 20px',
                      // borderRadius: '4px',
                      marginTop: '10px',
                    }}
                  >
                    <Select
                      placeholder={'Select Duration'}
                      options={[
                        { label: 'Days', value: 'days' },
                        { label: 'Weeks', value: 'weeks' },
                        { label: 'Months', value: 'months' },
                      ]}
                      value={this.state.followupData?.follow_up_unit}
                      onChange={(val) => {
                        let temp = { ...this.state.followupData }
                        temp.follow_up_unit = val
                        this.setState(
                          {
                            followupData: temp,
                            updateFollowUp: true,
                          },
                          () => this.autoSaveFollowUp(),
                        )
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#20A89233',
                          primary: '#20A892',
                        },
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <label
                  style={{ color: 'black', alignSelf: 'center' }}
                  class={Styles.checkContainer}
                >
                  <input
                    type="checkbox"
                    checked={this.state.followupData?.no_follow_up || false}
                    onClick={() => {
                      let temp = { ...this.state.followupData }
                      temp.no_follow_up = !this.state.followupData?.no_follow_up
                      this.setState(
                        {
                          followupData: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                  />
                  <span class={Styles.checkmark}></span>
                  No Follow up required
                </label>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Requested Authorizations:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <textarea
                    rows="1"
                    placeholder={'Please Enter Requested Authorizations'}
                    className={Styles.modalInputStyle}
                    type="text"
                    value={this.state.followupData?.requested_auth}
                    onChange={(e) => {
                      let temp = { ...this.state.followupData }
                      temp.requested_auth = e.target.value
                      this.setState(
                        { followupData: temp, updateFollowUp: true },
                        () => {
                          this.saveAppointmentToRedux()
                        },
                      )
                    }}
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <hr />
          <Accordion.Item eventKey="10">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Remote Monitoring & Chronic Care Management
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('10')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              {renderRPM()}
              {renderCCM()}
            </Accordion.Body>
          </Accordion.Item>
          <hr />
          <Accordion.Item eventKey="11">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Clinical Quality: Did any of these apply during the visit?
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('11')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div>
                {APPLY_DURING_VISIT.map((question, index) => {
                  let tempIndex = Array.isArray(this.state.applyVisit)
                    ? this.state.applyVisit.findIndex(
                        (ques) => ques === question?.q_index,
                      )
                    : -1
                  return (
                    <div
                      className={Styles.modalNotesRow}
                      style={{ justifyContent: 'flex-start' }}
                    >
                      <p className={Styles.modalNotesHeaderText}>
                        <label
                          // style={{ color: '#20A892' }}
                          class={Styles.checkContainer}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: question.question,
                            }}
                            style={{ fontSize: '18px' }}
                          />
                          {/* <p className={Styles.modalNotesQuestion}>No Show </p> */}
                          <input
                            type="checkbox"
                            checked={
                              this.state.applyVisit?.[tempIndex] ===
                              question.q_index
                                ? true
                                : false
                            }
                            onClick={() => {
                              if (tempIndex === -1) {
                                // if the obj is not present add it into the array
                                this.setState(
                                  {
                                    applyVisit: [
                                      ...this.state.applyVisit,
                                      question.q_index,
                                    ],
                                  },
                                  () => {
                                    this.autoSaveNote()
                                    this.saveAppointmentToRedux()
                                  },
                                )
                              } else {
                                this.setState(
                                  {
                                    applyVisit: this.state.applyVisit.filter(
                                      (ques) => {
                                        return ques !== question.q_index
                                      },
                                    ),
                                  },
                                  () => {
                                    this.autoSaveNote()
                                    this.saveAppointmentToRedux()
                                  },
                                )
                              }
                            }}
                          />
                          <span class={Styles.checkmark}></span>
                        </label>
                      </p>
                    </div>
                  )
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <hr />
          <Accordion.Item eventKey="12">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Demographics & SDOH
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('12')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}></p>
              </div>

              <hr />

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>Social Health:</p>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>Ethnicity:</p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={OPTIONS_ETHNICITY}
                    value={[
                      {
                        label: this.state.socialData.ethnicity || '',
                        value: this.state.socialData.ethnicity || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.ethnicity = val.value
                      this.setState(
                        {
                          socialData: temp,
                          ethnicity: val.value,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Hispanic or Latino:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.is_lat_his || '',
                        value: this.state.socialData?.is_lat_his || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.is_lat_his = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Employment Status:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={OPTIONS_EMPLOYMENT}
                    value={[
                      {
                        label: this.state.socialData?.employment || '',
                        value: this.state.socialData?.employment || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.employment = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>Occupation:</p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <textarea
                    rows="1"
                    placeholder={'Enter Occupation'}
                    className={Styles.modalInputStyle}
                    type="text"
                    value={this.state.socialData.occupation || ''}
                    onChange={(e) => {
                      let temp = this.state.socialData
                      temp.occupation = e.target.value
                      this.setState({ socialData: temp }, () => {
                        this.saveSocialHealth()
                      })
                    }}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>Education:</p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={OPTIONS_EDUCATION}
                    value={[
                      {
                        label: this.state.socialData.education || '',
                        value: this.state.socialData.education || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.education = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Environment (Have you been exposed to airway irritants such
                    as tobacco smoke, air pollution or chemical fumes):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData.airway_irritants || '',
                        value: this.state.socialData.airway_irritants || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.airway_irritants = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Housing (No. of family members living in your house
                    household including yourself):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: '1', value: '1' },
                      { label: '2', value: '2' },
                      { label: '3', value: '3' },
                      { label: '4', value: '5' },
                      { label: '5', value: '5' },
                      { label: '6', value: '6' },
                      { label: '7', value: '7' },
                      { label: '8', value: '8' },
                      { label: '9', value: '9' },
                      { label: '10+', value: '10+' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData.household || '',
                        value: this.state.socialData.household || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.household = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Transportation Access:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.access?.some(
                          (data) => data === 'transportation',
                        )
                          ? 'No'
                          : 'Yes',
                        value: this.state.socialData?.access?.some(
                          (data) => data === 'transportation',
                        )
                          ? 'No'
                          : 'Yes',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.access = temp?.access?.length
                        ? temp?.access.push('transportation')
                        : ['transportation']
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Housing Access:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.access?.some(
                          (data) => data === 'housing',
                        )
                          ? 'No'
                          : 'Yes',
                        value: this.state.socialData?.access?.some(
                          (data) => data === 'housing',
                        )
                          ? 'No'
                          : 'Yes',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.access = temp?.access?.length
                        ? temp?.access.push('housing')
                        : ['housing']
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Access to Healthy Food Nearby Home:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.access?.some(
                          (data) => data === 'food',
                        )
                          ? 'No'
                          : 'Yes',
                        value: this.state.socialData?.access?.some(
                          (data) => data === 'food',
                        )
                          ? 'No'
                          : 'Yes',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.access = temp?.access?.length
                        ? temp?.access.push('food')
                        : ['food']
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Distance to nearest hospital:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: '<10 Minutes', value: '<10 Minutes' },
                      { label: '<30 Minutes', value: '<30 Minutes' },
                      { label: '<1 Hour', value: '<1 Hour' },
                      { label: '1-2 Hours', value: '1-2 Hours' },
                      { label: '2-3 Hours', value: '2-3 Hours' },
                      { label: '>3 Hours', value: '>3 Hours' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.nearest_hospital || '',
                        value: this.state.socialData?.nearest_hospital || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.nearest_hospital = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Marital Status:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'Married', value: 'Married' },
                      { label: 'Single', value: 'Single' },
                      { label: 'Divorced', value: 'Divorced' },
                      { label: 'Widowed', value: 'Widowed' },
                      { label: 'Seperated', value: 'Seperated' },
                      {
                        label: 'Domestic Partnership',
                        value: 'Domestic Partnership',
                      },
                      {
                        label: 'I prefer to not answer',
                        value: 'I prefer to not answer',
                      },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.marital_status || '',
                        value: this.state.socialData?.marital_status || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.marital_status = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Has a support system at home to help manage care:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.has_care_help || '',
                        value: this.state.socialData?.has_care_help || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.has_care_help = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Has a full time caregiver:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    value={[
                      {
                        label: this.state.socialData?.has_caregiver || '',
                        value: this.state.socialData?.has_caregiver || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = this.state.socialData
                      temp.has_caregiver = val.value
                      this.setState(
                        {
                          socialData: temp,
                        },
                        () => {
                          this.saveSocialHealth()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>Identity:</p>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Gender Identity:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      { label: 'Female', value: 'Female' },
                      { label: 'Male', value: 'Male' },
                      { label: 'Trans Female', value: 'Trans Female' },
                      { label: 'Trans Male', value: 'Trans Male' },
                      { label: 'Non-Binary', value: 'Non-Binary' },
                      {
                        label: 'I prefer to self-identify',
                        value: 'I prefer to self-identify',
                      },
                    ]}
                    value={[
                      {
                        label: this.state.gender || '',
                        value: this.state.gender || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState(
                        {
                          gender: val.value,
                        },
                        () => this.savePatientCore(),
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Sexual Orientation:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="auth"
                    options={[
                      {
                        label: 'Heterosexual or straight',
                        value: 'Heterosexual or straight',
                      },
                      { label: 'Gay', value: 'Gay' },
                      { label: 'Lesbian', value: 'Lesbian' },
                      { label: 'Bisexual', value: 'Bisexual' },
                      { label: 'Asexual', value: 'Asexual' },
                      { label: 'Pansexual', value: 'Pansexual' },
                      {
                        label: 'I prefer not to answer',
                        value: 'I prefer not to answer',
                      },
                    ]}
                    value={[
                      {
                        label: this.state.sexual_orientation || '',
                        value: this.state.sexual_orientation || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState(
                        {
                          sexual_orientation: val.value,
                        },
                        () => {
                          this.savePatientCore()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>Pro-nouns:</p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="pron"
                    options={[
                      { label: 'she/her/hers', value: 'she/her/hers' },
                      { label: 'he/him/his', value: 'he/him/his' },
                      { label: 'they/them/theirs', value: 'they/them/theirs' },
                      {
                        label: 'I prefer to self-identify',
                        value: 'I prefer to self-identify',
                      },
                    ]}
                    value={[
                      {
                        label: this.state.pronoun || '',
                        value: this.state.pronoun || '',
                      },
                    ]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState(
                        {
                          pronoun: val.value,
                        },
                        () => {
                          this.savePatientCore()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <hr />
          <Accordion.Item eventKey="13">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Patient Reported Outcomes
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('13')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>
                  Symptoms Progress & Patient Reported Outcomes
                </p>
              </div>

              <SymptomsProgress
                selectedPatient={
                  this.props?.patient || this.props?.selectedPatient
                }
              />

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>
                  Patient Reported Outcomes Questionnaires:
                </p>
              </div>
              <div
                className={Styles.modalNotesRow}
                style={{ height: 300, overflow: 'scroll' }}
              >
                <Screeners patientId={this.state.patientId} token={token} />
              </div>

              <hr />

              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}>Psychosocial:</p>
              </div>
              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesRegularText}>
                  Depression Voice Screening:{' '}
                  {this.state.appointmentData?.audio_result?.value
                    ?.toString()
                    .replace(/_/g, ' ')
                    .replace(/\b[a-z]/g, function (letter) {
                      return letter.toUpperCase() || 'N/A'
                    }) || 'N/A'}
                </p>
              </div>
              <div className={Styles.modalNotesRow}>
                {this.renderBehavioralContent()}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <hr />
          <Accordion.Item eventKey="14">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Remote Therapeutic Monitoring
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('14')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesHeaderText}></p>
              </div>
              <hr />
              <div
                className={Styles.modalNotesRow}
                style={{ justifyContent: 'space-between' }}
              >
                <p className={Styles.modalNotesHeaderText}>Medication:</p>
                <FontAwesomeIcon
                  className={Styles.plusButtonNormal}
                  style={{ fontSize: '25px', margin: 10 }}
                  onClick={() => {
                    this.setState({ medMonitorModalIsOpen: true })
                  }}
                  icon={faPlusCircle}
                />
              </div>
              <div className={Styles.modalNotesRow}>
                <MedMonitor medMonitorData={this.state.medMonitorDataArray} />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <hr />
          <Accordion.Item eventKey="15">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Time Spent
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('15')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Video Visit Start Time (
                    {`${new Date()
                      .toLocaleDateString(undefined, {
                        day: '2-digit',
                        timeZoneName: 'long',
                      })
                      .substring(4)}`}
                    ):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Enter Value'}
                    className={Styles.modalInputStyle}
                    type="datetime-local"
                    id="appointmentDate"
                    name="appointmentDate"
                    value={
                      this.state.appointmentDate
                        ? moment
                            .unix(this.state.appointmentDate / 1000)
                            .format('YYYY-MM-DDTHH:mm')
                        : moment().format('YYYY-MM-DDTHH:mm')
                    }
                    onChange={(val) =>
                      this.setState(
                        {
                          appointmentDate: moment(val.target.value).valueOf(),
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Video Visit End Time(
                    {`${new Date()
                      .toLocaleDateString(undefined, {
                        day: '2-digit',
                        timeZoneName: 'long',
                      })
                      .substring(4)}`}
                    ):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Enter Value'}
                    className={Styles.modalInputStyle}
                    type="datetime-local"
                    id="appointmentEndDate"
                    name="appointmentEndDate"
                    value={
                      this.state.appointmentEndDate
                        ? moment
                            .unix(this.state.appointmentEndDate / 1000)
                            .format('YYYY-MM-DDTHH:mm')
                        : moment().format('YYYY-MM-DDTHH:mm')
                    }
                    onChange={(val) =>
                      this.setState(
                        {
                          appointmentEndDate: moment(
                            val.target.value,
                          ).valueOf(),
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Video Consult Minutes
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Enter Value'}
                    className={Styles.modalInputStyle}
                    // style={{ width: '100%' }}
                    type="text"
                    value={`${moment
                      .unix(
                        (this.state.appointmentEndDate || moment().valueOf()) /
                          1000,
                      )
                      .diff(
                        moment.unix(
                          (this.state.appointmentDate || moment().valueOf()) /
                            1000,
                        ),
                        'minute',
                      )} minutes`}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Pre-Visit Review of Records (minutes):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'0 minutes'}
                    className={Styles.modalInputStyle}
                    style={{ width: '100%' }}
                    type="number"
                    value={
                      this.state.timeSpent?.pre_review_time || 0 + ' minutes'
                    }
                    onChange={(e) => {
                      let time = e.target.value
                      let temp = this.state.timeSpent
                      temp.pre_review_time = time
                      this.setState(
                        {
                          timeSpent: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    After Visit Documentation (minutes):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'0 minutes'}
                    className={Styles.modalInputStyle}
                    style={{ width: '100%' }}
                    type="number"
                    value={this.state.timeSpent?.after_visit || 0 + ' minutes'}
                    onChange={(e) => {
                      let time = e.target.value
                      let temp = this.state.timeSpent
                      temp.after_visit = time
                      this.setState(
                        {
                          timeSpent: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Provider Collaboration (referrals/ consults/ prior auth)
                    (minutes):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'0 minutes'}
                    className={Styles.modalInputStyle}
                    style={{ width: '100%' }}
                    type="number"
                    value={
                      this.state.timeSpent?.provider_colab || 0 + ' minutes'
                    }
                    onChange={(e) => {
                      let time = e.target.value
                      let temp = this.state.timeSpent
                      temp.provider_colab = time
                      this.setState(
                        {
                          timeSpent: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Total Time Spent:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Enter Value'}
                    className={Styles.modalInputStyle}
                    // style={{ width: '100%' }}
                    type="text"
                    value={
                      Number(
                        moment
                          .unix(
                            (this.state.appointmentEndDate ||
                              moment().valueOf()) / 1000,
                          )
                          .diff(
                            moment.unix(
                              (this.state.appointmentDate ||
                                moment().valueOf()) / 1000,
                            ),
                            'minute',
                          ) || 0,
                      ) +
                      Number(this.state.timeSpent?.pre_review_time || 0) +
                      Number(this.state.timeSpent?.after_visit || 0) +
                      Number(this.state.timeSpent?.provider_colab || 0) +
                      ' minutes'
                    }
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Method of visit:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="method_of_visit"
                    options={[
                      {
                        label: 'Telehealth - Video Visit',
                        value: 'Telehealth - Video Visit',
                      },
                      {
                        label: 'Telehealth - Phone Call',
                        value: 'Telehealth - Phone Call',
                      },
                      {
                        label: 'Telehealth - E-Consult',
                        value: 'Telehealth - E-Consult',
                      },
                      {
                        label: 'Telehealth- Asynchronous visit',
                        value: 'Telehealth- Asynchronous visit',
                      },
                    ]}
                    value={
                      this.state.timeSpent?.method_of_visit
                        ? {
                            label: this.state.timeSpent?.method_of_visit,
                            value: this.state.timeSpent?.method_of_visit,
                          }
                        : {
                            label: 'Telehealth - Video Visit',
                            value: 'Telehealth - Video Visit',
                          }
                    }
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = { ...this.state.timeSpent }
                      // console.log('testing: ', temp)
                      temp.method_of_visit = val.value
                      this.setState(
                        {
                          timeSpent: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Interpreter Present at Visit:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="interpreter"
                    options={[
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' },
                      { label: 'N/A', value: 'n/a' },
                    ]}
                    value={
                      this.state.followupData?.interpreter_present || {
                        label: 'N/A',
                        value: 'n/a',
                      }
                    }
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = { ...this.state.followupData }
                      temp.interpreter_present = val
                      this.setState(
                        {
                          followupData: temp,
                          updateFollowUp: true,
                        },
                        () => this.autoSaveFollowUp(),
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {
            <>
              {/* {
              <div className={Styles.modalNotesRow}>
                <p className={Styles.modalNotesQuestion}>Prior Authorization Request for Rx: </p>
                <div style={{ width: '67%', marginTop: 10 }}>
                  <Select
                    inputId="auth"
                    options={[
                      {label: 'No', value: 'no'},
                      {label: 'Yes', value: 'yes'},
                    ]}
                    value={[this.state.priorAuthRx]}
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      this.setState({
                        priorAuthRx: val,
                      }, () => {
                        this.autoSaveNote()
                        this.saveAppointmentToRedux()
                      })
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>
            } */}
              <hr />
              <Accordion.Item eventKey="16">
                <Accordion.Button className={Styles.accordionHeaderButton}>
                  CPT Codes
                  <FontAwesomeIcon
                    icon={
                      this.state.activeAccordions.includes('16')
                        ? faArrowCircleUp
                        : faArrowCircleDown
                    }
                    className={Styles.arrowIcon}
                    onClick={() => {}}
                  />
                </Accordion.Button>
                <Accordion.Body>
                  <div className={Styles.modalNotesRow}>
                    <p className={Styles.modalNotesHeaderText}>
                      List of Suggested CPT Codes
                    </p>
                  </div>
                  {this.renderCPTOptions()}

                  {
                    // show CPT code for everyone
                    <div
                      className={Styles.modalNotesCPTRow}
                      style={{
                        marginTop: 10,
                        marginBottom: 20,
                        marginLeft: 30,
                      }}
                    >
                      <div className={Styles.modalNotesCol}>
                        <p className={Styles.modalNotesKey}>CPT</p>
                        <div className={Styles.modalValueValue}>
                          {/* <InstantSearch
                            searchClient={condnClient}
                            onSearchStateChange={({ query }) =>
                              this.setState({ cptquery: query })
                            }
                            indexName={'CPT_CODES'}
                          >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                              <SearchBox />
                              {this.state.cptquery &&
                                this.state.cptquery.length !== 0 && (
                                  <div
                                    style={{
                                      overflow: 'auto',
                                      height: 150,
                                      backgroundColor: '#F1F1F1',
                                    }}
                                  >
                                    <RefinementList limit={5} />
                                    <Hits
                                      hitComponent={(props) =>
                                        Hit(props, 'cpt')
                                      }
                                    />
                                  </div>
                                )}
                            </div>
                          </InstantSearch> */}
                          <SearchProvider config={esConfigCPTCodes}>
                            <WithSearch
                              mapContextToProps={({
                                wasSearched,
                                setSearchTerm,
                              }) => ({
                                wasSearched,
                                setSearchTerm,
                              })}
                            >
                              {({ wasSearched, setSearchTerm }) => {
                                // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                                return (
                                  <div className="App">
                                    <EsSearchBox
                                      debounceLength={0}
                                      autocompleteResults={{
                                        titleField: 'search_term',
                                      }}
                                      searchAsYouType={true}
                                      onSelectAutocomplete={(e) => {
                                        console.log(
                                          'tap:',
                                          mongoUser?.user_type,
                                        )
                                        if (
                                          'cpt' === 'cpt' && // Yes ik, but i copied the code, spare me
                                          mongoUser?.user_type !==
                                            'Administrator' &&
                                          mongoUser?.user_type !== 'Billing'
                                        ) {
                                          this.setState(
                                            (prevState) => ({
                                              addCpt: [
                                                ...prevState.addCpt,
                                                {
                                                  cpt_code: e.cpt_code.raw,
                                                  title: e.description.raw,
                                                },
                                              ],
                                              cptquery: '',
                                            }),
                                            () => {
                                              this.autoSaveNote()
                                              this.saveAppointmentToRedux()
                                            },
                                          )
                                        } else if (
                                          mongoUser?.user_type ===
                                            'Administrator' ||
                                          mongoUser?.user_type === 'Billing'
                                        ) {
                                          this.setState(
                                            (prevState) => ({
                                              addAmendCpt: [
                                                ...prevState.addAmendCpt,
                                                {
                                                  cpt_code: e.cpt_code.raw,
                                                  title: e.description.raw,
                                                },
                                              ],
                                              cptquery: '',
                                            }),
                                            () => {
                                              this.autoSaveNote()
                                              this.saveAppointmentToRedux()
                                            },
                                          )
                                        } else {
                                          eventFire(
                                            document.getElementById('icdclear'),
                                            'click',
                                          )
                                          this.setState(
                                            {
                                              addIcd10: {
                                                icd10_code: e.code.raw,
                                                title: e.display.raw,
                                              },
                                              icd10query: '',
                                            },
                                            () => this.saveAppointmentToRedux(),
                                          )
                                        }
                                        setSearchTerm('')
                                      }}
                                      inputView={({
                                        getAutocomplete,
                                        getInputProps,
                                        getButtonProps,
                                      }) => (
                                        <>
                                          <div className="sui-search-box__wrapper">
                                            <input
                                              {...getInputProps({
                                                placeholder: 'Search CPT codes',
                                              })}
                                            />
                                            {getAutocomplete()}
                                          </div>
                                        </>
                                      )}
                                    />
                                  </div>
                                )
                              }}
                            </WithSearch>
                          </SearchProvider>
                        </div>
                      </div>
                      {this.state.addCpt && this.state.addCpt.length !== 0 && (
                        <div className={Styles.modalNotesCol}>
                          <p className={Styles.modalNotesKey}>Selected CPT</p>
                          <div
                            className={Styles.modalValueValue}
                            style={{ overflow: 'auto', width: '100%' }}
                          >
                            {this.state.addCpt.map((cpt, index) => (
                              <div
                                className={Styles.modalNotesRow}
                                style={{
                                  justifyContent: 'space-between',
                                  borderTop: '1px solid #D3D3D3',
                                }}
                              >
                                <p>
                                  {index + 1}. {cpt.cpt_code}: {cpt.title}
                                </p>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className={Styles.deleteIcon}
                                  onClick={() => {
                                    let arr = this.state.addCpt.filter(
                                      (data) => data.cpt_code !== cpt.cpt_code,
                                    )
                                    this.setState({ addCpt: arr }, () => {
                                      this.autoSaveNote()
                                      this.saveAppointmentToRedux()
                                    })
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {/* {this.state.addAmendCpt &&
                        this.state.addAmendCpt.length !== 0 &&
                        (mongoUser?.user_type === 'Administrator' ||
                          mongoUser?.user_type === 'Billing') && (
                          <div className={Styles.modalNotesCol}>
                            <p className={Styles.modalNotesKey}>Amended CPT</p>
                            <div
                              className={Styles.modalValueValue}
                              style={{ overflow: 'auto', width: '100%' }}
                            >
                              {this.state.addAmendCpt.map((cpt, index) => (
                                <div
                                  className={Styles.modalNotesRow}
                                  style={{
                                    justifyContent: 'space-between',
                                    borderTop: '1px solid #D3D3D3',
                                  }}
                                >
                                  <p>
                                    {index + 1}. {cpt.cpt_code}: {cpt.title}
                                  </p>
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className={Styles.deleteIcon}
                                    onClick={() => {
                                      let arr = this.state.addAmendCpt.filter(
                                        (data) =>
                                          data.cpt_code !== cpt.cpt_code,
                                      )
                                      this.setState(
                                        { addAmendCpt: arr },
                                        () => {
                                          this.autoSaveNote()
                                          this.saveAppointmentToRedux()
                                        },
                                      )
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )} */}
                    </div>
                  }
                </Accordion.Body>
                {/* <Accordion.Body>
                  <div className={Styles.modalNotesRow}>
                    <p className={Styles.modalNotesHeaderText}>Amended CPT</p>
                  </div>
                  {this.renderCPTOptions()}

                  {
                    // show CPT code for everyone
                    <div
                      className={Styles.modalNotesCPTRow}
                      style={{
                        marginTop: 10,
                        marginBottom: 20,
                        marginLeft: 30,
                      }}
                    >
                      <div className={Styles.modalNotesCol}>
                        <p className={Styles.modalNotesKey}>CPT</p>
                        <div className={Styles.modalValueValue}>
                          <SearchProvider config={esConfigCPTCodes}>
                            <WithSearch
                              mapContextToProps={({
                                wasSearched,
                                setSearchTerm,
                              }) => ({
                                wasSearched,
                                setSearchTerm,
                              })}
                            >
                              {({ wasSearched, setSearchTerm }) => {
                                // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                                return (
                                  <div className="App">
                                    <EsSearchBox
                                      debounceLength={0}
                                      autocompleteResults={{
                                        titleField: 'search_term',
                                      }}
                                      searchAsYouType={true}
                                      onSelectAutocomplete={(e) => {
                                        console.log(
                                          'tap:',
                                          mongoUser?.user_type,
                                        )
                                        if (
                                          'cpt' === 'cpt' && // Yes ik, but i copied the code, spare me
                                          mongoUser?.user_type !==
                                            'Administrator' &&
                                          mongoUser?.user_type !== 'Billing'
                                        ) {
                                          this.setState(
                                            (prevState) => ({
                                              addCpt: [
                                                ...prevState.addCpt,
                                                {
                                                  cpt_code: e.cpt_code.raw,
                                                  title: e.description.raw,
                                                },
                                              ],
                                              cptquery: '',
                                            }),
                                            () => {
                                              this.autoSaveNote()
                                              this.saveAppointmentToRedux()
                                            },
                                          )
                                        } else if (
                                          mongoUser?.user_type ===
                                            'Administrator' ||
                                          mongoUser?.user_type === 'Billing'
                                        ) {
                                          this.setState(
                                            (prevState) => ({
                                              addAmendCpt: [
                                                ...prevState.addAmendCpt,
                                                {
                                                  cpt_code: e.cpt_code.raw,
                                                  title: e.description.raw,
                                                },
                                              ],
                                              cptquery: '',
                                            }),
                                            () => {
                                              this.autoSaveNote()
                                              this.saveAppointmentToRedux()
                                            },
                                          )
                                        } else {
                                          eventFire(
                                            document.getElementById('icdclear'),
                                            'click',
                                          )
                                          this.setState(
                                            {
                                              addIcd10: {
                                                icd10_code: e.code.raw,
                                                title: e.display.raw,
                                              },
                                              icd10query: '',
                                            },
                                            () => this.saveAppointmentToRedux(),
                                          )
                                        }
                                        setSearchTerm('')
                                      }}
                                      inputView={({
                                        getAutocomplete,
                                        getInputProps,
                                        getButtonProps,
                                      }) => (
                                        <>
                                          <div className="sui-search-box__wrapper">
                                            <input
                                              {...getInputProps({
                                                placeholder: 'Search CPT codes',
                                              })}
                                            />
                                            {getAutocomplete()}
                                          </div>
                                        </>
                                      )}
                                    />
                                  </div>
                                )
                              }}
                            </WithSearch>
                          </SearchProvider>
                        </div>
                      </div>
                      {this.state.addCpt && this.state.addCpt.length !== 0 && (
                        <div className={Styles.modalNotesCol}>
                          <p className={Styles.modalNotesKey}>Selected CPT</p>
                          <div
                            className={Styles.modalValueValue}
                            style={{ overflow: 'auto', width: '100%' }}
                          >
                            {this.state.addCpt.map((cpt, index) => (
                              <div
                                className={Styles.modalNotesRow}
                                style={{
                                  justifyContent: 'space-between',
                                  borderTop: '1px solid #D3D3D3',
                                }}
                              >
                                <p>
                                  {index + 1}. {cpt.cpt_code}: {cpt.title}
                                </p>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className={Styles.deleteIcon}
                                  onClick={() => {
                                    let arr = this.state.addCpt.filter(
                                      (data) => data.cpt_code !== cpt.cpt_code,
                                    )
                                    this.setState({ addCpt: arr }, () => {
                                      this.autoSaveNote()
                                      this.saveAppointmentToRedux()
                                    })
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {this.state.addAmendCpt &&
                        this.state.addAmendCpt.length !== 0 &&
                        (mongoUser?.user_type === 'Administrator' ||
                          mongoUser?.user_type === 'Billing') && (
                          <div className={Styles.modalNotesCol}>
                            <p className={Styles.modalNotesKey}>Amended CPT</p>
                            <div
                              className={Styles.modalValueValue}
                              style={{ overflow: 'auto', width: '100%' }}
                            >
                              {this.state.addAmendCpt.map((cpt, index) => (
                                <div
                                  className={Styles.modalNotesRow}
                                  style={{
                                    justifyContent: 'space-between',
                                    borderTop: '1px solid #D3D3D3',
                                  }}
                                >
                                  <p>
                                    {index + 1}. {cpt.cpt_code}: {cpt.title}
                                  </p>
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className={Styles.deleteIcon}
                                    onClick={() => {
                                      let arr = this.state.addAmendCpt.filter(
                                        (data) =>
                                          data.cpt_code !== cpt.cpt_code,
                                      )
                                      this.setState(
                                        { addAmendCpt: arr },
                                        () => {
                                          this.autoSaveNote()
                                          this.saveAppointmentToRedux()
                                        },
                                      )
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                    </div>
                  }
                </Accordion.Body> */}
                {/* <Accordion.Body>
                  <div className={Styles.addManualRow}>
                    <p className={Styles.addManualQuestion}>Task Notes: </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '70%',
                      }}
                    >
                      <textarea
                        placeholder={'Please enter notes about task'}
                        style={{
                          border: '1px solid #d0d0d0',
                          padding: '5px 20px',
                          borderRadius: '4px',
                          width: '90%',
                          // height: '40px',
                        }}
                        rows="2"
                        type="text"
                        value={this.state.taskNote || ''}
                        onChange={(e) =>
                          this.setState({ taskNote: e.target.value })
                        }
                      />
                      <FontAwesomeIcon
                        className={Styles.plusButton}
                        onClick={() => {
                          if (this.state.taskNote.length) {
                            let tempNote = this.state.taskNotesList
                            tempNote.unshift({
                              author_id: this.props.adminId,
                              name:
                                (this.props?.mongoUser?.first_name || '') +
                                ' ' +
                                (this.props?.mongoUser?.last_name || ''),
                              note_text: this.state.taskNote,
                              created_at: new Date(),
                            })
                            this.setState({
                              taskNotesList: tempNote,
                              taskNote: '',
                            })
                          }
                        }}
                        icon={faPlusCircle}
                      />
                    </div>
                  </div>
                  {this.state.taskNotesList?.map((note, index) => (
                    <div className={Styles.addManualRow}>
                      <p className={Styles.addManualQuestion}>
                        {note.name}{' '}
                        {`(${moment(note.created_at).format('ll')})`}:{' '}
                      </p>
                      <textarea
                        placeholder={'Please enter notes about task'}
                        style={{
                          border: '1px solid #d0d0d0',
                          padding: '5px 20px',
                          borderRadius: '4px',
                          width: '70%',
                          // height: '40px',
                        }}
                        rows="2"
                        type="text"
                        value={note.note_text || ''}
                        onChange={(e) => {
                          let tempNote = this.state.taskNotesList
                          tempNote[index].note_text = e.target.value
                          tempNote[index].updated_at = new Date()
                          this.setState({ taskNotesList: tempNote })
                        }}
                        // disabled={
                        //   !(
                        //     !support_id ||
                        //     note.author_id === this.props.adminId ||
                        //     this.props?.mongoUser?.user_type?.toLowerCase() ===
                        //       'administrator'
                        //   )
                        // } // only let authors to edit their note
                      />
                    </div>
                  ))}
                </Accordion.Body> */}
              </Accordion.Item>
            </>
          }

          <hr />
          <Accordion.Item eventKey="17">
            <Accordion.Button className={Styles.accordionHeaderButton}>
              Amended CPT
              <FontAwesomeIcon
                icon={
                  this.state.activeAccordions.includes('17')
                    ? faArrowCircleUp
                    : faArrowCircleDown
                }
                className={Styles.arrowIcon}
                onClick={() => {}}
              />
            </Accordion.Button>
            <Accordion.Body>
              <hr />
              <div
                className={Styles.modalNotesRow}
                style={{ justifyContent: 'space-between' }}
              >
                <p className={Styles.modalNotesHeaderText}>
                  Search Amended CPT Note:
                </p>

                <div className={Styles.modalNotesAnswer}>
                  <SearchProvider config={esConfigCPTCodes}>
                    <WithSearch
                      mapContextToProps={({ wasSearched, setSearchTerm }) => ({
                        wasSearched,
                        setSearchTerm,
                      })}
                    >
                      {({ wasSearched, setSearchTerm }) => {
                        // FROM: https://discuss.elastic.co/t/hide-or-modify-button-on-searchbox/228451
                        return (
                          <div className="App">
                            <EsSearchBox
                              debounceLength={0}
                              autocompleteResults={{
                                titleField: 'search_term',
                              }}
                              searchAsYouType={true}
                              onSelectAutocomplete={(e) => {
                                console.log('tap:', mongoUser?.user_type)
                                if (
                                  'cpt' === 'cpt' && // Yes ik, but i copied the code, spare me
                                  mongoUser?.user_type !== 'Administrator' &&
                                  mongoUser?.user_type !== 'Billing'
                                ) {
                                  this.setState(
                                    (prevState) => ({
                                      addCpt: [
                                        ...prevState.addCpt,
                                        {
                                          cpt_code: e.cpt_code.raw,
                                          title: e.description.raw,
                                        },
                                      ],
                                      cptquery: '',
                                    }),
                                    () => {
                                      this.autoSaveNote()
                                      this.saveAppointmentToRedux()
                                    },
                                  )
                                } else if (
                                  mongoUser?.user_type === 'Administrator' ||
                                  mongoUser?.user_type === 'Billing'
                                ) {
                                  this.setState(
                                    (prevState) => ({
                                      addAmendCpt: [
                                        ...prevState.addAmendCpt,
                                        {
                                          cpt_code: e.cpt_code.raw,
                                          title: e.description.raw,
                                        },
                                      ],
                                      cptquery: '',
                                    }),
                                    () => {
                                      this.autoSaveNote()
                                      this.saveAppointmentToRedux()
                                    },
                                  )
                                } else {
                                  eventFire(
                                    document.getElementById('icdclear'),
                                    'click',
                                  )
                                  this.setState(
                                    {
                                      addIcd10: {
                                        icd10_code: e.code.raw,
                                        title: e.display.raw,
                                      },
                                      icd10query: '',
                                    },
                                    () => this.saveAppointmentToRedux(),
                                  )
                                }
                                setSearchTerm('')
                              }}
                              inputView={({
                                getAutocomplete,
                                getInputProps,
                                getButtonProps,
                              }) => (
                                <>
                                  <div className="sui-search-box__wrapper">
                                    <input
                                      {...getInputProps({
                                        placeholder: 'Search CPT codes',
                                      })}
                                    />
                                    {getAutocomplete()}
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        )
                      }}
                    </WithSearch>
                  </SearchProvider>
                  {/* <textarea
                    rows="1"
                    placeholder={'Enter Note'}
                    className={Styles.modalInputStyle}
                    type="text"
                    value={this.state.currentNote || ''}
                    onChange={(e) => {
                      this.setState({ currentNote: e.target.value })
                    }}
                  /> */}
                </div>
                <FontAwesomeIcon
                  className={Styles.plusButtonNormal}
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    if (this.state.currentNote.length) {
                      let tempNote = this.state.amendedNotesList
                      tempNote.unshift({
                        name:
                          (this.props?.mongoUser?.first_name || '') +
                          ' ' +
                          (this.props?.mongoUser?.last_name || ''),
                        note_text: this.state.taskNote,
                        created_at: new Date(),
                      })
                      this.setState({
                        amendedNotesList: tempNote,
                        currentNote: '',
                      })
                    }
                  }}
                  icon={faPlusCircle}
                />
              </div>
              {this.state.addAmendCpt?.map((cpt, index) => (
                <div className={Styles.modalNotesRow}>
                  <p className={Styles.modalNotesQuestion}>
                    {index + 1}. {cpt.cpt_code}:
                  </p>
                  <textarea
                    placeholder={''}
                    style={{
                      border: '1px solid #d0d0d0',
                      padding: '5px 20px',
                      borderRadius: '4px',
                      width: '70%',
                      // height: '40px',
                    }}
                    rows="2"
                    type="text"
                    value={cpt.title || ''}
                    onChange={(e) => {
                      // let tempNote = this.state.amendedNotesList
                      // tempNote[index].note_text = e.target.value
                      // tempNote[index].updated_at = new Date()
                      // this.setState({ amendedNotesList: tempNote })
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    className={Styles.deleteIcon}
                    onClick={() => {
                      let arr = this.state.addAmendCpt.filter(
                        (data) => data.cpt_code !== cpt.cpt_code,
                      )
                      this.setState({ addAmendCpt: arr }, () => {
                        this.autoSaveNote()
                        this.saveAppointmentToRedux()
                      })
                    }}
                  />
                </div>
              ))}
              ;
              <div className={Styles.modalNotesRow}>
                <textarea
                  rows="2"
                  placeholder={'Amended CPT'}
                  className={Styles.modalInputStyle}
                  type="text"
                  value={this.state.careplanAmendedNote}
                  onChange={(e) =>
                    this.setState(
                      { careplanAmendedNote: e.target.value },
                      () => {
                        this.autoSaveNote()
                        this.saveAppointmentToRedux()
                      },
                    )
                  }
                />
              </div>
              {/* <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Video Visit Start Time (
                    {`${new Date()
                      .toLocaleDateString(undefined, {
                        day: '2-digit',
                        timeZoneName: 'long',
                      })
                      .substring(4)}`}
                    ):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Enter Value'}
                    className={Styles.modalInputStyle}
                    type="datetime-local"
                    id="appointmentDate"
                    name="appointmentDate"
                    value={
                      this.state.appointmentDate
                        ? moment
                            .unix(this.state.appointmentDate / 1000)
                            .format('YYYY-MM-DDTHH:mm')
                        : moment().format('YYYY-MM-DDTHH:mm')
                    }
                    onChange={(val) =>
                      this.setState(
                        {
                          appointmentDate: moment(val.target.value).valueOf(),
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Video Visit End Time(
                    {`${new Date()
                      .toLocaleDateString(undefined, {
                        day: '2-digit',
                        timeZoneName: 'long',
                      })
                      .substring(4)}`}
                    ):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Enter Value'}
                    className={Styles.modalInputStyle}
                    type="datetime-local"
                    id="appointmentEndDate"
                    name="appointmentEndDate"
                    value={
                      this.state.appointmentEndDate
                        ? moment
                            .unix(this.state.appointmentEndDate / 1000)
                            .format('YYYY-MM-DDTHH:mm')
                        : moment().format('YYYY-MM-DDTHH:mm')
                    }
                    onChange={(val) =>
                      this.setState(
                        {
                          appointmentEndDate: moment(
                            val.target.value,
                          ).valueOf(),
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Video Consult Minutes
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Enter Value'}
                    className={Styles.modalInputStyle}
                    // style={{ width: '100%' }}
                    type="text"
                    value={`${moment
                      .unix(
                        (this.state.appointmentEndDate || moment().valueOf()) /
                          1000,
                      )
                      .diff(
                        moment.unix(
                          (this.state.appointmentDate || moment().valueOf()) /
                            1000,
                        ),
                        'minute',
                      )} minutes`}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Pre-Visit Review of Records (minutes):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'0 minutes'}
                    className={Styles.modalInputStyle}
                    style={{ width: '100%' }}
                    type="number"
                    value={
                      this.state.timeSpent?.pre_review_time || 0 + ' minutes'
                    }
                    onChange={(e) => {
                      let time = e.target.value
                      let temp = this.state.timeSpent
                      temp.pre_review_time = time
                      this.setState(
                        {
                          timeSpent: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    After Visit Documentation (minutes):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'0 minutes'}
                    className={Styles.modalInputStyle}
                    style={{ width: '100%' }}
                    type="number"
                    value={this.state.timeSpent?.after_visit || 0 + ' minutes'}
                    onChange={(e) => {
                      let time = e.target.value
                      let temp = this.state.timeSpent
                      temp.after_visit = time
                      this.setState(
                        {
                          timeSpent: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Provider Collaboration (referrals/ consults/ prior auth)
                    (minutes):
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'0 minutes'}
                    className={Styles.modalInputStyle}
                    style={{ width: '100%' }}
                    type="number"
                    value={
                      this.state.timeSpent?.provider_colab || 0 + ' minutes'
                    }
                    onChange={(e) => {
                      let time = e.target.value
                      let temp = this.state.timeSpent
                      temp.provider_colab = time
                      this.setState(
                        {
                          timeSpent: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Total Time Spent:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <input
                    placeholder={'Enter Value'}
                    className={Styles.modalInputStyle}
                    // style={{ width: '100%' }}
                    type="text"
                    value={
                      Number(
                        moment
                          .unix(
                            (this.state.appointmentEndDate ||
                              moment().valueOf()) / 1000,
                          )
                          .diff(
                            moment.unix(
                              (this.state.appointmentDate ||
                                moment().valueOf()) / 1000,
                            ),
                            'minute',
                          ) || 0,
                      ) +
                      Number(this.state.timeSpent?.pre_review_time || 0) +
                      Number(this.state.timeSpent?.after_visit || 0) +
                      Number(this.state.timeSpent?.provider_colab || 0) +
                      ' minutes'
                    }
                  />
                </div>
              </div>
              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Method of visit:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="method_of_visit"
                    options={[
                      {
                        label: 'Telehealth - Video Visit',
                        value: 'Telehealth - Video Visit',
                      },
                      {
                        label: 'Telehealth - Phone Call',
                        value: 'Telehealth - Phone Call',
                      },
                      {
                        label: 'Telehealth - E-Consult',
                        value: 'Telehealth - E-Consult',
                      },
                      {
                        label: 'Telehealth- Asynchronous visit',
                        value: 'Telehealth- Asynchronous visit',
                      },
                    ]}
                    value={
                      this.state.timeSpent?.method_of_visit
                        ? {
                            label: this.state.timeSpent?.method_of_visit,
                            value: this.state.timeSpent?.method_of_visit,
                          }
                        : {
                            label: 'Telehealth - Video Visit',
                            value: 'Telehealth - Video Visit',
                          }
                    }
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = { ...this.state.timeSpent }
                      // console.log('testing: ', temp)
                      temp.method_of_visit = val.value
                      this.setState(
                        {
                          timeSpent: temp,
                        },
                        () => {
                          this.saveAppointmentToRedux()
                          this.autoSaveNote()
                        },
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div>

              <div className={Styles.modalNotesRow}>
                <div className={Styles.modalNotesQuestion}>
                  <p className={Styles.modalNotesRegularText}>
                    Interpreter Present at Visit:
                  </p>
                </div>
                <div className={Styles.modalNotesAnswer}>
                  <Select
                    inputId="interpreter"
                    options={[
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' },
                      { label: 'N/A', value: 'n/a' },
                    ]}
                    value={
                      this.state.followupData?.interpreter_present || {
                        label: 'N/A',
                        value: 'n/a',
                      }
                    }
                    isSearchable
                    autoBlur
                    onChange={(val) => {
                      let temp = { ...this.state.followupData }
                      temp.interpreter_present = val
                      this.setState(
                        {
                          followupData: temp,
                          updateFollowUp: true,
                        },
                        () => this.autoSaveFollowUp(),
                      )
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#20A89233',
                        primary: '#20A892',
                      },
                    })}
                  />
                </div>
              </div> */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div style={{ padding: 30 }} />

        {
          <div
            style={
              isHealthCoach
                ? {
                    bottom: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    marginBottom: 20,
                  }
                : {
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    marginBottom: 20,
                  }
            }
          >
            {this.state?.note_id?.length !== 0 && (
              <Button
                onClick={() => this.noteSubmit(isHealthCoach, 'archive')}
                className={Styles.saveButton}
                style={{ width: 140 }}
                variant="primary"
              >
                Archive
              </Button>
            )}
            <Button
              onClick={() => this.noteSubmit(isHealthCoach, 'saved')}
              className={Styles.saveButton}
              style={{ width: 140 }}
              variant="primary"
            >
              Save
            </Button>
            <Button
              onClick={() => this.noteSubmit(isHealthCoach, 'completed')}
              className={GlobalStyles.button}
              style={{ width: 140 }}
              variant="primary"
            >
              Complete note
            </Button>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    token: state.authReducer.token,
    docFirebaseAuthUser: state.userReducer.firebaseAuthUser,
    mongoUser: state.userReducer.mongoUser,
    adminId: state.userReducer.adminId,
    patientList: state.patientReducer.patientList,
    doctorPatientData: state.appointmentReducer.patientData,
    appointmentData: state.appointmentReducer.appointmentData,
    patient: state.patientReducer.patient,
    appointmentNotesData: state.appointmentReducer.appointmentNotesData,
    openNotesModal: state.genericReducer.openNotesModal,
  }
}

const mapDispatchToProps = {
  setAppointmentNotesData,
  fetchPatientCarePlanData,
  fetchPatientCoreDate,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal)
