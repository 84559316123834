import React from 'react'
import Select from 'react-select'
import Styles from './styles/Columns.module.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import Constants from '../../../values'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentEditable from 'react-contenteditable'
import ReactTooltip from 'react-tooltip'
import { taskTypes } from '../../../metadata'

export const clinicalSupportColumns = ({
  navigatetoPatientProfile,
  onPatientNavLinkClicked,
  providerOptionsData,
  allProviderData,
  updateSupportTicket,
  openNotesModal,
}) => {
  return [
    {
      Header: 'Patient Name',
      // accessor: 'patientName',
      accessor: (obj) => {
        let patientData = obj
        return patientData?.patient_name
      },
      Cell: ({ row }) => {
        let patientData = row?.original
        return (
          <Link
            onClick={() => {
              navigatetoPatientProfile(
                row?.original?.userType?.toLowerCase() === 'patient'
                  ? row?.original?.firebase_id
                  : row?.original?.patient_id,
              )
              onPatientNavLinkClicked('profile')
            }}
            to={{
              pathname: '/patient/profile',
              state: 'info',
              key: 2,
            }}
            className={Styles.link}
          >
            {patientData?.patient_name || ''}
          </Link>
        )
      },
      width: 250,
      sort: true,
      style: {
        position: 'sticky',
        left: '0px',
        zIndex: 10,
        backgroundColor: '#F3F3F3',
      },
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      disableFilters: true,
      sortType: (a, b) => {
        return new Date(b.values.created_at) - new Date(a.values.created_at)
      },
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          {moment(row.value).format('MMM DD YYYY')}
        </div>
      ),
      width: 200,
      sort: true,

      // style: {
      //   position: 'sticky',
      //   left: 300,
      //   zIndex: 10,
      //   backgroundColor: '#F3F3F3',
      // },
    },
    {
      Header: 'Raised By',
      accessor: (obj) =>
        obj?.userType?.toLowerCase() !== 'patient' ? obj?.name : 'Patient',
      width: 250,
      sort: true,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Provider Name',
      accessor: (obj) => {
        let providerData = obj?.providerData || null
        return (
          allProviderData?.find((ele) => ele.value === obj?.doctor_id)?.label ||
          '-'
        )
      },
      width: 250,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%' }}>
              <input
                placeholder="Search"
                className={Styles.textInput}
                style={{ width: '100%' }}
                type="text"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </span>
        )
      },
      sort: true,
    },
    {
      Header: 'Priority',
      accessor: (obj) => obj?.priority,
      width: 200,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="Priority"
                placeholder="Select"
                options={[
                  { label: 'High', value: 'HIGH' },
                  { label: 'Medium', value: 'MEDIUM' },
                  { label: 'Low', value: 'LOW' },
                ]}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Subject',
      accessor: (obj) => obj?.subject,
      width: 250,
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                inputId="Priority"
                placeholder="Select"
                options={taskTypes}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                autoBlur
                onChange={(val) => {
                  setFilter(val.value)
                  // filterByPatientType(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
    },
    {
      Header: 'Description',
      accessor: (obj) => obj?.description,
      Cell: ({ row }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              data-tip
              data-for={row?.original?._id + 'desc'}
            >
              {row?.original?.description}
            </p>
            <ReactTooltip
              id={row?.original?._id + 'desc'}
              multiline={true}
              place={'top'}
              effect={'solid'}
              clickable={true}
              className={Styles.tooltip}
            >
              {row?.original?.description}
            </ReactTooltip>
          </div>
        )
      },
      width: 350,
    },

    {
      Header: 'Assign',
      accessor: (obj) => {
        let data = allProviderData.find((ele) => ele.value === obj?.assigned_to)
        return data?.label
      },
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Select
              options={providerOptionsData}
              // className={Styles.addSelectInput}
              autoBlur={true}
              isMulti={false}
              onChange={(val) => {
                let tempSupport = {
                  _id: row?.original?._id,
                  assigned_to: val.value,
                }
                updateSupportTicket(tempSupport)
              }}
              value={
                allProviderData.find(
                  (ele) => ele.value === row?.original?.assigned_to,
                ) || null
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#20A89233',
                  primary: '#20A892',
                },
              })}
            />
          </div>
        )
      },
      Filter: ({ column }) => {
        const { filterValue, setFilter } = column
        return (
          <span>
            <div style={{ width: '100%', fontWeight: 'normal' }}>
              <Select
                options={providerOptionsData}
                // className={Styles.addSelectInput}
                autoBlur={true}
                isMulti={false}
                value={
                  filterValue
                    ? { label: filterValue, value: filterValue }
                    : null
                }
                isSearchable
                onChange={(val) => {
                  let data = allProviderData.find(
                    (ele) => ele.value === val?.value,
                  )
                  console.log(data?.label)
                  setFilter(data.label)
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#20A89233',
                    primary: '#20A892',
                  },
                })}
              />
            </div>
          </span>
        )
      },
      width: 300,
    },
    {
      Header: 'Status',
      accessor: (obj) => {
        const STATUS_OPTIONS = [
          { label: 'Not Started', value: 'not_started' },
          { label: 'In Progress', value: 'in_progress' },
          { label: 'Completed', value: 'completed' },
        ]
        return (
          <Select
            options={STATUS_OPTIONS}
            // className={Styles.addSelectInput}
            autoBlur={true}
            isMulti={false}
            disable
            onChange={(val) => {
              let tempSupport = {
                _id: obj._id,
                status: val.value,
              }
              updateSupportTicket(tempSupport)
            }}
            value={
              STATUS_OPTIONS.find((ele) => ele.value === obj?.status) ||
              (obj?.completed_status ? STATUS_OPTIONS[2] : STATUS_OPTIONS[0])
            }
            select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#20A89233',
                primary: '#20A892',
              },
            })}
          />
        )
      },
      width: 300,
    },
    {
      Header: 'Due Date',
      accessor: 'due_date',
      disableFilters: true,
      sortType: (a, b) => {
        return new Date(b.values.due_date) - new Date(a.values.due_date)
      },
      Cell: ({ row }) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <p
            style={{
              color:
                !(
                  row?.original?.completed_status ||
                  row?.original?.status === 'completed'
                ) && moment(row?.original?.due_date).isBefore(moment())
                  ? 'red'
                  : 'black',
            }}
          >
            {moment(row?.original?.due_date).format('MMM DD YYYY') || ''}
          </p>
        </div>
      ),
      width: 200,
      sort: true,
    },
    {
      Header: 'Notes',
      accessor: (obj) => {
        return (
          <p
            style={{
              textDecoration: 'underline',
              textDecorationColor: Constants.primaryTheme,
              color: Constants.primaryTheme,
              cursor: 'pointer',
              textAlign: 'center',
            }}
            onClick={() => {
              console.log('open note')
              openNotesModal(obj)
            }}
          >
            Notes
          </p>
        )
      },
      width: 200,
    },
  ]
}
