import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import GlobalStyles from '../styles/global.module.scss'
import AilaLogo from '../../assets/images/aila_logo.png'
import CommImage from '../../assets/images/comm_1.png'
import Styles from './styles/Success.module.scss'
const queryString = require('query-string')

export default class SuccessAppointment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage('success_subscription')

    const parsed = queryString.parse(this.props.location.search)
    console.log('parsed', parsed)
    try {
      window.tap('create', '23260-619ee4', { integration: 'stripe' })
      window.tap('conversion', parsed.charge_id, parsed.amount, {
        customer_id: parsed.customer_id,
      })
      console.log('successful conversion for tapfiliate')
    } catch (err) {
      console.log('error when triggering conversion for tapfiliate', err)
    }
    this.setState({ loading: false })
  }

  render() {
    const windowWidth = window.innerWidth
    if (this.state.loading)
      return (
        <div
          className={GlobalStyles.container}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            paddingTop: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '100%',
              height: '80vh',
            }}
          >
            <div className={GlobalStyles.loader} />
          </div>
        </div>
      )

    return (
      <div
        className={GlobalStyles.container}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          paddingTop: 20,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <img src={AilaLogo} style={{ height: 120, width: 120 }} />
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div className={Styles.commImageWrapper}>
            <img
              src={CommImage}
              style={
                windowWidth > 600
                  ? { height: 175, width: 175 }
                  : { height: 125, width: 125 }
              }
            />
          </div>
        </div>

        <h5
          style={{
            marginTop: 10,
            fontSize: windowWidth > 600 ? 20 : 14,
            textAlign: 'center',
            color: '#7e7e7e',
          }}
        >
          You have successfully booked your appointment with Aila Health Doctor.
        </h5>

        {windowWidth > 600
          ? this.renderDownloadNowButton()
          : this.renderGetStartedButton()}
      </div>
    )
  }

  renderDownloadNowButton() {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.ailahealth.com/ailahealthapp"
      >
        <button
          onClick={null}
          className={GlobalStyles.button}
          style={{
            width: 200,
            marginTop: 40,
            borderRadius: 15,
            borderWidth: 0,
            height: 40,
            fontWeight: 'bold',
          }}
        >
          <p style={{ color: 'white' }}>Dowload App Now</p>
        </button>
      </a>
    )
  }

  renderGetStartedButton() {
    return (
      <button
        onClick={() => {
          console.log('Onclick appointment booked iframe ')

          window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage('getStarted')
        }}
        className={GlobalStyles.button}
        style={{
          width: 200,
          marginTop: 40,
          borderRadius: 15,
          borderWidth: 0,
          height: 40,
          fontWeight: 'bold',
        }}
      >
        <p style={{ color: 'white' }}>Continue</p>
      </button>
    )
  }
}
