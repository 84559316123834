import React, {useState, useEffect} from 'react'
import Styles from "./Musculoskeletal.module.scss";
import {
	Legend,
	Line,
	LineChart,
	Tooltip,
	XAxis,
	YAxis,
	ReferenceLine,
	ReferenceArea,
	AreaChart,
	Label,
	CartesianGrid,
	Area
} from "recharts";
import Select from "react-select";
import axios from 'axios';
import moment from 'moment';
import Constants from "../../values";
import Config from "../../config";

let graphTempData = [];
let graphWidth = (window.innerWidth) / 2;

const MUSCLE_OPTIONS = [
	{key: 0, name: "Pain Index", checked: 0, dbName: 'painIndex', type: 'body'},
	{key: 1, name: "Jaw", checked: 0, dbName: 'jaw', type: 'body'},
	{key: 2, name: "Neck", checked: 0, dbName: 'neck', type: 'body'},
	{key: 3, name: "Chest", checked: 0, dbName: 'chest', type: 'body'},
	{key: 4, name: "Abdomen", checked: 0, dbName: 'abdomen', type: 'body'},
	{key: 5, name: "Shoulder Gridle", checked: 0, dbName: 'shoulder_gridle', type: 'front'},
	{key: 6, name: "Upper Arm", checked: 0, dbName: 'upper_arm', type: 'front'},
	{key: 7, name: "Lower Arm", checked: 0, dbName: 'lower_arm', type: 'front'}, 
	{key: 8, name: "Upper Leg", checked: 0, dbName: 'upper_leg', type: 'front'},
	{key: 9, name: "Lower Leg", checked: 0, dbName: 'lower_leg', type: 'front'},
	{key: 10, name: "Upper Arm Back", checked: 0, dbName: 'upper_arm', type: 'back'},
	{key: 11, name: "Lower Arm Back", checked: 0, dbName: 'lower_arm', type: 'back'},
	{key: 12, name: "Upper Leg Back", checked: 0, dbName: 'upper_leg', type: 'back'},
	{key: 13, name: "Lower Leg Back", checked: 0, dbName: 'lower_leg', type: 'back'},
	{key: 14, name: "Upper Back", checked: 0, dbName: 'upper_back', type: 'body'},
	{key: 15, name: "Lower Back", checked: 0, dbName: 'lower_back', type: 'body'},
	{key: 16, name: "Hip/ Buttock", checked: 0, dbName: 'hip_buttock', type: 'front'},
]

const getPainValue = (data, name) => {
  // console.log('Muscle Pain VALUES: ', data[name])
  if(name === 'painIndex') {
    return data[name]
  } else {
    return data[name]?.value
  }
}

const getSwollenValue = (data, name) => {
  // console.log('Swollen VALUES: ', data[name]?.swollen)
  return data[name]?.swollen
}

const CustomTooltip = ({ active, payload, label }) => {
	if (active) {
		return (
			<div className={Styles.customTooltip}>
				<p className={Styles.tooleTipLabel}>{`Treatment: ${label}`}</p>
				{/* <p className="intro">{getIntroOfPage(label)}</p>
        <p className="desc">Anything you want can be displayed here.</p> */}
			</div>
		);
	}
	return null;
};

const renderChart = (data, graphWidth,title, unitString, getMuscleName, chartRange, setChartRange) =>{
  let chartData = data.filter((data) => moment(data.created_at).isSame(new Date(), chartRange.value))

  if(chartData.length === 0) {
    return (
      <div style={{display:'flex', justifyContent: 'center', textAlign: 'center', height: 350, alignItems: 'center'}}>
       <p>
         No Data Present
       </p>
      </div>
    )
  }
  
	return(
    <LineChart width={graphWidth} height={350} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
      <Line 
        dot={false} 
        name={'Pain Index'}  
        type="monotone" 
        dataKey={(data) => getPainValue(data, getMuscleName())}
        stroke={Constants.graphLineOne} 
      />

      <XAxis dataKey="created_at" tickFormatter={(tickItem) => {
        return moment(tickItem).format('D-MMM')
      }}/>
      <YAxis
        label={{ value: 'Pain Score', angle: -90, position: 'insideLeft', offset: 10}}
        domain={[0,10]}/>
      <Legend verticalAlign="top" height={36}/>
      <Tooltip content={({ active, payload, label }) => {
        let date = moment(label).format('D-MMM')
        return (
          <div className={Styles.painChartTooltip} style={{width: 240}}>
            <p>{date}</p>
            
            <div style={{width: '100%', padding: '0px 5px'}}>
              <p style={{fontWeight: 'bold', textAlign: 'left'}}>{payload[0]?.name}: <span style={{fontWeight: 'normal', marginLeft: 5}}>{payload && payload.length > 0 && payload[0]?.value}</span></p>
            </div>
                
          </div>
        )
      }}/>
    </LineChart>
	)
}


export default function JointPainChart({token, patientId}) {
  const [muscleKey, setMuscleKey] = useState(0)
  const [muscleData, setMuscleData] = useState([])
  const [muscleSide, setMuscleSide] = useState({label: 'Right', value: 'right'})
  const [chartRange, setChartRange] = useState({label: 'Year', value: 'year'})
  // const [jointName, setJointName] = useState('')
  const [loading, setLoading] = useState(false)

  const getData = () => {
    const url = Config.BACKEND_URL + 'screenings/pain'
    axios({
      method: 'get',
      url,
      headers: { Authorization: `JWT ${token}`, x_firebase_id: patientId, complete: true },
    })
      .then(({ data }) => {
        setMuscleData(data.reverse())
        setLoading(false)
      })
      .catch((err) => {
        console.log('error when getting screening', err)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const getMuscleName = () => {
    if(MUSCLE_OPTIONS[muscleKey].type === 'body') {
      return MUSCLE_OPTIONS[muscleKey].dbName
    }
    else if(MUSCLE_OPTIONS[muscleKey].type === 'front') {
      if(muscleSide.value === 'right') {
        return `${MUSCLE_OPTIONS[muscleKey].dbName}_right`
      } else {
        return `${MUSCLE_OPTIONS[muscleKey].dbName}_left`
      }
    }
    else if(MUSCLE_OPTIONS[muscleKey].type === 'back') {
      if(muscleSide.value === 'right') {
        return `${MUSCLE_OPTIONS[muscleKey].dbName}_right_back`
      } else {
        return `${MUSCLE_OPTIONS[muscleKey].dbName}_left_back`
      }
    }
    else return ''
  }

  const onMuscleChange = (key) => {
    setMuscleKey(key)
  }

  return (
    <div className="row" style={{width: '100%', alignSelf: 'flex-start', paddingLeft: 30}}>
      <div className="col-sm-8">
      <div className={Styles.chartClassOld} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h5 style={{width: '100%', textAlign: 'center', color: Constants.primaryTheme}}>Widespread Pain Index</h5>
        <div className={Styles.chartSelectReproductive}>
				<div style={{width: '100%', height: 30}}>
					<Select
						isSearchable={false}
						className={Styles.selectStyle}
						classNamePrefix={Styles.selectStyleInnerGraph}
						autoBlur={true}
						options={[
							{label: 'Year', value: 'year'},
							{label: 'Month', value: 'month'},
							{label: 'Week', value: 'week'}
						]}
						value={chartRange}
						onChange={(val) => {
							setChartRange(val)
						}}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary25: '#20A89233',
								primary: '#20A892',
							},
						})}
					/>
				</div>
			</div>
        {renderChart(muscleData, graphWidth, 'Joint Pain', 'Severity', getMuscleName, chartRange, setChartRange)}
      </div>
      </div>
      <div className="col" >
        <div className="col">
        <div style={{width: 100, height: 30, marginBottom: 20}}>
					<Select
						isSearchable={false}
						className={Styles.selectStyle}
						classNamePrefix={Styles.selectStyleInnerGraph}
						autoBlur={true}
            defaultValue={{label: 'Right', value: 'right'}}
						options={[
							{label: 'Right', value: 'right'},
							{label: 'Left', value: 'left'},
						]}
						value={muscleSide}
						onChange={(val) => {
							setMuscleSide(val)
						}}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary25: '#20A89233',
								primary: '#20A892',
							},
						})}
					/>
				</div>
        <div className="radioButton" style={{height: 350, overflowY: 'scroll', borderStyle: 'ridge', padding: 10}}>
          {
            MUSCLE_OPTIONS.map((radioData, index)=>{
              return(
                <label className={Styles.radioConatinerNew} key={index.toString()}>{radioData.name}
                  <input
                    type="radio"
                    checked={radioData.key === muscleKey}
                    onChange={()=> {
                      onMuscleChange(radioData.key)
                    }}
                    name="muscle"
                  />
                  <span className={Styles.checkmark}></span>
                </label>
              )
            })
          }
        </div>
        </div>

      </div>
      
    </div>
  )
}
